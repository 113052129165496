export default {
    "FSM_SETTINGS_TIME_CARD": "Tarjetas de tiempo",
    "FSM_SETTINGS_TIME_CARD_driver": "Conductor",
    "FSM_SETTINGS_TIME_CARD_startDate": "Fecha de inicio",
    "FSM_SETTINGS_TIME_CARD_endDate": "Fecha de finalización",
    "FSM_SETTINGS_TIME_CARD_clockIn": "Fichar entrada",
    "FSM_SETTINGS_TIME_CARD_clockOut": "Fichar Salida",
    "FSM_SETTINGS_TIME_CARD_meakbegin": "Comienzo del descanso para comer",
    "FSM_SETTINGS_TIME_CARD_meakEnd": "Fin del descanso para comer",
    "FSM_SETTINGS_TIME_CARD_action": "Accion",
    "FSM_SETTINGS_TIME_CARD_date": "Fecha",
    "FSM_SETTINGS_TIME_CARD_title": "Nueva Tarjeta de tiempo",
    "FSM_SETTINGS_TIME_CARD_edit": "Editar Tarjeta de tiempo ",
    "FSM_SETTINGS_TIME_CARD_delete_detail":'¿Está seguro de que desea eliminar las siguientes Tarjeta de tiempo?',
    "FSM_SETTINGS_TIME_CARD_delete_warning":'Esta acción no se puede deshacer.',
    "FSM_SETTINGS_TIME_CARD_delete_title":'Eliminar Tarjeta de tiempo',
    "FSM_SETTINGS_TIME_CARD_create_success": "Tarjeta de tiempo Creada ",
    "FSM_SETTINGS_TIME_CARD_update_success": "Tarjeta de tiempo Actualizada",
    "FSM_SETTINGS_TIME_CARD_delete_success": "Tarjeta de tiempo Eliminada",
    "FSM_SETTINGS_TIME_CARD_error_date": "La fecha inicial no puede ser mayor a la fecha final",
    "FSM_SETTINGS_TIME_CARD_error_date_edit": " la fecha debe ser menor a la actual",
    "FSM_SETTINGS_TIME_CARD_total": "Total",
    "FSM_SETTINGS_TIME_CARD_daily_total": "Total Diario"
};