import React from "react";

export const LoginLogo = ({urlImgLogo}) => {
  return (
    <div style={{ textAlign: "center" }}>
      {urlImgLogo && (
        <img src={urlImgLogo} alt="image" className="login-logo-size" />
      )}
    </div>
  );
};

export default LoginLogo;
