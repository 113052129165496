import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

//STATE INITIAL
const INIT_STATE = {
    open: false,
    response: null,
    error: null,
    loading: false,
    tasks: null,
    driverName: null,
};

const route = "modules/maintenance/modals/deleteDriverScheduledTask/redux/slice";

export const maintenanceDDSTDeleteDriverScheduledTask = createAsyncThunk("maintenance/manageTasks/deleteDriverScheduledTask", async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientMutation(
            `
            mutation ($id: Int!) {
                data: deleteDriverScheduledTask (
                    id: $id
                )
            }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );
        if (!response.data) {
            throw 'Error Searching Scheduled tasks'
        }

        return response.data

    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceDDSTDeleteDriverScheduledTask');
        return rejectWithValue(exc);
    }
})

export const maintenanceDeleteDriverScheduledTaskRedux = createSlice({
    name: "maintenance/deleteDriverScheduledTask",
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openModalDeleteDriverScheduledTaskRedux(state, action) {
            return {
                ...state,
                open: action.payload.open,
                tasks: action.payload.tasks,
                driverName: action.payload.driverName
            }
        },
    },
    extraReducers: (builder) => {

        //maintenanceDDSTDeleteDriverScheduledTask
        builder.addCase(maintenanceDDSTDeleteDriverScheduledTask.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceDDSTDeleteDriverScheduledTask.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });

        builder.addCase(maintenanceDDSTDeleteDriverScheduledTask.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });
    }
})

const { actions, reducer } = maintenanceDeleteDriverScheduledTaskRedux;

// Extract and export each action creator by name
export const {
    reset,
    openModalDeleteDriverScheduledTaskRedux
} = actions;

export default reducer;