import {
  GET_DAILY_BEHAVIOR,
  GET_DAILY_BEHAVIOR_RESULT,
  UPDATE_POINTS_BEHAVIOR,
  UPDATE_GRADES_BEHAVIOR,
  SET_BEHAVIOR_POINTS,
  SET_BEHAVIOR_POINTS_RESULT,
  GET_BEHAVIOR_TRENDS,
  GET_BEHAVIOR_TRENDS_RESULT,
  BEHAVIOR_TRENDS_CHART_INFO,
  GET_POINTS_BEHAVIOR_RESULTS,
  GET_POINTS_BEHAVIOR,
  CREATE_NEW_CUSTOM_SPEED,
  CREATE_NEW_CUSTOM_SPEED_RESULTS,
  DELETE_CUSTOM_SPEED,
  DELETE_CUSTOM_SPEED_RESULT,
  EXPORT_DAILY_BEHAVIOR,
  EXPORT_DAILY_BEHAVIOR_RESULT,
  CLEAR_REDUX
} from 'Redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
  loadingDriversVehiclesList: false,
  driversOrVehicles: [],
  points: {
    hardTurn: 1,
    hardBrake: 1,
    suddenStop: 1,
    rapidAcceleration: 1,
    speeding75: 1,
    speeding80: 1,
    speeding85: 1,
    allowance: 1
  },
  grades: [0, 10, 20, 30, 40, 100],
  loadingPointsGrades: false,
  loadingBehaviorTrends: false,
  newCustomSpeedResult: false,
  deletedCustomSpeed: false,
  behaviorTrends: [],
  loadingBehivorTrendsChartInfo: false,
  behivorTrendsChartInfo: { data: { datasets: [], labels: [] } },
  updatedPoints: {
    update: false,
    isNew: false,
    Id: null
  },
  exportDailyBehaviorResult: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EXPORT_DAILY_BEHAVIOR:
      return {
        ...state,
        exportDailyBehaviorResult: null,
      };
    case EXPORT_DAILY_BEHAVIOR_RESULT:
      return {
        ...state,
        exportDailyBehaviorResult: action.payload.data
      };
    case CREATE_NEW_CUSTOM_SPEED:
      return {
        ...state,
        newCustomSpeedResult: false,
        loadingPointsGrades: true
      };
    case CREATE_NEW_CUSTOM_SPEED_RESULTS:
      return {
        ...state,
        newCustomSpeedResult: action.payload.data,
        loadingPointsGrades: false
      };
    case DELETE_CUSTOM_SPEED:
      return {
        ...state,
        deletedCustomSpeed: false,
        loadingPointsGrades: true
      };
    case DELETE_CUSTOM_SPEED_RESULT:
      return {
        ...state,
        deletedCustomSpeed: action.payload.data,
        loadingPointsGrades: false
      };
    case GET_POINTS_BEHAVIOR:
      return {
        ...state,
        loadingPointsGrades: true
      };
    case GET_POINTS_BEHAVIOR_RESULTS:
      return {
        ...state,
        points: action.payload.data,
        loadingPointsGrades: false
      };
    case GET_DAILY_BEHAVIOR:
      return {
        ...state,
        driversOrVehicles: [],
        loadingDriversVehiclesList: true,
      };
    case GET_DAILY_BEHAVIOR_RESULT:
      return {
        ...state,
        driversOrVehicles: action.payload.data,
        loadingDriversVehiclesList: false,
      };
    case UPDATE_POINTS_BEHAVIOR:
      return {
        ...state,
        points: action.payload.data
      };
    case UPDATE_GRADES_BEHAVIOR:
      return {
        ...state,
        grades: action.payload.data
      };
    case SET_BEHAVIOR_POINTS:
      return {
        ...state,
        updatedPoints: {
          update: false,
          isNew: false,
          Id: null
        }
      };
    case SET_BEHAVIOR_POINTS_RESULT:
      return {
        ...state,
        updatedPoints: action.payload.data
      };

    case GET_BEHAVIOR_TRENDS:
      return {
        ...state,
        loadingBehaviorTrends: true
      };
    case GET_BEHAVIOR_TRENDS_RESULT:
      return {
        ...state,
        loadingBehaviorTrends: false,
        behaviorTrends: action.payload.data
      };
    case BEHAVIOR_TRENDS_CHART_INFO:
      return {
        ...state,
        loadingBehivorTrendsChartInfo: false,
        behivorTrendsChartInfo: action.payload.data
      };
    case CLEAR_REDUX:
          return ["", "BEHAVIOR_MAIN"].includes(action.payload.option)
              ? action.payload.parameters
                  ? { ...state, ...action.payload.parameters }
                  : { ...INIT_STATE }
              : { ...state }
    default:
      return state;
  }
};
