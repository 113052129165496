import {
    MAP_LANDMARKS_DELETE_OPEN_MODAL,
    MAP_LANDMARKS_DELETE_LANDMARKS,
    MAP_LANDMARKS_DELETE_LANDMARKS_RESPONSE
} from 'Redux/actionTypes';

export const mapLandmarksOpenDeleteModal = (open, landmarks, deleteAll = false, unselectedRows = []) => ({
    type: MAP_LANDMARKS_DELETE_OPEN_MODAL,
    payload: { open, landmarks, deleteAll, unselectedRows},
});

export const mapLandmarksDeleteLandmarks = (landmarks, deleteAll, unselectedRows) => ({
    type: MAP_LANDMARKS_DELETE_LANDMARKS,
    payload: { landmarks, deleteAll, unselectedRows },
});

export const mapLandmarksDeleteLandmarksResponse = (data) => ({
    type: MAP_LANDMARKS_DELETE_LANDMARKS_RESPONSE,
    payload: { data },
});