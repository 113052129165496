// Dependencies
import React, { useEffect } from "react";
import { ImageList, ImageListItem, Grid } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Button } from 'Components';
import { Waypoint } from 'react-waypoint';
import CircularProgress from '@mui/material/CircularProgress';
import { useIntl } from 'react-intl';
// Components
import { Checkbox } from "Components";
import InputSearch from 'Components/InputSearch';

// Styles
import "../index.scss"

// Constants
const ENTITY_SELECTOR_HEIGHT = '300px';

const ItemsList = (props) => {
  const {
    items = [],
    onChange,
    selectedItems,
    initialSize = 4,
    checkedIcon,
    unCheckedIcon,
    expandButtonLabel = 'Expand Default',
    collapseButtonLabel = 'Collapse Default',
    onlyItem,
    selectedEntity,
    searchText: externalSearchText,
    triggerCollapse,
    selectAllItems,
    onChangeSelectAll
  } = props;

  const {
    entityOffset,
    entityCallback,
    fieldText,
    fieldValue,
    entitySearch: setExternalSearchText,
    landmarksPerPage,
    totalPaginatedItems,
  } = selectedEntity;
  
  const { messages } = useIntl();



  const [collapsed, setCollapsed] = React.useState(true);
  const [searchText, setSearchText] = React.useState('');

  // Search filter
  const searchResults = (searchText) => items.filter((item) => item[fieldText]
    .toUpperCase()
    .includes(searchText.toUpperCase()));
  const sliceArray = (array, limit) => array.slice(0, limit);
  const itemsList = searchText ? searchResults(searchText) : items;
  const filterEntities = () => collapsed ? sliceArray(itemsList, initialSize) : itemsList;
  const buttonLabel = () => {
    const text = collapsed ? expandButtonLabel : collapseButtonLabel;
    const totalItems = `(${itemsList.length})`;
    return `${text} ${collapsed ? totalItems : ''}`;
  };

  // The expand button is visible only when there are extra items to display.
  const visibleButton = itemsList?.length > initialSize;

  /**
   * Determines if it should display the "load more" button inside the entity items.
   * @returns {boolean} true if it should display the "load more" button, false otherwise.
   */
  const showLoadMoreButton = () => {
    const paginatedItems = entityCallback && !externalSearchText;
    const moreItemsAvailable = totalPaginatedItems > items?.length;

    return paginatedItems && moreItemsAvailable && !collapsed;
  };

  useEffect(() => {
    setSearchText('');
  }, [selectedEntity]);

  const loadMoreLandmarks = () => {
    return (
      <Waypoint
        onEnter={() => {
          const newOffset = entityOffset + landmarksPerPage;
          return entityCallback(newOffset);
        }}
      />
    );
  };
  return (
    <Grid>
      <InputSearch
        searchText={externalSearchText || searchText}
        onSearch={(param) => setExternalSearchText ? setExternalSearchText(param) : setSearchText(param)}
        searchWhenFinishedTyping={true}
        className={'EntitySelector-inputSearch'}
        onSearchWhenFinish={(param) => setExternalSearchText ? setExternalSearchText(param) : setSearchText(param)}
      />
      <ImageList className={'EntitySelector-gridList'}>
        
          {
            (!searchText) && onChangeSelectAll && (
              <ImageListItem style={{height: 'auto', width: '100%' }}>
                <Checkbox
                  id={'select-all-item-list'}
                  value={selectAllItems}
                  label={`${messages['all']} ${selectedEntity.name}`}
                  onChange={(checked) => {
                    onChangeSelectAll(checked, checked? items : [])
                  }}
                  size="small"
                  checkedIcon={checkedIcon || <CheckBoxIcon />}
                  unCheckedIcon={unCheckedIcon || <CheckBoxOutlineBlankIcon />}
                />
              </ImageListItem>
            )
          }
          
          {
            filterEntities(items).map((item, key) => {
              const id = item[fieldValue];
              const label = item[fieldText];
              if(!id && !label){
                return ;
              }
              return (
                <ImageListItem style={{height: 'auto', width: '100%' }} key={`${id}-${key}`}>
                  <div style={{ paddingLeft: 10}}>
                    <Checkbox
                      id={`${id?.toString()}-${key}` }
                      value={!!(selectedItems.find((selectedItem) => selectedItem[fieldValue] === id))}
                      label={label}
                      onChange={(checked) => {
                        let filteredItems;
                        if (checked) {
                          const checkedItem = items.find((item) => item[fieldValue] === id);
                          const selectedItemsList = onlyItem ? [] : selectedItems;
                          filteredItems = [...selectedItemsList, checkedItem];
                        } else {
                          filteredItems = [...selectedItems];
                          filteredItems.splice(selectedItems.indexOf(item), 1);
                        }
                        onChange(filteredItems);
                        filteredItems = [];
                      }}
                      size="small"
                      checkedIcon={checkedIcon || <CheckBoxIcon />}
                      unCheckedIcon={unCheckedIcon || <CheckBoxOutlineBlankIcon />}
                    >
                    </Checkbox>
                  </div>
                </ImageListItem>
              );
            })
          }
          
          
          {showLoadMoreButton() && (
            <div className="way-point-load-landmarks">
              {loadMoreLandmarks()}
              <CircularProgress disableShrink />
            </div>
          )}
        
      </ImageList>
      {visibleButton && (
        <Button
          type="button"
          className='buttonCollapsed'
          variant="contained"
          onClick={(event) => {
            event.preventDefault();
            setCollapsed(!collapsed);
            if(triggerCollapse){
              triggerCollapse(!collapsed);
            }
          }}>
          {buttonLabel()}
        </Button>
      )}
    </Grid>
  )
}

export default ItemsList;