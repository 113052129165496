import routesTrackerModalFiltersLanguage from '../components/modalFilters/lan/en';
import routesTrackerModalStopInformationLanguage from '../components/modalStopInformation/lan/en';
import routesTrackerModalStopsVisitedLanguage from '../components/modalStopsVisited/lan/en';
import routesTrackerModalAddRoutesTrackerLanguage from '../components//modalAddRoutesTracker/lan/en';

export default {
    ...routesTrackerModalFiltersLanguage,
    ...routesTrackerModalStopInformationLanguage,
    ...routesTrackerModalStopsVisitedLanguage,
    ...routesTrackerModalAddRoutesTrackerLanguage,

    fsmRoute_routesTracker_name: "Route Name",   
    fsmRoute_routesTracker_driver: "Driver",   
    fsmRoute_routesTracker_schedule_at: "Scheduled At",   
    fsmRoute_routesTracker_stops_visited: "Stops Visited",   
    fsmRoute_routesTracker_stops_detail: "Stops Detail",   
};