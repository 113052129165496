import {
    SETTINGS_KEY_FOB_SEARCH,
    SETTINGS_KEY_FOB_SEARCH_RESPONSE,
    SETTINGS_KEY_FOB_OPEN_CREATE_EDIT_MODAL,
    SETTINGS_KEY_FOB_CREATE_EDIT,
    SETTINGS_KEY_FOB_CREATE_EDIT_RESPONSE,
    SETTINGS_KEY_FOB_OPEN_DELETE_MODAL,
    SETTINGS_KEY_FOB_DELETE,
    SETTINGS_KEY_FOB_DELETE_RESPONSE,
    SETTINGS_KEY_FOB_GET_DRIVER,
    SETTINGS_KEY_FOB_GET_DRIVER_RESPONSE
} from "Redux/actionTypes";

export const searchSettingsKeyFob = (data) => {
    return {
        type: SETTINGS_KEY_FOB_SEARCH,
        payload: data,
    };
};

export const searchSettingsKeyFobResponse = (data) => {
    return {
        type: SETTINGS_KEY_FOB_SEARCH_RESPONSE,
        payload: data,
    };
};


export const openSettingsKeyFobCreateEditModal = (open, keyFob) => {
    return {
        type: SETTINGS_KEY_FOB_OPEN_CREATE_EDIT_MODAL,
        payload: { open, keyFob }

    };
};


export const createEditSettingsKeyFobs = (keyFob) => {
    return {
        type: SETTINGS_KEY_FOB_CREATE_EDIT,
        payload: { keyFob },
    };
}

export const createEditSettingsKeyFobsResponse = (data) => {
    return {
        type: SETTINGS_KEY_FOB_CREATE_EDIT_RESPONSE,
        payload: { data },
    };
};


export const openSettingsKeyFobsDeleteModal = (open, items = []) => {
    return {
        type: SETTINGS_KEY_FOB_OPEN_DELETE_MODAL,
        payload: { open, items },
    };
};

export const deleteSettingsKeyFobs = (items = []) => {
    return {
        type: SETTINGS_KEY_FOB_DELETE,
        payload: { items },
    };
};

export const deleteSettingsKeyFobsResponse = (data) => {
    return {
        type: SETTINGS_KEY_FOB_DELETE_RESPONSE,
        payload: { data },
    };
};

export const getSettingsKeyFobDrivers = () => {
    return {
        type: SETTINGS_KEY_FOB_GET_DRIVER,
        payload: {},
    };
};

export const getSettingsKeyFobDriversResponse = (data) => {
    return {
        type: SETTINGS_KEY_FOB_GET_DRIVER_RESPONSE,
        payload: data,
    };
};