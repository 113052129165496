import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';

// Actions
import {reportsMainGenerateReportProgress} from 'Redux/actions';

import {baseReportConditions, generateReportInstance, getTimeZone, handleAsyncReport, isJsonFormat} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getServiceItemsReport(report, page, rowsPerPage, sortField, sortOrder) {
    const {filterType, filterArray, startRange, endRange, otherFilters, unitsSelected, filterEntity} = report.filters;
    const {timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath} = report;
    const baseConditionsQuery = baseReportConditions({
        report,
        name: 'ServiceItems',
        dataResultPath,
        timeZone,
        delayed,
        unitsSelected,
        timeframeName,
        filterType,
        filterArray,
        categoryName,
        startRange,
        endRange
    })

    const addColumnDriver = (categoryName === 'driverId') ?
        [{
            "column": "idLabel",
            "header": "Driver"
        }] : [];

    const addColumnLabel = (report?.exportType === 'csv') ?
        [
            ...addColumnDriver,
            {
                "column": "deviceLabel",
                "header": "Vehicle"
            }, {
            "column": "dateTime",
            "header": "Time & Date"
        }, {
            "column": "service",
            "header": "Service Item"
        }, {
            "column": "maintenance",
            "header": "Main Item"
        }, {
            "column": "cost",
            "header": "Cost"
        }, {
            "column": "serviceNotes",
            "header": "Service Notes"
        }] : [
            {
                "column": "maintenance",
                "header": "Maintenance"
            },
            {
                "column": "dateTime",
                "header": "Time & Date"
            },
            {
                "column": "cost",
                "header": "Cost"
            },
            {
                "column": "serviceNotes",
                "header": "Service Notes"
            }];

    let payload = {
        conditions: {
            ...baseConditionsQuery,
            sortField: {
                field: sortField || "driverId",
                order: sortOrder === 'desc' ? 'DESC' : 'ASC'
            },
            stringValues: {
                values: otherFilters?.event === '' ? [] : [otherFilters?.event]
            },
        },
        offset: page,
        limit: rowsPerPage
    }
    if (report.export) {
        payload = {
            conditions: {
                ...payload.conditions,
                exportOptions: {
                    sendByEmail,
                    emailsTo,
                    culture: report.lang,
                    timeZone: getTimeZone(timeZone),
                    format: {
                        fileFormat: report?.exportType || "json",
                        formatOptions: "{}"
                    },
                    folderName: "px-customer-reports",
                    filePrefixName: "serviceItemsReport",
                    columns: [
                        ...addColumnLabel,
                    ]
                }
            },
            limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
            offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1
        }
    }
    try {
        let data = yield call(
            clientQuery,
            `query ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getServiceItemsReport (conditions: $conditions, limit: $limit, offset: $offset) {
          ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?

                `reportInstanceId`
                : `
            total
            limit
            offset
            category
            items {
                id
                idLabel
                noData
                devices {
                    deviceHID
                    deviceLabel
                    serviceItems {
                        id
                        title
                    }
                }
                totalCost
            }
          `}
        }
      }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );

        yield handleAsyncReport(report, data)
    } catch (exc) {
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
    }
}


export default getServiceItemsReport;
