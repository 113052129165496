import {
    ACCOUNT_TAGS_OPEN_CLOSE_MERGE_TAGS_MODAL,
    ACCOUNT_TAGS_MERGE_TAGS_SEARCH_ENTITIES,
    ACCOUNT_TAGS_MERGE_TAGS_SEARCH_ENTITIES_RESPONSE,
    ACCOUNT_TAGS_MERGE_TAGS_SAVE,
    ACCOUNT_TAGS_MERGE_TAGS_SAVE_RESPONSE
} from "Redux/actionTypes";

export const accountTagsOpenCloseMergeTagsModal = (open, selected) => {
    return {
        type: ACCOUNT_TAGS_OPEN_CLOSE_MERGE_TAGS_MODAL,
        payload: { open, selected },
    };
};

export const accountTagsMergeTagsSearchEntities = (filters) => ({
    type: ACCOUNT_TAGS_MERGE_TAGS_SEARCH_ENTITIES,
    payload: { filters },
});

export const accountTagsMergeTagsSearchEntitiesResponse = (response) => ({
    type: ACCOUNT_TAGS_MERGE_TAGS_SEARCH_ENTITIES_RESPONSE,
    payload: { response },
});

export const accountTagsMergeTagsSave = (data) => {
    return {
        type: ACCOUNT_TAGS_MERGE_TAGS_SAVE,
        payload: { data },
    };
};

export const accountTagsMergeTagsSaveResponse = (response) => {
    return {
        type: ACCOUNT_TAGS_MERGE_TAGS_SAVE_RESPONSE,
        payload: { response } ,
    };
};