//View Keys
export default {
    fleetLandmark_create: 'Create Landmark',
    fleetLandmark_edit: 'Edit Landmark: ',
    fleetLandmark_radius: 'Radius',
    fleetLandmark_shape: 'Shape',
    fleetLandmark_circle: 'Circle',
    fleetLandmark_polygon: 'Polygon',
    fleetLandmark_service_error_title:'Error',
    fleetLandmark_service_error_message:'There was an error with the service',
    fleetLandmark_tags: "Tags",
    fleetLandmark_notes: "Notes",
    fleetLandmark_successCreate: "Landmark created successfully",
    fleetLandmark_successEdit: "Landmark updated successfully",
    fleetLandmark_error_name: "Landmark duplicated name",
};