import React, { Fragment, PureComponent } from 'react';
// eslint-disable-next-line no-unused-vars
import PropTypes, { array, elementType } from 'prop-types';

import Input from '../Input';
import Select from '../Select';
import InputDate from '../InputDate';
import { FileUploadComponent } from '../FileUpload';
import EntitySelector from '../EntitySelector';

const ownControlsType = [Input, Select, InputDate, FileUploadComponent, EntitySelector];

//console.log("ownControlsType",ownControlsType)
//var elementsToValidate = ["Input", "Select", "DatePicker", "FileUpload", "InputSearch"]

export default class Form extends PureComponent {
	constructor(props) {
		super(props);
		this.submit = this.submit.bind(this);
		this.reset = this.reset.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	ownItems = [];

	submit(event, params) {
		//console.log("items --->", this.childs)
		if (event) event.preventDefault();

		let errorItems = [];
		let result = true;
		if (this.props.onSubmit)
			result = this.props.onSubmit();

		//validate
		this.ownItems.forEach((element) => {

			if (element.ref && element.ref.current && element.ref.current.validate) {
				let val = element.ref.current.validate() || false;
				if (!val) result = val;

				if (!val) {
					errorItems.push(element.ref.current);
				}
			}
		});

		/*if (errorItems.length > 0 && this.props.onValidRequiredFields) {
			this.props.onValidRequiredFields(errorItems)
		}*/

		//console.log("result", result)
		if (result && this.props.onValidSubmit) this.props.onValidSubmit(params);

		return result;
	}

	reset(validateReset) {

		//reset isValid values
		this.ownItems.forEach((element) => {
			if (element.ref && element.ref.current && element.ref.current.reset) {
				if (!validateReset)
					element.ref.current.reset();
				else{

				}
			}
		});

	}

	onChange() {
		//validate
		this.ownItems.forEach((element) => {

			let errorItems = [];
			let result = true;
			if (element.ref && element.ref.current && element.ref.current.validate) {
				let val = element.ref.current.validate() || false;
				if (!val) result = val;

				if (!val) {
					errorItems.push(element.ref.current);
				} else {
					if (element.ref && element.ref.current && element.ref.current.reset)
						element.ref.current.reset();
				}
			}
		});
	}

	cloneElement(element, key) {

		if (!element) {
			return null;
		}

		//si es un arreglo
		if (Array.isArray(element))
			return element.map((x) => {
				return this.cloneElement(x);
			});
		//si es un elemento string lo retornamos
		// if (!element || typeof (element) === "string" || typeof (element.type) == "string" || !element.props)
		if (element && ['a', 'p', 'li', 'button', 'hr', 'ul'].includes(element.type)) return element;


		if (element?.type && ownControlsType.includes(element.type)) {
			let elementCloned = React.cloneElement(element, {
				key: key,
				ref: React.createRef(),
				//loading: this.props.loading
			});

			this.ownItems.push(elementCloned);

			return elementCloned;
		}

		//console.log("item", element)
		if (element.props && element.props.children) {
			if (Array.isArray(element.props.children)) {
				//console.log("is array")

				return React.cloneElement(element, {
					key: key,
					children: element.props.children.map((x, key) => {
						return this.cloneElement(x, key);
					}),
				});




			} else {
				//console.log("is single")
				return React.cloneElement(element, {
					key: key,
					children: this.cloneElement(element.props.children),
				});
			}
		} else return element;
	}

	render() {
		this.ownItems = [];
		let childs = React.Children.map(this.props.children, (child) => {
			return this.cloneElement(child);
		});

		//console.log("ownItems", this.ownItems)

		return this.props.noSubmit ? (
			<Fragment>{childs}</Fragment>
		) : (
			<form onSubmit={this.submit} id={this.props.id}
                  className={this.props.className || "p-0 m-0"}
            >
				{<input type="submit" style={{ display: 'none' }} />}

				{childs}
			</form>
		);
	}
}
