export default {
    fsmRoute_routesTracker_modalInformation_title: "Información De La Parada", 
    fsmRoute_routesTracker_modalInformation_close: "Cerrar", 

    fsmRoute_routesTracker_modalInformation_stop_information: "Información de la parada", 
    fsmRoute_routesTracker_modalInformation_status_change_log: "Registro de cambios de estado", 

    fsmRoute_routesTracker_modalInformation_name: "Nombre", 
    fsmRoute_routesTracker_modalInformation_address: "Dirección", 
    fsmRoute_routesTracker_modalInformation_city: "Ciudad", 
    fsmRoute_routesTracker_modalInformation_state: "Estado", 
    fsmRoute_routesTracker_modalInformation_landmark: "Punto de referencia", 
    fsmRoute_routesTracker_modalInformation_latitude_longitude: "Latitud / Longitud", 
    fsmRoute_routesTracker_modalInformation_latitude_longitude_small: "Lat/Lon", 
    fsmRoute_routesTracker_modalInformation_radius: "Radio", 
    fsmRoute_routesTracker_modalInformation_Stop: "Parada", //sequence
    fsmRoute_routesTracker_modalInformation_note: "Nota", 
    fsmRoute_routesTracker_modalInformation_arrivalTime: "Hora de llegada", 
    fsmRoute_routesTracker_modalInformation_departureTime: "Hora de salida", 
    fsmRoute_routesTracker_modalInformation_status: "Estado", 
    fsmRoute_routesTracker_modalInformation_statusChangeOn: "Cambio de estado activado",
    fsmRoute_routesTracker_modalInformation_createdOn: "Creada en",
    fsmRoute_routesTracker_modalInformation_no_log: "No hay registros de cambios", 
};