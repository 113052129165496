import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Grid} from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
// controls
import InfiniteScrollTable from 'Modules/reports/views/main/components/reportsTab/.components/infiniteScrollTable'

//helpers
import {
  formatDateFromFilter,
  formatDatetime,
  formatTimeDelta,
  getGroupId, getMethodToFormatDuration,
  getNameUnitDriverForSubtables,
  getNameUnitDriverTag,
  useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';
//hooks
import {useReportsSelector, useSidePanelMap} from 'Core/hooks';
import {GetFormatForMoment} from 'Core/data/Helpers';

//actions
import {reportsMainGenerateReportUpdate} from 'Redux/actions'

import AddressLandmark from 'Modules/reports/views/main/components/reportsTab/.components/addressLandmark'

// styles
import './hourOfUseReport.scss';
import EventReports from "Modules/reports/views/main/components/reportsTab/.components/eventReport";
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

const ROW_PER_PAGE = 25;
const HourOfUseReport = (props) => {

  const { messages, report, user, copyReportToSetup, openReportSetup, scheduleReport, isActive } = props;
  const dispatch = useDispatch()
  const { loading, asyncLoading, data, filters, timeZone } = report
  const { filterEntity, filterArray, filterType } = filters;
  const exportHandler = useHandleExportReport(report)
  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
  //const [entitiesArray, setEntitiesArray] = useState({});
  const [lastEntity, setLastEntity] = useState({});
  const momentFormat = GetFormatForMoment(user.dateformat)

  const startDayFilter = formatDateFromFilter(report.filters.startRange, timeZone)
  const endDayFilter = formatDateFromFilter(report.filters.endRange, timeZone)
  const { durationFormat } = user;
  const reportRef = useRef();
  const entitiesArrayRef = useRef();
  const detailReportTableRef = useRef();
  const lastEntityRef = useRef({});
  const reportTableSelect = useReportsSelector(report, startDayFilter, endDayFilter, `MMMM DD, YYYY hh:mm A`, filterArray, filterType, timeZone)
  const unitDriverSelected = reportTableSelect.valueSelecteEntity?.length > 0 ? reportTableSelect.valueSelecteEntity: null;
  const dateSelected = reportTableSelect.dateSelected?.length > 0 ? reportTableSelect.dateSelected: null;
  useEffect(() => {
    if (report) {
      const newReport = JSON.parse(JSON.stringify(report));
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);

  useEffect(() => {
    if(isActive){
        reportTableSelect.onChangeResetCache();
    }
  }, [isActive])

  const buildTableHeader = () => {
    return (
      <Grid  container item  direction={"row"} className={"table-scroll-reports-title-details report-hours-of-container"}>
        <Grid item sm={1.8} className={'reports-cut-text'}>
          {getNameUnitDriverForSubtables(filterEntity, messages)}
        </Grid>
        <Grid item sm={0.6} className={'reports-cut-text report-hours-of'}>
            <span>{messages["generatedReport_reportRec"]}</span>
            <p style={{margin: 0}}>{messages["generatedReport_reportRecd"]}</p>
        </Grid>
        <Grid item sm={1.6} className={'reports-cut-text report-hours-of'} style={{paddingLeft: "15px"}}>
          {messages['generatedReport_date']}
        </Grid>
        <Grid item sm={1} className={'reports-cut-text report-hours-of'}>
          {messages['generatedReport_firstOn']}
        </Grid>
        <Grid item sm={1} className={'reports-cut-text report-hours-of'}>
          {messages['generatedReport_lastOff']}
        </Grid>
        <Grid item sm={1} className={'reports-cut-text report-hours-of'}>
          {messages['generatedReport_machineHours']}
        </Grid>
        <Grid item sm={1} className={'reports-cut-text report-hours-of'}>
          {messages['generatedReport_workHours']}
        </Grid>
        <Grid item sm={1} className={'reports-cut-text report-hours-of'}>
          {messages['generatedReport_saturdayHours']}
        </Grid>
        <Grid item sm={1} className={'reports-cut-text report-hours-of'}>
          {messages['generatedReport_sundayHours']}
        </Grid>
        <Grid item sm={1} className={'reports-cut-text report-hours-of'}>
          {messages['generatedReport_billingHours']}
        </Grid>
        <Grid item sm={1} className={'reports-cut-text'}>
          {messages['generatedReport_overtimeHours']}
        </Grid>
      </Grid>
    )
  }

  const TableViewGroup = ({index, style, content, id, previousItem}) => {
    const element = content?.recordInfo;
    const recordInfo = content?.recordInfo;


      return (
          <div
              className="listItem groupItem"
              id={id}
              key={id}
              style={style}
          >

              <Grid container direction={"column"} alignItems={"flex-start"} className='table-scroll-reports-group'>
                  <Grid container item className='table-scroll-reports-header'>
                      <Grid item sm={3}>

                          {
                              element?.tagId > 0 ?
                                  (<LocalOfferIcon
                                      style={{
                                          fontSize: "16.8px",
                                          color: `#${element.groupTagColor && element.groupTagColor != "ffffff" ? element.groupTagColor : "000"}`,
                                          position: 'absolute'
                                      }}/>)
                                  : ""
                          }
                          <span
                              className={`color-text-black ${element?.tagId > 0 ? 'report-home-header-padding' : ''}`}>{`${getNameUnitDriverTag(recordInfo?.groupLabel, messages)}`}</span>
                          {
                              report?.filters?.filterArray.length !== 1 && (<span  className={'color-text-black report-home-header-padding-text'}>{` (${element?.tagId > 0 ? `${getNameUnitDriverTag(filterEntity, messages)} ` : ''}${recordInfo?.groupIndex} ${messages['OF']} ${recordInfo?.groupTotal})`}</span>)
                          }
                      </Grid>
                      <Grid item sm={9}>
<span
    className={'color-text-black'}>{formatDatetime(report.filters.startRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} - {formatDatetime(report.filters.endRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} ({getAbbvTimezone(timeZone)})</span>
                      </Grid>
                  </Grid>
                  <div className='table-scroll-reports-separator'></div>
                  <Grid item>
                      <p className='table-scroll-reports-label'>{element?.entityLabel}</p>
                  </Grid>
              </Grid>
              {buildTableHeader()}
          </div>
      )
  }

  const TableViewDetail = ({index, style, content, id, previousItem}) => {
    const element = content?.recordDetail;
    let paddingBottom = '1px';

    switch (element.saturdayHours) {
      case 'Total Machine Hours':{
        element.saturdayHours = 'generatedReport_totalMachine'
        break
      }
      case 'Total Work Hours':{
        element.saturdayHours = 'generatedReport_totalWorkHours'
        break
      }
      case 'Total Billing Hours':{
        element.saturdayHours = 'generatedReport_totalBilling'
        break
      }
      case 'Total Overtime Hours':{
        paddingBottom = '10px'
        element.saturdayHours = 'generatedReport_totalOvertime'
        break
      }
    }

    return (
      <div
        className="listItem detailItem"
        style={style}
        id={id}
        key={id}
      >
        <Grid   container  direction={"column"} style={{marginBottom: "10px"}}>
          <Grid container item  direction={"row"} style={{paddingTop: "1px", paddingBottom}}>
            {
              element.noData ?
                <Grid item className={"report-ctn-text"} sm={12}>
                  {messages["reportsMainRT_noDataEvent"]}
                </Grid>
              :
                <>
                  <Grid item className={"report-ctn-text"} sm={2}>
                    {filterEntity === "Unit" ? element?.driverName : element?.deviceLabel}
                  </Grid>
                  <Grid item className={"report-ctn-text"} sm={0.5}>
                    <span>{element.recdEvents}</span>
                  </Grid>
                  <Grid item className={"report-ctn-text"} sm={1.5}>
                    {element.date && formatDatetime(element.date, `${momentFormat || "MMMM D, YYYY"}`)} {messages[`generatedReport_${element.dayName}`]}
                  </Grid>
                  <Grid item className={"report-ctn-text"} sm={1}>
                    <span>{element.firstOn}</span>
                  </Grid>
                  <Grid item className={"report-ctn-text"} sm={1}>
                    <span>{element.lastOff}</span>
                  </Grid>
                  <Grid item className={"report-ctn-text"} sm={1}>
                    <span>{element.machineHours}</span>
                  </Grid>
                  <Grid item className={"report-ctn-text"} sm={1}>
                    <span>{element.workHours}</span>
                  </Grid>
                  <Grid item className={"report-ctn-text"} sm={1}>
                    <span>{element.saturdayHours.includes('generatedReport') ? messages[element.saturdayHours] : element.saturdayHours}</span>
                  </Grid>
                  <Grid item className={"report-ctn-text"} sm={1}>
                    <span>{element.sundayHours}</span>
                  </Grid>
                  <Grid item className={"report-ctn-text"} sm={1}>
                    <span>{element.billingHours}</span>
                  </Grid>
                  <Grid item className={"report-ctn-text"} sm={1}>
                    <span>{element.overtimeHours}</span>
                  </Grid>
                </>
            }
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderItems = (parameters) => {
    const {content} = parameters;
    const type = content.recordInfo?.recordType;
    if(type === "GROUP"){
      const groupId = getGroupId(content)
      const entitiesArray = entitiesArrayRef.current || {};
      if(!entitiesArray[groupId]){
          const newEntityArray = {...entitiesArray};
          newEntityArray[groupId] = content;
          entitiesArrayRef.current = newEntityArray
      }

      if(parameters.index === 0){
        return;
      }

      return (
        <TableViewGroup {...parameters}></TableViewGroup>
      )
    } else if(type === "DETAIL" || type === "UNIT_SUMMARY"){
      return <TableViewDetail {...parameters}></TableViewDetail>;
    }

    if(parameters.index === 0){
      return;
    }

    return (
      <div>
        unknown type
      </div>
    )
  }

  const onStartIndex = (index) => {
    const item = data?.items[index];

    if(item) {
      if(item.recordInfo?.entityId !=lastEntityRef.current.recordInfo?.entityId){
        setLastEntity(item)
      }
      lastEntityRef.current = item
    }
  }

  const renderHeader = () => {
    const groupId = getGroupId(lastEntity);
    const entitiesArray = entitiesArrayRef.current || {};
    const itemHeader = entitiesArray[groupId];

    if(itemHeader){
      return (
        <TableViewGroup
          style={{
            backgroundColor: '#fff',
            position: 'sticky',
            top: 0,
            zIndex: 2

          }}
          content={itemHeader}
          id={itemHeader?.recordInfo?.entityId}
        ></TableViewGroup>
      );
    }
    return;
  };

  const findTag = filterArray?.findIndex((item) => item.name == "Tag")
  const hasTag = findTag >= 0;
  const type = hasTag ? messages['generatedReport_tag'] :
                  ( report?.filters?.filterType === "deviceId" ? messages['generatedReport_unit'] : messages['generatedReport_driver'])

  return (
    <>
      <InfiniteScrollTable
        refContainer={detailReportTableRef}
        renderItems={renderItems}
        loading={asyncLoading || loading}
        data={data.items || []}
        total={data.total }
        reportTitle={messages['reportsMainRT_hourOfUseReport']}
        editReport={()=> {
          copyReportToSetup({...report, data: []})
        }}
        onSchedule={() => {
          scheduleReport(report)
        }}
        onCreate={openReportSetup}
        tableContainerClass={"detailed-report-reports-container"}
        type={type}
        handleNewPageLoad={(page) => {
          let currentReport = {...report};
          currentReport.exportType = "json";
          currentReport.filters = {
            ...currentReport.filters,
            unitsSelected: unitDriverSelected?.id != -99 ? unitDriverSelected: null,
            accumulateData: true,
            dateSelected: dateSelected?.id != -99 ? unitDriverSelected: null,
          };
          dispatch(reportsMainGenerateReportUpdate(currentReport, page, ROW_PER_PAGE))
        }}
        renderHeader={renderHeader}
        onStartIndex={onStartIndex}
        isActive={isActive}
        {...reportTableSelect}
        {...exportHandler}
      />
      <SideMapComponent hiddenButton hiddenSearchMap  overlayClass={"overlay-class-reports"}/>
    </>
  );
};

export default HourOfUseReport;
