import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    loading: false,
    open: false,
    error: null,
    isRealtimeMaps: false
};

const route = "settings/views/routes/components/modalDirections";


export const RoutesManagedDirectionRedux = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalRouteDirections: (state, action) => {
            return {
                ...state,
                open: action.payload?.open,
                isRealtimeMaps: action.payload?.isRealtimeMaps || false
            }
        }
    }
    
});


const { actions, reducer } =  RoutesManagedDirectionRedux;

export const { reset, openModalRouteDirections } = actions;

export default reducer;