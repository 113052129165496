import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// controls
import Table, { Column } from '../../table'

import {
  formatDatetime,
  formatTimeDelta,
  getCategoryFilterType,
  getEntityType,
  useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';


// hooks
import { useSidePanelMap, useTableReport } from 'Core/hooks';
// actions
import {
  reportsMainGenerateReportUpdate,
  clearRedux,
  reportsMainGenerateSubEvents,
  reportsMainGenerateReportProgress,
  reportsMainGenerateEvents
} from 'Redux/actions';



import {
  dateTimeZoneToUTC
} from 'Core/data/Helpers';
import AddressLandmark from "Modules/reports/views/main/components/reportsTab/.components/addressLandmark";

import './style.scss';
import EventReports from "Modules/reports/views/main/components/reportsTab/.components/eventReport";

const FormStopSummaryReport = (props) => {

  const { messages, report } = props;
  const dispatch = useDispatch();
  const { loading, data, filters, loadingSummary, timeZone, summary, loadingSubEvents, events } = report;
  const { filterEntity, startRange, endRange } = filters;
  const [eventFilter, setEventFilter] = useState({});
  const [currentId, setCurrentId] = useState(null);
  const exportHandler = useHandleExportReport(report);
  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();

  const reportRef = useRef();
  useEffect(() => {
    if (report) {
      const newReport = JSON.parse(JSON.stringify(report));
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);


  const idEventsPerDay = (selected, id) => {
    let currentReport = report
    delete currentReport.events
    const date = dateTimeZoneToUTC(selected, timeZone)
    dispatch(reportsMainGenerateReportProgress(currentReport))
    let events = {
      filters: {
        filterArray: [id],
        subPage: 1,
        subLimit: 10,
        startRange: date,
        endRange: moment(date).tz(timeZone).endOf('day').utc()
      }
    }
    setEventFilter(events)
    dispatch(reportsMainGenerateSubEvents(report, events))
  }

  const renderExpandableRowSubEvents = (row) => {
    return (
      <div style={{ marginBottom: 10 }}>
        <Table
          loading={loadingSubEvents}
          data={events?.items || []}
          totalItems={events?.total}
          onChangedPage={(page) => {
            let currentEvents = eventFilter
            currentEvents.filters.subPage = page
            dispatch(reportsMainGenerateSubEvents(report, currentEvents))
          }}
          noDataMessage={messages["generatedReport_noEvents"]}
          hideNumberOfRows={true}
          classNameColumns={'report-sub-events-columns'}
          centerPagination
          serverSideTable={true}
        >

          <Column label={messages['reportsMainRT_event']} field="event" noSorting>
            {(element) => <EventReports element={{event: emptyField(element.event) || ''}}/>}
          </Column>
          <Column label={messages['generatedReport_address']} field="duration" noSorting>
            {(element) => {
              return (
                <div>
                  <span>
                    {element.address ? <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={element}/> : '--'}
                  </span>
                </div>
              )
            }}
          </Column>
          <Column label={messages['generatedReport_duration']} field="duration" noSorting>
            {(element) => {
              return (
                <div>
                  <span>
                    {element.duration ? formatTimeDelta(element.duration) : '--'}
                  </span>
                </div>
              )
            }}
          </Column>
          <Column label={messages['generatedReport_timeAndDate']} field="dateTime" noSorting>
            {(element) => {
              return (
                formatDatetime(element.dateTime, "MMMM D, YYYY h:mm A", timeZone)
              );
            }}
          </Column>
          <Column label={messages['reportsMainRT_Form']} field="form" noSorting>
            {(element) => <span >{emptyField(element.form)}</span>}
          </Column>
          <Column label={messages['reportsMainRT_Question']} field="question" noSorting>
            {(element) => <span >{emptyField(element.question)}</span>}
          </Column>
          <Column label={messages['reportsMainRT_Answer']} field="answer" noSorting>
            {(element) => <span >{emptyField(element.answer)}</span>}
          </Column>
        </Table>
      </div>
    )
  }
  const emptyField = (value) => {
    return value || '--';
  }
  const onChangeExpandable = (selected) => {
    if(!selected.noData){
      setCurrentId(selected?.id);
      let currentReport = report
      delete currentReport.summary
      dispatch(reportsMainGenerateReportProgress(currentReport))
      let events = {
        filters: {
          filterArray: [selected.id],
          page: 1,
        }
      }
      dispatch(reportsMainGenerateEvents(report, events))
    }
  }

  let parametersTableSummary = useTableReport({
    data: [],
    report,
    messages,
    hideHeader: true,
    hiddenPagination: true,
    renderExpandableRow: renderExpandableRowSubEvents,
    showExpandable: true
  });

  const renderExpandableRow = (row) => {
    const summaryParameters = {
      ...parametersTableSummary,
      data: summary?.summaryInfo?.dateList || [],
      onChangeSelector: null,
      placeholderSelector: null,
      hideSelectorUnitsDriver: true,
      classNameColumns: "table-report-main-sub-table",
      idRenderExpandableRow: (event) => { idEventsPerDay(event, row?.id) }
    }
    return (
      <div style={{ width: '100%' }}>
        <Table
          {...summaryParameters}
          loading={loadingSummary}
          noData={row.noData}
        >
          <Column
            label=""
            field="day"
            noSorting
          >
            {(element) => {
              return <strong>{element}</strong>
            }}
          </Column>
          <Column />
        </Table>
      </div>
    )
  }
  // END SUMMARY

  let parametersTable = useTableReport({
    data: data.items,
    report,
    messages,
    hideHeader: true,
    renderExpandableRow,
    onChangeExpandable,
    propertyToShowExpandable: 'id',
    classNameColumns: "table-report-main-expandible"
  });

  return (
    <Fragment>
      <Table
        {...parametersTable}
        {...exportHandler}
        loading={loading}

      >
        <Column noSorting className="report-formStopSummary-name">
          {(element) => {
            const type = getEntityType(report, messages)
            return <div style={{maxWidth: "300px"}}><span><strong>{type}:  </strong>{`${element.driverName || ''}`}</span></div>
          }}
        </Column>
        <Column noSorting>
          {() => {
            const localTimeStart = moment.utc(startRange).local().format();
            const localTimeEnd = moment.utc(endRange).local().format();
            return (<div>
                      <span>
                        <span>
                          <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                        </span>
                      </span>
            </div>);
          }}
        </Column>
      </Table>
      <SideMapComponent hiddenButton hiddenSearchMap />
    </Fragment>
  );
};

export default FormStopSummaryReport;
