// Dependencies
import React, { useState, useEffect } from 'react';
import {
	IconButton,
	Icon,
	OutlinedInput,
	InputAdornment,
	Grid,
} from '@mui/material';
import PropTypes from 'prop-types';

//international
import { useIntl } from "react-intl";


// Styles
import './inputSearch.scss';

const InputSearch = (props) => {
	const {
		onSearch,
		id,
		searchText,
		placeholder,
		label,
		searchOnClick,
		onSearchWhenFinish,
		className,
		noWhiteSpace
	} = props;

	const { messages } = useIntl();


	const [valueSearch, setValueSearch] = useState(searchText);
	const [typing, setTyping] = useState(false);

	useEffect(() => {
		if (props.searchWhenFinishedTyping && typing) {
			const timer = setTimeout(() => {
				onSearchWhenFinish(valueSearch);
			}, 500)
			return () => clearTimeout(timer);
		}
	}, [valueSearch]);

	return (
		<Grid container direction="row" justifyContent="flex-end" alignItems="flex-start" className={className}>
			<Grid item xs={12} className="mb-5 text-right">
				<div>
					{label && <label className="text-bold mb-10">{label}</label>}
					<OutlinedInput
						id={id || 'custom-search'}
						placeholder={placeholder || messages['search']}
						type={'text'}
						value={searchText || ''}
						fullWidth={true}
						style={{
							paddingRight: 0
						}}
						inputProps={{
							style: {
								height: "1em"
							}
						}}
						onChange={(event) => {
							if (noWhiteSpace && event.target.value == ' ') {
								setValueSearch('');
								return
							}
							setTyping(true);
							setValueSearch(event.target.value);
							if (onSearch) {
								onSearch(event.target.value);
							}
						}}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="placeholder"
									style={{ fontSize: 25, color: 'black' }}
									size="small"
									onClick={() => {
										if (searchOnClick) {
											searchOnClick(valueSearch);
										}
									}}
								>
									<Icon size="inherit">search</Icon>
								</IconButton>
							</InputAdornment>
						}
					/>
				</div>
			</Grid>
		</Grid>
	);
};

export default InputSearch;

InputSearch.propTypes = {
	onSearch: PropTypes.func.isRequired,
	searchText: PropTypes.string.isRequired
};
