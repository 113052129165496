import React, { PureComponent, Fragment } from 'react';

//global components
import { Icon, IconButton } from '@mui/material';

import { ContextMenu, Button } from 'Components';

//local components

import Loading from 'Components/Loading';


//containers
import ExtraOptionsContainer from './Containers/ExtraOptionsContainer'
import ValuesContainer from './Containers/ValuesContainer'

import "./index.scss"

//international
import {injectIntl} from "react-intl"


export class SelectMenuComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      errorMessage: null,
      open:false,
      selectedOptions:{},
      filterText:"",
      isCollapse:true,
      dataShow:10,
    };

    this.validate = this.validate.bind(this);

  
  }


  validate(val) {
    
    let isValid = true;
		let errorMessage;
		let value = val || this.props.value;
		if (this.props.required) {
			if (!value) {
				isValid = false;
				errorMessage = this.props.requiredMessage;
			} else if (this.props.multiple && !value.length) {
				isValid = false;
				errorMessage = this.props.requiredMessage;
			} else if (this.props.extraOptions && !value.length) {
				isValid = false;
				errorMessage = this.props.requiredMessage;
			}
		}

    this.setState({ isValid, errorMessage });

    return isValid;

  }

  componentDidUpdate(prevProps) {

    if( JSON.stringify(prevProps.value) != JSON.stringify(this.props.value) ){
      
      let newSelected = {}

      this.props.value.map( elem => {
        newSelected = { ...newSelected, [ elem[this.props.valueField] ]:elem.options } 
      })

      this.setState({ selectedOptions:{ ...newSelected } })
    }
  }

  render() {
    const { intl } = this.props
    const {messages} = intl;


    return (
      <div className="form-item select-menu-container">
        {
          //LABEL AREA
          this.props.label && (
            <label className="input-normal-label">
              {this.props.label}
              {!this.props.notRequiderdShow && this.props.required && (
                <span className="text-danger"> *</span>
              )}
            </label>
          )
        }
        {this.props.loadingUploadFile ? (
          <div className="input-loading">
            <Loading />
          </div>
        ) : (
            <Fragment>
              <div className="select-menu-container-input" ref="inputRefContainer"
                 onClick={ () => {                       
                  //this.setState({anchorEl:e.currentTarget})
                  this.setState({open:!this.state.open})
                  this.refs.inputRef.focus() }}
                >
                
                <input
                    className="select-menu-input"                    
                    type="text"
                    multiple={this.props.multiple}
                    onChange={(e) => {
                        this.setState({filterText:e.target.value})
                    }}
                    required={this.props.required}
                    disabled={this.props.disabled}
                    placeholder={this.props.placeholder}
                    ref="inputRef"
                />
                
                <div className="select-menu-icon-button">
                  <IconButton
                    aria-label="placeholder"
                    onClick={ () => {                       
                          //this.setState({anchorEl:e.currentTarget})
                          this.setState({open:!this.state.open})
                          this.refs.inputRef.focus() 
                        
                      }}
                    size="large">
                    <Icon>keyboard_arrow_down</Icon>
                  </IconButton>
                </div>
              </div>

                <ContextMenu
                    className="select-menu-input sticky-menu"  
                    ref="childContextMenuRef"   
                    style={{marginTop:8}}         
                    open={this.state.open}
                >

                 { this.props.type === "multi-checkbox" && <ExtraOptionsContainer
                   { ...this.props }
                   setState={( data ) => this.setState(data)} 
                   filterText={this.state.filterText}
                   selectedOptions={this.state.selectedOptions}
                  /> }
                   
                </ContextMenu>

              {this.props.value && this.props.value.length > 0 && (
                <ValuesContainer value={this.props.value} 
                  dataShow={this.state.dataShow}
                  textField={this.props.textField} 
                  extraOptionstextField={this.props.extraOptionstextField}
                  isCollapse={this.state.isCollapse}
                  handleDelete={(val) => {
                      let currentValue = [...this.props.value];
                      currentValue = currentValue.filter(
                          (cval) => cval[this.props.valueField] != val[this.props.valueField],
                      );

                      if (this.props.onChange) {
                          this.props.onChange(currentValue);
                      }
                  }}
                />
              )}

              { this.props.value.length > 10 &&
              <Button
                type="button"
                className='buttonCollapsed'
                variant="contained"
                onClick={ (e) =>{
                  e.preventDefault()
                  if(this.state.isCollapse){
                    this.setState({dataShow:+this.state.dataShow+10,isCollapse:false})
                  }else{
                    this.setState({dataShow:10,isCollapse:true})
                  }

                }}
              >
                { this.state.isCollapse ? `${messages["show_all"]} ${" "} (${ this.props.value.length || 0 })` : messages["collapse_to_ten"]  }
              </Button>
              }

              {this.props.helpText && (
                <small id={`${this.props.id}-help`} className="form-text text-muted">
                  {this.props.helpText}
                </small>
              )}

              {!this.state.isValid && this.state.errorMessage && (
                <small className="text-danger" style={{ paddingTop: 5 }}>
                  {this.state.errorMessage}
                </small>
              )}
            </Fragment>
          )}
      </div>
    );
  }
}


export default injectIntl(SelectMenuComponent);
