export default {
    fsmRoute_routesTracker_modalInformation_title: "Stop Information", 
    fsmRoute_routesTracker_modalInformation_close: "Close", 
    fsmRoute_routesTracker_modalInformation_stop_information: "Stop Information", 
    fsmRoute_routesTracker_modalInformation_status_change_log: "Status Change Log", 

    fsmRoute_routesTracker_modalInformation_name: "Name", 
    fsmRoute_routesTracker_modalInformation_address: "Address", 
    fsmRoute_routesTracker_modalInformation_city: "City", 
    fsmRoute_routesTracker_modalInformation_state: "State", 
    fsmRoute_routesTracker_modalInformation_landmark: "Landmark", 
    fsmRoute_routesTracker_modalInformation_latitude_longitude: "Latitude / Longitude", 
    fsmRoute_routesTracker_modalInformation_latitude_longitude_small: "Lat/Lon",
    fsmRoute_routesTracker_modalInformation_radius: "Radius", 
    fsmRoute_routesTracker_modalInformation_Stop: "Stop", //sequence
    fsmRoute_routesTracker_modalInformation_note: "Note", 
    fsmRoute_routesTracker_modalInformation_arrivalTime: "Arrival Time", 
    fsmRoute_routesTracker_modalInformation_departureTime: "Departure Time", 
    fsmRoute_routesTracker_modalInformation_status: "Status", 
    fsmRoute_routesTracker_modalInformation_statusChangeOn: "Status Change On",
    fsmRoute_routesTracker_modalInformation_createdOn: "Created On",
    fsmRoute_routesTracker_modalInformation_no_log: "No change records", 
};