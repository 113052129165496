//Module Keys
let locales = {};

//Merge Views
import mainView from 'Modules/reports/views/main/lan/es';
locales = { ...locales, ...mainView };

import mainRTView from 'Modules/reports/views/main/components/reportsTab/lan/es';
locales = { ...locales, ...mainRTView };

import mainSTView from 'Modules/reports/views/main/components/scheduleTab/lan/es';
locales = { ...locales, ...mainSTView };

import modalExtraTime from 'Modules/reports/views/main/components/reportsTab/.components/modalExtraTime/lan/es';
locales = { ...locales, ...modalExtraTime };

import formFtp from 'Modules/reports/views/main/components/ftpForm/lan/es'
locales = { ...locales, ...formFtp };
import modalReportTypes from 'Modules/reports/views/main/components/reportsTab/.components/reportTypes/lan/es';
locales = { ...locales, ...modalReportTypes };


export default locales;