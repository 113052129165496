import React, { useState, useEffect, memo } from "react";
import { useParams,useNavigate } from "react-router-dom";
import { useSelector, useDispatch} from "react-redux";
import { reset, getShortCode } from "./redux/slice";
import Loading from "Components/Loading";
import { prepareUrl } from "./utils";

const ShortCodesView = (props) => {
  const { code } = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { shortCode, loading,error,errorMessage } = useSelector((state) => state.shortCodeRedux);

  useEffect(() => {
    dispatch(getShortCode({ id: code }));
  }, []);

  useEffect(() => {
    if (shortCode?.url) {
      const url = prepareUrl(shortCode?.url, shortCode?.id)
      navigate(url)
    }
  }, [shortCode]);

  useEffect(() => {

    if(error){
      if(error=='Expirate Code')
        navigate('/error/page_expirate_link')
      else
        navigate('/error')
    }
  }, [error,errorMessage]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {loading && <Loading full />}
    </div>
  );
};

export default ShortCodesView;
