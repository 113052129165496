import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";

import {
  loginResult,
  checkLoginUserByCode
} from 'Redux/actions';
import { Loading } from 'Components';

import './loginSocialMedia.scss';

const LoginSocialMedia = (props) => {
  const { messages } = useIntl();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      let code = params.get('code');
      if (!code) {
        let error_description = params.get('error_description');
        errorHandler(error_description);
      } else {
        dispatch(checkLoginUserByCode(code, navigate));
      }
    } else if (location.hash) {
      const catchError = new URLSearchParams(location.hash);
      const error = catchError.get('#error_description');
      errorHandler(error);
    } else {
      errorHandler(messages['login_social_media_error']);
    }
  }, [location.search])

  const errorHandler = (error = messages['login_social_media_error_default']) => {
    navigate('/login');
    let trimError = String(error).trim();
    trimError = trimError.split(messages['login_social_media_error_presignin'])[1] || trimError;
    trimError = trimError.split(messages['login_social_media_error_preauthentication'])[1] || trimError;
    trimError = trimError.replace('.', '');
    if (trimError) {
      dispatch(loginResult(null, false, trimError));
    } else {
      dispatch(loginResult(null, false, error));
    }
  };

  return (
    <div className="loading-area center-loading-social-media">
      <Loading />
    </div>
  );
};

export default LoginSocialMedia;