import {
  DEBUG_UNIT_GET_UNIT_DATA,
  DEBUG_UNIT_GET_UNIT_DATA_RESPONSE
} from 'Redux/actionTypes';

export const debugUnitGetUnitData = (data) => ({
  type: DEBUG_UNIT_GET_UNIT_DATA,
  payload: { data },
});

export const debugUnitGetUnitDataResponse = (response, error) => ({
  type: DEBUG_UNIT_GET_UNIT_DATA_RESPONSE,
  payload: { response, error },
});