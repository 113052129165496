// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const useExport = (headerToExport, formatDataToExport, columns, data, selectedData, name, exportOrientation, notIsTable, showNumber0 = false, columnStyles = null) => {

	const getData = () => {
		let items = [];
		let newData = data;
		let fields = getColumns();
		if (selectedData && selectedData.length) {
			newData = selectedData;
		}
		if (formatDataToExport && typeof formatDataToExport === 'function') {
			//console.log("custom export")
			return formatDataToExport(newData);
		}
		newData?.forEach((row) => {
			let item = [];
			fields.map((field) => {
				let value = row[field];
				if (typeof value === 'string') {
					item.push(isNaN(value) ? value : parseInt(value));
				} else if (typeof value === 'number') {
					item.push(`\b${parseInt(value) || 0}`);
				} else {
					item.push('');
				}
			});

			items.push(item);
		});

		return items;
	};

	const getColumns = () => {
		let fields = []
		if (notIsTable) {
			fields = columns;
		} else {
			fields = columns.filter(c => !c.props.noExport).map((column) => column.props.label || column.props.field);
		}
		return fields;
	}

	const exportCSV = (data) => {
		let newColumns = getColumns();
		if (headerToExport) {

			newColumns = typeof headerToExport === 'function' ? headerToExport() : headerToExport;
		}

		let csvContent = '';
		if(newColumns && Array.isArray(newColumns) && newColumns.length > 0){
			csvContent = `${newColumns.join(',')}` + '\n';
		}

		let items = getData(data);
		items.forEach((item, index) => {
			//its necesary format the text because if possible has a line breaks or ,
			let itemFormat = 
				item.length > 0 && Array.isArray(item) 
				? 
					item.map(x => {
						let value = ""
						if(showNumber0){
							value = x === 0 ? "0" : x && x.toString().replaceAll(/(\r\n|\n|\r)/gm, "") || "";
						}else{
							value = x && x.toString().replaceAll(/(\r\n|\n|\r)/gm, "") || ""
						}
						
						if (value.includes(","))
							value = `"${value}"`;
						return value;
					}) 
				: item;
			let dataString = Array.isArray(itemFormat) ?  itemFormat.join(',') : itemFormat;
			csvContent += index < items.length ? dataString + '\n' : dataString;
		});

		let fileName = `${name || 'exported-data'}.csv`;
		let mimeType = 'text/csv;encoding:utf-8;charset=UTF-8';
		let a = document.createElement('a');

		mimeType = mimeType || 'application/octet-stream';

		if (navigator.msSaveBlob) {
			// IE10
			navigator.msSaveBlob(
				new Blob([csvContent], {
					type: mimeType,
				}),
				fileName,
			);
		} else if (URL && 'download' in a) {
			//html5 A[download]
			a.href = URL.createObjectURL(
				new Blob([csvContent], {
					type: mimeType,
				}),
			);
			a.setAttribute('download', fileName);
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		} else {
			location.href = 'data:application/octet-stream,' + encodeURIComponent(csvContent); // only this mime type is supported
		}
	};

	const exportPDF = (data) => {
		const unit = 'pt';
		const size = 'A4'; // Use A1, A2, A3 or A4
		const orientation = exportOrientation || 'portrait'; // portrait or landscape

		const doc = new jsPDF(orientation, unit, size);
		doc.setFontSize(12);

		jsPDF.autoTableSetDefaults(
			{
				headStyles: {
					font: "helvetica",
					fillColor: "white",
					textColor: "black",
					halign: "center",
					fontStyle: "normal",
					fontSize: 10,
					lineColor: "black",
					lineWidth: { top: 1, right: 1, bottom: 1, left: 1}
				}
			},
			doc
		)

		let newColumns = getColumns();
		if (headerToExport) {
			newColumns = typeof headerToExport === 'function' ? headerToExport() : headerToExport;
		} 

		const headers = [newColumns];
		let dataItems = getData(data);

		let content = {
			startY: 40,
			head: headers,
			columnStyles: {},
			didParseCell: function (data) {
				if (data.section === "head") {
					const columnIndex = data.column.index;
					if (columnStyles && columnIndex < columnStyles.length) {
					  const style = columnStyles[columnIndex];
					  Object.assign(data.cell.styles, style);
					}
				}
			},
			//its necesary loop the data to assign styles for wrap text when is a long value
			body: dataItems.map((element) => {
				let x = element?.data ? element.data : element;
				return Array.isArray(x) && x.length > 0 
					? 
						x.map((y, index) => { 
							return { content: y === 0 || y === "0" ? "0" : y || "", styles: { 
							cellWidth: 'auto', overflow: 'linebreak', minCellWidth: 50, 
							textColor: element?.format && Array.isArray(element?.format) ? element?.format[index]?.textColor || 'black' : 'black' } } }) 
					: 
						{ content: x === 0 || x === "0" ? "0" : x || "", styles: { cellWidth: 'auto', overflow: 'linebreak', minCellWidth: 50 } }
			}),
		};
		doc.autoTable(content);
		doc.save(`${name || 'export'}.pdf`);
	};

	return {
		exportCSV,
		exportPDF,
	};
};

export default useExport;
