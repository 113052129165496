import {
    DASHBOARD_PANEL_DEVICE_OFFLINE,
    DASHBOARD_PANEL_DEVICE_OFFLINE_RESPONSE
} from "Redux/actionTypes";


export const deviceOfflineDashboardPanel = (data) => {
    return {
        type: DASHBOARD_PANEL_DEVICE_OFFLINE,
        payload: {data},
    };
};

export const deviceOfflineDashboardPanelResponse = (data) => {
    return {
        type: DASHBOARD_PANEL_DEVICE_OFFLINE_RESPONSE,
        payload: data,
    };
};

