/* eslint-disable */
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export const usePagination = (tableRef, search, filters) => {
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [columns, setColumns] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [total, setTotal] = useState(0);

    const onSearchText = (newSearchText) => {
        setSearchText(newSearchText);

        if (window.typingTime) {
            clearTimeout(window.typingTime)
        }

        window.typingTime = setTimeout(() => {
            tableRef?.current?.setPage(1);
            tableRef?.current?.setSelectedItems([]);
            setSelectedItems([]);
            onChangePage(1, rowsPerPage, newSearchText, true);
            window.typingTime = null;
        }, 500);
    };

    const onChangeSort = (newSort) => {
        let newFilters = {
            ...filters,
            orderBy: newSort?.key,
            desc: (newSort?.order === "desc" ? true : false)
        };
        dispatch(search(newFilters));
    }


    const onChangePage = (newPage = 1, newRowsPerPage = rowsPerPage, newSearchText = searchText, resetSearch = false) => {
        const newOffset = (newPage - 1) * newRowsPerPage;
        let newFilters = {
            ...filters,
            openSearch: newSearchText,
            offset: newOffset,
            limit: newRowsPerPage,
            resetSearch: resetSearch
        };
        dispatch(search(newFilters));
    };

    const refresh = (_filters = {}) => {
        const newOffset = ((tableRef?.current?.page == 0 ? rowsPerPage : tableRef?.current?.page) - 1) * rowsPerPage;
        let newFilters = {
            ...filters,
            ..._filters,
            openSearch: searchText,
            offset: newOffset,
            limit: rowsPerPage,
            //resetSearch: resetSearch
        };
        dispatch(search(newFilters));
    };

    const onChangeRowsPerPage = (newPagem,newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        onChangePage(1, newRowsPerPage, searchText, true);
    };

    const onChangeColumns = (newColums) => {
        setColumns(newColums);
    };

    const onSelectedItems = (selectedItems) => {
        setSelectedItems(selectedItems);
    };

    const resetTable = () => {
        setSearchText("");
        setSelectedItems([]);
        onChangePage(1, 10, "", true);
        tableRef?.current?.setPage(1);
        tableRef?.current?.setSelectedItems([]);
    };

    return {
        onSearchText,
        onChangePage,
        onChangeSort,
        onChangeRowsPerPage,
        setTotal,
        onChangeColumns,
        setColumns,
        onSelectedItems,
        setSelectedItems,
        setSearchText,
        resetTable,
        rowsPerPage,
        hideAllInPagination: true,
        infiniteScroll: false,
        searchText,
        total,
        columns,
        selectedItems,
        refresh,
        filters: {
            ...filters,
            openSearch: searchText
        }
    };
};

export const useInfiniteScroll = (tableRef, search, filters) => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [total, setTotal] = useState(0);
    const [columns, setColumns] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const onSearchText = (newSearchText) => {
        setSearchText(newSearchText);
        if (window.typingTime) {
            clearTimeout(window.typingTime)
        }

        window.typingTime = setTimeout(() => {
            tableRef?.current?.setPage(1);
            tableRef?.current?.setSelectedItems([]);
            onChangePage(1, rowsPerPage, newSearchText, true);
            window.typingTime = null;
        }, 500);
    };

    const onChangePage = (newPage = 1, newRowsPerPage = rowsPerPage, newSearchText = searchText, resetSearch = false) => {
        setRowsPerPage(rowsPerPage);
        const newOffset = (newPage - 1) * newRowsPerPage;
        let newFilters = {
            ...filters,
            openSearch: newSearchText,
            offset: newOffset,
            limit: newRowsPerPage,
            resetSearch: resetSearch
        };
        dispatch(search(newFilters));
    };

    const onChangeColumns = (newColums) => {
        setColumns(newColums);
    };

    const onSelectedItems = (selectedItems) => {
        setSelectedItems(selectedItems);
    };

    return {
        onSearchText,
        onChangePage,
        setTotal,
        onChangeColumns,
        setColumns,
        onSelectedItems,
        setSelectedItems,
        setSearchText,
        infiniteScroll: true,
        searchText,
        total,
        columns,
        selectedItems
    };
};

export const useNextTokenInfiniteScroll = (tableRef, search, filters, nextToken) => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState("");
    const [columns, setColumns] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const onSearchText = (newSearchText) => {
        setSearchText(newSearchText);
        if (window.typingTime) {
            clearTimeout(window.typingTime)
        }

        window.typingTime = setTimeout(() => {
            tableRef?.current.setSelectedItems([]);
            setSelectedItems([]);
            onChangePage(newSearchText, true, null);
            window.typingTime = null;
        }, 500);
    };

    const onChangePage = (newSearchText = searchText, resetSearch = false, newNextToken = nextToken) => {
        let newFilters = {
            ...filters,
            limit: 25,
            openSearch: newSearchText,
            resetSearch: resetSearch,
            nextToken: newNextToken,
        };

        dispatch(search(newFilters));
    };

    const onChangeColumns = (newColums) => {
        setColumns(newColums);
    };

    const onSelectedItems = (selectedItems) => {
        setSelectedItems(selectedItems);
    };

    return {
        onSearchText,
        onChangePage,
        onChangeColumns,
        setColumns,
        onSelectedItems,
        setSelectedItems,
        setSearchText,
        infiniteScroll: true,
        moreRowsAvailable: nextToken,
        nextToken,
        searchText,
        columns,
        selectedItems
    };
};
