//View Keys
export default {
	serviceDetailView_vehicle: 'Vehicle',
	serviceDetailView_lastPerformed: 'Last Performed',
	serviceDetailView_miles: 'Miles',
	serviceDetailView_days: 'Days',
	serviceDetailView_hours: 'Hours',
	serviceDetailView_cost: 'Cost',
	serviceDetailView_status: 'Status',
	serviceDetailView_reminder: 'Reminder',
	serviceDetailView_subscribers: 'Subscribers',
	serviceDetailView_notes: 'Notes',
	serviceDetailView_attachments: 'Attachments',
};