//View Keys
export default {
  permissions_title: "Permissions",
  permissions_dashboard: "Dashboard",
  permissions_behavior: "Behavior",
  permissions_maintenance: "Maintenance",
  permissions_vehicleStatus: "Vehicle Status",
  permissions_vehicleStatusGeneral: "General",
  permissions_vehicleStatusGeneral_unitSettings: "Unit Settings",
  permissions_vehicleStatusGeneral_unitOutputs: "Unit Outputs",
  permissions_vehicleStatusGeneral_ping: "Ping",
  permissions_vehicleStatusApuControl: "APU Control",
  permissions_vehicleStatusApuControl_starter: "Starter Enable / Disable",
  permissions_vehicleStatusUnitSpecificAlerts: "Unit Specific Alerts",
  permissions_maps: "Maps",
  permissions_mapsAllUserMaps: "All users have access to",
  permissions_mapsGeneral: "General",
  permissions_mapsSetting: "Map Settings",
  permissions_mapsSendSMS: "Send SMS",
  permissions_mapsTrails: "Trails",
  permissions_mapsVehicleDirections: "Vehicle Directions",
  permissions_mapsNearestVehicleLandmarkEquipment: "Nearest Vehicle / Landmark / Equipment",
  permissions_mapsGeofence: "Geofence",
  permissions_mapsRoutes: "Routes",
  permissions_mapsLandmarks: "Landmarks",
  permissions_reports: "Reports",
  permissions_reportsGroupReports: "Group Reports",
  permissions_reportsDriverReports: "Driver Reports",
  permissions_reportsFSM: "FSM Reports",
  permissions_reportsExport: "Export Reports",
  permissions_utilization: "Utilization",
  permissions_reportsAdvancedSummary: "Advanced Summary",
  permissions_reportsDetailed: "Detailed",
  permissions_reportsMileage: "Mileage",
  permissions_reportsMileageSummary: "Mileage Summary",
  permissions_reportsSimple: "Simple",
  permissions_reportsSpeed: "Speed",
  permissions_reportsSummary: "Summary",
  permissions_reportsDriverSafety: "Driver Safety",
  permissions_reportsDriverScoring: "Driver Scoring",
  permissions_reportsEvent: "Event",
  permissions_reportsIdle: "Idle",
  permissions_reportsIgnition: "Ignition",
  permissions_reportsInput: "Input",
  permissions_reportsMediaEvents: "Media Events",
  permissions_reportsMoving: "Moving",
  permissions_reportsStartStopIdle: "Start Stop Idle",
  permissions_reportsStop: "Stop",
  permissions_reportsTimesheet: "Timesheet",
  permissions_reportsGeofence: "Geofence",
  permissions_reportsGeofenceBased: "Geofence Based",
  permissions_reportsLandmark: "Landmark",
  permissions_reportsLandmarkBased: "Landmark Based",
  permissions_reportsLandmarkSummary: "Landmark Summary",
  permissions_reportslatlong: "lat/long",
  permissions_reportsStateMiles: " State Miles",
  permissions_reportsAlertLog: "Alert Log",
  permissions_reportsDriverTimesheet: "Driver Timesheet",
  permissions_reportsLandmarkCrew: "Landmark Crew",
  permissions_reportsLocation: "Location",
  permissions_reportsNonReporting: "Non-Reporting",
  permissions_reportsOdometer: "Odometer",
  permissions_reportsPing: "Ping",
  permissions_reportsTemperature: "Temperature",
  permissions_reportsUnitInfo: "Unit Info",
  permissions_reportsFleetSummary: "Fleet Summary",
  permissions_settings: "Settings",
  permissions_settingsLandmarkUpload: "Landmark Upload",
  permissions_settingsOffHour: "Off Hour",
  permissions_settingsScheduledReports: "Scheduled Reports",
  permissions_settingsDataExports: "Data Exports",
  permissions_settingsXML: "XML",
  permissions_settingsKML: "KML",
  permissions_vehicleManagement: "Manage",
  permissions_vehicleManagementGroupSetup: "Group Setup",
  permissions_vehicleManagementDriverSetup: "Driver Setup",
  permissions_vehicleManagementVehicleStatusLabels: "Vehicle Status Labels",
  permissions_vehicleManagementVehicleLandmarks: "Vehicle Landmarks",
  permissions_vehicleManagementCrewManagement: "Crew Management",
  permissions_vehicleManagementVehicleLocations: "Vehicle Locations",
  permissions_vehicleManagementGeofenceVsIconChange: "Geofence vs Icon Change",
  permissions_vehicleManagementUnitSettings: "Management Unit Settings",
  permissions_vehicleManagementGeofence: "Management Geofence",
  permissions_vehicleManagementVehicleEquipment: "Vehicle Equipment",
  permissions_fsm: "FSM",
  permissions_fsmTwoWayCommunication: "Two-Way Communication",
  permissions_fsmDriverDriverCommunication: "Driver-Driver Communication",
  permissions_fsmRouteManager: "Route Manager",
  permissions_fsmSettings: "Settings",
  permissions_account: "Account",
  permissions_accountContacts: "Contacts",
  permissions_accountSubUsers: "SubUsers",
  permissions_accountLoginHistory: "Login History",
  permissions_fsmformGroup: "FSM Form Group",
  permissions_help: "Help Center",
  permissions_mobile: "Mobile",
  permissions_mobileFleetManagerMobile: "Fleet Manager Mobile",
  permissions_mediaEvents: "Media Events",
  permissions_mediaEventsGeneralMedia: "General Media",
  permissions_mobileFetchLiveVideo: "Fetch Live Video",
  permissions_mobileFetchImagesVideo: "Fetch Images & Video",
  permissions_mobileMediaDownload: "Media Download",
  permissions_mobileMediaShare: "Media Share",
  permissions_mobileReceivePanicButtonAlerts: "Receive Panic Button Alerts",
  permissions_mobileMediaMediaReview: "Media Review",
  permissions_mobileMediaPerformReview: "Perform Review",
  permissions_mobileManagement: "Management",
  permissions_mobileManageCameras: "Manage Cameras",
  permissions_mobileManageReviewChecklist: "Manage Review Checklist",
  permissions_mobileManageMediaAlerts: "Manage Media Alerts",
  permissions_marketplace: "Marketplace",
  permissions_fleet: "Status", //Rename temporaly
  permissions_feedback: "Feedback",
  permissions_allowAccessTo: "Allow access to",
  permissions_allowAccessToLegacy: "Allow access to Legacy",
  permissions_globalAccess: "Global Access",
  permissions_localAccess: "Local Access",
  permissions_alerts: "Alerts",
  permissions_general: "General",
  permissions_basic: "Basic",
  permissions_back: "Back",
  permissions_view: "View",
  permissions_add: "Add",
  permissions_event: "Event",
  permissions_location: "Location",
  permissions_other: "Other",
  permissions_custom: "Custom",
  permissions_edit: "Edit",
  permissions_apiKeys: "API Keys",
  permissions_video: "Video",
  permissions_promiles: "ProMiles",
  permissions_eld: "ELD",
  permissions_allow: 'Allow'
};