import dynamicFormsRedux from '../../views/main/components/forms/redux/slice';
import dynamicFormAnswersRedux from '../../views/main/components/answers/redux/slice';
import dynamicFormsDesignRedux from '../../modals/design/redux/slice';
import dynamicFormsDeleteRedux from '../../modals/delete/redux/slice';
import dynamicFormsDuplicateRedux from '../../modals/duplicate/redux/slice';
import dynamicFormsRenameRedux from '../../modals/rename/redux/slice';
import dynamicFormsViewAnswersRedux from '../../modals/viewAnswers/redux/slice';

export default {
    dynamicFormsRedux,
    dynamicFormAnswersRedux,
    dynamicFormsDesignRedux,
    dynamicFormsDeleteRedux,
    dynamicFormsDuplicateRedux,
    dynamicFormsRenameRedux,
    dynamicFormsViewAnswersRedux
};