import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';
// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

import {baseReportConditions, generateReportInstance, getTimeZone, handleAsyncReport, isJsonFormat} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getAlertLogReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
    const {timeZone, sendByEmail, emailsTo, timeframeName, categoryName, delayed, dataResultPath} = report;
    const {filterType, filterArray, startRange, endRange, filterEntity, unitsSelected} = report.filters
    const baseConditionsQuery = baseReportConditions({
        report,
        name: 'AlertLog',
        dataResultPath,
        timeZone,
        delayed,
        unitsSelected,
        timeframeName,
        filterType,
        filterArray,
        categoryName,
        startRange,
        endRange
    })

    const addColumnLabel = (report?.exportType === 'csv') ?
        [{
            "column": "idLabel",
            "header": filterEntity === 'Driver' ? "Driver" : "Label"
        }]: [];

    try {
        let payload = {
            conditions: {
                ...baseConditionsQuery,
                sortField: {
                    field: sortField || "deviceId",
                    order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                }
            },
            limit: rowsPerPage,
            offset: page
        };

        if (report.export) {
            payload = {
                ...payload,
                limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
                offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
                conditions: {
                    ...payload.conditions,
                    exportOptions: {
                        sendByEmail,
                        emailsTo,
                        format: {
                            fileFormat: report?.exportType || "json",
                            formatOptions: "{}"
                        },
                        culture: report.lang,
                        timeZone: getTimeZone(timeZone),
                        folderName: "px-customer-reports",
                        filePrefixName: "alertLogReport",
                        columns: [
                            ...addColumnLabel,
                            {
                                "column": "alertTime",
                                "header": "Time / Date"
                            },{
                                "column": "address",
                                "header": "Address"
                            },{
                                "column": "driverName",
                                "header": filterEntity === 'Driver' ? "Label" : "Driver"
                            },{
                                "column": "contacts",
                                "header": "Contacts"
                            },{
                                "column": "alertType",
                                "header": "Alert Type"
                            },{
                                "column": "alertDetail",
                                "header": "Alert Detail"
                            }
                        ]
                    }
                }
            }
        }
        let data = yield call(
            clientQuery,
            `query getAlertLogReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
                Report: getAlertLogReport (conditions: $conditions, limit: $limit, offset: $offset) {
                  ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
                `reportInstanceId`
                : `
                    items {
                        id
                        idLabel
                        noData
                    }
                    total
                    limit
                    offset
                    category
                    reportInstanceId
                `}
                }
              }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
        yield handleAsyncReport(report, data);

    } catch (exc) {
        ExceptionManager(exc, 'reports/alertLogReport', 'getAlertLogReport');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}

export default getAlertLogReport;
