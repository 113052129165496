//Imports
import React, { Fragment, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import 'Assets/sass/site.scss';
import { useSelector } from 'react-redux';

//Definitions
import locales from 'Locales';

//Components
import LoginView from 'Core/views/login'
import LoginSocialMedia from 'Core/views/loginSocialMedia'
import ForgotPasswordView from 'Core/views/forgotPassword'
import MigrateUserView from 'Core/views/migrateUser'
import ErrorView from 'Core/views/error'
import SharedVideoView from 'Core/views/sharedVideo';
import SharedMediaView from 'Core/views/sharedMedia';
import SharedLiveVideoSecView from 'Core/views/sharedLiveVideoSec'
import VideoAlertView from 'Core/views/videoAlert';
import AlertLogView from 'Core/views/alertLog';
import EtaLink from 'Core/views/etaLink';
import DebugUnit from 'Core/views/debugUnit';
import Test from 'Core/views/test';
import ForceChangePassword from 'Core/views/forceChangePassword';
import ShortCodesView from 'Core/views/shortCodes';
import ModulesRoutes from 'Modules'
import LiveIncident from 'Core/views/incident';
import { NotificationContainer } from "Components/Notifications";

import 'Assets/sass/site.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';


import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';


const theme = createTheme({
    typography: {
      fontFamily: ['Outfit','sans-serif'].join(',')
    },
  });

const MainApp = (props) => {

    const location = useLocation();


    if (location.pathname === '/') {
        return (<Navigate to={'/app'} />);
    }

    const current_reseller = useSelector(state => state.resellerRedux.current_reseller)
    const lang = useSelector(state => state.commomRedux.language);


    useEffect(() => {
        if (current_reseller) {

            if (current_reseller.title)
                document.title = current_reseller.title //chage tab name

            //find favicon webpack
            let favicon = document.querySelectorAll('[rel="icon"]')[0]

            if (favicon && current_reseller.favicon)
                favicon.href = current_reseller.favicon //add new favicon
            else
                favicon.href = "http://gpstrackit-error.com" //for remove favicon
        }
        else {
            document.title = "GPS Trackit Cloud"
        }
    }, [current_reseller])

    return (
        <Fragment>
            <IntlProvider locale={lang} messages={locales[lang]}>
                <NotificationContainer />
                <Fragment>
                    {/* <StyledEngineProvider injectFirst>*/}
                    <ThemeProvider theme={theme}>
                        <Routes>
                            <Route path='/' element={<Navigate to="/app" />} />
                            <Route path={'/app/*'} element={<ModulesRoutes />} />
                            <Route path={'/reseller/:reseller/forgotPassword'} element={<ForgotPasswordView />} />
                            <Route path={'/reseller/:reseller'} element={<LoginView />} />
                            <Route path={'/login-social-media'} element={<LoginSocialMedia />} />
                            <Route path={'/login/*'} element={<LoginView />} />
                            <Route path={'/forgotPassword'} element={<ForgotPasswordView />} />
                            <Route path={'/migrateUser/:username/:emailFromUrl/:compID'} element={<MigrateUserView />} />
                            <Route path={'/migrateUser/:username/:compID'} element={<MigrateUserView />} />
                            <Route path={'/error'} element={<ErrorView />} />
                            <Route path={'/sharedVideo'} element={<SharedVideoView />} />
                            <Route path={'/sharedMedia/:type/:id'} element={<SharedMediaView />} />
                            <Route path={'/SharedLiveVideoSec/:deviceId/:readingId/:clipId'} element={<SharedLiveVideoSecView />} />
                            <Route path={'/videoAlert/:id'} element={<VideoAlertView />} />
                            <Route path={'/alertLog/:id'} element={<AlertLogView />} />
                            <Route path={'/etaLink/:code'} element={<EtaLink />} />
                            <Route path={'/test'} element={<Test />} />
                            <Route path={'/forceChangePassword'} element={<ForceChangePassword />} />
                            <Route path={'/debugUnit/:id'} element={<DebugUnit />} />
                            <Route path={'/shortCodes/:code'} element={<ShortCodesView />} />
                            <Route path={'/s/:code'} element={<ShortCodesView />} />
                            <Route path={'/error/:type'} element={<ErrorView />} />
                            <Route path={'/incident/:code'} element={<LiveIncident />} />
                            <Route path="*" element={<Navigate to="/error" />} />
                        </Routes>
                    </ThemeProvider>
                    {/* </StyledEngineProvider> */}
                </Fragment>

            </IntlProvider>

        </Fragment>
    );
}

export default MainApp;
