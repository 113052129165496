import React from "react";
import { Button } from "Components";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

function ForgotPwdLink({ current_reseller }) {

  const location = useLocation()

  return (
    <Button
      id="btnForgoPassword"
      type="link"
      style={{
        fontSize: "15px",
        fontWeight: "400",
        position: "relative",
      }}
      onClick={(e) => {
        e.preventDefault();
      }}
      url={
        current_reseller?.key && location.pathname.includes("reseller")
          ? `/reseller/${current_reseller?.key}/forgotPassword`
          : "/forgotPassword"
      }
      className="btn-link login-page-link"
    >
      <FormattedMessage id="login_forgot_username_or_password" />
    </Button>
  );
}

export default ForgotPwdLink;
