// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    SETTINGS_FUEL_CARD_SEARCH,
    SETTINGS_FUEL_CARD_GET,
    SETTINGS_FUEL_CARD_UPDATE,
    SETTINGS_FUEL_CARD_BULK_UPDATE,
    SETTINGS_FUEL_CARD_RULE
} from "Redux/actionTypes";

// Actions
import {
    searchFuelCardsResponse,
    getFuelCardResponse,
    updateFuelCardResponse,
    updateBulkFuelCardsResponse,
    ruleFuelCardsResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* searchFuelCards({ payload }) {
    try {
        const {
            openSearch,
            unitId,
            driverId,
            limit,
            offset
        } = payload;

        const query = `
            query searchFuelCards ($openSearch: String, $unitId: Int, $driverId: Int, $limit: Int, $offset: Int) {
                searchFuelCards (openSearch: $openSearch, unitId: $unitId, driverId: $driverId, limit: $limit, offset: $offset) {
                    items {
                        id
                        companyId
                        unitId
                        driverId
                        cardNumber
                        vehicleId
                        vehicleDescription
                        unit {
                            id
                            label
                        }
                        driver {
                            name
                            groupName
                            contactInfo
                            companyId
                            driverId
                            unitId
                        }
                        tankSize
                        createdOn
                        createdBy
                        updatedBy
                    }
                    total
                }
            }
        `;

        const response = yield call(
            clientQuery,
            query,
            {
                openSearch,
                unitId,
                driverId,
                limit,
                offset
            },
            endpoints.GRAPHQL_GENERAL
        );
        const { searchFuelCards: { items, total } } = response;
        yield put(searchFuelCardsResponse({ items, total, error: false }));
    } catch (exc) {
        yield put(searchFuelCardsResponse({ items: [], total: 0, error: true }));
        ExceptionManager(
            exc,
            "modules/settings/views/fuelCard/redux/sagas",
            "searchFuelCards"
        );
    }
}

function* getFuelCard({ payload }) {
    try {
        const { id } = payload;

        const response = yield call(
            clientQuery,
            `
                query getFuelCard ($id: Int!) {
                    getFuelCard (id: $id) {
                        id
                        companyId
                        unitId
                        driverId
                        cardNumber
                        vehicleId
                        vehicleDescription
                        unit {
                            id
                            label
                        }
                        driver {
                            name
                            groupName
                            contactInfo
                            companyId
                            driverId
                            unitId
                        }
                        tankSize
                        createdOn
                        createdBy
                        updatedBy
                    }
                }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );
        yield put(getFuelCardResponse({ ...response.getFuelCard, error: false }));
    } catch (exc) {
        yield put(getFuelCardResponse({ error: true }));
        ExceptionManager(
            exc,
            "modules/settings/views/fuelCard/redux/sagas",
            "getFuelCard"
        );
    }
}

function* updateFuelCard({ payload }) {
    const {
        id,
        unitId,
        tankSize,
        driverId
    } = payload;

    const query = `
        mutation updateFuelCard ($id: Int!, $unitId: Int, $driverId: Int, $tankSize: Float) {
            updateFuelCard (id: $id, unitId: $unitId, driverId: $driverId, tankSize: $tankSize) {
                id
                companyId
                unitId
                driverId
                cardNumber
                vehicleId
                vehicleDescription
                unit {
                    id
                    label
                }
                driver {
                    name
                    groupName
                    contactInfo
                    companyId
                    driverId
                    unitId
                }    
                tankSize
                createdOn
                createdBy
                updatedBy
            }
        }
    `;

    const variables = {
        id,
        unitId,
        tankSize,
        driverId
    };

    try {
        const data = yield call(clientMutation, query, variables, endpoints.GRAPHQL_GENERAL);
        if (data) {
            yield put(updateFuelCardResponse({ update: data.updateFuelCard !== null, error: false }));
        } else {
            throw 'Unit Group has not been created';
        }
    } catch (exc) {
        yield put(
            updateFuelCardResponse({ error: true, update: false })
        );
        ExceptionManager(
            exc,
            "modules/settings/views/fuelCard/redux/sagas",
            "updateFuelCard"
        );
    }
}

function* bulkUpdateFuelCard({ payload }) {
    const {
        unitIds,
        tankSize
    } = payload;

    const query = `
        mutation bulkUpdateFuelCard ($unitIds: [Int], $tankSize: Float) {
            bulkUpdateFuelCard (unitIds: $unitIds, tankSize: $tankSize)
        }
    `;

    const variables = {
        unitIds,
        tankSize
    };

    try {
        const data = yield call(clientMutation, query, variables, endpoints.GRAPHQL_GENERAL);
        if (data) {
            yield put(updateBulkFuelCardsResponse({ update: data.bulkUpdateFuelCard, error: false }));
        } else {
            throw 'Unit Group has not been created';
        }
    } catch (exc) {
        yield put(
            updateBulkFuelCardsResponse({ error: true, update: false })
        );
        ExceptionManager(
            exc,
            "modules/settings/views/fuelCard/redux/sagas",
            "bulkUpdateFuelCard"
        );
    }
}

function* ruleFuelCards({ payload }) {
    try {
        
        const query = `
            query ruleFuelCards {
                ruleFuelCards
            }
        `;

        const response = yield call(
            clientQuery,
            query,
            {},
            endpoints.GRAPHQL_GENERAL
        );
        yield put(ruleFuelCardsResponse({ rule: response.ruleFuelCards, error: false }));
    } catch (exc) {
        yield put(ruleFuelCardsResponse({ rule: null, error: true }));
        ExceptionManager(
            exc,
            "modules/settings/views/fuelCard/redux/sagas",
            "ruleFuelCards"
        );
    }
}

function* watchMethods() {
    yield takeEvery(SETTINGS_FUEL_CARD_SEARCH, searchFuelCards);
    yield takeEvery(SETTINGS_FUEL_CARD_GET, getFuelCard);
    yield takeEvery(SETTINGS_FUEL_CARD_UPDATE, updateFuelCard);
    yield takeEvery(SETTINGS_FUEL_CARD_BULK_UPDATE, bulkUpdateFuelCard);
    yield takeEvery(SETTINGS_FUEL_CARD_RULE, ruleFuelCards);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}