import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../../../core/logManager';

import { resetALL } from '../../../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    error: null,
    loading: false,
    items: [],
    total: 0,

    listDriverScheduledTasks: [],
    loadingListDriverScheduledTasks: false,
    listTotalDriversScheduledTasks: [],
};

const route = 'modules/maintenance/views/main/components/driversTab/redux/slice'

export const maintenanceVDTSearchDrivers = createAsyncThunk('maintenance/driversTab/maintenanceVDTSearchDrivers', async ({ conditions, sort, limit = 10, offset = 0 }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query searchDrivers ($conditions: [DriverSearchInput], $sort: [DriverSortInput], $limit: Int, $offset: Int) {
                data: searchDrivers (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                    items {
                        id
                        title
                        driverId
                        companyId
                        status
                        driverScheduledTasksCount
                        nextDriverScheduledTask {
                            id
                            driverId
                            driverTaskId
                            driverTaskName
                            estimatedCost
                            notes
                            completed
                            dueDate
                            frequency
                            fileIds
                            status
                            lastMaintenanceDate
                            createdOn
                            files {
                              id
                              fileKey
                            }
                            subscribersCount
                        }
                    }
                    total
                }
            }
            `,
            {
                conditions,
                sort,
                limit,
                offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.data || { items: [], total: 0 };
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceVDTSearchDrivers');
        return rejectWithValue(exc)
    }
});

const maintenanceDriversTabRedux = createSlice({
    name: 'maintenance/driversTab',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : INIT_STATE
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        //maintenanceVDTSearchDrivers
        builder.addCase(maintenanceVDTSearchDrivers.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(maintenanceVDTSearchDrivers.fulfilled, (state, action) => {
            state.loading = false;
            state.items = action.payload.items
            state.total = action.payload.total
        });
        builder.addCase(maintenanceVDTSearchDrivers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = maintenanceDriversTabRedux;

// Extract and export each action creator by name
export const { reset, changeFilters } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;