
//initial values
const INIT_STATE = {
  current_reseller: null,
  type: null,
  reseller_was_validated: false
};

//imports
import {
  GET_RESELLER_INFO,
  GET_RESELLER_INFO_RESULT,
  CLEAR_REDUX
} from 'Redux/actionTypes';

//change redux object
export default (state = INIT_STATE, action) => {
  switch (action.type) {
      case GET_RESELLER_INFO:
          return { ...state, current_reseller: null, type: null, reseller_was_validated: false};
      case GET_RESELLER_INFO_RESULT:
        return { 
          ...state, 
          current_reseller: action.payload.data?.reseller,
          type: action.payload.data?.type,
          reseller_was_validated: true
        };
      case CLEAR_REDUX:
          return ["resellers"].includes(action.payload.option)
              ? action.payload.parameters
                  ? { ...state, ...action.payload.parameters }
                  : { ...INIT_STATE }
              : { ...state }
      default:
          return state;
  }
};
