export default {
    incidents_title: 'Incidentes',
    new_incident: 'Nuevo Incidente',
    search_incident: 'Buscar Incidente',
    incident_items_no_found: 'Incidentes No Encontrados',
    active_incident: 'Activos',
    history_incident: 'Historial',
    incident_end: 'Finalizar',
    incident_stared: 'Iniciado:',
    incident_last_report: 'Ultimo Rpte:',
    incident_vehicle: 'Vehiculo:',
    incident_reported_by: 'Reportado Por:',
    incident_ended: 'Finalizado:',
    incident_duration: 'Duracion:',
    incident_ended_by: 'Finalizado Por:',
    create_incident: 'Crear Incidente',
    edit_incident: 'Editar Incidente',
    incidents_next: 'Siguiente',
    incidents_prev: 'Anterior',
    incidents_close: 'Cerrar',
    incidents_message: "Nuevo Incidente le ayuda a rastrear y recuperar su vehículo robado en tiempo real. Simplemente seleccione su vehículo, elija a quién notificar sobre las actualizaciones y comience a rastrear. Un mapa en vivo muestra todas las actualizaciones, los vehículos con cámara mostrarán imágenes históricas y en vivo, puede compartir un enlace para obtener actualizaciones en tiempo real. Se centra en la recuperación, le brindaremos los detalles.",
    incident_name: 'Nombre del Incidente:',
    incident_estimate_start: 'Inicio Estimado',
    incident_vehicle_info: 'Vehiculo',
    incident_choose_vehicle: 'Elegir Vehiculo:',
    incident_last_event: 'Ultimo Evento:',
    incident_speed: 'Velocidad:',
    incident_address: 'Direccion:',
    incident_address_export: 'Direccion',
    incident_alert: 'Alerta',
    incident_alert_start: 'Inicio de Incidente',
    incident_alert_vehicle_stopped: 'Vehiculo Detenido',
    incident_alert_incident_end: 'Incidente Finalizado',
    incident_suscribers: 'Suscriptores',
    incident_suscribers_helper: '¿Quién debería recibir estas alertas?',
    incident_email: 'Correo',
    incident_phone: 'Telefono',
    incident_contacts: 'Contactos',
    incident_add_recipient: 'Agregar destinatario',
    incident_all_suscribers: 'Seleccionar todos los contactos',
    incident_current_driver: 'Conductor actual',
    incident_exist_phone: 'El telefono ya existe',
    incidents_all_contacts: 'Todos los Contactos',
    incidents_current_driver: 'Conductor Actual',
    incidents_name_rules_error: 'El nombre de la incidencia no puede ser menor de 3 y maximo 30 caracteres',
    incidents_unitId_rules_error: 'Seleccionar un vehiculo es obligatorio',
    incidents_date_rules_error: 'La fecha no puede ser mayor a la fecha actual',
    incidents_success_create: "El Incidente fue creado correctamente",
    incidents_success_update: "El Incidente fue actualizado correctamente",
    incidents_error_create: "Este vehiculo ya cuenta con un incidente abierto",
    incidents_error_update: "Ocurrio un error actualizando el incidente",
    incidents_delete_title: "Finalizar Incidente",
    incidents_end_confirm: "Finalizar",
    incidents_cancel: "Cancelar",
    incident_delete_note: "añadir nota...",
    incident_delete_body_1: "Por favor confirma si este incidente",
    incident_delete_body_2: "ya ha finalizado y agrega una nota",
    incident_note: "Nota:",
    incident_note_success: "El incidente finalizó correctamente.",
    incident_note_error: "Hubo un error por aqui",
    incidents_noUnitLabel: "Esta unidad no tiene nombre",
    incidents_overview_title: "Unidad:",
    incident_event_title: 'Eventos',
    incident_event_title_events_speed: 'Velocidad:',
    incident_event_title_events_driver: 'Conductor:',
    incident_event_trails: 'Rastros',
    incident_event_deviceInfo: 'Ultima Ubicacion',
    incident_event_deviceInfo_lastEvent: 'Ultimo Evento:',
    incident_event_deviceInfo_currentTime: 'Tiempo Actual:',
    incident_event_deviceInfo_unitTime: 'Tiempo de Unidad:',
    incident_event_deviceInfo_address: 'Direccion:',
    incident_event_deviceInfo_event: 'Evento:',
    incident_event_deviceInfo_speed: 'Velocidad:',
    incident_event_deviceInfo_lat_long: 'Lat/Long:',
    incidents_no_data_events: 'Esta unidad no tiene eventos en el rango de fecha seleccionado',
    incidents_no_data_trails: 'Esta unidad no tiene rastros en el rango de fecha seleccionado',
    incident_event_incident_started: 'Comenzo:',
    incident_event_incident_reportedBy: 'Reportado por:',
    incident_event_incident_scheduled_end: 'Fin Programado:',
    incident_event_incident_ended: 'Finalizo:',
    incident_event_incident_duration: 'Duracion:',
    incident_event_incident_endedBy: 'Finalizado por:',
    incident_event_incident_resolution: 'Resolución:',
    incident_export: 'exportar information',
    incident_events_export: 'Exportar informacion de eventos',
    incident_trails_export: 'Exportar informacion de rastros',
    incident_edit_name_title: "Editar nombre de incidente",
    incident_active_button_title: "INCIDENTE ACTIVO",
    incident_active_newName_incorrect: "El nombre no puede ser menor a 3 y mayor a 50",
    incident_active_newName_success: "El nombre del Incidente fue actualizado correctamente",
    event_from_start: "Inicio",
    event_last_24h: "Ultimas 24h",
    incident_active_title: "Incidente",
    incendent_active_extend_button: "Extender",
    incendent_active_end_button: "Finalizar",
    incendent_active_alerts_title: "Subscriptores de Alertas",
    incendent_active_alerts_extend_notification: "La Alerta se extendio por una hora",
    incident_live_share: "Compartir Incidencia en vivo",
    incident_map_layer: "Capas sobre el mapa",

    incident_history_title: 'Historial',
    incident_wrong_email: "El correo es incorrecto",
    incident_carriet_obligatory: 'Prefijo es obligatorio',
    incident_alert_after_created: 'Dado que existe una alerta para este incidente es necesario que ingrese alguna información aquí',
    incident_alert_no_selected: 'Si seleccionó algún contacto es necesario elegir una alerta',
    incident_contact_no_selected: 'Si seleccionó algúna alerta es necesario elegir al menos un contacto',
    incident_phone_prefix: 'Prefijo',
    incident_message_end_by_cron: 'Los siguientes incidentes finalizaron ya que el tiempo de finalizacion de estos fue superado por el tiempo actual',

    incident_map_hybrid: 'Hibrido',
    incident_map_map: 'Mapa',
    incident_map_satelite: 'Satelite',
    incident_map_terrain: 'Terreno',

    incident_clip_completed: 'Completado',
    incident_clip_request: 'Solicitud de Clip',
    incident_live_request: 'Solicitud en vivo',
    incident_view_trails: 'Ver Eventos',
    incident_hide_trails: 'Ocultar Eventos'



}