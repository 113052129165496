/*--------------------------------------------------
 * Imports and controls
 --------------------------------------------------*/
import { endpoints } from 'Core/defaultValues';

/*--------------------------------------------------
 * Global Vars
 --------------------------------------------------*/
 const apiKey = endpoints.GOOGLEMAPS_API_KEY;
const maxPathLength = 100;

/**--------------------------------------------------
 * Class to make petition to routes api
 * @param {*} path path or array for lat() / lng()
 * @param {*} callback method to response the petition
 * @returns true when the petition is made
 --------------------------------------------------*/
export const getSpeedValues = (path, callback) => {

    //formated to string before the call API 
    //return this structure
    //38.75807927603043,-9.03741754643809|38.6896537,-9.1770515|41.1399289,-8.6094075...'
      
    let totalRoute = [...path]
    // Dividir el array de puntos en tramos de máximo 100 puntos
    const chunkedPaths = [];
    while (totalRoute.length > 0) {
        const chunk = totalRoute.splice(0, 100);
        chunkedPaths.push(chunk);
    }     

    //the path can't not more than 100 of lenght
    //change the logic to generate multiples petitions if have more than 100 points
    const promises = chunkedPaths.map(async (newArrayPath) => {

        const stringPath = convertPathToStringRoutes(newArrayPath);

        const url = `https://roads.googleapis.com/v1/speedLimits?path=${stringPath}&key=${apiKey}`;
        return fetch(url)
            .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Error al obtener el límite de velocidad');
            }
        });
    });

    Promise.all(promises)
        .then(results => {
            const mergedData = results.reduce((merged, data) => {
                merged.speedLimits.push(...data.speedLimits);
                return merged;
            }, { speedLimits: [] });

            if (callback) {
                const responseArray = addSpeedLimit(path, mergedData);
                callback(responseArray, mergedData.speedLimits);
            } else {
                console.log(mergedData);
            }
        })
        .catch(error => {
            console.error(error);
    });

    return true

}

/**--------------------------------------------------
 * format the array to make petitions at the api speed
 * @param {*} arrayPathRoutes complete array for petitions
 * @returns return the string with de data to make a query
 --------------------------------------------------*/
const convertPathToStringRoutes = (arrayPathRoutes) => {
    const arrayResponse = []
    if(arrayPathRoutes){
      arrayPathRoutes.forEach((item) => {
        arrayResponse.push([
          item.lat(),
          item.lng()
        ])
      })
    }
    return arrayResponse.join('|');
}

/**--------------------------------------------------
 * format the array to make petitions at the api speed
 * @param {*} path array before petition API routes
 * @param {*} speedLimitArray speed limit data result for API routes
 * @param {*} completePath complete array for petitions
 * @returns complete array with add the data for speeds.
 --------------------------------------------------*/
const addSpeedLimit = (path, speedLimitArray) => {
    const routeSpeedLimitMerge = [];

    //complete the first array to add the information for speed.
    //now the lat / lng have a speed Limit values
    const { speedLimits } = speedLimitArray;
    
    path.forEach((item, index) => {
        routeSpeedLimitMerge.push({
            lat: item.lat(),
            lng: item.lng(),
            speedLimit: speedLimits[index]?.speedLimit || 0,
            placeId: speedLimits[index]?.placeId || '',
            units: speedLimits[index]?.units || ''
        })
    })
    return routeSpeedLimitMerge;
}