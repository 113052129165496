import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { LogManager, ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
    MAP_ICON_PICKER_SEARCH_ICONS
} from 'Redux/actionTypes';

import {
    mapIconPickerSearchIconsResponse
} from 'Redux/actions';

function* searchMapIconsRequest({ payload }) {
    try {
        const { data } = payload;

        const response = yield call(
            clientQuery,
            `
            query searchIcons ($openSearch: String, $category: String, $limit: Int, $offset: Int) {
                Icons: searchIcons (openSearch: $openSearch, category: $category, limit: $limit, offset: $offset) {
                    items {
                        iconName
                        entityName
                        category
                        svgPath
                        iconPath
                        iconPathIsFolder
                        active
                        createdOn
                    }
                    total
                }
            }
            `,
            data,
            endpoints.GRAPHQL_GENERAL,
        );
        
        if (!response || !response.Icons) {
            throw 'No map icons data';
        }

        const { Icons: { items, total }} = response;
        
        yield put(mapIconPickerSearchIconsResponse({ items, total, category: data.category, error: false }));
    } catch (exc) {
        ExceptionManager(exc, 'modules/.common/components/IconPicker/redux/saga', 'searchMapIconsRequest');
        yield put(mapIconPickerSearchIconsResponse({ error: true }));
    }
}

function* watchMapIconsMethods() {
    yield takeEvery(MAP_ICON_PICKER_SEARCH_ICONS, searchMapIconsRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMapIconsMethods)]);
}

