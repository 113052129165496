import { call, put } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';
// Actions
import { reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate } from 'Redux/actions';

import { baseReportConditions, generateReportInstance, getTimeZone, handleAsyncReport, isJsonFormat, getCategoryFilter } from 'Modules/reports/views/main/components/reportsTab/helper';

function* getTimesheetReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
    const { timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath } = report;
    const { filterType, filterArray, startRange, endRange, otherFilters, unitsSelected, filterEntity, accumulateData,dates } = report.filters
    const baseConditionsQuery = baseReportConditions({
        report,
        name,
        dataResultPath,
        timeZone,
        delayed,
        unitsSelected,
        timeframeName,
        filterType,
        filterArray,
        categoryName,
        startRange,
        endRange,
        dates
    })
    try {
        let payload = {
            conditions: {
                ...baseConditionsQuery,
                sortField: {
                    field: sortField || "deviceId",
                    order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                }
            },
            limit: rowsPerPage,
            offset: page
        };

        let addColumnDriver = [];
        if (report?.exportType === 'csv') {
            addColumnDriver.push(...[{
                column: getCategoryFilter(filterType) === "deviceId" ? "deviceLabel" : "driverName",
                header: getCategoryFilter(filterType) === "deviceId" ? "Unit" : "Driver"
            }, {
                column: getCategoryFilter(filterType) === "deviceId" ? "driverName" : "deviceLabel",
                header: getCategoryFilter(filterType) === "deviceId" ? "Driver" : "Unit"
            }, {
                column: "date",
                header: "Date"
            },
            {
                column: "firstIgnitionOnTime",
                header: "Ignition On"
            },
            {
                column: "lastIgnitionOffTime",
                header: "Ignition Off"
            },
            {
                column: "firstIgnitionOnAddress",
                header: "Ignition On Address"
            },
            {
                column: "lastIgnitionOffAddress",
                header: "Ignition Off Address"
            },
            {
                column: "durationTime",
                header: "Time"
            },
            {
                column: "distance",
                header: "Distance"
            }])
        }
        if (report?.exportType === 'pdf'){
            addColumnDriver.push(...[{
                    column: getCategoryFilter(filterType) === "deviceId" ? "driverName" : "deviceLabel",
                    header: getCategoryFilter(filterType) === "deviceId" ? "Driver" : "Unit"
                },
                {
                    column: "ignitionOn",
                    header: "First Ignition On"
                },
                {
                    column: "ignitionOff",
                    header: "Last Ignition Off"
                },
                {
                    column: "time",
                    header: "Time"
                },
                {
                    column: "distance",
                    header: "Distance"
                }])
        }

        if (report.export) {
            payload = {
                ...payload,
                limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
                offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
                conditions: {
                    ...payload.conditions,
                    exportOptions: {
                        sendByEmail,
                        emailsTo,
                        format: {
                            fileFormat: report?.exportType || "json",
                            formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
                            durationFormat: report?.durationFormat
                        },
                        culture: report.lang,
                        timeZone: getTimeZone(timeZone),
                        folderName: "px-customer-reports",
                        filePrefixName: "timesheetReport",
                        columns: [...addColumnDriver]
                    }
                }
            }
        }
        let data = yield call(
            clientQuery,
            `query getTimeSheetReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
                Report: getTimeSheetReport (conditions: $conditions, limit: $limit, offset: $offset) {
                  ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
                `reportInstanceId`
                : `
                    items {
                        recordInfo {
                            entityId
                            entityLabel
                            tagId
                            tagLabel
                            startDate
                            endDate
                            recordType
                            groupLabel
                            groupIndex
                            groupTotal
                            groupTagColor
                        }
                        recordDetail {
                            id
                            idLabel
                            date
                            isNoActivityInStart
                            isNoActivityInLast
                            firstIgnitionOnTime
                            lastIgnitionOffTime
                            firstIgnitionOnAddress
                            lastIgnitionOffAddress
                            landmarkIdIgnitionOn
                            landmarkNameIgnitionOn
                            landmarkIdIgnitionOff
                            landmarkNameIgnitionOff
                            ignitionOnLatitude
                            ignitionOnLongitude
                            ignitionOffLatitude
                            ignitionOffLongitude
                            durationTime
                            distance
                            driverName
                            deviceLabel
                            isKPH
                            noData
                        }
                    }
                    total
                    limit
                    offset
                    category
                    reportInstanceId
                `}
                }
              }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
        yield handleAsyncReport(report, data, accumulateData);

    } catch (exc) {
        ExceptionManager(exc, 'reports/getTimesheetReport', 'getTimeSheetReport');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}

export default getTimesheetReport;
