import React, { useState, useRef } from 'react';
import { Form, Button, Checkbox } from 'Components';
import { Grid } from '@mui/material';

const ExtraOptions = (props) => {
	const selectOptionForm = useRef();
	const [selectedOptions, setSelectedOptions] = useState({});
	const [errors, setErrors] = useState({});

	const { selectProps } = props;

	const selectOption = (options) => {
		
		let data = {
			...options.data,
			options: selectedOptions[options.value],
		};

		options.selectOption(data);
		setSelectedOptions({});
	
	};

	return (
		<div className="extra-option">
			<div style={{ marginBottom: 10, fontWeight: 700 }}>{props.label}</div>

			<Form ref={selectOptionForm} onValidSubmit={selectOption}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="flex-start"
					style={{ padding: '0px 0px 2px 8px' }}
				>
					{selectProps.extraOptions.map((extraOption, index) => {
						return (
							<Grid item xs key={index}>
								<Checkbox
									id={`${Math.floor(Math.random() * 100000)}-${
										extraOption[selectProps.extraOptionsvalueField]
									}`}
									value={
										selectedOptions[props.value]
											? !!selectedOptions[props.value].find(
													(option) =>
														option[selectProps.extraOptionsvalueField] ===
														extraOption[selectProps.extraOptionsvalueField],
											  )
											: false
									}
									onChange={(value) => {

										let newSelectedOptions = { ...selectedOptions };
										if (!newSelectedOptions[props.value]) {
											newSelectedOptions[props.value] = [];
										}

										if (value) {
											newSelectedOptions[props.value].push(extraOption);
										} else {
											newSelectedOptions[props.value] = newSelectedOptions[props.value].filter(
												(option) =>
													option[selectProps.extraOptionsvalueField] !=
													extraOption[selectProps.extraOptionsvalueField],
											);
										}

										setSelectedOptions(newSelectedOptions);
										setErrors({});
									}}
									label={extraOption[selectProps.extraOptionstextField]}
									size="sm"
								/>
							</Grid>
						);
					})}

					<Grid item xs style={{ textAlign: 'right' }}>
						<Button
							id={`${Math.floor(Math.random() * 100)}-${props.label}`}
							type="button"
							className="btn-black btn-sm"
							onClick={(e) => {
								e.preventDefault();
								setErrors({});
								if (selectedOptions[props.value] && selectedOptions[props.value].length) {
									selectOptionForm.current.submit(e, props);
								} else {
									let newErrors = {};
									newErrors[props.value] = true;
									setErrors(newErrors);
								}
							}}
						>
							ADD
						</Button>
					</Grid>
				</Grid>
				{errors[props.value] && (
					<small className="select-error text-danger">
						{selectProps.extraOptionsRequiredMessage}
					</small>
				)}
			</Form>
		</div>
	);
};

export default ExtraOptions;
