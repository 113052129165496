export default {
    "driversTasksTab_driver": "Driver",
    "driversTasksTab_labelTasks": "Tasks",
    "driversTasksTab_previouslyCompletedTask": "Previously Completed Task",
    "driversTasksTab_performed_at": "Performed At",
    "driversTasksTab_upcomingTask": "Upcoming Task",
    "driversTasksTab_status": "Status",
    "driversTasksTab_subscribers": "Subscribers",
    "driversTasksTab_notes": "Notes",
    "driversTasksTab_notesUpperCase": "Notes",
    "driversTasksTab_attachments": "Attachments ",
    "driversTasksTab_cost": "Cost",
    "driversTasksTab_due": "Due",
    "driversTasksTab_description": "Description",
    "driversTasksTab_task": "Task",
    "driversTasksTab_edit": "Edit",
    "driversTasksTab_complete": "Complete",
    "driversTasksTab_delete": "Delete",
    "driversTasksTab_days": "Days",

    "driversTasksTab_Regarding": "Regarding",
    "driversTasksTab_exportFileFormat": "File Format:",
    "driversTasksTab_exportDueIn": "Due In:",
    "driversTasksTab_exportUpcomingMaintenance": "Export Upcoming Maintenance",
    "driversTasksTab_exportDays": "Days",
    "driversTasksTab_exportMiles": "Miles",
    "driversTasksTab_exportEngineHours": "Engine Hours",
    "driversTasksTab_exportBtn": "Export",
    "driversTasksTab_cancelBtn": "Cancel",
    "driversTasksTab_Manage_tasks": "Manage Tasks",
}