import {
  IconButton,
  LinearProgress,
  Loading,
} from "@gpstrackit/gtc-react-controls";
import React from "react";
import { ItemActive } from "./ItemActive";
import { ItemHistory } from "./ItemHistory";
import { useDispatch, useSelector } from "react-redux";
import { incidentPanelAction } from "Redux/actions";
import "../../styles.scss";
import { useIntl } from "react-intl";
import { GetFormatForMoment } from "Core/data/Helpers";
import { useNavigate } from "react-router-dom";

const tabFilters = [
  {
    id: 0,
    label: "active_incident",
  },
  // {
  //   id: 1,
  //   label: "history_incident",
  // },
];

export const IncidentsList = ({
  tabActive,
  setTabActive,
  incidents,
  loadingIncidents,
  openSearch,
  setOpenSearch,
  closeList,
  openDelete,
  setOpenDelete,
  setFilters,
  filters,
  initialLoading,
  page, 
  setPage,
  totalIncidents,
  listInnerRef
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { messages } = useIntl();




  const { user } = useSelector((state) => state.securityRedux);
  const { dateformat, durationFormat } = user;

  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight &&
        incidents?.length < totalIncidents &&
        !loadingIncidents
      ) {
        setPage(page + 1);
      }
    }
  };

  return (
    <div style={{ width: "300px", height: "500px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 style={{ padding: 0 }}>{messages["incidents_title"]}</h2>
        <div style={{
          display: 'flex',
          width: '90px',
          justifyContent: 'space-between'
        }}>
          <IconButton
            onClick={() => {
              closeList && closeList();
              dispatch(incidentPanelAction({
                update: {},
                open: true
              }))
            }}
            style={{ background: "red", color: "white" }}
            icon="add-circle"
            tooltip={messages["new_incident"]}
          />
          <IconButton
            onClick={() => {
              closeList && closeList();
              navigate('incidents/history')
            }}
            style={{ background: "#1d2734", color: "white" }}
            icon="history"
            tooltip={messages["history_incident"]}
          />
        </div>
      </div>
      <div style={{ display: "flex", paddingBottom: '5px' }}>
        {tabFilters.map((item, index) => {
          return (
            <div
              key={item.id}
              className={`tab_div_custom custom-underline ${
                tabActive === index && "active-underline"
              }`}
              onClick={() => {
                if (loadingIncidents) return;
                setTabActive(item?.id);
                setOpenSearch('')
              }}
            >
              {messages[`${item.label}`]}
            </div>
          );
        })}
      </div>
      {/* {tabActive === 1 && (
        <div key='searchIncident' style={{ marginTop: "15px" }}>
          <SearchInput
            placeholder={messages["search_incident"]}
            searchText={openSearch}
            onSearchText={(e) => setOpenSearch(e)}
          />
        </div>
      )} */}
      <div
        onScroll={handleScroll}
        ref={listInnerRef}
        style={{ height: tabActive === 0 ? "90%" : "75%", overflow: "auto" }}
      >
        {initialLoading ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading />
          </div>
        ) : incidents && incidents?.length > 0 ? (
          tabActive === 0 ? (
            incidents?.map((item) => {
              return (
                <ItemActive
                  key={item?.id}
                  {...item}
                  openDelete={openDelete}
                  setOpenDelete={setOpenDelete}
                  closeList={closeList}
                  dateFormat={`${GetFormatForMoment(dateformat)} hh:mm:ss a`}
                />
              );
            })
          ) : (
            <div>
              {incidents?.map((item) => {
                return (
                  <ItemHistory
                    key={item?.id}
                    {...item}
                    closeList={closeList}
                    dateFormat={`${GetFormatForMoment(dateformat)} hh:mm:ss a`}
                    durationFormat={durationFormat}
                  />
                );
              })}
            </div>
          )
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {messages["incident_items_no_found"]}
          </div>
        )}
      </div>
      {loadingIncidents && !initialLoading &&
        <div style={{width: '100%'}}>
          <LinearProgress />
        </div>
      }
    </div>
  );
};
