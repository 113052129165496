import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ExceptionManager } from "Core/logManager";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";

const INIT_STATE = {
  facesData: [],
  total: 0,
  loadingFaceData: false,
  loadingUpdateTSPDriver: false,
  responseTSPDriver: "",
  loadingDeleteFaceId: false,
  responseDeleteFaceId: "",
  loadingDeleteDriverCollection: false,
  responseDeleteDriverCollection: "",

  supervisor: [],
  loadingSupervisor: false,
  errorSupervisor: null,
};

const route = "modules/settings/views/driver/redux/slice";

export const getDriverFaces = createAsyncThunk(
  "settings/driver/driverFaces",
  async (payload, { rejectWithValue, dispatch }) => {
    const { includes, limit, offset } = payload;
    try {
      let data = await clientQuery(
        `
    query getVidFleetDrivers ($includes: enumVidFleetCollectionIncludes!, $limit: Int, $offset: Int) {
      response: getVidFleetDrivers (includes: $includes, limit: $limit, offset: $offset)
    }
    `,
        {
          includes,
          limit,
          offset,
        },
        endpoints.GRAPHQL_GENERAL
      );
      if (data) {
        return data;
      } else {
        throw "Error while getting driver faces";
      }
    } catch (exc) {
      // ALWAYS RETURN ACTION
      ExceptionManager(exc, route, "maintenanceMEDEntityDetails");
      rejectWithValue(exc);
    }
  }
);

export const updateVidFleetTSPDriver = createAsyncThunk(
  "settings/driver/updateVidFleetTSPDriver",
  async ({ driverId, vidFleetDriversIds }, { rejectWithValue, dispatch }) => {
    try {
      const response = await clientMutation(
        `
          mutation updateVidFleetTSPDriver (
            $driverId: String, 
            $vidFleetDriversIds: [String]
          ) {
            data: updateVidFleetTSPDriver (
              driverId: $driverId, 
              vidFleetDriversIds: $vidFleetDriversIds
            )
          }
          `,
        {
          driverId,
          vidFleetDriversIds,
        },
        endpoints.GRAPHQL_GENERAL
      );
      return response?.data || false;
    } catch (exc) {
      ExceptionManager(exc, route, "dtcClearDTC");
      return rejectWithValue(exc);
    }
  }
);

export const deleteVidFleetFaceId = createAsyncThunk(
  "settings/driver/deleteVidFleetFaceId",
  async ({ faceId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await clientMutation(
        `
          mutation deleteVidFleetFaceId (
            $faceId: String
          ) {
            data: deleteVidFleetFaceId (
              faceId: $faceId
            )
          }
          `,
        {
          faceId,
        },
        endpoints.GRAPHQL_GENERAL
      );
      return response?.data || false;
    } catch (exc) {
      ExceptionManager(exc, route, "dtcClearDTC");
      return rejectWithValue(exc);
    }
  }
);

export const deleteVidFleetDriverCollection = createAsyncThunk(
  "settings/driver/deleteVidFleetDriverCollection",
  async ({ driverIds }, { rejectWithValue, dispatch }) => {
    try {
      const response = await clientMutation(
        `
          mutation deleteVidFleetDriverCollection (
            $driverIds: [String]
          ) {
            data: deleteVidFleetDriverCollection (
              driverIds: $driverIds
            )
          }
          `,
        {
          driverIds,
        },
        endpoints.GRAPHQL_GENERAL
      );

      return response?.data || false;
    } catch (exc) {
      ExceptionManager(exc, route, "deleteVidFleetDriverCollection");
      return rejectWithValue(exc);
    }
  }
);

export const searchSupervisorUsers = createAsyncThunk(
  "settings/driver/searchSupervisorUsers",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let data = await clientQuery(
        `
          query searchSubUsers ($conditions: [SubUserSearchInput], $sort: [SubUserSortInput], $limit: Int, $offset: Int) {
            response: searchSubUsers (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
              items {
                id
                firstName
                lastName
                userName
                isSupervisor
              }
              total
            }
          }
        `,
        {
        },
        endpoints.GRAPHQL_GENERAL_V2
      );

      if (data) {
        const filter = data?.response?.items?.filter(item => item?.isSupervisor === true)
        const createFullname = filter?.map((subUser) => {
            return {
              ...subUser,
              fullname: `${subUser?.firstName} ${subUser?.lastName}`,
            };
        });

        return createFullname;
      } else {
        throw "Error while getting supervisor users";
      }
    } catch (exc) {
      ExceptionManager(exc, route, "searchSupervisorUsers");
      rejectWithValue(exc);
    }
  }
);

export const driverSliceRedux = createSlice({
  name: "driver/driverFaces",
  initialState: INIT_STATE,
  reducers: {
    resetDriverFaceState: (state, action) => {
      return action.payload
        ? { ...state, ...action.payload }
        : { ...INIT_STATE };
    },
  },
  extraReducers: (builder) => {
    //getDriverFaces
    builder.addCase(getDriverFaces.pending, (state, action) => {
      state.loadingFaceData = true;
      state.facesData = [];
    });
    builder.addCase(getDriverFaces.fulfilled, (state, { payload }) => {
      const data = JSON.parse(payload?.response);
      state.facesData = data?.drivers;
      state.total = data?.totalNumber;
      state.loadingFaceData = false;
    });
    builder.addCase(getDriverFaces.rejected, (state, action) => {
      (state.facesData = []), (state.loadingFaceData = false);
    });
    //updateVidFleetTSPDriver
    builder.addCase(updateVidFleetTSPDriver.pending, (state, action) => {
      state.loadingUpdateTSPDriver = true;
    });
    builder.addCase(updateVidFleetTSPDriver.fulfilled, (state, { payload }) => {
      state.loadingUpdateTSPDriver = false;
      state.responseTSPDriver = payload ? "success" : "error";
    });
    builder.addCase(updateVidFleetTSPDriver.rejected, (state, action) => {
      state.loadingUpdateTSPDriver = false;
      state.responseTSPDriver = "error";
    });
    //deleteVidFleetFaceId
    builder.addCase(deleteVidFleetFaceId.pending, (state, action) => {
      state.loadingDeleteFaceId = true;
    });
    builder.addCase(deleteVidFleetFaceId.fulfilled, (state, { payload }) => {
      state.loadingDeleteFaceId = false;
      state.responseDeleteFaceId = payload ? "success" : "error";
    });
    builder.addCase(deleteVidFleetFaceId.rejected, (state, action) => {
      state.loadingDeleteFaceId = false;
      state.responseDeleteFaceId = "error";
    });
    //deleteVidFleetDriverCollection
    builder.addCase(deleteVidFleetDriverCollection.pending, (state, action) => {
      state.loadingDeleteDriverCollection = true;
    });
    builder.addCase(
      deleteVidFleetDriverCollection.fulfilled,
      (state, { payload }) => {
        state.loadingDeleteDriverCollection = false;
        state.responseDeleteDriverCollection = payload ? "success" : "error";
      }
    );
    builder.addCase(
      deleteVidFleetDriverCollection.rejected,
      (state, action) => {
        state.loadingDeleteDriverCollection = false;
        state.responseDeleteDriverCollection = "error";
      }
    );

    //searchSupervisorUsers
    builder.addCase(searchSupervisorUsers.pending, (state, action) => {
      state.loadingSupervisor = true;
    });
    builder.addCase(searchSupervisorUsers.fulfilled, (state, { payload }) => {
      state.loadingSupervisor = false;
      state.supervisor = payload;
    });
    builder.addCase(searchSupervisorUsers.rejected, (state, { payload }) => {
      state.loadingSupervisor = false;
      state.errorSupervisor = payload;
    });
  },
});

const { actions, reducer } = driverSliceRedux;

export const { resetDriverFaceState } = actions;

export default reducer;
