// Action Types
import { 
  MODULE_SEARCH,
  MODULE_SEARCH_RESPONSE,
  MODULE_GET,
  MODULE_GET_RESPONSE,
  CLEAR_REDUX
} from "Redux/actionTypes";

/**
 * Initial State MODULE
 */
const INITIAL_STATE = {
  loading: false,
  allfilters: {
    openSearch: "",
    limit: 0,
		offset: 0
  },
  registers: [],
  categories: null,
  loadedRecord: false,
  total: 0,
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MODULE_SEARCH:
      return {
        ...state,
        error: null,
        categories: null,
        loading: true
      };
    case MODULE_SEARCH_RESPONSE:
      let modules = action.payload?.items
      let categories
      let resolveList = []
      if (modules) {
        resolveList = [...modules];
        resolveList = resolveList.map((module) => {
          return { checked: module.userCode === 0 ? true : false, ...module } });
        resolveList = resolveList.map((module) => {
          module.subModules = module.subModules?.map((subModule) => { 
            return { checked: module.checked, ...subModule }})
          return module;
        });

        categories = {
          permission: resolveList?.filter((module) => module.category === 'Permission'),
          report: resolveList?.filter((module) => module.category === 'Report'),
          alert: resolveList?.filter((module) => module.category === 'Alert')
        }
      }
      return {
        ...state,
        registers: resolveList,
        categories: categories,
        total: action.payload?.total,
        error: action.payload?.error,
        loading: false
      };
    case MODULE_GET:
      return {
        ...state,
        loadedRecord: false,
        error: null,
        loading: true,
      };
    case MODULE_GET_RESPONSE:
      return {
        ...state,
        loading: false,
        error: action.payload?.error
      };
    case CLEAR_REDUX:
      return ["", "MODULE"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INITIAL_STATE }
        : { ...state };  
    default:
      return state;
  }
};
