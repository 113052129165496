import {
    MAP_GEOFENCES_DELETE_OPEN_MODAL,
    MAP_GEOFENCES_DELETE_GEOFENCE,
    MAP_GEOFENCES_DELETE_GEOFENCE_RESPONSE
} from 'Redux/actionTypes';

export const mapGeofenceOpenDeleteModal = (open, geofences, deleteAll = false, unselectedRows = []) => ({
    type: MAP_GEOFENCES_DELETE_OPEN_MODAL,
    payload: { open, geofences, deleteAll, unselectedRows },
});

export const mapGeofenceDeleteGeofences = (geofences, deleteAll, unselectedRows) => ({
    type: MAP_GEOFENCES_DELETE_GEOFENCE,
    payload: { geofences, deleteAll, unselectedRows },
});

export const mapGeofencesDeleteGeofencesResponse = (data) => ({
    type: MAP_GEOFENCES_DELETE_GEOFENCE_RESPONSE,
    payload: { data },
});