export const SETTINGS_VEHICLE_EQUIPMENT_SEARCH = "SETTINGS_VEHICLE_EQUIPMENT_SEARCH";
export const SETTINGS_VEHICLE_EQUIPMENT_SEARCH_RESPONSE = "SETTINGS_VEHICLE_EQUIPMENT_SEARCH_RESPONSE";
export const SETTINGS_VEHICLE_EQUIPMENT_OPEN_CREATE_EDIT_MODAL = "SETTINGS_VEHICLE_EQUIPMENT_OPEN_CREATE_EDIT_MODAL";


export const SETTINGS_VEHICLE_EQUIPMENT_CREATE_EDIT = "SETTINGS_VEHICLE_EQUIPMENT_CREATE_EDIT";
export const SETTINGS_VEHICLE_EQUIPMENT_CREATE_EDIT_RESPONSE = "SETTINGS_VEHICLE_EQUIPMENT_CREATE_EDIT_RESPONSE";

export const SETTINGS_VEHICLE_EQUIPMENT_UNIT_GET_RESPONSE = "SETTINGS_VEHICLE_EQUIPMENT_UNIT_GET_RESPONSE";

export const SETTINGS_VEHICLE_EQUIPMENT_OPEN_DELETE_MODAL  = "SETTINGS_VEHICLE_EQUIPMENT_OPEN_DELETE_MODAL";
export const SETTINGS_VEHICLE_EQUIPMENT_DELETE             = "SETTINGS_VEHICLE_EQUIPMENT_DELETE";
export const SETTINGS_VEHICLE_EQUIPMENT_DELETE_RESPONSE    = "SETTINGS_VEHICLE_EQUIPMENT_DELETE_RESPONSE";

export const SETTINGS_VEHICLE_EQUIPMENT_ASSIGNMENT_DELETE             = "SETTINGS_VEHICLE_EQUIPMENT_ASSIGNMENT_DELETE";
export const SETTINGS_VEHICLE_EQUIPMENT_ASSIGNMENT_DELETE_RESPONSE    = "SETTINGS_VEHICLE_EQUIPMENT_ASSIGNMENT_DELETE_RESPONSE";

export const SETTINGS_VEHICLE_EQUIPMENT_OPEN_ASSIGNMENT_MODAL = "SETTINGS_VEHICLE_EQUIPMENT_OPEN_ASSIGNMENT_MODAL";
