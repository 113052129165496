import {
  REPORTS_HANDLE_MODAL_DOWNLOAD_FILES,
  CLEAR_REDUX
} from "Redux/actionTypes";

//STATE INITIAL
const INIT_STATE = {
  open: false,
  loading: false
};

export default (state = INIT_STATE, action) => {
  let reportIndex
  switch (action.type) {
    case REPORTS_HANDLE_MODAL_DOWNLOAD_FILES:
      return { ...state, open: action.payload.open };
    case CLEAR_REDUX:
      return ["", "REPORTS_MODAL_DOWNLOAD_FILES"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
};
