import {
  REPORTS_MAIN_SHOW_REPORTSETUP,
  REPORTS_MAIN_HIDE_REPORTSETUP,
  REPORTS_MAIN_SET_FORM_DATA_REPORTSETUP,
  REPORTS_MAIN_GENERATE_REPORT,
  REPORTS_MAIN_GENERATE_REPORT_RESULT,
  REPORTS_MAIN_GENERATE_REPORT_PROGRESS,
  REPORTS_MAIN_REMOVE_REPORT,
  REPORTS_MAIN_GENERATE_REPORT_UPDATE,
  REPORTS_MAIN_GENERATE_EVENTS,
  REPORTS_MAIN_GENERATE_EVENTS_RESULT,
  REPORTS_VERIFY_STATE_REPORT,
  REPORTS_VERIFY_STATE_REPORT_RESULT,
  GET_LANDMARK_BY_ID,
  GET_LANDMARK_BY_ID_RESULT,
  REPORT_SHOW_MESSAGE,
  REPORTS_GET_REPORTS_READY_RESULT,
  REPORTS_GET_RECENTLY_GENERATED_RESULT,
  REPORTS_GET_REPORTS_PINNED_RESULT,
  REPORT_UPDATE_FIELD,
  REPORT_UPDATE_FIELD_RESULT,
  REPORTS_MAIN_UPDATE_REPORT,
  REPORTS_MAIN_UPDATE_REPORT_RESULT,
  CLEAR_REDUX,
  REPORTS_GET_JSON_REPORT,
  REPORTS_MOVE_TO_TAB,
  REPORTS_SEARCH_LOG,
  GET_SERVICE_ITEMS_LEGACY,
  GET_SERVICE_ITEMS_LEGACY_RESULT,
  UPDATE_REPORT_GENERATED,
  REPORTS_MAIN_DELETE_REPORT_LOG,
  REPORTS_MAIN_DELETE_REPORT_LOG_RESULT,
  REPORT_UPDATE_FIELD_BATCH

} from "Redux/actionTypes";

const INIT_STATE = {
  showReportSetup: true,
  formDataReportSetup: null,
  loadingGlobalReport: false,
  reports: {},
  events: [],
  loadingSummary: false,
  loadingExport: false,
  landmark: null,
  loadingLandmark: false,
  showMessageReport: false,
  messageReport: "",
  titleReport: "",
  statusMessageReport: null,
  reportsReady: [],
  recentlyGenerated: {},
  pinnedReports: [],
  loadingReportHome: false,
  statusUpdateReport: {},
  loadingReportsReady: false,
  loadingRecentlyReports: false,
  loadingPinnedReports: false,
  loadingDeleteReportLog: false,
  deletedReportLog: null,
  updateReport: null,
  moveToTab: null,
  fieldUpdated: '',
  serviceItems: []
}

export default (state = INIT_STATE, action) => {
  let reportIndex;
  let key;
  switch (action.type) {
    case REPORTS_GET_JSON_REPORT:
      return { ...state, loadingGlobalReport: true };
    case REPORTS_MOVE_TO_TAB:
      return { ...state, loadingGlobalReport: false, moveToTab: action.payload.id };
    case REPORT_SHOW_MESSAGE:
      return {
        ...state,
        showMessageReport: action.payload.showMessageReport,
        messageReport: action.payload.message,
        titleReport: action.payload.title,
        statusMessageReport: action.payload.status,
        loadingReportHome: false
    };
    case REPORTS_SEARCH_LOG:
      let loading = {};
      if (action.payload.category ==="ready"){
        loading = { loadingReportsReady: true }
      }else if (action.payload.category ==="recents"){
        loading ={ loadingRecentlyReports: true }
      }else if (action.payload.category ==="starred") {
        loading = { loadingPinnedReports: true }
      }
      return { ...state, ...loading }
    case REPORT_UPDATE_FIELD:
      return { ...state, loadingGlobalReport: true, updateReport: null, fieldUpdated: ''};
    case REPORT_UPDATE_FIELD_BATCH:
      return { ...state, loadingGlobalReport: true, updateReport: null, fieldUpdated: ''};
    case REPORT_UPDATE_FIELD_RESULT:
      return { ...state, loadingGlobalReport: false, updateReport:{ data: action.payload.data, actionName: action.payload.fieldName}, fieldUpdated: action.payload.fieldName};
    case REPORTS_MAIN_UPDATE_REPORT_RESULT:
      return { ...state, statusUpdateReport: { isLoading: false, complete: true, data: { ...action.payload } }};
    case REPORTS_MAIN_UPDATE_REPORT:
      return { ...state, statusUpdateReport: { isLoading: true, complete: false, data: {} }, loadingReportHome: true};
    case REPORTS_GET_REPORTS_READY_RESULT:
      return { ...state, loadingReportsReady: false, reportsReady: action.payload.data };
    case REPORTS_GET_RECENTLY_GENERATED_RESULT:
      return { ...state, loadingRecentlyReports: false, recentlyGenerated: {... action.payload.data, items:
              action.payload.data.offset > 1 ? [... (state.recentlyGenerated?.items || []), ... action.payload.data.items] : action.payload.data.items} };
    case REPORTS_GET_REPORTS_PINNED_RESULT:
      return { ...state, loadingPinnedReports: false, pinnedReports: {... action.payload.data, items:
              action.payload.data.offset > 1 ? [... (state.pinnedReports?.items || []), ... action.payload.data.items] : action.payload.data.items} };
    case REPORTS_MAIN_SHOW_REPORTSETUP:
      return { ...state, showReportSetup: true };

    case REPORTS_MAIN_HIDE_REPORTSETUP:
      return { ...state, showReportSetup: false };

    case REPORTS_MAIN_SET_FORM_DATA_REPORTSETUP:
      return { ...state, formDataReportSetup: action.payload };

    case REPORTS_MAIN_GENERATE_REPORT:
      return { ...state,  loadingReportHome: true };

    case REPORTS_MAIN_GENERATE_REPORT_UPDATE:
      return {
        ...state,
        showMessageReport: false,
        messageReport: "",
        titleReport: "",
        statusMessageReport: null
      };

    case REPORTS_MAIN_GENERATE_REPORT_RESULT:
      return { ...state,  loadingReportHome: false, statusUpdateReport: {} };

    case REPORTS_MAIN_GENERATE_EVENTS:
      return { ...state, loadingSummary: true };

    case REPORTS_MAIN_GENERATE_EVENTS_RESULT:
      return { ...state, loadingSummary: false };

    case REPORTS_VERIFY_STATE_REPORT :
      return { ...state, loadingExport: true };

    case GET_LANDMARK_BY_ID :
      return { ...state, landmark: null, loadingLandmark: true };

    case GET_LANDMARK_BY_ID_RESULT:
      return { ...state, landmark: action.payload, loadingLandmark: false };

    case GET_SERVICE_ITEMS_LEGACY:
      return { ...state, serviceItems: [], loadingLegacyItems: true };

    case GET_SERVICE_ITEMS_LEGACY_RESULT:
      return { ...state, serviceItems: action.payload, loadingLegacyItems: false };

    case  REPORTS_VERIFY_STATE_REPORT_RESULT:
      return { ...state, loadingExport: false};
    case  UPDATE_REPORT_GENERATED:
      const { report: currentReport, loading: currentLoading } = action.payload
      key = currentReport.id
      return { 
        ...state, // copy state
        reports: {
          ...state.reports, // copy reports
          [key]: {  // update one specific report (using Computed Property syntax)
            ...state.reports[key],  // copy that specific report's properties
            loading: currentLoading
          }
        }
      };
    case REPORTS_MAIN_GENERATE_REPORT_PROGRESS:
      const report = action.payload
      let { reports } = state;
      //find if exist report
      key = report.id
      let findReport = reports[key]
      if (findReport) {
        //add new progress
        reports = { ...reports, [key]: {  // update one specific report (using Computed Property syntax)
          ...report
        } }
      }
      else {
        //add new report
        reports = {...reports, report}
      }

      return { 
        ...state, // copy state
        reports: {
          ...state.reports, // copy reports
          [key]: {  // update one specific report (using Computed Property syntax)
            ...report
          }
        },
        loadingGlobalReport: false,
        loadingReportHome: false,
      };
    case REPORTS_MAIN_REMOVE_REPORT:
      key = action.payload
      const newReports = { ...state.reports}
      delete newReports[key]; 

      return { ...state, reports: {...newReports}};

    case REPORTS_MAIN_DELETE_REPORT_LOG:
      return {...state, loadingDeleteReportLog: true, deletedReportLog: null};
    case REPORTS_MAIN_DELETE_REPORT_LOG_RESULT:
      return {...state, loadingDeleteReportLog: false, deletedReportLog: action.payload.data};

    case CLEAR_REDUX:
      return ["", "REPORTS_MAIN_RT"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
};
