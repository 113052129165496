import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Loading, Button } from "Components";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { migrateUser } from "Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import ForgotPwdLink from "../common/ForgotPwdLink";
import ChatWithSupport from "../common/ChatWithSupport";

const FormMigrate = ({ current_reseller, setConfirmationCode }) => {
  const frmMigrateUser = useRef();
  const { messages } = useIntl();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, result } = useSelector((state) => state.migrateUserRedux);

  let { username, emailFromUrl } = useParams();
  if (!username || username == "") {
    navigate("/error");
  }

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState(emailFromUrl || "");

  useEffect(() => {
    if (result) {
      setEmail("");
      setPassword("");
      setConfirmationCode("");
    }
  }, [result]);

  return (
    <Form
      ref={frmMigrateUser}
      onValidSubmit={() => {
        dispatch(migrateUser(username, email, password));
      }}
    >
      <div>
        <Input
          id="txtUsername"
          disabled
          type="text"
          className="txt-username"
          label={""}
          placeholder={messages["migrateUser_currentUsername"]}
          value={username}
          onChange={() => { }}
          required
          requiredMessage={messages["required_field"]}
          autoFocus
        />
      </div>
      <div>
        <Input
          id="txtEmail"
          type="text"
          label={""}
          placeholder={messages["migrateUser_email"]}
          value={email}
          onChange={(text) => {
            setEmail(text);
          }}
          required
          requiredMessage={messages["required_field"]}
          autoFocus
        />
      </div>
      <div>
        <Input
          id="txtPassword"
          label={""}
          placeholder={messages["migrateUser_password"]}
          value={password}
          type="password"
          onChange={(text) => {
            setPassword(text);
          }}
          required
          requiredMessage={messages["required_field"]}
          autoFocus
        />
      </div>
      <div style={{ paddingTop: 10 }}>
        {loading ? (
          <div className="loading-area">
            <Loading />
          </div>
        ) : (
          <Button
            id="btnContinue"
            className="btn btn-blue btn-block"
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              frmMigrateUser.current.submit();
            }}
          >
            {messages["migrateUser_login"]}
          </Button>
        )}
      </div>
      <div style={{ paddingTop: 10 }}>
        <Button
          id="btnBack"
          className="btn btn-gray btn-block"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          {messages["migrateUser_cancel"]}
        </Button>
      </div>
      <Box style={{ margin: "25px 0 0 0" }}>
        <ForgotPwdLink current_reseller={current_reseller} />
      </Box>
      <Box style={{ margin: "5px 0 0 0" }}>
        <ChatWithSupport />
      </Box>
    </Form>
  );
};

export default FormMigrate;
