import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { endpoints } from 'Core/defaultValues';

import {
  getResellerInfo
} from 'Redux/actions';

const IframeComponent = () => {
  const dispatch = useDispatch();
  const [loadLegacy, setLoadLegacy] = useState(false);
  
  const current_reseller = useSelector(state => state.resellerRedux.current_reseller);
  useEffect(() => {
    if(!current_reseller && !location.pathname.includes('reseller')){
      const hostname = window.location.hostname;
      dispatch(getResellerInfo('domain', hostname))
    }
  }, []);
  const url = current_reseller?.legacyUrl || endpoints.LEGACY_URL;
  return (
    <Fragment>
      <iframe id="legacyIframe" src={ url  + "login/integration"} style={{ display: 'none' }} onLoad={() => { setLoadLegacy(true) }} />
    </Fragment>
  )
}

export default IframeComponent;