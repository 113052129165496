import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

//STATE INITIAL
const INIT_STATE = {
    loadingScheduledTasks: false,
    scheduledTasks: [],
    error: null,
}

const route = "modules/maintenance/views/driverDetail/.components/scheduleTasks/redux/slice";

export const maintenanceDDSDTSearchDriverScheduledTasks = createAsyncThunk("maintenance/driverDetail/ScheduleTasksTab/searchDriverScheduledTasks", async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientQuery(
            `
            query ($driverId: Int, $excludeCompleted: Boolean){
                data: searchDriverScheduledTasks(driverId: $driverId, excludeCompleted: $excludeCompleted){
                    id
                    driverId
                    driverTaskId
                    driverTaskName
                    estimatedCost
                    dueDate
                    frequency
                    notes
                    fileIds
                    subscribersCount
                    status
                    createdOn
                    completed
                    timeUnit
                    lastMaintenanceDate
                }
            }
            `,
            {  
                driverId: id || '',
				excludeCompleted: true
            },
            endpoints.GRAPHQL_GENERAL
        );
        return response.data
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceMCDTGetMaintenanceDriverTask');
        return rejectWithValue(exc);
    }
}
)

export const maintenanceScheduleTasksTabRedux = createSlice({
    name: 'maintenance/driverDetail/ScheduleTasksTab',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {

        //maintenanceCreateDriverTask
        builder.addCase(maintenanceDDSDTSearchDriverScheduledTasks.pending, (state, action) => {
            state.loadingScheduledTasks = true
        });

        builder.addCase(maintenanceDDSDTSearchDriverScheduledTasks.fulfilled, (state, action) => {
            state.scheduledTasks = action.payload
            state.loadingScheduledTasks = false;
        });

        builder.addCase(maintenanceDDSDTSearchDriverScheduledTasks.rejected, (state, action) => {
            state.loadingScheduledTasks = false;
            state.error = action.payload;
        });
    }
})

const { actions, reducer } = maintenanceScheduleTasksTabRedux;

// Extract and export each action creator by name
export const {
    reset,
} = actions;

export default reducer;