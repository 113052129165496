//View Keys
export default {
  realtimemaps_layer_map_unit: 'Unidad',
  realtimemaps_layer_map_date_time: 'Fecha / Hora',
  realtimemaps_layer_map_event: 'Evento',
  realtimemaps_layer_map_select_layer_type: 'Seleccione el tipo de capa',
  realtimemaps_layer_map_route: 'Ruta',
  realtimemaps_layer_map_vehicle_trail: 'Ruta del Vehículo',
  realtimemaps_layer_map_layer_kmz: 'Añadir Nueva Capa',
  realtimemaps_layer_map_layer_kmz_delete: 'Eliminar capa(s)',
  realtimemaps_layer_map_using_layer_kmz: 'Usando un archivo KMZ/KML',
  realtimemaps_layer_map_add: 'Añadir',
  realtimemaps_layer_map_delete: 'Eliminar',
  realtimemaps_layer_map_next: 'Siguiente',
  realtimemaps_layer_map_cancel: 'Cancelar',
  realtimemaps_layer_map_vehicletrails: 'Rutas de Vehículos',
  realtimemaps_layer_map_validatedate: 'El intervalo de fechas no puede ser superior a 1 día',
  realtimemaps_layer_map_validateunit: 'Seleccione una unidad',
  realtimemaps_layer_map_no_routes_available: 'No hay rutas disponibles',
  realtimemaps_layer_map_description_route: 'Esta opción le permite seleccionar una ruta de la lista. Después de seleccionar su opción, puede verla en el mapa temporalmente.',
  realtimemaps_layer_map_description_vehicle_trail: 'Esta opción le permite seleccionar un vehículo para ver el recorrido en un rango de fechas específico. Al hacer esto, puede superponer este recorrido con cualquier otro dato geográfico precargado en el mapa.',
  realtimemaps_layer_map_description_layer_kmz: 'Esta opción le permite elegir un archivo kmz/kml con objetos de mapas, líneas y marcadores generados externamente.',
  realtimemaps_layer_map_color: "Color",
  realtimemaps_layer_map_back: "Atras",
  realtimemaps_layer_map_add_new_layer: "Añadir nueva capa",
  realtimemaps_layer_map_remove_layer: "Eliminar las capas añadidas",
  realtimemaps_layer_map_invalid_date: "Fecha no válida",
  realtimemaps_layer_map_select_route: "Es necesario seleccionar una ruta",
  realtimemaps_layer_map_color_validation: "Seleccione un color para la ruta del vehículo",
  realtimemaps_layer_map_no_data: "No hay datos para el rango seleccionado",
  realtimemaps_layer_map_vehicle_trail_info: "La consulta se ha limitado a un máximo de 100 puntos en el mapa.",
  realtimemaps_layer_map_layer_attachments: "Adjuntos",
  realtimemaps_layer_map_layer_name: "Nombre del Archivo",
  realtimemaps_layer_map_layer_success: "Nombre del Archivo",
  realtimemaps_layer_map_layer_uploadFile: "El archivo se cargo correctamente",
  realtimemaps_layer_map_layer_warning: "Advertencia",
  realtimemaps_layer_map_layer_maxShowMessage: "Solo se permite mostrar 10 layers",
  realtimemaps_layer_map_layer_deleteMessage: "Esta seguro que desea eliminar la(s) capa(s) ",
  realtimemaps_layer_map_layer_deleteMessage_layers: "previamente seleccionadas?",
  realtimemaps_layer_map_layer_succesDelete: "Elementos eliminados correctamente!",
  realtimemaps_layer_map_layer_succesSave: "Los cambios fueron guardados correctamente!",
  realtimemaps_layer_map_layer_invalidName: "El nombre que intentas usar ya esta asignado a otro archivo. Intenta con otro",
  realtimemaps_layer_map_layer_invalidFile: "El archivo que intentas usar no es valido. Intenta con otro",
  realtimemaps_layer_map_layer_searchOnMap: "Buscar capas en el mapa",
  realtimemaps_layer_map_layer_searchOnMapItem: "Buscar esta capa en el mapa",
  realtimemaps_layer_map_layer_follow: "Seguir",
  realtimemaps_layer_map_layer_empty_name_warning: "El nombre no puede ser vacio",
  realtimemaps_layer_map_layer_used_name_warning: "El nombre ya esta siendo usado",
  realtimemaps_layer_map_layer_max_name_warning: "El nombre solo puede tener 25 caracteres",
  realtimemaps_layer_map_layer_files_uploaded: "Archivos Cargados",
  realtimemaps_layer_map_layer_files_uploaded_no_files: "Aun no se han cargado archivos",
  realtimemaps_layer_map_layer_files_uploaded_max_20_files: "Max 20 archivos",
  realtimemaps_layer_map_layer_files_uploaded_max_20_files_warning: "Sólo se permiten cargar 20 archivos.",
  realtimemaps_layer_map_layer_files_uploaded_duplicate_names: "Existen archivos con el mismo nombre. Cada archivo debe tener un nombre unico",
  realtimemaps_layer_map_layer_files_uploaded_exist_name: "Algunos de los archivos ya tienen un nombre cargado previamente o tienes más de un archivo con el mismo nombre. Cada archivo debe tener un nombre único y no pueden ser mayor a 50 caracteres o no puede ser vacio."









};