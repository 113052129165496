export default {
  "header_preferences": "Preferencias",
  "header_logout": "Cerrar sesión",
  "header_training": "Entrenamiento",
  "header_schedule_training": "Programar entrenamiento en vivo",
  "header_help": "Centro de ayuda",
  "header_changePassword": "Cambiar contraseña",
  "header_chatEnabled" : "Chat con Soporte",
  "header_chatDisabeld" : "Chat con Soporte - Desconectado",
  "header_alertIcon" : "Alertas",
  "header_personIcon" : "Perfil",
  "header_incidentIcon" : "Incidentes"

};