export default {
    settingsTemperature_createTitle:"Add new temperature alert",
    settingsTemperature_editTitle:"Edit temperature alert for",
    settingsTemperature_columTitle:"Vehicle",
    settingsTemperature_minLengthMessage:'The field must contain minimum chars: ',
    settingsTemperature_maxLengthMessage:'The field must contain maximum chars: ',
    settingsTemperature_success:'Temperature alert saved',
    settingsTemperature_deleteWarning:'Are you sure you want to remove the temperature alerts?',
    settingsTemperature_deleteTitle:'Delete temperature alerts',
    settingsTemperature_deleted:'Temperature alerts deleted',
    settingsTemperature_search:'Search by Vehicle',
    settingsTemperature_unitTitle:'Vehicle',
    settingsTemperature_zone:'Zone',
    settingsTemperature_title:'Name',
    settingsTemperature_high:'High (°F)',
    settingsTemperature_low:'Low (°F)',
    settingsTemperature_alert:'Alert',
    settingsTemperature_trigger:'Trigger',
    settingsTemperature_contact:'Contacts',
    settingsTemperature_complete_field_zone:'Please complete the fields in the zone ',
    settingsTemperature_complete_fields:'Please complete all fields',
    settingsTemperature_validate_high_low:'This fields high and low must be between 185°F to -40°F temperature',
    settingsTemperature_less_high_low:'Low field must be less than high field in zone ',
    settingsTemperature_Hour:'Hour',
    settingsTemperature_Inmediate:'Inmediate',
    settingsTemperature_Once:'Once',
    settingsTemperature_Recurring:'Recurring',
    settingsTemperature_Enable:'Enable',
    settingsTemperature_Disable:'Disable',
    settingsTemperature_subscribersRequired:'You must select a least one contact'
};