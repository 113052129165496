export default {
    eta_delete_title: "Borrar Enlace Eta",
    eta_delete_messages_links: "¿Seguro que quieres borrar los enlaces eta?",
    eta_delete_messages_link: "¿Seguro que quieres borrar el enlace eta?",
    eta_delete_unit: "Unidad",
    eta_delete_destination: "Destino",
    eta_delete_action_cannot_be_undone: "Esta acción no se puede deshacer.",
    eta_delete_success_links: "Los enlaces Eta han sido eliminados",
    eta_delete_success_link: "El enlace eta ha sido eliminado",
    eta_delete_error_links: "Se ha producido un error, inténtelo de nuevo.",
}