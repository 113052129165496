import React, { useState } from 'react';
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';

const CustomPopover = (props) => {
	const [open, setOpen] = useState(false);
	const [anchor, setAnchor] = useState(null);
	const { children, component, verticalPosition, horizontalPosition } = props;
	const id = open ? 'simple-popover' : undefined;

	return (
		<div>
			<div
				aria-describedby={id}
				onClick={(event) => {
					setOpen(!open);
					setAnchor(event.currentTarget);
				}}
			>
				{component}
			</div>

			<Popover
				id={id}
				open={open}
				anchorEl={anchor}
				onClose={() => {
					setOpen(false);
					setAnchor(null);
				}}
				anchorOrigin={{
					vertical: verticalPosition || 'bottom',
					horizontal: horizontalPosition || 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				{children}
			</Popover>
		</div>
	);
};

export default CustomPopover;

CustomPopover.propTypes = {
	//component: PropTypes.any.isRequired
};
