import {
	EDIT_UNIT_OPEN,
	EDIT_UNIT_OPEN_RESULT,
	EDIT_UNIT_CLOSE,
	EDIT_UNIT_UPDATE,
	EDIT_UNIT_UPDATE_RESULT,
	EDIT_MULTIPLE_UNITS,
	EDIT_UNIT_SEND_OTA,
	EDIT_UNIT_SEND_OTA_RESULT,
	EDIT_UNIT_SEND_PING,
	EDIT_UNIT_SEND_PING_RESULT,
	EDIT_UNIT_DECODE_VIN,
	EDIT_UNIT_DECODE_VIN_RESPONSE
} from 'Redux/actionTypes';

export const unitEditUnitOpen = (id) => ({
	type: EDIT_UNIT_OPEN,
	payload: { id }
});

export const unitEditUnitOpenResult = (data) => ({
	type: EDIT_UNIT_OPEN_RESULT,
	payload: data,
});

export const unitEditUnitClose = () => ({
	type: EDIT_UNIT_CLOSE,
	//payload: { data },
});

export const unitEditUpdate = (unit, inputs, outputs, systemAlerts, systemAlertsToDelete) => ({
	type: EDIT_UNIT_UPDATE,
	payload: { unit, inputs, outputs, systemAlerts, systemAlertsToDelete },
});

export const unitEditUpdateResult = (result, error) => ({
	type: EDIT_UNIT_UPDATE_RESULT,
	payload: { result, error },
});

export const editMultipleUnits = (units) => ({
	type: EDIT_MULTIPLE_UNITS,
	payload: units
});

export const sendOta = (data) => ({
	type: EDIT_UNIT_SEND_OTA,
	payload: data,
});

export const sendOtaResult = (response) => ({
	type: EDIT_UNIT_SEND_OTA_RESULT,
	payload: response
});

export const sendPing = (data) => ({
	type: EDIT_UNIT_SEND_PING,
	payload: data,
});

export const sendPingResult = (response) => ({
	type: EDIT_UNIT_SEND_PING_RESULT,
	payload: response
});

export const decodeVin = (data) => ({
	type: EDIT_UNIT_DECODE_VIN,
	payload: { data },
});

export const decodeVinResponse = (response) => ({
	type: EDIT_UNIT_DECODE_VIN_RESPONSE,
	payload: { response },
});