export default {
  videoClipRequest_pending: 'Pendiente',
  videoClipRequest_failed: 'Fallido',
  videoClipRequest_inProgress: 'En progreso',
  videoClipRequest_completed: 'Completado',
  videoClipRequest_new: 'Nueva solicitud de video',
  videoClipRequest_getClip: 'Obtener clip',
  videoClipRequest_unit: 'Unidad',
  videoClipRequest_date: 'Fecha y Hora',
  videoClipRequest_starredClips: 'Videos Importantes',
  videoClipRequest_clipRequest: 'Solicitudes de Video',
  videoClipRequest_successDeletion: 'La solicitud de video se ha eliminado correctamente',
  videoClipRequest_errorDeletion: 'Hubo un error eliminando la solicitud de video',
  videoClipRequest_successCreation: 'La solicitud de video se ha creado correctamente',
  videoClipRequest_invalidClipRange: 'El clip debe de estar dentro de un video',
  videoClipRequest_deleteConfirmationTitle: 'Advertencia',
  videoClipRequest_deleteConfirmationMessage: 'Deseas eliminar la solicutud de video?',
  videoClipRequest_updateNote: 'Actualizar nota',
  videoClipRequest_addNote: 'Agregar Nota',
  videoClipRequest_multiplesuccessDeletion: 'Los clips se han eliminado con éxito',
  videoClipRequest_multipleErrorDeletion: 'Hubo un error al borrar los clips',
  videoClipRequest_deleteConfirmationEventMessage: 'Desea eliminar el evento?',
  videoClipRequest_deleteConfirmationEventsMessage: 'Desea eliminar los eventos seleccionados?',
  videoClipRequest_restoreClipSuccess: 'El clip ha sido restaurado con éxito',
  videoClipRequest_restoreClipsSuccess: 'Los clips se han restaurado con éxito',
  // RadioCheck seconds selection
  videoClipRequest_seconds20: '20 seg clip (10 seg antes, 10 seg después)',
  videoClipRequest_seconds40: '40 seg clip (20 seg antes, 20 seg después)',
  videoClipRequest_seconds60: '60 seg clip (30 seg antes, 30 seg después)',
  videoClipRequest_validateDate: 'la fecha no es válida',
  videoClipRequest_selectedClip: 'Rango de video clip seleccionado',
  videoClipRequest_selectedDate: 'Seleccione una unidad y una fecha por favor...',
  videoClipRequest_noUnitSelected: 'Seleccione una unidad para ver la linea de tiempo',
  videoClipRequest_notAvailable: 'No Disponible',
  videoClipRequest_clipDate: 'Fecha',
  videoClipRequest_driverUnit: 'Conductor / Unidad',
  videoClipRequest_timeFrame: 'Tiempo',
  videoClipRequest_requestor: 'Solicitante',
  videoClipRequest_requested: 'Solicitado',
  videoClipRequest_from: 'Desde',
  videoClipRequest_to: 'Hasta',
  videoClipRequest_unit: "Unidad",
  videoClipRequest_driver: "Conductor",
  videoClipRequest_status: "Estado",
  videoClipRequest_dateTime: "Fecha/Hora",
  videoClipRequest_notes: "Notas",
  videoClipRequest_filters: "Filtros:",
  videoClipRequest_unitsFilter: "Etiquetas/Unidades/Conductores:",
  videoClipRequest_dateRange: "Rango de Fechas:",
  videoClipRequest_dates: "Fecha:",
  videoClipRequest_time: "Hora:",
  videoClipRequest_hasNotes: "Con Notas",
  videoClipRequest_noClips: "No hay clips para esta unidad",
  videoClipRequest_unassigned: 'No asignado',
  videoClipRequest_noMoreActions: 'No hay mas acciones',

  videoClipRequest_Alert: 'Alerta',
  videoClipRequest_message_grid: 'Selecciona un rango de tiempo disponible en la siguiente grilla para seleccionar tu video clip',
    videoClipRequest_latitude: "Latitud:",
  videoClipRequest_longitude: "Longitud:",
  videoClipRequest_duration: "Duración",
  videoClipRequest_date: "Fecha",
  videoClipRequest_Image: 'Imagen',
  videoClipRequest_Video: 'Video',
    videoClipRequest_durationBefore: 'Segundos antes',
  videoClipRequest_durationAfter: 'Segundos despues',
  videoClipRequest_message_grid: 'Selecciona un rango de tiempo disponible en la siguiente grilla para tu videoclip.',
  videoClipRequest_getPreview: "Obtener Vista Previa"
};
