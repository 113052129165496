export const ROUTES_GET_ROUTES = "ROUTES_GET_ROUTES";
export const ROUTES_GET_ROUTES_RESPONSE = "ROUTES_GET_ROUTES_RESPONSE";
export const ROUTES_GET_ROUTES_AND_POINT = "ROUTES_GET_ROUTES_AND_POINT";
export const ROUTES_OPEN_CREATE_EDIT_ROUTE = "ROUTES_OPEN_CREATE_EDIT_ROUTE";
export const ROUTES_OPEN_UPLOAD_ROUTE = "ROUTES_OPEN_UPLOAD_ROUTE";
export const ROUTES_OPEN_ADD_STOP = "ROUTES_OPEN_ADD_STOP";
export const ROUTES_CREATE_UPDATE_ROUTE = "ROUTES_CREATE_UPDATE_ROUTE";
export const ROUTES_CREATE_UPDATE_ROUTE_RESPONSE = "ROUTES_CREATE_UPDATE_ROUTE_RESPONSE";
export const ROUTES_ADD_STOP = "ROUTES_ADD_STOP";
export const ROUTES_ADD_STOP_RESPONSE = "ROUTES_ADD_STOP_RESPONSE";
export const ROUTES_DELETE_ROUTE = "ROUTES_DELETE_ROUTE";
export const ROUTES_DELETE_ROUTE_RESPONSE = "ROUTES_DELETE_ROUTE_RESPONSE";
export const ROUTES_DELETE_ROUTE_MULTIPLE = "ROUTES_DELETE_ROUTE_MULTIPLE";
export const ROUTES_DELETE_ROUTE_MULTIPLE_RESPONSE = "ROUTES_DELETE_ROUTE_MULTIPLE_RESPONSE";
export const ROUTES_DELETE_STOP = "ROUTES_DELETE_STOP";
export const ROUTES_DELETE_STOP_RESPONSE = "ROUTES_DELETE_STOP_RESPONSE";
export const ROUTES_GET_ROUTE = "ROUTES_GET_ROUTE";
export const ROUTES_GET_ROUTE_RESPONSE = "ROUTES_GET_ROUTE_RESPONSE";
export const ROUTES_OPEN_DELETE_MODAL = "ROUTES_OPEN_DELETE_MODAL";
export const ROUTES_UPDATE_STOP_ORDER = "ROUTES_UPDATE_STOP_ORDER";
export const ROUTES_UPDATE_STOP_ORDER_RESPONSE = "ROUTES_UPDATE_STOP_ORDER_RESPONSE";
export const ROUTES_UPDATE_STOP = "ROUTES_UPDATE_STOP";
export const ROUTES_UPDATE_STOP_RESPONSE = "ROUTES_UPDATE_STOP_RESPONSE";