// Dependencies
import { all } from 'redux-saga/effects';

// Components
import eventsSaga from 'Modules/video/views/main/.components/events/redux/saga';
import clipsSaga from 'Modules/video/views/main/.components/clips/redux/saga';
import manageCamerasSaga from 'Modules/video/views/manageCamerasTab/redux/sagas';
import videoDetailSaga from 'Modules/video/views/videoDetail/redux/saga';

// Merge sagas
export default function* rootSaga() {
  yield all([
    eventsSaga(),
    clipsSaga(),
    manageCamerasSaga(),
    videoDetailSaga()
  ]);
}