import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';

// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

// Helpers
import {
  baseReportConditions,
  generateReportInstance,
  getCategoryFilter,
  getTimeZone,
  handleAsyncReport,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';
import {ExceptionManager} from "Core/logManager";
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

function* getDriverSafetyReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
  const {timeZone, sendByEmail, emailsTo, name, delayed, timeframeName, categoryName, dataResultPath} = report;
  const {filterType, filterArray, startRange, endRange, unitsSelected, accumulateData = true, dates} = report.filters
  const baseConditionsQuery = baseReportConditions({
    report,
    name,
    dataResultPath,
    timeZone,
    delayed,
    unitsSelected,
    timeframeName,
    filterType,
    filterArray,
    categoryName,
    startRange,
    endRange,
    dates
  })
  try {

    let payload = {
      conditions: {
        ...baseConditionsQuery,
        sortField: {
          field: sortField || "deviceId",
          order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
      },
      limit: rowsPerPage,
      offset: page
    }

    if (report.export) {

      const isDevice = getCategoryFilter(filterType) === "deviceId"

      let addColumnDriver = [];
      if(report?.exportType === 'csv') {
        addColumnDriver.push(... [{
          column: isDevice ? "deviceLabel" : "driverName",
          header: isDevice ? "Unit" : "Driver"
        },{
          column: "unitTimeFormat",
          header: "Time & Date"
        },
          {
            column: "address",
            header: "Address"
          },
          {
            column: "speed",
            header: "Speed"
          },
          {
            column: isDevice ? "driverName" : "deviceLabel",
            header: isDevice ? "Driver" : "Unit"
          },
          {
            column: "direction",
            header: "Direction"
          },
          {
            column: "event",
            header: "Event"
          },
          {
            column: "signal",
            header: "Signal"
          }])
      }else {
        addColumnDriver.push({
          column: isDevice ? "driverName" : "deviceLabel",
          header: isDevice ? "Driver" : "Unit"
        },{
          column: "unitTimeFormat",
            header: "Time & Date"
        },
        {
          column: "address",
            header: "Address"
        },
        {
          column: "speed",
            header: "Speed"
        },
        {
          column: "direction",
            header: "Direction"
        },
        {
          column: "event",
            header: "Event"
        },
        {
          column: "signal",
            header: "Signal"
        })
      }

      payload = {
        limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
        offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
        conditions: {
          ...payload.conditions,
          exportOptions: {
            sendByEmail,
            emailsTo: emailsTo,
            culture: report.lang,
            timeZone: getTimeZone(timeZone),
            format: {
              fileFormat: report?.exportType || "json",
              formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
              durationFormat: report?.durationFormat
            },
            folderName: "px-customer-reports",
            filePrefixName: "driverSafetyReport",
            columns:addColumnDriver
          }
        }
      }
    }
    let data = yield call(
      clientQuery,
      `query($conditions: ReportConditionsInputRequired, $limit: Int, $offset: Int) {
        Report: getDriverSafetyReport(conditions: $conditions, limit: $limit, offset: $offset) {
          ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ? `
          reportInstanceId
          ` : `
          total
          limit
          offset
          category
          items {
            recordInfo {
              entityId
              entityLabel
              tagId
              tagLabel
              startDate
              endDate
              recordType
              groupLabel
              groupIndex
              groupTotal
              groupTagColor
            }
            recordDetail {
              earliestDate
              latestDate
              unitTime
              driverName
              deviceLabel
              address
              speed
              direction
              event
              signal
              earliestDate
              latestDate
              speedLimit
              isKPH
              noData
            }
          }`
      }
        }
        }
        `,
      payload,
      endpoints.GRAPHQL_PX_REPORTING,
    );
    yield handleAsyncReport(report, data, accumulateData);
  } catch (exc) {
    ExceptionManager(exc, 'reports/getDriverSafetyReport', 'getDriverSafetyReport');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
    if (!requestFlag) {
      let flag = true
      yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
    } else {
      report.loading = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  }
}

export default getDriverSafetyReport;

