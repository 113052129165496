import {
    MAP_GEOFENCES_ASSIGN_OPEN_CLOSE_MODAL,
    MAP_GEOFENCES_ASSIGN_GET_UNIT_GEOFENCE,
    MAP_GEOFENCES_ASSIGN_GET_UNIT_GEOFENCE_RESPONSE,
    MAP_GEOFENCES_ASSIGN_SAVE,
    MAP_GEOFENCES_ASSIGN_SAVE_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    open: false,
    id: null,
    type: null,
    unitGeofence: null,
    loadingUnitGeofence: false,
    response: null,
    loadingResponse: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MAP_GEOFENCES_ASSIGN_OPEN_CLOSE_MODAL:
            return {
                ...state,
                open: action.payload.open,
                id: action.payload.id,
                type: action.payload.type,
                unitGeofence: null,
                loadingUnitGeofence: false,
                response: null,
                loadingResponse: false
            };

        case MAP_GEOFENCES_ASSIGN_GET_UNIT_GEOFENCE:
            return {
                ...state,
                unitGeofence: null,
                loadingUnitGeofence: true,
            };

        case MAP_GEOFENCES_ASSIGN_GET_UNIT_GEOFENCE_RESPONSE:
            return {
                ...state,
                unitGeofence: action.payload.response,
                loadingUnitGeofence: false
            };

        case MAP_GEOFENCES_ASSIGN_SAVE:
            return {
                ...state,
                response: null,
                loadingResponse: true
            };

        case MAP_GEOFENCES_ASSIGN_SAVE_RESPONSE:
            return {
                ...state,
                response: action.payload.response,
                loadingResponse: false,
            };

        case CLEAR_REDUX:
            return ["", "MAP_GEOFENCE_ASSIGN"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
            return { ...state };
    }
}