import {
  MAP_GEOFENCE_VISIBLE_GEOFENCE_TAGS,
  MAP_GEOFENCE_VISIBLE_GEOFENCE_TAGS_RESPONSE,
  MAP_GEOFENCE_UPDATE_PREFERENCE,
  CLEAR_REDUX
} from "Redux/actionTypes";

const INIT_STATE = {
  loadingGeofenceTags: false,
  geofenceTags: [],
  contPreference: 0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MAP_GEOFENCE_VISIBLE_GEOFENCE_TAGS:
      return { ...state, geofenceTags: [], loadingGeofenceTags: true };

    case MAP_GEOFENCE_VISIBLE_GEOFENCE_TAGS_RESPONSE:
      return { ...state, geofenceTags: action.payload.response, loadingGeofenceTags: false };

    case MAP_GEOFENCE_UPDATE_PREFERENCE:
      return { ...state, contPreference: state.contPreference + 1 }


    case CLEAR_REDUX:
      return ["", "MAP_GEOFENCE_VISIBLE_LANDMARK_GROUPS"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state };

    default:
      return state;
  }
};