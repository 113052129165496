//imports
import {
  MIGRATE_USER_SEND,
  MIGRATE_USER_SEND_RESULT,
  MIGRATE_USER_CONFIRM_CLOSE,
  MIGRATE_USER_CONFIRM,
  MIGRATE_USER_CONFIRM_RESULT,
  SET_PERMISSIONS_TO_MIGRATE_THE_USER
} from 'Redux/actionTypes';

/* ACTIONS */

export const migrateUser = (username, email, password) => ({
  type: MIGRATE_USER_SEND,
  payload: { username, email, password }
});

export const migrateUserResult = (cognitoValues, error) => ({
  type: MIGRATE_USER_SEND_RESULT,
  payload: { cognitoValues, error }
});

export const migrateUserConfirmClose = () => ({
  type: MIGRATE_USER_CONFIRM_CLOSE,
  //payload: { }
});

export const migrateUserConfirm = (username, code, cognitoValues, navigate) => ({
  type: MIGRATE_USER_CONFIRM,
  payload: { username, cognitoValues, code, navigate }
});

export const migrateUserConfirmResult = (result, error) => ({
  type: MIGRATE_USER_CONFIRM_RESULT,
  payload: { result, error }
});

export const setPermissionToMigrateUser = (data) => {
  return {
    type: SET_PERMISSIONS_TO_MIGRATE_THE_USER,
    payload: { data }
  }
};
