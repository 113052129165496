import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Checkbox } from 'Components/uiControls';
import {
    ContextMenu,
    Button
} from 'Components';

import {
    Grid,
    Typography,
    Chip,
    Icon
} from "@mui/material";
import { getTagColor, cutNotes } from 'Core/data/Helpers';

// Styles
import '../styles.scss';

const TreeNode = (props) => {
    const { messages } = useIntl();
    const { tag, searchText, values, onChange } = props;

    const [showChildren, setShowChildren] = useState(false);

    useEffect(() => {
        if (values?.length) {
            if ((values?.find(v => v?.id == tag?.id) || values.some(v => tag?.offspring?.includes(v?.id))) && tag?.children?.length > 0 && !tag?.disabled) {
                setShowChildren(true);
            }
        }
    }, [values])

    const handleClick = () => {
        setShowChildren(!showChildren);
    };

    const getChildren = (item) => {
        let selected = [];
        if (item?.children?.length) {
            item?.children?.forEach(child => {
                if (!values?.find(v => v?.id == child?.id) && !child?.disabled) {
                    selected.push({
                        id: child?.id,
                        name: "Tag",
                        label: child?.label,
                        color: child?.color
                    });

                    if (child?.children?.length) {
                        let selectedChildren = getChildren(child);
                        selected = [ ...selected, ...selectedChildren];
                    }
                }
            });
        }

        return selected;
    }

    let checked = values?.find(v => v?.id == tag?.id) ? true : false;

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                wrap="nowrap"
                style={{
                    padding: 5
                }}
            >
                {
                    !searchText &&
                    <Grid item>
                        <div style={{ width: 33, height: 30 }}>
                            {
                                tag?.children?.length > 0 &&
                                <Icon
                                    disabled={tag?.disabled}
                                    style={{ fontSize: 30, margin: '0px', cursor: 'pointer' }}
                                    onClick={handleClick}
                                >
                                    {showChildren ? 'arrow_drop_down' : 'arrow_right'}
                                </Icon>
                            }
                        </div>
                    </Grid>
                }

                <Grid item>
                    <Checkbox
                        id={`tag-${tag?.id}`}
                        value={checked}
                        disabled={tag?.disabled}
                        partiallySelected={(values.some(v => tag?.offspring?.includes(v?.id)) && !checked) ? true : false}
                        onChange={() => {
                            let newValues = [...values];
                            if (values?.find(v => v?.id == tag?.id)) {
                                newValues = values?.filter(v => v?.id != tag?.id);
                            } else {
                                newValues.push({
                                    id: tag?.id,
                                    name: "Tag",
                                    label: tag?.label,
                                    color: tag?.color
                                });

                                if (tag?.children?.length) {
                                    let children = getChildren(tag);
                                    newValues = [ ...newValues, ...children];
                                }
                            }

                            if (onChange) onChange(newValues);
                        }}
                    />
                </Grid>

                <Grid item>
                    <Icon
                        style={{
                            fontSize: 17,
                            margin: '5px 5px 0px',
                            color: `#${getTagColor(tag?.color)}`
                        }}
                    >
                        local_offer
                    </Icon>
                </Grid>

                <Grid item xs zeroMinWidth>
                    <Typography noWrap>
                        {tag?.id == -1 ? messages[tag?.label] : tag?.label}
                    </Typography>
                </Grid>
            </Grid>

            <div style={{ paddingLeft: 10 }}>
                {
                    (tag?.children?.length > 0 && showChildren) &&
                    <Tree
                        tags={tag?.children}
                        searchText={searchText}
                        values={values}
                        onChange={onChange}
                    />
                }
            </div>
        </>
    );
}

const Tree = (props) => {
    const { tags, searchText, values, onChange, firstLevel } = props;
    return (
        <div
            style={{
                paddingLeft: firstLevel ? 0 : 10
            }}
        >
            {tags?.map((tag) => (
                <TreeNode
                    tag={tag}
                    key={tag.id}
                    searchText={searchText}
                    values={values}
                    onChange={onChange}
                />
            ))}
        </div>
    );
}

const EntityTagPickerTags = (props) => {
    const { messages } = useIntl();

    const inputRef = useRef();
    const containerRef = useRef();

    const {
        searchText,
        setSearchText,
        tags,
        values,
        onChange,
        disabled
    } = props;

    const allTags = useSelector(state => state.newEntityTagPickerRedux.tags);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open]);

    const handleClickOutside = (e) => {
        if (containerRef?.current?.contains(e.target)) {
            return;
        }

        setOpen(false);
        setSearchText("");
    };

    const openMenu = useCallback((e) => {
        if (e) e.preventDefault();
        setOpen(!open);
        inputRef?.current?.focus();
    });

    return (
        <div ref={containerRef} open={open} className="gtc-entity-tag-picker-container">
            <div className="gtc-entity-tag-picker-input-container">
                {
                    !open &&
                    <React.Fragment>
                        <div
                            className={`gtc-entity-tag-picker-value-container ${disabled ? 'gtc-entity-tag-picker-disabled' : ''}`}
                            onClick={(e) => {
                                if (!disabled) {
                                    openMenu(e);
                                }
                            }}
                        >
                            <span className='text-muted'>
                                {messages['entityTagPicker_searchTag']}
                            </span>
                        </div>

                        <div
                            style={{
                                marginTop: 8,
                                maxHeight: 178,
                                overflow: 'hidden auto'
                            }}
                        >
                            {
                                (values?.length > 0) && values.map((value, index) => {
                                    const currentTag = allTags?.find(tag => tag?.id == value?.id);
                                    return (
                                        <Chip
                                            label={cutNotes(value?.label, 15)}
                                            key={index}
                                            style={{
                                                color: "white",
                                                backgroundColor: `#${getTagColor(value?.color || currentTag?.color)}`,
                                                marginBottom: 8
                                            }}
                                            clickable={true}
                                            deleteIcon={<Icon style={{ fontSize: 14 }}>close</Icon>}
                                            onDelete={() => {
                                                let newValues = values.filter(nv => nv.id != value.id);
                                                if (onChange) onChange(newValues);
                                            }}
                                        />
                                    )
                                })
                            }
                        </div>
                    </React.Fragment>
                }

                {
                    open &&
                    <input
                        ref={inputRef}
                        className="gtc-entity-tag-picker-input input-normal form-control"
                        type="text"
                        value={searchText}
                        placeholder={messages['entityTagPicker_searchTag']}
                        onChange={(e) => {
                            setSearchText(e?.target?.value);
                        }}
                        autoFocus
                        disabled={disabled}
                    />
                }

                <div className="gtc-entity-tag-picker-menu-arrow-button">
                    <Button
                        type="icon"
                        icon={open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                        placement="bottom"
                        className="btn-xs"
                        fontSize="inherit"
                        disabled={disabled}
                        onClick={openMenu}
                    />
                </div>
            </div>

            <ContextMenu
                style={{
                    width: '100%',
                    marginTop: 2,
                    position: 'absolute',
                    maxHeight: 250,
                    overflow: 'auto'
                }}
                open={open}
            >
                <div className="gtc-entity-tag-picker-options-container">
                    <Tree
                        tags={(tags || [])}
                        searchText={searchText}
                        values={values}
                        onChange={onChange}
                        firstLevel={true}
                    />
                </div>
            </ContextMenu>
        </div>
    );
}

export default EntityTagPickerTags;
