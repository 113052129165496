export default {
    settingsDriverStatusLog_title: "Driver Time Card",
    settingsDriverStatusLog_timeFrame: "Timeframe",
    settingsDriverStatusLog_driver: "Driver",
    settingsDriverStatusLog_date: "Date",
    settingsDriverStatusLog_dailyTotal: "Daily Total",
    settingsDriverStatusLog_day: "day",
    settingsDriverStatusLog_hour: "hour",
    settingsDriverStatusLog_minute: "minute",
    settingsDriverStatusLog_second: "second",
};