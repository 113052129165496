//imports
import {
	FORGOT_PASSWORD,
	FORGOT_PASSWORD_RESULT,
	FORGOT_PASSWORD_CONFIRM,
	FORGOT_PASSWORD_CONFIRM_RESULT,
	FORGOT_PASSWORD_CONFIRM_CLOSE,
	CLEAR_REDUX
} from 'Redux/actionTypes';

//initial values
const INIT_STATE = {
	loading: false,
	showConfirm: false,
	error: null,
	result: false,
};

//change redux object
export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case FORGOT_PASSWORD:
			return { ...INIT_STATE, loading: true, error: null };

		case FORGOT_PASSWORD_RESULT:
			// console.log("FORGOT_PASSWORD_RESULT")
			return {
				...state,
				loading: false,
				showConfirm: action.payload.showConfirm,
				error: action.payload.error,
			};

		case FORGOT_PASSWORD_CONFIRM:
			return { ...state, loading: true, result: false, error: null };

		case FORGOT_PASSWORD_CONFIRM_RESULT:
			return {
				...state,
				loading: false,
				result: action.payload.result,
				error: action.payload.error,
			};

		case FORGOT_PASSWORD_CONFIRM_CLOSE:
			return { ...state, showConfirm: false, error: null };

		case CLEAR_REDUX:
			return ["", "FORGOT_PASSWORD"].includes(action.payload.option)
				? action.payload.parameters
					? { ...state, ...action.payload.parameters }
					: { ...INIT_STATE }
				: { ...state }
		default:
      return state;
	}
};
