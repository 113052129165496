import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// controls
import Table, {Column} from '../../table'
//helpers
import {
    formatDatetime,
    getCategoryFilterType,
    getNameVehicle,
    useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';

//hooks
import { useTableReport } from 'Core/hooks';
//actions
import {clearRedux, reportsMainGenerateEvents, reportsMainGenerateReportUpdate} from 'Redux/actions'
import moment from 'moment';

const SmartOneCUtilizationReport = (props) => {
    const {messages, report} = props;
    const {data, filters, summary, loadingSummary, timeZone} = report;
    const {filterEntity, otherFilters, startRange, endRange} = filters;
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
    const [currentNewSortField, setCurrentNewSortField] = useState("deviceId");
    const [currentNewSortOrder, setCurrentNewSortOrder] = useState("asc");
    const exportHandler = useHandleExportReport(report)

    const loadingCreateLandMark = useSelector(state => state.vehicleLandMarkRedux.loadingCreateLandMark)
    const createLandMarkResult = useSelector(state => state.vehicleLandMarkRedux.createLandMarkResult)

    const reportRef = useRef();
    const dispatch = useDispatch()

    useEffect(() => {
        if (report) {
            const newReport = JSON.parse(JSON.stringify(report));
            // to keep the reference to the units selected
            reportRef.current = newReport;
        }
    }, [JSON.stringify(report)]);


    useEffect(() => {
        if (!loadingCreateLandMark && createLandMarkResult) {
            dispatch(reportsMainGenerateReportUpdate(report, currentPage, currentRowsPerPage, currentNewSortField, currentNewSortOrder))
            dispatch(clearRedux("LANDMARK", {"createLandMarkResult": null}));
        }

    }, [loadingCreateLandMark, createLandMarkResult])

    const onChangeExpandable = (unit) => {
        if(!unit.noData){
            let events = {
                data: [],
                filters: {
                    filterType: getCategoryFilterType(filterEntity),
                    filterArray: unit,
                    page: 1,
                    rowsPerPage: 10,
                    sortField: 'eventDate',
                    otherFilters
                }
            }
            dispatch(reportsMainGenerateEvents(report, events))
        }
    }

    const exitstLabel = (label) => {
        if(!label){
            return '--'
        } 

        return label
    }

    let parametersSummary = useTableReport(
        {
            data, 
            report, 
            messages, 
            hideHeader: false
        }
    );

    const renderExpandableRow = (row) => {
        let parametersSummaryTable = {
            ...parametersSummary,
            data: summary?.items || [],
            totalItems: summary?.total || 0,
            hideHeader: false,
            serverSideTable: true,
            onChangedPage: (page, rowsPerPage, sortField, sortOrder) => {
              let events = {
                data: [],
                filters: { filterType: getCategoryFilterType(filterEntity), filterArray: row, page, rowsPerPage, sortField, sortOrder, otherFilters }
              }
              dispatch(reportsMainGenerateEvents(report, events))
            },
            onChangeSelector: null,
            itemsSelector: null,
            generateExport: false,
            hideSelectorUnitsDriver: true,
            groups:report.filters.units || [],
            hideNumberOfRows:true,
            centerPagination: true,
            noData: row.noData
          };

        return (
            <div>
                <Table
                   {...parametersSummaryTable}
                   loading={loadingSummary}
                >
                    <Column label={messages["reportsMainRT_label"]} field="idLabel" noSorting>
                        {(element) => {
                            const formatLabel = exitstLabel(element.idLabel)
                            return (
                                <div>
                                    <span>{formatLabel}</span>
                                </div>
                            )
                        }}
                    </Column>
                    <Column label={messages["reportsMainRT_tags"]} field="groupLabel" noSorting>
                        {(element) => {
                              const formatLabel = exitstLabel(element.groupLabel)
                            return (
                                <div>
                                    <span>{formatLabel}</span>
                                </div>
                            )
                        }}
                    </Column>
                    <Column label={messages["reportsMainRT_engineRunningTime"]} field="engineRuntimeHours" noSorting>
                        {(element) => {
                              const formatLabel = exitstLabel(element.engineRuntimeHours)
                            return (
                                <div>
                                    <span>{formatLabel}</span>
                                </div>
                            )
                        }}
                    </Column>
                    <Column label={messages["reportsMainRT_engineRunningTimePerc"]} field="engineRuntimePerc" noSorting>
                        {(element) => {
                              const formatLabel = exitstLabel(element.engineRuntimePerc)
                            return (
                                <div>
                                    <span>{`${formatLabel}%`}</span>
                                </div>
                            )
                        }}
                    </Column>
                    <Column label={messages["reportsMainRT_engineOffTime"]} field="engineOffTimeHours" noSorting>
                        {(element) => {
                              const formatLabel = exitstLabel(element.engineOffTimeHours)
                            return (
                                <div>
                                    <span>{formatLabel}</span>
                                </div>
                            )
                        }}
                    </Column>
                    <Column label={messages["reportsMainRT_engineOffTimePerc"]} field="engineOffTimePerc" noSorting>
                        {(element) => {
                              const formatLabel = exitstLabel(element.engineOffTimePerc)
                            return (
                                <div>
                                    <span>{`${formatLabel}%`}</span>
                                </div>
                            )
                        }}
                    </Column>
                </Table>
            </div>
        )
    }

    let parametersTable = useTableReport({
        data: data.items,
        report,
        messages,
        hideHeader: true,
        renderExpandableRow,
        onChangeExpandable,
        propertyToShowExpandable: 'id'
    });

    return (
        <Fragment>
            <Table
                {...parametersTable}
                {...exportHandler}
            >
                <Column label="Day" field="label" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span><strong>{getNameVehicle(filterEntity, messages)}:</strong> {element.idLabel} </span>
                            </div>
                        );
                    }}
                </Column>
                <Column label={messages['generatedReport_totalIdleTime']} field="label" noSorting>
                    {(element) => {
                        const localTimeStart = moment.utc(startRange).local().format();
                        const localTimeEnd = moment.utc(endRange).local().format();
                        return (
                            <div>
                                <span>
                                  <span>
                                    <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                                  </span>
                                </span>
                            </div>
                        );
                    }}
                </Column>
            </Table>
        </Fragment>
    );
}


export default SmartOneCUtilizationReport;
