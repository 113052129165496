//View Keys
export default {
  "migrateUser_title": "Recuperar Contraseña",
  "migrateUser_login": "Ingresar",
  "migrateUser_email": "Email",
  "migrateUser_currentUsername": "Username Actual",
  "migrateUser_password": "Contraseña",
  "migrateUser_verificationCode": "Código de verificación",
  "migrateUser_newPassword": "Nueva Contraseña",
  "migrateUser_ConfirmNewPassword": "Confirmar nueva contraseña",
  "migrateUser_emailVerification": "Verificación de Email",
  "migrateUser_verifyCode": "Código de verificación",
  "migrateUser_verifyCodeError": "Error durante el código de verificación",
  "migrateUser_modalSubtitle" : "Estamos cambiando a un sistema de autenticación basado en correo electrónico y necesitaremos convertir su nombre de usuario en una dirección de correo electrónico válida.",
  "migrateUser_modalSubtitle2" : "Ingrese una dirección de correo electrónico a la que tenga acceso y vuelva a ingresar su nombre de usuario y contraseña.",
  "migrateUser_verify": "Verificar",
  "migrateUser_verifyTex": "Se le acaba de enviar un código de verificación por correo electrónico, ingrese el código a continuación para verificar su correo electrónico",
  "migrateUser_success": "Su contraseña ha sido actualizada, ¡inicie sesión!",
  "migrateUser_passwordError": "Las contraseñas no coinciden",
  "migrateUser_back": "¿Volver a iniciar sesión?",
  "migrateUser_cancel": "Cancelar",
  "migrateUser_termsTitle": "TÉRMINOS Y CONDICIONES",
  "migrateUser_acceptTerms": "He leído y acepto los Términos y Condiciones",
  "migrateUser_terms": `    
  <div>
  <div>
  ESTOS TÉRMINOS Y CONDICIONES (el "<b> Acuerdo </b>"), se realizan y celebran entre usted y Global Tracking Communications, LLC ("GTC"),
  incluidos sus sucesores y / o cesionarios ("<b> GTC </b>", "<b> nosotros </b>" o "<b> nosotros </b>") y entra en vigor inmediatamente después de su acto afirmativo. de aceptación en el sitio web o aplicación
  (la "<b> Fecha de entrada en vigor </b>").

    </div>
  <ul>
    <li>
    1. Hardware, licencia de software y servicio al cliente: a cambio de sus pagos continuos por el servicio y el acceso a nuestra aplicación, le proporcionamos lo siguiente:
        <ul>
        <li>
        una. Uso / título del equipo: para el hardware comprado, el equipo debe continuar a partir de la fecha de entrada en vigor de la venta.
        Solo para el hardware incluido (dispositivos incluidos con los planes de software de rastreo GPS), la propiedad del equipo pertenece y seguirá siendo propiedad de GTC.
        El Cliente acepta que no permitirá que ningún gravamen, gravamen o garantía real se adhiera al Equipo.
        En caso de cancelación del servicio, el Cliente acepta que todo el Equipo se devolverá a GTC. No hay penalizaciones por cancelación.
        En caso de que el Equipo se pierda o no se devuelva, se puede cobrar una tarifa que no excederá el valor del Hardware Incluido.
          </li>
        <li>
          B. Licencia de software:
          <ul>
            <li>
            I. Sujeto a su cumplimiento de todos los términos y condiciones de este Acuerdo, GTC le otorga una
            Licencia no exclusiva (la "<b> Licencia de software </b>") para utilizar todo el software proporcionado por GTC (el "<b> Software </b>") durante la Vigencia de este Acuerdo.

            </li>
            <li>
            ii. <u> Reserva de derechos y propiedad: </u> GTC se reserva todos los derechos no otorgados expresamente a usted en este Acuerdo.
            El Software está protegido por derechos de autor y otras leyes y tratados de propiedad intelectual, propiedad de GTC. Estás licenciando el Software.
            No está comprando el Software.
            </li>
            <li>
            iii. <u> Prohibiciones. </u> No puede aplicar ingeniería inversa al Software. No puede revender el Software, permitir que otra parte (afiliada o de otro tipo) utilice el Software,
            o compartir o distribuir el Software a cualquier otra persona. Además, acepta asegurarse de que los Sistemas no se utilicen para fines ilegales,
            fines fraudulentos o abusivos o de una manera que afecte negativamente la reputación de InTouch en la comunidad empresarial.
            </li>
          </ul>
        </li>
        <li>
        C. Servicio al cliente: GTC cuenta con representantes de servicio al cliente durante el horario laboral normal para responder cualquier pregunta relacionada con los productos.
        </li>
        <li>
        d.  The Hardware, Software License, and Customer Service are sometimes collectively referred to herein as the “<b>System.</b>”
          <ul>
            <li>
            1.  Term: The term of this Agreement, unless otherwise documented in your sales agreement, begins on the Effective Date (ship date of hardware).
            This Agreement shall automatically renew for one (1) year periods unless you provide written notice of your intent to terminate this Agreement
            no less than sixty (60) days prior to the expiration of any particular term period (the “<b>Term</b>”).
            </li>
            <li>
            2.  Warranty and Liability: <br/>
            <u>Express Hardware and Software Warranty:</u> The Hardware may include a manufacturer’s warranty.  Information concerning this warranty may be provided upon request by you.
            The Software is warranted against manufacturer defects for one (1) year from the Effective Date (the “<b>Equipment and Software Warranty</b>”).
            This Equipment and Software Warranty entitles you to a replacement of the Equipment and Software in the case of a malfunction related to a manufacturing defect only, 
            and does not include replacement for any damage or malfunction caused by you, your customers, or any other third party.
            </li>
            <li>
            a.  <u>Descargo de responsabilidad de la garantía: </u> EXCEPTO LA GARANTÍA EXPRESA ANTERIOR, NI GTC NI NINGÚN PROVEEDOR DE SERVICIO DE GTC OFRECEN GARANTÍA DE NINGÚN TIPO CON RESPECTO AL SISTEMA,
            QUE SE SUMINISTRAN "TAL CUAL". GTC RENUNCIA EXPRESAMENTE A CUALQUIER REPRESENTACIÓN O GARANTÍA DE QUE LOS SISTEMAS ESTARÁN LIBRES DE ERRORES, SEGUROS O ININTERRUMPIDOS.
            </li>
            <li>
            B. <u> Garantías implícitas: </u> GTC (INCLUYENDO SUS PROVEEDORES DE SERVICIOS) RENUNCIA EXPRESAMENTE A TODAS LAS GARANTÍAS Y CONDICIONES IMPLÍCITAS, INCLUYENDO LAS GARANTÍAS Y CONDICIONES DE COMERCIABILIDAD IMPLÍCITAS,
            APTITUD PARA UN PROPÓSITO PARTICULAR, TÍTULO, NO INFRACCIÓN Y LOS QUE SURJAN POR ESTATUTO O DE OTRO MODO EN LA LEY O DE UN CURSO DE TRATAMIENTO O USO COMERCIAL.
            </li>
            <li>
            C. <u> Contenidos y lesiones personales: </u> GTC renuncia expresamente, y usted asume expresamente, todo riesgo de pérdida o daño a su vehículo monitoreado, su contenido,
            y por lesiones personales a personas que ocupen o se vean afectadas por su vehículo monitoreado.
            </li>
            <li>
            d.  <u>Limitaciones, exclusiones y renuncias </u> <br/>
            Limitación de daños: GTC NO SERÁ RESPONSABLE DE NINGÚN DAÑO GENERAL, DIRECTO, ESPECIAL, INCIDENTAL, EJEMPLAR, PUNITIVO Y / O CONSECUENTE RELACIONADO CON LOS SISTEMAS O SU USO DE LOS SISTEMAS.
            Además, usted reconoce que ninguna garantía expresa o implícita se considerará creada por cualquier declaración hecha a GTC o sus representantes fuera de este Acuerdo. <br/>
            I. Limitación de responsabilidad: Usted reconoce y acepta que GTC no es una aseguradora y no asegura los Sistemas ni sus activos. Por la presente se le informa que debe obtener o hacer que sus clientes
            para obtener un seguro para los Sistemas o activos por la cantidad que considere conveniente. El monto mensual pagado por usted se basa en los Sistemas (incluido el software y los servicios que contiene)
            y no está relacionado con el valor de su propiedad o activos. Por la presente, renuncia a toda subrogación y otros derechos de recuperación contra GTC que pueda tener cualquier asegurador u otra persona. Estás de acuerdo en que
            Dado que GTC no conoce el alcance de los daños potenciales como resultado de los mismos, GTC no puede y no será responsable de las interrupciones en el servicio del Dispositivo.
            Debido a la dificultad para determinar los daños reales que pueden resultar de la falta de cumplimiento de las obligaciones bajo este Acuerdo, si se determina que GTC o cualquiera de sus agentes, empleados, subsidiarias, afiliadas,
            asigna, o las empresas matrices son directa o indirectamente responsables de cualquier pérdida, daño, lesión u otra consecuencia, usted acepta que los daños se limitarán a $ 2,000 o al 20% de los cargos anuales que paga
            bajo este Acuerdo, el que sea menor. Estos daños acordados no son una sanción, sino un acuerdo de las Partes para ser el único remedio para usted sin importar cómo se cause la pérdida.
            El propósito de esto es proporcionar una resolución rápida a una disputa (lo que lo beneficiaría a usted) y al mismo tiempo limitar el límite máximo potencial para dicha cantidad (lo que nos beneficiaría).
            Específicamente en lo que se refiere al uso de los Dispositivos y no como limitación de cualquier otra parte de este párrafo, el Cliente acepta que GTC no es responsable de ningún impuesto, multa, tarifa,
            o sanciones asociadas con la falla de los Dispositivos para permanecer alimentados, conectados a Internet u otros dispositivos, o mecánicamente viables. 
            </li>
            <li>
            e.  <i>Remedio exclusivo: </i> Esta sección contiene su recurso exclusivo contra GTC. <br/>
            <i> Indemnización y exención de responsabilidad: </i> En caso de que cualquier parte amenace o presente cualquier demanda u otro reclamo contra GTC o sus agentes, empleados, subsidiarias, afiliadas o empresas matrices que surjan,
            en relación con, o en conexión con los Sistemas, usted acepta ser el único responsable de, indemnizar y eximir a GTC por completo de tales demandas u otras reclamaciones,
            independientemente del tipo o naturaleza de dicha demanda o reclamación. En consecuencia, debe pagar todos los daños, gastos, costos y honorarios de abogados y gastos asociados con los mismos.
            Esta Sección sobrevivirá a la terminación de este Acuerdo. <br/>
            I. <i> Estatuto de limitaciones: </i> cualquier demanda u otra acción que pueda tener contra GTC o agentes, empleados, subsidiarias, afiliadas,
            o las empresas matrices deben presentarse dentro de un (1) año a partir de la fecha del evento que causó la pérdida, daño o responsabilidad. <br/>
            <i> ii. Servicio de GPS y celular: </i> El sistema recibe señales del sistema de satélite de posicionamiento global ("<b> GPS </b>") y transmite y recibe señales de,
            Proveedores externos de GTC (los “<b> Proveedores </b>”) y a un Centro de servicios ("<b> SC </b>") y / o directamente a través de Internet a varios dispositivos. Los proveedores y los servicios de SC se pueden proporcionar en cualquier método determinado por GTC.
            El sistema no funciona con otros proveedores. Usted comprende que el Sistema instalado en el vehículo monitoreado utiliza tecnología de telefonía celular como modo de transmisión para enviarle señales.
            Los servicios solo están disponibles para usted en los Estados Unidos y Canadá y solo cuando se encuentre en un área de cobertura celular. Los servicios pueden ser limitados, interrumpidos o descontinuados, sin responsabilidad para InTouch, debido a muchas condiciones.
            Los sistemas GPS no estarán disponibles si las señales satelitales se interrumpen, bloquean u obstruyen de alguna manera. Para que el sistema GPS funcione correctamente, el sistema debe estar en el exterior con una línea clara de ubicación hacia el satélite.
            Usted reconoce que la confiabilidad del GPS y las redes celulares son fundamentales para la efectividad del producto y que GTC no tiene ningún control sobre la utilidad de ese sistema.
            Además, comprende y acepta explícitamente el riesgo de que el GPS y el sistema celular funcionen correctamente y acepta que GTC no será responsable del funcionamiento de ninguno de los sistemas.
            Además, reconoce y acepta que se producirán interrupciones del servicio en cualquiera de los sistemas y acepta el riesgo de dichas interrupciones. En consecuencia, acepta que GTC no será, de ninguna manera, responsable de,
            o tener responsabilidad con respecto al sistema GPS, cualquier información en el mismo obtenida, o por interrupciones en el servicio por cualquier motivo.
            También comprende que cuando el sistema está dañado, el GTC no recibe señales y que usted se encuentra en la mejor y más cercana posición para comprender cuándo se ha producido un daño o el sistema no está funcionando correctamente.
            Además, reconoce y acepta que GTC no tendrá ninguna responsabilidad relacionada con el Sistema por cualquier problema que ocurra debido a rayos, cortes de energía, disturbios, inundaciones, incendios, guerras o casos fortuitos.
            El uso de radiofrecuencias y dispositivos celulares está controlado por la Comisión Federal de Comunicaciones y los cambios en sus reglas podrían potencialmente terminar con el negocio de GTC.
            </li>
          </ul>
        </li>
        <li>
        1. Installation and Maintenance: You are responsible for installing, activating and maintaining the System. You understand and agree that GTC is not responsible for, and shall have no liability for the System’s installation into the monitored vehicle.
        You understand that government regulations apply to the proper operation of the System and that, in the event that the system is not operating properly, whether through a lack of power, connectivity, or otherwise, 
        you are responsible to keep accurate logs related to the system to comply with those regulations.  You further acknowledge and agree that GTC is not responsible to provide any backup information to you in order for you to comply with the regulations.
        We are not an insurer that your regulatory requirements are being met, but a tool to assist you.  If the System is not working, it is your responsibility to maintain compliance with regulations.

        </li>
        <li>
        2.  Authorized Use: You agree that you will only place the Systems on vehicles which you own.
        You further agree that you and any agent of yours will use the Systems only for lawful purposes and will not tamper with the device or in any way deviate from the intent of the parties with respect to the use of the Systems.  
        </li>
        <li>
        3.  Termination: This Agreement may be terminated immediately at the option of GTC at any time upon the occurrence of any of the following events:
        your default under this Agreement or failure to perform as required by this Agreement;<br/>
        a.  your default in payment due under this Agreement;<br/>
        b.  in the event governmental regulations or limitations necessitate the discontinuance of the System.

        </li>
      </ul>
    </li>
    <li>
    1.  Acceso a los servicios: el acceso a los servicios se puede proporcionar mediante un número de teléfono celular de diez dígitos (el "<b> Número de equipo </b>"), que es propiedad de GTC. GTC se reserva el derecho de cambiar el número de equipo con diez (10) días de anticipación.
    excepto que no se requiere notificación si GTC sospecha de fraude. El número de equipo no se puede transferir ni cambiar sin el consentimiento previo por escrito de GTC. Usted es responsable de todas las llamadas realizadas por o al número de equipo.
    </li>
    <li>
    2. Solicitudes de servicios: Usted autoriza a GTC a realizar solicitudes de información, servicio, pedidos o equipos en cualquier aspecto en su nombre a los Proveedores de servicios, según lo determine GTC que sea necesario o deseable.
    </li>
    <li>
    3.  Renuncia a la privacidad: Usted acepta que, con el fin de brindar capacitación interna basada en las interacciones con los clientes, GTC puede monitorear o grabar sus conversaciones con el personal de GTC involucrado en brindarle Servicios bajo este Acuerdo. Más lejos,
    usted acepta que GTC no puede garantizar la privacidad en un teléfono celular o configuración de GPS y, en consecuencia, GTC no será responsable ante usted por ningún reclamo, pérdida, daño o costo que resulte de la falta de privacidad. Usted reconoce y acepta que, si bien GTC no lo identificará personalmente,
    podemos analizar y distribuir su información y estadísticas para ofrecer nuevos productos o hacer cumplir los términos de este Acuerdo. Por la presente, nos otorga la autoridad total, sin limitación, para proporcionar cualquier autoridad gubernamental, sin su conocimiento o permiso adicional,
    información pasada, presente o futura relativa al Sistema. Su sistema puede incluir o no cámaras u otros dispositivos de grabación. En la medida en que una localidad regule asuntos relacionados con la privacidad,
    usted renuncia a su derecho a reclamar cualquier violación de la privacidad contra usted o cualquiera de sus agentes o empleados, y además acepta indemnizar completamente y eximir a GTC de cualquier reclamo de terceros relacionado con asuntos relacionados con la privacidad.
    </li>
    <li>
    4. Asigna: Usted no puede ceder este Acuerdo excepto con el consentimiento previo por escrito de GTC. GTC tendrá el derecho, en todo o en parte, de ceder este Acuerdo o subcontratar cualquiera de sus obligaciones en virtud de este Acuerdo sin previo aviso.
    </li>
    <li>
    5.  Notices.  Any notice, communication, request, reply or advice (hereinafter severally and collectively, for convenience, called “<b>Notice</b>”) in this Agreement provided or permitted to be given, made or accepted by either Party to the other, must be in writing,
    addressed to the person to be notified (in the event that such person is an entity, the address to be used is the address of record with the Secretary of State in the state of formation), and either delivered in person to the Party or an officer of such Party,
    sent via certified mail return receipt requested, or sent by a nationally recognized overnight courier for next business day delivery.  Notice deposited in the mail or via overnight courier in the manner hereinabove described shall be effective, unless otherwise stated in this Agreement, 
    from and after the date it is so deposited. Notice given in any other manner shall be effective only if and when received by the Party to be notified.  
    Any Party may change the address to which Notices are to be delivered by giving the other Parties notice in a manner consistent with the notice requirements described above.
    </li>
    <li>
    6.  Headings.  The headings in this Agreement are intended solely for convenience of reference and shall be given no effect in the construction or interpretation of this Agreement.
    </li>
    <li>
    7.  Contrapartes. Este Acuerdo puede ser ejecutado en múltiples contrapartes, cada una de las cuales se considerará un original, y todas juntas constituirán un solo y mismo documento.
    </li>
    <li>
    8. Ley aplicable. Este Acuerdo se regirá e interpretará de acuerdo con las leyes de Florida, sin tener en cuenta los principios de conflicto de leyes.
    </li>
    <li>
    9.  Competencia y jurisdicción exclusivas. Cada Parte del presente consiente y acuerda, con respecto a cualquier reclamo o causa de acción, ya sea en la ley o en equidad, incluido el desempeño específico, que surja de o de alguna manera relacionada con este Acuerdo, a la jurisdicción exclusiva de, y lugar en,
    Polk County, Florida, o si se encuentra en un tribunal federal, en el Distrito Medio de Florida, División de Tampa. Cada una de las Partes renuncia a cualquier objeción basada en forum non conveniens y renuncia a cualquier objeción al lugar de cualquier acción instituida en virtud del presente.
    </li>
    <li>
    10. Renuncias. El cumplimiento de las disposiciones de este Acuerdo solo puede ser renunciado mediante un instrumento escrito que se refiera específicamente a este Acuerdo y firmado por la Parte que renuncia al cumplimiento. Ningún curso de negociación, ni ninguna falla o demora en el ejercicio de ningún derecho, se interpretará como una renuncia,
    y ningún ejercicio único o parcial de un derecho impedirá cualquier otro o posterior ejercicio de ese o cualquier otro derecho.
    </li>
    <li>
    11. Pronombres. El uso de un pronombre en particular en este documento no será restrictivo en cuanto al género o el número, pero se interpretará en todos los casos según lo requiera el contexto.
    </li>
    <li>
    12. Períodos de tiempo. Cualquier acción que se requiera a continuación que se tome dentro de un cierto número de días se tomará dentro de ese número de días calendario; siempre que, sin embargo, si el último día para tomar tal acción sea un fin de semana o un feriado,
    el período durante el cual se puede tomar tal acción se extenderá automáticamente al siguiente día hábil.
    </li>
    <li>
    13. Sin construcción estricta. Se considerará que el lenguaje utilizado en este Acuerdo es el idioma elegido por las Partes para expresar su intención mutua, y no se aplicará ninguna regla de construcción estricta contra ninguna de las Partes.
    </li>
    <li>
    14. Modificación. Ningún suplemento, modificación o enmienda de este Acuerdo será vinculante a menos que se haga en un instrumento escrito firmado por todas las Partes y que se refiera específicamente a este Acuerdo.
    </li>
    <li>
    15. Acuerdo completo. Este Acuerdo y los acuerdos y documentos a los que se hace referencia en este Acuerdo o entregados a continuación son la declaración exclusiva del acuerdo entre las Partes con respecto al objeto del mismo.
    Todas las negociaciones entre las Partes se fusionan en este Acuerdo, y no hay representaciones, garantías, pactos, entendimientos o acuerdos, verbales o de otro tipo, en relación con el mismo entre las Partes que no sean las incorporadas en este documento y que se entregarán a continuación.
    </li>
    <li>
    16. Honorarios de abogados. Si se inicia cualquier acción legal, arbitraje u otro procedimiento para la aplicación de este Acuerdo, o debido a una supuesta disputa, incumplimiento, incumplimiento o tergiversación en relación con cualquiera de las disposiciones de este Acuerdo,
    la Parte o las Partes ganadoras o ganadoras tendrán derecho a recuperar los honorarios razonables de abogados y otros costos incurridos en esa acción o procedimiento, además de cualquier otra compensación a la que puedan tener derecho.
    </li>
  </ul>
  Firma electrónica: al hacer clic o dar permiso a un representante autorizado para hacer clic en la casilla de verificación "Acepto los Términos y condiciones" en el sitio web, usted acepta estar sujeto a los términos y condiciones de este Acuerdo, además, comprende, reconoce,
  y acepta que al hacer clic o dar permiso a un representante autorizado para hacer clic en "Acepto los Términos y condiciones", ha adjuntado su firma electrónica a este Acuerdo y está sujeto a los términos y condiciones antes mencionados según lo exige la Ley uniforme de transacciones electrónicas ( "UETA"),
  que establece la validez legal de firmas y contratos electrónicos en EE. UU.
</div>`
};

