import {
    FSM_SETTINGS_QUICK_RESPONSE_SEARCH,
    FSM_SETTINGS_QUICK_RESPONSE_SEARCH_RESPONSE,
    FSM_SETTINGS_QUICK_RESPONSE_CREATE,
    FSM_SETTINGS_QUICK_RESPONSE_CREATE_RESPONSE,
    FSM_SETTINGS_QUICK_RESPONSE_UPDATE,
    FSM_SETTINGS_QUICK_RESPONSE_UPDATE_RESPONSE,
    FSM_SETTINGS_QUICK_RESPONSE_DELETE,
    FSM_SETTINGS_QUICK_RESPONSE_DELETE_RESPONSE,
    FSM_SETTINGS_QUICK_RESPONSE_OPEN_CREATE_EDIT,
    FSM_SETTINGS_QUICK_RESPONSE_OPEN_DELETE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loadingQuickResponse: false,
    quickMData: [],
    QuickResponse: {},
    loadingCreateEdit: false,
    openCreateEditQuickResponse: false,
    loadingDelete: false,
    response: {},
    responseDelete: false,
    openDelete: false,
    quickResponseDelete: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case FSM_SETTINGS_QUICK_RESPONSE_SEARCH:
            return {
                ...state,
                loadingQuickResponse: true,
                quickMData: []
            };

        case FSM_SETTINGS_QUICK_RESPONSE_SEARCH_RESPONSE:
            return {
                ...state,
                loadingQuickResponse: false,
                quickMData: action?.payload?.quickResponse
            };

        case FSM_SETTINGS_QUICK_RESPONSE_CREATE:
            return {
                ...state,
                loadingCreateEdit: true,
                response: {}
            };

        case FSM_SETTINGS_QUICK_RESPONSE_CREATE_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                response: action.payload
            };

        case FSM_SETTINGS_QUICK_RESPONSE_UPDATE:
            return {
                ...state,
                loadingCreateEdit: true,
                response: {}
            };

        case FSM_SETTINGS_QUICK_RESPONSE_UPDATE_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                response: action.payload
            };

        case FSM_SETTINGS_QUICK_RESPONSE_DELETE:
            return {
                ...state,
                loadingDelete: true,
                responseDelete: false
            };

        case FSM_SETTINGS_QUICK_RESPONSE_DELETE_RESPONSE:
            return {
                ...state,
                loadingDelete: false,
                responseDelete: action.payload?.data
            };

        case FSM_SETTINGS_QUICK_RESPONSE_OPEN_CREATE_EDIT:
            return {
                ...state,
                openCreateEditQuickResponse: action.payload?.open,
                QuickResponse: action.payload?.quickResponse,
            };

        case FSM_SETTINGS_QUICK_RESPONSE_OPEN_DELETE:
            return {
                ...state,
                openDelete: action.payload?.open,
                quickResponseDelete: action.payload?.quickResponses,
            };

        case CLEAR_REDUX:
            return ["", "FSM_SETTINGS_QUICK_RESPONSE"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return state;
    }
};