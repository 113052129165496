import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"
import { uploadFile } from "Core/data/FileUpload";


//STATE INITIAL
const INIT_STATE = {
    loading: false,
    files: [],
    error: null,
    loadingDeleteLayer: false,
    errorDeleteLayer: null,
    statusDeleteLayer: false,
    updateFile: {},
    loadingUpdateFile: false,
    resUpdateFile: false,
    responseCreateFiles: null,
    errorCreateFiles: false,
    loadingCreateFile: false
};

const route = "layerMaps/redux/slice";

export const getListFile = createAsyncThunk("layerMaps/redux/slice/getListFile", async ({
    entityName,
    originalName
}, { rejectWithValue, dispatch }) => {

    try {
        let response = await clientQuery(
            `
            query ($entityName: String, $originalName: String, $limit: Int, $offset: Int) {
                data: searchAttachmentsList (entityName: $entityName, originalName: $originalName, limit: $limit, offset: $offset) {
                    items {
                        id
                        fileKey
                        originalName
                    } 
                }
            }
            `,
            {
                originalName,
                entityName,
                limit: 500,
                offset: 0
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        if (response?.data) return response?.data?.items
        else throw 'Error Deleting Driver Task';

    } catch (exc) {
        ExceptionManager(exc, route, 'layerMaps/redux/slice');
        return rejectWithValue(exc);
    }
})

export const uploadFiles = createAsyncThunk("layerMaps/redux/slice/uploadFiles", async ({
    files, entityName = 'KMZ'
}, { rejectWithValue, dispatch }) => {
    try {
        const resArray = []
        let res = await Promise.all(
            files?.length && files?.map(async (item) => {
                const { fileKey, originalName } = item;
                const extension = fileKey?.name.split(".").pop();
                const buildName = `${originalName}.${extension}`
                let presignedUrlResponse = await clientQuery(
                    `query(
                        $repositoryCode: String
                        $fileExtension: String
                    ) {
                        Data: getPresignedUrl(
                            repositoryCode: $repositoryCode,
                            fileExtension: $fileExtension
                        ) {
                            repositoryCode
                            fileKey
                            url
                        }
                    }`,
                    {
                        repositoryCode: endpoints.REPOSITORY_CODE,
                        fileExtension: extension,
                    },
                    endpoints.GRAPHQL_GENERAL
                )
    
                if (!presignedUrlResponse || !presignedUrlResponse.Data) {
                    throw "There are a problem uploading the File";
                }

                await uploadFile(presignedUrlResponse.Data.url, fileKey);

                let registerAttachmentResult = await clientMutation(
                    `mutation ($repositoryCode: String!, $fileKey: String!, $originalName: String, $entityName: String){
                        attachment: registerAttachment(repositoryCode: $repositoryCode, fileKey: $fileKey, originalName: $originalName, entityName: $entityName){
                          id
                          repositoryId
                          repositoryType
                          repositoryCode
                          repositoryPath
                          fileKey
                          originalName
                        }
                      }`,
                    {
                        repositoryCode: presignedUrlResponse.Data.repositoryCode,
                        fileKey: presignedUrlResponse.Data.fileKey,
                        originalName: buildName,
                        entityName: entityName
                    },
                    endpoints.GRAPHQL_GENERAL
                )
                resArray.push(registerAttachmentResult.attachment)
                return registerAttachmentResult.attachment
            })
        ).then((values) => {
            return values
        }).catch(console.error.bind(console));
        return resArray
    } catch {
        ExceptionManager(exc, route, 'layerMaps/redux/slice');
        return rejectWithValue(exc);
    }
})

export const deleteFileLayer = createAsyncThunk("layerMaps/redux/slice/deleteFileLayer", async ({ ids }, { rejectWithValue, dispatch }) => {

    try {
        let flag = true
        ids?.map(async (item) => {
            let response = await clientMutation(
                `
                    mutation ($id: Int!) {
                        data: deleteAttachment (id: $id)
                    }
                `,
                {
                    id: item?.id
                },
                endpoints.GRAPHQL_GENERAL_V2
            );
            if (!response?.data) flag = false
        })

        return flag

    } catch (exc) {
        ExceptionManager(exc, route, 'layerMaps/redux/slice');
        return rejectWithValue(exc);
    }
})

export const updateFileLayer = createAsyncThunk("layerMaps/redux/slice/updateFileLayer", async ({ id, originalName }, { rejectWithValue, dispatch }) => {

    try {
        let response = await clientMutation(
            `
            mutation ($id: Int!, $originalName: String) {
                data: updateAttachment (id: $id, originalName: $originalName)
            }
            `,
            {
                id,
                originalName
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        return { [id]: response?.data }

    } catch (exc) {
        ExceptionManager(exc, route, 'layerMaps/redux/slice');
        return rejectWithValue(exc);
    }
})

export const filesRedux = createSlice({
    name: "layer/files",
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {

        //getListFile
        builder.addCase(getListFile.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(getListFile.fulfilled, (state, action) => {
            state.loading = false;
            state.files = action.payload;
        });

        builder.addCase(getListFile.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //deleteFileLayer
        builder.addCase(deleteFileLayer.pending, (state, action) => {
            state.loadingDeleteLayer = true
        });

        builder.addCase(deleteFileLayer.fulfilled, (state, action) => {
            state.loadingDeleteLayer = false;
            state.statusDeleteLayer = action.payload;
        });

        builder.addCase(deleteFileLayer.rejected, (state, action) => {
            state.loadingDeleteLayer = false;
            state.errorDeleteLayer = action.payload;
        });

        //updateFileLayer
        builder.addCase(updateFileLayer.pending, (state, action) => {
            state.loadingUpdateFile = true
        });

        builder.addCase(updateFileLayer.fulfilled, (state, action) => {
            state.loadingUpdateFile = false
            state.updateFile = { ...state?.updateFile, ...action.payload };
        });

        builder.addCase(updateFileLayer.rejected, (state, action) => {
            state.loadingUpdateFile = false;
            state.resUpdateFile = action.payload;
        });


        //createFile
        builder.addCase(uploadFiles.pending, (state, action) => {
            state.loadingCreateFile = true
        });

        builder.addCase(uploadFiles.fulfilled, (state, action) => {
            state.loadingCreateFile = false
            state.responseCreateFiles = action.payload;
        });

        builder.addCase(uploadFiles.rejected, (state, action) => {
            state.loadingCreateFile = false;
        });
    }
})

const { actions, reducer } = filesRedux;

// Extract and export each action creator by name
export const {
    reset
} = actions;

export default reducer;