import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';

// Actions 
import { reportsMainGenerateReportProgress } from 'Redux/actions';

// Helpers
import {
  getTimeZone,
  getCategoryFilter
} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getFormStopSummaryDetailsReport(report, event) {
  const { timeZone, name, reportTimeRanges } = report;
  const { filterType, sortField, sortOrder,otherFilters } = report.filters;
  const { filterArray, startRange, endRange, subLimit, subPage, rowsPerPage, page } = event.filters;
  try {
    let data = yield call(
      clientQuery,
      `query getFormStopSummaryDetailsReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getFormStopSummaryDetailsReport (conditions: $conditions, limit: $limit, offset: $offset) {
          items {
            driverId
            dateTime
            event
            duration
            address
            form
            question
            answer
            latitude
            longitude
          }
          total
          limit
          offset
          category
        }
      }`,
      {
        conditions: {
          reportName: name,
          category: {
            field: getCategoryFilter(filterType) || filterType,
            value: filterArray
          },
          timeZone: getTimeZone(timeZone),
          dateRange: {
            fields: [
              "unitTime"
            ],
            GTE: startRange,
            LTE: endRange,
            reportTimeRanges
          },
          integerRanges: {
            fields: [
              "stop"
            ],
            GTE: otherFilters.minute || 0,
            LTE: 0
          },
          sortField: {
            field: sortField || "driverId",
            order: sortOrder === 'desc' ? 'DESC' : 'ASC'
          }
        },
        limit: subLimit,
        offset: subPage
      },
      endpoints.GRAPHQL_PX_REPORTING
    );

    if (data && data.Report) {
      report.events = data?.Report
      report.loadingSubEvents = false
      yield put(reportsMainGenerateReportProgress(report))
    } else {
      report.loadingSubEvents = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  } catch (exc) {
    report.loadingSubEvents = false
    yield put(reportsMainGenerateReportProgress(report))
  }
}

export default getFormStopSummaryDetailsReport;