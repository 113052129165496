// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    SETTINGS_CREWS_SEARCH,
    SETTINGS_CREWS_GET_CREW,
    SETTINGS_CREWS_CREATE_EDIT_CREW,
    SETTINGS_CREWS_DELETE_CREWS,
    SETTINGS_CREWS_BULK_UPDATE,
    SETTINGS_CREWS_SEARCH_AVAILABLE_ONLY,
    SETTINGS_CREWS_SEARCH_UNIT_ID,
    SETTINGS_CREWS_SEARCH_UNIT_CREWS
} from "Redux/actionTypes";

// Actions
import {
    searchSettingsCrewsResponse,
    getSettingsCrewResponse,
    createEditSettingsCrewResponse,
    deleteSettingsCrewsResponse,
    updateBulkCrewUpdateResponse,
    searchUnitCrewsResponse,
    searchSettingsCrewsByUnitIdResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* searchUnitCrews({ payload }) {
    try {
        const {
            openSearch,
            limit,
            offset
        } = payload;

        const query = `
            query searchUnitCrews ($openSearch: String, $limit: Int, $offset: Int) {
                searchUnitCrews (openSearch: $openSearch, limit: $limit, offset: $offset) {
                    items {
                        id
                        label
                        crews {
                            id
                            firstName
                            lastName
                            unitId
                            createdOn
                            createdBy
                            updatedOn
                            updatedBy
                        }
                    }
                    total
                }
            }
        `;

        const response = yield call(
            clientQuery,
            query,
            {
                openSearch,
                limit,
                offset
            },
            endpoints.GRAPHQL_GENERAL
        );

        const { searchUnitCrews: { items, total } } = response;
        yield put(searchUnitCrewsResponse({ items, total, error: false }));
    } catch (exc) {
        yield put(searchUnitCrewsResponse({ items: [], total: 0, error: true }));
        ExceptionManager(
            exc,
            "modules/settings/views/crew/redux/sagas",
            "searchUnitCrews"
        );
    }
}

function* searchCrewsRequest({ payload }) {
    const {
        openSearch,
        availableOnly,
        unitId,
        limit,
        offset
    } = payload;
    try {

        const query = `
            query searchCrews($openSearch: String, $availableOnly: Boolean!, $unitId: Int, $limit: Int, $offset: Int) {
                Crews: searchCrews(
                    openSearch: $openSearch, 
                    availableOnly: $availableOnly, 
                    unitId: $unitId,
                    limit: $limit,
                    offset: $offset
                ) {
                    items {
                        id
                        firstName
                        lastName
                        unit {
                            id
                            label
                        }
                    }
                    total
                }
            }
        `;

        const response = yield call(
            clientQuery,
            query,
            {
                openSearch,
                availableOnly:availableOnly || false,
                unitId,
                limit,
                offset
            },
            endpoints.GRAPHQL_GENERAL
        );

        if (!response && !response.Crews) throw "Error while getting crew list";

        const { Crews: { items, total } } = response;
        unitId > 0 ? 
            yield put(searchSettingsCrewsByUnitIdResponse({ items, total, error: false })) :
            yield put(searchSettingsCrewsResponse({ items, total, error: false }))
    } catch (exc) {
        unitId > 0 ? 
            yield put(searchSettingsCrewsByUnitIdResponse({ items: [], total: 0, error: true })) :
            yield put(searchSettingsCrewsResponse({ items: [], total: 0, error: true }))
        ExceptionManager(
            exc,
            "modules/settings/views/crew/redux/sagas",
            "searchCrewsRequest"
        );
    }
}

function* getCrewRequest({ payload }) {
    try {
        const { id } = payload;

        const response = yield call(
            clientQuery,
            `
                query getCrew($id: Int!) {
                    Crew: getCrew(id: $id) {
                        id
                        firstName
                        lastName
                        unitId
                    }
                }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );

        if (!response && !response.Crew) throw "Error while getting crew";

        yield put(getSettingsCrewResponse({ ...response.Crew, error: false }));
    } catch (exc) {
        yield put(getSettingsCrewResponse({ error: true }));
        ExceptionManager(
            exc,
            "modules/settings/views/crew/redux/sagas",
            "getCrewRequest"
        );
    }
}

function* createEditCrewRequest({ payload }) {
    try {
        const { crew } = payload;

        let query = `
            mutation createCrew (
                $firstName: String!, 
                $lastName: String!, 
                $unitId: Int
            ) {
                Crew: createCrew(firstName: $firstName, lastName: $lastName, unitId: $unitId) {
                    id
                    firstName
                    lastName
                    unitId
                    unit {
                        id
                        label
                    }
                }
            } 
        `;

        let data = {
            firstName: crew?.firstName,
            lastName: crew?.lastName,
            unitId: crew?.unitId
        }

        if (crew?.id) {
            query = `
                mutation updateCrew (
                    $id: Int!, 
                    $firstName: String!, 
                    $lastName: String!, 
                    $unitId: Int
                ) {
                    Crew: updateCrew(id: $id, firstName: $firstName, lastName: $lastName, unitId: $unitId) {
                        id
                        firstName
                        lastName
                        unit {
                            id
                            label
                        }
                    }
                } 
            `;
            data.id = crew?.id;
        }

        const response = yield call(
            clientMutation,
            query,
            data,
            endpoints.GRAPHQL_GENERAL,
        );

        if (!response || !response.Crew) throw 'Error while creating crew';

        yield put(createEditSettingsCrewResponse({ ...response.Crew, error: false }));
    } catch (exc) {
        yield put(createEditSettingsCrewResponse({ error: true }));
        ExceptionManager(
            exc,
            "modules/settings/views/crew/redux/sagas",
            "createEditCrewRequest"
        );
    }
}

function* deleteCrewsRequest({ payload }) {
    try {
        const { items } = payload;
        let mutations = [];
        items?.forEach((item, index) => {
            mutations.push(`
                Crew${index}: deleteCrew(id: ${item?.id})
            `);
        });

        let mutation = `mutation{${mutations.join("")}}`;

        const response = yield call(
            clientMutation,
            mutation,
            {},
            endpoints.GRAPHQL_GENERAL,
        );

        if (!response || !response.Crew0) throw 'Error while deleting crew members';

        yield put(deleteSettingsCrewsResponse({ ...response, error: false }));
    } catch (exc) {
        yield put(deleteSettingsCrewsResponse({ error: true }));
        ExceptionManager(
            exc,
            "modules/settings/views/crew/redux/sagas",
            "deleteCrewsRequest"
        );
    }
}

function* bulkUpdateCrewRequest({ payload }) {
    try {
        const { unitId, crewIds } = payload;
        let query = `
            mutation bulkUpdateCrew ($unitId: Int!, $crewIds: [Int]!) {
                bulkUpdateCrew (unitId: $unitId, crewIds: $crewIds)
            }        
        `;

        let data = {
            unitId,
            crewIds
        }

        const response = yield call(
            clientMutation,
            query,
            data,
            endpoints.GRAPHQL_GENERAL,
        );

        yield put(updateBulkCrewUpdateResponse({ update: response.bulkUpdateCrew, error: false }));
    } catch (exc) {
        yield put(updateBulkCrewUpdateResponse({ update: false, error: true }));
        ExceptionManager(
            exc,
            "modules/settings/views/crew/redux/sagas",
            "bulkUpdateCrewRequest"
        );
    }
}

function* watchMethods() {
    yield takeEvery(SETTINGS_CREWS_SEARCH, searchCrewsRequest);
    yield takeEvery(SETTINGS_CREWS_GET_CREW, getCrewRequest);
    yield takeEvery(SETTINGS_CREWS_CREATE_EDIT_CREW, createEditCrewRequest);
    yield takeEvery(SETTINGS_CREWS_DELETE_CREWS, deleteCrewsRequest);
    yield takeEvery(SETTINGS_CREWS_BULK_UPDATE, bulkUpdateCrewRequest);
    yield takeEvery(SETTINGS_CREWS_SEARCH_AVAILABLE_ONLY, searchCrewsRequest);
    yield takeEvery(SETTINGS_CREWS_SEARCH_UNIT_ID, searchCrewsRequest);
    yield takeEvery(SETTINGS_CREWS_SEARCH_UNIT_CREWS, searchUnitCrews);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}