export default {
    exportVehicles_title: "Exportación Próximos mantenimientos",
    exportVehicles_upcoming: "Próximos mantenimientos",
    exportVehicles_fileFormat: "Formato de archivo",
    exportVehicles_dueIn: "Vencimiento En:",
    exportVehicles_exportPeriod: "Período de exportación",
    exportVehicles_days: "días",
    exportVehicles_ahead: "de adelanto",
    exportVehicles_left: "pendientes",
    exportVehicles_vehicledueexport: "Exportación de vehículos pendientes",
    exportVehicles_pdfMessage: "Para pdf es necesario seleccionar un máximo de 7 columnas",
    exportVehicles_tags: 'Etiquetas',
    exportVehicles_vehicle: 'Vehículo',
    exportVehicles_lastServiceDate: 'Fecha del último servicio',
    exportVehicles_odometerAtLastService: 'Odómetro en el último servicio',
    exportVehicles_currentOdometer: 'Odómetro actual',
    exportVehicles_engineHoursAtLastService: 'Horas de motor en el último servicio',
    exportVehicles_currentEngineHours: 'Horas actuales del motor',
    exportVehicles_nextServiceDue: 'Próximo servicio previsto',
    exportVehicles_nextServiceStatus: 'Próximo estado del servicio',
    exportVehicles_daysInterval: 'Intervalo de días',
    exportVehicles_engineHoursInterval: 'Intervalo de horas del motor',
    exportVehicles_milesInterval: 'Intervalo de millas',
    exportVehicles_lastReportedLocation: 'Última ubicación informada',
    exportVehicles_pdfMaxFiles: 'Para exportar en PDF, es necesario seleccionar las columnas que desea ver en el archivo (máximo 7 campos)',
    exportVehicles_requireType: 'Se requiere un formato de archivo',
    exportVehicles_requirePdfFields: 'Se requiere al menos una columna seleccionada',



}