import {
	CATALOG_UPDATE_LAST_READING,
	CATALOG_UPDATE_LAST_READING_RESULT,
	FLEET_VEHICLE_GET_STATUSES,
	FLEET_VEHICLES_GET_STATUSES_RESULT,
	FLEET_MAIN_CHANGE_FILTERS,
	CLEAR_REDUX
} from 'Redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
	updateLastReadingLoading: false,
	updateLastReadingResult: {},

	fleetGetVehicleStatesLoading: false,
	fleetGetVehicleStatesResult: [],

	filters: {
		tags: [],
		events: [],
		time: null
	}
};

export default (state = INIT_STATE, action) => {
	let key;
	switch (action.type) {
		case FLEET_MAIN_CHANGE_FILTERS:
			return { ...state, filters: action.payload }

		case CATALOG_UPDATE_LAST_READING:
			return {
				...state,
				updateLastReadingLoading: true
			}
		case CATALOG_UPDATE_LAST_READING_RESULT:
			key = action?.payload?.response?.id;
			return {
				...state,
				updateLastReadingLoading: false,
				updateLastReadingResult: action?.payload?.response
			}
		case FLEET_VEHICLE_GET_STATUSES:
			return {
				...state,
				fleetGetVehicleStatesLoading: true,
				fleetGetVehicleStatesResult: []
			}
		case FLEET_VEHICLES_GET_STATUSES_RESULT:
			return {
				...state,
				fleetGetVehicleStatesLoading: false,
				fleetGetVehicleStatesResult: action.payload
			}

		case CLEAR_REDUX:
			return ["", "STATUS_FLEET"].includes(action.payload.option)
				? action.payload.parameters
					? { ...state, ...action.payload.parameters }
					: { ...INIT_STATE }
				: { ...state }
		default:
      return state;
	}
};