// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  FSM_SETTINGS_MESSAGE_SEARCH,
  FSM_SETTINGS_MESSAGE_CREATE,
  FSM_SETTINGS_MESSAGE_CREATE_ALL,
  FSM_SETTINGS_MESSAGE_GET_DRIVER_TO_DRIVER,
  FSM_SETTINGS_MESSAGE_UPDATE_COMPDATA_FSM_ALL
} from "Redux/actionTypes";

// Actions
import {
  searchMessageResponse,
  createMessageResponse,
  createMessageAllResponse,
  getAllowDriverToDriverResponse,
  updateAllowDriverToDriverResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* getAllowDriverFsm({ payload }) {

  try {

    const query = `
    query getCompdata {
      getCompdata {
        fsmAllChat
      }
  }
        `;

    const response = yield call(
      clientQuery,
      query,
      {},
      endpoints.GRAPHQL_GENERAL
    );
    if (!response && !response.getCompdata) {
      throw "Error while getting  getCompdata list";
    }
    yield put(getAllowDriverToDriverResponse(response.getCompdata));
  } catch (exc) {
    yield put(getAllowDriverToDriverResponse({}));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "getAllowDriverFsm"
    );
  }
}

function* searchMessage({ payload }) {

  try {

    const query = `
    query searchFsmDriverMessage ($driverId: Int, $limit: Int, $offset: Int) {
      searchFsmDriverMessage (driverId: $driverId, limit: $limit, offset: $offset) {
          items {
              id
              message
              date
              read
              driverTo {
                  name
                  companyId
                  driverId
                  unitId
                  unit {
                      id
                      label
                      typeId
                      type
                  }
                  username
              }
          }
          total
      }
  }
        `;

    const response = yield call(
      clientQuery,
      query,
      {
        driverId:payload.driverId
      },
      endpoints.GRAPHQL_GENERAL
    );
    if (!response && !response.searchFsmDriverMessage) {
      throw "Error while getting  Messages list";
    }
    let messages = response.searchFsmDriverMessage;
    yield put(searchMessageResponse({ messages, error: false }));
  } catch (exc) {
    yield put(searchMessageResponse({ messages: [], error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "searchMessage"
    );
  }
}

function* createMessage({ payload }) {
  try {

    let query = `
      mutation createFsmDriverMessage ($message: String!, $driverId: Int!) {
          createFsmDriverMessage (message: $message, driverId: $driverId) {
              id
              message
              date
              read
              driverTo {
                name
                companyId
                driverId
                unitId
                unit {
                    id
                    label
                    typeId
                    type
                }
                username
              }
            }
        }
      `;



    const response = yield call(
      clientMutation,
      query,
      payload,
      endpoints.GRAPHQL_GENERAL,
    );


    yield put(createMessageResponse({ create: response, error: false }));
  } catch (exc) {
    yield put(createMessageResponse({ create: null, error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "createMessage"
    );
  }
}

function* createMessageAll({ payload }) {
  try {

    const { message } = payload;
    let query = `
      mutation createFsmDriverAllMessage ($message: String!, $drivers: [Int]) {
        createFsmDriverAllMessage (message: $message, drivers: $drivers) {
              id
              message
              date
              read
              driverTo {
                name
                companyId
                driverId
                unitId
                unit {
                    id
                    label
                    typeId
                    type
                }
                username
              }
            }
        }
      `;


    const response = yield call(
      clientMutation,
      query,
      message,
      endpoints.GRAPHQL_GENERAL,
    );


    yield put(createMessageAllResponse({ create: response, error: false }));
  } catch (exc) {
    yield put(createMessageAllResponse({ create: null, error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "createMessage"
    );
  }
}

function* updateAllowDriverFsm({ payload }) {
  try {

    let query = `
          mutation updateCompdataFsmAll ($action: Int!) {
            updateCompdataFsmAll (action: $action)
        }
      `;



    const response = yield call(
      clientMutation,
      query,
      payload,
      endpoints.GRAPHQL_GENERAL,
    );


    yield put(updateAllowDriverToDriverResponse({}));
  } catch (exc) {
    yield put(updateAllowDriverToDriverResponse({}));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "createMessage"
    );
  }
}

function* watchMethods() {
  yield takeEvery(FSM_SETTINGS_MESSAGE_SEARCH, searchMessage);
  yield takeEvery(FSM_SETTINGS_MESSAGE_CREATE, createMessage);
  yield takeEvery(FSM_SETTINGS_MESSAGE_CREATE_ALL, createMessageAll);
  yield takeEvery(FSM_SETTINGS_MESSAGE_GET_DRIVER_TO_DRIVER, getAllowDriverFsm);
  yield takeEvery(FSM_SETTINGS_MESSAGE_UPDATE_COMPDATA_FSM_ALL, updateAllowDriverFsm);
  
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}