export default {
    entityTagPicker_units: "Unidades",
    entityTagPicker_drivers: "Conductores",
    entityTagPicker_landmarks: "Puntos de referencia",
    entityTagPicker_geofences: "Geocercas",
    entityTagPicker_forms: "Formularios",
    entityTagPicker_users: "Usuarios",
    entityTagPicker_all: "Todo",
    entityTagPicker_allUnit: "Todas las Unidades",
    entityTagPicker_allDriver: "Todas los Conductores",
    entityTagPicker_allLandmark: "Todas los Puntos de referencia",
    entityTagPicker_allGeofence: "Todas las Geocercas",
    entityTagPicker_allForm: "Todas los Formularios",
    entityTagPicker_allUser: "Todas los Usuarios",
    entityTagPicker_singleUnit: "Por Unidad",
    entityTagPicker_singleDriver: "Por Conductores",
    entityTagPicker_singleLandmark: "Por Puntos de referencia",
    entityTagPicker_singleGeofence: "Por Geocercas",
    entityTagPicker_singleForm: "Por Formularios",
    entityTagPicker_singleUser: "Por Usuarios",
    entityTagPicker_single: "Individual",
    entityTagPicker_tags: "Etiquetas",
    entityTagPicker_allUnitSelected: "Todas las unidades están seleccionadas",
    entityTagPicker_allDriverSelected: "Todos los conductores están seleccionados",
    entityTagPicker_allLandmarkSelected: "Todos los puntos de referencia están seleccionados",
    entityTagPicker_allGeofenceSelected: "Todas las geocercas están seleccionadas",
    entityTagPicker_allFormSelected: "Todos los formularios están seleccionados",
    entityTagPicker_allUserSelected: "Todos los usuarios están seleccionados",
    entityTagPicker_searchTag: "Seleccionar etiquetas o buscar",
    entityTagPicker_searchUnit: "Seleccionar unidades o buscar por nombre",
    entityTagPicker_searchDriver: "Seleccionar conductores o buscar por nombre",
    entityTagPicker_searchLandmark: "Seleccionar puntos de referencia o buscar por nombre",
    entityTagPicker_searchGeofence: "Seleccionar geocercas o buscar por nombre",
    entityTagPicker_searchForm: "Seleccionar formularios o buscar por nombre",
    entityTagPicker_searchUser: "Seleccionar usuarios o buscar por nombre",
    entityTagPicker_noItemsFound: "No se encontraron elementos",
    entityTagPicker_inactive: "Inactivo",
    entityTagPicker_active: "Activo",
    entityTagPicker_yesOption: "Si",
    entityTagPicker_notOption: "No",
    entityTagPicker_includeInactiveDrivers: "Incluir conductores inactivos",
    entityTagPicker_includeUnassignedDrivers: "Incluir conductores no asignados",
};
