export default {
    eta_title_edit: "Edit Eta link",
    eta_title_create: "Create Eta link",
    eta_title_creation_scheduled: "The eta link has been scheduled",
    eta_title_edit_scheduled: "The eta link has been edited and scheduled",
    eta_title_date_error: "The start date cannot be greater than the expiration date",
    eta_title_date_less_error: "The start date is less than the end date",
    eta_title_limit_end_date: "The end date cannot exceed one week from the start date",
    eta_title_error_current_date: "The start date must not be less than the current date",
    eta_title_message: "ETA Link - Active Period",
    eta_message: "The ETA link will not available during a time outside of the following start-end period",
    eta_notes: "Notes",
    eta_message_notes: "The following note will be sent to selected recipients with the ETA link",
}