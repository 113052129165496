// Merge Views
import mainAlertsView from 'Modules/alerts/views/main/lan/es';
import alertsTabView from 'Modules/alerts/views/main/components//alertsTab/lan/es';
import wizardAlert from 'Modules/alerts/components/wizardAlert/lan/es';
import inboxTab from 'Modules/alerts/views/main/components/inboxTab/lan/es';

let locales = {
  alertEvent_Asleep: 'Dormido',
  alertEvent_NoDriver: 'Cámara obstruida',
  alertEvent_HeadwayMonitoringWarning: 'Aviso de proximidad',
  alertEvent_ForwardCollisionWarning: 'Aviso de colisión',
  alertEvent_CyclistCollision: 'Alerta de Ciclista',
  alertEvent_DayDreaming: 'Cansancio',
  alertEvent_Distracted: 'Distraído',
  alertEvent_Drowsy: 'Adormecido',
  alertEvent_HardBrake: 'Freno brusco',
  alertEvent_HardTurn: 'Giro brusco',
  alertEvent_IdleNoDriver: 'Ralenti sin conductor',
  alertEvent_LaneDeparture: 'Cambio de carril',
  alertEvent_NoSeatbelt: 'Sin cinturón de seguridad',
  alertEvent_PassengersInside: 'Detección de Pasajero',
  alertEvent_PassengersNoSeatbelt: 'Pasajero sin cinturón',
  alertEvent_PedestrianCollision: 'Detección de peatones',
  alertEvent_Speeding: 'Limite de velocidad',
  alertEvent_PotentialIncident: 'Posible incidente',
  alertEvent_PowerOff: 'Fuente de poder apagada',
  alertEvent_RapidAcceleration: 'Acceleracion brusca',
  alertEvent_Smoking: 'Fumando',
  alertEvent_OverMaxSpeed: 'Exceso de Velocidad',
  alertEvent_StopSignViolation: 'Violación de Parada',
  alertEvent_SuddenStop: 'Frenado Brusco',
  alertEvent_HeadwayMonitoringEmergency: 'Emergencia de Proximidad',
  alertEvent_UsingPhone: 'Usando el teléfono',
  alertEvent_Yawn: 'Bostezo',
  alertEvent_Crash: 'Colisión',
  alertEvent_Manual: 'Manual'

};

locales = {
  ...locales,
  ...mainAlertsView,
  ...alertsTabView,
  ...wizardAlert,
  ...inboxTab
};

export default locales;