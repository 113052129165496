import React from 'react';

import { ColorsToRoute } from 'Components/Map/providers/enums';
import { useSelector } from 'react-redux';

/*********************************************************
 * Module:  Realtime Maps
 * Class associated to the speed override legends
 ********************************************************/
const ColorBox = ({ color, number }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <div
        style={{
          backgroundColor: color,
          width: "20px",
          height: "20px",
          marginRight: "10px",
          border: "2px solid #fff",
        }}
      ></div>
      <div
        style={{
            border: "2px solid rgb(199 199 199)",
            borderRadius: "50%",
            width: "24px",
            height: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#000",
            backgroundColor: "#FFF",
            fontSize: "14px",
        }}
      >{number}</div>
    </div>
);

const SpeedOverrideMap = () => {

    const { user: { isKph }, user } = useSelector((state) => state.securityRedux);

    return(
    <div>
        {
            ColorsToRoute.map((item, number) => {
              if(item.range != 0){
                return (
                  <ColorBox key={number} color={item.color} number={ isKph ? Math.round(item.range * 1.60934) : item.range} />
                )
              }else{
                return (
                  <ColorBox key={number} color={item.color} number={"?"} />
                )
              }
              
            })
        }
    </div>
    )
};

export default SpeedOverrideMap;