export default {
    settingsVehicleEquipment_editTitle:"Edit Equipment",
    settingsVehicleEquipment_createTitle:"Create new Equipment",
    settingsVehicleEquipment_minLengthMessage:'The field must contain minimum chars: ',
    settingsVehicleEquipment_maxLengthMessage:'The field must contain maximum chars: ',
    settingsVehicleEquipment_success:'Vehicle Equipment saved',
    settingsVehicleEquipment_deleteWarning:'Are you sure you want to delete the vehicle Equipment?',
    settingsVehicleEquipment_deleteTitle:'Delete vehicle Equipment',
    settingsVehicleEquipment_deleted:'Vehicle Equipment deleted',
    settingsVehicleEquipment_search:'Search by Equipment Name',
    settingsVehicleEquipment_assignmentTitle:'Assigned vehicles',
    settingsVehicleEquipment_name:'Equipment Name',
    settingsVehicleEquipment_selectorTitle:'Assign Units',
    settingsVehicleEquipment_selected:'Selected',
    settingsVehicleEquipment_filterGroup:'Filter for group',
    settingsVehicleEquipment_searchUnits:'Search for units',
    settingsVehicleEquipment_listUnit:'List units',
    settingsVehicleEquipment_assignments:'Assignments',
    settingsVehicleEquipment_equipmentSelectorAll:'All',
};