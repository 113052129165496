import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    loadingRoutes: false,
    restart: false,
    routes: { items: [], total: 0},
    loadingRoute: false,
    filters: {
      openSearch: "",
      limit: 10,
      offset: 0
    },
    erorrRoutes: null
};

const route = "settings/views/routes";

export const routesSearchRoutes = createAsyncThunk('routes/routesTab/routesSearchRoutes', async ({ openSearch, limit = 10, offset = 0 }, { rejectWithValue, dispatch }) => {
    try {
        const conditions = []
        
        conditions.push({
            fields: ["title"],
            terms: [openSearch]
        })

        conditions.push({
            fields: ["isTemplate"],
            terms: [1]
        })

        const sort = {
            field: "title",
            order: "ASC"
        };

        const response = await clientQuery(
            `
            query searchRoutes (
                $conditions: [RouteSearchInput],
                $sort: [RouteSortInput], 
                $limit: Int, 
                $offset: Int
              ) {
                Routes: searchRoutes (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                  items {
                    id
                    title
                    color
                    pointsCount
                    startPoint
                    endPoint
                  }
                  total
                }
              }
            `,
            {
                conditions: conditions,
                sort, 
                limit, 
                offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.Routes || { items: [], total: 0 }; 

    } catch (exc) {
        ExceptionManager(exc, route, 'routesSearchRoutes');
        return rejectWithValue(exc)
    }
});


export const RoutesTasksRedux = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        }
    },
    extraReducers: (builder) => {

        builder.addCase(routesSearchRoutes.pending, (state, action) => {
            state.loadingRoutes = true

        });
        builder.addCase(routesSearchRoutes.fulfilled, (state, action) => {
            state.routes = action?.payload || [];
            state.loadingRoutes = false;

        } );
        builder.addCase(routesSearchRoutes.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loadingRoutes = false;

        });

        
    }
})

const { actions, reducer } = RoutesTasksRedux;

export const { reset } = actions;

export default reducer;