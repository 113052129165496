import {
    DASHBOARD_PANEL_SEARCH,
    DASHBOARD_PANEL_SEARCH_RESPONSE,
    DASHBOARD_PANEL_OPEN_CREATE_EDIT_MODAL,
    DASHBOARD_PANEL_SIZE_CREATE_EDIT_MODAL,
    DASHBOARD_PANEL_CREATE,
    DASHBOARD_PANEL_CREATE_RESPONSE,
    DASHBOARD_PANEL_UPDATE,
    DASHBOARD_PANEL_UPDATE_RESPONSE,
    DASHBOARD_PANEL_DELETE,
    DASHBOARD_PANEL_DELETE_RESPONSE,
    DASHBOARD_PANEL_OPEN_CREATE,
    DASHBOARD_PANEL_CREATE_GADGET,
    DASHBOARD_PANEL_CREATE_GADGET_RESPONSE,
    DASHBOARD_PANEL_DELETE_GADGET,
    DASHBOARD_PANEL_DELETE_GADGET_RESPONSE,
    DASHBOARD_PANEL_UPDATE_GADGET,
    DASHBOARD_PANEL_UPDATE_GADGET_RESPONSE,
    DASHBOARD_PANEL_UPDATE_POSITION_GADGET,
    DASHBOARD_PANEL_UPDATE_POSITION_GADGET_RESPONSE,
    DASHBOARD_PANEL_UNIT,
    DASHBOARD_PANEL_UNIT_RESPONSE,
    DASHBOARD_PANEL_ERROR_USER_GADGET_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loadingDashboard: false,
    loadingCreateEdit: false,
    loadingCreate: false,
    dashboard: [],
    dashboardResponse: null,
    unitDashboard: [],
    errorUnitDashboard: false,
    size_modal: 'small',
    dashboardEdit: {},
    gadGet: {},
    createUserGadget: {},
    openCreateEdit: false,
    openCreate: false,
    deleteResponse: null,
    deleteDashboardResponse: null,
    updateResponse: null,
    updateResponseDashboard: null,
    updatePositionResponse: null,
    createEditResponse: null,
    errorUserGadget: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DASHBOARD_PANEL_SEARCH:
            return {
                ...state,
                dashboard: [],
                loadingDashboard: true
            };

        case DASHBOARD_PANEL_SEARCH_RESPONSE:
            return {
                ...state,
                dashboard: action.payload?.dashboard,
                loadingDashboard: false
            };

        case DASHBOARD_PANEL_CREATE_GADGET:
            return {
                ...state,
                loadingCreateEdit: true
            };

        case DASHBOARD_PANEL_CREATE_GADGET_RESPONSE:
            return {
                ...state,
                createUserGadget: action.payload?.createUserGadget,
                loadingCreateEdit: false
            };

        case DASHBOARD_PANEL_CREATE:
            return {
                ...state,
                dashboardResponse: null,
                loadingCreate: true
            };

        case DASHBOARD_PANEL_CREATE_RESPONSE:
            return {
                ...state,
                dashboardResponse: action.payload,
                loadingCreate: false
            };

        case DASHBOARD_PANEL_SIZE_CREATE_EDIT_MODAL:
            return {
                ...state,
                size_modal: action.payload?.size
            };

        case DASHBOARD_PANEL_DELETE:
            return {
                ...state,
                deleteDashboardResponse: null,
                loadingCreate: true
            };

        case DASHBOARD_PANEL_DELETE_RESPONSE:
            return {
                ...state,
                deleteDashboardResponse: action?.payload,
                loadingCreate: false
            };


        case DASHBOARD_PANEL_DELETE_GADGET:
            return {
                ...state,
                deleteResponse: null,
                loadingCreateEdit: true
            };

        case DASHBOARD_PANEL_DELETE_GADGET_RESPONSE:
            return {
                ...state,
                deleteResponse: action.payload?.data,
                loadingCreateEdit: false
            };

        case DASHBOARD_PANEL_UPDATE:
            return {
                ...state,
                updateResponseDashboard: null
            };

        case DASHBOARD_PANEL_UPDATE_RESPONSE:
            return {
                ...state,
                updateResponseDashboard: action.payload?.data
            };


        case DASHBOARD_PANEL_UPDATE_GADGET:
            return {
                ...state,
                updateResponse: null
            };

        case DASHBOARD_PANEL_UPDATE_GADGET_RESPONSE:
            return {
                ...state,
                updateResponse: action.payload?.data,
                loadingCreateEdit: true
            };

        case DASHBOARD_PANEL_ERROR_USER_GADGET_RESPONSE:
            return {
                ...state,
                errorUserGadget: action.payload?.data,
                loadingCreateEdit: false
            };

        case DASHBOARD_PANEL_UPDATE_POSITION_GADGET:
            return {
                ...state,
                updatePositionResponse: null
            };

        case DASHBOARD_PANEL_UPDATE_POSITION_GADGET_RESPONSE:
            return {
                ...state,
                updatePositionResponse: action.payload?.data
            };

        case DASHBOARD_PANEL_OPEN_CREATE_EDIT_MODAL:
            return {
                ...state,
                openCreateEdit: action.payload?.open,
                gadGet: action.payload?.gadGet
            };

        case DASHBOARD_PANEL_OPEN_CREATE:
            return {
                ...state,
                dashboardEdit: action.payload?.dashboardEdit,
                openCreate: action.payload?.open
            };

        case DASHBOARD_PANEL_UNIT:
            return {
                ...state,
                unitDashboard: [],
                errorUnitDashboard:false
            };

        case DASHBOARD_PANEL_UNIT_RESPONSE:
            return {
                ...state,
                unitDashboard: action.payload?.unitDashboard,
                errorUnitDashboard:action.payload?.errorUnitDashboard

            };
        case CLEAR_REDUX:
            return ["", "DASHBOARD_PANEL"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : state;

        default:
            return state;
    }
};