import {
    SETTINGS_INPUT_LABEL_SEARCH,
    SETTINGS_INPUT_LABEL_SEARCH_RESPONSE,
    SETTINGS_INPUT_LABEL_OPEN_CREATE_EDIT_MODAL,
    SETTINGS_INPUT_LABEL_CREATE_EDIT_RESPONSE,
    SETTINGS_INPUT_LABEL_CREATE_EDIT,
    SETTINGS_INPUT_LABEL_OPEN_DELETE_MODAL,
    SETTINGS_INPUT_LABEL_DELETE,
    SETTINGS_INPUT_LABEL_DELETE_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loadingInputLabel: false,
    loadingCreateEdit: false,
    loadingDelete: false,
    inputLabels: [],
    inputLabel: null,
    errorInputLabel: false,
    openCreateEdit: false,
    openDelete: false,
    createEditResponse: null,
    deleteResponse: null,
    filters: {
        openSearch: ""
    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SETTINGS_INPUT_LABEL_SEARCH:
            return {
                ...state,
                inputLabels: [],
                loadingInputLabel: true,
                errorInputLabel: false,
                filters: action.payload
            };

        case SETTINGS_INPUT_LABEL_SEARCH_RESPONSE:
            return {
                ...state,
                inputLabels: action.payload?.inputLabels,
                total: action.payload?.total,
                errorInputLabel: action.payload?.error,
                loadingInputLabel: false
            };

        case SETTINGS_INPUT_LABEL_OPEN_CREATE_EDIT_MODAL:
            return {
                ...state,
                openCreateEdit: action.payload?.open,
                inputLabel: action.payload?.inputLabel
            };

        case SETTINGS_INPUT_LABEL_CREATE_EDIT:
            return {
                ...state,
                loadingCreateEdit: true,
                createEditResponse: null
            };

        case SETTINGS_INPUT_LABEL_CREATE_EDIT_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                createEditResponse: action.payload?.data,
            };



        case SETTINGS_INPUT_LABEL_OPEN_DELETE_MODAL:
            return {
                ...state,
                openDelete: action.payload?.open,
                selectedItems: action.payload?.items,
                loadingDelete: false,
                deleteResponse: null
            };

        case SETTINGS_INPUT_LABEL_DELETE:
            return {
                ...state,
                loadingDelete: true,
                deleteResponse: null
            };

        case SETTINGS_INPUT_LABEL_DELETE_RESPONSE:
            return {
                ...state,
                loadingDelete: false,
                deleteResponse: action.payload?.data,
            };

        case CLEAR_REDUX:
            return ["", "SETTINGS_INPUT_LABEL"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return { ...state };
    }
};