import {
    SETTINGS_FUEL_CARD_SEARCH,
    SETTINGS_FUEL_CARD_SEARCH_RESPONSE,
    SETTINGS_FUEL_CARD_GET,
    SETTINGS_FUEL_CARD_GET_RESPONSE,
    SETTINGS_FUEL_CARD_UPDATE,
    SETTINGS_FUEL_CARD_UPDATE_RESPONSE,
    SETTINGS_FUEL_CARD_OPEN_UPDATE,
    SETTINGS_FUEL_CARD_BULK_OPEN,
    SETTINGS_FUEL_CARD_BULK_UPDATE,
    SETTINGS_FUEL_CARD_BULK_UPDATE_RESPONSE,
    SETTINGS_FUEL_CARD_RULE,
    SETTINGS_FUEL_CARD_RULE_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loading: false,
    loadingAction: false,
    updateResponse: false,
    error: false,
    fuelCards: [],
    fuelCardSelected: null,
    idFuelCardSelected: null,
    total: 0,
    filters: {
        openSearch: "",
        unitId: 0,
        driverId: 0,
        limit: 0,
        offset: 0
    },
    openEdit: false,
    openBulk: false,
    fuelCardsSelected: [],
    ruleChange: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SETTINGS_FUEL_CARD_SEARCH:
            return {
                ...state,
                updateResponse: false,
                fuelCardSelected: null,
                fuelCards: [],
                total: 0,
                error: false,
                loading: true,
                filters: action.payload
            }; 

        case SETTINGS_FUEL_CARD_SEARCH_RESPONSE:
            return  {
                ...state,
                fuelCards: action.payload?.items,
                total: action.payload?.total,
                error: action.payload?.error,
                loading: false
            }; 
        
        case SETTINGS_FUEL_CARD_GET:
            return {
                ...state,
                fuelCardSelected: null,
                loadingAction: true
            };

        case SETTINGS_FUEL_CARD_GET_RESPONSE:
            return {
                ...state,
                fuelCardSelected: action.payload?.data,
                loadingAction: false
            };

        case SETTINGS_FUEL_CARD_UPDATE:
            return {
                ...state,
                loadingAction: true,
                updateResponse: false
            };

        case SETTINGS_FUEL_CARD_UPDATE_RESPONSE:
            return {
                ...state,
                loadingAction: false,
                updateResponse: action.payload?.update,
                error: action.payload?.error
            };

        case SETTINGS_FUEL_CARD_OPEN_UPDATE:
            return {
                ...state,
                openEdit: action.payload?.open,
                idFuelCardSelected: action.payload?.id
            };

        case SETTINGS_FUEL_CARD_BULK_OPEN:
            return {
                ...state,
                openBulk: action.payload?.open,
                fuelCardsSelected: action.payload?.items
            };
        
        case SETTINGS_FUEL_CARD_BULK_UPDATE:
            return {
                ...state,
                loadingAction: true,
                updateResponse: false,
                error: false
            };
            
        case SETTINGS_FUEL_CARD_BULK_UPDATE_RESPONSE:
            return {
                ...state,
                loadingAction: false,
                updateResponse: action.payload?.update,
                error: action.payload?.error
            };

        case SETTINGS_FUEL_CARD_RULE:
            return {
                ...state,
                loading: true,
                ruleChange: null,
                error: false
            };

        case SETTINGS_FUEL_CARD_RULE_RESPONSE:
            return {
                ...state,
                loading: false,
                ruleChange: action.payload?.rule,
                error: action.payload?.error
            };    

        case CLEAR_REDUX:
            return ["", "SETTINGS_FUEL_CARD"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return state;
    }
};