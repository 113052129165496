import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';

// Actions
import {
  reportsMainGenerateReportProgress
} from 'Redux/actions';

import {
  getTimeZone,
  handleAsyncReport,
  getCategoryFilter,
  generateReportInstance,
  baseReportConditions,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getDiagnosticParametersReport(report, page, rowsPerPage, sortField, sortOrder) {
  const { filterType, filterArray, startRange, endRange, otherFilters, unitsSelected, filterEntity } = report.filters;
  let minutes = otherFilters.minute ? otherFilters.minute * 60 : 0;
  let { checkIdleProductive } = otherFilters;
  const { timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath } = report;
  const baseConditionsQuery = baseReportConditions({ report, name, dataResultPath, timeZone, delayed, unitsSelected, timeframeName , filterType, filterArray, categoryName, startRange, endRange})

  const addColumnLabel = (report?.exportType === 'csv') ?
                        [{
                          "column": "idName",
                          "header": "Label"
                        }]: [];
  let payload = {
    conditions: {
      ...baseConditionsQuery,
      sortField: {
        field: sortField || "deviceId",
        order: sortOrder === 'desc' ? 'DESC' : 'ASC'
      }
    },
    offset: page,
    limit: rowsPerPage
  }
  if (report.export) {
     payload = {
      conditions: {
        ...payload.conditions,
        exportOptions: {
          sendByEmail,
          emailsTo,
          culture: report.lang,
          timeZone: getTimeZone(timeZone),
          format: {
            fileFormat: report?.exportType  || "json",
            formatOptions: "{\"orientation\": \"landscape\"}"
          },
          folderName: "px-customer-reports",
          filePrefixName: "diagnosticParametersReport",
          columns: [
            ...addColumnLabel,
            {
              "column": "date",
              "header": "Time & Date"
            },
            {
              "column": "eventName",
              "header": "Event"
            },
            {
              "column": "batteryVoltage",
              "header": "Battery Voltage (V)"
            },
            {
              "column": "coolantTemp",
              "header": "Coolant Temp (F)"
            },
            {
              "column": "engineSpeed",
              "header": "Engine RPM"
            },
            {
              "column": "fuelPercent",
              "header": "Fuel Level (%)"
            },
            {
              "column": "fuelRemain",
              "header": "Fuel Level Remaining (gal)"
            },
            {
              "column": "fuelRate",
              "header": "Fuel Rate (gal/hr)"
            },
            {
              "column": "odometer",
              "header": "Odometer"
            },
            {
              "column": "throttlePosition",
              "header": "Throttle Position (%)"
            },
            {
              "column": "tripFuel",
              "header": "Calculated Fuel Usage (gal)"
            },
            {
              "column": "tripOdometer",
              "header": "Trip Odometer"
            },
            {
              "column": "vehicleSpeedUnit",
              "header": "Vehicle Speed"
            }
          ]
        }
      },
      limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
      offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1
    }
  }
  try {
    let data = yield call(
      clientQuery,
      `query($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getDiagnosticParametersReport(conditions: $conditions, limit: $limit, offset: $offset) {
          ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?  

            `reportInstanceId`
          :`
            total
            limit
            offset
            category
            items {
              id
              idLabel
              dates
              noData
            }
          `}
        }
      }`,
      payload,
      endpoints.GRAPHQL_PX_REPORTING,
    );

    yield handleAsyncReport(report, data)
  } catch (exc) {
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
  }
}


export default getDiagnosticParametersReport;
