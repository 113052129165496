import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// controls
import Table, { Column } from '../../table'

import {
  formatDatetime,
  formatTimeDelta,
  getCategoryFilterType,
  getEntityType,
  useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';


// hooks
import { useSidePanelMap, useTableReport } from 'Core/hooks';
// actions
import {
  reportsMainGenerateReportProgress,
  reportsMainGenerateEvents
} from 'Redux/actions';

import './temperatureReport.scss';

import AddressLandmark from 'Modules/reports/views/main/components/reportsTab/.components/addressLandmark'
const TemperatureReport = (props) => {

  const { messages, report } = props;
  const dispatch = useDispatch();
  const { loading, data, filters, loadingSummary, timeZone, summary } = report;
  const { filterEntity, startRange, endRange } = filters;
  const { user } = useSelector(state => state.securityRedux);
  const exportHandler = useHandleExportReport(report);
  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
  const loadingCreateLandMark = useSelector(state => state.vehicleLandMarkRedux.loadingCreateLandMark)
  const createLandMarkResult = useSelector(state => state.vehicleLandMarkRedux.createLandMarkResult)
  const [currentId, setCurrentId] = useState(null);

  const { isKph } = user;

  const reportRef = useRef();
  useEffect(() => {
    if (report) {
      const newReport = JSON.parse(JSON.stringify(report));
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);

  useEffect(() => {
    if (!loadingCreateLandMark && createLandMarkResult) {
      dispatch(reportsMainGenerateReportUpdate(report, currentPage, currentRowsPerPage, currentNewSortField, currentNewSortOrder))
      dispatch(clearRedux("LANDMARK", { "createLandMarkResult": null }));
    }
  }, [loadingCreateLandMark, createLandMarkResult])

  const onChangeExpandable = (selected, id) => {
    if(!selected.noData){
      let selectedId = (filterEntity === "Unit" ? selected.deviceId : selected.driverId) || selected.id
      setCurrentId(selectedId)
      let currentReport = report
      delete currentReport.summary
      dispatch(reportsMainGenerateReportProgress(currentReport))
      let events = {
        filters: {
          filterType: getCategoryFilterType(filterEntity),
          filterArray: [selectedId],
          page: 1,
          rowsPerPage: 10,
          startRange: moment(startRange).startOf('day').utc().toDate(),
          endRange: moment(endRange).endOf('day').utc().toDate()
        }
      }
      dispatch(reportsMainGenerateEvents(report, events))
    }
  }

  let parametersTableEvents = useTableReport({
    data: summary?.items || [],
    customLoading: loadingSummary,
    report,
    messages,
    hideHeader: false,
    customOnChangePage: (page) => {
      let events = {
        filters: {
          filterType: getCategoryFilterType(filterEntity),
          filterArray: [currentId],
          page: page,
          rowsPerPage: 10,
          startRange: moment(startRange).startOf('day').utc(),
          endRange: moment(endRange).endOf('day').utc()
        }
      }
      dispatch(reportsMainGenerateEvents(report, events))
    },
    classNameColumns: "report-sub-events-columns",
    hideNumberOfRows: true,
    totalItems: summary?.total,
    centerPagination: true,
    serverSideTable: true,
    hideSelectorUnitsDriver: true
  });

  const renderExpandableRowSubEvents = (row) => {
    return (
      <div style={{ marginBottom: 10 }}>
        <Table
          {...parametersTableEvents}
          noData={row.noData}
        >
          <Column label={messages['generatedReport_timeAndDate']}>
            {(element) => <span>{formatDatetime(element.dateTime, "MMMM D, YYYY h:mm A", timeZone)}</span>}
          </Column>
          <Column
            label={messages['generatedReport_address']}
            field="partsCost"
            className="big-column no-border-bottom"
            noSorting
          >
            {(element) => {
              return (
                <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={element} />
              )
            }}
          </Column>
          <Column label={messages['generatedReport_zone1']}>
            {(element) => <span>{parseTemperature(element.temperature1, isKph)}</span>}
          </Column>
          <Column label={messages['generatedReport_zone2']}>
            {(element) => <span>{parseTemperature(element.temperature2, isKph)}</span>}
          </Column>
          <Column label={messages['generatedReport_zone3']}>
            {(element) => <span>{parseTemperature(element.temperature3, isKph)}</span>}
          </Column>
        </Table>
      </div>
    )
  }
  const parseTemperature = (value, kph) => {
    return value ? `${value} °${kph ? 'C' : 'F'}` : '';
  }

  let parametersTable = useTableReport({
    data: data.items,
    report,
    messages,
    hideHeader: true,
    renderExpandableRow: renderExpandableRowSubEvents,
    onChangeExpandable,
    propertyToShowExpandable: 'id',
    classNameColumns: "table-report-main-expandible"
  });

  return (
    <Fragment>
      <Table
        {...parametersTable}
        {...exportHandler}
      >
        <Column noSorting>
          {(element) => {
            const type = getEntityType(report, messages)
            return <span><strong>{type}:  </strong>{`${element.idLabel || ''}`}</span>
          }}
        </Column>
        <Column noSorting>
          {() => {
            const localTimeStart = moment.utc(startRange).local().format();
            const localTimeEnd = moment.utc(endRange).local().format();
            return (<div>
                      <span>
                        <span>
                          <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                        </span>
                      </span>
            </div>);
          }}
        </Column>
      </Table>
      <SideMapComponent hiddenButton hiddenSearchMap />
    </Fragment>
  );
};

export default TemperatureReport;
