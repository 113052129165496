export default {
    settingsInputLabel_add: "Crear nueva etiqueta",
    settingsInputLabels_createTitle:"Crear nueva etiqueta de entrada",
    settingsInputLabels_editTitle:"Editar etiqueta de entrada",
    settingsInputLabels_columTitle:"Etiqueta de entrada ",
    settingsInputLabels_minLengthMessage:'El campo debe contener un mínimo de caracteres: ',
    settingsInputLabels_maxLengthMessage:'El campo debe contener un maximo de caracteres: ',
    settingsInputLabels_success:'Etiqueta de entrada Guardada',
    settingsInputLabels_deleteWarning:'¿Está seguro de que desea eliminar la etiqueta de entrada?',
    settingsInputLabels_deleteTitle:'Eliminar etiqueta de entrada',
    settingsInputLabels_deleted:'Etiqueta de entrada eliminada',
    settingsInputLabel_search:'Buscar por etiqueta de entrada',
    settingsInputLabel_inputONLabel:'Etiqueta ON',
    settingsInputLabel_InputOffLabel:'Etiqueta OFF',
};