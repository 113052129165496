import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { v4 as uuidv4 } from 'uuid';

import { clientQuery, clientMutation } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../core/logManager';

import { resetALL } from '../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    response: null,
    dynamicForm: null,
    error: false
};

const route = 'modules/dynamicForms/modals/duplicate/redux/slice'

export const duplicateForm = createAsyncThunk('dynamicForms/modals/duplicate/duplicateForm', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query getDynamicForm ($id: String!) {
                dynamicForm: getDynamicForm (id: $id) {
                    id
                    companyId
                    name
                    formStatus
                    pages {
                        id
                        title
                        fields {
                            id 
                            type
                            label
                            readOnly
                            required
                            placeHolder
                            help
                            attributes
                        }
                    }
                    properties {
                        allowDraft
                    }
                    description
                    tagIds
                    updatedOn
                    updatedBy
                    createdBy
                    createdOn
                }
            }
            `,
            { id },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response?.dynamicForm) {
            throw `Error while getting dynamic form: ${id}`;
        }

        let unique = uuidv4();
        unique = unique?.split("-");

        let name = response?.dynamicForm?.name

        if (name?.length >= 300) {
            name = name?.slice(0, 270);
        }

        name = `${name} (copy) ${unique[0]}`;
        name = name?.slice(0, 300);

        let form = {
            ...response?.dynamicForm,
            name: name,
            formStatus: "inactive",
            properties: {
                allowDraft: response?.dynamicForm?.properties?.allowDraft
            },
            pages: response?.dynamicForm?.pages?.map(page => {
                let newPage = {
                    ...page,
                    id: uuidv4(),
                    fields: page?.fields?.map(field => {
                        let newField = {
                            ...field,
                            id: uuidv4(),
                            attributes: field.attributes ? JSON.parse(field.attributes) : {}
                        };

                        delete newField.__typename;
                        return newField;
                    })
                };

                delete newPage.__typename;
                return newPage;
            }),
            tagIds: response?.dynamicForm?.tagIds
        };

        const createResponse = await clientMutation(
            `
                mutation createDynamicForm (
                    $name: String!, 
                    $description: String, 
                    $formStatus: enumFormStates!, 
                    $properties: DynamicFormPropertiesInput, 
                    $pages: [DynamicFormPageInput]!, 
                    $tagIds: [Int]!
                ) {
                    dynamicForm: createDynamicForm (
                        name: $name, 
                        description: $description, 
                        formStatus: $formStatus, 
                        properties: $properties, 
                        pages: $pages,
                        tagIds: $tagIds
                    ) {
                        id
                    }
                }
            `,
            form,
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!createResponse?.dynamicForm?.id) {
            throw 'Error while duplicating dynamic form';
        }

        return createResponse?.dynamicForm;
    } catch (exc) {
        ExceptionManager(exc, route, 'deleteDynamicForms');
        return rejectWithValue(exc)
    }
});

//Slice
const dynamicFormsDuplicateRedux = createSlice({
    name: 'dynamicForms/duplicate',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openDuplicateModal(state, action) {
            state.open = true;
            state.dynamicForm = action?.payload;
        },
        closeDuplicateModal(state) {
            state.open = false;
            state.dynamicForm = null;
            state.response = null;
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)
        //deleteDynamicForms
        builder.addCase(duplicateForm.pending, (state, action) => {
            state.loading = true;
            state.error = false;
            state.response = null;
        });
        builder.addCase(duplicateForm.fulfilled, (state, action) => {
            state.loading = false;
            state.error = false;
            state.response = action.payload;
        });
        builder.addCase(duplicateForm.rejected, (state, action) => {
            state.loading = false;
            state.response = action.payload;
            state.error = true;
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = dynamicFormsDuplicateRedux;

// Extract and export each action creator by name
export const {
    reset,
    openDuplicateModal,
    closeDuplicateModal,
} = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;