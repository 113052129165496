export default {
  "sharedMedia_title": "Share Media",
  "sharedMedia_subtitle": "Method",
  "sharedMedia_cancel": "Cancel",
  "sharedMedia_share": "Share",
  "sharedMedia_contactsEmail": "Type a valid email or select a contact",
  "sharedMedia_email": "Email Address",
  "sharedMedia_contactsSMS": "Type a valid phone number or select a contact",
  "sharedMedia_sms": "Phone Number",
  "sharedMedia_addEmailSubscriber": "Add email",
  "sharedMedia_invalidEmail": "Must enter a valid email address",
  "sharedMedia_success": "Has been shared successfully",
  "sharedMedia_error": "An error occurred, please try again.",
  "sharedMedia_notFound": "Media not found",
  "sharedMedia_selected": "Selected media",
  "sharedMedia_noOptionsMessageEmail": "This email is already added",
  "sharedMedia_noOptionsMessageSMS": "This phone number is already added",
  "sharedMedia_addSMSSubscriber": "Add phone number",
  "sharedMedia_carrier": "Carrier",
  "sharedMedia_ratesMayApply": "* Message and Data Rates May Apply",
  "sharedMedia_text": "Text",
  "sharedMedia_email": "Email",
  "sharedMedia_send": "Send",
  "sharedMedia_choose_contact": "Choose Contact Method:",
}