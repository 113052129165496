import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

const INIT_STATE = {
    loading: false,
    open: false,
    create: null,
    edit: null,
    error: false,
    eta: null,
    loadingMarkerDetail: false,
    markerDetail: null
};

const eta = "realtimeMaps/main/eta";

export const realtimeMapsEtaCreateEtaLink = createAsyncThunk('realtimeMaps/main/eta/realtimeMapsEtaCreateEtaLink', async (payload, { rejectWithValue, dispatch }) => {
    try {

        const response = await clientMutation(
            `
            mutation createEtaLink (
                $unitId: Int!,
                $entityId: Int!
                $entityName: String!
                $landmarkId: Int,
                $address: String!,
                $latitude: Float!,
                $longitude: Float!,
                $sharedWith: SharedWithInput!
                $eta: String
                $scheduleSnapshot: Boolean
                $dateRange: AWSDateTimeRange!
                $notes: String
            ) {
                etaLink: createEtaLink(
                    unitId: $unitId,
                    entityId: $entityId, 
                    entityName: $entityName,
                    landmarkId: $landmarkId,
                    address: $address,
                    latitude: $latitude,
                    longitude: $longitude,
                    sharedWith: $sharedWith
                    eta: $eta
                    scheduleSnapshot: $scheduleSnapshot
                    dateRange: $dateRange
                    notes: $notes
                ) {
                    id
                    code
                    status
                }
            }`,
            payload,
            endpoints.GRAPHQL_GENERAL_V2
        );

        return response?.etaLink

    } catch (exc) {
        ExceptionManager(exc, eta, 'realtimeMapsEtaCreateEtaLink');
        return rejectWithValue(exc)
    }
});

export const realtimeMapsEtaEditEtaLink = createAsyncThunk('realtimeMaps/main/eta/realtimeMapsEtaEditEtaLink', async (payload, { rejectWithValue, dispatch }) => {
    try {

        const response = await clientMutation(
            `
            mutation updateEtaLink (
                $id: Int!
                $unitId: Int!,
                $landmarkId: Int,
                $address: String!,
                $latitude: Float!,
                $longitude: Float!,
                $sharedWith: SharedWithInput!
                $eta: String
                $scheduleSnapshot: Boolean
                $dateRange: AWSDateTimeRange!
                $notes: String
            ) {
                etaLink: updateEtaLink(
                    id: $id,
                    unitId: $unitId, 
                    landmarkId: $landmarkId,
                    address: $address,
                    latitude: $latitude,
                    longitude: $longitude,
                    sharedWith: $sharedWith
                    eta: $eta
                    scheduleSnapshot: $scheduleSnapshot
                    dateRange: $dateRange
                    notes: $notes
                ) {
                    id
                    companyId
                    code
                    eta
                    unitId
                    address
                    unit {
                        id
                        label
                    }
                    status
                    address
                    latitude
                    longitude
                    expirationDate
                    sharedWith {
                        contacts
                        emails
                        sms
                    }
                    isKph
                    expirationDate
                    startDate
                    scheduleSnapshot
                }
            }`,
            payload,
            endpoints.GRAPHQL_GENERAL_V2
        );

        return response?.etaLink

    } catch (exc) {
        ExceptionManager(exc, eta, 'realtimeMapsEtaCreateEtaLink');
        return rejectWithValue(exc)
    }
});

export const realtimeMapsEtaGetMarkerDetail = createAsyncThunk('realtimeMaps/main/eta/realtimeMapsEtaGetMarkerDetail', async (payload, { rejectWithValue, dispatch }) => {
    try {

        const response = await clientMutation(
            `query ($type: MarkerType!, $id: String!) {
                data: getMarkerDetail(type: $type, id: $id) {
                id,
                type,
                markerDetail {
                    ... on MarkerDetailVehicle {
                        linkedDevice {
                            id
                            hasVideo
                            firmware
                            firmwareId
                            deviceModelName
                        }
                    }
                }
                }
            }`,
            {
                type: "Vehicle",
                id: payload.id
            },
            endpoints.GRAPHQL_GENERAL
        );

        return response?.data

    } catch (exc) {
        ExceptionManager(exc, eta, 'realtimeMapsEtaGetMarkerDetail');
        return rejectWithValue(exc)
    }
});

export const RealtimeMapEtaLinkCreateEditRedux = createSlice({
    name: eta,
    initialState: INIT_STATE,
    reducers: {
        etaCreateEditReset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        etaCreateEditSetField: (state, action) => {
            return { ...state, ...action.payload }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(realtimeMapsEtaCreateEtaLink.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(realtimeMapsEtaCreateEtaLink.fulfilled, (state, action) => {
            state.create = action?.payload;
            state.loading = false;
        });
        builder.addCase(realtimeMapsEtaCreateEtaLink.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });

        builder.addCase(realtimeMapsEtaEditEtaLink.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(realtimeMapsEtaEditEtaLink.fulfilled, (state, action) => {
            state.edit = action?.payload;
            state.loading = false;
        });
        builder.addCase(realtimeMapsEtaEditEtaLink.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });

        builder.addCase(realtimeMapsEtaGetMarkerDetail.pending, (state, action) => {
            state.loadingMarkerDetail = true;
        });
        builder.addCase(realtimeMapsEtaGetMarkerDetail.fulfilled, (state, action) => {
            state.markerDetail = action?.payload;
            state.loadingMarkerDetail = false;
        });
        builder.addCase(realtimeMapsEtaGetMarkerDetail.rejected, (state, action) => {
            state.loadingMarkerDetail = false;
            state.error = true;
        });

    }
})

const { actions, reducer } = RealtimeMapEtaLinkCreateEditRedux;

export const { etaCreateEditReset, etaCreateEditSetField } = actions;

export default reducer;