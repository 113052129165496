import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { Radio, RadioGroup, FormControlLabel } from '@mui/material';

import './radioGroup.scss';

export const RadioCheck = (props) => {
	return (
		<FormControlLabel
			className={`${props.className || ""}`}
			value={props.value.toString()}
			control={<Radio className="check-color check-padding" />}
			label={<span className={`${props.size ? 'label-' + props.size : ''}`}>{props.label}</span>}
		/>
	);
};

const RadioGroupComponent = (props) => {
	return (
		<Fragment>
			<RadioGroup
				className={`${props.className || ""}`}
				row={props.horizontal}
				value={props.value?.toString()}
				onChange={(event) => {
					if (props.onChange) props.onChange(event.target.value);
				}}
			>
				{props.children}
			</RadioGroup>
		</Fragment>
	);
};

export default RadioGroupComponent;

RadioGroupComponent.propTypes = {
	id: PropTypes.string.isRequired,
	value: PropTypes.any,
	onChange: PropTypes.func.isRequired,
	horizontal: PropTypes.bool,
	//children: PropTypes.element.isRequired
};

RadioCheck.propTypes = {
	id: PropTypes.string.isRequired,
	value: PropTypes.any.isRequired,
	label: PropTypes.string,
};
