import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';

// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

// Helpers
import {
  baseReportConditions,
  generateReportInstance,
  getCategoryFilter,
  getTimeZone,
  handleAsyncReport,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';
import {ExceptionManager} from "Core/logManager";
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

function* getStartStopIdleReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
  const {timeZone, sendByEmail, emailsTo, name, delayed, timeframeName, categoryName, dataResultPath} = report;
  const {filterType, filterArray, startRange, endRange, unitsSelected, accumulateData = true, dates} = report.filters
  const baseConditionsQuery = baseReportConditions({
    report,
    name,
    dataResultPath,
    timeZone,
    delayed,
    unitsSelected,
    timeframeName,
    filterType,
    filterArray,
    categoryName,
    startRange,
    endRange,
    dates
  })
  try {

    let payload = {
      conditions: {
        ...baseConditionsQuery,
        sortField: {
          field: sortField || "deviceId",
          order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
      },
      limit: rowsPerPage,
      offset: page
    }

    if (report.export) {
      payload = {
        limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
        offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
        conditions: {
          ...payload.conditions,
          exportOptions: {
            sendByEmail,
            emailsTo: emailsTo,
            culture: report.lang,
            timeZone: getTimeZone(timeZone),
            format: {
              fileFormat: report?.exportType || "json",
              formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\", \"orientation\": \"landscape\"}",
              durationFormat: report?.durationFormat
            },
            folderName: "px-customer-reports",
            filePrefixName: "startStopIdleReport",
            columns: [
              {
                column: getCategoryFilter(filterType) !== "deviceId" ? "driverName" : "deviceLabel",
                header: getCategoryFilter(filterType) !== "deviceId" ? "Driver" : "Unit"
              },
              {
                column: getCategoryFilter(filterType) === "deviceId" ? "driverName" : "deviceLabel",
                header: getCategoryFilter(filterType) === "deviceId" ? "Driver" : "Unit"
              },
              {
                column: "eventName",
                header: "Event"
              },
              {
                column: "landmarkAddress",
                header: "Address"
              },
              {
                column: "date",
                header: "Date"
              },
              {
                column: "Time",
                header: `${report?.exportType === "csv" ? `Time (${getAbbvTimezone(timeZone)})` : `Time`}`
              },
              {
                column: "duration",
                header: "Event Duration"
              }, {
                column: "distance",
                header: "Distance"
              }
            ]
          }
        }
      }
    }
    let data = yield call(
        clientQuery,
        `query($conditions: ReportConditionsInputRequired, $limit: Int, $offset: Int) {
        Report: getStartStopIdleReport(conditions: $conditions, limit: $limit, offset: $offset) {
          ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ? `
          reportInstanceId
          ` : `
          total
          limit
          offset
          category
          items {
            recordInfo {
              entityId
              entityLabel
              tagId
              tagLabel
              startDate
              endDate
              recordType
              groupLabel
              groupIndex
              groupTotal
              groupTagColor
            }
            recordDetail {
              id
              engineOn
              engineOff
              travelTime
              idleTime
              distance
              maxSpeed
              maxEcuSpeed
              startRowId
              RowIdStart
              endRowId
              RowIdEnd
              averageSpeed
              averageEcuSpeed
              unitId
              startTimeUTC
              startTime
              endTimeUTC
              endTime
              trips
              idLabel
              groupLabel
              isKPH
              recordType
              unitTime
              deviceLabel
              driverName
              deviceId
              endUnitTime
              driverId
              latitude
              longitude
              eventName
              duration
              address
              formattedAddress
              city
              landmarkId
              rowId
              landmarkName
              distanceSummaryTravels
              durationSummaryTravels
              noData
            }
          }`
          }
        }
        }
        `,
        payload,
        endpoints.GRAPHQL_PX_REPORTING,
    );
    yield handleAsyncReport(report, data, accumulateData);
  } catch (exc) {
    ExceptionManager(exc, 'reports/getStartStopIdleReport', 'getStartStopIdleReport');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
    if (!requestFlag) {
      let flag = true
      yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
    } else {
      report.loading = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  }
}

export default getStartStopIdleReport;
