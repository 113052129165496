import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';
// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

import {
    baseReportConditions,
    generateReportInstance, getCategoryFilter,
    getTimeZone,
    handleAsyncReport,
    isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getStateMileageCrossingReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
    const {timeZone, sendByEmail, emailsTo, timeframeName, categoryName, delayed, dataResultPath} = report;
    const {filterType, filterArray, startRange, endRange, otherFilters, unitsSelected,  accumulateData = true, dates} = report.filters
    const baseConditionsQuery = baseReportConditions({
        report,
        name: 'State Mileage Crossing',
        dataResultPath,
        timeZone,
        delayed,
        unitsSelected,
        timeframeName,
        filterType,
        filterArray,
        categoryName,
        startRange,
        endRange,
        dates
    })
    try {
        let payload = {
            conditions: {
                ...baseConditionsQuery,
                sortField: {
                    field: sortField || "deviceId",
                    order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                }
            },
            limit: rowsPerPage,
            offset: page
        };

        let addColumnDriver = [];
        if(report?.exportType === 'csv') {
            addColumnDriver.push(... [{
                column: getCategoryFilter(filterType) === "deviceId" ? "deviceLabel" : "driverName",
                header: getCategoryFilter(filterType) === "deviceId" ? "Unit" : "Driver"
            }, {
                column: getCategoryFilter(filterType) === "deviceId" ? "driverName" : "deviceLabel",
                header: getCategoryFilter(filterType) === "deviceId" ? "Driver" : "Unit"
            }])
        }else {
            addColumnDriver.push({
                column: getCategoryFilter(filterType) === "deviceId" ? "driverName" : "deviceLabel",
                header: getCategoryFilter(filterType) === "deviceId" ? "Driver" : "Unit"
              })
        }

        payload = {
            ...payload,
            limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
            offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
            conditions: {
                ...payload.conditions,
                exportOptions: {
                    sendByEmail,
                    emailsTo,
                    culture: report.lang,
                    timeZone: getTimeZone(timeZone),
                    format: {
                        fileFormat: report?.exportType || "json",
                        formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}"
                    },
                    folderName: "px-customer-reports",
                    filePrefixName: "stateMileageCrossingReport",
                    columns: [
                        ...addColumnDriver,
                          {
                            column: "state",
                            header: "State"
                          },
                          {
                            column: "event",
                            header: "Event"
                          },
                          {
                            column: "address",
                            header: "Address"
                          },
                          {
                            column: "unitTime",
                            header: "DateTime"
                          },
                          {
                            column: "distance",
                            header: "Distance"
                          },
                          {
                            column: "odometer",
                            header: "Odometer"
                          }
                    ]
                }
            }
        }
        let data = yield call(
            clientQuery,
            `query getStateMileageCrossingReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int, $recordsPerUnitLimit: Int) {
                Report: getStateMileageCrossingReport (conditions: $conditions, limit: $limit, offset: $offset, recordsPerUnitLimit: $recordsPerUnitLimit) {
${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
                `reportInstanceId`
                : `
                items {
                    recordInfo {
                        entityId
                        entityLabel
                        tagId
                        tagLabel
                        startDate
                        endDate
                        recordType
                        groupLabel
                        groupIndex
                        groupTotal
                        groupTagColor
                    }
                    recordDetail {
                        event
                        accountId
                        address
                        deviceId
                        idLabel
                        deviceLabel
                        distance
                        driverId
                        driverName
                        odometer
                        landmarkName
                        landmarkId
                        latitude
                        longitude
                        state
                        stateCrossings
                        stateTotalDistance
                        unitId
                        unitTime
                        unitTimeSorting
                        id
                        isKPH
                        noData
                    }
                }
                total
                limit
                offset
                category
                reportInstanceId
                `}
            }
        }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
        yield handleAsyncReport(report, data, accumulateData);
    } catch (exc) {
        ExceptionManager(exc, 'reports/getStateMileageCrossingReport', 'getStateMileageCrossingReport');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}

export default getStateMileageCrossingReport;
