import React, { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';

//local components
import Checkbox from 'Components/Checkbox'
import Button from 'Components/Button'
import { NotificationManager } from 'Components/Notifications';

//child components
import ExtraOptions from '../../ExtraOptions'

import { useInfiniteScroll } from 'Core/hooks'

const extraOptionsContainer = (props) => {

    //*** for context menu constants and alert */
    const { messages } = props.intl;
    //*** for context menu constants and alert */

    const containerRef = useRef()

    const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems,containerRef);

    const [ optionsShown, setOptionsShown ] = useState(25)
    const [ checkAll, setCheckAll ] = useState(false)

    function fetchMoreListItems() {
      setTimeout(() => {
        setIsFetching(false);
        setOptionsShown(+optionsShown+25)
      }, 1000);
    }
    
    return(
        <React.Fragment>

            <div style={{ width:394, padding:"5px 20px" }} >
                                
                <div style={{height:240, overflow:"scroll"}} ref={containerRef} >  
                
                
                <br/>
                <div style={{marginLeft:18}}>
                    <Checkbox						
                        label={messages["select_all"]}
                        size="sm"
                        id={`${Math.floor(Math.random() * 100000)}-${"select all"}`}
                        value={checkAll}
                        onChange={ value => {
                            setCheckAll(value)
                            if(value){
                            let newSelected = {}
                            props.options.map( elem => {
                                newSelected = { ...newSelected, [ elem[props.valueField] ]:props.extraOptions } 
                            })                              
                            props.setState({ selectedOptions:{ ...newSelected } })
                            }else{
                            props.setState({ selectedOptions:{} })
                            }
                        }}
                    />
                </div>
                
                <br/>                      
                    {props.options.
                    filter( data => !props.filterText && true || data[props.textField]?.toLowerCase().includes(props.filterText.toLowerCase())  )
                    .slice(0, optionsShown)
                    .map(
                        (option, key )=> <ExtraOptions 
                            key={`${Math.floor(Math.random() * 100000)}`+ key}
                            label={option[props.textField]}
                            selectProps={{
                                extraOptions:props.extraOptions,
                                extraOptionstextField:props.extraOptionstextField,
                                extraOptionsvalueField:props.extraOptionsvalueField
                            }}
                            onChange={ value => {
                                
                                props.setState({selectedOptions:value})                                      
                                
                            }}
                            value={option[props.valueField]}
                            selectedOptions={props.selectedOptions}
                            
                        />
                    )}
                    {isFetching && messages["fetching_more_list_items"]}
                </div>
                <br/>
                <div style={{display:"flex", justifyContent:"center"}} >
                    <Button className="btn-blue" style={{width:"100%"}}  
                    onClick={ (e)=> {

                        e.preventDefault()

                        const keys = Object.keys(props.selectedOptions)

                        if( keys.length == 0 ){
                            return  NotificationManager.warning(
                            messages['must_select_at_least_one_option'],
                            messages['visibility_validation_title'],
                            3000,
                            null,
                            null,
                            'warning',
                            null
                            );
                        }

                        let selectedOptions = []

                        keys.map( key => {
                            
                            const data = {
                                ...props.options.filter( find => find[props.valueField] == key )[0],
                                options:props.selectedOptions[key]
                            }                                  

                            selectedOptions.push(data)                                   

                        })

                       props.onChange(selectedOptions)

                        //this.setState({open:false,selectedOptions:{}})

                        props.setState({open:false})
                        
                    }} >
                        { props.buttonLabel }
                    </Button>
                </div>
            </div>

        </React.Fragment>
    );
}


export default injectIntl(extraOptionsContainer);