import { Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import VidFleetVideo from "Modules/.common/components/VidFleetVideo";
import { useDispatch, useSelector } from "react-redux";
import { getTimeZoneUnit } from "./helpers/createRailsEvents";
import {
  dateTimeZoneToUTC,
  checkDeviceData
} from 'Core/data/Helpers';
import moment from "moment";
import CameraSignalStatus from "../VidFleetVideo/.components/cameraSignalStatus";

const LiveStreamClip = ({ 
  currentClip, 
  startDate, 
  endDate,
  unitsList,
  selectedTime
}) => {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const vidFleetRequestVideoRef = useRef();
  const [handleStopLiveClip, setHandleStopLiveClip] = useState(false)

  const getStatus = useSelector(state => state.vidFleetVideoRedux.response)

  useEffect(() => {
    const handler = setTimeout(() => {
      // handleLiveClip()
      setHandleStopLiveClip(false)
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [selectedTime, currentClip?.deviceId])

  useEffect(() => {
    if(getStatus[currentClip?.esn] && getStatus[currentClip?.esn]?.status !== "stopped" && !handleStopLiveClip){
      vidFleetRequestVideoRef?.current?.startStopLiveStreaming("stop");
      setHandleStopLiveClip(true)
    }
  }, [startDate, endDate])

  const handleLiveClip = () => {
    if (selectedTime) {
      const { deviceId } = currentClip;
      let timezoneUnit = getTimeZoneUnit(unitsList, deviceId);

      let _start = dateTimeZoneToUTC(selectedTime, timezoneUnit?.linkedUnit?.timeZone?.name);
      let _end = dateTimeZoneToUTC(moment(selectedTime).add(1, 'minute'), timezoneUnit?.linkedUnit?.timeZone?.name);

      vidFleetRequestVideoRef?.current?.startStopLiveStreaming("start", { start: _start, end: _end });
      return
    }
  }

  if(!currentClip?.deviceId) return <></>
  let sdCard = currentClip?.sdCard ? checkDeviceData(currentClip?.sdCard) :  null

  return (
    <div style={{
      marginTop: 15,
      marginBottom: 15
    }}>
      <div>
        <hr />
      </div>
      <div style={{
        display: 'flex'
      }}>
        <Typography variant="h5">
          {messages["videoMainView_clipPreview"]}
        </Typography>
        <CameraSignalStatus
          id={currentClip?.esn}
          unitId={currentClip?.id}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          simProviderName={currentClip?.simProviderName}
          sdCard={sdCard}
        />
      </div>
      <div className="clip-streaming-video-container">
        <div style={{ width: 410, height: 290 }}>
          <VidFleetVideo
            ref={vidFleetRequestVideoRef}
            unitId={currentClip?.deviceId}
            autoplay={false}
            onClickPlay={() => {
              const { deviceId } = currentClip;
              let timezoneUnit = getTimeZoneUnit(unitsList, deviceId);
              let _start = dateTimeZoneToUTC(startDate, timezoneUnit?.linkedUnit?.timeZone?.name);
              let _end = dateTimeZoneToUTC(endDate, timezoneUnit?.linkedUnit?.timeZone?.name);
              vidFleetRequestVideoRef?.current?.startStopLiveStreaming("start", { start: _start, end: _end });
              setHandleStopLiveClip(false)
            }}
            messages={messages}
            mode="video"
            disableDownload
            type="clip-streaming"
            id={currentClip?.esn}
            deviceModel={currentClip?.unitVersionProduct}
            simProviderName=""
          />
        </div>
      </div>

    </div>
  );
};

export default LiveStreamClip;
