import {
    SETTINGS_VEHICLE_STATUS_SEARCH,
    SETTINGS_VEHICLE_STATUS_SEARCH_RESPONSE,
    SETTINGS_VEHICLE_STATUS_CREATE_EDIT,
    SETTINGS_VEHICLE_STATUS_CREATE_EDIT_RESPONSE,
    SETTINGS_VEHICLE_STATUS_ICON_GET,
    SETTINGS_VEHICLE_STATUS_ICON_GET_RESPONSE,
    SETTINGS_VEHICLE_STATUS_OPEN_CREATE_EDIT_MODAL,
    SETTINGS_VEHICLE_STATUS_OPEN_DELETE_MODAL,
    SETTINGS_VEHICLE_STATUS_DELETE,
    SETTINGS_VEHICLE_STATUS_DELETE_RESPONSE
} from "Redux/actionTypes";

export const searchSettingsVehicleStatus = (data) => {
    return {
        type: SETTINGS_VEHICLE_STATUS_SEARCH,
        payload: data,
    };
};

export const searchSettingsVehicleStatusResponse = (data) => {
    return {
        type: SETTINGS_VEHICLE_STATUS_SEARCH_RESPONSE,
        payload: data,
    };
};


export const openSettingsVehicleStatusCreateEditModal = (open, vehicleStatus) => {
    return {
        type: SETTINGS_VEHICLE_STATUS_OPEN_CREATE_EDIT_MODAL,
        payload: { open, vehicleStatus }

    };
};

export const createEditSettingsVehicleStatus = (vehicleStatus) => {
    return {
        type: SETTINGS_VEHICLE_STATUS_CREATE_EDIT,
        payload: { vehicleStatus },
    };
}

export const createEditSettingsVehicleStatusResponse = (data) => {
    return {
        type: SETTINGS_VEHICLE_STATUS_CREATE_EDIT_RESPONSE,
        payload: { data },
    };
};


export const getSettingsVehicleStatus = (data) => {
    return {
        type: SETTINGS_VEHICLE_STATUS_ICON_GET,
        payload: { data },
    };
};

export const getSettingsVehicleStatusResponse = (data) => {
    return {
        type: SETTINGS_VEHICLE_STATUS_ICON_GET_RESPONSE,
        payload: data,
    };
};

export const openSettingsVehicleStatusDeleteModal = (open, items = []) => {
    return {
        type: SETTINGS_VEHICLE_STATUS_OPEN_DELETE_MODAL,
        payload: { open, items },
    };
};

export const deleteSettingsVehicleStatus = (items = []) => {
    return {
        type: SETTINGS_VEHICLE_STATUS_DELETE,
        payload: { items },
    };
};

export const deleteSettingsVehicleStatusResponse = (data) => {
    return {
        type: SETTINGS_VEHICLE_STATUS_DELETE_RESPONSE,
        payload: { data },
    };
};
