import {
    MAP_SPEED_OVERRIDE_SEARCH_SPEED_OVERRIDE,
    MAP_SPEED_OVERRIDE_SEARCH_SPEED_OVERRIDE_CLEAR,
    MAP_SPEED_OVERRIDE_SEARCH_SPEED_OVERRIDE_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    speedOverrideData: { items: [], total: 0},
    loadingSpeedOverrideData: false,
    restart: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MAP_SPEED_OVERRIDE_SEARCH_SPEED_OVERRIDE:
            return {
                ...state,
                speedOverrideData: { items: [], total: 0},
                loadingSpeedOverrideData: true,
                restart: false
            };

        case MAP_SPEED_OVERRIDE_SEARCH_SPEED_OVERRIDE_CLEAR:
            return {
                ...state,
                speedOverrideData: { items: [], total: 0},
                loadingSpeedOverrideData: true,
                restart: true
            };


        case MAP_SPEED_OVERRIDE_SEARCH_SPEED_OVERRIDE_RESPONSE:
            return {
                ...state,
                speedOverrideData: { ...action.payload.response, restart: state.restart },
                loadingSpeedOverrideData: false,
                restart: false
            };

        case CLEAR_REDUX:
            return ["", "MAP_SPEED_OVERRIDE"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
            return { ...state };
    }
}