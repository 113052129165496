import {
  //TYPES
  MAP_UTILITIES_SEND_MESSAGE,
  MAP_UTILITIES_SEND_MESSAGE_RESPONSE,
} from 'Redux/actionTypes';

export const sendMessage = (data) => {
  return {
    type: MAP_UTILITIES_SEND_MESSAGE,
    payload: { data }
  };
}

export const sendMessageResponse = (data) => {
  return {
    type: MAP_UTILITIES_SEND_MESSAGE_RESPONSE,
    payload: { data }
  };
}