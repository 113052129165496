import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    open: false,
    routeId: 0,
    erorrRoutes: null
};

const route = "settings/views/routes/components/deleteRoutePoint";

export const RoutesManagerDeleteRoutePoint = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalDeleteRoutePoints: (state, action) => {
            return {
                ...state,
                open: action.payload.open,
                routeId: action.payload.routeId
            }
        }
    }
})

const { actions, reducer } = RoutesManagerDeleteRoutePoint;

export const { reset, openModalDeleteRoutePoints } = actions;

export default reducer;