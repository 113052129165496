export const SETTINGS_VEHICLE_STATUS_SEARCH = "SETTINGS_VEHICLE_STATUS_SEARCH";
export const SETTINGS_VEHICLE_STATUS_SEARCH_RESPONSE = "SETTINGS_VEHICLE_STATUS_SEARCH_RESPONSE";
export const SETTINGS_VEHICLE_STATUS_OPEN_CREATE_EDIT_MODAL = "SETTINGS_VEHICLE_STATUS_OPEN_CREATE_EDIT_MODAL";


export const SETTINGS_VEHICLE_STATUS_CREATE_EDIT = "SETTINGS_VEHICLE_STATUS_CREATE_EDIT";
export const SETTINGS_VEHICLE_STATUS_CREATE_EDIT_RESPONSE = "SETTINGS_VEHICLE_STATUS_CREATE_EDIT_RESPONSE";

export const SETTINGS_VEHICLE_STATUS_ICON_GET = "SETTINGS_VEHICLE_STATUS_ICON_GET";
export const SETTINGS_VEHICLE_STATUS_ICON_GET_RESPONSE = "SETTINGS_VEHICLE_STATUS_ICON_GET_RESPONSE";

export const SETTINGS_VEHICLE_STATUS_OPEN_DELETE_MODAL  = "SETTINGS_VEHICLE_STATUS_OPEN_DELETE_MODAL";
export const SETTINGS_VEHICLE_STATUS_DELETE             = "SETTINGS_VEHICLE_STATUS_DELETE";
export const SETTINGS_VEHICLE_STATUS_DELETE_RESPONSE    = "SETTINGS_VEHICLE_STATUS_DELETE_RESPONSE";