import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    loadingRoutes: false,
    restart: false,
    routes: { items: [], total: 0},
    loadingRoute: false,
    filters: {
      openSearch: "",
      limit: 10,
      offset: 0
    },
    erorrRoutes: null,
    searchRoutesTracker: false
};

const route = "settings/views/routesTracker";

export const routesTrackerSearchRoutes = createAsyncThunk('routes/routesTracker/routesTrackerSearchRoutes', async ({ openSearch, limit = 10, offset = 0, paramConditions = [] }, { rejectWithValue, dispatch }) => {
    try {

        let conditions = []

        conditions.push({
            fields: ["isTemplate"],
            terms: ["0"],
            exact: 1
        })

        if(openSearch){
            conditions.push({
                fields: ["title"],
                terms: [openSearch]
            })
        }

        if(paramConditions.length > 0){
            conditions = [...conditions, ...paramConditions]
        }

        const sort = {
            field: "title",
            order: "ASC"
        };

        const response = await clientQuery(
            `
            query searchRoutes (
                $conditions: [RouteSearchInput],
                $sort: [RouteSortInput], 
                $limit: Int, 
                $offset: Int
              ) {
                Routes: searchRoutes (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                  items {
                    id
                    title
                    color
                    optimized
                    avoidToll

                    isTemplate
                    templateRouteId
                    driverId
                    scheduledAt

                    distanceMeters
                    status
                    pointsCount

                    driver{
                        name
                        contactInfo
                        driverId
                    }   

                    startPoint
                    endPoint
                    stopInProgress
                    stopInProgressId
                    stopsCompletedCount
                    stopsPendingCount

                    points {
                        id
                        name
                        routeId
                        address
                        city
                        state
                        landmarkId
                        latitude
                        longitude
                        sequence
                        note
                        radius
                        status
                    }

                  }
                  total
                }
              }
            `,
            {
                conditions: conditions,
                sort, 
                limit, 
                offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.Routes || { items: [], total: 0 }; 

    } catch (exc) {
        ExceptionManager(exc, route, 'routesTrackerSearchRoutes');
        return rejectWithValue(exc)
    }
});

export const routesTrackerSearchRoutesWithFilters = createAsyncThunk('routes/routesTracker/routesTrackerSearchRoutesWithFilters', async ({ conditions, limit = 10, offset = 0 }, { rejectWithValue, dispatch }) => {
    try {
        const sort = {
            field: "title",
            order: "ASC"
        };

        const response = await clientQuery(
            `
            query searchRoutes (
                $conditions: [RouteSearchInput],
                $sort: [RouteSortInput], 
                $limit: Int, 
                $offset: Int
              ) {
                Routes: searchRoutes (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                  items {
                    id
                    title
                    color

                    isTemplate
                    templateRouteId
                    driverId
                    scheduledAt

                    distanceMeters
                    status
                    pointsCount

                    driver{
                        name
                    }   

                    startPoint
                    endPoint
                    stopInProgress
                    stopInProgressId
                    stopsCompletedCount
                    stopsPendingCount

                    points {
                        id
                        name
                        routeId
                        sequence
                        status
                    }

                  }
                  total
                }
              }
            `,
            {
                conditions: conditions,
                sort, 
                limit, 
                offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.Routes || { items: [], total: 0 }; 

    } catch (exc) {
        ExceptionManager(exc, route, 'routesTrackerSearchRoutesWithFilters');
        return rejectWithValue(exc)
    }
});


export const RoutesTrackerRedux = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        searchRoutesTrackerRefresh: (state, action) => {
            return {
                ...state,
                searchRoutesTracker: action.payload
                
            }
        },
    },
    extraReducers: (builder) => {

        builder.addCase(routesTrackerSearchRoutes.pending, (state, action) => {
            state.loadingRoutes = true;
        });
        builder.addCase(routesTrackerSearchRoutes.fulfilled, (state, action) => {
            state.routes = action?.payload;
            state.loadingRoutes = false;

        } );
        builder.addCase(routesTrackerSearchRoutes.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loadingRoutes = false;

        });

        

        builder.addCase(routesTrackerSearchRoutesWithFilters.pending, (state, action) => {
            state.loadingRoutes = true;
        });
        builder.addCase(routesTrackerSearchRoutesWithFilters.fulfilled, (state, action) => {
            state.routes = action?.payload;
            state.loadingRoutes = false;

        } );
        builder.addCase(routesTrackerSearchRoutesWithFilters.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loadingRoutes = false;

        });
        
    }
})

const { actions, reducer } = RoutesTrackerRedux;

export const { reset, searchRoutesTrackerRefresh } = actions;

export default reducer;