import {
    MAP_LANDMARKS_IMPORT_LANDMARK,
    MAP_LANDMARKS_IMPORT_LANDMARK_RESPONSE,
    MAP_LANDMARKS_IMPORT_OPEN_DETAIL,
    MAP_LANDMARKS_IMPORT_OPEN_EDIT
} from 'Redux/actionTypes';



export const importLandmark = (data) => {
    return {
        type: MAP_LANDMARKS_IMPORT_LANDMARK,
        payload: { data },
    };
  };

  export const importLandmarkResponse = (data) => {
    return {
        type: MAP_LANDMARKS_IMPORT_LANDMARK_RESPONSE,
        payload: { data },
    };
  };

  export const openDetailImportLandmark = (data) => {
    return {
        type: MAP_LANDMARKS_IMPORT_OPEN_DETAIL,
        payload: { data },
    };
  };

  export const openModalEditImportLandmark = (open,data) => {
    return {
        type: MAP_LANDMARKS_IMPORT_OPEN_EDIT,
        payload: { open,data },
    };
  };