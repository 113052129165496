import {
  ROUTES_GET_ROUTES,
  ROUTES_GET_ROUTES_RESPONSE,
  ROUTES_GET_ROUTES_AND_POINT,
  ROUTES_OPEN_CREATE_EDIT_ROUTE,
  ROUTES_OPEN_UPLOAD_ROUTE,
  ROUTES_OPEN_ADD_STOP,
  ROUTES_CREATE_UPDATE_ROUTE,
  ROUTES_CREATE_UPDATE_ROUTE_RESPONSE,
  ROUTES_ADD_STOP,
  ROUTES_ADD_STOP_RESPONSE,
  ROUTES_DELETE_ROUTE,
  ROUTES_DELETE_ROUTE_RESPONSE,
  ROUTES_DELETE_ROUTE_MULTIPLE,
  ROUTES_DELETE_ROUTE_MULTIPLE_RESPONSE,
  ROUTES_DELETE_STOP,
  ROUTES_DELETE_STOP_RESPONSE,
  ROUTES_GET_ROUTE,
  ROUTES_GET_ROUTE_RESPONSE,
  ROUTES_OPEN_DELETE_MODAL,
  ROUTES_UPDATE_STOP_ORDER,
  ROUTES_UPDATE_STOP_ORDER_RESPONSE,
  ROUTES_UPDATE_STOP,
  ROUTES_UPDATE_STOP_RESPONSE,
  CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
  loadingRoutes: false,
  restart: false,
  routes: { items: [], total: 0},
  openCreateRouteModal: false,
  openUploadRouteModal: false,
  openAddStopModal: false,
  pointToEdit: null,
  loadingCreateUpdateRoute: false,
  loadingRoute: false,
  route: null,
  loadingAddStop: false,
  addStopResponse: null,
  loadingDeleteRoute: false,
  deleteRouteResponse: null,
  loadingDeleteStop: false,
  deleteStopResponse: null,
  deleteModalData: false,
  loadingUpdateStopOrder: false,
  updateStopOrderResponse: null,
  makeUpdateStopOrder: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Routes
    case ROUTES_GET_ROUTES:
      return { ...state, routes: { items: [], total: 0}, restart: true, loadingRoutes: true };

    case ROUTES_GET_ROUTES_AND_POINT:
      return { ...state, routes: { items: [], total: 0}, restart: false, loadingRoutes: true };

    case ROUTES_GET_ROUTES_RESPONSE:
      return { ...state, routes: { ...action.payload.data, restart: state.restart }, loadingRoutes: false };
    
    case ROUTES_OPEN_CREATE_EDIT_ROUTE:
      return { ...state, route: null, openCreateRouteModal: action.payload.data };
    
    case ROUTES_OPEN_UPLOAD_ROUTE:
      return { ...state, route: null, openUploadRouteModal: action.payload.data };
    
    case ROUTES_OPEN_ADD_STOP:
      return { 
        ...state, 
        openAddStopModal: action.payload.data, 
        pointToEdit: action.payload.pointToEdit,
        deleteStopResponse: null, 
        addStopResponse: null,
        updateStopOrderResponse: null
      };

    // Create|Edit route
    case ROUTES_CREATE_UPDATE_ROUTE:
      return { ...state, route: null, loadingCreateUpdateRoute: true };

    case ROUTES_CREATE_UPDATE_ROUTE_RESPONSE:
      return { ...state, route: action.payload.data, loadingCreateUpdateRoute: false };

    // Create|Edit stop
    case ROUTES_ADD_STOP:
      return { ...state, addStopResponse: null, updateStopOrderResponse: null, loadingAddStop: true };

    case ROUTES_ADD_STOP_RESPONSE:
      return { ...state, addStopResponse: action.payload.data, loadingAddStop: false };

    // Delete route
    case ROUTES_OPEN_DELETE_MODAL:
      return { ...state, deleteModalData: action.payload.data };

    case ROUTES_DELETE_ROUTE:
      return { ...state, deleteRouteResponse: null, loadingDeleteRoute: true };

    case ROUTES_DELETE_ROUTE_RESPONSE:
      return { ...state, deleteRouteResponse: action.payload.data, loadingDeleteRoute: false };

    case ROUTES_DELETE_ROUTE_MULTIPLE:
      return { ...state, deleteRouteResponse: null, loadingDeleteRoute: true };
    
    case ROUTES_DELETE_ROUTE_MULTIPLE_RESPONSE:
      return { 
        ...state, 
        routes: { items: [], total: 0, restart: true},
        deleteRouteResponse: action.payload.data,
        loadingDeleteRoute: false 
      };

    // Delete stop
    case ROUTES_DELETE_STOP:
      return { ...state, deleteStopResponse: null, loadingDeleteStop: true };

    case ROUTES_DELETE_STOP_RESPONSE:
      return { ...state, deleteStopResponse: action.payload.data, loadingDeleteStop: false };

    case ROUTES_UPDATE_STOP_ORDER:
      return { ...state, loadingUpdateStopOrder: true };

    case ROUTES_UPDATE_STOP_ORDER_RESPONSE:
      return { ...state, loadingUpdateStopOrder: false };

    case ROUTES_UPDATE_STOP:
      return { ...state, addStopResponse: null, updateStopOrderResponse: null, loadingAddStop: true, makeUpdateStopOrder : action.payload.update };
  
    case ROUTES_UPDATE_STOP_RESPONSE:
      return { ...state, updateStopOrderResponse: state.makeUpdateStopOrder ? action.payload.data : null, loadingAddStop: false };

    // Get route
    case ROUTES_GET_ROUTE:
      return { ...state, route: null, loadingRoute: true };

    case ROUTES_GET_ROUTE_RESPONSE:
      return { ...state, route: action.payload.data, loadingRoute: false };

    case CLEAR_REDUX:
      return ["", "ROUTES_REDUX"].includes(action.payload.option)
      ? action.payload.parameters
        ? { ...state, ...action.payload.parameters }
        : { ...INIT_STATE }
      : { ...state };

    default:
      return state;
  }
}