// Action Types
export const ENTITY_SELECTOR_GET_LANDMARK_GROUPS = "ENTITY_SELECTOR_GET_LANDMARK_GROUPS";
export const ENTITY_SELECTOR_GET_LANDMARK_GROUPS_RESULT = "ENTITY_SELECTOR_GET_LANDMARK_GROUPS_RESULT";
export const ENTITY_SELECTOR_SEARCH_LANDMARKS = "ENTITY_SELECTOR_SEARCH_LANDMARKS";
export const ENTITY_SELECTOR_SEARCH_LANDMARKS_RESULT = "ENTITY_SELECTOR_SEARCH_LANDMARKS_RESULT";
export const ENTITY_SELECTOR_SET_TOTAL_PAGINATED_ITEMS = "ENTITY_SELECTOR_SET_TOTAL_PAGINATED_ITEMS";
export const ENTITY_SELECTOR_SEARCH_LANDMARKS_OVER_OFFSET = "ENTITY_SELECTOR_SEARCH_LANDMARKS_OVER_OFFSET";
export const ENTITY_SELECTOR_SEARCH_GEOFENCES = "ENTITY_SELECTOR_SEARCH_GEOFENCES";
export const ENTITY_SELECTOR_SEARCH_GEOFENCES_RESULT = "ENTITY_SELECTOR_SEARCH_GEOFENCES_RESULT";
export const ENTITY_SELECTOR_SEARCH_GEOFENCES_GROUP = "ENTITY_SELECTOR_SEARCH_GEOFENCES_GROUP";
export const ENTITY_SELECTOR_SEARCH_GEOFENCES_GROUP_RESULT = "ENTITY_SELECTOR_SEARCH_GEOFENCES_GROUP_RESULT";
export const ENTITY_SELECTOR_SEARCH_FORM_GROUP = "ENTITY_SELECTOR_SEARCH_FORM_GROUP"
export const ENTITY_SELECTOR_SEARCH_FORM_GROUP_RESULT = "ENTITY_SELECTOR_SEARCH_FORM_GROUP_RESULT"
export const ENTITY_SELECTOR_CLEAR_LANDMARKS = "ENTITY_SELECTOR_CLEAR_LANDMARKS";
