import {
    MAP_GEOFENCES_ASSIGN_OPEN_CLOSE_MODAL,
    MAP_GEOFENCES_ASSIGN_GET_UNIT_GEOFENCE,
    MAP_GEOFENCES_ASSIGN_GET_UNIT_GEOFENCE_RESPONSE,
    MAP_GEOFENCES_ASSIGN_SAVE,
    MAP_GEOFENCES_ASSIGN_SAVE_RESPONSE
} from 'Redux/actionTypes';

export const mapGeofencesOpenCloseAssignModal = (open, id, type) => ({
    type: MAP_GEOFENCES_ASSIGN_OPEN_CLOSE_MODAL,
    payload: { open, id, type },
});

export const mapGeofencesAssignGetUnitGeofence = (id, type) => ({
    type: MAP_GEOFENCES_ASSIGN_GET_UNIT_GEOFENCE,
    payload: { id, type },
});

export const mapGeofencesAssignGetUnitGeofenceResponse = (response) => ({
    type: MAP_GEOFENCES_ASSIGN_GET_UNIT_GEOFENCE_RESPONSE,
    payload: { response },
});

export const mapGeofencesAssignSave = (unitGeofence, type) => ({
    type: MAP_GEOFENCES_ASSIGN_SAVE,
    payload: { unitGeofence, type },
});

export const mapGeofencesAssignSaveResponse = (response) => ({
    type: MAP_GEOFENCES_ASSIGN_SAVE_RESPONSE,
    payload: { response },
});