import {
    SETTINGS_DRIVER_SEARCH,
    SETTINGS_DRIVER_SEARCH_RESPONSE,
    SETTINGS_DRIVER_CREATE,
    SETTINGS_DRIVER_CREATE_RESPONSE,
    SETTINGS_DRIVER_UPDATE,
    SETTINGS_DRIVER_UPDATE_RESPONSE,
    SETTINGS_DRIVER_DELETE,
    SETTINGS_DRIVER_DELETE_RESPONSE,
    SETTINGS_DRIVER_OPEN_CREATE_EDIT_MODAL,
    SETTINGS_DRIVER_OPEN_DELETE_MODAL,
    SETTINGS_DRIVER_OPEN_DRIVER_IMPORT_MODAL,
    SETTINGS_DRIVER_IMPORT,
    SETTINGS_DRIVER_IMPORT_RESPONSE,
    SETTINGS_DRIVER_LOAD_CSV,
    SETTINGS_DRIVER_LOAD_CSV_RESPONSE,
    SETTINGS_DRIVER_SEARCH_TRANSACTIONS,
    SETTINGS_DRIVER_SEARCH_TRANSACTIONS_RESPONSE,
    SETTINGS_DRIVER_OPEN_DRIVER_TRANSACTION_MODAL,
    SETTINGS_DRIVER_GET_URL_FILE,
    SETTINGS_DRIVER_GET_URL_FILE_RESPONSE,
    SETTINGS_DRIVER_OPEN_CLOSE_FILTERS,
    SETTINGS_DRIVER_SEARCH_TAGS,
    SETTINGS_DRIVER_SEARCH_TAGS_RESPONSE,
    SETTINGS_DRIVER_SET_ENTITY,
    SETTINGS_DRIVER_DELETE_DRIVERS_TAG,
    SETTINGS_DRIVER_DELETE_DRIVERS_TAG_RESPONSE,
    SETTINGS_DRIVER_OPEN_DELETE_DRIVERS_TAG_MODAL,
    SETTINGS_DRIVER_AVATAR,
    SETTINGS_DRIVER_AVATAR_RESPONSE,
    SETTINGS_DRIVER_OPEN_CHANGE_STATUS_MODAL, SETTINGS_DRIVER_CHANGE_STATUS, SETTINGS_DRIVER_CHANGE_STATUS_RESPONSE
} from "Redux/actionTypes";

export const searchDrivers = (data) => {
  return {
      type: SETTINGS_DRIVER_SEARCH,
      payload: data,
  };
};

export const searchDriversResponse = (data) => {
  return {
      type: SETTINGS_DRIVER_SEARCH_RESPONSE,
      payload: data,
  };
};

export const createDriver = (data) => {
  return {
      type: SETTINGS_DRIVER_CREATE,
      payload: data,
  };
};

export const createDriverResponse = (data) => {
  return {
      type: SETTINGS_DRIVER_CREATE_RESPONSE,
      payload: data,
  };
};

export const updateDriver = (data) => {
  return {
      type: SETTINGS_DRIVER_UPDATE,
      payload: data,
  };
};

export const updateDriverResponse = (data) => {
  return {
      type: SETTINGS_DRIVER_UPDATE_RESPONSE,
      payload: data,
  };
};

export const deleteDriver = (data) => {
  return {
      type: SETTINGS_DRIVER_DELETE,
      payload: data,
  };
};

export const deleteDriverResponse = (data) => {
  return {
      type: SETTINGS_DRIVER_DELETE_RESPONSE,
      payload: data,
  };
};

export const openDriverCreateEditModal = (open, driver) => {
  return {
      type: SETTINGS_DRIVER_OPEN_CREATE_EDIT_MODAL,
      payload: { open, driver }
  };
};

export const openDriverDeleteModal = (open, items) => {
  return {
      type: SETTINGS_DRIVER_OPEN_DELETE_MODAL,
      payload: { open, items }
  };
};

export const openDriverChangeStatusModal = (open, items) => {
  return {
      type: SETTINGS_DRIVER_OPEN_CHANGE_STATUS_MODAL,
      payload: { open, items }
  };
};

export const openDriverImportModal = (open) => {
  return {
      type: SETTINGS_DRIVER_OPEN_DRIVER_IMPORT_MODAL,
      payload: { open }
  };
};

export const openDriverTransactionModal = (open) => {
  return {
      type: SETTINGS_DRIVER_OPEN_DRIVER_TRANSACTION_MODAL,
      payload: { open }
  };
};

export const importDriver = (data) => {
  return {
      type: SETTINGS_DRIVER_IMPORT,
      payload: data,
  };
};

export const importDriverResponse = (data) => {
  return {
      type: SETTINGS_DRIVER_IMPORT_RESPONSE,
      payload: data,
  };
};

export const loadDriverCsv = (data) => {
  return {
      type: SETTINGS_DRIVER_LOAD_CSV,
      payload: data,
  };
};

export const loadDriverCsvResponse = (data) => {
  return {
      type: SETTINGS_DRIVER_LOAD_CSV_RESPONSE,
      payload: data,
  };
};

export const searchTransactions = (data) => {
  return {
      type: SETTINGS_DRIVER_SEARCH_TRANSACTIONS,
      payload: data,
  };
};

export const searchTransactionsResponse = (data) => {
  return {
      type: SETTINGS_DRIVER_SEARCH_TRANSACTIONS_RESPONSE,
      payload: data,
  };
};

export const getUrlFileCsv = (data) => {
  return {
      type: SETTINGS_DRIVER_GET_URL_FILE,
      payload: data,
  };
};

export const getUrlFileCsvResponse = (data) => {
  return {
      type: SETTINGS_DRIVER_GET_URL_FILE_RESPONSE,
      payload: data,
  };
};

export const openCloseDriversFilters = (open) => {
  return {
      type: SETTINGS_DRIVER_OPEN_CLOSE_FILTERS,
      payload: { open },
  };
};

export const searchDriversTags = (data) => {
  return {
      type: SETTINGS_DRIVER_SEARCH_TAGS,
      payload: data,
  };
};

export const searchDriversTagsResponse = (data) => {
  return {
      type: SETTINGS_DRIVER_SEARCH_TAGS_RESPONSE,
      payload: data,
  };
};

export const setDriversEntity = (entity) => {
  return {
      type: SETTINGS_DRIVER_SET_ENTITY,
      payload: { entity },
  };
};

export const deleteDriversTag = (data) => {
  return {
      type: SETTINGS_DRIVER_DELETE_DRIVERS_TAG,
      payload: data,
  };
};

export const deleteDriversTagResponse = (response) => {
  return {
      type: SETTINGS_DRIVER_DELETE_DRIVERS_TAG_RESPONSE,
      payload: { response },
  };
};

export const changeStatusDriver = (data) => {
  return {
      type: SETTINGS_DRIVER_CHANGE_STATUS,
      payload: data,
  };
};

export const changeStatusDriverResponse = (response) => {
  return {
      type: SETTINGS_DRIVER_CHANGE_STATUS_RESPONSE,
      payload: { response },
  };
};

export const openDriversTagDeleteModal = (open, items) => {
  return {
      type: SETTINGS_DRIVER_OPEN_DELETE_DRIVERS_TAG_MODAL,
      payload: { open, items }
  };
};

export const uploadDriverAvatar = (data) => {
  return {
      type: SETTINGS_DRIVER_AVATAR,
      payload: data,
  };
};

export const uploadDriverAvatarResponse = (data) => {
  return {
      type: SETTINGS_DRIVER_AVATAR_RESPONSE,
      payload: data,
  };
};
