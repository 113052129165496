import landmarkCreateEdit from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkCreateEdit/lan/es';
import landmarkDelete from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkDelete/lan/es';
import landmarkGroupCreateEdit from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkGroupCreateEdit/lan/es';
import landmarkGroupDelete from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkGroupDelete/lan/es';
import landmarkSettings from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkSettings/lan/es';
import landmarkVisibleGroups from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkVisibleGroups/lan/es';
import landmarkBulk from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkBulk/lan/es';
import landmarkImport from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkImport/lan/es';

export default {
    ...landmarkCreateEdit,
    ...landmarkDelete,
    ...landmarkGroupCreateEdit,
    ...landmarkGroupDelete,
    ...landmarkSettings,
    ...landmarkVisibleGroups,
    ...landmarkBulk,
    ...landmarkImport,
    "map_landmark_edit": "Editar",
    "map_landmark_delete": "Eliminar",
    "map_landmark_select_all": "Seleccionar Todos",
    "map_landmark_landmarkgroups": "Puntos de Referencia",
    "map_landmark_manage_landmarks": "Gestionar Puntos de Referencia",
    "map_landmark_manage_visibility": "Gestionar Visibilidad",
    "map_landmark_manage_export": "Tabla de Puntos de Referencia",
    "map_landmark_group_manage_export": "Tabla de Grupos de Referencia",
    "map_landmark_group_manage_export_color": "Color",
    "map_landmark_group_manage_export_name": "Nombre",
    "map_landmark_group_manage_export_iconName": "Icono",
    "map_landmark_bulk_actions": "Acciones Masivas",
    "map_landmark_import": "Importar"
};