// Action Types
import {
  VIDEO_MAIN_SET_INITIAL_TAB,
  CLEAR_REDUX,
} from 'Redux/actionTypes';

/**
 * Initial State for Clip Request Tab.
 */
const INITIAL_STATE = {
  currentTab: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VIDEO_MAIN_SET_INITIAL_TAB:
      return {
        ...state,
        currentTab: action.payload
      }

    case CLEAR_REDUX:
      return ["", "AVAILABLE_MEDIA"].includes(action.payload.option)
      ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INITIAL_STATE }
      : { ...state };

    default:
      return state;
  }
}