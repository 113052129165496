/* eslint-disable */
export let defaultLocale = localStorage.getItem("lan") || 'en';
export const localeOptions = [
	{ id: 'en', name: 'English' },
	{ id: 'es', name: 'Español' },
];

const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
const days = ['Lun', 'Mar', 'Mie', 'Ju', 'Vi', 'Sab', 'Dom']

const timeFormats = {
	full: 'HH:mm:ss zzzz',
	long: 'HH:mm:ss z',
	medium: 'HH:mm:ss',
	short: 'HH:mm'
}


const localeCalendarOpts = {
	"es":{
		localize: {
		  month: n => months[n],
		  day: n => days[n],
		},
		formatLong: {
			
		}	
	},
	"en":null	
}

export const localeCalendar = localeCalendarOpts[defaultLocale]

export const defaultStartPath = '/app/realtimemaps/main';

//export const defaultStartPath = '/app/maintenance/main';

export const endpoints = {
	WSS_READINGS: process.env.WSS_READINGS,
	COGNITO_URL: process.env.COGNITO_URL,
	COGNITO_API_KEY: process.env.COGNITO_API_KEY,
	LEGACY_URL: process.env.LEGACY_URL,
	GRAPHQL_GENERAL: process.env.GRAPHQL_GENERAL,
	GRAPHQL_GENERAL_V2: process.env.GRAPHQL_GENERAL_V2,
	GRAPHQL_SUBSCRIPTION_VEHICLE_STATUS: process.env.GRAPHQL_SUBSCRIPTION_VEHICLE_STATUS,
	GRAPHQL_SUBSCRIPTION_VEHICLE_STATUS_KEY: process.env.GRAPHQL_SUBSCRIPTION_VEHICLE_STATUS_KEY,
	STACK_PX_API_REST: process.env.STACK_PX_API_REST,
	GRAPHQL_BEHAVIOR: process.env.GRAPHQL_BEHAVIOR,
	REPOSITORY_CODE: process.env.REPOSITORY_CODE,
	INTERCOM_APP_ID: process.env.INTERCOM_APP_ID,
	ENVIROMENT: process.env.ENVIROMENT,
	HELP_URL: process.env.HELP_URL,
	GRAPHQL_PX_REPORTING: process.env.GRAPHQL_PX_REPORTING,
	COGNITO_DOMAIN_USER_POOL_EXTERNAL: process.env.COGNITO_DOMAIN_USER_POOL_EXTERNAL,
	COGNITO_CLIENT_SECRET: process.env.COGNITO_CLIENT_SECRET,
	COGNITO_CLIENT_ID: process.env.COGNITO_CLIENT_ID,
	VERSION_PUBLISH: process.env.VERSION_PUBLISH,
	VIDFLEET_API_URL: process.env.VIDFLEET_API_URL,
	VIDFLEET_ACCESS_KEY_ID: process.env.VIDFLEET_ACCESS_KEY_ID,
	VIDFLEET_SIGN: process.env.VIDFLEET_SIGN,
	MARKET_PLACE_LOGIN_URL:process.env.MARKET_PLACE_LOGIN_URL,
	PUBLIC_API_KEY: process.env.PUBLIC_API_KEY,
	SMARTWITNESS_API_URL: process.env.SMARTWITNESS_API_URL,
	SMARTWITNESS_API_KEY: process.env.SMARTWITNESS_API_KEY,
	DATAEXPORT_URL: process.env.DATAEXPORT_URL,
	PROMILES_URL: process.env.PROMILES_URL,
	NHTSA_URL: process.env.NHTSA_URL,
	APOLLO_URL: process.env.APOLLO_URL,
	CLOUD_URL: process.env.CLOUD_URL,
	GOOGLEMAPS_API_KEY: process.env.GOOGLEMAPS_API_KEY,
	MAP_WEATHER_URL: process.env.MAP_WEATHER_URL,
	MAP_WEATHER_ID: process.env.MAP_WEATHER_ID,
	OPEN_WEATHER_KEY: process.env.OPEN_WEATHER_KEY,
	CAR_IQ_URL: process.env.CAR_IQ_URL,
	TERMS_CONDITION_URL: process.env.TERMS_CONDITION_URL,
	TROUBLESHOOTING_URL: process.env.TROUBLESHOOTING_URL,
	PM_URL: process.env.PM_URL,
	FIELD_SYNC: process.env.FIELD_SYNC,
	BILLING_PORTAL_URL: process.env.BILLING_PORTAL_URL
};
