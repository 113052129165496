//View Keys
export default {
	driversTasksTab_task: 'Task',
	driversTasksTab_createDriverTask: 'Create New Task',
	driversTasksTab_taskLabel: 'TASK LABEL',
	driversTasksTab_taskName: 'Task name',
  driversTasksTab_repeat: 'REPEAT',
  driversTasksTab_repeatEvery: 'Repeat Every',
	driversTasksTab_repeatPlaceHolder: 'Repeat',
	driversTasksTab_frequency: 'FREQUENCY',
	driversTasksTab_frequencyPlaceHolder: 'Frequency',
	driversTasksTab_newTask: 'New Task',
	driversTasksTab_manageTask: 'Manage Tasks',
	driversTasksTab_estimatedCostLabel: 'ESTIMATED COST',
	driversTasksTab_estimatedCostPlaceholder: 'Estimated Cost',
  driversTasksTab_createdNewDriverTask: 'New task added Successfully',
  driversTasksTab_deleteConfirmation: 'Are you sure you want to delete the Driver Task ',
  driversTasksTab_delete: 'Delete',
  driversTasksTab_deleteConfirmationSuccessfully: 'Driver Task Removed Successfully',
  driversTasksTab_updatedSuccessfully: 'Driver Task Updated Successfully',
  driversTasksTab_hours: 'Hours',
  driversTasksTab_days: 'Days',
  driversTasksTab_months: 'Months',
  driversTasksTab_add_button: 'Add',
  driversTasksTab_cancel_button: 'Cancel',
  driversTasksTab_save_button: 'Save',
};
