export default {
    map_geofence_geofenceAssign_titleUnit: "Asignar Geocerca a: ",
    map_geofence_geofenceAssign_titleGeofence: "Asignar Unidad a: ",
    map_geofence_geofenceAssign_unitVersion: "Las geocercas soportadas por este veículo son {geofencesLimit}, con formas {shapes} y máximo {pointsLimit} puntos",
    map_geofence_geofenceAssign_unitVersion_circle: "Las geocercas soportadas por este veículo son {geofencesLimit}, con formas {shapes}",
    map_geofence_geofenceAssign_geofences: "Seleccione Geocercas para asignar",
    map_geofence_geofenceAssign_units: "Seleccione Unidades para asignar",
    map_geofence_geofenceAssign_geofencesLimitReached: "La unidad ha alcanzado el límite de geocercas",
    map_geofence_geofenceAssign_geofencesAssignedMessage: "Geocercas asignadas exitosamente",
    map_geofence_geofenceAssign_geofenceType: "Geocerca con forma '{shape}' y {points} puntos",
    map_geofence_geofenceAssign_geofenceType: "Geocerca con forma '{shape}'",
    map_geofence_geofenceAssign_circle: "círculo",
    map_geofence_geofenceAssign_polygon: "polígono",
    map_geofence_geofenceAssign_square: "cuadrado",
};