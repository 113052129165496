import {
    SETTINGS_TEMPERATURE_SEARCH,
    SETTINGS_TEMPERATURE_SEARCH_RESPONSE,
    SETTINGS_TEMPERATURE_OPEN_CREATE_EDIT_MODAL,
    SETTINGS_TEMPERATURE_CREATE_EDIT,
    SETTINGS_TEMPERATURE_CREATE_EDIT_RESPONSE,
    SETTINGS_TEMPERATURE_OPEN_DELETE_MODAL,
    SETTINGS_TEMPERATURE_DELETE,
    SETTINGS_TEMPERATURE_DELETE_RESPONSE,
    SETTINGS_TEMPERATURE_GET_UNIT,
    SETTINGS_TEMPERATURE_GET_UNIT_RESPONSE,
    SETTINGS_TEMPERATURE_GET_CONTACT,
    SETTINGS_TEMPERATURE_GET_CONTACT_RESPONSE
} from "Redux/actionTypes";

export const searchSettingsTemperature = (data) => {
    return {
        type: SETTINGS_TEMPERATURE_SEARCH,
        payload: data,
    };
};

export const searchSettingsTemperatureResponse = (data) => {
    return {
        type: SETTINGS_TEMPERATURE_SEARCH_RESPONSE,
        payload: data,
    };
};


export const openSettingsTemperatureCreateEditModal = (open, temperature) => {
    return {
        type: SETTINGS_TEMPERATURE_OPEN_CREATE_EDIT_MODAL,
        payload: { open, temperature }

    };
};


export const createEditSettingsTemperature = (temperatures, alertsToDelete) => {
    return {
        type: SETTINGS_TEMPERATURE_CREATE_EDIT,
        payload: { temperatures, alertsToDelete },
    };
}

export const createEditSettingsTemperatureResponse = (data) => {
    return {
        type: SETTINGS_TEMPERATURE_CREATE_EDIT_RESPONSE,
        payload: { data },
    };
};


export const openSettingsTemperatureDeleteModal = (open, items = []) => {
    return {
        type: SETTINGS_TEMPERATURE_OPEN_DELETE_MODAL,
        payload: { open, items },
    };
};

export const deleteSettingsTemperature = (items = []) => {
    return {
        type: SETTINGS_TEMPERATURE_DELETE,
        payload: { items },
    };
};

export const deleteSettingsTemperatureResponse = (data) => {
    return {
        type: SETTINGS_TEMPERATURE_DELETE_RESPONSE,
        payload: { data },
    };
};

export const getSettingsTemperatureUnits = () => {
    return {
        type: SETTINGS_TEMPERATURE_GET_UNIT,
        payload: {},
    };
};

export const getSettingsTemperatureUnitsResponse = (data) => {
    return {
        type: SETTINGS_TEMPERATURE_GET_UNIT_RESPONSE,
        payload: data,
    };
};

export const getSettingsTemperatureContact = () => {
    return {
        type: SETTINGS_TEMPERATURE_GET_CONTACT,
        payload: {},
    };
};

export const getSettingsTemperatureContactResponse = (data) => {
    return {
        type: SETTINGS_TEMPERATURE_GET_CONTACT_RESPONSE,
        payload: data,
    };
};