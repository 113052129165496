import React from 'react'
import { ConcatLabel } from "Components";

const CompanyName = ({children }) =>  {
  return (
    <div className="header-title">
        <ConcatLabel
          username={
            children
          }
          concatLimit={25}
        />
      </div>
  )
}

export default CompanyName