//Module Keys
let locales = {};

//Merge Views
import mainView from '../../views/main/lan/es';
locales = { ...locales, ...mainView };

import vehicleDetailView from '../../views/vehicleDetail/lan/es';
locales = { ...locales, ...vehicleDetailView };

import entityDetails from '../../modals/entityDetails/lan/es'
locales = { ...locales, ...entityDetails };

import manageServiceItems from '../../modals/manageServiceTasks/lan/es';
locales = { ...locales, ...manageServiceItems };

import deleteMaintenanceService from '../../modals/deleteMaintenanceService/lan/es';
locales = { ...locales, ...deleteMaintenanceService };

import scheduleDriverTask from '../../modals/scheduleDriverTask/lan/es';
locales = { ...locales, ...scheduleDriverTask };

import createEditDriverTask from '../../modals/createEditDriverTask/lan/es';
locales = { ...locales, ...createEditDriverTask };

import createEditMaintenanceService from '../../modals/createEditMaintenanceService/lan/es';
locales = { ...locales, ...createEditMaintenanceService };

import driversTab from '../../views/main/components/driversTab/lan/es';
locales = { ...locales, ...driversTab };

import vehiclesTab from '../../views/main/components/vehiclesTab/lan/es';
locales = { ...locales, ...vehiclesTab };

import deleteDriverScheduledTask from '../../modals/deleteDriverScheduledTask/lan/es';
locales = { ...locales, ...deleteDriverScheduledTask };

import completeDriverScheduledTask from '../../modals/completeDriverScheduledTask/lan/es';
locales = { ...locales, ...completeDriverScheduledTask };
import serviceDetail from '../../views/serviceDetail/lan/es';
locales = { ...locales, ...serviceDetail };

import exportVehicles from '../../views/main/components/vehiclesTab/exportVehicles/lan/es';
locales = { ...locales, ...exportVehicles };

import deleteMaintenanceServiceUnit from '../../modals/deleteMaintenanceServiceUnit/lan/es';
locales = { ...locales, ...deleteMaintenanceServiceUnit };

import scheduleMaintenanceService from '../../modals/scheduleMaintenanceService/lan/es';
locales = { ...locales, ...scheduleMaintenanceService };

import completeMaintenanceService from '../../modals/completeMaintenanceService/lan/es';
locales = { ...locales, ...completeMaintenanceService };

import manageDriverTasksScheduled from '../../modals/manageDriverTasksScheduled/lan/es';
locales = { ...locales, ...manageDriverTasksScheduled };

import maintenanceServicesToComplete from '../../modals/maintenanceServicesToComplete/lan/es';
locales = { ...locales, ...maintenanceServicesToComplete };

locales = {
  ...locales,
  "maintenance_VINNumberValidation": "El número VIN debe contener 17 caracteres"
}

export default locales;