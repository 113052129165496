import React, { Fragment,useState,useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { MapUtilitiesTypes } from '../../../../modules/realtimeMaps/main/components/MapUtilitiesMenu/enums';

import { Tooltip } from 'Components';
import styles from 'Assets/sass/core/vars.scss';
import { useIntl } from "react-intl";

/*********************************************************
 * Actions
*********************************************************/
import { selectModuleMapSettings, setShowTagFIlterApplied } from "Redux/actions";

const TagFilterAppliedOnMap = () => {
  
  const { messages } = useIntl();
  
  const dispatch = useDispatch();
  const [color, setColor] = useState(styles['color_button_icons_map_black']);
  const currentModule = useSelector(state => state.realtimeMapUtilitiesRedux.currentModule)
  const showTagFilterApplied = useSelector(state => state.realtimeMapUtilitiesRedux.showTagFilterApplied)

  const handleModule = useCallback(() => {
    if (currentModule !== MapUtilitiesTypes.TagFilter && showTagFilterApplied) {
      dispatch(selectModuleMapSettings(MapUtilitiesTypes.TagFilter));
    } else {
      dispatch(selectModuleMapSettings(MapUtilitiesTypes.Default));
    }

    if (!showTagFilterApplied) {
      dispatch(selectModuleMapSettings(MapUtilitiesTypes.TagFilter));
      dispatch(setShowTagFIlterApplied(true));
    }
  },[currentModule, showTagFilterApplied]);

  useEffect(() => {
    if (currentModule === MapUtilitiesTypes.TagFilter && showTagFilterApplied) {
      setColor(styles['color_btn_blue'])
    }else{
      setColor(styles['color_button_icons_map_black'])
    }
	}, [currentModule, showTagFilterApplied]);

  return (
    <Fragment>
      <Tooltip placement="left" title={messages['maputilities_tagsFilterOnMap']}>
        <button
          onClick={() => handleModule()}>
           <LocalOfferIcon style={{ color: color}}></LocalOfferIcon>
        </button>
      </Tooltip>
    </Fragment>
  )
}

export default TagFilterAppliedOnMap;