import {
  ALERTS_UPDATE_SYSTEM_ALERT,
  ALERTS_UPDATE_SYSTEM_ALERT_RESULT,
  ALERTS_CREATE_SYSTEM_ALERT,
  ALERTS_CREATE_SYSTEM_ALERT_RESULT,
  CLEAR_REDUX
} from "Redux/actionTypes";

const INIT_STATE = {
  loadingUpdateAlertConfig: false,
  updateAlertConfigResult: null,
  loadingCreateAlertConfig: false,
  createAlertConfigResult: null,
};



export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case ALERTS_CREATE_SYSTEM_ALERT:
      return { ...state, loadingCreateAlertConfig: true };

    case ALERTS_CREATE_SYSTEM_ALERT_RESULT:
      return { ...state, loadingCreateAlertConfig: false, createAlertConfigResult: action.payload };

    case ALERTS_UPDATE_SYSTEM_ALERT:
      return { ...state, loadingUpdateAlertConfig: true };

    case ALERTS_UPDATE_SYSTEM_ALERT_RESULT:
      return { ...state, loadingUpdateAlertConfig: false, updateAlertConfigResult: action.payload };

    /** Clear Redux */
    case CLEAR_REDUX:
      return ["", "ALERTS_COMMON"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
};