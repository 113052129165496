export default {
    "settingsDriverGroups_search": "Buscar por grupos de conductores",
    'settingsDriverGroups_createTitle': "Crear nuevo grupo ",
    'settingsDriverGroups_editTitle': "Editar grupo ",
    'settingsDriverGroups_groupName':'Nombre del grupo',
    'settingsDriverGroups_Drivers':'Conductores',
    'settingsDriverGroups_minLengthMessage':'El campo debe contener un mínimo de caracteres ',
    'settingsDriverGroups_maxLengthMessage':'El campo debe contener un maximo de caracteres  ',
    'settingsDriverGroups_success':'Grupos de conductores creado',
    'settingsDriverGroups_deleteTitle':'Eliminar grupos',
    'settingsDriverGroups_deleteWarning':'¿Está seguro de que desea eliminar los grupos?',
    'settingsDriverGroup_deleted':'Grupo Eliminado',
    'settingsDriverGroup_delete_move_member':'Es necesario mover los miembros del grupo a un grupo diferente',
    'settingsDriverGroup_delete_move_member_placeholder':'Mover grupo'

};