export default {
    eta_title_edit: "Editar Enlace Eta",
    eta_title_create: "Crear Enlace Eta",
    eta_title_creation_scheduled: "The creation of the link has been scheduled",
    eta_title_edit_scheduled: "El enlace Eta ha sido editado y programado",
    eta_title_date_error: "La fecha inicial no puede ser superior a la fecha de expiración",
    eta_title_date_less_error: "La fecha inicial es inferior a la fecha final",
    eta_title_limit_end_date: "La fecha de finalización no puede superar una semana desde la fecha de inicio",
    eta_title_error_current_date: "La fecha inicial no debe ser inferior a la fecha actual",
    eta_title_message: "Enlace ETA - Período Activo",
    eta_message: "El enlace ETA no estará disponible fuera del siguiente periodo de inicio-fin",
    eta_notes: "Notas",
    eta_message_notes: "La siguiente nota se enviará a los destinatarios seleccionados con el enlace ETA",
}