export default {
    completeDriverScheduledTasks_completeSchedule: "Completar y reprogramar",
    completeDriverScheduledTasks_complete: "Completar",
    completeDriverScheduledTasks_status: "ESTADO",
    completeDriverScheduledTasks_completed: "COMPLETADO",
    completeDriverScheduledTasks_repeatEvery: "REPETIR CADA",
    completeDriverScheduledTasks_dueDate: "FECHA LÍMITE",
    completeDriverScheduledTasks_estimatedCost: "COSTE ESTIMADO",
    completeDriverScheduledTasks_actualCost: "COSTE ACTUAL",
    completeDriverScheduledTasks_completedDate: "FECHA DE FINALIZACIÓN",
    completeDriverScheduledTasks_attachments: "ADJUNTOS ",
    completeDriverScheduledTasks_notes: "NOTAS",
    completeDriverScheduledTasks_nextDueDate: "PRÓXIMA FECHA LÍMITE",
    completeDriverScheduledTasks_rescheduleTask: "Reprogramar tarea",
    completeDriverScheduledTasks_taskCompleted: "Tarea completada",
}