import { createSelector } from 'reselect'
import { useDataToValidate, useComputeData } from 'Core/hooks'

const getReadingsCatalog = (state) => state.catalogsRedux.readings
const getUnitsCatalog = (state) => state.catalogsRedux.units
const getUnitIncident = (state) => state.incidentsRedux.isOpenOverViewUnit

export const getData = useDataToValidate(createSelector(
    [getUnitsCatalog, getReadingsCatalog, getUnitIncident],
    (units, readings, isOpenOverViewUnit) => {

        const id = isOpenOverViewUnit?.data?.unit?.id;
        const filterUnit = units?.filter(unit => unit?.id === id || unit.CUnits_id === id)
        if(!filterUnit) return []

        let unitList = []
        try{
            filterUnit.forEach((unit) => {

                if( unit.id == "-1" || (!unit.id && !unit.CUnits_id) ) return
                const idUnit = unit.id

                let lastReading = readings.find(x => x.deviceId && x.deviceId == idUnit)
                const lat = lastReading?.latitude || unit.latitude
                const lng = lastReading?.longitude || unit.longitud

                const driverName = unit?.driverName 
                const label = unit.label || lastReading?.deviceLabel
                const timezone = unit?.timeZone?.name
                
                const item = {
                    id: unit.id || lastReading?.deviceId,
                    label: label,
                    lat: lat ? parseFloat(lat) : 0,
                    lng: lng ? parseFloat(lng) : 0,
                    haveLatLng: lastReading?.haveLatLng, // this vehicle no have lat and lng, cant show on map
                    heading: lastReading?.heading ||  unit.heading || 0,
                    eventName: lastReading?.eventName || unit.eventName || '',
                    driverId: unit?.driverId,
                    driverName: unit?.driverId > 0 ? driverName : '',
                    unitGroups: unit?.unitGroups,
                    hasVideo: unit?.hasVideo || false,
                    useEcuSpeed: unit?.useEcuSpeed,
                    vehicleStatusId: unit?.vehicleStatusId,
                    vehicleInformation: unit?.vehicleInformation,
                    esn: unit?.esn,
                    unitType: lastReading?.unitType,
                    speed: lastReading?.speed,
                    ecuSpeed: lastReading?.speed,
                    address: lastReading?.address,
                    changeLabel: (unit.label != label),
                    changeLatLng: lastReading?.changeLatLng,
                    unitTime: lastReading?.unitTime,
                    LastMessageTime: lastReading?.LastMessageTime,
                    serverTime: lastReading?.serverTime,
                    timezone
                }
                unitList.push(item);
            });
        }catch(e){
          NotificationManager.error('Error', `Error /modules/realtimeMaps/ to create show vehicles - ${e}`, 3000, null, null, 'error');
        }

        return unitList
    }
), null)

export const buildUrlShare = (shortCode) => {
    const host = window.location.host;
    return `${host}/s/${shortCode}`
}

