// Dependencies
import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Loading } from 'Components';
// Components
import Modal from '@mui/material/Modal';
import { Button } from 'Components';
// Styles
import './globalAlert.scss';

const GlobalAlert = (props) => {
  const {
    title,
    contentText,
    confirmText,
    cancelText,
    onClose,
    onClick,
    open,
    loading,
    type
  } = props;




  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}
      >
        <div className={'GlobalAlert-paper'}>
          <Typography className="title-text" variant="h6">
            {title}
          </Typography>
          <Typography className="content-text">
            {contentText}
          </Typography>
          <div className="buttons-container">
            <Button
              onClick={onClose}
              variant="contained"
              className="btn-cancel">
              {cancelText}
            </Button>
            <Button
              onClick={onClick}
              disabled={loading}
              className={`btn ${type === 'alert' ? "btn-red": type === 'success' ? 'btn-blue':''}`}
            >
              {confirmText}
            </Button>
          </div>
          {loading && <Loading overlay show /> }
        </div>
      </Modal>
    </div>
  )
}

GlobalAlert.defaultProps = {
  open: true,
  type: 'alert'
}

GlobalAlert.propTypes = {
  title: PropTypes.string.isRequired,
  contentText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default GlobalAlert;