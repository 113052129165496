// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    MAP_SPEED_OVERRIDE_DELETE_SPEED_OVERRIDE
} from "Redux/actionTypes";

// Actions
import {
    mapSpeedOverrideDeleteSpeedOverrideResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* mapSpeedOverrideDeleteSpeedOverrideRequest({ payload }) {
    try {
        const { ids } = payload;
        let query = `
            mutation deleteSpeedOverrides (
                $ids: [Int]!
            ) {
                deleted: deleteSpeedOverrides (
                    ids: $ids
                )
            }
        `;

        const variables = {
            ids
        };

        const response = yield call(
            clientMutation,
            query,
            variables,
            endpoints.GRAPHQL_GENERAL,
        );

        if (!response || !response.deleted) throw 'Error while deleting speed overrides';

        yield put(mapSpeedOverrideDeleteSpeedOverrideResponse(response.deleted, false));
    } catch (exc) {
        yield put(mapSpeedOverrideDeleteSpeedOverrideResponse(null, true));
        ExceptionManager(
            exc,
            "realtimeMaps/main/components/speedOverride/components/speedOverrideDelete/redux/saga",
            "mapSpeedOverrideDeleteSpeedOverrideRequest"
        );
    }
}

function* watchMethods() {
    yield takeEvery(MAP_SPEED_OVERRIDE_DELETE_SPEED_OVERRIDE, mapSpeedOverrideDeleteSpeedOverrideRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}