// Action types.
import {
  CLIP_REQUEST_GET_UNITS,
  CLIP_REQUEST_GET_UNITS_RESULT,
  CLIP_REQUEST_OPEN_FORM,
  CLIP_REQUEST_CREATE,
  CLIP_REQUEST_CREATE_RESPONSE,
  CLIP_REQUEST_SEARCH,
  CLIP_REQUEST_SEARCH_RESPONSE,
  CLIP_REQUEST_OPEN_MODAL_ADD_NOTES,
  VIDEO_RESTORE_CLIP_REQUEST,
  VIDEO_RESTORE_CLIP_REQUEST_RESPONSE,
  CLIP_REQUEST_UPDATE_CLIP,
  CLIP_REQUEST_REMOVE_CLIPS,
  CLIP_REQUEST_DATA,
  CLIP_REQUEST_NEW_FORM,
  CLIP_AVAILABLE_SEARCH,
  CLIP_AVAILABLE_SEARCH_RESPONSE,
  CLIP_REQUEST_REFRESH_CLIP,
  CLIP_REQUEST_REFRESH_CLIP_RESPONSE
} from 'Redux/actionTypes';

export const clipRequestGetUnits = (data) => {
  return {
    type: CLIP_REQUEST_GET_UNITS,
    payload: data,
  };
};

export const clipRequestGetUnitsResult = (data) => {
  return {
    type: CLIP_REQUEST_GET_UNITS_RESULT,
    payload: data,
  };
};

export const clipRequestOpenForm = (data) => {
  return {
    type: CLIP_REQUEST_OPEN_FORM,
    payload: data,
  };
};

export const clipRequestNewForm = (data) => {
  return {
    type: CLIP_REQUEST_NEW_FORM,
    payload: data,
  };
};


export const createClipRequest = (data) => {
  return {
    type: CLIP_REQUEST_CREATE,
    payload: data,
  };
};

export const createClipRequestResponse = (data) => {
  return {
    type: CLIP_REQUEST_CREATE_RESPONSE,
    payload: data,
  };
};

export const setRequestClipData = (data) => {
  return {
    type: CLIP_REQUEST_DATA,
    payload: data,
  };
};

export const searchClipRequests = (data) => {
  return {
    type: CLIP_REQUEST_SEARCH,
    payload: data,
  };
};

export const searchClipRequeststResponse = (data) => {
  return {
    type: CLIP_REQUEST_SEARCH_RESPONSE,
    payload: data,
  };
};

export const searchAvailableClips = (data) => {
  return {
    type: CLIP_AVAILABLE_SEARCH,
    payload: data,
  };
};

export const searchAvailableClipstResponse = (data) => {
  return {
    type: CLIP_AVAILABLE_SEARCH_RESPONSE,
    payload: data,
  };
};

export const clipRequestOpenModalAddNotes = (open, data) => {
  return {
    type: CLIP_REQUEST_OPEN_MODAL_ADD_NOTES,
    payload: { open, data }
  };
};

export const videoRestoreClipRequest = (data) => {
  return {
    type: VIDEO_RESTORE_CLIP_REQUEST,
    payload: { data },
  };
};

export const videoRestoreClipRequestResponse = (data) => {
  return {
    type: VIDEO_RESTORE_CLIP_REQUEST_RESPONSE,
    payload: { data }
  };
};

export const videoMainUpdateClipRequest = (data) => {
  return {
    type: CLIP_REQUEST_UPDATE_CLIP,
    payload: { data }
  };
};

export const removeClipsFromList = (data) => {
  return {
    type: CLIP_REQUEST_REMOVE_CLIPS,
    payload: { data }
  };
};

export const getRefreshClip = (data) => {
  return {
    type: CLIP_REQUEST_REFRESH_CLIP,
    payload: data,
  };
};

export const refreshClipResponse = (data) => {
  return {
    type: CLIP_REQUEST_REFRESH_CLIP_RESPONSE,
    payload: data,
  };
};