import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
//import { resetAll } from "../../../../../../../../../../.globals/redux/actions";
import { NotificationManager } from 'Components/Notifications';
import { ExceptionManager } from 'Core/logManager';

const INIT_STATE = {
    deleteVehicleServiceUnit: null,
    open: false,
    loading: false,
    result: null,
    maintenanceService: {},
    maintenanceServicesList: [],
    error: null,
};

const route = "modules/maintenance/modals/deleteMaintenanceService/redux/slice";

export const maintenanceMDSDeleteMaintenanceService = createAsyncThunk("maintenance/deleteMaintenanceService/deleteMaintenanceService", async (payload, { rejectWithValue, dispatch }) => {
    let mutations = [];
    try {
        const { id, items } = payload;
        if (id) {
            let data = await clientMutation(
                `mutation ($id: Int!){
                    deleteMaintenanceService: deleteMaintenanceService(id: $id)
                }`,
                {
                    id
                },
                endpoints.GRAPHQL_GENERAL
            );
            if (data && data.deleteMaintenanceService)
                return data.deleteMaintenanceService;
            else throw 'Error while deleting maintenance service';
        }
        if (items) {
            items.forEach((service, index) => {
                mutations.push(`
            deleteMaintenanceService${index}: deleteMaintenanceService(
                    id: ${service.id},
                )
          `);
            })
            let mutation = `mutation { ${mutations.join(' ')} }`;
            let data = await clientMutation(
                mutation,
                {
                    id
                },
                endpoints.GRAPHQL_GENERAL,
            );
            if (data && data.deleteMaintenanceService0)
                return data.deleteMaintenanceService0;
            else throw 'Error while deleting maintenance services';
        }

    } catch (exc) {
        // ALWAYS RETURN ACTION
        NotificationManager.error('Error', `Error Deleting  Service`, 3000, null, null, 'error');
        ExceptionManager(exc, route, 'deleteMaintenanceService');
        rejectWithValue(exc);
    }
}
);

export const maintenanceDeleteMaintenanceServiceRedux = createSlice({
    name: 'maintenance/deleteMaintenanceService',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalDeleteMaintenanceService: (state, { payload }) => {
            state.open = payload.open,
            state.maintenanceService = payload.maintenanceService,
            state.maintenanceServicesList = payload.maintenanceServicesList || []
        }
    },
    extraReducers: (builder) => {
        //maintenanceMDSDeleteMaintenanceService
        builder.addCase(maintenanceMDSDeleteMaintenanceService.pending, (state) => {
            state.loading = true
        });

        builder.addCase(maintenanceMDSDeleteMaintenanceService.fulfilled, (state, { payload }) => {
            state.result = payload,
            state.loading = false
        });

        builder.addCase(maintenanceMDSDeleteMaintenanceService.rejected, (state, action) => {
            state.error = action.payload,
            state.loading = false
        });
    },
});

const { actions, reducer } = maintenanceDeleteMaintenanceServiceRedux;

export const {
    reset,
    openModalDeleteMaintenanceService,
} = actions;

export default reducer;