import {
	MODAL_PING_OPEN,
	MODAL_PING_OPEN_RESULT,
	MODAL_PING_CLOSE,
} from 'Redux/actionTypes';

export const modalPingOpen = (ids, units) => ({
	type: MODAL_PING_OPEN,
	payload: { ids, units }
})

export const modalPingClose = () => ({
	type: MODAL_PING_CLOSE,
	//payload: { limit, offset }
})

export const modalPingOpenResult = (data) => ({
	type: MODAL_PING_OPEN_RESULT,
	payload: data
})
