// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  SETTINGS_TEMPERATURE_SEARCH,
  SETTINGS_TEMPERATURE_GET_UNIT,
  SETTINGS_TEMPERATURE_CREATE_EDIT,
  SETTINGS_TEMPERATURE_GET_CONTACT,
  SETTINGS_TEMPERATURE_DELETE
} from "Redux/actionTypes";

// Actions
import {
  searchSettingsTemperatureResponse,
  createEditSettingsTemperatureResponse,
  getSettingsTemperatureContactResponse,
  getSettingsTemperatureUnitsResponse,
  deleteSettingsTemperatureResponse,
  deleteAlerts
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";
import { isJson } from 'Core/data/Helpers';

function* createEditTemperatureRequest({ payload }) {
  try {
    const { temperatures, alertsToDelete } = payload;

    if (alertsToDelete?.length) {
      yield put(deleteAlerts(alertsToDelete, "", ""));
    }

    let response = null;
    
    if (temperatures?.length) {
      for (let temperature of temperatures) {
        if (temperature?.id) {
          response = yield call(
            clientMutation,
            `mutation updateSystemAlert ($id: String!, $name: String, $type: enumAlertType, $config: AWSJSON, $severity: Severity!, $summary: String, $triggers: [SystemAlertTriggerInput], $assignedTo: [SystemAlertAssignmentInput], $schedule: SystemAlertScheduleInput, $subscribers: [AlertSubscriberInput]) {
              alert: updateSystemAlert (id: $id, name: $name, type: $type, config: $config, severity: $severity, summary: $summary, triggers: $triggers, assignedTo: $assignedTo, schedule: $schedule, subscribers: $subscribers) {
                id
              }
            }`,
            temperature,
            endpoints.GRAPHQL_GENERAL,
          );
        } else {
          response = yield call(
            clientMutation,
            `mutation createSystemAlert ($name: String!, $type: enumAlertType!, $config: AWSJSON, $severity: Severity!, $summary: String, $triggers: [SystemAlertTriggerInput], $assignedTo: [SystemAlertAssignmentInput], $schedule: SystemAlertScheduleInput, $subscribers: [AlertSubscriberInput]) {
              alert: createSystemAlert(name: $name, type: $type, config: $config, severity: $severity, summary: $summary, triggers: $triggers, assignedTo: $assignedTo, schedule: $schedule, subscribers: $subscribers) {
                id
              }
            }`,
            temperature,
            endpoints.GRAPHQL_GENERAL,
          );
        }
      }
    }

    if (!response || !response?.alert?.id) {
      throw "Error while getting creating/updating temperature alert";
    }

    yield put(createEditSettingsTemperatureResponse({ ...response, error: false }));
  } catch (exc) {
    yield put(createEditSettingsTemperatureResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/temperature/redux/saga",
      "createEditTemperatureRequest"
    );

  }
}

function* searchTemperatureRequest({ payload }) {
  try {
    const {
      openSearch
    } = payload;

    const query = `
      query searchUnits {
        data: searchUnits {
          items {
            id
            label
            hid
            systemAlertAssignment{
              id
            }
            temperatureAlerts {
              id
              name
              configJSON
              subscribers {
                id
                contactId
              }
            }
          }
        }
      }
    `;

    const response = yield call(
      clientQuery,
      query,
      {},
      endpoints.GRAPHQL_GENERAL_V2
    );

    if (!response && !response.data) {
      throw "Error while getting Temperature list";
    }
    let units = [];
    for (let i = 0; i < response?.data?.items?.length; i++) {
      let unit = response?.data?.items[i];
      let alerts = [];
      for (let j = 0; j < unit?.temperatureAlerts?.length; j++) {
        let alert = unit?.temperatureAlerts[j];
        alerts.push({
          ...alert,
          config: isJson(alert?.configJSON) && JSON.parse(alert?.configJSON) || {}
        });
      }

      units.push({
        ...unit,
        temperatureAlerts: alerts
      });
    }
    
    yield put(searchSettingsTemperatureResponse({ temperature: units, error: false }));
  } catch (exc) {
    yield put(searchSettingsTemperatureResponse({ temperature: [], error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/temperature/redux/saga",
      "searchTemperatureRequest"
    );
  }
}

function* getTemperatureUnitsRequest() {
  try {

    const query = `
      query searchUnits ($withTempSensorOnly: Boolean) {
        searchUnits (withTempSensorOnly: $withTempSensorOnly) {
                id
                label
                typeId
                type
                hid
            }
        }
      `;

    const response = yield call(
      clientQuery,
      query,
      {
        withTempSensorOnly: false
      },
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.searchUnits) {
      throw "Error while getting Units list";
    }
    let units = response.searchUnits;
    yield put(getSettingsTemperatureUnitsResponse({ units, error: false }));
  } catch (exc) {
    yield put(getSettingsTemperatureUnitsResponse({ units: [], error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/temperature/redux/saga",
      "getTemperatureUnitsRequest"
    );
  }
}

function* getTemperatureContacsRequest() {
  try {

    const query = `
            query getAllContacts ($openSearch: String, $limit: Int!, $offset: Int) {
              getAllContacts (openSearch: $openSearch, limit: $limit, offset: $offset) {
                  items {
                      id
                      title
                  }
              }
          }
      `;

    const response = yield call(
      clientQuery,
      query,
      {
        openSearch: '',
        limit: 0
      },
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.getAllContacts) {
      throw "Error while getting Units list";
    }
    let contacts = response.getAllContacts.items;
    yield put(getSettingsTemperatureContactResponse({ contacts, error: false }));
  } catch (exc) {
    yield put(getSettingsTemperatureContactResponse({ contacts: [], error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/temperature/redux/saga",
      "getTemperatureContacsRequest"
    );
  }
}

function* deleteTemperatureRequest({ payload }) {
  try {
    const { items } = payload;
    let mutations = [];

    items?.forEach((item, key) => {
      item?.temperatureAlerts?.forEach((alert, index) => {
        mutations.push(`
          alert${key}${index}: deleteSystemAlert(id: "${alert?.id}")
        `);
      });
    });

    let mutation = `mutation{${mutations.join("")}}`;

    const response = yield call(
      clientMutation,
      mutation,
      {},
      endpoints.GRAPHQL_GENERAL,
    );

    if (!response || !response.alert00) throw 'Error while deleting temperature alert';

    yield put(deleteSettingsTemperatureResponse({ ...response, error: false }));
  } catch (exc) {
    yield put(deleteSettingsTemperatureResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/temperature/redux/saga",
      "deleteTemperatureRequest"
    );
  }
}

function* watchMethods() {
  yield takeEvery(SETTINGS_TEMPERATURE_SEARCH, searchTemperatureRequest);
  yield takeEvery(SETTINGS_TEMPERATURE_CREATE_EDIT, createEditTemperatureRequest);
  yield takeEvery(SETTINGS_TEMPERATURE_GET_UNIT, getTemperatureUnitsRequest);
  yield takeEvery(SETTINGS_TEMPERATURE_GET_CONTACT, getTemperatureContacsRequest);
  yield takeEvery(SETTINGS_TEMPERATURE_DELETE, deleteTemperatureRequest);
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}