import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { speedFormatMPH } from 'Core/data/Helpers';

//Action types
import { SLIDER_VIDEO_VEHICLETRAILS_GET, SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET, SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNITS_GET } from 'Redux/actionTypes';

//actions
import { getSliderVehicleTrailsConfirmResult, searchSliderUnitTypeEventsResult, searchSliderUnitGetResult } from 'Redux/actions';

//Function
function* getVehicleTrailsRequest({ payload }) {
  const { params } = payload
  const { unitId, event, startDate, endDate, openSearch, limit, offset, useEcuSpeed, unitType, kph, withRecentEvent } = params
  let countItems = offset == 0 ? offset : (offset - 1) * limit; //starting at the offset element
  let wayPoint = 0
  let queryElements = { deviceId: unitId, event, startDate, endDate, openSearch: openSearch ? openSearch : '', limit, offset: countItems, includeLinkedData: true, withRecentEvent }
  
  if (event == '0') {
    delete queryElements["event"]
  }
  if (!openSearch) {
    delete queryElements["openSearch"]
  }
  try {
    const response = yield call(
      clientQuery,
      `query searchVehicleTrails ($startDate: AWSDateTime!, $endDate: AWSDateTime!, $event: VehicleTrailEvent, $deviceId: String!, $openSearch: String, $limit: Int, $offset: Int, $includeLinkedData: Boolean, $withRecentEvent: Boolean) {
        Trails: searchVehicleTrails (startDate: $startDate, endDate: $endDate, event: $event, deviceId: $deviceId, openSearch: $openSearch, limit: $limit, offset: $offset, includeLinkedData: $includeLinkedData, withRecentEvent: $withRecentEvent) {
            items {
                id
                deviceId
                latitude
                longitude
                heading
                eventCode
                eventName
                address
                formattedAddress
                driverId
                driverName
                ignitionStatus
                satelliteCount
                signal
                ecuSpeed
                speed
                speedLimit
                unitTime
                landmark {
                  id
                  companyId
                  name
                  tags {
                    id
                    label
                    color
                    path
                  }
                  address
                  city
                  zip
                  state
                  country
                }
            }
            total
        }
    }`,
      queryElements,
      endpoints.GRAPHQL_GENERAL,
    );

    let data = {};
    if (response && response.Trails) {
      const vehicleTrailsData = response.Trails;

      if (vehicleTrailsData && vehicleTrailsData.items) {
        wayPoint = vehicleTrailsData.items.length + 1
        const dataTrails = vehicleTrailsData.items.map((item) => {
          countItems++;
          wayPoint--;
          let speed =  useEcuSpeed && item?.ecuSpeed > 0 ? item?.ecuSpeed : item.speed
          if(unitType && kph){
            speed = speedFormatMPH(speed, unitType, kph, true)
          }
          return { ...item, index: countItems, wayPoint: wayPoint, speed: speed }
        });

        data = { ...response.Trails, items: dataTrails };
      }

      yield put(getSliderVehicleTrailsConfirmResult(data));
    } else {
      throw 'Not Vehicle Trails';
    }
  } catch (e) {
    ExceptionManager(e, 'modules/maintenance/views/redux/saga', 'getVehicleTrailsRequest');
    yield put(getSliderVehicleTrailsConfirmResult(null));
  }
}

function* searchUnitTypeEvents({ payload }) {
  try {
    const response = yield call(
      clientQuery,
      `query searchUnitTypeEvents ($unitTypeId: Int) {
        result: searchUnitTypeEvents (unitTypeId: $unitTypeId) {
          eventName
          eventId
        }
      }`,
      {
        unitTypeId: payload
      },
      endpoints.GRAPHQL_GENERAL,
    );
    if (response?.result) {
      yield put(searchSliderUnitTypeEventsResult(response?.result))
    } else yield put(searchSliderUnitTypeEventsResult())
  } catch (e) {
    yield put(searchSliderUnitTypeEventsResult())
    ExceptionManager(e, 'modules/realtimeMaps/main/components/VehicleTrails/redux/saga', 'searchUnitTypeEvents');
  }
}

function* searchUnits({ payload }) {
  try {
    const response = yield call(
      clientQuery,
      `query searchUnits ($openSearch: String) {
        result: searchUnits (openSearch: $openSearch) {
          id
          label
          typeId
          useEcuSpeed
          show
          timeZone {
            id
            name
          }
          lastReading {
            unitType
          }
        }
      }`,
      {
        openSearch: payload
      },
      endpoints.GRAPHQL_GENERAL,
    );
    if (response.result) {
      yield put(searchSliderUnitGetResult(response.result));
    } else yield put(searchSliderUnitGetResult())
  } catch (e) {
    yield put(searchSliderUnitGetResult())
    ExceptionManager(e, 'modules/realtimeMaps/main/components/VehicleTrails/redux/saga', 'searchUnits');
  }
}

function* watchGetSliderVideoVehicleTrailsTasksRequest() {
  yield takeEvery(SLIDER_VIDEO_VEHICLETRAILS_GET, getVehicleTrailsRequest);
  yield takeEvery(SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET, searchUnitTypeEvents);
  yield takeEvery(SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNITS_GET, searchUnits)
}
//Saga Export
export default function* rootSaga() {
  yield all([
    fork(watchGetSliderVideoVehicleTrailsTasksRequest)
  ]);
}