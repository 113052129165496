import { call, put } from "redux-saga/effects";
import { clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";
// Actions
import {
  reportsMainGenerateReportProgress,
  reportsMainGenerateReportUpdate,
} from "Redux/actions";

import {
  baseReportConditions,
  generateReportInstance,
  getTimeZone,
  handleAsyncReport,
  isJsonFormat,
} from "Modules/reports/views/main/components/reportsTab/helper";

function* getDriverFormsReport(
  report,
  page,
  rowsPerPage,
  sortField,
  sortOrder,
  requestFlag
) {
  const {
    timeZone,
    sendByEmail,
    emailsTo,
    timeframeName,
    categoryName,
    delayed,
    dataResultPath,
  } = report;
  const {
    filterType,
    filterArray,
    startRange,
    endRange,
    otherFilters,
    unitsSelected,
  } = report.filters;

  const baseConditionsQuery = baseReportConditions({
    report,
    name: "DriverFormsReport",
    dataResultPath,
    timeZone,
    delayed,
    unitsSelected,
    timeframeName,
    filterType,
    filterArray,
    categoryName,
    startRange,
    endRange,
  });

  let payload = {
    conditions: {
        ...baseConditionsQuery,
        stringValues: {
          values: otherFilters?.form
        },
        sortField: {
            field: sortField || "deviceId",
            order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
    },
    limit: rowsPerPage,
    offset: page
  };

  let columnsFormat;

  if (report?.exportType === "pdf") {
    columnsFormat = [
      {
        column: "dateTime",
        header: "Time & Date",
      },
      {
        column: "address",
        header: "Address",
      },
      {
        column: "form",
        header: "Form",
      },
      {
        column: "question",
        header: "Question",
      },
      {
        column: "answer",
        header: "Answer",
      }
    ];
  } else {
    columnsFormat = [
      {
        column: "label",
        header: "Driver",
      },
      {
        column: "dateTime",
        header: "Time & Date",
      },
      {
        column: "address",
        header: "Address",
      },
      {
        column: "form",
        header: "Form",
      },
      {
        column: "question",
        header: "Question",
      },
      {
        column: "answer",
        header: "Answer",
      }
    ];
  }

  if (report.export) {
    payload = {
      ...payload,
      limit: isJsonFormat(report?.exportType, dataResultPath)
        ? rowsPerPage
        : 5000,
      offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
      conditions: {
        ...payload.conditions,
        exportOptions: {
          sendByEmail,
          emailsTo,
          format: {
            fileFormat: report?.exportType || "json",
            formatOptions: '{"orientation": "landscape"}',
          },
          culture: report.lang,
          timeZone: getTimeZone(timeZone),
          folderName: "px-customer-reports",
          filePrefixName: "DriverFormsReport",
          columns: columnsFormat,
        },
      },
    };
  }

  try {
    let data = yield call(
      clientQuery,
      `query getDriverFormsReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
                Report: getDriverFormsReport (conditions: $conditions, limit: $limit, offset: $offset) {
                  ${
                    generateReportInstance(
                      report.export,
                      dataResultPath,
                      report.exportFromTable
                    )
                      ? `reportInstanceId`
                      : `
                    items {
                        driverId
                        driverName
                        noData
                    }
                    total
                    limit
                    offset
                    category
                    reportInstanceId
                `
                  }
                }
              }`,
      payload,
      endpoints.GRAPHQL_PX_REPORTING
    );
    yield handleAsyncReport(report, data);
  } catch (exc) {
    ExceptionManager(exc, "reports/DriverFormsReport", "getDriverFormsReport");
    report.loading = false;
    yield put(reportsMainGenerateReportProgress(report));
    if (!requestFlag) {
      let flag = true;
      yield put(
        reportsMainGenerateReportUpdate(
          report,
          page,
          rowsPerPage,
          sortField,
          sortOrder,
          flag
        )
      );
    } else {
      report.loading = false;
      yield put(reportsMainGenerateReportProgress(report));
    }
  }
}

export default getDriverFormsReport;
