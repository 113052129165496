import {
  //TYPES
  REALTIME_MAP_LAYER_MAP_GET,
  REALTIME_MAP_LAYER_MAP_GET_RESPONSE,
  REALTIME_MAP_LAYER_ROUTES_GET,
  REALTIME_MAP_LAYER_ROUTES_GET_RESPONSE,
  REALTIME_MAP_LAYER_ROUTE_GET,
  REALTIME_MAP_LAYER_ROUTE_REMOVE,
  REALTIME_MAP_LAYER_ROUTE_GET_RESPONSE,
  REALTIME_MAP_LAYER_CLEAR_LAYERS,
} from "Redux/actionTypes";

const INIT_STATE = {
  vehicleTrailsLayer: [],
  loadVehicleTrailsLayer: false,
  showVehicleTrailsLayer: false,
  allRoutes: {},
  loadingAllRoutes: false,
  routes: [],
  loadingRoute: false,
  layerDrawRoute: ''
};

export default (state = INIT_STATE, action) => {
  const vehicleTrail = action?.payload;
  let currentVehicleTrails = state?.vehicleTrailsLayer;
  let currentRoutes = state?.routes;
  switch (action.type) {

    case REALTIME_MAP_LAYER_MAP_GET:
      if (action.payload.startingQuery) {
        return {
          ...state,
          loadVehicleTrailsLayer: true,
        };
      }
      return {
        ...state,
        loadVehicleTrailsLayer: true,
      };

    case REALTIME_MAP_LAYER_MAP_GET_RESPONSE:
      if (vehicleTrail.result?.id) {
        currentVehicleTrails.push(vehicleTrail.result);
        return {
          ...state,
          showVehicleTrailsLayer: true,
          loadVehicleTrailsLayer: false,
          vehicleTrailsLayer: [...currentVehicleTrails],
        };
      } else return { ...state, loadVehicleTrailsLayer: false }
    case REALTIME_MAP_LAYER_ROUTES_GET:
      return { ...state, loadingAllRoutes: true };

    case REALTIME_MAP_LAYER_ROUTES_GET_RESPONSE:
      return { ...state, loadingAllRoutes: false, allRoutes: action.payload.data };

    case REALTIME_MAP_LAYER_ROUTE_GET:
      return { ...state, loadingRoute: true };

    case REALTIME_MAP_LAYER_ROUTE_REMOVE:
      if(action.payload.data?.id){
        return { ...state, routes: currentRoutes.filter(item => item.id != action.payload.data?.id), layerDrawRoute: '' };
      }
      return { ...state };
        

    case REALTIME_MAP_LAYER_ROUTE_GET_RESPONSE:
      if(action.payload.data?.id){
        currentRoutes.push(action.payload.data)
      }
      return { ...state, loadingRoute: false, routes: [...currentRoutes], layerDrawRoute: action.payload.data?.id || 0 };
    
    case REALTIME_MAP_LAYER_CLEAR_LAYERS:
      return  { ...state, routes: [], vehicleTrailsLayer: [] }

    default:
      return state;
  }
};