// Action Types
import { 
  LEGACY_API_KEY_SEARCH,
  LEGACY_API_KEY_SEARCH_RESPONSE,
  LEGACY_API_KEY_GET,
  LEGACY_API_KEY_GET_RESPONSE,
  LEGACY_API_KEY_CREATE,
  LEGACY_API_KEY_CREATE_RESPONSE,
  LEGACY_API_KEY_UPDATE,
  LEGACY_API_KEY_UPDATE_RESPONSE,
  LEGACY_API_KEY_DELETE,
  LEGACY_API_KEY_DELETE_RESPONSE,
  LEGACY_API_KEY_GET_COUNT,
  LEGACY_API_KEY_GET_COUNT_RESPONSE,
  CLEAR_REDUX
} from "Redux/actionTypes";

/**
 * Initial State LEGACY_API_KEY
 */
const INITIAL_STATE = {
  loading: false,
  allfilters: {
    openSearch: "",
    userId: 0,
    limit: 10,
		offset: 0
  },
  registers: [],
  getLegacyApiKey: null,
  loadedRecord: false,
  total: 0,
  error: false,
  loadingTotalLegacyApiKeys: false,
  totalLegacyApiKeys: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LEGACY_API_KEY_SEARCH:
      return {
        ...state,
        registers: [],
        getLegacyApiKey: null,
        loadedRecord: false,
        error: false,
        loading: true,
      };
    case LEGACY_API_KEY_SEARCH_RESPONSE:
      return {
        ...state,
        registers: action.payload?.items,
        total: action.payload?.total,
        loading: false,
        error: action.payload?.error
      };
    case LEGACY_API_KEY_GET:
      return {
        ...state,
        loadedRecord: false,
        getLegacyApiKey: null,
        error: false,
        loading: true,
      };
    case LEGACY_API_KEY_GET_RESPONSE:
      return {
        ...state,
        getLegacyApiKey: action.payload?.getLegacyApiKey,
        loading: false,
        error: action.payload?.error
      };  
    case LEGACY_API_KEY_CREATE:
      return {
        ...state,
        getLegacyApiKey: null,
        loadedRecord: false,
        error: false,
        loading: true,
      };
    case LEGACY_API_KEY_CREATE_RESPONSE:
      return {
        ...state,
        loadedRecord: action.payload?.newLegacyApiKey,
        error: action.payload?.error,
        loading: false
      };
    case LEGACY_API_KEY_UPDATE:
      return {
        ...state,
        getLegacyApiKey: null,
        loadedRecord: false,
        error: false,
        loading: true,
      };
    case LEGACY_API_KEY_UPDATE_RESPONSE:
      return {
        ...state,
        loadedRecord: action.payload?.updateLegacyApiKey,
        error: action.payload?.error,
        loading: false
      };  
    case LEGACY_API_KEY_DELETE:
      return {
        ...state,
        getLegacyApiKey: null,
        loadedRecord: false,
        error: false,
        loading: true
      };  
    case LEGACY_API_KEY_DELETE_RESPONSE:
      return {
        ...state,
        loadedRecord: action.payload?.deleteLegacyApiKey,
        error: action.payload?.error,
        loading: false
      };

    case LEGACY_API_KEY_GET_COUNT:
      return {
        ...state,
        loadingTotalLegacyApiKeys: true,
        totalLegacyApiKeys: 0,
      };
     
    case LEGACY_API_KEY_GET_COUNT_RESPONSE:
        return {
          ...state,
          loadingTotalLegacyApiKeys: false,
          totalLegacyApiKeys: action.payload?.total,
        };

    case CLEAR_REDUX:
      return ["", "LEGACY_API_KEY"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INITIAL_STATE }
        : { ...state };  
    default:
      return state;
  }
};
