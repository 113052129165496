// Action Types
import { TrainRounded } from "@mui/icons-material";
import {
  SETTINGS_DRIVER_SEARCH,
  SETTINGS_DRIVER_SEARCH_RESPONSE,
  SETTINGS_DRIVER_CREATE,
  SETTINGS_DRIVER_CREATE_RESPONSE,
  SETTINGS_DRIVER_UPDATE,
  SETTINGS_DRIVER_UPDATE_RESPONSE,
  SETTINGS_DRIVER_DELETE,
  SETTINGS_DRIVER_DELETE_RESPONSE,
  SETTINGS_DRIVER_OPEN_CREATE_EDIT_MODAL,
  SETTINGS_DRIVER_OPEN_DELETE_MODAL,
  SETTINGS_DRIVER_OPEN_DRIVER_IMPORT_MODAL,
  SETTINGS_DRIVER_IMPORT,
  SETTINGS_DRIVER_IMPORT_RESPONSE,
  SETTINGS_DRIVER_LOAD_CSV,
  SETTINGS_DRIVER_LOAD_CSV_RESPONSE,
  SETTINGS_DRIVER_SEARCH_TRANSACTIONS,
  SETTINGS_DRIVER_SEARCH_TRANSACTIONS_RESPONSE,
  SETTINGS_DRIVER_OPEN_DRIVER_TRANSACTION_MODAL,
  SETTINGS_DRIVER_GET_URL_FILE,
  SETTINGS_DRIVER_GET_URL_FILE_RESPONSE,
  SETTINGS_DRIVER_OPEN_CLOSE_FILTERS,
  SETTINGS_DRIVER_SEARCH_TAGS,
  SETTINGS_DRIVER_SEARCH_TAGS_RESPONSE,
  SETTINGS_DRIVER_SET_ENTITY,
  SETTINGS_DRIVER_DELETE_DRIVERS_TAG,
  SETTINGS_DRIVER_DELETE_DRIVERS_TAG_RESPONSE,
  SETTINGS_DRIVER_OPEN_DELETE_DRIVERS_TAG_MODAL,
  SETTINGS_DRIVER_AVATAR,
  SETTINGS_DRIVER_AVATAR_RESPONSE,
  CLEAR_REDUX,
  SETTINGS_DRIVER_OPEN_CHANGE_STATUS_MODAL,
  SETTINGS_DRIVER_CHANGE_STATUS_RESPONSE,
  SETTINGS_DRIVER_CHANGE_STATUS
} from "Redux/actionTypes";

/**
 * Initial State Driver
 */
const INITIAL_STATE = {
  loading: false,
  total: 0,
  filters: {
    includeInactiveDrivers: true,
    unassigned: true,
    openSearch: "",
    tagIds: [],
    limit: 0,
    offset: 0
  },
  registers: [],
  driverInfo:{},
  tags: [],
  tagsTotal: 0,
  errorTags: false,
  tagsFilters: {
    openSearch: "",
    limit: 0,
    offset: 0
  },

  transactions: [],
  driverResponse: null,
  driverImportResponse: null,
  driverGroup: null,
  urlFileCsv: null,
  defaultTabs: [
    {
      id: "chat",
      name: "Chat"
    },
    {
      id: "driver",
      name: "Driver"
    },
    {
      id: "forms",
      name: "Forms"
    },
    {
      id: "map",
      name: "Map"
    },
    {
      id: "stops",
      name: "Stops"
    }
  ],
  errorSearch: false,
  openCreateEdit: false,
  openImport: false,
  openTransaction: false,
  openDelete: false,
  openChangeStatus: false,
  csvFile: null,
  selectedItems: [],
  openFilters: false,
  driverAvatar:null,
  entity: { id: "drivers" },
  loadingDriversTag: false,
  openDriversTagDelete: false,
  driversTagDeleteResponse: null,
  changeStatusResponse: null,
  loadingDriversTagDelete: false,
  loadingChangeStatusDriver: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETTINGS_DRIVER_SEARCH:
      return {
        ...state,
        loading: true,
        errorSearch: false,
        filters: action.payload
      };
    case SETTINGS_DRIVER_SEARCH_RESPONSE:
      return {
        ...state,
        registers: action.payload?.items,
        total: action.payload?.total,
        loading: false,
        errorSearch: action.payload?.error
      };
    case SETTINGS_DRIVER_CREATE:
      return {
        ...state,
        driverResponse: null,
        loading: true
      };
    case SETTINGS_DRIVER_CREATE_RESPONSE:
      return {
        ...state,
        driverResponse: action.payload?.driverResponse,
        driverAvatar:null,
        tags:[],
        loading: false
      };
    case SETTINGS_DRIVER_UPDATE:
      return {
        ...state,
        driverResponse: null,
        loading: TrainRounded
      };
    case SETTINGS_DRIVER_UPDATE_RESPONSE:
      return {
        ...state,
        driverResponse: action.payload?.driverResponse,
        driverAvatar:null,
        tags:[],
        loading: false
      };
    case SETTINGS_DRIVER_DELETE:
      return {
        ...state,
        driverResponse: null,
        loading: true
      };
    case SETTINGS_DRIVER_DELETE_RESPONSE:
      return {
        ...state,
        driverResponse: action.payload?.driverDelete,
        loading: false
      };
    case SETTINGS_DRIVER_OPEN_CREATE_EDIT_MODAL:
      return {
        ...state,
        openCreateEdit: action.payload?.open,
        driverGroup: action.payload?.driver,
        driverResponse: null
      };
    case SETTINGS_DRIVER_OPEN_DELETE_MODAL:
      return {
        ...state,
        openDelete: action.payload?.open,
        selectedItems: action.payload?.items,
        driverResponse: null
      };
    case SETTINGS_DRIVER_OPEN_CHANGE_STATUS_MODAL:
      return {
        ...state,
        openChangeStatus: action.payload?.open,
        selectedItems: action.payload?.items,
        changeStatusResponse: null
      };
    case SETTINGS_DRIVER_OPEN_DRIVER_IMPORT_MODAL:
      return {
        ...state,
        openImport: action.payload?.open
      };
    case SETTINGS_DRIVER_OPEN_DRIVER_TRANSACTION_MODAL:
      return {
        ...state,
        openTransaction: action.payload?.open
      };
    case SETTINGS_DRIVER_IMPORT:
      return {
        ...state,
        driverResponse: null,
        loading: true
      };
    case SETTINGS_DRIVER_IMPORT_RESPONSE:
      return {
        ...state,
        driverImportResponse: action.payload?.driverImport,
        loading: false
      };
    case SETTINGS_DRIVER_LOAD_CSV:
      return {
        ...state,
        csvFile: null,
        loading: true,
        transactions: [],
      };
    case SETTINGS_DRIVER_LOAD_CSV_RESPONSE:
      return {
        ...state,
        csvFile: action.payload?.file,
        loading: false,
      };
    case SETTINGS_DRIVER_SEARCH_TRANSACTIONS:
      return {
        ...state,
        openCreateEdit: false,
        openImport: false,
        openDelete: false,
        loading: true,
        errorSearch: false,
      };
    case SETTINGS_DRIVER_SEARCH_TRANSACTIONS_RESPONSE:
      return {
        ...state,
        transactions: action.payload?.items,
        loading: false,
        errorSearch: action.payload?.error
      };
    case SETTINGS_DRIVER_GET_URL_FILE:
      return {
        ...state,
        urlFileCsv: null,
        loading: true,
      };
    case SETTINGS_DRIVER_GET_URL_FILE_RESPONSE:
      return {
        ...state,
        urlFileCsv: action.payload,
        loading: false,
      };

    case SETTINGS_DRIVER_OPEN_CLOSE_FILTERS:
      return {
        ...state,
        openFilters: action?.payload?.open,
      };

    case SETTINGS_DRIVER_SEARCH_TAGS:
      return {
        ...state,
        loadingDriversTag: true,
        errorTags: false,
        tagsFilters: action.payload
      };

    case SETTINGS_DRIVER_SEARCH_TAGS_RESPONSE:
      return {
        ...state,
        tags: action.payload?.items,
        tagsTotal: action.payload?.total,
        errorTags: action.payload?.error,
        loadingDriversTag: false
      };

    case SETTINGS_DRIVER_SET_ENTITY:
      return {
        ...state,
        entity: action.payload?.entity
      };

    case SETTINGS_DRIVER_DELETE_DRIVERS_TAG:
      return {
        ...state,
        driversTagDeleteResponse: null,
        loadingDriversTagDelete: true
      };

    case SETTINGS_DRIVER_DELETE_DRIVERS_TAG_RESPONSE:
      return {
        ...state,
        driversTagDeleteResponse: action.payload?.response,
        loadingDriversTagDelete: false
      };
    case SETTINGS_DRIVER_CHANGE_STATUS:
      return {
        ...state,
        changeStatusResponse: null,
        loadingChangeStatusDriver: true
      };

    case SETTINGS_DRIVER_CHANGE_STATUS_RESPONSE:
      return {
        ...state,
        changeStatusResponse: action.payload?.response,
        loadingChangeStatusDriver: false
      };

    case SETTINGS_DRIVER_OPEN_DELETE_DRIVERS_TAG_MODAL:
      return {
        ...state,
        openDriversTagDelete: action.payload?.open,
        selectedItems: action.payload?.items,
        driversTagDeleteResponse: null
      };

    case SETTINGS_DRIVER_AVATAR:
      return {
        ...state,
        driverAvatar: null,
        loading: true
      };
    case SETTINGS_DRIVER_AVATAR_RESPONSE:
      return {
        ...state,
        driverAvatarPresigned: null,
        driverAvatar: action.payload,
        loading: false
      };
    case CLEAR_REDUX:
      return ["", "SETTINGS_DRIVER"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INITIAL_STATE }
        : { ...state };
    default:
      return state;
  }
};
