import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { clientQuery, clientMutation } from '../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../../../core/logManager';

import { resetALL } from '../../../../../../../.globals/redux/actions';

import moment from "moment";

//STATE INITIAL
const INIT_STATE = {
    open: false,
    data: { total: 0, items: [] },
    drivers: [],
    error: null,
    filters: {
        openSearch: "",
        sortBy: "name",
        driverId: null,
        dateRange: {
            start: moment.max(moment()).startOf('day').toDate(),
            end: moment.max(moment()).endOf('day').toDate()
        },
        limit: 1000,
        offset: 0,
    }
};

const route = '/modules/settings/views/driver/components/timeClock/redux/slice';

export const searchDriverStatusLog = createAsyncThunk('driver/components/timeClock/searchDriverStatusLog', async ({ limit, offset, openSearch, driverId, sortBy, dateRange }, { rejectWithValue, dispatch }) => {
    try {
        let conditions = [];
        let sort = [];

        let condition = {};
        if (openSearch) {
            condition = {
                fields: ["name"],
                terms: [openSearch],
                exact: false
            }
        }

        if (dateRange?.start && dateRange?.end) {
            condition['range'] = {
                fields: ["createdOn"],
                //fields: ["statusLogOn"],
                GTE: dateRange?.start,
                LTE: dateRange?.end
            }
        }

        if (condition?.fields || condition?.range) {
            conditions.push(condition);
        }

        if (driverId) {
            conditions.push({
                fields: ["driverId"],
                terms: [driverId],
                exact: true
            });
        }

        // if (sortBy) {
        sort.push({
            field: "statusLogOn",
            order: "ASC"
        })
        // }

        const response = await clientQuery(
            `
            query searchDriverStatusLog (
                $conditions: [DriverStatusLogSearchInput], 
                $sort: [DriverStatusLogSortInput], 
                $limit: Int, 
                $offset: Int
            ) {
                data: searchDriverStatusLog (
                    conditions: $conditions, 
                    sort: $sort, 
                    limit: $limit, 
                    offset: $offset
                ) {
                    items {
                        id
                        driverId
                        statusId
                        driverStatus {
                            id
                            name
                            accumulateTime
                        }
                        latitude
                        longitude
                        address
                        statusLogOn
                        durationSeconds
                        createdOn
                        createdBy
                    }
                    total
                }
            }
            `,
            {
                conditions,
                sort,
                limit,
                offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response?.data) {
            throw 'Error while getting driver status log';
        }

        const { items, total } = response?.data;
        return { items: items, total: total || 0 };
    } catch (exc) {
        ExceptionManager(exc, route, 'searchDriverStatusLog');
        return rejectWithValue(exc)
    }
});

export const searchTimeCardDrivers = createAsyncThunk('driver/components/timeClock/searchTimeCardDrivers', async ({ }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query searchDrivers ($includeInactiveDrivers: Boolean, $openSearch: String, $limit: Int, $offset: Int, $fsmDriver: Boolean, $tagIds: [Int], $unitIds: [Int]) {
                drivers: searchDrivers (includeInactiveDrivers: $includeInactiveDrivers, openSearch: $openSearch, limit: $limit, offset: $offset, fsmDriver: $fsmDriver, tagIds: $tagIds, unitIds: $unitIds) {
                    name
                    driverId
                    status
                    unitId
                    unit {
                        id
                        label
                    }
                    driverStatus
                    connected
                    lastConnected
                }
            }
            `,
            {
                fsmDriver: true,
                tagIds: []
            },
            endpoints.GRAPHQL_GENERAL
        );

        if (!response?.drivers) {
            throw 'Error while getting FSM drivers';
        }

        return response?.drivers;
    } catch (exc) {
        ExceptionManager(exc, route, 'searchTimeCardDrivers');
        return rejectWithValue(exc)
    }
});

//Slice
const driverStatusLogListRedux = createSlice({
    name: 'driverStatusLog/list',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openListModal(state, action) {
            state.open = true;
            state.items = [];
        },
        closeListModal(state) {
            state.open = false;
            state.items = [];
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(searchDriverStatusLog.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(searchDriverStatusLog.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.filters = action?.meta?.arg;
        })
        builder.addCase(searchDriverStatusLog.rejected, (state, action) => {
            state.loading = false;
            state.data = { total: 0, items: [] };
            state.error = action.payload;
        })

        builder.addCase(searchTimeCardDrivers.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(searchTimeCardDrivers.fulfilled, (state, action) => {
            state.loading = false;
            state.drivers = action.payload;
        })
        builder.addCase(searchTimeCardDrivers.rejected, (state, action) => {
            state.loading = false;
            state.drivers = [];
            state.error = action.payload;
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = driverStatusLogListRedux;

// Extract and export each action creator by name
export const {
    reset,
    openListModal,
    closeListModal,
} = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;
