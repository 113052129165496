import de from 'date-fns/locale/de/index';
import {
    MAP_LANDMARKS_EDIT_CREATE_OPEN_MODAL,
    MAP_LANDMARKS_EDIT_CREATE_OPEN_MODAL_EDIT,
    MAP_LANDMARKS_GET_LANDMARK,
    MAP_LANDMARKS_GET_LANDMARK_RESPONSE,
    MAP_LANDMARKS_EDIT_CREATE_LANDMARK,
    MAP_LANDMARKS_EDIT_CREATE_LANDMARK_RESPONSE,
    MAP_LANDMARKS_EDIT_IMPORT_OPEN_MODAL,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    open: false,
    id: null,
    landmark: null,
    loadingLandmark: false,
    response: null,
    loadingResponse: false,
    openImport: false,
    editData: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MAP_LANDMARKS_EDIT_CREATE_OPEN_MODAL:
            return {
                ...state,
                open: action.payload.open,
                id: action.payload.id,
                editData: null,
                landmark: null,
                loadingLandmark: false,
                response: null,
                loadingResponse: false
            };

        case MAP_LANDMARKS_EDIT_CREATE_OPEN_MODAL_EDIT:
            return {
                ...state,
                open: action.payload.open,
                id: 0,
                editData: action.payload.data,
                landmark: null,
                loadingLandmark: false,
                response: null,
                loadingResponse: false
            };

        case MAP_LANDMARKS_GET_LANDMARK:
            return {
                ...state,
                landmark: null,
                loadingLandmark: true
            };

        case MAP_LANDMARKS_GET_LANDMARK_RESPONSE:
            return {
                ...state,
                landmark: action.payload.data,
                loadingLandmark: false
            };

        case MAP_LANDMARKS_EDIT_CREATE_LANDMARK:
            return {
                ...state,
                response: null,
                loadingResponse: true
            };

        case MAP_LANDMARKS_EDIT_CREATE_LANDMARK_RESPONSE:
            return {
                ...state,
                response: action.payload.data,
                loadingResponse: false
            };

        case MAP_LANDMARKS_EDIT_IMPORT_OPEN_MODAL:
            return {
                ...state,
                openImport: action.payload.open
            };

        case CLEAR_REDUX:
            return ["", "MAP_LANDMARKS_EDIT_CREATE"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
      return state;
    }
}