import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';
// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

import {baseReportConditions, generateReportInstance, getTimeZone, handleAsyncReport, isJsonFormat} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getDiagnosticIndicatorsReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
    const {timeZone, sendByEmail, emailsTo, timeframeName, categoryName, delayed, dataResultPath} = report;
    const {filterType, filterArray, startRange, endRange, otherFilters, unitsSelected} = report.filters


    const baseConditionsQuery = baseReportConditions({
        report,
        name: 'DiagnosticIndicatorsReport',
        dataResultPath,
        timeZone,
        delayed,
        unitsSelected,
        timeframeName,
        filterType,
        filterArray,
        categoryName,
        startRange,
        endRange
    })

    try {
        let payload = {
            conditions: {
                ...baseConditionsQuery,
                sortField: {
                    field: sortField || "deviceId",
                    order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                }
            },
            limit: rowsPerPage,
            offset: page
        };

        if (report.export) {
            payload = {
                ...payload,
                limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
                offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
                conditions: {
                    ...payload.conditions,
                    exportOptions: {
                        sendByEmail,
                        emailsTo,
                        format: {
                            fileFormat: report?.exportType || "json",
                            formatOptions: "{\"orientation\": \"landscape\"}"
                        },
                        culture: report.lang,
                        timeZone: getTimeZone(timeZone),
                        folderName: "px-customer-reports",
                        filePrefixName: "DiagnosticIndicatorsReport",
                        columns: [
                                {
                                    "column": "label",
                                    "header": "Label"
                                },
                                {
                                    "column": "dateTime",
                                    "header": "Time & Date"
                                },
                                {
                                    "column": "eventName",
                                    "header": "Event"
                                },
                                {
                                    "column": "abs",
                                    "header": "ABS Active"
                                },
                                {
                                    "column": "absActiveLamp",
                                    "header": "ABS Indicator"
                                },
                                {
                                    "column": "airBagIndicator",
                                    "header": "AirBag Indicator"
                                },
                                {
                                    "column": "brakeIndicator",
                                    "header": "Brake Indicator"
                                },
                                {
                                    "column": "brake",
                                    "header": "Brake Switch Statis"
                                },
                                {
                                    "column": "coolantLight",
                                    "header": "Coolant Light"
                                },
                                {
                                    "column": "cruiseControl",
                                    "header": "Cruise Control"
                                },
                                {
                                    "column": "ignitionStatus",
                                    "header": "Ignition Status"
                                },
                                {
                                    "column": "milStatus",
                                    "header": "Mil Status"
                                },
                                {
                                    "column": "oilPressure",
                                    "header": "Oil Pressure"
                                },
                                {
                                    "column": "pto",
                                    "header": "PTO"
                                },
                                {
                                    "column": "seatBelt",
                                    "header": "Seat Belt"
                                },
                                {
                                    "column": "dtc",
                                    "header": "DTC"
                                }
                        ]
                    }
                }
            }
        }
        let data = yield call(
            clientQuery,
            `query getDiagnosticIndicatorsReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
                Report: getDiagnosticIndicatorsReport (conditions: $conditions, limit: $limit, offset: $offset) {
                  ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
                        `reportInstanceId`
                        : `
                    items {
                        id
                        idLabel
                        noData
                    }
                    total
                    limit
                    offset
                    category
                    reportInstanceId
                `}
                }
              }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
        yield handleAsyncReport(report, data);

    } catch (exc) {
        ExceptionManager(exc, 'reports/DiagnosticIndicatorsReport', 'getDiagnosticIndicatorsReport');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}

export default getDiagnosticIndicatorsReport;
