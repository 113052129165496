import React from "react";
import "./style.scss";
import { Icons } from "@gpstrackit/gtc-react-controls";
import moment from "moment";
import { GetFormatForMoment } from "Core/data/Helpers";
import { converSpeedToMph } from "../../utils";
import { focusTrailFromEvent } from "../historyOverView/utils";
import { eventsWithoutSpeed } from "Components/Map/utils";

const COLOR_SELECTED = '#e5e5e5'


const TrailItem = ({
  element,
  count,
  timezone,
  dateformat,
  filterTrail,
  sliceTrails,
  numberItem,
  setNumberSelected,
  unitType,
  isKph
}) => {

  const { color, name, trail, descriptionDetail } = element;
  const { speed, speedLimit } = trail;
  const convertSpeed = converSpeedToMph(speed, unitType)
  const convertLimitSpeed = converSpeedToMph(speedLimit, unitType)
  const formatSpeed = isKph ? 'kph' : 'mph'


  return (
    <div
      id={`trail_${moment(element?.unitTime).utc().unix()}`}
      style={{background: sliceTrails?.length > 0 && sliceTrails?.length === count && COLOR_SELECTED}}
      className="incident_trail_container"
      onClick={() => focusTrailFromEvent(`event_${moment(element?.unitTime).utc().unix()}`)}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="incident_trail_title">
          <Icons color={color} name="play_circle" size="medium" />
          <div
            onClick={() => {
              setNumberSelected && setNumberSelected(count);
              filterTrail && filterTrail(numberItem);
            }}
          >
            {count} - {name}
          </div>
        </div>
        {/* {sliceTrails?.length > 0 && sliceTrails?.length === count && (
          <div onClick={() => clearTrailSelected && clearTrailSelected()}>
            <Icons name="filter_alt_off" size="medium" />
          </div>
        )} */}
      </div>
      <div
        onClick={() => {
          setNumberSelected && setNumberSelected(count);
          filterTrail && filterTrail(numberItem);
        }}
        style={{ marginTop: "5px" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              margin: "0 5px 0 0",
              display: "flex",
            }}
          >
            <Icons name="access_time" size="small" />
          </p>
          <p
            style={{
              margin: "0 5px 0 0",
              display: "flex",
            }}
          >
            {timezone
              ? moment(trail?.unitTime).tz(timezone).format("hh:mm:ss a")
              : moment(trail?.unitTime).format("hh:mm:ss a")}
            ,
          </p>
          <p
            style={{
              margin: "0 5px 0 0",
              display: "flex",
            }}
          >
            {timezone && moment.tz(timezone).zoneAbbr()}
          </p>
          <p
            style={{
              margin: "0 5px 0 0",
              display: "flex",
            }}
          >
            <Icons name="event" size="small" />
          </p>
          <p
            style={{
              margin: "0 5px 0 0",
              display: "flex",
            }}
          >
            {timezone
              ? moment(trail?.unitTime)
                  .tz(timezone)
                  .format(`${GetFormatForMoment(dateformat)}`)
              : moment(trail?.unitTime).format(
                  `${GetFormatForMoment(dateformat)}`
                )}
          </p>
        </div>
        <div style={{ marginTop: "5px", display: "flex" }}>
          <p
            style={{
              margin: "0 5px 0 0",
              display: "flex",
            }}
          >
            <Icons name="room" size="small" />
          </p>
          {descriptionDetail}
        </div>
        <div style={{
            width: '95%',
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            <div style={{display: 'flex', marginTop: '5px'}}>
              {trail?.landmark?.name &&
                <>
                  <Icons name="flag" size="small" />
                  <p style={{margin: 0}}>{trail?.landmark?.name}</p>
                </>
              }
            </div>
            <div style={{
                display: 'flex', 
                marginTop: '5px', 
                alignItems: 'center',
                fontWeight: 500,
                color: "#4f4d4d"
              }}>
              <Icons name="speed" size="small" />
              <p style={{
                margin: eventsWithoutSpeed.includes(name) ? '0 5px 0 0' : '0px'
                }}>
                {convertSpeed ? Number(convertSpeed).toFixed(0) : '0'}
              </p>
              {
                !eventsWithoutSpeed.includes(name) &&
                <div style={{
                  display: 'flex',
                  border: '1px solid black',
                  margin: '0 3px 0 3px',
                  padding: '2px'
                }}>
                  {convertLimitSpeed ? Number(convertLimitSpeed).toFixed(0) : '?'}
                </div> 

              }
              {formatSpeed} 
            </div>
        </div>
      </div>
    </div>
  );
};

export default TrailItem;
