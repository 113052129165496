//View Keys
export default {
    serviceItemsTab_delete: 'Delete',
    serviceItemsTab_deleteConfirmation: 'Are you sure you want to delete the Service Item',
    serviceItemsTab_createServiceItem: 'Create New Service Item',
    serviceItemsTab_serviceItemLabel: 'SERVICE ITEM LABEL',
    serviceItemsTab_serviceItemInputPlaceholder: "Service Item label",
    serviceItemsTab_createdNewServiceItem: 'Service Item added Successfully',
    serviceItemsTab_updatedSuccessfully: 'Service Item Updated Successfully',
    serviceItemsTab_Title: 'Service Items',
    serviceItemsTab_deleteConfirmationSuccessfully: 'Service Item Removed Successfully',
  };