import React, { useState, useEffect, Fragment } from 'react';

import { useDownload } from 'Core/hooks';
import { InputSearch } from 'Components';
import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon';
import PropTypes from 'prop-types';
import './pill.scss';
import { useIntl } from 'react-intl';


/**
 * Component to show a pill.
 * @property {boolean} outlined 
 * @property {object} item 
 * @property {string} textField - Pill property to use as its label. e.g. Pill={ name: 'testName' }, thus textField="name"
 * @property {string} colorField 
 * @property {boolean} isDownload 
 * @property {Object} style -Extra styles for the component.
 * @property {boolean} noDownloadButton -to show button download
 */
const Pill = (props) => {
	const { outlined, item, textField, colorField, isDownload, noDelete, colorSelection, noDownloadButton, urlDownloadFile, isDisabled } = props;
	const whites = ['white', '#FFF', '#FFFFFF', 'FFF', 'FFFFFF', 'fff', 'ffffff'];
	let color = colorField === "default" ? colorSelection || "00ADFF" : colorField ? item[colorField] : item?.color;
	const download = useDownload();
	const getColor = (color, defaultColor) => {
		let newColor = defaultColor || 'grey';

		if (color && !whites.includes(color)) {
			if (color.indexOf('#') > -1) {
				newColor = color;
			} else {
				newColor = `#${color}`;
			}
		}

		return newColor;
	};

	const styles = {
		border: `${isDownload ? '0px' : '1px'} solid ${colorField === "default" && outlined ? "#F1F1F1" : getColor(color || null)}`,
		// border: "#333",
		color: colorField === "default" && outlined ? "black" : outlined ? getColor(color || null) : whites.includes(color || null) ? 'grey' : 'white',
		// color: "#FF0000",
		backgroundColor: colorField === "default" && outlined ? "#F1F1F1" : outlined ? 'white' : getColor(color || null, whites.includes(color || null) && 'black')
		// backgroundColor: "#0000FF",
	};

	const propStyle = props.style ? props.style : {};

	const getUrlFile = () => {
		download.getUrlFile(item?.id);
	}

	return (
		<Fragment>
			{
				isDownload ?
					(
						<div className="pillDownload" style={styles}>
							<div className="pillDownloadLabelContainer">
								<span className="pillDownloadLabel">
									{textField && item ? item[textField] : item?.originalName}
								</span>
							</div>
							<div className="pillContentRight">
								{
									!noDelete &&
									<div>
										<span className="pillDownloadLabel cursorPointer" onClick={() => {
											props.onClose(item)
										}}><Icon style={{ fontSize: 14 }}>close</Icon></span>
									</div>
								}
                {
                  !noDownloadButton
                  &&
                  <div className="pillContentRightIconDownload cursorPointer" onClick={urlDownloadFile?urlDownloadFile:getUrlFile}>
                    <Icon className="pillDownloadIcon">get_app</Icon>
                  </div>
                }								
							</div>

						</div>
					)
					:
					(
						<Chip
							className={`${props.className || ""}`}
							label={textField && item ? item[textField] : item?.name}
							style={{ ...styles, ...propStyle }}
							clickable={props.clickable != undefined ? props.clickable : true}
							onClick={() => {
								if (props.onClick) {
									props.onClick(item);
								}
							}}
							disabled={isDisabled}
						/>
					)
			}
		</Fragment>
	);
};

/**
 * Build a group of pills.
 * @param {Array} values - The selected pills.
 * @param {Array} options - Options list of pills
 * @param {string} textField - Option prop to be used as the pill label.
 * @param {string} colorField - Pill color.
 * @param {boolean} multiple - Indicates if there is more than one pill.
 */
export const PillsGroup = (props) => {
	const {
		values,
		options,
		textField,
		colorField,
		itemsStyle = {},
		idPropName = "id",
	} = props;

	return (
		<div>
			{props.label && <div className="pills-group-label">{props.label}</div>}

			<div>
				{options &&
					options.length > 0 &&
					options.map((item, index) => {
						let selected = values ? values.find((selectedItem) => {
							return selectedItem[idPropName] === item[idPropName];
						}) : null;

						return (
							<Pill
								key={index}
								item={item}
								style={{ marginBottom: 8, ...itemsStyle }}
								outlined={!selected}
								onClick={(item) => {
									let newValue = [];

									if (props.multiple && item[idPropName] != -1 && item[idPropName] != 0) {
										if (selected) {
											newValue = values.filter(
												(selectedItem) => selectedItem[idPropName] != item[idPropName] && selectedItem[idPropName] != -1 && selectedItem[idPropName] != 0,
											);
										} else {
											newValue = newValue.concat(values, item);
											newValue = newValue.filter((newSelectedItem) => newSelectedItem[idPropName] != -1 && newSelectedItem[idPropName] != 0);
										}
									} else {
										newValue = [item];
									}

									if (props.onChange) {
										props.onChange(props.multiple ? newValue : newValue[0]);
									}
								}}
								textField={textField}
								colorField={colorField}
							/>
						);
					})}
			</div>
		</div>
	);
};

export const PillsGroupSearch = (props) => {

	const { label, value, options, onChange, textField, colorField, multiple, removeMargin, idPropName, showOptions } = props;
	const [searchText, setSearchText] = useState('');
	const [defaultOptions, setDefaultOptions] = useState((options ? options : []).slice(0, 7));
	const { messages } = useIntl();


	useEffect(() => {
		setDefaultOptions((options ? options : []).slice(0, 7));
	}, [options]);

	useEffect(() => {
		if (showOptions) {
			setDefaultOptions(options)
		}
	}, [showOptions])

	const handleSearch = (text) => {
		setSearchText(text);

		let newOptions = [];
		options.forEach((option) => {
			let match = [true];
			let name = textField ? option[textField] : option.name;
			name = name ? name.toString().toLowerCase() : '';
			text = text ? text.toString().toLowerCase() : null;

			if (text && name.indexOf(text) < 0) {
				match.push(false);
			}

			if (!match.includes(false)) {
				newOptions.push(option);
			}
		});

		setDefaultOptions(newOptions.length === options.length ? newOptions.slice(0, 7) : newOptions);
	};

	return (
		<div>
			{options.length > 7 && (
				<div>
					<InputSearch
						id="search-groups"
						searchText={searchText}
						onSearch={handleSearch}
						label={messages['search']}
						placeholder={messages['search']}
					/>
				</div>
			)}

			<div style={{ marginTop: !removeMargin && 8, marginBottom: !removeMargin && 20 }}>
				<PillsGroup
					label={label}
					options={defaultOptions}
					values={value}
					onChange={onChange}
					textField={textField}
					colorField={colorField}
					multiple={multiple}
					idPropName={idPropName}
				/>

				{!searchText && (
					<div style={{ marginTop: 7 }}>
						{options.length === defaultOptions.length ? (
							<span
								className="show-all-text"
								onClick={() => setDefaultOptions((options ? options : []).slice(0, 7))}
							>
								{options.length > 7 ? messages['show_less'] : ''}
							</span>
						) : (
								<span className="show-all-text" onClick={() => setDefaultOptions(options)}>
									{messages['show_all']} ({options.length})
								</span>
							)}
					</div>
				)}
			</div>
		</div>
	);
};

export default Pill;

Pill.propTypes = {
	item: PropTypes.object.isRequired,
};

PillsGroup.propTypes = {
	options: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
};

PillsGroupSearch.propTypes = {
	options: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
};
