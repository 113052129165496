// Action Types
import {
  VIDEO_MAIN_OPEN_ADVANCED_FILTERS,
} from 'Redux/actionTypes';

/**
 * Initial State for Clip Request advanced filters.
 */
const INITIAL_STATE = {
  loading: false,
  open: false,
  parentTab: "Events"
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
    case VIDEO_MAIN_OPEN_ADVANCED_FILTERS:
      return { 
        ...state, 
        open: action.payload.open, 
        parentTab: action.payload.parentTab
      };

    default:
      return state;
  }
}