export default {
    "fsm_settings_title": "FSM Settings",
    "fsm_settings_form": "Forms",
    "fsm_settings_form_tags": "Tags: ",
    "fsm_settings_form_edit": "Edit Form ",
    "fsm_settings_form_new": "New Form ",
    "fsm_settings_form_selectAll": "Select All",
    "fsm_settings_form_title": "Title",
    "fsm_settings_form_name": "Name",
    "fsm_settings_form_questions": "Questions",
    "fsm_settings_form_answer": "Answer",
    "fsm_settings_form_addQuestion": "Add Question",
    "fsm_settings_form_nameRequired": "Please Complete field Name",
    "fsm_settings_form_tagsRequired": "Please Complete field Tags",
    "fsm_settings_form_questionsRequired": "Please fill in the question fields",
    "fsm_settings_form_delete_detail":'Are you sure you want to delete the following Forms?',
    "fsm_settings_form_delete_warning":'This action cannot be undone.',
    "fsm_settings_form_delete_title":'Delete Forms',
    "fsm_settings_form_create_success": "Form Created",
    "fsm_settings_form_update_success": "Form Updated",
    "fsm_settings_form_delete_success": "Forms Deleted",
    "fsm_settings_form_error_answer_duplicate": "The answer already exists",
    "fsm_settings_form_error_question_duplicate": "There are repeated questions",
    "fsm_settings_form_no_more_items": "No more items",
    "fsm_settings_form_no_items_found": "No items found"
};