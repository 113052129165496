import React from "react";
import cx from 'classnames';
import { Grid } from '@mui/material';
import { parseUrl } from 'Core/data/Helpers';

function FooterResellers({current_reseller}) {
  console.log(current_reseller)
    return (
      <Grid className="login-footer-ctn">
        <Grid
          item
          className={cx(
            `login-item-footer`,
            `${
              (current_reseller.phone || current_reseller.address) &&
              "login-border-right"
            }`
          )}
        >
          <a
            href={current_reseller.homePageUrl}
            target="_blank"
            rel="noreferrer"
          >
            {parseUrl(current_reseller.homePageUrl)}
          </a>
        </Grid>
        <Grid
          item
          className={cx(
            `login-item-footer`,
            `${current_reseller.phone && "login-border-right"}`
          )}
        >
          {current_reseller.address}
        </Grid>
        <Grid item className="login-item-footer">
          {current_reseller.phone}
        </Grid>
      </Grid>
    );
}

export default FooterResellers;
