import React, { Fragment, useState, useEffect, useCallback } from 'react';

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from '@mui/material/Grid';
import TableSortLabel from "@mui/material/TableSortLabel";
import { Icon, Collapse, IconButton, TableContainer } from '@mui/material';
import cx from 'classnames';
import { Toolbar } from 'Components/Table';
import { Button, LinearProgress, Select } from 'Components';

import './table.scss';
import Pagination from './pagination'

import { Loading } from 'Components'

import {
  getDriversFromDriverGroupSelected,
  getUnitsFromDriverGroupSelected
} from 'Modules/reports/views/main/components/reportsTab/helper';
import { useIntl } from 'react-intl';

export const Column = (props) => {
  return (
    <div></div>
  );
};

export const Summary = (props) => {
  return (
    <div className={cx('summary', props.className && props.className)}>
      <span className={cx('', props.title && 'title', props.uppercase && 'uppercaseSummary',
      )}>{props.summaryMessage}</span>
      {props.value &&
        <span>{props.value || ''}</span>
      }
    </div>
  )
}

const TableComponent = (props) => {
  const { loading, data, groupField, customGroupArea, enableSorting, defaultSortField, defaultSortOrder, totalItems, groupTotal, footerField, hiddenPagination, renderExpandableRow, idRenderExpandableRow, hideHeader, hideNumberOfRows, numberofRows, propertyToShowExpandable, hideSelectorUnitsDriver, itemsSelector, onChangeSelector, valueSelector, textFieldSelector, totalArea, centerPagination, testPerPage, placeholderSelector, generateExport, openExpandableRowByDefault, renderSubTotalTable, classNameCell, hiddenColumns, loadingExport, asyncLoading, height, topSpaceSize = 365, heightAuto, localPagination , units, drivers, unitGroupField, unitSingleField, serverSideTable, name, isExpandbleTable, pinReport, filterType, showRangeDays, startRange, endRange, showExpandable, noData } = props

  const [sortField, setSortField] = useState(defaultSortField)
  const [sortOrder, setSortOrder] = useState(defaultSortOrder)
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState(props.data || []);
  const [rowsCount, setRowsCount] = useState(totalItems || rows ? rows.length : 0);
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [openCollapsedRows, setOpenCollapsedRows] = React.useState([]);
  const {messages}  = useIntl()

  useEffect(()=> {
    setRowsCount(totalItems);
    processData({});
  }, [JSON.stringify(props.data), totalItems])

  useEffect(() => {
    if (openExpandableRowByDefault && data.length) {
      const openAll = data.map((item, indexCell) => {
        return indexCell
      });
      setOpenCollapsedRows([...openAll]);
    }
  }, [openExpandableRowByDefault, data])
  /***
   * Local Pagination ProcessData
   */
  useEffect(() => {
    if(localPagination){
      processData({});
    }
  }, []);

  const filterUnitsDrivers = (newRows, currentGroupsId, filterType) => {
    const result = newRows.filter(row => {
      const filterLowerFirstLetter = filterType.charAt(0).toUpperCase() + filterType.slice(1);
      const id = row.Id || row.id || row[filterLowerFirstLetter];
      let isInTheGroup = false;
      currentGroupsId.forEach(element => {
        if(element.id == id) {
          isInTheGroup = true;
        }
      });
      return isInTheGroup;
    })
    return result
  }

  const processData = ({
    groupsId,
    newPage,
    newRowsPerPage,
    //newCustomValue
  }) => {
    let newRows = JSON.parse(JSON.stringify(props?.data || []));
    if(!serverSideTable){
      let rowsCount = 0;
      let currentGroupsId = groupsId || valueSelector;
      if(currentGroupsId?.length && !isExpandbleTable){
        //if(filterType === 'unitGroupId'){
        //  newRows = getUnitsFromDriverGroupSelected(units, currentGroupsId, newRows, unitGroupField, unitSingleField)
        if(filterType === 'deviceId' || filterType === 'driverId'){
          newRows = filterUnitsDrivers(newRows, currentGroupsId, filterType);
        } else if(filterType === 'driverGroupId'){
          newRows = getDriversFromDriverGroupSelected(newRows, currentGroupsId, drivers)
        }
      }

      //Filtering
      rowsCount = newRows.length;
      setRowsCount(rowsCount);
      //Pagination
      let currentPage = newPage != undefined ? newPage : page;
      let currentRowsPerPage = newRowsPerPage != undefined ? newRowsPerPage : rowsPerPage;
      newRows = newRows?.slice(
        (currentPage - 1) * currentRowsPerPage,
        (currentPage - 1) * currentRowsPerPage + currentRowsPerPage,
      );
      if (currentRowsPerPage != rowsPerPage) {
        newRows = newRows?.slice(0 * currentRowsPerPage, 0 * currentRowsPerPage + currentRowsPerPage);
      }
    }
    setRows([...newRows]);
  };
  /***
   * Local Pagination ProcessData end
   */
  //columnData
  let columnData = []
  let columns = Array.isArray(props.children)
    ? props.children.map(x => x.props)
    : [props.children?.children];

  let currentGroup
  const hidePaginationSubTable = () => {
    if (numberofRows && totalItems < numberofRows) {
      return true
    }
    return false
  }

  const handlePagination = (_page, _rowsPerPage, _sortField, _sortOrder, _availablePages) => {
    setPage(_page)
    setRowsPerPage(_rowsPerPage)
    if (props.onChangedPage) {
      setOpenCollapsedRows([])
      props.onChangedPage(_page, _rowsPerPage, _sortField, _sortOrder, _availablePages)
    }
    if(localPagination){
      processData({ newPage: _page, newRowsPerPage: _rowsPerPage });
    }
  }

  const heightValue =  heightAuto ? "auto" : `calc(100vh - ${topSpaceSize || 0}px)`;
  const renderTable = () => {
    const info  = serverSideTable ? props.data : JSON.parse(JSON.stringify(rows));
    return (
      <Table >
        <TableHead>
          <TableRow>
            {
              !hideHeader && columns.map((item, index) => (
                <TableCell
                  key={index}
                >
                  {
                    enableSorting && !item?.noSorting ?
                      <TableSortLabel
                        active={sortField === item.field}
                        direction={sortOrder}
                        onClick={() => {
                          //validate new values
                          let newSortField = sortField;
                          let newSortOrder = sortOrder;
                          if (sortField == item.field) {
                            newSortOrder = newSortOrder == "asc" ? "desc" : "asc";
                          }
                          else {
                            newSortField = item.field;
                            newSortOrder = "asc";
                          }

                          //set news values to sort
                          setSortField(newSortField);
                          setSortOrder(newSortOrder);

                          //propagate changes
                          if (props.onChangedPage)
                            props.onChangedPage(page, rowsPerPage, newSortField, newSortOrder)

                        }}
                      >
                        {item.title || item.label}
                      </TableSortLabel>
                      : item.title || item.label
                  }
                </TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>

          {
            noData ?
              <TableRow>
                <td colSpan={columns.length || 2} style={{padding: "10px"}}>
                  <span>{messages['reportsMainRT_noDataEvent']}</span>
                </td>
            </TableRow>
            :
            info.map((item, indexCell) => {
              let showGroup = false
              if (item[groupField] != currentGroup) {
                currentGroup = item[groupField]
                showGroup = true
              }
              const emptyCells = [];
              if (props.renderExpandableRowStartColumn) {
                for (let i = 0; i < props.renderExpandableRowStartColumn; i++) {
                  emptyCells.push(i);
                }
              }
              return (
                <Fragment key={indexCell}>
                  {
                    showGroup &&
                    <TableRow>
                      <td colSpan={props.renderExpandableRow ? columns.length + 1 : columns.length}>
                        {customGroupArea && customGroupArea(currentGroup, item, info) || ''}
                      </td>
                    </TableRow>
                  }

                  <TableRow className={props.classNameColumns || ''}>
                    {
                      !hiddenColumns && columns.map((col, index) => {
                        return (
                          <TableCell
                            key={index}
                            className={classNameCell || 'table-cell-'+index}
                          >
                            {
                              col?.children && col.children(item) ||
                              item[col?.field]
                            }
                          </TableCell>
                        )
                      })
                    }
                    {
                      props.renderExpandableRow && (
                        <TableCell className="report-table-comfortable-view">
                          {
                            item &&
                              (showExpandable || (item.Count && item.Count > 1) || (propertyToShowExpandable && item[propertyToShowExpandable]) ) ? (
                              <div style={{ width: "50px" }}>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => {
                                    if (openCollapsedRows.includes(indexCell)) {
                                      setOpenCollapsedRows([
                                        ...openCollapsedRows.filter((op) => op !== indexCell),
                                      ]);
                                    } else {
                                      idRenderExpandableRow && idRenderExpandableRow(item);
                                      if (openExpandableRowByDefault) {
                                        // if is close and openExpandableRowByDefault is true
                                        setOpenCollapsedRows((items) => [...items, indexCell]);
                                      } else {
                                        setOpenCollapsedRows([indexCell]);
                                      }
                                    }
                                  }}
                                >
                                  {openCollapsedRows.includes(indexCell) ? (
                                    <Icon>{`${props.iconOpen || 'keyboard_arrow_up'}`}</Icon>
                                  ) : (
                                    <Icon >{`${props.iconClose || 'keyboard_arrow_down'}`}</Icon>
                                  )}
                                </IconButton>
                              </div>

                            ) : ""
                          }


                        </TableCell>
                      )
                    }
                  </TableRow>
                  { renderExpandableRow && (
                    <TableRow>
                      <TableCell style={{ padding: 0, borderBottom: "none" }} colSpan={props.renderExpandableRowStartColumn ? (columns.length + 1) - props.renderExpandableRowStartColumn : columns.length + 1}>
                        <Collapse
                          in={openCollapsedRows.includes(indexCell)}
                          timeout="auto"
                          unmountOnExit
                        >
                          {renderExpandableRow(item)}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                  { renderSubTotalTable && (
                    <TableRow>
                      <TableCell style={{ padding: 0 }} colSpan={props.renderExpandableRowStartColumn ? (columns.length + 1) - props.renderExpandableRowStartColumn : columns.length + 1}>
                        {renderSubTotalTable(item)}
                      </TableCell>
                    </TableRow>
                  )}
                  {item[footerField] && (
                    <TableRow>
                      <td colSpan={props.renderExpandableRow ? columns.length + 1 : columns.length}>
                        {groupTotal && groupTotal(item) || ''}
                      </td>
                    </TableRow>
                  )}
                </Fragment>
              )
            })
          }
        </TableBody>
      </Table>
    );
  }
  return (
    <div className="report-table">
      {
        (data?.length > 0 || rowsCount > 0 || valueSelector?.length > 0 || noData) &&
        <Fragment>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} className="report-actions">
              {generateExport && (
                <div className="report-actions-buttons">
                  <Toolbar
                    disabledExport={loadingExport}
                    hideSearchForm
                    hideVisibilityButton
                    onExport={(e) => {
                      generateExport(e);
                    }}
                  >
                    <Button type="icon" tooltip={messages['reportsMainRT_pinReport']} placement="bottom" icon="push_pin" onClick={pinReport} />
                  </Toolbar>
                </div>
              )}

            </Grid>
            <Grid item xs={12} sm={6} className="report-actions">
              {!hideSelectorUnitsDriver && itemsSelector?.length && (
                <Select
                  id="services"
                  label={''}
                  value={valueSelector}
                  onChange={(value) => {
                    // if the selector changes, hide the collapsed rows
                    setOpenCollapsedRows([]);
                    setPage(1)
                    setRowsPerPage(10)
                    onChangeSelector(value)
                    processData({
                      newPage: 1,
                      newRowsPerPage: 10,
                      groupsId: value || []
                    });
                  }}
                  options={itemsSelector}
                  textField={textFieldSelector}
                  valueField="id"
                  placeholder={placeholderSelector ? placeholderSelector : 'Filter'}
                  isClearable
                  multiple
                  menuPlacement="top"
                />
              )}
            </Grid>
          </Grid>
          {
            loadingExport && (
              <LinearProgress color="primary" />
            )
          }
          {totalArea && totalArea()}
          { rows?.length> 0 &&showRangeDays && (
            <div className="report-ctn-days-range">
            <span>{`${startRange} - ${endRange}`}</span>
          </div>
          )}
          {
            rows?.length> 0 || noData ?
              // generateExport is applied in the main tables
              generateExport ? (
              <TableContainer
                style={{ height: height || heightValue }}
                className="report-table-container"
              >
                {renderTable()}
              </TableContainer>
              ): renderTable()
            : <p>No data found.</p>

          }


          {(!hiddenPagination && !hidePaginationSubTable()) && !noData &&
            <Pagination
              count={serverSideTable? totalItems: rowsCount}
              page={page}
              rowsPerPage={numberofRows || rowsPerPage}
              hideNumberOfRows={hideNumberOfRows}
              centerPagination={centerPagination}
              removeAll
              onChangedPage={handlePagination}
              sortField={sortField}
              sortOrder={sortOrder}
              testPerPage={testPerPage}
            />
          }

        </Fragment>
      }
      {
        loading &&
        <Loading overlay show />
      }
      {
        asyncLoading &&
        <>
          <span>{props.asyncMessage || messages['reportsMainRT_exportReportMessageStarted']}</span>
            <div className="MuiBox-root-8" style={{textAlign: "center"}}>
            <span>
              <Loading show />
            </span>
          </div>
        </>

      }
      {
        data?.length == 0 && !loading && !asyncLoading && !valueSelector?.length && !noData &&
        <div className="MuiBox-root-8">
          <span>{props.noDataMessage || 'No data found. Please refine your search and try again'}</span>
        </div>
      }

    </div>
  )

}

export default TableComponent;
