import { IconButton, MenuComponent, Tooltip } from "@gpstrackit/gtc-react-controls";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { incidentPanelAction, sendGenericExport } from "Redux/actions";
import { reset } from "../../../../redux/slice";
import { useIntl } from "react-intl";
import { getUserFormattedDate } from "Core/data/Helpers";

export const ExportButton = ({ hasVideo, rangeDate, data, clearFields }) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const { user } = useSelector((state) => state.securityRedux);
  const { isKph } = user;

  const getSpeed = () => {
    return `let newSpeed = row?.unit?.useEcuSpeed ? row?.reading?.ecuSpeed : row?.reading?.speed; if (!newSpeed) return ""; ${
      isKph
        ? ` let kph = 0; if (parseInt(newSpeed) > 0) { kph = (newSpeed * 1.609344); } return Math.ceil(kph) + "KPH";`
        : ` let speed = Math.round(newSpeed); return speed + " MPH" `
    }`;
  };

  const eventsExport = () => {
    const entities = [
      {
        id: data?.unitId,
        label: data?.unitLabel,
        name: "Unit",
      },
    ];

    const events = [];

    const exportColumns = [
      {
        header: messages["videoMainView_unit"],
        column: "unitLabel",
        functionString: 'return row?.unit?.label || ""',
      },
      {
        header: messages["videoMainView_driver"],
        column: "driver",
        functionString: 'return row?.reading?.driverName || ""',
      },
      {
        header: messages["videoMainView_event"],
        column: "event",
        functionString: 'return row?.reading?.eventName || ""',
      },
      {
        header: messages["videoMainView_address"],
        column: "address",
        functionString: 'return row?.reading?.formattedAddress || ""',
      },
      {
        header: messages["videoMainView_dateTime"],
        column: "dateTime",
        functionString:
          'return moment(row?.reading?.unitTime).tz(row?.unit?.timeZone?.name).format("llll") || ""',
      },
      {
        header: messages["videoMainView_speed"],
        column: "speed",
        functionString: getSpeed(),
      },
      {
        header: messages["videoMainView_notes"],
        column: "notes",
        functionString: 'return row?.reading?.notes || ""',
      },
    ];

    const tableHeader = [
      {
        name: messages["videoMainView_videoEvents"],
        value: "",
      },
      {
        name: messages["videoMainView_filters"],
        value: "",
      },
      {
        name: messages["videoMainView_units"],
        value: entities?.join(" / "),
      },
      {
        name: messages["videoMainView_events"],
        value: events?.join(", "),
      },
      {
        name: messages["videoMainView_openSearch"],
        value: "",
      },
      {
        name: messages["videoMainView_dateRange"],
        value: `${getUserFormattedDate(
          user,
          rangeDate?.start,
          messages,
          "h:mm A",
          false,
          true
        )} - ${getUserFormattedDate(
          user,
          rangeDate?.end,
          messages,
          "h:mm A",
          false,
          true
        )}`,
      },
    ];

    dispatch(
      sendGenericExport(
        "Media Events",
        "Csv",
        `
                      query searchAvailableMedia (
                        $openSearch: String,
                        $entities: [EntityInput]!, 
                        $dateRange: AWSDateTimeRange!, 
                        $events: [VidFleetEvent], 
                        $hasNotes: Boolean, 
                        $starred: Boolean, 
                        $mediaDeleted: Boolean, 
                        $limit: Int, 
                        $offset: Int
                      ) {
                        searchAvailableMedia (
                          openSearch: $openSearch,
                          entities: $entities, 
                          dateRange: $dateRange, 
                          events: $events, 
                          hasNotes: $hasNotes, 
                          starred: $starred, 
                          mediaDeleted: 
                          $mediaDeleted, 
                          limit: $limit, 
                          offset: $offset
                        ) {
                            items {
                              reading {
                                eventCode
                                eventName
                                driverName
                                unitTime
                                notes
                                speed
                                normalizedSpeed
                                ecuSpeed
                                formattedAddress
                              }
                              unit {
                                  label
                                  driverName
                                  notes
                                  useEcuSpeed
                                  timeZone {
                                    id
                                    name
                                  }
                              }
                              linkedDevice {
                                id
                                esn
                                deviceModelName
                                manufacturerName
                                hasVideo
                              }
                            }
                            total
                        }
                      }
                    `,
        {
          openSearch: "",
          limit: 100,
          offset: 0,
          dateRange: {
            start: rangeDate?.start,
            end: rangeDate?.end,
          },
          entities,
          mediaDeleted: false,
          mediaType: "All",
          reviewStatus: null,
          starred: false,
          events,
          hasNotes: false,
        },
        exportColumns,
        {
          unit: "pt",
          size: "A4",
          orientation: "landscape",
          fontSize: 10,
        },
        tableHeader,
        null,
        null,
        { 6: { cellWidth: 140 } }
      )
    );
  };

  const trailsExport = () => {
    const exportColumns = [
      {
        header: messages["videoMainView_driver"],
        column: "driverName",
        functionString: 'return row?.driverName || ""',
      },
      {
        header: messages["videoMainView_event"],
        column: "eventName",
        functionString: 'return row?.eventName || ""',
      },
      {
        header: "lat",
        column: "latitude",
        functionString: 'return row?.latitude || ""',
      },
      {
        header: "long",
        column: "longitude",
        functionString: 'return row?.longitude || ""',
      },
      {
        header: messages["realtimemaps_layer_map_date_time"],
        column: "unitTime",
        functionString: 'return moment(row?.unitTime).format("llll") || ""',
      },
      {
        header: messages["alertsTabView_speed"],
        column: "speed",
        functionString: 'return row?.speed || ""',
      },
      {
        header: messages["incident_address_export"],
        column: "formattedAddress",
        functionString: 'return row?.formattedAddress || ""',
      },
    ];

    const tableHeader = [
      {
        name: messages["videoMainView_driver"],
        value: "",
      },
      {
        name: messages["videoMainView_event"],
        value: "",
      },
      {
        name: "lat",
        value: "",
      },
      {
        name: "long",
        value: "",
      },
      {
        name: messages["realtimemaps_layer_map_date_time"],
        value: "",
      },
      {
        name: messages["alertsTabView_speed"],
        value: "",
      },
      {
        name: messages["incident_address_export"],
        value: "",
      },
    ];

    dispatch(
      sendGenericExport(
        "Trails Events",
        "Csv",
        `query searchVehicleTrails ($startDate: AWSDateTime!, $endDate: AWSDateTime!, $deviceId: String!, $limit: Int, $offset: Int, $includeLinkedData: Boolean) {
            searchVehicleTrails (startDate: $startDate, endDate: $endDate, deviceId: $deviceId, limit: $limit, offset: $offset, includeLinkedData: $includeLinkedData) {
              items {
                id
                deviceId
                latitude
                longitude
                heading
                eventCode
                eventName
                address
                formattedAddress
                driverId
                driverName
                ignitionStatus
                satelliteCount
                signal
                ecuSpeed
                speed
                speedLimit
                unitTime
                landmark {
                  id
                  companyId
                  name
                  tags {
                    id
                    label
                    color
                    path
                  }
                  address
                  city
                  zip
                  state
                  country
                }
              }
                total
            }
        }`,
        {
          deviceId: data?.unitId,
          limit: 50,
          offset: 0,
          startDate: rangeDate?.start,
          endDate: rangeDate?.end,
          includeLinkedData: true,
        },
        exportColumns,
        {
          unit: "pt",
          size: "A4",
          orientation: "landscape",
          fontSize: 10,
        },
        tableHeader,
        null,
        null,
        { 6: { cellWidth: 140 } }
      )
    );
  };

  const exportFunction = () => {
    if (hasVideo) eventsExport();
    trailsExport();
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton
        icon="edit"
        style={{color: 'black'}}
        tooltip={messages["edit"]}
        onClick={() => {
          dispatch(
            incidentPanelAction({
              update: data,
              open: true,
            })
          );
          dispatch(
            reset({
              isOpenOverViewUnit: {
                open: false,
                data: {},
              },
              trailsIncident: [],
              trailsIncidentTotal: 0,
              avMediaIncident: [],
              avMediaIncidentTotal: 0,
            })
          );
          clearFields && clearFields();
        }}
        size="xsmall"
      />
      {hasVideo ? (
        <Tooltip title={messages['table_export']}>
            <MenuComponent
              placement="bottom"
              options={[
                // messages["incident_event_title"],
                messages["incident_event_trails"],
              ]}
              icon="file_download"
              onClick={(type, value) => {
                if (type === messages["incident_event_title"]) {
                  eventsExport();
                } else if (type === messages["incident_event_trails"]) {
                  trailsExport();
                }
              }}
            />
        </Tooltip>
      ) : (
        <IconButton
          icon="file_download"
          tooltip={messages["download"]}
          onClick={() => exportFunction()}
          size="xsmall"
        />
      )}
    </div>
  );
};
