/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { LogManager, ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import axios from 'axios';

export const uploadFile = async (signedUrl, fileKey) => {
	try {

		return await axios.put(signedUrl, fileKey).then((res) => {
			return new Promise((resolve) => {
				resolve(res.data);
			});
		})
			.catch((error) => {
				return new Promise((resolve, reject) => {
					reject(error);
				});
			});

	} catch (exc) {
		ExceptionManager(exc, 'Core/data/FileUpload', 'uploadFile');
		throw exc;
	}
};

export const downloadFile = async (entity, entityId, key) => {
	try {
		const data = await clientMutation(
			`
            query($Entity:String!, $EntityName:String!, $Key:String!) {
                downloadAttachmentUrl( Entity:$Entity, EntityName:$EntityName, Key:$Key) {
                  Endpoint
                }
              }
            `,
			{
				Entity: '2',
				EntityName: 'vmaint2',
				Key: 'error.png',
			},
			endpoints.GRAPHQL_GENERAL,
		);

		if (data && data.downloadAttachmentUrl && data.downloadAttachmentUrl.Endpoint)
			window.open(data.downloadAttachmentUrl.Endpoint, '_blank');
		else {
			//error
		}
	} catch (exc) {
		ExceptionManager(exc, 'Core/data/FileUpload', 'downloadFile');
	}
};

export const deleteFile = async (id) => {
	try {
		const data = await clientQuery(
			`
             mutation($Id:Int!) {
                deleteAttachment(Id:$Id) 
              }
            `,
			{
				id: id,
			},
			endpoints.GRAPHQL_GENERAL,
		);
		LogManager('Delete File', data);
	} catch (exc) {
		ExceptionManager(exc, 'Core/data/FileUpload', 'deleteFile');
	}
};
