//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { NotificationManager } from 'Components/Notifications';

//Action types
import { MAP_LANDMARKS_LIST_GETTAGS, MAP_LANDMARKS_LIST_GETTAGS_TOCREATE } from 'Redux/actionTypes';

//actions
import { mapGetLandmarkTagsResponse, mapGetLandmarkTagsToCreateResponse } from 'Redux/actions';

function* getTagsInlineLandmarkList({ payload }) {
    const { entityName, getAllTags } = payload
    try {
        const sendParams = getAllTags ? {} : { entityName }

        const response = yield call(
            clientQuery,
                `query searchTagsInline ($entityName: String, $limit: Int, $offset: Int) {
                    result: searchTagsInline (entityName: $entityName, limit: $limit, offset: $offset) {
                        items {
                            id
                            label
                            color
                            parentId
                            path
                            level
                        }
                        total
                    }
                }`,
            { ...sendParams },
            endpoints.GRAPHQL_GENERAL,
        );
        if (response.result) {
            if(getAllTags){
                yield put(mapGetLandmarkTagsToCreateResponse(response.result.items?.map((element) => ({ ...element, name: element.label }))));
            }else{
                yield put(mapGetLandmarkTagsResponse(response.result.items?.map((element) => ({ ...element, name: element.label }))));
            }
            
        } else {
            if(getAllTags){
                yield put(mapGetLandmarkTagsToCreateResponse(null))
            }else{
                yield put(mapGetLandmarkTagsResponse(null))
            }
        } 
    } catch (e) {
        if(getAllTags){
            yield put(mapGetLandmarkTagsToCreateResponse(null, e))
        } else {
            yield put(mapGetLandmarkTagsResponse(null, e))
        }
        ExceptionManager(e, 'modules/realtimeMaps/main/components/landmarks/components/landmarkList/redux/saga', 'getTagsInlineLandmarkList');
    }
}
 
function* watchGetLandmarkListTasksRequest() {
    yield takeEvery(MAP_LANDMARKS_LIST_GETTAGS, getTagsInlineLandmarkList),
    yield takeEvery(MAP_LANDMARKS_LIST_GETTAGS_TOCREATE, getTagsInlineLandmarkList)
}

//Saga Export
export default function* rootSaga() {
    yield all([
        fork(watchGetLandmarkListTasksRequest)
    ]);
}