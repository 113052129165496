import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    loading: false,
    open: false,
    loadingRoutes: false, 
    routes: [],
    error: null
};

const route = "settings/views/routesTracker/components/modalAddRoutesTracker";

export const routesTrackerAddSearchRoutes = createAsyncThunk('modalAddRoutesTracker/redux/routesTrackerAddSearchRoutes', async ({ openSearch, limit = 500, offset = 0 }, { rejectWithValue, dispatch }) => {
    try {
        const conditions = [];
        conditions.push({
            fields: ["isTemplate"],
            terms: [1]
        })

        const sort = {
            field: "title",
            order: "ASC"
        };

        const response = await clientQuery(
            `
            query searchRoutes (
                $conditions: [RouteSearchInput],
                $sort: [RouteSortInput], 
                $limit: Int, 
                $offset: Int
              ) {
                Routes: searchRoutes (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                  items {
                    id
                    title
                  }
                  total
                }
              }
            `,
            {
                conditions: conditions,
                sort, 
                limit: limit, 
                offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.Routes?.items || []; 

    } catch (exc) {
        ExceptionManager(exc, route, 'routesTrackerAddSearchRoutes');
        return rejectWithValue(exc)
    }
});




export const RoutesTrackerModalAddRoutesTrackerRedux = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalRoutesTrackerAddRoutesTracker: (state, action) => {
            return {
                ...state,
                open: action?.payload?.open || false
            }
        }
    },

    extraReducers: (builder) => {

        builder.addCase(routesTrackerAddSearchRoutes.pending, (state, action) => {
            state.loadingRoutes = true;

        });
        builder.addCase(routesTrackerAddSearchRoutes.fulfilled, (state, action) => {
            state.routes = action?.payload || [];
            state.loadingRoutes = false;

        } );
        builder.addCase(routesTrackerAddSearchRoutes.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loadingRoutes = false;

        });

        
    }

});


const { actions, reducer } = RoutesTrackerModalAddRoutesTrackerRedux;

export const { reset, openModalRoutesTrackerAddRoutesTracker } = actions;

export default reducer;