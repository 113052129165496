export class UnitTags {
    /* eslint-disable */
    executeArray = (items, itemsExists) => {
      let units = [];

      for (let unit of itemsExists?.units) {
        let unitsTagsServer = items.filter(x => x.unitId == unit.id ||  x.id == unit.id);

        let unitTags = []
        for (let unitTag of unitsTagsServer) {
          let tag = itemsExists.tags?.find(t => t?.id == unitTag?.tagId);
          unitTags.push(this.getProcessItem({}, tag));
        }
  
        units.push({ ...unit, tags: unitTags });
      }

      //add all values from socket
      return [true, { units: units, unitTags: [...items] }]
    }
  
    getProcessItem = (item, newItem) => {

      if(!newItem && item){
        return item;
      } 

      if(!item){
        return {}
      } 

      let objItem = {
        ...item,
        id: newItem.id,
        label: newItem.label,
        color: newItem.color,
        parentId: newItem.parentId,
        path: newItem.path,
        hasChildren: newItem.hasChildren
      }
  
      return objItem
    }
  }