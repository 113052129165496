// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  FSM_SETTINGS_DRIVER_STATUS_SEARCH,
  FSM_SETTINGS_DRIVER_STATUS_CREATE,
  FSM_SETTINGS_DRIVER_STATUS_UPDATE,
  FSM_SETTINGS_DRIVER_STATUS_DELETE
} from "Redux/actionTypes";

// Actions
import {
  searchDriverStatusResponse,
  createDriverStatusResponse,
  updateDriverStatusResponse,
  deleteDriverStatusResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";


function* searchQuickRequest() {
  try {

    const query = `
                query searchDriverStatus ($openSearch: String) {
                  searchDriverStatus (openSearch: $openSearch) {
                      id
                      name
                  }
              }
        `;

    const response = yield call(
      clientQuery,
      query,
      {},
      endpoints.GRAPHQL_GENERAL
    );
    if (!response && !response.searchDriverStatus) {
      throw "Error while getting Quick Message list";
    }
    let driverStatus = response.searchDriverStatus;
    yield put(searchDriverStatusResponse({ driverStatus }));
  } catch (exc) {
    yield put(searchDriverStatusResponse({ driverStatus: [] }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "searchQuickRequest",
      true
      );
  }
}

function* createQuickRequest({ payload }) {
  try {

    let query = `
      mutation createDriverStatus ($name: String!) {
        createDriverStatus (name: $name) {
            id
            name
        }
    }
      `;



    const response = yield call(
      clientMutation,
      query,
      payload,
      endpoints.GRAPHQL_GENERAL,
    );


    yield put(createDriverStatusResponse({ create: response, error: false }));
  } catch (exc) {
    yield put(createDriverStatusResponse({ create: null, error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "createQuickRequest",
      true
      );
  }
}

function* updateQuickRequest({ payload }) {
  try {
    const { DriverStatus } = payload;

    let query = `
      mutation updateDriverStatus ($id: Int!, $name: String) {
        updateDriverStatus (id: $id, name: $name) {
            id
            name
        }
    }
      `;



    const response = yield call(
      clientMutation,
      query,
      payload,
      endpoints.GRAPHQL_GENERAL,
    );


    yield put(updateDriverStatusResponse({ update: response, error: false }));
  } catch (exc) {
    yield put(updateDriverStatusResponse({ update: null, error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "updateQuickRequest",
      true
      );
  }
}

function* deleteQuickRequest({ payload }) {
  try {

    const { driverStatus } = payload;
    let mutations = [];
    driverStatus?.forEach((Form, index) => {
      mutations.push(`
            Quick${index}: deleteDriverStatus(id: ${Form?.id})
        `);
    });

    let mutation = `mutation{${mutations.join("")}}`;

    const response = yield call(
      clientMutation,
      mutation,
      {},
      endpoints.GRAPHQL_GENERAL,
    );

    if (!response || !response.Quick0) throw `Error while deleting Quick Messages, ${driverStatus}`;



    yield put(deleteDriverStatusResponse({ data: response.Quick0, error: false }));
  } catch (exc) {
    yield put(deleteDriverStatusResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "deleteForms",
      true
      );
  }
}



function* watchMethods() {
  yield takeEvery(FSM_SETTINGS_DRIVER_STATUS_SEARCH, searchQuickRequest);
  yield takeEvery(FSM_SETTINGS_DRIVER_STATUS_CREATE, createQuickRequest);
  yield takeEvery(FSM_SETTINGS_DRIVER_STATUS_UPDATE, updateQuickRequest);
  yield takeEvery(FSM_SETTINGS_DRIVER_STATUS_DELETE, deleteQuickRequest);
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}