import React, { useEffect, useRef, useState } from 'react'

const Map = ({ coords, zoom }) => {

    const mapRef = useRef(null);
    const [map, setMap] = useState(null);
    const markerRef = useRef(null);

    useEffect(() => {

        if (!google) {
            return;
        }

        if (!map) {
            const mapOptions = {
                zoom: 4,
                center: { lat: 37.0902, lng: -95.7129 },
                mapTypeControl: false,
            };

            const newMap = new google.maps.Map(mapRef.current, mapOptions);
            setMap(newMap);
        } else {
            if (coords && coords.lat && coords.lng) {
                map.setCenter(coords);
                map.setZoom(zoom || 10);

                if (markerRef.current) {
                    markerRef.current.setMap(null);
                }

                const newMarker = new google.maps.Marker({
                    position: coords,
                    map: map
                });
                markerRef.current = newMarker;
            }
        }
    }, [coords, zoom, map]);

    return <div style={{ width: '100%', height: '300px' }} ref={mapRef}></div>;

}

export default Map