import {
    MAP_ICON_PICKER_SEARCH_ICONS,
    MAP_ICON_PICKER_SEARCH_ICONS_RESPONSE
} from 'Redux/actionTypes';

export const mapIconPickerSearchIcons = (data) => ({
    type: MAP_ICON_PICKER_SEARCH_ICONS,
    payload: { data },
});

export const mapIconPickerSearchIconsResponse = (data) => ({
    type: MAP_ICON_PICKER_SEARCH_ICONS_RESPONSE,
    payload: { data },
});