//View Keys
export default {
  realtimemaps_send_message_contact: 'Contact',
  realtimemaps_send_message_subject: 'Subject',
  realtimemaps_send_message_message: 'Message',
  realtimemaps_send_message_send: 'Send',
  realtimemaps_send_message_select_option: "Please select at least one option",
  realtimemaps_send_message_select_option_contacts: "Contacts: Please select at least one option",
  realtimemaps_send_message_select_option_driver: "Driver: Please select at least one option",
  realtimemaps_send_message_sent_successfully: "Email has been sent successfully",
  realtimemaps_send_message_sent_error: "The selected drivers do not have a cellphone set, please add this information before sending the message again",
  realtimemaps_send_message_drivers: "Drivers",
  realtimemaps_send_message_driverGroups: "Driver Groups",
  realtimemaps_send_message_contacts: "Contacts"
};