//imports
import { all } from 'redux-saga/effects';

//sagas
//import reportsSagas from 'Modules/reports/views/main/redux/saga';
import reportsMainRTSagas from 'Modules/reports/views/main/components/reportsTab/redux/saga';
import reportsMainRSSagas from 'Modules/reports/views/main/components/scheduleTab/redux/saga';
import reportsSetupModalSagas from 'Modules/reports/views/main/components/scheduleTab/.components/reportSetup/redux/saga';

// merge sagas 
export default function* rootSaga() {
  yield all([
    //reportsSagas(),
    reportsMainRTSagas(),
    reportsMainRSSagas(),
    reportsSetupModalSagas()
  ]);
}