import {
    TAG_PICKER_SEARCH_TAGS,
    TAG_PICKER_SEARCH_TAGS_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    tags: [],
    loading: false,
    filters: {
        openSearch: "",
        parentId: null,
        limit: 25,
        offset: 0,
        total: 0
    },
    groupedTags: {},
    groupedLoadings: {},
    error: false
};

export default (state = INIT_STATE, action) => {
    let error;
    switch (action.type) {
        case TAG_PICKER_SEARCH_TAGS:
            const { payload: { data }} = action;

            if (data.parentId != 0) {
                return { 
                    ...state,
                    error: false,
                    groupedLoadings: { ...state.groupedLoadings, [`${data.parentId || 0}`]: true },
                };
            }

            let newTags = data.openSearch != state.filters.openSearch ? [] : state.tags;
            return { 
                ...state,
                filters: { ...state.filters, data},
                tags: newTags,
                error: false,
                loading: true
            };

        case TAG_PICKER_SEARCH_TAGS_RESPONSE:
            const { items, total, parentId, error } = action?.payload?.response;

            if (parentId != 0) {
                return { 
                    ...state,
                    error: error,
                    //groupedTags: { [`${parentId || '0'}`]: items },
                    groupedTags: { ...state.groupedTags, [`${parentId || '0'}`]: items},
                    groupedLoadings: { ...state.groupedLoadings, [`${parentId || '0'}`]: false },
                };
            }

            return { 
                ...state,
                loading: false,
                error: error,
                tags: [ ...state.tags, ...items ],
                filters: { ...state.filters, total: total }
            };

        case CLEAR_REDUX:
            return ["", "TAG_PICKER"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state }

        default:
            return { ...state };
    }
}