import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "Components";
import { useDispatch, useSelector } from "react-redux";
import { getLiveIncident } from "./redux/slice";
import { IncidentInfo } from "./incidentInfo";
import { useIntl } from "react-intl";
import { isValidReading } from "./utils";
import Map from 'Components/Map';

let intervalNewData;

const LiveIncident = () => {
  const { code } = useParams();
  const dispatch = useDispatch();
  const mapRef = useRef();
  const navigate = useNavigate();

  const { liveIncidentLoading, liveIncident, liveIncidentError } = useSelector(
    (state) => state.LiveIncidentRedux
  );
  const [loading, setLoading] = useState(true);
  const [pointers, setPointers] = useState([]);

  useEffect(() => {
    dispatch(getLiveIncident({ code }));

    if (!intervalNewData) {
        intervalNewData = setInterval((code) => {
            dispatch(getLiveIncident({ code }));
        }, 15000, code);
    }
  }, [code]);

  useEffect(() => {
    if (liveIncidentLoading) return;
    if (liveIncidentError) return navigate('/error')
    const reading = liveIncident?.reading
    if (!isValidReading(reading)) return navigate('/error')
    const pointerMap = [
      {
        id: "1",
        lat: parseFloat(reading?.latitude || 0),
        lng: parseFloat(reading?.longitude || 0),
        heading: 0,
        color: "000",
        eventName: "no event",
        draggable: false,
        updatePointers: true,
        hideMarker: false,
      },
    ];
    setPointers(pointerMap)
    setLoading(false)
  }, [liveIncidentLoading, liveIncident, liveIncidentError]);


  if (loading) {
    return <Loading full />;
  }

  return (
    <div>
      {liveIncident?.name && (
        <IncidentInfo
          incidentName={liveIncident?.name}
          unitLabel={liveIncident?.reading?.deviceLabel}
        />
      )}
      <Map
        ref={mapRef}
        pointers={pointers || []}
        noRealtime={false}
        hiddenButton
        hiddenLegends={true}
        hiddenTraffic={true}
        hiddenSearchMap
        height="100vh"
      />
    </div>
  );
};

export default LiveIncident;
