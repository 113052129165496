import {
    /* SETTINGS_DRIVER_GROUP_SEARCH, */
    SETTINGS_DRIVER_GROUP_SEARCH_RESPONSE,
    SETTINGS_DRIVER_GROUP_OPEN_CREATE_EDIT_MODAL,
    SETTINGS_DRIVER_GROUP_CREATE_EDIT,
    SETTINGS_DRIVER_GROUP_CREATE_EDIT_RESPONSE,
    SETTINGS_DRIVER_GROUP_OPEN_DELETE_MODAL,
    SETTINGS_DRIVER_GROUP_DELETE, 
    SETTINGS_DRIVER_GROUP_DELETE_RESPONSE,
    SETTINGS_DRIVER_GROUP_GET,
    SETTINGS_DRIVER_GROUP_GET_RESPONSE
} from "Redux/actionTypes";

/* export const searchSettingsDriverGroups = (data) => {
    return {
        type: SETTINGS_DRIVER_GROUP_SEARCH,
        payload: data,
    };
}; */

export const searchSettingsDriverGroupsResponse = (data) => {
    return {
        type: SETTINGS_DRIVER_GROUP_SEARCH_RESPONSE,
        payload: data,
    };
};


export const openSettingsDriverGroupsCreateEditModal = (open, driverGroup) => {
    return {
        type: SETTINGS_DRIVER_GROUP_OPEN_CREATE_EDIT_MODAL,
        payload: { open, driverGroup }

    };
};

/* export const createEditSettingsDriverGroups = (driverGroup) => {
    return {
        type: SETTINGS_DRIVER_GROUP_CREATE_EDIT,
        payload: { driverGroup },
    };
} */

export const createEditSettingsDriverGroupsResponse = (data) => {
    return {
        type: SETTINGS_DRIVER_GROUP_CREATE_EDIT_RESPONSE,
        payload: { data },
    };
};

export const openSettingsDriverGroupsDeleteModal = (open, items = []) => {
    return {
        type: SETTINGS_DRIVER_GROUP_OPEN_DELETE_MODAL,
        payload: { open, items },
    };
};

/* export const deleteSettingsDriverGroups = (items = [],moveChildsToGroupId=0) => {
    return {
        type: SETTINGS_DRIVER_GROUP_DELETE,
        payload: { items,moveChildsToGroupId },
    };
}; */

export const deleteSettingsDriverGroupsResponse = (data) => {
    return {
        type: SETTINGS_DRIVER_GROUP_DELETE_RESPONSE,
        payload: { data },
    };
};

/* export const getSettingsDriverGroups = (data) => {
    return {
        type: SETTINGS_DRIVER_GROUP_GET,
        payload: { data },
    };
}; */

export const getSettingsDriverGroupsResponse = (data) => {
    return {
        type: SETTINGS_DRIVER_GROUP_GET_RESPONSE,
        payload:data,
    };
};