import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';

// Actions 
import {
  reportsMainGenerateReportProgress
} from 'Redux/actions';

// Helpers
import {
  getTimeZone,
  getCategoryFilter
} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getGeofenceBasedSummaryReport(report, event) {
  const { timeZone, name,reportTimeRanges } = report;
  const { startRange, endRange, filterType, sortField, sortOrder, otherFilters, unitsSelected, filterArray } = report.filters;
  const { filterArray: filterArrayEvent, page } = event.filters;
  let rowsToQuery = unitsSelected?.length  ? unitsSelected.map(x => ({id: x.id, type: x.name, parentId: x.parentTagId})) : filterArray.map(x => ({id: x.id, type: x.name, parentId: x.parentTagId}));

  const valueEntityFilter = {
    id: filterArrayEvent[0],
    type: "Geofence"
  };

  try {
    let data = yield call(
      clientQuery,
      `query($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getGeofenceBasedDetailsReport(conditions: $conditions, limit: $limit, offset: $offset) {
          total
          limit
          offset
          category
          items {
            idLabel
            dateFrom
            dateTo
            time
            distance
          }
        }
      }`,
      {
        conditions: {
          reportName: name,
          filterValues: {
            field: "Geofence",
            entities: valueEntityFilter
          },
          category: {
            field: getCategoryFilter(filterType) || filterType,
            entities: rowsToQuery
          },
          timeZone: getTimeZone(timeZone),
          dateRange: {
            fields: [
              "unitTime"
            ],
            GTE: startRange,
            LTE: endRange,
            reportTimeRanges
          },
          sortField: {
            field: sortField || "deviceId",
            order: sortOrder === 'desc' ? 'DESC' : 'ASC'
          }
        },
        limit: 10,
        offset: page
      },
      endpoints.GRAPHQL_PX_REPORTING,
    );
    if (data?.Report) {
      report.summary = data.Report
      report.loadingSummary = false
      yield put(reportsMainGenerateReportProgress(report))
    } else {
      report.loadingSummary = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  } catch (exc) {
    report.loading = false
    delete report.export
    yield put(reportsMainGenerateReportProgress(report))
  }
}

export default getGeofenceBasedSummaryReport;