export default {
    maintenanceDeleteMaintenanceService_deleteLabel: 'Delete Service',
    maintenanceDeleteMaintenanceService_messageDelete: 'Are you sure you want to delete the service',
    maintenanceDeleteMaintenanceService_deleteConfirmationSuccessfully: 'Service Removed Successfully',
    maintenanceDeleteMaintenanceService_deleteConfirmationDone: 'The service has already been completed, so it cannot be deleted',
    maintenanceDeleteMaintenanceService_theServiceLabel: 'The service',
    maintenanceDeleteMaintenanceService_isProgrammed: 'is currently programmed for a vehicle, do you want to delete it',
    maintenanceDeleteMaintenanceService_deleteServicesLabel: 'Delete Services',
    maintenanceDeleteMaintenanceService_deleteSevicesConfirmationSuccessfully: 'Services Removed Successfully',
    maintenanceDeleteMaintenanceService_messageDeleteServices: 'Are you sure you want to delete the services',
    maintenanceDeleteMaintenanceService_messageScheduledServices: 'Some selected services are currently scheduled for a vehicle, do you want to delete them',
    maintenanceDeleteMaintenanceService_scheduledServicesLabel: 'Scheduled services',
};