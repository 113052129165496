
import { clearStringValue } from 'Core/data/Helpers'

export class VehicleInformation {

  /* eslint-disable */
  executeArray = (items, itemsExists) => {
    let units = []
    for (let unit of itemsExists.units) {
      let index = items.findIndex(x => x.unit_id == unit.id || x.unitId == unit.id || x.id == unit.id)
      let newUnit = { ...unit }
      if (index > -1) {
        newUnit.vehicleInformation = this.getProcessItem(unit.vehicleInformation || {}, items[index])
      }

      units.push(newUnit)
    }

    return [true, { units: units }]
  }

  execute = (item, itemsExists) => {
    let items = [...itemsExists.units]
    let index = items.findIndex(x => x.id == item.unit_id || x.id == item.unitId || x.id == item.id)

    let newUnit = { ...items[index] }
    if (index > -1)
      newUnit.vehicleInformation = this.getProcessItem(items[index].vehicleInformation, item)

    items.splice(index, 1)
    items.push(newUnit)

    return [true, { units: [...items] }]
  }

  getProcessItem = (item, newItem) => {
    if (!newItem) return item;

    let objItem = {
      ...item,
      unitId: newItem.unit_id || newItem.unitId || item.unitId,
      vin: newItem.vin != undefined ? clearStringValue(newItem.vin) : clearStringValue(item?.vin),
      make: newItem.make != undefined ? clearStringValue(newItem.make) : clearStringValue(item?.make),
      model: newItem.model != undefined ? clearStringValue(newItem.model) : clearStringValue(item?.model),
      year: newItem.year != undefined ? clearStringValue(newItem.year) : clearStringValue(item?.year),
    }

    return objItem
  }

}