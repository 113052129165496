import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
  ACCOUNT_CHANGE_PASSWORD_SET_PASSWORD
} from 'Redux/actionTypes';

import {
  userChangePasswordResponse
} from 'Redux/actions';

function* changePasswordRequest({ payload }) {
  try {
    const { data } = payload;
    const response = yield call(
      clientMutation,
      `
      mutation changePassword (
        $accessToken: String!, 
        $oldPassword: String!, 
        $newPassword: String!
      ) {
        Result: changePassword(
          accessToken: $accessToken, 
          oldPassword: $oldPassword,
          newPassword: $newPassword
        )
      } `,
      data,
      endpoints.COGNITO_URL,
      {
        'x-api-key': endpoints.COGNITO_API_KEY
      }
    );

    if (response && response.Result) {
      yield put(userChangePasswordResponse(response.Result));
    } else {
      throw 'Error while changing user password';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/account/.components/changePassword/redux/saga', 'changePasswordRequest');
    yield put(userChangePasswordResponse({ error: true, message: exc?.error }));
  }
}

function* watchChangePasswordRequest() {
  yield takeEvery(ACCOUNT_CHANGE_PASSWORD_SET_PASSWORD, changePasswordRequest);
}

export default function* rootSaga() {
  yield all([
    fork(watchChangePasswordRequest)
  ]);
}