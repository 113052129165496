import { Typography } from "@mui/material";

import React from "react";

import { useIntl } from "react-intl";

export const Title = () => {
  const { messages } = useIntl();

  return (
    <Typography variant="h5">
      {messages["videoMainView_TitleTimeLine"]}
    </Typography>
  );
};

export const SubTitle = () => {
  const { messages } = useIntl();

  return (
    <Typography
      variant="subtitle2"
      mt={3}
      style={{ color: "gray" }}
      gutterBottom
      component="div"
    >
      {messages["videoMainView_subtitleTimeLine"]}
    </Typography>
  );
};
