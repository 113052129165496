import {
  //TYPES
  REALTIME_MAP_VEHICLETRAILS_GET,
  REALTIME_MAP_VEHICLETRAILS_GET_CONFIRM,
  REALTIME_MAP_VEHICLETRAILS_GET_CLEAR,
  REALTIME_MAP_VEHICLETRAILS_SHOW_TABLE,
  REALTIME_MAP_VEHICLETRAILS_SHOW_SETTINGS,
  REALTIME_MAP_VEHICLETRAILS_PLAYBACK_SET,
  REALTIME_MAP_VEHICLETRAILS_VEHICLE_PRINT,
  REALTIME_MAP_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET,
  REALTIME_MAP_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET_CONFIRM,
  REALTIME_MAP_VEHICLETRAILS_SEARCH_UNITS_GET,
  REALTIME_MAP_VEHICLETRAILS_SEARCH_UNITS_GET_RESULT,
  GET_LAST_VEHICLETRAILS,
  GET_LAST_VEHICLETRAILS_RESPONSE,
  REALTIME_MAP_VEHICLETRAILS_SELECT_ITEM_LIST,
  CLEAR_REDUX
} from "Redux/actionTypes";

const INIT_STATE = {
  vehicleTrails: [],
  lastVehicleTrail: [],
  vehicletrailsPrint: [],
  loadVehicleTrails: false,
  paramsVehicleTrails: { limit: 100, offset: 0, total: 0 }, //define de default elements 
  showVehicleTrails: false,
  showVehicleTrailsTable: false,
  showVehicleTrailsSettings: false,
  playback: false,
  queryPagination: false,
  unitToPrint: null,
  unitTypeEvents: [],
  loadingUnitTypeEvents: false,
  loadingSearchUnits: false,
  exportData: '',
  searchUnits: [],
  idClickMarkerTrails: 0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case REALTIME_MAP_VEHICLETRAILS_GET:
      if (action.payload.startingQuery) {
        return {
          ...state,
          loadVehicleTrails: true,
          paramsVehicleTrails: { ...state.paramsVehicleTrails, ...action.payload.params, limit: 100, offset: 0 },
          exportData: action.payload.exportData || '',
          vehicletrailsPrint: [],
          queryPagination: false,
          playback: false
        };
      }
      return {
        ...state,
        loadVehicleTrails: true,
        paramsVehicleTrails: { ...state.paramsVehicleTrails, ...action.payload.params },
        exportData: action.payload.exportData || '',
        vehicletrailsPrint: [],
        queryPagination: true
      };

    case REALTIME_MAP_VEHICLETRAILS_GET_CONFIRM:
      if (action.payload.result) {
        return {
          ...state,
          showVehicleTrails: action.payload.result.total > 0 ? true : false,
          loadVehicleTrails: false,
          vehicleTrails: !state.exportData ? action.payload.result : state.vehicleTrails,
          vehicletrailsPrint: state.exportData ? action.payload.result : [],
          paramsVehicleTrails: { ...state.paramsVehicleTrails, total: action.payload.result.total }
        };
      }
      return { ...state, showVehicleTrails: false, loadVehicleTrails: false, vehicleTrails: action.payload.result };

    case REALTIME_MAP_VEHICLETRAILS_GET_CLEAR:
      if (!action.payload) {
        return { ...state, 
          showVehicleTrails: action.payload, 
          showVehicleTrailsTable: action.payload, 
          paramsVehicleTrails: null,
          unitToPrint: null };
      }
      return { ...state, showVehicleTrails: action.payload };

    case REALTIME_MAP_VEHICLETRAILS_SHOW_TABLE:
      return { ...state, showVehicleTrailsTable: action.payload };

    case REALTIME_MAP_VEHICLETRAILS_SHOW_SETTINGS:
      return { ...state, showVehicleTrailsSettings: action.payload }

    case REALTIME_MAP_VEHICLETRAILS_PLAYBACK_SET:
      return { ...state, playback: action.payload }

    case REALTIME_MAP_VEHICLETRAILS_VEHICLE_PRINT:
      return { ...state, unitToPrint: action.payload }

    case REALTIME_MAP_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET:
      return { ...state, loadingUnitTypeEvents: true, }
    case REALTIME_MAP_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET_CONFIRM:
      return { ...state, loadingUnitTypeEvents: false, unitTypeEvents: action.payload }
    case REALTIME_MAP_VEHICLETRAILS_SEARCH_UNITS_GET:
      return { ...state, loadingSearchUnits: true }
    case REALTIME_MAP_VEHICLETRAILS_SEARCH_UNITS_GET_RESULT:
      return { ...state, loadingSearchUnits: false, searchUnits: action.payload }

    case GET_LAST_VEHICLETRAILS:
      if (action.payload.startingQuery) {
        return {
          ...state,
          paramsVehicleTrails: { ...state.paramsVehicleTrails, ...action.payload.params, limit: 100, offset: 0 },
          exportData: action.payload.exportData || '',
        };
      }
      return {
        ...state,
        paramsVehicleTrails: { ...state.paramsVehicleTrails, ...action.payload.params },
      };

    case GET_LAST_VEHICLETRAILS_RESPONSE:
      if (action.payload.result) {
        return {
          ...state,
          lastVehicleTrail: !state.exportData ? action.payload.result : state.lastVehicleTrail,
        };
      }
      return { ...state, lastVehicleTrail: action.payload.result };

    case REALTIME_MAP_VEHICLETRAILS_SELECT_ITEM_LIST:
      return { ...state, idClickMarkerTrails: action.payload }


    case CLEAR_REDUX:
      return ["", "TRAILS_REDUX"].includes(action.payload.option)
      ? action.payload.parameters
        ? { ...state, ...action.payload.parameters }
        : { ...INIT_STATE }
      : { ...state };
    
    default:
      return state;
  }
};