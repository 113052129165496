export default {
    name_incident_history: 'Nombre',
    vehicle_incident_history: 'Vehiculo',
    stared_incident_history: 'Comenzo',
    ended_incident_history: 'Finalizo',
    duration_incident_history: 'Duracion',
    reportedBy_incident_history: 'Reportado por',
    endedBy_incident_history: 'Finalizado Por',
    note_incident_history: 'Nota',
    reactive_incident_history: 'Reactivar Incidente',
    delete_incident_history: 'Eliminar Incidente',
    delete_message_incident_history: '¿Esta seguro que desea eliminar los siguientes incidents? esta acción es irreversible',
    noInformation_incident_history: 'No existen incidentes en el rango seleccionado...',
    reactivate_message_incident_history: 'El incidente se reactivará con una hora de actividad a partir de su confirmación.',
    incidentName_incident_history: 'Nombre:',
    incidentVehicle_incident_history: 'Vehiculo:',
    incidentStart_incident_history: 'Fecha de inicio:',
    reactiveConfirm_incident_history: 'Reactivar',
    deleteMessage_incident_history: 'Los Incidentes fueron eliminados correctamente',
    deleteMessageTitle_incident_history: 'Eliminar Incidentes',
    reactivateMessageTitle_incident_history: 'Reactivar Incidente',
    reactivateMessage_incident_history: 'El Incidente fue reactivado y su duracion es de una hora a partir de este momento',
    reactivateMessageError_incident_history: 'El Incidente no pudo activarse nuevamente ya que el vehiculo cuenta con un Incidente activo',








}