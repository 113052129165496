import React from 'react';

//controls
import {
    Icon,
    IconButton,
    OutlinedInput,
    InputAdornment,
    FormControl
} from '@mui/material';

import './styles.scss';

const SearchInput = (props) => {

    const { label, searchText, onSearchText, placeholder, disabled } = props

    return (
        <FormControl className="cloud-input-search" style={{ width: '100%' }}>
            {
                label &&
                <label>{label}</label>
            }

            <OutlinedInput
                id={props.id || 'custom-search'}
                placeholder={placeholder}
                type={'text'}
                value={searchText || ''}
                fullWidth={true}
                onChange={(event) => {
                    onSearchText && onSearchText(event.target.value);
                }}
                disabled={disabled}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="placeholder"
                            style={{ fontSize: 25, color: 'black' }}
                            size="large">
                            <Icon>search</Icon>
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
}

export default SearchInput