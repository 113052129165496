import {
  REPORTS_MAIN_GET_SCHEDULE_REPORT_SETUP,
  REPORTS_MAIN_GET_SCHEDULE_REPORT_SETUP_RESULT,
  REPORTS_MAIN_DELETE_SCHEDULE_REPORT,
  REPORTS_MAIN_DELETE_SCHEDULE_REPORT_RESULT
} from "Redux/actionTypes";

export const getSchedules = (messages, timeZone) => ({
  type: REPORTS_MAIN_GET_SCHEDULE_REPORT_SETUP,
  payload: {messages, timeZone}
});

export const getSchedulesResult = (data) => ({
  type: REPORTS_MAIN_GET_SCHEDULE_REPORT_SETUP_RESULT,
  payload: { data }
});

export const deleteSchedule = (data) => {
  return {
  type: REPORTS_MAIN_DELETE_SCHEDULE_REPORT,
  payload: { data }
}};

export const deleteScheduleResult = (data) => {
  return {
    type: REPORTS_MAIN_DELETE_SCHEDULE_REPORT_RESULT,
    payload: { data }
  }
};




