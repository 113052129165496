import {
    MAP_GEOFENCES_EDIT_CREATE_OPEN_MODAL,
    MAP_GEOFENCES_GET_GEOFENCE,
    MAP_GEOFENCES_GET_GEOFENCE_RESPONSE,
    MAP_GEOFENCES_EDIT_CREATE_GEOFENCE,
    MAP_GEOFENCES_EDIT_CREATE_GEOFENCE_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    open: false,
    id: null,
    geofence: null,
    loadingGeofence: false,
    response: null,
    loadingResponse: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MAP_GEOFENCES_EDIT_CREATE_OPEN_MODAL:
            return {
                ...state,
                open: action.payload.open,
                id: action.payload.id,
                geofence: null,
                loadingGeofence: false,
                response: null,
                loadingResponse: false
            };

        case MAP_GEOFENCES_GET_GEOFENCE:
            return {
                ...state,
                geofence: null,
                loadingGeofence: true
            };

        case MAP_GEOFENCES_GET_GEOFENCE_RESPONSE:
            return {
                ...state,
                geofence: action.payload.data,
                loadingGeofence: false
            };

        case MAP_GEOFENCES_EDIT_CREATE_GEOFENCE:
            return {
                ...state,
                response: null,
                loadingResponse: true
            };

        case MAP_GEOFENCES_EDIT_CREATE_GEOFENCE_RESPONSE:
            return {
                ...state,
                response: action.payload.data,
                loadingResponse: false,
                loadingGeofence: false,
            };

        case CLEAR_REDUX:
            return ["", "MAP_GEOFENCE_EDIT_CREATE"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
            return { ...state };
    }
}