// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  FSM_SETTINGS_QUICK_MESSAGE_SEARCH,
  FSM_SETTINGS_QUICK_MESSAGE_CREATE,
  FSM_SETTINGS_QUICK_MESSAGE_UPDATE,
  FSM_SETTINGS_QUICK_MESSAGE_DELETE
} from "Redux/actionTypes";

// Actions
import {
  searchQuickMessageResponse,
  createQuickMessageResponse,
  updateQuickMessageResponse,
  deleteQuickMessageResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";


function* searchQuickRequest() {
  try {

    const query = `
                query searchQuickMessages ($openSearch: String) {
                  searchQuickMessages (openSearch: $openSearch) {
                      id
                      name
                  }
              }
        `;

    const response = yield call(
      clientQuery,
      query,
      {},
      endpoints.GRAPHQL_GENERAL
    );
    if (!response && !response.searchQuickMessages) {
      throw "Error while getting Quick Message list";
    }
    let quickMessage = response.searchQuickMessages;
    yield put(searchQuickMessageResponse({ quickMessage }));
  } catch (exc) {
    yield put(searchQuickMessageResponse({ quickMessage: [] }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "searchQuickRequest",
      true
    );
  }
}

function* createQuickRequest({ payload }) {
  try {

    let query = `
      mutation createQuickMessage ($name: String!) {
        createQuickMessage (name: $name) {
            id
            name
        }
    }
      `;



    const response = yield call(
      clientMutation,
      query,
      payload,
      endpoints.GRAPHQL_GENERAL,
    );


    yield put(createQuickMessageResponse({ create: response, error: false }));
  } catch (exc) {
    yield put(createQuickMessageResponse({ create: null, error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "createQuickRequest",
      true
    );
  }
}

function* updateQuickRequest({ payload }) {
  try {
    const { QuickMessage } = payload;

    let query = `
      mutation updateQuickMessage ($id: Int!, $name: String) {
        updateQuickMessage (id: $id, name: $name) {
            id
            name
        }
    }
      `;



    const response = yield call(
      clientMutation,
      query,
      payload,
      endpoints.GRAPHQL_GENERAL,
    );


    yield put(updateQuickMessageResponse({ update: response, error: false }));
  } catch (exc) {
    yield put(updateQuickMessageResponse({ update: null, error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "updateQuickRequest",
      true
    );
  }
}

function* deleteQuickRequest({ payload }) {
  try {

    const { quickMessage } = payload;
    let mutations = [];
    quickMessage?.forEach((Form, index) => {
      mutations.push(`
            Quick${index}: deleteQuickMessage(id: ${Form?.id})
        `);
    });

    let mutation = `mutation{${mutations.join("")}}`;

    const response = yield call(
      clientMutation,
      mutation,
      {},
      endpoints.GRAPHQL_GENERAL,
    );

    if (!response || !response.Quick0) throw `Error while deleting Quick Messages, ${quickMessage}`;



    yield put(deleteQuickMessageResponse({ data: response.Quick0, error: false }));
  } catch (exc) {
    yield put(deleteQuickMessageResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "deleteForms",
      true
    );
  }
}



function* watchMethods() {
  yield takeEvery(FSM_SETTINGS_QUICK_MESSAGE_SEARCH, searchQuickRequest);
  yield takeEvery(FSM_SETTINGS_QUICK_MESSAGE_CREATE, createQuickRequest);
  yield takeEvery(FSM_SETTINGS_QUICK_MESSAGE_UPDATE, updateQuickRequest);
  yield takeEvery(FSM_SETTINGS_QUICK_MESSAGE_DELETE, deleteQuickRequest);
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}