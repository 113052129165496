export default {
    settingsCrew_name: "Name",
    settingsCrew_member: "Crew Member",
    settingsCrew_assignTo: "Assigned to",
    settingsCrew_search: "Search by Crew Member",
    settingsCrew_editTitle: "Edit Crew Member: ",
    settingsCrew_createTitle: "Add Crew Member ",
    settingsCrew_firstName: "First name",
    settingsCrew_lastName: "Last name",
    settingsCrew_minLengthMessage: "The field must contain minimum chars: ",
    settingsCrew_maxLengthMessage: "The field must contain maximum chars: ",
    settingsCrew_vehicle: "Vehicle",
    settingsCrew_success: "Crew Member Saved",
    settingsCrew_deleted: "Crews Members Deleted",
    settingsCrew_deleteTitle: "Delete Crew Members",
    settingsCrew_deleteWarning: "Are you sure you want delete the following crew members?",
    settingsCrew_alreadyExists: "A crew member already exists with the name entered",
    settingsCrew_bulkCrewAssigment: "Bulk Crew Assignment",
    settingsCrew_unit: "Unit",
    settingsCrew_unitDriver: "Unit - Driver",
    settingsCrew_crews: "Crew",
    settingsCrew_bulkSettings: "Bulk Settings",
    settingsCrew_assignEdit: "Re-Assign",
    settingsCrew_members: "Crew Members",
    settingsCrew_vehicles: "Vehicles",
    settingsCrew_crewAssigned: "Crew Assigned",
    settingsCrew_editCrewFor: "Edit Crew for ",
    settingsCrew_editCrew: "Edit Crew",
    settingsCrew_assignCrew: "Assign Crew"
};