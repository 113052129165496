import React from "react";
import { Menu, MenuItem } from "@mui/material";

const SliderList = ({list=[], onClick, anchorEl, setAnchorEl }) => {

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {list?.map((item) => (
        <MenuItem
          key={item.id}
          onClick={() => onClick(item)}
        >
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default SliderList;
