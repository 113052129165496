export default {
  videoClipRequest_pending: 'Pending',
  videoClipRequest_failed: 'Failed',
  videoClipRequest_inProgress: 'In Progress',
  videoClipRequest_completed: 'Completed',
  videoClipRequest_new: 'New Video Clip',
  videoClipRequest_getClip: 'Get Clip',
  videoClipRequest_unit: 'Unit',
  videoClipRequest_date: 'Date & Time',
  videoClipRequest_starredClips: 'Starred',
  videoClipRequest_clipRequest: 'Clip Request',
  videoClipRequest_successDeletion: 'The clip has been successfully removed',
  videoClipRequest_errorDeletion: 'There was an error deleting the clip',
  videoClipRequest_successCreation: 'The clip has been successfully requested.',
  videoClipRequest_invalidClipRange: 'The clip range is not beetwen an available clip',
  videoClipRequest_deleteConfirmationTitle: 'Warning',
  videoClipRequest_deleteConfirmationMessage: 'Do you want to delete the Clip?',
  videoClipRequest_updateNote: 'Update Note',
  videoClipRequest_addNote: 'Add Note',
  videoClipRequest_multiplesuccessDeletion: 'The clips has been successfully removed',
  videoClipRequest_multipleErrorDeletion: 'There was an error deleting the clips',
  videoClipRequest_deleteConfirmationEventMessage: 'Do you want to delete the event?',
  videoClipRequest_deleteConfirmationEventsMessage: 'Do you want to delete the selected events?',
  videoClipRequest_restoreClipSuccess: 'The clip has been successfully restored',
  videoClipRequest_restoreClipsSuccess: 'The clips has been successfully restored',
  videoClipRequest_deleteConfirmationClipsMessage: 'Do you want to delete the Clips?',
  // RadioCheck seconds selection
  videoClipRequest_seconds20: '20 sec clip (10 sec before, 10 sec after)',
  videoClipRequest_seconds40: '40 sec clip (20 sec before, 20 sec after)',
  videoClipRequest_seconds60: '60 sec clip (30 sec before, 30 sec after)',
  videoClipRequest_validateDate: 'The date is invalid',
  videoClipRequest_selectedClip: 'Selected Clip Range',
  videoClipRequest_selectedDate: 'Select a unit and a date please...',
  videoClipRequest_noUnitSelected: 'Select a unit to see the timeline...',
  videoClipRequest_notAvailable: 'Not Available',
  videoClipRequest_clipDate: 'Clip Date',
  videoClipRequest_driverUnit: 'Driver / Unit',
  videoClipRequest_timeFrame: 'Timeframe',
  videoClipRequest_requestor: 'Requestor',
  videoClipRequest_requested: 'Requested',
  videoClipRequest_from: 'From',
  videoClipRequest_to: 'To',

  videoClipRequest_unit: "Unit",
  videoClipRequest_driver: "Driver",
  videoClipRequest_status: "Status",
  videoClipRequest_dateTime: "Date/Time",
  videoClipRequest_notes: "Notes",
  videoClipRequest_requested: "Requested",
  videoClipRequest_filters: "Filters:",
  videoClipRequest_unitsFilter: "Tags/Units/Drivers:",
  videoClipRequest_dateRange: "Date Range:",
  videoClipRequest_dates: "Date:",
  videoClipRequest_time: "Time:",
  videoClipRequest_hasNotes: "Has Notes",
  videoClipRequest_noClips: "There are no clips for this unit",
  videoClipRequest_unassigned: 'Unassigned',
  videoClipRequest_noMoreActions: 'No more actions',
  videoClipRequest_Alert: 'Warning',
  videoClipRequest_message_grid: 'Select a range of available time in the following grid to choose your video clip',
  videoClipRequest_latitude: "Latitude:",
  videoClipRequest_longitude: "Longitude:",
  videoClipRequest_duration: "Duration",
  videoClipRequest_date: "Date",
  videoClipRequest_Image: 'Image',
  videoClipRequest_Video: 'Video',
  videoClipRequest_durationBefore: 'Seconds before',
  videoClipRequest_durationAfter: 'Seconds after',
  videoClipRequest_getPreview: "Get Preview"
};
