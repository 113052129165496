import landmarkCreateEdit from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkCreateEdit/lan/en';
import landmarkDelete from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkDelete/lan/en';
import landmarkGroupCreateEdit from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkGroupCreateEdit/lan/en';
import landmarkGroupDelete from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkGroupDelete/lan/en';
import landmarkSettings from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkSettings/lan/en';
import landmarkVisibleGroups from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkVisibleGroups/lan/en';
import landmarkBulk from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkBulk/lan/en';
import landmarkImport from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkImport/lan/en';
export default {
    ...landmarkCreateEdit,
    ...landmarkDelete,
    ...landmarkGroupCreateEdit,
    ...landmarkGroupDelete,
    ...landmarkSettings,
    ...landmarkVisibleGroups,
    ...landmarkBulk,
    ...landmarkImport,
    "map_landmark_edit": "Edit",
    "map_landmark_delete": "Delete",
    "map_landmark_select_all": "Select All",
    "map_landmark_landmarkgroups": "Landmarks",
    "map_landmark_manage_landmarks": "Manage Landmarks",
    "map_landmark_manage_visibility": "Manage Visibility",
    "map_landmark_manage_export": "Landmark Table",
    "map_landmark_group_manage_export": "Landmark Group Table",
    "map_landmark_group_manage_export_color": "Color",
    "map_landmark_group_manage_export_name": "Name",
    "map_landmark_group_manage_export_iconName": "Icon",
    "map_landmark_bulk_actions": "Bulk Actions",
    "map_landmark_import": "Import"
};