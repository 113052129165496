// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    SETTINGS_LOCATIONS_SEARCH,
    SETTINGS_LOCATIONS_CREATE_LOCATION,
    SETTINGS_LOCATIONS_EDIT_LOCATION,
    SETTINGS_LOCATIONS_DELETE_LOCATION
} from "Redux/actionTypes";

// Actions
import {
    searchLocationsResponse,
    createLocationResponse,
    editLocationResponse,
    deleteLocationResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* searchLocations({ payload }) {
    try {
        const query = `
        query searchLocations ($openSearch: String) {
            result: searchLocations (openSearch: $openSearch) {
                id
                title
                active
                unitsCount
            }
        }`;

        const variables = {
            openSearch: ""
        };
        const data = yield call(
            clientQuery,
            query,
            variables,
            endpoints.GRAPHQL_GENERAL
        );
        if (data && data.result) {
            yield put(searchLocationsResponse(data.result))
        } else {
            throw 'No locations found';
        }
    } catch (exc) {
        yield put(searchLocationsResponse())
        ExceptionManager(
            exc,
            "modules/settings/views/locations/redux/saga",
            "searchLocations"
        );
    }
}

function* createLocation({ payload }) {
    try {
        const { title, active } = payload
        const mutation = `
        mutation createLocation ($title: String!, $active: Boolean!) {
            result: createLocation (title: $title, active: $active) {
                id
                title
                active
            }
        }`;
        const variables = {
            title,
            active: true,
        };
        const data = yield call(
            clientMutation,
            mutation,
            variables,
            endpoints.GRAPHQL_GENERAL
        );
        if (data && data.result) {
            yield put(createLocationResponse(data.result))
        } else {
            throw 'The localization has not been created';
        }
    } catch (exc) {
        yield put(createLocationResponse({ error: true }));
        ExceptionManager(
            exc,
            "modules/settings/views/locations/redux/saga",
            "createLocation",
            true
        );
    }
}

function* editLocation({ payload }) {
    try {
        const mutation = `
        mutation updateLocation ($id: Int!, $title: String!, $active: Boolean!) {
            result: updateLocation (id: $id, title: $title, active: $active) {
                id
                title
                active
            }
        }
        `;
        const variables = {
            ...payload,
            active: true
        };
        const data = yield call(
            clientMutation,
            mutation,
            variables,
            endpoints.GRAPHQL_GENERAL
        );
        if(data && data.result){
            yield put(editLocationResponse(data.result))
        }
    } catch (exc) {
        yield put(editLocationResponse({ error: true }))
        ExceptionManager(
            exc,
            "modules/settings/views/locations/redux/saga",
            "editLocation",
            true
        );
    }
}

function* deleteLocation({ payload }){
    try {
        const { locations, moveChildsToGroupId } = payload;

        let mutations = [];

        locations.forEach((location, index) => {
			mutations.push(`
            deleteLocation${index}: deleteLocation(
              id: ${location.id},
              moveChildsToLocationId: ${moveChildsToGroupId},
            )
        `);
		});
        let mutation = `mutation { ${mutations.join(' ')} }`;

        let data = yield call(
			clientMutation,
			mutation,
			{},
			endpoints.GRAPHQL_GENERAL,
		);

        if(data && data?.deleteLocation0){
            yield put(deleteLocationResponse(data?.deleteLocation0))
        }
        
    } catch (exc) {
        yield put(deleteLocationResponse({ error: true }))
        ExceptionManager(
            exc,
            "modules/settings/views/locations/redux/saga",
            "deleteLocation",
            true
        );
    }
}

function* watchMethods() {
    yield takeEvery(SETTINGS_LOCATIONS_SEARCH, searchLocations);
    yield takeEvery(SETTINGS_LOCATIONS_CREATE_LOCATION, createLocation);
    yield takeEvery(SETTINGS_LOCATIONS_EDIT_LOCATION, editLocation);
    yield takeEvery(SETTINGS_LOCATIONS_DELETE_LOCATION, deleteLocation);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}