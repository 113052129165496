import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientMutation, clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
    ACCOUNT_TAGS_MERGE_TAGS_SEARCH_ENTITIES,
    ACCOUNT_TAGS_MERGE_TAGS_SAVE
} from 'Redux/actionTypes';

import {
    accountTagsMergeTagsSearchEntitiesResponse,
    accountTagsMergeTagsSaveResponse
} from 'Redux/actions';

function* accountTagsMergeTagsSearchEntitiesRequest({ payload }) {
    try {
        const { filters } = payload;

        const response = yield call(
            clientQuery,
            `
            query searchTagsAndEntitiesNested (
                $parentTagIds: [Int], 
                $entityName: EntityName!, 
                $openSearch: String,
                $onlyEntities: Boolean,
                $limit: Int, 
                $offset: Int
            ) {
                entityTags: searchTagsAndEntitiesNested(
                    parentTagIds: $parentTagIds, 
                    entityName: $entityName, 
                    limit: $limit, 
                    offset: $offset,
                    openSearch: $openSearch,
                    onlyEntities: $onlyEntities,
                ) {
                    items {
                        name
                        id
                        label
                    }
                    total
                }
            }
            `,
            {
                parentTagIds: filters?.parentTagIds,
                openSearch: filters?.openSearch,
                entityName: filters?.entityName,
                limit: filters?.limit || 0,
                offset: filters?.offset || 0,
                onlyEntities: true
            },
            endpoints.GRAPHQL_GENERAL,
        );
        
        if (!response || !response.entityTags) {
            throw 'No entityTags data';
        }

        const { entityTags: { items, total }} = response;
        yield put(accountTagsMergeTagsSearchEntitiesResponse({ items, total }));
    } catch (exc) {
        ExceptionManager(exc, 'modules/account/views/tags/components/mergeTags/redux/saga', 'accountTagsMergeTagsSearchEntitiesRequest');
        yield put(accountTagsMergeTagsSearchEntitiesResponse({ items: [], total: 0, error: true }));
    }
}

function* accountTagsMergeTagsSaveRequest({ payload }) {
    try {
        const { data } = payload;

        let query = `
            mutation mergeTags (
                $label: String!, 
                $color: String!, 
                $tagIds: [Int]!, 
                $parentId: Int
            ) {
                tag: mergeTags (
                    label: $label, 
                    color: $color, 
                    tagIds: $tagIds, 
                    parentId: $parentId
                ) {
                    id
                    label
                    color
                    parentId
                    path
                    level
                    hasChildren
                    unitsCount
                    driversCount
                    landmarksCount
                    usersCount
                    formsCount
                }
            }
        `;

        const response = yield call(
            clientMutation,
            query,
            data,
            endpoints.GRAPHQL_GENERAL,
        );

        if (!response || !response.tag) throw 'Error while merge tags';

        yield put(accountTagsMergeTagsSaveResponse({ ...response.tag, error: false }));
    } catch (exc) {
        yield put(accountTagsMergeTagsSaveResponse({ error: true }));
        ExceptionManager(exc, 'modules/account/views/tags/components/mergeTags/redux/saga', 'accountTagsMergeTagsSaveRequest', true);
    }
}

function* watchMethods() {
    yield takeEvery(ACCOUNT_TAGS_MERGE_TAGS_SEARCH_ENTITIES, accountTagsMergeTagsSearchEntitiesRequest);
    yield takeEvery(ACCOUNT_TAGS_MERGE_TAGS_SAVE, accountTagsMergeTagsSaveRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}