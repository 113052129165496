import {
    DASHBOARD_PANEL_GADGET_CHART_DRIVER_GROUP,
    DASHBOARD_PANEL_GADGET_CHART_DRIVER_GROUP_RESPONSE,
    DASHBOARD_PANEL_GADGET_CHART_DATA,
    DASHBOARD_PANEL_GADGET_CHART_DATA_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loading_driver_group: false,
    charts_driver_groups: [],
    loadingcharts:false,
    charts_data: []
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case DASHBOARD_PANEL_GADGET_CHART_DRIVER_GROUP:
            return {
                ...state,
                charts_driver_groups: [],
                loading_driver_group: true
            };

        case DASHBOARD_PANEL_GADGET_CHART_DRIVER_GROUP_RESPONSE:
            return {
                ...state,
                charts_driver_groups: action?.payload.driver_groups,
                loading_driver_group: false
            };

        case DASHBOARD_PANEL_GADGET_CHART_DATA:
            return {
                ...state,
                charts_data: [],
                loadingcharts: true
            };

        case DASHBOARD_PANEL_GADGET_CHART_DATA_RESPONSE:
            return {
                ...state,
                charts_data: action.payload,
                loadingcharts: false
            };

        case CLEAR_REDUX:
            return ["", "DASHBOARD_PANEL_GADGET_CHART"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : state;

        default:
            return state;
    }
};