// Dependencies
import React, { useState, useRef } from "react";
import { Avatar as AvatarIcon, Badge } from "@mui/material";

/**
 * Build a component to allow the user select items from entities.
 * @param {string} title - Component title.
 * @param {Array} entities - List of entities to show.
 * @param {string} selectedEntitiesTitle - Selected items section title.
 * @param {Function} triggerCollapse - Callback to trigger when the collapse inside the ItemsList is used.
 */
const Avatar = ({ value, width, name,color,onClick,style }) => {
  let src = "/assets/images/svg/avatars/blank.png";
  if (value?.url) {
    src = value.url;
  } else if (value?.file) {
    src = URL.createObjectURL(value.file);
  }

  if (!value?.url &&  !value?.file && name) {
    return <AvatarIcon className={color} aria-label="recipe" style={{margin:'0 auto',cursor:'pointer'}}  sx={{ width: width, height: width,...style }} onClick={onClick} >{name[0].toUpperCase()}</AvatarIcon>;
  }

  return <AvatarIcon src={src} className={color}  sx={{ width: width, height: width }} onClick={onClick} style={{margin:'0 auto',cursor:'pointer'}} />;
};

export default Avatar;
