import {
  //TYPES
  REALTIME_MAP_VEHICLETRAILS_GET,
  REALTIME_MAP_VEHICLETRAILS_GET_CONFIRM,
  REALTIME_MAP_VEHICLETRAILS_GET_CLEAR,
  REALTIME_MAP_VEHICLETRAILS_SHOW_TABLE,
  REALTIME_MAP_VEHICLETRAILS_SHOW_SETTINGS,
  REALTIME_MAP_VEHICLETRAILS_PLAYBACK_SET,
  REALTIME_MAP_VEHICLETRAILS_VEHICLE_PRINT,
  REALTIME_MAP_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET,
  REALTIME_MAP_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET_CONFIRM,
  REALTIME_MAP_VEHICLETRAILS_SEARCH_UNITS_GET,
  REALTIME_MAP_VEHICLETRAILS_SEARCH_UNITS_GET_RESULT,
  GET_LAST_VEHICLETRAILS,
  GET_LAST_VEHICLETRAILS_RESPONSE,
  REALTIME_MAP_VEHICLETRAILS_SELECT_ITEM_LIST
} from 'Redux/actionTypes';

export const getVehicleTrails = (params, startingQuery = true, exportData = '') => {
  return {
    type: REALTIME_MAP_VEHICLETRAILS_GET,
    payload: { params: params, startingQuery: startingQuery, exportData: exportData }
  };
}

export const getVehicleTrailsConfirmResult = (result, error) => ({
  type: REALTIME_MAP_VEHICLETRAILS_GET_CONFIRM,
  payload: { result, error },
});

export const updateShowVehicleTrails = (show) => ({
  type: REALTIME_MAP_VEHICLETRAILS_GET_CLEAR,
  payload: show,
});

export const updateShowVehicleTrailsTable = (show) => ({
  type: REALTIME_MAP_VEHICLETRAILS_SHOW_TABLE,
  payload: show,
});

export const updateShowVehicleTrailsSettings = (show) => ({
  type: REALTIME_MAP_VEHICLETRAILS_SHOW_SETTINGS,
  payload: show,
});

export const setVehicleTrailsPlayback = (value = true) => ({
  type: REALTIME_MAP_VEHICLETRAILS_PLAYBACK_SET,
  payload: value,
});

export const setVehicleTrailToPrint = (value) => ({
  type: REALTIME_MAP_VEHICLETRAILS_VEHICLE_PRINT,
  payload: value,
});

export const searchUnitTypeEvents = (value) => ({
  type: REALTIME_MAP_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET,
  payload: value,
});

export const searchUnitTypeEventsResult = (value) => ({
  type: REALTIME_MAP_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET_CONFIRM,
  payload: value,
});

export const searchUnitGet = (value) => ({
  type: REALTIME_MAP_VEHICLETRAILS_SEARCH_UNITS_GET,
  payload: value,
});

export const searchUnitGetResult = (value) => ({
  type: REALTIME_MAP_VEHICLETRAILS_SEARCH_UNITS_GET_RESULT,
  payload: value,
});

export const getLastVehicleTrail = (params, startingQuery = true, exportData = '') => {
  return {
    type: GET_LAST_VEHICLETRAILS,
    payload: { params: params, startingQuery: startingQuery, exportData: exportData }
  };
}

export const getLastVehicleTrailResult = (result, error) => ({
  type: GET_LAST_VEHICLETRAILS_RESPONSE,
  payload: { result, error },
});

export const onClickMarkerTrails = (value) => ({
  type: REALTIME_MAP_VEHICLETRAILS_SELECT_ITEM_LIST,
  payload: value,
})
