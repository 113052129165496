import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import moment from 'moment';
// controls
import Table, {Column} from '../../table'

import {
    formatDatetime,
    getCategoryFilterType,
    getEntityType, getMethodToFormatDuration,
    useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';


// hooks
import {useSidePanelMap, useTableReport} from 'Core/hooks';
// actions
import {reportsMainGenerateEvents, reportsMainGenerateReportProgress, reportsMainGenerateSubEvents} from 'Redux/actions';
import './stopSummaryReport.scss';
import AddressLandmark from "Modules/reports/views/main/components/reportsTab/.components/addressLandmark";
import {Accordion} from "Components";

const StopSummaryReport = (props) => {

    const {messages, report, user} = props;
    const { durationFormat } = user;
    const dispatch = useDispatch();
    const {loading, data, filters, loadingSummary, timeZone, summary} = report;
    const {filterEntity, startRange, endRange} = filters;

    const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
    const [currentId, setCurrentId] = useState(null);

    const [eventFilter, setEventFilter] = useState({});
    const exportHandler = useHandleExportReport(report);

    const reportRef = useRef();
    useEffect(() => {
        if (report) {
            const newReport = JSON.parse(JSON.stringify(report));
            reportRef.current = newReport;
        }
    }, [JSON.stringify(report)]);

    const durationFormatTime = getMethodToFormatDuration(durationFormat);

    const idEventsPerDay = (selected, id) => {
        let currentReport = report
        delete currentReport.summary
        dispatch(reportsMainGenerateReportProgress(currentReport))
        let events = {
            filters: {
                filterType: getCategoryFilterType(filterEntity),
                filterArray: [currentId],
                page: 1,
                rowsPerPage: 10,
                startRange: moment(selected).startOf('day').utc().toDate(),
                endRange: moment(selected).endOf('day').utc().toDate()
            }
        }
        setEventFilter(events)
        dispatch(reportsMainGenerateEvents(report, events))
    }


    const summaryTable = (item, type) => {
        const durations = item.map((id) => id?.duration)
        const summary = durations.reduce((p, c) => p + c );
        return (
            <Fragment>
                <Table
                    data={[0]}
                    noDataMessage={messages["reportsMainRT_noTripsAvailable"]}
                    hiddenPagination
                    hideHeader
                    classNameColumns={'report-sub-events-columns summary-event-columns'}
                >
                    <Column>
                        {() => {
                            return messages['generatedReport_stopSummaryTotal']
                        }}
                    </Column>
                    <Column>
                        {() => {
                            return summary ? durationFormatTime(summary, messages) : ''
                        }}
                    </Column>
                </Table>
            </Fragment>
        )
    }

    const extractedData = (data) => {
        const mapCount = data.reduce((acc, item) => {
                let group = item.stop
                acc.set(group, (acc.get(group) || 0) + 1)
                return acc
            }, new Map()
        )

        const contentMapEntries = [...mapCount.entries()];

        let contentIds = []
        for (const entry of contentMapEntries) {
            contentIds.push({stop: entry[0].toString(), count: entry[1]})
        }
        return contentIds;
    }

    const renderExpandableRowSubEvents = (row) => {

        const items = summary?.items.length ? extractedData(summary?.items) : [];

        return (
            <div style={{marginBottom: 10}}>
                <Table
                    loading={loadingSummary}
                    data={items || []}
                    totalItems={summary?.total}
                    onChangedPage={(page) => {
                        let currentEvents = eventFilter
                        currentEvents.filters.page = page
                        dispatch(reportsMainGenerateEvents(report, currentEvents))
                    }}
                    noDataMessage={messages["generatedReport_noEvents"]}
                    hideNumberOfRows={true}
                    classNameColumns={'report-sub-events-columns'}
                    centerPagination
                    serverSideTable={true}
                >
                    <Column noSorting>
                        {(element) => {
                            const filter = summary?.items.length ? summary?.items.filter((res) => res.stop === element.stop) : [];

                            return (
                                <div>
                                    <strong className="landmark-set">{element.stop}</strong>
                                    {
                                        <Accordion
                                            customTitle={() => { return (<b>{`${messages['generatedReport_totalsFor']}: ${element.stop}`}</b>) }}
                                            className="AccordionContainer-root start-stop-idle-sub-table"
                                            defaultExpanded
                                        >
                                            <div style={{width: '100%'}}>
                                                {summaryTable(filter || [], element.stop)}
                                            </div>
                                        </Accordion>
                                    }
                                    <Table
                                        data={filter}
                                        noDataMessage={messages["reportsMainRT_noTripsAvailable"]}
                                        hideNumberOfRows={true}
                                        hiddenPagination={true}
                                        classNameColumns={'report-sub-events-columns detail-day-moving-tr'}
                                    >
                                        <Column label={messages["generatedReport_timeAndDate"]} field="dateTime" noSorting>
                                            {(element) => {
                                                return (
                                                    <div>
                                                        <span className="speed-report-stacked">{element.dateTime && formatDatetime(element.dateTime, "h:mm A", timeZone)}</span>
                                                        <span
                                                            className="speed-reportstacked speed-report-event-date">{element.dateTime && formatDatetime(element.dateTime, "MMM D", timeZone)}</span>
                                                    </div>
                                                )
                                            }}
                                        </Column>
                                        <Column label={messages["generatedReport_address"]} field="address" noSorting>
                                            {(element) => {
                                                return (
                                                    <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={{address: element.address}}/>
                                                )
                                            }}
                                        </Column>
                                        <Column label={messages["generatedReport_status"]} field="status" noSorting>
                                            {(element) => {
                                                return (
                                                    <div>
                                                        <span>{element.status}</span>
                                                    </div>
                                                )
                                            }}
                                        </Column>
                                        <Column label={messages['generatedReport_duration']} field="duration" noSorting>
                                            {(element) => {
                                                return (
                                                    <div>
                                                        <span>
                                                          {element.duration ? durationFormatTime(element.duration, messages) : ''}
                                                        </span>
                                                    </div>
                                                )
                                            }}
                                        </Column>
                                    </Table>
                                </div>
                            );
                        }}
                    </Column>
                    <Column>
                        {() => { return '' }}
                    </Column>
                </Table>
                <SideMapComponent hiddenButton hiddenSearchMap/>
            </div>
        )
    }

    const onChangeExpandable = (selected) => {
        if(!selected.noData){
            let selectedId = (filterEntity === "Unit" ? selected.deviceId : selected.driverId) || selected.id
            setCurrentId(selectedId);
        }
    }

    let parametersTableSummary = useTableReport({
        data: [],
        report,
        messages,
        hideHeader: true,
        hiddenPagination: true,
        renderExpandableRow: renderExpandableRowSubEvents,
        showExpandable: true
    });

    const renderExpandableRow = (row) => {
        const summaryParameters = {
            ...parametersTableSummary,
            data: row?.dates,
            onChangeSelector: null,
            placeholderSelector: null,
            hideSelectorUnitsDriver: true,
            classNameColumns: "table-report-main-sub-table",
            idRenderExpandableRow: (event) => {idEventsPerDay(event, row?.id)},
            noData: row.noData
        }
        return (
            <Table
                {...summaryParameters}
            >
                <Column label="day" field="label">
                    {(element) => {
                        return <span><strong>{formatDatetime(element, "dddd, LL", timeZone)}</strong></span>
                    }}
                </Column>
                <Column label={messages['generatedReport_totalIdleTime']} field="label"></Column>
            </Table>
        )
    }

    let parametersTable = useTableReport({
        data: data.items,
        report,
        messages,
        hideHeader: true,
        renderExpandableRow,
        onChangeExpandable,
        propertyToShowExpandable: 'driverId',
        classNameColumns: "table-report-main-expandible"
    });

    return (
        <Fragment>
            <Table
                {...parametersTable}
                {...exportHandler}
            >
                <Column noSorting>
                    {(element) => {
                        const type = getEntityType(report, messages)
                        return <span><strong>{type}:  </strong>{`${element.driverName || ''}`}</span>
                    }}
                </Column>
                <Column noSorting>
                    {() => {
                        const localTimeStart = moment.utc(startRange).local().format();
                        const localTimeEnd = moment.utc(endRange).local().format();
                        return (<div>
                                  <span>
                                    <span>
                                      <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                                    </span>
                                  </span>
                            </div>);
                    }}
                </Column>
            </Table>
        </Fragment>
    );
};

export default StopSummaryReport;
