export default {
  role_role: "Role",
  role_permissions: "Permissions",
  role_alerts: "Alerts",
  role_reports: "Reports",
  role_edit: "Edit Role",
  role_create: "Create a New Role",
  role_label: "Role Label",
  role_minMessageUserType: "The role must contain minimun chars:",
  role_maxMessageUserType: "The role must contain maximum chars:",
  role_globalAccess: "Global Access",
  role_localAccess: "Local Access",
  role_createMessage: "Role Created",
  role_editMessage: "Role Updated",
  role_deleteMessage: "Role Deleted",
  role_messageDelete: "Are you sure you want to delete the roles?",
  role_delete: "Delete Role",
  role_custom: "Role Custom",
  role_supervisor: "Supervisor",
  role_thisUsetsupervisor: "This user is a Supervisor"

};