// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  FSM_SETTINGS_TIME_CARD_SEARCH,
  FSM_SETTINGS_TIME_CARD_CREATE,
  FSM_SETTINGS_TIME_CARD_UPDATE,
  FSM_SETTINGS_TIME_CARD_DELETE,
  FSM_SETTINGS_TIME_CARD_DRIVER
} from "Redux/actionTypes";

// Actions
import {
  searchTimeCardResponse,
  createTimeCardResponse,
  updateTimeCardResponse,
  deleteTimeCardResponse,
  searchDriverTimeCardResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";


function* searchTimeCardRequest({ payload }) {
  try {

    const query = `
    query searchTimeCards ($startDate: String!, $driverId: Int!, $endDate: String!, $limit: Int, $offset: Int) {
      searchTimeCards (startDate: $startDate, driverId: $driverId, endDate: $endDate, limit: $limit, offset: $offset) {
          items {
              id
              action
              date
              type
          }
          total
      }
  }
        `;

    const response = yield call(
      clientQuery,
      query,
      {
        ...payload?.data
      },
      endpoints.GRAPHQL_GENERAL
    );
    if (!response && !response.searchTimeCards) {
      throw "Error while getting Time Card list";
    }
    let timeCard = response.searchTimeCards?.items;
    yield put(searchTimeCardResponse({ timeCard }));
  } catch (exc) {
    yield put(searchTimeCardResponse({ timeCard: [] }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "searchTimeCardRequest",
      true
    );
  }
}

function* searchTimeCardDriverRequest() {
  try {

    const query = `
    query searchDrivers ($includeInactiveDrivers: Boolean, $openSearch: String, $limit: Int, $offset: Int, $fsmDriver: Boolean, $tagIds: [Int], $unitIds: [Int]) {
      searchDrivers (includeInactiveDrivers: $includeInactiveDrivers, openSearch: $openSearch, limit: $limit, offset: $offset, fsmDriver: $fsmDriver, tagIds: $tagIds, unitIds: $unitIds) {
          name
          driverId
          status
          unitId
          unit {
              id
              label
          }
          driverStatus
          connected
          lastConnected
      }
  }
        `;

    const response = yield call(
      clientQuery,
      query,
      {
        fsmDriver: true,
        tagIds: []
      },
      endpoints.GRAPHQL_GENERAL
    );
    if (!response && !response.searchDrivers) {
      throw "Error while getting Time Card list";
    }
    let drivers = response.searchDrivers;
    yield put(searchDriverTimeCardResponse({ drivers }));
  } catch (exc) {
    yield put(searchDriverTimeCardResponse({ drivers: [] }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "searchTimeCardDriverRequest",
      true
    );
  }
}


function* createTimeCardRequest({ payload }) {
  try {

    let query = `
    mutation createTimeCard ($driverId: Int!, $action: String!, $date: String!) {
      createTimeCard (driverId: $driverId, action: $action, date: $date) {
          id
          action
          date
      }
  }
      `;



    const response = yield call(
      clientMutation,
      query,
      payload,
      endpoints.GRAPHQL_GENERAL,
    );


    yield put(createTimeCardResponse({ create: response, error: false }));
  } catch (exc) {
    yield put(createTimeCardResponse({ create: null, error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "createTimeCardRequest",
      true
    );
  }
}

function* updateTimeCardRequest({ payload }) {
  try {
    const { TimeCard } = payload;

    let query = `
    mutation updateTimeCard ($id: Int!, $driverId: Int!, $action: String!, $date: String!) {
      updateTimeCard (id: $id, driverId: $driverId, action: $action, date: $date) {
          id
          action
          date
      }
  }
      `;



    const response = yield call(
      clientMutation,
      query,
      payload,
      endpoints.GRAPHQL_GENERAL,
    );


    yield put(updateTimeCardResponse({ update: response, error: false }));
  } catch (exc) {
    yield put(updateTimeCardResponse({ update: null, error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "updateTimeCardRequest",
      true
    );
  }
}

function* deleteTimeCardRequest({ payload }) {
  try {

    const { timeCard } = payload;
    let mutations = [];
    timeCard?.forEach((Form, index) => {
      mutations.push(`
            TimeCard${index}: deleteTimeCard(id: ${Form?.id})
        `);
    });

    let mutation = `mutation{${mutations.join("")}}`;

    const response = yield call(
      clientMutation,
      mutation,
      {},
      endpoints.GRAPHQL_GENERAL,
    );

    if (!response || !response.TimeCard0) throw `Error while deleting Time Cards, ${timeCard}`;



    yield put(deleteTimeCardResponse({ data: response.TimeCard0, error: false }));
  } catch (exc) {
    yield put(deleteTimeCardResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "deleteForms",
      true
    );
  }
}



function* watchMethods() {
  yield takeEvery(FSM_SETTINGS_TIME_CARD_SEARCH, searchTimeCardRequest);
  yield takeEvery(FSM_SETTINGS_TIME_CARD_DRIVER, searchTimeCardDriverRequest);
  yield takeEvery(FSM_SETTINGS_TIME_CARD_CREATE, createTimeCardRequest);
  yield takeEvery(FSM_SETTINGS_TIME_CARD_UPDATE, updateTimeCardRequest);
  yield takeEvery(FSM_SETTINGS_TIME_CARD_DELETE, deleteTimeCardRequest);
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}
