export const SUB_USER_SEARCH = "SUB_USER_SEARCH";
export const SUB_USER_SEARCH_RESPONSE = "SUB_USER_SEARCH_RESPONSE";
export const SUB_USER_GET = "SUB_USER_GET";
export const SUB_USER_GET_RESPONSE = "SUB_USER_GET_RESPONSE";
export const SUB_USER_CREATE = "SUB_USER_CREATE";
export const SUB_USER_CREATE_RESPONSE = "SUB_USER_CREATE_RESPONSE";
export const SUB_USER_UPDATE = "SUB_USER_UPDATE";
export const SUB_USER_UPDATE_RESPONSE = "SUB_USER_UPDATE_RESPONSE";
export const SUB_USER_DELETE = "SUB_USER_DELETE";
export const SUB_USER_DELETE_RESPONSE = "SUB_USER_DELETE_RESPONSE";
export const SUB_USER_RESET_COGNITO = "SUB_USER_RESET_COGNITO";
export const SUB_USER_RESET_COGNITO_RESPONSE = "SUB_USER_RESET_COGNITO_RESPONSE";
export const SUB_USER_DELETE_BULK = "SUB_USER_DELETE_BULK"
export const SUB_USER_RESET_PASSWORD = "SUB_USER_RESET_PASSWORD"
export const SUB_USER_RESET_PASSWORD_RESPONSE = "SUB_USER_RESET_PASSWORD_RESPONSE"
