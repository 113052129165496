// View Keys
export default {
  reportsMainST_scheduleReport: 'Programación de Reporte',
  reportsMainST_scheduleName: 'Schedule Name',
  reportsMainST_nameOfSchedule: 'Nombre de la programación',
  reportsMainST_stepGeneral: 'General',
  reportsMainST_stepFrequency: 'Frecuencia',
  reportsMainST_stepUnits: 'Unidades',
  reportsMainST_stepSubscribers: 'Entrega',
  reportsMainST_scheduleCreatedSuccessfully: 'Programación ha sido creado correctamente.',
  reportsMainST_scheduleUpdatedSuccessfully: 'Programación ha sido actualizado correctamente.',
  reportsMainST_scheduleFrequencyMessage: '¿Con qué frecuencia le gustaría recibir los informes?',
  reportsMainST_nameOfSchedulePlaceholder: 'Mi nuevo informe programado',
  reportsMainST_includeDays: 'Incluir estos días',
  reportsMainST_selectAtLeastOneDay: 'Seleccione al menos un día',
  reportsMainST_scheduleList: 'Lista de Programación',
  reportsMainST_withoutSchedules: 'No hay reportes programados previamente. Haga click en el icono de agregar para programar un nuevo reporte.',
  reportsMainST_deleteSchedule: 'Programación eliminada exitosamente',
  reportsMainST_deleteReport: 'Reporte eliminado exitosamente',
  reportsMainST_deleteAllScheduleMessage: 'Estas seguro que quieres eliminar todas las programaciones?',
  reportsMainST_deleteScheduleMessage: 'Estas seguro que quieres eliminar esta programación?',
  reportsMainST_deleteSchedulesMessage: 'Estas seguro que quieres eliminar estas programaciones?',
  reportsMainST_deleteExclusionsSchedulesMessage: 'Estas seguro que quieres todas las programaciones menos estas?',
  reportsMainST_deleteExclusionsScheduleMessage: 'Estas seguro que quieres todas las programaciones menos esta?',
  reportsMainST_deleteReportMessage: 'Estas seguro que quieres eliminar este reporte?',
  reportsMainST_deleteReportsMessage: 'Estas seguro que quieres eliminar estos reportes?',
  reportsMainST_deleteAllReportsMessage: '¿Seguro que quiere borrar todos los informes seleccionados?',
  reportsMainST_title: 'Título',
  reportsMainST_frequency: 'Frecuencia',
  reportsMainST_subscribers: 'Suscriptores',
  reportsMainST_deliveredAt: 'Entregar a las',
  reportsMainST_deliverOn: 'Entregar en',
  reportsMainST_errorNotDay: 'Por favor, seleccione al menos un día',
  reportsMainST_sendReportTo: 'Enviar informe a',
  reportsMainST_scheduleExpires: 'Schedule Expires',
  reportsMainST_limitToThisTimeFrame: 'Límite del plazo',
  reportsMainST_type: 'Tipo',
  reportsMainST_format: 'Formato',
  reportsMainST_scheduled: 'Programado',
  reportsMainST_days: 'Días',
  reportsMainST_time: 'Tiempo',
  reportsMainST_nextReport: 'Siguiente reporte',
  reportsMainST_selectUnitsOrDrivers: 'Seleccionar unidades o conductores',
  reportsMainST_selectViewOptions: 'Ver opciones',
  reportsMainST_selectViewBy: 'Vista por',
  reportsMainST_selectRunFor: 'Corre Por',
  reportsMainST_selectTimeframe: 'Periodo de tiempo',
  reportsMainST_selectCreated: 'Creado',
  reportsMainST_selectStatus: 'Estado',
  reportsMainST_selectReportFormat: 'Seleccionar formato de informe',
  reportsMainST_deliveryOptions: 'Opciones de entrega',
  reportsMainST_reportDetails: 'Detalles del reporte',
  reportsMainST_report: 'Reporte',
  reportsMainST_reportName: 'Nombre del Reporte',
  reportsMainST_reportType: 'Tipo de Reporte',
  reportsMainST_daily: 'Diario',
  reportsMainST_weekly: 'Semanal',
  reportsMainST_monthly: 'Mensualmente',
  reportsMainST_dailyDescription: 'Enviar el reporte el día seleccionado',
  reportsMainST_weeklyDescription: 'Enviar el reportes el día seleccionado cada semana',
  reportsMainST_monthlyDescription: 'Enviar el reporte el día seleccionado del mes',
  reportsMainST_selectADay: 'Seleccione un día',
  reportsMainST_excludeWeeKendDays: 'Excluye los días de fin de semana',
  reportsMainST_selectContacts: 'Seleccionar contactos',
  reportsMainST_pdf: 'PDF',
  reportsMainST_csv: 'CSV',
  reportsMainST_web: 'Web',
  reportsMainST_ftp: 'FTP',
  reportsMainST_delivery: 'Entrega',
  reportsMainST_initialLetterMonday: 'L',
  reportsMainST_initialLetterTuesday: 'M',
  reportsMainST_initialLetterWednesday: 'Mi',
  reportsMainST_initialLetterThursday: 'J',
  reportsMainST_initialLetterFriday: 'V',
  reportsMainST_initialLetterSaturday: 'S',
  reportsMainST_initialLetterSunday: 'D',
  reportsMainST_the: 'el',
  reportsMainST_ordinals: '',
  reportsMainST_deliveryOnRequired: 'Entregar en es requerido',
  reportsMainST_deliveryAtRequired: 'Entregar a las es requerido',
  reportsMainST_selectADeliveryMethod: 'Seleccione el método de entrega',
  reportsMainST_selectAnFTBServer: 'Seleccione un servidor FTP a continuación o añada uno nuevo',
  reportsMainST_pleaseSelectARecipientBelow: 'Seleccione un destinatario',
  time: 'Tiempo',



  daily: "Diario",
  weekly: "Semanal",
  monthly: "Mensual",

  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miercoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sábado",
  sunday: "Domingo",
  at: "a las",  
  entitySelect: "Seleccione una entidad",
  optionSelect: "Seleccione una opcion"
};
