// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    ACCOUNT_TAGS_SAVE_TAG,
    ACCOUNT_TAGS_GET_TAG,
    ACCOUNT_TAGS_GET_ENTITIES
} from "Redux/actionTypes";

// Actions
import {
    accountTagsSaveTagResponse,
    accountTagsGetTagResponse,
    accountTagsGetEntitiesResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* accountTagsSaveTagRequest({ payload }) {
    try {
        const { tag } = payload;

        let query = `
            mutation createTag (
                $label: String!, 
                $color: String!, 
                $parentId: Int,
                $unitIds: [Int], 
                $driverIds: [Int], 
                $landmarkIds: [Int],
                $userIds: [Int],
                $formIds: [Int],
                $geofenceIds: [Int],
                $dynamicFormIds: [Int]
            ) {
                tag: createTag (
                    label: $label, 
                    color: $color, 
                    parentId: $parentId,
                    unitIds: $unitIds, 
                    driverIds: $driverIds, 
                    landmarkIds: $landmarkIds, 
                    userIds: $userIds,
                    formIds: $formIds,
                    geofenceIds: $geofenceIds,
                    dynamicFormIds: $dynamicFormIds
                ) {
                    id
                    label
                    color
                    parentId
                }
            }
        `;

        if (tag?.id) {
            query = `
                mutation updateTag (
                    $id: Int!, 
                    $label: String, 
                    $color: String, 
                    $parentId: Int,
                    $unitIds: [Int], 
                    $driverIds: [Int], 
                    $landmarkIds: [Int],
                    $userIds: [Int],
                    $formIds: [Int],
                    $geofenceIds: [Int],
                    $dynamicFormIds: [Int]
                ) {
                    tag: updateTag (
                        id: $id, 
                        label: $label, 
                        color: $color, 
                        parentId: $parentId
                        unitIds: $unitIds, 
                        driverIds: $driverIds, 
                        landmarkIds: $landmarkIds, 
                        userIds: $userIds,
                        formIds: $formIds,
                        geofenceIds: $geofenceIds,
                        dynamicFormIds: $dynamicFormIds
                    ) {
                        id
                        label
                        color
                        parentId
                    }
                }
            `;
        }

        const response = yield call(
            clientMutation,
            query,
            tag,
            endpoints.GRAPHQL_GENERAL,
        );

        if (!response || !response.tag) throw 'Error while creating/updating tag';

        yield put(accountTagsSaveTagResponse({ ...response.tag, error: false }));
    } catch (exc) {
        yield put(accountTagsSaveTagResponse({ error: true }));
        ExceptionManager(
            exc,
            "modules/account/views/tags/components/redux/sagas",
            "accountTagsSaveTagRequest",
            true
        );
    }
}

function* accountTagsGetTagRequest({ payload }) {
    try {
        const { id } = payload;
        const query = `
            query getTag ($id: Int!) {
                tag: getTag (id: $id) {
                    id
                    label
                    color
                    parentId
                    parent {
                        id
                        label
                        color
                        parentId
                    }
                }
            }
        `;

        const response = yield call(
            clientQuery,
            query,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );

        if (!response?.tag) throw `Error while getting tag ${id}`;

        yield put(accountTagsGetTagResponse({ ...response?.tag, error: false }));
    } catch (exc) {
        yield put(accountTagsGetTagResponse({ error: true }));
        ExceptionManager(
            exc,
            "modules/account/views/tags/components/redux/sagas",
            "accountTagsGetTagRequest"
        );
    }
}

function* accountTagsGetEntitiesRequest({ payload }) {
    try {
        const { data } = payload;
        const query = `
            query searchTagsAndEntitiesNested (
                $parentTagIds: [Int], 
                $entityName: EntityName!, 
                $openSearch: String,
                $onlyEntities: Boolean,
                $limit: Int, 
                $offset: Int
            ) {
                entityTags: searchTagsAndEntitiesNested(
                    parentTagIds: $parentTagIds, 
                    entityName: $entityName, 
                    limit: $limit, 
                    offset: $offset,
                    openSearch: $openSearch,
                    onlyEntities: $onlyEntities,
                ) {
                    items {
                        name
                        id
                        label
                    }
                    total
                }
            }
        `;

        const response = yield call(
            clientQuery,
            query,
            {
                parentTagIds: data?.parentTagId ? [data?.parentTagId] : [],
                openSearch: "",
                entityName: data?.entityName,
                limit: 0,
                offset: 0,
                onlyEntities: true
            },
            endpoints.GRAPHQL_GENERAL
        );

        if (!response?.entityTags) throw `Error while getting entityTags ${data?.parentTagId}`;
        
        const { entityTags: { items, total }} = response;
        yield put(accountTagsGetEntitiesResponse({ items, total, entityName: data?.entityName, error: false }));
    } catch (exc) {
        yield put(accountTagsGetEntitiesResponse({ items: [], error: true }));
        ExceptionManager(
            exc,
            "modules/account/views/tags/components/redux/sagas",
            "accountTagsGetEntitiesRequest"
        );
    }
}

function* watchMethods() {
    yield takeEvery(ACCOUNT_TAGS_SAVE_TAG, accountTagsSaveTagRequest);
    yield takeEvery(ACCOUNT_TAGS_GET_TAG, accountTagsGetTagRequest);
    yield takeEvery(ACCOUNT_TAGS_GET_ENTITIES, accountTagsGetEntitiesRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}