import {
    FSM_SETTINGS_MEAL_BREAK_GET,
    FSM_SETTINGS_MEAL_BREAK_GET_RESPONSE,
    FSM_SETTINGS_MEAL_BREAK_CREATE,
    FSM_SETTINGS_MEAL_BREAK_CREATE_RESPONSE
} from "Redux/actionTypes";



export const getMealBreakSetting = (data) => {
    return {
        type: FSM_SETTINGS_MEAL_BREAK_GET,
        payload: { data },
    };
};

export const getMealBreakSettingResponse = (data) => {
    return {
        type: FSM_SETTINGS_MEAL_BREAK_GET_RESPONSE,
        payload: data,
    };
};

export const createBreakSettings = (data) => {
    return {
        type: FSM_SETTINGS_MEAL_BREAK_CREATE,
        payload: { data },
    };
};

export const createBreakSettingsResponse = (data) => {
    return {
        type: FSM_SETTINGS_MEAL_BREAK_CREATE_RESPONSE,
        payload: data,
    };
};


