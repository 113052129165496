import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

//STATE INITIAL
const INIT_STATE = {}

const route = "modules/dashboard/views/landmarkVisit/redux/slice";

export const dashboardSearchLandmarkVisits = createAsyncThunk("modules/dashboard/views/landmarkVisit", async ({ landmarksIds, keyMemory, fractionTime, parameterOrder, limit, offset }, { rejectWithValue, dispatch }) => {

  try {
    let response = await clientQuery(
      `
            query ($landmarksIds: [landmarkToSearchVisit]!,$keyMemory: String!, $fractionTime: String!, $parameterOrder: String!, $limit: Int!, $offset: Int!){
                data: searchLandmarkVisit(landmarksIds: $landmarksIds,keyMemory: $keyMemory, fractionTime: $fractionTime, parameterOrder: $parameterOrder, limit: $limit, offset: $offset){
                   items{
                   landmark{
                      landmarkId
                      landmarkName
                      url
                      color
                   }
                    visits
                    totalTime
                   }
                   total
                }
            }
            `,
      {
        landmarksIds,
        keyMemory,
        fractionTime,
        parameterOrder,
        limit,
        offset
      },
      endpoints.GRAPHQL_GENERAL_V2
    );
    return response.data
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, route, 'dashboardSearchLandmarkVisits');
    return rejectWithValue(exc);
  }
}
)

export const dashboardSearchLandmarkVisitsRedux = createSlice({
  name: 'dashboard/landmarkVisit',
  initialState: INIT_STATE,
  reducers: {
    reset(state, action) {
      const { viewId } = action.payload;
      if (viewId) {
        state[viewId] = { loadingLandmarksVisits: false, landmarksVisits: [], error: null };
      } else {
        return INIT_STATE;
      }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(dashboardSearchLandmarkVisits.pending, (state, action) => {
      const { viewId } = action.meta.arg;
      if (!state[viewId]) {
        state[viewId] = { loadingLandmarksVisits: false, landmarksVisits: [], error: null };
      }
      state[viewId].loadingLandmarksVisits = true;
    });

    builder.addCase(dashboardSearchLandmarkVisits.fulfilled, (state, action) => {
      const { viewId } = action.meta.arg;
      state[viewId].landmarksVisits = action.payload;
      state[viewId].loadingLandmarksVisits = false;
    });

    builder.addCase(dashboardSearchLandmarkVisits.rejected, (state, action) => {
      const { error } = action.payload;
      const { viewId } = action.meta.arg;
      state[viewId].loadingLandmarksVisits = false;
      state[viewId].error = error;
    });
  },
})

const { actions, reducer } = dashboardSearchLandmarkVisitsRedux;

// Extract and export each action creator by name
export const {
  reset,
} = actions;

export default reducer;
