//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { NotificationManager } from 'Components/Notifications';

//Action Types
import {
  REPORTS_MAIN_GET_SCHEDULE_REPORT_SETUP,
  REPORTS_MAIN_DELETE_SCHEDULE_REPORT
} from 'Redux/actionTypes';

//Actions
import { getSchedulesResult, deleteScheduleResult } from 'Redux/actions';
import { getReportTypeName, getFrequencyName,getFrequencyNameInMultiLanguage } from '../../../../../.globals/helpers';
import { formatDatetime } from '../../reportsTab/helper';
import { getDeliveryAtFormat } from 'Core/data/Helpers';

//Function
function* getSchedulesRequest({ payload }) {
  const { messages, timeZone } = payload;
  try {
    let data = yield call(
      clientQuery,
      `query searchSchedule ($openSearch: String, $offset: String, $reportType: enumReportType, $activeOnly: Boolean, $limit: Int) {
        searchSchedule (openSearch: $openSearch, offset: $offset, reportType: $reportType, activeOnly: $activeOnly, limit: $limit) {
            items {
                id
                name
                reportType
                category {
                    field
                    value
                    categoryName
                    dataResultFilter
                }
                integerRanges {
                    fields
                    GTE
                    LTE
                }
                dateRange {
                    fields
                    GTE
                    LTE
                    timeframeName
                }
                subscribersTxt 
                timeZone
                frequency
                weekDays
                includeWeekend
                monthDays
                deliveryAt
                deliveryOn
                scheduleExpires
                format
                active
                nextExecution
                ftpHost
                ftpHostType
            }
            total
            offset
        }
      }`,
      {
        "openSearch": "",
        "offset": "1",
        "reportType": null,
        "limit": -1
      },
      endpoints.GRAPHQL_PX_REPORTING
    );

    if (data.searchSchedule){
      const newItems = data.searchSchedule.items.map((item) => {
        item.deliveryAt
        const deliveryAt = getDeliveryAtFormat(item.deliveryAt, item.frequency, item.deliveryOn,messages)
        return {
          ...item,
          reportType: getReportTypeName(item.reportType, messages),
          deliveryAt,
          frequencyLabel: getFrequencyNameInMultiLanguage(item.frequency, messages),
          frequency: getFrequencyName(item.frequency),
          nextExecution: item.nextExecution ? `${formatDatetime(item.nextExecution, "YYYY/MM/DD", timeZone)} ${item.deliveryAt}`: '' ,
          format: item.format?.toUpperCase() || ''
        };
      });

      const newSchedules = {
        ...data.searchSchedule,

        items: newItems
      }
      yield put(getSchedulesResult(newSchedules));
    }
    else throw 'Error Searching Schedules';
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/reports/views/main/components/scheduleTab/redux/saga.js', 'getSchedulesRequest');
    NotificationManager.error('Error', `Error Searching Scheduled tasks`, 3000, null, null, 'error');
    yield put(getSchedulesResult([]));
  }
}

function* deleteSchedulesRequest({ payload }) {
  const { ids, idsExclusions } = payload.data;
  let mutations = [];
  try {
    if(ids){
      let mutation = `mutation deleteScheduleReport ($ids: [String]!, $idsExclusions: [String]){
        deleteScheduleReport (ids: $ids, idsExclusions: $idsExclusions)
      }`;
      let data = yield call(
				clientMutation,
				mutation,
				{
          ids,
          idsExclusions
        },
				endpoints.GRAPHQL_PX_REPORTING,
			);
      if (data?.deleteScheduleReport){
        yield put(deleteScheduleResult(data.deleteScheduleReport));
      } else {
        throw 'Error Deleting  Schedule';
      }
		}
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/reports/views/main/components/scheduleTab/redux/saga.js', 'deleteSchedulesRequest');
    NotificationManager.error('Error', `Error Deleting Scheduled`, 3000, null, null, 'error');
    yield put(deleteScheduleResult(null));
  }
}

function* watchGetSchedulesRequest() {
  yield takeEvery(REPORTS_MAIN_GET_SCHEDULE_REPORT_SETUP, getSchedulesRequest);
  yield takeEvery(REPORTS_MAIN_DELETE_SCHEDULE_REPORT, deleteSchedulesRequest);

}

//Saga Export
export default function* rootSaga() {
  yield all([fork(watchGetSchedulesRequest)]);
}
