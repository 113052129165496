
const DEVICE_ID_BASE = 278

const compareFirmwareVersion = (firmwareId) => {
  if(firmwareId >= DEVICE_ID_BASE ){
     return true
  }
  return false
}

export const isDeviceCompatible = (firmwareId) => {
    if (compareFirmwareVersion(firmwareId)) {
      return true;
    }
    
    return false;
}