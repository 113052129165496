// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    MAP_SPEED_OVERRIDE_SEARCH_SPEED_OVERRIDE,
    MAP_SPEED_OVERRIDE_SEARCH_SPEED_OVERRIDE_CLEAR
} from "Redux/actionTypes";

// Actions
import {
    mapSpeedOverrideSearchResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* mapSpeedOverrideSearchSpeedOverrideRequest({ payload }) {
    try {
        const query = `
        query searchSpeedOverride ($openSearch: String, $limit: Int, $offset: Int) {
            searchSpeedOverride (openSearch: $openSearch, limit: $limit, offset: $offset) {
                items {
                    id
                    companyId
                    name
                    startAddress
                    endAddress
                    encodedPath
                    speedLimit
                    notes
                    userName                
                    
                    createdBy
                    createdOn
                    updatedBy
                    updatedOn
                    isDeleted
                    deletedOn
                }
                total
            }
        }`
        
        const variables = {
            ...payload
        };
    
        const data = yield call(clientQuery, query, variables, endpoints.GRAPHQL_GENERAL);
        
        if (data) {
            yield put(mapSpeedOverrideSearchResponse(data.searchSpeedOverride));
        } else {
            throw 'Error in speed override module';
        }
        
        
    } catch (exc) {
        yield put(mapSpeedOverrideSearchResponse({ error: true }));
        ExceptionManager(
            exc,
            "realtimeMaps/main/components/speedOverride/components/speedOverrideList/redux/saga",
            "mapSpeedOverrideSearchSpeedOverrideRequest"
        );
    }
}

function* watchMethods() {
    yield takeEvery(MAP_SPEED_OVERRIDE_SEARCH_SPEED_OVERRIDE, mapSpeedOverrideSearchSpeedOverrideRequest);
    yield takeEvery(MAP_SPEED_OVERRIDE_SEARCH_SPEED_OVERRIDE_CLEAR, mapSpeedOverrideSearchSpeedOverrideRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}