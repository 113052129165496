//View Keys
export default {
  realtimemaps_geofence_create_select: 'Geofence Type',
  realtimemaps_geofence_polygon: 'Polygon',
  realtimemaps_geofence_polygon_max: 'Polygon +',
  realtimemaps_geofence_polygon_circle: 'Circle',
  realtimemaps_geofence_polygon_rectangle: 'Square',
  realtimemaps_geofence_contacts: 'Contacts',
  realtimemaps_geofence_group: 'Geofence Tags',
  realtimemaps_geofence_inout: 'In/Out',
  realtimemaps_geofence_in: 'In',
  realtimemaps_geofence_out: 'Out',
  realtimemaps_geofence_alerton: 'Alert On',
  realtimemaps_geofence_info_polygon: 'Begin clicking points on the map to create a geofence. Must be 3–{maxPoints} points.',
  realtimemaps_geofence_info_circle: 'The geofence will be centered at the address provided with the specified radius. Use the locate address area above to change the location.',
  realtimemaps_geofence_create: 'Geofence Create',
  realtimemaps_latLngError: 'Please, select a point in the map'
};