import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import {
    MAPS_ETA_LINK_GET
} from 'Redux/actionTypes';

import {
    mapsEtaLinkPublicGetResponse
} from 'Redux/actions';

function* searchPublicEtaLink({ payload }) {
    try {
        let hasVideo = false
        const { code } = payload;
        const markerDetail = yield call(
            clientQuery,
            `
            query getPublicEtaLink ($code: String!) {
                etaLinkData: getPublicEtaLink (code: $code) {
                    id
                    code
                    unit {
                        label
                        driverName
                        lastReading {
                            latitude
                            longitude
                            eventCode
                            eventName
                            heading
                        }
                    }
                    unitId
                    status
                    address
                    latitude
                    longitude
                    isKph
                    scheduleSnapshot
                }
            }`,
            { code: code },
            endpoints.GRAPHQL_GENERAL,
            {
                'x-api-key': endpoints.PUBLIC_API_KEY
            }
        );

        if (markerDetail?.etaLinkData?.unitId) {
            let unitDetail = yield call(
                clientQuery,
                `
                    query getPublicMarkerDetail($id: Int!) {
                        result: getPublicMarkerDetail(id: $id)
                    }
                `,
                {
                    id: markerDetail?.etaLinkData?.unitId
                },
                endpoints.GRAPHQL_GENERAL_V2,
                {
                    'x-api-key': process.env.COGNITO_API_KEY_V2
                }
            );
            if (unitDetail?.result) {
                let unit = JSON.parse(unitDetail?.result)
                if (unit?.markerDetail?.linkedDevice?.hasVideo) {
                    hasVideo = true
                }
            }
        }

        /********************************************* 
         * Return types of error in eta
         * *******************************************
            OK: No error
            NO_DATA: No find information
            LOAD_FAILED: failed to send petition
        **********************************************/

        if (markerDetail && markerDetail.etaLinkData) {
            if (markerDetail?.etaLinkData?.unit?.lastReading)
                yield put(mapsEtaLinkPublicGetResponse({ ...markerDetail.etaLinkData, hasVideo }, "OK"));
        } else {
            yield put(mapsEtaLinkPublicGetResponse(null, "NO_DATA"));
            //throw 'Error while getting public eta link';
        }
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, 'Core/views/eta/redux', 'searchPublicEtaLink');
        yield put(mapsEtaLinkPublicGetResponse(null, "LOAD_FAILED"));
    }
}


function* watchMaspEtaLinkRequests() {
    yield takeEvery(MAPS_ETA_LINK_GET, searchPublicEtaLink);
}

export default function* rootSaga() {
    yield all([
        fork(watchMaspEtaLinkRequests)
    ]);
}