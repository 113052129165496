export const REALTIME_MAP_VEHICLETRAILS_GET = "REALTIME_MAP_VEHICLETRAILS_GET";
export const REALTIME_MAP_VEHICLETRAILS_GET_CONFIRM = "REALTIME_MAP_VEHICLETRAILS_GET_CONFIRM";
export const REALTIME_MAP_VEHICLETRAILS_GET_CLEAR = "REALTIME_MAP_VEHICLETRAILS_GET_CLEAR";
export const REALTIME_MAP_VEHICLETRAILS_SHOW_TABLE = "REALTIME_MAP_VEHICLETRAILS_SHOW_TABLE";
export const REALTIME_MAP_VEHICLETRAILS_SHOW_SETTINGS = "REALTIME_MAP_VEHICLETRAILS_SHOW_SETTINGS";
export const REALTIME_MAP_VEHICLETRAILS_PLAYBACK_SET = "REALTIME_MAP_VEHICLETRAILS_PLAYBACK_SET";
export const REALTIME_MAP_VEHICLETRAILS_VEHICLE_PRINT = "REALTIME_MAP_VEHICLETRAILS_VEHICLE_PRINT";
export const REALTIME_MAP_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET = "REALTIME_MAP_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET";
export const REALTIME_MAP_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET_CONFIRM = "REALTIME_MAP_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET_CONFIRM";
export const REALTIME_MAP_VEHICLETRAILS_SEARCH_UNITS_GET = "REALTIME_MAP_VEHICLETRAILS_SEARCH_UNITS_GET";
export const REALTIME_MAP_VEHICLETRAILS_SEARCH_UNITS_GET_RESULT = "REALTIME_MAP_VEHICLETRAILS_SEARCH_UNITS_GET_RESULT";
export const GET_LAST_VEHICLETRAILS = "GET_LAST_VEHICLETRAILS";
export const GET_LAST_VEHICLETRAILS_RESPONSE = "GET_LAST_VEHICLETRAILS_RESPONSE";
export const REALTIME_MAP_VEHICLETRAILS_SELECT_ITEM_LIST = "REALTIME_MAP_VEHICLETRAILS_SELECT_ITEM_LIST";