import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';
// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

import {
    baseReportConditions,
    generateReportInstance,
    getTimeZone,
    handleAsyncReport,
    isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

export function getEventName(event) {
    if (event.includes('Input On')){
        return ['Input 1 On','Input 2 On','Input 3 On','Input 4 On','Input 5 On',
            'Input 6 On','Input 7 On']
    } else if (event.includes('Input Off')){
        return ['Input 1 Off','Input 2 Off','Input 3 Off','Input 4 Off','Input 5 Off',
            'Input 6 Off','Input 7 Off']
    } else {
        return [event];
    }
}
function* getEventReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
    const {timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath} = report;
    const {
        filterType,
        filterArray,
        startRange,
        endRange,
        unitsSelected,
        filterEntity,
        otherFilters,
        accumulateData = true,
        dates
    } = report.filters

    const baseConditionsQuery = baseReportConditions({
        report,
        name,
        dataResultPath,
        timeZone,
        delayed,
        unitsSelected,
        timeframeName,
        filterType,
        filterArray,
        categoryName,
        startRange,
        endRange,
        dates
    })

    const getFilter = getEventName(otherFilters?.event);
    try {
        let payload = {
            conditions: {
                ...baseConditionsQuery,
                stringValues: {
                    values: getFilter
                },
                sortField: {
                    field: sortField || "deviceId",
                    order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                }
            },
            limit: rowsPerPage,
            offset: page
        };
        const addColumnDriver = (
            report?.exportType === 'csv' ?
                [{
                    column: filterEntity === 'Unit' ? "deviceLabel" : "driverName",
                    header: filterEntity === 'Unit' ? "Unit" : "Driver"
                }, {
                    "column": filterEntity === 'Unit' ? "driverName" : "deviceLabel",
                    "header": filterEntity === 'Unit' ? "Driver" : "Unit"
                }]
                : [{
                    "column": filterEntity === 'Unit' ? "driverName" : "deviceLabel",
                    "header": filterEntity === 'Unit' ? "Driver" : "Unit"
                }])

            payload = {
                ...payload,
                limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
                offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
                conditions: {
                    ...payload.conditions,
                    exportOptions: {
                        sendByEmail,
                        emailsTo,
                        format: {
                            fileFormat: report?.exportType || "json",
                            formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
                            durationFormat: report?.durationFormat
                        },
                        culture: report.lang,
                        timeZone: getTimeZone(timeZone),
                        folderName: "px-customer-reports",
                        filePrefixName: "eventReport",
                        columns: [
                            ...addColumnDriver,
                            {
                                "column": "unitTime",
                                header: `${report?.exportType === "csv" ? `Date / Time (${getAbbvTimezone(timeZone)})` : `Date / Time`}`
                            },
                            {
                                "column": "address",
                                "header": "Address"
                            },
                            {
                                "column": "eventName",
                                   "header": "Event"
                            }
                        ]
                    }
                }
            }
        let data = yield call(
            clientQuery,
            `query getEventReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
                Report: getEventReport (conditions: $conditions, limit: $limit, offset: $offset) {
                    ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
                        `reportInstanceId`
                        : `
                    items {
                        recordInfo {
                            entityId
                            entityLabel
                            tagId
                            tagLabel
                            startDate
                            endDate
                            recordType
                            groupLabel
                            groupIndex
                            groupTotal
                            groupTagColor
                        }
                        recordDetail {
                            id
                            deviceId
                            deviceLabel
                            driverId
                            driverName
                            unitTime
                            address
                            landmarkId
                            landmarkName
                            latitude
                            longitude
                            eventName
                            noData
                        }
                    }
                    total
                    limit
                    offset
                    category
                    reportInstanceId
                `}
                }
              }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
        yield handleAsyncReport(report, data, accumulateData);
    } catch (exc) {
        ExceptionManager(exc, 'reports/eventReport', 'getEventReport');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}

export default getEventReport;
