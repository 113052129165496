export default {
    fsmRoute_edit_panel_route_savedRoute: "Information stored correctly",
    fsmRoute_edit_panel_route_remove_point: "Remove the incorrect point in order to continue",
    fsmRoute_edit_panel_route_remove_point: "Template",
    fsmRoute_edit_panel_route_driver: "Driver",
    fsmRoute_edit_panel_route_schedule_at: "Scheduled at",
    fsmRoute_edit_panel_route_is_template: "Is Template",
    fsmRoute_edit_panel_route_assigned_route: "Assigned Routes",
    fsmRoute_edit_panel_route_assigned_actions: "Actions",
    fsmRoute_edit_panel_route_assigned_add_stop: "Add Stop",
    fsmRoute_edit_panel_route_close_confirmation_modal_title: "Warning!",
    fsmRoute_edit_panel_route_close_confirmation_modal: "Are you sure you want to close the route editor? Any unsaved changes will be lost",
};