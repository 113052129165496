import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { HasPermission } from 'Core/security';
import CryptoJS from 'crypto-js';
// Controls
import { Typography, Box } from '@mui/material';
// Components
import { Form, Select, ToggleSwitch, Input, LinearProgress } from 'Components';
import { NotificationManager } from 'Components/Notifications';
//Actions
import {
	catalogLoad,
	saveAccountSettings,
	getPreference,
	clearRedux
} from "Redux/actions";
// Style
import './styles.scss';

const urlLinks = {
	"maps": "/app/realtimemaps/main",
	"vehiclestatus": "/app/fleet/main",
	"main": "/app/realtimemaps/main",
	"reports": "/app/reports/main",
	"dashboard": "/app/dashboard/main",
	"/app/fleet/main": "/app/fleet/main",
	"/app/realtimemaps/main": "/app/realtimemaps/main",
	"/app/reports/main": "/app/reports/main",
	"/app/dashboard/main": "/app/dashboard/main"
};



const AccountInformation = ({ setShowModal }) => {
	//Props
	const { messages } = useIntl();

	const vehicleStatusTab = {
		id: "vehiclestatus",
		name: messages['accountInformation_vehicleStatus']
	};

	const mapsTab = {
		id: "maps",
		name: messages['accountInformation_maps']
	}

	const reportsTab = {
		id: "reports",
		name: messages['accountInformation_reports']
	}

	const dashboardTab = {
		id: "dashboard",
		name: messages['accountInformation_dashboard']
	}
	// Hooks
	const dispatch = useDispatch();

	//const
	const DEFAULT_TABS = [
		vehicleStatusTab,
		mapsTab,
		reportsTab
	];

	const UNIT_OF_MEASURE = [
		{
			id: "mph",
			name: messages['accountInformation_mph']
		},
		{
			id: "kph",
			name: messages['accountInformation_kph']
		}
	];

	const DATE_FORMAT = [
		{
			id: "m/d/Y",
			name: "MM/DD/YYYY"
		},
		{
			id: "d/m/Y",
			name: "DD/MM/YYYY"
		},
		{
			id: "Y/m/d",
			name: "YYYY/MM/DD"
		},
	];

	const DURATION_FORMAT = [
		{
			id: "hh",
			name: `${messages["accountInformation_durationFormatHH"]} (26.28)`
		},
		{
			id: "DDhhmmss",
			name: `${messages["accountInformation_durationFormatDaysHoursMinSec"]}`
		}
	];

	// State manager
	const {
		loading,
		updatedAccount,
		preferences
	} = useSelector(
		(state) => state.accountInformationRedux
	);
	const {
		timeZones,
		loadingTimeZones,
	} = useSelector(
		(state) => state.catalogsRedux
	);

	const { user: { timeZone, dateformat, activeTab, isKph, isSubUser, durationFormat: durationFormatRedux }, user } = useSelector((state) => state.securityRedux);

	//Local state
	const [formData, setFormData] = useState({});
	const [listDefaultTabs, setListDefaultTabs] = useState([]);
	const [accountEld, setAccountEld] = useState(false);
	const [enableChatDriver, setEnableChatDriver] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	//Ref
	const formRef = useRef();

	const initializeData = () => {
		if (!isSubUser || HasPermission(user, 'db', [63])) {
			setListDefaultTabs([
				...DEFAULT_TABS,
				dashboardTab
			])
		}

		const parseActiveTav = getActiveTab(activeTab) || activeTab;

		const timezone = filterTimeZone(timeZone);
		const unitOfMeasure = isKph ?
			UNIT_OF_MEASURE.find((unit) => unit.id === 'kph') : UNIT_OF_MEASURE.find((unit) => unit.id === 'mph')
		const dateFormat = DATE_FORMAT.find((date) => date.id === dateformat);
		const durationFormat = (DURATION_FORMAT.find((date) => date.id === durationFormatRedux) || DURATION_FORMAT[1]);
		const defaultTab = listDefaultTabs.find((tab) => tab.id == parseActiveTav)?.id || urlLinksToId[activeTab];

		return {
			timezone,
			unitOfMeasure,
			dateFormat,
			defaultTab,
			durationFormat
		}
	}

	const getActiveTab = (tabUrl) => {
		const tabUrlToId = {
			"/app/fleet/main": "vehiclestatus",
			"/app/realtimemaps/main": "maps",
			"/app/reports/main": "reports",
			"/app/dashboard/main": "dashboard"
		}

		return tabUrlToId[tabUrl]
	}

	const filterTimeZone = (timezoneSelected) => timeZones.find((tz) => tz.name === timezoneSelected);

	const onSubmit = () => {
		let data = {
			timezone: formData.timezone.id,
			isKph: formData.unitOfMeasure.id === "kph",
			dateFormat: formData.dateFormat.id,
			defaultTab: urlLinks[formData?.defaultTab] || urlLinks[formData?.defaultTab?.id],
			eldUser: null,
			durationFormat: formData.durationFormat.id,
			enableChatDriver: null
		};

		let currentEldPassword = user?.eldUser?.password ? CryptoJS.AES.decrypt(user?.eldUser?.password, 'gpstrackit') : '';
		let originalPassword = currentEldPassword?.toString(CryptoJS.enc.Utf8) || user?.eldUser?.password || '';

		if (HasPermission(user, 'apolloeld')) {
			if (!isSubUser) {
				if (accountEld && (password || user?.eldUser?.password)) {
					data.eldUser = {
						username,
						password: CryptoJS.AES.encrypt(password || originalPassword, 'gpstrackit').toString(),
					}
				} else {
					data.eldUser = {
						username: '',
						password: ''
					}
				}
			}
		}

		if (HasPermission(user, 'bdrvcht')) {
			data.enableChatDriver = enableChatDriver
		}
		dispatch(saveAccountSettings(data));
	}

	useEffect(() => {
		if (user?.eldUser?.username) {
			setUsername(user?.eldUser?.username);
			setAccountEld(true);
		}
		if (HasPermission(user, 'bdrvcht')) {
			dispatch(getPreference({
				projectKey: "PX",
				entityName: "Company",
				entityId: user.companyId,
				preferenceKey: "chatSettings"
			}));
		}
		dispatch(catalogLoad(["timeZones"]));
	}, []);


	useEffect(() => {
		if (timeZones.length > 0) {
			setFormData(initializeData());
		}
	}, [timeZones]);

	useEffect(() => {
		if (preferences?.chatSettings) {
			setEnableChatDriver(preferences?.chatSettings?.enabledChatBetweenDrivers || false)
		}

	}, [preferences?.chatSettings])
	useEffect(() => {
		if (updatedAccount) {
			NotificationManager.success(
				messages['accountInformation_success'],
				messages['success'],
				3000,
				null,
				null,
				'Success',
			);
			dispatch(clearRedux("ACCOUNT_SETTINGS", { updatedAccount: false, updateCredentials: null }));
		}
	}, [updatedAccount])

	useEffect(() => {
		return () => {
			dispatch(clearRedux("ACCOUNT_SETTINGS"));
		}
	}, []);

	const urlLinksToId = {
		"/app/realtimemaps/main": mapsTab,
		"/app/fleet/main": vehicleStatusTab,
		"/app/reports/main": reportsTab,
		"/app/dashboard/main": dashboardTab,
		"maps": mapsTab,
		"vehiclestatus": vehicleStatusTab,
		"main": mapsTab,
		"reports": reportsTab,
		"dashboard": dashboardTab
	}
	return (
		<Fragment>
			{loading && <LinearProgress color="primary" />}
			<Form
				ref={formRef}
				onValidSubmit={() => onSubmit()}
			>
				<Box mb={1}>
					<Box mb={3}>
						<Typography variant="subtitle1">
							{messages['accountInformation_preferences']}
						</Typography>
					</Box>
					<Select
						id="type"
						label={messages['accountInformation_defaultTab']}
						placeholder={messages['accountInformation_select']}
						valueField="id"
						textField="name"
						options={listDefaultTabs}
						value={urlLinksToId[formData?.defaultTab] ?? urlLinksToId[activeTab]}
						onChange={(data) =>
							setFormData({
								...formData,
								defaultTab: data?.id
							}
							)}
						required
						requiredMessage={messages['required_field']}
					/>
				</Box>
				<Box mb={1}>
					<Select
						id="type"
						label={messages['accountInformation_unitOfMeasure']}
						placeholder={messages['accountInformation_select']}
						valueField="id"
						textField="name"
						options={UNIT_OF_MEASURE}
						value={formData?.unitOfMeasure}
						onChange={(data) =>
							setFormData({
								...formData,
								unitOfMeasure: data
							})
						}
						isClearable
						required
						requiredMessage={messages['required_field']}
					/>
				</Box>
				<Box mb={1}>
					<Select
						id="type"
						label={messages['accountInformation_timezone']}
						placeholder={messages['accountInformation_select']}
						valueField="id"
						textField="name"
						options={timeZones}
						value={formData?.timezone}
						onChange={(data) => {
							setFormData({
								...formData,
								timezone: data
							})
						}}
						loading={loadingTimeZones}
						isClearable
						required
						requiredMessage={messages['required_field']}
					/>
				</Box>
				<Box mb={1}>
					<Select
						id="type"
						label={messages['accountInformation_dateFormat']}
						placeholder={messages['accountInformation_select']}
						valueField="id"
						textField="name"
						options={DATE_FORMAT}
						value={formData?.dateFormat}
						onChange={(data) => {
							setFormData({
								...formData,
								dateFormat: data
							})
						}}
						isClearable
						required
						requiredMessage={messages['required_field']}
					/>
				</Box>
				<Box mb={1}>
					<Select
						id="type"
						label={messages['accountInformation_durationFormat']}
						placeholder={messages['accountInformation_select']}
						valueField="id"
						textField="name"
						options={DURATION_FORMAT}
						value={formData?.durationFormat}
						onChange={(data) => {
							setFormData({
								...formData,
								durationFormat: data
							})
						}}
						isClearable
						required
						requiredMessage={messages['required_field']}
					/>
				</Box>

				{
					!isSubUser && HasPermission(user, 'apolloeld') &&
					<>
						<Box mb={1} style={{ marginTop: 16 }} className="account-eld-toogle">
							<ToggleSwitch
								label={messages['accountInformation_apollo_eld_account']}
								id="eld-account-toogle"
								checked={accountEld}
								optionLabels={["", ""]}
								onChange={(value) => setAccountEld(value)}
								small
							/>
						</Box>

						{
							accountEld &&
							<>
								<Box mb={1}>
									<Input
										id="eld-username"
										type="text"
										label={messages['accountInformation_username']}
										placeholder={messages['accountInformation_username']}
										value={username}
										onChange={(value) => setUsername(value)}
										minLength={2}
										minLengthMessage={`${messages['accountInformation_minMessageUsername']} 2`}
										maxLength={200}
										maxLengthMessage={`${messages['accountInformation_maxMessageUsername']} 200`}
										required={true}
										requiredMessage={messages['required_field']}
									/>
								</Box>
								<Box mb={1}>
									<Input
										id="eld-password"
										type="password"
										label={user?.eldUser?.password ? messages['accountInformation_change_password'] : messages['accountInformation_password']}
										placeholder={user?.eldUser?.password ? messages['accountInformation_change_password'] : messages['accountInformation_password']}
										value={password}
										autoComplete="new-password"
										onChange={(value) => setPassword(value)}
										minLength={6}
										minLengthMessage={`${messages['accountInformation_minMessagePassword']} 6`}
										maxLength={20}
										maxLengthMessage={`${messages['accountInformation_maxMessagePassword']} 20`}
										required={user?.eldUser?.password ? password : !user?.eldUser?.username}
										requiredMessage={messages['required_field']}
									/>
								</Box>
								<Box mb={1}>
									<Input
										id="eld-confirm-password"
										type="password"
										label={messages['accountInformation_confirm_password']}
										placeholder={messages['accountInformation_confirm_password']}
										value={confirmPassword}
										autoComplete="new-password"
										valueToCompare={password}
										valueToCompareMessage={messages['subUser_valueToCompareMessage']}
										onChange={(value) => setConfirmPassword(value)}
										minLength={6}
										minLengthMessage={`${messages['accountInformation_minMessagePassword']} 6`}
										maxLength={20}
										maxLengthMessage={`${messages['accountInformation_maxMessagePassword']} 20`}
										required={user?.eldUser?.password ? password : !user?.eldUser?.username}
										requiredMessage={messages['required_field']}
									/>
								</Box>
							</>
						}
					</>
				}
				{
					HasPermission(user, 'bdrvcht') && (
						<Box mb={1} style={{ marginTop: 16 }} className="account-eld-toogle">
							<ToggleSwitch
								label={messages["accountInformation_enableChatBetweenDrivers"]}
								id="enable-chat-between-drivers"
								checked={enableChatDriver}
								optionLabels={["", ""]}
								onChange={(value) => setEnableChatDriver(value)}
								small
							/>
						</Box>
					)
				}
			</Form>
			<Box mt={5}>
				<div className={`button-save ${loading && 'disabled'}`} onClick={() => {
					if (password !== confirmPassword) return
					formRef.current.submit()
				}}>
					{messages["save"]}
				</div>
			</Box>
		</Fragment>
	)
}

export default AccountInformation;
