// Action types.
import {
  MANAGE_CAMERAS_LIST,
  MANAGE_CAMERAS_LIST_RESPONSE,
  MANAGE_CAMERAS_PAIR_DEVICE,
  MANAGE_CAMERAS_PAIR_DEVICE_RESPONSE,
  MANAGE_CAMERAS_UNPAIR_DEVICE,
  MANAGE_CAMERAS_UNPAIR_DEVICE_RESPONSE,
  MANAGE_CAMERAS_OPEN_MODAL,
} from 'Redux/actionTypes';

export const searchCamerasList = (data) => {
  return {
    type: MANAGE_CAMERAS_LIST,
    payload: data,
  };
};

export const searchCamerasListResponse = (data) => {
  return {
    type: MANAGE_CAMERAS_LIST_RESPONSE,
    payload: data,
  };
};

export const pairDevice = (data) => {
  return {
    type: MANAGE_CAMERAS_PAIR_DEVICE,
    payload: data,
  };
};

export const pairDeviceResponse = (data) => {
  return {
    type: MANAGE_CAMERAS_PAIR_DEVICE_RESPONSE,
    payload: data,
  };
};

export const unpairDevice = (data) => {
  return {
    type: MANAGE_CAMERAS_UNPAIR_DEVICE,
    payload: data,
  };
};

export const unpairDeviceResponse = (data) => {
  return {
    type: MANAGE_CAMERAS_UNPAIR_DEVICE_RESPONSE,
    payload: data,
  };
};

export const manageCamerasOpenModal = (open, camera) => {
  return {
    type: MANAGE_CAMERAS_OPEN_MODAL,
    payload: {
      open,
      camera
    },
  };
};



