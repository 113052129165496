import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { NotificationManager } from 'Components/Notifications';

//Action types
import {
  MAP_LANDMARK_GROUP_DELETE,
  MAP_LANDMARK_GROUP_EDIT,
  MAP_LANDMARK_GROUP_NEW,
  MAP_LANDMARK_NEW,
  MAP_LANDMARK_BULK_EDIT,
  MAP_LANDMARK_BULK_DELETE,
  MAP_LANDMARK_GET_LANDMARK_GROUPS,
  MAP_LANDMARK_GET_LANDMARKS
} from 'Redux/actionTypes';

//actions
import {
  deleteLandmarkGroupsResult,
  updateLandmarkGroupResult,
  createLandmarkGroupResult,
  createLandmarkMapResult,
  editLandmarkBulkResult,
  deleteLandmarkBulkResult,
  mapLandmarkGetLandmarkGroupsResponse,
  mapLandmarkGetLandmarkGroups,
  getKmls,
  searchLandmarksMapResult,
  mapLandmarkGroupPreferencesResponse,
  mapLandmarkGroupPreferences
} from 'Redux/actions';

function* getLandmarkGroupsRequest() {
  try {
    const query = `
        query searchTagsInline ($entityName: String, $limit: Int, $offset: Int) {
          result: searchTagsInline (entityName: $entityName, limit: $limit, offset: $offset) {
                items {
                    id
                    label
                    color
                }
                total
            }
        }`;

    const params = {
      entityName: "Landmark"
    }

    let data = yield call(
      clientQuery,
      query,
      params,
      endpoints.GRAPHQL_GENERAL,
    );

    let landmarkGroups = data?.result;

    if (landmarkGroups) {

      if(landmarkGroups?.items && landmarkGroups?.items?.length > 0 ){
        let landmarkTags = landmarkGroups.items.map((tag) => {
            return {
                ...tag,
                name: tag.label
            }
        })
        yield put(mapLandmarkGetLandmarkGroupsResponse(landmarkTags));
      }else{
        yield put(mapLandmarkGetLandmarkGroupsResponse([]));
      }

      
    } else {
      throw 'Error while getting landmark groups';
    }
  } catch (exc) {
    yield put(mapLandmarkGetLandmarkGroupsResponse([]));
    ExceptionManager(exc, 'modules/realtimeMaps/main/components/LandmarksListMain/redux/saga', 'getLandmarkGroupsRequest');
  }
};

/**
 * Get the paginated landmarks according the given params.
 * @param { {payload: {offset, limit}}} params - The paginations params.
 */
function* searchLandmarksMapRequest({ payload }) {
    const { offset, limit, openSearch, groupId } = payload;
    try {
        const query =
        `query ($openSearch: String, $limit: Int, $offset: Int) {
            searchLandmarks (openSearch: $openSearch, limit: $limit, offset: $offset) {
                items {
                    id
                    companyId
                    name
                    tags {
                      id
                      label
                      color
                      path
                    }
                    latitude
                    longitude
                    shape
                    radius
                    address
                    city
                    zip
                    state
                    country
                    note
                    coordinates {
                        latitude
                        longitude
                    }
                    createdBy
                    createdOn
                    updatedBy
                    updatedOn
                }
                total
            }
        }`;
    
        let params = {
        offset,
        limit,
        openSearch,
        groupId
        };
    
        if(!groupId){
        delete params["groupId"]
        }
    
        if(!openSearch){
        delete params["openSearch"]
        }
    
        const data = yield call(clientQuery, query, params, endpoints.GRAPHQL_GENERAL);
        const { searchLandmarks: { items, total } } = data;
        if (items) {
        yield put(searchLandmarksMapResult(items, total));
        //total is all landmarks elements
        } else {
        throw 'Error getting paginated landmarks';
        }
    
    } catch (exc) {
        ExceptionManager(exc, 'modules/realtimeMaps/main/components/LandmarksListMain/redux/saga', 'searchLandmarksMapRequest')
    }
};

//Function
function* deleteLandmarkGroupsRequest({ payload }) {
  try {
    const { ids, newId } = payload;
    let mutations = [];
    ids.forEach((id, index) => {
      mutations.push(
        `Group${index}: deleteLandmarkGroup(
          id: ${id}
          ${newId ? `moveChildsToGroupId: ${newId}` : ''}
        )`
      );
    });

    let mutation = `mutation { ${mutations.join(" ")} }`;

    let response = yield call(
      clientMutation,
      mutation,
      {},
      endpoints.GRAPHQL_GENERAL,
    );

    if (response) {
      yield put(deleteLandmarkGroupsResult(response));
      yield put(getKmls());
    } else {
      throw "Error while deleting landmark groups";
    }
  } catch (exc) {
    ExceptionManager(exc, 'modules/realtimeMaps/main/components/LandmarksListMain/redux/saga', 'deleteLandmarkGroupsRequest');
    yield put(deleteLandmarkGroupsResult({ error: true }));
  }
}

//Function
function* updateLandmarkGroupRequest({ payload }) {
  try {
    let data = yield call(
      clientMutation,
      `mutation updateLandmarkGroup ($id: Int!, $name: String, $color: String) {
        updateLandmarkGroup (id: $id, name: $name, color: $color) {
            id
            name
            color
        }
      }`,
      {
        ...payload
      },
      endpoints.GRAPHQL_GENERAL,
    );

    if (data && data.updateLandmarkGroup) {
      yield put(updateLandmarkGroupResult(data.updateLandmarkGroup));
      yield put(mapLandmarkGetLandmarkGroups());
      //yield put(getKmls())
    } else {
      throw "Error clearing Landmark Group";
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/realtimeMaps/main/components/LandmarksListMain/redux/saga', 'deleteLandmarkGroup');
    yield put(updateLandmarkGroupResult(false, exc.errorData));
    NotificationManager.error('Error', `Error Delete Landmark Group`, 3000, null, null, 'error');
  }
}

//Function
function* createLandmarkGroupRequest({ payload }) {
  try {
    let data = yield call(
      clientMutation,
      `mutation createLandmarkGroup ($name: String!, $color: String!) {
        createLandmarkGroup (name: $name, color: $color) {
            id
            name
            color
        }
      }`,
      {
        ...payload
      },
      endpoints.GRAPHQL_GENERAL,
    );

    if (data && data.createLandmarkGroup) {
      yield put(createLandmarkGroupResult(data.createLandmarkGroup));
      yield put(mapLandmarkGetLandmarkGroups());
    } else {
      throw "Error while creating landmark group";
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/realtimeMaps/main/components/LandmarksListMain/redux/saga', 'deleteLandmarkGroup');
    yield put(createLandmarkGroupResult(false, exc.errorData));
    NotificationManager.error('Error', `Error Delete Landmark Group`, 3000, null, null, 'error');
  }
}

//Function
function* createLandmarkMapRequest({ payload, updateKml }) {
  try {
    let data = yield call(
      clientMutation,
      `mutation createLandmark ($name: String!, $tagsIds: [Int]!, $latitude: Float!, $longitude: Float!, $shape: shapeType!, $radius: Float!, $address: String!, $city: String!, $zip: String!, $state: String!, $country: String!, $note: String, $coordinates: [CoordinateInput]) {
            createLandmark (name: $name, tagsIds: $tagsIds, latitude: $latitude, longitude: $longitude, shape: $shape, radius: $radius, address: $address, city: $city, zip: $zip, state: $state, country: $country, note: $note, coordinates: $coordinates) {
                id
                companyId
                name
                tags {
                  id
                  label
                  color
                  path
                }
                latitude
                longitude
                shape
                radius
                address
                city
                zip
                state
                country
                note
                coordinates {
                    latitude
                    longitude
                }
                createdBy
                createdOn
                updatedBy
                updatedOn
            }
        }
        `,
      {
        ...payload
      },
      endpoints.GRAPHQL_GENERAL,
    );
    if (data && data.createLandmark) {
      yield put(createLandmarkMapResult(data.createLandmark));
      if (updateKml) {
        yield put(getKmls())
      }
    } else {
      throw "Error create Landmark";
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/realtimeMaps/main/components/LandmarkListMain/redux/saga', 'createLandmarkMapRequest');
    yield put(createLandmarkMapResult(false, exc.errorData));
    NotificationManager.error('Error', `Error Create Landmark`, 3000, null, null, 'error');
  }
}

function* editLandmarkBulkRequest({ payload }) {
  try {
    let mutations = [];
    const { landmarksToUpdate } = payload;

    landmarksToUpdate.forEach((item, index) => {
      mutations.push(`
      updateLandmark${index}: updateLandmark(
          id: ${item.id},
          groupId: ${item.groupId}
        ){
          id
          groupId
        }`);
    })

    let mutation = `mutation { ${mutations.join(" ")} }`;

    let data = yield call(
      clientMutation,
      mutation,
      {
        id: 0,
        groupId: 0
      },
      endpoints.GRAPHQL_GENERAL,
    );
    if (data && data.updateLandmark0) {
      yield put(editLandmarkBulkResult(data.updateLandmark0));
      yield put(getKmls());
    } else {
      throw "Error update bulk Landmark";
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/realtimeMaps/main/components/LandmarksListMain/redux/saga', 'editLandmarkBulkRequest');
    yield put(editLandmarkBulkResult(false, exc.errorData));
    NotificationManager.error('Error', `Error Update Bulk Landmark`, 3000, null, null, 'error');
  }
}

function* deleteLandmarkBulkRequest({ payload }) {
  try {
    let mutations = [];
    const { landmarksToDelete } = payload;

    landmarksToDelete.forEach((id, index) => {
      mutations.push(`
      deleteLandmark${index}: deleteLandmark(
          id: ${id}
        )`);
    })

    let mutation = `mutation { ${mutations.join(" ")} }`;

    let data = yield call(
      clientMutation,
      mutation,
      {},
      endpoints.GRAPHQL_GENERAL,
    );
    if (data && data.deleteLandmark0) {
      yield put(deleteLandmarkBulkResult(data.deleteLandmark0));
      yield put(getKmls())
    } else {
      throw "Error delete bulk Landmark";
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/realtimeMaps/main/components/LandmarksListMain/redux/saga', 'deleteLandmarkBulkRequest');
    yield put(deleteLandmarkBulkResult(false, exc.errorData));
    NotificationManager.error('Error', `Error delete Bulk Landmark`, 3000, null, null, 'error');
  }
}


function* watchDeleteLandmarkGroupTasksRequest() {
  yield takeEvery(MAP_LANDMARK_GET_LANDMARK_GROUPS, getLandmarkGroupsRequest);
  yield takeEvery(MAP_LANDMARK_GROUP_DELETE, deleteLandmarkGroupsRequest);
  yield takeEvery(MAP_LANDMARK_GROUP_EDIT, updateLandmarkGroupRequest);
  yield takeEvery(MAP_LANDMARK_GROUP_NEW, createLandmarkGroupRequest);
  yield takeEvery(MAP_LANDMARK_NEW, createLandmarkMapRequest);
  yield takeEvery(MAP_LANDMARK_BULK_EDIT, editLandmarkBulkRequest);
  yield takeEvery(MAP_LANDMARK_BULK_DELETE, deleteLandmarkBulkRequest);
  yield takeEvery(MAP_LANDMARK_GET_LANDMARKS, searchLandmarksMapRequest);
}

//Saga Export
export default function* rootSaga() {
  yield all([
    fork(watchDeleteLandmarkGroupTasksRequest)
  ]);
}