import React, { useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoginLogo from "../../common/LoginLogo";
import SocialMediaError from "./SocialMediaError";
import { useIntl, FormattedMessage } from "react-intl";
import { Form, Input, Loading, Button } from "Components";
import ForgotPwdLink from "../../common/ForgotPwdLink";
import ChatWithSupport from "../../common/ChatWithSupport";
import SocialMediaButtons from "./SocialMediaButtons";
import {
  login,
  loginConfimrClose,
  checkMigrate,
  clearRedux,
} from "Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import ModalConfigMediaUser from "./ModalConfigMediaUser";
import ModalMigrateUser from "./ModalMigrateUser";


function FormLogin({ current_reseller, reseller_was_validated, callback }) {
  const { messages } = useIntl();
  const frmLogin = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");

  const {
    loading,
    loadingSM,
    error,
    showPassword,
    loginPage,
    cognitoValues,
    result,
    showConfirm,
  } = useSelector((state) => state.loginRedux);

  const urlImgLogo = current_reseller
    ? current_reseller?.loginCompanyLogo
    : "/assets/images/verticalLogo.png";

  const handleClose = () => {
    dispatch(loginConfimrClose());
    setConfirmationCode("");
  };

  return (
    <>
      <Box
        style={{
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          width: "100%",
        }}
      >
        <div style={{ width: "500px" }} className="background-white-login">
          <Box className="form-login-container">
            {reseller_was_validated && <LoginLogo urlImgLogo={urlImgLogo} />}
            {error && <SocialMediaError error={error} showPassword={showPassword} />}
            {loadingSM ? (
              <div className="loading-area" style={{ minHeight: 200 }}>
                <Loading />
              </div>
            ) : (
              <Form
                ref={frmLogin}
                onValidSubmit={() => {
                  setUsername(username.trim());
                  if (showPassword) {
                    callback(username.trim(), password)
                    dispatch(login(username.trim(), password, navigate));
                  } else {
                    dispatch(checkMigrate(username.trim(), navigate));
                  }
                }}
              >
                <div>

                  <p
                    style={{
                      fontSize: "16px",
                      margin: "30px 0 0 0",
                      fontWeight: "400",
                    }}
                  >
                    {messages["login_email_or_username"]}
                    <span className="text-danger"> *</span>
                  </p>
                  <Input
                    id="login"
                    name="login"
                    type="text"
                    autoComplete="on"
                    placeholder={messages["login_email_or_username"]}
                    value={username}
                    onChange={(text) => {
                      setUsername(text);
                    }}
                    required
                    requiredMessage={messages["required_field"]}
                    autoFocus
                    //notRequiderdShow
                    disabled={showPassword}
                  />
                </div>
                <div>
                  {showPassword && (
                    <Input
                      id="txtPassword"
                      type="password"
                      label={""}
                      placeholder={messages["login_password"]}
                      value={password}
                      onChange={(text) => {
                        setPassword(text);
                      }}
                      required
                      autoFocus
                      requiredMessage={messages["required_field"]}
                      notRequiderdShow
                    />
                  )}
                </div>
                <div style={{ paddingTop: 10 }}>
                  {loading ? (
                    <div className="loading-area">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      <Grid container spacing={showPassword ? 1 : 0}>
                        {showPassword && (
                          <Grid item sm={6}>
                            <Button
                              tabIndex={2}
                              id="btnCancelLogin"
                              onClick={() => {
                                dispatch(
                                  clearRedux("LOGIN", {
                                    cognitoValues: null,
                                    error: null,
                                    showConfirm: false,
                                    token: null,
                                    showPassword: false,
                                    loginPage: loginPage,
                                  })
                                );
                              }}
                              className="btn btn-gray btn-block margin-top-10"
                            >
                              <FormattedMessage id="login_back" />
                            </Button>
                          </Grid>
                        )}

                        <Grid
                          style={{ width: "100%" }}
                          item
                          sm={showPassword ? 6 : 12}
                        >
                          <Button
                            id="btnSignin"
                            className="btn btn-blue btn-block margin-top-10"
                            tabIndex={1}
                            disabled={loading}
                            onClick={(e) => {
                              e.preventDefault();
                              frmLogin.current.submit();
                            }}
                          >
                            <FormattedMessage
                              id={
                                showPassword ? `login_next` : `login_continue`
                              }
                            />
                          </Button>
                        </Grid>
                      </Grid>
                      <Box style={{ margin: "25px 0 0 0" }}>
                        <ForgotPwdLink current_reseller={current_reseller} />
                      </Box>
                      {!current_reseller && reseller_was_validated && (
                        <>
                          <Box style={{ margin: "5px 0 0 0" }}>
                            <ChatWithSupport />
                          </Box>
                          <SocialMediaButtons />
                        </>
                      )}
                    </>
                  )}
                </div>
              </Form>
            )}
          </Box>
        </div>
      </Box>
      <ModalConfigMediaUser />
      <ModalMigrateUser
        handleClose={handleClose}
        error={error}
        username={username}
        confirmationCode={confirmationCode}
        setConfirmationCode={setConfirmationCode}
        loading={loading}
        cognitoValues={cognitoValues}
        result={result}
        showConfirm={showConfirm}
      />
    </>
  );
}

export default FormLogin;
