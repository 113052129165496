import {
    FSM_SETTINGS_FORM_SEARCH,
    FSM_SETTINGS_FORM_SEARCH_RESPONSE,
    FSM_SETTINGS_FORM_CREATE,
    FSM_SETTINGS_FORM_CREATE_RESPONSE,
    FSM_SETTINGS_FORM_UPDATE,
    FSM_SETTINGS_FORM_UPDATE_RESPONSE,
    FSM_SETTINGS_FORM_DELETE,
    FSM_SETTINGS_FORM_DELETE_RESPONSE,
    FSM_SETTINGS_FORM_OPEN_CREATE_EDIT,
    FSM_SETTINGS_FORM_OPEN_DELETE
} from "Redux/actionTypes";



export const searchFormSettings = (data) => {
    return {
        type: FSM_SETTINGS_FORM_SEARCH,
        payload: { data },
    };
};

export const searchFormSettingsResponse = (data) => {
    return {
        type: FSM_SETTINGS_FORM_SEARCH_RESPONSE,
        payload: data,
    };
};

export const createFormSettings = (form) => {
    return {
        type: FSM_SETTINGS_FORM_CREATE,
        payload: form,
    };
};

export const createFormSettingsResponse = (data) => {
    return {
        type: FSM_SETTINGS_FORM_CREATE_RESPONSE,
        payload: data,
    };
};

export const updateFormSettings = (form) => {
    return {
        type: FSM_SETTINGS_FORM_UPDATE,
        payload: form,
    };
};

export const updateFormSettingsResponse = (data) => {
    return {
        type: FSM_SETTINGS_FORM_UPDATE_RESPONSE,
        payload: data,
    };
};

export const deleteformSettings = (Forms) => {
    return {
        type: FSM_SETTINGS_FORM_DELETE,
        payload: { Forms },
    };
};


export const deleteformSettingsResponse = (data) => {
    return {
        type: FSM_SETTINGS_FORM_DELETE_RESPONSE,
        payload: data,
    };
};


export const openFormCreateEdit = (open, form) => {
    return {
        type: FSM_SETTINGS_FORM_OPEN_CREATE_EDIT,
        payload: { open, form }
    };
};

export const openFormDelete = (open, forms) => {
    return {
        type: FSM_SETTINGS_FORM_OPEN_DELETE,
        payload: { open, forms }
    };
};