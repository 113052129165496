import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import { getMethodToFormatDuration } from "Modules/reports/views/main/components/reportsTab/helper";
import { openHistoryOverViewIncident } from "Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  Tooltip,
} from "@gpstrackit/gtc-react-controls";
import { getUnit } from "../../../../.globals/utils";

export const ItemHistory = ({
  id,
  name,
  unitId,
  start,
  end,
  shortcode,
  note,
  reading,
  dateFormat,
  durationFormat,
  incidentLog,
  closeList,
}) => {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const { units } = useSelector((state) => state.catalogsRedux);

  const unit = getUnit(unitId, units);

  const duration = moment(moment(end)).diff(moment(start), "s");
  const durationFormatTime = getMethodToFormatDuration(durationFormat);
  const durationResult = duration
    ? durationFormatTime(duration, messages)
    : "0 min, 0 sec";

  let formatName = name;
  if (formatName?.length > 25) {
    formatName = <Tooltip title={name}>{name.slice(0, 25) + "..."}</Tooltip>;
  }

  let vehicleFormat = reading?.deviceLabel;
  if (vehicleFormat?.length > 25) {
    vehicleFormat = (
      <Tooltip title={vehicleFormat}>
        {vehicleFormat.slice(0, 25) + "..."}
      </Tooltip>
    );
  }

  let reportedByFormat = incidentLog?.reportedBy;
  if (reportedByFormat?.length > 25) {
    reportedByFormat = (
      <Tooltip title={incidentLog?.reportedBy}>
        {reportedByFormat.slice(0, 15) + "..."}
      </Tooltip>
    );
  }

  let endedByFormat = incidentLog?.endedBy;
  if(endedByFormat === 'system'){
    endedByFormat = messages[endedByFormat]
  }
  if (endedByFormat?.length > 25) {
    endedByFormat = (
      <Tooltip title={incidentLog?.endedBy}>
        {endedByFormat.slice(0, 15) + "..."}
      </Tooltip>
    );
  }

  return (
    <div key={id} className="item_incident">
      <div style={{ display: "flex" }}>
        <div className="item_title_incident">
          <p
            onClick={() => {
              dispatch(
                openHistoryOverViewIncident({
                  data: {
                    incident: {
                      id,
                      unitId,
                      name,
                      start,
                      end,
                      shortcode,
                      note,
                      duration: durationResult,
                      reportedBy: incidentLog?.reportedBy,
                      endedBy: incidentLog?.endedBy,
                    },
                    reading,
                    unit,
                  },
                  open: true,
                })
              );
              closeList && closeList();
            }}
            style={{ margin: 0, fontWeight: "bold", fontSize: "18px" }}
          >
            {formatName}
          </p>
        </div>
      </div>
      <div className="item_date_incident">
        <p>{messages["incident_vehicle"]}</p>
        <p>{vehicleFormat ? vehicleFormat : "..."}</p>
      </div>
      <div className="item_date_incident">
        <p>{messages["incident_stared"]}</p>
        <p>{moment(start).format(dateFormat)}</p>
      </div>
      <div className="item_date_incident">
        <p>{messages["incident_reported_by"]}</p>
        <p>{reportedByFormat ? reportedByFormat : "..."}</p>
      </div>
      <div className="item_date_incident">
        <p>{messages["incident_ended"]}</p>
        <p>{moment(end).format(dateFormat)}</p>
      </div>
      <div className="item_date_incident">
        <p>{messages["incident_duration"]}</p>
        <p>{durationResult}</p>
      </div>
      <div className="item_date_incident">
        <p>{messages["incident_ended_by"]}</p>
        <p>{endedByFormat ? endedByFormat : "..."}</p>
      </div>
    </div>
  );
};
