import RealtimeMapsModalCreateEditRouteLanguage from "../components/modalCreateEditRoute/lan/en";
import RealtimeMapsModalUploadRouteLanguage from "../components/uploadRoute/lan/en";

export default {
  ...RealtimeMapsModalCreateEditRouteLanguage,
  ...RealtimeMapsModalUploadRouteLanguage,
  
  "routes_edit": "Edit",
  "routes_directions": "Get Directions",
  "routes_delete": "Delete",
  "routes_add": "Add Route",
  "routes_upload": "Upload Routes",
  "routes_upload_route": "Upload",
  "routes_createRoute": "Create Route",
  "routes_editRoute": "Edit Route",
  "routes_cancel": "Cancel",
  "routes_save": "Save",
  "routes_name": "Name",
  "routes_start": "Start",
  "routes_start_point": "Start Point",
  "routes_start_point_exist": "The Start point already exists, this option will add a new Start Point and leaving the previous one as the First Stop.",
  "routes_end": "End",
  "routes_stop": "Stop",
  "routes_routeDirections":"Get Route Directions",
  "routes_end_point": "End Point",
  "routes_end_point_exist": "The End Point already exists, this option will add a new point as the End of the Route leaving the previous one as the last stop before the end of the route.",
  "routes_title": "Title",
  "routes_file": "File",
  "routes_optimizeRoute": "Optimize Route",
  "routes_avoidTolls": "Avoid Tolls",
  "routes_stops": "Stops",
  "routes_addStop": "Add Stop",
  "routes_addStop_end": "Cannot add more than 25 stops.",
  "routes_Address": "Address",
  "routes_Unit": "Unit",
  "routes_Landmark": "Landmark",
  "routes_mapClick": "Use Map Click",
  "routes_selectUnit": "Select Unit",
  "routes_selectAddressType": "Select location type",
  "routes_latitude": "Latitude",
  "routes_longitude": "Longitude",
  "routes_removeStop": "Remove",
  "routes_error": "An error occurred, please try again.",
  "routes_error_name": "A route with this name already exists.",
  "routes_next": "Next",
  "routes_deleteRouteTitle": "Delete route: ",
  "routes_delete_routes_title": "Delete routes",
  "routes_deleteRouteContent": "Are you sure you want to delete this route? This action can not be undone",
  "routes_delete_routes_content": "Are you sure you want to delete this routes? This action can not be undone",
  "routes_deleteStopTitle": "Delete stop: ",
  "routes_deleteStopContent": "Are you sure you want to delete this stop? This action can not be undone",
  "routes_deleteRouteSuccess": "Route deleted succesfully",
  "routes_notes": "Notes",
  "routes_createEditRouteSuccess": "Route saved successfully",
  "routes_selectAddress": "Type and select address",
  "routes_attachmentsFilesAllowed": 'csv. 25 Stops Maximum, 4MB',
  "routes_attachments_latitude": 'Latitude',
  "routes_attachments_longitude": 'Longitude',
  "routes_attachments_address": 'Address',
  "routes_attachments_city": 'City',
  "routes_attachments_state": 'State',
  "routes_attachments_zip": 'Zip',
  "routes_attachments_note": 'Notes',
  "routes_attachments_template": 'Download Template',
  "routes_attachments_error": 'The file is not in the correct format',
  "routes_attachments_max": 'The file should have 25 stops max',
  "routes_deleteRoutesSuccess": "Routes deleted succesfully",
  "route_minLengthMessage": "This field must have a least 4 characters",
  "route_maxLengthMessage": "This field must have a maximun of 50 characters",
  "route_room": "room",
  "route_required_field": "is a",
  "route_required_field_click": "Click on map to create point",
  "route_required_field_template": "Template",
  "route_required_field_assgned_route": "Assigned Routes",
  "route_required_field_driver": "Driver",
  "route_required_field_schedule_at": "Scheduled At",

  "route_status_pending": "Pending",
  "route_status_in_route": "In Route",
  "route_status_on_site": "On Site",
  "route_status_done": "Done",
  "route_status_done_with_exception": "Done With Exception",
}