export const SLIDER_VIDEO_VEHICLETRAILS_GET = "SLIDER_VIDEO_VEHICLETRAILS_GET";
export const SLIDER_VIDEO_VEHICLETRAILS_GET_CONFIRM = "SLIDER_VIDEO_VEHICLETRAILS_GET_CONFIRM";
export const SLIDER_VIDEO_VEHICLETRAILS_GET_CLEAR = "SLIDER_VIDEO_VEHICLETRAILS_GET_CLEAR";
export const SLIDER_VIDEO_VEHICLETRAILS_SHOW_TABLE = "SLIDER_VIDEO_VEHICLETRAILS_SHOW_TABLE";
export const SLIDER_VIDEO_VEHICLETRAILS_SHOW_SETTINGS = "SLIDER_VIDEO_VEHICLETRAILS_SHOW_SETTINGS";
export const SLIDER_VIDEO_VEHICLETRAILS_PLAYBACK_SET = "SLIDER_VIDEO_VEHICLETRAILS_PLAYBACK_SET";
export const SLIDER_VIDEO_VEHICLETRAILS_VEHICLE_PRINT = "SLIDER_VIDEO_VEHICLETRAILS_VEHICLE_PRINT";
export const SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET = "SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET";
export const SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET_CONFIRM = "SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET_CONFIRM";
export const SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNITS_GET = "SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNITS_GET";
export const SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNITS_GET_RESULT = "SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNITS_GET_RESULT";