import {
  MAP_LANDMARKS_VISIBLE_LANDMARK_GROUPS_GET_LANDMARKS,
  MAP_LANDMARKS_VISIBLE_LANDMARK_GROUPS_GET_LANDMARKS_RESPONSE,
  CLEAR_REDUX
} from "Redux/actionTypes";

const INIT_STATE = {
  loadingLandmarkGroups: false,
  landmarkGroups: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MAP_LANDMARKS_VISIBLE_LANDMARK_GROUPS_GET_LANDMARKS:
      return { ...state, landmarkGroups: [], loadingLandmarkGroups: true };

    case MAP_LANDMARKS_VISIBLE_LANDMARK_GROUPS_GET_LANDMARKS_RESPONSE:
      return { ...state, landmarkGroups: action.payload.response, loadingLandmarkGroups: false };

    case CLEAR_REDUX:
      return ["", "MAP_LANDMARKS_VISIBLE_LANDMARK_GROUPS"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state };

    default:
      return state;
  }
};