import {
    MAP_SPEED_OVERRIDE_DELETE_OPEN_MODAL,
    MAP_SPEED_OVERRIDE_DELETE_SPEED_OVERRIDE,
    MAP_SPEED_OVERRIDE_DELETE_SPEED_OVERRIDE_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    open: false,
    speedOverrides: [],
    response: null,
    error: false,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MAP_SPEED_OVERRIDE_DELETE_OPEN_MODAL:
            return {
                ...state,
                open: action.payload.open,
                speedOverrides: action.payload.speedOverrides || [],
                response: null,
                loading: false,
                error: false
            };

        case MAP_SPEED_OVERRIDE_DELETE_SPEED_OVERRIDE:
            return {
                ...state,
                response: null,
                error: false,
                loading: true
            };

        case MAP_SPEED_OVERRIDE_DELETE_SPEED_OVERRIDE_RESPONSE:
            return {
                ...state,
                response: action.payload.response,
                error: action.payload.error,
                loading: false
            };

        case CLEAR_REDUX:
            return ["", "MAP_SPEED_OVERRIDE_DELETE"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
            return { ...state };
    }
}