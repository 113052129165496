import {
  MAP_LANDMARKS_VISIBLE_LANDMARK_TAGS_GET_LANDMARKS,
  MAP_LANDMARKS_VISIBLE_LANDMARK_TAGS_GET_LANDMARKS_RESPONSE,
  MAP_LANDMARK_UPDATE_PREFERENCE,
  CLEAR_REDUX
} from "Redux/actionTypes";

const INIT_STATE = {
  loadingLandmarkTags: false,
  landmarkTags: [],
  contPreference: 0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MAP_LANDMARKS_VISIBLE_LANDMARK_TAGS_GET_LANDMARKS:
      return { ...state, landmarkTags: [], loadingLandmarkTags: true };

    case MAP_LANDMARKS_VISIBLE_LANDMARK_TAGS_GET_LANDMARKS_RESPONSE:
      return { ...state, landmarkTags: action.payload.response, loadingLandmarkTags: false };

      case MAP_LANDMARK_UPDATE_PREFERENCE:
        return { ...state, contPreference: state.contPreference + 1 }

    case CLEAR_REDUX:
      return ["", "MAP_LANDMARKS_VISIBLE_LANDMARK_GROUPS"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state };

    default:
      return state;
  }
};