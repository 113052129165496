export default {
  manageCameras_deviceLabel: 'Dispositivo',
  manageCameras_esn: 'ESN',
  manageCameras_linkedDevice: 'Unidad emparejada',
  manageCameras_successfulPairing: 'La unidad ha sido emparejada con éxito',
  manageCameras_errorPairing: 'La unidad no ha sido emparejada',
  manageCameras_successfulUnPairing: 'La unidad ha sido desparejada con éxito',
  manageCameras_errorfulUnPairing: 'La unidad no ha sido desparejada',
  manageCameras_deviceEsnLabel: 'Dispositivo ESN',
  manageCameras_unpair: 'Desemparejar',
  manageCameras_associateCamera: 'Asociar cámara al dispositivo GPS',
  manageCameras_pair: 'Emparejar'
};
