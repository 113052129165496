//imports
import React, { useState, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from 'react-intl';
import { Grid } from '@mui/material';
import { ErrorHandlerLogin, validateEmail } from 'Core/data/Helpers';

import Modal, { ModalBody, ModalFooter } from 'Components/Modal';

import Button from 'Components/Button';

import './main.scss'

import {
  reportsOpenModalDownloadFiles,
  clearRedux
} from 'Redux/actions';

import colors from 'Assets/sass/core/vars.scss';

const ModalDownloadFiles = ({error, submitExtraTimeReport}) => {
  const { messages } = useIntl();
  const dispatch = useDispatch();

  const open = useSelector(state => state.reportsModalDownloadFiles.open);
  const loading = useSelector(state => state.reportsModalDownloadFiles.loading);
  const urlsDownload = useSelector(state => state.catalogsRedux.urlsDownload)
  
  const handleClose = () => {
    dispatch(reportsOpenModalDownloadFiles(false));
    dispatch(clearRedux("CATALOG_REDUX", { urlsDownload: [] }))
  }
  
  return (
    <Modal
      title={messages['reportsModalDownloadReports']}
      open={open}
      handleClose={handleClose}
      size="small"
    >
      <ModalBody>
        <div style={{ padding: 20, paddingBottom: 0 }}>
          {messages['files']}:
          <ul>
          {
            urlsDownload?.length > 0 && urlsDownload.map((url, index) => {
              return (
                <li key={index}>
                  <a style={{color: colors.color_black}} href={url.url} target={'_blank'}>{url.name}</a>
                </li>
              )
            })
          }
          </ul>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button id="btnCloseConfirm" onClick={handleClose} className="btn-cancel">
          {messages['cancel']}
        </Button>
      </ModalFooter>
    </Modal>
  )

}

export default ModalDownloadFiles