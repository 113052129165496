export default {
    "FSM_SETTINGS_MESSAGES_UNIT": "Unit:",
    "FSM_SETTINGS_MESSAGES_DRIVER_STATUS": "Driver Status:",
    "FSM_SETTINGS_MESSAGES_WRITE_MESSAGE": "Write a message here",
    "FSM_SETTINGS_MESSAGES_SEND": "Send",
    "FSM_SETTINGS_MESSAGES_SEND_ALL": "Send All",
    "FSM_SETTINGS_MESSAGES_LAST_CONNECTION": "Last Connection",
    "FSM_SETTINGS_MESSAGES_DRIVER_ALL": "All FSM Drivers",
    "FSM_SETTINGS_MESSAGES_FSM_DRIVER": "FSM Drivers",
    "FSM_SETTINGS_MESSAGES_DRIVER_CONNECTED": "All FSM Drivers Connected",
    "FSM_SETTINGS_MESSAGES_CREATED": "FSM Message created",
    "FSM_SETTINGS_MESSAGES_CHARACTERS": "Characters:",
    "FSM_SETTINGS_MESSAGES_CONFIRM": "Confirm",
    "FSM_SETTINGS_MESSAGES_CONFIRM_TITLE": "Send New Message to All",
    "FSM_SETTINGS_MESSAGES_CONFIRM_CONTENT": "Are you sure want to send this messages to all drivers?",
    "FSM_SETTINGS_MESSAGES_LEGEN": "Legend:",
    "FSM_SETTINGS_MESSAGES_STATUS_NOT_CONNECTED": "Not Connected",
    "FSM_SETTINGS_MESSAGES_STATUS_CONNECTED": "Connected",
    "FSM_SETTINGS_MESSAGES_STATUS_CLOCKED": "Clocked In",
    "FSM_SETTINGS_MESSAGES_STATUS_CLOCKED_CONNECTED": "Connected/Clocked-In",
    "FSM_SETTINGS_MESSAGES_ALLOW_DRIVER_DRIVER": "Allow Driver-Driver Comm"
};

