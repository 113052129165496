/********************************************************
 * Third Controls
 ********************************************************/
 import React from 'react'

 /********************************************************
  * Icon marker to realtime maps
  ********************************************************/
 const GeofenceSvg = (props) => {
   const color = props.color || '#ff0000'
   const width = props.width || 16 
   const height = props.height || 16 
   const title = props.title || 'Geofence'

   return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 60 60">
        <title>{title}</title>
        <path 
          fill={color} 
          opacity=".25" d="M17.4 51.82L4.8 30 17.4 8.18h25.2L55.2 30 42.6 51.82H17.4z"/>
        <path 
          fill={color}
          d="M41.73 9.68L53.47 30 41.73 50.32H18.27L6.53 30 18.27 9.68h23.46m1.73-3H16.54L3.07 30l13.47 23.32h26.92L56.93 30 43.46 6.68z" />
    </svg>
   )
 }
 
 export default GeofenceSvg
 
 
 