export const CLIP_REQUEST_GET_UNITS = 'CLIP_REQUEST_GET_UNITS';
export const CLIP_REQUEST_GET_UNITS_RESULT = 'CLIP_REQUEST_GET_UNITS_RESULT';
export const CLIP_REQUEST_OPEN_FORM = 'CLIP_REQUEST_OPEN_FORM';
export const CLIP_REQUEST_CREATE = 'CLIP_REQUEST_CREATE';
export const CLIP_REQUEST_CREATE_RESPONSE = 'CLIP_REQUEST_CREATE_RESPONSE';
export const CLIP_REQUEST_SEARCH = 'CLIP_REQUEST_SEARCH';
export const CLIP_REQUEST_SEARCH_RESPONSE = 'CLIP_REQUEST_SEARCH_RESPONSE';
export const CLIP_REQUEST_OPEN_MODAL_ADD_NOTES = 'CLIP_REQUEST_OPEN_MODAL_ADD_NOTES';
export const VIDEO_RESTORE_CLIP_REQUEST = 'VIDEO_RESTORE_CLIP_REQUEST';
export const VIDEO_RESTORE_CLIP_REQUEST_RESPONSE = 'VIDEO_RESTORE_CLIP_REQUEST_RESPONSE';
export const CLIP_REQUEST_UPDATE_CLIP = 'CLIP_REQUEST_UPDATE_CLIP';
export const CLIP_REQUEST_REMOVE_CLIPS = 'CLIP_REQUEST_REMOVE_CLIPS';
export const CLIP_REQUEST_DATA = 'CLIP_REQUEST_DATA';
export const CLIP_REQUEST_NEW_FORM = 'CLIP_REQUEST_NEW_FORM';
export const CLIP_AVAILABLE_SEARCH = 'CLIP_AVAILABLE_SEARCH';
export const CLIP_AVAILABLE_SEARCH_RESPONSE = 'CLIP_AVAILABLE_SEARCH_RESPONSE';
export const CLIP_REQUEST_REFRESH_CLIP = 'CLIP_REQUEST_REFRESH_CLIP';
export const CLIP_REQUEST_REFRESH_CLIP_RESPONSE = 'CLIP_REQUEST_REFRESH_CLIP_RESPONSE';


