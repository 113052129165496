import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { clientQuery, clientMutation } from '../../../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../../../../../core/logManager';

import { resetALL } from '../../../../../../../../../.globals/redux/actions';


//STATE INITIAL
const INIT_STATE = {
    open: false,
    response: null,
    driverStatusLogs: [],
    error: null,
    loading: false
};

const route = '/modules/settings/views/driver/components/timeClock/components/delete/redux/slice';

export const deleteDriverStatusLogs = createAsyncThunk('driver/components/timeClock/components/delete/deleteDriverStatusLogs', async ({ ids }, { rejectWithValue, dispatch }) => {
    try {
        let query = `
            mutation deleteDriverStatusLog($id: Int!) {
                deleted: deleteDriverStatusLog(id: $id)
            }
        `;

        const response = await clientMutation(
            query,
            {
                id: ids[0]
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response?.deleted) {
            throw 'Error while deleting driver status logs';
        }

        return response?.deleted;
    } catch (exc) {
        ExceptionManager(exc, route, 'deleteDriverStatusLogs');
        return rejectWithValue(exc);
    }
});

//Slice
const driverStatusLogDeleteRedux = createSlice({
    name: 'driverStatusLog/delete',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openDeleteModal(state, action) {
            state.open = true;
            state.response = null;
            state.driverStatusLogs = action?.payload;
            state.error = null;
        },
        closeDeleteModal(state) {
            state.open = false;
            state.driverStatusLogs = [];
            state.response = null;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(deleteDriverStatusLogs.pending, (state, action) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteDriverStatusLogs.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
            state.error = null;
        });
        builder.addCase(deleteDriverStatusLogs.rejected, (state, action) => {
            state.loading = false;
            state.response = null;
            state.error = action.payload;
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = driverStatusLogDeleteRedux;

// Extract and export each action creator by name
export const {
    reset,
    openDeleteModal,
    closeDeleteModal,
} = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;