//View Keys
export default {
	listDriverVehiclesView_date: 'Fecha',
  listDriverVehiclesView_day: 'Día',
  listDriverVehiclesView_month: 'Mes',
  listDriverVehiclesView_week: 'Semana',
  listDriverVehiclesView_weighed: 'Peso',
  listDriverVehiclesView_vehicle: 'Vehículo',
  listDriverVehiclesView_driver: 'Conductor',
  listDriverVehiclesView_rapidAcceleration: 'Aceleración rápida',
  listDriverVehiclesView_hardBrake: 'Freno duro',
  listDriverVehiclesView_suddenStop: 'Parada repentina',
  listDriverVehiclesView_hardTurn: 'Giro duro',
  listDriverVehiclesView_speed: 'Velocidad > ',
  listDriverVehiclesView_speed75: 'Velocidad > 75',
  listDriverVehiclesView_speed80: 'Velocidad > 80',
  listDriverVehiclesView_speed85: 'Velocidad > 85',
  listDriverVehiclesView_speed75KPH: 'Velocidad > 121',
  listDriverVehiclesView_speed80KPH: 'Velocidad > 129',
  listDriverVehiclesView_speed85KPH: 'Velocidad > 137',
  listDriverVehiclesView_speed_custom: 'Velocidad > {customSpeed}',
  listDriverVehiclesView_totalSafetyEvents: 'Eventos de seguridad total',
  listDriverVehiclesView_score: 'Puntuación',
  listDriverVehiclesView_scores: 'Puntuaciónes',
  listDriverVehiclesView_aggresion: 'Agresión',
  listDriverVehiclesView_aggresion_total: 'Agresión Total',
  listDriverVehiclesView_vehicleSpeed: 'Velocidad del vehículo',
  listDriverVehiclesView_vehicleSpeed_total: 'Total de Velocidad',
  listDriverVehiclesView_addCustomSpeed: 'Agregar Velocidad personalizada',
  listDriverVehiclesView_speedingOverPosted: 'Exceso de velocidad',
  listDriverVehiclesView_speedingOverPosted_total: 'Total exceso de velocidad',
  listDriverVehiclesView_speedingOverPostedLow: '1-5 (bajo)',
  listDriverVehiclesView_speedingOverPostedMedium: '6-10 (medio)',
  listDriverVehiclesView_speedingOverPostedHigh: '11+ (Alto)',
  listDriverVehiclesView_idling:"Inactivo",
  listDriverVehiclesView_continous:"Continuo",
  listDriverVehiclesView_perDay:"Por día",
  listDriverVehiclesView_minutes:"Minutos",
  listDriverVehiclesView_hours:"Horas",
  listDriverVehiclesView_customSpeed:"Velocidad >",
  listDriverVehiclesView_mph:"MPH",
  listDriverVehiclesView_kph:"KPH",
  listDriverVehiclesView_idlingContinous:"30 Min Continuos",
  listDriverVehiclesView_idlingPerDay:"30 Min Por Día",
  listDriverVehiclesView_speedingOverPostedOccurrences: 'Exceso de Velocidad Público (Ocurrencias)',
  listDriverVehiclesView_totalPointAllowancePerDay: 'Asignación total de puntos por día',
  listDriverVehiclesView_tootipScores: 'Mueva el control deslizante verde a continuación para establecer cuántos puntos por día puede acumular un conductor o vehículo para ser considerado de “bajo riesgo”. Utilice el control deslizante amarillo para configurarlo como "Riesgo medio" y todos los demás que superen el máximo amarillo se considerarán "Riesgo alto".',
  listDriverVehiclesView_tootipTotalPointAllowance:"Este número debe establecerse en la cantidad total de puntos que se pueden acumular hoy sin afectar negativamente la puntuación del conductor.",
  listDriverVehiclesView_tootipRapidAcceleration:"La aceleración rápida se activa cuando un vehículo acelera por encima de un umbral de 5,6 MPH / s durante 1,5 a 3,0 s.",
  listDriverVehiclesView_tootipHardBraking:"El frenado brusco se detecta en base a un vehículo que reduce la velocidad a más de 4 MPH / s durante 1,5 sa 3,0 s.",
  listDriverVehiclesView_tootipSuddenStop:"Se detecta una parada repentina cuando se ejercen 9 G de fuerza durante 1,5 s-3,0 s.",
  listDriverVehiclesView_tootipHardTurn:"Se detecta un giro brusco cuando la dirección de viaje basada en el rumbo del vehículo se desvía 4 MPH / s durante 1.5-3.0 segundos.",
  listDriverVehiclesView_tootipVehicleSpeed75:"Se activa si se registra una velocidad de más de 75 MPH, esto no se registra si excede 80 o 85 MPH.",
  listDriverVehiclesView_tootipVehicleSpeed80:"Se activa si se registra una velocidad de más de 80 MPH, esto no se registra si supera los 85 MPH.",
  listDriverVehiclesView_tootipVehicleSpeed85:"Se activa si se registra una velocidad de más de 85 MPH.",
  listDriverVehiclesView_tootipVehicleSpeed75KPH:"Se activa si se registra una velocidad superior a 120 KPH, no se registra si supera los 128 o 136 KPH.",
  listDriverVehiclesView_tootipVehicleSpeed80KPH:"Se activa si se registra una velocidad superior a 128 KPH, no se registra si supera los 136 KPH.",
  listDriverVehiclesView_tootipVehicleSpeed85KPH:"Se activa si se registra una velocidad superior a 136 KPH.",
  behavior_deleteMessage: '¿Está seguro de que desea Eliminar la velocidad personalizada?',
  behavior_deleteTitle: 'Eliminar',
  behavior_acceptMessage: 'Aceptar',
  behavior_cancelMessage: 'Cancelar',
  behavior_deleteMessageSuccess: 'Velocidad personalizada eliminada correctamente',
  behavior_dateToday: 'Hoy',
  behavior_dateYesterday: 'Ayer',
  behavior_dateThisWeek: 'Esta semana',
  behavior_dateLastToday: 'La semana pasada',
  behavior_dateThisQuarter: 'Este trimestre',
  behavior_dateLastQuarter: 'Ultimo trimestre',
  behavior_dateCustom: 'Personalizado',
  behavior_dateThisMonth: 'Este Mes',
  behavior_dateLastMonth: 'El mes pasado',
  behavior_driverSafetyScore: 'Puntuación de seguridad del conductor',
  behavior_ScoresWeightsPoints:'Puntuaciones y pesos',
  behavior_driverTrends:'Promedio de Tendencias de riesgo',
  behavior_allUnits:'Todas las unidades',
  behavior_allDrivers:'Todos los conductores',
  behavior_settings: 'Configuración',
  listDriverVehiclesView_totalScore: 'Puntaje total',
  behavior_scorecard: 'Puntaje de seguridad del conductor',
  behavior_risk: 'Riesgo',
  behavior_errorDateRange: 'El rango de fechas no puede ser mayor a 60 días',
  behavior_errorValues: 'Por favor seleccione una Unidad/Conductor o Etiqueta'

};
