import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { LogManager, ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
    ACCOUNT_TAGS_VIEW_ENTITIES_SEARCH_ENTITIES
} from 'Redux/actionTypes';

import {
    accountTagsViewEntitiesSearchEntitiesResponse
} from 'Redux/actions';

function* accountTagsViewEntitiesSearchEntitiesRequest({ payload }) {
    try {
        const { filters } = payload;

        const response = yield call(
            clientQuery,
            `
            query searchTagsAndEntitiesNested (
                $parentTagIds: [Int], 
                $entityName: EntityName!, 
                $openSearch: String,
                $onlyEntities: Boolean,
                $limit: Int, 
                $offset: Int
            ) {
                entityTags: searchTagsAndEntitiesNested(
                    parentTagIds: $parentTagIds, 
                    entityName: $entityName, 
                    limit: $limit, 
                    offset: $offset,
                    openSearch: $openSearch,
                    onlyEntities: $onlyEntities,
                ) {
                    items {
                        name
                        id
                        label
                    }
                    total
                }
            }
            `,
            {
                parentTagIds: filters?.parentTagIds,
                openSearch: filters?.openSearch,
                entityName: filters?.entityName,
                limit: filters?.limit || 0,
                offset: filters?.offset || 0,
                onlyEntities: true
            },
            endpoints.GRAPHQL_GENERAL,
        );
        
        if (!response || !response.entityTags) {
            throw 'No entityTags data';
        }

        const { entityTags: { items, total }} = response;
        yield put(accountTagsViewEntitiesSearchEntitiesResponse({ items, total }));
    } catch (exc) {
        ExceptionManager(exc, 'modules/account/views/tags/components/viewEntities/redux/saga', 'accountTagsViewEntitiesSearchEntitiesRequest');
        yield put(accountTagsViewEntitiesSearchEntitiesResponse({ items: [], total: 0, error: true }));
    }
}

function* watchMethods() {
    yield takeEvery(ACCOUNT_TAGS_VIEW_ENTITIES_SEARCH_ENTITIES, accountTagsViewEntitiesSearchEntitiesRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}

