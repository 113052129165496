export default {
    fleetModalBulkOutputs_title: "Outputs Masivos ({number_units}) Unidades",
    fleetModalBulkOutputs_selectOutput: "Seleccionar Output",
    fleetModalBulkOutputs_commandType: "Tipo de Comando",
    fleetModalBulkOutputs_selectedUnits: "Unidades Seleccionadas",
    fleetModalBulkOutputs_supported: "Soportado",
    fleetModalBulkOutputs_notSupported: "No Soportado",
    fleetModalBulkOutputs_sendPulse: "Enviar Pulso",
    fleetModalBulkOutputs_sendOn: "Enviar Encendido",
    fleetModalBulkOutputs_sendOff: "Enviar Apagado",
    fleetModalBulkOutputs_commandSendSuccess: "Comando enviado con éxito",
}