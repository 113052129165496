export default {
    "scheduleDriverTask_wait":"Espera",
    "scheduleDriverTask_title": "Programa una nueva tarea",
    "reminder_time_invalid":"El tiempo de recordatorio no puede ser mayor o igual que la frecuencia",
    "pastdue_time_invalid":"El tiempo de vencimiento no puede ser mayor o igual que la frecuencia",
    "scheduleDriverTask_notesTaskLabel": "Notas de la Tarea",
    "scheduleDriverTask_attachmentsTaskLabel": "Adjuntos de la Tarea",
    "scheduleDriverTask_dateInputPlaceholder": "Selecciona Fecha",
    "scheduleDriverTask_driverInputLabel": "Conductor",
    "scheduleDriverTask_driverInputPlaceholder": "Selecciona Conductor",
    "scheduleDriverTask_schedule": "Programar",
    "scheduleDriverTask_update": "Actualizar",
    "scheduleDriverTask_taskInputLabel": "Tarea",
    "scheduleDriverTask_taskInputPlaceholder": "Selecciona Tarea",
    "scheduleDriverTask_dateInputLabel": "Fecha de Vencimiento",
    "scheduleDriverTask_timeInputLabel": "Hora",
    "scheduleDriverTask_timeInputPlaceholder": "Hora",
    "scheduleDriverTask_notesInputPlaceholder": "",
    "scheduleDriverTask_timeUnitInputPlaceholder": "Unidad de tiempo",
    "scheduleDriverTask_frecuencyInputPlaceholder": "Frecuencia",
    "scheduleDriverTask_remindAtInputPlaceholder": "Recordar a:",
    "scheduleDriverTask_contactsInputLabel": "Notificar",
    "scheduleDriverTask_contactsInputPlaceholder": "Selecciona Suscriptor",
    "scheduleDriverTask_createNewTask": "Adicionar Nuevo",
    "scheduleDriverTask_taskNameInputLabel": "Nombre de la Tarea",
    "scheduleDriverTask_taskNameInputPlaceholder": "Nombre de la Tarea",
    "scheduleDriverTask_taskDescrptionInputLabel": "Descripcion de la Tarea",
    "scheduleDriverTask_taskDescrptionInputPlaceholder": "Descripción de la Tarea",
    "scheduleDriverTask_taskAlreadyExists": "Una tarea con el mismo nombre ya existe",
    "scheduleDriverTask_taskScheduledSuccess": "La tarea fue programada exitosamente",
    "scheduleDriverTask_attachmentsInputLabel": "Adjuntos ",
    "scheduleDriverTask_attachmentsInputPlaceholder": "Seleccionar",
    "scheduleDriverTask_selectOneLeast": "Selecciona al menos uno",
    "scheduleDriverTask_selectContact": "Seleccionar Suscriptor",
    "scheduleDriverTask_subscribersInputLabel": "Suscriptores",
    "scheduleDriverTask_repeatEveryInputLabel": "Repetir Cada",
    "scheduleDriverTask_alertsInputLabel": "Alertas",
    "scheduleDriverTask_reminderInputLabel": "Recordatorio",
    "scheduleDriverTask_reminderInputPlaceholder": "Recordatorio",
    "scheduleDriverTask_reminderTimeUnitInputPlaceholder": "Seleccionar",
    "scheduleDriverTask_pastDueInputLabel": "Atrasado en",
    "scheduleDriverTask_pastDueInputPlaceholder": "Atrasado en",
    "scheduleDriverTask_pastDueTimeUnitInputPlaceholder": "Seleccionar",
    "scheduleDriverTask_editTitle": "Editar tarea programada: ",
    "scheduleDriverTask_invalidTime": "Fecha Invalida",
    "scheduleDriverTask_lastService_invalidTime": "La fecha del último servicio no puede ser posterior a la hora actual",
    "scheduleDriverTask_next_due_invalid_time": "La próxima fecha de vencimiento puede ser anterior a la hora actual",
    "scheduleDriverTask_notesInputLabel": "Notas",
    "scheduleDriverTask_frequencyMax": "Frecuencia máxima: ",
    "scheduleDriverTask_saved": "Tarea de conductor guardada",
    "scheduleTask_saved": "Tarea guardada",
    "scheduleTask_saved_select": "Tarea guardada, porfavor seleccionala",
    "ScheduleService_saved": "Servicio programado guardado",
}
