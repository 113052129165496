//imports
import {
	CATALOG_LOAD,
	CATALOG_LOAD_RESULT,
	CATALOG_INITIAL,
	CATALOG_LOADINGS,
	CATALOG_INITIAL_RESULT,
	CATALOG_PROCESS_ITEM,
	CATALOG_PROCESS_ITEMS,
	CREATE_ASYNC_REPORTS,
	CATALOG_INITIAL_REFRESH,
	CATALOG_INITIAL_REFRESH_RESULT,
	CATALOG_SYNC_PUSH_NOTIFICATIONS,
	CATALOG_SYNC_PUSH_NOTIFICATIONS_RESULT,
	CATALOG_UPDATE_ITEM,
	CATALOG_INITIAL_GET_UNIT,
	CATALOG_INITIAL_GET_UNIT_RESULT,
	CATALOG_ADD_ITEM,
	CATALOG_REMOVE_ITEM
} from 'Redux/actionTypes';

/* ACTIONS */
export const catalogLoad = (keys, force = false) => ({
	type: CATALOG_LOAD,
	payload: { keys, force },
});

export const catalogLoadResult = (data) => ({
	type: CATALOG_LOAD_RESULT,
	payload: data,
});

export const catalogInitial = () => ({
	type: CATALOG_INITIAL,
	//payload: { keys, filters },
});

export const catalogLoadign = (loadings) => ({
	type: CATALOG_LOADINGS,
	payload: loadings,
});

export const catalogInitialResult = (data) => ({
	type: CATALOG_INITIAL_RESULT,
	payload: data,
});

export const catalogProcessItem = (type, item, entity, entityKey) => ({
	type: CATALOG_PROCESS_ITEM,
	payload: { type, item, entity, entityKey },
});

export const catalogProcessItems = (type, items, entity, entityKey) => ({
	type: CATALOG_PROCESS_ITEMS,
	payload: { type, items, entity, entityKey },
});

export const catalogInitialRefresh = (refreshTime) => ({
	type: CATALOG_INITIAL_REFRESH,
	payload: { refreshTime },
});

export const catalogInitialRefreshResult = (data) => ({
	type: CATALOG_INITIAL_REFRESH_RESULT,
	payload: data
});

export const catalogSyncPushNotifications = (sessionId, accountId, dateRange) => ({
	type: CATALOG_SYNC_PUSH_NOTIFICATIONS,
	payload: { sessionId, accountId, dateRange },
});

export const catalogSyncPushNotificationsResult = (data) => ({
	type: CATALOG_SYNC_PUSH_NOTIFICATIONS_RESULT,
	payload: data
});


export const createAsyncReport = (report, reportInstanceId, applyTimeZone) => ({
	type: CREATE_ASYNC_REPORTS,
	payload: {
		report: {
			pending: true,
			reportInstanceId,
			report,
			applyTimeZone
		}
	},
});

export const catalogUpdateItem = (entity, entityKey, values) => ({
	type: CATALOG_UPDATE_ITEM,
	payload: { entity, entityKey, values },
});


export const catalogInitialGetUnit = (data) => ({
	type: CATALOG_INITIAL_GET_UNIT,
	payload: { ids: data?.ids || [] },
});

export const catalogInitialGetUnitResult = (data) => ({
	type: CATALOG_INITIAL_GET_UNIT_RESULT,
	payload: data,
});

export const catalogAddItem = (data) => ({
	type: CATALOG_ADD_ITEM,
	payload: data
});

export const catalogRemoveItem = (data) => ({
	type: CATALOG_REMOVE_ITEM,
	payload: data
})