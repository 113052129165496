//imports
import {
  GET_PREFERENCES,
  GET_PREFERENCES_RESULT,
  GET_PREFERENCES_RESULT_UPDATE,
  SAVE_PREFERENCE,
} from 'Redux/actionTypes';

/* ACTIONS */

//****  get and save user pref  ****/

export const getPreferences = (entityName, entityId, force) => ({
  type: GET_PREFERENCES,
  payload: { entityName, entityId, force }
});

export const getPreferencesResult = (data) => ({
  type: GET_PREFERENCES_RESULT,
  payload: data
});

export const savePreference = (entityName, entityId, preferenceKey, preferenceValue) => ({
  type: SAVE_PREFERENCE,
  payload: { entityName, entityId, preferenceKey, preferenceValue }
})

export const updatePreferenceView = (value) => ({
  type: GET_PREFERENCES_RESULT_UPDATE,
  payload: value
})
