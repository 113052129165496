// Dependencies
import { all } from "redux-saga/effects";
// Views
import fsmSaga from "Modules/fsm/views/main/redux/saga";
import fsmSettingSaga from "Modules/fsm/views/fsmSettings/components/formSettings/redux/saga";
import quickMessageSaga from "Modules/fsm/views/fsmSettings/components/quickMessage/redux/saga";
import quickResposeSaga from "Modules/fsm/views/fsmSettings/components/quickResponse/redux/saga";
import mealBreakNotificationsSaga from "Modules/fsm/views/fsmSettings/components/mealBreakNotifications/redux/saga";
import driverStatusSaga from "Modules/fsm/views/fsmSettings/components/driverStatus/redux/saga";
import timeCardSaga from "Modules/fsm/views/fsmSettings/components/timeCards/redux/saga";
import MessageSaga from "Modules/fsm/views/fsmMessages/redux/saga";
import RouteSaga from "Modules/fsm/views/fsmRoutes/redux/saga";


export default function* rootSaga() {
    yield all([
        fsmSaga(),
        fsmSettingSaga(),
        quickMessageSaga(),
        quickResposeSaga(),
        driverStatusSaga(),
        timeCardSaga(),
        MessageSaga(),
        RouteSaga(),
        mealBreakNotificationsSaga()
    ]);
}
