import { Icons, Tooltip } from "@gpstrackit/gtc-react-controls";
import { Divider } from "@mui/material";
import React, { useRef } from "react";

import VidFleetVideo from "Modules/.common/components/VidFleetVideo";

import { getVidFleetEvent } from "Core/data/VideoEvents";
import { getFormattedDateByTimeZone, speedFormatMPH } from "Core/data/Helpers";

import { useIntl } from "react-intl";
import moment from "moment";
import { focusTrailFromEvent } from "../../../historyOverView/utils";

const STATUS_ASSETS_PATH = "/assets/images/";

const Event = ({
  id,
  unitTime,
  timeZone,
  eventCode,
  eventName,
  thumbnailUrl,
  duration,
  clipID,
  deviceModelName,
  url,
  useEcuSpeed,
  ecuSpeed,
  speed,
  dateformat,
  unitType,
  isKph,
  driverName,
}) => {
  const { messages } = useIntl();
  const vidFleetVideoRef = useRef();

  let event = getVidFleetEvent(eventCode);
  const imageRoute = `${STATUS_ASSETS_PATH}clipRequestFailed.png`;

  const newSpeed = useEcuSpeed ? (ecuSpeed ? ecuSpeed : speed) : speed;
  const speedFormat = speedFormatMPH(newSpeed, unitType, isKph);

  const getEventName = `${
    messages[event?.lanKey] ||
    (eventName === "Crash" ? messages["videoMainView_drivingHit"] : eventName)
  }`;
  let truncate = getEventName?.toString()?.slice(0, 20);
  if (getEventName?.length > 20) {
    truncate = truncate + "...";
  }

  const options = {
    messages: messages, //For to access to language keys
    mode:
      eventName === "AutoSnapshot" || !thumbnailUrl || duration === 0
        ? "picture"
        : "video",
    type: "playback", //Depends of the playing type, the url VidFleet API changes
    id: clipID, // This could be a ESN or Clip Id
    autoplay: false, //If is true the video will be played automatically
    deviceModel: deviceModelName || "4K", //4K|360
    thumbnailUrl: thumbnailUrl || url || imageRoute, // For the available media there is always an available resource
    pictureId: null, // The id of the picture for request to VidFleet API for the thumbnail
    disabled: false, // true if not completed
    name: `${clipID}-${id}-${getFormattedDateByTimeZone(
      unitTime,
      timeZone?.name,
      messages,
      "YYYY-MM-DD-HH-mm-ss"
    )}`,
  };

  return (
    <div id={`event_${moment(unitTime).utc().unix()}`} className="incident_event_item">
      <Tooltip
        placement="right-end"
        key="dateInformation"
        title={
          <div className="incident_event_item_information">
            <p style={{ margin: 0, fontSize: "16px", fontWeight: "bold" }}>
              {getEventName?.length > 20 ? (
                <div style={{ display: "flex", textAlign: "left" }}>
                  <Tooltip key={getEventName} title={getEventName}>
                    {truncate}
                  </Tooltip>
                </div>
              ) : (
                <>{getEventName}</>
              )}
            </p>
            <div style={{ display: "flex" }}>
              <p style={{ marginRight: "5px" }}>
                {messages["incident_event_title_events_speed"]}
              </p>
              <p>{speedFormat}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ marginRight: "5px" }}>
                {messages["incident_event_title_events_driver"]}
              </p>
              <p>{driverName}</p>
            </div>
          </div>
        }
      >
        <div style={{display: 'flex', width: '100px', cursor: 'pointer'}}>
          <div 
            onClick={() => focusTrailFromEvent(`trail_${moment(unitTime).utc().unix()}`)}
            className="incident_event_item_date" style={{width: '55px'}}>
            <div>
              <p style={{ margin: 0, marginRight: "5px" }}>
                {moment(unitTime).tz(timeZone?.name).format("hh:mm")}
              </p>
              <Tooltip title={timeZone?.name}>
                {moment.tz(timeZone?.name).zoneAbbr()}
              </Tooltip>
            </div>
            <p style={{ margin: 0 }}>{moment(unitTime).format(dateformat)}</p>
            <p style={{ margin: 0, textAlign: 'center' }}>{getEventName?.length > 20 ? (
                <div style={{ display: "flex", textAlign: "left" }}>
                  <Tooltip key={getEventName} title={getEventName}>
                    {truncate}
                  </Tooltip>
                </div>
              ) : (
                <>{getEventName}</>
              )}</p>
          </div>
          <Divider
            style={{ height: "150px" }}
            orientation="vertical"
            variant="middle"
            flexItem
          >
            <img
              src={event?.icon}
              alt={event?.name}
              style={{ width: 25, height: 25 }}
            />
          </Divider>
        </div>
      </Tooltip>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "black",
          width: "200px",
          height: "150px",
        }}
      >
        <div style={{ width: "200px", height: "150px" }}>
          <VidFleetVideo ref={vidFleetVideoRef} {...options} />
        </div>
      </div>
    </div>
  );
};

export default Event;
