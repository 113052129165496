import {
  //TYPES
  MAP_GEOFENCE_GROUP_HIDE,
  MAP_GEOFENCE_NEW,
  MAP_GEOFENCE_NEW_RESPONSE,
  MAP_GEOFENCE_RESTART_PROCESS
} from 'Redux/actionTypes';

export const hideGeofenceGroups = (params) => {
  return {
    type: MAP_GEOFENCE_GROUP_HIDE,
    payload: params 
  };
}

export const createGeofence = (params = {}) => {
  return {
    type: MAP_GEOFENCE_NEW,
    payload: { ...params }
  };
}

export const createGeofenceResult = (result, error = '') => ({
  type: MAP_GEOFENCE_NEW_RESPONSE,
  payload: { result, error },
});

export const geofenceRestarProcess = () => ({
  type: MAP_GEOFENCE_RESTART_PROCESS
});

