import {
  DEBUG_UNIT_GET_UNIT_DATA,
  DEBUG_UNIT_GET_UNIT_DATA_RESPONSE,
  CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
  loading: false,
  response: null,
  error: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DEBUG_UNIT_GET_UNIT_DATA:
      return { ...state, response: null, loading: true };

    case DEBUG_UNIT_GET_UNIT_DATA_RESPONSE:
      return { ...state, response: action.payload.response, error: action.payload.error, loading: false };

    case CLEAR_REDUX:
      return ["", "DEBUG_UNIT"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
}