//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
//import { clientGET } from 'Core/data/RestApi';
import moment from 'moment';

//action types
import {
	GET_PREFERENCES,
	SAVE_PREFERENCE,
} from 'Redux/actionTypes';

//actions
import {
	getPreferencesResult,
  updatePreferenceView
} from 'Redux/actions';

const getPreferencesName = (entityName) => {
	switch (entityName) {
		case "User":
			return "preferencesUser"
		case "Company":
			return "preferencesCompany"
		default:
			return "preferencesOther"
	}
}

function* getPreferencesRequest({ payload }) {
	try {
		const { entityName, entityId, force = false } = payload;
		let getDataServer = false;
		let data, preferences = []
		if (!force) {
			data = localStorage.getItem(getPreferencesName(entityName));
			if (data) data = JSON.parse(data);

			if (data && data.date) {
				let date = moment(data.date).add(600, 'seconds');
				if (date > moment()) {
					preferences = data.data
				}
				else
					getDataServer = true;
			}
			else
				getDataServer = true;
		}
		else {
			getDataServer = true
		}

		if (getDataServer) {
			data = yield call(
				clientQuery,
				`query ($projectKey: ProjectKey!, $entityName: EntityName!, $entityId: String!, $preferenceKey: [String]){
				Preferences: searchPreferences (projectKey: $projectKey, entityName: $entityName, entityId: $entityId, preferenceKeys: $preferenceKey) {
					id
					entityName
					entityId
					preferenceKey
					preferenceValue
				}
			}`,
				{
					projectKey: "PX",
					entityName,
					entityId,
					//preferenceKey
				},
				endpoints.GRAPHQL_GENERAL,
			);

			if (data && data.Preferences) {
				data = { date: moment(), data: data.Preferences }
				localStorage.setItem(getPreferencesName(entityName), JSON.stringify(data))
				preferences = data.data
			}
		}

		let result = {}
		result[getPreferencesName(entityName)] = preferences

		yield put(getPreferencesResult(result))

	} catch (exc) {
		// ALWAYS RETURN ACTION
		ExceptionManager(
			exc,
			'core/containers/Prefereces/redux/saga',
			'getPreferences',
		);
		yield put(getPreferencesResult({}))
	}
}

export function* getPreferences() {
	yield takeEvery(GET_PREFERENCES, getPreferencesRequest)
}

export function* savePreference() {
	yield takeEvery(SAVE_PREFERENCE, function* ({ payload }) {
		try {
			const { entityName, entityId, preferenceKey, preferenceValue } = payload;
			yield call(
				clientMutation,
				` mutation savePreference ($projectKey: ProjectKey!, $entityName: EntityName!, $entityId: String!, $preferenceKey: String!, $preferenceValue: AWSJSON!){
					preference: savePreference (projectKey: $projectKey, entityName: $entityName, entityId: $entityId, preferenceKey: $preferenceKey, preferenceValue: $preferenceValue) {
						id
					}
				}`,
				{
					projectKey: "PX",
					entityName,
					entityId,
					preferenceKey,
					preferenceValue: JSON.stringify(preferenceValue)
				},
				endpoints.GRAPHQL_GENERAL,
			);

			let data = localStorage.getItem(getPreferencesName(entityName));
			if (data)
				data = JSON.parse(data);
			else
				data = { date: moment(), data: [] }

			let dataPreferences = data.data;
			let index = dataPreferences.findIndex(x => x.preferenceKey === preferenceKey)
			if (index === -1) {



				yield call(getPreferencesRequest, { payload: { entityName, entityId, force: true } })        
			}
			else {
				dataPreferences[index].preferenceValue = JSON.stringify(preferenceValue);
				data = { date: moment(), data: dataPreferences }
				localStorage.setItem(getPreferencesName(entityName), JSON.stringify(data))
			}

			let result = {}
			result[getPreferencesName(entityName)] = dataPreferences
			yield put(getPreferencesResult(result))
			yield put(updatePreferenceView(false)); //update the other views

		} catch (exc) {
			// ALWAYS RETURN ACTION
			ExceptionManager(
				exc,
				'core/containers/Prefereces/redux/saga',
				'savePreference',
			);
      		yield put(updatePreferenceView(false)); //update the other views
			//yield put(setCurrentUserPreference(null));
		}
	});
}

//MERGE SAGA
export default function* rootSaga() {
	yield all([
		fork(getPreferences),
		fork(savePreference)
	]);
}
