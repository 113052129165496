export default {
    fleet_inputs_modal_title: 'Dispositivos De Salidad Unidad',
    fleet_inputs_ivalid_title: 'Espera',
    fleet_inputs_ivalid_message: 'No Se Puede Conseguir Información De Esta Unidad',
    back: 'Regresar',
    fleet_inputs_title: 'TITULO',
    fleet_inputs_type: 'Tipo',
    fleet_inputs_label: 'Label',
    fleet_send_on: 'Prender',
    fleet_send_off: 'Apagar',
    fleet_send_pulse: 'Pulsar',
    fleet_send_single_pulse: 'Pulso único',
    fleet_output: 'Salida',

    fleet_validation_outputs_message:"Salidas Actualizadas",
    fleet_validation_outputs_title:"Existoso",

    fleet_invalid_outputs_message:"No Hay Dispositivos De Salidad Para Esta Unidad",
    fleet_invalid_outputs_title:"Espera",

    fleetModalOutput_label: "Etiqueta",
    fleetModalOutput_title: "Título",
};