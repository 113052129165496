//imports
import {
	FORGOT_PASSWORD,
	FORGOT_PASSWORD_RESULT,
	FORGOT_PASSWORD_CONFIRM,
	FORGOT_PASSWORD_CONFIRM_RESULT,
	FORGOT_PASSWORD_CONFIRM_CLOSE,
} from 'Redux/actionTypes';

/* ACTIONS */

export const forgotPassword = (username) => ({
	type: FORGOT_PASSWORD,
	payload: { username },
});

export const forgotPasswordResult = (showConfirm, error) => ({
	type: FORGOT_PASSWORD_RESULT,
	payload: { showConfirm, error },
});

export const forgotPasswordConfirmClose = () => ({
	type: FORGOT_PASSWORD_CONFIRM_CLOSE,
	//payload: { }
});

export const forgotPasswordConfirm = (username, confirmationCode, password, confirmPassword) => ({
	type: FORGOT_PASSWORD_CONFIRM,
	payload: { username, confirmationCode, password, confirmPassword },
});

export const forgotPasswordConfirmResult = (result, error) => ({
	type: FORGOT_PASSWORD_CONFIRM_RESULT,
	payload: { result, error },
});
