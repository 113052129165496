import {
    FSM_SETTINGS_MESSAGE_SEARCH,
    FSM_SETTINGS_MESSAGE_SEARCH_RESPONSE,
    FSM_SETTINGS_MESSAGE_CREATE,
    FSM_SETTINGS_MESSAGE_CREATE_RESPONSE,
    FSM_SETTINGS_MESSAGE_CREATE_ALL,
    FSM_SETTINGS_MESSAGE_CREATE_ALL_RESPONSE,
    FSM_SETTINGS_MESSAGE_GET_DRIVER_TO_DRIVER,
    FSM_SETTINGS_MESSAGE_GET_DRIVER_TO_DRIVER_RESPONSE,
    FSM_SETTINGS_MESSAGE_UPDATE_COMPDATA_FSM_ALL,
    FSM_SETTINGS_MESSAGE_UPDATE_COMPDATA_FSM_ALL_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loadingMessage: false,
    Messages: {},
    allowDrivertoDriver: {},
    responseAllowDrivertoDriver: {},
    loadingCreateEdit: false,
    response: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case FSM_SETTINGS_MESSAGE_SEARCH:
            let paramsLoad = action?.payload?.load?{}:{ loadingMessage: true,Messages: []};
            return {
                ...state,
                ...paramsLoad
            };


        case FSM_SETTINGS_MESSAGE_SEARCH_RESPONSE:
            return {
                ...state,
                loadingMessage: false,
                Messages: action?.payload?.messages
            };

        case FSM_SETTINGS_MESSAGE_CREATE:
            return {
                ...state,
                loadingCreateEdit: true,
                response: {}
            };

        case FSM_SETTINGS_MESSAGE_CREATE_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                response: action.payload
            };


        case FSM_SETTINGS_MESSAGE_CREATE_ALL:
            return {
                ...state,
                loadingCreateEdit: true,
                response: {}
            };

        case FSM_SETTINGS_MESSAGE_CREATE_ALL_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                response: action.payload
            };


        case FSM_SETTINGS_MESSAGE_GET_DRIVER_TO_DRIVER:
            return {
                ...state,
                allowDrivertoDriver: {}
            };

        case FSM_SETTINGS_MESSAGE_GET_DRIVER_TO_DRIVER_RESPONSE:

            return {
                ...state,
                allowDrivertoDriver: action.payload
            };

        case FSM_SETTINGS_MESSAGE_UPDATE_COMPDATA_FSM_ALL:
            return {
                ...state,
                responseAllowDrivertoDriver: {}
            };

        case FSM_SETTINGS_MESSAGE_UPDATE_COMPDATA_FSM_ALL_RESPONSE:

            return {
                ...state,
                responseAllowDrivertoDriver: action.payload
            };

        case CLEAR_REDUX:
            return ["", "FSM_SETTINGS_MESSAGE"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return state;
    }
};