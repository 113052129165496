export default {
    maintenanceDeleteMaintenanceService_deleteLabel: 'Eliminar Servicio',
    maintenanceDeleteMaintenanceService_messageDelete: 'Estás seguro de querer eliminar el servicio',
    maintenanceDeleteMaintenanceService_deleteConfirmationSuccessfully: 'Servicio Eliminado Exitosamente',
    maintenanceDeleteMaintenanceService_deleteConfirmationDone: 'El servicio ha sido completado, así que no puede ser eliminado',
    maintenanceDeleteMaintenanceService_theServiceLabel: 'El servicio',
    maintenanceDeleteMaintenanceService_isProgrammed: 'Esta actualmente programado a un vehiculo, quiere eliminarlo',
    maintenanceDeleteMaintenanceService_deleteServicesLabel: 'Eliminar Servicios',
    maintenanceDeleteMaintenanceService_deleteSevicesConfirmationSuccessfully: 'Servicios Eliminados Exitosamente',
    maintenanceDeleteMaintenanceService_messageDeleteServices: 'Estás seguro de querer eliminar los servicios',
    maintenanceDeleteMaintenanceService_messageScheduledServices: 'Algunos servicios actualmente estan programados a un vehiculo, quiere eliminarlos',
    maintenanceDeleteMaintenanceService_scheduledServicesLabel: 'Programar servicios',
};
