import {
    MAP_GEOFENCES_BULK_OPEN_MODAL,
    MAP_GEOFENCES_BULK_UPDATE,
    MAP_GEOFENCES_BULK_UPDATE_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    open: false,
    geofencesToBulk: [],
    loadingGeofenceBulk: false,
    response: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MAP_GEOFENCES_BULK_OPEN_MODAL:
            return {
                ...state,
                open: action.payload.open,
                geofencesToBulk: action.payload.geofences,
                response: null
            };

        case MAP_GEOFENCES_BULK_UPDATE:
            return {
                ...state,
                response: null,
                loadingGeofenceBulk: true
            };

        case MAP_GEOFENCES_BULK_UPDATE_RESPONSE:
            return {
                ...state,
                response: action.payload.data,
                loadingGeofenceBulk: false
            };
        
        case CLEAR_REDUX:
            return ["", "MAP_GEOFENCES_BULK"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
      return state;
    }
}