import {
  REPORTS_HANDLE_MODAL_REPORT_TYPE,
  REPORTS_HANDLE_MODAL_SELECT_TYPE,
  REPORTS_HANDLE_MODAL_SELECT_REPORT
} from "Redux/actionTypes";

export const reportsOpenModalReportType = (open) => ({
  type: REPORTS_HANDLE_MODAL_REPORT_TYPE,
  payload: {
    open
  },
});

export const reportModalSelectType = (reportType) => ({
  type: REPORTS_HANDLE_MODAL_SELECT_TYPE,
  payload: {
    reportType
  },
});

export const reportModalSelectReport = (reportSelected) => ({
  type: REPORTS_HANDLE_MODAL_SELECT_REPORT,
  payload: {
    reportSelected
  },
});
