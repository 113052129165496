import { call, put } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';
// Actions
import { reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate } from 'Redux/actions';

import {
    baseReportConditions,
    generateReportInstance,
    getTimeZone,
    handleAsyncReport,
    isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

function* getChatHistoryReportV2(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
    const { timeZone, sendByEmail,name, emailsTo, timeframeName, categoryName, delayed, dataResultPath } = report;
    const { filterType, filterArray, startRange, endRange, otherFilters, unitsSelected,accumulateData = true, dates } = report.filters
    const baseConditionsQuery = baseReportConditions({
        report,
        name,
        dataResultPath,
        timeZone,
        delayed,
        unitsSelected,
        timeframeName,
        filterType,
        filterArray,
        categoryName,
        startRange,
        endRange,
        dates
    })
    try {
        let payload = {
            conditions: {
                ...baseConditionsQuery,
                sortField: {
                    field: sortField || "driverId",
                    order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                }
            },
            limit: rowsPerPage,
            offset: page
        };

        let addColumnDriver = [];
        if (report?.exportType === 'csv') {
            addColumnDriver.push({
                column: "driverName",
                header: "Driver"
            })
        }

        payload = {
            ...payload,
            limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
            offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
            conditions: {
                ...payload.conditions,
                exportOptions: {
                    sendByEmail,
                    emailsTo,
                    format: {
                        fileFormat: report?.exportType || "json",
                        formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
                        durationFormat: report?.durationFormat
                    },
                    culture: report.lang,
                    timeZone: getTimeZone(timeZone),
                    folderName: "px-customer-reports",
                    filePrefixName: "chatHistoryReport",
                    columns: [
                        ...addColumnDriver
                        , {
                            "column": "dateTime",
                            header: `${report?.exportType === "csv" ? `Time & Date (${getAbbvTimezone(timeZone)})` : `Time & Date`}`
                        }, {
                            "column": "messageDirection",
                            "header": "To/From"
                        }, {
                            "column": "message",
                            "header": "Message"
                        }
                    ]
                }
            }
        }

        let data = yield call(
            clientQuery,
            `query getChatHistoryReportV2 ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
                Report: getChatHistoryReportV2 (conditions: $conditions, limit: $limit, offset: $offset) {
                  ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
                `reportInstanceId`
                : `
                    items {
                        recordInfo {
                            entityId
                            entityLabel
                            tagId
                            tagLabel
                            startDate
                            endDate
                            recordType
                            groupLabel
                            groupIndex
                            groupTotal
                            groupTagColor
                        }
                        recordDetail {
                            id
                            driverName
                            driverId
                            idLabel
                            message
                            unitTime
                            messageDirection
                            receiverName
                            transmitterName
                            noData
                        }
                    }
                    total
                    limit
                    offset
                    category
                `}
                }
              }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
        yield handleAsyncReport(report, data, accumulateData);

    } catch (exc) {
        ExceptionManager(exc, 'reports/chatHistoryReportV2', 'getChatHistoryReportV2');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}

export default getChatHistoryReportV2;
