import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';
// Actions
import {
  reportsMainGenerateReportProgress,
  reportsMainGenerateReportUpdate
} from 'Redux/actions';

import {
  getTimeZone,
  getCategoryFilter,
  handleAsyncReport,
  baseReportConditions,
  generateReportInstance,
  getEntityType,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getJBDiagnosticsReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
  const { timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath } = report;
  const { filterType, filterArray, startRange, endRange, unitsSelected } = report.filters
  const baseConditionsQuery = baseReportConditions({ report, name, dataResultPath, timeZone, delayed, unitsSelected, timeframeName, filterType, filterArray, categoryName, startRange, endRange })
  try {
    let payload = {
      conditions: {
        ...baseConditionsQuery,
        sortField: {
          field: sortField || "deviceId",
          order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
      },
      limit: rowsPerPage,
      offset: page
    };

    if (report.export) {
      payload = {
        ...payload,
        limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
        offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
        conditions: {
          ...payload.conditions,
          exportOptions: {
            sendByEmail,
            emailsTo,
            format: {
              fileFormat: report?.exportType || "json",
              formatOptions: "{\"orientation\": \"landscape\"}"
            },
            culture: report.lang,
            timeZone: getTimeZone(timeZone),
            folderName: "px-customer-reports",
            filePrefixName: "jbDiagnosticsReport",
            columns: [
              ...(
                report?.exportType === 'pdf' ?
                []
                :
                [
                  {
                    "column": "idLabel",
                    "header": getCategoryFilter(filterType) === "deviceId" ? "Unit" : "Driver"
                  }
                ]
              ),
              {
                "column": "unitTime",
                "header": "Date"
              },
              {
                "column": "eventName",
                "header": "Event Name"
              },
              {
                "column": "acceleratorPedalPosition",
                "header": "Accel Pedal Position (%)"
              },
              {
                "column": "batteryVoltage",
                "header": "Battery Voltage (V)"
              },
              {
                "column": "brakePedalPosition",
                "header": "Brake Pedal Position (%)"
              },
              {
                "column": "engineCoolantLevel",
                "header": "Eng Coolant Level (%)"
              },
              {
                "column": "engineCoolantPressure",
                "header": "Eng Coolant Pressure (Psi)"
              },
              {
                "column": "engineCoolantTemp",
                "header": "Eng Coolant Temp (F)"
              },
              {
                "column": "engineFuelRate",
                "header": "Eng Fuel Rate (gal/h)"
              },
              {
                "column": "engineOilLevel",
                "header": "Eng Oil Level (%)"
              },
              {
                "column": "engineOilPressure",
                "header": "Eng Oil Pressure (Psi)"
              },
              {
                "column": "engineOilTemp",
                "header": "Eng Oil Temp (F)"
              },
              {
                "column": "fuelLevelOne",
                "header": "fuel Level 1 (%)"
              }, {
                "column": "fuelLevelTwo",
                "header": "fuel Level 2 (%)"
              },
              {
                "column": "odometer",
                "header": "Odometer (Miles)"
              },
              {
                "column": "rpm",
                "header": "RPM"
              },
              {
                "column": "seatbeltswitch",
                "header": "Seatbelt switch"
              }

            ]
          }
        }
      }
    }
    let data = yield call(
      clientQuery,
      `query getJBDiagnosticsReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getJBDiagnosticsReport (conditions: $conditions, limit: $limit, offset: $offset) {
          ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
        `reportInstanceId`
        : `
            items {
              id
              idLabel
              noData
          }
            total
            limit
            offset
            category
        `}
        }
        
      }`,
      payload,
      endpoints.GRAPHQL_PX_REPORTING,
    );
    yield handleAsyncReport(report, data);

  } catch (exc) {
    ExceptionManager(exc, 'reports/detailedReport', 'getJBDiagnosticsReport');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
    if (!requestFlag) {
      let flag = true
      yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
    } else {
      report.loading = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  }
}


export default getJBDiagnosticsReport;
