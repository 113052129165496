//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientPOST } from 'Core/data/RestApi';
import { clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

//Action types
import {
  SMARTWITNESS_VIDEO_GET_SNAPSHOTS_URLS,
  SMARTWITNESS_VIDEO_REFRESH_SNAPSHOTS_URLS,
  SMARTWITNESS_VIDEO_SAVE_SNAPSHOTS_URLS
} from 'Redux/actionTypes';

//actions
import {
  getSnapshotsUrlsResponse,
  refreshSnapshotsUrlsResponse,
  saveSnapshotsUrlsResponse
} from 'Redux/actions';

/* FUNTIONS ASYNC */
function* getSnapshotsUrlsRequest({ payload }) {
  const { data } = payload;
  const { recorderId, cameraChannel, user } = data;

  try {
    const headers = {
      'Content-Type': 'application/json',
      'APIKey': endpoints.SMARTWITNESS_API_KEY
    };

    const url = `${endpoints.SMARTWITNESS_API_URL}/SmartWitness/RESTCommandWebService/ExecuteServerCommand/Snapshots/GetURLs?RecorderID=${recorderId}&CameraChannel=${cameraChannel}`;
    const result = yield call(clientPOST, '', {}, url, headers, false, false);

    if (result) {
      let response = { recorderId };

      if (result?.Code === 202) {
        response = { ...response, data: result, error: true };
      } else {
        let newResult = result.map(r => {
          let speed = r?.Speed ? parseInt(r?.Speed) : 0;
          return { ...r, Speed: (user?.isKph ? speed : speed * 0.6213) };
        });
        response = { recorderId, data: newResult };
      }

      yield put(getSnapshotsUrlsResponse(response));
    } else {
      throw 'Error, access denied';
    }
  } catch (exc) {
    // catch throw
    yield put(getSnapshotsUrlsResponse({ error: true, message: 'smartwitness_errorAccessDenied', recorderId, Code: 202, data: [] }));
    ExceptionManager(exc, 'modules/.common/components/SmartWitnessVideo/redux', 'Error while getting smartwitness snapshots urls');
  }
}

function* refreshSnapshotsUrlsRequest({ payload }) {
  const { data } = payload;
  const { recorderId, cameraChannel, user } = data;

  try {
    const headers = {
      'Content-Type': 'application/json',
      'APIKey': endpoints.SMARTWITNESS_API_KEY
    };

    const url = `${endpoints.SMARTWITNESS_API_URL}/SmartWitness/RESTCommandWebService/ExecuteServerCommand/Snapshots/GetURLs?RecorderID=${recorderId}&CameraChannel=${cameraChannel}`;
    const result = yield call(clientPOST, '', {}, url, headers, false, false);

    if (result) {
      let newResult = result.map(r => {
        let speed = r?.Speed ? parseInt(r?.Speed) : 0;
        return { ...r, Speed: (user?.isKph ? speed : speed * 0.6213) };
      });

      let response = { recorderId, data: newResult };
      if (result?.Code === 202) {
        response = { ...response, error: true };
      }
      yield put(refreshSnapshotsUrlsResponse(response));
    } else {
      throw 'Error, access denied';
    }
  } catch (exc) {
    // catch throw
    yield put(refreshSnapshotsUrlsResponse({ error: true, message: 'smartwitness_errorAccessDenied', recorderId, data: [] }));
    ExceptionManager(exc, 'modules/.common/components/SmartWitnessVideo/redux', 'Error while getting smartwitness snapshots urls');
  }
}

function* saveSnapshotsUrlsRequest({ payload }) {
  const { data } = payload;

  try {
    const response = yield call(
      clientMutation,
      `mutation createSWMedia (
        $deviceId: String!,
        $media: AWSJSON!
      ) {
        Snapshot: createSWMedia (
          deviceId: $deviceId,
          media: $media
        ) {
          eventId  
          uuid
          eventType
          deviceId 
        }
      }`,
      data,
      endpoints.GRAPHQL_GENERAL,
    );

    if (response && response.Snapshot) {
      yield put(saveSnapshotsUrlsResponse({ ...response.Snapshot, recorderId: data?.recorderId }));
    } else {
      throw 'Error while saving snapshots urls for smart witness';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/.common/components/SmartWitnessVideo/redux/saga', 'saveSnapshotsUrlsRequest');
    yield put(saveSnapshotsUrlsResponse({ recorderId: data?.recorderId, error: true }));
  }
}

function* watchGetSnapshotsUrlsRequest() {
  yield takeEvery(SMARTWITNESS_VIDEO_GET_SNAPSHOTS_URLS, getSnapshotsUrlsRequest);
}

function* watchRefreshSnapshotsUrlsRequest() {
  yield takeEvery(SMARTWITNESS_VIDEO_REFRESH_SNAPSHOTS_URLS, refreshSnapshotsUrlsRequest);
}

function* watchSaveSnapshotsUrlsRequest() {
  yield takeEvery(SMARTWITNESS_VIDEO_SAVE_SNAPSHOTS_URLS, saveSnapshotsUrlsRequest);
}

//merge sagas
export default function* rootSaga() {
  yield all([
    fork(watchGetSnapshotsUrlsRequest),
    fork(watchRefreshSnapshotsUrlsRequest),
    fork(watchSaveSnapshotsUrlsRequest)
  ]);
}
