import moment from "moment";
import * as momentTz from "moment-timezone";

export const getFormattedDateByTimeZone = (
  date = "",
  timeZone,
  dateFormat = "",
  fromNow = false,
  locale = "en"
) => {
  moment.locale(locale);
  let momentDate = moment();
  if (!date || !timeZone) return;

  if (Number.isInteger(date * 1)) {
    if (date.toString().length > 10) date = date / 1000;

    momentDate = momentTz.unix(date).tz(timeZone);
  } else if (typeof date === "string") {
    momentDate = momentTz.utc(date).tz(timeZone);
  }

  if (!momentDate) return;

  if (dateFormat)
    return `${moment(momentDate).format(dateFormat)} ${
      fromNow && isValidCurrentDate(date) ? `(${momentDate.lang(locale).fromNow()})` : ""
    }`;

  if (momentTz.utc().tz(timeZone).isSame(momentDate, "day")) {
    return `${"date_today"} ${"at"} ${momentDate.format("h:mm A")}`;
  } else if (
    momentTz.utc().tz(timeZone).subtract(1, "d").format("DD") ===
    momentDate.format("DD")
  ) {
    return `${"date_yesterday"} ${"at"} ${momentDate.format("h:mm A")}`;
  }

  return `${momentDate.format("Y/m/d h:mm A")} ${
    fromNow && isValidCurrentDate(date) ? `(${momentDate.lang(locale).fromNow()})` : ""
  }`;
};

function millisToSeconds(milliseconds) {
  return Math.floor(milliseconds / 1000);
}

// Function to determine if the timestamp is in milliseconds or seconds
function isTimestampInMillis(timestamp) {
  // A value in milliseconds would have a length greater than 10 digits
  return timestamp.toString().length > 10;
}

export const isValidCurrentDate = (inputDate) => {
  const now = millisToSeconds(Date.now());
  let timestampInSeconds;

  if (isTimestampInMillis(inputDate)) {
      timestampInSeconds = millisToSeconds(inputDate); // Convert from milliseconds to seconds
  } else {
      timestampInSeconds = inputDate; // Already in seconds
  }
    
  // Compare the provided date with the current date
  return timestampInSeconds <= now;
}

export const GetFormatForMoment = (dateFormat, customFormat = false) => {
  let format = "MM/DD/YYYY";
  if(customFormat == true){
    format = dateFormat
  }
  if (dateFormat === "m/d/Y") {
    format = "MM/DD/YYYY";
  } else if (dateFormat === "d/m/Y") {
    format = "DD/MM/YYYY";
  } else if (dateFormat === "Y/m/d") {
    format = "YYYY/MM/DD";
  }

  return format;
};

export const getAbbvTimezone = (timezone) => {
  let abbr = moment.tz(timezone).zoneAbbr();
  switch (abbr) {
    case '-05':
      return abbr = 'COT'
  }

  return abbr;
};

export const getDateByTimezone = (date, timezone) => {
  let newDate;
  if (Number.isInteger(date * 1)) {
    if (date.toString().length > 10) date = date / 1000;
    newDate = momentTz.unix(date).tz(timezone);
  } else if (typeof date === "string") {
    newDate = momentTz.utc(date).tz(timezone);
  }

  return newDate
}
