import {
    ALERTS_UPDATE_SYSTEM_ALERT,
    ALERTS_UPDATE_SYSTEM_ALERT_RESULT,
    ALERTS_CREATE_SYSTEM_ALERT,
    ALERTS_CREATE_SYSTEM_ALERT_RESULT
} from 'Redux/actionTypes';

export const AlertsCreateSystemAlert = (data) => {
	return {
		type: ALERTS_CREATE_SYSTEM_ALERT,
		payload: data
	};
}

export const AlertsCreateSystemAlertResult = (data) => {
	return {
		type: ALERTS_CREATE_SYSTEM_ALERT_RESULT,
		payload: data
	};
}

export const AlertsUpdateSystemAlert = (data) => {
	return {
		type: ALERTS_UPDATE_SYSTEM_ALERT,
		payload: data
	};
}

export const AlertsUpdateSystemAlertResult = (data) => {
	return {
		type: ALERTS_UPDATE_SYSTEM_ALERT_RESULT,
		payload: data
	};
}