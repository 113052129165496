import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { clientMutation, clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";
import { formatAvailableMedia } from "../utils";

//STATE INITIAL
const INIT_STATE = {
  openIncidentPanel: {
    open: false,
    update: {},
  },
  incidents: [],
  loadingIncidents: false,
  errorIncidents: false,
  totalIncidents: 0,
  lastReading: null,
  loadingLastReading: false,
  errorLastReading: null,
  createIncidentRes: null,
  loadingCreateIncident: false,
  errorCreateIncident: false,
  endIncident: null,
  loadingEndIncident: false,
  errorEndIncident: false,
  loadingItem: [],
  isOpenOverViewUnit: {
    open: false,
    data: {},
  },
  isOpenHistoryOverViewIncident: {
    open: false,
    data: {},
  },
  avMediaIncident: [],
  avMediaIncidentTotal: 0,
  loadingAvMediaIncident: false,
  errorAvMediaIncident: null,
  loadingTrailsIncident: false,
  errorTrailsIncident: null,
  trailsIncidentTotal: 0,
  trailsIncident: [],

  activeIncidentUnitLoading: false,
  activeIncidentUnitError: false,
  activeIncidentUnit: {},

  updateIncidentLoading: false,
  updateIncidentError: false,
  updateIncident: {},

  getUnitOverviewLoading: false,
  getUnitOverviewError: false,
  unitOverviewItems: [],
  unitOverviewItemsTotal: 0,

  sliceTrails: []
};

const route = "core/components/incidents/redux/slice";

export const searchIncidents = createAsyncThunk(
  "incidents/list",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const {
      limit,
      offset,
      conditions,
      sort,
      research = true,
      currentData = [],
    } = payload;

    try {
      const result = await clientQuery(
        `
        query searchIncidents ($conditions: [IncidentSearchInput], $sort: [IncidentSortInput], $limit: Int, $offset: Int) {
            data: searchIncidents (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                items{
                    id
                    unitId
                    name
                    start
                    end
                    shortcode
                    note
                    config
                    systemAlertId
                    assignments
                    subscribers
                    incidentLog{
                        reportedBy
                        endedBy
                    }
                    reading{
                        unitTime
                        deviceLabel
                        latitude
                        longitude
                    }
                }
                total
            }
        }`,
        {
          limit,
          offset,
          conditions,
          sort,
        },
        endpoints.GRAPHQL_GENERAL_V2
      );

      return {
        ...result?.data,
        research,
        currentData,
      };
    } catch (exc) {
      ExceptionManager(exc, route, "incidents");
      return rejectWithValue("There is a problem to get the incidents");
    }
  }
);

export const incidentGetLastReading = createAsyncThunk(
  "incidents/getLastReading",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const data = await clientQuery(
        `query getLastReading ($deviceId: Int!) {
                result: getLastReading (deviceId: $deviceId){
                    deviceId
                    latitude
                    longitude
                    speed
                    deviceData
                    LastMessageTime
                    address {
                        address
                        city
                        state
                        formatted
                        zip
                    }
                    unitTime
                    eventName
                }
            }`,
        {
          deviceId: id,
        },
        endpoints.GRAPHQL_GENERAL_V2
      );

      return data.result;
    } catch (exc) {
      ExceptionManager(exc, route, "incident/lastReading");
      return rejectWithValue(exc);
    }
  }
);

export const createIncident = createAsyncThunk(
  "incidents/createIncident",
  async (payload, { rejectWithValue, dispatch }) => {
    const { unitId, name, start, end, note, jsonAlert } = payload;

    try {
      const data = await clientMutation(
        `mutation createIncident ($unitId: Int!, $name: String!, $start: String!, $end: String, $note: String, $jsonAlert: String) {
                createIncident (unitId: $unitId, name: $name, start: $start, end: $end, note: $note, jsonAlert: $jsonAlert) {
                    id
                    unitId
                    name
                    start
                    end
                    shortcode
                    note
                    createdBy
                    createdOn
                    updatedBy
                    updatedOn
                }
            }`,
        {
          unitId,
          name,
          start,
          end,
          note,
          jsonAlert
        },
        endpoints.GRAPHQL_GENERAL_V2
      );

      return data.createIncident;
    } catch (exc) {
      ExceptionManager(exc, route, "incident/createIncident");
      return rejectWithValue(exc);
    }
  }
);

export const endIncidentRedux = createAsyncThunk(
  "incidents/endIncidentRedux",
  async (payload, { rejectWithValue, dispatch }) => {
    const { id, note } = payload;

    try {
      const data = await clientMutation(
        `mutation endIncident ($id: Int!, $note: String!) {
                endIncident (id: $id, note: $note) {
                    id
                }
            }`,
        {
          id,
          note,
        },
        endpoints.GRAPHQL_GENERAL_V2
      );

      return data.endIncident;
    } catch (exc) {
      ExceptionManager(exc, route, "incident/endIncidentRedux");
      return rejectWithValue(exc);
    }
  }
);

export const searchAvailableMediaIncident = createAsyncThunk(
  "incidents/searchAvailableMediaIncident",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const {
      limit,
      dateRange,
      offset,
      entities,
      research = true,
      currentData = [],
    } = payload;

    try {
      const result = await clientQuery(
        `query searchAvailableMedia (
              $openSearch: String,
              $entities: [EntityInput]!, 
              $dateRange: AWSDateTimeRange!, 
              $events: [VidFleetEvent], 
              $hasNotes: Boolean, 
              $starred: Boolean, 
              $mediaDeleted: Boolean, 
              $mediaType: enumMediaType,
              $reviewStatus: enumVideoReviewStatus,
              $limit: Int, 
              $offset: Int
            ) {
              searchAvailableMedia (
                openSearch: $openSearch,
                entities: $entities, 
                dateRange: $dateRange, 
                events: $events, 
                hasNotes: $hasNotes, 
                starred: $starred, 
                mediaDeleted: $mediaDeleted, 
                mediaType: $mediaType,
                reviewStatus: $reviewStatus,
                limit: $limit, 
                offset: $offset
              ) {
                  items {
                      reading {
                          id
                          deviceId
                          heading
                          eventCode
                          eventName
                          driverId
                          driverName
                          unitTime
                          deviceData
                          starred
                          notes
                          speed
                          normalizedSpeed
                          ecuSpeed
                          unitType
                          formattedAddress
                      }
                      unit {
                          id
                          label
                          type
                          hid
                          esn
                          imei
                          driverId
                          driverName
                          notes
                          useEcuSpeed
                          timeZone {
                            id
                            name
                          }
                      }
                      linkedDevice {
                        id
                        esn
                        deviceModelId
                        deviceModelName
                        manufacturerId
                        manufacturerName
                        hasVideo
                      }
                    }
                  total
              }
          }`,
        {
          openSearch: "",
          limit,
          offset,
          dateRange,
          entities:
            !entities || !entities?.length || entities.find((e) => e.id == -1)
              ? [{ name: "All", id: -1 }]
              : entities,
          mediaDeleted: false, //pending
          mediaType: "All",
          reviewStatus: null,
          starred: false, //pendign
          events: [],
          hasNotes: false,
        },
        endpoints.GRAPHQL_GENERAL
      );

      return {
        ...result?.searchAvailableMedia,
        research,
        currentData,
      };
    } catch (exc) {
      ExceptionManager(exc, route, "incidents");
      return rejectWithValue("There is a problem to get the incidents");
    }
  }
);

export const searchTrailsIncident = createAsyncThunk(
  "incidents/searchTrailsIncident",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const {
      deviceId,
      limit,
      offset,
      startDate,
      endDate,
      includeLinkedData = true,
      research = true,
      currentData = [],
    } = payload;

    try {
      const result = await clientQuery(
        `query searchVehicleTrails ($startDate: AWSDateTime!, $endDate: AWSDateTime!, $deviceId: String!, $limit: Int, $offset: Int, $includeLinkedData: Boolean) {
          searchVehicleTrails (startDate: $startDate, endDate: $endDate, deviceId: $deviceId, limit: $limit, offset: $offset, includeLinkedData: $includeLinkedData) {
            items {
              id
              deviceId
              latitude
              longitude
              heading
              eventCode
              eventName
              address
              formattedAddress
              driverId
              driverName
              ignitionStatus
              satelliteCount
              signal
              ecuSpeed
              speed
              speedLimit
              unitTime
              trail{
                lat
                lon
                timestamp
              }
              landmark {
                id
                companyId
                name
                tags {
                  id
                  label
                  color
                  path
                }
                address
                city
                zip
                state
                country
              }
            }
              total
          }
      }`,
        {
          deviceId,
          limit,
          offset,
          startDate,
          endDate,
          includeLinkedData
        },
        endpoints.GRAPHQL_GENERAL
      );

      return {
        ...result?.searchVehicleTrails,
        research,
        currentData,
      };
    } catch (exc) {
      ExceptionManager(exc, route, "incidents");
      return rejectWithValue("There is a problem to get the incidents");
    }
  }
);

export const validateActiveIncidentByUnit = createAsyncThunk(
  "incidents/validateActiveIncidentByUnit",
  async ({ unitId }, { rejectWithValue, dispatch }) => {
    try {
      const data = await clientQuery(
        `query validateActiveIncidentByUnit ($unitId: Int!) {
                result: validateActiveIncidentByUnit (unitId: $unitId)
                {
                  id
                  unitId
                  name
                  start
                  end
                  shortcode
                  note
                  incidentLog{
                      reportedBy
                      endedBy
                  }
                  reading{
                      unitTime
                      deviceLabel
                      latitude
                      longitude
                  }
                }
          }`,
        {
          unitId
        },
        endpoints.GRAPHQL_GENERAL_V2
      );

      return data.result;
    } catch (exc) {
      ExceptionManager(exc, route, "incident/validateActiveIncidentByUnit");
      return rejectWithValue(exc);
    }
  }
);

export const updateIncidentRedux = createAsyncThunk(
  "incidents/updateIncidentRedux",
  async (payload, { rejectWithValue, dispatch }) => {
    const { id, unitId, name, start, end, shortcode, note, systemAlertId, jsonAlert } = payload;

    try {
      const data = await clientMutation(
        `mutation updateIncident (
          $id: Int!,
          $unitId: Int!,
          $name: String!,
          $start: String!,
          $end: String,
          $shortcode: String,
          $note: String,
          $systemAlertId: String,
          $jsonAlert: String
        ) {
          updateIncident (id: $id, unitId: $unitId, name: $name, start: $start, end: $end, shortcode: $shortcode, note: $note, systemAlertId: $systemAlertId, jsonAlert: $jsonAlert ) {
                    id
                    name
                    start
                    end
                }
          }`,
        {
          id, unitId, name, start, end, shortcode, note, systemAlertId, jsonAlert
        },
        endpoints.GRAPHQL_GENERAL_V2
      );

      return data.updateIncident;
    } catch (exc) {
      ExceptionManager(exc, route, "incident/updateIncidentRedux");
      return rejectWithValue(exc);
    }
  }
);

export const getUnitOverviewRedux = createAsyncThunk(
  "incidents/getUnitOverviewRedux",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const {
      unitId,
      limit,
      offset,
      startDate,
      endDate,
      research,
      currentData
    } = payload;

    try {
      const result = await clientQuery(
        `query getIncidentOverview(
  $startDate: String,
  $endDate: String,
  $unitId: Int!,
  $limit: Int,
  $offset: Int
) {
  getIncidentOverview(
    startDate: $startDate,
    endDate: $endDate,
    unitId: $unitId,
    limit: $limit,
    offset: $offset
  ) {
    items {
      ... on MediaIncident {
        reading {
          id
          deviceId
          heading
          eventCode
          eventName
          driverId
          driverName
          unitTime
          deviceData
          starred
          notes
          speed
          normalizedSpeed
          ecuSpeed
          unitType
          formattedAddress
        }
        unit {
          id
          label
          type
          hid
          esn
          imei
          driverId
          driverName
          notes
          useEcuSpeed
          timeZone {
            id
            name
          }
        }
        linkedDevice {
          id
          esn
          deviceModelId
          deviceModelName
          manufacturerId
          manufacturerName
          hasVideo
        }
        type
        startDate
      }
      ... on ClipRequestIncident {
        id
        accountId
        createdOn
        deviceId
        esn
        label
        status
        time
        tripID
        clipID
        rotate
        url
        startDate
        endDate
        notes
        starred
        errorMsg
        deviceModelId
        deviceModelName
        driverName
        thumbnailUrl
        transactionId
        isDeleted
        createdBy
        unitId
        uploadType
      }
    }
    total
  }
}
`,
        {
          unitId,
          limit,
          offset,
          startDate,
          endDate
        },
        endpoints.GRAPHQL_GENERAL_V2
      );

      return {
        ...result?.getIncidentOverview,
        research,
        currentData,
      };
    } catch (exc) {
      ExceptionManager(exc, route, "incidents");
      return rejectWithValue("There is a problem to get the incidents");
    }
  }
);


//Slice
const incidentsRedux = createSlice({
  name: "incidents",
  initialState: INIT_STATE,
  reducers: {
    reset(state, action) {
      return action.payload
        ? { ...state, ...action.payload }
        : { ...INIT_STATE };
    },
    incidentPanelAction(state, action) {
      return { ...state, openIncidentPanel: action?.payload };
    },
    refreshIncidentsList(state, action) {
      const currentIncidents = state?.incidents;
      const id = action?.payload?.id;
      const newItems = currentIncidents?.filter((item) => item?.id !== id);
      return { ...state, incidents: newItems };
    },
    openOverViewUnit(state, action) {
      return { ...state, isOpenOverViewUnit: action?.payload };
    },
    openHistoryOverViewIncident(state, action) {
      return { ...state, isOpenHistoryOverViewIncident: action?.payload };
    },
    sliceHistoryTrails(state, action) {
      return { ...state, sliceTrails: action?.payload}
    }
  },
  extraReducers: (builder) => {
    //searchIncidents
    builder.addCase(searchIncidents.pending, (state, action) => {
      state.loadingIncidents = true;
      state.errorIncidents = false;
      // state.incidents = [];
    });
    builder.addCase(searchIncidents.fulfilled, (state, action) => {
      state.loadingIncidents = false;
      state.errorIncidents = false;
      state.totalIncidents = action?.payload?.total;
      const research = action?.payload?.research;
      const currentData = action?.payload?.currentData;
      if (!research && currentData.length > 0) {
        const newItems = action?.payload?.items;
        state.incidents = [...currentData, ...newItems];
      } else {
        state.incidents = action?.payload?.items;
      }
    });
    builder.addCase(searchIncidents.rejected, (state, action) => {
      state.loadingIncidents = false;
      state.incidents = action.payload;
      state.errorIncidents = true;
    });
    //incidentGetLastReading
    builder.addCase(incidentGetLastReading.pending, (state, action) => {
      state.loadingLastReading = true;
      state.errorLastReading = false;
      state.lastReading = null;
    });
    builder.addCase(incidentGetLastReading.fulfilled, (state, action) => {
      state.loadingLastReading = false;
      state.errorLastReading = false;
      state.lastReading = action?.payload;
    });
    builder.addCase(incidentGetLastReading.rejected, (state, action) => {
      state.loadingLastReading = false;
      state.lastReading = action.payload;
      state.errorLastReading = true;
    });
    //createIncident
    builder.addCase(createIncident.pending, (state, action) => {
      state.loadingCreateIncident = true;
      state.errorCreateIncident = false;
      state.createIncidentRes = null;
    });
    builder.addCase(createIncident.fulfilled, (state, action) => {
      state.loadingCreateIncident = false;
      state.errorCreateIncident = false;
      state.createIncidentRes = action?.payload;
    });
    builder.addCase(createIncident.rejected, (state, action) => {
      state.loadingCreateIncident = false;
      state.createIncidentRes = action.payload;
      state.errorCreateIncident = true;
    });
    //end Incident
    builder.addCase(endIncidentRedux.pending, (state, action) => {
      state.loadingEndIncident = true;
      state.errorEndIncident = false;
      state.endIncident = null;
    });
    builder.addCase(endIncidentRedux.fulfilled, (state, action) => {
      state.loadingEndIncident = false;
      state.errorEndIncident = false;
      state.endIncident = action?.payload;
    });
    builder.addCase(endIncidentRedux.rejected, (state, action) => {
      state.loadingEndIncident = false;
      state.endIncident = action.payload;
      state.errorEndIncident = true;
    });
    //searchAvailableMediaIncident
    builder.addCase(searchAvailableMediaIncident.pending, (state, action) => {
      state.loadingAvMediaIncident = true;
      state.errorAvMediaIncident = false;
      // state.avMediaIncident = [];
    });
    builder.addCase(searchAvailableMediaIncident.fulfilled, (state, action) => {
      state.loadingAvMediaIncident = false;
      state.errorAvMediaIncident = null;
      state.avMediaIncidentTotal = action?.payload?.total;
      const research = action?.payload?.research;
      const currentData = action?.payload?.currentData;
      if (!research && currentData.length > 0) {
        const newItems = formatAvailableMedia(action?.payload?.items);
        state.avMediaIncident = [...currentData, ...newItems];
      } else {
        state.avMediaIncident = formatAvailableMedia(action?.payload?.items);
      }
    });
    builder.addCase(searchAvailableMediaIncident.rejected, (state, action) => {
      state.loadingAvMediaIncident = false;
      state.avMediaIncident = [];
      state.errorAvMediaIncident = action.payload;
    });

    //searchTrailsIncident
    builder.addCase(searchTrailsIncident.pending, (state, action) => {
      state.loadingTrailsIncident = true;
      state.errorTrailsIncident = false;
      // state.trailsIncident = [];
    });
    builder.addCase(searchTrailsIncident.fulfilled, (state, action) => {
      state.loadingTrailsIncident = false;
      state.errorTrailsIncident = null;
      state.trailsIncidentTotal = action?.payload?.total;
      const research = action?.payload?.research;
      const currentData = action?.payload?.currentData;
      if (!research && currentData.length > 0) {
        const newItems = action?.payload?.items;
        state.trailsIncident = [...currentData, ...newItems];
      } else {
        state.trailsIncident = action?.payload?.items;
      }
    });
    builder.addCase(searchTrailsIncident.rejected, (state, action) => {
      state.loadingTrailsIncident = false;
      state.trailsIncident = [];
      state.errorTrailsIncident = action.payload;
    });
    //validateActiveIncidentByUnit
    builder.addCase(validateActiveIncidentByUnit.pending, (state, action) => {
      state.activeIncidentUnitLoading = true;
      state.activeIncidentUnitError = false;
      state.activeIncidentUnit = {};
    });
    builder.addCase(validateActiveIncidentByUnit.fulfilled, (state, action) => {
      state.activeIncidentUnitLoading = false;
      state.activeIncidentUnitError = false;
      state.activeIncidentUnit = action.payload;
    });
    builder.addCase(validateActiveIncidentByUnit.rejected, (state, action) => {
      state.activeIncidentUnitLoading = false;
      state.activeIncidentUnitError = true;
      state.activeIncidentUnit = action.payload;
    });
    //updateIncidentRedux
    builder.addCase(updateIncidentRedux.pending, (state, action) => {
      state.updateIncidentLoading = true;
      state.updateIncidentError = false;
      state.updateIncident = {};
    });
    builder.addCase(updateIncidentRedux.fulfilled, (state, action) => {
      state.updateIncidentLoading = false;
      state.updateIncidentError = false;
      state.updateIncident = action.payload;
    });
    builder.addCase(updateIncidentRedux.rejected, (state, action) => {
      state.updateIncidentLoading = false;
      state.updateIncidentError = true;
      state.updateIncident = action.payload;
    });


    //getUnitOverviewRedux
    builder.addCase(getUnitOverviewRedux.pending, (state, action) => {
      state.getUnitOverviewLoading = true;
      state.getUnitOverviewError = false;
      // state.unitOverviewItems = [];
    });
    builder.addCase(getUnitOverviewRedux.fulfilled, (state, action) => {
      state.getUnitOverviewLoading = false;
      state.getUnitOverviewError = null;
      state.unitOverviewItemsTotal = action?.payload?.total;
      const research = action?.payload?.research;
      const currentData = action?.payload?.currentData;

      const reverseItems = [...action?.payload?.items]?.reverse()
      if (!research && currentData && currentData.length > 0) {
        const newItems = formatAvailableMedia(reverseItems);
        state.unitOverviewItems = [...currentData, ...newItems];
      } else {
        state.unitOverviewItems = formatAvailableMedia(reverseItems);
      }
    });
    builder.addCase(getUnitOverviewRedux.rejected, (state, action) => {
      state.getUnitOverviewLoading = false;
      state.unitOverviewItems = [];
      state.getUnitOverviewError = action.payload;
    });
  },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = incidentsRedux;

// Extract and export each action creator by name
export const {
  reset,
  incidentPanelAction,
  refreshIncidentsList,
  openOverViewUnit,
  openHistoryOverViewIncident,
  sliceHistoryTrails
} = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;
