// Action Types
import {
  ENTITY_SELECTOR_GET_LANDMARK_GROUPS,
  ENTITY_SELECTOR_GET_LANDMARK_GROUPS_RESULT,
  ENTITY_SELECTOR_SEARCH_LANDMARKS,
  ENTITY_SELECTOR_SEARCH_LANDMARKS_RESULT,
  ENTITY_SELECTOR_SET_TOTAL_PAGINATED_ITEMS,
  ENTITY_SELECTOR_SEARCH_GEOFENCES,
  ENTITY_SELECTOR_SEARCH_GEOFENCES_RESULT,
  ENTITY_SELECTOR_SEARCH_GEOFENCES_GROUP,
  ENTITY_SELECTOR_SEARCH_GEOFENCES_GROUP_RESULT,
  ENTITY_SELECTOR_SEARCH_FORM_GROUP,
  ENTITY_SELECTOR_SEARCH_FORM_GROUP_RESULT,
  ENTITY_SELECTOR_CLEAR_LANDMARKS
} from 'Redux/actionTypes';

const INIT_STATE = {
  loading: false,
  landmarks: [],
  landmarkGroups: [],
  geofences: [],
  geofenceGroups: [],
  totalPaginatedItems: 0,
  formGroups: []
};

const currentLandmarks = (shouldClearLandmarks, data, previousLandmarks) => (
  shouldClearLandmarks ? data : [...previousLandmarks, ...data]
);

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ENTITY_SELECTOR_GET_LANDMARK_GROUPS:
      return { ...state, loading: true };
    case ENTITY_SELECTOR_GET_LANDMARK_GROUPS_RESULT:
      return { ...state, landmarkGroups: action.payload.data, loading: false };
    case ENTITY_SELECTOR_SEARCH_LANDMARKS:
      return { ...state, loading: true };
    case ENTITY_SELECTOR_SEARCH_LANDMARKS_RESULT:
      const { data, openSearch, shouldClearLandmarks } = action.payload;
      return {
        ...state,
        landmarks: openSearch
          ? data
          : currentLandmarks(shouldClearLandmarks, action.payload.data, state.landmarks),
        loading: false,
      };

    case ENTITY_SELECTOR_SET_TOTAL_PAGINATED_ITEMS:
      return { ...state, totalPaginatedItems: action.payload.data };
    
    case ENTITY_SELECTOR_SEARCH_GEOFENCES:
        return { ...state, loading: true };

    case ENTITY_SELECTOR_SEARCH_GEOFENCES_RESULT:
        return { ...state, geofences: action.payload?.data, loading: false };

    case ENTITY_SELECTOR_SEARCH_GEOFENCES_GROUP:
        return { ...state, loading: true };

    case ENTITY_SELECTOR_SEARCH_GEOFENCES_GROUP_RESULT:
      return { ...state, geofenceGroups: action.payload?.data, loading: false };

    case ENTITY_SELECTOR_SEARCH_FORM_GROUP:
      return { ...state, loading: true };
     
    case ENTITY_SELECTOR_SEARCH_FORM_GROUP_RESULT:
      return { ...state, formGroups: action.payload?.data, loading: false };

    case ENTITY_SELECTOR_CLEAR_LANDMARKS:
      return { ...state, loading: false, landmarks: [] };
         
    default:
      return state;
  }
}
