import React from 'react';
import ReactDOM from 'react-dom'
import { Button } from 'Components';
// Styles
import './style.scss';
import { injectIntl } from "react-intl"; 

class InfoWindow extends React.Component {


  
  render() {
    const { open, overlay, close, children, mapInstance } = this.props;
    const {messages} = this.props.intl


    if (!open || !overlay || !overlay.container_) {
      return (
        <React.Fragment></React.Fragment>
      );
    }


    return ReactDOM.createPortal(
      <React.Fragment>
        {
          open
          &&
          <div className="info-window-container"
            onMouseEnter={() => { mapInstance && mapInstance.setOptions({draggable: false}); }}
            onMouseLeave={() => { mapInstance && mapInstance.setOptions({draggable: true}); }}
          >
            <div className="info-window-content">
              <div className="info-window-close-button-container">
                <Button 
                  type="icon" 
                  tooltip={messages['close']}
                  placement="bottom" 
                  icon="close" 
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    if (close) close();
                    overlay.setMap(null);
                  }}
                />
              </div>
              <div>
                {children}
              </div>
            </div>
          </div>
        }
      </React.Fragment>,
      overlay.container_
    );
  }
}

export default injectIntl(InfoWindow);
