// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import { LOGIN_HISTORY_SEARCH } from "Redux/actionTypes";

// Actions
import { searchLoginHistoryResponse } from "Redux/actions";

// Helpers
import { clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* searchLoginHistory({ payload }) {
  const { openSearch, username, dateRange, limit, nextToken, resetSearch } =
    payload;
  const query = `
  query searchLoginHistory ($openSearch: String, $username: String, $dateRange: AWSDateTimeRange!, $limit: Int!, $nextToken: String) {
    searchLoginHistory (openSearch: $openSearch, username: $username, dateRange: $dateRange, limit: $limit, nextToken: $nextToken) {
        items {
          accountId
          applicationId
          username
          createdOn
          ip
          attemptResult
        }
        nextToken
    }
}`;
  const variables = {
    openSearch,
    username,
    dateRange,
    limit,
    nextToken,
  };

  try {
    const data = yield call(
      clientQuery,
      query,
      variables,
      endpoints.GRAPHQL_GENERAL
    );
    if (data) {
      const {
        searchLoginHistory: { items, nextToken },
      } = data;
      yield put(searchLoginHistoryResponse({ items, nextToken, resetSearch, error: false }));
    }
  } catch (exc) {
    yield put(
      searchLoginHistoryResponse({
        items: [],
        error: true,
        nextToken: null,
        resetSearch: true,
      })
    );
    ExceptionManager(
      exc,
      "modules/account/views/loginHistory/redux/sagas",
      "searchLoginHistory"
    );
  }
}

function* watchMethods() {
  yield takeEvery(LOGIN_HISTORY_SEARCH, searchLoginHistory);
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}
