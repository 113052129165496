export default {
    "FSM_SETTINGS_DRIVER_STATUS": "Estado del conductor en linea",
    "FSM_SETTINGS_DRIVER_STATUS_title": "Nuevo estado del conductor",
    "FSM_SETTINGS_DRIVER_STATUS_edit": "Editar estado del conductor ",
    "FSM_SETTINGS_DRIVER_STATUS_delete_detail":'¿Está seguro de que desea eliminar los siguientes estado del conductor?',
    "FSM_SETTINGS_DRIVER_STATUS_delete_warning":'Esta acción no se puede deshacer.',
    "FSM_SETTINGS_DRIVER_STATUS_delete_title":'Eliminar estado del conductor',
    "FSM_SETTINGS_DRIVER_STATUS_create_success": "Estado del conductor creado",
    "FSM_SETTINGS_DRIVER_STATUS_update_success": "Estado del conductor Actualizado",
    "FSM_SETTINGS_DRIVER_STATUS_delete_success": "Estado del conductor Eliminado",

};