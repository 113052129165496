//View Keys
export default {
  realtimemaps_landmark_view: 'VIEW',
  realtimemaps_landmark_create: 'CREATE',
  realtimemaps_landmark_create_select: 'Landmark Type',
  realtimemaps_landmark_create_lat: 'Lat',
  realtimemaps_landmark_create_lng: 'Long',
  realtimemaps_landmark_create_notes: 'Notes',
  realtimemaps_landmark_visible_groups: 'Manage Visible Landmark Groups',
  realtimemaps_landmark_visible_actions: 'ACTIONS',
  realtimemaps_landmark_manage_landmarks: 'Manage Landmarks',
  realtimemaps_landmarks: 'Landmarks',
  realtimemaps_landmarks_create: 'Landmarks Create',
  realtimemaps_geofences_create: 'Geofences Create',
  realtimemaps_landmarks_groups: 'Landmarks Groups',
  realtimemaps_landmarks_groups_create: 'Create Landmarks Group',
  realtimemaps_landmarks_groups_edit: 'Edit Landmarks Group',
  realtimemaps_landmarks_group: 'Landmark Group',
  realtimemaps_landmarks_fromto: 'From To',
  realtimemaps_landmarks_groupcolor: 'Color',
  realtimemaps_landmarks_groupactions: 'Actions',
  realtimemaps_landmarks_groups_select_name: 'Group',
  realtimemaps_landmarks_groups_select_placeholder: 'Filter by Landmark Group',
  realtimemaps_landmarks_groups_update_placeholder: 'Change Landmark Group',
  realtimemaps_landmarks_groups_delete_question: 'Are you sure you want to delete this landmark group?',
  realtimemaps_landmarks_groups_delete_questionsub: 'Delete actions cannot be undone.',
  realtimemaps_landmarks_groups_delete_action: 'Available Actions',
  realtimemaps_landmarks_groups_delete_list: 'Delete (All landmarks in this group is removed)',
  realtimemaps_landmarks_groups_delete_yes: 'Yes, Delete',
  realtimemaps_landmarks_groups_delete_cancel: 'No, Cancel',
  realtimemaps_landmarks_edit_modal:'Edit Landmark',
  realtimemaps_landmarks_groups_edit_modal:'Bulk Edit Landmarks',
  realtimemaps_landmarks_groups_delete_modal:'Delete Landmark Groups',
  realtimemaps_landmarks_bulk_delete:'Bulk Delete Landmarks',
  realtimemaps_landmarks_delete:'Delete Landmark',
  realtimemaps_landmarks_bulk_delete_detail:'Are you sure you want to delete {number} landmarks?', //Replace number value
  realtimemaps_landmarks_bulk_delete_sdetail:'This action cannot be undone.',
  realtimemaps_landmarks_groups_delete_title: 'What do you want to do with landmarks in those landmark groups?',
  realtimemaps_landmarks_groups_delete_move: 'Move to',
  realtimemaps_landmarks_grid_title: 'Title',
  realtimemaps_landmarks_grid_address: 'Address',
  realtimemaps_landmarks_grid_latlng: 'Lat / Long',
  realtimemaps_landmarks_grid_radius: 'Radius',
  realtimemaps_landmarks_grid_groups: 'Tag',
  realtimemaps_landmarks_grid_notes: 'Notes',
  realtimemaps_landmarks_grid_export: 'Export',
  realtimemaps_landmarks_groups_new_modal: 'New Landmark Group',
  realtimemaps_landmarks_circle: 'Circle',
  realtimemaps_landmarks_polygon: 'Polygon',
  realtimemaps_landmarks_groups_delete: 'Delete (All landmarks in those groups are removed)',
  realtimemaps_landmarks_groups_bulk_delete: 'Bulk Delete Landmark Groups',
  realtimemaps_landmarks_groups_delete_success: 'Landmark groups deleted successfully'
};