//imports
import {
	FLEET_LANDMARK_CREATE_UPDATE_LANDMARK,
	FLEET_LANDMARK_CREATE_UPDATE_LANDMARK_RESULT,
	FLEET_LANDMARK_OPEN_MODAL
} from 'Redux/actionTypes';

/* ACTIONS */

export const openFleetLandmarkModal = (open, vehicle, landmark) => ({
    type: FLEET_LANDMARK_OPEN_MODAL,
    payload: { open, vehicle, landmark }
});

export const createEditFleetLandmark = (data, refresh) => ({
	type: FLEET_LANDMARK_CREATE_UPDATE_LANDMARK,
	payload: { data, refresh }
});

export const createEditFleetLandmarkResponse = (response) => ({
	type: FLEET_LANDMARK_CREATE_UPDATE_LANDMARK_RESULT,
	payload: { response },
});