import {
  FLEET_MODAL_BULK_OUTPUTS_OPEN,
  FLEET_MODAL_BULK_OUTPUTS_SEND_COMMAND,
	FLEET_MODAL_BULK_OUTPUTS_SEND_COMMAND_RESULT,
  FLEET_MODAL_BULK_OUTPUTS_GET_OUTPUTS,
	FLEET_MODAL_BULK_OUTPUTS_GET_OUTPUTS_RESULT,
  CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
  open: false,
  loading: false,
  response: null,
  units: [],
  outputs: [],
  loadingOutputs: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case FLEET_MODAL_BULK_OUTPUTS_OPEN:
      return { ...state, open: action.payload.open, loading: false, units: action.payload.units, response: null };

    case FLEET_MODAL_BULK_OUTPUTS_SEND_COMMAND:
      return { ...state, loading: true, response: null };

    case FLEET_MODAL_BULK_OUTPUTS_SEND_COMMAND_RESULT:
      return { ...state, loading: false, response: action.payload.response };

    case FLEET_MODAL_BULK_OUTPUTS_GET_OUTPUTS:
      return { ...state, loadingOutputs: true, outputs: [] };

    case FLEET_MODAL_BULK_OUTPUTS_GET_OUTPUTS_RESULT:
      return { ...state, loadingOutputs: false, outputs: action.payload.response };

    case CLEAR_REDUX:
      return ["", "FLEET_MODAL_BULK_OUTPUTS"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
};
