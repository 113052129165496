import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  clientMutation,
  clientQuery,
} from "../../../../../core/data/GraphQLApi";
import { endpoints } from "../../../../../core/defaultValues";
import { ExceptionManager } from "../../../../../core/logManager";
import moment from 'moment';

//STATE INITIAL
const INIT_STATE = {
  loading: false,
  webHooks: [],
  webHookLogs:[],
  open:false,
  openDelete:false,
  openLog:false,
  webhook:null,
  responseWebHooks:null,
  responseDeleteWebHooks:null,
  webHookDelete:null,
  webHookLogSelected:null
};

const route ="account/views/webHooks/redux/slice";

export const accountSearchWebHooks = createAsyncThunk("account/webHooks/accountSearchWebHooks",
  async ({ serviceId, unitId }, { rejectWithValue, dispatch }) => {
    try {
      let query = `
              query searchWebhooks ($conditions: [WebhookSearchInput], $sort: [WebhookSortInput], $limit: Int, $offset: Int) {
                searchWebhooks (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                    items {
                        id
                        companyId
                        name
                        url
                        catalog
                        headers
                        status
                        webhookLog{
                          error
                          occurrences
                          lastMessageError
                        }
                        createdBy
                        createdOn
                        updatedBy
                        updatedOn
                    }
                    total
                }
            }
        `;
      const response = await clientQuery(
        query,
        {},
        endpoints.GRAPHQL_GENERAL_V2
      );
      
      return response?.searchWebhooks || [];
    } catch (exc) {
      ExceptionManager(
        exc,
        route,
        "accountSearchWebHooks"
      );
      return rejectWithValue(exc);
    }
  }
);

export const accountSearchWebHooksLog = createAsyncThunk("account/webHooks/accountSearchWebHooksLog",
  async ({ webhookId,limit,offset,startDate,endDate  }, { rejectWithValue, dispatch }) => {
    try {
      let query = `
              query searchWebhookLog ($conditions: [WebhookLogSearchInput], $sort: [WebhookLogInput], $limit: Int, $offset: Int) {
                searchWebhookLog (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                    items {
                        error
                        occurrences
                        lastMessageError
                        date
                    }
                    total
                }
            }
        `;

   
      const response = await clientQuery(
        query,
        {
          conditions: [
            {
              "fields": ["webhookId"],
              "terms":[webhookId]
            },
            {
              "range":{
                    "fields": "date",
                    "GTE": `${moment(startDate).format("YYYY-MM-DD")}T00:00:00Z`,
                    "LTE": `${moment(endDate).format("YYYY-MM-DD")}T23:59:59Z`
              }
          }],
         limit: 100,
         offset: 0
        },
        endpoints.GRAPHQL_GENERAL_V2
      );
      
      return response?.searchWebhookLog || [];
    } catch (exc) {
      ExceptionManager(
        exc,
        route,
        "accountSearchWebHooksLog"
      );
      return rejectWithValue(exc);
    }
  }
);

export const accountWebHooksCreateUpdate = createAsyncThunk("account/webHooks/accountWebHooksCreateUpdate", async (payload, { rejectWithValue, dispatch }) => {
  try {

      let response = await clientMutation(
          `
          mutation(
            ${payload?.id ? '$id: Int!' : ''}
            $name: String!, $url: String!, $catalog: String!, $headers: String, $status: Int!
          ) {
            data:${payload?.id ? 'updateWebhook' : 'createWebhook'} (
              ${payload?.id ? 'id: $id,' : ''}
              name: $name, url: $url, catalog: $catalog, headers: $headers, status: $status
            ) {
              id
              companyId
              name
              url
              catalog
              headers
              status
              createdBy
              createdOn
              updatedBy
              updatedOn
            }
          }
          `,
          {
            ...payload,
            headers:payload?.headers?JSON.stringify(payload?.headers):null
          },
          endpoints.GRAPHQL_GENERAL_V2
      );

      return response.data

  } catch (exc) {
      // ALWAYS RETURN ACTION
      ExceptionManager(exc, route, 'accountWebHooksCreateUpdate');
      return {error:exc?.errorData?.errors[0]?.message}
  }
})

export const accountWebHooksDelete = createAsyncThunk('dynamicForms/modals/delete/accountWebHooksDelete', async ({ ids }, { rejectWithValue, dispatch }) => {
  try {
      let query = `
          mutation deleteWebhooks ($ids: [Int]!) {
            deleted: deleteWebhooks (ids: $ids)
        }
      `;

      const response = await clientMutation(
          query,
          {
              ids
          },
          endpoints.GRAPHQL_GENERAL_V2
      );

      if (!response?.deleted) {
          throw 'Error while deleting Webhooks';
      }

      return response?.deleted;
  } catch (exc) {
      ExceptionManager(exc, route, 'accountWebHooksDelete');
      return rejectWithValue(exc)
  }
});


const accountWebHooksRedux = createSlice({
  name: "account/webHook",
  initialState: INIT_STATE,
  reducers: {
    reset(state, action) {
      state.open = false
      state.openDelete = false
      state.openLog = false
      state.loading = false
      state.webhook = null
      state.responseWebHooks = null
      state.responseDeleteWebHooks = null
      state.webHookLogSelected = null
      
    },
    openModalCreateEditWebhook: (state, { payload }) => {
      state.open = payload.open
      state.webhook = payload?.data
    },
    openModalDeleteWebhook: (state, { payload }) => {
      state.openDelete = payload.open
      state.open = false
      state.responseDeleteWebHooks = null
      state.webHookDelete = payload?.data
    },
    openModalLogWebhook: (state, { payload }) => {
      state.openLog = payload.open
      state.webHookLogSelected = payload?.data
      state.open = false
      state.openDelete = false
    }
    
  },

  extraReducers: (builder) => {
    //builder.addCase(resetALL, () => INIT_STATE);

    //accountSearchWebHooks
    builder.addCase(
      accountSearchWebHooks.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      accountSearchWebHooks.fulfilled,
      (state, action) => {
        state.loading = false;
        state.webHooks = action.payload;
      }
    );
    builder.addCase(
      accountSearchWebHooks.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );

    //accountSearchWebHooksLog
    builder.addCase(
      accountSearchWebHooksLog.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      accountSearchWebHooksLog.fulfilled,
      (state, action) => {
        state.loading = false;
        state.webHookLogs = action.payload;
      }
    );
    builder.addCase(
      accountSearchWebHooksLog.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
    //accountWebHooksCreateUpdate
    builder.addCase(
      accountWebHooksCreateUpdate.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      accountWebHooksCreateUpdate.fulfilled,
      (state, action) => {
        state.loading = false;
        state.responseWebHooks = action.payload;
      }
    );
    builder.addCase(
      accountWebHooksCreateUpdate.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );

    //accountWebHooksDelete
    builder.addCase(
      accountWebHooksDelete.pending,
      (state, action) => {
        state.loading = true;        
        state.responseDeleteWebHooks = null;

      }
    );
    builder.addCase(
      accountWebHooksDelete.fulfilled,
      (state, action) => {
        state.loading = false;
        state.responseDeleteWebHooks = action.payload;
      }
    );
    builder.addCase(
      accountWebHooksDelete.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );

    


  },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = accountWebHooksRedux;

// Extract and export each action creator by name
export const { reset,openModalCreateEditWebhook,openModalDeleteWebhook,openModalLogWebhook } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;
