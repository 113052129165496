//imports
import { all } from 'redux-saga/effects';

//Views
import fleetMainSagas from 'Modules/fleet/views/main/redux/saga';
import vehicleScheduleStarterSagas from 'Modules/fleet/components/ModalScheduleStarter/redux/saga';
import vehicleEnableDisableSagas from 'Modules/fleet/components/ModalEnableDisable/redux/saga';
import vehiclePingSagas from 'Modules/fleet/components/ModalPing/redux/saga';
import vehicleLandMarkSaga from 'Modules/fleet/components/ModalLandMark/redux/saga';
import vehicleModalOutputsSaga from 'Modules/fleet/components/ModalOutputs/redux/saga';
import fleetModalBulkOutputsSaga from 'Modules/fleet/components/ModalBulkOutputs/redux/saga';

//merge sagas
export default function* rootSaga() {
	yield all([
		fleetMainSagas(), 
		vehicleScheduleStarterSagas(), 
		vehicleEnableDisableSagas(), 
		vehiclePingSagas(), 
		vehicleLandMarkSaga(), 
		vehicleModalOutputsSaga(),
		fleetModalBulkOutputsSaga()
	]);
}
