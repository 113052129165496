import React, { useEffect, useImperativeHandle, forwardRef, useState } from "react";
import axios from "axios";
import {
  AutoSizer,
  InfiniteLoader,
  List,
  WindowScroller,
  CellMeasurerCache,
  CellMeasurer
} from "react-virtualized";

import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material'

import cx from 'classnames';

import "./styles.scss";
import { Loading, LinearProgress } from 'Components'

const VirtualizedTable = ({renderItems, data = [], initialLoadingMessage, isNextPageLoading, handleNewPageLoad, total = 0, containerClass, noDataMessage, showLoadingComponent, containerRef, resetCache, renderHeader, onStartIndex = () => {}, isActive= true, scrollEvent = () => {}}) => {

  const container = containerRef || React.useRef();
  const [manualCache, setManualCache] = useState();
  const [count, setCount] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    setManualCache(new CellMeasurerCache({
        keyMapper: (rowIndex, columnIndex) => rowIndex,
        defaultHeight: 100,
        fixedWidth: true,
    }));
  }, [data?.length]);

  const cache = React.useRef(
    new CellMeasurerCache({
      keyMapper: (rowIndex, columnIndex) => rowIndex,
      fixedWidth: true,
      defaultHeight: 100,
    })
  );

  const handleResize = () => {
    //cache.current.clearAll();
  }

  useEffect(() => {
    if(resetCache > 0) {
      cache.current = manualCache;
      setCount(count + 1)
    }
  }, [resetCache]);

  useEffect(() => {

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
    parent
  }) => {
    return (
      <CellMeasurer
        key={key}
        cache={cache.current}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
        idx={index}
      >
        <>
          {renderItems({key, index, style, content: data[index], id: index, previousItem: data[index -1]})}
        </>

      </CellMeasurer>
    );
  };

  function isRowLoaded({ index }) {
    return !!data[index];
  }

  const loadingReport = () => {
    return (
      <Grid container className="loading-virtualized-table">
        <Grid item xs>
          <Typography component="div" key={"h3-1"} variant={"h3"}>
            <Skeleton />
          </Typography>
          <Typography component="div" key={"h3-2"} variant={"h3"}>
            <Skeleton />
          </Typography>
          <Typography component="div" key={"h3-3"} variant={"h4"}>
            <Skeleton />
          </Typography>
        </Grid>
      </Grid>
    )
  }
  const loadMoreRows = isNextPageLoading ? () => {} : handleNewPageLoad;

  const handleScroll = ({ scrollTop }) => {
    scrollEvent(scrollTop)
    setScrollTop(scrollTop)
  }

  if (!data.length && isNextPageLoading) {
    return (
      <div style={{marginTop: "20px"}}>
        <span>{initialLoadingMessage}</span>
        {showLoadingComponent ? (
          <div style={{width: "100%", position: "relative", minHeight: "50px", textAlign: "center"}}>
            <Loading />
          </div>
        ): <LinearProgress color="primary"/>}
      </div>
    )
  }

  if (!data.length) return <div style={{marginTop: "20px"}}><span>{noDataMessage}</span></div>;

  if (!isActive) return <></>

  return (
    <div className={cx("container-virtualized", containerClass)} ref={container}>
      <div className="repositoriesWrapper" onScroll={handleScroll}>
        {renderHeader && renderHeader()}
        <AutoSizer disableHeight={true}>
          {({ width }) => (
              <>
                <InfiniteLoader
                  isRowLoaded={isRowLoaded}
                  loadMoreRows={loadMoreRows}
                  rowCount={total} // 1000
                >
                  {({ onRowsRendered, registerChild }) => (
                    <>
                      <List
                        onRowsRendered={(_ref) => {
                            var startIndex = _ref.startIndex;
                            onStartIndex(startIndex)
                            onRowsRendered(_ref);
                        }}
                        ref={registerChild}
                        height={500}
                        rowCount={data.length}
                        rowHeight={cache.current.rowHeight}
                        deferredMeasurementCache={cache.current}
                        rowRenderer={rowRenderer}
                        width={width}
                        onScroll={handleScroll}
                        scrollTop={scrollTop}
                      />
                    </>

                  )}
                </InfiniteLoader>
              </>
          )}
        </AutoSizer>
        {isNextPageLoading && loadingReport()}
      </div>
    </div>
  );
}

export default VirtualizedTable;
