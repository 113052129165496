//View Keys
export default {
  legacyApiKey_title: 'Legacy Api Key',
  legacyApiKey_username: "User",
  legacyApiKey_ipAdresses: "Ip Addresses",
  legacyApiKey_key: "Key",
  legacyApiKey_createLegacyApiKey: "New Legacy Api Key",
  legacyApiKey_editLegacyApiKey: "Edit Legacy Api Key",
  legacyApiKey_deleteLegacyApiKey: "Delete Legacy Api Key",
  legacyApiKey_cancel: "Cancel",
  legacyApiKey_createButton: "Save",
  legacyApiKey_editButton: "Save",
  legacyApiKey_delete: "Delete",
  legacyApiKey_messageDelete: "Are you sure you want to delete the api key inherited from",
  legacyApiKey_messageIpAddress: "Required valid IP address",
  legacyApiKey_createLegacy: "Legacy Api Key Created",
  legacyApiKey_editLegacy: "Legacy Api Key Updated",
  legacyApiKey_deleteLegacy: "Legacy Api Key Deleted",
  legacyApiKey_select: "Select",
  legacyApiKey_maxLengthMessageIpAddress: "The IP Address must contain a maximum of characters:",
  legacyApiKey_addButtonInputIpAddress: "Add new IP address",
  legacyApiKey_deleteButtonInputIpAddress: "Delete IP address"
};
