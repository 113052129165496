// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    MAP_GEOFENCES_ASSIGN_GET_UNIT_GEOFENCE,
    MAP_GEOFENCES_ASSIGN_SAVE
} from "Redux/actionTypes";

// Actions
import {
    mapGeofencesAssignGetUnitGeofenceResponse,
    mapGeofencesAssignSaveResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* mapGeofencesAssignGetUnitGeofenceRequest({ payload }) {
    try {
        const { id, type } = payload;

        let query = `
            query getUnitGeofence ($id: Int!) {
                unitGeofence: getUnitGeofence (id: $id) {
                    unitId
                    name
                    unitVersion
                    geofencesLimit
                    pointsLimit
                    polygon
                    square
                    circle
                    countGeofence
                    geofences {
                        id
                        name
                        radius
                        alertOnType
                        color
                        shape
                    }
                }
            }
        `;

        if (type === "geofence") {
            query = `
                query getGeofenceUnit ($id: Int!) {
                    unitGeofence: getGeofenceUnit (id: $id) {
                        geofenceId
                        name
                        radius
                        shape
                        coordinates {
                            latitude
                            longitude
                        }
                        units {
                            id
                            name
                            geofencesLimit
                            pointsLimit
                            polygon
                            square
                            circle
                        }
                    }
                }
            `;
        }

        const response = yield call(
            clientQuery,
            query,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );

        if (!response && !response.unitGeofence) throw `Error while getting unit geofence: ${id}`;

        yield put(mapGeofencesAssignGetUnitGeofenceResponse({ ...response.unitGeofence, error: false }));
    } catch (exc) {
        yield put(mapGeofencesAssignGetUnitGeofenceResponse({ error: true }));
        ExceptionManager(
            exc,
            "modules/realtimeMaps/main/components/geofences/components/geofenceAssign/redux/saga",
            "mapGeofencesAssignGetUnitGeofenceRequest"
        );
    }
}

function* mapGeofencesAssignSaveRequest({ payload }) {
    try {
        const { unitGeofence, type } = payload;
        let query = `
            mutation createUnitGeofence (
                $unitId: Int,
                $geofenceIds: [Int]
            ) {
                unitGeofence: createUnitGeofence (
                    unitId: $unitId,
                    geofenceIds: $geofenceIds
                ) {
                    unitId
                    name
                    unitVersion
                    geofencesLimit
                    pointsLimit
                    polygon
                    square
                    circle
                    countGeofence
                    geofences {
                        id
                        name
                        radius
                        alertOnType
                        color
                        shape
                    }
                }
            } 
        `;

        if (type === "geofence") {
            query = `
                mutation createGeofenceUnit (
                    $geofenceId: Int,
                    $unitIds: [Int]
                ) {
                    unitGeofence: createGeofenceUnit (
                        geofenceId: $geofenceId,
                        unitIds: $unitIds
                    ) {
                        geofenceId
                        name
                        radius
                        units {
                            id
                            name
                            geofencesLimit
                            pointsLimit
                            polygon
                            square
                            circle
                        }
                    }
                } 
            `;
        }

        const response = yield call(
            clientMutation,
            query,
            unitGeofence,
            endpoints.GRAPHQL_GENERAL,
        );

        if (!response || !response.unitGeofence) throw `Error while creating unit geofence: ${unitGeofence}`;
        yield put(mapGeofencesAssignSaveResponse({ ...response.unitGeofence, error: false }));
    } catch (exc) {
        let message = null
        if (exc.errorData && exc.errorData.errors) {
            exc.errorData.errors.forEach(error => {
                if (error.errorInfo && error.errorInfo.code) {
                    message = error.message
                }
            });
        }

        yield put(mapGeofencesAssignSaveResponse({ error: true, message: message }));
    }
}

function* watchMethods() {
    yield takeEvery(MAP_GEOFENCES_ASSIGN_GET_UNIT_GEOFENCE, mapGeofencesAssignGetUnitGeofenceRequest);
    yield takeEvery(MAP_GEOFENCES_ASSIGN_SAVE, mapGeofencesAssignSaveRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}