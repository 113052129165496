//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import {
  errorCodeHandler
} from 'Core/data/Helpers';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import { NotificationManager } from 'Components/Notifications';

//Action Types
import {
  REPORTS_MAIN_SAVE_SCHEDULE_REPORT,
  REPORTS_MAIN_GET_SCHEDULE_REPORT,
  REPORTS_MAIN_UPDATE_SCHEDULE_REPORT,
  REPORTS_MAIN_UPDATE_SUBSCRIBERS,
  REPORTS_MAIN_FTP_LIST,
  REPORTS_MAIN_FTP_UPDATE,
  REPORTS_MAIN_FTP_DELETE
} from 'Redux/actionTypes';

//Actions
import { saveScheduleReportResult, getScheduleResult, updateScheduleResult, updateSubscribersResult,getListFtpResult,updateFtpResult,deleteFtpResult} from 'Redux/actions';

//Function
import {
  getTimeZone
} from 'Modules/reports/views/main/components/reportsTab/helper';

import {
  getIntegerRange
} from 'Modules/reports/views/main/components/scheduleTab/.components/reportSetup/helper';
import { isFilterBaseView } from '../../../../reportsTab/.components/reports/landmarkBasedReport/sagaFunction';
import { getEventName } from '../../../../reportsTab/.components/reports/event/sagaFunction';

const reportPingStringValues = [
  'Ping',
  'Locate'
];

function* updateSubscribers({ payload }){
  try {
    const { updateAlerts, deleteAlerts } = payload;
    if (updateAlerts && updateAlerts.length > 0) {
      for (let alert of updateAlerts) {
        yield call(
          clientMutation,
          ` 
              mutation 
              {
                UpdateSubscriber: ${alert.id ? 'updateAlertSubscriber' : 'createAlertSubscriber'} (
                  ${alert.id ? 'id: ' + alert.id : ""}
                  ${!alert.id ? 'entityName: ' + '' + alert.entityName + ',' : ""}
                  ${!alert.id ? 'entityId: ' + '"' + alert.entityId + '",' : ""} 
                  contactId: ${alert.contactId}, 
                  alert: ${!!alert.alert}, 
                  alertBefore: ${!!alert.alertBefore}, 
                  alertAfter: ${!!alert.alertAfter} ) {
                  id
                }
              }
          `,
          {},
          endpoints.GRAPHQL_GENERAL
        );
      }
    }

    if (deleteAlerts && deleteAlerts.length > 0) {
      for (let alert of deleteAlerts) {
        yield call(
          clientMutation,
          `mutation {
            DeleteSubscriber: deleteSubscribers (id: "${alert.id}")
          }`,
          {},
          endpoints.GRAPHQL_PX_REPORTING
        );
      }
    }

    yield put(updateSubscribersResult(true));

  } catch (exc) {
    // ALWAYS RETURN ACTION
    yield put(updateSubscribersResult());
    ExceptionManager(exc, 'modules/reports/views/main/components/scheduleTab/redux/saga', 'updateSubscribers');
  }
}

const getCustomFilterValue = (baseView, singleValue, tagsValue, type) => {
  const valueEntityFilter = isFilterBaseView(baseView) ? [{
    id: singleValue,
    type: type
  }] : Array.isArray(tagsValue)
          ? tagsValue.map(tag =>({id: tag?.id, type: "Tag"}))
          : {id: tagsValue?.id, type: "Tag"};
  return valueEntityFilter;
}

const getStringValueReportType = (otherFilters) => {
  const getFilter = getEventName(otherFilters?.event);


  const stringValueReportType = {
    pingReport: reportPingStringValues,
    eventReport: getFilter,
    driverFormsReport: otherFilters?.form,
    driverFormsReportV2: otherFilters?.form,
    formStopSummaryReport: otherFilters?.form,
    formStopSummaryReportV2: otherFilters?.form,
    behaviorReport: otherFilters?.risk
  }
  return stringValueReportType;
}

function* saveSchedulesRequest({ payload }) {
  const {data} = payload;
  const {timeZone, filterEntity, reportType, filterType, filterArray, otherFilters,  exportType, name, categoryName, timeframeName, frequency, selectedDays, deliveryOn, deliveryAt, messages,ftpHost,scheduleTimeRanges, includeInactiveDrivers} = data;

  const stringValueReportType = getStringValueReportType(otherFilters);

  const UIParams = {
    id: uuidv4(),
    type: reportType,
    loading: true,
    data: [],
    timeZone,
    filters: { filterEntity, filterType, filterArray, startRange: '', endRange: '', otherFilters, includeInactiveDrivers },
    export: false,
    exportType,
    asyncLoading: true,
    loadSync: false,
    ftpHost:ftpHost,
    name,
    categoryName,
    timeframeName: "Custom",
    delayed: true,

  };
  const stringValues = getStringValues(stringValueReportType[reportType], reportType)

  const filterValues = getFilterValue(otherFilters)

  try {
    let data = yield call(
      clientMutation,
      `mutation createScheduleReport ($reportType: enumReportType!, $category: ReportCategoryInput!, $dateRange: DateRangeInput!, $integerRanges: [IntegerRangeInput], $timeZone: String!, $name: String!, $frequency: enumFrequencySchedule!, $weekDays: [enumWeekDay], $includeWeekend: Boolean, $monthDays: [Int], $deliveryAt: String!, $deliveryOn: [String], $scheduleExpires: AWSDateTime, $format: enumFileFormat!, $UIParams: AWSJSON, $stringValues: [StringValueInput], $filterValues: [FilterValuesInput],$ftpHost: String,$scheduleTimeRanges: [scheduleTimeRangeInput]) {
        createScheduleReport (reportType: $reportType, category: $category, dateRange: $dateRange, integerRanges: $integerRanges, timeZone: $timeZone, name: $name, frequency: $frequency, weekDays: $weekDays, includeWeekend: $includeWeekend, monthDays: $monthDays, deliveryAt: $deliveryAt, deliveryOn: $deliveryOn, scheduleExpires: $scheduleExpires, format: $format, UIParams: $UIParams,
          stringValues: $stringValues, filterValues: $filterValues, ftpHost: $ftpHost, scheduleTimeRanges: $scheduleTimeRanges) {
            id
            name
            reportType
            category {
                field
                value
                categoryName
                dataResultFilter
            }
            integerRanges {
                fields
                GTE
                LTE
            }
            dateRange {
                fields
                GTE
                LTE
                timeframeName
            }
            timeZone
            frequency
            weekDays
            includeWeekend
            monthDays
            deliveryAt
            scheduleExpires
            subscribers {
                id
                contactId
                contact {
                    id
                    title
                    address
                    type
                }
                alert
                alertBefore
                alertAfter
                createdOn
                createdBy
                updatedOn
                updatedBy
            }
            format
            active
            nextExecution
            ftpHost
            createdOn
            createdBy
            updatedOn
            updatedBy
        }
      }`,
      {
        "reportType": reportType,
        "integerRanges": getIntegerRange(reportType, otherFilters),
        "category": {
          field: filterType,
          entities: filterArray,
          categoryName: categoryName,
          dataResultFilter: false,
          includeInactiveDrivers: includeInactiveDrivers
        },
        "dateRange": {
          fields: [
            "unitTime"
          ]
        },
        "timeZone": getTimeZone(timeZone),
        "name": name,
        "frequency": frequency,
        "weekDays": selectedDays,
        "includeWeekend": true,
        "monthDays": [
          0
        ],
        "deliveryAt": deliveryAt,
        "deliveryOn": deliveryOn,
        "format": exportType,
        "ftpHost": ftpHost,
        "filterValues": filterValues[reportType],
        "stringValues": stringValues,
        "UIParams": JSON.stringify(UIParams),
        "scheduleTimeRanges": scheduleTimeRanges
      },
      endpoints.GRAPHQL_PX_REPORTING
    );

    if (data.createScheduleReport){
      yield put(saveScheduleReportResult(data.createScheduleReport));
    }
    else throw 'Not Schedule';
  } catch (exc) {

    // ALWAYS RETURN ACTION
    //ExceptionManager(exc, 'modules/reports/views/main/components/scheduleTab/redux/saga.js', 'saveScheduleReportResult');
    const message = exc?.errorData?.errors[0]?.errorInfo?.code;
    NotificationManager.error(messages[errorCodeHandler(message)] || `Error Saving Scheduled`, 'Error' , 3000, null, null, 'error');
    yield put(saveScheduleReportResult(null));
  }
}
const getStringValues = (values, reportType)=>{

    if (reportType === 'driverFormsReportV2' && values === 1){
        return []
    }

    return values ? {
        values: values
    }: null;
}
const getFilterValue = (otherFilters) => {
  const valueEntityFilter = getCustomFilterValue(otherFilters.landmarkBaseView, otherFilters.landmark, otherFilters.landmarkTags, "Landmark")
  const valueEntityGeofenceFilter = getCustomFilterValue(otherFilters.geofenceBaseView, otherFilters.geofence, otherFilters.geofenceTags, "Geofence")
  const filterValues = {
    landmarkBasedReport: {
      field: isFilterBaseView(otherFilters.landmarkBaseView) ? "Landmark" : "Tag",
      entities: otherFilters.landmarkBaseView == "all"? null: valueEntityFilter,
    },
    geofenceBasedReport: {
      field: isFilterBaseView(otherFilters.geofenceBaseView) ? "Geofence" : "Tag",
      entities: otherFilters.geofenceBaseView == "all"? null: valueEntityGeofenceFilter,
    },
    stopReport: {
      field: "excludeIdle",
      booleanValue: otherFilters.excludeIdle,
    }
  }
  return filterValues;
}

function* updateSchedulesRequest({ payload }) {
  const {data} = payload;
  const {timeZone, filterEntity, reportType, filterType, filterArray, otherFilters,  exportType, name, categoryName, timeframeName, frequency, selectedDays, deliveryOn, deliveryAt, id, messages,ftpHost,scheduleTimeRanges, includeInactiveDrivers} = data;

  const stringValueReportType = getStringValueReportType(otherFilters);

  const filterValues =  getFilterValue(otherFilters)

  const UIParams = {
    id: uuidv4(),
    type: reportType,
    loading: true,
    data: [],
    timeZone,
    filters: { filterEntity, filterType, filterArray, startRange: '', endRange: '', otherFilters, includeInactiveDrivers },
    export: false,
    exportType,
    asyncLoading: true,
    loadSync: false,
    name,
    categoryName,
    ftpHost:ftpHost,
    timeframeName: "Custom",
    delayed: true
  };

    const stringValues = getStringValues(stringValueReportType[reportType], reportType)

  try {
    let data = yield call(
      clientMutation,
      `mutation updateScheduleReport ($id: String!, $reportType: enumReportType!, $category: ReportCategoryInput!, $dateRange: DateRangeInput!, $integerRanges: [IntegerRangeInput], $timeZone: String!, $name: String!, $frequency: enumFrequencySchedule!, $weekDays: [enumWeekDay], $includeWeekend: Boolean, $monthDays: [Int], $deliveryAt: String!, $scheduleExpires: AWSDateTime, $deliveryOn: [String], $format: enumFileFormat!, $UIParams: AWSJSON, $stringValues: [StringValueInput], $filterValues: [FilterValuesInput],$ftpHost: String, $scheduleTimeRanges: [scheduleTimeRangeInput]) {
        updateScheduleReport (id: $id, reportType: $reportType, category: $category, dateRange: $dateRange, integerRanges: $integerRanges, timeZone: $timeZone, name: $name, frequency: $frequency, weekDays: $weekDays, includeWeekend: $includeWeekend, monthDays: $monthDays, deliveryAt: $deliveryAt, scheduleExpires: $scheduleExpires, deliveryOn: $deliveryOn, format: $format, UIParams: $UIParams,
          stringValues: $stringValues, filterValues: $filterValues,ftpHost: $ftpHost,scheduleTimeRanges: $scheduleTimeRanges)
    }`,
      {
        "id": id,
        "reportType": reportType,
        "integerRanges": getIntegerRange(reportType, otherFilters),
        "category": {
          field: filterType,
          entities: filterArray,
          categoryName: categoryName,
          dataResultFilter: false,
          includeInactiveDrivers: includeInactiveDrivers
        },
        "dateRange": {
          fields: [
            "unitTime"
          ]
        },
        "timeZone": getTimeZone(timeZone),
        "name": name,
        "frequency": frequency,
        "weekDays": selectedDays,
        "includeWeekend": true,
        "monthDays": [
          0
        ],
        "ftpHost": ftpHost,
        "deliveryAt": deliveryAt,
        "deliveryOn": deliveryOn,
        "format": exportType,
        "filterValues": filterValues[reportType],
        "stringValues": stringValues,
        "UIParams": JSON.stringify(UIParams),
        "scheduleTimeRanges": scheduleTimeRanges
      },
      endpoints.GRAPHQL_PX_REPORTING
    );
    if (data.updateScheduleReport){
      yield put(updateScheduleResult(data.updateScheduleReport));
    }
    else throw 'Not Schedule';
  } catch (exc) {
    // ALWAYS RETURN ACTION
    NotificationManager.error(messages[errorCodeHandler(exc?.errorData?.errors[0]?.errorInfo?.code)] || `Error Updating Scheduled`, 'Error' , 3000, null, null, 'error');
    yield put(updateScheduleResult(null));
  }
}

function* getSchedule({ payload }){
  const {data} = payload;
  try {
    let schedule = yield call(
      clientQuery,
      `query(
        $id: String!
      ) {
        Schedule: getSchedule(
          id: $id,
        ) {
          id
          name
          reportType
          category {
              field
              value
              categoryName
              dataResultFilter
              entities {
                id
                type
                label
                parentId
              }
          }
          integerRanges {
              fields
              GTE
              LTE
          }
          dateRange {
              fields
              GTE
              LTE
              timeframeName
          }
          timeZone
          frequency
          weekDays
          includeWeekend
          monthDays
          deliveryAt
          scheduleExpires
          subscribers {
              id
              contactId
              contact {
                  id
                  title
                  address
                  type
              }
              alert
              alertBefore
              alertAfter
              createdOn
              createdBy
              updatedOn
              updatedBy
          }
          format
          UIParams
          stringValues  {
            values
          }
          filterValues {
            field
            entities {
              id
              type
              parentId
            }
            stringValues{
              values
            }
            dateRange {
              fields
              GTE
              LTE
              timeframeName
            }
            integerRanges {
              fields
              GTE
              LTE
            }
            booleanValue
          }
          deliveryOn
          active
          nextExecution
          ftpHost
          selectAll
          createdOn
          createdBy
          updatedOn
          updatedBy
          scheduleTimeRanges{
            day
            starTime
            endTime
            createdOn
            createdBy
          }
        }
      }`,
      {
        id: data
      },
      endpoints.GRAPHQL_PX_REPORTING
    );
    if (schedule.Schedule){
      yield put(getScheduleResult(schedule.Schedule));
    }
    else throw 'Not Schedule';
  } catch (exc) {
    NotificationManager.error()
  }
}

function* getListFtp({ payload }){
  const {data} = payload;
  try {
    let listFtp = yield call(
      clientQuery,
      `query listFtp ($limit: Int, $offset: Int) {
        listFtp (limit: $limit, offset: $offset) {
            items {
                id
                host
                username
                password
                type
                port
                basePath
                createdOn
                createdBy
                updatedOn
                updatedBy
            }
            total
            limit
            offset
        }
    }`,
      {},
      endpoints.GRAPHQL_PX_REPORTING
    );
    if (listFtp.listFtp){
      yield put(getListFtpResult(listFtp.listFtp));
    }
    else throw 'Not ListFtp';
  } catch (exc) {
    NotificationManager.error()
  }
}

function* updateFtp({ payload }) {
  const {data} = payload;
  const {basePath, host, password, port, type, username, messages, id} = data;
  const ftp = {basePath,host,password,port,type,username, id}
  try {

      let query;

      if (ftp?.id) {
          query = `mutation updateFtp ($id: String!, $host: String!, $username: String!, $password: String!, $type: enumFtp!, $port: Int, $basePath: String) {
            ftp: updateFtp (id: $id, host: $host, username: $username, password: $password, type: $type, port: $port, basePath: $basePath) {
                id
                host
                username
                password
                type
                port
                basePath
                createdOn
                createdBy
                updatedOn
                updatedBy
            }
        }`;
      }else{
        query = `mutation createFtp ($host: String!, $username: String!, $password: String!, $type: enumFtp!, $port: Int, $basePath: String!) {
          ftp: createFtp (host: $host, username: $username, password: $password, type: $type, port: $port, basePath: $basePath) {
              id
              host
              username
              password
              type
              port
              basePath
              createdOn
              createdBy
              updatedOn
              updatedBy
          }
        }`;
      }

      const response = yield call(
          clientMutation,
          query,
          ftp,
          endpoints.GRAPHQL_PX_REPORTING,
      );

      yield put(updateFtpResult(response.ftp));
  } catch (exc) {

      let message = null
      if (exc.errorData && exc.errorData.errors) {
          exc.errorData.errors.forEach(error => {
              if (error.errorInfo && error.errorInfo.code) {
                  message = error.message
              }
          });
      }
      NotificationManager.error(messages[errorCodeHandler(exc?.errorData?.errors[0]?.errorInfo?.code + 'ftp')] || `Error Updating Ftp`, 'Error' , 3000, null, null, 'error');
      yield put(updateFtpResult({error: exc?.errorData?.errors[0]?.errorInfo?.code}));
  }
}

function* deleteFtp({ payload }) {
  const {data} = payload;
  const {host, messages} = data;
  const ftp = {host}
  try {

      let query = `mutation deleteFtp ($host: String!) {
        ftp: deleteFtp (host: $host)
       }`;

      const response = yield call(
          clientMutation,
          query,
          ftp,
          endpoints.GRAPHQL_PX_REPORTING,
      );

      yield put(deleteFtpResult(response.ftp));
  } catch (exc) {
      let message = null
      if (exc.errorData && exc.errorData.errors) {
          exc.errorData.errors.forEach(error => {
              if (error.errorInfo && error.errorInfo.code) {
                  message = error.message
              }
          });
      }
    NotificationManager.error(messages[errorCodeHandler(exc?.errorData?.errors[0]?.errorInfo?.code + 'ftp')] || `Error Updating Ftp`, 'Error' , 3000, null, null, 'error');
    yield put(updateFtpResult(null));
  }
}

function* watchGetSchedulesRequest() {
  yield takeEvery(REPORTS_MAIN_SAVE_SCHEDULE_REPORT, saveSchedulesRequest);
  yield takeEvery(REPORTS_MAIN_GET_SCHEDULE_REPORT, getSchedule);
  yield takeEvery(REPORTS_MAIN_UPDATE_SCHEDULE_REPORT, updateSchedulesRequest);
  yield takeEvery(REPORTS_MAIN_UPDATE_SUBSCRIBERS, updateSubscribers);
  yield takeEvery(REPORTS_MAIN_FTP_LIST,getListFtp);
  yield takeEvery(REPORTS_MAIN_FTP_UPDATE,updateFtp);
  yield takeEvery(REPORTS_MAIN_FTP_DELETE,deleteFtp);
}

//Saga Export
export default function* rootSaga() {
  yield all([fork(watchGetSchedulesRequest)]);
}


