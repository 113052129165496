//View Keys
export default {
    serviceItemsTab_delete: 'Eliminar',
    serviceItemsTab_deleteConfirmation: 'Está seguro de que desea eliminar el elemento de servicio',
    serviceItemsTab_createServiceItem: 'Crear nuevo elemento de servicio',
    serviceItemsTab_serviceItemLabel: 'LABEL DEL ARTÍCULO DE SERVICIO',
    serviceItemsTab_serviceItemInputPlaceholder: "label de artículo de servicio",
    serviceItemsTab_createdNewServiceItem: 'artículo de servicio agregado con éxito',
    serviceItemsTab_updatedSuccessfully: 'Artículo de servicio actualizado con éxito',
    serviceItemsTab_Title: 'Artículos de servicio',
    serviceItemsTab_deleteConfirmationSuccessfully: 'Elemento de servicio eliminado con éxito',
  };