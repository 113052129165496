// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    MAP_LANDMARKS_GET_LANDMARK,
    MAP_LANDMARKS_EDIT_CREATE_LANDMARK
} from "Redux/actionTypes";

// Actions
import {
    mapLandmarksGetLandmarkResponse,
    mapLandmarksEditCreateLandmarkResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* mapLandmarksGetLandmarkRequest({ payload }) {
    try {
        const { id } = payload;

        const response = yield call(
            clientQuery,
            `
                query getLandmark ($id: Int!, $showDelete: Boolean) {
                    Landmark: getLandmark (id: $id, showDelete: $showDelete) {
                        id
                        companyId
                        name
                        tags {
                            id
                            label
                            color
                            path
                        }
                        latitude
                        longitude
                        shape
                        radius
                        address
                        city
                        zip
                        state
                        country
                        note
                        coordinates {
                            latitude
                            longitude
                        }
                        color
                        iconName
                    }
                }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );

        if (!response && !response.Landmark) throw `Error while getting landmark: ${id}`;

        yield put(mapLandmarksGetLandmarkResponse({ ...response.Landmark, error: false }));
    } catch (exc) {
        yield put(mapLandmarksGetLandmarkResponse({ error: true }));
        ExceptionManager(
            exc,
            "realtimeMaps/main/components/landmarks/components/landmarkCreateEdit/redux/saga",
            "mapLandmarksGetLandmarkRequest"
        );
    }
}

function* mapLandmarksCreateEditLandmarkRequest({ payload }) {
    try {
        const { landmark } = payload;

        let query = `
            mutation createLandmark (
                $name: String!, 
                $tagsIds: [Int]!, 
                $latitude: Float!, 
                $longitude: Float!, 
                $shape: shapeType!, 
                $radius: Float!, 
                $address: String!, 
                $city: String!, 
                $zip: String!, 
                $state: String!, 
                $country: String!, 
                $note: String, 
                $coordinates: [CoordinateInput], 
                $color: String, 
                $iconName: String
            ) {
                Landmark: createLandmark (
                    name: $name, 
                    tagsIds: $tagsIds, 
                    latitude: $latitude, 
                    longitude: $longitude, 
                    shape: $shape, 
                    radius: $radius, 
                    address: $address, 
                    city: $city, 
                    zip: $zip, 
                    state: $state, 
                    country: $country, 
                    note: $note, 
                    coordinates: $coordinates, 
                    color: $color, 
                    iconName: $iconName
                ) {
                    id
                    companyId
                    name
                    tags {
                        id
	                    label
	                    color
                        path
                    }
                    latitude
                    longitude
                    shape
                    radius
                    address
                    city
                    zip
                    state
                    country
                    note
                    coordinates {
                        latitude
                        longitude
                    }
                    color
                    iconName
                }
            } 
        `;

        if (landmark?.id) {
            query = `
                mutation updateLandmark (
                    $id: Int!, 
                    $name: String, 
                    $tagsIds: [Int], 
                    $latitude: Float, 
                    $longitude: Float, 
                    $shape: shapeType, 
                    $radius: Float, 
                    $address: String, 
                    $city: String, 
                    $zip: String, 
                    $state: String, 
                    $country: String, 
                    $note: String, 
                    $coordinates: [CoordinateInput], 
                    $color: String, 
                    $iconName: String
                ) {
                    Landmark: updateLandmark (
                        id: $id, 
                        name: $name, 
                        tagsIds: $tagsIds, 
                        latitude: $latitude, 
                        longitude: $longitude, 
                        shape: $shape, 
                        radius: $radius, 
                        address: $address, 
                        city: $city, 
                        zip: $zip, 
                        state: $state, 
                        country: $country, 
                        note: $note, 
                        coordinates: $coordinates, 
                        color: $color, 
                        iconName: $iconName
                    ) {
                        id
                        companyId
                        name
                        tags {
                            id
                            label
                            color
                            path
                        }
                        latitude
                        longitude
                        shape
                        radius
                        address
                        city
                        zip
                        state
                        country
                        note
                        coordinates {
                            latitude
                            longitude
                        }
                        color
                        iconName
                    }
                } 
            `;
        }

        const response = yield call(
            clientMutation,
            query,
            landmark,
            endpoints.GRAPHQL_GENERAL,
        );

        if (!response || !response.Landmark) throw `Error while creating/editing landmark: ${landmark}`;

        yield put(mapLandmarksEditCreateLandmarkResponse({ ...response.Landmark, error: false }));
    } catch (exc) {
        let message = null
        if (exc.errorData && exc.errorData.errors) {
            exc.errorData.errors.forEach(error => {
                if (error.errorInfo && error.errorInfo.code) {
                    message = error.message
                }
            });
        }

        yield put(mapLandmarksEditCreateLandmarkResponse({ error: true, message: message }));
        /*
        ExceptionManager(
            exc,
            "realtimeMaps/main/components/landmarks/components/landmarkCreateEdit/redux/saga",
            "mapLandmarksCreateEditLandmarkRequest"
        );
        */
    }
}

function* watchMapLandmarksMethods() {
    yield takeEvery(MAP_LANDMARKS_GET_LANDMARK, mapLandmarksGetLandmarkRequest);
    yield takeEvery(MAP_LANDMARKS_EDIT_CREATE_LANDMARK, mapLandmarksCreateEditLandmarkRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMapLandmarksMethods)]);
}