export default {
  clipRequestAdvancedFilters_advancedFilters: 'Filtros Avanzados',
  clipRequestAdvancedFilters_selected: 'seleccionados',
  clipRequestAdvancedFilters_hasNotes: 'Tiene notas',
  clipRequestAdvancedFilters_singleUnits: 'Unidades',
  clipRequestAdvancedFilters_unitGroups: 'Grupos de unidaes',
  clipRequestAdvancedFilters_drivers: 'Conductores',
  clipRequestAdvancedFilters_driverGroups: 'Grupos de conductores',
  clipRequestAdvancedFilters_all: 'Todos',
  clipRequestAdvancedFilterBy: 'Filtrar por:',
  clipRequestAdvancedTimeFrame: 'Periodo de tiempo:',
  clipRequestAdvancedStatus: 'Estado',
  clipRequestAdvancedMediaType: 'Tipo de medio',
};
