import { Box } from "@mui/material";

import React from "react";

import MultipleActions from "./helpers/driversActions";

const SliderStockRecords = () => {
  const actions = MultipleActions();

  const actionsArr = actions.map((item) => (
    <Box key={item.id} style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "11px",
          height: "11px",
          background: item.color,
          marginRight: "5px",
        }}
      />

      <label>{item.label}</label>
    </Box>
  ));

  return actionsArr;
};

export default SliderStockRecords;
