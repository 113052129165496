import React from 'react';
import { ExceptionManager, LogManagerConsole } from 'Core/logManager'
import { getValueFloat, copyToClipboard } from 'Core/data/Helpers';
import { Button } from 'Components'

// Global styles
import styles from 'Assets/sass/core/vars.scss';

export const getOdometerValue = (unit, isKph, hideMeasure) => {
    const { useEcuOdometer = false, ecuEngineOdometer, offsetEcuEngineOdometer = 0, engineOdometer, offsetEngineOdometer = 0 } = unit;
    let odometer;

    try {
        if (ecuEngineOdometer != undefined && useEcuOdometer) {
            odometer = Math.round(getValueFloat(ecuEngineOdometer) + getValueFloat(offsetEcuEngineOdometer));
        } else if (engineOdometer != undefined) {
            odometer = Math.round(getValueFloat(engineOdometer) + getValueFloat(offsetEngineOdometer));
        }
    } catch (exc) {
        ExceptionManager(exc, "common", "getOdometerValue");
    }

    if (isKph && odometer != undefined && odometer != NaN) {
        odometer = Math.round(odometer * 1.609344);
    }

    if (hideMeasure) {
        //If is undefined return -1 for sort
        return odometer != undefined && odometer != NaN ? odometer : -1;
    }

    return odometer != undefined && odometer != NaN ? `${odometer?.toLocaleString('en-US')} ${isKph ? "km" : "mi"}` : "N/A";
}

export const getOdometerLabel = ({engineOdometer, ecuEngineOdometer, useEcuOdometer, offsetEcuEngineOdometer, offsetEngineOdometer, isKph}) => {
    let odometer;

    try {
        if (ecuEngineOdometer != undefined && useEcuOdometer) {
            odometer = Math.round(getValueFloat(ecuEngineOdometer) + getValueFloat(offsetEcuEngineOdometer));
        } else if (engineOdometer != undefined) {
            odometer = Math.round(getValueFloat(engineOdometer) + getValueFloat(offsetEngineOdometer));
        }
    } catch (exc) {
        ExceptionManager(exc, "common", "OdometerView");
    }

    if (isKph && odometer != undefined && odometer != NaN) {
        odometer = Math.round(odometer * 1.609344);
    }
    return odometer;
}

const OdometerView = ({ label, useEcuOdometer = false, ecuEngineOdometer, offsetEcuEngineOdometer = 0, engineOdometer, offsetEngineOdometer = 0, isKph }) => {
    LogManagerConsole("OdometerView", label, useEcuOdometer, ecuEngineOdometer, offsetEcuEngineOdometer, engineOdometer, offsetEngineOdometer)
   
    let odometer = getOdometerLabel({engineOdometer, ecuEngineOdometer, useEcuOdometer, offsetEcuEngineOdometer, offsetEngineOdometer, isKph})
    return (
        <div>
            <span style={{ paddingRight: 8 }}>
                {
                    useEcuOdometer &&
                    <span
                        className="ecuChip"
                        style={{ background: styles.ecu }}
                    >
                        ECU
                    </span>
                }
                {odometer != undefined && odometer != NaN ? `${odometer?.toLocaleString('en-US')} ${isKph ? "km" : "mi"}` : "N/A"}
            </span>
            <Button
                type="icon"
                icon="content_copy"
                onClick={() => copyToClipboard(odometer || "N/A")}
                fontSize="inherit"
                className="btn-no-padding hover-item"
            />
        </div>
    )
}

export default OdometerView