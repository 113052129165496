//Module Keys
let locales = {};

//Merge Views
import login from 'Core/views/login/lan/en';
locales = { ...locales, ...login };

import forgotPassword from 'Core/views/forgotPassword/lan/en';
locales = { ...locales, ...forgotPassword };

import migrateUser from 'Core/views/migrateUser/lan/en'
locales = { ...locales, ...migrateUser };

import userAlerts from 'Core/views/userAlerts/lan/en'
locales = { ...locales,  ...userAlerts };

import videoAlert from 'Core/views/videoAlert/lan/en';
locales = { ...locales,  ...videoAlert };

import header from 'Core/containers/Header/lan/en';
locales = { ...locales,  ...header };

import forceChangePassword from 'Core/views/forceChangePassword/lan/en';
locales = { ...locales, ...forceChangePassword };

import alertLog from 'Core/views/alertLog/lan/en';
locales = { ...locales,  ...alertLog };

export default locales;
