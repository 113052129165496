import React from "react";

import { Box } from "@mui/material";

import { Button } from "Components";

const SliderPanelActions = ({
  isDisable,
  actionCancel,
  actionOk,
  actionPreview,
  cancelLabel,
  actionLabel,
  previewLabel,
  avClips
}) => {
  return (
    <Box>
      <Button
        className="btn-cancel"
        onClick={() => actionCancel()}
        disabled={isDisable}
      >
        {cancelLabel}
      </Button>

      {
        actionPreview &&
        <Button
          className="btn-blue"
          onClick={() => actionPreview()}
          disabled={isDisable}
        >
          {previewLabel}
        </Button>
      }

      <Button
        className="btn-blue"
        onClick={() => actionOk()}
        disabled={isDisable || avClips}
      >
        {actionLabel}
      </Button>
    </Box>
  );
};

export default SliderPanelActions;
