// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    MAP_GEOFENCES_BULK_UPDATE
} from "Redux/actionTypes";

// Actions
import {
    mapGeofenceBulkUpdateResponse,
    getKmls
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* mapGeofencesBulkRequest({ payload }) {
    try {
        const { geofencesBulk, geofenceIcon } = payload

        let mutations = [];
        let countMutations = 0
        
        geofencesBulk.forEach((item, index) => {
            const { tagId, entityName, entityIds, action } = item;
            mutations.push(`updateTagAssignment${countMutations}: updateTagAssignment(tagId: ${tagId}, entityName: ${entityName}, entityIds: [${entityIds}], action: ${action})`)
            countMutations = countMutations + 1;
        });

        if(geofenceIcon){
            const { geofencesIds, color } = geofenceIcon;
            if(geofencesIds){
                mutations.push(`updateTagAssignment${countMutations}: updateGeofenceBulkIcon(geofenceIds: [${geofencesIds}], color: "${color}")`)
            }
        }
    
        let mutation = `mutation{${mutations.join("")}}`;
    
        const response = yield call(
            clientMutation,
            mutation,
            {},
            endpoints.GRAPHQL_GENERAL,
        );
        if (response && response.updateTagAssignment0) {
            yield put(mapGeofenceBulkUpdateResponse(response.updateTagAssignment0));
            yield put(getKmls());
        } else {
            throw 'Error while bulk geofence';
        }
        
    } catch (exc) {
        yield put(mapGeofenceBulkUpdateResponse({ error: true }));
        ExceptionManager(
            exc,
            "realtimeMaps/main/components/geofences/components/geofenceBulk/redux/saga",
            "mapGeofencesBulkRequest"
        );
    }
}

function* watchMapGeofencesBulkMethods() {
    yield takeEvery(MAP_GEOFENCES_BULK_UPDATE, mapGeofencesBulkRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMapGeofencesBulkMethods)]);
}