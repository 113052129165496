import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
// Material UI
import { Grid, Menu } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
// Components
import { Loading, Pill, Button } from 'Components';
import { CirclePicker } from 'react-color';
import { endpoints } from 'Core/defaultValues';
import { FlagColorData } from 'Components/Map/mapsData';
import { NotificationManager } from 'Components/Notifications';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// Styles
import './styles.scss';
// Actions
import {
    mapIconPickerSearchIcons,
    clearRedux
} from 'Redux/actions';
import { width } from '@mui/system';

const IconPicker = (props) => {
    const dispatch = useDispatch();

    const { intl, color = "8677D9", icon, options = ["color", "icon"], defaultIcon = false, restartIcon } = props;
    const { messages } = intl;

    const {
        placesIcons,
        mapsIcons,
        error,
        filters,
        loadingIcons
    } = useSelector(state => state.iconPickerRedux);

    const [anchorEl, setAnchorEl] = useState(null);
    const [category, setCategory] = useState("places");
    const [loadingIcon, setLoadingIcon] = useState(false);
    const [currentColor, setCurrentColor] = useState(color);
    const [currentIcon, setCurrentIcon] = useState(icon);

    const mapIconCategories = [
        {
            id: 'places',
            name: messages['mapIconPicker_places'],
            color: '#66BB6A'
        },
        {
            id: 'maps',
            name: messages['mapIconPicker_maps'],
            color: '#66BB6A'
        }
    ];

    useEffect(() => {
        return () => {
            dispatch(clearRedux("MAP_ICON_PICKER"));
        }
    }, []);

    useEffect(() => {
        dispatch(mapIconPickerSearchIcons({ ...filters, category: "places" }));
    }, []);

    useEffect(() => {
        if(restartIcon > 0){
            setCurrentIcon(icon);
        }
    }, [restartIcon]);

    useEffect(() => {
        if (error) {
            NotificationManager.error(
                messages['mapIconPicker_error'],
                messages['error'],
                3000,
                null,
                null,
                'warning',
            );
            dispatch(clearRedux("MAP_ICON_PICKER", { error: false }));
        }
    }, [error]);

    const renderIcons = useCallback((icons) => {
        if (!icons || !icons.length) {
            return <Grid item={true} xs />;
        }

        return (
            <React.Fragment>
                {
                    icons.filter(item => item?.svgPath && item?.svgPath.includes(".svg")).map((item, key) => {
                        let selected = item.svgPath === currentIcon || `${item.iconPath}/${currentColor}.png` === currentIcon;
                        return (
                            <Grid item={true} xs key={key}>
                                {/*
                                <img
                                    className={`map-icon-image ${selected ? 'active' : ''}`}
                                    src={`https://s3.amazonaws.com/${endpoints.REPOSITORY_CODE}/${item.svgPath}`}
                                    onClick={() => onChange("icon", item)}
                                    alt={item.iconName}
                                    loading="lazy"
                                />
                                */}

                                <LazyLoadImage
                                    className={`map-icon-image ${selected ? 'active' : ''}`}
                                    alt={item.iconName}
                                    effect="blur"
                                    src={`https://s3.amazonaws.com/${endpoints.REPOSITORY_CODE}/${item.svgPath}`}
                                    delayTime={500}
                                    onClick={() => {
                                        let newIcon = item.svgPath;
                                        if (options.includes("color")) {
                                            newIcon = `${item.iconPath}/${currentColor}.png`;
                                        }

                                        setCurrentIcon(newIcon);

                                    }}
                                />
                            </Grid>
                        )
                    })
                }
            </React.Fragment>
        )
    });

    const renderPicker = useCallback(() => {
        if (defaultIcon || options.includes("icon") && icon) {
            return (
                <React.Fragment>
                    {
                        !anchorEl ?
                            <React.Fragment>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid item xs>
                                        <Skeleton
                                            variant="rect"
                                            className={`map-icon-picker-selected-image`}
                                            style={{
                                                height: 25,
                                                display: loadingIcon ? "flex" : "none",
                                                borderRadius: 4
                                            }}
                                        />
                                        <LazyLoadImage
                                            className={`map-icon-picker-selected-image`}
                                            src={`https://s3.amazonaws.com/${endpoints.REPOSITORY_CODE}/${icon}`}
                                            alt={icon}
                                            effect="blur"
                                            delayTime={500}
                                            style={{
                                                display: !loadingIcon ? "flex" : "none",
                                                borderRadius: 4
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={loadingIcon}
                                            className="btn-blue btn-sm"
                                        >
                                            {messages['mapIconPicker_chooseIcon']}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </React.Fragment> :
                                <img
                                    src={`https://s3.amazonaws.com/${endpoints.REPOSITORY_CODE}/${icon}`}
                                    alt={icon}
                                    loading="lazy"
                                    style={{
                                        paddingTop: 7,
                                        paddingLeft: 2,
                                        height: 25
                                    }}
                                />
                    }
                </React.Fragment>
            );
        } else if (options.includes("color")) {
            return (
                <div className="map-icon-color" style={{ backgroundColor: `#${color}`, border: `1px solid #${color || 'DDD'}` }} />
            );
        }
    });

    const onCancel = useCallback(() => {
        setCurrentColor(color);
        setCurrentIcon(icon);
        setAnchorEl(null);
    });

    let height = 324;
    if (options.includes("color") && !options.includes("icon")) {
        height = 60;
    } else if (!options.includes("color") && options.includes("icon")) {
        height = 220;
    }

    return (
        <div style={{ width: '100%' }}>
            <div
                style={{ width: '100%' }}
                onClick={(e) => setAnchorEl(e?.currentTarget)}
            >
                {renderPicker()}
            </div>

            <Menu
                id="map-icon-picker-context-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <div>
                    <div
                        className="map-icons-container"
                        style={{ height: height }}
                    >
                        {
                            options.includes("color") &&
                            <div className="map-icons-color-picker-container">
                                <CirclePicker
                                    colors={FlagColorData.map(colorHex => {
                                        return `#${colorHex}`;
                                    })}
                                    width="100%"
                                    circleSize={25}
                                    circleSpacing={10}
                                    color={`#${currentColor}`}
                                    onChange={(value) => {
                                        let newColor = value?.hex?.replace("#", "");
                                        newColor = newColor?.toUpperCase();

                                        if (options.includes("icon")) {
                                            let newIcon = currentIcon.replace(currentColor, newColor);
                                            setCurrentIcon(newIcon);
                                        }

                                        setCurrentColor(newColor);
                                    }}
                                />
                            </div>
                        }


                        {
                            options.includes("icon") &&
                            <React.Fragment>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {
                                        mapIconCategories.map((item, key) => {
                                            const { id } = item;
                                            let selected = category === id ? false : true;
                                            let styles = { width: '100%' };
                                            if (selected) {
                                                styles = {
                                                    ...styles,
                                                    ...{
                                                        backgroundColor: "#F1F1F1",
                                                        color: "#484848",
                                                        border: "1px solid #F1F1F1"
                                                    }
                                                };
                                            }

                                            return (
                                                <Grid item xs style={{ padding: 2 }} key={key}>
                                                    <Pill
                                                        style={styles}
                                                        item={item}
                                                        outlined={selected}
                                                        onClick={(value) => {
                                                            setCategory(value.id);
                                                            if (value.id === "places" && !placesIcons.length) {
                                                                dispatch(mapIconPickerSearchIcons({ ...filters, category: value.id }));
                                                            } else if (value.id === "maps" && !mapsIcons.length) {
                                                                dispatch(mapIconPickerSearchIcons({ ...filters, category: value.id }));
                                                            }

                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>

                                {
                                    loadingIcons ?
                                        <Loading full /> :
                                        <div className="map-icons-list">
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                style={{ display: category === "places" ? "flex" : "none" }}
                                            >
                                                {renderIcons(placesIcons)}
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                style={{ display: category === "maps" ? "flex" : "none" }}
                                            >
                                                {renderIcons(mapsIcons)}
                                            </Grid>
                                        </div>
                                }
                            </React.Fragment>
                        }


                    </div>

                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{
                            padding: "8px 11px 2px 11px"
                        }}
                        spacing={1}
                    >
                        <Grid item xs>
                            <Button
                                disabled={loadingIcon}
                                className="btn-cancel btn-block"
                                onClick={(e) => {
                                    if (e) e?.preventDefault();
                                    onCancel();
                                }}
                            >
                                {messages['cancel']}
                            </Button>
                        </Grid>

                        <Grid item xs>
                            <Button
                                disabled={loadingIcon}
                                className="btn-blue btn-block"
                                onClick={(e) => {
                                    if (e) e?.preventDefault();
                                    if (props.onChange) props.onChange(currentColor, currentIcon);
                                    setAnchorEl(null);
                                }}
                            >
                                {messages['apply']}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Menu>
        </div>
    );
};

export default injectIntl(IconPicker);