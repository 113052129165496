import React, { Fragment, useEffect } from 'react';
// Material UI
import { Grid, Typography } from '@mui/material';

import {Timezone} from '../../../../../components/uiControls';

import {
  copyToClipboard
} from 'Core/data/Helpers'
import { Button } from 'Components';

import { Loading } from 'Components';
import moment from 'moment';
import { useIntl } from 'react-intl';

import {
  routesTrackerRoutePointsLogSearchRoutes
} from 'Redux/actions';

import { ColorsToRoutePointsOnRoutesTracker } from '../../../../../components/Map/providers/enums'

// Styles
import '../infoBubble.scss';
import '../../../../../components/Map/providers/google/InfoWindow/style.scss'
import { useDispatch, useSelector } from 'react-redux';

const RoutePointInfo = (props) => {

  const { messages, locale } = useIntl();
  const { data } = props;

  const dispatch = useDispatch()

  const loadingRoutePointLog = useSelector(state => state.routesTrackerModalStopInformationRedux?.loadingRoutePointLog);
  const routePointLogs = useSelector(state => state.routesTrackerModalStopInformationRedux?.routePointLogs);
  const timeZoneUser = useSelector((state) => state.securityRedux.user.timeZone);  
  

  useEffect(()=> {
    dispatch(routesTrackerRoutePointsLogSearchRoutes({pointId: data?.markerDetail?.routePoint?.id}))
  },[])

  const getDate = (dateIn, timezone) => {
    let date = moment(dateIn)
    if (!date.isValid()) {
        if (dateIn && dateIn.length > 10) {
            date = moment.unix(Number(dateIn?.substring(0, dateIn.length - 3)))
        } else {
            date = moment.unix(Number(dateIn))
        }
    }

    if(timezone){
        date = date.tz(timezone);
    }

    return date;
  }

  const ItemStatusChangeLog = (props) => {
    const { label, isColumn } = props
    return (
        <div className={isColumn ? 'routesModal-stop-information-item-card-content' : 'routesModal-stop-information-item-card-content-row'}>
            <label className="routesModal-stop-information-label">{label}:</label>
            {props.children}
        </div>
    )
  }

  const CardStopInformationLog = (props) => {
      const { item } = props
      const { status, createdOn, latitude, longitude, note, address } = item
      const statusToLog = ColorsToRoutePointsOnRoutesTracker.find((colorItem) => colorItem.name == status);
      let date = getDate(createdOn, timeZoneUser);

      let addressData = {}, strAddress = "";
      if(address){
          //{"zip": "92139", "city": "San Diego", "state": "CA", "address": "6719 Paris Way", "formatted": "6719 Paris Way, San Diego, CA 92139, USA"}
          try {
              addressData = JSON.parse(address)
              strAddress = addressData?.formatted
          } catch (error) {
              strAddress = "";
          }
          
      }

      return(
          <div className="routesModal-stop-information-card">
              <div className="routesModal-stop-information-card-content">
                  <ItemStatusChangeLog isColumn label={messages['fsmRoute_routesTracker_modalInformation_status']} > 
                      <div style={{ width: 100 }}>
                          <label style={{ color: statusToLog?.color }}>
                              {messages[statusToLog?.messages]}
                          </label>
                      </div>
                  </ItemStatusChangeLog>
                  <ItemStatusChangeLog label={messages['fsmRoute_routesTracker_modalInformation_createdOn']}>
                      <div style={{ width: 220 }}>
                      {
                          createdOn && date.isValid() 
                          ?
                          <Fragment>
                              <Timezone
                                  //oneControl
                                  onlyHour
                                  date={createdOn}
                                  dateFormat={""}
                                  timeFormat={"h:mm a"}
                                  timezone={timeZoneUser}
                                  className="routes-tracker-TimeZoneClassName"
                                  fromNow={true}
                                  lang={locale}
                              /> 
                              <div className='subtitle'>{date.locale(locale).format('MMM DD, YYYY')}</div>
                          </Fragment>
                          :
                          <Fragment></Fragment>
                      }
                      </div>
                  </ItemStatusChangeLog>
                  <ItemStatusChangeLog isColumn label={messages['fsmRoute_routesTracker_modalInformation_latitude_longitude_small']}>
                      <span>{`${latitude ? latitude.toFixed(4) : 0}, ${longitude ? longitude.toFixed(4) : 0}`}</span>
                  </ItemStatusChangeLog>
                  {
                      strAddress
                      &&
                      <ItemStatusChangeLog isColumn label={messages['fsmRoute_routesTracker_modalInformation_address']}>
                          <label>
                              {strAddress || ""}
                          </label>
                      </ItemStatusChangeLog>
                  }
                  
                  <ItemStatusChangeLog isColumn label={messages['fsmRoute_routesTracker_modalInformation_note']}>
                      <label>
                          {note || ""}
                      </label>
                  </ItemStatusChangeLog>
              </div>
          </div>
      )
  }

  const StatusChangeLog = () => {
    return(
        loadingRoutePointLog
        ?
            <Loading overlay show />
        :
        <div className="routesModal-stop-information-card-list">

            {
              (!routePointLogs?.items || routePointLogs?.items <= 0)
              &&
              <table className="info-bubble-table">
                <tbody>
                  <tr>
                    <td>
                    {messages['fsmRoute_routesTracker_modalInformation_no_log']}
                    </td>
                  </tr>
                </tbody>
              </table>
            }

            {
                routePointLogs?.items 
                &&
                routePointLogs?.items.map((item) => {
                    return(
                      <div>
                        <CardStopInformationLog item={item} />
                      </div>
                    )
                })
            }
            
        </div>
    )
  }

  return (
    <div className='info-window-tab-streetview'>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          padding: '16px 45px 16px 16px'
        }}
      >
        <Grid item xs zeroMinWidth>
          <Typography noWrap style={{ fontWeight: 'bold' }}>
            {data?.markerDetail?.routePoint?.name || data?.markerDetail?.routePoint?.address}
          </Typography>
        </Grid>
      </Grid>

      <div style={{ borderTop: 'solid 1px #e4e4e4' }}></div>

      <div className='routesModal-stop-information-log'>
       <StatusChangeLog />
      </div>
    </div>
  )
}

export default RoutePointInfo;