import React, { useState, useEffect, memo } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
// Components
import { Loading, Button } from 'Components';
import VidFleetVideo from 'Modules/.common/components/VidFleetVideo';
// Material UI
import { Grid } from '@mui/material';
import { checkDeviceData } from 'Core/data/Helpers'
import { useIntl } from "react-intl";
import { useLocation } from 'react-router-dom';

import {
  //getSharedMedia,

  getAvailableMediaDetail,
  getMarkerDetails,
  getClipRequestDetail
} from 'Redux/actions';

const STATUS_ASSETS_PATH = '/assets/images/';

const SharedLiveVideoSecView = (props) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  
  const { deviceId, readingId, clipId } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenParam = queryParams.get('token');

  const imageRoute = `${STATUS_ASSETS_PATH}clipRequestFailed.png`;

  const mediaResponse = useSelector(state => state.videoDetailRedux.mediaResponse);
  const loadingClipRequest = useSelector(state => state.videoDetailRedux.loadingClipRequest);
  const loadingAvailableMedia = useSelector(state => state.videoDetailRedux.loadingAvailableMedia);

  const getMarkerDetailsResponse = useSelector(state => state.infoBubbleRedux.getMarkerDetailsResponse);
  const loadingGetMarkerDetails = useSelector(state => state.infoBubbleRedux.loadingGetMarkerDetails);


  const loading = loadingClipRequest || loadingAvailableMedia || loadingGetMarkerDetails
  const response = mediaResponse || getMarkerDetailsResponse || null

  useEffect(()=> {
    localStorage.setItem('IdToken', tokenParam)
  },[])

  useEffect(() => {
    if (deviceId && readingId > 0) {
        dispatch(getAvailableMediaDetail({ deviceId: deviceId, id: readingId }));
    }else if(deviceId > 0){        
        dispatch(getMarkerDetails({ type: "Vehicle", id: deviceId, unitId: null}));
    } else if(clipId > 0){
      dispatch(getClipRequestDetail({ id: clipId }));
    }
  }, [deviceId, clipId]);

  const [options, setOptions] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (response) {
      if (response.error) {
        setError(messages['sharedMedia_notFound']);
      } else {
        if (readingId > 0) { // is "DeviceReading"
          if (response?.reading?.deviceData) {
            try {
              let deviceData = checkDeviceData(response?.reading?.deviceData);
              let videoOptions = {
                mode: 'picture',
                id: deviceData?.pictureID,
                pictureId: deviceData?.pictureID
              };

              if (deviceData?.clipID) {
                if (deviceData?.duration) {
                  videoOptions.mode = "video";
                } else {
                  videoOptions.thumbnailUrl = deviceData?.url;
                }
                videoOptions.id = deviceData?.clipID;
                videoOptions.pictureId = null;
              }
              setOptions({
                ...videoOptions,
                unitId: deviceId,
                messages: messages,
                type: "playback",
                autoplay: true,
                deviceModel: response?.linkedDevice?.deviceModelName === '360' ? '360' : '4K',
                manufacturer: response.linkedDevice?.manufacturerName
              });
            } catch (e) {
              /* console.log("Error while parsing string json view SharedMedia"); */
            }
          }
        } else if(clipId > 0){
          setOptions({
            unitId: deviceId,
            mode: 'video',
            id: response?.clipID,
            thumbnailUrl: !response?.clipID && !response?.thumbnailUrl ? imageRoute : response?.thumbnailUrl,
            messages: messages,
            type: "playback",
            autoplay: true,
            deviceModel: response?.deviceModelName === '360' ? '360' : '4K',
            disabled: !response?.clipID,
            manufacturer: "VidFleet"
          });

        } else {
            setOptions({
                unitId: deviceId,
                autoplay: true,
                id: response?.markerDetail?.linkedDevice?.esn, //"2B2CJHHZ"
                simProviderName: response?.markerDetail?.linkedDevice?.simProviderName,
                messages: messages,
                mode: 'video',
                type: "streaming",
                vehicleEvent: response?.markerDetail?.unit?.lastReading?.eventName,
                deviceModel: response?.markerDetail?.linkedDevice?.deviceModelName === '360' ? '360' : '4K',
                manufacturer: response.markerDetail?.linkedDevice?.manufacturerName
            });
        }
      }
    }
  }, [response]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {
        loading
          ?
          <Loading full />
          :
          <div style={{ width: '100%', height: '100%', backgroundColor: 'black' }}>
            {
              options && options?.manufacturer === "smartwitness" && <SmartWitnessVideo  {...options} /> 
            }
            {
              options && options?.manufacturer === "VidFleet" && <VidFleetVideo  {...options} />
            }

            {
              error &&
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ padding: 20, height: '100%' }}
              >
                <Grid item>
                  <span style={{ fontSize: 18 }}>{error}</span>
                </Grid>

                <Grid item>
                  <Button
                    type="icon"
                    icon="refresh"
                    onClick={(e) => {
                      if (e) e.preventDefault();
                      setError(null);
                      if (deviceId && readingId > 0) {
                        dispatch(getAvailableMediaDetail({ deviceId: deviceId, id: readingId }));
                      }else{
                       
                        dispatch(getMarkerDetails({ type: "Vehicle", id: deviceId, unitId: null }));
                      }
                      //dispatch(getSharedMedia({ uuid: id }));
                    }}
                  />
                </Grid>
              </Grid>
            }
          </div>
      }
    </div>
  );
};

export default memo(SharedLiveVideoSecView);