import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// controls
import Table, {Column} from '../../table'
//helpers
import {formatDatetime, getCategoryFilterType, getNameEntity, useHandleExportReport} from 'Modules/reports/views/main/components/reportsTab/helper';

//hooks
import {useSidePanelMap, useTableReport} from 'Core/hooks';
//actions
import {clearRedux, reportsMainGenerateEvents, reportsMainGenerateReportUpdate} from 'Redux/actions'
import AddressLandmark from "Modules/reports/views/main/components/reportsTab/.components/addressLandmark";
import moment from 'moment';

// styles
import './deviceSummaryInfoReport.scss';

const DeviceSummaryInfoReport = (props) => {
    const {messages, report} = props;
    const {data, filters, summary, loadingSummary, timeZone} = report;
    const {filterEntity, otherFilters, startRange, endRange} = filters;

    const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
    const [SideMapComponentCurrent, onToggleSideMapCurrent] = useSidePanelMap();
    const [unitDriverSelected, setUnitDriverSelected] = useState();
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
    const [currentNewSortField, setCurrentNewSortField] = useState("deviceId");
    const [currentNewSortOrder, setCurrentNewSortOrder] = useState("asc");

    const exportHandler = useHandleExportReport(report)

    const loadingCreateLandMark = useSelector(state => state.vehicleLandMarkRedux.loadingCreateLandMark)
    const createLandMarkResult = useSelector(state => state.vehicleLandMarkRedux.createLandMarkResult)

    const reportRef = useRef();
    const dispatch = useDispatch()
    useEffect(() => {
        if (report) {
            const newReport = JSON.parse(JSON.stringify(report));
            // to keep the reference to the units selected
            reportRef.current = newReport;
        }
    }, [JSON.stringify(report)]);

    useEffect(() => {
        if (!loadingCreateLandMark && createLandMarkResult) {
            dispatch(reportsMainGenerateReportUpdate(report, currentPage, currentRowsPerPage, currentNewSortField, currentNewSortOrder))
            dispatch(clearRedux("LANDMARK", {"createLandMarkResult": null}));
        }

    }, [loadingCreateLandMark, createLandMarkResult])

    const onChangeExpandable = (unit) => {
        if(!unit.noData) {
            let events = {
                data: [],
                filters: {
                    filterType: getCategoryFilterType(filterEntity),
                    filterArray: unit,
                    page: 1,
                    rowsPerPage: 10,
                    sortField: 'eventDate',
                    otherFilters
                }
            }
            dispatch(reportsMainGenerateEvents(report, events))
        }
    }

    const renderExpandableRow = (row) => {
        return (
            <Table
                loading={loadingSummary}
                data={summary?.items || []}
                totalItems={summary?.total || 0}
                onChangedPage={(page, rowsPerPage, sortField, sortOrder) => {
                    let events = {
                        data: [],
                        filters: {filterType: getCategoryFilterType(filterEntity), filterArray: row, page, rowsPerPage, sortField, sortOrder, otherFilters}
                    }
                    dispatch(reportsMainGenerateEvents(report, events))
                }}
                serverSideTable={true}
                hideNumberOfRows={true}
                hiddenPagination={false}
                classNameColumns={'report-column-events-expandible'}
                noDataMessage={messages["generatedReport_noEvents"]}
                numberofRows={10}
                centerPagination
                noData={row.noData}
            >
                <Column label={messages["generatedReport_group"]} field="groupName" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span className="speed-report-stacked">{element.groupName}</span>
                            </div>
                        )
                    }}
                </Column>
                <Column label={messages["generatedReport_unitLabel"]} field="idLabel" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span className="speed-report-stacked">{element.idLabel}</span>
                            </div>
                        )
                    }}
                </Column>
                <Column label={messages["generatedReport_unit"] + ' SN'} field="unitSN" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span className="speed-report-stacked">{element.unitSN}</span>
                            </div>
                        )
                    }}
                </Column>
                <Column label={messages["generatedReport_notes"]} field="notes" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span className="speed-report-stacked">{element.notes}</span>
                            </div>
                        )
                    }}
                </Column>
                <Column label={messages["generatedReport_engineHours"] + 'Total'} field="totalEngineHours" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span className="speed-report-stacked">{element.totalEngineHours}</span>
                            </div>
                        )
                    }}
                </Column>
                <Column label={messages["generatedReport_engineRunning"]} field="engineRunningTime" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span className="speed-report-stacked">{element.engineRunningTime}</span>
                            </div>
                        )
                    }}
                </Column>
                <Column label={messages["generatedReport_currentAddress"]} field="currentAddress" noSorting>
                    {(element) => {
                        return (
                            <AddressLandmark onToggleSideMap={onToggleSideMapCurrent} messages={messages} element={{address: element.currentAddress}}/>
                        )
                    }}
                </Column>
                <Column label={messages["generatedReport_daysCurrentAddress"]} field="daysAtCurrentAddress" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span className="speed-report-stacked">{element.daysAtCurrentAddress}</span>
                            </div>
                        )
                    }}
                </Column>
                <Column label={messages["generatedReport_previousAddress"]} field="previousAddress" noSorting>
                    {(element) => {
                        return (
                            <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={{address: element.previousAddress}}/>
                        )
                    }}
                </Column>
                <Column label={messages["generatedReport_lastEventTime"]} field="lastEventTime" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span className="speed-report-stacked">{element.lastEventTime && formatDatetime(element.lastEventTime, "h:mm A", timeZone)}</span>
                                <span className="speed-reportstacked speed-report-event-date">{element.lastEventTime && formatDatetime(element.lastEventTime, "MMM D", timeZone)}</span>
                            </div>
                        )
                    }}
                </Column>
            </Table>
        )
    }

    let parametersTable = useTableReport({
        data: data.items,
        report,
        messages,
        hideHeader: true,
        renderExpandableRow,
        onChangeExpandable,
        propertyToShowExpandable: 'id',
        classNameColumns: 'start-stop-idle-main-table'
    });

    return (
        <Fragment>
            <Table
                {...parametersTable}
                {...exportHandler}
            >
                <Column label="Day" field="label" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span>
                                  <span><strong>{getNameEntity(filterEntity, messages)}:</strong> {element.idLabel} </span>
                                </span>
                            </div>
                        );
                    }}
                </Column>
                <Column label={messages['generatedReport_totalIdleTime']} field="label" noSorting>
                    {(element) => {
                        const localTimeStart = moment.utc(startRange).local().format();
                        const localTimeEnd = moment.utc(endRange).local().format();
                        return (
                            <div>
                                <span>
                                  <span>
                                    <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                                  </span>
                                </span>
                            </div>
                        );
                    }}
                </Column>
            </Table>
            <SideMapComponent hiddenButton hiddenSearchMap/>
            <SideMapComponentCurrent hiddenButton hiddenSearchMap/>
        </Fragment>
    );
}

export default DeviceSummaryInfoReport;
