import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../core/logManager';

import { resetALL } from '../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    response: null,
    dynamicForms: [],
    error: false
};

const route = 'modules/dynamicForms/modals/delete/redux/slice'

export const deleteDynamicForms = createAsyncThunk('dynamicForms/modals/delete/deleteDynamicForms', async ({ ids }, { rejectWithValue, dispatch }) => {
    try {
        let query = `
            mutation deleteDynamicForms ($ids: [Int]!) {
                deleted: deleteDynamicForms (ids: $ids)
            }
        `;

        const response = await clientMutation(
            query,
            {
                ids
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response?.deleted) {
            throw 'Error while deleting dynamic forms';
        }

        return response?.deleted;
    } catch (exc) {
        ExceptionManager(exc, route, 'deleteDynamicForms');
        return rejectWithValue(exc)
    }
});

//Slice
const dynamicFormsDeleteRedux = createSlice({
    name: 'dynamicForms/delete',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openDeleteModal(state, action) {
            state.open = true;
            state.dynamicForms = action?.payload;
        },
        closeDeleteModal(state) {
            state.open = false;
            state.dynamicForms = [];
            state.response = null;
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)
        //deleteDynamicForms
        builder.addCase(deleteDynamicForms.pending, (state, action) => {
            state.loading = true;
            state.error = false;
            state.response = null;
        });
        builder.addCase(deleteDynamicForms.fulfilled, (state, action) => {
            state.loading = false;
            state.error = false;
            state.response = action.payload;
        });
        builder.addCase(deleteDynamicForms.rejected, (state, action) => {
            state.loading = false;
            state.response = action.payload;
            state.error = true;
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = dynamicFormsDeleteRedux;

// Extract and export each action creator by name
export const { 
    reset, 
    openDeleteModal, 
    closeDeleteModal,
} = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;