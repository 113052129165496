// Dependencies
import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@mui/material/ListItemText';
import {
	Grid,
	Icon,
	IconButton,
	OutlinedInput,
	InputAdornment,
	FormControl,
	Grow,
	Menu,
	MenuItem,
} from '@mui/material';

// Cloud React Components
import { Button } from 'Components';
import { useIntl } from 'react-intl';


//Styles
import './toolbar.scss';

const Toolbar = forwardRef((props, ref) => {
	useImperativeHandle(ref, () => ({
		setNewSearchText: setNewSearchText
	}));

	const [searchText, setSearchText] = React.useState('');
	const [typing, setTyping] = React.useState(false);
	const { messages } = useIntl();

	useEffect(() => {
		setSearchText('');
	}, [])

	React.useEffect(() => {
		if (typing) {
			const timer = setTimeout(() => {
				if (props.onSearch) {
					props.onSearch(searchText);
				}
			}, 350)
			return () => clearTimeout(timer);
		}
	}, [searchText]);

	const setNewSearchText = useCallback((newSearchText) => {
		setSearchText(newSearchText);
	});

	return (
		<div className={props.toolbarClassName || "toolbar"}>
			<Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
				{
					props.children &&
					<Grid
						container
						item
						direction="row"
						xs={12}
						sm={props.sizeLeftGrid || 6}
						className={`mb-10 ${props.className || ''}`}
					>
						{
							props.children
						}
					</Grid>
				}

				{props.inputSearch !== false &&
					<Grid item xs={12} sm={props.children ? 6 : 12} className="mb-10 text-right">
						<div>
							<FormControl style={{ width: '100%' }}>
								{props.label && <label>{props.label}</label>}
								<OutlinedInput
									disabled={props.loading}
									id={props.id || 'custom-search'}
									placeholder={props.placeholder || messages['search']}
									type={'text'}
									value={searchText || ''}
									fullWidth={true}
									onChange={() => {
										setSearchText(event.target.value);
										setTyping(true);
									}}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="placeholder"
												style={{ fontSize: 25, color: 'black' }}
												size="large">
												<Icon>search</Icon>
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
						</div>
					</Grid>
				}
			</Grid>
		</div>
	);
});

export default Toolbar;


// <--------------------- Toolbar Components --------------------->

/**
 * Builts a button inside the TableView toolbar.
 * @property {string} tooltip - The icon tooltip.
 * @property {string} icon - The material UI icon name, e.g. add_box, delete, filter_list.
 * @returns {React.Node} The toolbar button.
 */
const ToobarButton = (props) => {
	const { tooltip, icon } = props;

	return (
		<Button
			type="icon"
			tooltip={tooltip}
			placement="bottom"
			icon={icon}
			{...props}
		/>)
		;
};

/**
 * Proptypes for ToobarButton.
 */
ToobarButton.propTypes = {
	tooltip: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
};

/**
 * Builts an export button for the TableView toolbar.
 * @returns {React.Node} The export button.
 */
const ExportButton = (props) => {
	const { onExport, firstOption, secondOption, icon, className, size, fontSize } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const { messages } = useIntl();


	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<Button
				type="icon"
				aria-controls="customized-menu"
				aria-haspopup="true"
				variant="contained"
				onClick={handleClick}
				placement="bottom"
				icon={icon ? icon : "get_app"}
				tooltip={firstOption ? firstOption : messages['table_export']}
				className={className ? className : "icon-down-download"}
				style={{ color: 'black' }}
				size={size || ""}
				fontSize={fontSize || ""}
			/>
			<Menu
				id="customized-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				sx={{
					'& .MuiMenu-paper': {
						border: '1px solid #d3d4d5'
					}
				}}
				elevation={0}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<MenuItem
					onClick={() => {
						const valueFirstExport = firstOption ? firstOption : 'CSV'
						onExport(valueFirstExport);
						handleClose();
					}}
				>
					<ListItemText primary={firstOption ? firstOption : messages['table_export_csv']} />
				</MenuItem>
				{!props.hidePdfOption &&
					<MenuItem
						onClick={() => {
							const valueFirstExport = secondOption ? secondOption : 'PDF'
							onExport(valueFirstExport);
							handleClose();
						}}
					>
						<ListItemText primary={secondOption ? secondOption : messages['table_export_pdf']} />
					</MenuItem>
				}
			</Menu>
		</React.Fragment>
	);
};

export { ExportButton, ToobarButton };

