export const SETTINGS_INPUT_LABEL_SEARCH = "SETTINGS_INPUT_LABEL_SEARCH";
export const SETTINGS_INPUT_LABEL_SEARCH_RESPONSE = "SETTINGS_INPUT_LABEL_SEARCH_RESPONSE";
export const SETTINGS_INPUT_LABEL_OPEN_CREATE_EDIT_MODAL = "SETTINGS_INPUT_LABEL_OPEN_CREATE_EDIT_MODAL";


export const SETTINGS_INPUT_LABEL_CREATE_EDIT = "SETTINGS_INPUT_LABEL_CREATE_EDIT";
export const SETTINGS_INPUT_LABEL_CREATE_EDIT_RESPONSE = "SETTINGS_INPUT_LABEL_CREATE_EDIT_RESPONSE";

export const SETTINGS_INPUT_LABEL_OPEN_DELETE_MODAL  = "SETTINGS_INPUT_LABEL_OPEN_DELETE_MODAL";
export const SETTINGS_INPUT_LABEL_DELETE             = "SETTINGS_INPUT_LABEL_DELETE";
export const SETTINGS_INPUT_LABEL_DELETE_RESPONSE    = "SETTINGS_INPUT_LABEL_DELETE_RESPONSE";