export default {
    "FSM_SETTINGS_QUICK_MESSAGE": "Mensajes Rapidos",
    "FSM_SETTINGS_QUICK_MESSAGE_title": "Nuevo mensaje rápido",
    "FSM_SETTINGS_QUICK_MESSAGE_edit": "Editar mensaje rápido ",
    "FSM_SETTINGS_QUICK_MESSAGE_delete_detail":'¿Está seguro de que desea eliminar los siguientes mensaje rapido?',
    "FSM_SETTINGS_QUICK_MESSAGE_delete_warning":'Esta acción no se puede deshacer.',
    "FSM_SETTINGS_QUICK_MESSAGE_delete_title":'Eliminar mensaje rapido',
    "FSM_SETTINGS_QUICK_MESSAGE_create_success": "Mensaje Rapido Creado ",
    "FSM_SETTINGS_QUICK_MESSAGE_update_success": "Mensaje Rapido Actualizado",
    "FSM_SETTINGS_QUICK_MESSAGE_delete_success": "Mensaje Rapido Eliminado",

};