export default {
    speedOverride_create_title: 'Nueva Velocidad Corregida',
    speedOverride_create_edit_title: 'Velocidad Corregida',
    speedOverride_create_info: 'Navegue hasta una ubicación o introduzca una dirección en el campo de búsqueda del mapa para empezar. Haz clic en el tramo de carretera en el que comienza el control de velocidad para añadir un punto de inicio. A continuación, haz clic en el tramo de carretera en el que finaliza el control para añadir el punto final.',
    speedOverride_create_label: 'Nombre de Velocidad Corregida',
    speedOverride_create_segment: 'Segmento de Velocidad Corregida',
    speedOverride_create_start: 'Inicio',
    speedOverride_create_end: 'Final',
    speedOverride_create_exist_speedLimit: 'Límite de Velocidad existente',
    speedOverride_create_correct_speedLimit: 'Corrección de Velocidad Límite',
    speedOverride_create_notes: 'Notas',
    speedOverride_create_notes_placeholder: 'Ingrese una nota',
    speedOverride_create_route_valid: 'Antes de guardar es necesario crear una ruta válida en el mapa',
    speedOverride_create_sucessfull: "El límite de velocidad se ha almacenado correctamente",
    speedOverride_create_error_name: "El nombre establecido ya existe",
    speedOverride_create_error_address: "Las direcciones de inicio y fin ya han sido configuradas anteriormente.",
    speedOverride_create_error_distance: "La distancia de los 2 puntos debe ser superior a 15 metros."
};