/*********************************************************
 * Imports
 *********************************************************/
import React, { Fragment, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
/*********************************************************
 * Controls
 *********************************************************/
import { Grid } from '@mui/material';

/*********************************************************
 * Components
 *********************************************************/
import AutoCompleteMap from '../AutoCompleteMap'

/*********************************************************
 * Style
 *********************************************************/
import './style.scss'

/*********************************************************
 * Import Hooks
 *********************************************************/
import { useGeocoder } from 'Core/hooks'
//actions
import { addPointers, zoomPointOnMap } from "Redux/actions";

/*********************************************************
 * Author:  Walter Paz Londoño
 * Date:    29 Jan 2020
 * Module:  Realtime Maps
 * 
 * Class associated to the google map provider
 ********************************************************/
const InputSearchMap = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const { messages, restart, hideClear, addressValue, onClearSetAddress, onClearInput } = props;
  const [clear, setClear] = useState(false)
  const [onGetGeocoder, address] = useGeocoder()


  useImperativeHandle(ref, () => ({
    clear: clearAutocompleteDirectionsInput,
  }));

  /*********************************************************
   * Restart the control
  *********************************************************/
  useEffect(() => {
    if (restart > 0) {
      setClear(true)
    }
  }, [restart])

  /********************************************************
   * Clear the elements to find and remove the marker
   ********************************************************/
  const clearAutocompleteDirectionsInput = () => {
    setClear(true)
    if (!props.createElements) {
      dispatch(addPointers([]))
    }
  }

  /********************************************************
   * Using geocoder to get lat lng to the point
   ********************************************************/
  const getDirection = (placeId) => {
    onGetGeocoder({
      from: 'placeId', //latlng, address
      placeId: placeId
    })
  }

  /********************************************************
   * Validate dirección value from geocoder
   * @param {string} value as direction
   * @returns 
   ********************************************************/
  const validateDirection = (value) => {
    value = value.toString().replace(',', '')
    value = value.toString().replace(/\s/g, "")
    if (value) return true
    return false
  }

  /********************************************************
   * Create the marker into the map with the lat long geocoder
   ********************************************************/
  useEffect(() => {
    if (address && address.result == true) {
      const { data } = address
      if (props.onGetAddress) {
        props.onGetAddress(data)
        return
      }
      const pointer = {
        id: '01',
        lat: data[0].lat,
        lng: data[0].lng,
        label: validateDirection(data[0].fulladdress) ? data[0].fulladdress : '',
        showLabel: true,
        heading: 0,
        color: '000',
        eventName: 'no event',
        draggable: props.draggable || false
      }
      if (props.createElements) {
        dispatch(zoomPointOnMap(pointer));
      } else {
        dispatch(addPointers([pointer]));
      }
    }
  }, [address])

  /********************************************************
   * Perform the clear event to parent
   ********************************************************/
  const onClear = () => {
    setClear(false)
    if (props.onClear) {
      props.onClear()
    }
  }

  const getDirectionLatLng = (lat, lng) => {
    onGetGeocoder({
      from: 'latlng',
      lat: lat,
      lng: lng
    });
  }

  const styleSearch = { width: '100%', heigth: 30 };

  return (
    <div>
      {
        !props.noMap
          ?
          <Fragment>
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="flex-start"
              style={{ backgroundColor: 'transparent' }}
            >
              <Grid item xs>
                <AutoCompleteMap
                  clear={clear}
                  searchValueMap={props.searchValueMap}
                  getDirection={getDirection}
                  getDirectionLatLng={getDirectionLatLng} //find by lat - lng
                  clearCallback={() => onClear()}
                  disabled={props.disabled}
                  addressValue={addressValue || null}
                  onClearSetAddress={onClearSetAddress}
                  onClearInput={onClearInput}
                  placeholder={props?.placeholder}
                  noOptionsText={props?.noOptionsText}
                  hideClear={hideClear}
                />
              </Grid>
            </Grid>
          </Fragment>
          :
          <Fragment>
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="flex-start"
              style={{ backgroundColor: 'transparent' }}
            >
              <Grid item xs>
                <AutoCompleteMap
                  clear={clear}
                  getDirection={getDirection}
                  clearCallback={() => onClear()}
                  searchValueMap={props.searchValueMap}
                  styleAutoComplete={styleSearch}
                  disabled={props.disabled}
                  onKeyPress={props.onKeyPress}
                  addressValue={addressValue || null}
                  onClearSetAddress={onClearSetAddress}
                  onClearInput={onClearInput}
                  placeholder={props?.placeholder}
                  noOptionsText={props?.noOptionsText}
                  hideClear={hideClear}
                />
              </Grid>
            </Grid>
          </Fragment>
      }
    </div>
  )
});

export default InputSearchMap;
