import React, { useState, useEffect, memo } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
// Components
import { Loading, Button } from 'Components';
import VidFleetVideo from 'Modules/.common/components/VidFleetVideo';
import SmartWitnessSnapshot from 'Modules/.common/components/SmartWitnessSnapshot';
// Material UI
import { Grid } from '@mui/material';
import { checkDeviceData } from 'Core/data/Helpers'

import {
  getSharedMedia
} from 'Redux/actions';

const SharedMediaView = (props) => {
  const dispatch = useDispatch();
  const { messages } = props.intl;
  const { id, type } = useParams();
  const { loadingGetSharedMedia: loading, getSharedMediaResponse: response } = useSelector(state => state.sharedMediaRedux);

  const [options, setOptions] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(getSharedMedia({ uuid: id, type }));
    }
  }, [id]);
  
  useEffect(() => {
    if (response) {
      if (response.error) {
        setError(messages['sharedMedia_notFound']);
      } else {
        if (response?.entity?.name === "DeviceReading") {
          if (response?.mediaDetail?.deviceData) {
            try {

              let deviceData = checkDeviceData(response?.mediaDetail?.deviceData);
              //const deviceData = JSON.parse(JSON.parse(response?.mediaDetail?.deviceData));
              let videoOptions = {
                mode: 'picture',
                id: deviceData?.pictureID,
                pictureId: deviceData?.pictureID
              };

              if (deviceData?.clipID) {
                if (deviceData?.duration) {
                  videoOptions.mode = "video";
                } else {
                  videoOptions.thumbnailUrl = deviceData?.url;
                }
                videoOptions.id = deviceData?.clipID;
                videoOptions.pictureId = null;
              }

              setOptions({
                ...videoOptions,
                messages: messages,
                type: "playback",
                autoplay: true,
                deviceModel: response?.deviceModelName === '360' ? '360' : '4K'
              });
            } catch (e) {
              /* console.log("Error while parsing string json view SharedMedia"); */
            }
          }
        } else {
          setOptions({
            messages: messages,
            mode: 'video',
            type: "playback",
            id: response?.mediaDetail?.clipID,
            autoplay: false,
            deviceModel: response?.mediaDetail?.deviceModelName === '360' ? '360' : '4K',
          });
        }
      }
    }
  }, [response]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {
        loading
          ?
          <Loading full />
          :
          <div style={{ width: '100%', height: '100%', backgroundColor: 'black' }}>
            {
              options && (response?.mediaDetail?.unitType=="switness" || response?.mediaDetail?.deviceModelName=="SmartWitness") &&
              <div style={{width: 900, height: '100%',display:"table-caption",padding:10}}>
                 <SmartWitnessSnapshot height={600} indicators={true} vehicleTrail={{deviceData:response?.mediaDetail?.deviceData ||response?.mediaDetail?.details}} />
              </div>

            }
            {
              options &&  response?.mediaDetail?.unitType!=="switness" && response?.mediaDetail?.deviceModelName!=="SmartWitness" &&
                <VidFleetVideo  {...options} />
            }

            {
              error &&
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ padding: 20, height: '100%' }}
              >
                <Grid item>
                  <span style={{ fontSize: 18 }}>{error}</span>
                </Grid>

                <Grid item>
                  <Button
                    type="icon"
                    icon="refresh"
                    onClick={(e) => {
                      if (e) e.preventDefault();
                      setError(null);
                      dispatch(getSharedMedia({ uuid: id }));
                    }}
                  />
                </Grid>
              </Grid>
            }
          </div>
      }
    </div>
  );
};

export default injectIntl(memo(SharedMediaView));
