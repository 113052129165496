import {
    FSM_SETTINGS_MESSAGE_SEARCH,
    FSM_SETTINGS_MESSAGE_SEARCH_RESPONSE,
    FSM_SETTINGS_MESSAGE_CREATE,
    FSM_SETTINGS_MESSAGE_CREATE_RESPONSE,
    FSM_SETTINGS_MESSAGE_CREATE_ALL,
    FSM_SETTINGS_MESSAGE_CREATE_ALL_RESPONSE,
    FSM_SETTINGS_MESSAGE_GET_DRIVER_TO_DRIVER,
    FSM_SETTINGS_MESSAGE_GET_DRIVER_TO_DRIVER_RESPONSE,
    FSM_SETTINGS_MESSAGE_UPDATE_COMPDATA_FSM_ALL,
    FSM_SETTINGS_MESSAGE_UPDATE_COMPDATA_FSM_ALL_RESPONSE
} from "Redux/actionTypes";



export const searchMessage = (driverId,load) => {
    return {
        type: FSM_SETTINGS_MESSAGE_SEARCH,
        payload: { driverId,load },
    };
};

export const searchMessageResponse = (data) => {
    return {
        type: FSM_SETTINGS_MESSAGE_SEARCH_RESPONSE,
        payload: data,
    };
};

export const createMessage = (Message) => {
    return {
        type: FSM_SETTINGS_MESSAGE_CREATE,
        payload: Message,
    };
};

export const createMessageResponse = (data) => {
    return {
        type: FSM_SETTINGS_MESSAGE_CREATE_RESPONSE,
        payload: data,
    };
};


export const createMessageAll = (message) => {
    return {
        type: FSM_SETTINGS_MESSAGE_CREATE_ALL,
        payload: { message }
    };
};

export const createMessageAllResponse = (data) => {
    return {
        type: FSM_SETTINGS_MESSAGE_CREATE_ALL_RESPONSE,
        payload: data,
    };
};

export const getAllowDriverToDriver = () => {
    return {
        type: FSM_SETTINGS_MESSAGE_GET_DRIVER_TO_DRIVER,
        payload: {},
    };
};

export const getAllowDriverToDriverResponse = (data) => {
    return {
        type: FSM_SETTINGS_MESSAGE_GET_DRIVER_TO_DRIVER_RESPONSE,
        payload: data,
    };
};

export const updateAllowDriverToDriver = (action) => {
    return {
        type: FSM_SETTINGS_MESSAGE_UPDATE_COMPDATA_FSM_ALL,
        payload: action,
    };
};

export const updateAllowDriverToDriverResponse = (data) => {
    return {
        type: FSM_SETTINGS_MESSAGE_UPDATE_COMPDATA_FSM_ALL_RESPONSE,
        payload: data,
    };
};