export default {
    mapLandmarkDeleteLandmark_title:'Eliminar Puntos de Referencia',
    mapLandmarkDeleteLandmark_detail:'¿Está seguro que desea eliminar los siguientes puntos de referencia?', //Replace number value
    mapLandmarkDeleteLandmark_detail_one:'¿Está seguro que desea eliminar el punto de Referencia?', //Replace number value
    mapLandmarkDeleteLandmark_warning:'Esta acción no se puede deshacer.',
    mapLandmarkDeleteLandmark_success:'Puntos de referencia eliminados',
    mapLandmarkDeleteLandmark_success_one:'Puntos de referencia eliminado',
    mapLandmarkDeleteLandmark_remove_all_landmark_keyword: 'todos los Puntos de Referencia',
    mapLandmarkDeleteLandmark_remove_all: '¿Esta seguro de eliminar',
    mapLandmarkDeleteLandmark_remove_all_except: 'con excepción de la siguiente lista'
}