// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    MAP_LANDMARKS_GET_LANDMARK_GROUP,
    MAP_LANDMARKS_EDIT_CREATE_LANDMARK_GROUP
} from "Redux/actionTypes";

// Actions
import {
    mapLandmarksGetLandmarkGroupResponse,
    mapLandmarksEditCreateLandmarkGroupResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* mapLandmarksGetLandmarkGroupRequest({ payload }) {
    try {
        const { id } = payload;

        const response = yield call(
            clientQuery,
            `
                query getLandmarkGroup ($id: Int!) {
                    LandmarkGroup: getLandmarkGroup (id: $id) {
                        id
                        name
                        color
                        iconName
                    }
                }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );

        if (!response && !response.LandmarkGroup) throw `Error while getting landmark group: ${id}`;

        yield put(mapLandmarksGetLandmarkGroupResponse({ ...response.LandmarkGroup, error: false }));
    } catch (exc) {
        yield put(mapLandmarksGetLandmarkGroupResponse({ error: true }));
        ExceptionManager(
            exc,
            "realtimeMaps/main/components/landmarks/components/landmarkGroupCreateEdit/redux/saga",
            "mapLandmarksGetLandmarkGroupRequest"
        );
    }
}

function* mapLandmarksCreateEditLandmarkGroupRequest({ payload }) {
    try {
        const { landmarkGroup } = payload;

        let query = `
            mutation createLandmarkGroup (
                $name: String!, 
                $color: String!, 
                $iconName: String
            ) {
                LandmarkGroup: createLandmarkGroup (
                    name: $name, 
                    color: $color, 
                    iconName: $iconName
                ) {
                    id
                    name
                    color
                    iconName
                }
            } 
        `;

        if (landmarkGroup?.id) {
            query = `
                mutation updateLandmarkGroup (
                    $id: Int!, 
                    $name: String, 
                    $color: String, 
                    $iconName: String
                ) {
                    LandmarkGroup: updateLandmarkGroup (
                        id: $id, 
                        name: $name, 
                        color: $color, 
                        iconName: $iconName
                    ) {
                        id
                        name
                        color
                        iconName
                    }
                } 
            `;
        }

        const response = yield call(
            clientMutation,
            query,
            landmarkGroup,
            endpoints.GRAPHQL_GENERAL,
        );

        if (!response || !response.LandmarkGroup) throw `Error while creating/editing landmark groups: ${landmarkGroup}`;

        yield put(mapLandmarksEditCreateLandmarkGroupResponse({ ...response.LandmarkGroup, error: false }));
    } catch (exc) {
        let message = null
        if (exc.errorData && exc.errorData.errors) {
            exc.errorData.errors.forEach(error => {
                if (error.errorInfo && error.errorInfo.code) {
                    message = error.message
                }
            });
        }

        yield put(mapLandmarksEditCreateLandmarkGroupResponse({ error: true, message: message }));
    }
}

function* watchMapLandmarkGroupsMethods() {
    yield takeEvery(MAP_LANDMARKS_GET_LANDMARK_GROUP, mapLandmarksGetLandmarkGroupRequest);
    yield takeEvery(MAP_LANDMARKS_EDIT_CREATE_LANDMARK_GROUP, mapLandmarksCreateEditLandmarkGroupRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMapLandmarkGroupsMethods)]);
}