export const getIntegerRange = (enumReportType, otherFilters) => {
  let integerRangesOption = {
    speedReport: () => {
      return [
        {
          fields: [
            "speed"
          ],
          LTE: 300,
          GTE: +otherFilters?.speed || 0
        },
        {
          fields: [
            "speedLimitDelta"
          ],
          LTE: +otherFilters?.speedLimitDelta || 0,
          GTE: 0
        }
      ]
    },
    idleReport: () => {
      const minutes = otherFilters.minute || 0;
      return  [
        {
          fields: [
            "idle"
          ],
          GTE: minutes,
          LTE: 0
        }
      ]
    },
    stopReport: () => {
      return [
        {
          fields: [
            "stop"
          ],
          GTE: otherFilters.minute || 0,
          LTE: 0
        }
      ]
    },
    formStopSummaryReport: () => {
      return [
        {
          fields: [
            "stop"
          ],
          GTE: otherFilters.minute || 0,
          LTE: 0
        }
      ]
    },
    formStopSummaryReportV2: () => {
      return [
        {
          fields: [
            "stop"
          ],
          GTE: otherFilters.minute || 0,
          LTE: 0
        }
      ]
    }
  };
  return integerRangesOption[enumReportType] ? integerRangesOption[enumReportType]() : [];
}