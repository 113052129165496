import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// controls
import Table, { Column } from '../../table'
import {
  formatDatetime,
  getCategoryFilterType,
  useHandleExportReport,
  getEntityType
} from 'Modules/reports/views/main/components/reportsTab/helper';

import { useSidePanelMap, useTableReport } from 'Core/hooks';


import {
  reportsMainGenerateEvents
} from 'Redux/actions'

const MilesPerGallonReport = (props) => {
  const { messages, report } = props;
  const { loading, data, filters, events, summary, loadingSummary, subEvents, loadingSubEvents, timeZone } = report;
  const { filterEntity, filterArray, otherFilters, filterType, startRange, endRange } = filters;

  const reportRef = useRef();
  const dispatch = useDispatch()
  const exportHandler = useHandleExportReport(report);

  useEffect(() => {
    if (report) {
      const newReport = JSON.parse(JSON.stringify(report));
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);



  const onChangeExpandable = (unit) => {
    if(!unit.noData){
      let events = {
        data: [],
        filters: {
          filterType: getCategoryFilterType(filterEntity),
          filterArray: unit,
          page: 1,
          rowsPerPage: 10,
          sortField: 'eventDate',
          otherFilters
        }
      }
      dispatch(reportsMainGenerateEvents(report, events))
    }
  }

  const formatDate = (date) => {
    let dateFormat = new Date(new Date(date).toISOString().slice(0, -1));
    return moment(dateFormat).format('MMM DD YY, h:mm: a')
  };
  let parametersSummary = useTableReport({ data, report, messages, hideHeader: false });



  const renderExpandableRow = (row) => {
    let parametersSummaryTable = {
      ...parametersSummary,
      data: summary?.items || [],
      totalItems: summary?.total || 0,
      hideHeader: false,
      serverSideTable: true,
      onChangedPage: (page, rowsPerPage, sortField, sortOrder) => {
        let events = {
          data: [],
          filters: { filterType: getCategoryFilterType(filterEntity), filterArray: row, page, rowsPerPage, sortField, sortOrder, otherFilters }
        }
        dispatch(reportsMainGenerateEvents(report, events))
      },
      onChangeSelector: null,
      itemsSelector: null,
      generateExport: false,
      hideSelectorUnitsDriver: true,
      groups: report.filters.units || [],
      hideNumberOfRows: true,
      centerPagination: true,
      noData: row.noData
    };
    return (
      <Table
        {...parametersSummaryTable}
        loading={loadingSummary}
        classNameColumns={"report-sub-events-columns"}
      >

        <Column label={messages['generatedReport_timeAndDate']} field="dateTime" noSorting>
          {(element) => {
            return (
              formatDate(element?.dateTime)
            );
          }}
        </Column>
        <Column label={messages['reportsMainRT_card']} field="card" noSorting>
          {(element) => {
            return (
              element?.card
            );
          }}
        </Column>
        <Column label={messages['generatedReport_mpg']} field="mpg" noSorting>
          {(element) => {
            return (
              element?.mpg
            );
          }}
        </Column>

      </Table>
    )
  }

  let parametersTable = useTableReport({
    data: data.items,
    report,
    messages,
    hideHeader: true,
    renderExpandableRow,
    onChangeExpandable,
    propertyToShowExpandable: "id",
    classNameColumns: "table-report-main-expandible"
  });

  return (
    <Fragment>
      <Table
        {...parametersTable}
        {...exportHandler}
      >
        <Column label="Day" field="label">
          {(element) => {
            const type = getEntityType(report, messages)
            return <span><strong>{type}:  </strong>{`${element.idLabel || ''}`}</span>

          }}
        </Column>
        <Column label={messages['generatedReport_totalIdleTime']} field="label">
          {(element) => {
            const localTimeStart = moment.utc(startRange).local().format();
            const localTimeEnd = moment.utc(endRange).local().format();
            return (
              <div>
                <span>
                  <span>
                    <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                  </span>
                </span>
              </div>
            );
          }}
        </Column>
      </Table>
    </Fragment>
  );
}

export default MilesPerGallonReport
