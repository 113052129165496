import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';

// Actions 
import { reportsMainGenerateReportProgress } from 'Redux/actions';

// Helpers
import {
  getTimeZone,
  getCategoryFilter
} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getFormStopSummaryReport(report, event) {
  const { timeZone, name,reportTimeRanges } = report;
  const { startRange, endRange, filterType, sortField, sortOrder,otherFilters } = report.filters;
  const { filterArray } = event.filters;
  try {
    let data = yield call(
      clientQuery,
      `query getFormStopSummaryReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
          Report: getFormStopSummaryReport (conditions: $conditions, limit: $limit, offset: $offset) {
            summaryInfo {
              dateList
              id
            }
            total
          }
        }`,
      {
        conditions: {
          reportName: name,
          category: {
            field: getCategoryFilter(filterType) || filterType,
            value: filterArray
          },
          timeZone: getTimeZone(timeZone),
          dateRange: {
            fields: [
              "unitTime"
            ],
            GTE: startRange,
            LTE: endRange,
            reportTimeRanges
          },
          integerRanges: {
            fields: [
              "stop"
            ],
            GTE: otherFilters.minute || 0,
            LTE: 0
          },
          sortField: {
            field: sortField || "driverId",
            order: sortOrder === 'desc' ? 'DESC' : 'ASC'
          }
        },
        limit: 10,
        offset: 0
      },
      endpoints.GRAPHQL_PX_REPORTING
    );

    if (data && data.Report) {
      report.summary = data.Report
      report.loadingSummary = false
      yield put(reportsMainGenerateReportProgress(report))
    } else {
      report.loadingSummary = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  } catch (exc) {
    report.loadingSummary = false
    yield put(reportsMainGenerateReportProgress(report))
  }
}

export default getFormStopSummaryReport;