import {
    ACCOUNT_TAGS_OPEN_CLOSE_DELETE_TAGS_MODAL,
    ACCOUNT_TAGS_DELETE_TAGS,
    ACCOUNT_TAGS_DELETE_TAGS_RESPONSE
} from "Redux/actionTypes";

export const accountTagsOpenCloseDeleteTagsModal = (open, selected) => {
    return {
        type: ACCOUNT_TAGS_OPEN_CLOSE_DELETE_TAGS_MODAL,
        payload: { open, selected },
    };
};

export const accountTagsDeleteTags = (data) => {
    return {
        type: ACCOUNT_TAGS_DELETE_TAGS,
        payload: { data },
    };
};

export const accountTagsDeleteTagsResponse = (response) => {
    return {
        type: ACCOUNT_TAGS_DELETE_TAGS_RESPONSE,
        payload: { response } ,
    };
};