import {
    FSM_SETTINGS_TIME_CARD_SEARCH,
    FSM_SETTINGS_TIME_CARD_SEARCH_RESPONSE,
    FSM_SETTINGS_TIME_CARD_CREATE,
    FSM_SETTINGS_TIME_CARD_CREATE_RESPONSE,
    FSM_SETTINGS_TIME_CARD_UPDATE,
    FSM_SETTINGS_TIME_CARD_UPDATE_RESPONSE,
    FSM_SETTINGS_TIME_CARD_DELETE,
    FSM_SETTINGS_TIME_CARD_DELETE_RESPONSE,
    FSM_SETTINGS_TIME_CARD_OPEN_CREATE_EDIT,
    FSM_SETTINGS_TIME_CARD_OPEN_DELETE,
    FSM_SETTINGS_TIME_CARD_DRIVER,
    FSM_SETTINGS_TIME_CARD_DRIVER_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loadingTimeCard: false,
    quickMData: [],
    TimeCard: {},
    TimeCardDrivers: [],
    loadingCreateEdit: false,
    loadingTimeCardDrivers: false,
    openCreateEditTimeCard: false,
    loadingDelete: false,
    response: {},
    responseDelete: false,
    openDelete: false,
    timeCardDelete: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case FSM_SETTINGS_TIME_CARD_SEARCH:
            let paramsLoad = action?.payload?.load ? {} : { loadingTimeCard: true, quickMData: [] };
            return {
                ...state,
                ...paramsLoad
            };

        case FSM_SETTINGS_TIME_CARD_SEARCH_RESPONSE:
            return {
                ...state,
                loadingTimeCard: false,
                quickMData: action?.payload?.timeCard
            };

        case FSM_SETTINGS_TIME_CARD_DRIVER:
            return {
                ...state,
                TimeCardDrivers: [],
                loadingTimeCardDrivers: true
            };

        case FSM_SETTINGS_TIME_CARD_DRIVER_RESPONSE:
            return {
                ...state,
                TimeCardDrivers: action?.payload?.drivers,
                loadingTimeCardDrivers: false

            };

        case FSM_SETTINGS_TIME_CARD_CREATE:
            return {
                ...state,
                loadingCreateEdit: true,
                response: {}
            };

        case FSM_SETTINGS_TIME_CARD_CREATE_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                response: action.payload
            };

        case FSM_SETTINGS_TIME_CARD_UPDATE:
            return {
                ...state,
                loadingCreateEdit: true,
                response: {}
            };

        case FSM_SETTINGS_TIME_CARD_UPDATE_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                response: action.payload
            };

        case FSM_SETTINGS_TIME_CARD_DELETE:
            return {
                ...state,
                loadingDelete: true,
                responseDelete: false
            };

        case FSM_SETTINGS_TIME_CARD_DELETE_RESPONSE:
            return {
                ...state,
                loadingDelete: false,
                responseDelete: action.payload?.data
            };

        case FSM_SETTINGS_TIME_CARD_OPEN_CREATE_EDIT:
            return {
                ...state,
                openCreateEditTimeCard: action.payload?.open,
                TimeCard: action.payload?.timeCard,
            };

        case FSM_SETTINGS_TIME_CARD_OPEN_DELETE:
            return {
                ...state,
                openDelete: action.payload?.open,
                timeCardDelete: action.payload?.timeCards,
            };

        case CLEAR_REDUX:
            return ["", "FSM_SETTINGS_TIME_CARD"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return state;
    }
};