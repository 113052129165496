//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientGraphQLAuthMutation } from 'Core/data/GraphQLApi';
import { LogManager, ExceptionManager } from 'Core/logManager';
// import { endpoints } from 'Core/defaultValues';

//Action types
import { FORGOT_PASSWORD, FORGOT_PASSWORD_CONFIRM } from 'Redux/actionTypes';

//actions
import { forgotPasswordResult, forgotPasswordConfirmResult } from 'Redux/actions';

/* FUNTIONS ASYNC */

function* forgotPasswordRequest({ payload }) {
	try {
		const { username } = payload;

		let data = yield call(
			clientGraphQLAuthMutation,
			`
				mutation ($username: String!) {
					result: forgotPassword(username: $username) { username }
				}
			`,
			{
				username,
			}
		);

		if (data && data.result) {
			yield put(forgotPasswordResult(true));
		} else {
			yield put(forgotPasswordResult(false, data.errorMessage));
		}
	} catch (exc) {
		// catch throw
		ExceptionManager(exc, 'Core/views/forgotPassword/redux', 'forgotPasswordRequest');
		yield put(forgotPasswordResult(false, exc.toString()));
	}
}

function* watchForgotPasswordRequest() {
	yield takeEvery(FORGOT_PASSWORD, forgotPasswordRequest);
}

function* forgotPasswordConfirmRequest({ payload }) {
	try {
		const { username, confirmationCode, password, confirmPassword } = payload;

		if (password !== confirmPassword) {
			LogManager('confirmpass', password, confirmPassword);
			yield put(forgotPasswordConfirmResult(false, 'forgotPassword_passwordError'));
			return;
		}

		let data = yield call(
			clientGraphQLAuthMutation,
			`
				mutation ($confirmationCode: String!, $username: String!, $password: String!  ) {
						result: confirmForgotPassword (
							confirmationCode: $confirmationCode
							username: $username
							password: $password
						)
				}
			`,
			{
				username,
				confirmationCode,
				password,
			}
		);

		if (data && data.result) {
			yield put(forgotPasswordConfirmResult(true));
		} else {
			yield put(forgotPasswordConfirmResult(false, data.errorMessage));
		}
	} catch (exc) {
		// catch throw
		ExceptionManager(exc, 'Core/views/forgotPassword/redux', 'forgotPasswordConfirmRequest');
		yield put(forgotPasswordConfirmResult(false, exc.toString()));
	}
}

function* watchForgotPasswordConfirmRequest() {
	yield takeEvery(FORGOT_PASSWORD_CONFIRM, forgotPasswordConfirmRequest);
}

//merge sagas
export default function* rootSaga() {
	yield all([fork(watchForgotPasswordRequest), fork(watchForgotPasswordConfirmRequest)]);
}
