export default {
    warnings_main_issues: "Problemas",
    warnings_main_resolved: "Resueltos",
    warnings_main_sd_card_status_is: "El estado de la tarjeta SD es",
    warnings_main_last_status: "Último estado",
    warnings_main_resolve_all: "Resolver Todo",
    warnings_main_clear_all: "Eliminar Todo",
    warnings_main_search_by_warning: "Buscar por Advertencia",
    warnings_main_select: "Seleccionar",
    warnings_main_clear: "Eliminar",
    warnings_main_revolve_issue: "Resolver el Problema",
    warnings_main_first_occurrence: "Primera Incidencia",
    warnings_main_last_occurrence: "Última Incidencia",
    warnings_main_resolved_on: "Resuelto En",
    warnings_main_resolved_by: "Resuelto Por",
    warnings_main_occurrences: "Ocurrencias",
    warnings_main_details: "Detalles",
    warnings_main_close: "Cerrar",
    warnings_main_resolve_all_warnings: "Resolver Todas las Advertencias",
    warnings_main_resolve_warning: "Resolver Advertencia",
    warnings_main_success_message_resolved_all: "Las advertencias se han resuelto",
    warnings_main_success_message_resolved: "La advertencia se ha resuelto",
    warnings_main_error_message_resolved_all: "Las advertencias no se han podido resolver debido a un error",
    warnings_main_error_message_resolved: "No se ha podido resolver la advertencia debido a un error",
    warnings_main_message_resolved_all: "¿Está seguro de que desea resolver todas las advertencias? Esta acción no se puede deshacer.",
    warnings_main_message_resolved: "¿Está seguro de que desea resolver la advertencia? Esta acción no se puede deshacer.",
    warnings_main_resolve: "Resolver",
    warnings_main_delete_warnings: "Eliminar Advertencias",
    warnings_main_delete_warning: "Eliminar Advertencia",
    warnings_main_success_message_delete_all: "Se han eliminado las advertencias",
    warnings_main_success_message_delete: "Se ha eliminado la advertencia",
    warnings_main_error_message_delete_all: "No se han podido eliminar las advertencias debido a un error",
    warnings_main_error_message_delete: "No se ha podido eliminar la advertencias debido a un error",
    warnings_main_message_delete_all: "¿Está seguro de que desea borrar todas las advertencias? Esta acción no se puede deshacer.",
    warnings_main_message_delete: "¿Estás seguro de que quieres borrar la advertencias? Esta acción no se puede deshacer.",
    warnings_main_delete: "Eliminar",
    warnings_main_warning: "Advertencia",
    warnings_main_warning_time: "Hora de Advertencia",
    warnings_main_value: "Valor",
    warnings_main_there_is_no_warnings: "No hay Advertencias",
    warnings_main_AuxCamDisconnected: "Cámara Aux Desconectada",
    warnings_main_BatteryVoltageLow: "Tensión de la Batería Baja",
    warnings_main_CameraDisconnectedFromServerOver7Days: "Cámara desconectada del servidor durante 7 días",
    warnings_main_CameraTilted: "Cámara Inclinada",
    warnings_main_dmsStatusChange: "Cambio de Estado de DMS",
    warnings_main_DrivingOver18Hours: "Conducir más de 18 Horas",
    warnings_main_FailedToEnterStandby: "Error al Entrar en Modo de Espera",
    warnings_main_FormatSDCard: "Formatear Tarjeta SD",
    warnings_main_GPSGapTooLarge: "Espacio GPS Demasiado Grande",
    warnings_main_LowVBUSVoltage: "Tensión VBUS baja",
    warnings_main_MCUDisconnected: "MCU Desconectado",
    warnings_main_ReplaceSDCard: "Reemplazar Tarjeta SD",
    warnings_main_SDCardStatus: "Estado de la Tarjeta SD",
    warnings_main_SIMCardInfoChanged: "Información de la Tarjeta SIM Modificada",
    warnings_main_UnfavorableAngleOfRoadView: "Ángulo Desfavorable de Visión de la Carretera",
    warnings_main_VinNumChanged: "Vin Num Cambiado",
    warnings_main_UnsupportedVideoConfigCombinations: "Combinaciones de Configuración de Vídeo no Compatibles: Compruebe las Restricciones en el Documento",
    warnings_main_HDOPQuality: "Calidad HDOP",
    warnings_main_ExcessiveEvents: "Eventos Excesivos",
    warnings_main_LowBattery: "Batería Baja",
    warnings_main_IgnitionOnEventsAbnormal: "Encendido Anormal",
    warnings_main_PowerDisconnect: "Desconexión de la Alimentación",
    warnings_main_LowSatellites: "Satélites Bajos",
    warnings_main_DailyOdometerExceeded: "Cuentakilómetros Diario Superado",
    warnings_main_WirelessSignal: "Señal Inalámbrica",
    warnings_main_MaintenanceLimitExceeded: "Límite de Mantenimiento Superado",
    warnings_main_LateEvents: "Eventos Tardíos",
    warnings_main_CommLost: "Comunicación Perdida",
    warnings_main_DailyEngineHoursExceeded: "Horas Diarias de Motor Superadas",
    warnings_main_description_comming_soon: "Esta advertencia se ha producido debido a un comportamiento inesperado del dispositivo, póngase en contacto con el servicio de asistencia para resolverlo.",
    warnings_main_back_to_issues: "Volver a Problemas",
    warnings_main_offline: "Fuera de línea",
    warnings_main_revolve_selected: "Resolver Seleccionados",
    warnings_main_message_resolved_selected: "¿Está seguro de que desea resolver las advertencias seleccionadas? Esta acción no se puede deshacer.",
    warnings_main_resolve_warnings: "Resolver Advertencias",
    warnings_main_device_support: "Soporte Para Dispositivos",
    warnings_main_delete_selected: "Eliminar Seleccionados",
    warnings_main_message_deleted_selected: "¿Está seguro de que desea eliminar las advertencias seleccionadas? Esta acción no se puede deshacer.",
    warnings_main_warnings: "Advertencias de Dispositivos",
    warnings_main_definition: "Definición",
    warnings_main_first_message: "Este dispositivo no ha reportado",
    warnings_main_second_message: "Utilice la opción Solución de problemas o Póngase en contacto con el servicio de asistencia.",
}