import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// controls
import Table, {Column} from '../../table'
//helpers
import {formatDatetime, getCategoryFilterType, getNameEntity, useHandleExportReport} from 'Modules/reports/views/main/components/reportsTab/helper';

//hooks
import {useSidePanelMap, useTableReport} from 'Core/hooks';
//actions
import {clearRedux, reportsMainGenerateEvents, reportsMainGenerateReportUpdate} from 'Redux/actions'
import moment from 'moment';

// styles
import './landmarkEngineHoursReport.scss';

const LandmarkEngineHoursReport = (props) => {
    const {messages, report} = props;
    const {data, filters, summary, loadingSummary, timeZone} = report;
    const {filterEntity, otherFilters, startRange, endRange} = filters;

    const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
    const [unitDriverSelected, setUnitDriverSelected] = useState();
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
    const [currentNewSortField, setCurrentNewSortField] = useState("deviceId");
    const [currentNewSortOrder, setCurrentNewSortOrder] = useState("asc");

    const exportHandler = useHandleExportReport(report)

    const loadingCreateLandMark = useSelector(state => state.vehicleLandMarkRedux.loadingCreateLandMark)
    const createLandMarkResult = useSelector(state => state.vehicleLandMarkRedux.createLandMarkResult)

    const reportRef = useRef();
    const dispatch = useDispatch()
    useEffect(() => {
        if (report) {
            const newReport = JSON.parse(JSON.stringify(report));
            // to keep the reference to the units selected
            reportRef.current = newReport;
        }
    }, [JSON.stringify(report)]);

    useEffect(() => {
        if (!loadingCreateLandMark && createLandMarkResult) {
            dispatch(reportsMainGenerateReportUpdate(report, currentPage, currentRowsPerPage, currentNewSortField, currentNewSortOrder))
            dispatch(clearRedux("LANDMARK", {"createLandMarkResult": null}));
        }

    }, [loadingCreateLandMark, createLandMarkResult])

    const onChangeExpandable = (unit) => {
        if(!unit.noData) {
            let events = {
                data: [],
                filters: {
                    filterType: getCategoryFilterType(filterEntity),
                    filterArray: unit,
                    page: 1,
                    rowsPerPage: 10,
                    sortField: 'eventDate',
                    otherFilters
                }
            }
            dispatch(reportsMainGenerateEvents(report, events))
        }
    }

    const renderExpandableRow = (row) => {
        return (
            <Table
                loading={loadingSummary}
                data={summary?.items || []}
                totalItems={summary?.total || 0}
                onChangedPage={(page, rowsPerPage, sortField, sortOrder) => {
                    let events = {
                        data: [],
                        filters: {filterType: getCategoryFilterType(filterEntity), filterArray: row, page, rowsPerPage, sortField, sortOrder, otherFilters}
                    }
                    dispatch(reportsMainGenerateEvents(report, events))
                }}
                serverSideTable={true}
                hideNumberOfRows={true}
                hiddenPagination={false}
                classNameColumns={'report-column-events-expandible'}
                noDataMessage={messages["generatedReport_noEvents"]}
                numberofRows={10}
                centerPagination
                noData={row.noData}
            >
                <Column label={messages["generatedReport_date"]} field="date" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span className="speed-report-stacked">{element.date}</span>
                            </div>
                        )
                    }}
                </Column>
                <Column label={messages["generatedReport_landmarkLabel"]} field="landmarkName" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span className="speed-report-stacked">{element.landmarkName}</span>
                            </div>
                        )
                    }}
                </Column>
                <Column label={messages["generatedReport_vehicle_group"]} field="groupIdLabel" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span className="speed-report-stacked">{element.groupIdLabel}</span>
                            </div>
                        )
                    }}
                </Column>
                <Column label={messages["generatedReport_totalVisits"]} field="totalVisits" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span className="speed-report-stacked">{element.totalVisits}</span>
                            </div>
                        )
                    }}
                </Column>
                <Column label={messages["generatedReport_totalVisitDuration"]} field="totalVisitsDuration" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span className="speed-report-stacked">{element.totalVisitsDuration}</span>
                            </div>
                        )
                    }}
                </Column>
                <Column label={messages["generatedReport_engineHours"]} field="engineHours" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span className="speed-report-stacked">{element.engineHours}</span>
                            </div>
                        )
                    }}
                </Column>
            </Table>
        )
    }

    let parametersTable = useTableReport({
        data: data.items,
        report,
        messages,
        hideHeader: true,
        renderExpandableRow,
        onChangeExpandable,
        propertyToShowExpandable: 'id',
        classNameColumns: 'start-stop-idle-main-table'
    });

    return (
        <Fragment>
            <Table
                {...parametersTable}
                {...exportHandler}
            >
                <Column label="Day" field="label" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span>
                                  <span><strong>{getNameEntity(filterEntity, messages)}:</strong> {element.idLabel} </span>
                                </span>
                            </div>
                        );
                    }}
                </Column>
                <Column label={messages['generatedReport_totalIdleTime']} field="label" noSorting>
                    {(element) => {
                        const localTimeStart = moment.utc(startRange).local().format();
                        const localTimeEnd = moment.utc(endRange).local().format();
                        return (
                            <div>
                                <span>
                                  <span>
                                    <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                                  </span>
                                </span>
                            </div>
                        );
                    }}
                </Column>
            </Table>
            <SideMapComponent hiddenButton hiddenSearchMap/>
        </Fragment>
    );
}

export default LandmarkEngineHoursReport;
