// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    ACCOUNT_API_KEYS_SEARCH,
    ACCOUNT_API_KEYS_GET_API_KEY,
    ACCOUNT_API_KEYS_DELETE_API_KEYS,
    ACCOUNT_API_KEYS_CREATE_EDIT_API_KEY
} from "Redux/actionTypes";

// Actions
import {
    searchAccountApiKeysResponse,
    deleteAccountApiKeysResponse,
    createEditAccountApiKeyResponse,
    getAccountApiKeyResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";
import { NotificationManager } from 'Components/Notifications';

function* searchAccountApiKeysRequest({ payload }) {
    try {
        const { openSearch, nextToken } = payload;
        const query = `
            query searchApiKeys($openSearch: String, $nextToken: String) {
                ApiKeys: searchApiKeys(openSearch: $openSearch, nextToken: $nextToken) {
                    items {
                        id
	                    name
                        key
                    }
                    nextToken
                }
            }
        `;

        const response = yield call(
            clientQuery,
            query,
            {
                openSearch,
                nextToken
            },
            endpoints.GRAPHQL_GENERAL
        );

        if (!response?.ApiKeys) {
            throw 'Error while getting API keys';
        }

        const { ApiKeys: { items, nextToken: newNextToken } } = response;
        yield put(searchAccountApiKeysResponse({ items: items, nextToken: newNextToken, error: false }));
    } catch (exc) {
        yield put(searchAccountApiKeysResponse({ items: [], nextToken: null, error: true }));
        ExceptionManager(
            exc,
            "modules/account/views/apiKeys/redux/sagas",
            "searchAccountApiKeysRequest"
        );
    }
}

function* getApiKeysRequest({ payload }) {
    try {
        const { id } = payload;
        const query = `
            query getApiKey ($id: String!) {
                ApiKey: getApiKey (id: $id) {
                    id
                    name
                    key
                }
            }
        `;

        const response = yield call(
            clientQuery,
            query,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );

        if (!response?.ApiKey) throw `Error while getting API key ${id}`;

        yield put(getAccountApiKeyResponse({ ...response?.ApiKey, error: false }));
    } catch (exc) {
        yield put(getAccountApiKeyResponse({ error: true }));
        ExceptionManager(
            exc,
            "modules/account/views/apiKeys/redux/sagas",
            "getApiKeysRequest"
        );
    }
}

function* deleteAccountApiKeysRequest({ payload }) {
    try {
        const { items } = payload;
        let mutations = [];
        items?.forEach((item, index) => {
            mutations.push(`
                ApiKey${index}: deleteApiKey(id: "${item?.id}")
            `);
        });

        let mutation = `mutation{${mutations.join("")}}`;

        const response = yield call(
            clientMutation,
            mutation,
            {},
            endpoints.GRAPHQL_GENERAL,
        );

        if (!response || !response.ApiKey0) throw 'Error while deleting account API keys';

        yield put(deleteAccountApiKeysResponse({ ...response, error: false }));
    } catch (exc) {
        yield put(deleteAccountApiKeysResponse({ error: true }));
        ExceptionManager(
            exc,
            "modules/account/views/apiKeys/redux/sagas",
            "deleteAccountApiKeysRequest"
        );
    }
}

function* createEditAccountApiKeyRequest({ payload }) {
    try {
        const { apiKey } = payload;

        let query = `
            mutation createApiKey ($name: String!) {
                ApiKey: createApiKey (name: $name) {
                    id
                    name
                    key
                }
            } 
        `;

        if (apiKey?.id) {
            query = `
                mutation updateApiKey ($id: String!, $name: String!) {
                    ApiKey: updateApiKey (id: $id, name: $name) {
                        id
                        name
                        key
                    }
                } 
            `;
        }

        const response = yield call(
            clientMutation,
            query,
            apiKey,
            endpoints.GRAPHQL_GENERAL,
        );

        if (!response || !response.ApiKey) throw 'Error while creating api key';

        yield put(createEditAccountApiKeyResponse({ ...response.ApiKey, error: false }));
    } catch (exc) {
        yield put(createEditAccountApiKeyResponse({ error: true }));
        ExceptionManager(
            exc,
            "modules/account/views/apiKeys/redux/sagas",
            "createEditAccountApiKeyRequest"
        );
    }
}

function* watchMethods() {
    yield takeEvery(ACCOUNT_API_KEYS_SEARCH, searchAccountApiKeysRequest);
    yield takeEvery(ACCOUNT_API_KEYS_GET_API_KEY, getApiKeysRequest);
    yield takeEvery(ACCOUNT_API_KEYS_DELETE_API_KEYS, deleteAccountApiKeysRequest);
    yield takeEvery(ACCOUNT_API_KEYS_CREATE_EDIT_API_KEY, createEditAccountApiKeyRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}