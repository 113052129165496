//imports
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { ExceptionManager } from "Core/logManager";
import { endpoints } from "Core/defaultValues";
import { uploadFile } from "Core/data/FileUpload";

//Action Types
import {
  DOWNLOAD_ATTACHMENT_URL,
  UPLOAD_ATTACHMENT,
  GET_USER_LANGUAGE,
  GENERIC_EXPORT_SEND,
} from "Redux/actionTypes";

//Actions
import {
  downloadAttachmentUrlResult,
  uploadAttachmentResult,
  sendGenericExportResult,
  getUserLanguageResult,
} from "Redux/actions";

import axios from "axios";

// eslint-disable-next-line no-unused-vars
function* getUrlDownloadAttachment({ payload }) {
  try {
    const { id } = payload;
    let data = yield call(
      clientQuery,
      `query (
        $id: Int!
      ){
        downloadAttachmentUrl: downloadAttachmentUrl(
          id: $id
        )
      }`,
      {
        id,
      },
      endpoints.GRAPHQL_GENERAL
    );
    if (data && data.downloadAttachmentUrl && data.downloadAttachmentUrl) {
      yield put(downloadAttachmentUrlResult(data.downloadAttachmentUrl));
      window.open(data.downloadAttachmentUrl, "_blank");
    } else throw "There are not url attachment";
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(
      exc,
      "modules/maintenance/views/serviceDetail/redux/saga",
      "getUrlDownloadAttachment"
    );
    yield put(downloadAttachmentUrlResult(null));
  }
}

//userLanguage
function* getUserLanguage() {
  try {
    let lang = localStorage.getItem("lan");
    if (!lang) localStorage.setItem("lan", "en");
    lang = lang[0].toUpperCase() + lang.slice(1);

    let url = `https://s3.amazonaws.com/${endpoints.REPOSITORY_CODE}/languages/languagelabels_${lang}.js?date=${new Date().getTime()}`;
    // Get info resellers from S3
    let langKeys = yield call(async () => {
      const res = axios.get(url).then((langKeys) => {
        return langKeys?.data;
      });
      return res;
    });
    yield put(getUserLanguageResult())
    return localStorage.setItem("langByS3", JSON.stringify(langKeys));
  } catch (err) {
    yield put(getUserLanguageResult())
    return localStorage.setItem("langByS3", JSON.stringify('inavailable'));
  }
}

function* uploadAttachment({ payload }) {

  const { fileKey, originalName } = payload;
  let extension = originalName ? originalName.split(".").pop() : fileKey?.name.split(".").pop();

  try {
    //Request to the API for a "Presigned URL"
    let presignedUrlResponse = yield call(
      clientQuery,
      `query(
				$repositoryCode: String
				$fileExtension: String
			) {
				Data: getPresignedUrl(
					repositoryCode: $repositoryCode,
					fileExtension: $fileExtension
				) {
					repositoryCode
					fileKey
					url
				}
			}`,
      {
        repositoryCode: endpoints.REPOSITORY_CODE,
        fileExtension: extension,
      },
      endpoints.GRAPHQL_GENERAL
    );

    if (!presignedUrlResponse || !presignedUrlResponse.Data) {
      throw "There are a problem uploading the File";
    }

    //Upload the image by the pre-signed url
    yield uploadFile(presignedUrlResponse.Data.url, fileKey);

    //Register file
    let registerAttachmentResult = yield call(
      clientMutation,
      `mutation ($repositoryCode: String!, $fileKey: String!, $originalName: String, $entityName: String){
        attachment: registerAttachment(repositoryCode: $repositoryCode, fileKey: $fileKey, originalName: $originalName, entityName: $entityName){
          id
					repositoryId
					repositoryType
					repositoryCode
					repositoryPath
					fileKey
					originalName
        }
      }`,
      {
        repositoryCode: presignedUrlResponse.Data.repositoryCode,
        fileKey: presignedUrlResponse.Data.fileKey,
        originalName: originalName ? originalName : fileKey?.name,
        entityName: payload?.entityName
      },
      endpoints.GRAPHQL_GENERAL
    );

    if (registerAttachmentResult) {
      yield put(uploadAttachmentResult(registerAttachmentResult.attachment));
    } else {
      throw "There are a problem register the File";
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, "Core/common/views/redux/sagas", "uploadAttachment");
    yield put(uploadAttachmentResult());
  }
}

//Watcher
function* watchAttachmentRequests() {
  // eslint-disable-next-line no-undef
  yield takeEvery(UPLOAD_ATTACHMENT, uploadAttachment);
  yield takeEvery(DOWNLOAD_ATTACHMENT_URL, getUrlDownloadAttachment);
  yield takeEvery(GET_USER_LANGUAGE, getUserLanguage);
}

function* sendGenericExportRequest({ payload }) {
  const { 
    name, 
    type, 
    graphqlQuery, 
    graphqlVariables, 
    columns, 
    exportFormat, 
    header,
    isDynamo,
    general,
    columnStyles
  } = payload;
  
  try {
    //Request to the API for a "Presigned URL"
    let genericExport = yield call(
      clientMutation,
      `mutation genericExport (
        $name: String, 
        $sessionId: String, 
        $type: ExportType!, 
        $graphqlQuery: String!, 
        $graphqlVariables: AWSJSON, 
        $columns: [ColumnsInput]!, 
        $exportFormat: ExporterFormatInput
        $header: [Header]
        $isDynamo: Boolean
        $general: Boolean
        $columnStyles: AWSJSON
      ) {
				result: genericExport (
          name:$name, 
          sessionId: $sessionId,
          type: $type, 
          graphqlQuery: $graphqlQuery, 
          graphqlVariables: $graphqlVariables, 
          columns: $columns, 
          exportFormat: $exportFormat,
          header: $header,
          isDynamo: $isDynamo,
          general: $general,
          columnStyles: $columnStyles
        )
			}`,
      {
        name: name,
        sessionId: sessionStorage.getItem("sessionId"), 
        type: type,
        graphqlQuery,
        graphqlVariables: JSON.stringify(graphqlVariables || {}),
        columns,
        exportFormat,
        header: header || [],
        isDynamo: isDynamo || false,
        general: general || false,
        columnStyles: JSON.stringify(columnStyles || {})
      },
      endpoints.GRAPHQL_GENERAL
    );

    yield put(sendGenericExportResult(genericExport.result));
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(
      exc,
      "Core/common/views/redux/sagas",
      "sendGenericExportRequest"
    );
    yield put(sendGenericExportResult(false));
  }
}

function* watchSendGenericExportRequest() {
  yield takeEvery(GENERIC_EXPORT_SEND, sendGenericExportRequest);
}

//Saga Export
export default function* rootSaga() {
  yield all([
    fork(watchAttachmentRequests),
    fork(watchSendGenericExportRequest),
  ]);
}
