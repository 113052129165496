import React, { useEffect, useRef, useState } from "react";

import {
  DateRangePicker,
  IconButton,
  LinearProgress,
  Loading,
} from "@gpstrackit/gtc-react-controls";

import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { searchTrailsIncident, sliceHistoryTrails } from "Redux/actions";
import moment from "moment";
import { getMethodToFormatDuration } from "Modules/reports/views/main/components/reportsTab/helper";
import { GetFormatForMoment, getFormattedDateByTimeZone } from "Core/data/Helpers";
import useExport from "Components/Table/Export";
import TrailItem from "../../../trailEvent";
import { createDataToShow } from "../../../trailEvent/utils";

export const Trails = ({
  dateRange,
  setNumberSelected,
  unitType,
  isKph,
}) => {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const scrollTrailsRef = useRef();

  const {
    isOpenHistoryOverViewIncident,
    loadingTrailsIncident,
    errorTrailsIncident,
    trailsIncidentTotal,
    trailsIncident,
    sliceTrails,
  } = useSelector((state) => state.incidentsRedux);
  const landmarkGroups = useSelector(
    (state) => state.entitySelectorRedux.landmarkGroups
  );
  const lang = useSelector((state) => state.commomRedux.language);

  const { user } = useSelector((state) => state.securityRedux);
  const { timeZone, durationFormat, dateformat } = user;

  const deviceId = isOpenHistoryOverViewIncident?.data?.unit?.id;
  const hasVideo = isOpenHistoryOverViewIncident?.data?.unit?.hasVideo;

  const [page, setPage] = useState(0);
  const [paginationLoading, setPaginationLoading] = useState(true);
  const [filters, setFilters] = useState({
    limit: 5000,
    offset: 0,
    deviceId,
    startDate: dateRange?.start,
    endDate: dateRange?.end,
  });
  const [currentTrails, setCurrentTrails] = useState([])


  useEffect(() => {
    dispatch(searchTrailsIncident(filters));
  }, [filters]);

  useEffect(() => {
    if (page === 0) return;

    setFilters({
      ...filters,
      offset: page * filters?.limit,
      research: false,
      currentData: trailsIncident,
    });
  }, [page]);

  useEffect(() => {
    if (!loadingTrailsIncident && trailsIncident?.length > 0) {
      dispatch(sliceHistoryTrails(trailsIncident));
      setPaginationLoading(false);
      setCurrentTrails(trailsIncident)
    }else{
      dispatch(sliceHistoryTrails([]));
    }
  }, [loadingTrailsIncident, trailsIncident]);

  const handleScroll = () => {
    if (scrollTrailsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollTrailsRef.current;
      if (
        scrollTop + clientHeight + 100 >= scrollHeight &&
        trailsIncident?.length < trailsIncidentTotal &&
        !paginationLoading
      ) {
        setPaginationLoading(true);
        setPage(page + 1);
      }
    }
  };

  const getDurationIncident = () => {
    const date = moment(dateRange?.start).format(
      GetFormatForMoment(dateformat)
    );
    const duration = moment(moment(dateRange?.end)).diff(
      moment(dateRange?.start),
      "s"
    );
    const durationFormatTime = getMethodToFormatDuration(durationFormat);
    const durationResult = duration
      ? durationFormatTime(duration, messages)
      : "0 min, 0 sec";
    return `${date} (${durationResult})`;
  };

  const items = createDataToShow(
    currentTrails,
    landmarkGroups,
    messages,
    {},
    isOpenHistoryOverViewIncident?.data?.unit,
    lang
  );

  const getHeaders = () => {
    return [
      messages["videoMainView_driver"],
      messages["videoMainView_event"],
      messages["realtimemaps_landmarks_grid_latlng"],
      messages["realtimemaps_layer_map_date_time"],
      messages["alertsTabView_speed"],
      messages["incident_address_export"],
    ];
  };

  const getDataToExportCsv = () => {
    if (trailsIncident?.length === 0) return;
    const timezone = isOpenHistoryOverViewIncident?.data?.unit?.timeZone?.name;
    const newData = [];
    trailsIncident?.map((item) => {
      const latLng = `${item?.latitude} / ${item?.longitude}`;
      return newData.push([
        item?.driverName,
        item?.eventName,
        latLng,
        getFormattedDateByTimeZone(item?.unitTime, timezone, messages, `${GetFormatForMoment(dateformat)} hh:mm:ss a` ,false ,lang) || '',
        item?.speed,
        item?.formattedAddress,
      ]);
    });

    return newData;
  };

  const exportData = useExport(
    getHeaders,
    getDataToExportCsv,
    [],
    [],
    null,
    messages["incident_trails_export"], //title to export
    "portrait",
    true,
    true //show 0 value
  );

  const filterTrail = (item = 0) => {
    const newSlice = currentTrails?.slice(item);
    if (newSlice?.length > 0) {
      // if (scrollTrailsRef.current) {
      //   scrollTrailsRef.current.scrollTop = 0;
      // }
      dispatch(sliceHistoryTrails(newSlice));
    }
  };

  const clearTrailSelected = () => {
    if (scrollTrailsRef.current) {
      scrollTrailsRef.current.scrollTop = 0;
    }
    setNumberSelected(null);
    dispatch(sliceHistoryTrails(trailsIncident));
  };

  return (
    <div
      className="incident_trails_history"
      style={{ width: hasVideo ? "33%" : "50%" }}
    >
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className="incident_trails_title_history"
      >
        <div style={{ display: "flex" }}>
          <p style={{ margin: 0, marginRight: "5px" }}>
            {messages["incident_event_trails"]}
          </p>
          <p style={{ margin: 0, fontWeight: "400" }}>
            {getDurationIncident()}
          </p>
        </div>
        <IconButton
          icon="download"
          style={{ padding: 0 }}
          isDisabled={
            loadingTrailsIncident || trailsIncident?.length === 0 ? true : false
          }
          tooltip={messages["incident_trails_export"]}
          onClick={() => exportData.exportCSV()}
        />
      </div>
      {loadingTrailsIncident && trailsIncident?.length === 0 ? (
        <div className="incident_trails_loading">
          <Loading />
        </div>
      ) : !loadingTrailsIncident && trailsIncident?.length === 0 ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            textAlign: "center",
          }}
        >
          <p>{messages["incidents_no_data_trails"]}</p>
        </div>
      ) : (
        <div
          className="incident_trails_list"
          onScroll={handleScroll}
          ref={scrollTrailsRef}
        >
          {items &&
            items?.map((item, index) => {
              // return <Trail element={item} count={index} key={index} focusTrail={focusTrail} setReadingSelected={setReadingSelected}/>
              return (
                <TrailItem
                  dateformat={dateformat}
                  timezone={
                    isOpenHistoryOverViewIncident?.data?.unit?.timeZone?.name
                  }
                  numberItem={index}
                  count={items?.length - index}
                  element={item}
                  filterTrail={filterTrail}
                  clearTrailSelected={clearTrailSelected}
                  sliceTrails={sliceTrails}
                  setNumberSelected={setNumberSelected}
                  unitType={unitType}
                  isKph={isKph}
                />
              );
            })}
        </div>
      )}
      {paginationLoading && trailsIncident?.length > 0 && (
        <div style={{ width: "100%" }}>
          <LinearProgress />
        </div>
      )}
    </div>
  );
};
