import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    loading: false,
    open: false,
    routeId: 0,
    route: null,
    error: null
};

const route = "modules/routes/views/routesTracker/components/modalStopVisited";


export const routesTrackerGetRoute = createAsyncThunk('modalStopVisited/redux/slice/routesTrackerGetRoute', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query getRoute ($id: Int!) {
                Route: getRoute (id: $id) {
                  id
                  stopsPendingCount
                  stopsCompletedCount
                  points {
                    id
                    name
                    sequence
                    status
                    arrivalTime
                    departureTime
                  }                  
                }
              }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.Route || null; 

    } catch (exc) {
        ExceptionManager(exc, route, 'routesTrackerGetRoute');
        return rejectWithValue(exc)
    }
});


export const RoutesTrackerModalStopsVisitedRedux = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalRoutesTrackerStopsVisited: (state, action) => {
            return {
                ...state,
                open: action?.payload?.open || false,
                routeId: action?.payload?.routeId || 0
            }
        }
    },
    extraReducers: (builder) => {

        builder.addCase(routesTrackerGetRoute.pending, (state, action) => {
            state.route = null;
            state.loadingRoutes = true

        });
        builder.addCase(routesTrackerGetRoute.fulfilled, (state, action) => {
            state.route = action?.payload;
            state.loadingRoutes = false;

        } );
        builder.addCase(routesTrackerGetRoute.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loadingRoutes = false;

        });
    }
    
});


const { actions, reducer } = RoutesTrackerModalStopsVisitedRedux;

export const { reset, openModalRoutesTrackerStopsVisited } = actions;

export default reducer;