//View Keys
export default {
  legacyApiKey_title: 'Clave de API heredada',
  legacyApiKey_username: "Usuario",
  legacyApiKey_ipAdresses: "Direcciones IP",
  legacyApiKey_key: "Llave",
  legacyApiKey_createLegacyApiKey: "Nueva clave de API heredada",
  legacyApiKey_editLegacyApiKey: "Editar clave de API heredada",
  legacyApiKey_deleteLegacyApiKey: "Eliminar clave de API heredada",
  legacyApiKey_cancel: "Cancelar",
  legacyApiKey_createButton: "Guardar",
  legacyApiKey_editButton: "Guardar",
  legacyApiKey_delete: "Eliminar",
  legacyApiKey_messageDelete: "Está seguro de que desea eliminar la clave Api heredada de",
  legacyApiKey_messageIpAddress: "Dirección IP válida requerida",
  legacyApiKey_createLegacy: "Clave de API heredada Creada",
  legacyApiKey_editLegacy: "Clave de API heredada Actualizada",
  legacyApiKey_deleteLegacy: "Clave de API heredada Eliminada",
  legacyApiKey_select: "Seleccione",
  legacyApiKey_maxLengthMessageIpAddress: "La dirección IP debe contener un máximo de caracteres:",
  legacyApiKey_addButtonInputIpAddress: "Agregar nueva direccion IP",
  legacyApiKey_deleteButtonInputIpAddress: "Eliminar direccion IP"
};
