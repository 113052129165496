import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../core/logManager';
import { resetALL } from '../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    data: null,
    limit: 10,
    offset: 0,
    items: [],
    total: 0,
    offlineThresholdMins: 0
};

const route = 'Warnings/redux/slice'

export const searchGroupedMaintenanceWarningEvents = createAsyncThunk('warnings/searchGroupedMaintenanceWarningEvents', async (payload, { rejectWithValue, dispatch }) => {
    const { unitId, offset, openSearch, resolved } = payload
    try {
        let response = await clientQuery(`
            query searchGroupedMaintenanceWarningEvents($openSearch: String, $unitId: Int!, $resolved: Boolean!, $dateRange: AWSDateTimeRange, $limit: Int!, $offset: Int!) {
                result: searchGroupedMaintenanceWarningEvents(
                openSearch: $openSearch
                unitId: $unitId
                resolved: $resolved
                dateRange: $dateRange
                limit: $limit
                offset: $offset
                ) {
                items {
                    warning
                    firstEventDate
                    lastEventDate
                    eventCount
                    nameResolvedby
                    resolvedBy
                    resolvedOn
                }
                total
                }
            }
        `,
            {
                "openSearch": openSearch || "",
                "unitId": unitId,
                "resolved": resolved,
                "limit": 10,
                "offset": offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        )
        
        return response?.result || { items: [], total: 0 }

    } catch (exc) {
        ExceptionManager(exc, route, 'searchGroupedMaintenanceWarningEvents');
        return rejectWithValue(exc)
    }
});

export const getUnitOfflineThreshold = createAsyncThunk('warnings/getUnitOfflineThreshold', async (payload, { rejectWithValue, dispatch }) => {
    const { id } = payload
    try {
        let response = await clientQuery(`
            query getUnitOfflineThreshold($id: Int!) {
                result: getUnitOfflineThreshold(id: $id) {
                    id
                    offlineThresholdMins
                }
            }
        `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL_V2
        )

        if(response?.result){
            return response.result
        }else {
            return { offlineThresholdMins: 0 }
        }

    } catch (exc) {
        ExceptionManager(exc, route, 'getUnitOfflineThreshold');
        return rejectWithValue(exc)
    }
});

//Slice
const warningsReduxRedux = createSlice({
    name: 'warnings/details',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : INIT_STATE
        },
        openWarningsModal(state, action) {
            state.open = true
            state.data = action.payload
        },
        updateWarningsItems(state, action){
            state.items = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        //searchGroupedMaintenanceWarningEvents
        builder.addCase(searchGroupedMaintenanceWarningEvents.pending, (state, action) => {
            state.open = true
            state.loading = true
        });
        builder.addCase(searchGroupedMaintenanceWarningEvents.fulfilled, (state, action) => {
            state.loading = false
            state.items = [...state.items, ...action.payload.items] || []
            state.total = action.payload.total || 0
        });
        builder.addCase(searchGroupedMaintenanceWarningEvents.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });

        //getUnitOfflineThreshold
        builder.addCase(getUnitOfflineThreshold.fulfilled, (state, action) => {
            state.offlineThresholdMins = action.payload.offlineThresholdMins
        });
        builder.addCase(getUnitOfflineThreshold.rejected, (state, action) => {
            state.offlineThresholdMins = 0
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = warningsReduxRedux;

// Extract and export each action creator by name
export const { reset, openWarningsModal, updateWarningsItems } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;