import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';

// Actions
import {
  reportsMainGenerateReportProgress
} from 'Redux/actions';

// Helpers
import {
  handleAsyncReport,
  getTimeZone,
  baseReportConditions,
  generateReportInstance,
  getCategoryFilter,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getStateMileageGroupedReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
  const { timeZone , sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath } = report;
  const { filterType, filterArray, startRange, endRange, unitsSelected } = report.filters;
  const baseConditionsQuery = baseReportConditions({ report, name, dataResultPath, timeZone, delayed, unitsSelected, timeframeName , filterType, filterArray, categoryName, startRange, endRange})
  try {
    let payload = {
      conditions: {
        ...baseConditionsQuery,
        sortField: {
          field: sortField || "deviceId",
          order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
      },
      limit: rowsPerPage,
      offset: page
    }

    if (report.export) {
      payload = {
        limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 250,
        offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
        conditions: {
          ...payload.conditions,
          exportOptions: {
            sendByEmail,
            emailsTo,
            culture: report.lang,
            timeZone: getTimeZone(timeZone),
            format: {
              fileFormat: report?.exportType  || "json",
              formatOptions: "{}"
            },
            folderName: "px-customer-reports",
            filePrefixName: "stateMileageReport",
            columns: [
              {
                column: "idLabel",
                header: "Label"
              },
              {
                column: "state",
                header: "State"
              },
              {
                column: "event",
                header: "Event"
              },
              {
                column: "address",
                header: "Address"
              },
              {
                column: "unitTime",
                header: "DateTime"
              },
              {
                column: getCategoryFilter(filterType) === "deviceId" ? "driverName" : "deviceLabel",
                header: getCategoryFilter(filterType) === "deviceId" ? "Driver" : "Unit"
              },
              {
                column: "distance",
                header: "Distance"
              },
              {
                column: "odometer",
                header: "Odometer"
              }
            ]
          }
        }
      }
    }

     let data = yield call(
      clientQuery,
      `query getStateMileageGroupedReport  ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
          Report: getStateMileageGroupedReport  (conditions: $conditions, limit: $limit, offset: $offset) {
            ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
              `reportInstanceId`
              : 
              `items {
                id
                idLabel
                startDate
                endDate
                noData
              }
              total
              limit
              offset
              category
              summaryInfo {
                id
                totalCrossings
                summaryByState {
                    state
                    mileage
                    isKPH
                }
              }
              ${!requestFlag ?
                `grandSummaryInfo {
                      totalCrossings
                      summaryByState {
                        mileage
                        state
                      }
                    }`
                : ''}
            `}
          }
        }`,
      payload,
      endpoints.GRAPHQL_PX_REPORTING,
    );

    /*if (!report.export) {
      if (data && data.Report) {
        report.data = data.Report
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
      } else {
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
      }
    } else {
      report.loading = false;
      delete report.export
      yield put(reportsMainGenerateReportProgress(report))

      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = data.Report?.reportInstanceId;
      a.click();
      document.body.removeChild(a);
    }*/
    yield handleAsyncReport(report, data)

  } catch (exc) {
    report.loading = false
    delete report.export
    yield put(reportsMainGenerateReportProgress(report))
  }
}

export default getStateMileageGroupedReport;
