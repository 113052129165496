import {
  DateRangePicker,
  IconButton,
  LinearProgress,
  Loading,
} from "@gpstrackit/gtc-react-controls";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { GetFormatForMoment } from "Core/data/Helpers";
import { getUnitOverviewRedux } from "Redux/actions";
import Event from "./event";
import moment from "moment";
import { getMethodToFormatDuration } from "Modules/reports/views/main/components/reportsTab/helper";
import useExport from "Components/Table/Export";
import Clip from "./clip";

export const Events = ({ dateRange }) => {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const {
    isOpenHistoryOverViewIncident,
    unitOverviewItems,
    getUnitOverviewLoading,
    unitOverviewItemsTotal,
  } = useSelector((state) => state.incidentsRedux);

  const { unitsList } = useSelector(state => state.catalogsRedux);
  const { user } = useSelector((state) => state.securityRedux);
  const { dateformat, isKph, durationFormat } = user;
  const listInnerRef = useRef();
  const unitId = isOpenHistoryOverViewIncident?.data?.unit?.id;
  const unitLabel = isOpenHistoryOverViewIncident?.data?.unit?.label;
  const hasVideo = isOpenHistoryOverViewIncident?.data?.unit?.hasVideo;

  const [paginationLoading, setPaginationLoading] = useState(false);
  const [timeZone, setTimeZone] = useState({name: ''})
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    startDate: dateRange?.start,
    endDate: dateRange?.end,
    unitId
  });

  useEffect(() => {
    const unit = unitsList?.find(unit => unit?.id === unitId)
    if(unit?.timeZone){
      setTimeZone(unit?.timeZone)
    }
  }, [unitsList])

  useEffect(() => {
    dispatch(getUnitOverviewRedux(filters));
  }, [filters]);

  useEffect(() => {
    if (page === 0) return;

    setFilters({
      ...filters,
      offset: page * filters?.limit,
      research: false,
      currentData: unitOverviewItems,
    });
  }, [page]);

  useEffect(() => {
    if (!getUnitOverviewLoading && unitOverviewItems?.length > 0) {
      setPaginationLoading(false);
    }
  }, [getUnitOverviewLoading, unitOverviewItems]);

  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight &&
        unitOverviewItems?.length < unitOverviewItemsTotal &&
        !paginationLoading
      ) {
        setPaginationLoading(true);
        setPage(page + 1);
      }
    }
  };

  const getDurationIncident = () => {
    const date = moment(dateRange?.start).format(
      GetFormatForMoment(dateformat)
    );
    const duration = moment(moment(dateRange?.end)).diff(
      moment(dateRange?.start),
      "s"
    );
    const durationFormatTime = getMethodToFormatDuration(durationFormat);
    const durationResult = duration
      ? durationFormatTime(duration, messages)
      : "0 min, 0 sec";
    return `${date} (${durationResult})`;
  };

  const getHeaders = () => {
    return [
      messages["videoMainView_unit"],
      messages["videoMainView_driver"],
      messages["videoMainView_event"],
      messages["videoMainView_address"],
      messages["videoMainView_dateTime"],
      messages["videoMainView_speed"],
      messages["videoMainView_notes"],
    ];
  };

  const getDataToExportCsv = () => {
    if (unitOverviewItems?.length === 0) return;
    const newData = [];
    unitOverviewItems?.map((item) => {
      return newData.push([
        item?.label,
        item?.driverName,
        item?.eventName,
        item?.formattedAddress,
        item?.unitTime,
        item?.speed,
        item?.notes,
      ]);
    });

    return newData;
  };

  const exportData = useExport(
    getHeaders,
    getDataToExportCsv,
    [],
    [],
    null,
    messages["incident_event_title"], //title to export
    "portrait",
    true,
    true //show 0 value
  );

  return (
    <div
      className="incident_events_history"
      style={{ width: hasVideo ? "26%" : "50%" }}
    >
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className="incident_events_title_history"
      >
        <div style={{ display: "flex" }}>
          <p style={{ margin: 0, marginRight: "5px" }}>
            {messages["incident_event_title"]}
          </p>
          <p style={{ margin: 0, fontWeight: "400" }}>
            {getDurationIncident()}
          </p>
        </div>
        {/* Fix download */}
        {/* <IconButton
          icon="download"
          style={{ padding: 0 }}
          isDisabled={
            getUnitOverviewLoading || unitOverviewItems?.length === 0
              ? true
              : false
          }
          tooltip={messages["incident_events_export"]}
          onClick={() => exportData.exportCSV()}
        /> */}
      </div>
      {getUnitOverviewLoading && unitOverviewItems?.length === 0 ? (
        <div className="incident_events_loading">
          <Loading />
        </div>
      ) : !getUnitOverviewLoading && unitOverviewItems?.length === 0 ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            textAlign: "center",
          }}
        >
          <p>{messages["incidents_no_data_events"]}</p>
        </div>
      ) : (
        <div
          className="incident_event_list"
          onScroll={handleScroll}
          ref={listInnerRef}
        >
         {unitOverviewItems?.map((item) => {
            if(item?.type === 'deviceReading'){
              return (
                <Event
                  {...item}
                  isKph={isKph}
                  timeZone={timeZone}
                  dateformat={GetFormatForMoment(dateformat)}
                />
              );
            }else{
              return (
                <Clip
                    {...item}
                    isKph={isKph}
                    timezone={timeZone}
                    dateformat={GetFormatForMoment(dateformat)}
                />
              )
            }
          })}
        </div>
      )}
      {paginationLoading && unitOverviewItems?.length > 0 && (
        <div style={{ width: "100%" }}>
          <LinearProgress />
        </div>
      )}
    </div>
  );
};
