import {
    MAP_LANDMARKS_LIST_GETTAGS,
    MAP_LANDMARKS_LIST_GETTAGS_RESPONSE,
    MAP_LANDMARKS_LIST_GETTAGS_TOCREATE,
    MAP_LANDMARKS_LIST_GETTAGS_TOCREATE_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    landmarkTags: [],
    landmarkTagsLoading: false,
    landmarkTagsToCreate: [],
    landmarkTagsToCreateLoading: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case MAP_LANDMARKS_LIST_GETTAGS:
            return {
                ...state,
                landmarkTagsLoading: true
            };

        case MAP_LANDMARKS_LIST_GETTAGS_RESPONSE:
            return {
                ...state,
                landmarkTags: action.payload.data,
                landmarkTagsLoading: false
            };
            
        case MAP_LANDMARKS_LIST_GETTAGS_TOCREATE:
            return {
                ...state,
                landmarkTagsToCreateLoading: true
            };
    
        case MAP_LANDMARKS_LIST_GETTAGS_TOCREATE_RESPONSE:
            return {
                ...state,
                landmarkTagsToCreate: action.payload.data,
                landmarkTagsToCreateLoading: false
            };

        case CLEAR_REDUX:
            return ["", "MAP_LANDMARKS_LIST_TAGS"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
      return state;
    }
}