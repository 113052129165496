//View Keys
export default {
  login_username: 'Username',
  login_log_into_account: 'Log into your account',
  login_password: 'Password',
  login_signIn: 'Sign In',
  login_continue: 'Continue',
  login_next: 'Next',
  login_signInWithGoogle: 'Sign In with Google',
  login_signInWithFacebook: 'Sign In with Facebook',
  login_signInWithApple: 'Sign In with Apple',
  login_forgot_password: 'Forgot Password?',
  page_expirate_link: 'The link expired',
  page_not_found: 'Oops! We´ve hit a speed bump',
  page_not_found_message: 'The page you are looking for seems to be missing. If you need a ride someplace else, try one of the buttons on the left',
  page_not_found_homepage: 'go to homepage',
  login_social_media_or: 'OR',
  login_social_media_error_default: 'Incorrect Username or Password',
  login_social_media_error_presignin: 'PreSignUp failed with error ',
  login_social_media_error_preauthentication:'PreAuthentication failed with error',
  login_social_media_error: 'Error during the login process',
  login_social_modal_configuration_facebook: 'Facebook Configuration',
  login_social_modal_configuration_apple: 'Apple Configuration',
  login_social_media_title: 'Follow the steps below to correctly configure the login with ',
  login_social_step_one_facebook: 'Go to Facebook and click on',
  login_social_step_two_facebook: 'Look for the GPSTrackIt Fleet application and click on "View and Edit"',
  login_social_step_three_facebook: 'Click on "Apps and Websites"',
  login_social_step_four_facebook: 'Click on the button "Remove"',
  login_social_step_five_facebook: 'Try to sign in again with the option "Share email" enabled.',
  login_social_step_one_apple: 'Go to manage your Apple account',
  login_social_step_two_apple: 'In the Security section, click on "Manage apps & websites".',
  login_social_step_three_apple: 'Click on the app "GPSTrackit".',
  login_social_step_four_apple: 'Click on "Stop using Apple ID".',
  login_social_step_five_apple: 'Try to sign in again with the "Share email" option enabled.',
  login_social_recommendation_google: 'Recommendation',
  login_social_multiples_accounts: 'If you have multiple Google accounts and you want to try with another one, please click',
  login_social_here: 'here.',
  login_social_error_title: 'Your email address has been hidden to us, you can fix it by correctly setup it in',
  login_social_settings: 'Settings',
  login_facebook: 'Facebook',
  login_apple: 'Apple',
  login_referral_bonus: "Get a $500 Referral Bonus!",
  login_new_video: "New VIDEO is here! Click here to learn more.",
  login_back: "Back",
  login_error_by_default_cognito: 'Incorrect username or password.',
  login_password_incorrect: 'Password entered is incorrect.',
  login_api_email_is_already_used: 'Email is already used by another user. Please choose another email.',
  login_api_user_is_already_migrated: 'The user is already migrated.',
  login_api_user_email_not_found: 'User email not found',
  login_api_new_password_must_be_different: 'Change Password: newPassword must be different from old Password',
  login_api_username_incorrect: 'Username or email entered is incorrect.',
  login_api_challenge_name: 'Unexpected challenge name',
  login_privacy_policy: 'Privacy policy',
  login_passive_acceptance: "By logging into GPSTrackit, you acknowledge that you accept the ",
  login_end_user_license_agreement: 'Terms of Service',
  login_need_help: 'Need help getting registered?',
  login_inactive_account: 'Incorrect username or password.',
  login_forgot_username_or_password: 'Forgot Username or Password?',
  login_email_or_username: 'Email or Username',
  login_accept_terms_condition: 'Do you accept the terms and conditions?',
  login_chat_with_support: 'Chat with Support'
};
