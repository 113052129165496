import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { NotificationManager } from 'Components/Notifications';

//Action types
import { MAP_UTILITIES_SEND_MESSAGE } from 'Redux/actionTypes';

// Actions
import {
	sendMessageResponse,
} from 'Redux/actions';

//Function
function* sendMessage({ payload }) {
	try {
		const { message, subject, to } = payload.data;
		let data = yield call(
			clientMutation,
			`mutation ( $subject: String! $message: String! $to: [EntityInput]) {
				SendMessage: sendMessage(subject: $subject, message: $message, to: $to)
			} 
			`,
			{
				subject,
				message,
				to
			},
			endpoints.GRAPHQL_GENERAL,
		);
		if (data && data.SendMessage){
			yield put(sendMessageResponse(data.SendMessage));
		}
		else {
			NotificationManager.error("Emails/SMS doesn't exists", "Error", 3000, null, null, "error");
			yield put(sendMessageResponse(null));
		}
	} catch (exc) {
		let message = null
        if (exc.errorData && exc.errorData.errors) {
            exc.errorData.errors.forEach(error => {
                if (error.errorInfo && error.errorInfo.code) {
                    message = error.message
                }
            });
        }
		if(message != "Emails/SMS doesn't exists"){
			ExceptionManager(exc, 'modules/realtimeMaps/main/components/SendMessage/redux/saga', 'sendMessage');
		}

     	yield put(sendMessageResponse({ error: true, message: message}));
	}
}

function* watchSendMessageRequest() {
	yield takeEvery(MAP_UTILITIES_SEND_MESSAGE, sendMessage);
}

//Saga Export
export default function* rootSaga() {
	yield all([
		fork(watchSendMessageRequest)
	]);
}