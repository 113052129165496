import React, { useEffect, useState, useRef, useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
// Cloud React components
import { Button, Select, Form, Input, Pill } from 'Components';
import Modal, { ModalBody, ModalFooter } from 'Components/Modal';
import { NotificationManager } from 'Components/Notifications';
// Material UI
import { Grid } from '@mui/material';
// Helpers
import { OrderData } from 'Core/data/Helpers';
import { setContactId } from '../../../../core/hooks';
import { ContactsSelector } from 'Components';

//actions
import {
  openSharedMediaModal,
  catalogLoad,
  sharedMedia,
  clearRedux
} from 'Redux/actions';

// Styles
import './sharedMedia.scss';

const SharedMedia = (props) => {
  const dispatch = useDispatch();
  const { intl } = props;
  const { messages } = intl;

  const sharedMediaForm = useRef();
  const { open, items, loadingSharedMedia: loading, sharedMediaResponse: response } = useSelector(state => state.sharedMediaRedux);
  const { contacts: contactsList, loadingContacts, cellCarriers, loadingCellCarriers } = useSelector(state => state.catalogsRedux);

  const defaultFormData = { subscribers: [], type: "email", phone: null, carrier: null };
  const [formData, setFormData] = useState({ ...defaultFormData });
  const [error, setError] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [country, setCountry] = useState("US");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const smsRegex = /^[0-9]{10}$/;

  const methods = [
    {
      id: 'sms',
      name: messages['sharedMedia_text'],
      color: '#66BB6A',
    },
    {
      id: 'email',
      name: messages['sharedMedia_email'],
      color: '#66BB6A',
    }
  ];

  useEffect(() => {
    return () => {
      setFormData({ ...defaultFormData });
      dispatch(clearRedux('SHARED_MEDIA', { items: null, sharedMediaResponse: null }));
    }
  }, []);

  useEffect(() => {
    dispatch(catalogLoad(['contacts', 'cellCarriers']));
  }, []);

  useEffect(() => {
    if (contactsList && contactsList?.length) {
      const newContacts = setContactId(contactsList);
      setContacts(OrderData(newContacts, 'title'));
    }
  }, [contactsList]);

  useEffect(() => {
    if (response) {
      if (response.error) {
        NotificationManager.error(
          messages['sharedMedia_error'],
          messages['error'],
          3000,
          null,
          null,
          'warning',
        );
      } else {
        NotificationManager.success(
          messages['sharedMedia_success'],
          messages['success'],
          3000,
          null,
          null,
          'success',
        );
        handleClose();
      }
    }
  }, [response]);

  const handleClose = useCallback(() => {
    dispatch(openSharedMediaModal({ open: false }));
    setFormData({ ...defaultFormData });
  });

  const onSubmit = useCallback(() => {
    const { subscribers } = formData;

    let formatItems = []

    if(items){
      items.forEach((elementItem) => {
        formatItems.push({
          ...elementItem,
          deviceId: elementItem?.deviceId || ''
        })
      })
    }

    let data = {
      sharedEntities: formatItems,
      sharedTo: []
    }

    subscribers.forEach(subscriber => {
      if (subscriber.value && subscriber.type != "EMAIL" || (subscriber.value && subscriber.type == "EMAIL" && emailRegex.test(subscriber.value))) {
        data.sharedTo.push({
          via: subscriber.type == "CONTACT" ? subscriber?.contactType == "cellphone" ? "SMS" : "EMAIL" : subscriber?.type?.toUpperCase(),
          to: subscriber.value
        });
      }
    });

    dispatch(sharedMedia(data));
  });

  return (
    <Modal
      title={messages['sharedMedia_title']}
      open={open}
      position="right"
      size="small"
      handleClose={handleClose}
    >
      <ModalBody>
        <div
          style={{ padding: 24, height: '100%' }}
        >
          
          <Form ref={sharedMediaForm} onValidSubmit={onSubmit}>

            <div style={{ padding: "5px 0px 20px 0px" }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >

                  <ContactsSelector
                      styleMax
                      label={messages['eta_choose_contact']}
                      values={formData.subscribers}
                      disabled={loading || (response?.id ? true : false)}
                      required
                      loading={loadingContacts}
                      country={country}
                      contacts={contactsList ? contactsList.map(contact => {           
                        return { ...contact, name: contact?.title }
                      }) : []}
                      entities={["EMAIL", "SMS", "CONTACT"]}
                      emailKeyPress={(e) => { e.code === 'Space' && e.preventDefault(); }}
                      onChange={(values) => {  
                          setFormData({ ...formData, phone: null, subscribers: values });
                      }}
                      onChangeCountry={(newCountry) => {
                        setCountry(newCountry?.code)
                      }}
                  />
              </Grid>
            </div>

            {
              error &&
              <small className="text-danger" style={{ paddingTop: 5 }}>
                {error}
              </small>
            }
          </Form>
        </div>
      </ModalBody>
      <ModalFooter style={{ boxShadow: "2px 2px 2px 1px rgb(2 0 0 / 94%)" }}>
        <Button
          disabled={loading}
          onClick={handleClose}
          className="btn-cancel"
        >
          {messages['sharedMedia_cancel']}
        </Button>

        <Button
          disabled={loading}
          onClick={(e) => {
            if (e) e.preventDefault();
            sharedMediaForm.current.submit();
          }}
          className="btn-blue"
        >
          {messages['sharedMedia_send']}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default injectIntl(memo(SharedMedia));