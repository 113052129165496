// View Keys
export default {
    "AlertsinboxTab_DateTime": "Fecha/Hora",
    "AlertsinboxTab_Label": "Label",    
    "AlertsinboxTab_Units": "Unidades/Conductores", 
    "AlertsinboxTab_Severity": "gravedad",  
    "AlertsinboxTab_Location": "Ubicación",
    "AlertsinboxTab_Speed": "Velocidad",
    "AlertsinboxTab_Read": "Leer", 
    "AlertsinboxTab_None": "Ninguno",    
};
