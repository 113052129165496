import fsmRedux from "Modules/fsm/views/main/redux/reducers";
import fsmSettingsRedux from "Modules/fsm/views/fsmSettings/components/formSettings/redux/reducers";
import quickMessageRedux from "Modules/fsm/views/fsmSettings/components/quickMessage/redux/reducers";
import quickResponseRedux from "Modules/fsm/views/fsmSettings/components/quickResponse/redux/reducers";
import mealBreakNotificationsRedux from "Modules/fsm/views/fsmSettings/components/mealBreakNotifications/redux/reducers";
import driverStatusFsmRedux from "Modules/fsm/views/fsmSettings/components/driverStatus/redux/reducers";
import timeCardsRedux from "Modules/fsm/views/fsmSettings/components/timeCards/redux/reducers";
import MessagesRedux from "Modules/fsm/views/fsmMessages/redux/reducers";
import RoutesRedux from "Modules/fsm/views/fsmRoutes/redux/reducers";





export default {
    fsmRedux,
    fsmSettingsRedux,
    quickMessageRedux,
    quickResponseRedux,
    driverStatusFsmRedux,
    timeCardsRedux,
    MessagesRedux,
    RoutesRedux,
    mealBreakNotificationsRedux
};