// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    DASHBOARD_PANEL_GADGET_CHART_DRIVER_GROUP,
    DASHBOARD_PANEL_GADGET_CHART_DATA
} from "Redux/actionTypes";

// Actions
import {
    driverGroupChartPanelResponse,
    chartDashboardPanelResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";


function* unitGroupChartRequest() {
    try {

        const query = `
        query searchTags ($openSearch: String, $parentId: Int, $entityName: String, $entityId: Int, $limit: Int, $offset: Int) {
            searchTags (openSearch: $openSearch, parentId: $parentId, entityName: $entityName, entityId: $entityId, limit: $limit, offset: $offset) {
                items {
                    id
                    label
                    color
                    parentId
                    parent {
                        id
                        label
                        color
                        parentId
                    }
                    path
                }
            }
        }
        `;

        const response = yield call(
            clientQuery,
            query,
            {},
            endpoints.GRAPHQL_GENERAL
        );

        if (!response && !response.searchTags) {
            throw "Error while getting Tags list";
        }
        yield put(driverGroupChartPanelResponse({driver_groups:response?.searchTags?.items}));
    } catch (exc) {
        yield put(driverGroupChartPanelResponse({}));
        ExceptionManager(
            exc,
            "modules/dashboard/views/dashboardPanel/gadgets/redux/saga",
            "unitGroupChartRequest"
        );
    }
}

function* chartRequest({ payload }) {
    try {

        const { data } = payload;


        const query = `
              query getGadgetData ($id: String!) {
                getGadgetData (id: $id)
            }`;

        const response = yield call(
            clientQuery,
            query,
            { id: data.id },
            endpoints.GRAPHQL_GENERAL
        );

        if (!response && !response.getGadgetData) {
            throw "Error while getting Charts list";
        }

        let charts = JSON.parse(response.getGadgetData);

        yield put(chartDashboardPanelResponse({ data:charts ,id:data.id,error:false})); 
    } catch (exc) {
        yield put(chartDashboardPanelResponse({ data:[],id:payload.data.id,error:true }));
        ExceptionManager(
            exc,
            "modules/dashboard/views/dashboardPanel/redux/saga",
            "chartRequest"
        );
    }
}

function* watchMethods() {
    yield takeEvery(DASHBOARD_PANEL_GADGET_CHART_DRIVER_GROUP, unitGroupChartRequest);
    yield takeEvery(DASHBOARD_PANEL_GADGET_CHART_DATA, chartRequest);

}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}