export default {
    completeDriverScheduledTasks_completeSchedule: "Complete & Reschedule",
    completeDriverScheduledTasks_complete: "Complete",
    completeDriverScheduledTasks_status: "STATUS",
    completeDriverScheduledTasks_completed: "COMPLETED",
    completeDriverScheduledTasks_repeatEvery: "REPEAT EVERY",
    completeDriverScheduledTasks_dueDate: "DUE DATE",
    completeDriverScheduledTasks_estimatedCost: "ESTIMATED COST",
    completeDriverScheduledTasks_actualCost: "ACTUAL COST",
    completeDriverScheduledTasks_completedDate: "COMPLETED DATE",
    completeDriverScheduledTasks_attachments: "ATTACHMENTS ",
    completeDriverScheduledTasks_notes: "NOTES",
    completeDriverScheduledTasks_nextDueDate: "NEXT DUE DATE",
    completeDriverScheduledTasks_rescheduleTask: "Reschedule Task",
    completeDriverScheduledTasks_taskCompleted: "Task Completed",
}