export default {
    "dynamicFormAnswers_title": "Respuestas",
    "dynamicFormAnswers_form": "Formulario",
    "dynamicFormAnswers_answer": "Respuesta",
    "dynamicFormAnswers_updatedBy": "Actualizado Por",
    "dynamicFormAnswers_address": "Dirección",
    "dynamicFormAnswers_updatedAt": "Actualizado El",
    "dynamicFormAnswers_viewAnswers": "Ver Respuestas",

    "dynamicFormAnswers_advanceFilters": "Filtros Avanzados",
    "dynamicFormAnswers_clearAll": "Borrar Todo",
    "dynamicFormAnswers_form": "Formulario",
    "dynamicFormAnswers_user": "Conductor",

    "dynamicFormAnswers_searchByAnswer": "Buscar por respuesta",

    "dynamicFormAnswers_today": 'Hoy',
    "dynamicFormAnswers_yesterday": 'Ayer',
    "dynamicFormAnswers_last7days": 'Últimos 7 Días',
    "dynamicFormAnswers_last30Days": 'Últimos 30 Días',
    "dynamicFormAnswers_custom": 'Personalizado'
}