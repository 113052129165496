import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../core/logManager';
import { resetALL } from '../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    unit: null,
    unitDetail: null,
    simDetails: null,
    simSession: null,
    installedOn: null,
    installationValidation: null,
    simCardValidation: null,
    simCardSessionValidation: null,
    loadingInstallationValidation: false,
    loadingSimCardValidation: true,
    loadingsimCardSessionValidation: true,
    lastReadings: null,
    loadingLastReading: false,
    attValidation: true,
    locationValidation: null,
    createUserTroubleshooting: null,
    updateUserTroubleshooting: null,
    loadingUserTroubleshooting: false,
    pingSent: null,
    loadingSendPing: false,
    config: null,
    loadingConfig: false,
    loadingSendPing: false,
    refreshLocation: false,
    confirmLocation: null,
    odometerAndEngineOdometerValidation: null,
    rebootVidfleetDevice: null,
    loadingRebootVidfleetDevice: false,
    rebootGeneralDevice: null,
    loadingRebootGeneralDevice: false,
    sequence: 0,
    newOdometer: "1",
    newEngineOdometer: "1",
    loadingUpdateUnit: false,
    updateUnit: null,
    isLocation: "1",
    confirmLastReading: "1",
    openChat: false,
    chatSubject: "",
    chatDescription: "",
    isCompleted: false,
    isAgentAvailable: false,
    createSfActivity: null,
    loadingCreateSfActivity: false,
    pingConfirmation: null,
    loadingPingConfirmation: false,
};

const route = 'modules/.common/components/TroubleshootDevice/redux/slice'

export const troubleshootDeviceGetUnit = createAsyncThunk('troubleshootDevice/getUnit', async ({ id, history }, { rejectWithValue, dispatch }) => {
    try {
        const data = await clientQuery(
            `query getUnit ($id: Int!) {
                result: getUnit (id: $id) {
                    id
                    label
                    installed
                    deviceCategory
                    installedOn
                    type
                    esn
                    timeZone {
                        id
                        title
                    }
                    simCard {
                        iccId
                        simType
                        msisdn
                    }
                    deviceModel {
                        id
                        name
                        manufacturerId
                        manufacturer {
                            id
                            name
                        }
                        unitTypeId
                        hoursEnable
                        odometerEnable
                    }
                    lastReading {
                        accountId
                        ecuEngineHours
						ecuEngineOdometer
                        engineHours
						engineOdometer
                    }
                    useEcuOdometer
                    offsetEngineOdometer
                    offsetEcuEngineOdometer
                    useEcuEngineHours
                    offsetEngineHours
                    offsetEcuEngineHours
                }
            }`,
            {
                id: id
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return { ...data.result }

    } catch (exc) {
        ExceptionManager(exc, route, 'troubleshootDeviceGetUnit');
        return rejectWithValue(exc)
    }
});

export const troubleshootDeviceGetSimDetails = createAsyncThunk('troubleshootDevice/getSimDetails', async ({ iccId }, { rejectWithValue, dispatch }) => {
    try {
        const data = await clientQuery(
            `query getSimDetails ($iccid: String!) {
                result: getSimDetails (iccid: $iccid)
            }`,
            {
                iccid: iccId
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'troubleshootDeviceGetSimDetails');
        return rejectWithValue(exc)
    }
});

export const troubleshootDeviceGetSimSession = createAsyncThunk('troubleshootDevice/getSimSession', async ({ iccId }, { rejectWithValue, dispatch }) => {
    try {
        const data = await clientQuery(
            `query getSimSession ($iccid: String!) {
                result: getSimSession (iccid: $iccid)
            }`,
            {
                iccid: iccId
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'troubleshootDeviceGetSimSession');
        return rejectWithValue(exc)
    }
});

export const troubleshootDeviceGetLastReading = createAsyncThunk('troubleshootDevice/getLastReading', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const data = await clientQuery(
            `query getLastReading ($deviceId: Int!) {
                result: getLastReading (deviceId: $deviceId){
                    deviceId
                    latitude
                    longitude
                    hDop
                    deviceData
                    LastMessageTime
                    address {
                        address
                        city
                        state
                        formatted
                        zip
                    }
                    heading
                    eventName
                }
            }`,
            {
                deviceId: id
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'troubleshootDeviceGetLastReading');
        return rejectWithValue(exc)
    }
});

export const troubleshootDeviceCreateUserTroubleshooting = createAsyncThunk('troubleshootDevice/createUserTroubleshooting', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientMutation(
            `
                mutation($id: String!, $unitId: Int!, $flow: AWSJSON!) {
                    data: createUserTroubleshooting (
                        id: $id,
                        unitId: $unitId,
                        flow: $flow
                    ) {
                        id
                        flow
                    }
                }
            `,
            payload,
            endpoints.GRAPHQL_GENERAL_V2
        );

        return response?.data || false

    } catch (exc) {
        ExceptionManager(exc, route, 'troubleshootDeviceCreateUserTroubleshooting');
        return rejectWithValue(exc)
    }
});

export const troubleshootDeviceUpdateUserTroubleshooting = createAsyncThunk('troubleshootDevice/updateUserTroubleshooting', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientMutation(
            `
                mutation($id: String!, $flow: AWSJSON!) {
                    data: updateUserTroubleshooting (
                        id: $id,
                        flow: $flow
                    ) {
                        id
                        flow
                    }
                }
            `,
            payload,
            endpoints.GRAPHQL_GENERAL_V2
        );

        return response?.data || false

    } catch (exc) {
        ExceptionManager(exc, route, 'troubleshootDeviceUpdateUserTroubleshooting');
        return rejectWithValue(exc)
    }
});

export const troubleshootDeviceCreateMessageOutbox = createAsyncThunk('troubleshootDevice/createMessageOutbox', async ({ command }, { rejectWithValue, dispatch }) => {
    try {
        const data = await clientMutation(
            ` 
            mutation bulkMessageOutbox(
                $via: enumVia!
                $message: MessageOutboxMessage
                $description: String
                $system: Int
                $to: [MessageToInput]!
                $from: MessageFromInput
            ) {
                response: bulkMessageOutbox(
                    via: $via
                    message: $message
                    description: $description
                    system: $system
                    to: $to
                    from: $from
                ) {
                    id
                    to
                    via
                    type
                    message
                    description
                    status
                }
            }
			`,
            command,
            endpoints.GRAPHQL_GENERAL
        );

        return { result: data?.response }

    } catch (exc) {
        ExceptionManager(exc, route, 'troubleshootDeviceCreateMessageOutbox');
        return rejectWithValue(exc)
    }
});

export const troubleshootDeviceGetOdometerEngineHours = createAsyncThunk('troubleshootDevice/getOdometerEngineHours', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const data = await clientQuery(
            `query getUnit ($id: Int!) {
                result: getUnit (id: $id) {
                    id
                    lastReading {
                        ecuEngineHours
						ecuEngineOdometer
                        engineHours
						engineOdometer
                    }
                    unitVersion {
                        odometerEnable
                        hoursEnable
                    }
                    useEcuVin
                    useEcuSpeed
                    useEcuOdometer
                    offsetEcuEngineHours
                    useEcuEngineHours
                    offsetEngineOdometer
                    offsetEcuEngineOdometer
                    offsetEngineHours
                    offsetEngineOdometerUpdatedOn
                    offsetEcuEngineOdometerUpdatedOn
                    offsetEngineHoursUpdatedOn
                    offsetEcuEngineHoursUpdatedOn
                }
            }`,
            {
                id: id
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return { ...data.result }

    } catch (exc) {
        ExceptionManager(exc, route, 'troubleshootDeviceGetUnit');
        return rejectWithValue(exc)
    }
});

export const troubleshootDeviceUpdateUnit = createAsyncThunk('troubleshootDevice/updateUnit', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const data = await clientMutation(
            `
                mutation updateUnit (
                    $id: Int!,
                    $offsetEngineOdometer: Float, 
                    $offsetEcuEngineOdometer: Float,
                    $offsetEngineHours: Float, 
                    $offsetEcuEngineHours: Float, 
                    $offsetEngineOdometerUpdatedOn: AWSDateTime,
                    $offsetEcuEngineOdometerUpdatedOn: AWSDateTime, 
                    $offsetEngineHoursUpdatedOn: AWSDateTime, 
                    $offsetEcuEngineHoursUpdatedOn: AWSDateTime
                ) {
                    result: updateUnit (
                        id: $id, 
                        offsetEngineOdometer: $offsetEngineOdometer, 
                        offsetEcuEngineOdometer: $offsetEcuEngineOdometer, 
                        offsetEngineHours: $offsetEngineHours, 
                        offsetEcuEngineHours: $offsetEcuEngineHours, 
                        offsetEngineOdometerUpdatedOn: $offsetEngineOdometerUpdatedOn, 
                        offsetEcuEngineOdometerUpdatedOn: $offsetEcuEngineOdometerUpdatedOn, 
                        offsetEngineHoursUpdatedOn: $offsetEngineHoursUpdatedOn, 
                        offsetEcuEngineHoursUpdatedOn: $offsetEcuEngineHoursUpdatedOn
                    ) {
                        id
                    }
                }
            `,
            payload,
            endpoints.GRAPHQL_GENERAL_V2
        );

        return data?.result || {}

    } catch (exc) {
        ExceptionManager(exc, route, 'troubleshootDeviceGetUnit');
        return rejectWithValue(exc)
    }
});

export const troubleshootDeviceRebootVidFleetDevice = createAsyncThunk('troubleshootDevice/rebootVidFleetDevice', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const data = await clientMutation(
            `
                mutation rebootVidFleetDevice ($unitId: Int!) {
                    result: rebootVidFleetDevice (
                        unitId: $unitId
                    ) 
                }
            `,
            {
                unitId: payload
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return { result: data?.result }

    } catch (exc) {
        ExceptionManager(exc, route, 'troubleshootDeviceRebootVidFleetDevice');
        return rejectWithValue(exc)
    }
});

export const troubleshootDeviceCreateSfActivity = createAsyncThunk('troubleshootDevice/createSfActivity', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const data = await clientMutation(
            `
                mutation createSfActivity ($subject: String!, $description: String!) {
                    result: createSfActivity (
                        subject: $subject
                        description: $description
                    ) 
                }
            `,
            payload,
            endpoints.GRAPHQL_GENERAL_V2
        );

        return { result: data?.result }

    } catch (exc) {
        ExceptionManager(exc, route, 'troubleshootDeviceCreateSfActivity');
        return rejectWithValue(exc)
    }
});

export const troubleshootDevicePingConfirmation = createAsyncThunk('troubleshootDevice/pingConfirmation', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const data = await clientQuery(
            `query getLastReading ($deviceId: Int!) {
                result: getLastReading (deviceId: $deviceId){
                    deviceId
                    LastMessageTime
                    hDop
                    latitude
                    longitude
                    address {
                        address
                        city
                        state
                        formatted
                        zip
                    }
                }
            }`,
            {
                deviceId: id
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'troubleshootDeviceGetLastReading');
        return rejectWithValue(exc)
    }
});


//Slice
const troubleshootDeviceRedux = createSlice({
    name: 'troubleshootDevice',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : INIT_STATE
        },
        openTroubleshootDeviceModal(state, action) {
            state.open = action.payload.open
            state.unit = action.payload.unit
        },
        editField(state, action) {
            state[action.payload.field] = action.payload.value
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        //troubleshootDeviceGetUnit
        builder.addCase(troubleshootDeviceGetUnit.pending, (state, action) => {
            state.loadingInstallationValidation = true
        });
        builder.addCase(troubleshootDeviceGetUnit.fulfilled, (state, action) => {
            state.loadingInstallationValidation = false
            state.unitDetail = action?.payload
        });
        builder.addCase(troubleshootDeviceGetUnit.rejected, (state, action) => {
            state.loadingInstallationValidation = false
        });

        //troubleshootDeviceGetSimDetails
        builder.addCase(troubleshootDeviceGetSimDetails.pending, (state, action) => {
            state.loadingSimCardValidation = true
        });
        builder.addCase(troubleshootDeviceGetSimDetails.fulfilled, (state, action) => {
            state.loadingSimCardValidation = false
            state.simDetails = action?.payload
        });
        builder.addCase(troubleshootDeviceGetSimDetails.rejected, (state, action) => {
            state.loadingSimCardValidation = false
        });

        //troubleshootDeviceGetSimSession
        builder.addCase(troubleshootDeviceGetSimSession.pending, (state, action) => {
            state.loadingsimCardSessionValidation = true
        });
        builder.addCase(troubleshootDeviceGetSimSession.fulfilled, (state, action) => {
            state.loadingsimCardSessionValidation = false
            state.simSession = action?.payload
        });
        builder.addCase(troubleshootDeviceGetSimSession.rejected, (state, action) => {
            state.loadingsimCardSessionValidation = false
        });

        //troubleshootDeviceGetLastReading
        builder.addCase(troubleshootDeviceGetLastReading.pending, (state, action) => {
            state.loadingLastReading = true
        });
        builder.addCase(troubleshootDeviceGetLastReading.fulfilled, (state, action) => {
            state.loadingLastReading = false
            state.lastReadings = action?.payload || { error: "No readings" }
        });
        builder.addCase(troubleshootDeviceGetLastReading.rejected, (state, action) => {
            state.loadingLastReading = false
            state.lastReadings = { error: "No readings" }
        });

        //troubleshootDeviceCreateUserTroubleshooting
        builder.addCase(troubleshootDeviceCreateUserTroubleshooting.pending, (state, action) => {
            state.loadingUserTroubleshooting = true
            state.loadingInstallationValidation = true
        });
        builder.addCase(troubleshootDeviceCreateUserTroubleshooting.fulfilled, (state, action) => {
            state.loadingUserTroubleshooting = false
            state.createUserTroubleshooting = action?.payload
        });
        builder.addCase(troubleshootDeviceCreateUserTroubleshooting.rejected, (state, action) => {
            state.loadingUserTroubleshooting = false
            state.loadingInstallationValidation = false
        });

        //troubleshootDeviceUpdateUserTroubleshooting
        builder.addCase(troubleshootDeviceUpdateUserTroubleshooting.pending, (state, action) => {
            state.loadingUserTroubleshooting = true
        });
        builder.addCase(troubleshootDeviceUpdateUserTroubleshooting.fulfilled, (state, action) => {
            state.loadingUserTroubleshooting = false
            state.updateUserTroubleshooting = action?.payload
        });
        builder.addCase(troubleshootDeviceUpdateUserTroubleshooting.rejected, (state, action) => {
            state.loadingUserTroubleshooting = false
        });

        //troubleshootDeviceCreateMessageOutbox
        builder.addCase(troubleshootDeviceCreateMessageOutbox.pending, (state, action) => {
            if (action?.meta?.arg?.refreshLocation) {
                state.loadingSendPing = true
            }
            if (action?.meta?.arg?.resetUnit) {
                state.loadingRebootGeneralDevice = true
            }
        });
        builder.addCase(troubleshootDeviceCreateMessageOutbox.fulfilled, (state, action) => {
            if (action?.meta?.arg?.refreshLocation) {
                state.pingSent = { result: action?.payload?.result?.length > 0 || false }
            }
            if (action?.meta?.arg?.resetUnit) {
                state.loadingRebootGeneralDevice = false
                state.rebootGeneralDevice = { result: action?.payload?.result?.length > 0 || false }
            }
        });
        builder.addCase(troubleshootDeviceCreateMessageOutbox.rejected, (state, action) => {
            state.loadingSendPing = false
            state.refreshLocation = false
            state.loadingRebootGeneralDevice = false
        });

        //troubleshootDeviceGetOdometerEngineHours
        builder.addCase(troubleshootDeviceGetOdometerEngineHours.pending, (state, action) => {
            state.loadingConfig = true
        });
        builder.addCase(troubleshootDeviceGetOdometerEngineHours.fulfilled, (state, action) => {
            state.loadingConfig = false
            state.config = action?.payload
        });
        builder.addCase(troubleshootDeviceGetOdometerEngineHours.rejected, (state, action) => {
            state.loadingConfig = false
        });

        //troubleshootDeviceRebootVidFleetDevice
        builder.addCase(troubleshootDeviceRebootVidFleetDevice.pending, (state, action) => {
            state.loadingRebootVidfleetDevice = true
        });
        builder.addCase(troubleshootDeviceRebootVidFleetDevice.fulfilled, (state, action) => {
            state.loadingRebootVidfleetDevice = false
            state.rebootVidfleetDevice = action?.payload
        });
        builder.addCase(troubleshootDeviceRebootVidFleetDevice.rejected, (state, action) => {
            state.loadingRebootVidfleetDevice = false
        });

        //troubleshootDeviceUpdateUnit
        builder.addCase(troubleshootDeviceUpdateUnit.pending, (state, action) => {
            state.loadingUpdateUnit = true
        });
        builder.addCase(troubleshootDeviceUpdateUnit.fulfilled, (state, action) => {
            state.loadingUpdateUnit = false
            state.updateUnit = action?.payload
        });
        builder.addCase(troubleshootDeviceUpdateUnit.rejected, (state, action) => {
            state.loadingUpdateUnit = false
        });

        //troubleshootDeviceCreateSfActivity
        builder.addCase(troubleshootDeviceCreateSfActivity.pending, (state, action) => {
            state.loadingCreateSfActivity = true
        });
        builder.addCase(troubleshootDeviceCreateSfActivity.fulfilled, (state, action) => {
            state.loadingCreateSfActivity = false
            state.createSfActivity = action?.payload
        });
        builder.addCase(troubleshootDeviceCreateSfActivity.rejected, (state, action) => {
            state.loadingCreateSfActivity = false
        });

        //troubleshootDevicePingConfirmation
        builder.addCase(troubleshootDevicePingConfirmation.pending, (state, action) => {
            state.loadingPingConfirmation = true
        });
        builder.addCase(troubleshootDevicePingConfirmation.fulfilled, (state, action) => {
            state.loadingPingConfirmation = false
            state.pingConfirmation = action?.payload
        });
        builder.addCase(troubleshootDevicePingConfirmation.rejected, (state, action) => {
            state.loadingPingConfirmation = false
        });

    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = troubleshootDeviceRedux;

// Extract and export each action creator by name
export const { reset, openTroubleshootDeviceModal, editField } = actions;

// Export the reducer, either as a default or named export
export default reducer;