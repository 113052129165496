import React from "react";
import { Box } from "@mui/material";
import ToggleLanguage from "Components/ToggleLanguage";
import { ConcatLabel } from "Components";
import { SetUsername } from "../utils";
import { useIntl } from "react-intl";
import { endpoints } from "Core/defaultValues";
import { HasPermission } from "Core/security";
import { useDispatch, useSelector } from "react-redux";
import { ModalType } from "./ModalType";
import { useNavigate } from "react-router-dom";

const URL_TRAINING_STANDARD = "https://gps_trackit.timetap.com/#/" 
const URL_TRAINING_SMARTWITNESS = "https://gpstrackitvidfleet.timetap.com/#/"
export const DefaultMenu = ({ handleOptionClick, user, current_reseller, logout, type }) => {
  const isSMLogued = localStorage.getItem("sm");
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const { messages } = useIntl();

  const userData = useSelector((state) => state.securityRedux.user);
  const isDeveloper = useSelector((state) => state.commomRedux.isDeveloper);
  let siteOptions = user && user.siteOptions?.split(',');
  const hasTraining = siteOptions?.includes("training")
  let urlTraining = URL_TRAINING_STANDARD;
  if (
     siteOptions?.includes("vidfleetvideo") &&
    !user.companyName?.includes("US Cellular")
  ) {
    urlTraining = URL_TRAINING_SMARTWITNESS
  }
  
  return (
    <>
      <div className="username">
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <ConcatLabel username={userData.fullName} concatLimit={15} />
          </div>
          <div>
            <ToggleLanguage />
          </div>
        </Box>
      </div>
      <ul>
        {!userData.isSubUser && (
          <li
            onClick={() => handleOptionClick(ModalType.PREFERENCES)}
          >
            {messages["header_preferences"]}
          </li>
        )}
        {
          hasTraining && (
            <li
              onClick={() => window.open(urlTraining, "_blank")}
            >
              {messages["header_schedule_training"]}
            </li>
          )
        }
        {!isSMLogued && (
          <li
            onClick={() => handleOptionClick(ModalType.PWD_CHANGE)}
          >
            {messages["header_changePassword"]}
          </li>
        )}
        
        {HasPermission(user, "help", [104]) && (<HelpClickLi current_reseller={current_reseller}>{messages["header_help"]}</HelpClickLi>)}
        {isDeveloper && (
          <li
            onClick={() => {
              //SetIdToken();
              //RefreshToken();
              SetUsername();
            }}
          >
            Test Refresh Token
          </li>
        )}
        <li
          onClick={() => {
            logout(navigate, current_reseller, type);
          }}
        >
          {messages["header_logout"]}
        </li>
      </ul>
    </>
  );
};

// Method to set a correct URL to the help label
  const HelpClickLi = ({children, current_reseller}) => {
    let url = endpoints.HELP_URL;
    if (current_reseller?.helpUrl) {
      if (current_reseller) {
        // If the current user is a reseller and have helpUrl, the url for help will be replaced
        url = current_reseller.helpUrl;
      } else {
        return <></>;
      }
    }
    return (
       // TODO submodule-approved
        <li
          onClick={() => {
            window.open(url, "_blank");
          }}
        >
          {children}
        </li>
      )
  };
