import React, { useEffect, useCallback, useRef } from 'react';
import * as THREE from 'three';
// Styles
import './image360.scss';

const Image360 = (props) => {
  let viewerTopRef = useRef();
  let viewerDownRef = useRef();

  useEffect(() => {
    if (props.imageUrl) {
      init();
    }
  }, [props.imageUrl]);

  const init = useCallback(() => {
    // get the DOM Element Size
    let box = viewerTopRef.current.getBoundingClientRect();
    let viewerTop = viewerTopRef.current;
    let viewerDown = viewerDownRef.current;

    let options = {
      rotateX: props.isUpsidedown ? -Math.PI : 0,
      rotateY: 0,
      rotateZ: 0,
    };

    // use WebGL(THREE.js)
    let scene = new THREE.Scene();

    //create a camera (a concept in THREE)
    let camera = new THREE.PerspectiveCamera(52, box.width / box.height, 1, 2000);
    // Position it in the center of a sphere
    // Focus on the up half
    camera.target = new THREE.Vector3(0, 0, 0);
    camera.position.z = 200;
    camera.rotation.x = 0.2;

    //create a canvas for top viewer(front view)
    const rendererTop = new THREE.WebGLRenderer();
    rendererTop.setPixelRatio(window.devicePixelRatio);
    rendererTop.setSize(box.width, box.height);
    viewerTop.appendChild(rendererTop.domElement);

    //create a canvas for bottom viewer(back view)
    let rendererBottom = new THREE.WebGLRenderer();
    rendererBottom.setPixelRatio(window.devicePixelRatio);
    rendererBottom.setSize(box.width, box.height);
    viewerDown.appendChild(rendererBottom.domElement);

    // load the image as a texture (props.src[url])
    let loader = new THREE.TextureLoader();
    let texture = loader.load(props.imageUrl, () => {
      rendererTop.render(scene, camera);
      //rotation the sphere to render another half;
      sphere.rotation.y = Math.PI;
      rendererBottom.render(scene, camera);
      clearScene(scene);
    });

    texture.image.crossOrigin = "Anonymous";

    texture.wrapS = THREE.RepeatWrapping;
    texture.repeat.x = -1;

    // The main method to project the image on a sphere
    let geometry = new THREE.SphereBufferGeometry(300, 200, 200);
    let normals = geometry.attributes.normal.array;
    let uvs = geometry.attributes.uv.array;
    for (let i = 0, l = normals.length / 3; i < l; i++) {
      let x = normals[i * 3 + 0];
      let y = normals[i * 3 + 1];
      let z = normals[i * 3 + 2];

      let r = Math.asin(Math.sqrt(x * x + z * z) / Math.sqrt(x * x + y * y + z * z)) / Math.PI;
      if (y < 0) r = 1 - r
      let theta = x == 0 && z == 0 ? 0 : Math.acos(x / Math.sqrt(x * x + z * z));
      if (z < 0) theta = theta * -1
      uvs[i * 2 + 0] = -0.8 * r * Math.cos(theta) + 0.5;
      uvs[i * 2 + 1] = 0.8 * r * Math.sin(theta) + 0.5;
    }

    geometry.rotateX(options.rotateX);
    geometry.rotateY(options.rotateY);
    geometry.rotateZ(options.rotateZ);

    //add the mesh to the scene
    let material = new THREE.MeshBasicMaterial({ map: texture });
    material.side = THREE.DoubleSide;

    let sphere = new THREE.Mesh(geometry, material);
    scene.add(sphere);
  });

  const clearScene = (scene) => {
    for (let i = scene.children.length - 1; i >= 0; i--) {
      let object = scene.children[i]
      if (object.type === 'Mesh') {
        object.geometry.dispose()
        object.material.dispose()
        scene.remove(object)
      }
    }
  }

  return (
    <div className="image-360-container">
      <div ref={viewerTopRef} style={{ width: '100%', height: '50%' }}></div>
      <div ref={viewerDownRef} style={{ width: '100%', height: '50%' }}></div>
    </div>
  )
}

export default Image360;