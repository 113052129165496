import React from 'react'

export const IconReactMarkerRouteFSMPoint = ({color = '#f50057', number = 0}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 48">
            <path d="M16 0C7.164 0 0 7.164 0 16c0 14.592 16 32 16 32s16-17.408 16-32C32 7.164 24.836 0 16 0zm0 22c-2.208 0-4-1.792-4-4s1.792-4 4-4s4 1.792 4 4s-1.792 4-4 4z" fill={`${color}`} />
            <circle cx="16" cy="20" r="20" fill={`${color}`} />
            { number > 0 ? <text x="16" y="18" textAnchor="middle" fill="white" fontSize="18" fontWeight="bold" fontFamily="Arial, sans-serif" dy=".4em">{number}</text> : <></> }
        </svg>
    );
}


export const IconMarkerRoutesTracker = ({ color = '#f50057', number = 1 , size = 'full' }) => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height={size == 'full' ?  "150" : "92"} viewBox={`0 0 100 ${size == 'full' ? 150 : 92}`}>    
            <path d="M50 30c-10 0-18 8-18 18s8 18 18 18s18-8 18-18S60 30 50 30z" fill={`${color}`} />
            <polygon points="50,75 65,58 35,58" fill={`${color}`} />
            <circle cx="50" cy={size == 'full' ? "128" : "84"} r="8" fill={`${color}`} />
            <circle cx="50" cy={size == 'full' ? "128" : "84"} r="5" fill="white" />
            <circle cx="50" cy={size == 'full' ? "128" : "84"} r="3" fill={`${color}`} />
            <text x="50" y="47" textAnchor="middle" fill="white" fontSize="18" fontWeight="bold" fontFamily="Arial, sans-serif" dy=".4em">{number}</text>
        </svg>

    )
}

export const IconMarkerRoutesTrackerSmall = ({ color = '#f50057', number = 1 , size = 'full' }) => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="92" viewBox="0 0 72 92">
            <path d="M35 10c-10 0-18 8-18 18s8 18 18 18s18-8 18-18S45 10 35 10z" fill={`${color}`} />
            <polygon points="35,55 50,38 20,38" fill={`${color}`} />
            <circle cx="35" cy="66" r="8" fill={`${color}`} />
            <circle cx="35" cy="66" r="5" fill="white" />
            <circle cx="35" cy="66" r="3" fill={`${color}`} />
            <text x="35" y="29" textAnchor="middle" fill="white" fontSize="18" fontWeight="bold" fontFamily="Arial, sans-serif" dy=".4em">{number}</text>
        </svg>

    )
}

export const IconMarkerVehicleTrailsPoint = ({ color = '#808080'}) => {
    return `
            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="92" viewBox="26 58 20 16">
                {/* <!-- circle ext --> */}
                <circle cx="36" cy="66" r="8" fill="none" stroke='${color}' stroke-width="2"/>
                {/* <!-- circle inside big inside transparent --> */}
                <circle cx="36" cy="66" r="6" fill="none" stroke="none" stroke-width="2"/>
                {/* <!-- circle small inside --> */}
                <circle cx="36" cy="66" r="4" fill='${color}' />
            </svg>
        `;

}
