import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'

const ThirdPartyApi = () => {
  const { user } = useSelector(state => state.securityRedux)
  let sm = localStorage.getItem("sm")

  let authenticatedId = "company-" + user.companyId + (user.subUserId ? "-user-" + user.subUserId : "")

  useEffect(() => {
    //console.log("user pendo", user, authenticatedId)

    if (!sm)
      /*global pendo*/
      pendo.initialize({
        visitor: {
          id: authenticatedId,   // Required if user is logged in
          email: user.email,        // Recommended if using Pendo Feedback, or NPS Email
          full_name: user.fullName,    // Recommended if using Pendo Feedback
          // role:         // Optional
          username: user.username,
          isSubUser: user.isSubUser,
          environment: process.env.ENVIROMENT

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          id: user.companyId, // Highly recommended
          name: user.companyName,// Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          creationDate: user.createdOn,// Optional
          unitsCount: user.unitsCount,
          dealerId: user.dealerId,
          packageId: user.packageId,
          packageName: user.packageName
          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        }
      });


    return () => {
      //shutdown();
    };

  }, []);

  return (
    <div> </div>
  )
}

export default ThirdPartyApi;