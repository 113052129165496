import React from "react";
import {
  ErrorHandlerLogin,
  isAppleError,
  isFacebookError,
  EmailNotFound,
} from "Core/data/Helpers";
import { useIntl } from "react-intl";

const SocialMediaError = ({
  error,
  setShowErrorSocialMedia,
  setErrorIsInFacebook,
  showPassword,
}) => {
  const { messages } = useIntl();

  return (
    <div className="form-item card-error-login">
      <p className="txt-card-error-login">
        {isFacebookError(error) ? (
          <>
            <p>
              {messages["login_social_error_title"]}{" "}
              {messages["login_facebook"]}.{" "}
              <span
                className="learn-more-error"
                onClick={() => {
                  setShowErrorSocialMedia(true);
                  setErrorIsInFacebook(true);
                }}
              >
                Learn more
              </span>
            </p>
          </>
        ) : isAppleError(error) ? (
          <>
            <p>
              {messages["login_social_error_title"]} {messages["login_apple"]}.{" "}
              <span
                className="learn-more-error"
                onClick={() => {
                  setShowErrorSocialMedia(true);
                  setErrorIsInFacebook(false);
                }}
              >
                Learn more
              </span>
            </p>
          </>
        ) : (
          <>
            {messages[`${ErrorHandlerLogin(error, showPassword)}`] ||
              ErrorHandlerLogin(error)}
            {EmailNotFound(error) && (
              <>
                <p>
                  <b>{messages["login_social_recommendation_google"]}:</b>{" "}
                  <br />
                  {messages["login_social_multiples_accounts"]}
                  <a
                    href="https://accounts.google.com/AddSession"
                    target="_blank"
                    rel="noreferrer"
                    className="email-not-found-error"
                  >
                    <span>{messages["login_social_here"]}</span>
                  </a>
                </p>
              </>
            )}
          </>
        )}
      </p>
    </div>
  );
};

export default SocialMediaError;
