export default {
    "FSM_SETTINGS_QUICK_RESPONSE": "Respuesta rápida",
    "FSM_SETTINGS_QUICK_RESPONSE_title": "Nueva Respuesta rápida",
    "FSM_SETTINGS_QUICK_RESPONSE_edit": "Editar Respuesta rápida ",
    "FSM_SETTINGS_QUICK_RESPONSE_delete_detail":'¿Está seguro de que desea eliminar los siguientes formularios?',
    "FSM_SETTINGS_QUICK_RESPONSE_delete_warning":'Esta acción no se puede deshacer.',
    "FSM_SETTINGS_QUICK_RESPONSE_delete_title":'Eliminar Respuesta rapida',
    "FSM_SETTINGS_QUICK_RESPONSE_create_success": "Respuesta rápida Creada ",
    "FSM_SETTINGS_QUICK_RESPONSE_update_success": "Respuesta rápida Actualizada",
    "FSM_SETTINGS_QUICK_RESPONSE_delete_success": "Respuesta rápida Eliminada",

};