//View Keys
export default {
  loginHistoryView_login: 'Inicios de sesión',
	loginHistoryView_loginTime: 'Hora de inicio de sesión',
  loginHistoryView_username: 'Nombre de usuario',
  loginHistoryView_ip: 'Nombre de host / dirección IP',
  loginHistoryView_attempt: 'Intento',
  loginHistory_successful: "Exitoso",
  loginHistory_failed: "Fallido",
};
