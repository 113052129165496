//View Keys
export default {
	locationsView_locations: 'Ubicaciones',
    locationsView_vehicleLocation: 'Ubicación del vehículo',
    locationsView_units: 'Unidades',
    locationsView_delete: 'Eliminar',
    locationsView_addLocation: 'Añadir',
    locationsView_createNewLocation: 'Crear una nueva ubicación',
    locationsView_editLocation: 'Editar Ubicación',
    locationsView_successfullyCreated: 'La ubicación fue creada con éxito',
    locationsView_successfullyEdited: 'La ubicación fue editada con éxito',
    locationsView_messagesDelete: 'Está seguro de que quiere eliminar las ubicaciones',
    locationsView_locationName: 'Nombre de la ubicación',
    locationsView_deleteLocation: 'Borrar ubicación',
    locationsView_move: 'Mover a',
    locationsView_deleteLabel: 'Borrar (Se borran todas las ubicaciones de las unidades)',
    locationsView_deleteMessage: 'Qué quiere hacer con las ubicaciones asignados a las unidades',
    locationsView_deleteSuccess: 'Ubicaciones eliminadas con éxito',
    locationsView_availableActions: 'Acciones disponibles',
    locationsView_searchByLocation: 'Buscar por ubicación del vehículo',
};