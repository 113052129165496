import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../core/logManager';

//STATE INITIAL
const INIT_STATE = {
    loadingServiceDetail: false,
    serviceDetail: [],
    loadingVehicles: false,
    vehicles: [],
    error: null,
};

const route = 'modules/maintenance/views/serviceDetail/redux/slice.'

export const maintenanceVSDGetMaintenanceService = createAsyncThunk('maintenance/serviceDetail/getMaintenanceService', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `{
                data: getMaintenanceService(id: ${id}) {
                    id
                    title
                    notes
                    miles
                    milesBefore
                    milesAfter
                    days
                    daysBefore
                    daysAfter
                    engineHours
                    engineHoursBefore
                    engineHoursAfter
                    recurrent
                    estimatedCost
                    unitsCount
                    subscribersCount
                    fileIds
                    serviceItems{
                        id
                        name
                        labor
                        parts
                        subtotal
                    }
                    units {
                        id
                        serviceId
                        unitId
                        lastReadingId
                        lastMaintenanceDate
                        lastMaintenanceOdometer
                        lastMaintenanceEngineHours
                        nextServiceDate
                        nextServiceOdometer
                        nextServiceEngineHours
                        status
                        notes
                        completed
                        estimatedCost
                        fileIds
                        subscribersCount
                    } 
                }
            }
            `,
            {},
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response.data
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceVSDGetMaintenanceService');
        return rejectWithValue(exc)
    }
});

export const maintenanceVSDSearchUnits = createAsyncThunk('maintenance/serviceDetail/searchUnits', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `{
                data: searchUnits {
                    id
                    lastMaintenanceServiceUnitLog {
                        id
                        maintenanceDate
                        serviceUnit {
                            service {
                                title
                            }
                        }
                    }
                }
            }
            `,
            {},
            endpoints.GRAPHQL_GENERAL
        );
        return response.data
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceVSDGetMaintenanceService');
        return rejectWithValue(exc)
    }
});

//Slice
const maintenanceServiceDetailRedux = createSlice({
    name: 'maintenance/serviceDetail',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : INIT_STATE
        }
    },
    extraReducers: (builder) => {

        //maintenanceVSDGetMaintenanceService
        builder.addCase(maintenanceVSDGetMaintenanceService.pending, (state, action) => {
            state.loadingServiceDetail = true;
        });
        builder.addCase(maintenanceVSDGetMaintenanceService.fulfilled, (state, action) => {
            state.loadingServiceDetail = false;
            state.serviceDetail = action.payload;
        });
        builder.addCase(maintenanceVSDGetMaintenanceService.rejected, (state, action) => {
            state.loadingServiceDetail = false;
            state.error = action.payload;
        });

        //maintenanceVSDSearchUnits
        builder.addCase(maintenanceVSDSearchUnits.pending, (state, action) => {
            state.loadingVehicles = true;
        });
        builder.addCase(maintenanceVSDSearchUnits.fulfilled, (state, action) => {
            state.loadingVehicles = false;
            state.vehicles = action.payload;
        });
        builder.addCase(maintenanceVSDSearchUnits.rejected, (state, action) => {
            state.loadingVehicles = false;
            state.error = action.payload;
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = maintenanceServiceDetailRedux;

// Extract and export each action creator by name
export const { reset } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;