// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  UNIT_SETTINGS_GET_UNIT_GROUPS,
  UNIT_SETTINGS_UPDATE_MULTIPLE_UNITS
} from "Redux/actionTypes";

// Actions
import {
  getUnitGroupsByUnitsResponse,
  updateUnitGroupTagAssignmentResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";


function* updateUnitGroupTagAssignment({ payload }) {
  try {
    const { data, dataIcons } = payload;

    let mutations = [];
    data.forEach((d, index) => {
      mutations.push(`Unit${index}: updateTagAssignment(
          tagId: ${d?.tagId}
          entityName: ${d?.entityName}
          entityIds: [${d?.entityIds?.join(", ")}]
          action: ${d?.action}
        )`)
    });

    //add the bulk to icons
    if(dataIcons && dataIcons?.updateIcon){
      mutations.push(`Unit${data.length}: updateVehicleStatusIconBulk(
        unitIds: [${dataIcons.unitIds?.join(",")}]
        ${dataIcons.iconId ? `iconId: ${dataIcons.iconId}` : '' }
      )`)
    }

    let mutation = `mutation{${mutations.join("")}}`;

    const response = yield call(
      clientMutation,
      mutation,
      {},
      endpoints.GRAPHQL_GENERAL,
    );
    
    if (response && response.Unit0) {
      yield put(updateUnitGroupTagAssignmentResponse({ updateUnits: response.Unit0, error: false }));
    } else {
      throw 'Error while updated units.';
    }
  } catch (exc) {
    yield put(
      updateUnitGroupTagAssignmentResponse({
        updateUnits: false,
        error: true
      })
    );
    ExceptionManager(
      exc,
      "modules/settings/views/unitSettings/redux/sagas",
      "updateUnitGroupTagAssignment"
    );
  }
}

function* watchMethods() {
  yield takeEvery(UNIT_SETTINGS_UPDATE_MULTIPLE_UNITS, updateUnitGroupTagAssignment);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}