//imports
import { all } from 'redux-saga/effects';

//Views
import behaviorMainSagas from 'Modules/behavior/views/main/redux/saga';

//merge sagas
export default function* rootSaga() {
	yield all([
		behaviorMainSagas()
	]);
}
