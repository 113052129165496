
import React from 'react';

/*********************************************************
 * Icons
 *********************************************************/
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

/*********************************************************
 * Style
 *********************************************************/
import './style.scss';

/*********************************************************
 * Author:  Walter Paz Londoño
 * Date:    28 Jan 2020
 * Module:  Realtime Maps
 * 
 * Class associated to the botton in realtime maps view
 ********************************************************/
const ButtonToggleMap = (props) =>  {
  return (
    <button 
      onClick={(event) => {
        if(props.onClick){
          props.onClick(event)
        }
      }}
  >
        {props.label ? props.label : '' }
        {props.open ? <ArrowDropDownIcon className={'legend-button'} /> : <ArrowDropUpIcon className={'legend-button'} /> }
    </button>
      
  )
}

export default ButtonToggleMap;