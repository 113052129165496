//Module Keys
let locales = {};

//Merge Views
import mainView from 'Modules/dynamicForms/views/main/lan/es';
import formsView from 'Modules/dynamicForms/views/main/components/forms/lan/es';
import answersView from 'Modules/dynamicForms/views/main/components/answers/lan/es';
import designView from 'Modules/dynamicForms/modals/design/lan/es';
import deleteView from 'Modules/dynamicForms/modals/delete/lan/es';
import duplicateView from 'Modules/dynamicForms/modals/duplicate/lan/es';
import renameView from 'Modules/dynamicForms/modals/rename/lan/es';
import viewAnswersView from 'Modules/dynamicForms/modals/viewAnswers/lan/es';

locales = { ...locales, ...mainView };
locales = { ...locales, ...formsView };
locales = { ...locales, ...answersView };
locales = { ...locales, ...designView };
locales = { ...locales, ...deleteView };
locales = { ...locales, ...duplicateView };
locales = { ...locales, ...renameView };
locales = { ...locales, ...viewAnswersView };

export default locales;