import {
  ALERTS_TAB_GET_ALERTS,
  ALERTS_TAB_GET_ALERT,
  ALERTS_TAB_GET_ALERTS_RESULT,
  ALERTS_TAB_EDIT_SUBSCRIBERS,
  ALERTS_TAB_CLOSE_MODAL,
  ALERTS_TAB_OPEN_FILTER_MODAL,
  ALERTS_TAB_MANAGE_FILTER,
  ALERTS_TAB_SAVE_ALERTS,
  ALERTS_TAB_DELETE_ALERTS,
  ALERTS_TAB_DELETE_ALERT_SUBSCRIBER,
  ALERTS_TAB_OPEN_DELETE_MODAL,
  ALERTS_TAB_OPEN_ASSIGNED_MODAL
} from 'Redux/actionTypes';

export const searchAlertsTab = (assignedTo) => ({
  type: ALERTS_TAB_GET_ALERTS,
  payload: assignedTo,
});

export const searchAlertsTabResult = (alerts) => ({
  type: ALERTS_TAB_GET_ALERTS_RESULT,
  payload: alerts,
});

export const alertsOpenModalSubscribers = (alert) => ({
  type: ALERTS_TAB_EDIT_SUBSCRIBERS,
  payload: alert,
});

export const alertsCloseModalAlertsTab = () => ({
  type: ALERTS_TAB_CLOSE_MODAL,
});

export const alertsOpenFilterModal = (open) => ({
  type: ALERTS_TAB_OPEN_FILTER_MODAL,
  payload: { open }
});

export const alertsManageFilter = (filter) => ({
  type: ALERTS_TAB_MANAGE_FILTER,
  payload: filter,
});

export const saveAlertsResult = (alerts) => ({
  type: ALERTS_TAB_SAVE_ALERTS,
  payload: alerts,
})

export const deleteAlerts = (alerts, successDeletionMessage, failedDeletionMessage) => ({
  type: ALERTS_TAB_DELETE_ALERTS,
  payload: { alerts, successDeletionMessage, failedDeletionMessage },
})

export const deleteAlertSubscriber = (
  alertSubscriberId,
  alertId,
  successDeletionMessage,
  failedDeletionMessage,
) => ({
  type: ALERTS_TAB_DELETE_ALERT_SUBSCRIBER,
  payload: { alertSubscriberId, alertId, successDeletionMessage, failedDeletionMessage },
})

export const searchSystemAlert = (alertId) => ({
  type: ALERTS_TAB_GET_ALERT,
  payload: alertId,
})

export const alertsOpenDeleteModal = (open,alertsDeleted) => ({
  type: ALERTS_TAB_OPEN_DELETE_MODAL,
  payload: { open,alertsDeleted }
});

export const alertsOpenAssignedModal = (open, alert, tab1, tab2) => ({
  type: ALERTS_TAB_OPEN_ASSIGNED_MODAL,
  payload: { open, alert, tab1, tab2 }
});