import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate,useParams } from 'react-router-dom';

import 'Core/views/error/index.scss';


const ErrorComponent = (props) => {

  const { messages } = useIntl();
  const navigate = useNavigate()
  const { type } = useParams();


  return (
    <div className="containerError">
      <div className="containerErrorCenter">
        <div>
          <img src="https://gpstrackit.com/wp-content/uploads/2020/04/Service-Van.gif" />
        </div>
        <div className="containerErrorInternal">
          <div className="containerErrorCenter" style={{ display: "contents" }}>
            <div>
              <span className="errorLabelTiitle">404</span>
            </div>
            <div>
              <span className="errorLabelOpps">{type?messages[type]:messages['page_not_found']}</span>
            </div>
            <div style={{ maxWidth: 400 }}>
              <span className="errorLabelDescription" style={{ cursor: "pointer" }} onClick={() => {
                navigate("/app/realtimemaps/main")
              }}>{messages['page_not_found_homepage']}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorComponent;
