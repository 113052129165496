import {
	FLEET_MODAL_OUTPUTS_OPEN,
	FLEET_MODAL_OUTPUTS_GET,
	FLEET_MODAL_OUTPUTS_GET_RESULT,
	FLEET_MODAL_OUTPUTS_SAVE,
	FLEET_MODAL_OUTPUTS_SAVE_RESULT
} from 'Redux/actionTypes';

export const fleetModalOutputsOpen = (open, id) => ({
	type: FLEET_MODAL_OUTPUTS_OPEN,
	payload: { open, id }
});

export const fleetModalOutputsGet = (id) => ({
	type: FLEET_MODAL_OUTPUTS_GET,
	payload: { id }
});

export const fleetModalOutputsGetResult = (response) => ({
	type: FLEET_MODAL_OUTPUTS_GET_RESULT,
	payload: { response }
});

export const fleetModalOutputsSave = (data) => ({
	type: FLEET_MODAL_OUTPUTS_SAVE,
	payload: { data }
});

export const fleetModalOutputsSaveResult = (response) => ({
	type: FLEET_MODAL_OUTPUTS_SAVE_RESULT,
	payload: { response }
});