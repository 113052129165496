export default {
    settingsFuelCard_fuelCard: "Fuel Card",
    settingsFuelCard_vehicle: "Vehicle",
    settingsFuelCard_unitDriver: "Unit / Driver",
    settingsFuelCard_tankSize: "Tank Size (Value in Gallons)",
    settingsFuelCard_searchTankSize: "Search by Fuel Card",
    settingsFuelCard_driver: "Driver",
    settingsFuelCard_unit: "Unit",
    settingsFuelCard_success: "Fuel Card Saved",
    settingsFuelCard_unassignment: "Unassignment",
    settingsFuelCard_vehicleDescription: "Vehicle Description",
    settingsFuelCard_editFuelCard: "Edit Fuel Card",
    settingsFuelCard_bulkSettings: "Bulk Settings",
    settingsFuelCard_fuelCardSelected: "Selected Fuel Card Units",
    settingsFuelCard_bulkFuelCardAssignment: "Bulk Tank Size Assignment",
    settingsFuelCard_bulkErrorDescription: "Select only fuel cards that have a unit assigned."
};