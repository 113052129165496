import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    loading: false,
    open: false,
    routePointId: 0,
    routePoint: null,
    routePointLogs: [],
    loadingRoutePointLog: false,
    error: null
};

const route = "settings/views/routesTracker/components/modalStopInformation";

export const routesTrackerGetRoutePoint = createAsyncThunk('routes/modalStopInformation/routesTrackerGetRoutePoint', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query getRoutePoint ($id: Int!) {
                RoutePoint: getRoutePoint (id: $id) {
                    name
                    address
                    city
                    state
                    landmarkId
                    landmark {
                        title
                    }
                    latitude
                    longitude
                    radius
                    sequence
                    note

                    arrivalTime
                    departureTime
                    status
                    statusChangedOn
                }
              }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.RoutePoint || null;

    } catch (exc) {
        ExceptionManager(exc, route, 'routesTrackerGetRoutePoint');
        return rejectWithValue(exc)
    }
});


export const routesTrackerRoutePointsLogSearchRoutes = createAsyncThunk('routes/modalStopInformation/routesTrackerRoutePointsLogSearchRoutes', async ({ pointId, limit = 50, offset = 0 }, { rejectWithValue, dispatch }) => {
    try {
        
        const conditions = {
            fields: ["pointId"],
            terms: [pointId],
            exact: 1
        }

        const sort = {
            field: "createdOn",
            order: "DESC"
        }; 


        const response = await clientQuery(
            `
            query searchRoutePointLogs (
                $conditions: [RoutePointLogSearchInput],
                $sort: [RoutePointLogSortInput], 
                $limit: Int, 
                $offset: Int
              ) {
                RoutePointsLog: searchRoutePointLogs (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                  items {
                    id
                    pointId
                    status
                    address
                    note
                    latitude
                    longitude
                    createdOn
                    routePoint {
                        id
                        name
                    }
                  }
                  total
                }
              }
            `,
            {
                conditions: conditions,
                sort, 
                limit, 
                offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        
        
        return response?.RoutePointsLog || { items: [], total: 0 }; 

    } catch (exc) {
        ExceptionManager(exc, route, 'routePointsLogSearchRoutes');
        return rejectWithValue(exc)
    }
});


export const RoutesTrackerModalStopInformationRedux = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalRoutesTrackerStopInformation: (state, action) => {
            return {
                ...state,
                open: action?.payload?.open || false,
                routePointId: action?.payload?.routePointId || 0
            }
        }
    },

    extraReducers: (builder) => {

        builder.addCase(routesTrackerGetRoutePoint.pending, (state, action) => {
            state.routePoint = null;
            state.loading = true

        });
        builder.addCase(routesTrackerGetRoutePoint.fulfilled, (state, action) => {
            state.routePoint = action?.payload;
            state.loading = false;

        });
        builder.addCase(routesTrackerGetRoutePoint.rejected, (state, action) => {
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loading = false;

        });

        builder.addCase(routesTrackerRoutePointsLogSearchRoutes.pending, (state, action) => {
            state.routePointLogs = [];
            state.loadingRoutePointLog = true

        });
        builder.addCase(routesTrackerRoutePointsLogSearchRoutes.fulfilled, (state, action) => {
            state.routePointLogs = action?.payload;
            state.loadingRoutePointLog = false;

        });
        builder.addCase(routesTrackerRoutePointsLogSearchRoutes.rejected, (state, action) => {
            state.erorrRoutes = action.payload;
            state.loadingRoutePointLog = false;

        });
    }

});


const { actions, reducer } = RoutesTrackerModalStopInformationRedux;

export const { reset, openModalRoutesTrackerStopInformation } = actions;

export default reducer;