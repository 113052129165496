import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import './Stepper.scss'

const customStyle = {
    '& .MuiStepConnector-alternativeLabel': {
        top: '12px',
        left: 'calc(-50% + 20px)',
        right: 'calc(50% + 20px)',
    },

    '& .MuiStepConnector-line': {
        borderColor: '#eaeaf0',
        borderTopWidth: '3px',
        borderRadius: '1px',
    },
};

const CustomizedSteppers = (props) => {
    const { steps, activeStep, className } = props
    
    return (
        <div style={{width: '100%'}}>
            <div className="stepper-container">
                <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    className={className}
                    connector={<StepConnector sx={customStyle}/>}
                >
                    {
                        steps.map((label) => (
                            <Step key={label} >
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))
                    }
                </Stepper>
            </div>
        </div >
    );
}

export default CustomizedSteppers