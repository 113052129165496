export default {
    "fsm_settings_title": "FSM Configuraciones",
    "fsm_settings_form": "Formulario",
    "fsm_settings_form_tags": "Etiquetas: ",
    "fsm_settings_form_selectAll": "Seleccionar Todos",
    "fsm_settings_form_edit": "Editar Formulario ",
    "fsm_settings_form_new": "Nuevo Formulario ",
    "fsm_settings_form_title": "Titulo",
    "fsm_settings_form_name": "Nombre",
    "fsm_settings_form_questions": "Preguntas",
    "fsm_settings_form_answer": "Respuestas",
    "fsm_settings_form_addQuestion": "Agregar Pregunta",
    "fsm_settings_form_nameRequired": "Por favor complete el campo Nombre",
    "fsm_settings_form_tagsRequired": "Por favor complete el campo Tags",
    "fsm_settings_form_questionsRequired": "Por favor complete los campos de las preguntas",
    "fsm_settings_form_delete_detail":'¿Está seguro de que desea eliminar los siguientes formularios?',
    "fsm_settings_form_delete_warning":'Esta acción no se puede deshacer.',
    "fsm_settings_form_delete_title":'Eliminar Formularios',
    "fsm_settings_form_create_success": "Formulario Creado ",
    "fsm_settings_form_update_success": "Formulario Actualizado",
    "fsm_settings_form_delete_success": "Formularios Eliminados",
    "fsm_settings_form_error_answer_duplicate": "La respuesta ya existe",
    "fsm_settings_form_error_question_duplicate": "Se encontraton preguntas repetidas",
    "fsm_settings_form_no_more_items": "No hay más elementos",
    "fsm_settings_form_no_items_found": "No hay elementos"
};