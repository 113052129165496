import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    loading: false,
    open: false,
    data: null,
    loadingRoutes: false, 
    routeId: 0,
    isTemplate: false,
    error: null
};

const route = "modules/realtimeMaps/routes/components/modalCreateEditRoute";


export const RealtimeMapsRoutesModalCreateEditRoute = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalRealtimeMapsRoutesCreateEditRoute: (state, action) => {
            return {
                ...state,
                open: action?.payload?.open || false,
                routeId: action?.payload?.routeId,
                isTemplate: action?.payload?.isTemplate
            }
        }
    },

});


const { actions, reducer } = RealtimeMapsRoutesModalCreateEditRoute;

export const { reset, openModalRealtimeMapsRoutesCreateEditRoute } = actions;

export default reducer;