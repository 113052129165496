
const locales = {
    chat_usersConnected: 'Solo usuarios conectados',
    chat_newChat: "Seleccione un usuario para iniciar un chat:",
    chat_lastMessage: "Último mensaje  ",
    MASTERUSER: 'Usuario Admin',
    SUBUSER: 'Usuario',
    DRIVER: 'Conductor'
};

export default locales;