import {
    MAP_LANDMARKS_BULK_OPEN_MODAL,
    MAP_LANDMARKS_BULK_UPDATE,
    MAP_LANDMARKS_BULK_UPDATE_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    open: false,
    landmarksToBulk: [],
    loadingLandmarkBulk: false,
    response: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MAP_LANDMARKS_BULK_OPEN_MODAL:
            return {
                ...state,
                open: action.payload.open,
                landmarksToBulk: action.payload.landmarks,
                response: null
            };

        case MAP_LANDMARKS_BULK_UPDATE:
            return {
                ...state,
                response: null,
                loadingLandmarkBulk: true
            };

        case MAP_LANDMARKS_BULK_UPDATE_RESPONSE:
            return {
                ...state,
                response: action.payload.data,
                loadingLandmarkBulk: false
            };
        
        case CLEAR_REDUX:
            return ["", "MAP_LANDMARKS_BULK"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
      return state;
    }
}