import {
  REPORTS_MAIN_SHOW_REPORTSETUP,
  REPORTS_MAIN_HIDE_REPORTSETUP,
  REPORTS_MAIN_SET_FORM_DATA_REPORTSETUP,
  REPORTS_MAIN_UPDATE_REPORT,
  REPORTS_MAIN_UPDATE_REPORT_RESULT,
  REPORTS_MAIN_GENERATE_REPORT,
  REPORTS_MAIN_GENERATE_REPORT_UPDATE,
  REPORTS_MAIN_GENERATE_REPORT_RESULT,
  REPORTS_MAIN_GENERATE_REPORT_PROGRESS,
  REPORTS_MAIN_REMOVE_REPORT,
  REPORTS_MAIN_GENERATE_EVENTS,
  REPORTS_MAIN_GENERATE_EVENTS_RESULT,
  REPORTS_MAIN_GENERATE_SUB_EVENTS,
  REPORTS_MAIN_GENERATE_SUB_EVENTS_RESULT,
  REPORTS_VERIFY_STATE_REPORT,
  REPORTS_VERIFY_STATE_REPORT_RESULT,
  GET_LANDMARK_BY_ID,
  GET_LANDMARK_BY_ID_RESULT,
  REPORT_SHOW_MESSAGE,
  REPORTS_GENERATE_REPORT_ASYNC,
  REPORTS_SEARCH_LOG,
  REPORTS_GET_REPORTS_READY_RESULT,
  REPORTS_GET_RECENTLY_GENERATED_RESULT,
  REPORTS_GET_REPORTS_PINNED_RESULT,
  REPORT_UPDATE_FIELD,
  REPORT_UPDATE_FIELD_RESULT,
  REPORTS_GET_JSON_REPORT,
  REPORTS_GET_JSON_REPORT_RESULT,
  GET_SERVICE_ITEMS,
  UPDATE_REPORT_GENERATED,
  REPORTS_MOVE_TO_TAB,
  GET_SERVICE_ITEMS_LEGACY,
  GET_SERVICE_ITEMS_LEGACY_RESULT,
  REPORTS_MAIN_DELETE_REPORT_LOG,
  REPORTS_MAIN_DELETE_REPORT_LOG_RESULT,
  REPORT_UPDATE_FIELD_BATCH
} from "Redux/actionTypes";

export const reportsMainShowReportSetup = () => ({
  type: REPORTS_MAIN_SHOW_REPORTSETUP,
  //payload: {},
});

export const reportsMainHideReportSetup = () => ({
  type: REPORTS_MAIN_HIDE_REPORTSETUP,
  //payload: {},
});

export const reportsMainSetFormDataReportSetup = (payload) => ({
  type: REPORTS_MAIN_SET_FORM_DATA_REPORTSETUP,
  payload,
});

export const reportsMainUpdateReport = (payload ) => ({
  type: REPORTS_MAIN_UPDATE_REPORT,
  payload
});

export const reportsMainUpdateReportResult = (payload) => ({
  type: REPORTS_MAIN_UPDATE_REPORT_RESULT,
  payload
})

export const reportsMainGenerateReport = (allEntities,isAll,timeZone, filterEntity, reportType, filterType, filterArray, startRange, endRange, otherFilters, isExport, exportType, sendByEmail, emailsTo, name, categoryName, timeframeName, delayed, excludeEmptyTables, dataResultPath, createdOn, userId,reportTimeRanges, durationFormat, includeInactiveDrivers, lang ) => ({
  type: REPORTS_MAIN_GENERATE_REPORT,
  payload: { allEntities, isAll, timeZone, filterEntity, reportType, filterType, filterArray, startRange, endRange, otherFilters, isExport, exportType, sendByEmail, emailsTo, name, categoryName, timeframeName, delayed , excludeEmptyTables, dataResultPath, createdOn, userId,reportTimeRanges, durationFormat, includeInactiveDrivers, lang },
});

export const reportsMainGenerateReportResult = () => ({
  type: REPORTS_MAIN_GENERATE_REPORT_RESULT,
  //payload: {},
});

export const reportsMainGenerateReportUpdate = (report, page, rowsPerPage, sortField, sortOrder, requestFlag) => ({
  type: REPORTS_MAIN_GENERATE_REPORT_UPDATE,
  payload: { report, page, rowsPerPage, sortField, sortOrder, requestFlag},
});

export const reportsMainGenerateReportProgress = (report) => ({
  type: REPORTS_MAIN_GENERATE_REPORT_PROGRESS,
  payload: report
});

export const reportsMainRemoveReport = (id) => ({
  type: REPORTS_MAIN_REMOVE_REPORT,
  payload: id
});

export const reportsMainGenerateEvents = (report, event) => {
  return {
    type: REPORTS_MAIN_GENERATE_EVENTS,
    payload: { report, event},
  }
};

export const reportsMainGenerateEventsResult = (event) => ({
  type: REPORTS_MAIN_GENERATE_EVENTS_RESULT,
  payload: event
});


export const reportsMainGenerateSubEvents = (report, event) => {
  return {
    type: REPORTS_MAIN_GENERATE_SUB_EVENTS,
    payload: { report, event},
  }
};

export const reportsMainGenerateSubEventsResult = (event) => ({
  type: REPORTS_MAIN_GENERATE_SUB_EVENTS_RESULT,
  payload: event
});

export const reportsVerifyStateReport = (exportInstanceId, report, isInReports, successMessage, errorMessage) => ({
  type: REPORTS_VERIFY_STATE_REPORT,
  payload: {
    exportInstanceId,
    isInReports,
    report,
    successMessage,
    errorMessage
  }
});

export const reportsVerifyStateReportResult = (event) => ({
  type: REPORTS_VERIFY_STATE_REPORT_RESULT,
  payload: event
});

export const getLandmarkById = (idLandmark) => ({
  type: GET_LANDMARK_BY_ID,
  payload: {
    id: idLandmark
  }
});

export const getLandmarkByIdResult = (landmark) => ({
  type: GET_LANDMARK_BY_ID_RESULT,
  payload: landmark
});

//TODO: RC Cambiar esto
export const getServiceItems = () => ({
  type: GET_SERVICE_ITEMS_LEGACY
});

export const getServiceItemsLegacy = () => ({
  type: GET_SERVICE_ITEMS_LEGACY
});

export const getServiceItemsLegacyResult = (serviceItems) => ({
  type: GET_SERVICE_ITEMS_LEGACY_RESULT,
  payload: serviceItems
});

export const reportShowMessage = (showMessageReport, status, title, message) => ({
  type: REPORT_SHOW_MESSAGE,
  payload: {status, message, title, showMessageReport}
})

export const generateReportAsync = (data, title) => ({
  type: REPORTS_GENERATE_REPORT_ASYNC,
  payload: {data, title}
})

export const searchReportsLog = (category, openSearch, limit, offset, endDate ) => ({
  type: REPORTS_SEARCH_LOG,
  payload: {
    category,
    openSearch,
    limit,
    offset,
    endDate
  }
})

export const reportUpdateField = (id, fielValue, field, createdOn) => ({
  type: REPORT_UPDATE_FIELD,
  payload: {
    id,
    fielValue,
    field,
    createdOn
  }
})

export const reportUpdateFieldResult = (data, fieldName) => ({
  type: REPORT_UPDATE_FIELD_RESULT,
  payload: {
    data,
    fieldName
  }
})

export const reportUpdateFieldBatch = (items, fieldValue, field, createdOn) => ({
  type: REPORT_UPDATE_FIELD_BATCH,
  payload: {
    items,
    fieldValue,
    field,
    createdOn
  }
})

export const getJsonReport = (url, id, UIParams, reportsHome, reports) => ({
  type: REPORTS_GET_JSON_REPORT,
  payload: {url, id, reportsHome, reports, UIParams}
})

export const getJsonReportResult = (data) => ({
  type: REPORTS_GET_JSON_REPORT_RESULT,
  payload: {data}
})

export const moveToTab = (id) => ({
  type: REPORTS_MOVE_TO_TAB,
  payload: {
    id
  }
})


export const generateReportReadyResult = (data, loading) => ({
  type: REPORTS_GET_REPORTS_READY_RESULT,
  payload: { data, loading }
})

export const generateRecentlyReportsResult = (data) => ({
  type: REPORTS_GET_RECENTLY_GENERATED_RESULT,
  payload: { data }
})

export const generatePinnedReportsResult = (data) => ({
  type: REPORTS_GET_REPORTS_PINNED_RESULT,
  payload: { data }
})


export const updateReportGenerated = (report, loading) => {
  return {
    type: UPDATE_REPORT_GENERATED,
    payload: {
      report,
      loading
    }
  }
}
export const deleteReportLog = (id,createdOn,reportLogId,ids, category,idsExclusions) => {
  return {
    type: REPORTS_MAIN_DELETE_REPORT_LOG,
    payload: {
      id,
      createdOn,
      reportLogId,
      ids,
      category,
      idsExclusions
    }
  }
};

export const deleteReportLogResult = (data) => {
  return {
    type: REPORTS_MAIN_DELETE_REPORT_LOG_RESULT,
    payload: { data }
  }
};
