export const SetUsername = () => {
    return localStorage.setItem("username", "gtcemployee")
}

export const configFielsSFChat = ({username, lastname, email, subject}) => {
    window.embedded_svc.liveAgentAPI.startChat({
        extraPrechatInfo: [
          {
            entityFieldMaps: [
              {
                doCreate: true,
                doFind: true,
                fieldName: "FirstName",
                isExactMatch: true,
                label: "First Name",
              }
            ],
            entityName: "Contact",
            showOnCreate: true,
          },
        ],

        extraPrechatFormDetails: [
          {
            label: "First Name",
            value: `${username}`,
            displayToAgent: true,
          },
          {
            label: "Last Name",
            value: `${lastname}`,
            displayToAgent: true,
          },
          {
            label: "Email",
            value: `${email}`,
            displayToAgent: true,
          },
          {
            label: "Subject",
            value: `${subject}`,
            displayToAgent: true,
          }
        ],
      });
}

export const getStatusChat = () => {
  const existConversation = localStorage.getItem('inConversation')
  let returning = false
  if(existConversation){
    returning = true
  }
  return returning
} 

export const saveStatusChat = () => {
  localStorage.setItem('inConversation', true)
}

export const closeChat = () => {
  localStorage.removeItem('inConversation')
}

export const queuedMessages = (action = 'read') => {
  let res = false
  const messages = localStorage.getItem('queuedMessages')

  switch (action) {
    case 'save':
      localStorage.setItem('queuedMessages', true)
      break;
    case 'delete':
      localStorage.removeItem('queuedMessages')
      break;
    case 'read':
      if(messages){
        res = true
      }
      break;
  }

  return res

}

