export const MAP_LANDMARK_MANAGE_SHOW_MODAL = "MAP_LANDMARK_MANAGE_SHOW_MODAL";
export const MAP_LANDMARK_VISIBLE_SHOW_MODAL = "MAP_LANDMARK_VISIBLE_SHOW_MODAL";
export const MAP_LANDMARK_GROUP_DELETE_SHOW_MODAL = "MAP_LANDMARK_GROUP_DELETE_SHOW_MODAL";
export const MAP_LANDMARK_GROUP_DELETE = "MAP_LANDMARK_GROUP_DELETE";
export const MAP_LANDMARK_GROUP_DELETE_RESPONSE = "MAP_LANDMARK_GROUP_DELETE_RESPONSE";
export const MAP_LANDMARK_GROUP_EDIT_SHOW_MODAL = "MAP_LANDMARK_GROUP_EDIT_SHOW_MODAL";
export const MAP_LANDMARK_GROUP_EDIT = "MAP_LANDMARK_GROUP_EDIT";
export const MAP_LANDMARK_GROUP_EDIT_RESPONSE = "MAP_LANDMARK_GROUP_EDIT_RESPONSE";
export const MAP_LANDMARK_GROUP_NEW_SHOW_MODAL = "MAP_LANDMARK_GROUP_NEW_SHOW_MODAL";
export const MAP_LANDMARK_GROUP_NEW = "MAP_LANDMARK_GROUP_NEW";
export const MAP_LANDMARK_GROUP_NEW_RESPONSE = "MAP_LANDMARK_GROUP_NEW_RESPONSE";
export const MAP_LANDMARK_BULK_DELETE_SHOW_MODAL = "MAP_LANDMARK_BULK_DELETE_SHOW_MODAL";
export const MAP_LANDMARK_BULK_EDIT = "MAP_LANDMARK_BULK_EDIT";
export const MAP_LANDMARK_BULK_EDIT_RESPONSE = "MAP_LANDMARK_BULK_EDIT_RESPONSE";
export const MAP_LANDMARK_BULK_EDIT_SHOW_MODAL = "MAP_LANDMARK_BULK_EDIT_SHOW_MODAL";
export const MAP_LANDMARK_CREATE_SELECT_TYPE = "MAP_LANDMARK_CREATE_SELECT_TYPE";
export const MAP_LANDMARK_GROUP_HIDE = "MAP_LANDMARK_GROUP_HIDE";
export const MAP_LANDMARK_NEW = "MAP_LANDMARK_NEW";
export const MAP_LANDMARK_NEW_RESPONSE = "MAP_LANDMARK_NEW_RESPONSE";
export const MAP_LANDMARK_RESTART_PROCESS = "MAP_LANDMARK_RESTART_PROCESS";
export const MAP_LANDMARK_BULK_DELETE = "MAP_LANDMARK_BULK_DELETE";
export const MAP_LANDMARK_BULK_DELETE_RESPONSE = "MAP_LANDMARK_BULK_DELETE_RESPONSE";
export const MAP_LANDMARK_EDIT_SHOW_MODAL = "MAP_LANDMARK_EDIT_SHOW_MODAL";
export const MAP_LANDMARK_DELETE_SHOW_MODAL = "MAP_LANDMARK_DELETE_SHOW_MODAL";
export const MAP_LANDMARK_GET_LANDMARK_GROUPS = "MAP_LANDMARK_GET_LANDMARK_GROUPS";
export const MAP_LANDMARK_GET_LANDMARK_GROUPS_RESPONSE = "MAP_LANDMARK_GET_LANDMARK_GROUPS_RESPONSE";
export const MAP_LANDMARK_GET_LANDMARKS = "MAP_LANDMARK_GET_LANDMARKS";
export const MAP_LANDMARK_GET_LANDMARKS_RESPONSE = "MAP_LANDMARK_GET_LANDMARKS_RESPONSE";
export const MAP_LANDMARK_UPDATE_ADD_LANDMARK_GROUP = "MAP_LANDMARK_UPDATE_ADD_LANDMARK_GROUP";
