export const SETTINGS_LOCATIONS_SEARCH = "SETTINGS_LOCATIONS_SEARCH";
export const SETTINGS_LOCATIONS_SEARCH_RESPONSE = "SETTINGS_LOCATIONS_SEARCH_RESPONSE";

export const SETTINGS_LOCATIONS_CREATE_MODAL_SHOW_MODAL = "SETTINGS_LOCATIONS_CREATE_MODAL_SHOW_MODAL";
export const SETTINGS_LOCATIONS_CREATE_MODAL_HIDE_MODAL = "SETTINGS_LOCATIONS_CREATE_MODAL_HIDE_MODAL";

export const SETTINGS_LOCATIONS_CREATE_LOCATION = "SETTINGS_LOCATIONS_CREATE_LOCATION";
export const SETTINGS_LOCATIONS_CREATE_LOCATION_RESPONSE = "SETTINGS_LOCATIONS_CREATE_LOCATION_RESPONSE";

export const SETTINGS_LOCATIONS_EDIT_LOCATION = "SETTINGS_LOCATIONS_EDIT_LOCATION";
export const SETTINGS_LOCATIONS_EDIT_LOCATION_RESPONSE = "SETTINGS_LOCATIONS_EDIT_LOCATION_RESPONSE";

export const SETTINGS_LOCATIONS_DELETE_MODAL_SHOW_MODAL = "SETTINGS_LOCATIONS_DELETE_MODAL_SHOW_MODAL";
export const SETTINGS_LOCATIONS_DELETE_MODAL_HIDE_MODAL = "SETTINGS_LOCATIONS_DELETE_MODAL_HIDE_MODAL";

export const SETTINGS_LOCATIONS_DELETE_LOCATION = "SETTINGS_LOCATIONS_DELETE_LOCATION";
export const SETTINGS_LOCATIONS_DELETE_LOCATION_RESPONSE = "SETTINGS_LOCATIONS_DELETE_LOCATION_RESPONSE";
