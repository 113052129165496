import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { openHistoryOverViewIncident } from "Redux/actions";
import { reset } from "../../../redux/slice";
import { Tooltip } from "@gpstrackit/gtc-react-controls";

export const Title = ({
  clearFields
}) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const { isOpenHistoryOverViewIncident } = useSelector(
    (state) => state.incidentsRedux
  );

  let incidentName = isOpenHistoryOverViewIncident?.data?.incident?.name;
  if (incidentName?.length > 20) {
    incidentName = (
      <Tooltip title={incidentName}>
        {incidentName.slice(0, 15) + "..."}
      </Tooltip>
    );
  }
  let unitLabel = isOpenHistoryOverViewIncident?.data?.unit?.label;
  if (unitLabel?.length > 20) {
    unitLabel = (
      <Tooltip title={unitLabel}>{unitLabel.slice(0, 15) + "..."}</Tooltip>
    );
  }

  return (
    <div className="incident_modal-title">
      <p style={{display: "flex"}}>
        {messages['incident_history_title']} - {incidentName} - {unitLabel ? unitLabel : messages["incidents_noUnitLabel"]}
      </p>

      <button
        className="incident_modal-close"
        onClick={() => {
          clearFields()
          dispatch(
            openHistoryOverViewIncident({
              data: {},
              open: false,
            })
          );
          dispatch(
            reset({
              openIncidentPanel: {
                open: false,
                update: {},
              },
              lastReading: null,
              loadingLastReading: false,
              errorLastReading: null,
              createIncidentRes: null,
              loadingCreateIncident: false,
              errorCreateIncident: false,
              endIncident: null,
              loadingEndIncident: false,
              errorEndIncident: false,
              loadingItem: [],
              isOpenOverViewUnit: {
                open: false,
                data: {},
              },
              isOpenHistoryOverViewIncident: {
                open: false,
                data: {},
              },
              avMediaIncident: [],
              avMediaIncidentTotal: 0,
              loadingAvMediaIncident: false,
              errorAvMediaIncident: null,
              loadingTrailsIncident: false,
              errorTrailsIncident: null,
              trailsIncidentTotal: 0,
              trailsIncident: [],
            
              activeIncidentUnitLoading: false,
              activeIncidentUnitError: false,
              activeIncidentUnit: {},
            
              updateIncidentLoading: false,
              updateIncidentError: false,
              updateIncident: {},
            
              getUnitOverviewLoading: false,
              getUnitOverviewError: false,
              unitOverviewItems: [],
              unitOverviewItemsTotal: 0,
            
              sliceTrails: []
            })
          );
        }}
      >
        x
      </button>
    </div>
  );
};
