export default {
  "accountChangePassword_title": "Change Password",
  "accountChangePassword_currentPassword": "Current password",
  "accountChangePassword_newPassword": "New password",
  "accountChangePassword_confirmNewPassword": "Confirm new password",
  "accountChangePassword_passwordNotEqual": "Password does not match",
  "accountChangePassword_passwordValidation": "The password must contain at least 1 lowercase alphabetical character, 1 uppercase alphabetical character, 1 numeric character, 1 special character, spaces are not allowed and must be 6 characters or longer (20)",
  "accountChangePassword_passwordChanged": "Your password has been changed",
  "accountChangePassword_incorrectPassword": "Incorrect current password",
  "accountChangePassword_diferentErrorPassword": "The new password must be different from old password",
  "accountChangePassword_attemptLimitExceeded": "Attempt limit exceeded, please try again later",
  "accountChangePassword_minLengthMessage": "The password must contain minimum chars: ",
  "accountChangePassword_maxLengthMessage": "The password must contain maximum chars: "
};  