import moment from "moment"

export const formatOffsetDisplayOdometer = (unit) => {

	const result = unit.useEcuOdometer ? Math.round(getValueFloat(unit?.ecuEngineOdometer) + getValueFloat(unit?.offsetEcuEngineOdometer))
		: Math.round(getValueFloat(unit?.engineOdometer) + getValueFloat(unit.offsetEngineOdometer))

	if (result !== null && result !== undefined)
		if (result < 0)
			return 0
		else
			return result

	return ''
}

export const formatOffsetDisplayEngineHours = (unit) => {
	let result = unit.useEcuEngineHours ? (getValueFloat(unit?.ecuEngineHours) + getValueFloat(unit?.offsetEcuEngineHours))
		: (getValueFloat(unit?.engineHours) + getValueFloat(unit?.offsetEngineHours));

	if (result !== null && result !== undefined && !isNaN(result)) {
		if (result < 0) {
			return 0
		} else {
			return result?.toFixed(2);
		}
	}

	return ''
}

export const getValueFloat = (value) => {
	return parseFloat(value || 0);
}

export const getApiDate = (date) => {
	try {
		let newDate = date
		if (_.isString(newDate))
			if (newDate.includes(':') || newDate.includes('Z')) {
				newDate = moment.utc(date).unix() * 1000
			}
			else {
				newDate = parseInt(date)
			}

		return newDate
	}
	catch (exc) {
		console.log('Error', exc)
	}
}

//comes the unitTime in miliseconds
export const validateFutureDates = (unitTime) => {
	const now = Date.now();
	const diff = now - unitTime;

	// Convert in miliseconds 1 day
	const oneDayInMiliseconds = 2 * 24 * 60 * 60 * 1000;

	// validate diff with 1 day to future.
	if (diff > oneDayInMiliseconds) {
    	return false;
	} 
	return true;
}

export const convertEpochToUTC = (date) => {
	try {
		let unitTime = date

		if (unitTime < 1000000000000) {
			// If both timestamps are greater than 1000000000000, then assume they are in milliseconds
			unitTime = unitTime * 1000;
		} 

		// Convertir el timestamp a milisegundos y luego crear un objeto de fecha con moment
		const utcDate = moment.utc(unitTime);

		// Mostrar la fecha en formato UTC
		return utcDate.format();
		
	}
	catch (exc) {
		console.log('Error', exc)
		return date
	}
}