import React, { Fragment } from 'react';
import cx from 'classnames';

import CircularProgress from '@mui/material/CircularProgress';
import './loading.scss';

const Loading = (props) => {
	let show = props.show !== false;
	return (
		<Fragment>
			{
				show && !props.overlay && (
					<Fragment>
						<div
							style={{ ...props.style }}
							className={cx(
								'',
								props.full && 'loading-container',
								props.overlay && 'loading-container loading-overlay',
								props.className,
							)}
						>
							<CircularProgress className="loading-spinner" size={props.size || 40} />
						</div>

						{props.overlay && !props.full && props.children}
					</Fragment>
				)

			}
			{
				props.overlay &&
				<Fragment>
					{
						props.show &&
						<div
							style={{ ...props.style }}
							className={cx(
								'',
								props.overlay && 'loading-container loading-overlay',
								props.className,
							)}
						>
							<CircularProgress className="loading-spinner" size={props.size || 40} />
						</div>
					}
					{
						props.overlay && !props.full && props.children
					}
				</Fragment>
			}
		</Fragment>
	);
};

export default Loading;
