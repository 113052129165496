import { createSlice } from "@reduxjs/toolkit";

const INIT_STATE = {
    open: false,
    data: null
};

const eta = "realtimeMaps/main/eta";


export const RealtimeMapEtaLinkContactsRedux = createSlice({
    name: eta,
    initialState: INIT_STATE,
    reducers: {
        etaContactsReset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        etaContactsOpenModal: (state, action) => {
            return {
                ...state,
                open: action.payload.open,
                data: action.payload.data
            }
        }
    },
    extraReducers: (builder) => {}
})

const { actions, reducer } = RealtimeMapEtaLinkContactsRedux;

export const { etaContactsReset, etaContactsOpenModal } = actions;

export default reducer;