/* export const SETTINGS_DRIVER_GROUP_SEARCH = "SETTINGS_DRIVER_GROUP_SEARCH"; */
export const SETTINGS_DRIVER_GROUP_SEARCH_RESPONSE = "SETTINGS_DRIVER_GROUP_SEARCH_RESPONSE";
export const SETTINGS_DRIVER_GROUP_OPEN_CREATE_EDIT_MODAL = "SETTINGS_DRIVER_GROUP_OPEN_CREATE_EDIT_MODAL";


export const SETTINGS_DRIVER_GROUP_CREATE_EDIT = "SETTINGS_DRIVER_GROUP_CREATE_EDIT";
export const SETTINGS_DRIVER_GROUP_CREATE_EDIT_RESPONSE = "SETTINGS_DRIVER_GROUP_CREATE_EDIT_RESPONSE";

export const SETTINGS_DRIVER_GROUP_OPEN_DELETE_MODAL  = "SETTINGS_DRIVER_GROUP_OPEN_DELETE_MODAL";
export const SETTINGS_DRIVER_GROUP_DELETE             = "SETTINGS_DRIVER_GROUP_DELETE";
export const SETTINGS_DRIVER_GROUP_DELETE_RESPONSE    = "SETTINGS_DRIVER_GROUP_DELETE_RESPONSE";

export const SETTINGS_DRIVER_GROUP_GET = "SETTINGS_DRIVER_GROUP_GET";
export const SETTINGS_DRIVER_GROUP_GET_RESPONSE = "SETTINGS_DRIVER_GROUP_GET_RESPONSE";