import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';

// Actions
import {reportsMainGenerateReportProgress} from 'Redux/actions';

function* getServiceItemsDetailsReport(report, event) {
    const {filters, name,reportTimeRanges} = report;

    const {filterType, filterArray, page, rowsPerPage, sortField, sortOrder, startRange, endRange, otherFilters} = event.filters;
    try {
        let data = yield call(
            clientQuery,
            `query( $conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getServiceItemsDetailsReport(conditions: $conditions, limit: $limit, offset: $offset) {
          total
          limit
          offset
          category
          items {
            deviceId
            deviceLabel
            dateTime
            maintenance
            cost
            serviceNotes
          }
        }
      }`,
            {
                conditions: {
                    reportName: name,
                    category: {
                        field: filterType,
                        value: filterArray
                    },
                    dateRange: {
                        fields: [
                            "unitTime"
                        ],
                        GTE: startRange,
                        LTE: endRange,
                        reportTimeRanges
                    },
                    stringValues: {
                        values: filterArray
                    },
                    filterValues: {
                        entities: [{
                            id: otherFilters.event,
                            type: "Unit"
                        }]
                    },
                    sortField: {
                        field: sortField || "driverId",
                        order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                    }
                },
                limit: rowsPerPage,
                offset: page
            },
            endpoints.GRAPHQL_PX_REPORTING,
        );

        if (data?.Report) {
            report.summary = data.Report
            report.loadingSummary = false
            yield put(reportsMainGenerateReportProgress(report))
        } else {
            report.loadingSummary = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    } catch (exc) {
        report.loadingSubEvents = false
        ExceptionManager(exc, 'src/modules/reports/views/main/components/reportsTab/.components/reports/stopSummaryReport', 'getStopSummaryDetailsReport');
        yield put(reportsMainGenerateReportProgress(report))
    }
}

export default getServiceItemsDetailsReport;
