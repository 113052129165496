import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    vehicle: null,
    services: [],
    error: null,
};

const route = "modules/maintenance/modals/maintenanceServiceToCompleteList/redux/slice";

export const getMaintenanceServices = createAsyncThunk(
    "maintenance/servicesTab/maintenanceVVDSearchMaintenanceServiceUnits",
    async ({ serviceId, unitId }, { rejectWithValue, dispatch }) => {
      try {
        let query = `
              query ($serviceId: Int, $unitId: Int, $excludeCompleted: Boolean){
                  data: searchMaintenanceServiceUnits(serviceId: $serviceId, unitId: $unitId, excludeCompleted: $excludeCompleted) {
                      id
                      serviceId
                      service {
                          id
                          title
                          notes
                          miles
                          days
                          engineHours
                          estimatedCost
                          unitsCount
                          engineHoursBefore
                          engineHoursAfter
                          daysBefore 
                          daysAfter 
                          milesBefore
                          milesAfter
                      }
                      items
                      estimatedCost
                      unitId
                      lastMaintenanceDate
                      lastMaintenanceOdometer
                      lastMaintenanceEngineHours
                      nextServiceDate
                      nextServiceOdometer
                      nextServiceEngineHours
                      status
                      notes
                      completed
                      fileIds
                      subscribersCount
  
                  }
              }
          `;
        const response = await clientQuery(
          query,
          {
            serviceId: serviceId,
            unitId: unitId,
            excludeCompleted: true,
          },
          endpoints.GRAPHQL_GENERAL
        );
  
        return response?.data || [];
      } catch (exc) {
        ExceptionManager(
          exc,
          route,
          "maintenanceVVDSearchMaintenanceServiceUnits"
        );
        return rejectWithValue(exc);
      }
    }
);

export const maintenanceServicesToCompleteRedux = createSlice({
    name: "maintenance/maintenanceServiceToCompleteList",
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openCloseModalMaintenanceServicesToComplete(state, action) {
            return {
                ...state,
                open: action.payload.open,
                vehicle: action.payload.vehicle,
                services: [],
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMaintenanceServices.pending, (state, action) => {
            state.loading = true;
            state.error = null;
        });

        builder.addCase(getMaintenanceServices.fulfilled, (state, action) => {
            state.services = action.payload;
            state.loading = false;
            state.error = null;
        });

        builder.addCase(getMaintenanceServices.rejected, (state, action) => {
            state.loading = false;
            state.services = [];
            state.error = action.payload;
        });
    }
})

const { actions, reducer } = maintenanceServicesToCompleteRedux;

// Extract and export each action creator by name
export const {
    openCloseModalMaintenanceServicesToComplete,
    reset,
} = actions;

export default reducer;