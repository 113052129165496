// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    MAP_GEOFENCES_UNITS_SEARCH,
} from "Redux/actionTypes";

// Actions
import {
    searchUnitsGeofenceResponse,
} from "Redux/actions";

// Local GraphQL
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* searchUnitsGeofenceRequest({ payload }) {
    const { openSearch } = payload;
    try {
      const query =
        `query searchUnitGeofence ($openSearch: String) {
            searchUnitGeofence (openSearch: $openSearch) {
                unitId
                name
                countGeofence
                geofencesLimit
                pointsLimit
                polygon
                square
                circle
                unitTags {
                    id
                    label
                    color
                    path
                }
          }
      }`;
  
      let params = {
        openSearch
      };
  
      if(!openSearch){
        delete params["openSearch"]
      }
  
      const data = yield call(clientQuery, query, params, endpoints.GRAPHQL_GENERAL);
      const { searchUnitGeofence } = data;
      
      if (searchUnitGeofence) {
        yield put(searchUnitsGeofenceResponse(searchUnitGeofence));
      } else {
        const error = 'Error getting geofences groups'
        yield put(searchUnitsGeofenceResponse({ data: [], error}));
        throw error;
      }
  
    } catch (exc) {
        const error = 'Error getting geofences groups'
        yield put(searchUnitsGeofenceResponse({ data: [], error}));
        ExceptionManager(exc, 'src/modules/realtimeMaps/main/components/geofences/redux/saga.js', 'searchUnitsGeofenceRequest') 
    }
}

function* watchUnitsGeofenceMethods() {
    yield takeEvery(MAP_GEOFENCES_UNITS_SEARCH, searchUnitsGeofenceRequest);
}

export default function* rootSaga() {
    yield all([fork(watchUnitsGeofenceMethods)]);
}