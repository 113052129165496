import {
    MAP_SPEED_OVERRIDE_EDIT_CREATE_OPEN_MODAL,
    MAP_SPEED_OVERRIDE_GET_SPEED_OVERRIDE,
    MAP_SPEED_OVERRIDE_GET_SPEED_OVERRIDE_RESPONSE,
    MAP_SPEED_OVERRIDE_EDIT_CREATE_SPEED_OVERRIDE,
    MAP_SPEED_OVERRIDE_EDIT_CREATE_SPEED_OVERRIDE_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    open: false,
    id: null,
    speedOverride: null,
    loadingSpeedOverride: false,
    response: null,
    loadingResponse: false,
    showSpeedOverride: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MAP_SPEED_OVERRIDE_EDIT_CREATE_OPEN_MODAL:
            return {
                ...state,
                open: action.payload.open,
                id: action.payload.id,
                speedOverride: null,
                loadingSpeedOverride: false,
                response: null,
                loadingResponse: false,
                showSpeedOverride: action.payload.open
            };

        case MAP_SPEED_OVERRIDE_GET_SPEED_OVERRIDE:
            return {
                ...state,
                speedOverride: null,
                loadingSpeedOverride: true
            };

        case MAP_SPEED_OVERRIDE_GET_SPEED_OVERRIDE_RESPONSE:
            return {
                ...state,
                speedOverride: action.payload.response,
                loadingSpeedOverride: false
            };

        case MAP_SPEED_OVERRIDE_EDIT_CREATE_SPEED_OVERRIDE:
            return {
                ...state,
                response: null,
                loadingResponse: true
            };

        case MAP_SPEED_OVERRIDE_EDIT_CREATE_SPEED_OVERRIDE_RESPONSE:
            return {
                ...state,
                response: action.payload.response,
                loadingResponse: false,
                loadingSpeedOverride: false,
            };

        case CLEAR_REDUX:
            return ["", "MAP_SPEED_OVERRIDE_CREATE_EDIT"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
            return { ...state };
    }
}