//Module Keys
let locales = {};

//Merge Views
import mainView from 'Modules/dynamicForms/views/main/lan/en';
import formsView from 'Modules/dynamicForms/views/main/components/forms/lan/en';
import answersView from 'Modules/dynamicForms/views/main/components/answers/lan/en';
import designView from 'Modules/dynamicForms/modals/design/lan/en';
import deleteView from 'Modules/dynamicForms/modals/delete/lan/en';
import duplicateView from 'Modules/dynamicForms/modals/duplicate/lan/en';
import renameView from 'Modules/dynamicForms/modals/rename/lan/en';
import viewAnswersView from 'Modules/dynamicForms/modals/viewAnswers/lan/en';

locales = { ...locales, ...mainView };
locales = { ...locales, ...formsView };
locales = { ...locales, ...answersView };
locales = { ...locales, ...designView };
locales = { ...locales, ...deleteView };
locales = { ...locales, ...duplicateView };
locales = { ...locales, ...renameView };
locales = { ...locales, ...viewAnswersView };

export default locales;