import {
	//TYPES
	CATALOG_UPDATE_LAST_READING,
	CATALOG_UPDATE_LAST_READING_RESULT,

	FLEET_VEHICLE_GET_STATUSES,
	FLEET_VEHICLES_GET_STATUSES_RESULT,
	FLEET_MAIN_CHANGE_FILTERS
} from 'Redux/actionTypes';

export const fleetMainChangeFilters = (filters) => ({
	type: FLEET_MAIN_CHANGE_FILTERS,
	payload: filters,
});


export const updateLastReading = (data) => ({
	type: CATALOG_UPDATE_LAST_READING,
	payload: { data },
});

export const setUpdateLastReadingResult = (response) => ({
	type: CATALOG_UPDATE_LAST_READING_RESULT,
	payload: { response  },
});

export const fleetVehicleGetStatuses = () => ({
	type: FLEET_VEHICLE_GET_STATUSES
});

export const fleetVehicleGetStatusesResult = (data) => ({
	type: FLEET_VEHICLES_GET_STATUSES_RESULT,
	payload: { data },
});