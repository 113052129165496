import {
  REPORTS_MAIN_SCHEDULE_SHOW_REPORTSETUP,
  REPORTS_MAIN_SCHEDULE_HIDE_REPORTSETUP,
  REPORTS_MAIN_SAVE_SCHEDULE_REPORT,
  REPORTS_MAIN_SAVE_SCHEDULE_REPORT_RESULT,
  REPORTS_MAIN_GET_PRE_SCHEDULE_REPORT,
  REPORTS_MAIN_GET_SCHEDULE_REPORT,
  REPORTS_MAIN_GET_SCHEDULE_REPORT_RESULT,
  REPORTS_MAIN_UPDATE_SCHEDULE_REPORT,
  REPORTS_MAIN_UPDATE_SCHEDULE_REPORT_RESULT,
  REPORTS_MAIN_UPDATE_SUBSCRIBERS,
  REPORTS_MAIN_UPDATE_SUBSCRIBERS_RESULT,
  REPORTS_MAIN_FTP_LIST,
  REPORTS_MAIN_FTP_LIST_RESULT,
  REPORTS_MAIN_FTP_UPDATE,
  REPORTS_MAIN_FTP_UPDATE_RESULT,
  REPORTS_MAIN_FTP_DELETE,
  REPORTS_MAIN_FTP_DELETE_RESULT
} from "Redux/actionTypes";

export const reportsScheduleShowReportSetup = () => ({
  type: REPORTS_MAIN_SCHEDULE_SHOW_REPORTSETUP
});

export const reportsScheduleHideReportSetup = () => ({
  type: REPORTS_MAIN_SCHEDULE_HIDE_REPORTSETUP
});

export const getSchedule = (data) => ({
  type: REPORTS_MAIN_GET_SCHEDULE_REPORT,
  payload: { data }
});

export const getPreSchedule = (data) => ({
  type: REPORTS_MAIN_GET_PRE_SCHEDULE_REPORT,
  payload: { data }
});

export const getScheduleResult = (data) => ({
  type: REPORTS_MAIN_GET_SCHEDULE_REPORT_RESULT,
  payload: { data }
});

export const getListFtp = (data) => ({
  type: REPORTS_MAIN_FTP_LIST,
  payload: { data }
});

export const getListFtpResult = (data) => ({
  type: REPORTS_MAIN_FTP_LIST_RESULT,
  payload: { data }
});

export const updateFtp = (data) => ({
  type: REPORTS_MAIN_FTP_UPDATE,
  payload: { data }
});

export const updateFtpResult = (data) => ({
  type: REPORTS_MAIN_FTP_UPDATE_RESULT,
  payload: { data }
});

export const deleteFtp = (data) => ({
  type: REPORTS_MAIN_FTP_DELETE,
  payload: { data }
});

export const deleteFtpResult = (data) => ({
  type: REPORTS_MAIN_FTP_DELETE_RESULT,
  payload: { data }
});

export const saveSchedule = (data) => ({
  type: REPORTS_MAIN_SAVE_SCHEDULE_REPORT,
  payload: { data }
});

export const saveScheduleReportResult = (data) => ({
  type: REPORTS_MAIN_SAVE_SCHEDULE_REPORT_RESULT,
  payload: { data }
});


export const updateSchedule = (data) => ({
  type: REPORTS_MAIN_UPDATE_SCHEDULE_REPORT,
  payload: { data }
});

export const updateScheduleResult = (data) => ({
  type: REPORTS_MAIN_UPDATE_SCHEDULE_REPORT_RESULT,
  payload: { data }
});



export const updateSubscribers = (data) => ({
  type: REPORTS_MAIN_UPDATE_SUBSCRIBERS,
  payload: data
})

export const updateSubscribersResult = (data) => ({
  type: REPORTS_MAIN_UPDATE_SUBSCRIBERS_RESULT,
  payload: { data }
})
