import {
    MAP_SPEED_OVERRIDE_SEARCH_SPEED_OVERRIDE,
    MAP_SPEED_OVERRIDE_SEARCH_SPEED_OVERRIDE_CLEAR,
    MAP_SPEED_OVERRIDE_SEARCH_SPEED_OVERRIDE_RESPONSE
} from 'Redux/actionTypes';

export const mapSpeedOverrideSearch = (openSearch = '', limit = 0, offset = 0) => ({
    type: MAP_SPEED_OVERRIDE_SEARCH_SPEED_OVERRIDE,
    payload: { openSearch, limit, offset },
});

export const mapSpeedOverrideClearSearch = (openSearch = '', limit = 0, offset = 0) => ({
    type: MAP_SPEED_OVERRIDE_SEARCH_SPEED_OVERRIDE_CLEAR,
    payload: { openSearch, limit, offset },
});

export const mapSpeedOverrideSearchResponse = (response) => ({
    type: MAP_SPEED_OVERRIDE_SEARCH_SPEED_OVERRIDE_RESPONSE,
    payload: { response },
});

