export default {
    "dynamicFormsMain_forms": "Formularios",
    "dynamicFormsMain_answers": "Respuestas",
    "dynamicFormsMain_name": "Nombre",
    "dynamicFormsMain_status": "Estado",
    "dynamicFormsMain_updatedAt": "Actualizado El",
    "dynamicFormsMain_updatedBy": "Actualizado Por",
    "dynamicFormsMain_createdAt": "Creado El",
    "dynamicFormsMain_edit": "Editar",
    "dynamicFormsMain_rename": "Renombrar",
    "dynamicFormsMain_viewAnswers": "Ver respuestas",
    "dynamicFormsMain_duplicate": "Duplicar",
    "dynamicFormsMain_delete": "Eliminar",
    "dynamicFormsMain_advancedFilters": "Filtros Avanzados",
    "dynamicFormsMain_clearAll": "Limpiar",
    "dynamicFormsMain_timeframe": "Rango de fechas",
    "dynamicFormsMain_all": "Todo",
    "dynamicFormsMain_draft": "Borrador",
    "dynamicFormsMain_inactive": "Inactivo",
    "dynamicFormsMain_active": "Activo",
    
    "dynamicFormsMain_edit": "Editar",
    "dynamicFormsMain_rename": "Renombrar",
    "dynamicFormsMain_viewAnswers": "Ver Respuestas",
    "dynamicFormsMain_copy": "Copiar",
    "dynamicFormsMain_delete": "Eliminar",

    "dynamicFormsMain_tags": "Etiquetas"
}