import {
    ACCOUNT_TAGS_OPEN_CREATE_EDIT_MODAL,
    ACCOUNT_TAGS_SAVE_TAG,
    ACCOUNT_TAGS_SAVE_TAG_RESPONSE,
    ACCOUNT_TAGS_GET_TAG,
    ACCOUNT_TAGS_GET_TAG_RESPONSE,
    ACCOUNT_TAGS_GET_ENTITIES,
    ACCOUNT_TAGS_GET_ENTITIES_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    openCreateEdit: false,
    id: null,
    tag: null,
    loadingTag: false,
    loadingCreateEdit: false,
    response: null,
    units: [],
    drivers: [],
    landmarks: [],
    users: [],
    forms: [],
    dynamicForms: [],
    geofences: [],
    loadingEntities: false,
    disabledEntities: false
};

export default (state = INITIAL_STATE, action) => {
    const keys = {
        "Unit": "units",
        "Driver": "drivers",
        "Landmark": "landmarks",
        "User": "users",
        "Form": "forms",
        "Geofence": "geofences",
        "DynamicForm": "dynamicForms",
    };

    switch (action.type) {

        case ACCOUNT_TAGS_OPEN_CREATE_EDIT_MODAL:
            return {
                ...state,
                openCreateEdit: action.payload?.open,
                id: action.payload?.id,
                disabledEntities: action.payload?.disabledEntities
            };

        case ACCOUNT_TAGS_SAVE_TAG:
            return {
                ...state,
                loadingCreateEdit: true,
                response: null
            };

        case ACCOUNT_TAGS_SAVE_TAG_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                response: action.payload?.data,
            }

        case ACCOUNT_TAGS_GET_TAG:
            return {
                ...state,
                tag: null,
                loadingTag: true
            };

        case ACCOUNT_TAGS_GET_TAG_RESPONSE:
            return {
                ...state,
                //id: null,
                tag: action.payload?.data,
                loadingTag: false
            };

        case ACCOUNT_TAGS_GET_ENTITIES:
            return {
                ...state,
                loadingEntities: true
            };

        case ACCOUNT_TAGS_GET_ENTITIES_RESPONSE:
            return {
                ...state,
                [keys[action.payload?.response?.entityName]]: action.payload?.response?.items,
                loadingEntities: false
            };

        case CLEAR_REDUX:
            return ["", "ACCOUNT_TAGS_CREATE_EDIT"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return state;
    }
};