export const ALERTS_TAB_GET_ALERTS = "alerts/ALERTS_TAB_GET_ALERTS";
export const ALERTS_TAB_GET_ALERT = "alerts/ALERTS_TAB_GET_ALERT";
export const ALERTS_TAB_GET_ALERTS_RESULT = "alerts/ALERTS_TAB_GET_ALERTS_RESULT";
export const ALERTS_TAB_EDIT_SUBSCRIBERS = "alerts/ALERTS_TAB_EDIT_SUBSCRIBERS";
export const ALERTS_TAB_CLOSE_MODAL = "alerts/ALERTS_TAB_CLOSE_MODAL";
export const ALERTS_TAB_OPEN_FILTER_MODAL = "alerts/ALERTS_TAB_OPEN_FILTER_MODAL";
export const ALERTS_TAB_MANAGE_FILTER = "alerts/ALERTS_TAB_MANAGE_FILTER";
export const ALERTS_TAB_SAVE_ALERTS = "alerts/ALERTS_TAB_SAVE_ALERTS";
export const ALERTS_TAB_DELETE_ALERTS = "alerts/ALERTS_TAB_DELETE_ALERTS";
export const ALERTS_TAB_DELETE_ALERT_SUBSCRIBER = "alerts/ALERTS_TAB_DELETE_ALERT_SUBSCRIBER";
export const ALERTS_TAB_OPEN_DELETE_MODAL = "alerts/ALERTS_TAB_OPEN_DELETE_MODAL";

export const ALERTS_TAB_OPEN_ASSIGNED_MODAL = "alerts/ALERTS_TAB_OPEN_ASSIGNED_MODAL";
