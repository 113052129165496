export default {
    settingsFuelCard_fuelCard: "Tarjeta de combustible",
    settingsFuelCard_vehicle: "Vehiculo",
    settingsFuelCard_unitDriver: "Unidad / Conductor",
    settingsFuelCard_tankSize: "Tamaño del tanque (Valor en Galones)",
    settingsFuelCard_searchTankSize: "Buscar por Tarjeta de Combustible",
    settingsFuelCard_driver: "Conductor",
    settingsFuelCard_unit: "Unidad",
    settingsFuelCard_success: "Tarjeta de combustible Guardada",
    settingsFuelCard_unassignment: "Desasignado",
    settingsFuelCard_vehicleDescription: "Descripción Vehiculo",
    settingsFuelCard_editFuelCard: "Editar Tarjeta de Combustible",
    settingsFuelCard_bulkSettings: "Configuraciones masivas",
    settingsFuelCard_fuelCardSelected: "Unidades de tarjeta de combustible seleccionadas",
    settingsFuelCard_bulkFuelCardAssignment: "Asignación masiva Tamaño del Tanque",
    settingsFuelCard_bulkErrorDescription: "Seleccione solo fuel cards que tengan asignada una unidad."
};