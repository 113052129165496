import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
  VIDEO_ALERT_GET_EVENT_MEDIA
} from 'Redux/actionTypes';

import {
  getEventMediaResponse
} from 'Redux/actions';

function* getEventMediaRequest({ payload }) {
  try {
    const { data: body } = payload;
    const { readingId, deviceId } = body;

    let data = yield call(
      clientQuery,
      `query (
        $readingId: Float!,
        $deviceId: String!
      ) {
        EventMedia: getEventMedia(
          readingId: $readingId,
          deviceId: $deviceId
        ) {
          reading {
            id
            deviceData
          }
          deviceId
          esn
          deviceModelName
        }
      }`,
      {
        readingId,
        deviceId
      },
      endpoints.GRAPHQL_GENERAL,
      {
        'x-api-key': endpoints.PUBLIC_API_KEY
      }
    );

    if (data && data.EventMedia) {
      yield put(getEventMediaResponse(data.EventMedia));
    } else {
      throw 'Not event media data';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'Core/views/videoAlert/redux', 'getEventMediaRequest');
    yield put(getEventMediaResponse({ error: true }));
  }
}

function* watchGetEventMediaRequest() {
  yield takeEvery(VIDEO_ALERT_GET_EVENT_MEDIA, getEventMediaRequest);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetEventMediaRequest)
  ]);
}