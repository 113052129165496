export const REPORTS_MAIN_SHOW_REPORTSETUP = "REPORTS_MAIN_SHOW_REPORTSETUP";
export const REPORTS_MAIN_HIDE_REPORTSETUP = "REPORTS_MAIN_HIDE_REPORTSETUP";
export const REPORTS_MAIN_SET_FORM_DATA_REPORTSETUP = "REPORTS_MAIN_SET_FORM_DATA_REPORTSETUP";

export const REPORTS_MAIN_UPDATE_REPORT = "REPORTS_MAIN_UPDATE_REPORT";
export const REPORTS_MAIN_UPDATE_REPORT_RESULT = "REPORTS_MAIN_UPDATE_REPORT_RESULT";

export const REPORTS_MAIN_GENERATE_REPORT = "REPORTS_MAIN_GENERATE_REPORT";
export const REPORTS_MAIN_GENERATE_REPORT_RESULT = "REPORTS_MAIN_GENERATE_REPORT_RESULT";

export const REPORTS_MAIN_GENERATE_REPORT_UPDATE = "REPORTS_MAIN_GENERATE_REPORT_UPDATE";

export const REPORTS_MAIN_GENERATE_REPORT_PROGRESS = "REPORTS_MAIN_GENERATE_REPORT_PROGRESS";
export const REPORTS_MAIN_REMOVE_REPORT = "REPORTS_MAIN_REMOVE_REPORT";

export const REPORTS_MAIN_GENERATE_EVENTS = "REPORTS_MAIN_GENERATE_EVENTS";
export const REPORTS_MAIN_GENERATE_EVENTS_RESULT = "REPORTS_MAIN_GENERATE_EVENTS_RESULT";

export const REPORTS_MAIN_GENERATE_SUB_EVENTS = "REPORTS_MAIN_GENERATE_SUB_EVENTS";
export const REPORTS_MAIN_GENERATE_SUB_EVENTS_RESULT = "REPORTS_MAIN_GENERATE_SUB_EVENTS_RESULT";

export const REPORTS_VERIFY_STATE_REPORT = "REPORTS_VERIFY_STATE_REPORT";
export const REPORTS_VERIFY_STATE_REPORT_RESULT = "REPORTS_VERIFY_STATE_REPORT_RESULT";

export const GET_LANDMARK_BY_ID = "GET_LANDMARK_BY_ID";
export const GET_SERVICE_ITEMS_LEGACY = "GET_SERVICE_ITEMS_LEGACY";
export const GET_SERVICE_ITEMS_LEGACY_RESULT = "GET_SERVICE_ITEMS_LEGACY_RESULT";
export const GET_LANDMARK_BY_ID_RESULT = "GET_LANDMARK_BY_ID_RESULT";

export const REPORT_SHOW_MESSAGE = "REPORT_SHOW_MESSAGE";

export const REPORTS_GENERATE_REPORT_ASYNC = "REPORTS_GENERATE_REPORT_ASYNC";

export const REPORTS_SEARCH_LOG = "REPORTS_SEARCH_LOG";
export const REPORTS_GET_REPORTS_READY_RESULT = "REPORTS_GET_REPORTS_READY_RESULT";
export const REPORTS_GET_RECENTLY_GENERATED_RESULT = "REPORTS_GET_RECENTLY_GENERATED_RESULT";
export const REPORTS_GET_REPORTS_PINNED_RESULT = "REPORTS_GET_REPORTS_PINNED_RESULT";


export const REPORT_UPDATE_FIELD = "REPORT_UPDATE_FIELD";
export const REPORT_UPDATE_FIELD_RESULT = "REPORT_UPDATE_FIELD_RESULT";

export const REPORTS_GET_JSON_REPORT = "REPORTS_GET_JSON_REPORT";
export const REPORTS_GET_JSON_REPORT_RESULT = "REPORTS_GET_JSON_REPORT_RESULT";


export const REPORTS_MOVE_TO_TAB = "REPORTS_MOVE_TO_TAB";

// 
export const UPDATE_REPORT_GENERATED = "UPDATE_REPORT_GENERATED";
export const REPORTS_MAIN_DELETE_REPORT_LOG = "REPORTS_MAIN_DELETE_REPORT_LOG";
export const REPORTS_MAIN_DELETE_REPORT_LOG_RESULT = "REPORTS_MAIN_DELETE_REPORT_LOG_RESULT";

export const REPORT_UPDATE_FIELD_BATCH = "REPORT_UPDATE_FIELD_BATCH"
