import {
    //TYPES
    MAP_UTILITIES_UNIT_SELECTION_SET
  } from 'Redux/actionTypes';
  
  
  export const setListUnits = (units, show) => {
    return {
        type: MAP_UTILITIES_UNIT_SELECTION_SET,
        payload: {
          units, show
        } 
    };
  }