import {
    NEW_ENTITY_TAG_PICKER_GET_TAGS,
    NEW_ENTITY_TAG_PICKER_GET_TAGS_RESPONSE
} from 'Redux/actionTypes';

export const entityTagPickerGetTags = (entity) => ({
    type: NEW_ENTITY_TAG_PICKER_GET_TAGS,
    payload: { entity },
});

export const entityTagPickerGetTagsResponse = (tags, tree, error) => ({
    type: NEW_ENTITY_TAG_PICKER_GET_TAGS_RESPONSE,
    payload: { tags, tree, error},
});