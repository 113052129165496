// View Keys
export default {
  reportsMainST_scheduleReport: 'Schedule Report',
  reportsMainST_scheduleName: 'Schedule Name',
  reportsMainST_nameOfSchedule: 'Name of Schedule',
  reportsMainST_stepGeneral: 'General',
  reportsMainST_stepFrequency: 'Frequency',
  reportsMainST_stepUnits: 'Units',
  reportsMainST_stepSubscribers: 'Delivery',
  reportsMainST_scheduleCreatedSuccessfully: 'The Schedule has been created successfully',
  reportsMainST_scheduleUpdatedSuccessfully: 'The Schedule has been updated successfully',
  reportsMainST_scheduleFrequencyMessage: 'How often would you like to get the Report(s)?',
  reportsMainST_nameOfSchedulePlaceholder: 'My New Scheduled Report',
  reportsMainST_includeDays: 'Include these days',
  reportsMainST_selectAtLeastOneDay: 'Select at least one day',
  reportsMainST_scheduleList: 'Schedule List',
  reportsMainST_withoutSchedules: 'No previously scheduled reports. Click the add "+" icon to schedule a new report.',
  reportsMainST_deleteSchedule: 'Schedule deleted successfully',
  reportsMainST_deleteReport: 'Report deleted successfully',
  reportsMainST_deleteAllScheduleMessage: 'Are you sure you want to delete all schedules?',
  reportsMainST_deleteScheduleMessage: 'Are you sure you want to delete this schedule?',
  reportsMainST_deleteSchedulesMessage: 'Are you sure you want to delete those schedules?',
  reportsMainST_deleteExclusionsSchedulesMessage: 'Are you sure you want all but these schedules?',
  reportsMainST_deleteExclusionsScheduleMessage: 'Are you sure you want all schedules except this?',
  reportsMainST_deleteReportMessage: 'Are you sure you want to delete this report?',
  reportsMainST_deleteReportsMessage: 'Are you sure you want to clear the selected reports?',
  reportsMainST_deleteAllReportsMessage: 'Are you sure you want to delete all selected reports?',
  reportsMainST_title: 'Title',
  reportsMainST_frequency: 'Frequency',
  reportsMainST_subscribers: 'Subscribers',
  reportsMainST_deliveredAt: 'Deliver at',
  reportsMainST_deliverOn: 'Deliver on',
  reportsMainST_errorNotDay: 'Please select at least one day',
  reportsMainST_sendReportTo: 'Send report to',
  reportsMainST_scheduleExpires: 'Schedule Expires',
  reportsMainST_limitToThisTimeFrame: 'Limit to this timeframe',
  reportsMainST_type: 'Type',
  reportsMainST_format: 'Format',
  reportsMainST_scheduled: 'Scheduled',
  reportsMainST_days: 'Days',
  reportsMainST_time: 'Time',
  reportsMainST_nextReport: 'Next Report',
  reportsMainST_selectUnitsOrDrivers: 'Select Units or Drivers',
  reportsMainST_selectViewOptions: 'View Options',
  reportsMainST_selectViewBy: 'View By',
  reportsMainST_selectRunFor: 'Run For',
  reportsMainST_selectTimeframe: 'Timeframe',
  reportsMainST_selectCreated: 'Created',
  reportsMainST_selectStatus: 'Status',
  reportsMainST_selectReportFormat: 'Select Report Format',
  reportsMainST_deliveryOptions: 'Delivery Options',
  reportsMainST_reportDetails: 'Report Details',
  reportsMainST_report: 'Report',
  reportsMainST_reportName: 'Report Name',
  reportsMainST_reportType: 'Report Type',
  reportsMainST_daily: 'Daily',
  reportsMainST_weekly: 'Weekly',
  reportsMainST_monthly: 'Monthly',
  reportsMainST_dailyDescription: 'Send reports on selected day',
  reportsMainST_weeklyDescription: 'Send reports on selected day each week',
  reportsMainST_monthlyDescription: 'Send reports on selected day of the month',
  reportsMainST_selectADay: 'Select a Day',
  reportsMainST_excludeWeeKendDays: 'Exclude Weekend Days',
  reportsMainST_selectContacts: 'Select Contacts',
  reportsMainST_pdf: 'PDF',
  reportsMainST_csv: 'CSV',
  reportsMainST_web: 'Web',
  reportsMainST_ftp: 'FTP',
  reportsMainST_delivery: 'Delivery',
  reportsMainST_initialLetterMonday: 'M',
  reportsMainST_initialLetterTuesday: 'T',
  reportsMainST_initialLetterWednesday: 'W',
  reportsMainST_initialLetterThursday: 'Th',
  reportsMainST_initialLetterFriday: 'F',
  reportsMainST_initialLetterSaturday: 'S',
  reportsMainST_initialLetterSunday: 'Su',
  reportsMainST_the: 'the',
  reportsMainST_ordinals: 'th',
  reportsMainST_deliveryOnRequired: 'Delivery On is required',
  reportsMainST_deliveryAtRequired: 'Delivery At is required',
  reportsMainST_selectADeliveryMethod: 'Select delivery method.',
  reportsMainST_selectAnFTBServer: 'Select an FTP Server below or add a new one.',
  reportsMainST_pleaseSelectARecipientBelow: 'Please select a recipient below',



  time: 'Time',
  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  at: "at",
  entitySelect: "Select entity",
  optionSelect: "Select option"
};
