import {
    ACCOUNT_TAGS_OPEN_CREATE_EDIT_MODAL,
    ACCOUNT_TAGS_SAVE_TAG,
    ACCOUNT_TAGS_SAVE_TAG_RESPONSE,
    ACCOUNT_TAGS_GET_TAG,
    ACCOUNT_TAGS_GET_TAG_RESPONSE,
    ACCOUNT_TAGS_GET_ENTITIES,
    ACCOUNT_TAGS_GET_ENTITIES_RESPONSE
} from "Redux/actionTypes";

export const accountTagsOpenCreateEdit = (open, id, disabledEntities = false) => {
    return {
        type: ACCOUNT_TAGS_OPEN_CREATE_EDIT_MODAL,
        payload: { open, id, disabledEntities },
    };
};

export const accountTagsSaveTag = (tag) => {
    return {
        type: ACCOUNT_TAGS_SAVE_TAG,
        payload: { tag },
    };
};

export const accountTagsSaveTagResponse = (data) => {
    return {
        type: ACCOUNT_TAGS_SAVE_TAG_RESPONSE,
        payload: { data } ,
    };
};

export const accountTagsGetTag = (id) => {
    return {
        type: ACCOUNT_TAGS_GET_TAG,
        payload: { id },
    };
};

export const accountTagsGetTagResponse = (data) => {
    return {
        type: ACCOUNT_TAGS_GET_TAG_RESPONSE,
        payload: { data },
    };
};

export const accountTagsGetEntities = (data) => {
    return {
        type: ACCOUNT_TAGS_GET_ENTITIES,
        payload: { data },
    };
};

export const accountTagsGetEntitiesResponse = (response) => {
    return {
        type: ACCOUNT_TAGS_GET_ENTITIES_RESPONSE,
        payload: { response },
    };
};