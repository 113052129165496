import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip } from 'Components';
import { Icon, IconButton } from '@mui/material';

import './button.scss';

const renderButton = (props) => {
	switch (props.type) {
		default:
		case 'button':
			return (
				<button
					tabIndex={props.tabIndex}
					style={{ ...props.style }}
					className={cx('btn', props.disabled && 'btn-disabled', props.className)}
					disabled={props.disabled}
					onClick={(e) => {
						if (props.onClick) props.onClick(e);
					}}
				>
					{props.children}
				</button>
			);
		case 'link':
			return (
				<NavLink
					style={{ ...props.style }}
					className={cx('btn-link', props.className)}
					to={props.url}
					target={`${props.openNewTab ? '_blank' : ''}`}
				>
					{props.children}
				</NavLink>
			);
		case 'icon':
			return (
				<IconButton
					style={{ ...props.style }}
					className={cx('icon-button', props.disabled && 'icon-button-disabled', props.className)}
					{...props}
					onClick={(e) => {
						if (props.onClick) props.onClick(e);
					}}
					size={props.size || "small"}
				>
					<Icon
						style={props.style || {}}
						fontSize={props.fontSize || "small"}
					>
						{props.icon}
					</Icon>
				</IconButton>
			);
		case 'span':
			return (
				<span
					{...props}
					className={cx('span-button', props.disabled && 'span-button-disabled', props.className)}
					onClick={(e) => {
						if (props.onClick && !props.disabled) props.onClick(e);
					}}
				>
					{props.children} {props.icon ? <Icon style={{fontSize: 14}}>{props.icon}</Icon> : ""}
				</span>
			);
	}
};

const Button = (props) => {
	return (
		<Fragment>
			{props.tooltip ? (
				<Tooltip title={props.tooltip} placement={props.placement || 'bottom'}>
					<span>{renderButton(props)}</span>
				</Tooltip>
			) : (
				renderButton(props)
			)}
		</Fragment>
	);
};

export default Button;

Button.propTypes = {
	type: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['button', 'link', 'icon', 'span']),
};
