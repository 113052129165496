export default {
  accountContacts_contacts: "Contacts",
  accountContacts_title: "Title",
  accountContacts_details: "Details",
  accountContacts_type: "Type",
  accountContacts_carrier: "Carrier",
  accountContacts_address: "Address",
  accountContacts_cellphone: "Cell phone",
  accountContacts_email: "Email",
  accountContacts_newContact: "New Contact",
  accountContacts_editContact: "Edit Contact",
  accountContacts_deleteContact: "Delete Contact",
  accountContacts_cancel: "Cancel",
  accountContacts_add: "Save",
  accountContacts_edit: "Save",
  accountContacts_delete: "Delete",
  accountContacts_messageDelete: "Are you sure you want to delete the contact",
  accountContacts_maxLengthMessageCellPhone: "The cell phone must contain a maximum of digits:",
  accountContacts_minLengthMessageCellPhone: "The cell phone must contain a minimum of digits:",
  accountContacts_maxLengthMessagetitle: "The title must contain a maximum of characters:",
  accountContacts_emailMessage: "The email is incorrect",
  accountContacts_createContact: "Contact Created",
  accountContacts_updateContact: "Contact Updated",
  accountContacts_deleteContact: "Contact Deleted",
  accountContacts_select: "Select",
};