export default {
  map_geofence_geofenceCreateEdit_createTitle: "Crear Nueva Geocerca",
  map_geofence_geofenceCreateEdit_editTitle: "Editar Geocerca: ",
  map_geofence_geofenceCreateEdit_typeGeofence: "Tipo de Geocerca",
  map_geofence_geofenceCreateEdit_polygon: 'Polígono',
  map_geofence_geofenceCreateEdit_max: 'Polígono +',
  map_geofence_geofenceCreateEdit_circle: 'Círculo',
  map_geofence_geofenceCreateEdit_square: 'Cuadrado',
  map_geofence_geofenceCreateEdit_inout: 'Dentro/Fuera',
  map_geofence_geofenceCreateEdit_in: 'Dentro',
  map_geofence_geofenceCreateEdit_out: 'Fuera',
  map_geofence_geofenceCreateEdit_alerton: 'Alerta de Ingreso',
  map_geofence_geofenceCreateEdit_info_polygon: 'Comience a hacer clic en los puntos del mapa para crear una Geocerca. Deben ser 3-{maxPoints} puntos.',
  map_geofence_geofenceCreateEdit_info_circle: 'La Geocerca se centrará en la dirección proporcionada con el radio especificado. Utilice el área de localización de la dirección anterior para cambiar la ubicación.',
  map_geofence_geofenceCreateEdit_group: 'Grupo de Geocercas',
  map_geofence_geofenceCreateEdit_tag: 'Etiqueta',
  map_geofence_geofenceCreateEdit_tag_select: "Seleccionar Etiquetas",
  map_geofence_geofenceCreateEdit_contacts: 'Suscriptores',
  map_geofence_geofenceCreateEdit_name: "Nombre",
  map_geofence_geofenceCreateEdit_address: "Dirección",
  map_geofence_geofenceCreateEdit_latLng: "Lat/Long",
  map_geofence_geofenceCreateEdit_radius: "Radio",
  map_geofence_geofenceCreateEdit_diagonal: "Diagonal",
  map_geofence_geofenceCreateEdit_circleRequired: 'Por favor selecciona un punto en el mapa para crear el Geofence',
  map_geofence_geofenceCreateEdit_alerton: 'Alerta de Ingreso',
  map_geofence_geofenceCreateEdit_create: 'Geocerca Creada',
  map_geofence_geofenceCreateEdit_success: "Geocercas almacenadas correctamente",
  map_geofence_geofenceCreateEdit_insideDuration:"Duración interior",
  map_geofence_geofenceCreateEdit_isSquare:"Diseño libre (Dibujar un Rectangulo)",
  map_geofence_geofenceCreateEdit_timeInside:"Tiempo dentro",
  map_geofence_geofenceCreateEdit_enabled:"Activo",
  map_geofence_geofenceCreateEdit_disabled:"Inactivo",
  map_geofence_error_time_duration:"El tiempo dentro debe ser inferior a 120 horas",
  map_geofence_error_time_duration_min:"El tiempo dentro debe ser mayor a 1 minuto",
  map_geofence_error_time_duration_hours:"horas",
  map_geofence_error_time_duration_minutes:"minutos",
  map_geofence_geofenceCreateEdit_tags_shape: "Forma de la Geocerca",
  map_geofence_geofenceCreateEdit_tags_label: "Etiqueta de la Geocerca",
  map_geofence_geofenceCreateEdit_alert: "Enviar Alerta",
  map_geofence_geofenceCreateEdit_error_message: "El nombre de la Geofence ya se encuentra asignado",
    map_geofence_geofenceCreateEdit_error_define_geofence: "Utilice una Dirección, un Clic en el mapa o una Unidad para crear una Geocerca en el mapa"
};
