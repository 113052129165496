import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { clientQuery, clientMutation } from '../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../../../core/logManager';

import { resetALL } from '../../../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    response: null,
    driverStatus: null,
    error: false
};

const route = 'modules/settings/views/driverStatus/components/createEdit/redux/slice';

export const createEditDriverStatus = createAsyncThunk('driverStatus/components/createEdit/createEditDriverStatus', async ({ id, name,accumulateTime }, { rejectWithValue, dispatch }) => {
    try {
        let query = `
            mutation createDriverStatus ($name: String!,$accumulateTime: Boolean) {
                driverStatus: createDriverStatus (name: $name,accumulateTime:$accumulateTime) {
                    id
                    accumulateTime
                }
            }
        `;

        if (id) {
            query = `
                mutation updateDriverStatus ($id: Int!, $name: String!,$accumulateTime: Boolean!) {
                    driverStatus: updateDriverStatus (id: $id, name: $name,accumulateTime:$accumulateTime) {
                        id
                        accumulateTime
                    }
                }
            `;
        }


        const response = await clientMutation(
            query,
            {
                id,
                name,
                accumulateTime
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response?.driverStatus?.id) {
            throw `Error while creating/updating driverStatus ${id}`;
        }

        return response?.driverStatus;
    } catch (exc) {
        ExceptionManager(exc, route, 'createEditDriverStatus');
        return rejectWithValue(exc)
    }
});

//Slice
const driverStatusCreateEditRedux = createSlice({
    name: 'driverStatus/createEdit',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openCreateEditModal(state, action) {
            state.open = true;
            state.driverStatus = action?.payload;
        },
        closeCreateEditModal(state) {
            state.open = false;
            state.driverStatus = null;
            state.response = null;
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)
        //deleteDynamicForms
        builder.addCase(createEditDriverStatus.pending, (state, action) => {
            state.loading = true;
            state.error = false;
            state.response = null;
        });
        builder.addCase(createEditDriverStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.error = false;
            state.response = action.payload;
        });
        builder.addCase(createEditDriverStatus.rejected, (state, action) => {
            state.loading = false;
            state.response = action.payload;
            state.error = true;
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = driverStatusCreateEditRedux;

// Extract and export each action creator by name
export const {
    reset,
    openCreateEditModal,
    closeCreateEditModal,
} = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;