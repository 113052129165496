import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';
// Actions
import {
  reportsMainGenerateReportProgress,
  reportsMainGenerateReportUpdate
} from 'Redux/actions';

import {
  getTimeZone,
  getCategoryFilter,
  handleAsyncReport,
  baseReportConditions,
  generateReportInstance,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';
function* getUtilizationReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
  const { timeZone , sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath } = report;
  const {
    filterType,
    filterArray,
    startRange,
    endRange,
    unitsSelected,
    filterEntity,
    accumulateData = true,
    dates
  } = report.filters
  const baseConditionsQuery = baseReportConditions({
    report,
    name,
    dataResultPath,
    timeZone,
    delayed,
    unitsSelected,
    timeframeName,
    filterType,
    filterArray,
    categoryName,
    startRange,
    endRange,
    dates
  })
  try {
    let payload = {
      conditions: {
        ...baseConditionsQuery,
        sortField: {
          field: sortField || "deviceId",
          order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
      },
      limit: rowsPerPage,
      offset: page
    };

    if (report.export) {
      payload = {
        ...payload,
        limit: isJsonFormat(report?.exportType, dataResultPath)? rowsPerPage : 5000,
        offset: isJsonFormat(report?.exportType, dataResultPath)? page : 1,
        conditions: {
          ...payload.conditions,
          exportOptions: {
            sendByEmail,
            emailsTo,
            format: {
              fileFormat: report?.exportType || "json",
              formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
              durationFormat: report?.durationFormat
            },
            culture: report.lang,
            timeZone: getTimeZone(timeZone),
            folderName: "px-customer-reports",
            filePrefixName: "utilizationReport",
            columns: [
              {
                "column": filterEntity === 'Unit' ? "deviceLabel" : "driverName",
                "header": filterEntity === 'Unit' ?  "Unit" : "Driver"
              },
              {
                "column": filterEntity !== 'Unit' ? "deviceLabel" : "driverName",
                "header": filterEntity !== 'Unit' ?  "Unit" : "Driver"
              },
              {
                "column": "distance",
                "header": "Distance"
              },
              {
                "column": "duration",
                "header": "Duration"
              },
              {
                "column": "idleTime",
                "header": "Idle Time"
              },
              {
                "column": "stoppedTime",
                "header": "Stop Time"
              },
              {
                "column": "moving",
                "header": "Moving Percentage"
              },
              {
                "column": "daysWorkedTotalPerc",
                "header": "Days (worked, total, percent)"
              }
            ]
          }
        }
      }
    }
    let data = yield call(
      clientQuery,
      `query getUtilizationReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getUtilizationReport (conditions: $conditions, limit: $limit, offset: $offset) {
          ${ generateReportInstance(report.export, dataResultPath, report.exportFromTable)?
            `reportInstanceId`
          :`
            items {
              recordInfo {
                entityId
                entityLabel
                tagId
                startDate
                endDate
                recordType
                groupLabel
                groupIndex
                groupTotal
                groupTagColor
              }
              recordDetail {
                id
                label
                groupId
                groupName
                driverName
                deviceLabel
                groupColor
                distance
                durationTime
                runTime
                idleTime
                stoppedTime
                moving
                daysWorked
                daysTotal
                daysPercent
                isKPH
                noData
              }
            }
            total
            limit
            offset
            category
        `}
        }
        
      }`,
      payload ,
      endpoints.GRAPHQL_PX_REPORTING,
    );
    yield handleAsyncReport(report, data, accumulateData);

  } catch (exc) {
    ExceptionManager(exc, 'reports/detailedReport', 'getUtilizationReport');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
    if (!requestFlag) {
      let flag = true
      yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
    } else {
      report.loading = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  }
}


export default getUtilizationReport;
