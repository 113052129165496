export default {
    completeMaintenanceService_complete: "Complete",
    completeMaintenanceService_max_complete: "COMPLETE",
    completeMaintenanceService_date: "DATE",
    completeMaintenanceService_select_date: "Select Date",
    completeMaintenanceService_invalid_time: "Invalid Time",
    completeMaintenanceService_wait: "Wait",
    completeMaintenanceService_max_odometer: "ODOMETER ",
    completeMaintenanceService_odometer: "Odometer",
    completeMaintenanceService_max_engine_hours: "ENGINE HOURS",
    completeMaintenanceService_engine_hours: "Engine Hours",
    completeMaintenanceService_add_service_items: "ADD SERVICE ITEMS",
    completeMaintenanceService_add_new_service_item: "Add New Service Item",
    completeMaintenanceService_error_items: "Parts and Labor shouldn’t be $0.00 at the same time for a service item",
    completeMaintenanceService_total_cost: "TOTAL COST",
    completeMaintenanceService_re_schedule_next_maintenance: "Re-Schedule Next Maintenance",
    completeMaintenanceService_next_due_date: "NEXT DUE DATE",
    completeMaintenanceService_next_odometer: "NEXT ODOMETER",
    completeMaintenanceService_error_next_odometer: "NEXT ODOMETER should be equal or greater than",
    completeMaintenanceService_next_engine_hours: "NEXT ENGINE HOURS",
    completeMaintenanceService_error_next_engine_hours: "NEXT ENGINE HOURS should be equal or greater than",
    completeMaintenanceService_attachment: "ATTACHMENT",
    completeMaintenanceService_select_files: "Select File(s)",
    completeMaintenanceService_max_notes: "NOTES",
    completeMaintenanceService_notes: "Notes",

    completeMaintenanceService_service_completed: "Service Completed",
    completeMaintenanceService_service_item_success: "Service item created successfully",
    completeMaintenanceService_item: "ITEM",
    completeMaintenanceService_parts: "PARTS",
    completeMaintenanceService_highest_possible_value: "Highest possible value",
    completeMaintenanceService_labor: "LABOR",
    completeMaintenanceService_subtotal: "SUBTOTAL",
    completeMaintenanceService_for: "for",
}