// Action Types
export const VIDEO_DETAIL_GET_AVAILABLE_MEDIA = "VIDEO_DETAIL_GET_AVAILABLE_MEDIA";
export const VIDEO_DETAIL_GET_CLIP_REQUEST = "VIDEO_DETAIL_GET_CLIP_REQUEST";
export const VIDEO_DETAIL_GET_MEDIA_DETAIL_RESPONSE = "VIDEO_DETAIL_GET_MEDIA_DETAIL_RESPONSE";

export const VIDEO_DETAIL_UPDATE_CLIP_REQUEST = "VIDEO_DETAIL_UPDATE_CLIP_REQUEST";
export const VIDEO_DETAIL_UPDATE_AVAILABLE_MEDIA = "VIDEO_DETAIL_UPDATE_AVAILABLE_MEDIA";
export const VIDEO_DETAIL_UPDATE_CLIP_REQUEST_RESPONSE = "VIDEO_DETAIL_UPDATE_CLIP_REQUEST_RESPONSE";
export const VIDEO_DETAIL_UPDATE_AVAILABLE_MEDIA_RESPONSE = "VIDEO_DETAIL_UPDATE_AVAILABLE_MEDIA_RESPONSE";

export const VIDEO_DETAIL_DELETE_CLIPS_REQUEST = "VIDEO_DETAIL_DELETE_CLIPS_REQUEST";
export const VIDEO_DETAIL_DELETE_AVAILABLE_MEDIA = "VIDEO_DETAIL_DELETE_AVAILABLE_MEDIA";
export const VIDEO_DETAIL_DELETE_CLIPS_REQUEST_RESPONSE = "VIDEO_DETAIL_DELETE_CLIPS_REQUEST_RESPONSE";
export const VIDEO_DETAIL_DELETE_AVAILABLE_MEDIA_RESPONSE = "VIDEO_DETAIL_DELETE_AVAILABLE_MEDIA_RESPONSE";

export const VIDEO_DETAIL_OPEN_DELETE_MODAL = "VIDEO_DETAIL_OPEN_DELETE_MODAL";

export const VIDEO_DETAIL_ADD_NOTE = "VIDEO_DETAIL_ADD_NOTE";
export const VIDEO_DETAIL_ADD_NOTE_RESPONSE = "VIDEO_DETAIL_ADD_NOTE_RESPONSE";

export const VIDEO_DETAIL_DELETE_NOTES = "VIDEO_DETAIL_DELETE_NOTES";
export const VIDEO_DETAIL_DELETE_NOTES_RESPONSE = "VIDEO_DETAIL_DELETE_NOTES_RESPONSE";