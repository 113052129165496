// Action types.
import { 
  CONTACTS_SEARCH, 
  CONTACTS_SEARCH_RESPONSE, 
  CONTACTS_CREATE,
  CONTACTS_CREATE_RESPONSE,
  CONTACTS_UPDATE,
  CONTACTS_UPDATE_RESPONSE,
  CONTACTS_DELETE,
  CONTACTS_DELETE_RESPONSE,
  CONTACTS_SEARCH_CELL_CARRIERS,
  CONTACTS_SEARCH_CELL_CARRIERS_RESPONSE
} from "Redux/actionTypes";

export const searchContacts = (data) => {
  return {
    type: CONTACTS_SEARCH,
    payload: data,
  };
};

export const searchContactsResponse = (data) => {
  return {
    type: CONTACTS_SEARCH_RESPONSE,
    payload: data,
  };
};

export const createContact = (data) => {
  return {
    type: CONTACTS_CREATE,
    payload: data,
  };
};

export const createContactResponse = (data) => {
  return {
    type: CONTACTS_CREATE_RESPONSE,
    payload: data,
  };
};

export const updateContact = (data) => {
  return {
    type: CONTACTS_UPDATE,
    payload: data,
  };
};

export const updateContactResponse = (data) => {
  return {
    type: CONTACTS_UPDATE_RESPONSE,
    payload: data,
  };
};

export const deleteContact = (data) => {
  return {
    type: CONTACTS_DELETE,
    payload: data,
  };
};

export const deleteContactResponse = (data) => {
  return {
    type: CONTACTS_DELETE_RESPONSE,
    payload: data,
  };
};

export const searchCellCarriers = (data) => {
  return {
    type: CONTACTS_SEARCH_CELL_CARRIERS,
    payload: data,
  };
};

export const searchCellCarriersResponse = (data) => {
  return {
    type: CONTACTS_SEARCH_CELL_CARRIERS_RESPONSE,
    payload: data,
  };
};