// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    MAP_GEOFENCES_CONTACTS_GET_GEOFENCE,
    MAP_GEOFENCES_CONTACTS_SAVE
} from "Redux/actionTypes";

// Actions
import {
    mapGeofencesContactsGetGeofenceResponse,
    mapGeofencesContactsSaveResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* mapGeofencesContactsGetGeofenceRequest({ payload }) {
    try {
        const { id } = payload;

        const response = yield call(
            clientQuery,
            `
                query getGeofence ($id: Int!) {
                    geofence: getGeofence (id: $id) {
                        id
                        name
                        tags {
                            id
                            label
                            color
                            path
                        }
                        shape
                        radius
                        alertOnType
                        isAlert
                        contacts {
                            id
                            title
                            address
                            type
                        }
                        color
                    }
                }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );

        if (!response && !response.geofence) throw `Error while getting geofence: ${id}`;

        yield put(mapGeofencesContactsGetGeofenceResponse({ ...response.geofence, error: false }));
    } catch (exc) {
        yield put(mapGeofencesContactsGetGeofenceResponse({ error: true }));
        ExceptionManager(
            exc,
            "modules/realtimeMaps/main/components/geofences/components/geofenceContacts/redux/saga",
            "mapGeofencesContactsGetGeofenceRequest"
        );
    }
}

function* mapGeofencesContactsSaveRequest({ payload }) {
    try {
        const { geofence } = payload;
        let query = `
                mutation updateGeofence (
                    $id: Int!, 
                    $name: String, 
                    $tagsIds: [Int], 
                    $alertOnType: String, 
                    $contacts: [Int], 
                    $color: String
                ) {
                    geofence: updateGeofence (
                        id: $id, 
                        name: $name, 
                        tagsIds: $tagsIds,  
                        alertOnType: $alertOnType, 
                        contacts: $contacts, 
                        color: $color
                    ) {
                        id
                        name
                        contacts {
                            id
                            title
                            address
                            type
                        }
                    }
                } 
            `;
        const response = yield call(
            clientMutation,
            query,
            geofence,
            endpoints.GRAPHQL_GENERAL,
        );

        if (!response || !response.geofence) throw `Error while removing geofence contacts: ${geofence}`;
        yield put(mapGeofencesContactsSaveResponse({ ...response.geofence, error: false }));
    } catch (exc) {
        let message = null
        if (exc.errorData && exc.errorData.errors) {
            exc.errorData.errors.forEach(error => {
                if (error.errorInfo && error.errorInfo.code) {
                    message = error.message
                }
            });
        }

        yield put(mapGeofencesContactsSaveResponse({ error: true, message: message }));
    }
}

function* watchMethods() {
    yield takeEvery(MAP_GEOFENCES_CONTACTS_GET_GEOFENCE, mapGeofencesContactsGetGeofenceRequest);
    yield takeEvery(MAP_GEOFENCES_CONTACTS_SAVE, mapGeofencesContactsSaveRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}