import {
    SETTINGS_TEMPERATURE_SEARCH,
    SETTINGS_TEMPERATURE_SEARCH_RESPONSE,
    SETTINGS_TEMPERATURE_OPEN_CREATE_EDIT_MODAL,
    SETTINGS_TEMPERATURE_CREATE_EDIT_RESPONSE,
    SETTINGS_TEMPERATURE_CREATE_EDIT,
    SETTINGS_TEMPERATURE_OPEN_DELETE_MODAL,
    SETTINGS_TEMPERATURE_DELETE,
    SETTINGS_TEMPERATURE_DELETE_RESPONSE,
    SETTINGS_TEMPERATURE_GET_UNIT,
    SETTINGS_TEMPERATURE_GET_UNIT_RESPONSE,
    SETTINGS_TEMPERATURE_GET_CONTACT,
    SETTINGS_TEMPERATURE_GET_CONTACT_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loadingTemperature: false,
    loadingCreateEdit: false,
    loadingDelete: false,
    units: [],
    contacts: [],
    temperature: {},
    temperatures: [],
    errorTemperature: false,
    openCreateEdit: false,
    openDelete: false,
    createEditResponse: null,
    deleteResponse: null,
    filters: {
        openSearch: ""
    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SETTINGS_TEMPERATURE_SEARCH:
            return {
                ...state,
                temperatures: [],
                loadingTemperature: true,
                errorTemperature: false,
                filters: action.payload
            };

        case SETTINGS_TEMPERATURE_SEARCH_RESPONSE:
            return {
                ...state,
                temperatures: action.payload?.temperature,
                errorTemperature: action.payload?.error,
                loadingTemperature: false
            };

        case SETTINGS_TEMPERATURE_OPEN_CREATE_EDIT_MODAL:
            return {
                ...state,
                openCreateEdit: action.payload?.open,
                temperature: action.payload?.temperature
            };

        case SETTINGS_TEMPERATURE_CREATE_EDIT:
            return {
                ...state,
                loadingCreateEdit: true,
                createEditResponse: null
            };

        case SETTINGS_TEMPERATURE_CREATE_EDIT_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                createEditResponse: action.payload?.data,
            };



        case SETTINGS_TEMPERATURE_OPEN_DELETE_MODAL:
            return {
                ...state,
                openDelete: action.payload?.open,
                selectedItems: action.payload?.items,
                loadingDelete: false,
                deleteResponse: null
            };

        case SETTINGS_TEMPERATURE_DELETE:
            return {
                ...state,
                loadingDelete: true,
                deleteResponse: null
            };

        case SETTINGS_TEMPERATURE_DELETE_RESPONSE:
            return {
                ...state,
                loadingDelete: false,
                deleteResponse: action.payload?.data,
            };

        case SETTINGS_TEMPERATURE_GET_UNIT:
            return {
                ...state,
                units: [],
                loadingCreateEdit: true
            };

        case SETTINGS_TEMPERATURE_GET_UNIT_RESPONSE:
            return {
                ...state,
                units: action.payload?.units,
                loadingCreateEdit: false
            };

        case SETTINGS_TEMPERATURE_GET_CONTACT:
            return {
                ...state,
                contacts: [],
                loadingCreateEdit: true
            };

        case SETTINGS_TEMPERATURE_GET_CONTACT_RESPONSE:
            return {
                ...state,
                contacts: action.payload?.contacts,
                loadingCreateEdit: false
            };

        case CLEAR_REDUX:
            return ["", "SETTINGS_TEMPERATURE"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return { ...state };
    }
};