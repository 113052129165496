//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientMutation, clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
	FLEET_MODAL_BULK_OUTPUTS_SEND_COMMAND,
	FLEET_MODAL_BULK_OUTPUTS_GET_OUTPUTS
} from 'Redux/actionTypes';

import {
	fleetModalBulkOutputsSendCommandResult,
	fleetModalBulkOutputsGetOutputsResult
} from 'Redux/actions';

export function* requestBulkOutputsSendCommands() {
	yield takeEvery(FLEET_MODAL_BULK_OUTPUTS_SEND_COMMAND, function* ({ payload }) {
		const { data } = payload;
		try {
			const response = yield call(
				clientMutation,
				` 
					mutation bulkMessageOutbox(
						$via: enumVia!
						$message: MessageOutboxMessage
						$description: String
						$system: Int
						$to: [MessageToInput]!
						$from: MessageFromInput
					) {
						command: bulkMessageOutbox(
							via: $via
							message: $message
							description: $description
							system: $system
							to: $to
							from: $from
						) {
							id
							to
							via
							type
							message
							description
							status
						}
					}
				`,
				data,
				endpoints.GRAPHQL_GENERAL,
			);
			
			yield put(fleetModalBulkOutputsSendCommandResult(response));
		} catch (exc) {
			ExceptionManager(
				exc,
				'modules/fleet/components/ModalBulkOutputs/redux/saga',
				'requestBulkOutputsSendCommands'
			);
			yield put(fleetModalBulkOutputsSendCommandResult({ error: true }));
		}
	});
}

export function* requestBulkGetOutputs() {
	yield takeEvery(FLEET_MODAL_BULK_OUTPUTS_GET_OUTPUTS, function* ({ payload }) {
		const { data } = payload;
		try {
			const response = yield call(
				clientMutation,
				`query {
					${data.map((item) => {
						return `
							output_${item?.id}: getUnit (id: ${item.id}) {
								id
								outputs {
									unitId
									position
									name
									type
									state
								}
							}
						`})
					}
				}`,
				{},
				endpoints.GRAPHQL_GENERAL,
			);

			yield put(fleetModalBulkOutputsGetOutputsResult(response));
		} catch (exc) {
			ExceptionManager(
				exc,
				'modules/fleet/components/ModalBulkOutputs/redux/saga',
				'requestBulkGetOutputs'
			);
			yield put(fleetModalBulkOutputsGetOutputsResult({ error: true }));
		}
	});
}

//Saga Export
export default function* rootSaga() {
	yield all([
		fork(requestBulkOutputsSendCommands),
		fork(requestBulkGetOutputs)
	]);
}