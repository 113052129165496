import {
    GET_GEOFENCES_GROUP,
    GET_GEOFENCES_GROUP_RESULT,
    MAP_GEOFENCES_UPDATE_ADD_GEOFENCE_GROUP,
    MAP_GEOFENCE_LIST_GETTAGS,
    MAP_GEOFENCE_LIST_GETTAGS_RESPONSE,
    MAP_GEOFENCE_LIST_GETTAGS_TOCREATE,
    MAP_GEOFENCE_LIST_GETTAGS_TOCREATE_RESPONSE
  } from 'Redux/actionTypes';


export const getGeofenceGroups = () => ({
    type: GET_GEOFENCES_GROUP,
    payload: {}
});

export const getGeofenceGroupsResult = (result, error) => ({
    type: GET_GEOFENCES_GROUP_RESULT,
    payload: { result, error },
});

export const updateGeofenceGroupInRedux = (action, geofenceGroup) => ({
    type: MAP_GEOFENCES_UPDATE_ADD_GEOFENCE_GROUP,
    payload: { action, geofenceGroup }
});

export const mapGetGeofenceTags = () => ({
    type: MAP_GEOFENCE_LIST_GETTAGS,
    payload: { entityName: "Geofence", getAllTags : false }
});

export const mapGetGeofenceTagsResponse = (data) => ({
    type: MAP_GEOFENCE_LIST_GETTAGS_RESPONSE,
    payload: { data },
});

export const mapGetGeofenceTagsToCreate = () => ({
    type: MAP_GEOFENCE_LIST_GETTAGS_TOCREATE,
    payload: { entityName: "Geofence", getAllTags : true }
});

export const mapGetGeofenceTagsToCreateResponse = (data) => ({
    type: MAP_GEOFENCE_LIST_GETTAGS_TOCREATE_RESPONSE,
    payload: { data },
});
