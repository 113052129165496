import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import { Grid } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import { Select } from 'Components'
import './selector.scss'
import { isNumber } from 'lodash';
const ArrowSelect = ({value, onChange, options, textField, valueField, placeholderSelector, showReportSetup, loading}) => {
  
  const [count, setCount] = useState(1)
  useEffect(() => {
    setCount(count + 1)
  }, [showReportSetup])

  const currentIndex = options?.findIndex((valueOption) => valueOption.id === value[0].id)
  const isFirstItem = currentIndex === 0;
  const isLastItem = currentIndex === options.length - 1
  const handleNextItem = () => {

    if(value?.length && options.length){
      const currentItemIndex = options?.findIndex((valueOption) => valueOption.id  === value[0].id);
      if(isNumber(currentItemIndex)){
        const nextItem = options[currentItemIndex + 1]
        if(nextItem){
          onChange(nextItem)
          return;
        } else {
          onChange(options[0])
        }
      }
    } else {
      onChange(options[0])
    }
  }

  const handleBeforeItem = () => {
    if(value?.length && options.length){
      const currentItemIndex = options?.findIndex((valueOption) => valueOption.id  === value[0].id);
      if(isNumber(currentItemIndex)){
        const previousItem = options[currentItemIndex - 1]
        if(previousItem){
          onChange(previousItem)
          return;
        } else {
          onChange(options[options.length -1])
        }
      }
    } else {
      onChange(options[options.length -1])
    }
  }
  
  return (
    <Grid container alignItems={"center"} spacing={1}>
      <Grid item  className={cx('ctn-selector-select', showReportSetup ? "ctn-selector-select-open": "")}>
        <Select
          id="select-report-data"
          label={''}
          value={value}
          onChange={onChange}
          options={options}
          textField={textField || "label"}
          valueField={valueField || "id"}
          placeholder={placeholderSelector ? placeholderSelector : 'Filter'}
          menuPlacement="top"
          className={"select-report-data"}
          disabled={loading}
        />
      </Grid>
      <Grid container item spacing={1} className={cx('ctn-selector-arrows', showReportSetup ? "ctn-selector-arrows-open": "")}>
        <Grid item sm={6} onClick={() => {
          if(!isFirstItem && !loading){
            handleBeforeItem()
          }
        }} className={isFirstItem ? "" : 'ctn-selector-arrows-pointer'}>
          <ArrowLeftIcon className={(isFirstItem || loading) ? 'ctn-selector-arrows-deselected' : 'ctn-selector-arrows-has-more-items'}></ArrowLeftIcon>
        </Grid>
        <Grid item sm={6} onClick={() => {
          if(!isLastItem && !loading) {
            handleNextItem()
          }
        }} className={isLastItem ? "" : 'ctn-selector-arrows-pointer'}>
          <ArrowRightIcon className={(isLastItem || loading) ? 'ctn-selector-arrows-deselected': 'ctn-selector-arrows-has-more-items'}></ArrowRightIcon>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ArrowSelect;