//imports
import {
    MIGRATE_USER_SEND,
    MIGRATE_USER_SEND_RESULT,
    MIGRATE_USER_CONFIRM_CLOSE,
    MIGRATE_USER_CONFIRM,
    MIGRATE_USER_CONFIRM_RESULT,
    CLEAR_REDUX,
    SET_PERMISSIONS_TO_MIGRATE_THE_USER
} from "Redux/actionTypes";

//initial values
const INIT_STATE = {
    loading: false,
    showConfirm: false,
    error: null,
    cognitoValues: null,
    result: false,
    hasPermissionToMigrateUser: false
};

//change redux object
export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case MIGRATE_USER_SEND:
            return { ...INIT_STATE, loading: true, error: null };

        case MIGRATE_USER_SEND_RESULT:
            return { ...state, loading: false, showConfirm: !action.payload.error, cognitoValues: action.payload.cognitoValues, error: action.payload.error };

        case MIGRATE_USER_CONFIRM_CLOSE:
            return { ...state, showConfirm: false, cognitoValues: false, error: null };

        case MIGRATE_USER_CONFIRM:
            return { ...state, loading: true, result: false, error: null };

        case MIGRATE_USER_CONFIRM_RESULT:
            return { ...state, loading: false, result: action.payload.result, error: action.payload.error };
          case SET_PERMISSIONS_TO_MIGRATE_THE_USER:
            return { ...state, hasPermissionToMigrateUser: action.payload.data};
        case CLEAR_REDUX:
            return ["", "MIGRATE_USER"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state }
        default:
      return state;
    }
};
