import {
	//TYPES
	FLEET_OPEN_STARTER_SCHEDULE,
	FLEET_CLOSE_STARTER_SCHEDULE,
	FLEET_CREATE_STARTER_SCHEDULE,
	FLEET_CREATE_STARTER_SCHEDULE_RESULT,
	FLEET_SEARCH_STARTER_SCHEDULES,
	FLEET_SEARCH_STARTER_SCHEDULES_RESULT,
	FLEET_DELETE_STARTER_SCHEDULE,
	FLEET_DELETE_STARTER_SCHEDULE_RESULT,
	FLEET_UPDATE_STARTER_SCHEDULE,
	FLEET_UPDATE_STARTER_SCHEDULE_RESULT,
} from 'Redux/actionTypes';

export const fleetOpenModalStarterSchedule = () => {
	return {
		type: FLEET_OPEN_STARTER_SCHEDULE
	}
}

export const fleetCloseModalStarterSchedule = () => {
	return {
		type: FLEET_CLOSE_STARTER_SCHEDULE
	}
}

export const updateStarterScheduleRequest = (data) => {
	return {
		type: FLEET_UPDATE_STARTER_SCHEDULE,
		payload: data,
	};
};

export const updateStarterScheduleResult = (data) => {
	return {
		type: FLEET_UPDATE_STARTER_SCHEDULE_RESULT,
		payload: { data },
	};
};

export const deleteStarterScheduleRequest = (data) => {
	return {
		type: FLEET_DELETE_STARTER_SCHEDULE,
		payload: data,
	};
};

export const deleteStarterScheduleResult = (data) => {
	return {
		type: FLEET_DELETE_STARTER_SCHEDULE_RESULT,
		payload: { data },
	};
};

export const saveStarterSchedule = (data) => {
	return {
		type: FLEET_CREATE_STARTER_SCHEDULE, //ACTION TYPE
		payload: data
	}
};

export const saveStarterScheduleResult = (data) => {
	return {
		type: FLEET_CREATE_STARTER_SCHEDULE_RESULT,
		payload: { data }
	}
};

export const searchStarterSchedulesRequest = () => {
	return {
		type: FLEET_SEARCH_STARTER_SCHEDULES, //ACTION TYPE
		payload: {}
	}
};

export const searchStarterSchedulesResult = (data) => {
	return {
		type: FLEET_SEARCH_STARTER_SCHEDULES_RESULT,
		payload: { data },
	};
};
