// Action Types
import {
  SETTINGS_LOCATIONS_SEARCH,
  SETTINGS_LOCATIONS_SEARCH_RESPONSE,
  SETTINGS_LOCATIONS_CREATE_MODAL_SHOW_MODAL,
  SETTINGS_LOCATIONS_CREATE_MODAL_HIDE_MODAL,
  SETTINGS_LOCATIONS_CREATE_LOCATION,
  SETTINGS_LOCATIONS_CREATE_LOCATION_RESPONSE,
  SETTINGS_LOCATIONS_EDIT_LOCATION,
  SETTINGS_LOCATIONS_EDIT_LOCATION_RESPONSE,
  SETTINGS_LOCATIONS_DELETE_MODAL_SHOW_MODAL,
  SETTINGS_LOCATIONS_DELETE_MODAL_HIDE_MODAL,
  SETTINGS_LOCATIONS_DELETE_LOCATION,
  SETTINGS_LOCATIONS_DELETE_LOCATION_RESPONSE,
  CLEAR_REDUX,
} from "Redux/actionTypes";

/**
 * Initial State Contacts
 */
const INITIAL_STATE = {
  searchLoading: false,
  location: null,
  searchResult: [],
  showModalCreateEdit: false,
  showModalDelete: false,
  createLoading: false,
  editLoading: false,
  deleteLoading: false,
  items: [],
  create: null,
  edit: null,
  delete: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case SETTINGS_LOCATIONS_SEARCH:

      return {
        ...state,
        searchLoading: true,
      };
    case SETTINGS_LOCATIONS_SEARCH_RESPONSE:
      return {
        ...state,
        searchLoading: false,
        searchResult: action.payload
      };
    case SETTINGS_LOCATIONS_CREATE_MODAL_SHOW_MODAL:
      return {
        ...state,
        showModalCreateEdit: true,
        location: action?.payload
      }
    case SETTINGS_LOCATIONS_CREATE_MODAL_HIDE_MODAL:
      return {
        ...state,
        showModalCreateEdit: false,
        location: null
      }
    case SETTINGS_LOCATIONS_CREATE_LOCATION:
      return {
        ...state,
        createLoading: true
      }
    case SETTINGS_LOCATIONS_CREATE_LOCATION_RESPONSE:
      return {
        ...state,
        createLoading: false,
        create: action.payload,
      }

    case SETTINGS_LOCATIONS_EDIT_LOCATION:
      return {
        ...state,
        editLoading: true,
      }

    case SETTINGS_LOCATIONS_EDIT_LOCATION_RESPONSE:
      return {
        ...state,
        editLoading: false,
        edit: action.payload
      }
    case SETTINGS_LOCATIONS_DELETE_MODAL_SHOW_MODAL:
      return {
        ...state,
        showModalDelete: true,
        items: action.payload
      }
    case SETTINGS_LOCATIONS_DELETE_MODAL_HIDE_MODAL:
      return {
        ...state,
        showModalDelete: false,
        items: [],
      }
    case SETTINGS_LOCATIONS_DELETE_LOCATION:
      return {
        ...state,
        deleteLoading: true,
      }
    case SETTINGS_LOCATIONS_DELETE_LOCATION_RESPONSE:
      return {
        ...state,
        deleteLoading: false,
        delete: action.payload,
      }
    case CLEAR_REDUX:
      return ["", "SETTINGS_LOCATIONS"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INITIAL_STATE }
        : { ...state };
    default:
      return { ...state };
  }
};