import { createSlice } from "@reduxjs/toolkit";

const INIT_STATE = {
    open: false,
    data: null
};

export const warningsResolveRedux = createSlice({
    name: 'devices/Warnings/resolve',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openWarningResolveModal(state, action) {
            state.open = true
            state.data = action.payload
        },
        closeWarningResolveModal(state, action) {
            return { ...INIT_STATE }
        }
    }
})

const { actions, reducer } = warningsResolveRedux;

// Extract and export each action creator by name
export const {
    reset,
    openWarningResolveModal,
    closeWarningResolveModal
} = actions;

export default reducer;
