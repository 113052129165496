import React from 'react';
import colors from 'Assets/sass/core/vars.scss';
import {useIntl} from "react-intl";

const EventReports = (props) => {
    const event = props.element.event;
    const {messages}  = useIntl();

    const eventsColors = [
        {
            label: 'Ignition On',
            color: '#00AEEF',
            id: 1
        },
        {
            label: 'Ignition Off',
            color: '#ED2020',
            id: 2
        },
        {
            label: 'Travel Stop',
            color: '#ED2020',
            id: 3
        },
        {
            label: 'Travel Start',
            color: '#2AAC30',
            id: 4
        },
        {
            label: 'Ping',
            color: '#2AAC30',
            id: 5
        },
        {
            label: 'Idle',
            color: '#FF7337',
            id: 6
        },
        {
            label: 'Main Power Off',
            color: '#ED2020',
            id: 7
        },
        {
            label: 'Drive',
            color: '#2AAC30',
            id: 8
        },
        {
            label: 'Hard Turn',
            color: '#7B37D2',
            id: 9
        },
        {
            label: 'Hard Brake',
            color: '#7B37D2',
            id: 10
        },
        {
            label: 'Stop',
            color: '#ED2020',
            id: 11
        },
        {
            label: 'Rapid Acceleration',
            color: '#7B37D2',
            id: 12
        },
        {
            label: 'Main Power Disconnect',
            color: '#ED2020',
            id: 13
        },
        {
            label: 'IDLE',
            color: '#FF7337',
            id: 14
        },
        {
            label: 'MOVE',
            color: '#2AAC30',
            id: 15
        },
    ];

    const renderViewEvent = () => {
        const filterEvent = eventsColors.filter((ev) => ev.label === event)
        return (<strong style={{color: filterEvent[0] ? filterEvent[0]?.color : colors.black}}>{messages['generatedReport_event_' + filterEvent[0]?.id] || event}</strong>)
    }

    return (
        <span>
            {renderViewEvent()}
        </span>
    )
}

export default EventReports;
