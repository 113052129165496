import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';

// Actions
import {
  reportsMainGenerateReportProgress
} from 'Redux/actions';

import {
  getTimeZone,
  handleAsyncReport,
  getCategoryFilter,
  generateReportInstance,
  baseReportConditions,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getFSMCombinedReport(report, page, rowsPerPage, sortField, sortOrder) {
  const { filterType, filterArray, startRange, endRange,  unitsSelected, filterEntity } = report.filters;
  const { timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath } = report;
  const baseConditionsQuery = baseReportConditions({ report, name, dataResultPath, timeZone, delayed, unitsSelected, timeframeName , filterType, filterArray, categoryName, startRange, endRange})

  let payload = {
    conditions: {
      ...baseConditionsQuery,
      sortField: {
        field: sortField || "deviceId",
        order: sortOrder === 'desc' ? 'DESC' : 'ASC'
      }
    },
    offset: page,
    limit: rowsPerPage
  }
  if (report.export) {
     payload = {
      conditions: {
        ...payload.conditions,
        exportOptions: {
          sendByEmail,
          emailsTo,
          culture: report.lang,
          timeZone: getTimeZone(timeZone),
          format: {
            fileFormat: report?.exportType  || "json",
            formatOptions: "{}"
          },
          folderName: "px-customer-reports",
          filePrefixName: "fsmCombinedReport",
          columns: [
            {
              column: "driverName",
              header: "Label"
            },
            {
              column: "driverStatus",
              header: "Driver Status"
            },
            {
              column: "inOut",
              header: "In/Out"
            },
            {
              column: "dateTime",
              header: "Time & Date"
            },
            {
              column: "address",
              header: "Address"
            },
            {
              column: "messageDirection",
              header: "Chat To/From"
            },
            {
              column: "message",
              header: "Message"
            },
            {
              column: "stopTitle",
              header: "Stop Title"
            },
            {
              column: "stopStatus",
              header: "Stop Status"
            }
          ]
        }
      },
      limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
      offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1
    }
  }
  try {
    let data = yield call(
      clientQuery,
      `query($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getCombinedReport(conditions: $conditions, limit: $limit, offset: $offset) {
          ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?  

            `reportInstanceId`
          :`
            total
            limit
            offset
            category
            items {
              id
              driverName
              noData
            }
          `}
        }
      }`,
      payload,
      endpoints.GRAPHQL_PX_REPORTING,
    );

    yield handleAsyncReport(report, data)
  } catch (exc) {
    ExceptionManager(exc, 'src/modules/reports/views/main/components/reportsTab/.components/reports/fsmCombinedReport', 'getFSMCombinedReport');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
  }
}


export default getFSMCombinedReport;
