export default {
    speedOverride_create_title: 'New Speed Override',
    speedOverride_create_edit_title: 'Speed Override',
    speedOverride_create_info: 'Navigate to a location or enter an address in the search field on the map to get started. Click the section of road where the speed override begins to add a start point. Then click the section of road where the override ends to add the end point.',
    speedOverride_create_label: 'Speed Override Label',
    speedOverride_create_segment: 'Speed Override Segment',
    speedOverride_create_start: 'Start',
    speedOverride_create_end: 'End',
    speedOverride_create_exist_speedLimit: 'Existing Speed Limit',
    speedOverride_create_correct_speedLimit: 'Correct Speed Limit',
    speedOverride_create_notes: 'Notes',
    speedOverride_create_notes_placeholder: 'Include a note',
    speedOverride_create_route_valid: 'Before the save is necessary to create a valid route on the map',
    speedOverride_create_sucessfull: "Speed Override saved successfully",
    speedOverride_create_error_name: "Duplicated name for speed override.",
    speedOverride_create_error_address: "The start and end addresses have already been configured previously.",
    speedOverride_create_error_distance: "The distance of the 2 points must be greater than 15 meters."
};