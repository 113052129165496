import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../core/logManager';

import { resetALL, catalogLoad } from '../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    openModal: false,
    result: null,
    error: null
};

const route = 'modules/chat/modals/chatListContacts/redux/slice'

export const chatListContactsOpen = createAsyncThunk('chat/listContact/open', async (params, { rejectWithValue, dispatch }) => {
    try {
        // dispatch(catalogLoad(['chatUsers']));
        return null;
    } catch (exc) {
        ExceptionManager(exc, route, 'chatListContactsOpen');
        return rejectWithValue(exc)
    }
});

export const createChatChannel = createAsyncThunk('chat/listContact/createChannel', async ({ name, users }, { rejectWithValue, dispatch }) => {
    try {
        const result = await clientMutation(`
            mutation createChatChannel ($isGroup: Boolean!, $name: String, $users: [String]!, $avatar: String) {
                data: createChatChannel (isGroup: $isGroup, name: $name, users: $users, avatar: $avatar) {
                    id
                    isGroup
                    name
                    avatar
                }
            }
        `,
            {
                isGroup: false,
                name: name,
                users: users
            },
            endpoints.GRAPHQL_GENERAL_V2
        )
        return result?.data;
    } catch (exc) {
        ExceptionManager(exc, route, 'createChatChannel');
        return rejectWithValue(exc)
    }
});

//Slice
const chatListContactsRedux = createSlice({
    name: 'chat/listContact',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        // chatListContactsOpen(state, action) {
        //     state.open = true
        // },
        close(state, action) {
            state.openModal = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        builder.addCase(chatListContactsOpen.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(chatListContactsOpen.fulfilled, (state, action) => {
            state.loading = false;
            state.openModal = true;
        });
        builder.addCase(chatListContactsOpen.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(createChatChannel.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(createChatChannel.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action.payload;
        });
        builder.addCase(createChatChannel.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = chatListContactsRedux;

// Extract and export each action creator by name
export const { reset, close } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;