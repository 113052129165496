import React from "react";
import { useIntl } from "react-intl";

const AgreementMessage = () => {
  const { messages } = useIntl();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        color: '#868989'
      }}
    >
      {messages["login_passive_acceptance"]}
      <a
        style={{color:'#00AEEF'}}
        href="https://gpstrackit.com/terms-of-service"
        target="_blank"
        rel="noreferrer"
        className="login-term-service-link"
      >
        {messages["login_end_user_license_agreement"]}
      </a>
    </div>
  );
};

export default AgreementMessage;
