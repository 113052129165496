import React from 'react';
import { Checkbox } from 'Components';
import { Grid } from '@mui/material';

const ExtraOptions = (props) => {
	
	const { selectProps, selectedOptions } = props;	

	return (
		<div className="extra-option">
			<div style={{ marginBottom: 10, fontWeight: 700 }}>{props.label}</div>
			
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="flex-start"
					style={{ padding: '0px 0px 2px 8px' }}
				>
					{selectProps.extraOptions.map((extraOption, index) => {
						return (
							<Grid item xs key={index}>
								<Checkbox
									id={`${Math.floor(Math.random() * 100000)}-${
										extraOption[selectProps.extraOptionsvalueField]
									}`+index}
									value={
										selectedOptions[props.value]
											? !!selectedOptions[props.value].find(
													(option) =>
														option[selectProps.extraOptionsvalueField] ===
														extraOption[selectProps.extraOptionsvalueField],
											  )
											: false
									}
									onChange={(value) => {								

										let newSelectedOptions = { ...selectedOptions };
                                        
										if (!newSelectedOptions[props.value]) {
											newSelectedOptions[props.value] = [];
										}

										if (value) {
											newSelectedOptions[props.value].push(extraOption);
										} else {
											newSelectedOptions[props.value] = newSelectedOptions[props.value].filter(
												(option) =>
													option[selectProps.extraOptionsvalueField] !=
													extraOption[selectProps.extraOptionsvalueField],
											);
										}

										props.onChange(newSelectedOptions);									
                                       
									}}
									label={extraOption[selectProps.extraOptionstextField]}
									size="sm"
								/>
							</Grid>
						);
					})}

				</Grid>
			
		</div>
	);
};

export default ExtraOptions;
