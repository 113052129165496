import {
    ALERT_LOG_GET_SYSTEM_ALERT_LOG,
    ALERT_LOG_GET_SYSTEM_ALERT_LOG_RESPONSE,
    CLEAR_REDUX
  } from 'Redux/actionTypes';
  
  const INIT_STATE = {
    loading: false,
    response: null
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case ALERT_LOG_GET_SYSTEM_ALERT_LOG:
        return { ...state, response: null, loading: true };
  
      case ALERT_LOG_GET_SYSTEM_ALERT_LOG_RESPONSE:
        return { ...state, response: action.payload.data, loading: false };
  
      case CLEAR_REDUX:
        return ["", "ALERT_LOG"].includes(action.payload.option)
          ? action.payload.parameters
            ? { ...state, ...action.payload.parameters }
            : { ...INIT_STATE }
          : { ...state }
  
      default:
        return state;
    }
  }