//View Keys
export default {
	locationsView_locations: 'Locations',
    locationsView_vehicleLocation: 'Vehicle Location',
    locationsView_units: 'Units',
    locationsView_delete: 'Delete',
    locationsView_addLocation: 'Add',
    locationsView_createNewLocation: 'Create New Location',
    locationsView_editLocation: 'Edit Location',
    locationsView_successfullyCreated: 'The location was successfully created',
    locationsView_successfullyEdited: 'The location was successfully edited',
    locationsView_messagesDelete: 'Are you sure you want to delete the locations',
    locationsView_locationName: 'Location Name',
    locationsView_deleteLocation: 'Delete Location',
    locationsView_move: 'Move To',
    locationsView_deleteLabel: 'Delete (All locations of the units are deleted)',
    locationsView_deleteMessage: 'What do you want to do with the locations assigned to the units',
    locationsView_deleteSuccess: 'Locations deleted successfully',
    locationsView_availableActions: 'Available Actions',
    locationsView_searchByLocation: 'Search by Vehicle Location',
};