export default {
    mapGeofenceUnitGeofence_vehicle:'Vehiculo',
    mapGeofenceUnitGeofence_count:'Geocercas Asignadas', //Replace number value
    mapGeofenceUnitGeofence_assigned:'Asignar Geocerca',
    mapGeofenceUnitGeofence_view:'Ver Geocercas',
    mapGeofenceUnitGeofence_event:'Evento',
    mapGeofenceUnitGeofence_group:'Etiquetas',
    mapGeofenceUnitGeofence_geofences:'Geocercas',
    mapGeofenceUnitGeofence_selectPlaceholder:'Seleccionar...',

}