export default {
  "nearest_vehicle": "Vehicle",
  "nearest_equipment": "Equipment",
  "nearest_fromTitle": "Find Nearest",
  "nearest_selectFrom": "Please select an option...",
  "nearest_toTitle": "To",
  "nearest_find": "Find",
  "nearest_show": "Show in map",
  "nearest_directions": "Get Directions",
  "nearest_clear": "Clear",
  "nearest_error": "An error occurred, please try again.",
  "nearest_vehicleDirections": "Vehicle Directions",
  "nearest_optimizeRoute": "Optimize Route",
  "nearest_avoidTolls": "Avoid Tolls",
  "nearest_avoidHighways": "Avoid Highways",
  "nearest_landmark": "Landmark",
  "nearest_equipmentWithoutUnitError": "The selected equipment has not an unit assigned",
  "nearest_miles": "Miles",
  "nearest_kilometers": "Km",
}