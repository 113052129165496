export default {
    "FSM_SETTINGS_TIME_CARD": "Driver Time Card",
    "FSM_SETTINGS_TIME_CARD_driver": "Driver",
    "FSM_SETTINGS_TIME_CARD_startDate": "Start date",
    "FSM_SETTINGS_TIME_CARD_endDate": "End date",
    "FSM_SETTINGS_TIME_CARD_clockIn": "Clock in",
    "FSM_SETTINGS_TIME_CARD_clockOut": "Clock Out",
    "FSM_SETTINGS_TIME_CARD_meakbegin": "Meal Break Begin",
    "FSM_SETTINGS_TIME_CARD_meakEnd": "Meal Break End",
    "FSM_SETTINGS_TIME_CARD_action": "Action",
    "FSM_SETTINGS_TIME_CARD_date": "Date",
    "FSM_SETTINGS_TIME_CARD_title": "New Driver Time Card ",
    "FSM_SETTINGS_TIME_CARD_edit": "Edit Driver Time Card ",
    "FSM_SETTINGS_TIME_CARD_delete_detail":'Are you sure you want to delete the following Driver Time Cards?',
    "FSM_SETTINGS_TIME_CARD_delete_warning":'This action cannot be undone.',
    "FSM_SETTINGS_TIME_CARD_delete_title":'Delete Driver Time Cards',
    "FSM_SETTINGS_TIME_CARD_create_success": "Driver Time Card Created",
    "FSM_SETTINGS_TIME_CARD_update_success": "Driver Time Card Updated",
    "FSM_SETTINGS_TIME_CARD_delete_success": "Driver Time Card Deleted",
    "FSM_SETTINGS_TIME_CARD_error_date": "The start date cannot be less than the end date",
    "FSM_SETTINGS_TIME_CARD_error_date_edit": "The date must be less than current",
    "FSM_SETTINGS_TIME_CARD_total": "Total",
    "FSM_SETTINGS_TIME_CARD_daily_total": "Daily Total",
};