//imports
import { all } from 'redux-saga/effects';

import realtimeMapsGeofenceListSagas from 'Modules/realtimeMaps/main/components/geofences/components/geofenceList/redux/saga';
import realtimeMapsGeofenceCreateEditSagas from 'Modules/realtimeMaps/main/components/geofences/components/geofenceCreateEdit/redux/saga';
import realtimeMapsGeofenceGroupCreateEditSagas from 'Modules/realtimeMaps/main/components/geofences/components/geofenceGroupCreateEdit/redux/saga';
import realtimeMapsGeofenceDeleteSagas from 'Modules/realtimeMaps/main/components/geofences/components/geofenceDelete/redux/saga';
import realtimeMapsGeofenceGroupDeleteSagas from 'Modules/realtimeMaps/main/components/geofences/components/geofenceGroupDelete/redux/saga';
import realtimeMapsGeofenceSettingsViewUnitsSagas from 'Modules/realtimeMaps/main/components/geofences/components/geofenceSettingsViewUnits/redux/saga';
import realtimeMapsGeofenceContactsSagas from 'Modules/realtimeMaps/main/components/geofences/components/geofenceContacts/redux/saga';
import realtimeMapsGeofenceAssignSagas from 'Modules/realtimeMaps/main/components/geofences/components/geofenceAssign/redux/saga';
import realtimeMapsGeofenceBulkSagas from 'Modules/realtimeMaps/main/components/geofences/components/geofenceBulk/redux/saga';
import realtimeMapsGeofenceVisibleTags from 'Modules/realtimeMaps/main/components/geofences/components/geofenceVisibleTags/redux/saga';

//merge sagas
export default function* rootSaga() {
    yield all([
        realtimeMapsGeofenceListSagas(),
        realtimeMapsGeofenceCreateEditSagas(),
        realtimeMapsGeofenceGroupCreateEditSagas(),
        realtimeMapsGeofenceDeleteSagas(),
        realtimeMapsGeofenceGroupDeleteSagas(),
        realtimeMapsGeofenceSettingsViewUnitsSagas(),
        realtimeMapsGeofenceContactsSagas(),
        realtimeMapsGeofenceAssignSagas(),
        realtimeMapsGeofenceBulkSagas(),
        realtimeMapsGeofenceVisibleTags()
    ]);
}