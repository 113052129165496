export default {
  mapTagFilter_vehicles: 'Vehicles',
  mapTagFilter_landmark: 'Landmarks',
  mapTagFilter_geofences: 'Geofences',
  mapTagFilter_total: 'Total',
  mapTagFilter_showAll: 'Show All Tags',
  mapTagFilter_showAll_vehicles: 'Show All Vehicles',
  mapTagFilter_showAll_landmarks: 'Show All Landmarks',
  mapTagFilter_showAll_geofences: 'Show All Geofences',
  mapTagFilter_tags_total: 'Total Tags',
  mapTagFilter_tags_visible: 'Visible Tags',
  mapTagFilter_tags_hidden: 'Hide Tags',
  mapTagFilter_tags_have_vehicles: 'The Tag contains Vehicles',
  mapTagFilter_tags_have_landmarks: 'The Tag contains Landmarks',
  mapTagFilter_tags_have_geofences: 'The Tag contains Geofences',
};