//import defaultInfoResellers from './../default.json';
import { call, all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  GET_RESELLER_INFO
} from 'Redux/actionTypes';
import { endpoints } from 'Core/defaultValues';
import {
  setLegacyUrl
} from 'Core/data/Helpers';

//actions
import {
  getResellerInfoResult
} from 'Redux/actions';
import { ExceptionManager } from 'Core/logManager';
import axios from 'axios';
export function* getResellerInfo({ payload }) {
  try {
    // URL reseller.json file in S3
    let url = `https://s3.amazonaws.com/${endpoints.REPOSITORY_CODE}/resellers/resellers.json` + "?date=" + new Date().getTime();
    // Get info resellers from S3
    let resellersS3 = yield call(async () => {
      const res = axios.get(url).then(reseller => {
        return reseller?.data;
      });
      return res;
    });
    if (resellersS3) {
      const { type, value } = payload;
      let reseller = null;
      // There are 3 ways to identify a reseller
      // by domain - by key - by id
      switch (type) {
        case 'domain':
          reseller = resellersS3.find((resellerInfo) => {
            if (resellerInfo?.domains?.length > 0 && resellerInfo.domains.indexOf(value) >= 0) {
              return true;
            }
          })
          break;
        case 'key':
          reseller = resellersS3.find((resellerInfo) => {
            if (resellerInfo.key === value) {
              return true;
            }
          })
          break;
        case 'id':
          reseller = resellersS3.find((resellerInfo) => {
            if (resellerInfo.id === value) {
              return true;
            }
          })
          break;
      }
      if (reseller) {
        localStorage.setItem("legacyUrl", setLegacyUrl(endpoints.LEGACY_URL, reseller));
        reseller = { ...reseller, legacyUrl: setLegacyUrl(endpoints.LEGACY_URL, reseller) };
        yield put(getResellerInfoResult({
          reseller,
          type
        }));
      } else {
        yield put(getResellerInfoResult(null));
        localStorage.setItem("legacyUrl", null);
      }

    } else {
      yield put(getResellerInfoResult(null));
      localStorage.setItem("legacyUrl", null);
    }
  } catch (exc) {
    // catch throw
    ExceptionManager(exc, 'Core/resellers/redux', 'getResellerInfoResult');
    localStorage.setItem("legacyUrl", null);
    yield put(getResellerInfoResult(null));
  }
}


function* watchResellerInfo() {
  yield takeEvery(GET_RESELLER_INFO, getResellerInfo);
}

//merge sagas
export default function* rootSaga() {
  yield all([
    fork(watchResellerInfo)

  ]);
}
