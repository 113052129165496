import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';
// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

import {
  baseReportConditions,
  generateReportInstance,
  getCategoryFilter,
  getTimeZone,
  handleAsyncReport,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

export const isFilterBaseView = (filter) => {
  return filter === 'single' || filter === 'all'
}
function* getLandmarkBasedReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
  const {timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath} = report;
  const {filterType, filterArray, startRange, endRange, unitsSelected,otherFilters, accumulateData = true, dates} = report.filters

  const baseConditionsQuery = baseReportConditions({
    report,
    name,
    dataResultPath,
    timeZone,
    delayed,
    unitsSelected,
    timeframeName,
    filterType,
    filterArray,
    categoryName,
    startRange,
    endRange,
    dates
  })
  try {
    const valueEntityFilter = isFilterBaseView(otherFilters.landmarkBaseView) ? [{
      id: otherFilters.landmark,
      type: "Landmark"
    }] : {id: otherFilters.landmarkTags.id, type: "Tag"}

    let payload = {
      conditions: {
        ...baseConditionsQuery,
        filterValues: {
          field: isFilterBaseView(otherFilters.landmarkBaseView) ? "Landmark" : "Tag",
          entities: otherFilters.landmarkBaseView === "all"? null: valueEntityFilter
        },
        sortField: {
          field: sortField || "deviceId",
          order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
      },
      limit: rowsPerPage,
      offset: page
    };
    let columns = [];
    if(report?.exportType === 'csv') {
      columns = [
        {
          "column": "landmarkLabel",
          "header": "Landmark"
        },
        {
          "column": getCategoryFilter(filterType) !== "deviceId" ? "driverName" : "deviceLabel",
          "header": getCategoryFilter(filterType) !== "deviceId" ? "Driver" : "Unit"
        },
        {
          "column": "dateFrom",
          "header": "Arrived Date Time"
        },
        {
          "column": "dateTo",
          "header": "Departed Date Time"
        },{
          "column": "time",
          "header": "At Landmarks For"
        },{
          "column": "distance",
          "header": "Distance"
        }

      ]
    }else{
      columns = [
        {
          "column": getCategoryFilter(filterType) !== "deviceId" ? "driverName" : "deviceLabel",
          "header": getCategoryFilter(filterType) !== "deviceId" ? "Driver" : "Unit"
        },
        {
          "column": "dateFrom",
          "header": "From"
        },
        {
          "column": "dateTo",
          "header": "To"
        },
        {
          "column": "time",
          "header": "Time"
        },
        {
          "column": "distance",
          "header": "Distance"
        }
      ]
    }

    payload = {
      ...payload,
      limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
      offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
      conditions: {
        ...payload.conditions,
        exportOptions: {
          sendByEmail,
          emailsTo,
          format: {
            fileFormat: report?.exportType || "json",
            formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
            durationFormat: report?.durationFormat
          },
          culture: report.lang,
          timeZone: getTimeZone(timeZone),
          folderName: "px-customer-reports",
          filePrefixName: "landmarkBasedReport",
          columns
        }
      }
    }
    let data = yield call(
      clientQuery,
      `query($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
                Report: getLandmarkBasedReport (conditions: $conditions, limit: $limit, offset: $offset) {
                    ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
        `reportInstanceId`
        : `
                    items {
                        recordInfo {
                            entityId
                            entityLabel
                            tagId
                            tagLabel
                            startDate
                            endDate
                            recordType
                            groupLabel
                            groupIndex
                            groupTotal
                            groupTagColor
                        }
                        recordDetail {
                          ins
                          duration
                          distance
                          driverName
                          deviceLabel
                          landmarkLabel
                          landmarkId
                          dateFrom
                          dateTo
                          totalSeconds
                          distance
                          isUnitInside
                          time
                          isKPH
                          noData
                        }
                    }
                    total
                    limit
                    offset
                    category
                  `}
                }
              }`,
      payload,
      endpoints.GRAPHQL_PX_REPORTING,
    );
    yield handleAsyncReport(report, data, accumulateData);
  } catch (exc) {
    ExceptionManager(exc, 'reports/landmarkBasedReport', 'getLandmarkBasedReport');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
    if (!requestFlag) {
      let flag = true
      yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
    } else {
      report.loading = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  }
}

export default getLandmarkBasedReport;
