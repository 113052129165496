export default {
    "dynamicFormsMain_forms": "Forms",
    "dynamicFormsMain_answers": "Answers",
    "dynamicFormsMain_name": "Name",
    "dynamicFormsMain_status": "Status",
    "dynamicFormsMain_updatedAt": "Updated At",
    "dynamicFormsMain_updatedBy": "Updated By",
    "dynamicFormsMain_createdAt": "Created At",
    "dynamicFormsMain_edit": "Edit",
    "dynamicFormsMain_rename": "Rename",
    "dynamicFormsMain_viewAnswers": "View answers",
    "dynamicFormsMain_duplicate": "Duplicate",
    "dynamicFormsMain_delete": "Delete",
    "dynamicFormsMain_advancedFilters": "Advanced Filter",
    "dynamicFormsMain_clearAll": "Clear All",
    "dynamicFormsMain_timeframe": "Timeframe",
    "dynamicFormsMain_all": "All",
    "dynamicFormsMain_draft": "Draft",
    "dynamicFormsMain_inactive": "Inactive",
    "dynamicFormsMain_active": "Active",

    "dynamicFormsMain_edit": "Edit",
    "dynamicFormsMain_rename": "Rename",
    "dynamicFormsMain_viewAnswers": "View Answers",
    "dynamicFormsMain_copy": "Copy",
    "dynamicFormsMain_delete": "Delete",

    "dynamicFormsMain_tags": "Tags"
}