import {
  DateRangePicker,
  LinearProgress,
  Loading,
} from "@gpstrackit/gtc-react-controls";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { GetFormatForMoment } from "Core/data/Helpers";
import { searchAvailableMediaIncident, getUnitOverviewRedux, clearRedux} from "Redux/actions";
import Event from "./event";
import Clip from "./clip";
import moment from "moment";
import { reset } from "../../../../redux/slice";

export const Events = ({ rangeDate, onChangeDateTime, activeIncident, startIncident }) => {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const {
    isOpenOverViewUnit,
    unitOverviewItems : realData,
    getUnitOverviewLoading,
    unitOverviewItemsTotal,
  } = useSelector((state) => state.incidentsRedux);
  const { user } = useSelector((state) => state.securityRedux);
  const { liveClipUpdated, unitsList } = useSelector(state => state.catalogsRedux);
  const { dateformat, isKph } = user;
  const listInnerRef = useRef();


  const id = isOpenOverViewUnit?.data?.unit?.id
  const hasVideo = isOpenOverViewUnit?.data?.unit?.hasVideo

  
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [activeFilterDate, setActiveFilterDate] = useState(1)
  const [page, setPage] = useState(0);
  const [unitOverviewItems, setUnitOverviewItems] = useState([])
  const [timeZone, setTimeZone] = useState({name: ''})
  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    startDate: rangeDate?.start,
    endDate: rangeDate?.end,
    unitId: id
  });
  useEffect(() => {
    if(!liveClipUpdated) return
    const mergeItems = [liveClipUpdated, ...realData]
    setUnitOverviewItems(mergeItems)
    dispatch(clearRedux("CATALOG_REDUX", { liveClipUpdated: null }));
  }, [liveClipUpdated])

  useEffect(() => {
    const unit = unitsList?.find(unit => unit?.id === id)
    if(unit?.timeZone){
      setTimeZone(unit?.timeZone)
    }
  }, [unitsList])

  useEffect(() => {
    setUnitOverviewItems(realData)
  }, [realData])

  useEffect(() => {
    dispatch(getUnitOverviewRedux(filters));
  }, [filters]);

  useEffect(() => {
    if (page === 0) return;

    setFilters({
      ...filters,
      offset: page * filters?.limit,
      research: false,
      currentData: unitOverviewItems,
    });
  }, [page]);

  useEffect(() => {
    if (
      moment(filters?.dateRange?.start).isSame(moment(rangeDate?.start)) &&
      moment(filters?.dateRange?.end).isSame(moment(rangeDate?.end))
    ) {
    } else {
      if (listInnerRef.current) {
        listInnerRef.current.scrollTop = 0;
      }

      setPage(0)
      dispatch(
        reset({
          unitOverviewItems: [],
          unitOverviewItemsTotal: 0
        })
      )

      setFilters({
        ...filters,
        offset: 0,
        limit: 10,
        research: true,
        currentData: [],
        startDate: rangeDate?.start,
        endDate: rangeDate?.end, 
      });
    }
  }, [rangeDate]);

  useEffect(() => {
    if(!getUnitOverviewLoading && unitOverviewItems?.length > 0){
      setPaginationLoading(false)
    }
  }, [getUnitOverviewLoading, unitOverviewItems])

  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (
        scrollTop + clientHeight + 100  >= scrollHeight &&
        unitOverviewItems?.length < unitOverviewItemsTotal &&
        !paginationLoading
      ) {
        setPaginationLoading(true)
        setPage(page + 1);
      }
    }
  };

  return (
    <div
      className="incident_events"
      style={{ width: hasVideo ? "26%" : "50%", height: "auto" }}
    >
      <div style={{ display: "flex", height: '52px'}} className="incident_events_title">
        <p style={{ margin: 0, marginRight: "5px" }}>
          {messages["incident_event_title"]}
        </p>
        <div style={{width: '100%'}}>
          {
            !activeIncident ?
              <DateRangePicker
                format="MMM DD, YYYY"
                calendarPosition="bottom-end"
                showButtons
                withTime={true}
                startDate={rangeDate?.start}
                endDate={rangeDate?.end}
                style={{ minWidth: "0" }}
                maxDate={moment().endOf("day").toDate()}
                onChange={(start, end) => onChangeDateTime(start, end)}
                customRangeDate={8}
              /> :
              <div style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-evenly'
              }}>
                <div
                  className={activeFilterDate === 0 ? 'incident_selected_filter_date' : 'incident_filter_date'}
                  onClick={() => {
                    if(activeFilterDate === 0) return
                    onChangeDateTime(startIncident, moment().endOf('day'))
                    setActiveFilterDate(0)
                  }}
                >
                  {messages['event_from_start']}
                </div>
                <div
                  className={activeFilterDate === 1 ? 'incident_selected_filter_date' : 'incident_filter_date'}
                  onClick={() => {
                    if(activeFilterDate === 1) return
                    onChangeDateTime(moment().subtract(24, 'hours'), moment())
                    setActiveFilterDate(1)
                  }}
                >
                  {messages['event_last_24h']}
                </div>
              </div>
          }
         
        </div>
      </div>
      {getUnitOverviewLoading && unitOverviewItems?.length === 0 ? (
        <div className="incident_events_loading">
          <Loading />
        </div>
      ) : (

        !getUnitOverviewLoading && unitOverviewItems?.length === 0 ?
          <div style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            textAlign: 'center'
          }}>
            <p style={{padding: '10px'}}>
              {messages['incidents_no_data_events']}
            </p>
          </div> :

        <div
          className="incident_event_list"
          onScroll={handleScroll}
          ref={listInnerRef}
        >
          {unitOverviewItems?.map((item) => {
            if(item?.type === 'deviceReading'){
              return (
                <Event
                  {...item}
                  isKph={isKph}
                  dateformat={GetFormatForMoment(dateformat)}
                  timeZone={timeZone}
                />
              );
            }else{
              return (
                <Clip
                    {...item}
                    isKph={isKph}
                    dateformat={GetFormatForMoment(dateformat)}
                    timezone={timeZone}
                />
              )
            }
          })}
        </div>
      )}
      {paginationLoading && unitOverviewItems?.length > 0 && (
        <div style={{ width: "100%" }}>
          <LinearProgress />
        </div>
      )}
    </div>
  );
};
