import { isJson } from 'Core/data/Helpers';
import { clearStringValue } from 'Core/data/Helpers'

import { getApiDate, validateFutureDates, convertEpochToUTC } from '../../hooks/useUnitData';
import { LogManagerConsole } from '../../logManager';

export class LastReading {

  executeArray = (items, itemsExists) => {
    //console.log("executeArray")
    let readings = [...itemsExists.readings];

    if (!items) return [true, { readings: [...readings] }]

    for (let unit of itemsExists.units) {
      let item = items.find(x => x.deviceId == unit.id || x.id == unit.id);

      //exists in items recived
      if (item) {
        let index = readings.findIndex(x => x.deviceId == unit.id)
        //exists reading
        if (index > -1) {
          readings[index] = this.getProcessItem(readings[index], item);
        }
        else {
          readings.push(this.getProcessItem({}, item))
        }
      }
    }

    return [true, { readings: [...readings] }]
  }

  execute = (item, itemsExists) => {

    let readings = itemsExists.readings
    let index = readings.findIndex(x => x.deviceId == item.deviceId || x.deviceId == item.id)
    if (index != -1)
      readings[index] = this.getProcessItem(readings[index], item)

    return [true, { readings: [...readings] }]

  }

  getProcessItem = (item, newItem) => {

    LogManagerConsole("LastReading", "getProcessItem", item, newItem)
    newItem = { ...newItem, serverTime: getApiDate(newItem.serverTime), unitTime: getApiDate(newItem.unitTime) }

    let stringToLog = { 
      ageSeconds: (newItem.unitTime || 0) - (item.unitTime || 0),
      unitTimeOld: convertEpochToUTC(item.unitTime),
      unitTime: convertEpochToUTC(newItem.unitTime),
      serverTime: convertEpochToUTC(newItem.serverTime),
      unitId: item.id,
      deviceId: newItem.deviceId,
      rowId: newItem?.rowId, 
      Label:  newItem.deviceLabel,
      eventName: newItem.eventName,
      eventCode : newItem?.eventCode };

    //always recent item
    if (newItem.unitTime && item.unitTime > newItem.unitTime) {
      LogManagerConsole("LastReading", "No paso fecha", item.unitTime, newItem.unitTime)
      LogManagerConsole("LastReading-Rejected", "**** Rejected Socket Reading: (unitTime)", stringToLog)
      return item
    }

    if(!validateFutureDates(newItem.unitTime)){
      LogManagerConsole("LastReading", "Is a future date", item.unitTime, newItem.unitTime)
      LogManagerConsole("LastReading-Rejected", "**** Rejected Socket Reading: (validateFutureDates)", stringToLog)
      return item
    }

    if (newItem.unitTime && newItem.unitTime == item.unitTime && !validateLastEvent(newItem.eventName, item.eventName, newItem.eventCode)) {
      LogManagerConsole("LastReading", "No paso evento", item.eventName, newItem.eventName)
      LogManagerConsole("LastReading-Rejected", "**** Rejected Socket Reading: (validateLastEvent)", stringToLog)
      return item
    }

    
    const latitude = newItem.latitude != undefined ? newItem.latitude : item.latitude;
    const longitude = newItem.longitude != undefined ? newItem.longitude : item.longitude;
    const speed = newItem.speed != undefined ? newItem.speed : item.speed;
    const ecuSpeed = newItem.ecuSpeed != undefined ? newItem.ecuSpeed : item.ecuSpeed;
    const haveLatLng = Boolean((latitude && !isNaN(latitude) && parseFloat(latitude) != 0) && (longitude && !isNaN(longitude) && parseFloat(longitude) != 0));

    LogManagerConsole("LastReading", "getProcessItem paso", latitude, longitude, haveLatLng)
    let objItem = {
      ...item,
      id: newItem.deviceId,
      deviceId: newItem.deviceId != undefined ? newItem.deviceId : item.deviceId,
      make: newItem.make != undefined ? clearStringValue(newItem.make) : clearStringValue(item.make),
      model: newItem.model != undefined ? clearStringValue(newItem.model) : clearStringValue(item.model),
      year: newItem.vin != undefined ? clearStringValue(newItem.year) : clearStringValue(item.year),
      /* driverId: newItem.driverId != undefined ? newItem.driverId : item.driverId, //this value come from unit procesor
      driverName: newItem.driverName != undefined ? clearStringValue(newItem.driverName) : clearStringValue(item.driverName), */
      vin: newItem.vin != undefined ? clearStringValue(newItem.vin) : clearStringValue(item.vin),
      ecuVin: newItem.ecuVin != undefined ? newItem.ecuVin : item.ecuVin,
      unitTime: newItem.unitTime != undefined ? newItem.unitTime : item.unitTime,
      eventCode: newItem.eventCode != undefined ? newItem.eventCode : item.eventCode,
      eventName: newItem.eventName != undefined ? newItem.eventName : item.eventName,
      starterSupported: newItem.starterSupported != undefined ? newItem.starterSupported : item.starterSupported,
      starterDisabled: newItem.starterDisabled != undefined ? newItem.starterDisabled : item.starterDisabled,
      /* vehicleStatusId: newItem.vehiclestatus_id != undefined && newItem.vehiclestatus_id || newItem.vehicleStatusId != undefined && newItem.vehicleStatusId || item.vehicleStatusId, */
      longitude: longitude,
      latitude: latitude,
      engineOdometer: newItem.engineOdometer != undefined && Number(newItem.engineOdometer).toFixed(2) || item.engineOdometer != undefined && Number(item.engineOdometer).toFixed(2) || 0,
      ecuEngineOdometer: newItem.ecuEngineOdometer != undefined && Number(newItem.ecuEngineOdometer).toFixed(2) || item.ecuEngineOdometer != undefined && Number(item.ecuEngineOdometer).toFixed(2) || 0,
      engineHours: newItem.engineHours != undefined && Number(newItem.engineHours).toFixed(2) || item.engineHours != undefined && Number(item.engineHours).toFixed(2) || 0,
      ecuEngineHours: newItem.ecuEngineHours != undefined && Number(newItem.ecuEngineHours).toFixed(2) || item.ecuEngineHours != undefined && Number(item.ecuEngineHours).toFixed(2) || 0,
      dtc: newItem.dtc != undefined && newItem.dtc !== "null" && newItem.dtc || item.dtc,
      deviceLabel: newItem.deviceLabel != undefined ? newItem.deviceLabel : item.deviceLabel,
      heading: newItem.heading != undefined ? newItem.heading : item.heading,
      address: newItem.address != undefined ? getAddress(newItem.address) : getAddress(item.address),
      desiredStarterState: newItem.desiredStarterState != undefined ? getDesiredStarterState(newItem.desiredStarterState) : getDesiredStarterState(item.desiredStarterState),
      serverTime: newItem.serverTime != undefined ? newItem.serverTime : item.serverTime,
      landmarkId: newItem.landmarkId != undefined ? (newItem?.landmarkId || newItem?.landmark_id) : item.landmarkId,
      landmarkname: newItem.landmarkname != undefined ? newItem.landmarkname : item.landmarkname,
      haveLatLng: haveLatLng,
      changeLatLng: !item?.haveLatLng != haveLatLng && haveLatLng,
      temperatures: newItem.temperatures != undefined ? getTemperatures(newItem.temperatures) : getTemperatures(item.temperatures),
      deviceData: newItem.deviceData != undefined ? newItem.deviceData : item.deviceData,
      speed,
      ecuSpeed,
      unitType: newItem.unitType != undefined ? newItem.unitType : item.unitType,
      trail: newItem?.trail && Array.isArray(newItem.trail) ? newItem.trail.map((t) => { return {...t, lng: t.lon, isPlayed : false} }) : item?.trail || [],
      rowId: newItem?.rowId || 0
    }

    //update diferentialUnits
    let unitsDiferential = sessionStorage.getItem("unitsDiferential")
    unitsDiferential = unitsDiferential && JSON.parse(unitsDiferential) || []
    let unitDiferentialIndex = unitsDiferential.findIndex(x => x.deviceId == objItem.deviceId)
    if (unitDiferentialIndex > 0) {
      unitsDiferential[unitDiferentialIndex] = { deviceId: objItem.deviceId, unitTime: objItem.unitTime }
    }
    else {
      unitsDiferential.push({ deviceId: objItem.deviceId, unitTime: objItem.unitTime })
    }
    sessionStorage.setItem("unitsDiferential", JSON.stringify(unitsDiferential))

    return objItem
  }
}

const getAddress = (address) => {
  if (address) {
    if (typeof address === 'string') {
      let n = 4

      const parsedAddress = address.replace(/=/g, '":"').replace('{', '{"').replace('}', '"}').replace(/,/g, s => n ? (n--, '","') : s).replace(/\s/g, '').replace(/'/g, '"').replace(/""/g, '"')

      address = isJson(parsedAddress) && JSON.parse(parsedAddress) || parsedAddress
    }
  }

  return address
}

const getTemperatures = (temperatures) => {
  if (!temperatures) return temperatures;
  if (typeof temperatures != 'string') return temperatures;

  temperatures = temperatures.replace(/=/gi, ":");
  let parsedString = temperatures.replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": ');

  return isJson(parsedString) && JSON.parse(parsedString) || {};
}

const getDesiredStarterState = (desiredStarterState) => {
  if (desiredStarterState) {
    if (typeof desiredStarterState === 'string' && desiredStarterState.indexOf('{') != -1) {

      const parsedState = desiredStarterState.replace(/=/g, '":"').replace('{', '{"').replace('}', '"}').replace(/,/g, '","').replace(/\s/g, '').replace(/'/g, '"').replace(/""/g, '"')

      if (isJson(parsedState)) {
        const stateAsObject = JSON.parse(parsedState)

        if (stateAsObject.state === "true" || stateAsObject.state === "Enable") {
          desiredStarterState = "Enable"
        }
        else if (stateAsObject.state === "false" || stateAsObject.state === "Disable") {
          desiredStarterState = "Disable"
        }
      }

    }
  }

  return desiredStarterState;
}

//if Travel Stop coming at the same time with Ignition Off, Ignore the event Travel Stop
const validateLastEvent = (eventName, oldEventName, eventCode) => {
  if (oldEventName && oldEventName.toLowerCase() == 'ignition off') {
    return validateIgnitionOff(eventCode, eventName)
  }
  return true
}

const validateIgnitionOff = (eventCode, eventName) => {
  return !(eventName.toLowerCase() == 'travel stop' &&
    (eventCode == '5' || eventCode == '23' || eventCode == '-SN' || eventCode == '-SF' ||
      eventCode == '6031' || eventCode == '12' || eventCode == '301' || eventCode == '54'))
}
