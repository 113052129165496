export default {
    scheduleMaintenanceService_vehicle: "Vehicle",
    scheduleMaintenanceService_service: "Service",
    scheduleMaintenanceService_dateOfServiceCompletes: "Date of service completes",
    scheduleMaintenanceService_dateOfLastService: "Date of last service",
    scheduleMaintenanceService_odometer: "Odometer",
    scheduleMaintenanceService_engineHours: "Engine Hours",
    scheduleMaintenanceService_notes: "Notes",
    scheduleMaintenanceService_intervalTypes: "Interval Types",
    scheduleMaintenanceService_servicesNotes: "Services Notes",
    scheduleMaintenanceService_addVehicle: "Add a Vehicle to",
    scheduleMaintenanceService_selectDate: "Select Date",
    scheduleMaintenanceService_servicesAttachment: "Services Attachment",
    scheduleMaintenanceService_serviceSettings: "Service Settings",
    scheduleMaintenanceService_editScheduledServiceFor: "Edit Scheduled Service for",
    scheduleMaintenanceService_scheduleServiceFor: "Schedule Service for",
    scheduleMaintenanceService_editScheduledVehicleFor: "Edit Scheduled Vehicle for",
    scheduleMaintenanceService_addNewService: "Add New Service",
}