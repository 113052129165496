export default {
    accountWebHooks_name: 'Nombre',
    accountWebHooks_url: 'Url',
    accountWebHooks_catalog: 'Tipo',
    accountWebHooks_status: 'Estado',
    accountWebHooks_createdBy: 'Creado Por',
    accountWebHooks_createdOn: 'Creado En',
    accountWebHooks_updatedBy: 'Actualizado Por',
    accountWebHooks_updatedOn:'Actualizado En',
    accountWebHooks_webhooks:'Webhooks',
    accountWebHooks_createEditHeaders:'Encabezados',
    accountWebHooks_createEditSample:'Ejemplo',
    accountWebHooks_createEditKey:'Llave',
    accountWebHooks_createEditValue:'Valor',
    accountWebHooks_deleteWarning:'¿Está seguro de que desea eliminar los siguientes webhooks?',
    accountWebHooks_deleteTitle:'Eliminar WebHooks',
    accountWebHooks_typeCatalog_SystemAlert:'Notificación de alertas',
    accountWebHooks_typeCatalog_LastReading:'Lecturas del dispositivo',
    accountWebHooks_typeCatalog_Driver:'Conductores',
    accountWebHooks_typeCatalog_Unit:'Unidad',
    accountWebHooks_typeCatalog_VehicleInformation:'Información Vehículo',
    accountWebHooks_error_urlFormat:'El formato del campo Url es invalido',
    accountWebHooks_error_headerFields:'Por favor complete los campos Encabezados',
    accountWebHooks_title_create:'Adicionar Webhook',
    accountWebHooks_title_edit:'Editar Webhook',
    accountWebHooks_success: "Exito",
    accountWebHooks_successMessage: "Webhook Eliminado Correctamente",
    accountWebHooks_header_deleteWarning:'¿Está seguro de que desea eliminar el Encabezado?',
    accountWebHooks_header_deleteTitle:'Eliminar Encabezado',
    accountWebHooks_helpField_url:'Este campo proporciona una URL única al servidor API al que se enviarán automáticamente los datos actualizados para el catálogo solicitado',
    accountWebHooks_helpField_catalog:'Este campo proporciona una lista de los tipos de catálogo a los que el webhook puede suscribirse',
    accountWebHooks_helpField_sample:'Es posible obtener algunos datos de este objeto o  todo el objeto.',
    accountWebHooks_Ocurrences: "Ocurrencias",
    accountWebHooks_Errors: "Errores",
    accountWebHooks_Date: "Fecha",
    accountWebHooks_LastMessage: "Ultimo Mensaje",
    accountWebHooks_Latestevents: "Registros",
    accountWebHooks_LogTitle: "Registros Webhook",
    accountWebHooks_ErrorandSuccessRate: "Recuento de errores y tasa de éxito",
    accountWebHooks_NoInvocationsYet: "Aún no hay invocaciones"

};