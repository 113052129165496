import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import {
  DeviceInfo,
  Events,
  Incident,
  LiveVideo,
  Map,
  Title,
  Trails,
} from "./components";
import moment from "moment";
import { validateActiveIncidentByUnit } from "Redux/actions";
import { NotificationManager } from 'Components/Notifications';
import { reset } from "../../redux/slice";
import { useIntl } from "react-intl";
import { HasPermission } from "Core/security"


export const OverView = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();

  const isOpenOverViewUnit = useSelector((state) => state.incidentsRedux.isOpenOverViewUnit);
  const activeIncidentUnitLoading = useSelector((state) => state.incidentsRedux.activeIncidentUnitLoading);
  const activeIncidentUnit = useSelector((state) => state.incidentsRedux.activeIncidentUnit);
  const endIncident = useSelector((state) => state.incidentsRedux.endIncident);
  const loadingEndIncident = useSelector((state) => state.incidentsRedux.loadingEndIncident);
  const user = useSelector((state) => state.securityRedux.user);
  
  const [rangeDate, setRangeDate] = useState({
    start: moment().subtract(24, 'h'),
    end: moment()
  });
  const [activeIncident, setActiveIncident] = useState(false);
  const [incidentData, setIncidentData] = useState();
  const [viewTrails, setViewTrails] = useState(false)
  const [readingSelected, setReadingSelected] = useState(null);
  const [numberSelected, setNumberSelected] = useState()


  const onChangeDateTime = (start, end) => {
    setRangeDate({
      start: moment(start).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      end: moment(end).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    });
  };
  const hasVideo = isOpenOverViewUnit?.data?.unit?.hasVideo && HasPermission(user, 'vidfleetvideo', [217]);
  const timezone = isOpenOverViewUnit?.data?.unit?.timeZone?.name;

  useEffect(() => {

    setViewTrails(false)

    const id = isOpenOverViewUnit?.data?.unit?.id;
    const incident = isOpenOverViewUnit?.data?.incident;

    
    if (incident?.id) {
      setIncidentData(incident);
      setActiveIncident(true);
    }else if(id){
        dispatch(
          validateActiveIncidentByUnit({
            unitId: id
          })
        );
    }
  }, [isOpenOverViewUnit]);

  useEffect(() => {
    if (!activeIncidentUnitLoading && activeIncidentUnit?.id) {
      setIncidentData(activeIncidentUnit);
      return setActiveIncident(true);
    }
  }, [activeIncidentUnitLoading, activeIncidentUnit]);

  useEffect(() => {
    if(!loadingEndIncident && endIncident?.id){
      NotificationManager.success(
        messages["incident_note_success"],
        messages["success"],
        3000,
        null,
        null,
        "success"
      )
      dispatch(reset({
        endIncident: {},
        loadingEndIncident: false,
        isOpenOverViewUnit: {
          open: false,
          data: {},
        },
        trailsIncident: [],
        trailsIncidentTotal: 0,
        avMediaIncident: [],
        avMediaIncidentTotal: 0,
      }))
      
    }
  }, [endIncident, loadingEndIncident])

  if (!isOpenOverViewUnit?.open) {
    return null;
  }

  const clearFields = () => {
    setActiveIncident(false)
    setIncidentData()
    // setRangeDate({
    //     start: moment().startOf('day'),
    //     end: moment().endOf('day')
    // })
  }

  return (
    <div className="incident_modal-overlay">
      <div
        className="incident_modal-body"
        style={{ width: hasVideo ? "100%" : "75%", textAlign: 'left' }}
      >
        <Title clearFields={clearFields} activeIncident={activeIncident} incidentData={incidentData}/>
        <div className="incident_liveVideo_map" style={{height: activeIncident ? '250px' : '300px'}}>
          {hasVideo && HasPermission(user, '', [235]) && <LiveVideo />}
          <Map 
            activeIncident={activeIncident}
            viewTrails={viewTrails}
            setReadingSelected={setReadingSelected}
            setNumberSelected={setNumberSelected}
          />
        </div>
        <div className="incident_container_overView">
          {hasVideo && (
            <Events rangeDate={rangeDate} onChangeDateTime={onChangeDateTime} activeIncident={activeIncident} startIncident={incidentData?.start}/>
          )}
          <Trails 
            viewTrails={viewTrails} 
            setViewTrails={setViewTrails} 
            rangeDate={rangeDate} 
            onChangeDateTime={onChangeDateTime} 
            activeIncident={activeIncident} 
            startIncident={incidentData?.start}
            setNumberSelected={setNumberSelected}
          />
          <div style={{width: '50%'}}>
            <DeviceInfo numberSelected={numberSelected}  readingSelected={readingSelected} viewTrails={viewTrails}/>
            {
              activeIncident && (
                <Incident hasVideo={hasVideo} rangeDate={rangeDate} data={incidentData} timezone={timezone} clearFields={clearFields}/>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
