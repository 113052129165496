export default {
    mapLandmarkDeleteLandmark_title:'Delete Landmarks',
    mapLandmarkDeleteLandmark_detail:'Are you sure you want to delete the following landmarks?', //Replace number value
    mapLandmarkDeleteLandmark_detail_one:'Are you sure you want to delete the following landmark?', //Replace number value
    mapLandmarkDeleteLandmark_warning:'This action cannot be undone.',
    mapLandmarkDeleteLandmark_success:'Landmarks deleted',
    mapLandmarkDeleteLandmark_success_one:'Landmark deleted',
    mapLandmarkDeleteLandmark_remove_all_landmark_keyword: 'all Landmarks',
    mapLandmarkDeleteLandmark_remove_all: 'Are you sure to delete',
    mapLandmarkDeleteLandmark_remove_all_except: 'except this list'
    



}