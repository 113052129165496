import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import {
  getTimeZone
} from 'Modules/reports/views/main/components/reportsTab/helper';

// Actions
import {
  reportsMainGenerateReportProgress,
  reportsMainGenerateReportUpdate
} from 'Redux/actions';


function* getMileageReport(report, event) {
  const {
    filterType,
    filterArray,
    page,
    rowsPerPage,
    sortField,
    sortOrder,
    startRange,
    endRange,
  } = event.filters;
  const { timeZone, name,reportTimeRanges } = report;

  try {
    let data = yield call(
      clientQuery,
      `query getMileageReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getMileageReport (conditions: $conditions, limit: $limit, offset: $offset) {
            items {
              address
              deviceId
              deviceLabel
              driverId
              driverName
              unitId
              distance
              distanceDelta
              eventName
              landmarkId
              landmarkName
              longitude
              latitude
              unitTime
              groupId
              groupName
              ecuOdometer
              odometer
              isKPH
            }
            summaryInfo {
              id
              totalDistance
            }
            total
            limit
            offset
            category
        }
      }`,
      {
        conditions: {
          reportName: name,
          timeZone: getTimeZone(timeZone),
          category: {
            field: filterType,
            value: filterArray
          },
          dateRange: {
            fields: [
              "unitTime"
            ],
            GTE: startRange,
            LTE: endRange,
            reportTimeRanges
          },
          sortField: {
            field: sortField || "deviceId",
            order: sortOrder === 'desc' ? 'DESC' : 'ASC'
          }
        },
        limit: rowsPerPage,
        offset: page
      },
      endpoints.GRAPHQL_PX_REPORTING,
    );

    if (data && data.Report) {
      report.events = data.Report
      report.events.filters = event.filters
      report.loadingSubEvents = false
      yield put(reportsMainGenerateReportProgress(report))
    } else {
      report.loadingSubEvents = false
      yield put(reportsMainGenerateReportProgress(report))
    }

  } catch (exc) {
    report.loadingSubEvents = false
    delete report.export
    yield put(reportsMainGenerateReportProgress(report))
  }
}

export default getMileageReport;
