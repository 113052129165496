const locales = {
    dashboardPanel_title: "Panel",
    dashboardPanel_mutiple_title: "PANELES",
    dashboardPanel_preview: "Vista Anticipada",
    dashboardPanel_add_gadget: "Agregar Gadget",
    dashboardPanel_edit_gadget: "Editar Gadget ",
    dashboardPanel_edit_info: "En este momento, estás editando tu dashboard. Los cambios se guardarán automáticamente.",
    dashboardPanel_edit_frequency: "Frecuencia de actualización",
    dashboardPanel_edit_title: "Título",
    dashboardPanel_edit_minutes: "Minutos",
    dashboardPanel_edit_hours: "Hora",
    dashboardPanel_edit_days: "Día",
    dashboardPanel_edit_report_type: "Tipo de reporte",
    dashboardPanel_edit_chart_type: "Tipo de gráfica",
    dashboardPanel_edit_groups_unit: "Comparar unidades",
    dashboardPanel_edit_time_metric: "Métrica de tiempo",
    dashboardPanel_edit_IdleTime: 'Tiempo de inactividad',
    dashboardPanel_edit_IdleStop: 'Tiempo detenido',
    dashboardPanel_edit_DriveTime: 'Tiempo de conduccion',
    dashboardPanel_edit_DriverStop: 'Tiempo parado del conductor',
    dashboardPanel_edit_Speeding: 'Exceso de velocidad',
    dashboardPanel_edit_Mileage: 'Kilometraje',
    dashboardPanel_edit_EngineTime: 'Tiempo del motor',
    dashboardPanel_edit_DriverOverview: 'Resumen conductor',
    dashboardPanel_edit_DriverSafety: 'Seguridad del conductor',
    dashboardPanel_edit_allUnits: 'Todas las unidades',
    dashboardPanel_edit_singleGroup: 'Etiqueta única',
    dashboardPanel_edit_compareGroup: 'Compararar Etiquetas',
    dashboardPanel_edit_month: 'Mes',
    dashboardPanel_edit_week: 'Semana',
    dashboardPanel_edit_quarter: 'Trimestre',
    dashboardPanel_edit_chart_pie: 'Circular',
    dashboardPanel_edit_chart_bar: 'Barras',
    dashboardPanel_edit_device_offline: 'Dispositivos fuera de línea',
    dashboardPanel_edit_chart: 'Gráficas',
    dashboardPanel_edit_fleet: "Dispositivos Categorias",
    dashboardPanel_offline_thresholdMinutes: "Tiempo de búsqueda",
    dashboardPanel_edit_group: "Grupo",
    dashboardPanel_edit_tag: "Etiqueta",
    dashboardPanel_edit_title_offline_device: 'Dispositivos fuera de línea',
    dashboardPanel_edit_description_offline_device: 'Muestra una lista filtrable de dispositivos que están fuera de línea',
    dashboardPanel_edit_title_chart: 'Gráficas',
    dashboardPanel_edit_description_chart: 'Gráficos circulares o de columnas personalizables para resumir los datos del informe para las unidades seleccionadas',
    dashboardPanel_edit_title_landmark_visit: 'Visita en punto de referencia',
    dashboardPanel_edit_description_landmark_visit:'Muestra una lista de Landmarks, un recuento de visitas y el tiempo total que las unidades han pasado dentro de un Landmark.',
    dashboardPanel_edit_title_landmark_real_time: 'Punto de referencia en tiempo real',
    dashboardPanel_edit_description_landmark_real_time:'Muestra una lista de los puntos de referencia que están siendo visitados y los vehículos que se encuentran en ellos.',
    dashboardPanel_edit_title_recent_alerts: 'Alertas Recientes',
    dashboardPanel_edit_description_recent_alerts: 'Muestra una lista filtrable de alertas activadas recientemente.',
    dashboardPanel_edit_view: 'Vista',
    dashboardPanel_edit_unit_count: 'Numero de unidades',
    dashboardPanel_edit_percentage: 'Como porcentaje',
    dashboardPanel_edit_highest: 'Valores más altos',
    dashboardPanel_edit_lowest: 'Valores más bajos',
    dashboardPanel_edit_average: 'Mostrar promedio',
    dashboardPanel_edit_confirmation_message_create_dashboard_title: "Crear Panel",
    dashboardPanel_edit_confirmation_message_rename_dashboard_title: "Renombrar Panel",
    dashboardPanel_edit_confirmation_message_delete_dashboard_title: "Borrar Panel",
    dashboardPanel_edit_confirmation_message_save_dashboard_title: "Editar Panel",
    dashboardPanel_edit_confirmation_message_create_dashboard_description: "El panel fue creado exitosamente",
    dashboardPanel_edit_confirmation_message_rename_dashboard_description: "El panel fue renombrado exitosamente",
    dashboardPanel_edit_confirmation_message_delete_dashboard_description: "El panel fue creado exitosamente",
    dashboardPanel_edit_confirmation_message_save_dashboard_description: "Cambios guardados exitosamente",
    dashboardPanel_name: "Nombre del panel",
    dashboardPanel_rename: "Renombrar Panel",
    dashboardPanel_delete: "Eliminar Panel",
    dashboardPanel_title_new: "Nuevo Panel",
    dashboardPanel_title_edit: "Editar Panel",
    dashboardPanel_chart_label_Mon: "Lun",
    dashboardPanel_chart_label_Tue: "Mar",
    dashboardPanel_chart_label_Wed: "Mie",
    dashboardPanel_chart_label_Thu: "Jue",
    dashboardPanel_chart_label_Fri: "Vie",
    dashboardPanel_chart_label_Sat: "Sab",
    dashboardPanel_chart_label_Sun: "Dom",
    dashboardPanel_chart_label_Jan: "Ene",
    dashboardPanel_chart_label_Feb: "Feb",
    dashboardPanel_chart_label_Mar: "Mar",
    dashboardPanel_chart_label_Apr: "Abr",
    dashboardPanel_chart_label_May: "May",
    dashboardPanel_chart_label_Jun: "Jun",
    dashboardPanel_chart_label_Jul: "Jul",
    dashboardPanel_chart_label_Aug: "Ago",
    dashboardPanel_chart_label_Sep: "Sep",
    dashboardPanel_chart_label_Oct: "Oct",
    dashboardPanel_chart_label_Nov: "Nov",
    dashboardPanel_chart_label_Dec: "Dic",
    dashboardPanel_chart_label_Week: "Semana",
    dashboardPanel_date: "Fecha",
    dashboardPanel_nameRegister: "Nombre",
    dashboardPanel_events: "Eventos",
    dashboardPanel_to: "Hasta",
    dashboardPanel_Week: "S",
    dashboardPanel_Average: "Promedio",
    dashboardPanel_delete_title: "¿Estás seguro  que deseas eliminar este gadget?",
    dashboardPanel_delete_dashboard: "¿Estás seguro  que deseas eliminar este Panel?",
    dashboardPanel_edit_show_labels: 'Mostrar etiquetas de unidad',
    dashboardPanel_gadget_name: "Nombre del dispositivo",
    dashboardPanel_gadget_name_instruction: "Nombre del dispositivo",
    dashboardPanel_recent_alert_type: "Tipo/Etiqueta",
    dashboardPanel_recent_alert_unit: "Unidad",
    dashboardPanel_recent_alert_time: "Tiempo",
    dashboardPanel_recent_alerts_time_frame: "Periodo de tiempo",
    dashboardPanel_recent_alerts_type_label: "Seleccione un tipo de alerta",
    dashboardPanel_recent_alerts_time_frame_today: "Hoy",
    dashboardPanel_recent_alerts_time_frame_yesterday: "Ayer",
    dashboardPanel_recent_alerts_time_frame_last_7_days: "Ultimos 7 dias",
    dashboardPanel_recent_alerts_time_frame_last_30_days: "Ultimos 30 dias",
    dashboardPanel_recent_alerts_time_frame_custom: "Personalizado",
    dashboardPanel_recent_alerts_type: "Tipo de alerta",
    dashboardPanel_recent_all_alerts_type: "- Todos los tipos de alerta -",
    dashboardPanel_recent_alerts_driver: "Conductor(es)",
    dashboardPanel_recent_alerts_unit: "Unidad(es)",
    dashboardPanel_recent_alerts_view_by: "Ver alertas recientes por",
    dashboardPanel_recent_alerts_run_for: "Ejecutar alertas recientes para",
    dashboardPanel_pagination_button_next: "siguiente",
    dashboardPanel_pagination_button_previous: "anterior",
    dashboardPanel_user_gadget_edited: "Cambios guardados exitosamente",
    dashboardPanel_user_gadget_delete: "El Gadget ha sido eliminado exitosamente",
    dashboardPanel_user_gadget_created: "El Gadget ha sido creado exitosamente",
    dashboardPanel_user_gadget_edit_title: "Editar Gadget",
    dashboardPanel_user_gadget_create_title: "Crear Gadget",
    dashboardPanel_user_gadget_delete_title: "Eliminar Gadget",
    dashboardPanel_user_gadget_save: "Se guardaron los cambios satisfactoriamente",
    dashboardPanel_edit_today: 'Hoy',
    dashboardPanel_edit_yesterday: 'Ayer',
    dashboardPanel_edit_this_week: 'Esta semana',
    dashboardPanel_edit_this_month: 'Este mes',
    dashboardPanel_edit_include_time_total: 'Incluir tiempo total',
    dashboardPanel_edit_yes: 'Si',
    dashboardPanel_edit_no: 'No',
    dashboardPanel_landmarks: 'Puntos de referencia',
    dashboardPanel_select_all: 'Seleccionar todos',
    dashboardPanel_landmark: 'Punto de referencia',
    dashboardPanel_Visits: 'Visitas',
    dashboardPanel_Num_Vehicles: 'Número de Vehículos',
    dashboardPanel_Total_Time:'Tiempo Total',
    dashboardPanel_Idle_Time: 'Tiempo de Inactividad',
     dashboardPanel_vehicle: 'Vehículo',
     dashboardPanel_order_By: 'Ordenar Por',
    dashboardPanel_number_vehicle_asc: 'Número de Vehículos Asc',
    dashboardPanel_number_vehicle_desc: 'Número de Vehículos Desc',
    dashboardPanel_number_visits_asc: 'Número de Visitas Asc',
    dashboardPanel_number_visits_desc: 'Número de Visitas Desc',
    dashboardPanel_total_time_asc: 'Tiempo Total Asc',
    dashboardPanel_total_time_desc: 'Tiempo Total Desc',
    dashboardPanel_landmark_name_asc: 'Nombre del Punto de Referencia A-Z',
    dashboardPanel_landmark_name_desc: 'Nombre del Punto de Referencia Z-A',
     dashboardPanel_order: 'Ordenar',
     dashboardPanel_vehicles: 'Vehículos',
     order: "Ordenar"
};

export default locales;
