//imports
import {
	GET_USER_INFO,
	GET_USER_INFO_RESULT,
	REFRESH_TOKEN,
	REFRESH_TOKEN_RESULT,
	GET_SYSTEM_PREF_RESULT,
	GET_ACCESS_ELD_APOLLO,
	GET_ACCESS_ELD_APOLLO_RESULT,
	CHANGE_SALESFORCE_STATUS,
	CHANGE_SALESFORCE_STATUS_RESULT,
	LOGOUT
} from 'Redux/actionTypes';
/* ACTIONS */

export const getUserInfo = (navigate) => {
	return {
		type: GET_USER_INFO,
		payload: { navigate }
	}
};

export const getUserInfoResult = (user) => {
	return {
		type: GET_USER_INFO_RESULT,
		payload: user,
	}
};

export const getSystemPrefResult = (data) => {
	return {
		type: GET_SYSTEM_PREF_RESULT,
		payload: data,
	}
};

export const logout = (navigate, current_reseller, type_reseller) => {
	return {
		type: LOGOUT,
		payload: { navigate, current_reseller, type_reseller },
	}
};

export const refreshToken = () => {
	return {
		type: REFRESH_TOKEN,
	}
};


export const refreshTokenResult = (result = true) => {
	return {
		type: REFRESH_TOKEN_RESULT,
		payload: result
	}
};

export const getAccessEldApollo = (data) => {
	return {
		type: GET_ACCESS_ELD_APOLLO,
		payload: data
	}
};


export const getAccessEldApolloResult = (result) => {
	return {
		type: GET_ACCESS_ELD_APOLLO_RESULT,
		payload: result
	}
};

export const changeSalesForceStatus = (state) => {
	return {
		type: CHANGE_SALESFORCE_STATUS,
		payload: state
	}
};


export const changeSalesForceStatusResult = (result) => {
	return {
		type: CHANGE_SALESFORCE_STATUS_RESULT,
		payload: result
	}
};