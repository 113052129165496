import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';

// Helpers
import {
  getTimeZone,
  handleAsyncReport,
  generateReportInstance,
  baseReportConditions,
  isJsonFormat,
  getCategoryFilter
} from 'Modules/reports/views/main/components/reportsTab/helper';

// Actions
import {
  reportsMainGenerateReportProgress
} from 'Redux/actions';
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

function* getMileageGroupedByIdReport(report, page, rowsPerPage, sortField, sortOrder) {
  const { timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath } = report;
  const { filterType, filterArray, startRange, endRange, unitsSelected, accumulateData = true, dates } = report.filters
  const baseConditionsQuery = baseReportConditions({ report, name, dataResultPath, timeZone, delayed, unitsSelected, timeframeName , filterType, filterArray, categoryName, startRange, endRange, dates})
  try {
    let payload = {
      conditions: {
        ...baseConditionsQuery,
        sortField: {
          field: sortField || "deviceId",
          order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
      },
      limit: rowsPerPage,
      offset: page
    }

    if (report.export) {
      let addMainColumnEntity =[ {
        column: getCategoryFilter(filterType) === "deviceId" ? "deviceLabel": "driverName",
        header: getCategoryFilter(filterType) === "deviceId" ? "Unit" : "Driver"
      }];

      let addColumnSubEntity = [{
        column: getCategoryFilter(filterType) === "deviceId" ? "driverName": "deviceLabel",
        header: getCategoryFilter(filterType) === "deviceId" ? "Driver" : "Unit"
      }];

      let columns = []

      if(report?.exportType === 'pdf') {
        columns = [
        ...addColumnSubEntity,
        {
          column: "unitTime",
          header: "Time/Date"
        },
        {
          column: "address",
          header: "Address"
        },
        {
          column: "odometer",
          header: "Odometer"
        },
        {
          column: "distanceDelta",
          header: "Distance"
        },
        {
          column: "distance",
          header: "Total Distance"
        }]
      } else if(report?.exportType === 'csv') {
        columns = [
          ...addMainColumnEntity,
          {
            column: "unitTime",
            header: `${report?.exportType === "csv" ? `Time/Date (${getAbbvTimezone(timeZone)})` : `Time/Date`}`
          },
          {
            column: "address",
            header: "Address"
          },
          {
            column: "distanceDelta",
            header: "Distance"
          },
          {
            column: "distance",
            header: "Total Distance"
          },
          {
            column: "odometer",
            header: "Odometer"
          },
          ...addColumnSubEntity
        ]
      }
      payload = {
        ...payload,
        limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 10000,
        offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
        conditions: {
          ...payload.conditions,
          exportOptions: {
            sendByEmail,
            emailsTo,
            format: {
              fileFormat: report?.exportType  || "json",
              formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
              durationFormat: report?.durationFormat
            },
            culture: report.lang,
            timeZone: getTimeZone(timeZone),
            folderName: "px-customer-reports",
            filePrefixName: "mileageReport",
            columns
          }
        }
      }
    }

    let data = yield call(
      clientQuery,
      `query getMileageReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getMileageReport (conditions: $conditions, limit: $limit, offset: $offset) {
          ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ? `
              reportInstanceId
            `:
            `
            items {
              recordInfo {
                entityId
                entityLabel
                tagId
                tagLabel
                startDate
                endDate
                recordType
                groupLabel
                groupIndex
                groupTotal
                groupTagColor
              }
              recordDetail {
                address
                deviceId
                deviceLabel
                driverId
                driverName
                unitId
                distance
                distanceDelta
                eventName
                landmarkId
                landmarkName
                longitude
                latitude
                unitTime
                groupId
                groupName
                ecuOdometer
                odometer
                isKPH
                useEcuOdometer
              }
            }
            total
            limit
            offset
            category
          `}
        }
      }`,
      payload,
      endpoints.GRAPHQL_PX_REPORTING
    );
    yield handleAsyncReport(report, data, accumulateData);
  } catch (exc) {
    report.loading = false
    delete report.export
    yield put(reportsMainGenerateReportProgress(report))
  }
}

export default getMileageGroupedByIdReport;
