import {
  //TYPES
  REALTIME_MAP_LAYER_MAP_GET,
  REALTIME_MAP_LAYER_MAP_GET_RESPONSE,
  REALTIME_MAP_LAYER_ROUTES_GET,
  REALTIME_MAP_LAYER_ROUTES_GET_RESPONSE,
  REALTIME_MAP_LAYER_ROUTE_GET,
  REALTIME_MAP_LAYER_ROUTE_GET_RESPONSE,
  REALTIME_MAP_LAYER_ROUTE_REMOVE,
  REALTIME_MAP_LAYER_CLEAR_LAYERS
} from 'Redux/actionTypes';

export const getVehicleTrailLayerMap = (params, startingQuery = true) => {
  return {
    type: REALTIME_MAP_LAYER_MAP_GET,
    payload: { params: params, startingQuery: startingQuery }
  };
}

export const getVehicleTrailLayerMapResult = (result, error) => ({
  type: REALTIME_MAP_LAYER_MAP_GET_RESPONSE,
  payload: { result, error },
});

export const getRoutesLayerMap = (data) => {
  return {
    type: REALTIME_MAP_LAYER_ROUTES_GET,
    payload: { data }
  };
}

export const getRoutesResponseLayerMap = (data) => ({
  type: REALTIME_MAP_LAYER_ROUTES_GET_RESPONSE,
  payload: { data },
});

export const getRouteLayerMap = (id) => {
  return {
    type: REALTIME_MAP_LAYER_ROUTE_GET,
    payload: { id }
  };
}

export const removeRouteLayerMap = (id) => {
  return {
    type: REALTIME_MAP_LAYER_ROUTE_REMOVE,
    payload: { id }
  };
}

export const getRouteResponseLayerMap = (data) => ({
  type: REALTIME_MAP_LAYER_ROUTE_GET_RESPONSE,
  payload: { data },
});

export const clearLayers = () => ({
  type: REALTIME_MAP_LAYER_CLEAR_LAYERS
});