export default {
    formFtp_newAdd: 'Add New FTP Server',
    formFtp_ftpServer: 'FTP Server',
    formFtp_host: 'Host',
    formFtp_protocol: 'Protocol',
    formFtp_userName: 'Username',
    formFtp_password: 'Password',
    formFtp_port: 'Port',
    formFtp_basePath: 'Base Path',
    formFtp_cancel: 'Cancel',
    formFtp_delete: 'Delete FTP Server',
    formFtp_saveFtpServer: 'Save FTP Server',
    formFtp_saveChanges: 'Save Changes',
    formFtp_success: 'FTP has been successfully registered.',
    formFtp_successDelete: 'FTP has been successfully deleted',
    formFtp_errorHostDuplicate: 'Duplicate FTP server, change it and try again.',
    formFtp_error: 'An error occurred try again later',
    formFtp_Label: 'Select FTP Server'
}
