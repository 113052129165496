import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';
// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

import {baseReportConditions, generateReportInstance, getTimeZone, handleAsyncReport, isJsonFormat} from 'Modules/reports/views/main/components/reportsTab/helper';
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

function* getDriverTimesheetReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
    try {
        const {timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath} = report;
        const {filterType, filterArray, startRange, endRange, unitsSelected, accumulateData = true, dates, otherFilters, filterEntity} = report.filters
        const baseConditionsQuery = baseReportConditions({
            report, name, dataResultPath, timeZone, delayed, unitsSelected, timeframeName, filterType, filterArray, categoryName, startRange, endRange, dates
        })

        let payload = {
            conditions: {
                ...baseConditionsQuery,
                sortField: {
                    field: sortField || "deviceId",
                    order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                }
            },
            limit: rowsPerPage,
            offset: page
        };

        if (report.export) {
            const addColumnDriver = (
                report?.exportType === 'csv' ?
                    [...filterEntity === 'Unit' ?
                        [{
                            "column": "idLabel", "header": 'Unit'
                        }, {
                            "column": "driverName", "header": "Driver"
                        },] :
                        [{
                            "column": "idLabel", "header": "Driver"
                        }, {
                            "column": "deviceLabel", "header": 'Unit'
                        }]
                    ] : [
                        {
                            "column": filterEntity === 'Unit' ? "driverName" : "idLabel",
                            "header": filterEntity === 'Unit' ? "Driver" : "Unit",
                        }
                    ])
            payload = {
                ...payload, limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000, offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
                conditions: {
                    ...payload.conditions,
                    exportOptions: {
                        sendByEmail,
                        emailsTo,
                        format: {
                            fileFormat: report?.exportType || "json", formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
                            durationFormat: report?.durationFormat
                        },
                        culture: report.lang,
                        timeZone: getTimeZone(timeZone),
                        folderName: "px-customer-reports",
                        filePrefixName: "driverTimesheetReport",
                        columns: [
                            ...addColumnDriver, ...(filterEntity === 'Unit') ?
                                [{
                                    "column": "status",
                                    "header": "Status"
                                }] : [],
                            {
                                "column": "dateIn",
                                header: `${report?.exportType === "csv" ? `In (${getAbbvTimezone(timeZone)})` : `In`}`
                            },
                            {
                                "column": "dateOut",
                                header: `${report?.exportType === "csv" ? `Out (${getAbbvTimezone(timeZone)})` : `Out`}`
                            },
                            {
                                "column": "duration",
                                "header": "Time"
                            }
                        ]
                    }
                }
            }
        }
        let data = yield call(
            clientQuery,
            `query getDriverTimesheetReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
                Report: getDriverTimesheetReport (conditions: $conditions, limit: $limit, offset: $offset) {
                        ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ? `reportInstanceId` : `
                        items {
                            recordInfo {
                                entityId
                                entityLabel
                                tagId
                                tagLabel
                                startDate
                                endDate
                                recordType
                                groupLabel
                                groupIndex
                                groupTotal
                                groupTagColor
                            }
                            recordDetail {
                                id
                                idLabel
                                deviceId
                                deviceLabel
                                driverId
                                driverName
                                status
                                dateIn
                                dateOut
                                duration
                                totalDuration
                                noData
                            }
                        }
                        total
                        limit
                        offset
                        category
                        reportInstanceId
                        `}
                    }
                }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
        yield handleAsyncReport(report, data, accumulateData);
    } catch (exc) {
        ExceptionManager(exc, 'reports/driverTimesheetReport', 'getDriverTimesheetReport');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}

export default getDriverTimesheetReport;
