// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";

// Action Types
import {
  VIDEO_MAIN_EVENTS_SEARCH_AVAILABLE_MEDIA,
  VIDEO_MAIN_EVENTS_RESTORE_AVAILABLE_MEDIA
} from 'Redux/actionTypes';

// Actions
import {
  searchAvailableMediaResponse,
  restoreAvailableMediaResponse
} from 'Redux/actions';

// Helpers
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';

function* searchAvailableMediaRequest({ payload }) {
  const {
    limit,
    dateRange,
    openSearch,
    resetSearch,
    offset,
    starred,
    entities,
    hideTableLoading,
    events,
    mediaDeleted,
    mediaType,
    reviewStatus,
    hasNotes,
  } = payload;

  const query = `
query searchVideoReadings ($openSearch: String, $entities: [EntityInput]!, $dateRange: AWSDateTimeRange!, $eventNames: [String], $hasNotes: Boolean, $starred: Boolean, $mediaDeleted: Boolean, $mediaType: enumMediaType, $reviewStatus: enumVideoReviewStatus, $limit: Int, $offset: Int) {
    searchVideoReadings (openSearch: $openSearch, entities: $entities, dateRange: $dateRange, eventNames: $eventNames, hasNotes: $hasNotes, starred: $starred, mediaDeleted: $mediaDeleted, mediaType: $mediaType, reviewStatus: $reviewStatus, limit: $limit, offset: $offset) {
        items {
            reading {
                id
                deviceId
                heading
                eventCode
                eventName
                driverId
                driverName
                unitTime
                deviceData
                starred
                notes
                speed
                normalizedSpeed
                ecuSpeed
                unitType
                formattedAddress
                speedLimit
            }
            unit {
                id
                label
                type
                hid
                esn
                imei
                driverId
                driverName
                notes
                useEcuSpeed
                timeZone {
                  id
                  name
                }
                tags {
                  id
                  label
                  color
                }
            }
            linkedDevice {
              id
              esn
              deviceModelId
              deviceModelName
              manufacturerId
              manufacturerName
              hasVideo
            }
          }
        total
    }
}`
  const variables = {
    openSearch,
    limit,
    offset,
    dateRange,
    entities: !entities || !entities?.length ? [{ name: 'All', id: -1 }] : entities,
    mediaDeleted: mediaDeleted || false,
    mediaType: mediaType || "All",
    reviewStatus: (!reviewStatus || reviewStatus === "All" ? null : reviewStatus),
    starred: starred || false,
    eventNames: events?.length ? events : [],
    hasNotes: hasNotes || false,
  };

  try {
    const data = yield call(clientQuery, query, variables, endpoints.GRAPHQL_GENERAL);
    if (data) {
      const { searchVideoReadings: { items, total } } = data;
      yield put(searchAvailableMediaResponse({ items, total, openSearch, resetSearch, hideTableLoading: hideTableLoading, error: false, mediaDeleted, starred }));
    } else {
      throw 'Error while searching events video module';
    }
  } catch (exc) {
    yield put(searchAvailableMediaResponse({ 
      items: [], 
      total: 0,
      resetSearch: true, 
      error: true 
    }));
    ExceptionManager(
      exc,
      'modules/video/views/main/redux/sagas',
      'searchAvailableMedia',
    );
  }
}

function* restoreAvailableMediaRequest({ payload }) {
  try {
    const { data } = payload;
    const ids = [];
    const devicesId = [] 
    data?.forEach(item => {
      ids.push(item.id)
      devicesId.push(item.deviceId)
    });
    const res = yield call(
      clientMutation,
      `mutation restoreAvailableMedia ($rowsId: [String]!, $devicesId: [String]!) {
        restoreAvailableMedia (rowsId: $rowsId, devicesId: $devicesId)
      }`,
    {
      rowsId : ids,
      devicesId: devicesId
      },
      endpoints.GRAPHQL_GENERAL,
    );

    if (res && res.restoreAvailableMedia) {
      yield put(restoreAvailableMediaResponse(res.restoreAvailableMedia));
    } else {
      throw 'Error while restoring available media';
    }
  } catch (exc) {
    yield put(restoreAvailableMediaResponse({ error: true }));
    ExceptionManager(
      exc,
      'modules/video/views/main/.components/events/redux/sagas',
      'restoreAvailableMedia',
    );
  }
}

function* watchMethods() {
  yield takeEvery(VIDEO_MAIN_EVENTS_SEARCH_AVAILABLE_MEDIA, searchAvailableMediaRequest);
  yield takeEvery(VIDEO_MAIN_EVENTS_RESTORE_AVAILABLE_MEDIA, restoreAvailableMediaRequest);
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}