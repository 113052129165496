export default {
    "no_notifications_message":"You have no notifications",
    "user_notifications_title":"Alerts",
    "user_alerts_latest_title":"Latest Alerts",
    "user_alerts_marked_title":"Mark all as read",
    "user_alerts_low_title":"Low",
    "user_alerts_medium_title":"Medium",
    "user_alerts_high_title":"High",
    "user_alerts_today":"Today",
    "user_alerts_yesterday":"Yesterday",
    "user_alerts_view_all":"View All"
}