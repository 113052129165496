import {
  REPORTS_MAIN_LIST_SHOW_REPORTFILTER,
  REPORTS_MAIN_LIST_HIDE_REPORTFILTER,
  CLEAR_REDUX
} from "Redux/actionTypes";

//STATE INITIAL
const INIT_STATE = {
  showReportSetup: false,
  data: null,
};

export default (state = INIT_STATE, action) => {
  //let reportIndex
  switch (action.type) {

    case REPORTS_MAIN_LIST_SHOW_REPORTFILTER:
      return { ...state, showReportSetup: true, data: action.payload };
    case REPORTS_MAIN_LIST_HIDE_REPORTFILTER:
      return { ...state, showReportSetup: false, data: action.payload};

    case CLEAR_REDUX:
      return ["", "REPORTS_MAIN_ST"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
};
