// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    MAP_GEOFENCES_GET_GEOFENCE_GROUP,
    MAP_GEOFENCES_EDIT_CREATE_GEOFENCE_GROUP
} from "Redux/actionTypes";

// Actions
import {
    mapGeofencesGetGeofenceGroupResponse,
    mapGeofencesEditCreateGeofenceGroupResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* mapGeofencesGetGeofenceGroupRequest({ payload }) {
    try {
        const { id } = payload;

        const response = yield call(
            clientQuery,
            `
                query getGeofenceGroup ($id: Int!) {
                    GeofenceGroup: getGeofenceGroup (id: $id) {
                        id
                        name
                        color
                    }
                }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );

        if (!response && !response.GeofenceGroup) throw `Error while getting landmark group: ${id}`;

        yield put(mapGeofencesGetGeofenceGroupResponse({ ...response.GeofenceGroup, error: false }));
    } catch (exc) {
        yield put(mapGeofencesGetGeofenceGroupResponse({ error: true }));
        ExceptionManager(
            exc,
            "realtimeMaps/main/components/geofences/components/geofenceGroupCreateEdit/redux/saga",
            "mapGeofencesGetGeofenceGroupRequest"
        );
    }
}

function* mapGeofencesCreateEditGeofenceGroupRequest({ payload }) {
    try {
        const { geofenceGroup } = payload;

        let query = `
            mutation createGeofenceGroup (
                $name: String!, 
                $color: String!
            ) {
                GeofenceGroup: createGeofenceGroup (
                    name: $name, 
                    color: $color
                ) {
                    id
                    name
                    color
                }
            } 
        `;

        if (geofenceGroup?.id) {
            query = `
                mutation updateGeofenceGroup (
                    $id: Int!, 
                    $name: String, 
                    $color: String
                ) {
                    GeofenceGroup: updateGeofenceGroup (
                        id: $id, 
                        name: $name, 
                        color: $color
                    ) {
                        id
                        name
                        color
                    }
                } 
            `;
        }

        const response = yield call(
            clientMutation,
            query,
            geofenceGroup,
            endpoints.GRAPHQL_GENERAL,
        );

        if (!response || !response.GeofenceGroup) throw `Error while creating/editing landmark groups: ${geofenceGroup}`;

        yield put(mapGeofencesEditCreateGeofenceGroupResponse({ ...response.GeofenceGroup, error: false }));
    } catch (exc) {
        let message = null
        if (exc.errorData && exc.errorData.errors) {
            exc.errorData.errors.forEach(error => {
                if (error.errorInfo && error.errorInfo.code) {
                    message = error.message
                }
            });
        }

        yield put(mapGeofencesEditCreateGeofenceGroupResponse({ error: true, message: message }));
    }
}

function* watchMapLandmarkGroupsMethods() {
    yield takeEvery(MAP_GEOFENCES_GET_GEOFENCE_GROUP, mapGeofencesGetGeofenceGroupRequest);
    yield takeEvery(MAP_GEOFENCES_EDIT_CREATE_GEOFENCE_GROUP, mapGeofencesCreateEditGeofenceGroupRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMapLandmarkGroupsMethods)]);
}