import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    driverTasks: [],
    deleteResponse: null,
    error: null,
};

const route = "modules/maintenance/modals/manageTasks/redux/slice";

export const maintenanceMMTSearchMaintenanceDriverTasks = createAsyncThunk("maintenance/manageTasks/searchMaintenanceDriverTasks", async ({ name }, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientQuery(
            `
            {
                data: searchMaintenanceDriverTasks(openSearch: "${name}"){
                    id
                    name
                    description
                    frequency
                    estimatedCost
                    timeUnit
                    fileIds
                    files {
                        id
                        originalName
                        fileKey
                        repositoryCode
                    }
                }
            }
            `,
            {},
            endpoints.GRAPHQL_GENERAL
        );
        return response?.data || []
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceMMTSearchMaintenanceDriverTasks');
        return rejectWithValue(exc);
    }
})

export const maintenanceMMTDeleteMaintenanceDriverTask = createAsyncThunk("maintenance/manageTasks/deleteMaintenanceDriverTask", async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientMutation(
            `
                mutation ($id: Int!) {
                    data: deleteMaintenanceDriverTask (
                        id: $id
                    )
                }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );
        if(response?.data)  return response?.data
        else throw 'Error Deleting Driver Task';
       
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceMMTSearchMaintenanceDriverTasks');
        return rejectWithValue(exc);
    }
})

export const maintenanceManageTasksRedux = createSlice({
    name: "maintenance/manageTasks",
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openModalManageTasks(state, action) {
            return {
                ...state,
                open: action.payload.open
            }
        },
    },
    extraReducers: (builder) => {

        //maintenanceMSTGetServiceItems
        builder.addCase(maintenanceMMTSearchMaintenanceDriverTasks.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceMMTSearchMaintenanceDriverTasks.fulfilled, (state, action) => {
            state.loading = false;
            state.driverTasks = action.payload;
        });

        builder.addCase(maintenanceMMTSearchMaintenanceDriverTasks.rejected, (state, action) => {
            state.loading = false;
        });

        //maintenanceMMTDeleteMaintenanceDriverTask
        builder.addCase(maintenanceMMTDeleteMaintenanceDriverTask.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceMMTDeleteMaintenanceDriverTask.fulfilled, (state, action) => {
            state.loading = false;
            state.deleteResponse = action.payload;
        });

        builder.addCase(maintenanceMMTDeleteMaintenanceDriverTask.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });
    }
})

const { actions, reducer } = maintenanceManageTasksRedux;

// Extract and export each action creator by name
export const {
    reset,
    openModalManageTasks
} = actions;

export default reducer;