export default {
  manageCameras_deviceLabel: 'Device',
  manageCameras_esn: 'ESN',
  manageCameras_linkedDevice: 'Paired Unit',
  manageCameras_successfulPairing: 'The unit has been successfully paired',
  manageCameras_associateCamera: 'Associate Camera to GPS Device',
  manageCameras_errorPairing: 'The unit has not been paired',
  manageCameras_successfulUnPairing: 'The unit has been successfully unpaired.',
  manageCameras_errorfulUnPairing: 'The unit has not been unpaired.',
  manageCameras_errorfulUnPairing: 'The unit has not been unpaired.',
  manageCameras_deviceEsnLabel: 'Device ESN',
  manageCameras_unpair: 'Unpair',
  manageCameras_pair: 'Pair'
};
