import contactsView from "Modules/account/views/contacts/lan/en";
import loginHistoryView from "Modules/account/views/loginHistory/lan/en";
import legacyApiKeyView from "Modules/account/views/legacyApiKey/lan/en";
import changePasswordComponent from "Modules/account/.components/changePassword/lan/en";
import subUserView from "Modules/account/views/subUser/lan/en";
import permissionsView from "Modules/account/views/permissions/lan/en";
import apiKeysView from "Modules/account/views/apiKeys/lan/en";
import tagsView from "Modules/account/views/tags/lan/en";
import createEditTagComponent from "Modules/account/views/tags/components/createEditTag/lan/en";
import viewEntitiesComponent from "Modules/account/views/tags/components/viewEntities/lan/en";
import mergeTagsComponent from "Modules/account/views/tags/components/mergeTags/lan/en";
import roleView from "Modules/account/views/role/lan/en";
import deleteTagsComponent from "Modules/account/views/tags/components/deleteTags/lan/en";
import dataExportView from "Modules/account/views/dataExport/lan/en";
import WebHooksView from "Modules/account/views/webHooks/lan/en";

const locales = {
  ...contactsView,
  ...loginHistoryView,
  ...legacyApiKeyView,
  ...changePasswordComponent,
  ...subUserView,
  ...permissionsView,
  ...apiKeysView,
  ...tagsView,
  ...createEditTagComponent,
  ...viewEntitiesComponent,
  ...mergeTagsComponent,
  ...roleView,
  ...deleteTagsComponent,
  ...dataExportView,
  ...WebHooksView
};

export default locales;
