export default {
    "updateService_title": "Add a New Service",
    "updateService_EditTitle": "Edit Service",
    "updateService_titleInputLabel": "Service Name",
    "updateService_titleInputPlaceholder": "Service Name",
    "updateService_engineHoursInputLabel": "Engine Hours",
    "updateService_engineHoursInputPlaceholder": "Engine Hours",
    "updateService_engineOdometerInputLabel": "Odometer",
    "updateService_engineOdometerInputPlaceholder": "Odometer",
    "updateService_costInputLabel": "Estimated Cost",
    "updateService_costInputPlaceholder": "$0",
    "updateService_notesInputLabel": "Notes",
    "updateService_notesInputPlaceholder": "",
    "updateService_recurringInputLabel": "Save as Recurring Service",
    "updateService_intervalTypeInputLabel": "Interval Type",
    "updateService_intervalTypeInputPlaceholder": "Select",
    "updateService_intervalInputLabel": "Interval",
    "updateService_intervalInputPlaceholder": "Interval",
    "updateService_nextServiceInputLabel": "Next Service",
    "updateService_nextServiceInputPlaceholder": "Select Date",
    "updateService_reminderInputLabel": "Reminder",
    "updateService_reminderInputPlaceholder": "Reminder",
    "updateService_periodExport": "Export Period:",
    "updateService_pastDueInputLabel": "Past Due",
    "updateService_pastDueInputPlaceholder": "Past Due",
    "updateService_addNewIntervalType": "Add New Interval Type",
    "updateService_alertInputLabel": "Alert",
    "updateService_alertInputPlaceholder": "Select Alert",
    "updateService_alertTypeInputLabel": "",
    "updateService_alertTypeInputPlaceholder": "Alert Type",
    "updateService_addNewAlertType": "Add New Alert Type",
    "updateService_attachmentsInputLabel": "Attachment",
    "updateService_attachmentsInputPlaceholder": "Select File(s)",
    "updateService_success": "Service Saved",
    "updateService_intervalTypeError": "You must select a least one interval type",
    "updateService_alertError": "You must select a least one alert",
    "updateService_errorLabel": "Error",
    "updateService_subscribers": "Subscribers",
}