export default {
  vidFleet_wakingCamera: 'Waking up camera...',
  vidFleet_stopingCamera: 'Finishing streaming...',
  vidFleet_stoppedCamera: 'Streaming finished',
  vidFleet_errorApi: 'The video could not be loaded, please try again',
  vidFleet_retryMessage: 'The video could not be loaded, either because the server or network failed, please try again.',
  vidFleet_cameraOffline: 'The camera is offline',
  vidFleet_360Message: 'Please use your mouse drag and drop the video',
  vidFleet_errorAccessDenied: 'The video could not be loaded, access denied',
  vidFleet_timeout: 'Live video has timed out.',
  vidFleet_unableToLiveStream: 'The vehicle is currently offline - unable to live stream',
  vidFleet_uploading: "Loading live photos...",
  vidFleet_firmwareNotSupport: "Feature not supported",
  vidFleet_sdCardError: "SD card error",
  vidFleet_failed: "There is not clip available in the selected time range. Try again",
  vidfleet_downloadingClip: "Downloading clip..."
}