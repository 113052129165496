//View Keys
export default {
	forceChangePassword_username: 'Usuario',
	forceChangePassword_password: 'Contraseña',
	forceChangePassword_confirmPassword: 'Confirmar contraseña',
	forceChangePassword_minMessagePassword: "La constraseña debe contener un mínimo de caracteres:",
  forceChangePassword_maxMessagePassword: "La constraseña debe contener un máximo de caracteres:",
  forceChangePassword_minMessageConfirmPassword: "La confirmación de constraseña debe contener un mínimo de caracteres:",
  forceChangePassword_maxMessageConfirmPassword: "La confirmación de constraseña debe contener un máximo de caracteres:",
	forceChangePassword_valueToCompareMessage: "Confirme que la contraseña coincida con la nueva contraseña",
	forceChangePassword_changePasswordMessages: "Es necesario cambiar la contraseña"
};
