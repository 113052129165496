import React from 'react'

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import cx from 'classnames';

import './accordion.scss'

const AccordionComponent = (props) => {

  const { children, title, customTitle, defaultExpanded = false, expanded } = props

  const onChange = () => (event, isExpanded) => {
    if(props.onChange){
      props.onChange(isExpanded)
    }
  };

  return (
    <div className="accordion">
      <Accordion
        defaultExpanded={defaultExpanded}
        onChange={onChange()}
        expanded={expanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={cx('', props.className && props.className)}
        >
          {customTitle && customTitle() || title}
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default AccordionComponent;