import {
  //TYPES
  MAP_UTILITIES_SELECT_MODULE,
  MAP_UTILITIES_SHOW_MAP_SETTINGS,
  MAP_UTILITIES_SHOW_TAG_APPLIES
} from "Redux/actionTypes";

import { MapUtilitiesTypes } from '../enums';

const INIT_STATE = {
  currentModule: MapUtilitiesTypes.Default,
  showMapSettings: true,
  showTagFilterApplied: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MAP_UTILITIES_SELECT_MODULE:
      return { ...state, currentModule: action.payload.currentModule };
    case MAP_UTILITIES_SHOW_MAP_SETTINGS:
      return { ...state, showMapSettings: action.payload.showMapSettings };
    case MAP_UTILITIES_SHOW_TAG_APPLIES:
      return { ...state, showTagFilterApplied: action.payload.showTagFilterApplied };

    default:
      return state;
  }
};