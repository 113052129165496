export default {
    accountTags_label: "Label",
    accountTags_units: "Units",
    accountTags_drivers: "Drivers",
    accountTags_landmarks: "Landmarks",
    accountTags_users: "Users",
    accountTags_forms: "Forms",
    accountTags_dynamicForms: "Dynamic Forms",
    accountTags_path: "Path",
    accountTags_geofences: "Geofences",
};