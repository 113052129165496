// Action Types
import {
  MANAGE_CAMERAS_LIST,
  MANAGE_CAMERAS_LIST_RESPONSE,
  MANAGE_CAMERAS_OPEN_MODAL,
  MANAGE_CAMERAS_PAIR_DEVICE,
  MANAGE_CAMERAS_PAIR_DEVICE_RESPONSE,
  MANAGE_CAMERAS_UNPAIR_DEVICE,
  MANAGE_CAMERAS_UNPAIR_DEVICE_RESPONSE,
  CLEAR_REDUX,
} from 'Redux/actionTypes';

/**
 * Initial State for Manage Cameras Tab.
 */
const INITIAL_STATE = {
  camerasList: [],
  loadingCamerasList: false,
  totalCameras: 0,
  error: null,
  openModal: false,
  camera: null,
  loadingPairDevice: false,
  loadingUnpairDevice: false,
  pairDevice: false,
  unpairDevice: false,
  camerasListOffset: 0,
  camerasListLimit: 25,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MANAGE_CAMERAS_LIST:
      return { 
        ...state,
        loadingCamerasList: !action.payload?.hideTableLoading,
        camerasListOffset: action.payload?.offset
      }

    case MANAGE_CAMERAS_LIST_RESPONSE:
      return {
        ...state,
        totalCameras: action.payload.total,
        camerasList: action.payload.resetSearch
          ? action.payload.items
          : [...state.camerasList, ...action.payload.items],
        loadingCamerasList: false,
        error: action?.payload?.error
      }
    case MANAGE_CAMERAS_OPEN_MODAL:
      return { ...state, openModal: action.payload.open, camera: action.payload.camera }

    case MANAGE_CAMERAS_PAIR_DEVICE:
      return { ...state, loadingPairDevice: true }

    case MANAGE_CAMERAS_PAIR_DEVICE_RESPONSE:
      return { ...state, loadingPairDevice: false, pairDevice: action.payload }

    case MANAGE_CAMERAS_UNPAIR_DEVICE:
      return { ...state, loadingUnpairDevice: true }

    case MANAGE_CAMERAS_UNPAIR_DEVICE_RESPONSE:
      return { ...state, loadingUnpairDevice: false, unpairDevice: action.payload }

    case CLEAR_REDUX:
      return ["", "MANAGE_CAMERAS"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INITIAL_STATE }
        : { ...state }

    default:
      return state;
  }
}