import React from 'react';

import { ColorsToRoutePointsOnRoutesTracker } from '../../../../components/Map/providers/enums'
import { useSelector } from 'react-redux';
import { useIntl } from "react-intl";
import { IconReactMarkerRouteFSMPoint } from '../../../../components/Map/iconsReact'
import './style.scss'

/*********************************************************
 * Module:  Realtime Maps
 * Class associated to the speed override legends
 ********************************************************/
const ColorBox = ({ color, name }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <div
        style={{
          backgroundColor: color,
          width: "20px",
          height: "20px",
          marginRight: "10px",
          border: "2px solid #fff",
          borderRadius: "50%",
        }}
      ></div>
      {/* <div className="route-info-items-div">
        <IconReactMarkerRouteFSMPoint color={color} />
      </div> */}
      <div
        style={{
            //border: "1px solid rgb(199 199 199)",
            width: "165px",
            height: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "rgb(121, 122, 124)",
            fontWeight: "400",
            backgroundColor: "#FFF",
            fontSize: "14px",
        }}
      >{name}</div>
    </div>
);

const RoutesInfoMap = () => {

    const { user: { isKph }, user } = useSelector((state) => state.securityRedux);
    const { messages } = useIntl();

    return(
    <div>
        {
            ColorsToRoutePointsOnRoutesTracker.map((item, number) => {
                return (
                  <ColorBox key={number} color={item.color} name={messages[item.messages]} />
                )
            })
        }
    </div>
    )
};

export default RoutesInfoMap;