import React, { useState, Fragment, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemText from '@mui/material/ListItemText';
import Button from 'Components/Button';
import './style.scss';

const MenuComponent = ({ icon, className, classNameItem, onClick, options, value, disabled, visible, typeBtn = 'icon', titleMenu, elementId = null }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		if (elementId && anchorEl) {
			const element = document.getElementById(elementId)
			if (element) {
				const { x, y } = element.getBoundingClientRect()
				if (anchorEl && !x && !y) setAnchorEl(null)
			}
		}
	})

	const handleClick = (event) => {
		if (elementId) {
			const element = document.getElementById(elementId)
			if (element) setAnchorEl(element)
		}
		else setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};


	return (
		<Fragment>
			<Button
				type={typeBtn}
				aria-controls="customized-menu"
				className={className}
				aria-haspopup="true"
				variant="contained"
				onClick={handleClick}
				placement="bottom"
				icon={icon}
				style={{
					visibility: visible ? 'visible' : (anchorEl ? 'visible' : ''),
					color: 'black'
				}}
				disabled={disabled}
			>
				{titleMenu}
			</Button>
			<Menu
				id="customized-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				sx={{
					'& .MuiMenu-paper': {
						border: '1px solid #d3d4d5'
					}
				}}
				elevation={0}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				{options.map((option, key) => {
					return (
						<MenuItem
							onClick={() => {
								handleClose();
								onClick(option, value);
							}}
							disabled={option?.disabled || false}
							key={key}
						>
							<ListItemText primary={option?.name || option} className={`listMenuItem ${classNameItem || ''}`} />
						</MenuItem>
					);
				})}
			</Menu>
		</Fragment>
	);
};

export { MenuComponent };
