//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
	ALERTS_WIZARD_ALERT_OPEN,
	ALERTS_WIZARD_ALERT_SAVE,
	ALERTS_WIZARD_ALERT_TYPES_SUPPORTED
} from 'Redux/actionTypes';

//actions 
import {
	alertsWizardOpenResult,
	alertsWizardSaveResult,
	alertsWizardAlertTypesSupported,
	alertsWizardAlertTypesSupportedResult
} from 'Redux/actions';

const route = 'modules/alerts/components/wizardAlert/redux/saga'

/* FUNCTIONS ASYNC */
function* alertsWizardOpenRequest() {
	yield takeEvery(ALERTS_WIZARD_ALERT_OPEN, function* ({ payload }) {
		try {

			yield put(alertsWizardAlertTypesSupported())
			yield put(alertsWizardOpenResult({ data: null }))
		} catch (exc) {
			ExceptionManager(exc, route, 'alertsWizardOpenRequest');
			yield put(alertsWizardOpenResult({ error: exc.error }));
		}
	});
}


function* alertWizardGetSupportedRequest() {
	yield takeEvery(ALERTS_WIZARD_ALERT_SAVE, function* ({ payload }) {
		try {
			const {
				id,
				name,
				type,
				config,
				severity,
				summary,
				triggers,
				assignedTo,
				schedule,
				subscribers,
			} = payload;

			let data
			if (id) {
				//update
				data = yield call(
					clientMutation,
					`mutation updateSystemAlert ($id: String!, $name: String, $type: enumAlertType, $config: AWSJSON, $severity: Severity!, $summary: String, $triggers: [SystemAlertTriggerInput], $assignedTo: [SystemAlertAssignmentInput], $schedule: SystemAlertScheduleInput, $subscribers: [AlertSubscriberInput]) {
						updateSystemAlert (id: $id, name: $name, type: $type, config: $config, severity: $severity, summary: $summary, triggers: $triggers, assignedTo: $assignedTo, schedule: $schedule, subscribers: $subscribers) {
							id
						}
					}`,
					{
						id, name, type, config, severity, summary, triggers, assignedTo, schedule, subscribers
					},
					endpoints.GRAPHQL_GENERAL,
				);
			}
			else {
				//create
				data = yield call(
					clientMutation,
					`mutation createSystemAlert ($name: String!, $type: enumAlertType!, $config: AWSJSON, $severity: Severity!, $summary: String, $triggers: [SystemAlertTriggerInput], $assignedTo: [SystemAlertAssignmentInput], $schedule: SystemAlertScheduleInput, $subscribers: [AlertSubscriberInput]) {
						createSystemAlert (name: $name, type: $type, config: $config, severity: $severity, summary: $summary, triggers: $triggers, assignedTo: $assignedTo, schedule: $schedule, subscribers: $subscribers) {
							id
						}
					}`,
					{
						name, type, config, severity, summary, triggers, assignedTo, schedule, subscribers
					},
					endpoints.GRAPHQL_GENERAL,
				);
			}

			if (data) {
				yield put(alertsWizardSaveResult({ result: true, id: data.id }));
			} else {
				throw "Error Saving"
			}

		} catch (exc) {
			ExceptionManager(
				exc,
				'modules/alerts/components/newAlert/redux/saga',
				'searchAlertTypesSupported',
			);
			yield put(alertsWizardSaveResult({ result: false, error: exc }))
		}
	});
}

function* alertsWizardAlertTypesSupportedRequest() {
	yield takeEvery(ALERTS_WIZARD_ALERT_TYPES_SUPPORTED, function* ({ payload }) {
		try {

			let data = yield call(
				clientQuery,
				`query searchAlertTypes ($supportedOnly: Boolean) {
					result: searchAlertTypes(supportedOnly: $supportedOnly){
						id
						name
						code
						parentId
						unitVersionIds
					}
				}`,
				{
					supportedOnly: true
				},
				endpoints.GRAPHQL_GENERAL,
			);
			if (data.result) {
				yield put(alertsWizardAlertTypesSupportedResult(data.result))
			}

		} catch (exc) {
			ExceptionManager(exc, route, 'alertsWizardAlertTypesSupportedRequest');
			yield put(alertsWizardAlertTypesSupportedResult([]));
		}
	});
}


//MERGE SAGA
export default function* rootSaga() {
	yield all([fork(alertsWizardOpenRequest)]);
	yield all([fork(alertWizardGetSupportedRequest)]);
	yield all([fork(alertsWizardAlertTypesSupportedRequest)]);
}
