// Action types.
import { 
  LEGACY_API_KEY_SEARCH, 
  LEGACY_API_KEY_SEARCH_RESPONSE,
  LEGACY_API_KEY_GET,
  LEGACY_API_KEY_GET_RESPONSE,
  LEGACY_API_KEY_CREATE,
  LEGACY_API_KEY_CREATE_RESPONSE,
  LEGACY_API_KEY_UPDATE,
  LEGACY_API_KEY_UPDATE_RESPONSE,
  LEGACY_API_KEY_DELETE,
  LEGACY_API_KEY_DELETE_RESPONSE,
  LEGACY_API_KEY_GET_COUNT,
  LEGACY_API_KEY_GET_COUNT_RESPONSE
} from "Redux/actionTypes";

export const searchLegacyApiKey = (data) => {
  return {
    type: LEGACY_API_KEY_SEARCH,
    payload: data,
  };
};

export const searchLegacyApiKeyResponse = (data) => {
  return {
    type: LEGACY_API_KEY_SEARCH_RESPONSE,
    payload: data,
  };
};

export const getLegacyApiKey = (data) => {
  return {
    type: LEGACY_API_KEY_GET,
    payload: data,
  };
};


export const getLegacyApiKeyResponse = (data) => {
  return {
    type: LEGACY_API_KEY_GET_RESPONSE,
    payload: data,
  };
};


export const createLegacyApiKey = (data) => {
  return {
    type: LEGACY_API_KEY_CREATE,
    payload: data,
  };
};

export const createLegacyApiKeyResponse = (data) => {
  return {
    type: LEGACY_API_KEY_CREATE_RESPONSE,
    payload: data,
  };
};

export const updateLegacyApiKey = (data) => {
  return {
    type: LEGACY_API_KEY_UPDATE,
    payload: data,
  };
};

export const updateLegacyApiKeyResponse = (data) => {
  return {
    type: LEGACY_API_KEY_UPDATE_RESPONSE,
    payload: data,
  };
};

export const deleteLegacyApiKey = (data) => {
  return {
    type: LEGACY_API_KEY_DELETE,
    payload: data,
  };
};

export const deleteLegacyApiKeyResponse = (data) => {
  return {
    type: LEGACY_API_KEY_DELETE_RESPONSE,
    payload: data,
  };
};

export const countLegacyApiKeys = () => {
  return {
    type: LEGACY_API_KEY_GET_COUNT
  };
};

export const countLegacyApiKeysResponse = (data) => {
  return {
    type: LEGACY_API_KEY_GET_COUNT_RESPONSE,
    payload: data,
  };
};