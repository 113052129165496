import {
  SMARTWITNESS_VIDEO_GET_SNAPSHOTS_URLS,
  SMARTWITNESS_VIDEO_GET_SNAPSHOTS_URLS_RESPONSE,
  SMARTWITNESS_VIDEO_REFRESH_SNAPSHOTS_URLS,
  SMARTWITNESS_VIDEO_REFRESH_SNAPSHOTS_URLS_RESPONSE,
  SMARTWITNESS_VIDEO_SAVE_SNAPSHOTS_URLS,
  SMARTWITNESS_VIDEO_SAVE_SNAPSHOTS_URLS_RESPONSE
} from 'Redux/actionTypes';

export const getSnapshotsUrls = (data) => ({
  type: SMARTWITNESS_VIDEO_GET_SNAPSHOTS_URLS,
  payload: { data },
});

export const getSnapshotsUrlsResponse = (data) => ({
  type: SMARTWITNESS_VIDEO_GET_SNAPSHOTS_URLS_RESPONSE,
  payload: { data },
});

export const refreshSnapshotsUrls = (data) => ({
  type: SMARTWITNESS_VIDEO_REFRESH_SNAPSHOTS_URLS,
  payload: { data },
});

export const refreshSnapshotsUrlsResponse = (data) => ({
  type: SMARTWITNESS_VIDEO_REFRESH_SNAPSHOTS_URLS_RESPONSE,
  payload: { data },
});

export const saveSnapshotsUrls = (data) => ({
  type: SMARTWITNESS_VIDEO_SAVE_SNAPSHOTS_URLS,
  payload: { data },
});

export const saveSnapshotsUrlsResponse = (data) => ({
  type: SMARTWITNESS_VIDEO_SAVE_SNAPSHOTS_URLS_RESPONSE,
  payload: { data },
});
