import {
    ACCOUNT_TAGS_SEARCH,
    ACCOUNT_TAGS_SEARCH_RESPONSE,
    ACCOUNT_TAGS_SEARCH_PARENTS,
    ACCOUNT_TAGS_SEARCH_PARENTS_RESPONSE,
    ACCOUNT_TAGS_SEARCH_CHILDREN,
    ACCOUNT_TAGS_SEARCH_CHILDREN_RESPONSE
} from "Redux/actionTypes";
//All tags
export const searchAccountTags = (data) => {
    return {
        type: ACCOUNT_TAGS_SEARCH,
        payload: { data },
    };
};

export const searchAccountTagsResponse = (data) => {
    return {
        type: ACCOUNT_TAGS_SEARCH_RESPONSE,
        payload: data,
    };
};

//Only tag parents first level
export const searchAccountTagsParents = (data) => {
    return {
        type: ACCOUNT_TAGS_SEARCH_PARENTS,
        payload: { data },
    };
};

export const searchAccountTagsParentsResponse = (data) => {
    return {
        type: ACCOUNT_TAGS_SEARCH_PARENTS_RESPONSE,
        payload: data,
    };
};

//Only tags behind first level
export const searchAccountTagsChildren = (data) => {
    return {
        type: ACCOUNT_TAGS_SEARCH_CHILDREN,
        payload: { data },
    };
};

export const searchAccountTagsChildrenResponse = (data) => {
    return {
        type: ACCOUNT_TAGS_SEARCH_CHILDREN_RESPONSE,
        payload: data,
    };
};