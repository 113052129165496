import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';

// Actions
import {
  reportsMainGenerateReportProgress
} from 'Redux/actions';

function* getFSMCombinedDetailsReport(report, event) {
  const {name, filters, reportTimeRanges} = report;
  const { filterType, sortField, sortOrder } = filters;
  const { filterArray, startRange, endRange, subLimit, subPage, rowsPerPage, page } = event.filters;

  try {
    let data = yield call(
      clientQuery,
      `query( $conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getCombinedDetailsReport(conditions: $conditions, limit: $limit, offset: $offset) {
          total
          limit
          offset
          category
          items {
            driverId
            dateTime
            driverStatus
            inOut
            address
            messageDirection
            message
            stopTitle
            stopStatus
          }
        }
      }`,
      {
        conditions: {
          reportName: name,
          category: {
            field: filterType,
            value: filterArray
          },
          dateRange: {
            fields: [
              "unitTime"
            ],
            GTE: startRange,
            LTE: endRange,
            reportTimeRanges
          },
          sortField: {
            field: sortField || "deviceId",
            order: sortOrder === 'desc' ? 'DESC' : 'ASC'
          }
        },
        limit: subLimit,
        offset: subPage
      },
      endpoints.GRAPHQL_PX_REPORTING,
    );
  
    if (data?.Report) {
      report.events = data?.Report
      report.loadingSubEvents = false
      yield put(reportsMainGenerateReportProgress(report))
    } else {
      report.loadingSubEvents = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  } catch (exc) {
    report.loadingSubEvents = false
    ExceptionManager(exc, 'src/modules/reports/views/main/components/reportsTab/.components/reports/fsmCombinedReport', 'getTimeCardDetailsReport');
    yield put(reportsMainGenerateReportProgress(report))
  }
}

export default getFSMCombinedDetailsReport;