import {
    ACCOUNT_TAGS_OPEN_VIEW_ENTITIES_MODAL,
    ACCOUNT_TAGS_VIEW_ENTITIES_SEARCH_ENTITIES,
    ACCOUNT_TAGS_VIEW_ENTITIES_SEARCH_ENTITIES_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    openViewEntities: false,
    tag: null,
    tab: 0,
    onlyView: false,
    entities: [],
    loadingEntities: false,
    filters: {
        limit: 30,
        offset: 0,
        entityName: "",
        parentTagId: 0,
        openSearch: ""
    },
    total: 0
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case ACCOUNT_TAGS_OPEN_VIEW_ENTITIES_MODAL:
            return {
                ...state,
                openViewEntities: action.payload?.open,
                tag: action.payload?.tag,
                tab: action.payload?.tab,
                onlyView: action.payload?.onlyView || false
            };

        case ACCOUNT_TAGS_VIEW_ENTITIES_SEARCH_ENTITIES:
            return {
                ...state,
                loadingEntities: true,
                filters: action.payload?.filters,
                entities: action.payload?.filters?.reset ? [] : state.entities
            };

        case ACCOUNT_TAGS_VIEW_ENTITIES_SEARCH_ENTITIES_RESPONSE:
            const { items, total } = action?.payload?.response;
            return {
                ...state,
                entities: [...state.entities, ...items],
                loadingEntities: false,
                total: total
            };

        case CLEAR_REDUX:
            return ["", "ACCOUNT_TAGS_VIEW_ENTITIES"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return state;
    }
};