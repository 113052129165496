// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    MAP_GEOFENCES_GET_GEOFENCE,
    MAP_GEOFENCES_EDIT_CREATE_GEOFENCE
} from "Redux/actionTypes";

// Actions
import {
    mapGeofencesGetGeofenceResponse,
    mapGeofencesEditCreateGeofenceResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* mapGeofencesGetGeofenceRequest({ payload }) {
    try {
        const { id } = payload;

        const response = yield call(
            clientQuery,
            `
                query getGeofence ($id: Int!) {
                    Geofence: getGeofence (id: $id) {
                        id
                        companyId
                        name
                        tags {
                            id
                            label
                            color
                            path
                        }
                        shape
                        radius
                        alertOnType
                        center_latlong
                        contacts {
                            id
                            title
                            address
                            type
                        }
                        coordinates {
                            latitude
                            longitude
                        }
                        color
                        assignedUnitsCount
                        insideDurationMins
                        isAlert
                        createdBy
                        createdOn
                        updatedBy
                        updatedOn
                    }
                }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );

        if (!response && !response.Geofence) throw `Error while getting geofence: ${id}`;

        yield put(mapGeofencesGetGeofenceResponse({ ...response.Geofence, error: false }));
    } catch (exc) {
        yield put(mapGeofencesGetGeofenceResponse({ error: true }));
        ExceptionManager(
            exc,
            "realtimeMaps/main/components/geofences/components/geofenceCreateEdit/redux/saga",
            "mapGeofencesGetGeofenceRequest"
        );
    }
}

function* mapGeofencesCreateEditGeofencesRequest({ payload }) {
    try {
        const { geofence } = payload;
        
        let query = `
            mutation createGeofence (
                $name: String!, 
                $tagsIds: [Int]!, 
                $latitude: Float!, 
                $longitude: Float!, 
                $shape: shapeType!, 
                $radius: Int!, 
                $insideDurationMins: Int!, 
                $alertOnType: String, 
                $contacts: [Int]!, 
                $coordinates: [CoordinateInput]!, 
                $color: String,
                $isAlert: Int
            ) {
                Geofence: createGeofence (
                    name: $name, 
                    tagsIds: $tagsIds, 
                    latitude: $latitude, 
                    longitude: $longitude, 
                    shape: $shape, 
                    radius: $radius, 
                    insideDurationMins: $insideDurationMins, 
                    alertOnType: $alertOnType, 
                    contacts: $contacts, 
                    coordinates: $coordinates, 
                    color: $color,
                    isAlert: $isAlert
                ) {
                    id
                    companyId
                    name
                    tags {
                        id
                        label
                        color
                        path
                    }
                    shape
                    center_latlong
                    radius
                    alertOnType
                    contacts {
                        id
                        title
                        address
                        type
                    }
                    coordinates {
                        latitude
                        longitude
                    }
                    color
                    assignedUnitsCount
                    insideDurationMins
                    isAlert
                    createdBy
                    createdOn
                    updatedBy
                    updatedOn
                }
            } 
        `;

        if (geofence?.id) {
            query = `
                mutation updateGeofence (
                    $id: Int!, 
                    $name: String, 
                    $tagsIds: [Int], 
                    $latitude: Float, 
                    $longitude: Float, 
                    $insideDurationMins: Int!, 
                    $alertOnType: String, 
                    $contacts: [Int], 
                    $color: String
                    $isAlert: Int,
                    $radius: Int,
                    $coordinates: [CoordinateInput]
                ) {
                    Geofence: updateGeofence (
                        id: $id, 
                        name: $name, 
                        tagsIds: $tagsIds, 
                        latitude: $latitude, 
                        longitude: $longitude,
                        alertOnType: $alertOnType, 
                        insideDurationMins: $insideDurationMins, 
                        contacts: $contacts, 
                        color: $color
                        isAlert: $isAlert,
                        radius: $radius,
                        coordinates: $coordinates, 
                    ) {
                        id
                        companyId
                        name
                        tags {
                            id
                            label
                            color
                            path
                        }
                        shape
                        center_latlong
                        radius
                        alertOnType
                        contacts {
                            id
                            title
                            address
                            type
                        }
                        coordinates {
                            latitude
                            longitude
                        }
                        color
                        assignedUnitsCount
                        insideDurationMins
                        isAlert
                        createdBy
                        createdOn
                        updatedBy
                        updatedOn
                    }
                } 
            `;
        }

        const response = yield call(
            clientMutation,
            query,
            geofence,
            endpoints.GRAPHQL_GENERAL,
        );

        if (!response || !response.Geofence) throw `Error while creating/editing Geofence: ${geofence}`;
            
        yield put(mapGeofencesEditCreateGeofenceResponse({ ...response.Geofence, error: false }));
    } catch (exc) {
        let message = null
        if (exc.errorData && exc.errorData.errors) {
            exc.errorData.errors.forEach(error => {
                if (error.errorInfo && error.errorInfo.code) {
                    message = error.message
                }
            });
        }

        yield put(mapGeofencesEditCreateGeofenceResponse({ error: true, message: message }));
    }
}

function* watchMapLandmarksMethods() {
    yield takeEvery(MAP_GEOFENCES_GET_GEOFENCE, mapGeofencesGetGeofenceRequest);
    yield takeEvery(MAP_GEOFENCES_EDIT_CREATE_GEOFENCE, mapGeofencesCreateEditGeofencesRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMapLandmarksMethods)]);
}