import React, { useCallback, useRef, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
// Material UI
import { Grid } from '@mui/material';
// Cloud React components
import { Button, LinearProgress, ContextMenu } from 'Components';
// Utils
import onClickOutside from 'react-onclickoutside';
// Styles
import "./asyncSelect.scss"

// International
import { useIntl } from "react-intl";



const AsyncSelect = (props) => {
  const {
    id,
    name,
    label,
    value,
    helpText,
    required,
    requiredMessage,
    disabled,
    placeholder,
    total,
    multiple,
    data
  } = props;

  const inputRef = useRef();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const { messages } = useIntl();


  //AsyncSelect.handleClickOutside = () => setOpen(false);

  const validate = useCallback((val) => {
    let isValid = true;
    let errorMessage;
    let currentValue = val || value;

    if (required) {
      if (!currentValue) {
        isValid = false;
        errorMessage = requiredMessage;
      } else if (multiple && !currentValue.length) {
        isValid = false;
        errorMessage = requiredMessage;
      }
    }

    setIsValid(isValid);
    setErrorMessage(errorMessage);

    return isValid;
  });

  const onSearch = useCallback((newSearchText, newPage) => {
    props.onSearch(newSearchText, newPage);
  });

  return (
    <div className="async-select-container">
      {
        label && (
          <label className="input-normal-label">
            {label}
            {required && <span className="text-danger"> *</span>}
          </label>
        )
      }

      <div className="async-select-input-container">
        {
          !open &&
          <div
            className={`async-select-value-container ${disabled ? 'async-select-disabled' : ''}`}
            onClick={() => {
              if (!disabled) {
                setOpen(!open);
                inputRef?.current?.focus();
              }
            }}
          >
            {value ? value[props.nameKey] : placeholder}
          </div>
        }

        {
          open &&
          <input
            id={id}
            name={name}
            ref={inputRef}
            className="async-select-input input-normal form-control"
            type="text"
            value={searchText}
            onChange={(e) => {
              const value = e?.target?.value;
              setSearchText(value);

              if (window.typingTime) {
                clearTimeout(window.typingTime);
              }

              window.typingTime = setTimeout(() => {
                setPage(1);
                onSearch(value, 0);

                window.typingTime = null;
              }, 500);
            }}
            autoFocus
            required={required}
            disabled={disabled}
            placeholder={placeholder}
          />
        }

        <div className="async-menu-arrow-button">
          <Button
            type="icon"
            icon={open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            placement="bottom"
            className="btn-xs"
            fontSize="inherit"
            disabled={disabled}
            onClick={(e) => {
              if (e) e.preventDefault();
              setOpen(!open);
              inputRef?.current?.focus();
            }}
          />
        </div>
      </div>

      <ContextMenu
        style={{
          width: '100%',
          marginTop: 3,
          /* maxWidth: 230, */
          overflowY: 'hidden'
        }}
        className='async-select-modal'
        open={open}
      >
        <div className="async-select-options-container">
          <InfiniteScroll
            dataLength={data.length}
            height={200}
            next={() => {
              setPage(page + 1);
              onSearch(searchText, page);
            }}
            hasMore={props.hasMore}
            loader={(props.loading && data.length) ? <LinearProgress color="primary" /> : <></>}
            endMessage={
              <div style={{ padding: 10, textAlign: "center" }}>
                {
                  !props.loading &&
                  <span style={{ fontSize: 13, color: 'grey' }}>
                    {`${total ? messages['noMoreItems'] : messages['noItemsFound']}`}
                  </span>
                }
              </div>
            }
          >
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: '100%' }}
            >
              {
                data.map((item, index) => {
                  const isSelected = value && value[props.idKey] === item[props.idKey];
                  return (
                    <Grid
                      key={index}
                      item
                      xs
                      style={{ width: '100%' }}
                    >
                      <div
                        className={`async-select-option ${(isSelected ? 'async-select-option-selected' : '')}`}
                        onClick={() => {
                          if (props.onChange) props.onChange(item);
                          setOpen(!open);
                          validate(item);
                        }}
                      >
                        {item[props.nameKey]}
                      </div>
                    </Grid>
                  )
                })
              }

              {
                (props.loading && !data.length) &&<LinearProgress color="primary" />
              }
            </Grid>
          </InfiniteScroll>
        </div>
      </ContextMenu>

      {
        helpText &&
        <small id={`${id}-help`} className="form-text text-muted">
          {helpText}
        </small>
      }

      {!isValid && errorMessage && (
        <small className="text-danger" style={{ paddingTop: 5 }}>
          {errorMessage}
        </small>
      )}
    </div>
  );
}


export default AsyncSelect