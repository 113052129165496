import React, { useRef } from "react";
import Modal, { ModalBody, ModalFooter } from "Components/Modal";
import { Form, Input, Loading, Button } from "Components";
import { ErrorHandlerLogin } from "Core/data/Helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { loginConfirm } from "Redux/actions";
import { useDispatch } from "react-redux";

const ModalMigrateUser = ({
  handleClose,
  error,
  username,
  confirmationCode,
  setConfirmationCode,
  loading,
  cognitoValues,
  result,
  showConfirm
}) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const frmConfirm = useRef();
  return (
    <Modal
      title={messages["migrateUser_emailVerification"]}
      open={showConfirm || false}
      handleClose={handleClose}
      size="small"
    >
      <ModalBody>
        <div style={{ padding: 20 }}>
          {error && (
            <div className="form-item card-error-login login-code-error">
              <p className="txt-card-error-login">
                {messages[error] || ErrorHandlerLogin(error)}
              </p>
            </div>
          )}

          <div>
            <br />
            {messages["migrateUser_verifyTex"]}
          </div>
          {result ? (
            messages["migrateUser_success"]
          ) : (
            <Form
              ref={frmConfirm}
              onValidSubmit={() => {
                // dispatch(logout());
                dispatch(
                  loginConfirm(
                    username,
                    confirmationCode,
                    cognitoValues,
                    navigate,
                    messages
                  )
                );
              }}
            >
              <div className="form-item">
                <Input
                  id="txtVerificationCode"
                  type="text"
                  label={messages["migrateUser_verifyCode"]}
                  placeholder={messages["migrateUser_verifyCode"]}
                  value={confirmationCode}
                  onChange={(text) => {
                    setConfirmationCode(text);
                  }}
                  required
                  requiredMessage={messages["required_field"]}
                  autoFocus
                />
              </div>
            </Form>
          )}
        </div>
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <div className="loading-area">
            <Loading />
          </div>
        ) : (
          <>
            {result ? (
              <Button
                id="btnContinueConfirm"
                type="link"
                url="/login"
                className="btn btn-blue"
              >
                <FormattedMessage id="continue" />
              </Button>
            ) : (
              <>
                <Button
                  id="btnCloseConfirm"
                  onClick={handleClose}
                  className="btn-cancel"
                >
                  {messages["close"]}
                </Button>
                {"  "}
                <Button
                  id="btnConfirm"
                  onClick={(e) => {
                    e.preventDefault();
                    frmConfirm.current.submit();
                  }}
                  className="btn-blue"
                >
                  {messages["migrateUser_verify"]}
                </Button>
                <div
                  className="fb-login-button"
                  data-max-rows="1"
                  data-size="large"
                  data-button-type="continue_with"
                  data-show-faces="false"
                  data-auto-logout-link="true"
                  data-use-continue-as="false"
                ></div>
              </>
            )}
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ModalMigrateUser;
