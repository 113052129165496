// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  FSM_SETTINGS_FORM_SEARCH,
  FSM_SETTINGS_FORM_CREATE,
  FSM_SETTINGS_FORM_UPDATE,
  FSM_SETTINGS_FORM_DELETE
} from "Redux/actionTypes";

// Actions
import {
  searchFormSettingsResponse,
  createFormSettingsResponse,
  updateFormSettingsResponse,
  deleteformSettingsResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";


function* searchFormsRequest({ payload }) {
  try {
    const { data } = payload;
    
    const query = `
        query searchForms ($openSearch: String, $limit: Int, $offset: Int) {
          searchForms (openSearch: $openSearch, limit: $limit, offset: $offset) {
              items {
                  id
                  name
                  tags {
                      id
                      label
                      color
                  }
                  questions {
                      id
                      name
                      priority
                      answers {
                          id
                          name
                          priority
                      }
                  }
              }
              total
          }
      }
        `;

    const response = yield call(
      clientQuery,
      query,
      data,
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.searchForms) {
      throw "Error while getting Forms list";
    }
    let forms = response?.searchForms?.items || [];
    let total = response?.searchForms?.total;
    yield put(searchFormSettingsResponse({ forms, total }));
  } catch (exc) {
    yield put(searchFormSettingsResponse({ forms: [] }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "searchFormsRequest",
      true
    );
  }
}

function* createFormsRequest({ payload }) {
  try {
 
      let query = `
      mutation createForm ($name: String!, $questions: [QuestionInput], $tagIds: [Int]) {
        createForm (name: $name, questions: $questions, tagIds: $tagIds) {
            id
            name
            tags {
                id
                label
                color
            }
            questions {
                id
                name
                priority
                answers {
                    id
                    name
                    priority
                }
            }
        }
    }
      `;

 

      const response = yield call(
          clientMutation,
          query,
          payload,
          endpoints.GRAPHQL_GENERAL,
      );


      yield put(createFormSettingsResponse({ create:response, error: false }));
  } catch (exc) {
      yield put(createFormSettingsResponse({  create:null, error: true }));
      ExceptionManager(
          exc,
          "modules/fsm/views/fsmSettings/components/formSettings/redux/saga",
          "createFormsRequest",
          true
      );
  }
}

function* updateFormsRequest({ payload }) {
  try {
      const { form } = payload;

      let query = `
      mutation updateForm ($id: Int!, $name: String, $questions: [QuestionInput], $tagIds: [Int]) {
        updateForm (id: $id, name: $name, questions: $questions, tagIds: $tagIds) {
            id
            name
            tags {
                id
                label
                color
            }
            questions {
                id
                name
                priority
                answers {
                    id
                    name
                    priority
                }
            }
        }
    }
      `;

 

      const response = yield call(
          clientMutation,
          query,
          payload,
          endpoints.GRAPHQL_GENERAL,
      );


      yield put(updateFormSettingsResponse({ update:response,  error: false }));
  } catch (exc) {
      yield put(updateFormSettingsResponse({  update:false , error: true }));
      ExceptionManager(
          exc,
          "modules/fsm/views/fsmSettings/components/formSettings/redux/saga",
          "updateFormsRequest",
          true
      );
  }
}

function* deleteFormsRequest({payload}) {
  try {

    const { Forms } = payload;
    let mutations = [];
    Forms?.forEach((Form, index) => {
        mutations.push(`
            Form${index}: deleteForm(id: ${Form?.id})
        `);
    });

    let mutation = `mutation{${mutations.join("")}}`;

    const response = yield call(
        clientMutation,
        mutation,
        {},
        endpoints.GRAPHQL_GENERAL,
    );

    if (!response || !response.Form0) throw `Error while deleting Forms, ${Forms}`;



    yield put(deleteformSettingsResponse({ data: response.Form0, error: false }));
  } catch (exc) {
    yield put(deleteformSettingsResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "deleteForms",
      true
    );
  }
}



function* watchMethods() {
  yield takeEvery(FSM_SETTINGS_FORM_SEARCH, searchFormsRequest);
  yield takeEvery(FSM_SETTINGS_FORM_CREATE, createFormsRequest);
  yield takeEvery(FSM_SETTINGS_FORM_UPDATE, updateFormsRequest);
  yield takeEvery(FSM_SETTINGS_FORM_DELETE, deleteFormsRequest);
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}