import alertsTabRedux from 'Modules/alerts/views/main/components//alertsTab/redux/reducer';
import alertsWizardRedux from 'Modules/alerts/components/wizardAlert/redux/reducer';
import alertsCommonRedux from 'Modules/alerts/common/redux/reducer';
import alertsConfigRedux from '../../components/wizardAlert/redux/slice';

export default {
	alertsTabRedux,
	alertsWizardRedux,
	alertsCommonRedux,
	alertsConfigRedux
};