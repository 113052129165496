import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientMutation, clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
//import { resetAll } from "../../../../../../../../../../.globals/redux/actions";
import { NotificationManager } from 'Components/Notifications';
import { ExceptionManager } from 'Core/logManager';

const INIT_STATE = {
    open: false,
    loading: false,
    result: null,
    service: null,
    maintenanceServiceUnit: null,
    maintenanceServiceUnitId: null,
    previousReading: null,
    loadingPreviousReading: false,
    loadingUpdateScheduleMaintenanceService: false,
    updateScheduleMaintenanceServiceResponse: null,
    services: [],
    loadingMaintenanceServiceUnit: false,
    serviceItems: [],
    newServiceItemResult: null
};

const route = "modules/maintenance/modals/scheduleMaintenanceService/redux/slice";

export const maintenanceSMSgetMaintenanceServiceUnit = createAsyncThunk('maintenance/scheduleMaintenanceService/getMaintenanceServiceUnit', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `query ($id: Int!){
                data: getMaintenanceServiceUnit(id: $id){
                    id
                    serviceId
                    service {
                        id
                        title
                        notes
                        miles
                        milesBefore
                        milesAfter
                        days
                        daysBefore
                        daysAfter
                        engineHours
                        engineHoursBefore
                        engineHoursAfter
                        recurrent
                        estimatedCost
                        unitsCount
                        subscribers {
                            id
                            contactId
                            contact {
                              id
                              title
                              address
                              type
                            }
                            alert
                            alertBefore
                            alertAfter
                        }
                        fileIds
                        files {
                          id
                          originalName
                          fileKey
                          repositoryCode
                        }
                        serviceItems{
                            id
                            name
                            parts
                            labor
                            subtotal
                        }
                    }
                    serviceUnitItems{
                        id
                        name
                        parts
                        labor
                        subtotal
                    }
                    subscribers {
                        id
                        contactId
                        contact {
                          id
                          title
                          address
                          type
                        }
                        alert
                        alertBefore
                        alertAfter
                    }
                    unitId
                    lastReadingId
                    lastMaintenanceDate
                    lastMaintenanceOdometer
                    lastMaintenanceEngineHours
                    nextServiceDate
                    nextServiceOdometer
                    nextServiceEngineHours
                    status
                    notes
                    completed
                    fileIds
                    files {
                      id
                      originalName
                      fileKey
                      repositoryCode
                    }
                }
            }`,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.data;
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceMSDTSearchMaintenanceDriverTasks');
        return rejectWithValue(exc)
    }
});

export const maintenanceSMSGetMaintenanceService = createAsyncThunk('maintenance/scheduleMaintenanceService/getMaintenanceService', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query ($id: Int!){
                data: getMaintenanceService(id: $id){
                    id
                    title
                    notes
                    miles
                    milesBefore
                    milesAfter
                    days
                    daysBefore
                    daysAfter
                    engineHours
                    engineHoursBefore
                    engineHoursAfter
                    recurrent
                    estimatedCost
                    serviceItems{
                        id
                        name
                        parts
                        labor
                        subtotal
                    }
                    subscribers {
                        id
                        contactId
                        contact {
                            id
                            title
                            address
                            type
                        }
                        alert
                        alertBefore
                        alertAfter
                    }
                    fileIds
                    files {
                        id
                        originalName
                        fileKey
                        repositoryCode
                    }
                }
            }`,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.data;
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceSMSGetMaintenanceService');
        return rejectWithValue(exc)
    }
});

export const maintenanceSMSGetPreviousReading = createAsyncThunk('maintenance/scheduleMaintenanceService/getPreviousReading', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const { deviceId, unitTime } = payload
        const response = await clientQuery(
            `
                query($deviceId:String!, $unitTime:DateRange) {
                    data: getPreviousReading(deviceId:$deviceId, unitTime:$unitTime)
                }
            `,
            {
                deviceId,
                unitTime
            },
            endpoints.GRAPHQL_GENERAL
        );
        return JSON.parse(response?.data);
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceSMSGetMaintenanceService');
        return rejectWithValue(exc)
    }
});

export const maintenanceSMSCreateEditMaintenanceServiceUnit = createAsyncThunk('maintenance/scheduleMaintenanceService/createEditMaintenanceServiceUnit', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientMutation(
            `mutation (
                ${payload.id ? '$id: Int!' : ''}
                ${!payload.id ? '$serviceId: Int!' : ''}
                ${!payload.id ? '$unitId: Int!' : ''}
                $lastReadingId: String
                $lastMaintenanceDate: ${payload.id ? 'String' : 'String!'}
                ${payload.maintenanceCompleted ? '$maintenanceCompleted: String' : ''}
                $lastMaintenanceOdometer: Float
                $lastMaintenanceEngineHours: Float
                $nextServiceDate: String
                $nextServiceOdometer: Float
                $nextServiceEngineHours: Float
                $notes: String,
                $estimatedCost: Float,
                $fileIds: [Int]
                $subscribers: [AlertSubscriberInput]
                $items: [MaintenanceServiceUnitItemsInput]

            ) {
                data: ${payload.id ? 'updateMaintenanceServiceUnit' : 'createMaintenanceServiceUnit'} (
                    ${payload.id ? 'id: $id,' : ''}
                    ${!payload.id ? 'serviceId: $serviceId,' : ''}
                    ${!payload.id ? 'unitId: $unitId,' : ''}
                    lastReadingId: $lastReadingId,
                    lastMaintenanceDate: $lastMaintenanceDate,
                    ${payload.maintenanceCompleted ? 'maintenanceCompleted: $maintenanceCompleted,' : ''},
                    lastMaintenanceOdometer: $lastMaintenanceOdometer,
                    lastMaintenanceEngineHours: $lastMaintenanceEngineHours,
                    nextServiceDate: $nextServiceDate,
                    nextServiceOdometer: $nextServiceOdometer,
                    nextServiceEngineHours: $nextServiceEngineHours,
                    notes: $notes,
                    estimatedCost: $estimatedCost,
                    fileIds: $fileIds,
                    subscribers: $subscribers,
                    items: $items
                ) {
                    id
                }
            }`,
            payload,
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.data
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceSMSCreateEditMaintenanceServiceUnit');
        return rejectWithValue(exc)
    }
});

export const maintenanceSMSSearchMaintenanceServices = createAsyncThunk('maintenance/scheduleMaintenanceService/searchMaintenanceServices', async ({ searchText }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `query ($openSearch: String){
                data: searchMaintenanceServices(openSearch: $openSearch){
                    id
                    title
                }
            }`,
            {
                openSearch: searchText || ''
            },
            endpoints.GRAPHQL_GENERAL
        );
        return response?.data
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceSMSSearchMaintenanceServices');
        return rejectWithValue(exc)
    }
});

export const maintenanceSMSSearchMaintenanceServiceItems = createAsyncThunk("maintenance/createEditMaintenanceService/searchMaintenanceServiceItems", async ({ }, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientQuery(
            `query ($openSearch: String){
                data: searchMaintenanceServiceItems(openSearch: $openSearch){
                  id
                  name
                }
            }`,
            {
                openSearch: ''
            },
            endpoints.GRAPHQL_GENERAL
        );
        return response.data
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceSMSSearchMaintenanceServiceItems');
        return rejectWithValue(exc);
    }
}
)


export const maintenanceSMSCreateMaintenanceServiceItem = createAsyncThunk("maintenance/createEditMaintenanceService/createMaintenanceServiceItem", async ({ name }, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientMutation(
            `mutation ($name: String!) {
                data: createMaintenanceServiceItem(name: $name){
                    id
                    name
                }
            }`,
            {
                name
            },
            endpoints.GRAPHQL_GENERAL
        );
        return response.data

    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceSMSCreateMaintenanceServiceItem');
        return rejectWithValue(exc);
    }
}
)


export const maintenanceScheduleMaintenanceServiceRedux = createSlice({
    name: 'maintenance/scheduleMaintenanceService',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalScheduleMaintenanceServiceUnit: (state, { payload }) => {
            state.open = payload.open
            state.maintenanceServiceUnit = payload?.data,
            state.maintenanceServiceUnitId = payload?.id
        }
    },
    extraReducers: (builder) => {
        //maintenanceSMSgetMaintenanceServiceUnit
        builder.addCase(maintenanceSMSgetMaintenanceServiceUnit.pending, (state, action) => {
            state.loadingMaintenanceServiceUnit = true
        });

        builder.addCase(maintenanceSMSgetMaintenanceServiceUnit.fulfilled, (state, action) => {
            state.maintenanceServiceUnit = action.payload,
                state.loadingMaintenanceServiceUnit = false
        });

        builder.addCase(maintenanceSMSgetMaintenanceServiceUnit.rejected, (state, action) => {
            state.loadingMaintenanceServiceUnit = false
            state.error = action.payload
        });

        //maintenanceSMSGetMaintenanceService
        builder.addCase(maintenanceSMSGetMaintenanceService.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceSMSGetMaintenanceService.fulfilled, (state, action) => {
            state.service = action.payload,
                state.loading = false
        });

        builder.addCase(maintenanceSMSGetMaintenanceService.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        });

        //maintenanceSMSGetPreviousReading
        builder.addCase(maintenanceSMSGetPreviousReading.pending, (state, action) => {
            state.loadingPreviousReading = true
        });

        builder.addCase(maintenanceSMSGetPreviousReading.fulfilled, (state, action) => {
            state.previousReading = action.payload,
                state.loadingPreviousReading = false
        });

        builder.addCase(maintenanceSMSGetPreviousReading.rejected, (state, action) => {
            state.loadingPreviousReading = false
            state.error = action.payload
        });

        //maintenanceSMSCreateEditMaintenanceServiceUnit
        builder.addCase(maintenanceSMSCreateEditMaintenanceServiceUnit.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceSMSCreateEditMaintenanceServiceUnit.fulfilled, (state, action) => {
            state.updateScheduleMaintenanceServiceResponse = action.payload,
                state.loading = false
        });

        builder.addCase(maintenanceSMSCreateEditMaintenanceServiceUnit.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        });

        //maintenanceSMSSearchMaintenanceServices
        builder.addCase(maintenanceSMSSearchMaintenanceServices.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceSMSSearchMaintenanceServices.fulfilled, (state, action) => {
            state.services = action.payload,
            state.loading = false
        });

        builder.addCase(maintenanceSMSSearchMaintenanceServices.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        });


        builder.addCase(maintenanceSMSSearchMaintenanceServiceItems.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceSMSSearchMaintenanceServiceItems.fulfilled, (state, action) => {
            state.serviceItems = action.payload
            state.loading = false;
        });

        builder.addCase(maintenanceSMSSearchMaintenanceServiceItems.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(maintenanceSMSCreateMaintenanceServiceItem.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceSMSCreateMaintenanceServiceItem.fulfilled, (state, action) => {
            state.newServiceItemResult = action.payload
            state.loading = false;
        });

        builder.addCase(maintenanceSMSCreateMaintenanceServiceItem.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

const { actions, reducer } = maintenanceScheduleMaintenanceServiceRedux;

export const {
    reset,
    openModalScheduleMaintenanceServiceUnit,
} = actions;

export default reducer;