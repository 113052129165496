export default {
    accountWebHooks_name: 'Name',
    accountWebHooks_url: 'Url',
    accountWebHooks_catalog: 'Type',
    accountWebHooks_status: 'Status',
    accountWebHooks_createdBy: 'Created By',
    accountWebHooks_createdOn: 'Created On',
    accountWebHooks_updatedBy: 'Updated By',
    accountWebHooks_updatedOn:'Updated On',
    accountWebHooks_webhooks:'Webhooks',
    accountWebHooks_createEditHeaders:'Headers',
    accountWebHooks_createEditSample:'Sample',
    accountWebHooks_createEditKey:'Key',
    accountWebHooks_createEditValue:'Value',
    accountWebHooks_deleteWarning:'Are you sure you want to remove the following Webhooks?',
    accountWebHooks_deleteTitle:'Delete WebHooks',
    accountWebHooks_typeCatalog_SystemAlert:'Alert Notification',
    accountWebHooks_typeCatalog_LastReading:'Device Reading',
    accountWebHooks_typeCatalog_Driver:'Driver',
    accountWebHooks_typeCatalog_Unit:'Unit',
    accountWebHooks_typeCatalog_VehicleInformation:'Vehicle Information',
    accountWebHooks_error_urlFormat:'The URL format is invalid',
    accountWebHooks_error_headerFields:'Please Complete the header Fields',
    accountWebHooks_title_create:'Add a New Webhook',
    accountWebHooks_title_edit:'Edit Webhook',
    accountWebHooks_success: "Success",
    accountWebHooks_successMessage: "Webhook deleted successfully",
    accountWebHooks_header_deleteWarning:'Are you sure you want to remove the  Header?',
    accountWebHooks_header_deleteTitle:'Delete Header',
    accountWebHooks_helpField_url:'This field provides a unique URL to the API server to which updated data for the requested catalog will be automatically sent',
    accountWebHooks_helpField_catalog:'This field provides a list of the catalog types that the webhook can subscribe to',
    accountWebHooks_helpField_sample:'It is possible to get some data from this object or from the entire object',
    accountWebHooks_Ocurrences: "Occurrences",
    accountWebHooks_Errors: "Errors",
    accountWebHooks_Date: "Date",
    accountWebHooks_LastMessage: "Last Message",
    accountWebHooks_Latestevents: "Logs",
    accountWebHooks_LogTitle: "Webhook Logs",
    accountWebHooks_ErrorandSuccessRate: "Error count and success rate",
    accountWebHooks_NoInvocationsYet: "no invocations yet"
};