// Dependencies
import React, { useState } from "react";
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

// Components
import ItemsList from './.components/itemsList';
import SelectedItems from './.components/selectedItems';
import Pill from "Components/Pill";

// Styles
import "./index.scss"

/**
 * Build a component to allow the user select items from entities.
 * @param {string} title - Component title.
 * @param {Array} entities - List of entities to show.
 * @param {string} selectedEntitiesTitle - Selected items section title.
 * @param {Array} selectedItems - The current selected items.
 * @param {Component} checkedIcon - Left side icon showed when the item is selected.
 * @param {Component} checkedIcon - Right side icon showed when the item is not selected.
 * @param {string} expandButtonLabel - Expand button label.
 * @param {string} collapseButtonLabel - Collapse button label.
 * @param {boolean} onlyItem - It allows to select only one item from a entity.
 * @param {boolean} multiple - It allows to select items from one or more entities (Not Implemented Yet).
 * @param {number} initialSize - Set the number of visible items before click the expand button (default 4).
 * @param {boolean} required - Sets if at least one item should be selected.
 * @param {Function} itemsFilterCallback - It allows to filter the items what will be displayed in the entitySelector.
 * @param {String} searchText - Text to make API searches of items.
 * @param {Object} selectedEntity - The selected entity.
 * @param {Function} onChangeEntity - Callback to trigger when an entity is changed.
 * @param {boolean} hideSelectedItems - Hide the Pills with the selected items
 * @param {Function} triggerCollapse - Callback to trigger when the collapse inside the ItemsList is used.
 */
const EntitySelector = (props) => {
  const {
    title,
    entities = [],
    selectedEntitiesTitle,
    selectedItems = [],
    checkedIcon,
    unCheckedIcon,
    expandButtonLabel,
    collapseButtonLabel,
    onlyItem,
    initialSize,
    required,
    itemsFilterCallback,
    searchText,
    selectedEntity,
    onChangeEntity,
    onChangeItems,
    hideSelectedItems,
    triggerCollapse,
    multiple,
    colorSelection,
    selectAllItems,
    onChangeSelectAll
  } = props;
  const isAllEntity = (selectedItems) => selectedItems[0]?.id == '-1';
  const getSelectedEntity = (entities) => entities?.find((entity) => entity?.id === selectedEntity?.id);

  /**
   * Gets all the items from an entity and returns only the selected ones
   * with the proper fields to be printed in the UI.
   * @param {Array} selectedItems - The previusly selected items.
   */
  const formatSelectedItems = (selectedItems) => {
    const { items: allEntityItems } = getSelectedEntity(entities) || { items: [] };
    let formatedItems = []
    if (!multiple) {
      formatedItems = allEntityItems.filter((item) => {
        const { id } = item;
        return selectedItems.some((selectedItem) => selectedItem.id == id);
      });
    } else {
      formatedItems = selectedItems
    }
    return formatedItems;
  };

  /**
   * Gets the items that are outside the initial offset.
   * @param {Array} formatedSelectedItems - The formated items given from the API.
   * @param {Array} selectedItems - All the selected items, including those outside the initial offset.
   * @returns {Array} All the selected items formated.
   */
  // eslint-disable-next-line no-unused-vars
  const getExtraSelectedItems = (formatedSelectedItems, selectedItems) => {
    const { searchOverOffset } = selectedEntity;
    const formatedSelectedItemsIds = formatedSelectedItems.map((item) => item.id.toString());
    const selectedItemsIds = selectedItems.map((item) => item.id);
    const extraItems = selectedItemsIds.filter((item) => !formatedSelectedItemsIds.includes(item));

    searchOverOffset && searchOverOffset(extraItems);

    return formatedSelectedItems;
  }

  /**
   * Get the items when there is a extra filter applyed, return all items if there is no a extra filter.
   * @param {Array} items - Items to filter.
   * @returns {Array} filtered items.
   */
  const extraFilteredItems = (items = []) => itemsFilterCallback ? itemsFilterCallback(items) : items;

  const setItems = () => multiple ? selectedItems : []
  return (
    <Grid className={` ${props.className || ""} root`}>
      <div className={cx('mt-10', 'mh-20')}>
        <Typography variant="subtitle2">
          {title}{required && <span className="text-danger"> *</span>}
        </Typography>
      </div>

      {
        entities?.length > 1 &&
        <Grid container direction="row" alignItems={'center'} spacing={1} color="primary" aria-label="outlined primary button group">
          {
            entities.map((entity, key) => {
              const { id } = entity;

              return (
                <Grid item key={`${id}-${key}`} sm={6}>
                  <Pill
                    textField={'name'}
                    item={entity}
                    outlined={id !== selectedEntity?.id}
                    onClick={(entity) => {
                      onChangeEntity(entity);
                      onChangeItems(entity.id === 'all' ? [{ __typename: 'All', name: 'All', id: -1 }] : setItems());
                      selectedEntity?.entitySearch && selectedEntity?.entitySearch('');
                    }}
                    style={{ border: '0', margin: '2px 0', width: '100%' }}
                    label="pill-label-text"
                    colorField="default"
                    colorSelection={colorSelection}
                  />
                </Grid>
              );
            })
          }
        </Grid>
      }

      {(selectedEntity?.items?.length > 0 || searchText) && (
        <ItemsList
          items={extraFilteredItems(selectedEntity?.items)}
          selectedItems={formatSelectedItems(selectedItems)}
          checkedIcon={checkedIcon}
          unCheckedIcon={unCheckedIcon}
          expandButtonLabel={expandButtonLabel}
          collapseButtonLabel={collapseButtonLabel}
          onlyItem={onlyItem}
          initialSize={initialSize}
          selectedEntity={selectedEntity}
          searchText={searchText}
          onChange={onChangeItems}
          onChangeSelectAll={onChangeSelectAll}
          triggerCollapse={triggerCollapse}
          selectAllItems={selectAllItems}
        />
      )}
      {!hideSelectedItems && (selectedItems?.length > 0 && !isAllEntity(selectedItems)) &&
        <SelectedItems
          selectedItems={formatSelectedItems(selectedItems)}
          itemLabel={selectedEntity?.fieldText}
          fieldValue={selectedEntity?.fieldValue}
          label={selectedEntitiesTitle}
          onChange={onChangeItems}
        />}
    </Grid>
  );
}

export default EntitySelector;