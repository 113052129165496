import React, { useCallback, useState } from 'react';
import cx from 'classnames';

import { Grid } from '@mui/material/';
import { Pagination as MaterialPagination } from '@mui/material';
import { usePagination } from '@mui/lab';
import { useIntl } from 'react-intl';

const Pagination = (props) => {

  const { count, removeAll, sortField, sortOrder, hideNumberOfRows, centerPagination, testPerPage, page, rowsPerPage, valueSelector } = props
  //const [rowsPerPage, setRowsPerPage] = useState(10)
  //const [page, setPage] = useState(props.defaultPage || 1)
  const availablePages = Math.ceil(count / rowsPerPage);
  const {messages} = useIntl()
  const onChangedPage = (newPage, newRowsPerPage, sortField, sortOrder) => {
    //setRowsPerPage(newRowsPerPage)
    //setPage(newPage)

    if (props.onChangedPage)
      props.onChangedPage(newPage, newRowsPerPage, sortField, sortOrder, availablePages)

  };
  
  const centerInCenterIfHideNumberofRows = () => {
    // If the number of rows is hide, so the pagination is center
    return hideNumberOfRows && centerPagination ? "center" : "flex-start"
  }

  const handlePagination = (event, newPage) => {
    onChangedPage(newPage, rowsPerPage, sortField, sortOrder)
  }

  const { items } = usePagination({
    count: availablePages,
    page: page,
    onChange: handlePagination,
    color: "secondary"
  });
  
  return (
    <div className="pagination-report-container">
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={centerPagination ? 12 : 6}
          container
          direction="row"
          justifyContent={centerInCenterIfHideNumberofRows()}
          alignItems={centerInCenterIfHideNumberofRows()}
        >
          <MaterialPagination
            count={availablePages}
            page={page}
            onChange={(event, newPage) => onChangedPage(newPage, rowsPerPage, sortField, sortOrder)}
            color="secondary"
            style={{ display: 'none' }}
          />
          <ul className="MuiPagination-ul">
            {
              items.map(({ page, type, selected, ...item }, index) => {
                let children = null;
                if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                  children = '…';
                } else if (type === 'page') {
                  children = (
                    <button type="button" className={cx('MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-page MuiPaginationItem-textSecondary', selected && 'Mui-selected')} {...item}>
                      {page}
                    </button>
                  );
                } else {
                  children = (
                    <button type="button" className="btn-link" {...item}>
                      {type === "previous" ? messages['pag_prev'] : messages['pag_next']}
                    </button>
                  );
                }

                return <li key={index}>{children}</li>;
              })
            }
          </ul>
        </Grid>
        { // In the subtables could be no necessary to show the number of row per page
          !hideNumberOfRows && (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
              //className="pagination-controls"
              >
                <div>
                  <span
                    className={cx('page-circle', rowsPerPage == 10 && 'page-circle-active')}
                    onClick={() => onChangedPage(1, 10, sortField, sortOrder)}
                  >
                    10
                </span>

                  <span
                    className={cx('page-circle', rowsPerPage == 25 && 'page-circle-active')}
                    onClick={() => onChangedPage(1, 25, sortField, sortOrder)}
                  >
                    25
                </span>

                  <span
                    className={cx('page-circle', rowsPerPage == 50 && 'page-circle-active')}
                    onClick={() => onChangedPage(1, 50, sortField, sortOrder)}
                  >
                    50
                </span>
                  {
                    !removeAll && (
                      <span
                        className={cx('page-circle', rowsPerPage == -99 && 'page-circle-active')}
                        onClick={() => onChangedPage(1, -99, sortField, sortOrder)}
                      >
                        all
                      </span>
                    )
                  }
                  <span className={`page-circle cursor-auto`}>{testPerPage ? testPerPage : 'per page'}</span>
                </div>
              </Grid>
            </>
          )
        }

      </Grid>
    </div>
  );
};

export default Pagination;
