// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  DASHBOARD_PANEL_SEARCH,
} from "Redux/actionTypes";

// Actions
import {
  searchDashboardPanelResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";


function* searchDashboardsRequest() {
  try {

    const query = `
            query searchDashboards {
                searchDashboards {
                    id
                    name
                    gadgets {
                        id
                        dashboardId
                        name
                        type
                        settings
                        columnPanel
                        columnOrder
                        refreshSeconds
                    }
                }
            }
        `;

    const response = yield call(
      clientQuery,
      query,
      {},
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.searchDashboards) {
      throw "Error while getting Dashboard list";
    }
    let dashboard = response.searchDashboards;
    yield put(searchDashboardPanelResponse({ dashboard }));
  } catch (exc) {
    yield put(searchDashboardPanelResponse({ dashboard: [] }));
    ExceptionManager(
      exc,
      "modules/dashboard/views/dashboardPanel/redux/saga",
      "searchDashboardsRequest"
    );
  }
}




function* watchMethods() {
  yield takeEvery(DASHBOARD_PANEL_SEARCH, searchDashboardsRequest);
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}