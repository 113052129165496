import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';
// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

import {baseReportConditions, generateReportInstance, getTimeZone, handleAsyncReport, isJsonFormat} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getLandmarkEngineHoursReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
    const {timeZone, sendByEmail, emailsTo, timeframeName, categoryName, delayed, dataResultPath} = report;
    const {filterType, filterArray, startRange, endRange, otherFilters, unitsSelected} = report.filters
    const baseConditionsQuery = baseReportConditions({
        report,
        name: 'LandmarkEngineHours',
        dataResultPath,
        timeZone,
        delayed,
        unitsSelected,
        timeframeName,
        filterType,
        filterArray,
        categoryName,
        startRange,
        endRange
    })

    try {
        let payload = {
            conditions: {
                ...baseConditionsQuery,
                sortField: {
                    field: sortField || "deviceId",
                    order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                }
            },
            limit: rowsPerPage,
            offset: page
        };

        if (report.export) {
            payload = {
                ...payload,
                limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
                offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
                conditions: {
                    ...payload.conditions,
                    exportOptions: {
                        sendByEmail,
                        emailsTo,
                        format: {
                            fileFormat: report?.exportType || "json",
                            formatOptions: "{}"
                        },
                        culture: report.lang,
                        timeZone: getTimeZone(timeZone),
                        folderName: "px-customer-reports",
                        filePrefixName: "landmarkEngineHoursReport",
                        columns: [
                            {
                                "column": "date",
                                "header": "Date"
                            },
                            {
                                "column": "idLabel",
                                "header": "Vehicle"
                            },
                            {
                                "column": "landmarkName",
                                "header": "Landmark"
                            },
                            {
                                "column": "groupIdLabel",
                                "header": "Vehicle Tags"
                            },
                            {
                                "column": "totalVisits",
                                "header": "Total Visits"
                            },
                            {
                                "column": "totalVisitsDuration",
                                "header": "Total Visits Duration"
                            },
                            {
                                "column": "engineHours",
                                "header": "Engine Hours"
                            }
                        ]
                    }
                }
            }
        }
        let data = yield call(
            clientQuery,
            `query getLandmarkEngineHoursReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
                Report: getLandmarkEngineHoursReport (conditions: $conditions, limit: $limit, offset: $offset) {
                  ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
                        `reportInstanceId`
                        : `
                    items {
                        id
                        idLabel
                        noData
                    }
                    total
                    limit
                    offset
                    category
                    reportInstanceId
                `}
                }
              }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
        yield handleAsyncReport(report, data);

    } catch (exc) {
        ExceptionManager(exc, 'reports/landmarkEngineHoursReport', 'getLandmarkEngineHoursReport');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}

export default getLandmarkEngineHoursReport;
