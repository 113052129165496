export const REPORTS_MAIN_SCHEDULE_SHOW_REPORTSETUP = "REPORTS_MAIN_SCHEDULE_SHOW_REPORTSETUP";
export const REPORTS_MAIN_SCHEDULE_HIDE_REPORTSETUP = "REPORTS_MAIN_SCHEDULE_HIDE_REPORTSETUP";

export const REPORTS_MAIN_SAVE_SCHEDULE_REPORT = "REPORTS_MAIN_SAVE_SCHEDULE_REPORT";
export const REPORTS_MAIN_SAVE_SCHEDULE_REPORT_RESULT = "REPORTS_MAIN_SAVE_SCHEDULE_REPORT_RESULT";

export const REPORTS_MAIN_GET_PRE_SCHEDULE_REPORT = "REPORTS_MAIN_GET_PRE_SCHEDULE_REPORT";
export const REPORTS_MAIN_GET_SCHEDULE_REPORT = "REPORTS_MAIN_GET_SCHEDULE_REPORT";
export const REPORTS_MAIN_GET_SCHEDULE_REPORT_RESULT = "REPORTS_MAIN_GET_SCHEDULE_REPORT_RESULT";

export const REPORTS_MAIN_UPDATE_SCHEDULE_REPORT = "REPORTS_MAIN_UPDATE_SCHEDULE_REPORT";
export const REPORTS_MAIN_UPDATE_SCHEDULE_REPORT_RESULT = "REPORTS_MAIN_UPDATE_SCHEDULE_REPORT_RESULT";

export const REPORTS_MAIN_UPDATE_SUBSCRIBERS = "REPORTS_MAIN_UPDATE_SUBSCRIBERS";
export const REPORTS_MAIN_UPDATE_SUBSCRIBERS_RESULT = "REPORTS_MAIN_UPDATE_SUBSCRIBERS_RESULT";

export const REPORTS_MAIN_FTP_LIST = "REPORTS_MAIN_FTP_LIST";
export const REPORTS_MAIN_FTP_LIST_RESULT = "REPORTS_MAIN_FTP_LIST_RESULT";

export const REPORTS_MAIN_FTP_UPDATE = "REPORTS_MAIN_FTP_UPDATE";
export const REPORTS_MAIN_FTP_UPDATE_RESULT = "REPORTS_MAIN_FTP_UPDATE_RESULT";

export const REPORTS_MAIN_FTP_DELETE = "REPORTS_MAIN_FTP_DELETE";
export const REPORTS_MAIN_FTP_DELETE_RESULT = "REPORTS_MAIN_FTP_DELETE_RESULT";
