import React from "react";
import { Box } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function PanelLabel({ value, message, row=false, onClick }) {
  return (
    <Box mt={"15px"} display='flex' onClick={onClick}>
      <label style={{marginRight:'13px', fontWeight:'bold', fontSize:'14px', color: '#000000', cursor: 'pointer'}}>{message}</label>
      <label style={{color: '#000000', cursor: 'pointer'}}>{value}</label>
      {row && <span style={{margin: '-1px'}}><ArrowDropDownIcon /></span>}
    </Box>
  );
}