//View Keys
export default {
  realtimemaps_title: 'Maps',
  realtimemaps_route_error: 'Directions request returned no results.',
  realtimemaps_polygon_maxpoints: 'The polygon must be 3-{maxPoints} points.',
  realtimemaps_route_limit: 'The route exceeds the 50 miles value set as limit, please set the start and end point again.',
  realtimemaps_drive: 'Drive',
  realtimemaps_motion: 'Motion',
  realtimemaps_motion_start: 'Motion Start',
  realtimemaps_motion_stop: 'Motion Stop',
  realtimemaps_ignitionOff: 'Ignition Off',
  realtimemaps_ignitionOn: 'Ignition On',
  realtimemaps_travelStart: 'Travel Start',
  realtimemaps_travelStop: 'Travel Stop',
  realtimemaps_geofenceIn: 'Geofence In',
  realtimemaps_geofenceOut: 'Geofence Out',
  realtimemaps_idle: 'Idle',
  realtimemaps_stop: 'Stop',
  realtimemaps_parked: 'Parked',
  realtimemaps_update: 'Update',
  realtimemaps_rapidAcceleration: 'Rapid Acceleration',
  realtimemaps_hardBrake: 'Hard Brake',
  realtimemaps_hardTurn: 'Hard Turn',
  realtimemaps_suddenStop: 'Sudden Stop',
  realtimemaps_other: 'Other',
  realtimemaps_landmark: 'Landmark',
  realtimemaps_geofence: 'Geofence',
  realtimemaps_offline: 'Offline',
  realtimemaps_address: 'Address',
  realtimemaps_useMapClick: 'Map Click',
  realtimemaps_unit: 'Unit',
  realtimemaps_latLng: 'Lat/Long',
  realtimemaps_latitude: 'Latitude',
  realtimemaps_longitude: 'Longitude',
};