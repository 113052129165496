import {
    MAP_LANDMARKS_GROUPS_DELETE_OPEN_MODAL,
    MAP_LANDMARKS_DELETE_LANDMARK_GROUPS,
    MAP_LANDMARKS_DELETE_LANDMARK_GROUPS_RESPONSE
} from 'Redux/actionTypes';

export const mapLandmarkGroupsOpenDeleteModal = (open, landmarkGroups) => ({
    type: MAP_LANDMARKS_GROUPS_DELETE_OPEN_MODAL,
    payload: { open, landmarkGroups },
});

export const mapLandmarksDeleteLandmarkGroups = (ids, newId) => ({
    type: MAP_LANDMARKS_DELETE_LANDMARK_GROUPS,
    payload: { ids, newId },
});

export const mapLandmarksDeleteLandmarkGroupsResponse = (data) => ({
    type: MAP_LANDMARKS_DELETE_LANDMARK_GROUPS_RESPONSE,
    payload: { data },
});