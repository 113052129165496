//View Keys
export default {
  realtimemaps_vehicledirections_units: 'Select Unit',
  realtimemaps_vehicledirections_landmark: 'Select Landmark',
  realtimemaps_vehicledirections_tolls: 'Avoid Tolls',
  realtimemaps_vehicledirections_highways: 'Avoid Highways',
  realtimemaps_vehicledirections_route: 'Optimize Route',
  realtimemaps_vehicledirections_from: 'From',
  realtimemaps_vehicledirections_to: 'To',
  realtimemaps_vehicledirections_options: 'Options',
  realtimemaps_vehicledirections_getdirections: 'Get Directions',
  realtimemaps_vehicledirections_nolatlng: 'No Latitude and Longitude for this unit',
  realtimemaps_vehicledirections_latError: 'Invalid latitude',
  realtimemaps_vehicledirections_lngError: 'Invalid longitude',
};