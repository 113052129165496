import React, { useEffect, useState, useCallback, memo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// Utils
import { getUserFormattedDate } from 'Core/data/Helpers';
import { NotificationManager } from 'Components/Notifications';

import {
	Button,
	Modal,
} from '@gpstrackit/gtc-react-controls';
import Table, { Column } from 'Components/Table';
import { NotificationContainer } from "Components/Notifications";
import Tabs, { TabContainer } from 'Components/Tabs';

//Actions
import {
	changeVehicles,
	clearRedux,
} from 'Redux/actions';
import {
	dtcGetDeviceDTC,
	openDTCModal,
	dtcClearDTC,
	reset,
} from './redux/slice';

//Styles
import './styles.scss';

const DiagnosticCodes = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const activeTable = useRef();
	const historyTable = useRef();
	const tabsRef = useRef();

	const {
		open,
		loading,
		device: vehicle,
		codes,
		loadingClearDTC,
		clearDTCResponse,
		numberTab,
		history
	} = useSelector(state => state.dtcRedux);
	const { user } = useSelector(state => state.securityRedux);
	const { getMarkerDetailsResponse } = useSelector(state => state.infoBubbleRedux);
	const { vehicles } = useSelector(state => state.maintenanceVehiclesTabRedux)

	const { messages } = useIntl();

	const [ tab, setTab ] = useState(navigate ? 1 : 0);
	const [selectedItems, setSelectedItems] = useState([]);

	useEffect(() => {
		if (open && clearDTCResponse && clearDTCResponse.Message) {
			dispatch(dtcGetDeviceDTC({ device: vehicle, history: true }));
			activeTable.current?.setSelectedItems([]);
			const total = selectedItems?.length;
			setSelectedItems([]);
			NotificationManager.success(
				'Success',
				clearDTCResponse.Message,
				3000,
				null,
				null,
				'warning',
			);
			setTab(1);
			dispatch(reset({ clearDTCResponse: null }))
			if (getMarkerDetailsResponse) {
				dispatch(clearRedux('INFO_BUBBLE', {
					getMarkerDetailsResponse: {
						...getMarkerDetailsResponse,
						markerDetail: {
							...getMarkerDetailsResponse?.markerDetail,
							unit: {
								...getMarkerDetailsResponse?.markerDetail?.unit,
								dtcCount: {
									history: getMarkerDetailsResponse?.markerDetail?.unit?.dtcCount?.history + total,
									active: getMarkerDetailsResponse?.markerDetail?.unit?.dtcCount?.active - total,
								}
							}
						}
					}
				}));
			}

			if (vehicles) {
				let newVehicles = vehicles?.map(v => {
					if (v?.id == vehicle?.id) {
						return {
							...vehicle,
							dtcCount: {
								history: vehicle?.dtcCount?.history + total,
								active: vehicle?.dtcCount?.active - total,
							}
						}
					}
					return v;
				});
				dispatch(changeVehicles({ vehicles: newVehicles }));
			}
		}
	}, [clearDTCResponse, open]);

	useEffect(() => {
		if (numberTab === 1 && open) setSelectedItems([]);
	}, [numberTab, open]);

	useEffect(() => {
		setTab(history ? 1 : 0);
	}, [history]);

	const handleClose = useCallback(() => {
		dispatch(openDTCModal(false));
		dispatch(reset())
		setSelectedItems([])
	});

	const getDescription = useCallback((code) => {
		if (!code?.description || typeof code?.description != 'string') return (<span>---</span>);

		let description = code?.description?.replace("[{description=", '');
		description = description?.replace("}]", '');
		return (<span>{description} ({code?.occurrences || 1})</span>);
	});

	const getCustomDescription = useCallback((code) => {
		if (!code?.description || typeof code?.description != 'string') return "0";

		let description = code?.description?.replace("[{description=", '');
		description = description?.replace("}]", '');
		return `${description} (${code?.occurrences || 1})`;
	});

	const getDate = useCallback((code) => {
		if (!code?.lastUnitTime) return (<span>---</span>);
		let date = getUserFormattedDate(user, parseInt(code?.lastUnitTime), messages, 'h:mm:ss A');
		return (<span>{date}</span>);
	});

	const title = `${messages['diagnosticCodes_title']} '${(vehicle?.Label || vehicle?.label || '')}'`;


	return (
		<Modal
			title={title}
			open={open}
			position="right"
			size="small"
			handleClose={handleClose}
			footer={(
				<div style={{ display: 'flex', alignItems: "center", width: '100%' }}>
					<Button
						isDisabled={selectedItems?.length === 0 || loadingClearDTC}
						variant='secondary'
						style={{
							marginRight: 8,
							backgroundColor: 'black',
							color: 'white', opacity: selectedItems?.length === 0 && '0.5'
						}}
						onClick={() => {
							let ids = selectedItems.map((code) => code?.id);
							dispatch(dtcClearDTC({ deviceId: vehicle.id, ids: ids }));
						}}
					>
						{messages['diagnosticCodes_clear']}
					</Button>
					<Button
						isDisabled={loading}
						onClick={handleClose}
						variant='primary'
					>
						{messages['diagnosticCodes_done']}
					</Button>
				</div>
			)}
		>
			<div className="dtc-container">
				<Tabs
					tab={tab}
					disabled={loading || loadingClearDTC}
					ref={tabsRef}
					onChange={(index) => {
						if (index !== tab) {
							dispatch(reset({ codes: [] }))
							dispatch(dtcGetDeviceDTC({ device: vehicle, history: index !== 0 }));
							setTab(index);
							setSelectedItems([])
						}
					}}
				>
					<TabContainer
						disabled={loading || loadingClearDTC}
						label={messages['diagnosticCodes_active']}
						index={0}
					>
						<Table
							data={codes}
							loading={loading || loadingClearDTC}
							setSelectedItems={setSelectedItems}
							heightAuto
							hidePagination
						>
							<Column label={messages['diagnosticCodes_code']} field="dtcCode" />
							<Column
								label={messages['diagnosticCodes_definition']}
								field="description"
								customValue={(code) => {
									return getCustomDescription(code);
								}}
							>
								{(code) => {
									return getDescription(code);
								}}
							</Column>
							<Column
								label={messages['diagnosticCodes_when']}
								field="lastUnitTime"
							>
								{(code) => {
									return getDate(code);
								}}
							</Column>
						</Table>
					</TabContainer>
					<TabContainer
						disabled={loading || loadingClearDTC}
						label={messages['diagnosticCodes_history']}
						index={1}
					>
						<Table
							data={codes}
							loading={loading}
							heightAuto
							hidePagination
							hideSelectableCheckbox
						>
							<Column label={messages['diagnosticCodes_code']} field="dtcCode" />
							<Column
								label={messages['diagnosticCodes_definition']}
								field="description"
								customValue={(code) => {
									return getCustomDescription(code);
								}}
							>
								{(code) => {
									return getDescription(code);
								}}
							</Column>
							<Column
								label={messages['diagnosticCodes_when']}
								field="updatedOn"
							>
								{(code) => {
									return getDate(code);
								}}
							</Column>
						</Table>
					</TabContainer>
				</Tabs>
			</div>
			<NotificationContainer />
		</Modal>
	);
};

export default memo(DiagnosticCodes);