//View Keys
export default {
  realtimemaps_layer_map_unit: 'Unit',
  realtimemaps_layer_map_date_time: 'Date / Time',
  realtimemaps_layer_map_event: 'Event',
  realtimemaps_layer_map_select_layer_type: 'Select layer type',
  realtimemaps_layer_map_route: 'Route',
  realtimemaps_layer_map_vehicle_trail: 'Vehicle Trail',
  realtimemaps_layer_map_using_layer_kmz: 'Using a KMZ/KML File',
  realtimemaps_layer_map_layer_kmz: 'Add New Layer',
  realtimemaps_layer_map_layer_kmz_delete: 'Delete Layer(s)',
  realtimemaps_layer_map_add: 'Add',
  realtimemaps_layer_map_delete: 'Delete',
  realtimemaps_layer_map_next: 'Next',
  realtimemaps_layer_map_cancel: 'Cancel',
  realtimemaps_layer_map_vehicletrails: 'Vehicle Trails',
  realtimemaps_layer_map_validatedate: 'The date range can not exceed 1 day',
  realtimemaps_layer_map_validateunit: 'Select a unit',
  realtimemaps_layer_map_no_routes_available: 'No routes available',
  realtimemaps_layer_map_description_route: 'This option allows you to select a route from the list. After selecting your option you can view it on the map temporarily.',
  realtimemaps_layer_map_description_vehicle_trail: 'This option allow you to select a vehicle to see the trail for a date range. By doing this, you can overlap this trail with any other geographical data pre-loaded in the map.',
  realtimemaps_layer_map_description_layer_kmz: 'This option allows you to choose a kmz/kml file with maps objects, lines, markers generated externally',
  realtimemaps_layer_map_color: "Color",
  realtimemaps_layer_map_back: "Back",
  realtimemaps_layer_map_add_new_layer: "Add New Layer",
  realtimemaps_layer_map_remove_layer: "Remove added layers",
  realtimemaps_layer_map_invalid_date: "Invalid date",
  realtimemaps_layer_map_select_route: "It is necessary to select a route",
  realtimemaps_layer_map_color_validation: "Select a color for vehicle trail",
  realtimemaps_layer_map_no_data: "There is no data for range selected",
  realtimemaps_layer_map_vehicle_trail_info: "The consultation has been limited to a maximum of 100 points on the map.",
  realtimemaps_layer_map_layer_attachments: "Attachments",
  realtimemaps_layer_map_layer_uploadFile: "The file was successfully uploaded",
  realtimemaps_layer_map_layer_warning: "Warning",
  realtimemaps_layer_map_layer_maxShowMessage: "Only 10 layers are allowed to be displayed.",
  realtimemaps_layer_map_layer_deleteMessage: "Are you sure you want to delete the previously selected ",
  realtimemaps_layer_map_layer_deleteMessage_layers: "Layer(s)?",
  realtimemaps_layer_map_layer_succesDelete: "Items deleted correctly!",
  realtimemaps_layer_map_layer_succesSave: "The changes was saved correctly!",
  realtimemaps_layer_map_layer_name: "File Name",
  realtimemaps_layer_map_layer_invalidName: "The name you are trying to use is already assigned to another file. Try another",
  realtimemaps_layer_map_layer_invalidFile: "The file you are trying to use is not valid. Try another",
  realtimemaps_layer_map_layer_searchOnMap: "Search layers on the map",
  realtimemaps_layer_map_layer_searchOnMapItem: "Search this layers on the map",
  realtimemaps_layer_map_layer_follow: "Follow",
  realtimemaps_layer_map_layer_empty_name_warning: "The name can not be empty",
  realtimemaps_layer_map_layer_max_name_warning: "The name can only be 25 characters",
  realtimemaps_layer_map_layer_used_name_warning: "The name is already being used",
  realtimemaps_layer_map_layer_files_uploaded: "Uploaded Files",
  realtimemaps_layer_map_layer_files_uploaded_no_files: "No files uploaded yet",
  realtimemaps_layer_map_layer_files_uploaded_max_20_files: "Max 20 files",
  realtimemaps_layer_map_layer_files_uploaded_max_20_files_warning: "Only 20 files are allowed to be uploaded.",
  realtimemaps_layer_map_layer_files_uploaded_duplicate_names: "There are files with the same name. Each file must have a unique name",
  realtimemaps_layer_map_layer_files_uploaded_exist_name: "Some of the files already have a name pre-loaded or you have more than one file with the same name. Each file must have a unique name and cannot be longer than 50 characters or cannot be empty."






};