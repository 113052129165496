import {
    //TYPES
    MAP_TEST1,
    CLEAR_REDUX
  } from "Redux/actionTypes";
  
  const INIT_STATE = {
      test: null
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case MAP_TEST1:
        return { ...state, smodalManageLandmark: action.payload };
  
      case CLEAR_REDUX:
        return ["", "MAP_LANDMARKS"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state };
  
      default:
        return state ;
    }
  };