import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../../../core/logManager';
import axios from 'axios';
import moment from 'moment';

import { resetALL, catalogLoad } from '../../../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    reponsehDeviceReadingVideo: {},
    reponsehClipRequestSmartwitness: {},
    error: {},
    loadingClipRequestSmartwitness: false
};

const route = 'modules/video/views/main/.components/clips/redux/slice'


export const createClipRequestSmartwitness = createAsyncThunk('deviceReadingVideo/createClipRequestSmartwitness', async ({ date,deviceId,durationAfter,durationBefore,esn,mediaType }, { rejectWithValue, dispatch }) => {
    try {

           
        let dateFrom = moment(date);
        let dateTo = moment(date);
        if(durationBefore)
            dateFrom.subtract(durationBefore, 'seconds');

        if(durationAfter)
            dateTo.add(durationAfter, 'seconds');

        const url = (mediaType==="image")?`${endpoints.SMARTWITNESS_API_URL}/SmartWitness/RESTCommandWebService/ExecuteServerCommand/Snapshots/ManualBackupJPG`:`${endpoints.SMARTWITNESS_API_URL}/SmartWitness/RESTCommandWebService/ExecuteServerCommand/Device/ManualBackup`;

        let config = {
            method: 'post',
            url:url, 
            headers: { 'APIKey': endpoints.SMARTWITNESS_API_KEY},
            params : {
                RecorderID: esn,
                Storage: "All",
                FromTime: dateFrom.toISOString(),
                ToTime: dateTo.toISOString(),
                CameraChannel:"*",
                GoToDateTime:date
            }
          };
          
         let manualBackupRequest = await axios.request(config).then((response) => {return response.data }).catch((error) => {return error;});

          if(manualBackupRequest?.Code!==202){
            return {error:true,erroMessage:manualBackupRequest?.response?.data,data:null}
          }


        const result = await clientMutation(`mutation createClipRequest ($deviceId: String!, $dateRange: AWSDateTimeRange!, $thumbnailUrl: String, $transactionId: String, $tripID: String, $mp4Url: String, $rotate: String, $clipID: String) {
                    createClipRequest (deviceId: $deviceId, dateRange: $dateRange, thumbnailUrl: $thumbnailUrl, transactionId: $transactionId, tripID: $tripID, mp4Url: $mp4Url, rotate: $rotate, clipID: $clipID) {
                        id
                        accountId
                        createdOn
                        deviceId
                        esn
                        label
                        status
                        time
                        tripID
                        clipID
                        rotate
                        url
                        startDate
                        endDate
                        notes
                        starred
                        errorMsg
                        deviceModelId
                        deviceModelName
                        driverName
                        thumbnailUrl
                        transactionId
                        unitId
                        details
                    }
                }`,
        {
            deviceId,
            dateRange:{ start: dateFrom.toDate(), end: dateTo.toDate() },
            transactionId:manualBackupRequest?.MoreInfo?.RequestID,
            thumbnailUrl:" ", 
            clipID:" ", 
            mp4Url:" "
          },
            endpoints.GRAPHQL_GENERAL
        )


        if(!result?.createClipRequest && result?.errors?.length)
            return {error:true,erroMessage:result?.errors[0].message,data:null}

        return {error:false,data:result?.createClipRequest}

    } catch (exc) {
        return {error:true,erroMessage:{Message:exc?.error},data:null}
    }
});

//Slice
const clipRequestRedux = createSlice({
    name: 'clip/request',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        close(state, action) {
            state.openModal = false
        }
    },
    extraReducers: (builder) => {


        builder.addCase(createClipRequestSmartwitness.pending, (state, action) => {
            state.loadingClipRequestSmartwitness = true
        });
        builder.addCase(createClipRequestSmartwitness.fulfilled, (state, action) => {
            state.loadingClipRequestSmartwitness = false;
            state.reponsehClipRequestSmartwitness = action.payload;
        });
        builder.addCase(createClipRequestSmartwitness.rejected, (state, action) => {
            state.loadingClipRequestSmartwitness = false;
            state.error = action.payload;
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = clipRequestRedux;

// Extract and export each action creator by name
export const { reset, close } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;