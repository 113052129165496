export const SETTINGS_KEY_FOB_SEARCH = "SETTINGS_KEY_FOB_SEARCH";
export const SETTINGS_KEY_FOB_SEARCH_RESPONSE = "SETTINGS_KEY_FOB_SEARCH_RESPONSE";
export const SETTINGS_KEY_FOB_OPEN_CREATE_EDIT_MODAL = "SETTINGS_KEY_FOB_OPEN_CREATE_EDIT_MODAL";


export const SETTINGS_KEY_FOB_CREATE_EDIT = "SETTINGS_KEY_FOB_CREATE_EDIT";
export const SETTINGS_KEY_FOB_CREATE_EDIT_RESPONSE = "SETTINGS_KEY_FOB_CREATE_EDIT_RESPONSE";

export const SETTINGS_KEY_FOB_OPEN_DELETE_MODAL  = "SETTINGS_KEY_FOB_OPEN_DELETE_MODAL";
export const SETTINGS_KEY_FOB_DELETE             = "SETTINGS_KEY_FOB_DELETE";
export const SETTINGS_KEY_FOB_DELETE_RESPONSE    = "SETTINGS_KEY_FOB_DELETE_RESPONSE";

export const SETTINGS_KEY_FOB_GET_DRIVER = "SETTINGS_KEY_FOB_GET_DRIVER";
export const SETTINGS_KEY_FOB_GET_DRIVER_RESPONSE = "SETTINGS_KEY_FOB_GET_DRIVER_RESPONSE";