import React from 'react'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './styles.scss'
import moment from 'moment';


const localizer = momentLocalizer(moment)

const Calendar = ({
    clipsPerDay,
    selectedTime,
    filterComponents,
    min,
    max,
    height = 500
}) => {
    return (
        <BigCalendar
            selectable
            localizer={localizer}
            events={clipsPerDay}
            startAccessor="start"
            endAccessor="end"
            date={new Date(selectedTime)}
            style={{ height: height }}
            view="day"
            step={30}
            components={filterComponents}
            toolbar={false}
            min={min}
            max={max}
        />
    )
}


export default Calendar