import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import moment from 'moment';

/**
 * Hook to validate information from selector and filters changes
 * @param {*} dataSelector
 * @param {*} filterSelector
 */
export const useDataToValidate = (dataSelector, filterSelector, extraData) => {

  let lastSync = moment()
  let lastDataSync = []
  let lastFilers
  let lastIds = []

  //Function to validate changes in two arrays validate specific ids and properties changed
  const useValidateChanges = (lastData, nextData, ids, idProperty, properties = [], validateAll, callbackChanges) => {
    let refresh = false;

    //if empty is invalid
    if (lastData.length === 0 && nextData.length === 0) {
      return false
    }

    if (lastData.length > 0 && nextData.length > 0 && (!validateAll && (!ids || ids.length === 0))) {
      return false
    }

    let lastDataFilter
    let nextDataFilter
    if (validateAll) {
      lastDataFilter = lastData
      nextDataFilter = nextData
    }
    else {
      lastDataFilter = ids.length > 0 && lastData.filter(x => ids.includes(x[idProperty].toString())) || lastData
      nextDataFilter = ids.length > 0 && nextData.filter(x => ids.includes(x[idProperty].toString())) || nextData
    }

    if (lastDataFilter.length != nextDataFilter.length) {
      return true;
    }

    let propertiesChangesGlobal = []
    let newDataChanges = []
    lastDataFilter.forEach(ld => {
      let internalRefresh = false;
      const nd = nextDataFilter?.find(x => x[idProperty].toString() === ld[idProperty].toString())
      if (properties.length > 0) {
        let propertiesChanges = []
        properties.forEach(p => {
          if (nd && ld && !_.isEqual(ld[p], nd[p])) {
            propertiesChanges.push({
              id: nd[idProperty],
              atribute: p,
              oldValue: ld[p],
              newValue: nd[p]
            })
            internalRefresh = true
            refresh = true
          }
        })
        if (internalRefresh && propertiesChanges.length > 0) {
          propertiesChangesGlobal.push(...propertiesChanges)
        }
      }
      else
        if (!_.isEqual(ld, nd)) {
          internalRefresh = true
          refresh = true
        }

      if (callbackChanges && internalRefresh) {
        newDataChanges.push(nd)
      }

    })

    if (callbackChanges && newDataChanges.length > 0) {
      callbackChanges({
        data: newDataChanges,
        changes: propertiesChangesGlobal
      })
    }

    return refresh;
  }

  //when send custom filter selector to validate changes and force sync
  let selectors = [dataSelector]
  if (filterSelector){
    selectors.push(filterSelector)
  }

  return createSelector(
    selectors,
    (data, filters) => (ids, idProperty, propertiesToValidate, firstLoad, loadCallback, validateAll, callbackChanges) => {

      //validate force sync
      let forceSync = true;

      //if necesary because when memoize the function the lazy load is problem
      if (firstLoad) {
        //console.log("firstLoad")
        loadCallback();
        lastSync = moment()
        lastDataSync = []
        lastFilers = filters
        return lastDataSync
      }

      //if necesary send data when changed ids
      if (!_.isEqual(lastIds, ids)) {
        //console.log("force sync change IDs", lastIds, ids)
        lastSync = moment();
        lastIds = ids;
        if (ids.length === 0) {
          return lastDataSync
        }
        else {
          lastDataSync = data;
          return data;
        }
      }

      //handle time to refresh
      let date = moment(lastSync).add(0.2, 'seconds');

      if (moment() > date) {
        if (lastDataSync.length === 0 && data.length > 0) {
          //console.log("datatime")
          //console.log("force sync array empty", data)
          lastSync = moment();
          lastDataSync = data;
          return data;
        }
      }

      //when has a custom filter selector to handle change logic
      if (filters) {
        if (!lastFilers) {
          lastFilers = filters
        }

        //when filters changed is necesary force sync
        forceSync = !_.isEqual(lastFilers, filters)
        if (forceSync) {
          //console.log("force")
          lastSync = moment();
          lastDataSync = data;
          lastFilers = filters
          return data;
        }
      }


      if (moment() > date) {
        //console.log("moment data")

        //validate changes to refresh
        forceSync = useValidateChanges(lastDataSync, data, ids, idProperty, propertiesToValidate, validateAll, callbackChanges)
        lastSync = moment();
        if (forceSync) {
          //console.log("sync new data")
          lastDataSync = data;
          return data;
        }
      }

      return lastDataSync
    }
  )
}

/**
 * Hook to compute date respecto ids array and specific properties
 * @param {*} dataSelector
 * @param {*} idProperty
 * @param {*} propertiesToValidate
 */
export const useComputeData = (dataSelector, idProperty, propertiesToValidate, validateAll = false, callbackSingleChange) => {

  //Validate FirstLoad In component for lazy load
  const [firstLoad, setFirstLoad] = useState(true)
  const [ids, setIds] = useState([])
  const loadCallback = useCallback(() => {
    if (firstLoad)
      setFirstLoad(false)
  }, [])

  //handle when change items to validate changes
  const changeIds = useCallback((value, field = "id") => {
    //", value)
    let idsN = value?.map(x => x[field].toString());
    if (!_.isEqual(ids, idsN)) {
      //console.log("changeIds", ids, idsN)
      setIds(idsN)
    }
  }, []);

  //Return selector with compute data
  let data = useSelector(
    (state) => dataSelector(state)(ids, idProperty, propertiesToValidate, firstLoad, loadCallback, validateAll, callbackSingleChange), (prevProps, nextProps) => {
      //console.log("return", _.isEqual(prevProps, nextProps))
      return _.isEqual(prevProps, nextProps)
    })

  return [data, changeIds]
}
