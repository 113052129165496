import {
    FSM_SETTINGS_QUICK_MESSAGE_SEARCH,
    FSM_SETTINGS_QUICK_MESSAGE_SEARCH_RESPONSE,
    FSM_SETTINGS_QUICK_MESSAGE_CREATE,
    FSM_SETTINGS_QUICK_MESSAGE_CREATE_RESPONSE,
    FSM_SETTINGS_QUICK_MESSAGE_UPDATE,
    FSM_SETTINGS_QUICK_MESSAGE_UPDATE_RESPONSE,
    FSM_SETTINGS_QUICK_MESSAGE_DELETE,
    FSM_SETTINGS_QUICK_MESSAGE_DELETE_RESPONSE,
    FSM_SETTINGS_QUICK_MESSAGE_OPEN_CREATE_EDIT,
    FSM_SETTINGS_QUICK_MESSAGE_OPEN_DELETE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loadingQuickMessage: false,
    quickMData: [],
    QuickMessage: {},
    loadingCreateEdit: false,
    openCreateEditQuickMessage: false,
    loadingDelete: false,
    response: {},
    responseDelete: false,
    openDelete: false,
    quickMessageDelete: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case FSM_SETTINGS_QUICK_MESSAGE_SEARCH:
            return {
                ...state,
                loadingQuickMessage: true,
                quickMData: []
            };

        case FSM_SETTINGS_QUICK_MESSAGE_SEARCH_RESPONSE:
            return {
                ...state,
                loadingQuickMessage: false,
                quickMData: action?.payload?.quickMessage
            };

        case FSM_SETTINGS_QUICK_MESSAGE_CREATE:
            return {
                ...state,
                loadingCreateEdit: true,
                response: {}
            };

        case FSM_SETTINGS_QUICK_MESSAGE_CREATE_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                response: action.payload
            };

        case FSM_SETTINGS_QUICK_MESSAGE_UPDATE:
            return {
                ...state,
                loadingCreateEdit: true,
                response: {}
            };

        case FSM_SETTINGS_QUICK_MESSAGE_UPDATE_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                response: action.payload
            };

        case FSM_SETTINGS_QUICK_MESSAGE_DELETE:
            return {
                ...state,
                loadingDelete: true,
                responseDelete: false
            };

        case FSM_SETTINGS_QUICK_MESSAGE_DELETE_RESPONSE:
            return {
                ...state,
                loadingDelete: false,
                responseDelete: action.payload?.data
            };

        case FSM_SETTINGS_QUICK_MESSAGE_OPEN_CREATE_EDIT:
            return {
                ...state,
                openCreateEditQuickMessage: action.payload?.open,
                QuickMessage: action.payload?.quickMessage,
            };

        case FSM_SETTINGS_QUICK_MESSAGE_OPEN_DELETE:
            return {
                ...state,
                openDelete: action.payload?.open,
                quickMessageDelete: action.payload?.quickMessages,
            };

        case CLEAR_REDUX:
            return ["", "FSM_SETTINGS_QUICK_MESSAGE"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return state;
    }
};