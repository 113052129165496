
import React, { forwardRef } from 'react'
import MapsController from './mapsController'
import { MapProvider } from './providers/enums';

const Map = forwardRef((props, ref) => {
  return (
    <MapsController
      ref={ref}
      mapProvider={MapProvider.Google}
      {...props}
    />
  )
})

export default Map