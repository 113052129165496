import {
    ACCOUNT_TAGS_OPEN_VIEW_ENTITIES_MODAL,
    ACCOUNT_TAGS_VIEW_ENTITIES_SEARCH_ENTITIES,
    ACCOUNT_TAGS_VIEW_ENTITIES_SEARCH_ENTITIES_RESPONSE
} from "Redux/actionTypes";

export const accountTagsOpenViewEntitiesModal = (open, tag, tab, onlyView) => {
    return {
        type: ACCOUNT_TAGS_OPEN_VIEW_ENTITIES_MODAL,
        payload: { open, tag, tab, onlyView },
    };
};

export const accountTagsViewEntitiesSearchEntities = (filters) => ({
    type: ACCOUNT_TAGS_VIEW_ENTITIES_SEARCH_ENTITIES,
    payload: { filters },
});

export const accountTagsViewEntitiesSearchEntitiesResponse = (response) => ({
    type: ACCOUNT_TAGS_VIEW_ENTITIES_SEARCH_ENTITIES_RESPONSE,
    payload: { response },
});