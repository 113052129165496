/* eslint-disable no-unused-vars */
import React, { Component, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
//import ReactSelect from "react-select";
//import CustomSelectInput from "../Select/CustomSelectInput";
import { useTimePicker } from 'Core/hooks';
import { Icon, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import 'react-datepicker/dist/react-datepicker.css';
import './inputDate.scss';
import Input from 'Components/Input';
import CustomDatePicker from './DatePicker';
import Button from 'Components/Button';
import { GetFormatForMoment } from 'Core/data/Helpers';
import { v4 as uuidv4 } from 'uuid';

//language
import es from "date-fns/locale/es"; // the locale you want
registerLocale("es", es);

//international
import { useIntl, injectIntl } from "react-intl";


const ampmOptions = [{ 'id': 1, 'name': 'AM' }, { 'id': 2, 'name': 'PM' }];

export const TimeInput = ({ ampm, handleChange, hour, minute, second, formatNumberToTwoDigists, onChange, paddingRight, showSeconds }) => {
  const onBlur = (value, type) => {
    let twoDigist = formatNumberToTwoDigists(value);
    if (type === "hour" && value == "00") {
      twoDigist = "01";
    }
    const result = handleChange(twoDigist, type);
    if (onChange && result) {
      onChange(result);
    }
  }

  const onChangeHour = (value) => {
    let hour;
    if (value.length === 3) {
      const tempHour = value.slice(-2);
      hour = (parseInt(tempHour < 60 ? tempHour : 0, 10) || 0);
    } else if (value < 60) {
      hour = (value || 0);
    }

    const result = handleChange(hour, 'hour');
    if (onChange && result) {
      onChange(result);
    }
  }

  const onChangeMinute = (value) => {
    let newMinute;
    if (value.length === 3) {
      const tempMinute = value.slice(-2);
      newMinute = (parseInt(tempMinute < 60 ? tempMinute : 0, 10) || 0);
    } else if (value < 60) {
      newMinute = (value || 0);
    }

    const result = handleChange(newMinute, 'minute');
    if (onChange && result) {
      onChange(result);
    }
  }

  const onChangeSecond = (value) => {
    let newSecond;
    if (value.length === 3) {
      const tempSecond = value.slice(-2);
      newSecond = (parseInt(tempSecond < 60 ? tempSecond : 0, 10) || 0);
    } else if (value < 60) {
      newSecond = (value || 0);
    }

    const result = handleChange(newSecond, 'second');
    if (onChange && result) {
      onChange(result);
    }
  }

  const onChangeAMPM = (value) => {
    const result = handleChange(value, 'am/pm');
    if (onChange && result) {
      onChange(result);
    }
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className="ctnSelectTime"
      style={{
        width: '100%',
        paddingTop: 10,
        borderTop: (showSeconds ? '0px solid #ccc' : '1px solid #ccc')
      }}
    >
      {
        !showSeconds &&
        <Grid item>
          <div className="form-item">
            <Icon style={{ marginRight: 4 }}>
              query_builder
            </Icon>
          </div>
        </Grid>
      }

      <Grid item xs>
        <Input
          onlyPositive
          preventOnChange
          onChange={onChangeHour}
          style={showSeconds ? { width: 20 } : {}}
          containerStyle={{ paddingTop: 0 }}
          value={hour.value}
          className="inputDateTime"
          type={'time'}
          min={0}
          max={12}
          id={`hourInputDateTime-${uuidv4()}`}
          required
          onBlur={() => {
            onBlur(hour.value || '00', 'hour');
          }}
        />
      </Grid>

      <Grid item>
        <span style={{ paddingRight: 5 }}>:</span>
      </Grid>

      <Grid item xs>
        <Input
          onlyPositive
          preventOnChange
          value={minute.value}
          onChange={onChangeMinute}
          style={showSeconds ? { width: 20 } : {}}
          containerStyle={{ paddingTop: 0 }}
          type={'time'}
          min={0}
          max={59}
          required
          className="inputDateTime"
          onBlur={() => {
            onBlur(minute.value || '00', 'minute');
          }}
        />
      </Grid>

      {
        showSeconds &&
        <Grid item>
          <span style={{ paddingRight: 5 }}>:</span>
        </Grid>
      }

      {
        showSeconds &&
        <Grid item xs>
          <Input
            onlyPositive
            preventOnChange
            value={second?.value}
            onChange={onChangeSecond}
            style={{ width: 20 }}
            containerStyle={{ paddingTop: 0 }}
            type={'time'}
            min={0}
            max={59}
            required
            className="inputDateTime"
            onBlur={() => {
              onBlur(second?.value || '00', 'second');
            }}
          />
        </Grid>
      }

      <Grid item xs>
        <div className="form-item">
          <select
            className="input-date-select"
            value={ampm?.name}
            onChange={(e) => {
              const value = e?.target?.value;
              onChangeAMPM(ampmOptions.find(option => option.name === value));
            }}
          >
            {
              ampmOptions.map(option => {
                return (
                  <option key={option.id} value={option.name}>{option.name}</option>
                );
              })
            }
          </select>
        </div>
      </Grid>
    </Grid>
  )
}

const DateTimePicker = (props) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [valueTxt, setValueTxt] = useState('');
  const dateTimePickerForm = useRef();
  const timePicker = useTimePicker();
  const { messages, locale } = useIntl();
  
  useEffect(() => {
    if (props.value) {
      setDate(props.value);
      const momentValue = moment(props.value);
      timePicker.setHour(momentValue.format('hh'), momentValue.format('mm'), momentValue.format('ss'), momentValue.format('A'));
      const completeHour = timePicker.getCompleteHour(momentValue.format('hh'), momentValue.format('mm'), momentValue.format('ss'), momentValue.format('A'));
      setFormatValueTxt(completeHour);
    }
  }, [props.value]);

  const setFormatValueTxt = (completeHour) => {
    let newDate = moment(props.value).locale(locale || "en").format('MMM DD, yyyy');
    newDate = `${newDate} ${messages["at_time"]} ${completeHour || timePicker.getCompleteHour()}`;
    setValueTxt(newDate);
    return newDate;
  }

  const getTime = (time) => {
    let timeStartMoment = moment(time, 'hh:mm:ss A');
    return timeStartMoment.format('hh:mm:ss A');
  }

  const combineDateWithTime = (date, time) => {
    const momentFormat = GetFormatForMoment('m/d/Y');
    let newStart = moment(date).format(momentFormat);
    newStart = `${newStart} ${getTime(time)}`;
    return new Date(newStart);
  }

  return (
    <div>
      <Input
        id={`txtCustomDatePicker-${uuidv4()}`}
        type="text"
        label={''}
        placeholder={''}
        value={valueTxt}
        onClick={() => {
          setOpen(show => !show);
          props.onClick()
        }}
        onClickIcon={() => {
          setOpen(show => !show);
          props.onClick()
        }}
        onChange={props.onChange}
        icon={'today'}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        readOnly={true}
      />

      <Grid
        container
        className={`container container-date-time-picker`}
        spacing={0}
        style={props.style ? {
            display: open ? 'flex' : 'none',
            ...props.style
          } : {
            width: props.direction === "row" ? '520px' : '263px',
            left: '0px',
            display: open ? 'flex' : 'none',
        }}
        direction={props.direction ? props.direction : 'column'}
      >
        <Grid item sm={props.direction === "row" ? 6 : 12}>
          <DatePicker
            locale={locale}
            disabledKeyboardNavigation
            dateFormat={props.format || 'MMM d, yyyy'}
            selected={date}
            onChange={value => {
              setDate(value);
            }}
            inline
            popperPlacement="top-start"
            minDate={props.minDate}
            maxDate={props.maxDate}
            includeDates={props?.includeDates?.length && props.includeDates}
          />
        </Grid>
        <Grid item sm={props.direction === "row" ? 6 : 12}>
          <Grid container>
            <TimeInput
              {...timePicker}
              direction={props.direction}
              showSeconds={props.showSeconds || false}
              onChange={(valueTime) => {
                const combineDateTime = combineDateWithTime(date, valueTime);
                setDate(combineDateTime);
              }}
            />
          </Grid>
          <Grid container alignContent="center" justifyContent="space-between" className="ctnBtnsTime top10" spacing={1}>
            <Grid item sm={6}>
              <Button
                id="btnCloseTimePicker"
                onClick={(e) => {
                  setOpen(false);
                  e.preventDefault();
                }}
                className="btn-cancel full-width btn-cancel-time"
                type="button"
              >
                {props.cancelMessage || messages['Cancel']}
              </Button>
            </Grid>
            <Grid item sm={6}>
              <Button
                id="btnConfirmDateTime"
                onClick={(e) => {
                  e.preventDefault()
                  setOpen(false);
                  props.onApply(date);
                }}
                className="btn-blue full-width"
                type="button"
              >
                {props.confirmMessage || messages['Apply']}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
class InputDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
      errorMessage: null,
    };
    this.validate = this.validate.bind(this);
    this.handleRange = this.handleRange.bind(this);

  }

  validate(val) {
    let isValid = true;
    let errorMessage;
    let value = val || this.props.value;

    if (this.props.required) {
      if (!value || value.length === 0) {
        isValid = false;
        errorMessage = this.props.requiredMessage;
      }
    }

    let min = this.props.min;
    let max = this.props.max;

    if (value && this.props.type === 'time') {
      value = new Date(1900, 1, 1, value.getHours(), value.getMinutes(), value.getSeconds());
      min = min && new Date(1900, 1, 1, min.getHours(), min.getMinutes(), min.getSeconds());
      max = max && new Date(1900, 1, 1, max.getHours(), max.getMinutes(), max.getSeconds());
    } else if (value && this.props.type === 'date') {
      value = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0);
      min = min && new Date(min.getFullYear(), min.getMonth(), min.getDate(), 0, 0, 0);
      max = max && new Date(max.getFullYear(), max.getMonth(), max.getDate(), 0, 0, 0);
    }

    if (value && min) {
      if (value < min) {
        isValid = false;
        errorMessage = this.props.minMessage;
      }
    }

    if (value && max) {
      if (value > max) {
        isValid = false;
        errorMessage = this.props.maxMessage;
      }
    }

    this.setState({ isValid, errorMessage });
    return isValid;
  }

  handleRange(type, value) {
    if (type === 'start') {
      this.props.onChange({
        start: value,
        end: this.props.valueEnd
      })
    }

    if (type === 'end') {
      this.props.onChange({
        start: this.props.value,
        end: value
      })
    }

  }

  changeSelectorDateValue(obj) {
    let startDate = this.state.startDate,
      endDate = this.state.endDate,
      lastValue = this.state.lastValue;
    if (obj.key === 1) {
      startDate = new Date();
      endDate = new Date();
      lastValue = obj;
    } else if (obj.key === 2) {
      startDate = new Date();
      endDate = new Date();
      startDate.setDate(startDate.getDate() - 1);
      endDate.setDate(endDate.getDate() - 1);
      lastValue = obj;
    } else if (obj.key === 3) {
      startDate = new Date();
      endDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
      //endDate.setDate(date.getDate() - 1);
      lastValue = obj;
    }
    startDate.setHours(12, 0, 0, 0);
    endDate.setHours(23, 59, 59, 59);
    this.setState({ selectorValue: obj, lastValue, startDate, endDate });
    if (obj.key != 4) {
      if (this.props.onChange) {
        this.props.onChange(startDate, endDate);
      }
    }
  }

  renderType(props) {

    const { messages, locale } = this.props.intl;

    switch (this.props.type) {
      default:
      case 'date':
        return <DatePicker {...props}  includeDates={this.props.includeDates?.length && this.props.includeDates} locale={locale} dateFormat={this.props.format || 'MMM d, yyyy'} isClearable />;
      case 'time':
        return (
          <DatePicker
            {...props}
            locale={locale}
            minTime={props.minTime || ''}
            maxTime={props.maxTime || ''}
            showTimeSelect
            showTimeSelectOnly
            timeCaption={messages['time']}
            timeIntervals={this.props.timeIntervals || 15}
            dateFormat={this.props.format || 'h:mm aa'}
            injectTimes={this.props.injectTimes}
            popperPlacement={this.props.popperPlacement}
            popperModifiers={this.props.popperModifiers}
          />
        );
      case 'datetime':

        return (
          <div>
            <DateTimePicker
              {...this.props}
              onApply={this.props.onApply}
              cancelMessage={this.props.cancelMessage}
              confirmMessage={this.props.confirmMessage}
              onChange={this.props.onChange}
              onClick={() => {
                if (!this.state.isValid && this.state.errorMessage) {
                  this.setState({ isValid: true, errorMessage: null })
                }
              }}
            />
          </div>
        )
      case 'range':
        return (
          <CustomDatePicker
            onChange={this.props.onChange}
            value={this.props.value}
            valueEnd={this.props.valueEnd}
            isVertical={false}
            maxDate={this.props.maxDate}
            minDate={this.props.minDate}
            onClose={this.props.onClose}
            classNameDate={this.props.classNameDate}
          />
        );
      case 'rangetime':
        return (
          <CustomDatePicker
            classNameDate={this.props.classNameDate}
            withTime={true}
            value={this.props.value}
            valueEnd={this.props.valueEnd}
            maxDate={this.props.maxDate}
            minDate={this.props.minDate}
            onChange={this.props.onChange}
            isVertical={false}
            style={{ ...this.props.style }}
          />
        );
      case 'rangetimemodal':
        return (
          <CustomDatePicker
            classNameDate={this.props.classNameDate}
            withTime={true}
            value={this.props.value}
            valueEnd={this.props.valueEnd}
            maxDate={this.props.maxDate}
            minDate={this.props.minDate}
            onChange={this.props.onChange}
            isVertical={false}
            style={{ ...this.props.style }}
            showModal={true}
            handleCloseModal={this.props.handleCloseModal}
          />
        );
      case 'rangevertical':
        return (
          <div>
            <CustomDatePicker
              value={this.props.value}
              valueEnd={this.props.valueEnd}
              onChange={this.props.onChange}
              isVertical={true}
            />
          </div>
        );
      case 'rangetimevertical':
        return (
          <div>
            <CustomDatePicker
              classNameDate={this.props.classNameDate}
              withTime={true}
              value={this.props.value}
              valueEnd={this.props.valueEnd}
              maxDate={this.props.maxDate}
              minDate={this.props.minDate}
              onChange={this.props.onChange}
              {...(!('isVertical' in this.props) || this.props.isVertical) && { isVertical: true }}
              style={{ ...this.props.style }}
            />
          </div>
        );
      case 'rangetimeverticalselector':
        return (
          <div>
            <CustomDatePicker
              classNameDate={this.props.classNameDate}
              withTime={true}
              value={this.props.value}
              valueEnd={this.props.valueEnd}
              maxDate={this.props.maxDate}
              minDate={this.props.minDate}
              onChange={this.props.onChange}
              {...(!('isVertical' in this.props) || this.props.isVertical) && { isVertical: true }}
              style={{ ...this.props.style }}
              timeSelector={true}
              noHeaderSelector={true}
            />
          </div>
        );
      case 'rangemodaltimepicker':
          return (
            <div>
              <CustomDatePicker
                classNameDate={this.props.classNameDate}
                withTime={true}
                value={this.props.value}
                valueEnd={this.props.valueEnd}
                maxDate={this.props.maxDate}
                minDate={this.props.minDate}
                onChange={this.props.onChange}
                isVertical={false}
                style={{ ...this.props.style }}
                showModal={true}
                handleCloseModal={this.props.handleCloseModal}
                timeSelector={true}
              />
            </div>
          );
    }
    /*
          this.props.type === "selector" &&
          <Fragment>
            {
              this.state.selectorValue.key !== 4
                ?
                <ReactSelect
                  components={{ Input: CustomSelectInput }}
                  className="react-select"
                  classNamePrefix="react-select"
                  optionRenderer={this.optionRenderer}
                  value={this.state.selectorValue}
                  onChange={(obj) => {
                    this.changeSelectorDateValue(obj);
                  }}
                  options={[{ key: 1, label: "Today" }, { key: 2, label: "Yesterday" }, { key: 3, label: "Last Week" }, { key: 4, label: "Range" }]}
                  getOptionLabel={this.props.getOptionLabel || ((obj) => {
                    let label = "";
                    label = obj['label'];
                    return label
                  })}
                  getOptionValue={(obj) => obj['key']}
                />
                :
                <Fragment>
                  <div className="d-flex">
                    <div className="pr-2">
                      <DatePicker
                        selected={this.state.startDate}
                        selectsStart
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onChange={(date) => { this.setState({ startDate: date }) }}
                        placeholderText={this.props.placeholder}
                      />
                    </div>
                    <div className="pr-2">
                      <DatePicker
                        selected={this.state.endDate}
                        selectsEnd
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onChange={(date) => { this.setState({ endDate: date }) }}
                        placeholderText={this.props.placeholderEnd}
                        minDate={this.state.startDate}
                      />
                    </div>
                    <div className="pr-2 d-flex ">
                      <button
                        className="border-transparent btn-primary btn-sm cursor-pointer"
                        onClick={() => { 
                          if (this.props.onChange) {
                            this.props.onChange(this.state.startDate, this.state.endDate);
                          }
                        }}
                      >
                        Search
                      </button>
                      <a className="pl-2 pt-2 cursor-pointer" onClick={() => {
                        this.changeSelectorDateValue(this.state.lastValue);
                      }}>
                        <Icon className="toolbar-list-icon">
                          close
                        </Icon>
                      </a>
                    </div>
                  </div>
                </Fragment>
                    }
          </Fragment>
                  */
  }

  render() {
    let { value } = this.props;
    let min = this.props.min;
    let max = this.props.max;
    
    if (this.props.type === 'time') {
      if (value?.getHours != 'function') {
        value = new Date(value)
      }
      value = value && new Date(1900, 1, 1, value.getHours(), value.getMinutes(), value.getSeconds());
      min = min && new Date(1900, 1, 1, min.getHours(), min.getMinutes(), min.getSeconds());
      max = max && new Date(1900, 1, 1, max.getHours(), max.getMinutes(), max.getSeconds());
    }

    return (
      <div className="form-item">
        {this.props.label && (
          <label className={this.props.classLabel ? this.props.classLabel : "input-normal-label"}>
            {this.props.label}
            {!this.props.notRequiderdShow && this.props.required && (
              <span className="text-danger"> *</span>
            )}
          </label>
        )}
        <div
          className={cx(
            'input-date-container',
            this.props.className || '',
            !this.state.isValid && 'input-date-container-error',
          )}
        >
          {this.renderType({
            ref: 'input',
            selected: this.props.value,
            onChange: (date) => {
              this.validate(date);
              if (this.props.onChange) this.props.onChange(date);
            },
            placeholderText: this.props.placeholder,
            onFocus: () => {
              this.setState({ focused: true });
            },
            onBlur: () => {
              this.setState({ focused: false });
            },
            minDate: min,
            maxDate: max,
            minTime: this.props.minTime,
            maxTime: this.props.maxTime,
            disabled: this.props.disabled,
            onClose: this.props.onClose,
            classNameDate: this.props.classNameDate,
            handleCloseModal: this.props.handleCloseModal,
            injectTimes: this.props.injectTimes,
            popperPlacement: this.props.popperPlacement,
            popperModifiers: this.props.popperModifiers
          })}
          {
            (this.props.type !== 'range' && this.props.type !== 'datetime' && this.props.type !== 'rangetime' && this.props.type !== 'rangevertical'
              && this.props.type !== 'rangetimevertical' && this.props.type !== 'rangetimeverticalselector' && this.props.type !== 'rangetimemodal' && this.props.type !== 'rangemodaltimepicker') &&
            <div
              className={cx('input-icon-button', this.state.focused && 'input-icon-button-focused')}
            >
              <IconButton
                aria-label="placeholder"
                className={this.props.classNameIcon}
                onClick={() => {
                  this.refs.input.deferFocusInput();
                  this.props.onClickIcon && this.props.onClickIcon();
                }}
                style={{ padding: 10 }}
                size="large">
                <Icon style={{ backgroundColor: 'white' }} >{this.props.iconType && this.props.type === 'time' ? this.props.iconType : this.props.type === 'time' ? 'access_time' : 'today'} </Icon>
              </IconButton>
            </div>
          }

          {!this.state.isValid && this.state.errorMessage && (
            <small className="text-danger" style={{ paddingTop: 5 }}>
              {this.state.errorMessage}
            </small>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(InputDate)


InputDate.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['date', 'time', 'range', 'datetime', 'rangetime', 'rangevertical', 'rangetimevertical', 'rangetimeverticalselector', 'rangetimemodal', 'rangemodaltimepicker']),
  //value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  classNameDate: PropTypes.string,
  handleCloseModal: PropTypes.func
};
