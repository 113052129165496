import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../core/logManager';

import { resetALL } from '../../../../../.globals/redux/actions';

import moment from "moment";

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    response: null,
    id: null,
    dynamicForm: null,
    error: null,
    open: false,
    openQuestionConfiguration: false,
    question: null
};

const route = 'modules/dynamicForms/modals/design/redux/slice'

export const getDynamicForm = createAsyncThunk('dynamicForms/modals/design/getDynamicForm', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query getDynamicForm ($id: String!) {
                dynamicForm: getDynamicForm (id: $id) {
                    id
                    companyId
                    name
                    formStatus
                    pages {
                        id
                        title
                        fields {
                            id 
                            type
                            label
                            readOnly
                            required
                            placeHolder
                            help
                            attributes
                        }
                    }
                    properties {
                        allowDraft
                    }
                    tagIds
                    description
                    updatedOn
                    updatedBy
                    createdBy
                    createdOn
                }
            }
            `,
            { id },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response?.dynamicForm) {
            throw `Error while getting dynamic form: ${id}`;
        }

        let form = {
            ...response?.dynamicForm,
            properties: {
                allowDraft: response?.dynamicForm?.properties?.allowDraft
            },
            pages: response?.dynamicForm?.pages?.map(page => {
                let newPage = {
                    ...page,
                    fields: page?.fields?.map(field => {
                        let newField = {
                            ...field,
                            attributes: field.attributes ? JSON.parse(field.attributes) : {}
                        };

                        delete newField.__typename;
                        return newField;
                    })
                };

                delete newPage.__typename;
                return newPage;
            })
        };

        return form;
    } catch (exc) {
        ExceptionManager(exc, route, 'getDynamicForm');
        return rejectWithValue(exc)
    }
});

export const saveDynamicForm = createAsyncThunk('dynamicForms/modals/design/saveDynamicForm', async (data, { rejectWithValue, dispatch }) => {
    try {
        let query = `
            mutation createDynamicForm (
                $name: String!, 
                $description: String, 
                $formStatus: enumFormStates!, 
                $properties: DynamicFormPropertiesInput, 
                $pages: [DynamicFormPageInput]!
                $tagIds: [Int]!
            ) {
                dynamicForm: createDynamicForm (
                    name: $name, 
                    description: $description, 
                    formStatus: $formStatus, 
                    properties: $properties, 
                    pages: $pages
                    tagIds: $tagIds
                ) {
                    id
                }
            }
        `;

        if (data?.id) {
            query = `
                mutation updateDynamicForm (
                    $id: Int!, 
                    $name: String, 
                    $description: String, 
                    $formStatus: enumFormStates, 
                    $properties: DynamicFormPropertiesInput, 
                    $pages: [DynamicFormPageInput]
                    $tagIds: [Int]!
                ) {
                    dynamicForm: updateDynamicForm (
                        id: $id, 
                        name: $name, 
                        description: $description, 
                        formStatus: $formStatus, 
                        properties: $properties, 
                        pages: $pages
                        tagIds: $tagIds
                    ) {
                        id
                    }
                }
            `;
        }

        const response = await clientMutation(
            query,
            data,
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response?.dynamicForm?.id) {
            throw 'Error while saving dynamic form';
        }

        return response?.dynamicForm;
    } catch (exc) {
        ExceptionManager(exc, route, 'saveDynamicForm');
        return rejectWithValue(exc)
    }
});

//Slice
const dynamicFormsDesignRedux = createSlice({
    name: 'dynamicForms/design',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openDesignModal(state, action) {
            state.open = true;
            state.id = action?.payload;
        },
        closeDesignModal(state) {
            state.open = false;
        },
        openQuestionConfigurationModal(state, action) {
            state.openQuestionConfiguration = true;
            state.question = action.payload;
        },
        closeQuestionConfigurationModal(state) {
            state.openQuestionConfiguration = false;
            state.question = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)
        //getDynamicForm
        builder.addCase(getDynamicForm.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(getDynamicForm.fulfilled, (state, action) => {
            state.loading = false;
            state.dynamicForm = action.payload;
        });
        builder.addCase(getDynamicForm.rejected, (state, action) => {
            state.loading = false;
            state.dynamicForm = null;
            state.error = true;
        });
        //saveDynamicForm
        builder.addCase(saveDynamicForm.pending, (state, action) => {
            state.loading = true;
            state.error = false;
            state.response = null;
        });
        builder.addCase(saveDynamicForm.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(saveDynamicForm.rejected, (state, action) => {
            state.loading = false;
            state.response = action.payload;
            state.error = true;
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = dynamicFormsDesignRedux;

// Extract and export each action creator by name
export const { 
    reset, 
    openDesignModal, 
    closeDesignModal, 
    openQuestionConfigurationModal, 
    closeQuestionConfigurationModal 
} = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;