import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

import {
    baseReportConditions,
    generateReportInstance,
    getCategoryFilter,
    getTimeZone,
    handleAsyncReport,
    isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';
import {ExceptionManager} from "Core/logManager";

function* getLandmarkReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
    try {
        const {timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath} = report;
        const {filterType, filterArray, startRange, endRange, unitsSelected, accumulateData = true, dates} = report.filters
        const baseConditionsQuery = baseReportConditions({
            report,
            name,
            dataResultPath,
            timeZone,
            delayed,
            unitsSelected,
            timeframeName,
            filterType,
            filterArray,
            categoryName,
            startRange,
            endRange,
            dates
        })

        let payload = {
            conditions: {
                ...baseConditionsQuery,
                sortField: {
                    field: sortField || "deviceId",
                    order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                }
            },
            limit: rowsPerPage,
            offset: page
        };
        if (report.export) {
            payload = {
                ...payload,
                limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
                offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
                conditions: {
                    ...payload.conditions,
                    exportOptions: {
                        sendByEmail,
                        emailsTo,
                        format: {
                            fileFormat: report?.exportType || "json",
                            formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
                            durationFormat: report?.durationFormat
                        },
                        culture: report.lang,
                        timeZone: getTimeZone(timeZone),
                        folderName: "px-customer-reports",
                        filePrefixName: "LandmarkReport",
                        columns: report?.exportType === 'csv' ? [{
                            "column": "idLabel", "header": getCategoryFilter(filterType) === "deviceId" ? "Unit" : "Driver"
                        }, {
                            "column": "landmarkName", "header": "Landmark Name"
                        }, {
                            "column": "enterLandmarkTime", "header": "Arrived"
                        }, {
                            "column": "departLandmarkTime", "header": "Departed"
                        }, {
                            "column": "duration", "header": "At Landmark for"
                        }, {
                            "column": "durationBetweenLandmark", "header": "Time Between Landmarks"
                        }, {
                            "column": "distance", "header": "Distance in Landmark"
                        }, {
                            "column": "distanceBetweenLandmark", "header": "Distance Between Landmarks"
                        }] : [{
                            "column": "landmarkName", "header": "Landmark"
                        }, {
                            "column": "enterLandmarkTime", "header": "Enter Landmark"
                        }, {
                            "column": "departLandmarkTime", "header": "Leave Landmark"
                        }, {
                            "column": "distanceBetweenLandmark", "header": "Distance in Landmark"
                        }, {
                            "column": "durationBetweenLandmark", "header": "Time in Landmark"
                        }]
                    }
                }
            }
        }
        let data = yield call(
            clientQuery,
            `query getLandmarkReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getLandmarkReport (conditions: $conditions, limit: $limit, offset: $offset) {
                ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
                `reportInstanceId`
                : `
                    items {
                        recordInfo {
                            entityId
                            entityLabel
                            tagId
                            tagLabel
                            startDate
                            endDate
                            recordType
                            groupLabel
                            groupIndex
                            groupTotal
                            groupTagColor
                        }
                        recordDetail {
                            id
                            unitId
                            deviceId
                            deviceLabel
                            driverId
                            driverName
                            landmarkId
                            landmarkName
                            enterLandmarkTime
                            departLandmarkTime
                            totalDistance
                            distance
                            totalDuration
                            duration
                            durationBetweenLandmark
                            distanceBetweenLandmark
                            isKPH
                            noData
                        }
                    }
                    total
                    limit
                    offset
                    category
                    reportInstanceId
                `
            }
            }
        }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
        yield handleAsyncReport(report, data, accumulateData);
    } catch (exc) {
        ExceptionManager(exc, 'reports/landmarkReport', 'getLandmarkReport');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}

export default getLandmarkReport;
