import {
    ACCOUNT_TAGS_SEARCH,
    ACCOUNT_TAGS_SEARCH_RESPONSE,
    ACCOUNT_TAGS_SEARCH_PARENTS,
    ACCOUNT_TAGS_SEARCH_PARENTS_RESPONSE,
    ACCOUNT_TAGS_SEARCH_CHILDREN,
    ACCOUNT_TAGS_SEARCH_CHILDREN_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    //Search all tags
    loadingTags: false,
    tags: [],
    filters: {
        openSearch: "",
        limit: 10,
        offset: 0,
        parentId: null
    },
    //Get only tag parent first level
    parents: [],
    loadingParents: false,
    parentsFilters: {
        openSearch: "",
        limit: 10,
        offset: 0,
        parentId: null
    },
    //Get only children behind first level
    children: {},
    loadingChildren: {},
    error: false,
    totalTags: 0,
    totalParents: 0
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACCOUNT_TAGS_SEARCH:
            return {
                ...state,
                loadingTags: true,
                filters: action.payload.data,
                error: false
            };

        case ACCOUNT_TAGS_SEARCH_RESPONSE:
            return {
                ...state,
                loadingTags: false,
                tags: action.payload?.items,
                totalTags: action.payload?.total,
                error: action.payload?.error
            };

        case ACCOUNT_TAGS_SEARCH_PARENTS:
            return {
                ...state,
                parentsFilters:  action.payload.data,
                loadingParents: true
            };

        case ACCOUNT_TAGS_SEARCH_PARENTS_RESPONSE:
            return {
                ...state,
                parents: action.payload?.items,
                totalParents: action.payload?.total,
                error: action.payload?.error,
                loadingParents: false
            };

        case ACCOUNT_TAGS_SEARCH_CHILDREN:
            return {
                ...state,
                loadingChildren: { ...state.loadingChildren, [action?.payload?.data?.parentId]: true }
            };

        case ACCOUNT_TAGS_SEARCH_CHILDREN_RESPONSE:
            return {
                ...state,
                loadingChildren: { ...state.loadingChildren, [action.payload.parentId]: false },
                children: { ...state.children, [action.payload.parentId]: action.payload?.items }
            };

        case CLEAR_REDUX:
            return ["", "ACCOUNT_TAGS"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return state;
    }
};