import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';
import { MapUtilitiesTypes } from 'Modules/realtimeMaps/main/components/MapUtilitiesMenu/enums';

import { Tooltip } from 'Components';

/*********************************************************
 * Actions
*********************************************************/
import { selectModuleMapSettings, setShowMapSettings } from "Redux/actions";

const DrawMapSettings = (props) => {
  
  const { messages } = props
  
  const dispatch = useDispatch();
  const [color,setColor] = useState('#2b333f');
  const { showMapSettings, currentModule } = useSelector(state => state.realtimeMapUtilitiesRedux)

  const handleModule = () => {

    if (currentModule !== MapUtilitiesTypes.MapSettings && showMapSettings) {
      dispatch(selectModuleMapSettings(MapUtilitiesTypes.MapSettings));
    } else {
      dispatch(selectModuleMapSettings(MapUtilitiesTypes.Default));
    }

    if (!showMapSettings) {
      dispatch(selectModuleMapSettings(MapUtilitiesTypes.MapSettings));
      dispatch(setShowMapSettings(true));
    }
  }

  useEffect(() => {
    if (currentModule === MapUtilitiesTypes.MapSettings && showMapSettings) {
      setColor('#00AEEF')
    }else{
      setColor('#2b333f')
    }
	}, [currentModule,showMapSettings]);



  return (
    <Fragment>
      <Tooltip placement="left" title={messages['maputilities_mapsettings']}>
        <button
          onClick={() => handleModule()}>
           <SettingsIcon style={{ color: color}}></SettingsIcon>
        </button>
      </Tooltip>
    </Fragment>
  )
}

export default DrawMapSettings;