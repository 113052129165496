import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
  FIND_NEAREST_SEARCH
} from 'Redux/actionTypes';

import {
  searchNearestResponse
} from 'Redux/actions';

function* searchNearestRequest({ payload }) {
  try {
    const { data, offset, limit } = payload;

    let query = `
      query searchNearest (
        $entity: NearestEntity!, 
        $entityType: String, 
        $from: NearestFromInput!, 
        $limit: Int!, 
        $offset: Int!
      ) {
        Items: searchNearest (entity: $entity, entityType: $entityType, from: $from, limit: $limit, offset: $offset) {
          items {
            entity {
              name
              id
              label
            }
            latitude
            longitude
            distanceMeters
          }
          total
        }
    }
    `;

    const response = yield call(clientQuery, query, { ...data, offset, limit }, endpoints.GRAPHQL_GENERAL);
    if (response && response.Items) {
      yield put(searchNearestResponse({
        error: false,
        items: response.Items.items,
        total: response.Items.total
      }));
    } else {
      throw 'Not nearest data';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    // ExceptionManager(exc, 'modules/realtimeMaps/main/components/findNearest/redux/saga', 'searchNearestRequest');
    let message = "nearest_error";
    if (exc?.errorData && exc?.errorData?.errors) {
			exc?.errorData?.errors?.forEach(error => {
        if (error.errorInfo && error.errorInfo.code) {
          if (error?.message.includes("There is not unit for id")) {
            message = "nearest_equipmentWithoutUnitError";
          }
        }
      });
    }

    yield put(searchNearestResponse({ error: true, message: message, items: [], total: 0 }));
  }
}

function* watchSearchNearestRequest() {
  yield takeEvery(FIND_NEAREST_SEARCH, searchNearestRequest);
}

export default function* rootSaga() {
  yield all([
    fork(watchSearchNearestRequest)
  ]);
}