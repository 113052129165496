// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    MAP_LANDMARKS_DELETE_LANDMARKS,
} from "Redux/actionTypes";

// Actions
import {
    mapLandmarksDeleteLandmarksResponse,
} from "Redux/actions";

// Helpers
import { clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* deleteLandmarksRequest({ payload }) {
    try {


        const { landmarks, deleteAll, unselectedRows } = payload;

        const ids = landmarks && landmarks.length > 0 ? landmarks.map((item) => item.id) : null
        const unselectedRowsIds = unselectedRows && unselectedRows.length > 0 ? unselectedRows.map((item) => item.id) : null

        const mutation = `mutation deleteLandmarkBulk($ids: [Int], $deleteAll: Boolean, $unselectedRowsIds: [Int]) {
            Landmark: deleteLandmarkBulk(ids: $ids, deleteAll: $deleteAll, unselectedRowsIds: $unselectedRowsIds)
        }`;
        
        let variables = {
            ids: ids,
            deleteAll: deleteAll ? true : false, 
            unselectedRowsIds: unselectedRowsIds
        };

        if(!variables?.ids) delete variables['ids']
        if(!variables?.unselectedRowsIds) delete variables['unselectedRowsIds']
        
        const response = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL_V2);

        if (!response || !response.Landmark) throw `Error while deleting landmarks, ${landmarks}`;

        yield put(mapLandmarksDeleteLandmarksResponse({ ...response, error: false }));
    } catch (exc) {
        yield put(mapLandmarksDeleteLandmarksResponse({ error: true }));
        ExceptionManager(
            exc,
            "realtimeMaps/main/components/landmarks/components/landmarkDelete/redux/saga",
            "deleteLandmarksRequest"
        );
    }
}

function* watchDeleteLandmarksMethods() {
    yield takeEvery(MAP_LANDMARKS_DELETE_LANDMARKS, deleteLandmarksRequest);
}

export default function* rootSaga() {
    yield all([fork(watchDeleteLandmarksMethods)]);
}