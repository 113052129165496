import React from "react";
import { HasPermission } from "Core/security";
import { useSelector, useDispatch } from "react-redux";

import ChatSFButton from './components/ChatSFButton';
import ChatButton from './components/Chat';
import HamburgerToggle from "./components/HamburgerToggle";
import CompanyName from "./components/CompanyName";
import CompanyLogo from "./components/CompanyLogo";
import AlertsButton from "./components/AlertsButton";
import AccountButton from "./components/AccountButton";
import { catalogLoad } from "Redux/actions";
import './header.scss';
import { IncidentButton } from "./components/IncidentsButton";
import { CreateEdit, OverView, HistoryOverView } from "Modules/incidents/views/overview";


const Header = ({ user, logout }) => {
  const { companyName } = user;
  const dispatch = useDispatch()
  const current_reseller = useSelector(
    (state) => state.resellerRedux.current_reseller || null
  );
  const type = useSelector((state) => state.resellerRedux.type);
  const userSecurity = useSelector(state => state.securityRedux.user);
  const reseller_was_validated = useSelector(
    (state) => state.resellerRedux.reseller_was_validated
  );
  const urlImgLogo = current_reseller
    ? current_reseller.inAppLogo
    : "/assets/images/logo.png";


  dispatch(catalogLoad(['chatUsers']));
  return (
    <div className="header">
      <HamburgerToggle />
      <CompanyName>{(user && companyName) || "COMPANY NAME"}</CompanyName>
      {urlImgLogo && reseller_was_validated && <CompanyLogo urlImgLogo={urlImgLogo} />}
      <div className="header-content">
        {
          HasPermission(userSecurity, ["incdnt"], [265]) &&
          <>
            <IncidentButton user={user} />
            <CreateEdit />
            <HistoryOverView />
          </>
        }
          <OverView />
        {
          HasPermission(userSecurity, ["dappcht"], [245]) &&
          <ChatButton user={user} />
        }
        {
          ["1", "6", "70"].includes(userSecurity?.dealerId) &&
          <ChatSFButton user={user} />
        }
        
        {
          HasPermission(userSecurity, ["alrt"], [222, 6, 7]) &&
          <AlertsButton />
        }
        <AccountButton
          logout={logout}
          current_reseller={current_reseller}
          type={type}
          user={user}
        />
      </div>
    </div>
  );
};

export default React.memo(Header);
