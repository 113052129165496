import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    open: false,
    routeId: 0,
    route: null,
    cloneRouteResponse: false,
    loading: false,
    isTemplate: false,
    erorrRoutes: null
};

const route = "settings/views/routes/components/modalCloneRoute";

export const routesModalCloneRoute = createAsyncThunk('routes/routesTab/routesModalCloneRoute', async (body, { rejectWithValue, dispatch }) => {
    try {
        const data = await clientMutation(
            `
            mutation createRoute (
                $title: String!, 
                $color: String, 
                $optimized: Boolean, 
                $avoidToll: Boolean,
                $points: [RoutePointInput],
                $driverId: Int,
                $isTemplate: Boolean,
                $scheduledAt: AWSDateTime,
                $templateRouteId: Int,
                $distanceMeters: Float
              ) {
                Route: createRoute(title: $title, color: $color, optimized: $optimized, avoidToll: $avoidToll, points: $points, driverId: $driverId, isTemplate: $isTemplate, scheduledAt: $scheduledAt, templateRouteId: $templateRouteId, distanceMeters: $distanceMeters) {
                    id
                    title
                    color
                    optimized
                    avoidToll

                    isTemplate
                    templateRouteId
                    driverId
                    scheduledAt

                    points {
                        id
                        name
                        routeId
                        address
                        city
                        state
                        landmarkId
                        latitude
                        longitude
                        radius
                        sequence
                        note       
                        radius       
                    }        
                }
              }

            `,
            body
            ,
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (data && data.Clone) {
            return data.Clone
        }
        return null;

    } catch (exc) {
        ExceptionManager(exc, route, 'routesCloneRoute');
        return rejectWithValue(exc)
    }
});


export const RoutesTemplateModalCloneRoute = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalRoutesCloneRoute: (state, action) => {
            return {
                ...state,
                open: action.payload.open,
                routeId: action.payload.routeId, 
                isTemplate: action.payload.isTemplate || false,
            }
        }
    },
    extraReducers: (builder) => {

        //clone routes
        builder.addCase(routesModalCloneRoute.pending, (state, action) => {
            state.loading = true;
            state.cloneRouteResponse = false;
            state.erorrRoutes = null;

        });
        builder.addCase(routesModalCloneRoute.fulfilled, (state, action) => {
            state.loading = false;
            state.cloneRouteResponse = true;
            state.route = action?.payload;
        } );
        builder.addCase(routesModalCloneRoute.rejected, (state, action) => {  
            state.detail = [];
            if(action.payload.error == "Route duplicated title" || action.payload.error == "Routes duplicated title"){
                state.erorrRoutes = "fsmRoute_modal_editCreate_error_name";
            }else{
                state.erorrRoutes = action.payload.error;
            }
            state.cloneRouteResponse = true;
            state.loading = false;
        });
    }

})

const { actions, reducer } = RoutesTemplateModalCloneRoute;

export const { reset, openModalRoutesCloneRoute } = actions;

export default reducer;