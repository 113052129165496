import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';
import './stylesClassicDesign.scss';

const customStyleWithPoint =  {
  root: {
    sx: {
      color: '#466180',
      height: '10px',
    }
  },
  thumb: {
    sx: {
      height: '24px',
      width: '24px',
      backgroundColor: '#fff',
      border: '3px solid currentColor',
      marginTop: '-8px',
      marginLeft: '-12px',
      '&:focus,&:hover,&$active': {
        boxShadow: 'inherit',
      },
      circle: {
        borderRadius: '0 0 0 0',
      }
    }
  }, 
  //active: {},
  valueLabel: {
    sx: {
      left: 'calc(-50% + 3px)',
      circle: {
        borderRadius: '0 0 0 0'
      }
    }
  },
  track: {
    sx: {
      height: '8px',
      borderRadius: '4px',
    }
  },
  rail: {
    sx: {
      height: '8px',
      borderRadius: '4px',
    }
  },
  markLabel: {
    sx: {
      fontSize: '15px',
      paddingTop: '8px',
      color: 'red'
    }
  }
}


const customStyleBlue = {
  root: {
    sx: {
      color: '#CCC',
      height: '10px',
    }
  },
  thumb: {
    sx: {
      height: '24px',
      width: '24px',
      backgroundColor: '#00AEEF',
      border: '3px solid #00AEEF',
      marginTop: '-8px',
      marginLeft: '-12px',
      '&:focus,&:hover,&$active': {
        boxShadow: 'inherit',
      },
      circle: {
        borderRadius: '0 0 0 0',
      }
    }
  },
  //active: {},
  valueLabel: {
    sx: {
      left: 'calc(-50% + 3px)',
      circle: {
        borderRadius: '0 0 0 0'
      }
    }
  },
  track: {
    sx: {
      height: '8px',
      borderRadius: '4px'
    }
  },
  rail: {
    sx: {
      height: '8px',
      borderRadius: '4px',
    }
  },
  markLabel: {
    sx: {
      fontSize: '15px',
      paddingTop: '8px'
    }
  }
}

const CustomizedSlider = ({
  min,
  max,
  colorLeft,
  colorRight,
  colorMiddle,
  value,
  isMultiple,
  valueLabelDisplay,
  onChange,
  blueSlider
}) => {
  let start, end;
  if (isMultiple){
    [start, end] = value;
  }

  return (
    <Fragment>
      {
        blueSlider
        ?
        <Slider //SliderBlue
          track={false}
          className="myCustomSlider"
          classes={{
            valueLabel: 'row-value-label-one-color'
          }}
          value={value}
          onChange={onChange}
          min={min}
          max={max}
          start={start}
          end={end}
          marks={[]}
          colorleft={colorLeft}
          colormiddle={isMultiple? colorMiddle : colorLeft}
          colorright={colorRight}
          valueLabelDisplay={valueLabelDisplay}
          slotProps={customStyleBlue}
        />
        : 
        <Slider //SliderWhitPoint
          className="myCustomSlider"
          classes={{
            valueLabel: 'row-value-label-one-color'
          }}
          value={value}
          onChange={onChange}
          min={min}
          max={max}
          start={start}
          end={end}
          marks={[]}
          colorleft={colorLeft}
          colormiddle={isMultiple? colorMiddle : colorLeft}
          colorright={colorRight}
          valueLabelDisplay={valueLabelDisplay}
          slotProps={customStyleWithPoint}
        />
      }
    </Fragment>
  );
};

CustomizedSlider.defaultProps = {
  min:0,
  max: 100,
  colorLeft: '#00AEEF',
  colorRight: '#ccc',
  value: 30,
  isMultiple: false,
  valueLabelDisplay: "on"
}

CustomizedSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: (props, propName, componentName) => {
    let error;
    const max = props[propName];
    const min = props['min'];
    if(!max){
      error = new Error(`\`${componentName}\` max is required.`,);
    }
    if(max<=min){
      error = new Error(`\`${componentName}\` max should be greater than min.`,);
    }
    return error;
  },
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number
  ]),
  isMultiple: PropTypes.bool,
  valueLabelDisplay: PropTypes.string,
  colorLeft: PropTypes.string,
  colorRight: PropTypes.string,
  colorCompletePoint: PropTypes.bool
};

export default CustomizedSlider;