import {
  REPORTS_MAIN_GET_SCHEDULE_REPORT_SETUP,
  REPORTS_MAIN_GET_SCHEDULE_REPORT_SETUP_RESULT,
  REPORTS_MAIN_DELETE_SCHEDULE_REPORT,
  REPORTS_MAIN_DELETE_SCHEDULE_REPORT_RESULT,
  CLEAR_REDUX
} from "Redux/actionTypes";

//STATE INITIAL
const INIT_STATE = {
  schedules: [],
  loadingSchedules: false,
  deletedSchedule: null
};

export default (state = INIT_STATE, action) => {
  //let reportIndex
  switch (action.type) {
    case REPORTS_MAIN_GET_SCHEDULE_REPORT_SETUP:
      return {...state,  loadingSchedules: true};
    case REPORTS_MAIN_GET_SCHEDULE_REPORT_SETUP_RESULT:
      return {...state, schedules: action.payload.data, loadingSchedules: false};
    case REPORTS_MAIN_DELETE_SCHEDULE_REPORT:
      return {...state, loadingSchedules: true, deletedSchedule: null};
    case REPORTS_MAIN_DELETE_SCHEDULE_REPORT_RESULT:
      return {...state, loadingSchedules: false, deletedSchedule: action.payload.data};
    case CLEAR_REDUX:
      return ["", "REPORTS_MAIN_ST"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
};
