import {
    DASHBOARD_PANEL_SEARCH,
    DASHBOARD_PANEL_SEARCH_RESPONSE,
    DASHBOARD_PANEL_CREATE,
    DASHBOARD_PANEL_CREATE_RESPONSE,
    DASHBOARD_PANEL_UPDATE,
    DASHBOARD_PANEL_UPDATE_RESPONSE,
    DASHBOARD_PANEL_DELETE,
    DASHBOARD_PANEL_DELETE_RESPONSE,
    DASHBOARD_PANEL_OPEN_CREATE,
    DASHBOARD_PANEL_OPEN_CREATE_EDIT_MODAL,
    DASHBOARD_PANEL_SIZE_CREATE_EDIT_MODAL,
    DASHBOARD_PANEL_CREATE_GADGET,
    DASHBOARD_PANEL_CREATE_GADGET_RESPONSE,
    DASHBOARD_PANEL_DELETE_GADGET,
    DASHBOARD_PANEL_DELETE_GADGET_RESPONSE,
    DASHBOARD_PANEL_UPDATE_GADGET,
    DASHBOARD_PANEL_UPDATE_GADGET_RESPONSE,
    DASHBOARD_PANEL_UPDATE_POSITION_GADGET,
    DASHBOARD_PANEL_UPDATE_POSITION_GADGET_RESPONSE,
    DASHBOARD_PANEL_UNIT,
    DASHBOARD_PANEL_UNIT_RESPONSE,
    DASHBOARD_PANEL_ERROR_USER_GADGET_RESPONSE
} from "Redux/actionTypes";

export const searchDashboardPanel = () => {
    return {
        type: DASHBOARD_PANEL_SEARCH,
        payload: {},
    };
};

export const searchDashboardPanelResponse = (data) => {
    return {
        type: DASHBOARD_PANEL_SEARCH_RESPONSE,
        payload: data,
    };
};

export const openDashboardPanelCreateEditModal = (open,gadGet={}) => {
    return {
        type: DASHBOARD_PANEL_OPEN_CREATE_EDIT_MODAL,
        payload: { open,gadGet }
    };
};

export const sizeDashboardPanelCreateEditModal = (size) => {
    return {
        type: DASHBOARD_PANEL_SIZE_CREATE_EDIT_MODAL,
        payload: { size }
    };
};

export const createDashboardPanel = (dashboard) => {
    return {
        type: DASHBOARD_PANEL_CREATE,
        payload: dashboard,
    };
};

export const createDashboardPanelResponse = (data) => {
    return {
        type: DASHBOARD_PANEL_CREATE_RESPONSE,
        payload: data,
    };
};

export const updateDashboardPanel = (dashboard) => {
    return {
        type: DASHBOARD_PANEL_UPDATE,
        payload: dashboard,
    };
};

export const updateDashboardPanelResponse = (data) => {
    return {
        type: DASHBOARD_PANEL_UPDATE_RESPONSE,
        payload: data,
    };
};


export const deleteDashboardPanel = (dashboardId) => {
    return {
        type: DASHBOARD_PANEL_DELETE,
        payload: dashboardId,
    };
};

export const deleteDashboardPanelResponse = (data) => {
    return {
        type: DASHBOARD_PANEL_DELETE_RESPONSE,
        payload: data,
    };
};

export const openDashboardPanel = (open,dashboardEdit) => {
    return {
        type: DASHBOARD_PANEL_OPEN_CREATE,
        payload: { open,dashboardEdit }
    };
};

export const createGadgetDashboardPanel = (gadGet) => {
    return {
        type: DASHBOARD_PANEL_CREATE_GADGET,
        payload: {gadGet},
    };
};

export const createGadgetDashboardPanelResponse = (data) => {
    return {
        type: DASHBOARD_PANEL_CREATE_GADGET_RESPONSE,
        payload: data,
    };
};



export const deleteGadgetDashboardPanel = (gadGetId) => {
    return {
        type: DASHBOARD_PANEL_DELETE_GADGET,
        payload: {gadGetId},
    };
};

export const deleteGadgetDashboardPanelResponse = (data) => {
    return {
        type: DASHBOARD_PANEL_DELETE_GADGET_RESPONSE,
        payload: data,
    };
};

export const updateGadgetDashboardPanel = (gadGet) => {
    return {
        type: DASHBOARD_PANEL_UPDATE_GADGET,
        payload: {gadGet},
    };
};

export const updateGadgetDashboardPanelResponse = (data) => {
    return {
        type: DASHBOARD_PANEL_UPDATE_GADGET_RESPONSE,
        payload: data,
    };
};

export const updateGadgetPositionDashboardPanel = (gadGets) => {
    return {
        type: DASHBOARD_PANEL_UPDATE_POSITION_GADGET,
        payload: {gadGets},
    };
};

export const updateGadgetPositionDashboardPanelResponse = (data) => {
    return {
        type: DASHBOARD_PANEL_UPDATE_POSITION_GADGET_RESPONSE,
        payload: data,
    };
};

export const searchDashboardUnits = () => {
    return {
        type: DASHBOARD_PANEL_UNIT,
        payload: {},
    };
};

export const searchDashboardUnitsResponse = (data) => {
    return {
        type: DASHBOARD_PANEL_UNIT_RESPONSE,
        payload: data,
    };
};

export const updateErrorUserGadgetResponse = (data) => {
    return {
        type: DASHBOARD_PANEL_ERROR_USER_GADGET_RESPONSE,
        payload: data,
    };
};
