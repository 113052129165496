export const SETTINGS_TEMPERATURE_SEARCH = "SETTINGS_TEMPERATURE_SEARCH";
export const SETTINGS_TEMPERATURE_SEARCH_RESPONSE = "SETTINGS_TEMPERATURE_SEARCH_RESPONSE";
export const SETTINGS_TEMPERATURE_OPEN_CREATE_EDIT_MODAL = "SETTINGS_TEMPERATURE_OPEN_CREATE_EDIT_MODAL";


export const SETTINGS_TEMPERATURE_CREATE_EDIT = "SETTINGS_TEMPERATURE_CREATE_EDIT";
export const SETTINGS_TEMPERATURE_CREATE_EDIT_RESPONSE = "SETTINGS_TEMPERATURE_CREATE_EDIT_RESPONSE";

export const SETTINGS_TEMPERATURE_OPEN_DELETE_MODAL  = "SETTINGS_TEMPERATURE_OPEN_DELETE_MODAL";
export const SETTINGS_TEMPERATURE_DELETE             = "SETTINGS_TEMPERATURE_DELETE";
export const SETTINGS_TEMPERATURE_DELETE_RESPONSE    = "SETTINGS_TEMPERATURE_DELETE_RESPONSE";

export const SETTINGS_TEMPERATURE_GET_UNIT = "SETTINGS_TEMPERATURE_GET_UNIT";
export const SETTINGS_TEMPERATURE_GET_UNIT_RESPONSE = "SETTINGS_TEMPERATURE_GET_UNIT_RESPONSE";

export const SETTINGS_TEMPERATURE_GET_CONTACT = "SETTINGS_TEMPERATURE_GET_CONTACT";
export const SETTINGS_TEMPERATURE_GET_CONTACT_RESPONSE = "SETTINGS_TEMPERATURE_GET_CONTACT_RESPONSE";