import moment from "moment";
import { getVidFleetEvent } from "Core/data/VideoEvents";
import { DEFAULTCOLOR, EVENTS_CODES } from "./constants";
import { v4 as uuidv4 } from 'uuid';

export const getRailEvents = (items = [], date, timeZone) => {
  const railColor = [];
  const eventsData = [];
  let color = DEFAULTCOLOR;
  let initialDate = moment.tz(date, timeZone).utc()
  let prevPerUnit = "0";
  if (items.length > 0) {
    items.forEach((item) => {
      const evDate = moment.tz(item.unitTime, timeZone).utc();
      const percentPerUnit = getPercentForGivenTime(initialDate, evDate);
      color = EVENTS_CODES[item.eventName] || EVENTS_CODES[item.eventCode] || color;
      const searchEventData = getVidFleetEvent(item.eventCode);
      railColor.push(`${color} ${prevPerUnit}%, ${color} ${percentPerUnit}%`);
      if (color !== DEFAULTCOLOR) {
        prevPerUnit = percentPerUnit;
      }
      if (
        searchEventData.name !== "Default" &&
        searchEventData.name !== "AutoSnapshot"
      ) {
        eventsData.push({
          id: uuidv4(),
          dataEvent: searchEventData,
          position: percentPerUnit,
        });
      }
    });
  } else {
    railColor.push(`${color} 0%, ${color} 100%`);
  }
  return { rail: railColor.join(","), eventData: eventsData };
};

const getPercentForGivenTime = (initialDate, date, totalSeconds = 300) => {
  const evSeconds = date.diff(initialDate, "seconds");
  return evSeconds <= 0
    ? 0
    : evSeconds >= totalSeconds
      ? 100
      : (evSeconds / totalSeconds) * 100;
};

export const getPairFromRange = (data = [], startD, timezoneUnit = "America/Los_Angeles") => {
  const dates = [];
  //review
  const format = startD.format("YYYY-MM-DD HH:mm:ss");
  let startRange = moment.tz(format, timezoneUnit).utc();
  let endRange = moment.tz(format, timezoneUnit).add(5, "minute").utc();
  startRange = startRange.valueOf();
  endRange = endRange.valueOf();
  data.forEach((item) => {
    const jsnParse = JSON.parse(item);
    //UTC method
    // const startClip = jsnParse[0];
    // const endClip = jsnParse[1];

    //timezone method
    let startClip = moment.tz(jsnParse[0], timezoneUnit).utc().valueOf();
    let endClip = moment.tz(jsnParse[1], timezoneUnit).utc().valueOf();

    if (startClip < startRange && endClip > startRange && endClip < endRange) {
      dates.push({ startClip: startRange, endClip });
    }

    // clip is larger than range
    if (startClip < startRange && endClip > endRange) {
      dates.push({ startClip: startRange, endClip: endRange });
    }

    // clip is inside of range
    if (startClip > startRange && startClip < endRange && endClip > endRange) {
      dates.push({ startClip, endClip: endRange });
    }

    // clip starts before range but finish between  range
    if (startClip > startRange && endClip < endRange) {
      dates.push({ startClip, endClip });
    }

  });
  return dates;
};

export const getTimeZoneUnit = (unitsList, deviceId) => {
  let timezoneUnit = null;
  timezoneUnit = unitsList.find(item => item?.id == deviceId || item?.linkedUnit?.id == deviceId || item?.linkedUnitId == deviceId);

  if (!timezoneUnit) {
    timezoneUnit = {
      timeZone: {
        name: "US/Eastern"
      }
    };
  }

  return timezoneUnit;
}