export default {
    mapGeofenceSettings_geofence_manage: "Manage",
    mapGeofenceSettings_geofence_contacts: "Contacts",
    mapGeofenceSettings_geofence_radius: "Radius",
    mapGeofenceSettings_geofence_group: "Tags",
    mapGeofenceSettings_geofence_alerton: "Alert On",
    mapGeofenceSettings_geofence_units: "Units",
    mapGeofenceSettings_geofence_export: "Geofence Table",
    mapGeofenceSettings_geofence_title: 'Title',
    mapGeofenceSettings_geofence_radius: 'Radius',
    mapGeofenceSettings_geofence_groups: 'Tags',
    mapGeofenceSettings_geofence_exportgroup: "Geofence Group Table",
};