import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';
// Actions
import {
  reportsMainGenerateReportProgress
} from 'Redux/actions';


import {
  getCategoryFilter,
  getTimeZone,
  handleAsyncReport,
  convertTimeItems,
  generateReportInstance,
  baseReportConditions,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

function* getSimpleReport(report, page, rowsPerPage, sortField, sortOrder) {
  const { timeZone, sendByEmail, emailsTo, name, delayed, timeframeName, categoryName, dataResultPath } = report;
  const { filterType, filterArray, startRange, endRange, unitsSelected, accumulateData = true, dates, filterEntity } = report.filters
  const baseConditionsQuery = baseReportConditions({
    report,
    name,
    dataResultPath,
    timeZone,
    delayed,
    unitsSelected,
    timeframeName,
    filterType,
    filterArray,
    categoryName,
    startRange,
    endRange,
    dates
  })
  try {
    let payload = {
      conditions: {
        ...baseConditionsQuery,
        sortField: {
          field: sortField || "deviceId",
          order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
      },
      limit: rowsPerPage,
      offset: page
    };
    let addColumnDriver = [];
    if (report?.exportType === 'csv') {
      addColumnDriver.push({
        column: "idLabel",
        header: getCategoryFilter(filterType) === "deviceId" ? "Label" : "Driver"
      })
    }

    payload = {
      ...payload,
      limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
      offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
      conditions: {
        ...payload.conditions,
        exportOptions: {
          sendByEmail,
          emailsTo,
          format: {
            fileFormat: report?.exportType || "json",
            formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
            durationFormat: report?.durationFormat
          },
          culture: report.lang,
          timeZone: getTimeZone(timeZone),
          folderName: "px-customer-reports",
          filePrefixName: "simpleReport",
          columns: [
            ...addColumnDriver,
            {
              column: "DriverUnit",
              header: getCategoryFilter(filterType) === "deviceId" ? "Driver" : "Unit"
            },
            {
              "column": "unitTime",
              header: `${report?.exportType === "csv" ? `Time/Date (${getAbbvTimezone(timeZone)})` : `Time/Date`}`
            },
            {
              "column": "address",
              "header": "Address"
            },
            {
              "column": "distance",
              "header": "Distance"
            },
            {
              "column": "speed",
              "header": "Speed"
            },
            {
              "column": "speedLimit",
              "header": "Posted"
            },
            {
              "column": "eventName",
              "header": "Event"
            }
          ]
        }
      },
    }
    let data = yield call(
      clientQuery,
      `query getSimpleReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getSimpleReport (conditions: $conditions, limit: $limit, offset: $offset) {
          ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
        `reportInstanceId`
        : `
            items {
              recordInfo {
                entityId
                entityLabel
                tagId
                tagLabel
                startDate
                endDate
                recordType
                groupLabel
                groupIndex
                groupTotal
                groupTagColor
            }
            recordDetail {
                deviceId
                unitId
                unitType
                unitTime
                timeDelta
                eventName
                driverId
                driverName
                address
                speed
                speedLimit
                signal
                distance
                deviceLabel
                landmarkId
                landmarkName  
                latitude
                longitude
                temperature
                isKPH
                noData
              }
            }
            total
            limit
            offset
            category
          `}
        }
      }`,
      payload,
      endpoints.GRAPHQL_PX_REPORTING,
    );
    yield handleAsyncReport(report, data, accumulateData);
  } catch (exc) {
    ExceptionManager(
      exc,
      'modules/reports/views/main/components/reportsTab/.components/reports/simpleReport/sagaFunction.js',
      'simpleReport',
    );
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
  }
}


export default getSimpleReport;
