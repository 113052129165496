import templates from "../../views/templates/lan/es";
import routesTracker from "../../views/routesTracker/lan/es";


const locales = {
    ...templates,
    ...routesTracker,
    
    "module_routes_routes_tracker":"Seguimiento de Rutas",
    "module_routes_routes_template":"Plantillas",
    "module_routes_select_date":"Seleccione la fecha",
};
  
export default locales;