import _ from 'lodash'

export class GenericItem {

  executeArray = (items, itemsExists, entity, entityKey) => {
    console.log("GenericItem", "executeArray", items, itemsExists, entity, entityKey)
    let cloneItems = _.clone(itemsExists[entity])

    for (let itemIdex in cloneItems) {
      let item = cloneItems[itemIdex];

      let index = items.findIndex(x => x[entityKey] == item[entityKey] || x.id == item.id)
      if (index > -1) {
        item = { ...item, ...items[index] }
        cloneItems[itemIdex] = item;
      }
      else
        cloneItems.push(item)
    }

    itemsExists[entity] = cloneItems;
    return [true, cloneItems]
  }

  execute = (item, itemsExists, entity, entityKey) => {
    console.log("GenericItem", "execute", entity, entityKey)

    let cloneItems = _.clone(itemsExists[entity])
    console.log("GenericItem", "cloneItems", _.clone(cloneItems))
    let index = cloneItems.findIndex(x => x[entityKey] == item[entityKey])
    console.log("GenericItem", "index", index)
    if (index != -1)
      cloneItems[index] = { ...cloneItems[index], ...item }
    else
      cloneItems.push(item)

    console.log("GenericItem", "cloneItems2", cloneItems)
    itemsExists[entity] = cloneItems;
    return [true, itemsExists]
  }
}