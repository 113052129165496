export default {
    videoAssignDriver_title: "Viaje - Asignar Conductor",
    videoAssignDriver_driver: "Conductor",
    videoAssignDriver_start: "Inicio: ",
    videoAssignDriver_end: "Fin: ",
    videoAssignDriver_ignitionOn: "Encendido",
    videoAssignDriver_ignitionOff: "Apagado",
    videoAssignDriver_activeTrip: "Viaje activo",
    videoAssignDriver_tripNotFinished: "El viaje está en progreso, por lo cual el conductor será asignado a la unidad y eventos futuros.",
    videoAssignDriver_success: "Exito",
    videoAssignDriver_successMessage: "Conductor asignado exitosamente",
    videoAssignDriver_currentDriver: "Conductor: ",
    videoAssignDriver_errorStartNotFound: "El inicio del viaje (encendido del vehículo) no se encontró en las últimas 24 horas antes de este evento",
    videoAssignDriver_errorDriverNotAvailable: "El conductor ya está asignado a otro viaje en este rango de fecha y hora."
}