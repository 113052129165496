// Action types.
import {
  LOGIN_HISTORY_SEARCH,
  LOGIN_HISTORY_SEARCH_RESPONSE,
  LOGIN_HISTORY_FILTER_SEARCH_RESPONSE,
  LOGIN_HISTORY_SHOW_FILTER_SIDEPANEL,
} from "Redux/actionTypes";

export const searchLoginHistory = (data) => {
  return {
    type: LOGIN_HISTORY_SEARCH,
    payload: data,
  };
};

export const searchLoginHistoryResponse = (data) => {
  return {
    type: LOGIN_HISTORY_SEARCH_RESPONSE,
    payload: data,
  };
};

export const filterSearchLoginHistory = (data) => {
  return {
    type: LOGIN_HISTORY_FILTER_SEARCH_RESPONSE,
    payload: data,
  };
};

export const showSidePanelFilter = (data) => {
  return {
    type: LOGIN_HISTORY_SHOW_FILTER_SIDEPANEL,
    payload: data,
  };
};
