export default {
    settingsVehicleStatus_editTitle:"Etiqueta de estado",
    settingsVehicleStatus_createTitle:"Crear nueva etiqueta de estado",
    settingsVehicleStatus_minLengthMessage:'El campo debe contener un mínimo de caracteres: ',
    settingsVehicleStatus_maxLengthMessage:'El campo debe contener un maximo de caracteres: ',
    settingsVehicleStatus_success:'Etiqueta de estado del vehiculo Guardado',
    settingsVehicleStatus_deleteWarning:'Esta seguro que desea eliminar la etiqueta de estado?',
    settingsVehicleStatus_deleteTitle:'Eliminar etiqueta de estado',
    settingsVehicleStatus_deleted:'Etiqueta de estado eliminada',
    settingsVehicleStatus_search:'Buscando por Etiqueta de estado',
    settingsVehicleStatus_statusLabel:'Etiqueta de estado',
    settingsVehicleStatus_vehicleStatusIcon:'Icono'
};