export default {
    fsmRoute_routesTracker_modalStopVisited_title: "Stops Visited", 
    fsmRoute_routesTracker_modalStopVisited_close: "Close", 
    fsmRoute_routesTracker_modalStopVisited_name: "Name", 
    fsmRoute_routesTracker_modalStopVisited_status: "Status", 
    fsmRoute_routesTracker_modalStopVisited_departureTime: "Departure Time", 
    fsmRoute_routesTracker_modalStopVisited_arrivalTime: "Arrival Time", 
    fsmRoute_routesTracker_modalStopVisited_pending: "Pending", 
    fsmRoute_routesTracker_modalStopVisited_completed: "Completed", 
    fsmRoute_routesTracker_modalStopVisited_progress: "Progress", 
};