import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
    ACCOUNT_TAGS_DELETE_TAGS
} from 'Redux/actionTypes';

import {
    accountTagsDeleteTagsResponse
} from 'Redux/actions';

function* accountTagsDeleteTagsRequest({ payload }) {
    try {
        const { data } = payload;

        let query = `
            mutation deleteTags (
                $tagId: Int!, 
                $tagIdsToDelete: [Int]!
            ) {
                tag: deleteTags (
                    tagId: $tagId, 
                    tagIdsToDelete: $tagIdsToDelete
                )
            }
        `;

        const response = yield call(
            clientMutation,
            query,
            data,
            endpoints.GRAPHQL_GENERAL,
        );

        if (!response || !response.tag) throw 'Error while deleting tags';

        yield put(accountTagsDeleteTagsResponse({ ...response.tag, error: false }));
    } catch (exc) {
        yield put(accountTagsDeleteTagsResponse({ error: true }));
        ExceptionManager(exc, 'modules/account/views/tags/components/deleteTags/redux/saga', 'accountTagsDeleteTagsResponse');
    }
}

function* watchMethods() {
    yield takeEvery(ACCOUNT_TAGS_DELETE_TAGS, accountTagsDeleteTagsRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}