export default {
    realtimemaps_map_settings_offline_device: 'Offline Devices',
    realtimemaps_map_settings_offline_asset: 'Asset',
    realtimemaps_map_settings_offline_fleet: 'Fleet',
    realtimemaps_map_settings_offline_minutes: 'Minutes',
    realtimemaps_map_settings_offline_hours: 'Hours',
    realtimemaps_map_settings_offline_days: 'Days',
    realtimemaps_map_settings_offline_minute: 'Minute',
    realtimemaps_map_settings_offline_hour: 'hour',
    realtimemaps_map_settings_offline_day: 'Day',
    realtimemaps_map_settings_offline_threshold: 'Offline Threshold',
    realtimemaps_map_settings_device_type: 'Device Type',
    realtimemaps_map_settings_current_zoom: 'Current Zoom',
    realtimemaps_map_settings_unit_labels: 'Unit/Labels',
    realtimemaps_map_settings_considered_offline: 'Device is considered offline at',
    realtimemaps_map_settings_description_fleet: 'Fleet devices have consistent power sources, and typically report every 1-min while driving. When not driving but connected to power, the device will check in to confirm it is still Online. Use the options below to set the duration of time a Fleet Vehicle can go without checking in before it is considered Offline',
    realtimemaps_map_settings_description_asset: 'Asset devices do not have consistent power sources. At a minimum these devices are expected to check in or report their location once a day. Use the options below to set the duration of time an Asset can go without checking in before it is considered Offline',   
};