import {
  SMARTWITNESS_VIDEO_GET_SNAPSHOTS_URLS,
  SMARTWITNESS_VIDEO_GET_SNAPSHOTS_URLS_RESPONSE,
  SMARTWITNESS_VIDEO_REFRESH_SNAPSHOTS_URLS,
  SMARTWITNESS_VIDEO_REFRESH_SNAPSHOTS_URLS_RESPONSE,
  SMARTWITNESS_VIDEO_SAVE_SNAPSHOTS_URLS,
  SMARTWITNESS_VIDEO_SAVE_SNAPSHOTS_URLS_RESPONSE,
  CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
  loadingSnapshots: {},
  snapshotsResponse: {},
  loadingSaveSnapshotsUrls: {},
  saveSnapshotsUrlsResponse: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SMARTWITNESS_VIDEO_GET_SNAPSHOTS_URLS:
      return {
        ...state,
        snapshotsResponse: {
          ...state.snapshotsResponse,
          [action.payload.data?.recorderId]: null
        },
        loadingSnapshots: {
          ...state.loadingSnapshots,
          [action.payload.data?.recorderId]: true
        }
      };

    case SMARTWITNESS_VIDEO_GET_SNAPSHOTS_URLS_RESPONSE:
      return {
        ...state,
        snapshotsResponse: {
          ...state.snapshotsResponse,
          [action.payload.data?.recorderId]: action.payload.data
        },
        loadingSnapshots: {
          ...state.loadingSnapshots,
          [action.payload.data?.recorderId]: false
        }
      };

    case SMARTWITNESS_VIDEO_REFRESH_SNAPSHOTS_URLS:
      return {
        ...state
      };

    case SMARTWITNESS_VIDEO_REFRESH_SNAPSHOTS_URLS_RESPONSE:
      return {
        ...state,
        snapshotsResponse: {
          ...state.snapshotsResponse,
          [action.payload.data?.recorderId]: action.payload.data
        }
      };


    case SMARTWITNESS_VIDEO_SAVE_SNAPSHOTS_URLS:
      return {
        ...state,
        loadingSaveSnapshotsUrls: {
          ...state.loadingSaveSnapshotsUrls,
          [action.payload.data?.recorderId]: true
        },
        saveSnapshotsUrlsResponse: {
          ...state.saveSnapshotsUrlsResponse,
          [action.payload.data?.recorderId]: null
        }
      };

    case SMARTWITNESS_VIDEO_SAVE_SNAPSHOTS_URLS_RESPONSE:
      return {
        ...state,
        saveSnapshotsUrlsResponse: {
          ...state.saveSnapshotsUrlsResponse,
          [action.payload.data?.recorderId]: action.payload.data
        },
        loadingSaveSnapshotsUrls: {
          ...state.loadingSaveSnapshotsUrls,
          [action.payload.data?.recorderId]: false
        },
      };

    case CLEAR_REDUX:
      return ["", "SMARTWITNESS_VIDEO"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
}
