import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientMutation } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';

const INIT_STATE = {
    loading: false,
    open: false,
    result: null,
    data: null,
    error: false
};

const route = "Warnings/.components/Delete/redux/slice";

export const deleteMaintenanceWarningsEvents = createAsyncThunk("devices/.components/maintenanceWarnings/.components/confirmResolveMaintenanceWarningEvent/redux/slice/deleteMaintenanceWarningsEvents", async (payload, { rejectWithValue, dispatch }) => {
    const { unitId, warnings } = payload
    try {
        let response = await clientMutation(`
            mutation deleteMaintenanceWarningEvents($unitId: Int!, $warnings: [String]) {
                result: deleteMaintenanceWarningEvents(unitId: $unitId, warnings: $warnings)
            }`,
            {
                unitId: unitId,
                warnings: warnings
            },
            endpoints.GRAPHQL_GENERAL_V2
        )
        return { delete: response?.result }

    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'deleteMaintenanceWarningsEvents');
        return rejectWithValue(exc);
    }
})

export const warningsDeleteRedux = createSlice({
    name: 'devices/maintenanceWarnings/resolveMaintenanceWarningEvent',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openDeleteWarningsModal(state, action) {
            state.open = true
            state.data = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(deleteMaintenanceWarningsEvents.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(deleteMaintenanceWarningsEvents.fulfilled, (state, action) => {
            state.result = action.payload
            state.loading = false;
        });
        builder.addCase(deleteMaintenanceWarningsEvents.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });
    }
})

const { actions, reducer } = warningsDeleteRedux;

// Extract and export each action creator by name
export const {
    reset,
    openDeleteWarningsModal
} = actions;

export default reducer;
