//View Keys
export default {
  subUser_title: "Usuarios",
  subUser_delete: "Eliminar",
  subUser_cancel: "Cancelar",
  subUser_manageUserTypes: "Administrar tipos de usuarios",
  subUser_userName: "Nombre de usuario",
  subUser_email: "Correo electrónico",
  subUser_groups: "Grupos",
  subUser_driverGroups: "Grupos de conductores",
  subUser_tags: "Etiquetas",
  subUser_formGroup: "Grupos de formularios",
  subUser_landmarkGroups: "Grupos Emblemáticos",
  subUser_permissions: "Permisos",
  subUser_expires: "Expira",
  subUser_expiryDate: "Fecha de caducidad",
  subUser_selectDate: "Seleccione la fecha",
  subUser_ipRestriction: "Restricción de IP",
  subUser_password: "Contraseña",
  subUser_confirmPassword: "Confirmar Contraseña",
  subUser_userType: "Tipo de usuario",
  subUser_select: "Seleccione",
  subUser_messageDelete: "Está seguro de que desea eliminar el/los usuario(s)",
  subUser_deleteUser: "Eliminar Usuario(s)",
  subUser_create: "Crear Usuario",
  subUser_edit: "Editar Usuario",
  subUser_createMessage: "Usuario Creado",
  subUser_editMessage: "Usuario Actualizado",
  subUser_deleteMessage: "Usuario Eliminado",
  subUser_valueToCompareMessage: "Confirme que la contraseña coincida con la nueva contraseña",
  subUser_minMessageUsername: "El nombre de usuario debe contener un mínimo de caracteres:",
  subUser_minMessagePassword: "La constraseña debe contener un mínimo de caracteres:",
  subUser_maxMessagePassword: "La constraseña debe contener un máximo de caracteres:",
  subUser_minMessageConfirmPassword: "La confirmación de constraseña debe contener un mínimo de caracteres:",
  subUser_maxMessageConfirmPassword: "La confirmación de constraseña debe contener un máximo de caracteres:",
  subUser_maxMessageUsername: "El nombre de usuario debe contener un máximo de caracteres:",
  subUser_messageIpRestriction: "Dirección IP inválida",
  subUser_resetCognito: "Reiniciar",
  subUser_viewPermissions: "Ver permisos",
  subUser_resetUser: "Restablecer correo electrónico del usuario",
  subUser_resetPermissionMessage: "Está seguro que desea restablecer el correo electrónico del usuario",
  subUser_successResetCognito: "La dirección de correo electrónico se ha restablecido. Inicie sesión nuevamente para registrar una nueva dirección de correo electrónico.",
  subUser_name: "Nombre",
  subUser_role: "Rol",
  subUser_firstName: "Nombres",
  subUser_lastName: "Apellidos",
  subUser_never: "Nunca",
  subUser_phone: "Celular",
  subUser_maxLengthMessageCellPhone: "El celular debe contener un máximo de caracteres:",
  subUser_minLengthMessageCellPhone: "El celular debe contener un mínimo de caracteres:",
  subUser_successPasswordReset: "Contraseña restablecida",
  subUser_resetPassword: "Restablecer contraseña del usuario",
  subUser_resetPasswordMessage: "Está seguro que desea restablecer la contraseña del usuario",
  subUser_emailMessage: "El correo electrónico es invalido",
  subUser_minLengthMessageEmail: "El correo debe contener un mínimo de caracteres:",
  subUser_maxLengthMessageEmail: "El correo debe contener un máximo de caracteres:",
  subUser_apolloEldAccount: "Cuenta Apollo ELD",
  subUser_changePassword: "Cambiar la contraseña",
};