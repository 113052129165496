import {
    MAP_LANDMARKS_IMPORT_LANDMARK,
    MAP_LANDMARKS_IMPORT_LANDMARK_RESPONSE,
    MAP_LANDMARKS_IMPORT_OPEN_DETAIL,
    MAP_LANDMARKS_IMPORT_OPEN_EDIT,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    landmarkImportResponse: {},
    landmarkEdit: {},
    landmarkImportLoading: false,
    openDetailImportModal: false,
    openImportEditModal: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case MAP_LANDMARKS_IMPORT_LANDMARK:
            return {
                ...state,
                landmarkImportLoading: true
            };

        case MAP_LANDMARKS_IMPORT_LANDMARK_RESPONSE:
            return {
                ...state,
                landmarkImportResponse: action.payload.data,
                landmarkImportLoading: false
            };

        case MAP_LANDMARKS_IMPORT_OPEN_DETAIL:
            return {
                ...state,
                openDetailImportModal: action.payload.data
            };

        case MAP_LANDMARKS_IMPORT_OPEN_EDIT:
            return {
                ...state,
                openImportEditModal: action.payload.open,
                landmarkEdit: action.payload.data,
            };
            
        case CLEAR_REDUX:
            return ["", "MAP_LANDMARKS_IMPORT"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
            return state;
    }
}