import {
    MAP_LANDMARKS_DELETE_OPEN_MODAL,
    MAP_LANDMARKS_DELETE_LANDMARKS,
    MAP_LANDMARKS_DELETE_LANDMARKS_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    open: false,
    landmarks: [],
    response: null,
    loadingResponse: false,
    deleteAll: false,
    unselectedRows : []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MAP_LANDMARKS_DELETE_OPEN_MODAL:
            return {
                ...state,
                open: action.payload.open,
                landmarks: action.payload.landmarks || [],
                response: null,
                deleteAll: action.payload.deleteAll || false,
                unselectedRows: action.payload.unselectedRows || [],
                loadingResponse: false
            };

        case MAP_LANDMARKS_DELETE_LANDMARKS:
            return {
                ...state,
                response: null,
                loadingResponse: true
            };

        case MAP_LANDMARKS_DELETE_LANDMARKS_RESPONSE:
            return {
                ...state,
                response: action.payload.data,
                loadingResponse: false,
                deleteAll: false,
                unselectedRows: [],
            };

        case CLEAR_REDUX:
            return ["", "MAP_LANDMARKS_DELETE_LANDMARK"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
      return state;
    }
}