// Action Types
import { 
  UNIT_SETTINGS_GET_UNIT_GROUPS,
  UNIT_SETTINGS_GET_UNIT_GROUPS_RESPONSE,
  UNIT_SETTINGS_UPDATE_MULTIPLE_UNITS,
  UNIT_SETTINGS_UPDATE_MULTIPLE_UNITS_RESPONSE,
  CLEAR_REDUX
} from "Redux/actionTypes";

/**
 * Initial State Contacts
 */
const INITIAL_STATE = {
  loading: false,
  error: false,
  registers: [],
  updateUnits: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UNIT_SETTINGS_GET_UNIT_GROUPS:
      return {
        ...state,
        registers: [],
        loading: true,
        error: false,
      };
    case UNIT_SETTINGS_GET_UNIT_GROUPS_RESPONSE:
      return {
        ...state,
        registers: action.payload?.items,
        loading: false,
        error: action.payload?.error
      };
    case UNIT_SETTINGS_UPDATE_MULTIPLE_UNITS:
      return {
        ...state,
        updateUnits: false,
        loading: true,
        error: false,
      };
    case UNIT_SETTINGS_UPDATE_MULTIPLE_UNITS_RESPONSE:
      return {
        ...state,
        updateUnits: action.payload?.updateUnits,
        loading: false,
        error: action.payload?.error
      };
    case CLEAR_REDUX:
      return ["", "UNIT_SETTINGS"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INITIAL_STATE }
        : { ...state };  
    default:
      return state;
  }
};
