import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../core/logManager';
import { resetALL } from '../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    device: null,
    history: false,
    codes: [],
    loadingClearDTC: false,
    clearDTCResponse: null,
    error: null,
    numberTab: 0,
};

const route = 'modules/.common/components/Dtc/redux/slice'

export const dtcGetDeviceDTC = createAsyncThunk('dtc/dtcGetDeviceDTC', async ({ device, history }, { rejectWithValue, dispatch }) => {
    try {
        const { id } = device
        const response = await clientQuery(
            `
                query($deviceId:String!, $showHistory:Boolean) {
                    data: getDeviceDTC(deviceId:$deviceId, showHistory:$showHistory) {
                        id
                        deviceId
                        dtcCode
                        occurrences
                        lastUnitTime
                        protocol			
                        iotDtcStatus
                        lastRowId
                        description
                        updatedOn
                    }
                }
            `,
            {
                deviceId: id.toString(),
                showHistory: history
            },
            endpoints.GRAPHQL_GENERAL
        );

        return { codes: response?.data, device: device }
    } catch (exc) {
        ExceptionManager(exc, route, 'dtcGetDeviceDTC');
        return rejectWithValue(exc)
    }
});

export const dtcClearDTC = createAsyncThunk('dtc/dtcClearDTC', async ({ deviceId, ids }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientMutation(
            `
                mutation(
                    $deviceId:String!,
                    $ids:[String]!
                    ) {
                    data: deleteDeviceDTC (
                        deviceId: $deviceId,
                        ids: $ids
                    ) {
                        Message
                    }
                }
            `,
            {
                deviceId,
                ids
            },
            endpoints.GRAPHQL_GENERAL
        );

        return response?.data || false
    } catch (exc) {
        ExceptionManager(exc, route, 'dtcClearDTC');
        return rejectWithValue(exc)
    }
});

//Slice
const dtcDetailsRedux = createSlice({
    name: 'dtc/details',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : INIT_STATE
        },
        openDTCModal(state, action) {
            state.open = action.payload
        },
        setHistoryDTC(state, action) {
            state.history = action.payload.history
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        //maintenanceVTGetServicesList
        builder.addCase(dtcGetDeviceDTC.pending, (state, action) => {
            state.open = true
            state.loading = true
        });
        builder.addCase(dtcGetDeviceDTC.fulfilled, (state, action) => {
            state.loading = false,
                state.codes = action.payload.codes,
                state.device = action.payload.device
        });
        builder.addCase(dtcGetDeviceDTC.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });

        //dtcClearDTC
        builder.addCase(dtcClearDTC.pending, (state, action) => {
            state.loadingClearDTC = true
        });
        builder.addCase(dtcClearDTC.fulfilled, (state, action) => {
            state.loadingClearDTC = false,
                state.clearDTCResponse = action.payload
        });
        builder.addCase(dtcClearDTC.rejected, (state, action) => {
            state.loadingClearDTC = false;
            state.error = action.payload
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = dtcDetailsRedux;

// Extract and export each action creator by name
export const { reset, openDTCModal, setHistoryDTC } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;