//imports
import React, { useState, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from 'react-intl';
import { Grid } from '@mui/material';
import { ErrorHandlerLogin, validateEmail } from 'Core/data/Helpers';

import Modal, { ModalBody, ModalFooter } from 'Components/Modal';
import Loading from 'Components/Loading';
import Input from 'Components/Input';
import Button from 'Components/Button';
import Form from 'Components/Form';
import Pill from "Components/Pill";

import { useInput } from 'Core/hooks';
import './main.scss'

import {
  reportsOpenModalExtraTime
} from 'Redux/actions';


const ModalExtraTime = ({error, submitExtraTimeReport}) => {
  const { messages } = useIntl();
  const option = useSelector(state => state.reportsModalExtraTime.option);
  const OPTIONS_BIG_REPORTS = [
    { option: 1, id: "pdf", label: messages['reportsModalExtraTime_sendPDF'] },
    { option: 2, id: "csv", label: messages['reportsModalExtraTime_sendCSV'] },
    { option: 3, id: option, label: messages['reportsModalExtraTime_Illwait'] }
  ];
  const open = useSelector(state => state.reportsModalExtraTime.open);
  const loading = useSelector(state => state.reportsModalExtraTime.loading);
  const [ sendOption, setSendOption] = useState(OPTIONS_BIG_REPORTS[0])

  const email = useInput(
		'',
		'',
		messages['reportsModalExtraTime_email']
	);
  const dispatch = useDispatch();

  const frmModalExtraTime = useRef();
  const handleClose = () => {
    setSendOption(OPTIONS_BIG_REPORTS[0])
    email.onChange('')
    dispatch(reportsOpenModalExtraTime(false));
  }



  return (
    <Modal
      title={messages['reportsModalExtraTime_Title']}
      open={open}
      handleClose={handleClose}
      size="small"
    >
      <ModalBody>
        <div style={{ padding: 20 }}>
          {error && (
            <div className="form-item card-error-login">
              {
                messages[error] || ErrorHandlerLogin(error)
              }
            </div>
          )}
            <Form
              ref={frmModalExtraTime}
              onValidSubmit={() => {
                submitExtraTimeReport(sendOption?.id, sendOption?.option != 3 && !!email.value, email?.value || '')
                handleClose()
              }}
            >
              <div>
                <p className="report-txt-no-margin">{messages['reportsModalExtraTime_inputText']}</p>
                <Grid
                  container
                  spacing={1}
                >
                    <Grid
                      item
                      sm={6}
                    >
                      <Input
                        id="txtVerificationCode"
                        type="email"
                        email
                        emailMessage={messages['accountContacts_emailMessage']}
                        required={sendOption?.option != 3}
                        requiredMessage={messages['required_field']}
                        autoFocus
                        {...email}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      sm={6}
                      spacing={1}
                      justifyContent={'flex-end'}
                      alignItems={'center'}
                      style={{marginTop: '8px', textAlign:"center"}}
                    >
                      {
                        OPTIONS_BIG_REPORTS.map((optionMap, key) => {
                          const { option } = sendOption;
                          return (
                            <Grid
                              item
                              sm={4}
                              key={key}
                            >
                              <Pill
                                textField={'label'}
                                item={optionMap}
                                outlined={option !== optionMap?.option}
                                onClick={(entity) => {
                                  setSendOption(entity);
                                }}
                                style={{ border: '0', margin: '0px 0' }}
                                label="pill-label-text"
                                colorField="default"
                                className="pill-modal-extra-time"
                              />
                            </Grid>
                          );
                        })
                      }
                    </Grid>
                </Grid>

              </div>
            </Form>
        </div>
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <div className="loading-area">
            <Loading />
          </div>
        ) : (
            <Grid
              container
              justifyContent="flex-end"
            >
              <Button id="btnCloseConfirm" onClick={handleClose} className="btn-cancel">
                {messages['cancel']}
              </Button>
              {'  '}
              <Button
                id="btnConfirm"
                onClick={(e) => {
                  e.preventDefault();
                  frmModalExtraTime.current.submit();
                }}
                className="btn-blue"
              >
                {messages['okay']}
              </Button>
            </Grid>
          )}
      </ModalFooter>
    </Modal>
  )

}

export default ModalExtraTime
