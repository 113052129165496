import React, { useState } from 'react';
import { Grid } from '@mui/material/';
import { Pagination as MaterialPagination } from '@mui/material';
import { usePagination } from '@mui/lab';
import cx from 'classnames';
import './pagination.scss';

const Pagination = (props) => {
	const { messages } = props;
	const availablePages = Math.ceil(props.count / props.rowsPerPage);
	const [allRows, setAllRows] = useState(false);

	const handleChangeRowsPerPage = (rowsPerPage) => {
		const { onChangeRowsPerPage } = props;
		if (rowsPerPage == props.count) {
			setAllRows(true);
		} else {
			setAllRows(false);
		}

		if (onChangeRowsPerPage) {
			onChangeRowsPerPage(rowsPerPage);
		}
	};

	const { items } = usePagination({
		count: availablePages,
		page: props.page,
		onChange: props.onChangePage,
		color: "secondary",
		hideNextButton: props.hideNextButton || false
	});

	return (
		<div className="pagination-container">
			<Grid container direction="row" justifyContent="space-between" alignItems="flex-start" className="pagination-container-table-view">
				<Grid
					item
					xs={12}
					sm={12}
					md={4}
					lg={4}
					container
					direction="row"
					justifyContent="flex-start"
					alignItems="flex-start"
					className="pagination-controls"
				>
					<MaterialPagination
						style={{ display: 'none' }}
						count={availablePages}
						page={props.page}
						onChange={props.onChangePage}
						color="secondary"
					/>

					<ul className={props.onlyLine ? "onlyLine" : 'MuiPagination-ul'}>
						{
							items.map(({ page, type, selected, ...item }, index) => {
								let children = null;

								if (type === 'start-ellipsis' || type === 'end-ellipsis') {
									children = '…';
								} else if (type === 'page') {
									children = (
										<button type="button" className={cx('MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-page MuiPaginationItem-textSecondary', selected && 'Mui-selected')} {...item}>
											{page}
										</button>
									);
								} else {
									children = (
										<button type="button" className="btn-link" {...item}>
											{type === "previous" ? (messages ? messages['prev'] : 'prev') : (messages ? messages['nextp'] : 'next')}
										</button>
									);
								}

								return <li key={index}>{children}</li>;
							})
						}
					</ul>
				</Grid>

				<Grid
					item
					xs={props.children ? 2 : 4}
					sm={props.children ? 2 : 4}
					md={props.children ? 2 : 4}
					lg={props.children ? 2 : 4}
					container
					direction="row"
					justifyContent="center"
					alignItems="flex-start"
					style={{ marginBottom: 20 }}
				>
					<div className={props.classTotal ? props.classTotal : ''}>
						<span className="tableview-page-circle cursor-auto" style={{ padding: "2px 9px"}}>Total: {props.count}</span>
					</div>
				</Grid>

				{
					props.children
						?
						<Grid
							item
							xs={2}
							sm={2}
							md={2}
							lg={2}
							container
							direction="row"
							alignItems="flex-start"
							justifyContent="flex-start"
						>
							{props.children}
						</Grid>
						:
						<></>
				}

				<Grid
					item
					xs={4}
					sm={4}
					md={4}
					lg={4}
					container
					direction="row"
					justifyContent="flex-end"
					alignItems="flex-start"
					className="pagination-controls-table-view"
				>
					<React.Fragment>
						{
							!props.valuesPerPage
								?
								//Default values
								<React.Fragment>
									<span
										className={`tableview-page-circle ${props.rowsPerPage == 10 ? 'tableview-page-circle-active' : ''}`}
										onClick={() => handleChangeRowsPerPage(10)}
									>
										10
									</span>

									<span
										className={`tableview-page-circle ${props.rowsPerPage == 25 ? 'tableview-page-circle-active' : ''}`}
										onClick={() => handleChangeRowsPerPage(25)}
									>
										25
									</span>

									<span
										className={`tableview-page-circle ${props.rowsPerPage == 50 ? 'tableview-page-circle-active' : ''}`}
										onClick={() => handleChangeRowsPerPage(50)}
									>
										50
									</span>
								</React.Fragment>
								:
								//Array to pagination items
								props.valuesPerPage.map((element, index) => {
									return (
										<span
											key={index}
											className={`tableview-page-circle ${props.rowsPerPage == element ? 'tableview-page-circle-active' : ''}`}
											onClick={() => handleChangeRowsPerPage(element)}
										>
											{element}
										</span>
									)
								})
						}

						{
							!props.hideAllInPagination && (
								<span
									className={`tableview-page-circle ${props.rowsPerPage == props.count || allRows ? 'tableview-page-circle-active' : ''
										}`}
									onClick={() => handleChangeRowsPerPage(props.count)}
								>
									{(messages ? messages['all'] : 'all')}
								</span>
							)
						}


						<span className={`page-circle cursor-auto`}>{(messages ? messages['perPage'] : 'per page')}</span>
					</React.Fragment>
				</Grid>
			</Grid>
		</div>
	);
};

export default Pagination;
