// eslint-disable-next-line no-unused-vars
import React, { Fragment } from "react";
import cx from 'classnames';
import axios from 'axios'
import moment from 'moment';

import { endpoints } from 'Core/defaultValues';

import packageJson from '../../../package.json';

export const HasPermission = (user, permission, subuser) => {
	//console.log('HasPermission', user, permission, subuser)
	let siteOptions = user && user.siteOptions?.split(',');
	let subuserOptions = user && user.options && user.options?.split(',') || []
	let result = false;
	if (!siteOptions)
		return result;

	//if have permission
	if (!permission || permission === '') {
		result = true;
	} else if (Array.isArray(permission)) {
		//if array
		permission.forEach(p => {
			if (siteOptions.includes(p))
				result = true;
		})
	} else if (siteOptions.includes(permission)) {
		result = true;
	}

	//validate subuser permissions
	if (result && user.isSubUser && subuser) {
		result = false;

		//if array
		if (Array.isArray(subuser))
			subuser.forEach(p => {
				if (subuserOptions.includes(p.toString()))
					result = true;
			})
		else
			result = subuserOptions.includes(subuser)
	}

	//if(subuser?.includes('32'))
	return result;
};

export const HasReport = (user, permission, subuser) => {
	let reportOptions = user && user.reportOptions?.split(',');
	let subuserOptions = user && user.options && user.options?.split(',') || []

	let result = false;
	if (!reportOptions)
		return result;

	//if have permission
	if (!permission || permission === '') {
		result = true;
	} else if (Array.isArray(permission)) {
		//if array
		permission.forEach(p => {
			if (reportOptions.includes(p))
				result = true;
		})
	} else if (reportOptions.includes(permission)) {
		result = true;
	}

	//validate subuser permissions
	if (result && user.isSubUser && subuser) {
		result = false;

		//if array
		if (Array.isArray(subuser))
			subuser.forEach(p => {
				if (subuserOptions.includes(p.toString()))
					result = true;
			})
		else
			result = subuserOptions.includes(subuser)
	}

	return result;
}

let lastRevision;
export const GerVersionFromServer = (callback) => {
	if (!lastRevision || moment() >= lastRevision) {
		lastRevision = moment().add(5, 'second')
		//console.log("revision")
		try {
			axios.get(endpoints.VERSION_PUBLISH + "?date=" + new Date().getTime()).then(version => {
				if (version.status === 200 && version.data && version.data.Version) {
					localStorage.setItem("currentVersion", version.data.Version)
					if (callback)
						callback()
				}
			})

		} catch (error) {
			// console.log(error)
		}
	}
}

export const ValidateVersion = (type) => {

	let currentString = packageJson.version
	let latestString = localStorage.getItem("currentVersion")
	let currentNumber = 0
	let latestNumber = 0

	if (currentString)
		currentNumber = parseInt(currentString.replace(".", "").replace(".", ""))

	if (latestString)
		latestNumber = parseInt(latestString.replace(".", "").replace(".", ""))


	//console.log("validate version", currentString, latestString, currentNumber, latestNumber)

	//if (type == "login") {
	if (currentString && latestString && currentNumber < latestNumber) {
		window.location.reload();
		console.log("reload!!")
		//}
	}

	return (

		//currentString && latestString && currentNumber < latestNumber &&
		<Fragment>
			{/*
				type === "head" &&
				<div className={cx("version", "refresh-cache")}
					onClick={() => {
						window.location.reload();
					}}
				>
					New Update Available! <u><b>Update Now</b></u> to version v.{latestString}
				</div>
				*/}
		</Fragment>
	)
}

//dashboard_access 63
//vehicle_status_access 103
//status_unit_settings 64
//status_unit_outputs 30
//status_ping 67
//status_apu_starter 66
//status_alerts 65
//c4000_alerts 132
//jbus_alerts 130
//obdvbus_alerts 131
//driver_status_access 68
//map_settings 5
//ping 33
//send_sms 34
//google_earth 35
//trails 32
//vehicle_playback 100
//vehicle_directions 101
//nearest 102
//geofence_view 1
//geofence_add 2
//landmark_view 3
//landmark_add 4
//routes_add 36
//advanced_routing_access 136
//reports_access 21
//reports_group 27
//reports_driver 28
//reports_mdt 37
//reports_fsm 58
//reports_export 38
//reports Options 68-95
//settings_access 22
//settings_landmark_upload 12
//settings_mileage_reimbursement 129
//settings_landmark_proximity 123
//settings_alerts_add  6
//settings_alerts_edit 7
//settings_alerts_off_hour 40
//settings_scheduled_add 8
//settings_scheduled_edit 9
//settings_exports_xml 10
//settings_exports_kml 11
//vm_access 23
//vm_group_setup 16
//vm_driver_setup 17
//vm_status_labels 43
//vm_vehicle_landmarks 41
//fuel_card 49
//miles_per_gallon 53
//vm_keyfob 42
//vm_input_labels 18
//crew_management 126
//vm_vehicle_locations 44
//vm_geofence_icons 45
//vm_unit_settings 13
//vm_temperature 46
//vm_idle_kill 47
//vm_maintenance_new 20
//vm_maintenance_edit 19
//vm_geofence_view 14
//vm_geofence_edit 15
//vm_equipment_view 25
//vm_equipment_edit 26
//customers_access 142
//rfid_access 138
//mdt_access 56
//mdt_two_way 50
//mdt_routes 52
//mdt_settings 51
//fsm_access 59
//fsm_two_way 60
//fsm_driver_driver 124
//fsm_routes 61
//fsm_settings 62
//account_access 24
//account_contacts 54
//account_subusers 31
//account_login_history 55
//fsm_form_access 105
//help_access 104
//promiles_access 57
//tire_pressure_access 121
//eld_access 148
//elogs_access 122
//mobile_access 133
//fleet_manager_mobile_access 134
//media_events_access 201
//media_events_fetch_live_video 202
//media_events_fetch_video 203
//media_events_review_download_media 204
//media_events_review_share_media 205
//media_events_panic_button_alerts 211
//media_events_review_view 206
//media_events_review_edit 207
//media_events_review_perform_reviews 208
//media_events_manage_cameras 209
//media_events_manage_review_checklist 201
//media_events_manage_alerts 212
//maintenance_events_access 213
