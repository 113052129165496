import { Icons } from '@gpstrackit/gtc-react-controls'
import { Snackbar, Alert as AlertMui } from '@mui/material'
import React from 'react'

export const Alert = ({
    open,
    handleClose,
    onClick
}) => {

  return (
    <Snackbar
        style={{
          marginTop: '80px'
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        onClick={() => {
            onClick()
            handleClose(!open)
        }}
        key="Active Incident"
    >
        <AlertMui
          sx={{ width: '100%', background: 'red', color: 'white', cursor: 'pointer' }}
          severity="error"
          icon={<></>}
        >
            <div style={{display: 'flex', alignItems: 'center'}}>
              <h3 style={{margin: 0}}>Active Incident</h3>
              <Icons style={{fontSize: '20px', marginLeft: '10px'}} onClick={handleClose} name='open_in_new'></Icons>
            </div>
          
        </AlertMui>
    </Snackbar>
  )
}
