import React, { useEffect, useState } from "react";
import { Tooltip } from "..";
import { truncateString } from "./utils";

const ConcatLabel = ({ username, concatLimit }) => {
  const [truncate, setTruncateString] = useState();

  useEffect(() => {
    if (username) {
      setTruncateString(truncateString(username, concatLimit));
    }
  }, []);

  return (
    <div className="header-title">
      {truncate ? (
          <Tooltip title={username}>
            <p style={{margin: 0}}>{truncate}</p>
          </Tooltip>
      ) : (
        username || "COMPANY NAME"
      )}
    </div>
  );
};

export default ConcatLabel;
