export default {
  clipRequestAdvancedFilters_advancedFilters: 'Advanced Filters',
  clipRequestAdvancedFilters_selected: 'selected',
  clipRequestAdvancedFilters_hasNotes: 'Has Notes',
  clipRequestAdvancedFilters_singleUnits: 'Units',
  clipRequestAdvancedFilters_unitGroups: 'Unit Groups',
  clipRequestAdvancedFilters_drivers: 'Drivers',
  clipRequestAdvancedFilters_driverGroups: 'Drivers Groups',
  clipRequestAdvancedFilters_all: 'All',
  clipRequestAdvancedFilterBy: 'Filter By:',
  clipRequestAdvancedTimeFrame: 'Timeframe:',
  clipRequestAdvancedStatus: 'Status',
  clipRequestAdvancedMediaType: 'Media Type',
};
