import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';
// Actions
import {
  reportsMainGenerateReportProgress,
  reportsMainGenerateReportUpdate
} from 'Redux/actions';

import {
  getTimeZone,
  getCategoryFilter,
  handleAsyncReport,
  baseReportConditions,
  generateReportInstance,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';


import {
  getAbbvTimezone
} from "./../../../../../../../../../components/uiControls/Timezone/tools";

function* getFormStopReportV2(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
  const { timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath } = report;
  const { filterType, filterArray, startRange, endRange, unitsSelected,otherFilters, accumulateData = true, dates } = report.filters
  const baseConditionsQuery = baseReportConditions({ report, name, dataResultPath, timeZone, delayed, unitsSelected, timeframeName, filterType, filterArray, categoryName, startRange, endRange, dates })
  try {
    const stringValues = otherFilters?.form === 1 ? [] : {
      values: otherFilters?.form
  }
    let payload = {
      conditions: {
        ...baseConditionsQuery,
        integerRanges: {
          fields: [
            "stop"
          ],
          GTE: otherFilters.minute || 0,
          LTE: 0
        },
        stringValues,
        sortField: {
          field: sortField || "deviceId",
          order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
      },
      limit: rowsPerPage,
      offset: page
    };

    if (report.export) {
      const columnsCSV = report?.exportType === "csv" ? [{
            "column": "formName",
            "header": "Form"
        },
        {
            "column": "label",
            "header": "Question"
        },
        {
            "column": "value",
            "header": "Answer"
        }] : []

      const columnDriverCSV = report?.exportType === "csv" ? [ {
          "column": "idLabel",
          "header": "Driver"
        }] : []

      payload = {
        ...payload,
        limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 1000,
        offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
        conditions: {
          ...payload.conditions,
          exportOptions: {
            sendByEmail,
            emailsTo,
            format: {
              fileFormat: report?.exportType || "json",
              formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\", \"rowPageBreak\": \"avoid\"}",
              durationFormat: report?.durationFormat
            },
            culture: report.lang,
            timeZone: getTimeZone(timeZone),
            folderName: "px-customer-reports",
            filePrefixName: "formStopSummaryReportV2",
            columns: [
              ...columnDriverCSV,
              {
                "column": "event",
                "header": "Event"
              },
              {
                "column": "address",
                "header": "Address"
              },
              {
                "column": "duration",
                "header": "Duration"
              },
              {
                "column": "dateTime",
                "header": `Time & Date ${report?.exportType === 'csv' ? `(${getAbbvTimezone(timeZone)})`: ''}`
              },
              ...columnsCSV
            ]
          }
        }
      }
    }

    let data = yield call(
      clientQuery,
      `query getFormStopReportV2 ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getFormStopReportV2 (conditions: $conditions, limit: $limit, offset: $offset) {
          ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
        `reportInstanceId`
        : `
            items {
              recordInfo {
                entityId
                entityLabel
                tagId
                tagLabel
                startDate
                endDate
                recordType
                groupLabel
                groupIndex
                groupTotal
                groupTagColor
              }
              recordDetail {
                driverName
                driverId
                unitTime
                event
                duration
                address
                formValues {
                  label
                  value
                  type
                }
                noData
                formName
                latitude
                longitude
                noData
                date
              }
            }
            total
            limit
            offset
            category
        `}
        }
        
      }`,
      payload,
      endpoints.GRAPHQL_PX_REPORTING,
    );
    yield handleAsyncReport(report, data, accumulateData);

  } catch (exc) {
    ExceptionManager(exc, 'reports/detailedReport', 'getFormStopReport');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
    if (!requestFlag) {
      let flag = true
      yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
    } else {
      report.loading = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  }
}


export default getFormStopReportV2;
