import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Tooltip } from "../../../components";

//Utils
import {
    saveStatusChat,
    queuedMessages,
    getStatusChat,
    closeChat,
} from "../../containers/Header/utils";
import { LogManagerConsole } from 'Core/logManager';

//Actions
import { changeSalesForceStatus } from "Redux/actions";

const ChatWithSupport = () => {
    const { messages } = useIntl();
    const dispatch = useDispatch();

    //Redux States
    const { salesForceStatus } = useSelector(state => state.securityRedux);

    //Local States
    const [isAgentAvailable, setIsAgentAvailable] = useState(false);
    const [status, setStatus] = useState();

    const chatHandler = () => {
        if (isAgentAvailable) {
            dispatch(changeSalesForceStatus(salesForceStatus))
            queuedMessages('delete')
            if (status === 'message') {
                setStatus('inConversation')
            }
            const chatButton = document.querySelector('.helpButtonEnabled');
            if (chatButton) chatButton.click()
        }
    }

    useEffect(() => {
        setTimeout(() => {
            if (window.embedded_svc?.liveAgentAPI) {
                window.embedded_svc.liveAgentAPI.ping();
                window.embedded_svc.addEventHandler(
                    "onAvailability",
                    function (data) {
                        document.querySelector('.embeddedServiceSidebar')?.classList.add('login-sf-chat');
                        //status salesforce chat
                        LogManagerConsole("SF", 'status salesforce chat', data)
                        if (data.isAgentAvailable) {
                            setIsAgentAvailable(true);
                            setStatus('available')
                            if (getStatusChat()) {
                                setStatus('inConversation')
                            }
                        } else {
                            setIsAgentAvailable(false);
                            closeChat()
                            queuedMessages('delete')
                            setStatus('available')
                        }
                    }
                );
                window.embedded_svc.addEventHandler(
                    "onChatEstablished",
                    function () {
                        setStatus('inConversation')
                    }
                );
                window.embedded_svc.addEventHandler("onAgentMessage", function () {
                    setStatus('message')
                    queuedMessages('save')
                    saveStatusChat()
                });
                window.embedded_svc.addEventHandler(
                    "onChatEndedByChasitor",
                    function () {
                        closeChat()
                        setStatus('available')
                        queuedMessages('delete')
                    }
                );
                window.embedded_svc.addEventHandler(
                    "onChatEndedByAgent",
                    function () {
                        closeChat()
                        setStatus('available')
                        queuedMessages('delete')
                    }
                );
            }
        }, 2000);

    }, []);

    return (
        <Tooltip
            title={isAgentAvailable ? messages["header_chatEnabled"] : messages["header_chatDisabeld"]}
        >
            <a
                id="btn-chat-with-support"
                type="button"
                className="btn-link login-chat-support"
                style={{ color: !isAgentAvailable && '#e4e4e4' }}
                onClick={() => chatHandler()}
            >
                {messages['login_chat_with_support']}
            </a>
        </Tooltip>
    );
}

export default ChatWithSupport;
