import {
    MAP_LANDMARKS_LIST_GETTAGS,
    MAP_LANDMARKS_LIST_GETTAGS_RESPONSE,
    MAP_LANDMARKS_LIST_GETTAGS_TOCREATE,
    MAP_LANDMARKS_LIST_GETTAGS_TOCREATE_RESPONSE
} from 'Redux/actionTypes';


export const mapGetLandmarkTags = () => ({
    type: MAP_LANDMARKS_LIST_GETTAGS,
    payload: { entityName: "Landmark", getAllTags : false }
});

export const mapGetLandmarkTagsResponse = (data) => ({
    type: MAP_LANDMARKS_LIST_GETTAGS_RESPONSE,
    payload: { data },
});

export const mapGetLandmarkTagsToCreate = () => ({
    type: MAP_LANDMARKS_LIST_GETTAGS_TOCREATE,
    payload: { entityName: "Landmark", getAllTags : true }
});

export const mapGetLandmarkTagsToCreateResponse = (data) => ({
    type: MAP_LANDMARKS_LIST_GETTAGS_TOCREATE_RESPONSE,
    payload: { data },
});