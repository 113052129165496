export default {
    eta_delete_title: "Delete Eta Link",
    eta_delete_messages_links: "Are you sure you want to delete the eta links?",
    eta_delete_messages_link: "Are you sure you want to delete the eta link?",
    eta_delete_unit: "Unit",
    eta_delete_destination: "Destination",
    eta_delete_action_cannot_be_undone: "This action cannot be undone.",

    eta_delete_success_links: "Eta links have been deleted",
    eta_delete_success_link: "The eta link has been deleted",
    eta_delete_error_links: "An error occurred, please try again.",
}