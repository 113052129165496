export default {
    videoAssignDriver_title: "Trip - Assign Driver",
    videoAssignDriver_driver: "Driver",
    videoAssignDriver_start: "Start: ",
    videoAssignDriver_end: "End: ",
    videoAssignDriver_ignitionOn: "Ignition On",
    videoAssignDriver_ignitionOff: "Ignition Off",
    videoAssignDriver_activeTrip: "Active trip",
    videoAssignDriver_tripNotFinished: "The trip is still in progress so the driver will be assigned to the unit, and future events",
    videoAssignDriver_success: "Success",
    videoAssignDriver_successMessage: "Driver assigned successfully",
    videoAssignDriver_currentDriver: "Driver: ",
    videoAssignDriver_errorStartNotFound: "The trip start (Ignition On) not found in the last 24 hours before this event",
    videoAssignDriver_errorDriverNotAvailable: "The driver is already assigned to other trip at this date time range",
}