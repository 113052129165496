import React, { useState } from 'react';
import cx from 'classnames';
import { AppBar, Toolbar, Icon, Typography, IconButton, CardContent, Card } from '@mui/material';
import { Tooltip } from 'Components';
import './cardflip.scss';


const CardContainer = (props) => {
  const { type, children, headerTitle, secondTitle, flip = true, collapse = false, collapseActivated, customIcon, tooltipIcon, onClick, showTitle, className } = props;
  const buildTitle = () => {
    // method to build the Title in the Card if necesary
    return (
      <Typography color="inherit" className={"flip-card-title"} >
        {type === "flip-card-front" ? headerTitle : secondTitle ? secondTitle : headerTitle}
      </Typography>
    )
  }
  const renderButtonIcon = () => {
    return (
      <IconButton
        className={"flip-card-icon"}
        aria-haspopup="true"
        onClick={() => props.handleFlip()}
        color="inherit"
        size="large">
        <Icon>{props.customIconInfo || 'info'}</Icon>
      </IconButton>
    );
  }
  return (
    <Card className={`flip-card-root ${type}`} onClick={onClick}>
      <AppBar position="static" className="flip-card-header">
        <Toolbar className={cx('title-container', !collapseActivated ? "flip-card-iconCollapse" : "")}>

          {showTitle ? buildTitle() : collapseActivated  && buildTitle()}
          <div>
            {flip && collapseActivated && (
              tooltipIcon ? (
                <Tooltip title={tooltipIcon}>
                  {renderButtonIcon()}
                </Tooltip>
              ) :
              renderButtonIcon()
            )}
            {collapse && collapseActivated ? (
              <IconButton
                className={"flip-card-icon"}
                aria-haspopup="true"
                onClick={() => props.handleSection()}
                color="inherit"
                size="large">
                <Icon>keyboard_arrow_left</Icon>
              </IconButton>
            ) : (
              !showTitle &&(
                <IconButton
                  className={"flip-card-icon"}
                  aria-haspopup="true"
                  onClick={() => props.handleSection()}
                  color="inherit"
                  style={{ padding: 0 }}
                  size="large">
                  <Icon>{customIcon}</Icon>
                </IconButton>
              )
            )}
          </div>
        </Toolbar>
      </AppBar>
      <CardContent className={className}>
        {children}
      </CardContent>
    </Card>
  );
}

const CardFlip = (props) => {
  const { children, title, secondTitle = "", flip } = props;
  const [state, setstate] = useState(false);
  const handleFlip = () => setstate(!state);
  return (
    <div className="wrapper">
      <div className={`flip-card ${state && 'do-flip'}`}>
        <CardContainer {...props} handleFlip={handleFlip} type="flip-card-front" headerTitle={title} secondTitle={secondTitle}>
          {flip ? children[0] : children}
        </CardContainer>
        <CardContainer {...props} handleFlip={handleFlip} type="flip-card-back" headerTitle={title} secondTitle={secondTitle}>
          {children[1]}
        </CardContainer>
      </div>
    </div>
  );
};

CardFlip.defaultProps = {
  title: '',
  secondTitle: "Back"
};

CardContainer.defaultProps = {
  customIcon: "settings"
}


export default CardFlip;