export default {
    troubleshootDevice_title: "Solución de problemas",
    troubleshootDevice_isThisDeviceNotReportingCorrectly: "¿Este dispositivo no informa correctamente?",
    troubleshootDevice_clickStartToBeginTroubleshooting: "Haga clic en Iniciar para comenzar la solución de problemas, en cualquier momento durante el proceso puede hacer clic",
    troubleshootDevice_alternatively: "Alternativamente, puede explorar los pasos recomendados en el Centro de ayuda",
    troubleshootDevice_connectionHistory: "Historial de conexiones",
    troubleshootDevice_location: "Ubicación",
    troubleshootDevice_OdometerEngineHours: "Horas del cuentakilómetros y del motor",
    troubleshootDevice_noInstallationHistory: "Sin historial de instalación",
    troubleshootDevice_recommendedSteps: "No podemos encontrar ningún historial de instalación del dispositivo. Si se ha instalado, póngase en contacto con nuestro equipo de asistencia o siga los pasos recomendados en el Centro de ayuda",
    troubleshootDevice_supportChat: "Chat de asistencia",
    troubleshootDevice_installationHistory: "Historia de la instalación",
    troubleshootDevice_installedOn: "Instalado en",
    troubleshootDevice_confirmingInstallation: "Confirmación de la instalación",
    troubleshootDevice_checkingCellularConnection: "Comprobación de la conexión celular",
    troubleshootDevice_cellularHistory: "Historia del celular",
    troubleshootDevice_lastSession: "Última sesión",
    troubleshootDevice_lastStatus: "Estado",
    troubleshootDevice_connectivityIssuesMessage: "Hemos detectado que la SIM del dispositivo tiene problemas de conectividad. Ponte en contacto con el servicio de asistencia a través del chat que aparece a continuación o consulta los pasos recomendados en el Centro de asistencia.",
    troubleshootDevice_validInstallationDate: "Hemos identificado una fecha de instalación válida.  El historial celular indica que la unidad tiene una conexión saludable. Haga clic en Siguiente para continuar con la ubicación",
    troubleshootDevice_yes: "Si ",
    troubleshootDevice_no: "No ",
    troubleshootDevice_bad: "Malo",
    troubleshootDevice_poor: "Débil",
    troubleshootDevice_good: "Bueno",
    troubleshootDevice_refreshingLocation: "Refrescando la Ubicación. Puede tardar hasta 2 minutos",
    troubleshootDevice_checkingLocation: "Comprobando de la ubicación",
    troubleshootDevice_gpsSignal: "Señal GPS",
    troubleshootDevice_address: "Dirección",
    troubleshootDevice_unitUnresponsive: "La unidad no responde, por favor contacta con el soporte usando el chat de abajo o explora los pasos recomendados en el Centro de Ayuda.",
    troubleshootDevice_unitResponsive: "La unidad respondió y la ubicación se ha actualizado ¿es aquí donde se encuentra la unidad?",
    troubleshootDevice_theLocationIsAccurate: "La ubicación es correcta haga clic en Siguiente continuar",
    troubleshootDevice_IsThisWhereTheUnitIsLocated: "¿Es aquí donde se encuentra la unidad?",
    troubleshootDevice_clickPingBuntton: "Haga clic en PING para forzar a la unidad a informar de una nueva ubicación",
    troubleshootDevice_checkingOdometer: "Comprobando el odómetro",
    troubleshootDevice_CheckingEngineHours: "Comprobación de las horas del motor",
    troubleshootDevice_odometer: "Odómetro",
    troubleshootDevice_isTheOdometerReadingCorrect: "¿Es correcta la lectura del odómetro?",
    troubleshootDevice_dashReading: "Lectura",
    troubleshootDevice_offset: "Desplazar",
    troubleshootDevice_engineHours: "Horas de motor",
    troubleshootDevice_IsThisEngineHoursReadingCorrect: "¿Es correcta la lectura de las horas del motor?",
    troubleshootDevice_clickNextToContinue: "El Odómetro y/o las Horas Motor se actualizarán al pulsar Siguiente. Haga clic en Siguiente para continuar",
    troubleshootDevice_clickNextToContinueOnlyOdometer: "El Odómetro se actualizará al pulsar Siguiente. Haga clic en Siguiente para continuar",
    troubleshootDevice_clickNextToContinueOnlyEngineHours: "Las horas del motor se actualizarán al hacer clic en Siguiente. Haga clic en Siguiente para continuar",
    troubleshootDevice_summary: "Resumen",
    troubleshootDevice_theTrobleshootingCompleted: "La resolución de problemas ha finalizado.",
    troubleshootDevice_additionalSupport: "Si sigue observando un comportamiento incorrecto, haga clic en Chat de soporte para obtener ayuda adicional o siga los pasos recomendados en el Centro de ayuda.",
    troubleshootDevice_endMessage: "Si el problema se ha resuelto, haga clic en Finalizar",
    troubleshootDevice_resettingTheDevice: "Restableciendo el dispositivo",
    troubleshootDevice_noReading: "Sin lectura",
    troubleshootDevice_resetProcessHasBeenExecuted: "Se ha ejecutado el proceso de reinicio",
    troubleshootDevice_restartProcessCouldNotBeExecuted: "No se ha podido ejecutar el proceso de reinicio.",
    troubleshootDevice_pleaseContactOurSupport: "Póngase en contacto con nuestro equipo de asistencia o siga los pasos recomendados en el Centro de ayuda",
    troubleshootDevice_resetDeviceMessage: "El restablecimiento de un dispositivo en buenas condiciones puede tardar entre 10 y 15 minutos en realizarse con éxito. Una vez restablecido, el dispositivo volverá a encenderse y restablecerá la conexión. Si el problema no se resuelve, es posible que tenga que volver a ejecutar el trobleshooter.",
    troubleshootDevice_resetDeviceConfirm: "¿Seguro que quieres reiniciar?",
    troubleshootDevice_back: "Volver ",
    troubleshootDevice_Reset: "Reiniciar ",
    troubleshootDevice_resetDeviceResponse: "Esta unidad está respondiendo con una mala localización. Esto podría deberse a un GPS incorrecto. Reiniciar el dispositivo puede resolver este problema. Haga clic en Reiniciar para reiniciar el dispositivo ",
}