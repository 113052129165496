import {
    FSM_SETTINGS_DRIVER_STATUS_SEARCH,
    FSM_SETTINGS_DRIVER_STATUS_SEARCH_RESPONSE,
    FSM_SETTINGS_DRIVER_STATUS_CREATE,
    FSM_SETTINGS_DRIVER_STATUS_CREATE_RESPONSE,
    FSM_SETTINGS_DRIVER_STATUS_UPDATE,
    FSM_SETTINGS_DRIVER_STATUS_UPDATE_RESPONSE,
    FSM_SETTINGS_DRIVER_STATUS_DELETE,
    FSM_SETTINGS_DRIVER_STATUS_DELETE_RESPONSE,
    FSM_SETTINGS_DRIVER_STATUS_OPEN_CREATE_EDIT,
    FSM_SETTINGS_DRIVER_STATUS_OPEN_DELETE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loadingDriverStatus: false,
    quickMData: [],
    DriverStatus: {},
    loadingCreateEdit: false,
    openCreateEditDriverStatus: false,
    loadingDelete: false,
    response: {},
    responseDelete: false,
    openDelete: false,
    driverStatusDelete: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case FSM_SETTINGS_DRIVER_STATUS_SEARCH:
            return {
                ...state,
                loadingDriverStatus: true,
                quickMData: []
            };

        case FSM_SETTINGS_DRIVER_STATUS_SEARCH_RESPONSE:
            return {
                ...state,
                loadingDriverStatus: false,
                quickMData: action?.payload?.driverStatus
            };

        case FSM_SETTINGS_DRIVER_STATUS_CREATE:
            return {
                ...state,
                loadingCreateEdit: true,
                response: {}
            };

        case FSM_SETTINGS_DRIVER_STATUS_CREATE_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                response: action.payload
            };

        case FSM_SETTINGS_DRIVER_STATUS_UPDATE:
            return {
                ...state,
                loadingCreateEdit: true,
                response: {}
            };

        case FSM_SETTINGS_DRIVER_STATUS_UPDATE_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                response: action.payload
            };

        case FSM_SETTINGS_DRIVER_STATUS_DELETE:
            return {
                ...state,
                loadingDelete: true,
                responseDelete: false
            };

        case FSM_SETTINGS_DRIVER_STATUS_DELETE_RESPONSE:
            return {
                ...state,
                loadingDelete: false,
                responseDelete: action.payload?.data
            };

        case FSM_SETTINGS_DRIVER_STATUS_OPEN_CREATE_EDIT:
            return {
                ...state,
                openCreateEditDriverStatus: action.payload?.open,
                DriverStatus: action.payload?.driverStatus,
            };

        case FSM_SETTINGS_DRIVER_STATUS_OPEN_DELETE:
            return {
                ...state,
                openDelete: action.payload?.open,
                driverStatusDelete: action.payload?.driverStatuss,
            };

        case CLEAR_REDUX:
            return ["", "FSM_SETTINGS_DRIVER_STATUS"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return state;
    }
};