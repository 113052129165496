import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';
// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

import {
  baseReportConditions,
  generateReportInstance,
  getCategoryFilter,
  getTimeZone,
  handleAsyncReport,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getIgnitionReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
  const {timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath} = report;
  const {filterType, filterArray, startRange, endRange, unitsSelected, accumulateData = true, dates} = report.filters

  const baseConditionsQuery = baseReportConditions({
    report,
    name,
    dataResultPath,
    timeZone,
    delayed,
    unitsSelected,
    timeframeName,
    filterType,
    filterArray,
    categoryName,
    startRange,
    endRange,
    dates
  })
  try {
    let payload = {
      conditions: {
        ...baseConditionsQuery,
        sortField: {
          field: sortField || "deviceId",
          order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
      },
      limit: rowsPerPage,
      offset: page
    };
    let addColumnDriver = [];
    if (report?.exportType === 'csv') {
      addColumnDriver.push({
        "column": getCategoryFilter(filterType) === "deviceId" ? "deviceLabel" : "driverName",
        "header": getCategoryFilter(filterType) === "deviceId" ? "Unit" : "Driver"
      })
    }


    let columns = [
      ...addColumnDriver,
      {
        "column": getCategoryFilter(filterType) === "deviceId" ? "driverName" : "deviceLabel",
        "header": getCategoryFilter(filterType) === "deviceId" ? "Driver" : "Unit"
      },
      {
        "column": "date",
        "header": "Date / Time"
      },
      {
        "column": "address",
        "header": "Address"
      },
      {
        "column": "eventName",
        "header": "Event"
      },
      {
        "column": "durationDelta",
        "header": "Duration"
      }
    ]

    payload = {
      ...payload,
      limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
      offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
      conditions: {
        ...payload.conditions,
        exportOptions: {
          sendByEmail,
          emailsTo,
          format: {
            fileFormat: report?.exportType || "json",
            formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
            durationFormat: report?.durationFormat
          },
          culture: report.lang,
          timeZone: getTimeZone(timeZone),
          folderName: "px-customer-reports",
          filePrefixName: "ignitionReport",
          columns
        }
      }
    }
    let data = yield call(
        clientQuery,
        `query getIgnitionReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getIgnitionReport (conditions: $conditions, limit: $limit, offset: $offset) {
          ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) || report.exportFromTable ?
            `reportInstanceId`
            : `
                    items {
                        recordInfo {
                            entityId
                            entityLabel
                            tagId
                            tagLabel
                            startDate
                            endDate
                            recordType
                            groupLabel
                            groupIndex
                            groupTotal
                            groupTagColor
                        }
                        recordDetail {
                            id
                            idLabel
                            totalIgnitionOnTime
                            totalIgnitionOffTime
                            startDate
                            endDate
                            date
                            deviceId
                            unitId
                            unitTime
                            driverId
                            driverName
                            eventName
                            address
                            deviceLabel
                            landmarkId
                            landmarkName
                            duration
                            latitude
                            longitude
                            noData
                        }
                    }
                    total
                    limit
                    offset
                    category
                  `}
                }
              }`,
        payload,
        endpoints.GRAPHQL_PX_REPORTING,
    );
    yield handleAsyncReport(report, data, accumulateData);
  } catch (exc) {
    ExceptionManager(exc, 'reports/ignitionReport', 'getIgnitionReport');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
    if (!requestFlag) {
      let flag = true
      yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
    } else {
      report.loading = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  }
}


export default getIgnitionReport;
