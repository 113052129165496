export default {
    fsmRoute_edit_panel_route_savedRoute: "Informacion almacenada correctamente",
    fsmRoute_edit_panel_route_remove_point: "Elimine el punto incorrecto para poder continuar",
    fsmRoute_edit_panel_route_template: "Plantilla",
    fsmRoute_edit_panel_route_driver: "Conductor",
    fsmRoute_edit_panel_route_schedule_at: "Programada en",
    fsmRoute_edit_panel_route_is_template: "Pantilla",
    fsmRoute_edit_panel_route_assigned_route: "Rutas Asignadas",
    fsmRoute_edit_panel_route_assigned_actions: "Acciones",
    fsmRoute_edit_panel_route_assigned_add_stop: "Agregar Parada",
    fsmRoute_edit_panel_route_close_confirmation_modal_title: "¡Aviso!",
    fsmRoute_edit_panel_route_close_confirmation_modal: "¿Está seguro de que desea cerrar el editor de rutas?, perderá los cambios no guardados.",
};