import {
    MAP_LANDMARKS_BULK_OPEN_MODAL,
    MAP_LANDMARKS_BULK_UPDATE,
    MAP_LANDMARKS_BULK_UPDATE_RESPONSE,
} from 'Redux/actionTypes';

export const mapLandmarkBulkOpenModal = (open, landmarks) => ({
    type: MAP_LANDMARKS_BULK_OPEN_MODAL,
    payload: { open, landmarks },
});

export const mapLandmarksBulkUpdate = (landmarksBulk, landmarkIcon = null) => ({
    type: MAP_LANDMARKS_BULK_UPDATE,
    payload: { landmarksBulk, landmarkIcon },
});

export const mapLandmarksBulkUpdateResponse = (data) => ({
    type: MAP_LANDMARKS_BULK_UPDATE_RESPONSE,
    payload: { data },
});