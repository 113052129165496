export const GET_DAILY_BEHAVIOR = 'GET_DAILY_BEHAVIOR';
export const GET_DAILY_BEHAVIOR_RESULT = 'GET_DAILY_BEHAVIOR_RESULT';
export const UPDATE_POINTS_BEHAVIOR = 'UPDATE_POINTS_BEHAVIORS';
export const UPDATE_GRADES_BEHAVIOR = 'UPDATE_GRADES_BEHAVIOR';
export const SET_BEHAVIOR_POINTS = 'SET_BEHAVIOR_POINTS';
export const SET_BEHAVIOR_POINTS_RESULT = 'SET_BEHAVIOR_POINTS_RESULT';

export const GET_BEHAVIOR_TRENDS = 'GET_BEHAVIOR_TRENDS';
export const GET_BEHAVIOR_TRENDS_RESULT = 'GET_BEHAVIOR_TRENDS_RESULT';

export const BEHAVIOR_TRENDS_CHART_INFO = 'BEHAVIOR_TRENDS_CHART_INFO';

//Fetching points behaviors
export const GET_POINTS_BEHAVIOR = 'GET_POINTS_BEHAVIOR';
export const GET_POINTS_BEHAVIOR_RESULTS = 'GET_POINTS_BEHAVIOR_RESULTS';

//Fetching points behaviors
export const CREATE_NEW_CUSTOM_SPEED = 'CREATE_NEW_CUSTOM_SPEED';
export const CREATE_NEW_CUSTOM_SPEED_RESULTS = 'CREATE_NEW_CUSTOM_SPEED_RESULTS';

//Fetching points behaviors
export const DELETE_CUSTOM_SPEED = 'DELETE_CUSTOM_SPEED';
export const DELETE_CUSTOM_SPEED_RESULT = 'DELETE_CUSTOM_SPEED_RESULT';

// EXPORT_DAILY_BEHAVIOR
export const EXPORT_DAILY_BEHAVIOR = 'EXPORT_DAILY_BEHAVIOR';
export const EXPORT_DAILY_BEHAVIOR_RESULT = 'EXPORT_DAILY_BEHAVIOR_RESULT';