import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// controls
import Table, { Column } from '../../table'

import {
  formatDatetime,
  formatTimeDelta,
  getCategoryFilterType,
  getEntityType,
  useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';


// hooks
import { useSidePanelMap, useTableReport } from 'Core/hooks';
// actions
import {
  reportsMainGenerateReportUpdate,
  clearRedux,
  reportsMainGenerateSubEvents,
  reportsMainGenerateReportProgress,
  reportsMainGenerateEvents
} from 'Redux/actions';

import {
  dateTimeZoneToUTC
} from 'Core/data/Helpers';

import AddressLandmark from "Modules/reports/views/main/components/reportsTab/.components/addressLandmark";

import './fsmCombinedReport.scss';

const FSMCombinedReport = (props) => {

  const { messages, report } = props;
  const dispatch = useDispatch();
  const { loading, data, filters, loadingSummary, timeZone, summary, loadingSubEvents, events } = report;
  const { filterEntity,  startRange, endRange } = filters;
  const [eventFilter, setEventFilter] = useState({});
  const [currentId, setCurrentId] = useState(null);
  const exportHandler = useHandleExportReport(report);
  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();

  const reportRef = useRef();
  useEffect(() =>{
    if(report){
      const newReport = JSON.parse(JSON.stringify(report));
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);


  const idEventsPerDay = (selected,id) => {
    let currentReport = report;
    let startDate = dateTimeZoneToUTC(selected, timeZone)
    const endDate = moment(startDate).tz(timeZone).endOf('day').utc()
    delete currentReport.events
    dispatch(reportsMainGenerateReportProgress(currentReport))
    let events = {
      filters: {
        filterArray: [id],
        subPage: 1,
        subLimit: 10,
        startRange: isSameDates(startDate, currentReport.filters.startRange, timeZone, true) ? currentReport.filters.startRange : startDate,
        endRange: isSameDates(endDate, currentReport.filters.endRange, timeZone, false) ? currentReport.filters.endRange : endDate
      }
    }
    setEventFilter(events)
    dispatch(reportsMainGenerateSubEvents(report, events))
  }

  const isSameDates = (selectDate, startRange, timeZone, isStartDate) => {
    return moment(selectDate).isSame(isStartDate ? moment(startRange).tz(timeZone).startOf('day').utc().format() : moment(startRange).tz(timeZone).endOf('day').utc().format());
  }

  const getStartDateUTC = (startDate, timeZone) => {
    const newStartDate = moment(startDate);
    return newStartDate.tz(timeZone).startOf('day').utc().format();
  }

  const getEndDateUTC = (endDate, timeZone) => {
    return moment(endDate).tz(timeZone).endOf('day').utc().format();
  }

  const renderExpandableRowSubEvents = (selected) => {
    const total = 0
    return (
      <div style={{ marginBottom: 10 }}>
        <Table
          loading={loadingSubEvents}
          data={events?.items || []}
          totalItems={events?.total}
          onChangedPage={(page) => {
            let currentReport = eventFilter;
            let startDate = getStartDateUTC(selected, timeZone)
            const endDate = getEndDateUTC(selected, timeZone)
            delete currentReport.events
            let events = {
              filters: {
                filterType: getCategoryFilterType(filterEntity),
                filterArray: [selected.id],
                page: page,
                rowsPerPage: 10,
                startRange: isSameDates(startDate, currentReport.filters.startRange, timeZone, true) ? currentReport.filters.startRange : startDate,
                endRange: isSameDates(endDate, currentReport.filters.endRange, timeZone, false) ? currentReport.filters.endRange : endDate
              }
            }
            dispatch(reportsMainGenerateSubEvents(report, events))
          }}
          noDataMessage={messages["generatedReport_noEvents"]}
          hideNumberOfRows={true}
          classNameColumns={'report-sub-events-columns'}
          centerPagination
          serverSideTable={true}
        >

          <Column label={messages['reportsMainRT_driverStatus']} >
            {(element) => <span >{element.driverStatus}</span>}
          </Column>
          <Column label={messages['reportsMainRT_inOut']} >
            {(element) => <span >{element.inOut}</span>}
          </Column>
          <Column label={messages['generatedReport_timeAndDate']}>
            {(element) => <span>{formatDatetime(element.dateTime, "MMMM D, YYYY h:mm A", timeZone)}</span>}
          </Column>
          <Column label={messages['generatedReport_address']}>
            {(element) => (element.address && <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={element}/>)}
          </Column>
          <Column label={messages['reportsMainRT_chatToFrom']}>
            {(element) => <span >{element.messageDirection}</span>}
          </Column>
          <Column label={messages['reportsMainRT_message']}>
            {(element) => <span >{element.message}</span>}
          </Column>
          <Column label={messages['reportsMainRT_stopTitle']}>
            {(element) => <span >{element.stopTitle}</span>}
          </Column>
          <Column label={messages['reportsMainRT_stopStatus']}>
            {(element) => <span >{element.stopStatus}</span>}
          </Column>
        </Table>
      </div>

    )
  }

  const onChangeExpandable = (selected) => {
    if(!selected.noData){
      setCurrentId(selected?.id);
      let currentReport = report
      delete currentReport.summary
      dispatch(reportsMainGenerateReportProgress(currentReport))
      let events = {
        filters: {
          filterArray: [selected.id],
          page: 1,
        }
      }
      dispatch(reportsMainGenerateEvents(report, events))
    }

  }
  // START SUMMARY

  let parametersTableSummary = useTableReport({
    data:  [],
    report,
    messages,
    hideHeader: true,
    hiddenPagination: true,
    renderExpandableRow: renderExpandableRowSubEvents,
    showExpandable: true
  });

  const renderExpandableRow = (row) => {
    const summaryParameters = {
      ...parametersTableSummary,
      data : summary?.summaryInfo?.dateList || [],
      onChangeSelector:null,
      placeholderSelector:null,
      hideSelectorUnitsDriver:true,
      classNameColumns:"table-report-main-sub-table",
      idRenderExpandableRow:(event)=> {idEventsPerDay(event,row?.id)}
    }
    return (
      <div style={{ width: '100%' }}>
        <Table
          {...summaryParameters}
          loading={loadingSummary}
          noData={row.noData}
        >
          <Column
            label=""
            field="day"
            noSorting
          >
            {(element) => {
              return <strong>{element}</strong>
            }}
          </Column>
          <Column/>
        </Table>
      </div>
    )
  }
  // END SUMMARY

  let parametersTable = useTableReport({
    data: data.items,
    report,
    messages,
    hideHeader: true,
    renderExpandableRow,
    onChangeExpandable,
    propertyToShowExpandable: 'id',
    classNameColumns: "table-report-main-expandible"
  });

  return (
    <Fragment>
      <Table
        {...parametersTable}
        {...exportHandler}
      >
        <Column noSorting>
          {(element) => {
            const type = getEntityType(report, messages)
            return <span><strong>{type}:  </strong>{`${element.driverName || ''}`}</span>
          }}
        </Column>
        <Column noSorting>
          {() => {
            const localTimeStart = moment.utc(startRange).local().format();
            const localTimeEnd = moment.utc(endRange).local().format();
            return (<div>
                      <span>
                        <span>
                          <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                        </span>
                      </span>
            </div>);
          }}
        </Column>
      </Table>
      <SideMapComponent hiddenButton hiddenSearchMap/>
    </Fragment>
  );
};

export default FSMCombinedReport;
