import { useIntl } from "react-intl";

const MultipleActions = () => {
  const { messages } = useIntl();

  return [
    {
      id: 1,
      label: messages["driving"],
      color: "#5BDC9D",
    },

    {
      id: 2,
      label: messages["idling"],
      color: "#F5E081",
    },

    {
      id: 3,
      label: messages["stopped"],
      color: "#E76060",
    },
    {
      id: 4,
      label: messages["no_video_av"],
      color: "#D1D1D1",
    }
  ];
};

export default MultipleActions;
