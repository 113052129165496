export default {
    settingsTemperature_createTitle:"Agregar alerta de temperatura",
    settingsTemperature_editTitle:"Editar alerta de temperatura de",
    settingsTemperature_columTitle:"Unidad ",
    settingsTemperature_minLengthMessage:'El campo debe contener un mínimo de caracteres: ',
    settingsTemperature_maxLengthMessage:'El campo debe contener un maximo de caracteres: ',
    settingsTemperature_success:'Alerta de temperatura guardada',
    settingsTemperature_deleteWarning:'¿Está seguro de que desea eliminar las alertas de temperatura?',
    settingsTemperature_deleteTitle:'Eliminar alertas de temperatura',
    settingsTemperature_deleted:'Alertas de temperatura eliminadas',
    settingsTemperature_search:'Buscar por Unidad',
    settingsTemperature_unitTitle:'Unidad',
    settingsTemperature_zone:'Zona',
    settingsTemperature_title:'Nombre',
    settingsTemperature_high:'Temperatura máxima (°F)',
    settingsTemperature_low:'Temperatura mínima (°F)',
    settingsTemperature_alert:'Modo de alerta',
    settingsTemperature_contact:'Contactos',
    settingsTemperature_clear:'Limpiar zona',
    settingsTemperature_complete_field_zone:'Por favor complete todos los campos en la zona ',
    settingsTemperature_complete_fields:'Por favor completar todos los campos' ,
    settingsTemperature_validate_high_low:'Estos campos de temperatura máxima y mínima deben estar entre 185°F y -40°F de temperatura',
    settingsTemperature_less_high_low:'El campo temperatura mínima debe ser menor al campo temperatura máxima en la zona',
    settingsTemperature_trigger:'Modo de disparo',
    settingsTemperature_Hour:'Hora',
    settingsTemperature_Inmediate:'Inmediato',
    settingsTemperature_Once:'Una Vez',
    settingsTemperature_Recurring:'Periódica',
    settingsTemperature_Enable:'Activa',
    settingsTemperature_Disable:'Inactiva',
    settingsTemperature_subscribersRequired:'Debe seleccionar al menos un contacto'
};