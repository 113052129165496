import { Icons, Tooltip } from "@gpstrackit/gtc-react-controls";
import { Divider } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import VidFleetVideo from "Modules/.common/components/VidFleetVideo";

import { useIntl } from "react-intl";
import moment from "moment";
import { useSelector } from "react-redux";
import { getVidFleetClipStatus } from "Core/data/VideoEvents";
import { GetFormatForMoment } from "Core/data/Helpers";


const Clip = ({
  id,
  accountId,
  createdOn,
  deviceId,
  esn,
  label,
  status,
  time,
  tripID,
  clipID,
  rotate,
  url,
  startDate,
  endDate,
  notes,
  starred,
  errorMsg,
  deviceModelId,
  deviceModelName,
  driverName,
  thumbnailUrl,
  transactionId,
  isDeleted,
  createdBy,
  unitId,
  timezone,
  uploadType
}) => {
  const { messages } = useIntl();
  const vidFleetVideoRef = useRef();

  const { user } = useSelector((state) => state.securityRedux);
  const { dateformat } = user;

  const clipStatus = getVidFleetClipStatus(status);
  const options = {
    messages: messages, // For to access to language keys
    mode: status === "mp4RemuxFinished" ? "video" : "picture",
    type: "playback", //Depends of the playing type, the url VidFleet API changes
    id: clipID, // This could be a ESN or Clip Id
    autoplay: false, // If is true the video will be played automatically
    deviceModel: deviceModelName, //4k|360
    thumbnailUrl:
      status !== "mp4RemuxFinished" ? clipStatus?.image : thumbnailUrl,
    disabled: status === "mp4RemuxFinished" ? false : true,
  };

  return (
    <div className="incident_event_item">
      <Tooltip
        placement="right-end"
        key="dateInformation"
        title={
          <div className="incident_event_item_information">
            <p style={{ margin: 0, fontSize: "16px", fontWeight: "bold" }}>
              {messages["incident_clip_completed"]}
            </p>
            <div style={{ display: "flex" }}>
              <p style={{ marginRight: "5px" }}>
                {messages["incident_event_title_events_driver"]}
              </p>
              <p>{driverName}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ marginRight: "5px" }}>
                {messages["videoClipRequest_timeFrame"]}
              </p>
              <p>{moment.unix(startDate / 1000).tz(timezone?.name).format("hh:mm:ss a")} - {moment.unix(endDate / 1000).tz(timezone?.name).format("hh:mm:ss a")}</p>
            </div>
          </div>
        }
      >
        <div style={{ display: "flex" , width: '100px' }}>
          <div className="incident_event_item_date" style={{width: '53px'}}>
            <div>
              <p style={{ margin: 0, marginRight: "5px" }}>
                {moment.unix(startDate / 1000).tz(timezone?.name).format("hh:mm")}
              </p>
              <Tooltip title={timezone?.name}>
                {moment.tz(timezone?.name).zoneAbbr()}
              </Tooltip>
            </div>
            <p style={{ margin: 0 }}>{moment.unix(startDate / 1000).tz(timezone?.name).format(`${GetFormatForMoment(dateformat)}`)}</p>
            {uploadType === 'CLIPREQUEST' ?
              <p style={{ margin: 0, textAlign: 'center' }}>{messages['incident_clip_request']}</p> :
              <p style={{ margin: 0, textAlign: 'center' }}>{messages['incident_live_request']}</p>
            }
          </div>
          <Divider
            style={{ height: "150px" }}
            orientation="vertical"
            variant="middle"
            flexItem
          >
            <img
              style={{ width: 30, height: 30 }}
              src={clipStatus?.icon}
              alt={clipStatus?.lanKey}
            />
          </Divider>
        </div>
      </Tooltip>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "black",
          width: "200px",
          height: "150px",
        }}
      >
        <div style={{ width: "200px", height: "150px" }}>
          <VidFleetVideo ref={vidFleetVideoRef} {...options} />
        </div>
      </div>
    </div>
  );
};

export default Clip;
