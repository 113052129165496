import React, { useCallback, useEffect, useState, useRef, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@mui/material';

import { Select, Form, Input, Pill, Button, Chip } from '../../components'//'Components';

import countries from './countries.json';

const ContactsSelector = (props) => {
    const {
        label,
        required,
        contacts,
        loading,
        values,
        entities,
        onChange,
        disabled,
        country: currentCountry,
        onChangeCountry,
        styleMax
    } = props;

    const { messages } = useIntl();

    const contactSelectorRef = useRef();

    const defaultOptions = [
        { name: messages['email'], id: "EMAIL", color: '#66BB6A' },
        { name: messages['phone'], id: "SMS", color: '#66BB6A' },
        { name: messages['contacts'], id: "CONTACT", color: '#66BB6A' }
    ];

    const defaulSelectedOption = defaultOptions?.filter(o => entities?.includes(o.id));
    const [selectedOption, setSelectedOption] = useState(defaulSelectedOption ? defaulSelectedOption[0] : null);

    const [email, setEmail] = useState("");
    const [country, setCountry] = useState({ id: "1", code: "US" });
    const [phone, setPhone] = useState("");
    const [contact, setContact] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);


    useEffect(() => {
        return () => {
            clearForm();
        }
    }, []);

    useEffect(() => {
        if (currentCountry) {
            let ct = countries?.find(c => c?.code == currentCountry);
            setCountry(ct || currentCountry);
        }
    }, [currentCountry]);

    const clearForm = useCallback(() => {
        setEmail("");
        setPhone("");
        setContact(null);
        setErrorMessage("");
    })

    const contactIds = values?.map(v => {
        return v?.id
    });
    const currentContacts = contacts?.filter(c => !contactIds?.includes(c?.id));

    return (
        <div style={{ width: styleMax ? '100%' : undefined }}>
            <Form
                ref={contactSelectorRef}
                onValidSubmit={() => {
                    if (onChange) {
                        let newValues = [...(values || [])];
                        let id = contact?.id || email || phone;
                        let value = contact?.address || email || phone || contact;
                        if (selectedOption?.id === "SMS") {
                            id = `+${country?.id || "1"}${phone}`;
                            value = `+${country?.id || "1"}${phone}`;
                        }

                        let newRecipient = {
                            id: id,
                            value: value,
                            type: selectedOption?.id,
                            contactType: contact?.type || ''
                        };

                        if (!newValues?.find(nv => (nv?.id == newRecipient?.id || nv?.value == newRecipient?.value))) {
                            newValues.push(newRecipient);
                        }
                        onChange(newValues);
                    }

                    clearForm();
                }}
            >
                {
                    label &&
                    <label className="input-normal-label">
                        {label}
                        {required && <span className="text-danger"> *</span>}
                    </label>
                }

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: 10, marginBottom: 0 }}
                >
                    {
                        defaultOptions?.filter(o => entities?.includes(o.id))?.map((item, key) => {
                            const { id } = item;
                            let selected = selectedOption?.id === id ? false : true;
                            let styles = { width: '100%' };
                            if (selected) {
                                styles = {
                                    ...styles,
                                    ...{
                                        backgroundColor: "#F1F1F1",
                                        color: "#484848",
                                        border: "1px solid #F1F1F1"
                                    }
                                };
                            }

                            return (
                                <Grid item xs style={{ padding: 2 }} key={key}>
                                    <Pill
                                        style={styles}
                                        item={item}
                                        outlined={selected}
                                        onClick={(value) => {
                                            if (!disabled) {
                                                clearForm();
                                                setSelectedOption(value);
                                            }
                                        }}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>

                {
                    selectedOption?.id === "EMAIL" &&
                    <Input
                        id="email"
                        type="email"
                        email
                        label={messages['emailAddress']}
                        placeholder={messages['enterEmailAddress']}
                        value={email || ""}
                        onChange={(newValue) => {
                            setEmail(newValue);
                        }}
                        minLength={5}
                        maxLength={100}
                        disabled={disabled}
                        required
                        requiredMessage={messages['required_field']}
                        emailMessage={messages['required_email']}
                        onKeyPress={props?.emailKeyPress}
                    />
                }

                {
                    selectedOption?.id === "SMS" &&
                    <Fragment>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                            style={{
                                marginBottom: 1
                            }}
                        >

                            <Grid item xs={5}>
                                <Select
                                    id="country"
                                    value={country || null}
                                    label={messages['phoneNumber']}
                                    loading={loading}
                                    style={{ width: 110 }}
                                    onChange={(newValue) => {
                                        setCountry(newValue);
                                        if (onChangeCountry) onChangeCountry(newValue);
                                    }}
                                    getOptionLabel={(opt) => `${opt?.code} (+${opt?.id})`}
                                    options={countries}
                                    disabled={disabled}
                                    valueField="id"
                                    textField="code"
                                    required
                                    requiredMessage={messages['required_field']}
                                />
                            </Grid>

                            <Grid item xs={7}>
                                <Input
                                    className='box-container-in-view'
                                    id="sms"
                                    type="number"
                                    customLabel={<br/>}
                                    placeholder={messages['enterPhoneNumber']}
                                    value={phone || ""}
                                    onlyPositive
                                    onChange={(newValue) => {
                                        setPhone(newValue);
                                    }}
                                    minLength={10}
                                    maxLength={10}
                                    disabled={disabled}
                                    required
                                //requiredMessage={messages['required_field']}
                                //minLengthMessage={messages['required_phone']}
                                //maxLengthMessage={messages['required_phone']}
                                />
                            </Grid>
                        </Grid>

                        {
                            errorMessage &&
                            <small className="text-danger" style={{ paddingTop: 5 }}>
                                {errorMessage}
                            </small>
                        }
                    </Fragment>

                }

                {
                    selectedOption?.id === "CONTACT" &&
                    <Select
                        id="contacts"
                        value={contact || null}
                        label={messages['selectContact']}
                        placeholder={messages['typeToSearch']}
                        loading={loading}
                        onChange={(newValue) => {
                            setContact(newValue);
                        }}
                        options={currentContacts}
                        disabled={disabled}
                        valueField="id"
                        textField="name"
                        required
                        requiredMessage={messages['required_field']}
                    />
                }
            </Form>

            <div style={{ marginTop: 8 }}>
                <Button
                    disabled={(!email && !phone && !contact) || disabled}
                    className="btn-blue"
                    style={{ width: '100%' }}
                    onClick={(e) => {
                        e.preventDefault();
                        setErrorMessage("");
                        let error = contactSelectorRef.current.submit();
                        if (selectedOption?.id === "SMS" && !error) {
                            setErrorMessage(messages['required_phone'])
                        }
                    }}
                >
                    {messages['add_recipient']}
                </Button>
            </div>


            <div
                style={{
                    marginTop: 8,
                    maxHeight: styleMax ? '100%' : 130,
                    overflow: 'hidden auto'
                }}
            >
                {values?.map((value, index) => {
                    let label = value?.value;
                    if (value?.type === "CONTACT") {
                        let contact = contacts?.find(c => c?.id == value?.id);
                        if (contact) {
                            label = contact?.address;
                        }
                    }

                    return (
                        <Grid key={index}>
                            <Chip
                                label={label}
                                disabled={false}
                                color={'#66BB6A'}
                                handleDelete={() => {
                                    let newValues = [...(values || [])];
                                    let currentValues = newValues.filter(n => n?.id != value?.id);
                                    if (onChange) onChange(currentValues);
                                }}
                            />
                        </Grid>
                    );
                })}
            </div>
        </div>
    );
}

export default ContactsSelector