import React, { useRef, useCallback, useState, useEffect } from 'react';
// Cloud React Components
import MapSettingsButton from 'Modules/realtimeMaps/main/components/MapSetting/components/mapSettingPreference/MapSettingsButton';
import Preferences from 'Core/containers/Preferences';
// Material UI
import { Typography } from '@mui/material';
// Utils
import { convertIntFromObjectToBoolean, convertStringToIntegerBoolean } from 'Core/data/Helpers';
// Styles
import '../infoBubble.scss';
import '../../../../../components/Map/providers/google/InfoWindow/style.scss'
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

/********************************************************
 * Global var to timer
 ********************************************************/
let timerLastEventPreferences = null
var varLocaleSettings = localStorage.getItem('lan');

/*********************************************************
 * Actions
*********************************************************/
import { updateShowVehicleTrailsSettings } from 'Redux/actions';

const Settings = (props) => {
  const { id, authenticatedId, data, trailsMenu } = props;

  const { messages, locale } = useIntl();

  const keyPreferences = "mapSettings";
  const [settings, setSettings] = useState({})
  const preferencesRef = useRef();

  const dispatch = useDispatch()

  const onOptionChange = (key, checked) => {
    const newSettings = { ...settings, [key]: Boolean(checked) };
    setSettings(newSettings);

    if (timerLastEventPreferences) {
      clearInterval(timerLastEventPreferences)
    }
    timerLastEventPreferences = setInterval(onSavePreferences, 500, newSettings)
  }

  useEffect(()=> {
    if(varLocaleSettings != locale){
      dispatch(updateShowVehicleTrailsSettings(false))
      varLocaleSettings = locale
    }
  },[locale])

  /*********************************************************
    * Only send the preferences to the server before the last event 
    * at the user in UI
  *********************************************************/
  const onSavePreferences = (newSettings) => {
    //restart the timer
    clearInterval(timerLastEventPreferences)
    timerLastEventPreferences = null
    savePreferences(newSettings);
  }

  /*********************************************************
    * When the trail settings is load into the modal view
  *********************************************************/
  useEffect(()=>{
    if(!trailsMenu){
      dispatch(updateShowVehicleTrailsSettings(false))
    }
  },[])

  const loadPreferences = useCallback((data) => {
    let preferences = data;

    //get information the preferences when control is load (first time)
    if(JSON.stringify(settings) != JSON.stringify({})) return;

    if (typeof data == 'string') {
      preferences = JSON.parse(data);
    }
    if (preferences) {
      let newSettings = { ...settings };
      Object.keys(preferences)?.forEach((key) => {
        let value = preferences[key];
        value = convertStringToIntegerBoolean(value);
        if (typeof value !== "boolean" && !isNaN(value) && value < 4) {
          newSettings = { ...newSettings, [key]: Boolean(value) };
        } else {
          if (typeof value === 'string') {
            newSettings = { ...newSettings, [key]: value };
          } else {
            newSettings = { ...newSettings, [key]: isNaN(value) ? false : value };
          }
        }
      });
      //set the Trail line by default in true for the first load.
      if(newSettings['trailConnectingLine'] == undefined){
        newSettings = { ...newSettings, ['trailConnectingLine']: true };
      }
      setSettings(newSettings);
    }else{
      //set the Trail line by default in true for the first load.
      let newSettings = { trailConnectingLine: true }
      setSettings(newSettings);
    }
  });

  const savePreferences = (newSettings) => {
    if (authenticatedId) {
      const preferencesToSave = convertIntFromObjectToBoolean(newSettings);
      if (preferencesToSave) {
        preferencesRef?.current?.savePreference("User", keyPreferences, preferencesToSave);
      }
    }
  }

  return (
    <div className='info-window-tab-streetview'>
      <div style={{ padding: 16 }}>
        <Typography noWrap style={{ fontWeight: 'bold' }}>
          {messages['infoBubble_settings']}
        </Typography>
      </div>

      <div style={{ borderTop: 'solid 1px #e4e4e4' }}></div>

      <div style={{ padding: 16 }}>
        <Preferences
          ref={preferencesRef}
          preferencesKey={keyPreferences}
          onInitialPreferences={loadPreferences}
        >
          <div style={{ padding: '0px 0px 10px 0px' }}>
            <MapSettingsButton
              id={'trailShowLandmark'}
              mediuml={(locale == 'es')}
              checked={settings['trailShowLandmark'] || false}
              label={messages['infoBubble_landmarks']}
              onChange={onOptionChange}
            />
          </div>

          <div style={{ padding: '10px 0px 10px 0px' }}>
            <MapSettingsButton
              id={'trailShowWaypointNumbers'}
              mediuml={(locale == 'es')}
              checked={settings['trailShowWaypointNumbers'] || false}
              label={messages['infoBubble_waypointNumbers']}
              onChange={onOptionChange}
            />
          </div>

          {/*
          <div style={{ padding: '10px 0px 10px 0px' }}>
            <MapSettingsButton
              id={'trailShowOtherVehicles'}
              checked={settings['trailShowOtherVehicles'] || false}
              label={messages['infoBubble_otherVehicles']}
              onChange={onOptionChange}
            />
          </div>
          */}

          <div style={{ padding: '10px 0px 0px 0px' }}>
            <MapSettingsButton
              id={'trailConnectingLine'}
              mediuml={(locale == 'es')}
              checked={settings['trailConnectingLine'] || false}
              label={messages['infoBubble_connectingLine']}
              onChange={onOptionChange}
            />
          </div>
        </Preferences>
      </div>
    </div>
  );
}

export default Settings;