/*********************************************************
 * Imports
 ********************************************************/
import React , { useEffect, useMemo, useState } from 'react';

/*********************************************************
 * Controls
 ********************************************************/
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { Icon, Grid, Box } from '@mui/material';
import { useIntl } from 'react-intl';
/*********************************************************
 * Css
 ********************************************************/
import './style.scss'

const autocompleteService = { current: null };



/*********************************************************
 * Author:  Walter Paz Londoño
 * Date:    2 Feb 2020
 * Module:  Realtime Maps
 * 
 * Class to get diferents directions in find control
 ********************************************************/
const AutoCompleteMap = (props) => {

  const { addressValue, onClearSetAddress, onClearInput } = props
  const { messages } = useIntl();

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    return () => {
      setValue(null);
      setInputValue('')
      setOptions([])
    }
  }, []);

  useEffect(() => {
    if(addressValue && onClearSetAddress){
      setInputValue(addressValue)
      setValue(addressValue);
      onClearSetAddress();
    }
  },[addressValue])
  

  /*********************************************************
   * Use memo to memorize elements
   ********************************************************/
  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  /*********************************************************
   * split array by char space and comma
   * @param {string} word to find lat lng
   *********************************************************/
  const validateLatLng = (param) => {
    let isDirectionLatLng = false;
    let arrayLatLng = splitArrayLatLng(param, ' ');
    if(arrayLatLng){
        isDirectionLatLng = true
    }else{
        arrayLatLng = splitArrayLatLng(param, ',')
        if(arrayLatLng){
            isDirectionLatLng = true
        }
    }
    
    if(isDirectionLatLng){
        props.getDirectionLatLng(arrayLatLng[0], arrayLatLng[1])
    }
  }

  /*********************************************************
   * split string by param and evalate if value is lat and lng 
   * @param {string} valueArray element to split value
   * @param {char} valueSplit value to split
   * @returns {array} array lat lng position
   *********************************************************/
  const splitArrayLatLng = (valueArray, valueSplit = ' ') => {
    let arrayLatLng = valueArray.split(valueSplit)
    if(arrayLatLng && arrayLatLng.length > 0){
        arrayLatLng = arrayLatLng.filter((item) => item != '');
        if(arrayLatLng.length < 2) return null;
        arrayLatLng = arrayLatLng.map((item) => {
            return item.toString().replace(',', '');
        })
        if(arrayLatLng.length == 2){
            if(!isNaN(arrayLatLng[0]) && !isNaN(arrayLatLng[1]))
            {
                return arrayLatLng
            }   
        }
    }
    return null
  }

  /*********************************************************
   * Get the direction using AutocompleteService
   ********************************************************/
  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });
    
    //validate latitude or Longitude positions
    if(props.getDirectionLatLng){
        validateLatLng(inputValue);
    }

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  /*********************************************************
   * Use efect to clear elements
   ********************************************************/
  useEffect(()=>{
    if(props.clear){
      clearAutoComplete()
    }
  },[props.clear])

  const clearAutoComplete = () => {
    setInputValue('')
    setValue(null)
    setOptions([])
    if(props.clearCallback){
        props.clearCallback()
    }
  }

  useEffect(() => {
    if (props.searchValueMap) {
      fetch({ input: props.searchValueMap }, (results) => {
        if (results.length > 0) {
          setValue(results[0])
          if (props.getDirection) {
            props.getDirection(results[0].place_id)
          }
        }
      });
    }
  }, [props.searchValueMap])


  return (
    <Autocomplete
      id="google-map-autocomplete"
      style={props.styleAutoComplete ? props.styleAutoComplete : { }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      onKeyPress={props.onKeyPress || null}
      value={value}
      noOptionsText={props.noOptionsText ? props.noOptionsText : 'No options' }
      disabled={props.disabled}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options.filter((item) => item?.description));
        setValue(newValue);
        if(props.getDirection && newValue?.place_id){
            props.getDirection(newValue.place_id)
        }else{
          !newValue && onClearInput && onClearInput()
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue, reason) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <div id={!props.styleAutoComplete ? 'autocomplete-realtime-maps' : 'autocomplete-realtime-maps-form'} ref={params.InputProps.ref}>
          <div style={{ position: "relative" }}>
            <input 
              type="text" {...params.inputProps} 
              className="input-normal form-control" 
              style={{ paddingLeft: 8, paddingRight: !props?.hideClear ? 35 : 8 }} 
              placeholder={props?.placeholder || "search"} /> 
            {!props?.hideClear && inputValue?.length > 0  && (
              <Icon 
                className="AutoCompleteMap-clear-button"
                onClick={() => {clearAutoComplete()}}>
                cancel
              </Icon>
            )}
          </div>
        </div>
        /* <TextField {...params} label="" variant="outlined" fullWidth /> */
      )}
      renderOption={(props, option, state) => {
        if(!option || !option?.structured_formatting?.main_text_matched_substrings) return (<div></div>)
        const matches = option.structured_formatting.main_text_matched_substrings;
        if(!matches) return (<div></div>)
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <div key={`${option.main_text}_${option.id}`} {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={'AutoCompleteMap-icon'} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </div>
        );
      }}
    />
  );
}


export default AutoCompleteMap