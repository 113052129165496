import {
	FLEET_MODAL_BULK_OUTPUTS_OPEN,
	FLEET_MODAL_BULK_OUTPUTS_SEND_COMMAND,
	FLEET_MODAL_BULK_OUTPUTS_SEND_COMMAND_RESULT,
	FLEET_MODAL_BULK_OUTPUTS_GET_OUTPUTS,
	FLEET_MODAL_BULK_OUTPUTS_GET_OUTPUTS_RESULT
} from 'Redux/actionTypes';

export const fleetModalBulkOutputsOpen = (open, units) => ({
	type: FLEET_MODAL_BULK_OUTPUTS_OPEN,
	payload: { open, units }
});

export const fleetModalBulkOutputsSendCommand = (data) => ({
	type: FLEET_MODAL_BULK_OUTPUTS_SEND_COMMAND,
	payload: { data }
});

export const fleetModalBulkOutputsSendCommandResult = (response) => ({
	type: FLEET_MODAL_BULK_OUTPUTS_SEND_COMMAND_RESULT,
	payload: { response }
});

export const fleetModalBulkOutputsGetOutputs = (data) => ({
	type: FLEET_MODAL_BULK_OUTPUTS_GET_OUTPUTS,
	payload: { data }
})

export const fleetModalBulkOutputsGetOutputsResult = (response) => ({
	type: FLEET_MODAL_BULK_OUTPUTS_GET_OUTPUTS_RESULT,
	payload: { response }
})