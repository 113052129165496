import {
    ALERTS_WIZARD_ALERT_OPEN,
    ALERTS_WIZARD_ALERT_OPEN_RESULT,
    ALERTS_WIZARD_ALERT_CLOSE,
    ALERTS_WIZARD_ALERT_SAVE,
    ALERTS_WIZARD_ALERT_SAVE_RESULT,
    ALERTS_WIZARD_ALERT_TYPES_SUPPORTED,
    ALERTS_WIZARD_ALERT_TYPES_SUPPORTED_RESULT,
    ALERTS_WIZARD_CLEAN_FIELDS
} from 'Redux/actionTypes';

export const alertsWizardOpen = (data) => ({
    type: ALERTS_WIZARD_ALERT_OPEN,
    payload: data,
});

export const alertsWizardOpenResult = (id) => ({
    type: ALERTS_WIZARD_ALERT_OPEN_RESULT,
    payload: { id },
});

export const alertsWizardClose = () => ({
    type: ALERTS_WIZARD_ALERT_CLOSE,
    //payload: { id },
});

export const alertsWizardSave = (data) => ({
    type: ALERTS_WIZARD_ALERT_SAVE,
    payload: data,
});

export const alertsWizardSaveResult = (response) => ({
    type: ALERTS_WIZARD_ALERT_SAVE_RESULT,
    payload: response,
});

export const alertsWizardAlertTypesSupported = () => {
	return {
		type: ALERTS_WIZARD_ALERT_TYPES_SUPPORTED,
		//payload: data
	};
}

export const alertsWizardAlertTypesSupportedResult = (data) => {
	return {
		type: ALERTS_WIZARD_ALERT_TYPES_SUPPORTED_RESULT,
		payload: data
	};
}

export const alertsWizardCleanFields = (data) => ({
    type: ALERTS_WIZARD_CLEAN_FIELDS,
    payload: data,
});
