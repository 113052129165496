//View Keys
export default {
  listDriverVehiclesView_date: 'Date',
  listDriverVehiclesView_day: 'Day',
  listDriverVehiclesView_month: 'Month',
  listDriverVehiclesView_week: 'Week',
  listDriverVehiclesView_weighed: 'Weighed',
  listDriverVehiclesView_vehicle: 'Vehicle',
  listDriverVehiclesView_driver: 'Driver',
  listDriverVehiclesView_rapidAcceleration: 'Rapid Acceleration',
  listDriverVehiclesView_hardBrake: 'Hard Brake',
  listDriverVehiclesView_suddenStop: 'Sudden Stop',
  listDriverVehiclesView_hardTurn: 'Hard Turn',
  listDriverVehiclesView_speed: 'Speed > ',
  listDriverVehiclesView_speed75: 'Speed > 75',
  listDriverVehiclesView_speed80: 'Speed > 80',
  listDriverVehiclesView_speed85: 'Speed > 85',
  listDriverVehiclesView_speed75KPH: 'Speed > 121',
  listDriverVehiclesView_speed80KPH: 'Speed > 129',
  listDriverVehiclesView_speed85KPH: 'Speed > 137',
  listDriverVehiclesView_speed_custom: 'Speed > {customSpeed}',
  listDriverVehiclesView_totalSafetyEvents: 'Total Safety Events',
  listDriverVehiclesView_score: 'Risk Factor',
  listDriverVehiclesView_scores: 'Risk Factors',
  listDriverVehiclesView_aggresion: 'Aggression',
  listDriverVehiclesView_aggresion_total: 'Aggression Total',
  listDriverVehiclesView_vehicleSpeed: 'Vehicle Speed',
  listDriverVehiclesView_vehicleSpeed_total: 'Vehicle Speed Total',
  listDriverVehiclesView_addCustomSpeed: 'Add Custom Speed',
  listDriverVehiclesView_speedingOverPosted: 'Speeding Over Posted',
  listDriverVehiclesView_speedingOverPosted_total: 'Speeding Over Posted Total',
  listDriverVehiclesView_speedingOverPostedLow: '1-5 (Low)',
  listDriverVehiclesView_speedingOverPostedMedium: '6-10 (Medium)',
  listDriverVehiclesView_speedingOverPostedHigh: '11+ (High)',
  listDriverVehiclesView_idling:"Idling",
  listDriverVehiclesView_continous:"Continous",
  listDriverVehiclesView_perDay:"Per Day",
  listDriverVehiclesView_minutes:"Minutes",
  listDriverVehiclesView_hours:"Hours",
  listDriverVehiclesView_customSpeed:"Speed >",
  listDriverVehiclesView_mph:"MPH",
  listDriverVehiclesView_kph:"KPH",
  listDriverVehiclesView_idlingContinous:"30 Min Continous",
  listDriverVehiclesView_idlingPerDay:"30 Min Per Day",
  listDriverVehiclesView_speedingOverPostedOccurrences: 'Speeding Over Posted (Occurrences)',
  listDriverVehiclesView_totalPointAllowancePerDay: 'Total Point Allowance Per Day',
  listDriverVehiclesView_tootipScores: 'Move the green slider below to set how many points per-day a driver or vehicle can accrue to be considered “Low Risk”. Use the yellow slider to set this for “Medium Risk” and all others that exceed the yellow maximum will be considered "High Risk".',
  listDriverVehiclesView_tootipTotalPointAllowance:"This number should be set to the total amount of points that can be accumulated today without negatively impacting the driver's score.",
  listDriverVehiclesView_tootipRapidAcceleration:"Rapid Acceleration triggers when a vehicle is accelerating in excess of a threshold of 5.6 MPH/s for 1.5s- 3.0s.",
  listDriverVehiclesView_tootipHardBraking:"Hard braking is detected based on a vehicle slowing in excess of 4 MPH/s for 1.5s - 3.0s.",
  listDriverVehiclesView_tootipSuddenStop:"Potential Incident is detected when 9 G’s of force are exerted over 1.5s-3.0s.",
  listDriverVehiclesView_tootipHardTurn:"A hard turn is detected when the direction of travel based on the vehicles heading deviates in by 4 MPH/s for 1.5-3.0 seconds.",
  listDriverVehiclesView_tootipVehicleSpeed75:"Triggers if a speed of over 75 MPH is recorded, this is not recorded if exceeding 80 or 85 MPH.",
  listDriverVehiclesView_tootipVehicleSpeed80:"Triggers if a speed of over 80 MPH is recorded, this is not recorded if exceeding 85 MPH.",
  listDriverVehiclesView_tootipVehicleSpeed85:"Triggers if a speed of over 85 MPH is recorded.",
  listDriverVehiclesView_tootipVehicleSpeed75KPH:"Triggers if a speed of over 120 KPH is recorded, this is not recorded if exceeding 128 or 136 KPH.",
  listDriverVehiclesView_tootipVehicleSpeed80KPH:"Triggers if a speed of over 128 KPH is recorded, this is not recorded if exceeding 136 KPH.",
  listDriverVehiclesView_tootipVehicleSpeed85KPH:"Triggers if a speed of over 136 KPH is recorded.",
  behavior_deleteMessage: 'Are you sure you want to delete the Custom Speed',
  behavior_deleteTitle: 'Delete',
  behavior_acceptMessage: 'Accept',
  behavior_cancelMessage: 'Cancel',
  behavior_deleteMessageSuccess: 'Custom Speed deleted successfully',
  behavior_dateToday: 'Today',
  behavior_dateYesterday: 'Yesterday',
  behavior_dateThisWeek: 'This Week',
  behavior_dateLastToday: 'Last Week',
  behavior_dateThisQuarter: 'This Quarter',
  behavior_dateLastQuarter: 'Last Quarter',
  behavior_dateCustom: 'Custom',
  behavior_dateThisMonth: 'This Month',
  behavior_dateLastMonth: 'Last Month',
  behavior_driverSafetyScore: 'Behavior Report', 
  behavior_ScoresWeightsPoints:'Settings',
  behavior_driverTrends:'Risk Factor Indicator',
  behavior_allUnits:'All Units',
  behavior_allDrivers:'All Drivers',
  behavior_settings: 'Settings',
  listDriverVehiclesView_totalScore: 'Total Score',
  behavior_scorecard: 'Behavior Scorecard',
  behavior_risk: 'Risk',
  behavior_errorDateRange: 'The date range cannot be longer than 60 days',
  behavior_errorValues: 'Please select a Unit/Driver or Tag'
};

