import {
    FSM_SETTINGS_QUICK_RESPONSE_SEARCH,
    FSM_SETTINGS_QUICK_RESPONSE_SEARCH_RESPONSE,
    FSM_SETTINGS_QUICK_RESPONSE_CREATE,
    FSM_SETTINGS_QUICK_RESPONSE_CREATE_RESPONSE,
    FSM_SETTINGS_QUICK_RESPONSE_UPDATE,
    FSM_SETTINGS_QUICK_RESPONSE_UPDATE_RESPONSE,
    FSM_SETTINGS_QUICK_RESPONSE_DELETE,
    FSM_SETTINGS_QUICK_RESPONSE_DELETE_RESPONSE,
    FSM_SETTINGS_QUICK_RESPONSE_OPEN_CREATE_EDIT,
    FSM_SETTINGS_QUICK_RESPONSE_OPEN_DELETE
} from "Redux/actionTypes";



export const searchQuickResponse = () => {
    return {
        type: FSM_SETTINGS_QUICK_RESPONSE_SEARCH,
        payload: {},
    };
};

export const searchQuickResponseResponse = (data) => {
    return {
        type: FSM_SETTINGS_QUICK_RESPONSE_SEARCH_RESPONSE,
        payload: data,
    };
};

export const createQuickResponse = (quickResponse) => {
    return {
        type: FSM_SETTINGS_QUICK_RESPONSE_CREATE,
        payload: quickResponse,
    };
};

export const createQuickResponseResponse = (data) => {
    return {
        type: FSM_SETTINGS_QUICK_RESPONSE_CREATE_RESPONSE,
        payload: data,
    };
};

export const updateQuickResponse = (quickResponse) => {
    return {
        type: FSM_SETTINGS_QUICK_RESPONSE_UPDATE,
        payload: quickResponse,
    };
};

export const updateQuickResponseResponse = (data) => {
    return {
        type: FSM_SETTINGS_QUICK_RESPONSE_UPDATE_RESPONSE,
        payload: data,
    };
};

export const deleteQuickResponse = (quickResponse) => {
    return {
        type: FSM_SETTINGS_QUICK_RESPONSE_DELETE,
        payload: { quickResponse },
    };
};


export const deleteQuickResponseResponse = (data) => {
    return {
        type: FSM_SETTINGS_QUICK_RESPONSE_DELETE_RESPONSE,
        payload: data,
    };
};


export const openQuickResponseCreateEdit = (open, quickResponse) => {
    return {
        type: FSM_SETTINGS_QUICK_RESPONSE_OPEN_CREATE_EDIT,
        payload: { open, quickResponse }
    };
};

export const openQuickResponseDelete = (open, quickResponses) => {
    return {
        type: FSM_SETTINGS_QUICK_RESPONSE_OPEN_DELETE,
        payload: { open, quickResponses }
    };
};