import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Styles
import './styles.scss';

const Checkbox = (props) => {
	const {
		id,
		size,
		value,
		onChange,
		label,
		required,
		disabled,
		partiallySelected
	} = props;

	let checked = value;
	if (!checked || checked == undefined)
		checked = false;

	return (
        <div className='gtc-checkbox-container'>
            <label className='gtc-form-control'>
                <input 
                    id={id} 
                    type="checkbox" 
                    className={cx('gtc-checkbox', partiallySelected ? 'gtc-checkbox-partially-selected' : '' , disabled ? 'gtc-checkbox-disabled' : '', `gtc-checkbox-${size || 'md'}`)}
                    checked={value}
                    disabled={disabled}
                    onChange={(event) => {
						if (onChange) onChange(event.target.checked);
					}}
                /> 
                {required && <span className="text-danger"> *</span>}
                {label}
            </label>
        </div>
	);
};

export default Checkbox;

Checkbox.propTypes = {
	id: PropTypes.string.isRequired,
	value: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string,
	infoText: PropTypes.string,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	partiallySelected: PropTypes.bool
};

Checkbox.defaultProps = {
	required: false,
}