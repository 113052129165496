import React, { useEffect, useState } from "react";
import { Button, Modal, TextArea, Tooltip } from "@gpstrackit/gtc-react-controls";
import { useIntl } from "react-intl";
import { endIncidentRedux, refreshIncidentsList } from "Redux/actions";
import { useDispatch } from "react-redux";


export const EndIncident = ({ item, openDelete, setDeleteOpen }) => {
  const { messages } = useIntl();
  const dispatch = useDispatch()
  const [note, setNote] = useState("");


  const endIncidentAction = () => {
    dispatch(endIncidentRedux({
      id: item?.id,
      unitId: item?.unitId,
      name: item?.name,
      start: item?.start,
      end: item?.end,
      note
    }))
    dispatch(refreshIncidentsList({
      id: item?.id
    }))
    setNote('')
    return setDeleteOpen(false)

  }

  let incidentName = item?.name
    if (incidentName?.length > 25) {
      incidentName = (
        <Tooltip title={item?.name}>
          {incidentName.slice(0, 20) + "..."}
        </Tooltip>
      );
    }

  return (
    <Modal
      title={messages["incidents_delete_title"]}
      open={openDelete}
      position="center"
      size="medium"
      handleClose={() => {
        setDeleteOpen(false)
        setNote('')
      }}
    >
      <div className="incident_end_modal_body">
        <p style={{ padding: "10px", margin: 0, display: 'flex' }}>
          {messages["incident_delete_body_1"]} "{incidentName}"{" "}
          {messages["incident_delete_body_2"]}
        </p>
        <div style={{ width: "95%", marginLeft: "10px" }}>
          <p style={{margin: 0}}>{messages["incident_note"]}</p>
          <TextArea
            id="note"
            maxLength={500}
            placeholder={messages["incident_delete_note"]}
            style={{ height: "120px", resize: "none" }}
            value={note}
            onChange={(e) => setNote(e)}
          />
        </div>
      </div>
      <div className="incident_end_modal_footer">
        <Button
          variant="secondary"
          style={{ marginRight: "5PX" }}
          onClick={() => {
            setDeleteOpen(false)
            setNote('')
          }}
        >
          {messages["incidents_cancel"]}
        </Button>
        <Button
          isDisabled={note?.length <= 10 ? true : false}
          variant="primary"
          onClick={() => endIncidentAction()}
        >
          {messages["incidents_delete_title"]}
        </Button>
      </div>
    </Modal>
  );
};
