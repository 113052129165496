import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../core/logManager';

import { resetALL } from '../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    error: null,
    loading: false,
    items: [],
    total: 0,
    dataExport: null
};

const route = 'modules/settings/views/cameraManage/redux/slice'

export const cameraManageSearchUnits = createAsyncThunk('settings/cameraManageSearchUnits', async ({ conditions, sort, limit = 10, offset = 0 }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query searchUnits ($conditions: [UnitSearchInput], $sort: [UnitSortInput], $limit: Int, $offset: Int) {
                data: searchUnits (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                    items {
                        id
                        label
                        esn
                        imei
                        linkedUnitId
                        linkedUnit {
                            id
                            label
                        }
                        timeZone {
                            id
                            name
                        }
                        maintenanceWarningEventsCount
                        sdCard
                        isOffline
                    }
                    total
                }
            }
            `,
            {
                conditions,
                sort,
                limit,
                offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.data || { items: [], total: 0 };
    } catch (exc) {
        ExceptionManager(exc, route, 'cameraManageSearchUnits');
        return rejectWithValue(exc)
    }
});


export const cameraManageExportUnits = createAsyncThunk('settings/cameraManageExportUnits', async ({ conditions, sort, limit = 100, offset = 0 }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query searchUnits ($conditions: [UnitSearchInput], $sort: [UnitSortInput], $limit: Int, $offset: Int) {
                data: searchUnits (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                    items {
                        id
                        label
                        esn
                        imei
                        linkedUnitId
                        linkedUnit {
                            id
                            label
                        }
                    }
                    total
                }
            }
            `,
            {
                conditions,
                sort,
                limit,
                offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.data || { items: [], total: 0 };
    } catch (exc) {
        ExceptionManager(exc, route, 'cameraManageSearchUnits');
        return rejectWithValue(exc)
    }
});

const settingsCameraManageTabRedux = createSlice({
    name: 'settings/cameraManageTab',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : INIT_STATE
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        //maintenanceVDTSearchDrivers
        builder.addCase(cameraManageSearchUnits.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(cameraManageSearchUnits.fulfilled, (state, action) => {
            state.loading = false;
            state.items = action.payload.items
            state.total = action.payload.total
        });
        builder.addCase(cameraManageSearchUnits.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });

        //maintenanceVDTSearchDrivers
        builder.addCase(cameraManageExportUnits.fulfilled, (state, action) => {
            state.dataExport = action.payload
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = settingsCameraManageTabRedux;

// Extract and export each action creator by name
export const { reset } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;