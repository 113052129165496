import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { LogManager, ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
    ACCOUNT_TAGS_SEARCH,
    ACCOUNT_TAGS_SEARCH_PARENTS,
    ACCOUNT_TAGS_SEARCH_CHILDREN,
} from 'Redux/actionTypes';

import {
    searchAccountTagsResponse,
    searchAccountTagsParentsResponse,
    searchAccountTagsChildrenResponse
} from 'Redux/actions';

function* searchAccountTagsRequest({ payload }) {
    try {
        const { data } = payload;
        const response = yield call(
            clientQuery,
            `
            query searchTags ($openSearch: String, $parentId: Int, $limit: Int, $offset: Int) {
                Tags: searchTags (openSearch: $openSearch, parentId: $parentId, limit: $limit, offset: $offset) {
                    items {
                        id
                        label
                        parentId
                        path
                        color
                        unitsCount
                        driversCount
                        landmarksCount
                        usersCount
                        formsCount
                        geofencesCount
                        dynamicFormsCount
                        disabled
                    }
                    total
                }
            }
            `,
            data,
            endpoints.GRAPHQL_GENERAL,
        );
        
        if (!response || !response.Tags) {
            throw 'No tags data';
        }

        const { Tags: { items, total }} = response;
        yield put(searchAccountTagsResponse({ items, total, error: false }));
    } catch (exc) {
        ExceptionManager(exc, 'modules/account/views/tags/redux/sagas', 'searchAccountTagsRequest');
        yield put(searchAccountTagsResponse({ error: true, items: [], total: 0 }));
    }
}

function* searchAccountTagsParentsRequest({ payload }) {
    try {
        const { data } = payload;
        const response = yield call(
            clientQuery,
            `
            query searchTags ($openSearch: String, $parentId: Int, $limit: Int, $offset: Int) {
                Tags: searchTags (openSearch: $openSearch, parentId: $parentId, limit: $limit, offset: $offset) {
                    items {
                        id
                        label
                        parentId
                        color
                        hasChildren
                        unitsCount
                        driversCount
                        landmarksCount
                        usersCount
                        formsCount
                        geofencesCount
                        dynamicFormsCount
                        disabled
                    }
                    total
                }
            }
            `,
            data,
            endpoints.GRAPHQL_GENERAL,
        );
        
        if (!response || !response.Tags) {
            throw 'No tags data';
        }

        const { Tags: { items, total }} = response;
        yield put(searchAccountTagsParentsResponse({ items, total, error: false }));
    } catch (exc) {
        ExceptionManager(exc, 'modules/account/views/tags/redux/sagas', 'searchAccountTagsParentsRequest');
        yield put(searchAccountTagsParentsResponse({ error: true, items: [], total: 0 }));
    }
}

function* searchAccountTagsChildrenRequest({ payload }) {
    const { data } = payload;
    try {
        const response = yield call(
            clientQuery,
            `
            query searchTags ($openSearch: String, $parentId: Int, $limit: Int, $offset: Int) {
                Tags: searchTags (openSearch: $openSearch, parentId: $parentId, limit: $limit, offset: $offset) {
                    items {
                        id
                        label
                        parentId
                        color
                        hasChildren
                        unitsCount
                        driversCount
                        landmarksCount
                        usersCount
                        formsCount
                        geofencesCount
                        dynamicFormsCount
                        disabled
                    }
                    total
                }
            }
            `,
            {
                openSearch: "",
                limit: 0,
                offset: 0,
                parentId: data.parentId
            },
            endpoints.GRAPHQL_GENERAL,
        );
        
        if (!response || !response.Tags) {
            throw 'No tags data';
        }

        const { Tags: { items }} = response;
        yield put(searchAccountTagsChildrenResponse({ items, error: false, parentId: data.parentId }));
    } catch (exc) {
        ExceptionManager(exc, 'modules/account/views/tags/redux/sagas', 'searchAccountTagsChildrenRequest');
        yield put(searchAccountTagsChildrenResponse({ error: true, items: [], parentId: data.parentId }));
    }
}

function* watchAccountTagsMethods() {
    yield takeEvery(ACCOUNT_TAGS_SEARCH, searchAccountTagsRequest);
    yield takeEvery(ACCOUNT_TAGS_SEARCH_PARENTS, searchAccountTagsParentsRequest);
    yield takeEvery(ACCOUNT_TAGS_SEARCH_CHILDREN, searchAccountTagsChildrenRequest);
}

export default function* rootSaga() {
    yield all([fork(watchAccountTagsMethods)]);
}

