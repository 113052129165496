/* eslint-disable */


export function resizableGrid(table,resizeProperties) {

    //console.log("resizable grid",resizeProperties)
    //checking all td
    const tds = table.getElementsByTagName("td");

    //console.log("tds",tds)

    //window.setTimeout(()=>{
      for (let n=0; n<tds.length;n++)
      {
         
          if(tds[n]?.childNodes[0])
          {

            if(tds[n]?.childNodes[0].classList)
            {
              tds[n]?.childNodes[0].classList.add("resize-wrapper")
            }
            
            
            /*const org_html = tds[n].innerHTML
            const new_html = "<div class='resize-wrapper'>" + org_html + "</div>"            
            tds[n].innerHTML = new_html*/
            
            /*const wrapper = document.createElement("div");
            wrapper.classList.add("resize-wrapper")

            for (var i = 0; i < tds[n]?.childNodes.length; i++) {
              wrapper.appendChild(tds[n]?.childNodes[i])
            }*/

            //tds[n].appendChild(wrapper)     
               
          }
          else{
            const wrapper = document.createElement("div");
            if(wrapper)
            {
              //wrapper.classList.add("resize-wrapper")
              //tds[n]?.appendChild(wrapper)
            }            
          }            
         
      }
    //},2000)  

    var row = table.getElementsByTagName('tr')[0],
    cols = row ? row.children : undefined;
    if (!cols) return;
    
    table.style.overflow = 'hidden';
    
    var tableHeight = table.offsetHeight;
    
    for (var i=0;i<cols.length;i++){
      if(cols[i]?.querySelectorAll(".resize-helper").length == 0)
      {
        var div = createDiv(tableHeight);
        cols[i].appendChild(div);
        cols[i].style.position = 'relative';
        setListeners(div);
      }else{
        //console.log("not have rh")
      }      
    }

    setDefaultWidths(resizeProperties)
   
    function setListeners(div){
      var pageX,curCol,nxtCol,curColWidth,nxtColWidth;
    
      div.addEventListener('mousedown', function (e) {
        curCol = e.target.parentElement;
        nxtCol = curCol.nextElementSibling;
        pageX = e.pageX; 
      
        var padding = paddingDiff(curCol);
      
        curColWidth = curCol.offsetWidth - padding;

        if (nxtCol){
          nxtColWidth = nxtCol.offsetWidth - padding;
        }
       
      });
    
      div.addEventListener('mouseover', function (e) {
        e.target.style.borderRight = '2px solid #0000ff';
      })
    
      div.addEventListener('mouseout', function (e) {
        e.target.style.borderRight = '';
      })
    
      document.addEventListener('mousemove', function (e) {
        
        //console.log("mouse move")

        if (curCol) {

        var diffX = e.pageX - pageX;

        //console.log("mouse move on cursor",diffX,curCol)

        for (let n=0; n<row?.children.length;n++)
        {
           //console.log("row",row?.children[n])
           if(row?.children[n] === curCol)
           {
             //console.log("(curColWidth + diffX)",(curColWidth + diffX))
             changeAlWrappers(n,(curColWidth + diffX))
           }
           
        }

      
        if (nxtCol)
          nxtCol.style.width = (nxtColWidth - (diffX))+'px';
    
          curCol.style.width = (curColWidth + diffX)+'px';

        }
      });
    
      document.addEventListener('mouseup', function (e) { 
        curCol = undefined;
        nxtCol = undefined;
        pageX = undefined;
        nxtColWidth = undefined;
        curColWidth = undefined
      });
    }
    
    function createDiv(height){
      var div = document.createElement('div');
      div.style.top = 0;
      div.style.right = 0;
      div.style.width = '5px';
      div.style.position = 'absolute';
      div.style.cursor = 'col-resize';
      div.style.userSelect = 'none';
      div.style.height = height + 'px';
      div.classList.add("resize-helper")
      return div;
    }
    
    function paddingDiff(col){

      if (getStyleVal(col,'box-sizing') == 'border-box'){
        return 0;
      }
      
      var padLeft = getStyleVal(col,'padding-left');
      var padRight = getStyleVal(col,'padding-right');
      return (parseInt(padLeft) + parseInt(padRight));
   
    }
   
    function getStyleVal(elm,css){
      return (window.getComputedStyle(elm, null).getPropertyValue(css))
    }

    function changeAlWrappers(index,widthToChange){

      //console.log("widthToChange",widthToChange,index)      

      const trs = table.getElementsByTagName('tr')

      //console.log("trs[0]?.children[index]",trs[0]?.children[index].textContent)

      const Column = trs[0]?.children[index].textContent

      const minimalWidth = Column.length * 10

      let finalWidth = 0

      if( widthToChange >  minimalWidth )
      {
        finalWidth = widthToChange 
      }
      else{
        finalWidth = minimalWidth 
      }

      const event = new CustomEvent('resize-columns',{ 'detail' : { finalWidth , Column }});

      window.dispatchEvent(event);      

      for (let n=0; n<trs.length;n++)
      {
         if(trs[n]?.children[index])
         {
            //console.log("minimalWidth",minimalWidth,widthToChange)

            const classElement = trs[n]?.children[index].querySelector(".resize-wrapper")

            if(classElement){

                classElement.style.width = finalWidth + "px"
             
            }            
            //console.log("classElement",classElement)

         }         
         
      }

    }

    function setDefaultWidths(defaultWidths){

      //console.log("defaultWidths",defaultWidths)

      //if(!defaultWidths || window.firstRender){ return }

      const trs = table.getElementsByTagName('tr')
      const headers = trs[0]?.children
      for (let n=0; n<headers.length;n++)
      {
        //console.log("header:",headers[n].textContent)

        if( defaultWidths[headers[n].textContent] )
        {
          for (let j=0; j<trs.length;j++)
          {
            if(trs[j]?.children[n])
            {    
              const definedWidth = defaultWidths[headers[n].textContent]

                /*const classElement = trs[j]?.children[n].querySelector(".resize-wrapper")

                console.log("classElement",classElement,trs[j]?.children[n].children)

               
               
                if(classElement){
                  //trs[j]?.children[n].style.width = definedWidth + "px"
                  if(classElement?.parentElement)
                  {
                    const parentClass = classElement?.parentElement
                    //console.log("parentClass",parentClass)
                  }
                  
                  classElement.style.width = definedWidth + "px"
                }            
                //console.log("classElement",classElement)*/

                //console.log("just childs",trs[j]?.children[n],trs[j]?.children[n].childNodes[0])
                

                /*if( trs[j]?.children[n].childNodes.length === 0 )
                {
                  window.setTimeout(()=>{
                    if(trs[j]?.children[n].childNodes.length === 1 )
                    {
                      console.log("ahora si perra", trs[j]?.children[n].children[0])
                      const divResult =  trs[j]?.children[n].getElementsByTagName('div')
                      console.log("divResult",divResult)
                      divResult[0].style.width = definedWidth + "px"
                    }
                  },500)
                }
                else{
                 
                }*/

                if(trs[j]?.children[n].children[0])
                {
                  const divResult =  trs[j]?.children[n].getElementsByTagName('div')
                  if(divResult[0])
                  {
                    divResult[0].style.width = definedWidth + "px"

                  }
                }

            }         
            
          }
        }

      }

      window.firstRender = true

    }


};