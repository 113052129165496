export default {
    manageDriverTasksScheduled_tile: "Gestionar tareas para",
    manageDriverTasksScheduled_completed: "Completado",
    manageDriverTasksScheduled_pastDue: "Atrasado",
    manageDriverTasksScheduled_dueStatus: "Vencido",
    manageDriverTasksScheduled_dueSoon: "Prox a Vencer",
    manageDriverTasksScheduled_scheduled: "Programado",
    manageDriverTasksScheduled_done: "Terminado",
    manageDriverTasksScheduled_noTasks: "Sin tareas",
    manageDriverTasksScheduled_addNew: "Agregar nuevo",
    manageDriverTasksScheduled_toComplete: "Completar",
    manageDriverTasksScheduled_completed: "Completado",
}