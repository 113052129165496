import React from "react";
import { Grid, Icon, Breadcrumbs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import './breadcrumb.scss'

const Breadcrumb = (props) => {
  // eslint-disable-next-line no-unused-vars
  const preventDefault = (event) => event.preventDefault();
  const navigate = useNavigate()

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {(props.links && props.links.length) && props.links.map((link, key) => {
        let nav;

        if (key >= (props.links.length - 1)) {
          nav = <span className="nav-title" key={key}>{link.title}</span>
        } else {
          nav = <Grid
            key={key}
            container
            spacing={0}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => {
              if (navigate) {
                navigate(link.link);
              }
            }}
          >
            {
              key === 0
              &&
              <Icon className="nav-arrow-back cursor-pointer">arrow_back</Icon>
            }

            <Grid item xs>
              <span className="nav-link cursor-pointer">{link.title}</span>
            </Grid>
          </Grid>
        }

        return nav;
      })}
    </Breadcrumbs>
  );
}

export default Breadcrumb;