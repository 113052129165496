import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../../../core/logManager';

import { resetALL } from '../../../../../../../.globals/redux/actions';

import moment from "moment";

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: { total: 0, items: [] },
    error: null,
    openAdvancedFilters: false,
    filters: {
        openSearch: "",
        sortBy: "name",
        tagIds: [],
        dateRange: {
            start: moment().subtract(7, 'days').startOf('day').toDate(),
            end: moment.max(moment()).endOf('day').toDate()
        },
        limit: 10,
        offset: 0,
    }
};

const route = 'modules/dynamicForms/views/main/redux/slice'

//Extend async Action
export const searchDynamicForms = createAsyncThunk('dynamicForms/main/searchDynamicForms', async ({ limit, offset, openSearch, status, tagIds, sortBy, dateRange }, { rejectWithValue, dispatch }) => {
    try {
        let conditions = [];
        let sort = [];
        let newStatus = "";
        if (status === 1) {
            newStatus = "active";
        } else if (status === 0) {
            newStatus = "inactive";
        }

        if (newStatus) {
            conditions.push({
                fields: ["formStatus"],
                terms: [newStatus],
                exact: true
            });
        }

        let condition = {};
        if (openSearch) {
            condition = {
                fields: ["name", "description"],
                terms: [openSearch],
                exact: false
            }
        }

        // if (dateRange?.start && dateRange?.end) {
        //     condition['range'] = {
        //         fields: ["createdOn"],
        //         GTE: dateRange?.start,
        //         LTE: dateRange?.end
        //     }
        // }

        if (condition?.fields || condition?.range) {
            conditions.push(condition);
        }

        if (tagIds?.length) {
            conditions.push({
                fields: ["tagIds"],
                terms: tagIds,
                exact: true
            });
        }

        // if (sortBy) {
            sort.push({
                field: "name",
                order: "ASC"
            })
        // }


        const response = await clientQuery(
            `
            query searchDynamicForms ($conditions: [DynamicFormSearchInput], $sort: [DynamicFormSortInput], $limit: Int, $offset: Int) {
                dynamicForms: searchDynamicForms (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                    items {
                        id
                        companyId
                        name
                        formStatus
                        tagIds
                        pages {
                            id
                            title
                            fields {
                                id 
                                type
                                label
                                readOnly
                                required
                                placeHolder
                                help
                            }
                        }
                        properties {
                            allowDraft
                        }
                        description
                        updatedOn
                        updatedBy
                        createdBy
                        createdOn
                    }
                    total
                }
            }
            `,
            {
                conditions,
                sort,
                limit, 
                offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response?.dynamicForms) {
            throw 'Error while getting dynamic forms';
        }

        const { items, total } = response?.dynamicForms;
        return { items: items, total: total || 0 };
    } catch (exc) {
        ExceptionManager(exc, route, 'searchDynamicForms');
        return rejectWithValue(exc)
    }
});

//Slice
const dynamicFormsRedux = createSlice({
    name: 'dynamicForms/list',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openAdvancedFilters(state) {
            state.openAdvancedFilters = true;
        },
        closeAdvancedFilters(state) {
            state.openAdvancedFilters = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(searchDynamicForms.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(searchDynamicForms.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.filters = action?.meta?.arg;
        })
        builder.addCase(searchDynamicForms.rejected, (state, action) => {
            state.loading = false;
            state.data = { total: 0, items: [] };
            state.error = action.payload;
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = dynamicFormsRedux;

// Extract and export each action creator by name
export const { reset, openAdvancedFilters, closeAdvancedFilters } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;