export default {
    accountTags_label: "Etiqueta",
    accountTags_units: "Unidades",
    accountTags_drivers: "Conductores",
    accountTags_landmarks: "Landmarks",
    accountTags_users: "Usuarios",
    accountTags_forms: "Formularios",
    accountTags_dynamicForms: "Formularios Dinámicos",
    accountTags_path: "Recorrido",
    accountTags_geofences: "Geofences",
};