import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { clientQuery, clientMutation } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../core/logManager';

import { resetALL } from '../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    event: null,
    response: null,
    trip: null,
    trails: [],
    total: 0,
    filters: {
        openSearch: "",
        startDate: null,
        endDate: null,
        limit: 50,
        offset: 0,
        reset: false
    },
    error: null
};

const route = 'modules/.common/components/modals/VideoAssignDriver/redux/slice';

export const getTripInfoFromEvent = createAsyncThunk('videoAssignDriver/getTripInfoFromEvent', async ({ unitId, unitTime }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query getTripInfoFromEvent ($unitId: String!, $unitTime: AWSDateTime!) {
                trip: getTripInfoFromEvent (unitId: $unitId, unitTime: $unitTime) {
                    unit {
                        id
                        label
                        timeZone {
                            id
                            name
                        }
                    }
                    tripStart {
                        id
                        deviceId
                        eventCode
                        eventName
                        driverId
                        driverName
                        unitTime
                        unitId
                    }
                    tripEnd {
                        id
                        deviceId
                        eventCode
                        eventName
                        driverId
                        driverName
                        unitTime
                        unitId
                    }
                }
            }
            `,
            {
                unitId: unitId,
                unitTime: unitTime
            },
            endpoints.GRAPHQL_GENERAL_V2
        );


        if (!response?.trip) {
            throw `Error while getting trip info from event ${id}, ${deviceId}`;
        }

        return response?.trip;
    } catch (exc) {
        ExceptionManager(exc, route, 'getTripInfoFromEvent');
        return rejectWithValue(exc)
    }
});

export const searchVehicleTrailsForAssignDriver = createAsyncThunk('videoAssignDriver/searchVehicleTrailsForAssignDriver', async (payload, { rejectWithValue, dispatch }) => {
    const { deviceId, event, startDate, endDate, openSearch, limit, offset, useEcuSpeed, unitType, kph, withRecentEvent } = payload;

    try {
        const response = await clientQuery(
            `query searchVehicleTrails (
                $startDate: AWSDateTime!, 
                $endDate: AWSDateTime!, 
                $event: VehicleTrailEvent, 
                $deviceId: String!, 
                $openSearch: String, 
                $limit: Int, 
                $offset: Int, 
                $includeLinkedData: Boolean, 
                $withRecentEvent: Boolean
            ) {
                trails: searchVehicleTrails (
                    startDate: $startDate, 
                    endDate: $endDate, 
                    event: $event, 
                    deviceId: $deviceId, 
                    openSearch: $openSearch, 
                    limit: $limit, 
                    offset: $offset, 
                    includeLinkedData: 
                    $includeLinkedData, 
                    withRecentEvent: $withRecentEvent
                ) {
                    items {
                        id
                        deviceId
                        eventCode
                        eventName
                        address
                        formattedAddress
                        driverId
                        driverName
                        unitTime
                        speed
                        speedLimit
                        unitType
                        landmark {
                            name
                            color
                        }
                    }
                    total
                }
            }`,
            {
                deviceId, 
                event, 
                startDate, 
                endDate, 
                openSearch, 
                limit, 
                offset, 
                useEcuSpeed, 
                unitType, 
                kph, 
                withRecentEvent,
                includeLinkedData: true
            },
            endpoints.GRAPHQL_GENERAL,
        );

        if (!response?.trails) {
            throw 'Error while getting vehicle trails';
        }

        return response?.trails;
    } catch (exc) {
        ExceptionManager(exc, route, 'searchVehicleTrailsForAssignDriver');
        return rejectWithValue(exc);
    }
});

export const assignDriverToEvents = createAsyncThunk('videoAssignDriver/assignDriverToEvents', async ({ unitId, driverId, tripStartTime, tripEndTime }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientMutation(
            `
                mutation updateDeviceReadingAssignDriverToTrip ($unitId: String!, $driverId: String!, $tripStartTime: AWSDateTime!, $tripEndTime: AWSDateTime) {
                    reading: updateDeviceReadingAssignDriverToTrip (unitId: $unitId, driverId: $driverId, tripStartTime: $tripStartTime, tripEndTime: $tripEndTime)
                }
            `,
            {
                unitId,
                driverId,
                tripStartTime: tripStartTime,
                tripEndTime: tripEndTime || null
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response?.reading) {
            throw 'Error while assign driver to event';
        }

        return response?.reading;
    } catch (exc) {
        ExceptionManager(exc, route, 'assignDriverToEvents');
        return rejectWithValue(exc);
    }
});

//Slice
const videoAssignDriverRedux = createSlice({
    name: '.common/components/videoAssignDriverRedux',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openVideoAssignDriverModal(state, action) {
            state.open = true;
            state.event = action?.payload;
        },
        closeVideoAssignDriverModal(state) {
            state.open = false;
            state.event = null;
            state.response = null;
            state.trip = null;
            state.trails = [];
            state.total = 0;
            state.loading = false;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)
        //Get the ignition on and off relate to the event
        builder.addCase(getTripInfoFromEvent.pending, (state, action) => {
            state.loading = true;
            state.error = null;
            state.trip = null;
        });
        builder.addCase(getTripInfoFromEvent.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.trip = action.payload;
        });
        builder.addCase(getTripInfoFromEvent.rejected, (state, action) => {
            state.loading = false;
            state.trip = null;
            state.error = action.payload;
        });

        //Get vehicle trails for to show a resume of all events that will be assigned to the driver
        builder.addCase(searchVehicleTrailsForAssignDriver.pending, (state, action) => {
            state.loading = true;
            state.error = null;
            state.filters = action?.meta?.arg || state?.filters;
        });
        builder.addCase(searchVehicleTrailsForAssignDriver.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.total = action.payload.total;
            state.trails = action?.meta?.arg?.restart ? action.payload.items : [...state.trails, ...action.payload.items];
        });
        builder.addCase(searchVehicleTrailsForAssignDriver.rejected, (state, action) => {
            state.loading = false;
            state.trails = [];
            state.total = 0;
            state.error = action.payload;
        });

        //Assign the event to the driver
        builder.addCase(assignDriverToEvents.pending, (state, action) => {
            state.loading = true;
            state.error = null;
            state.response = null;
        });
        builder.addCase(assignDriverToEvents.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.response = action.payload;
        });
        builder.addCase(assignDriverToEvents.rejected, (state, action) => {
            state.loading = false;
            state.response = null;
            state.error = action.payload;
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = videoAssignDriverRedux;

// Extract and export each action creator by name
export const {
    reset,
    openVideoAssignDriverModal,
    closeVideoAssignDriverModal
} = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;