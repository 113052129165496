// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    MAP_GEOFENCES_DELETE_GEOFENCE,
} from "Redux/actionTypes";

// Actions
import {
    mapGeofencesDeleteGeofencesResponse,
} from "Redux/actions";

// Helpers
import { clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* deleteGeofencesRequest({ payload }) {
    try {
        const { geofences, deleteAll, unselectedRows } = payload;

        const ids = geofences && geofences.length > 0 ? geofences.map((item) => item.id) : null
        const unselectedRowsIds = unselectedRows && unselectedRows.length > 0 ? unselectedRows.map((item) => item.id) : null

        const mutation = `mutation deleteGeofenceBulk($ids: [Int], $deleteAll: Boolean, $unselectedRowsIds: [Int]) {
            Geofence: deleteGeofenceBulk(ids: $ids, deleteAll: $deleteAll, unselectedRowsIds: $unselectedRowsIds)
        }`;
        
        
        let variables = {
            ids: ids,
            deleteAll: deleteAll ? true : false, 
            unselectedRowsIds: unselectedRowsIds
        };

        if(!variables?.ids) delete variables['ids']
        if(!variables?.unselectedRowsIds) delete variables['unselectedRowsIds']
        
        const response = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL_V2);

        if (!response || !response.Geofence) throw `Error while deleting Geofences, ${geofences}`;

        yield put(mapGeofencesDeleteGeofencesResponse({ ...response, error: false }));
    } catch (exc) {
        yield put(mapGeofencesDeleteGeofencesResponse({ error: true }));
        ExceptionManager(
            exc,
            "realtimeMaps/main/components/geofences/components/geofenceDelete/redux/saga.js",
            "deleteGeofencesRequest"
        );
    }
}

function* watchDeleteGeofenceMethods() {
    yield takeEvery(MAP_GEOFENCES_DELETE_GEOFENCE, deleteGeofencesRequest);
}

export default function* rootSaga() {
    yield all([fork(watchDeleteGeofenceMethods)]);
}