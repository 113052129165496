import React from 'react';

//controls
import {
    Grid,
} from '@mui/material';

const Toolbar = (props) => {
    return (
        <Grid
            container
            item
            direction="row"
            xs={12}
            sm={props.sizeLeftGrid || 6}
            className={`mb-10`}
            style={{ zIndex: 2 }}
        >
            {
                //TOOLBAR
                props.children
            }
        </Grid>
    )
}

export default Toolbar