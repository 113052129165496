import React, { useCallback, useState, useRef } from "react";
import { Icon, IconButton } from "@mui/material";
import AreaMenu, { Area, Menu } from "Components/AreaMenu";
import { useIntl } from "react-intl";

//Components
import ChangePassword from "Modules/account/.components/changePassword";
import AccountInformation from "Modules/.common/components/AccountInformation";
import { ModalType } from "./ModalType";
import { DefaultMenu } from "./DefaultMenu";
import { Tooltip } from "../../../../components"

//Actions


//pending refactor
const AccountButton = ({
  logout,
  current_reseller,
  type,
  user,
}) => {
  const { messages } = useIntl();
  const [showModal, setShowModal] = useState(null);
  const [noCloseModal, setNoCloseModal] = useState(false)
  const areaMenuRef = useRef()
  const handleOptionClick = useCallback((type) => { 
    if(type === ModalType.PWD_CHANGE){
      setNoCloseModal(true)
    }
    setShowModal(type)
   }, [])
  return (
    <div className="header-action">
      <AreaMenu id="areaUser" noHideOnBlur={true} ref={areaMenuRef} noCloseAway={true}>
        <Area>
        <Tooltip 
            title={messages["header_personIcon"]}
            >
            <IconButton
              aria-label="placeholder"
              style={{ fontSize: 25, color: "#1D2734" }}
              onClick={() => setShowModal(null)}
              size="large">
              <Icon>person</Icon>
            </IconButton>
          </Tooltip>
        </Area>
        {renderOptionsMenuUser({showModal,setShowModal, handleOptionClick, user, current_reseller, logout, type, areaMenuRef})}
      </AreaMenu>
    </div>
  );
};

export default AccountButton;

const renderOptionsMenuUser = ({showModal,setShowModal, areaMenuRef, ...rest}) => {
  switch (showModal) {
    case ModalType.PREFERENCES:
      return <Menu className="menu-option"><AccountInformation setShowModal={setShowModal}/></Menu>
    case ModalType.PWD_CHANGE:
      return <Menu className="menu-pwd">
      <ChangePassword closeChangePwd={() => areaMenuRef?.current?.toggle(false)}/>
    </Menu>;
    default:
      return <Menu className="menu-user"><DefaultMenu {...rest}/></Menu>
  }
};
