import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation } from '../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../../../core/logManager';
import { resetALL } from '../../../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    data: null,
    result: null
};

const route = 'Warnings/.components/ConfirmResolve/redux/slice';

export const resolveMaintenanceWarningEvents = createAsyncThunk("Warnings/.components/ConfirmResolve/resolveMaintenanceWarningEvents", async (payload, { rejectWithValue, dispatch }) => {
    const { unitId, warnings } = payload
    try {
        let response = await clientMutation(`
            mutation resolveMaintenanceWarningEvents($unitId: Int!, $warnings: [String], $dateRange: AWSDateTimeRange) {
                result: resolveMaintenanceWarningEvents(unitId: $unitId, warnings: $warnings, dateRange: $dateRange)
            }`,
            {
                unitId: unitId,
                warnings: warnings
            },
            endpoints.GRAPHQL_GENERAL_V2
        )
        return { resolve: response?.result }

    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'resolveMaintenanceWarningEvents');
        return rejectWithValue(exc);
    }
}
)

//Slice
const warningsConfirmResolveRedux = createSlice({
    name: 'warnings/confirmResolve',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : INIT_STATE
        },
        openWarningConfirmResolveModal(state, action) {
            state.open = true
            state.data = action.payload
        },
        closeWarningConfirmResolveModal() {
            return INIT_STATE
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        //resolveMaintenanceWarningEvents
        builder.addCase(resolveMaintenanceWarningEvents.pending, (state, action) => {
            state.open = true
            state.loading = true
        });
        builder.addCase(resolveMaintenanceWarningEvents.fulfilled, (state, action) => {
            state.result = action.payload
            state.loading = false
        });
        builder.addCase(resolveMaintenanceWarningEvents.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = warningsConfirmResolveRedux;

// Extract and export each action creator by name
export const { reset, closeWarningConfirmResolveModal, openWarningConfirmResolveModal } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;