//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
	MODAL_PING_OPEN,
} from 'Redux/actionTypes';

import {
	modalPingOpenResult,
} from 'Redux/actions';

export function* requestPingToUnits() {
	yield takeEvery(MODAL_PING_OPEN, function* ({ payload }) {
		const { ids } = payload;
		try {

			let result = yield call(
				clientMutation,
				` mutation sendPingBySMS ($unitIds:[Int]!){
					pingResult: sendPingBySMS (unitIds:$unitIds) {
						unitId
						outId
						errorMessage
					}
				}`,
				{
					unitIds: ids
				},
				endpoints.GRAPHQL_GENERAL,
			);

			yield put(modalPingOpenResult(result.pingResult));
		} catch (exc) {
			ExceptionManager(
				exc,
				'modules/fleet/views/redux/saga',
				'makePingToUnities',
			);
			yield put(modalPingOpenResult(null));
		}
	});
}

//Saga Export
export default function* rootSaga() {
	yield all([
		fork(requestPingToUnits)
	]);
}