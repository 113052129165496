//View Keys
export default {
    "mainView_vehicles": "Vehicles",
    "mainView_services": "Services",
    "mainView_drivers": "Drivers",
    "mainView_export_button": "Export",
    "unit_not_exist": "This unit can not be edited",
    "unit_edit_title": "Edit",
    "unitService_date_validation_message": "Please enter a valid 4-digit year,from 1950 to",
    "unitService_engine_liters_validation_message": "you can add up to 10000 engine liters",
    "vehicle_tab_export": "Vehicle",

    "next_service_due_tab_export": "Next Service Due",
    "days_tab_export": "Days",
    "engine_tab_export": "Engine Run Time",
    "odometer_tab_export": "Odometer",
    "service_due_tab_export": "Service Due",

    "schedule_task_regarding_title": "Regarding",
    "schedule_task_task_title": "Task",
    "schedule_task_due_title": "Due",
    "schedule_task_cost_title": "Cost",
    "schedule_task_status_title": "Status",
    "schedule_task_notes_title": "Notes",
    "schedule_task_performed_at_title": "Performed At",
    "schedule_task_performed_attachments_title": "Attachments",
    "schedule_task_subscribers_title": "Subscribers",

    "scheduleDriverTask_service": "Service",
    "scheduleDriverTask_miles": "Miles",
    "scheduleDriverTask_days": "Days",
    "scheduleDriverTask_hours": "Hours",
    "scheduleDriverTask_cost": "Cost",
    "scheduleDriverTask_reminders": "Reminders",
    "scheduleDriverTask_notes": "Notes",
    "scheduleDriverTask_errorServiceCompleted": "cannot be deleted because it has already been completed",

    "deleteSubscriber_success": "Alert subscriber removed",


    "deleteAttachment_success": "Attachment removed",

    "maintenance_lastServicePerformed": "Last Service Performed",
    "maintenance_nextService": "Next Service",

    "scheduleDriverTask_manage_service_items": "Manage Service Items",

    "unitService_lastUpdated": "Last update",

    "service_title_add_button": "Add",

    "vehicleView_tags": "Tags"
};