import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../../../core/logManager';

//STATE INITIAL
const INIT_STATE = {
    loadingPerformedServices: false,
    performedServices: []
};

const route = 'maintenance/views/main/components/vehiclesTab/components/vehicleDetail/components/performedServices/redux/slice'

export const maintenanceVVDSearchMaintenanceServiceUnitsLogs = createAsyncThunk('maintenance/servicesTab/maintenanceVVDSearchMaintenanceServiceUnitsLogs', async ({ serviceId, unitId }, { rejectWithValue, dispatch }) => {
    try {
        let query = `
            query ($serviceId: Int, $unitId: Int){
                data: searchMaintenanceServiceUnitLogs(serviceId: $serviceId, unitId: $unitId) {
                    id
                    serviceUnitId
                    serviceUnit {
                        id
                        serviceId
                        lastReadingId
                        lastMaintenanceDate
                        lastMaintenanceOdometer
                        lastMaintenanceEngineHours
                        status
                        service {
                            id
                            title
                            miles
                            days
                            engineHours
                            estimatedCost
                        }
                    }
                    maintenanceDate
                    maintenanceItems {
                        id
                        serviceItemName
                        laborCost
                        partsCost
                    }
                    actualCost
                    notes
                    engineHours
                    odometer
                    startDate
                    startOdometer
                    startEngineHours
                    dueDate
                    dueOdometer
                    dueEngineHours
                    fileIds
                    files {
                        id
                        originalName
                        fileKey
                        repositoryCode
                    }
                }
            }
        `
        const response = await clientQuery(
            query,
            {
                serviceId: serviceId,
				unitId: unitId
            },
            endpoints.GRAPHQL_GENERAL
        );
        return response?.data || [];
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceVVDSearchMaintenanceServiceUnitsLogs');
        return rejectWithValue(exc)
    }
});


const maintenancePerformedServicesRedux = createSlice({
    name: 'maintenance/servicesTab',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : INIT_STATE
        },
    },

    extraReducers: (builder) => {
        //builder.addCase(resetALL, () => INIT_STATE);

        //maintenanceVVDSearchMaintenanceServiceUnitsLogs
        builder.addCase(maintenanceVVDSearchMaintenanceServiceUnitsLogs.pending, (state, action) => {
            state.loadingPerformedServices = true
        });
        builder.addCase(maintenanceVVDSearchMaintenanceServiceUnitsLogs.fulfilled, (state, action) => {
            state.loadingPerformedServices = false;
            state.performedServices = action.payload
        });
        builder.addCase(maintenanceVVDSearchMaintenanceServiceUnitsLogs.rejected, (state, action) => {
            state.loadingPerformedServices = false;
            state.error = action.payload
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = maintenancePerformedServicesRedux;

// Extract and export each action creator by name
export const { reset } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;