import React from 'react';
// Material UI Components
import { Grid } from '@mui/material';
// Cloud React Components
import { Checkbox } from 'Components';
// Styles
import './card.scss';



const CardView = (props) => {
  const { rows, renderRow, onSelectRow, selectedRows, showCheck = true } = props;
  const isSelected = (row) => selectedRows.indexOf(row) !== -1;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={4}
    >
      {
        rows.map((row, index) => {
          const isItemSelected = isSelected(row);
          return (
            <Grid
              key={index}
              item
              xs={10}
              sm={10}
              md={6}
              lg={4}
              xl={3}
            >
              <div className="table-view-card-container">
                {renderRow(row)}
                <div
                  // className={`card-selector-container ${isItemSelected ? 'card-selected' : ''}`}
                  className={`card-selector-container card-selected`}
                >
                  {showCheck &&
                    <Checkbox
                      value={isItemSelected}
                      id={row?.id + ""}
                      onChange={() => {
                        onSelectRow(row);
                      }}
                    />
                  }
                  
                </div>
              </div>
            </Grid>
          )
        })
      }
    </Grid>
  );
};

export default CardView;