//Merge Views
import main from 'Modules/realtimeMaps/main/lan/es';
import mainView from 'Modules/realtimeMaps/main/components/MapUtilitiesMenu/lan/es';
import vehicleList from 'Modules/realtimeMaps/main/components/VehicleList/lan/es';
import routes from 'Modules/realtimeMaps/main/components/routes/lan/es';
import vehicleTrail from 'Modules/realtimeMaps/main/components/VehicleTrails/lan/es';
import landmarks from 'Modules/realtimeMaps/main/components/LandmarksListMain/lan/es';
import landmarkMain from 'Modules/realtimeMaps/main/components/landmarks/lan/es';
import landmarkList from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkList/lan/es';
import landmarkSettings from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkSettings/lan/es';
import landmarkVisibleGroups from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkVisibleGroups/lan/es';
import oldGeofence from 'Modules/realtimeMaps/main/components/GeofenceListMain/lan/es';
import geofence from 'Modules/realtimeMaps/main/components/geofences/lan/es';
import SendMessage from 'Modules/realtimeMaps/main/components/SendMessage/lan/es';
import vehicleDirections from 'Modules/realtimeMaps/main/components/VehicleDirections/lan/es';
import LayerMap from 'Modules/realtimeMaps/main/components/LayerMap/lan/es';
import findNearest from 'Modules/realtimeMaps/main/components/findNearest/lan/es';
import unitSelection from 'Modules/realtimeMaps/main/components/UnitSelection/lan/es';
import mapSetting from 'Modules/realtimeMaps/main/components/MapSetting/lan/es';
import eta from 'Modules/realtimeMaps/main/components/eta/lan/es';
import speedOverride from 'Modules/realtimeMaps/main/components/speedOverride/lan/es';
import vicinityTool from 'Modules/realtimeMaps/main/components/vicinityTool/lan/es';
import etaCreateEdit from 'Modules/realtimeMaps/main/components/eta/components/etaCreateEdit/lan/es';
import etaTable from 'Modules/realtimeMaps/main/components/eta/components/etaTable/lan/es';
import etaDelete from 'Modules/realtimeMaps/main/components/eta/components/etaDelete/lan/es';
import tagFilterMenu from '../../main/components/TagFilterAppliedMenu/lan/es';

//Module Keys
//Module Keys
let locales = {
    ...main,
    ...mainView,
    ...vehicleList,
    ...routes,
    ...vehicleTrail,
    ...landmarks,
    ...landmarkMain,
    ...landmarkList,
    ...landmarkSettings,
    ...landmarkVisibleGroups,
    ...oldGeofence,
    ...geofence,
    ...SendMessage,
    ...vehicleDirections,
    ...LayerMap,
    ...findNearest,
    ...unitSelection,
    ...mapSetting,
    ...eta,
    ...speedOverride,
    ...vicinityTool,
    ...etaCreateEdit,
    ...etaTable,
    ...etaDelete,
    ...tagFilterMenu,
};


export default locales;