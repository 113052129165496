import { IconButton, Loading, Tooltip } from "@gpstrackit/gtc-react-controls";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetFormatForMoment } from "Core/data/Helpers";
import moment from "moment";
import { useIntl } from "react-intl";

export const IncidentInfo = () => {
  const { messages } = useIntl();

  const { isOpenHistoryOverViewIncident } = useSelector(
    (state) => state.incidentsRedux
  );
  const { user } = useSelector((state) => state.securityRedux);

  const { dateformat, durationFormat, isKph } = user;
  const unitId = isOpenHistoryOverViewIncident?.data?.unit?.id;
  const timezone = isOpenHistoryOverViewIncident?.data?.unit?.timeZone?.name;

  const [loading, setLoading] = useState(true);
  const [incidentInfo, setIncidentInfo] = useState({
    name: "...",
    started: "...",
    reportedBy: "...",
    ended: "...",
    duration: "...",
    endedBy: "...",
    note: "...",
  });

  if (!unitId) return <></>;

  useEffect(() => {
    const incident = isOpenHistoryOverViewIncident?.data?.incident;

    let abbTimezone = moment.tz(timezone).zoneAbbr()
      if(abbTimezone === '-05'){
        abbTimezone = 'COT'
      }

    let formatName = incident?.name
    if(formatName?.length > 20){
      formatName = (
        <Tooltip title={incident?.name}>
          {formatName.slice(0, 20) + "..."}
        </Tooltip>
      );

    }

    let formatNote = incident?.note
    if(formatNote?.length > 30){
      formatNote = (
        <Tooltip title={incident?.note}>
          {formatNote.slice(0, 30) + "..."}
        </Tooltip>
      );

    }

    setIncidentInfo({
      ...incidentInfo,
      name: formatName,
      started: (
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", marginRight: "5px" }}>
            <p style={{ margin: 0, marginRight: "5px" }}>
              {moment(incident?.start).format("hh:mm:ss a")}
            </p>
            <Tooltip title={timezone}>{abbTimezone}</Tooltip>
          </div>
          <p style={{ margin: 0 }}>
            {moment(incident?.start).format(GetFormatForMoment(dateformat))}
          </p>
        </div>
      ),
      reportedBy: incident?.reportedBy,
      ended: (
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", marginRight: "5px" }}>
            <p style={{ margin: 0, marginRight: "5px" }}>
              {moment(incident?.end).format("hh:mm:ss a")}
            </p>
            <Tooltip title={timezone}>{abbTimezone}</Tooltip>
          </div>
          <p style={{ margin: 0 }}>
            {moment(incident?.end).format(GetFormatForMoment(dateformat))}
          </p>
        </div>
      ),
      duration: incident?.duration,
      endedBy: incident?.endedBy,
      note: formatNote
    });

    setLoading(false);
  }, [isOpenHistoryOverViewIncident]);

  return (
    <div style={{ width: "100%", height: "250px", marginLeft: "10px" }}>
        <div style={{display: 'flex'}}>
            <p className="incident_deviceInfo_title_history">{incidentInfo?.name}</p>
        </div>
      {loading ? (
        <div className="incident_deviceInfo_loading">
          <Loading />
        </div>
      ) : (
        <div className="incident_deviceInfo_container_history">
          <div style={{ display: "flex", marginBottom: '5px' }}>
            <p style={{ fontWeight: "bold", marginRight: "5px" }}>
              {messages["incident_event_incident_started"]}
            </p>
            {incidentInfo?.started}
          </div>
          <div style={{ display: "flex", marginBottom: '5px' }}>
            <p style={{ fontWeight: "bold", marginRight: "5px" }}>
              {messages["incident_event_incident_reportedBy"]}
            </p>
            {incidentInfo?.reportedBy}
          </div>
          <div style={{ display: "flex", marginBottom: '5px' }}>
            <p style={{ fontWeight: "bold", marginRight: "5px" }}>
              {messages["incident_event_incident_ended"]}
            </p>
            {incidentInfo?.ended}
          </div>
          <div style={{ display: "flex", marginBottom: '5px' }}>
            <p style={{ fontWeight: "bold", marginRight: "5px" }}>
              {messages["incident_event_incident_duration"]}
            </p>
            {incidentInfo?.duration}
          </div>
          <div style={{ display: "flex", marginBottom: '5px' }}>
            <p style={{ fontWeight: "bold", marginRight: "5px" }}>
              {messages["incident_event_incident_endedBy"]}
            </p>
            {incidentInfo?.endedBy}
          </div>
          <div style={{ display: "flex", marginBottom: '5px' }}>
            <p style={{ fontWeight: "bold", marginRight: "5px" }}>
              {messages["incident_event_incident_resolution"]}
            </p>
            {incidentInfo?.note}
          </div>
        </div>
      )}
    </div>
  );
};
