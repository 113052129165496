import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';

// Actions
import {
  reportsMainGenerateReportProgress
} from 'Redux/actions';

import {
  getTimeZone,
  handleAsyncReport,
  getCategoryFilter,
  generateReportInstance,
  baseReportConditions,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getTemperatureReport(report, page, rowsPerPage, sortField, sortOrder) {
  const { filterType, filterArray, startRange, endRange, otherFilters, unitsSelected, filterEntity } = report.filters;
  let minutes = otherFilters.minute ? otherFilters.minute * 60 : 0;
  const { timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath } = report;
  const baseConditionsQuery = baseReportConditions({ report, name, dataResultPath, timeZone, delayed, unitsSelected, timeframeName , filterType, filterArray, categoryName, startRange, endRange})

  let payload = {
    conditions: {
      ...baseConditionsQuery,
      sortField: {
        field: sortField || "deviceId",
        order: sortOrder === 'desc' ? 'DESC' : 'ASC'
      }
    },
    offset: page,
    limit: rowsPerPage
  }
  if (report.export) {
     payload = {
      conditions: {
        ...payload.conditions,
        exportOptions: {
          sendByEmail,
          emailsTo,
          culture: report.lang,
          timeZone: getTimeZone(timeZone),
          format: {
            fileFormat: report?.exportType  || "json",
            formatOptions: "{}"
          },
          folderName: "px-customer-reports",
          filePrefixName: "temperatureReport",
          columns: [
            {
              "column": "idLabel",
              "header": "Label"
            },
            {
              "column": "dateTime",
              "header": "Time & Date"
            },
            {
              "column": "address",
              "header": "Address"
            },
            {
              "column": "temperature1",
              "header": "Zone 1"
            },
            {
              "column": "temperature2",
              "header": "Zone 2"
            },
            {
              "column": "temperature3",
              "header": "Zone 3"
            }
          ]
        }
      },
      limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
      offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1
    }
  }
  try {
    let data = yield call(
      clientQuery,
      `query($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getTemperatureReport(conditions: $conditions, limit: $limit, offset: $offset) {
          ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?  

            `reportInstanceId`
          :`
            total
            limit
            offset
            category
            items {
              id
              idLabel
              noData
            }
          `}
        }
      }`,
      payload,
      endpoints.GRAPHQL_PX_REPORTING,
    );

    yield handleAsyncReport(report, data)
  } catch (exc) {
    ExceptionManager(exc, 'src/modules/reports/views/main/components/reportsTab/.components/reports/temperatureReport', 'getTemperatureReport');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
  }
}


export default getTemperatureReport;
