import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// controls
import Table, { Column } from '../../table'
//helpers
import {
    formatDatetime,
    formatGeneralNumber,
    getCategoryFilterType, getMethodToFormatDuration,
    getNameVehicle, isKPH,
    useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';

//hooks
import { useSidePanelMap, useTableReport } from 'Core/hooks';
//actions
import { clearRedux, reportsMainGenerateEvents, reportsMainGenerateReportUpdate } from 'Redux/actions'
import moment from 'moment';

// styles
import './geofenceReport.scss';
import { Accordion } from "Components";
import AddressLandmark from "Modules/reports/views/main/components/reportsTab/.components/addressLandmark";
import EventReports from "Modules/reports/views/main/components/reportsTab/.components/eventReport";
import { isKPHAcronym } from '../../../helper';

const GeofenceReport = (props) => {
    const { messages, report, user } = props;
    const { durationFormat } = user;
    const { data, filters, summary, loadingSummary, timeZone } = report;
    const { filterEntity, otherFilters, startRange, endRange } = filters;

    const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
    const [unitDriverSelected, setUnitDriverSelected] = useState();
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
    const [currentNewSortField, setCurrentNewSortField] = useState("deviceId");
    const [currentNewSortOrder, setCurrentNewSortOrder] = useState("asc");

    const exportHandler = useHandleExportReport(report)

    const loadingCreateLandMark = useSelector(state => state.vehicleLandMarkRedux.loadingCreateLandMark)
    const createLandMarkResult = useSelector(state => state.vehicleLandMarkRedux.createLandMarkResult)

    const reportRef = useRef();
    const dispatch = useDispatch()
    useEffect(() => {
        if (report) {
            const newReport = JSON.parse(JSON.stringify(report));
            // to keep the reference to the units selected
            reportRef.current = newReport;
        }
    }, [JSON.stringify(report)]);

    useEffect(() => {
        if (!loadingCreateLandMark && createLandMarkResult) {
            dispatch(reportsMainGenerateReportUpdate(report, currentPage, currentRowsPerPage, currentNewSortField, currentNewSortOrder))
            dispatch(clearRedux("LANDMARK", { "createLandMarkResult": null }));
        }

    }, [loadingCreateLandMark, createLandMarkResult])

    const durationFormatTime = getMethodToFormatDuration(durationFormat);
    const onChangeExpandable = (unit) => {
        if(!unit.noData){
            let events = {
                data: [], filters: {
                    filterType: getCategoryFilterType(filterEntity), filterArray: unit, page: 1, rowsPerPage: 10, sortField: 'eventDate', otherFilters
                }
            }
            dispatch(reportsMainGenerateEvents(report, events))
        }
    }

    function summaryDetails(data) {
        const mapCount = data.reduce((acc, item) => {
            let group = item.geofenceName;
            const distance = Number(acc.get(group)?.split(':')[1]) || 0;
            const duration = Number(acc.get(group)?.split(':')[0]) || 0;
            acc.set(group, `${duration + item.duration}:${distance + item.distance}:${item.isKPH}`)
            return acc
        }, new Map()
        )

        const contentMapEntries = [...mapCount.entries()];

        let contentIds = []
        for (const entry of contentMapEntries) {
            contentIds.push({ label: entry[0].toString(), duration: entry[1].split(':')[0], distance: entry[1].split(':')[1], isKPH: entry[1].split(':')[2] })
        }
        return contentIds;
    }

    const summaryTable = (item) => {
        const summaryList = summaryDetails(item);
        return (<Fragment>
            <Table
                data={summaryList || []}
                noDataMessage={messages["reportsMainRT_noTripsAvailable"]}
                propertyToShowExpandable="duration"
                hiddenPagination
                classNameColumns="start-stop-idle-sub-table"
            >
                <Column label={messages['generatedReport_geofenceTotal']} noSorting>
                    {(element) => {
                        return (<div>
                            <span>{element.label}</span>
                        </div>)
                    }}
                </Column>
                <Column label={messages['generatedReport_totalTime']} noSorting>
                    {(element) => {
                        return element.duration ? durationFormatTime(element.duration, messages) : '0 min, 0 sec'
                    }}
                </Column>
                <Column label={messages['generatedReport_totalDistance']} noSorting>
                    {(element) => {
                        return <span>{element.distance ? `${formatGeneralNumber(element.distance)} ${messages[isKPHAcronym(element.isKPH)]}` : `0 ${messages[isKPHAcronym(element.isKPH)]}`}</span>
                    }}
                </Column>
            </Table>
        </Fragment>)
    }

    const renderExpandableRow = (row) => {
        const type = getNameVehicle(filterEntity === 'Driver' ? 'Unit' : 'Driver', messages)

        let result = [];
        summary?.items.forEach((res) => {
            result.push(...[{
                event: res.eventIn, geofence: res.geofenceName, address: res.addressIn, group: res.groupName, date: res.dateIn, duration: null, distance: null, isKPH: res.isKPH
            }, {
                event: res.eventOut, geofence: res.geofenceName, address: res.addressOut, group: res.groupName, date: res.dateOut, duration: res.duration, distance: res.distance, isKPH: res.isKPH
            }])
        })

        return (<div>
            { !row.noData && (
                <Accordion
                    customTitle={() => { return (<b>{`${messages['generatedReport_totalsFor']}: ${row.idLabel}`}</b>) }}
                    className="AccordionContainer-root start-stop-idle-accordeon"
                    defaultExpanded
                >
                    <div style={{ width: '100%' }}>
                        {summaryTable(summary?.items || [], type)}
                    </div>
                </Accordion>)
            }
            <Table
                loading={loadingSummary}
                data={result || []}
                totalItems={summary?.total || 0}
                onChangedPage={(page, rowsPerPage, sortField, sortOrder) => {
                    let events = {
                        data: [], filters: { filterType: getCategoryFilterType(filterEntity), filterArray: row, page, rowsPerPage, sortField, sortOrder, otherFilters }
                    }
                    dispatch(reportsMainGenerateEvents(report, events))
                }}
                serverSideTable={true}
                hideNumberOfRows={true}
                hiddenPagination={false}
                classNameColumns={'report-column-events-expandible'}
                noDataMessage={messages["generatedReport_noEvents"]}
                numberofRows={10}
                centerPagination
                noData={row.noData}
            >
                <Column label={messages["generatedReport_event"]} field="event" noSorting>
                    {(element) => {
                        return (
                            <EventReports element={{event: element.event || ''}}/>
                        )
                    }}
                </Column>
                <Column label={messages["generatedReport_geofence"]} field="geofence" noSorting>
                    {(element) => {
                        return (<div>
                            <span>{element.geofence}</span>
                        </div>)
                    }}
                </Column>
                <Column label={messages["generatedReport_address"]} field="address" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={{ address: element.address }} />
                            </div>
                        )
                    }}
                </Column>
                <Column label={messages["generatedReport_tag"]} field="group" noSorting>
                    {(element) => {
                        return (<div>
                            <span>{element.group}</span>
                        </div>)
                    }}
                </Column>
                <Column label={messages["generatedReport_timeAndDate"]} field="date" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span className="speed-report-stacked">{element.date && formatDatetime(element.date, "h:mm A", timeZone)}</span>
                                <span className="speed-reportstacked speed-report-event-date">{element.date && formatDatetime(element.date, "MMM D", timeZone)}</span>
                            </div>
                        )
                    }}
                </Column>
                <Column label={messages['generatedReport_timeInLandmark']} field="duration" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span>
                                    {element.duration !== null ? durationFormatTime(element.duration, messages) : ''}
                                </span>
                            </div>
                        )
                    }}
                </Column>
                <Column label={messages["generatedReport_distance"]} field="distance" noSorting>
                    {(element) => {
                        return <span>{element.distance !== null ? `${formatGeneralNumber(element.distance)} ${messages[isKPHAcronym(element.isKPH)]}` : ''}</span>
                    }}
                </Column>
            </Table>
        </div>)
    }

    let parametersTable = useTableReport({
        data: data.items, report, messages, hideHeader: true, renderExpandableRow, onChangeExpandable, propertyToShowExpandable: 'id',
        classNameColumns: 'start-stop-idle-main-table'
    });

    return (<Fragment>
        <Table
            {...parametersTable}
            {...exportHandler}
        >
            <Column label="Day" field="label" noSorting>
                {(element) => {
                    return (<div>
                        <span><strong>{getNameVehicle(filterEntity, messages)}:</strong> {element.idLabel} </span>
                    </div>);
                }}
            </Column>
            <Column label={messages['generatedReport_totalIdleTime']} field="label" noSorting>
                {(element) => {
                    const localTimeStart = moment.utc(startRange).local().format();
                    const localTimeEnd = moment.utc(endRange).local().format();
                    return (<div>
                        <span>
                            <span>
                                <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                            </span>
                        </span>
                    </div>);
                }}
            </Column>
        </Table>
        <SideMapComponent hiddenButton hiddenSearchMap />
    </Fragment>);
}

export default GeofenceReport;
