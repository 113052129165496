export default {
    mapGeofenceDeleteGeofence_title:'Eliminar Geocerca',
    mapGeofenceDeleteGeofence_detail:'¿Está seguro que desea eliminar los siguientes Geocercas?', //Replace number value
    mapGeofenceDeleteGeofence_detail_one:'¿Está seguro que desea eliminar la Geocerca?', //Replace number value
    mapGeofenceDeleteGeofence_warning:'Esta acción no se puede deshacer.',
    mapGeofenceDeleteGeofence_success:'Geocercas eliminadas',
    mapGeofenceDeleteGeofence_success_one:'Geocerca eliminada',
    mapGeofenceDeleteGeofence_remove_all_geofence_keyword: 'todas las Geocercas',
    mapGeofenceDeleteGeofence_remove_all: '¿Esta seguro de eliminar',
    mapGeofenceDeleteGeofence_remove_all_except: 'con excepción de la siguiente lista'
}