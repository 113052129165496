//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
  FLEET_LANDMARK_CREATE_UPDATE_LANDMARK
} from 'Redux/actionTypes';

//actions 
import {
  createEditFleetLandmarkResponse,
  editLandmarkBulkResult,
  getKmls
} from 'Redux/actions';

function* createEditFleetLandmarkRequest({ payload }) {
  try {
    let { data, refresh } = payload;

    let query = ` 
      mutation createLandmark (
        $name: String!, 
        $tagsIds: [Int]!, 
        $latitude: Float!, 
        $longitude: Float!, 
        $shape: shapeType!, 
        $radius: Float!, 
        $address: String!, 
        $city: String!, 
        $zip: String!, 
        $state: String!, 
        $country: String!, 
        $note: String, 
        $coordinates: [CoordinateInput], 
        $color: String, 
        $iconName: String
      ) {
        landmark: createLandmark (
          name: $name, 
          tagsIds: $tagsIds, 
          latitude: $latitude, 
          longitude: $longitude, 
          shape: $shape, 
          radius: $radius, 
          address: $address, 
          city: $city, 
          zip: $zip, 
          state: $state, 
          country: $country, 
          note: $note, 
          coordinates: $coordinates, 
          color: $color, 
          iconName: $iconName
        ) {
          id
          companyId
          name
          tags {
              id
            label
            color
              path
          }
          latitude
          longitude
          shape
          radius
          address
          city
          zip
          state
          country
          note
          coordinates {
              latitude
              longitude
          }
          color
          iconName
        }
      } 
    `;

    if (data?.id)  {
      query = ` 
        mutation updateLandmark (
          $id: Int!, 
          $name: String, 
          $tagsIds: [Int], 
          $latitude: Float, 
          $longitude: Float, 
          $shape: shapeType, 
          $radius: Float, 
          $address: String, 
          $city: String, 
          $zip: String, 
          $state: String, 
          $country: String, 
          $note: String, 
          $coordinates: [CoordinateInput], 
          $color: String, 
          $iconName: String
        ) {
          landmark: updateLandmark (
            id: $id, 
            name: $name, 
            tagsIds: $tagsIds, 
            latitude: $latitude, 
            longitude: $longitude, 
            shape: $shape, 
            radius: $radius, 
            address: $address, 
            city: $city, 
            zip: $zip, 
            state: $state, 
            country: $country, 
            note: $note, 
            coordinates: $coordinates, 
            color: $color, 
            iconName: $iconName
          ) {
            id
            companyId
            name
            tags {
                id
                label
                color
                path
            }
            latitude
            longitude
            shape
            radius
            address
            city
            zip
            state
            country
            note
            coordinates {
                latitude
                longitude
            }
            color
            iconName
          }
        } 
      `;
    }

    const response = yield call(
      clientMutation,
      query,
      data,
      endpoints.GRAPHQL_GENERAL,
    );
    if (response && response?.landmark) {
      const { landmark } = response;
      yield put(createEditFleetLandmarkResponse({ ...landmark, error: false }));

      if (refresh) {
        yield put(editLandmarkBulkResult(landmark));
        yield put(getKmls());
      }
    } else {
      throw 'Error while updating/creating landmark';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(
      exc,
      'modules/fleet/redux/saga',
      'createEditFleetLandmarkRequest',
    );

    let message = null
        if (exc.errorData && exc.errorData.errors) {
            exc.errorData.errors.forEach(error => {
                if (error.errorInfo && error.errorInfo.code) {
                    message = error.message
                }
            });
        }

      yield put(createEditFleetLandmarkResponse({ error: true, message: message }));
  }
}

function* watchCreateEditFleetLandmark() {
  yield takeEvery(FLEET_LANDMARK_CREATE_UPDATE_LANDMARK, createEditFleetLandmarkRequest);
}

export default function* rootSaga() {
  yield all([fork(watchCreateEditFleetLandmark)]);
}
