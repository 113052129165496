//View Keys
export default {
	fleet_vehicle: 'Vehicle',
	fleet_starter_vehicles: 'Selected Units',
	fleet_ScheduledGroupStarter: 'Scheduled Group Starter',
	//vehicleStatusModal_titleModalEnableDisable: 'Enable/Disable Starter',
	fleetModal_titleModalEnableDisable: 'Vehicle Starter',
	vehicleStatusModal_confirmationModalEnableDisable:'Are you sure you want to change the status starter?',
	vehicleStatusModal_currentStatus: 'Status',
	fleet_disable: 'DISABLE',
	fleet_enable: 'ENABLE',
	fleet_starter_status: 'Status',
	fleet_sentMessage: 'Sent',
	fleet_sentMessageError: 'Error',
	disable: 'Disable',
	enable: 'Enable',
	fleet_starter_process_status: "Process Status",


	fleet_starter_not_supported: 'Not Supported',
	fleet_starter_enable: 'Enabled',
	fleet_starter_pending_enable: 'Pending Enable',
	fleet_starter_disable: 'Disabled',
	fleet_starter_pending_disable: 'Pending Disable',

	fleet_validation_starter_title:'Good',
	fleet_validation_starter_message:'Starter changed',
	fleet_starter_disable_confirmation: "Are you sure you want to disable starter for {number_units} units?",
	fleet_starter_enable_confirmation: "Are you sure you want to enable starter for {number_units} units?",
	fleetModal_units: "Units"
};
