import {
  MODAL_PING_OPEN,
  MODAL_PING_OPEN_RESULT,
  MODAL_PING_CLOSE,
  CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
  isOpen: false,
  loadingPing: false,
  units: [],
  pingResponse: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case MODAL_PING_OPEN:
      return { ...state, loadingPing: true, isOpen: true, units: action.payload.units };

    case MODAL_PING_CLOSE:
      return { ...state, isOpen: false, pingResponse: [] };

    case MODAL_PING_OPEN_RESULT:
      return { ...state, loadingPing: false, pingResponse: action.payload };

    case CLEAR_REDUX:
      return ["", "MODAL_PING"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
};
