import React from "react";
import { useIntl } from "react-intl";
import {
    Icons,
    Tooltip,
  } from "@gpstrackit/gtc-react-controls";
import { Grid } from "@mui/material";
import colors from "Assets/sass/core/vars.scss";
import { Pill } from 'Components';

const suscribersTypes = [
  { id: "1", name: "incident_email", color: "green" },
  { id: "2", name: "incident_phone", color: "green" },
  { id: "3", name: "incident_contacts", color: "green" },
];

const SubscribersTypes = ({
  selectedSuscriber,
  setSelectedSuscriber,
  setEmailPhone,
}) => {
  const { messages } = useIntl();
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h3 style={{ marginRight: "10px" }}>
          {messages["incident_suscribers"]}
        </h3>
        <Tooltip
          style={{ marginTop: "5px" }}
          title={messages["incident_suscribers_helper"]}
          arrow={false}
          placement="right"
        >
          <Icons name="info" size="small" />
        </Tooltip>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {suscribersTypes.map((item, key) => {
          const { name, id, color } = item;

          return (
            <Grid item sm={3} style={{ padding: 2, width: "130px" }} key={key}>
              <Pill
                key={key}
                textField="label"
                outlined={item?.id !== selectedSuscriber}
                item={{ label: messages[name], color: colors[color], id }}
                style={{ border: '0', width: '100%' }}
                colorField="default"
                colorSelection="66bb6a"
                onClick={(item) => {
                  setEmailPhone("");
                  setSelectedSuscriber(item?.id);
                }}
              />
            </Grid>
          );
        })}
      </div>
    </div>
  );
};

export default SubscribersTypes;
