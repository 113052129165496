import React, { useEffect, useState, useRef, useCallback, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
// Cloud React components
import { Button, Input, Form } from 'Components';
import Modal, { ModalBody, ModalFooter } from 'Components/Modal';
import { NotificationManager } from 'Components/Notifications';
// Controls
import { Box, Typography } from '@mui/material';
// Material UI
import _ from 'lodash';

//actions
import {
  refreshToken,
  userChangePassword,
  clearRedux
} from 'Redux/actions';

const ChangePassword = (props) => {
  const dispatch = useDispatch();
  const { intl, closeChangePwd } = props;
  const { messages } = intl;

  const changePasswordform = useRef();
  const { open, loading, response } = useSelector(state => state.userChangePasswordRedux);

  const [formData, setFormData] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    return () => {
      dispatch(clearRedux('ACCOUNTS_CHANGE_PASSWORD', { response: null }));
    }
  }, []);

  useEffect(() => {
    //dispatch(refreshToken());
  }, []);

  useEffect(() => {
    if (response) {


      if (response.error) {
        let msg = messages['errorMessage'];
        if (response.message) {
          if (response.message === "GraphQL error: Incorrect username or password." ||
            response.message === "Incorrect username or password." || response.message === "NotAuthorizedException: Incorrect username or password.") {
            msg = messages['accountChangePassword_incorrectPassword'];
          } else if (response.message === "CustomException: newPassword must be different from oldPassword" ||
            response.message === "changePassword: newPassword must be different from oldPassword") {
            msg = messages['accountChangePassword_diferentErrorPassword'];
          } else if (response.message === "GraphQL error: Attempt limit exceeded, please try after some time." ||
            response.message === "Attempt limit exceeded, please try after some time." || response.message === "LimitExceededException: Attempt limit exceeded, please try after some time.") {
            msg = messages['accountChangePassword_attemptLimitExceeded'];
          }
        }

        NotificationManager.error(
          msg,
          messages['error'],
          3000,
          null,
          null,
          'warning',
        );
      } else {
        NotificationManager.success(
          messages['accountChangePassword_passwordChanged'],
          messages['success'],
          3000,
          null,
          null,
          'success',
        );
        closeChangePwd && closeChangePwd()
        handleClose();
      }
    }
  }, [response]);

  const handleClose = useCallback(() => {
    if (!loading) {
      dispatch(clearRedux('ACCOUNTS_CHANGE_PASSWORD', { open: false, response: null }));
      setFormData({});
      setError(null);
    }
  });

  const onSubmit = useCallback(() => {
    setError(null);

    if (!_.isEqual(formData.newPassword, formData.confirmNewPassword)) {
      setError(messages['accountChangePassword_passwordNotEqual']);
      return;
    }

    const strongPassword = new RegExp('^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,20})');
    if (!strongPassword.test(formData.newPassword)) {
      setError(messages['accountChangePassword_passwordValidation']);
      return;
    }

    dispatch(userChangePassword({
      accessToken: localStorage.getItem('AccessToken'),
      oldPassword: formData.currentPassword,
      newPassword: formData.newPassword
    }));
    
  });

  return (
    <Fragment>
      <Form ref={changePasswordform} onValidSubmit={onSubmit}>
        <div>
          <Box mb={3}>
            <Typography variant="subtitle1">
              {messages['accountChangePassword_title']}
            </Typography>
          </Box>
          <Input
            id="currentPassword"
            value={formData.currentPassword || ""}
            onChange={(value) => {
              setFormData({ ...formData, currentPassword: value });
              setError(null);
            }}
            minLength={1}
            maxLength={30}
            label={messages['accountChangePassword_currentPassword']}
            placeholder={messages['accountChangePassword_currentPassword']}
            type="password"
            required
            requiredMessage={messages['required_field']}
            minLengthMessage={`${messages['accountChangePassword_minLengthMessage']} 1`}
            maxLengthMessage={`${messages['accountChangePassword_maxLengthMessage']} 30`}
          />
        </div>

        <div>
          <Input
            id="newPassword"
            value={formData.newPassword || ""}
            onChange={(value) => {
              setFormData({ ...formData, newPassword: value });
              setError(null);
            }}
            minLength={6}
            maxLength={20}
            label={messages['accountChangePassword_newPassword']}
            placeholder={messages['accountChangePassword_newPassword']}
            type="password"
            required
            requiredMessage={messages['required_field']}
            minLengthMessage={`${messages['accountChangePassword_minLengthMessage']} 6`}
            maxLengthMessage={`${messages['accountChangePassword_maxLengthMessage']} 20`}
          />
        </div>

        <div>
          <Input
            id="confirmNewPassword"
            value={formData.confirmNewPassword || ""}
            onChange={(value) => {
              setFormData({ ...formData, confirmNewPassword: value });
              setError(null);
            }}
            minLength={6}
            maxLength={20}
            label={messages['accountChangePassword_confirmNewPassword']}
            placeholder={messages['accountChangePassword_confirmNewPassword']}
            type="password"
            required
            requiredMessage={messages['required_field']}
            minLengthMessage={`${messages['accountChangePassword_minLengthMessage']} 6`}
            maxLengthMessage={`${messages['accountChangePassword_maxLengthMessage']} 20`}
          />
        </div>

        {
          error &&
          <div style={{ marginTop: 10 }}>
            <small className="text-danger" style={{ paddingTop: 5 }}>
              {error}
            </small>
          </div>
        }
      </Form>
      <Box mt={5} style={{display: 'flex'}}>
        <div className={`button-save ${loading && 'disabled'}`} onClick={() => changePasswordform.current.submit()}>
          {messages["save"]}
        </div>
        <Button
          onClick={closeChangePwd && closeChangePwd}
          className="btn-cancel"
        >
          {messages['cancel']}
        </Button>
      </Box>
    </Fragment>
  )
}

export default injectIntl(ChangePassword);