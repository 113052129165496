// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  SETTINGS_INPUT_LABEL_SEARCH,
  SETTINGS_INPUT_LABEL_DELETE,
  SETTINGS_INPUT_LABEL_CREATE_EDIT
} from "Redux/actionTypes";

// Actions
import {
  searchSettingsInputLabelResponse,
  createEditSettingsinputLabelsResponse,
  deleteSettingsInputLabelsResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* createEditInputLabelRequest({ payload }) {
  try {
      const { inputLabel } = payload;

      let query = `
          mutation createInputLabel ($title: String!, $onLabel: String!, $offLabel: String!) {
            createInputLabel (title: $title, onLabel: $onLabel, offLabel: $offLabel) {
                id
                title
                onLabel
                offLabel
            }
          }
      `;
        
      if(inputLabel?.idInputLabel){
        query = `
            mutation updateInputLabel ($id: Int!, $title: String!, $onLabel: String!, $offLabel: String!) {
              updateInputLabel (id: $id, title: $title, onLabel: $onLabel, offLabel: $offLabel) {
                  id
                  title
                  onLabel
                  offLabel
              }
            }
        `;
      }
      

      let data = {
        id: inputLabel?.idInputLabel,
        title: inputLabel?.title,
        onLabel: inputLabel?.onLabel,
        offLabel:inputLabel?.offLabel
      }

      const response = yield call(
          clientMutation,
          query,
          data,
          endpoints.GRAPHQL_GENERAL,
      );
      
      if (!response && !inputLabel?.idInputLabel || !response.createInputLabel && !inputLabel?.idInputLabel) throw 'Error while creating input Label';

      
      if (!response && inputLabel?.idInputLabel || !response.updateInputLabel && inputLabel?.idInputLabel) throw 'Error while update input Label';

      let responseData = (!inputLabel?.idInputLabel)?response.createInputLabel:response.updateInputLabel;
      yield put(createEditSettingsinputLabelsResponse({ ...responseData, error: false }));
  } catch (exc) {
      yield put(createEditSettingsinputLabelsResponse({ error: true }));
      ExceptionManager(
          exc,
          "modules/settings/views/inputLabels/redux/saga",
          "createEditInputLabelRequest",
          true
      );
      
  }
}

function* searchInputLabelRequest({ payload }) {
  try {
    const {
      openSearch
    } = payload;

    const query = `
            query searchInputLabels ($openSearch: String) {
              searchInputLabels (openSearch: $openSearch) {
                  id
                  title
                  onLabel
                  offLabel
              }
          }
        `;

    const response = yield call(
      clientQuery,
      query,
      {
        openSearch
      },
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.searchInputLabels) {
      throw "Error while getting Input Labels  list";
    }
    let inputLabels = response.searchInputLabels;
    yield put(searchSettingsInputLabelResponse({ inputLabels, error: false }));
  } catch (exc) {
    yield put(searchSettingsInputLabelResponse({ inputLabels: [], error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/inputLabels/redux/saga",
      "searchInputLabelRequest"
    );
  }
}

function* deleteInputLabelsRequest({ payload }) {
  try {
      const { items } = payload;
      let mutations = [];

      items?.forEach((item, index) => {
          mutations.push(`
              DeleteInput${index}: deleteInputLabel(id: ${item?.id})
          `);
      });

    let mutation = `mutation{${mutations.join("")}}`;

      const response = yield call(
          clientMutation,
          mutation,
          {},
          endpoints.GRAPHQL_GENERAL,
      );

      if (!response || !response.DeleteInput0) throw 'Error while deleting Input';

      yield put(deleteSettingsInputLabelsResponse({ ...response, error: false }));
  } catch (exc) {
      yield put(deleteSettingsInputLabelsResponse({ error: true }));
      ExceptionManager(
          exc,
          "modules/settings/views/inputLabels/redux/saga",
          "deleteInputLabelsRequest"
      );
  }
}



function* watchMethods() {
  yield takeEvery(SETTINGS_INPUT_LABEL_SEARCH, searchInputLabelRequest);
  yield takeEvery(SETTINGS_INPUT_LABEL_CREATE_EDIT, createEditInputLabelRequest);
  yield takeEvery(SETTINGS_INPUT_LABEL_DELETE, deleteInputLabelsRequest);

}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}