//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { NotificationManager } from 'Components/Notifications';

//Action types
import { 
    MAP_UTILITIES_VEHICLE_LIST_SEARCH_UNITS, 
    MAP_UTILITIES_VEHICLE_LIST_GET_UNIT, 
    MAP_UTILITIES_VEHICLE_LIST_GET_TAG_UNIT } from 'Redux/actionTypes';

//actions
import { 
    getUnitsVehicleListResponse, 
    getUnitVehicleListResponse,
    getTagsVehicleListResponse } from 'Redux/actions';

function* searchUnitsVehicleList({ payload }) {
    try {
        
      const response = yield call(
        clientQuery,
            `query searchUnits ($openSearch: String) {
                result: searchUnits (openSearch: $openSearch) {
                    id
                    label
                    typeId
                    show
                    driverName
                    timeZone {
                    id
                    name
                    }
                }
            }`,
            {
            openSearch: payload
            },
            endpoints.GRAPHQL_GENERAL,
        );
        if (response.result) {
            yield put(getUnitsVehicleListResponse(response.result));
        } else yield put(getUnitsVehicleListResponse([]))
    } catch (e) {
        yield put(getUnitsVehicleListResponse([], e))
        ExceptionManager(e, 'modules/realtimeMaps/main/components/VehicleList/redux/saga.js', 'searchUnitsVehicleList');
    }
}

function* getUnitsVehicleList({ payload }) {
    try {
        
      const response = yield call(
        clientQuery,
            `query getUnit ($id: Int!) {
                result: getUnit (id: $id) {
                    id
                    label
                    typeId
                    show
                    driverName
                    unitGroups {
                        id
                        name
                        color
                        unitsCount
                    }
                    timeZone {
                        id
                        name
                    }
                }
            }`,
            {
            id: payload
            },
            endpoints.GRAPHQL_GENERAL,
        );
        if (response.result) {
            yield put(getUnitVehicleListResponse(response.result));
        } else yield put(getUnitVehicleListResponse(null))
    } catch (e) {
        yield put(getUnitVehicleListResponse(null, e))
        ExceptionManager(e, 'modules/realtimeMaps/main/components/VehicleList/redux/saga.js', 'getUnitsVehicleList');
    }
}

function* getTagsInlineVehicleList({ payload }) {
    try {
        const { entityName } = payload
        const response = yield call(
            clientQuery,
                `query searchTagsInline ($entityName: String!, $limit: Int, $offset: Int) {
                    result: searchTagsInline (entityName: $entityName, limit: $limit, offset: $offset) {
                        items {
                            id
                            label
                            color
                            parentId
                            path
                            level
                        }
                        total
                    }
                }`,
            {
                entityName
            },
            endpoints.GRAPHQL_GENERAL,
        );
        if (response.result) {
            yield put(getTagsVehicleListResponse(response.result.items));
        } else yield put(getTagsVehicleListResponse(null))
    } catch (e) {
        yield put(getTagsVehicleListResponse(null, e))
        ExceptionManager(e, 'modules/realtimeMaps/main/components/VehicleList/redux/saga.js', 'getTagsInlineVehicleList');
    }
}
 
function* watchGetVehicleListTasksRequest() {
    yield takeEvery(MAP_UTILITIES_VEHICLE_LIST_SEARCH_UNITS, searchUnitsVehicleList)
    yield takeEvery(MAP_UTILITIES_VEHICLE_LIST_GET_UNIT, getUnitsVehicleList)
    yield takeEvery(MAP_UTILITIES_VEHICLE_LIST_GET_TAG_UNIT, getTagsInlineVehicleList)
}

//Saga Export
export default function* rootSaga() {
    yield all([
        fork(watchGetVehicleListTasksRequest)
    ]);
}