import React, { forwardRef, useState, Children, cloneElement, Fragment, useRef, useImperativeHandle, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './areamenu.scss';

export const Area = (props) => {
	return (
		<span
			className={props.className}
			style={props.style}
			onClick={() => {
				props.toggleShow(!props.showMenu);
			}}
		>
			{props.children}
		</span>
	);
};

export const Menu = (props) => {
	return (
		<div className={cx('areamenu-menu', props.className, props.showMenu ? 'show' : '')} style={props.style}
			onClick={() => {
				if (props.clickClose) {
					props.toggleShow(!props.showMenu);
				}
			}}>
			{props.children}
		</div>
	);
};

const AreaMenu = forwardRef((props, ref) => {
	const [showMenu, toggleShow] = useState(false);
	const areaMenu = useRef();
	const childrenWithProps = Children.map(props.children, (child) => {
		return cloneElement(child, { showMenu: showMenu, toggleShow: toggleShow, clickClose: props.clickClose });
	});

	let mouseOver = false;

	useImperativeHandle(ref, () => ({
		toggle: (value) => { toggleShow(value) }
	}))

	useEffect(() => {
        if (showMenu && props.noHideOnBlur) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showMenu, props.noHideOnBlur]);

    const handleClickOutside = (e) => {
		if(props?.noCloseAway) return

        if (areaMenu?.current?.contains(e.target)) {
            return;
        }

        toggleShow(false);
    };

	return (
		<Fragment>
			<div
				ref={areaMenu}
				className={cx('areamenu', props.className)}
				onMouseOver={() => {
					props.over && areaMenu.current.focus();
					props.over && toggleShow(true);
					mouseOver = true;
				}}
				onMouseLeave={() => {
					mouseOver = false;
				}}
				onBlur={() => {
					if (!mouseOver && !props.noHideOnBlur) {
						//console.log("blur", mouseOver,props.noHideOnBlur)
						toggleShow(false);
					}
					!mouseOver && props.onBlur && props.onBlur()
				}}
				tabIndex={1}
			>
				{childrenWithProps}
			</div>
		</Fragment>
	);
});

export default AreaMenu;

AreaMenu.propTypes = {
	id: PropTypes.string.isRequired,
	mouseOver: PropTypes.bool,
	className: PropTypes.string,
};
