import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../core/defaultValues';
import { ExceptionManager } from '../../../../core/logManager';

import { resetALL, catalogLoad } from '../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    shortCode: {},
    error: null
};

const route = 'core/views/shortCodes/redux/slice'

export const getShortCode = createAsyncThunk('shortCodes/get', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        
        const result = await clientQuery(`
                query getShortCode ($id: String!) {
                    getShortCode (id: $id) {
                        id
                        url
                        expirationDate
                    }
                }`,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL_V2,
            {
				"x-api-key": process.env.COGNITO_API_KEY_V2
            }
        )



        return result?.getShortCode || { };
    } catch (exc) {
        return exc?.error
    }
});

//Slice
const shortCodeRedux = createSlice({
    name: 'shortCode',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        builder.addCase(getShortCode.pending, (state, action) => {
            state.loading = true
            state.error = null
            state.shortCode = {}
        });
        builder.addCase(getShortCode.fulfilled, (state, action) => {
            return { ...state, loading: false, error:action.payload?.url?null:action.payload, shortCode: action.payload }
        });
        builder.addCase(getShortCode.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

    },
})

export const { actions, reducer } = shortCodeRedux;
// Extract and export each action creator by name
export const { reset } = actions;
export default reducer;