import React from 'react';
// Material UI
import { Grid, Typography } from '@mui/material';
import {
  GeofenceRadiusValues
} from 'Components/Map/providers/enums';
import {
  copyToClipboard
} from 'Core/data/Helpers'
import { Button } from 'Components';
import { TagsGroup } from 'Components/Tag';

// Styles
import '../infoBubble.scss';
import '../../../../../components/Map/providers/google/InfoWindow/style.scss'
import { useSelector } from 'react-redux';

const GeofenceInfo = (props) => {
  const { data, messages } = props;

  const isKph = useSelector((state) => state.securityRedux.user.isKph);

  let coordinatesGeofence = data?.markerDetail?.geofence?.center_latlong || null
  if(coordinatesGeofence){
    let valueCoor = coordinatesGeofence.split(',')    
    if(valueCoor && valueCoor.length > 1){
      if(valueCoor[0] != 'undefined' && valueCoor[1] != 'undefined'){
        coordinatesGeofence = `${parseFloat(valueCoor[0]).toFixed(6)}, ${parseFloat(valueCoor[1]).toFixed(6)}`;
      }else{
        coordinatesGeofence = null
      } 
    }else { 
      coordinatesGeofence = null
    }
    
  }
  coordinatesGeofence = !coordinatesGeofence ? (data?.markerDetail?.geofence?.coordinates[0]?.latitude && data?.markerDetail?.geofence?.coordinates[0]?.longitude ? `${data?.markerDetail?.geofence?.coordinates[0]?.latitude?.toFixed(6)}, ${data?.markerDetail?.geofence?.coordinates[0]?.longitude?.toFixed(6)}` : "N/A") : coordinatesGeofence;
  //get Radius
  const valueRadius = data?.markerDetail?.geofence?.radius
  let geofenceRadius = GeofenceRadiusValues.find(value => value.value == valueRadius)?.text;
  geofenceRadius = geofenceRadius ? geofenceRadius : `${isKph ? parseFloat(valueRadius * 0.001).toFixed(2) : parseFloat(valueRadius * 0.000621371).toFixed(2)} ${isKph ? messages['kilometers'] : messages['miles']}`;

  return (
    <div className='info-window-tab-streetview'>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          padding: '16px 45px 16px 16px'
        }}
      >
        <Grid item xs zeroMinWidth>
          <Typography noWrap style={{ fontWeight: 'bold' }}>
            {data?.markerDetail?.geofence?.name}
          </Typography>
        </Grid>
      </Grid>

      <div style={{ borderTop: 'solid 1px #e4e4e4' }}></div>

      <div>
        <table className="info-bubble-table">
          <tbody>
            {/* <tr>
              <td>
                Group
              </td>
              <td>
                <div className="hover-container">
                  <span style={{ paddingRight: 8 }}>
                    {data?.markerDetail?.geofence?.groupName || messages['infoBubble_defaultGroup']}
                  </span>
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(data?.markerDetail?.geofence?.groupName || messages['infoBubble_defaultGroup'])}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr> */}

            <tr>
              <td>
                {messages['infoBubble_latLng']}
              </td>
              <td>
                <div className="hover-container">
                  <span style={{ paddingRight: 8 }}>
                    {coordinatesGeofence}
                  </span>
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(`${(data?.markerDetail?.geofence?.coordinates[0]?.latitude && data?.markerDetail?.geofence?.coordinates[0]?.longitude ? `${data?.markerDetail?.geofence?.coordinates[0]?.latitude?.toFixed(6)}, ${data?.markerDetail?.geofence?.coordinates[0]?.longitude?.toFixed(6)}` : "N/A")}`)}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr>

            {
              data?.markerDetail?.geofence?.radius > 0 &&
              <tr>
                <td>
                  {messages['infoBubble_radius']}
                </td>
                <td>
                  <div className="hover-container">
                    <span style={{ paddingRight: 8 }}>
                      {geofenceRadius}
                    </span>
                    <Button
                      type="icon"
                      icon="content_copy"
                      onClick={() => copyToClipboard(geofenceRadius)}
                      fontSize="inherit"
                      className="btn-no-padding hover-item"
                    />
                  </div>
                </td>
              </tr>
            }

            {
              data?.markerDetail?.geofence?.tags?.length > 0 &&
              <tr>
                <td>
                  {messages['infoBubble_tags']}
                </td>
                <td>
                  <TagsGroup
                    options={data?.markerDetail?.geofence?.tags}
                    nameField="label"
                    colorField="color"
                    //alwaysGroup={true}
                  />
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default GeofenceInfo;