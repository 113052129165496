//View Keys
export default {
	fleetModal_title: 'Schedule Group Starter',
	vehicleStatusModal_selectVehicle: 'Select Vehicle Groups',
	vehicleStatusModal_newStarterSchedule: 'Name of New Starter Schedule',
	vehicleStatusModal_scheduleName: 'Schedule Name',
	vehicleStatusModal_selectEnableDisable: 'Select Enable/Disable Times',
	vehicleStatusModal_starterEnabled: 'Starter Enabled',
	vehicleStatusModal_starterDisabled: 'Starter Disabled',
	fleetModal_time: 'Time',
	vehicleStatusModal_monday: 'Monday',
	vehicleStatusModal_tuesday: 'Tuesday',
	vehicleStatusModal_wednesday: 'Wednesday',
	vehicleStatusModal_thursday: 'Thursday',
	vehicleStatusModal_friday: 'Friday',
	vehicleStatusModal_saturday: 'Saturday',
	vehicleStatusModal_sunday: 'Sunday',
	fleetModal_createStarterScheduleSuccess: 'Successfully added Starter Schedule',
	fleetModal_deleteStarterSchuduleSuccess: 'Starter Schedule Removed Successfully',
	fleetModal_updateStarterScheduleSuccess: 'Starter Schedule Updated Successfully',
	fleetModal_tableName: 'NAME',
	fleetModal_tableDetail: 'DETAILS',
	fleetModal_tableGroups: 'GROUPS',
	fleetModal_delete: 'Delete',
	fleetModal_cancel: 'Cancel',
	fleetModal_deleteBtn: 'DELETE',
	fleetModal_deleteConfirmation: 'Are you sure you want to delete the schedule?',
	fleetModal_tabNew: 'Create New',
	fleetModal_tabUpdate: 'Update',
	vehicleStatusModal_scheduleBtn: 'SCHEDULE',
	fleetModal_schedule: 'Scheduled',
	fleetModal_newStarterScheduleName: 'SCHEDULE NAME',
	fleetModal_schedulePlaceholder: 'My New Schedule',
	fleetModal_starterEnabledLabel: 'Enable at:',
	fleetModal_starterDisabledLabel: 'Disable at:',
	fleetModal_save: 'Save',
	fleetModal_vehicleGroup: 'VEHICLE GROUPS',
	fleetModal_starterDisabledBeGreater : 'Starter Disabled must be greater than Starter Enabled',
	fleetModal_fieldRequired: 'Required Field'
};
