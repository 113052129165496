export default {
    mapLandmarkCreateEditLandmark_createTitle: "Crear Nuevo Punto de referencia",
    mapLandmarkCreateEditLandmark_editTitle: "Editar Punto de referencia: ",
    mapLandmarkCreateEditLandmark_circle: "Círculo",
    mapLandmarkCreateEditLandmark_polygon: "Polígono",
    mapLandmarkCreateEditLandmark_createLandmark: "Crear Referencia",
    mapLandmarkCreateEditLandmark_square: "Cuadrado",
    mapLandmarkCreateEditLandmark_select: "Tipo de Punto de referencia",
    mapLandmarkCreateEditLandmark_name: "Nombre",
    mapLandmarkCreateEditLandmark_landmarkName: "Nombre Punto de referencia",
    mapLandmarkCreateEditLandmark_landmarkIcon: "Ícono del Punto de referencia",
    mapLandmarkCreateEditLandmark_iconType: "Tipo de ícono",
    mapLandmarkCreateEditLandmark_Icon_color: "Ícono & Color",
    mapLandmarkCreateEditLandmark_group: "Grupo",
    mapLandmarkCreateEditLandmark_tag: "Etiqueta",
    mapLandmarkCreateEditLandmark_tag_select: "Seleccionar Etiquetas",
    mapLandmarkCreateEditLandmark_radius: "Radio",
    mapLandmarkCreateEditLandmark_note: "Notas",
    mapLandmarkCreateEditLandmark_address: "Dirección",
    mapLandmarkCreateEditLandmark_latLng: "Lat/Long",
    mapLandmarkCreateEditLandmark_success: "Punto de referencia guardado exitosamente",
    mapLandmarkCreateEditLandmark_iconRequired: 'Debe seleccionar un ícono o deshabilitar el switch de "Ícono de Punto de referencia", por favor intente de nuevo',
    mapLandmarkCreateEditLandmark_circleRequired: 'Por favor selecciona un punto en el mapa para crear el Punto de referencia',
    mapLandmarkCreateEditLandmark_tags_shape: "Forma de punto de referencia",
    mapLandmarkCreateEditLandmark_tags_label: "Nombre de punto de referencia",
    mapLandmarkCreateEditLandmark_error_define_landmark: "Utilice una Dirección, un Clic en el mapa o una Unidad para crear un Punto de Referencia en el mapa",
    mapLandmarkCreateEditLandmark_info_polygon: 'Comience a hacer clic en los puntos del mapa para crear un Punto de Referencia. Deben ser 3–{maxPoints} puntos.'
};