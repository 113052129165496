import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation,clientQuery } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../core/logManager';

import { resetALL } from '../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    response: null,
    service: null,
    error: null,
    id: null,
    updateMaintenanceServiceResult: null, 
    loadingUpdateMaintenanceService: false,
    serviceItems: [],
    newServiceItemResult: null
};

const route = 'modules/maintenance/modals/createEditMaintenanceService/redux/slice'

export const maintenanceMCSGetMaintenanceService = createAsyncThunk('maintenance/createEditMaintenanceService/getMaintenanceService', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        let query = `
            query ($id: Int!){
                data: getMaintenanceService(id: $id){
                    id
                    title
                    notes
                    miles
                    milesBefore
                    milesAfter
                    days
                    daysBefore
                    daysAfter
                    engineHours
                    engineHoursBefore
                    engineHoursAfter
                    recurrent
                    estimatedCost
                    serviceItems{
                        id
                        name
                        parts
                        labor
                        subtotal
                    }
                    subscribers {
                        id
                        contactId
                        contact {
                            id
                            title
                            address
                            type
                        }
                        alert
                        alertBefore
                        alertAfter
                    }
                    fileIds
                    files {
                        id
                        originalName
                        fileKey
                        repositoryCode
                    }
                }
            }
        `;

        const response = await clientMutation(
            query,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        return response?.data;
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceMCSGetMaintenanceService');
        return rejectWithValue(exc)
    }
});

export const maintenanceMCSCreateEditMaintenanceService = createAsyncThunk('maintenance/createEditMaintenanceService/createEditMaintenanceService', async (payload, { rejectWithValue, dispatch }) => {
    try {

        let query = `mutation (
            ${payload.id ? '$id: Int!,' : ''}
            $title: String!,
            $notes: String,
            $miles: Float,
            $milesBefore: Float,
            $milesAfter: Float,
            $days: Int,
            $daysBefore: Int,
            $daysAfter: Int,
            $engineHours: Int,
            $engineHoursBefore: Int,
            $engineHoursAfter: Int,
            $recurrent: Boolean!,
            $estimatedCost: Float,
            $fileIds: [Int],
            $items: [MaintenanceServiceItemsInput],
            $subscribers: [AlertSubscriberInput]
          ) {
            data: ${payload.id ? 'updateMaintenanceService' : 'createMaintenanceService'} (
                ${payload.id ? 'id: $id,' : ''}
                title: $title, 
                notes: $notes,
                miles: $miles,
                milesBefore: $milesBefore,
                milesAfter: $milesAfter,
                days: $days,
                daysBefore: $daysBefore,
                daysAfter: $daysAfter,
                engineHours: $engineHours,
                engineHoursBefore: $engineHoursBefore,
                engineHoursAfter: $engineHoursAfter,
                recurrent: $recurrent,
                estimatedCost: $estimatedCost,
                fileIds: $fileIds,
                items: $items,
                subscribers: $subscribers
            ) {
                id
                title
                notes
                miles
                milesBefore
                milesAfter
                days
                daysBefore
                daysAfter
                engineHours
                engineHoursBefore
                engineHoursAfter
                recurrent
                estimatedCost
                fileIds
                serviceItems{
                    id
                    name
                    parts
                    labor
                    subtotal
                }
            }
        }`
        let response = await clientMutation(
            query,
            payload,
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.data;

    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceVSTcreateDeleteAlertSubscriber');
        return rejectWithValue(exc)
    }
});

export const maintenanceMCSCSearchMaintenanceServiceItems = createAsyncThunk("maintenance/createEditMaintenanceService/searchMaintenanceServiceItems", async ({ }, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientQuery(
            `query ($openSearch: String){
                data: searchMaintenanceServiceItems(openSearch: $openSearch){
                  id
                  name
                }
            }`,
            {
                openSearch: ''
            },
            endpoints.GRAPHQL_GENERAL
        );
        return response.data
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceMCSCSearchMaintenanceServiceItems');
        return rejectWithValue(exc);
    }
}
)


export const maintenanceMCSCCreateMaintenanceServiceItem = createAsyncThunk("maintenance/createEditMaintenanceService/createMaintenanceServiceItem", async ({ name }, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientMutation(
            `mutation ($name: String!) {
                data: createMaintenanceServiceItem(name: $name){
                    id
                    name
                }
            }`,
            {
                name
            },
            endpoints.GRAPHQL_GENERAL
        );
        return response.data

    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceMCSCCreateMaintenanceServiceItem');
        return rejectWithValue(exc);
    }
}
)

const maintenanceCreateEditMaintenanceServiceRedux = createSlice({
    name: 'maintenance/createEditMaintenanceService',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openModalCreateEditMaintenanceService(state, action) {
            state.open = true;
            state.id = action?.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        //maintenanceMCSGetMaintenanceService
        builder.addCase(maintenanceMCSGetMaintenanceService.pending, (state, action) => {
            state.loading = true;
            state.service = null;
        });
        builder.addCase(maintenanceMCSGetMaintenanceService.fulfilled, (state, action) => {
            state.loading = false;
            state.service = action.payload;
        });
        builder.addCase(maintenanceMCSGetMaintenanceService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //maintenanceMCSCreateEditMaintenanceService
        builder.addCase(maintenanceMCSCreateEditMaintenanceService.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(maintenanceMCSCreateEditMaintenanceService.fulfilled, (state, action) => {
            state.loading = false;
            state.updateMaintenanceServiceResult = action.payload;
        });
        builder.addCase(maintenanceMCSCreateEditMaintenanceService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });


        //maintenanceMCMSSearchMaintenanceServiceItems
        builder.addCase(maintenanceMCSCSearchMaintenanceServiceItems.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceMCSCSearchMaintenanceServiceItems.fulfilled, (state, action) => {
            state.serviceItems = action.payload
            state.loading = false;
        });

        builder.addCase(maintenanceMCSCSearchMaintenanceServiceItems.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(maintenanceMCSCCreateMaintenanceServiceItem.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceMCSCCreateMaintenanceServiceItem.fulfilled, (state, action) => {
            state.newServiceItemResult = action.payload
            state.loading = false;
        });

        builder.addCase(maintenanceMCSCCreateMaintenanceServiceItem.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = maintenanceCreateEditMaintenanceServiceRedux;

// Extract and export each action creator by name
export const {
    reset,
    openModalCreateEditMaintenanceService,
} = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;