export default {
    warnings_main_issues: "Issues",
    warnings_main_resolved: "Resolved",
    warnings_main_sd_card_status_is: "SD Card Status is",
    warnings_main_last_status: "Last Status",
    warnings_main_resolve_all: "Resolve All",
    warnings_main_clear_all: "Delete All",
    warnings_main_search_by_warning: "Search by Warning",
    warnings_main_select: "Select",
    warnings_main_clear: "Delete",
    warnings_main_revolve_issue: "Revolve Issue",
    warnings_main_first_occurrence: "First Occurrence",
    warnings_main_last_occurrence: "Last Occurrence",
    warnings_main_resolved_on: "Resolved On",
    warnings_main_resolved_by: "Resolved By",
    warnings_main_occurrences: "Occurrences",
    warnings_main_details: "Details",
    warnings_main_close: "Close",
    warnings_main_resolve_all_warnings: "Resolve all warnings",
    warnings_main_resolve_warning: "Resolve Warning",
    warnings_main_success_message_resolved_all: "The warnings have been resolved",
    warnings_main_success_message_resolved: "The warning has been resolved",
    warnings_main_error_message_resolved_all: "Warnings could not be resolved due to error",
    warnings_main_error_message_resolved: "Unable to resolve the warning due to an error",
    warnings_main_message_resolved_all: "Are you sure you want to resolve all warnings? This action cannot be undone.",
    warnings_main_message_resolved: "Are you sure you want to resolve the warning? This action cannot be undone.",
    warnings_main_resolve: "Resolve",
    warnings_main_delete_warnings: "Delete Warnings",
    warnings_main_delete_warning: "Delete Warning",
    warnings_main_success_message_delete_all: "Warnings have been deleted",
    warnings_main_success_message_delete: "The warning has been deleted",
    warnings_main_error_message_delete_all: "Warnings could not be deleted due to an error",
    warnings_main_error_message_delete: "Unable to delete the warning due to an error",
    warnings_main_message_delete_all: "Are you sure you want to delete all warnings? This action cannot be undone.",
    warnings_main_message_delete: "Are you sure you want to delete the warning? This action cannot be undone.",
    warnings_main_delete: "Delete",
    warnings_main_warning: "Warning",
    warnings_main_warning_time: "Warning Time",
    warnings_main_value: "Value",
    warnings_main_there_is_no_warnings: "There is no warnings",
    warnings_main_AuxCamDisconnected: "Aux Camera Disconnected",
    warnings_main_BatteryVoltageLow: "Battery Voltage Low",
    warnings_main_CameraDisconnectedFromServerOver7Days: "Camera Disconnected From Server Over 7 Days",
    warnings_main_CameraTilted: "Camera Tilted - Check Orientation",
    warnings_main_dmsStatusChange: "DMS Status Change",
    warnings_main_DrivingOver18Hours: "Driving Over 18 Hours",
    warnings_main_FailedToEnterStandby: "Failed To Enter Standby",
    warnings_main_FormatSDCard: "Format SD Card",
    warnings_main_GPSGapTooLarge: "GPS Gap Too Large",
    warnings_main_LowVBUSVoltage: "Low VBUS Voltage",
    warnings_main_MCUDisconnected: "MCU Disconnected",
    warnings_main_ReplaceSDCard: "Replace SD Card",
    warnings_main_SDCardStatus: "SD Card Status",
    warnings_main_SIMCardInfoChanged: "SIM Card Info Changed",
    warnings_main_UnfavorableAngleOfRoadView: "Unfavorable Angle Of Road View",
    warnings_main_VinNumChanged: "Vin Num Changed",
    warnings_main_UnsupportedVideoConfigCombinations: "Unsupported Video Config Combinations",
    warnings_main_HDOPQuality: "HDOP Quality",
    warnings_main_ExcessiveEvents: "Excessive Events",
    warnings_main_LowBattery: "Low Battery",
    warnings_main_IgnitionOnEventsAbnormal: "Ignition On Events Abnormal",
    warnings_main_PowerDisconnect: "Power Disconnect",
    warnings_main_LowSatellites: "Low Satellites",
    warnings_main_DailyOdometerExceeded: "Daily Odometer Exceeded",
    warnings_main_WirelessSignal: "Wireless Signal",
    warnings_main_MaintenanceLimitExceeded: "Maintenance Limit Exceeded",
    warnings_main_LateEvents: "Late Events",
    warnings_main_CommLost: "Comm Lost",
    warnings_main_DailyEngineHoursExceeded: "Daily Engine Hours Exceeded",

    warnings_main_description_comming_soon: "This warning was triggered due to an unexpected device behavior, please contact support to resolve it.",
    warnings_main_back_to_issues: "Back To Issues",
    warnings_main_offline: "Offline",
    warnings_main_revolve_selected: "Resolve Selected",
    warnings_main_message_resolved_selected: "Are you sure you want to resolve the selected warnings? This action cannot be undone.",
    warnings_main_resolve_warnings: "Resolve Warnings",
    warnings_main_device_support: "Device Support",
    warnings_main_delete_selected: "Delete Selected",
    warnings_main_message_deleted_selected: "Are you sure you want to delete the selected warnings? This action cannot be undone.",
    warnings_main_warnings: "Device Warnings",
    warnings_main_definition: "Definition",
    warnings_main_first_message: "This device has not reported in",
    warnings_main_second_message: "Please use the Troubleshooting option or Contact Support",
}