export default {
    "driversTasksTab_driver": "Conductor",
    "driversTasksTab_labelTasks": "Tareas",
    "driversTasksTab_previouslyCompletedTask": "Tarea Previamente Completada",
    "driversTasksTab_performed_at": "Realizado",
    "driversTasksTab_upcomingTask": "Proxima Tarea",
    "driversTasksTab_status": "Estado",
    "driversTasksTab_subscribers": "Suscriptores",
    "driversTasksTab_notes": "Notas",
    "driversTasksTab_notesUpperCase": "Notas",
    "driversTasksTab_attachments": "Adjuntos ",
    "driversTasksTab_cost": "Costo",
    "driversTasksTab_due": "Vencido",
    "driversTasksTab_description": "Descripción",
    "driversTasksTab_task": "Tarea",
    "driversTasksTab_edit": "Editar",
    "driversTasksTab_complete": "Completar",
    "driversTasksTab_delete": "Eliminar",
    "driversTasksTab_days": "Días",

    "driversTasksTab_Regarding": "Con respecto a",
    "driversTasksTab_exportFileFormat": "Formato del Archivo:",
    "driversTasksTab_exportDueIn": "Vencimiento En:",
    "driversTasksTab_exportUpcomingMaintenance": "Exportar Proximo Mantenimiento",
    "driversTasksTab_exportDays": "Días",
    "driversTasksTab_exportMiles": "Millas",
    "driversTasksTab_exportEngineHours": "Horas de Motor",
    "driversTasksTab_exportBtn": "EXPORTAR",
    "driversTasksTab_cancelBtn": "Cancelar",
    "driversTasksTab_Manage_tasks": "Administrar Tareas",
}