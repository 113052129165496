// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    MAP_LANDMARKS_BULK_UPDATE
} from "Redux/actionTypes";

// Actions
import {
    mapLandmarksBulkUpdateResponse,
    getKmls
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* mapLandmarksBulkRequest({ payload }) {
    try {
        const { landmarksBulk, landmarkIcon } = payload

        let mutations = [];
        let countMutations = 0
        
        landmarksBulk.forEach((item, index) => {
            const { tagId, entityName, entityIds, action } = item;
            mutations.push(`updateTagAssignment${countMutations}: updateTagAssignment(tagId: ${tagId}, entityName: ${entityName}, entityIds: [${entityIds}], action: ${action})`)
            countMutations = countMutations + 1;
        });

        if(landmarkIcon){
            const { landmarkIds, iconName, color } = landmarkIcon;
            if(landmarkIds){
                mutations.push(`updateTagAssignment${countMutations}: updateLandmarkBulkIcon(landmarkIds: [${landmarkIds}], ${iconName ? `iconName: "${iconName}", ` : ''}color: "${color}")`)
            }
        }
    
        let mutation = `mutation{${mutations.join("")}}`;
    
        const response = yield call(
            clientMutation,
            mutation,
            {},
            endpoints.GRAPHQL_GENERAL,
        );
        if (response && response.updateTagAssignment0) {
            yield put(mapLandmarksBulkUpdateResponse(response.updateTagAssignment0));
            yield put(getKmls());
        } else {
            throw 'Error while deleting user';
        }
        
    } catch (exc) {
        yield put(mapLandmarksBulkUpdateResponse({ error: true }));
        ExceptionManager(
            exc,
            "realtimeMaps/main/components/landmarks/components/landmarkBulk/redux/saga",
            "mapLandmarksBulkRequest"
        );
    }
}

function* watchMapLandmarksBulkMethods() {
    yield takeEvery(MAP_LANDMARKS_BULK_UPDATE, mapLandmarksBulkRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMapLandmarksBulkMethods)]);
}