import React, { useEffect, useState } from 'react'
import {Button} from 'Components';
import { useDispatch } from "react-redux";
import {
	setChangeSelectLanguage,
	getUserLanguage
} from "Redux/actions";

const ToggleLanguage = ({className}) => {
    const dispatch = useDispatch();
    const [selectLan, setSelectLan] = useState()

    const onChangeLan = () => {
		dispatch(setChangeSelectLanguage(selectLan.id))
		localStorage.setItem('lan', selectLan.id)
		switchChangeLan(selectLan.id)
		dispatch(getUserLanguage());
	}

	//use Effect configure language button label
	useEffect(() => {
		dispatch(getUserLanguage());
		const language = localStorage.getItem('lan')
		if(!language){
			localStorage.setItem('lan', 'en')
		}
		switchChangeLan(language)
	},[])

    //function use switch for language change
	const switchChangeLan = (lanState) => {
		switch(lanState){
			case 'es':
				return setSelectLan({id: 'en', label: 'English'})
			case 'en':
				return setSelectLan({id: 'es', label: 'Español'})
			default:
				return setSelectLan({id: 'en', label: 'English'})
		}
	}


  return (
    <Button
		type="button"
		className={`${className ? className : "btn-blue"} full-width" `}
		onClick={() => onChangeLan()}>
		{selectLan?.label}
	</Button>
  )
}

export default ToggleLanguage