//View Keys
export default {
  loginHistoryView_login: 'Logins',
	loginHistoryView_loginTime: 'Login Time',
  loginHistoryView_username: 'Username',
  loginHistoryView_ip: 'Hostname / IP Address',
  loginHistoryView_attempt: 'Attempt',
  loginHistory_successful: "Successful",
  loginHistory_failed: "Failed",
};
