/*********************************************************
 * Third Components
*********************************************************/
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';

/*********************************************************
 * Css
*********************************************************/
import './style.scss'

/*********************************************************
 * Get the Directions options into the map
*********************************************************/
const DirectionsMap = (props) => {

  const { print, id } = props
  const refDiv = useRef()

  const [showDirections, setShowDirections] = useState(false)
  
  /*********************************************************
   * Get the redux properties
  *********************************************************/
  const {
    directions
  } = useSelector((state) => state.realtimeMapRedux);

  /*********************************************************
   * Show or hide control
  *********************************************************/
  useEffect(()=> {
    if(directions){
      const { getDirections } = directions
      setShowDirections(getDirections)
    }
  },[directions])

  /*********************************************************
   * Clear the divs elements
  *********************************************************/
  useEffect(()=>{
    if(!showDirections){
        refDiv.current.innerHTML = '';
    }
  },[showDirections])

  const idPanel = print ? 'directionsPanelToPrint' : 'directionsPanel'

  return (
    <div 
        ref={refDiv}
        id={id || idPanel}
        className={showDirections ? 'showDirections' : 'hideDirections'}>
    </div>
  )

}

export default DirectionsMap
