//imports
import React, { useEffect } from "react";

import { Box, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
//sass
import "Core/views/login/login.scss";
//actions
import {
  forgotPassword,
  clearRedux,
  getResellerInfo,
} from "Redux/actions";

//components
import ToggleLanguage from "../../../components/ToggleLanguage";
import LinkToLogin from "./components/LinkToLogin";
import FormForgotPwd from "./components/FormForgotPwd";
import {  useDispatch, useSelector } from "react-redux";
import SalesForceApi from 'Core/salesForceApi';
const ForgotPasswordView = () => {

  const dispatch = useDispatch();
  const { current_reseller, reseller_was_validated } = useSelector(
    (state) => state.resellerRedux
  );
  let { reseller } = useParams();

  useEffect(() => {
    if (reseller) {
      dispatch(getResellerInfo("key", reseller));
    }
  }, [reseller]);

  useEffect(() => {
    return () => {
      dispatch(clearRedux("FORGOT_PASSWORD"));
    }
  }, []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className="all-space-container"
    >
      <Grid
        item
        xs={12}
        lg={5}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "end",
            margin: "20px 25px 0 0px",
          }}
        >
          <Box>
            <ToggleLanguage className="btn-white" />
          </Box>
        </Box>
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <FormForgotPwd
            forgotPassword={forgotPassword}
            current_reseller={current_reseller}
            reseller_was_validated={reseller_was_validated}
          />
        </div>

        <LinkToLogin current_reseller={current_reseller} />
      </Grid>
      <Grid item xs={12} lg className="all-space-container">
        <div
          className="login_bg"
          style={{
            backgroundImage: (!current_reseller?.loginBackgroundImage && current_reseller?.loginBackgroundColor) ? `none`
              : `url(${
                current_reseller?.loginBackgroundImage ||
                "/assets/images/loginbg-dark.png"
              })`,
            backgroundColor: current_reseller?.loginBackgroundImage ? "transparent": (current_reseller?.loginBackgroundColor || "transparent")
          }}
        />
      </Grid>
      {
        !current_reseller && reseller_was_validated &&
        <SalesForceApi />
      }
    </Grid>
  );
};

export default ForgotPasswordView;
