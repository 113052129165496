// Dependencies
import React, { useState, useEffect, useRef } from "react";
import { Avatar } from "../";
import { Badge } from "@mui/material";
import { IconButton } from "@gpstrackit/gtc-react-controls";
// Styles
import "./index.scss";

import { useIntl } from "react-intl";
/**
 * Build a component to allow the user select items from entities.
 * @param {string} title - Component title.
 * @param {Array} entities - List of entities to show.
 * @param {string} selectedEntitiesTitle - Selected items section title.
 * @param {Function} triggerCollapse - Callback to trigger when the collapse inside the ItemsList is used.
 */
const InputAvatar = ({
  value,
  onChange,
  name,
  required,
  label,
  deleteAvatar,
}) => {
  const fileUploadRef = useRef();
  const { messages } = useIntl();
  const [file, setFile] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    if (value?.url) {
      setFile(value);
    }
  }, [value]);

  return (
    <div className="inputAvatar d-block">
      <label className="input-normal-label">
        {label}
        {required && <span className="text-danger"> *</span>}
      </label>
      <div className="mb-3">
        <Badge
          badgeContent={
            <IconButton
              className="icon-trash-item"
              onClick={() => {
                setFile({});
                deleteAvatar();
              }}
              icon="delete"
            />
          }
          invisible={!value?.url && !file?.file}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Avatar
            name={name}
            value={file}
            width={100}
            onClick={() => {
              fileUploadRef?.current?.click();
            }}
          />
        </Badge>
      </div>
      <div className="custom-file d-none" style={{ display: "none" }}>
        <input
          ref={fileUploadRef}
          className="form-control"
          type="file"
          required={required}
          accept=".png, .jpg, .jpeg"
          onChange={(e) => {
            if (e?.target?.files[0]?.size > 2048000) {
              setError(`${messages['max']}: 2MB`);
            } else if(e?.target?.files?.length){
              setError('');
              var reader = new FileReader();
              reader.onload = function () {
                onChange({ file: e.target.files[0] });
              };

              setFile({ file: e.target.files[0] });
              reader.readAsDataURL(e.target.files[0]);
            }
          }}
        />
      </div>
      {error && (
          <small className="text-danger" style={{ paddingTop: 5 }}>
            {error}
          </small>
        )}
    </div>
  );
};

export default InputAvatar;
