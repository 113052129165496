//View Keys
export default {
	fleet_vehicle: 'Vehiculo',
	fleet_starter_vehicles: 'Unidades Seleccionadas',
	fleet_ScheduledGroupStarter: 'Grupo De Arranque Programador',
	//vehicleStatusModal_titleModalEnableDisable: 'Enable/Disable Starter',
	fleetModal_titleModalEnableDisable: 'Arrancador de Vehículos',
	vehicleStatusModal_confirmationModalEnableDisable:
		'¿Esta seguro de cambiar el estado de arranque de vehiculo?',
	vehicleStatusModal_currentStatus: 'Estado',
	fleet_disable: 'DESHABILITAR',
	fleet_enable: 'HABILITAR',
	fleet_starter_status: 'Estado',
	fleet_sentMessage: 'Enviar',
	fleet_sentMessageError: 'Error',
	disable: 'Deshabilitar',
	enable: 'Habilitar',
	fleet_starter_process_status: "Estado del Proceso",


	fleet_starter_not_supported: 'No Soportado',
	fleet_starter_enable: 'Habilitado',
	fleet_starter_pending_enable: 'Pendiente Para Habilitar',
	fleet_starter_disable: 'Deshabilitado',
	fleet_starter_pending_disable: 'Pendiente Para Deshabilitar',

	fleet_validation_starter_title:'Bien',
	fleet_validation_starter_message:'Estado Cambiado',
	fleet_starter_disable_confirmation: "¿Está seguro de que desea deshabilitar el arrancador para {number_units} unidades?",
	fleet_starter_enable_confirmation: "¿Está seguro de que desea habilitar el arrancador para {number_units} unidades?",
	fleetModal_units: "Unidades"
};
