export default {
  accountContacts_contacts: "Contactos",
  accountContacts_title: "Titulo",
  accountContacts_details: "Detalles",
  accountContacts_type: "Tipo",
  accountContacts_carrier: "Portador",
  accountContacts_address: "Email",
  accountContacts_email: "Correo",
  accountContacts_cellphone: "Celular",
  accountContacts_newContact: "Nuevo Contacto",
  accountContacts_editContact: "Editar Contacto",
  accountContacts_deleteContact: "Eliminar Contacto",
  accountContacts_cancel: "Cancelar",
  accountContacts_add: "Guardar",
  accountContacts_edit: "Guardar",
  accountContacts_delete: "Eliminar",
  accountContacts_messageDelete: "¿Está seguro de que desea eliminar el contacto",
  accountContacts_maxLengthMessageCellPhone: "El teléfono celular debe contener un máximo de caracteres:",
  accountContacts_minLengthMessageCellPhone: "El teléfono celular debe contener un mínimo de caracteres:",
  accountContacts_maxLengthMessagetitle: "El titulo debe contener un máximo de caracteres:",
  accountContacts_emailMessage: "El correo es incorrecto",
  accountContacts_createContact: "Contacto Creado",
  accountContacts_updateContact: "Contacto Actualizado",
  accountContacts_deleteContact: "Contacto Eliminado",
  accountContacts_select: "Seleccionar",
};
