import {
    NEW_ENTITY_TAG_PICKER_GET_TAGS,
    NEW_ENTITY_TAG_PICKER_GET_TAGS_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    tags: [],
    tree: [],
    loadingTags: false,
    error: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case NEW_ENTITY_TAG_PICKER_GET_TAGS:
            return {
                ...state,
                loadingTags: true,
                tags: [],
                tree: [],
                error: false
            };

        case NEW_ENTITY_TAG_PICKER_GET_TAGS_RESPONSE:
            return {
                ...state,
                tags: action?.payload?.tags,
                tree: action?.payload?.tree,
                loadingTags: false,
                error: action?.payload?.error,
            };

        case CLEAR_REDUX:
            return ["", "NEW_ENTITY_TAG_PICKER"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state }

        default:
            return { ...state };
    }
}