import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    loading: false,
    open: false,
    error: null,
    filters: {
        routeName: "",
        drivers: [],
        dateStart: null,
        dateEnd: null,
        timeFrameOptionSelected: null,
        noDriver: false
    }
};

const route = "settings/views/routesTracker/components/modalFilters";


export const RoutesTrackerModalFilterRedux = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalRoutesTrackerFilter: (state, action) => {
            return {
                ...state,
                open: action.payload?.open
            }
        },
        setRoutesTrackerFilters: (state, action) => {
            return {
                ...state,
                filters: {
                    routeName: action.payload?.routeName,
                    drivers: action.payload?.drivers,
                    dateStart: action.payload?.dateStart,
                    dateEnd: action.payload?.dateEnd,
                    timeFrameOptionSelected: action.payload?.timeFrameOptionSelected,
                    noDriver: action.payload?.noDriver
                }
            }
        },
        clearFiltersRoutesTracker: (state, action) => {
            return {
                ...state,
                filters: {
                    routeName: "",
                    drivers: [],
                    dateStart: null,
                    dateEnd: null,
                    noDriver: false
                }
            }
        }

    }
    
});


const { actions, reducer } = RoutesTrackerModalFilterRedux;

export const { reset, openModalRoutesTrackerFilter, setRoutesTrackerFilters, clearFiltersRoutesTracker } = actions;

export default reducer;