import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';
// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

import {
    baseReportConditions,
    generateReportInstance,
    getCategoryFilter,
    getTimeZone,
    handleAsyncReport,
    isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

function* getDetailedReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
    const {timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath} = report;
    const {filterType, filterArray, startRange, endRange, unitsSelected, accumulateData = true, dates} = report.filters

    const baseConditionsQuery = baseReportConditions({
        report,
        name,
        dataResultPath,
        timeZone,
        delayed,
        unitsSelected,
        timeframeName,
        filterType,
        filterArray,
        categoryName,
        startRange,
        endRange,
        dates
    })

    try {
        let payload = {
            conditions: {
                ...baseConditionsQuery,
                sortField: {
                    field: sortField || "deviceId",
                    order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                }
            },
            limit: rowsPerPage,
            offset: page
        };
        let addColumnDriver = [];
        let addColumnAfterEvent = [];
        let addColumnDriverUnit = {
            column: "DriverUnit",
            header: getCategoryFilter(filterType) === "deviceId" ? "Driver" : "Unit"
        }
        ;
        if(report?.exportType === 'csv') {
            addColumnDriver.push({
                column: "idLabel",
                header: getCategoryFilter(filterType) === "deviceId" ? "Label" : "Driver"
            })
            addColumnAfterEvent = [
                {
                    "column": "timeDelta",
                    "header": "Duration"
                },
                {
                    "column": "distance",
                    "header": "Distance"
                }
            ]
        } else if (report?.exportType === 'pdf') {
            addColumnAfterEvent = [
                {
                    "column": "duration",
                    "header": "Duration"
                }
            ]
        }

        let columns = [
            ...addColumnDriver,
            {
                column: "unitTime",
                header: `${report?.exportType === "csv" ? `Time/Date (${getAbbvTimezone(timeZone)})` : `Time/Date`}`
            },
            {
                column: "address",
                header: "Address"
            },
            {
                column: "speed",
                header: "Speed"
            },
            {
                column: "speedLimit",
                header: "Posted"
            },
            {
                column: "direction",
                header: report?.exportType === 'pdf' ? "Dir": "Direction"
            }
            ,
            {
                column: "eventName",
                header: "Event"
            },
            ...addColumnAfterEvent,
            {
                column: "signal",
                header: "Signal"
            },
            {
                column: "odometer",
                header: "Odometer"
            },
            {
                column: "longitude",
                header: "Longitude"
            },
            {
                column: "latitude",
                header: "Latitude"
            }
        ]

        if(report?.exportType === 'pdf') {
            columns.splice(0, 0, addColumnDriverUnit);
        }

        if(report?.exportType === 'csv') {
            columns.splice(4, 0, addColumnDriverUnit);
        }
        payload = {
            ...payload,
            limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
            offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
            conditions: {
                ...payload.conditions,
                exportOptions: {
                    sendByEmail,
                    emailsTo,
                    format: {
                        fileFormat: report?.exportType || "json",
                        formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
                        durationFormat: report?.durationFormat
                    },
                    culture: report.lang,
                    timeZone: getTimeZone(timeZone),
                    folderName: "px-customer-reports",
                    filePrefixName: "DetailedReport",
                    columns
                }
            }
        }
        let data = yield call(
            clientQuery,
            `query getDetailedReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getDetailedReport (conditions: $conditions, limit: $limit, offset: $offset) {
                ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
                    `reportInstanceId`
                    : `
                    items {
                        recordInfo {
                            entityId
                            entityLabel
                            tagId
                            tagLabel
                            startDate
                            endDate
                            recordType
                            groupLabel
                            groupIndex
                            groupTotal
                            groupTagColor
                        }
                        recordDetail {
                            deviceId
                            unitId
                            unitType
                            unitTime
                            eventName
                            driverId
                            driverName
                            address
                            speed
                            speedLimit
                            direction
                            signal
                            odometer
                            distance
                            timeDelta
                            deviceLabel
                            landmarkId
                            landmarkName
                            latitude
                            longitude
                            temperature
                            isKPH
                            noData
                            useEcuSpeed
                            useEcuOdometer
                        }
                    }
                    total
                    limit
                    offset
                    label
                `
                }
            }
        }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
    yield handleAsyncReport(report, data, accumulateData);
    } catch (exc) {
        ExceptionManager(exc, 'reports/detailedReport', 'getDetailedReport');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}


export default getDetailedReport;
