import {
  SHARED_MEDIA_OPEN_MODAL,
  SHARED_MEDIA_SHARE_MEDIA,
  SHARED_MEDIA_SHARE_MEDIA_RESPONSE,
  SHARED_MEDIA_GET_SHARED_MEDIA,
  SHARED_MEDIA_GET_SHARED_MEDIA_RESPONSE,
  CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
  open: false,
  items: null,
  loadingSharedMedia: false,
  sharedMediaResponse: null,
  loadingGetSharedMedia: false,
  getSharedMediaResponse: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHARED_MEDIA_OPEN_MODAL:
      return { ...state, open: action.payload?.data?.open, items: action.payload?.data?.items, sharedMediaResponse: null, loadingSharedMedia: false };

    case SHARED_MEDIA_SHARE_MEDIA:
      return { ...state, sharedMediaResponse: null, loadingSharedMedia: true };

    case SHARED_MEDIA_SHARE_MEDIA_RESPONSE:
      return { ...state, sharedMediaResponse: action.payload.data, loadingSharedMedia: false };

    case SHARED_MEDIA_GET_SHARED_MEDIA:
      return { ...state, getSharedMediaResponse: null, loadingGetSharedMedia: true };

    case SHARED_MEDIA_GET_SHARED_MEDIA_RESPONSE:
      return { ...state, getSharedMediaResponse: action.payload.data, loadingGetSharedMedia: false };

    case CLEAR_REDUX:
      return ["", "SHARED_MEDIA"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
}