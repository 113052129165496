//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
	ALERTS_UPDATE_SYSTEM_ALERT,
	ALERTS_CREATE_SYSTEM_ALERT
} from 'Redux/actionTypes';

//actions 
import {
	AlertsUpdateSystemAlertResult,
	AlertsCreateSystemAlertResult
} from 'Redux/actions';

const route = 'modules/alerts/components/wizardAlert/redux/saga'

/* FUNCTIONS ASYNC */
function* updateSystemAlertsRequest() {
	yield takeEvery(ALERTS_UPDATE_SYSTEM_ALERT, function* ({ payload }) {
			try {
				const {
					id,
					name,
					type,
					config,
					severity,
					summary,
					triggers,
					assignedTo,
					schedule,
					subscribers,
				} = payload;
		
				let data = yield call(
					clientMutation,
					`mutation updateSystemAlert ($id: String!, $name: String, $type: enumAlertType, $config: AWSJSON, $severity: Severity!, $summary: String, $triggers: [SystemAlertTriggerInput], $assignedTo: [SystemAlertAssignmentInput], $schedule: SystemAlertScheduleInput, $subscribers: [AlertSubscriberInput]) {
						updateSystemAlert (id: $id, name: $name, type: $type, config: $config, severity: $severity, summary: $summary, triggers: $triggers, assignedTo: $assignedTo, schedule: $schedule, subscribers: $subscribers) {
							id
						}
					}`,
					{
						id, name, type, config, severity, summary, triggers, assignedTo, schedule, subscribers
					},
					endpoints.GRAPHQL_GENERAL,
				);
				if (data) {
					yield put(AlertsUpdateSystemAlertResult(data));
				} else {
					yield put(AlertsUpdateSystemAlertResult(null));
				}
			} catch (exc) {
				// ALWAYS RETURN ACTION
				ExceptionManager(
					exc,
					'modules/alerts/components/newAlert/redux/saga',
					'updateSystemAlerts',
				);
				yield put(AlertsUpdateSystemAlertResult(null))
			}
	});
}

function* createSystemAlertsRequest() {
	yield takeEvery(ALERTS_CREATE_SYSTEM_ALERT, function* ({ payload }) {
		try {
			const {
				name,
				type,
				config,
				severity,
				summary,
				triggers,
				assignedTo,
				schedule,
				subscribers,
			} = payload;
	
			let data = yield call(
				clientMutation,
				`mutation createSystemAlert ($name: String!, $type: enumAlertType!, $config: AWSJSON, $severity: Severity!, $summary: String, $triggers: [SystemAlertTriggerInput], $assignedTo: [SystemAlertAssignmentInput], $schedule: SystemAlertScheduleInput, $subscribers: [AlertSubscriberInput]) {
					createSystemAlert (name: $name, type: $type, config: $config, severity: $severity, summary: $summary, triggers: $triggers, assignedTo: $assignedTo, schedule: $schedule, subscribers: $subscribers) {
						id
					}
				}`,
				{
					name, type, config, severity, summary, triggers, assignedTo, schedule, subscribers
				},
				endpoints.GRAPHQL_GENERAL,
			);
	
			if (data) {
				yield put(AlertsCreateSystemAlertResult(data));
			} else {
				yield put(AlertsCreateSystemAlertResult(null));
			}
		} catch (exc) {
			// ALWAYS RETURN ACTION
			ExceptionManager(
				exc,
				'modules/alerts/components/newAlert/redux/saga',
				'createNewSystemAlert',
			);
			yield put(AlertsCreateSystemAlertResult(null))
		}
	});
}

//MERGE SAGA
export default function* rootSaga() {
	yield all([fork(updateSystemAlertsRequest)]);
	yield all([fork(createSystemAlertsRequest)]);
}
