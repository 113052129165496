import {
    SETTINGS_CREWS_SEARCH,
    SETTINGS_CREWS_SEARCH_RESPONSE,
    SETTINGS_CREWS_OPEN_CREATE_EDIT_MODAL,
    SETTINGS_CREWS_GET_CREW,
    SETTINGS_CREWS_GET_CREW_RESPONSE,
    SETTINGS_CREWS_CREATE_EDIT_CREW,
    SETTINGS_CREWS_CREATE_EDIT_CREW_RESPONSE,
    SETTINGS_CREWS_OPEN_DELETE_MODAL,
    SETTINGS_CREWS_DELETE_CREWS,
    SETTINGS_CREWS_DELETE_CREWS_RESPONSE,
    SETTINGS_CREWS_BULK_MODAL,
    SETTINGS_CREWS_BULK_UPDATE,
    SETTINGS_CREWS_BULK_UPDATE_RESPONSE,
    SETTINGS_CREWS_SEARCH_AVAILABLE_ONLY,
    SETTINGS_CREWS_SEARCH_UNIT_ID,
    SETTINGS_CREWS_ASSIGN_EDIT_VEHICLE,
    SETTINGS_CREWS_SEARCH_UNIT_CREWS,
    SETTINGS_CREWS_SEARCH_UNIT_CREWS_RESPONSE,
    SETTINGS_CREWS_SEARCH_UNIT_ID_RESPONSE
} from "Redux/actionTypes";

export const searchSettingsCrews = (data) => {
    return {
        type: SETTINGS_CREWS_SEARCH,
        payload: data,
    };
};

export const searchSettingsCrewsResponse = (data) => {
    return {
        type: SETTINGS_CREWS_SEARCH_RESPONSE,
        payload: data
    };
};

export const openSettingsCrewsCreateEditModal = (open, id) => {
    return {
        type: SETTINGS_CREWS_OPEN_CREATE_EDIT_MODAL,
        payload: { open, id }
    };
};

export const getSettingsCrew = (id) => {
    return {
        type: SETTINGS_CREWS_GET_CREW,
        payload: { id }
    };
};

export const getSettingsCrewResponse = (data) => {
    return {
        type: SETTINGS_CREWS_GET_CREW_RESPONSE,
        payload: { data }
    };
};

export const createEditSettingsCrew = (crew) => {
    return {
        type: SETTINGS_CREWS_CREATE_EDIT_CREW,
        payload: { crew }
    };
}

export const createEditSettingsCrewResponse = (data) => {
    return {
        type: SETTINGS_CREWS_CREATE_EDIT_CREW_RESPONSE,
        payload: { data }
    };
};

export const openSettingsCrewsDeleteModal = (open, items = []) => {
    return {
        type: SETTINGS_CREWS_OPEN_DELETE_MODAL,
        payload: { open, items }
    };
};

export const deleteSettingsCrews = (items = []) => {
    return {
        type: SETTINGS_CREWS_DELETE_CREWS,
        payload: { items }
    };
};

export const deleteSettingsCrewsResponse = (data) => {
    return {
        type: SETTINGS_CREWS_DELETE_CREWS_RESPONSE,
        payload: { data }
    };
};

export const openBulkCrewsModal = (open, unit) => {
    return {
        type: SETTINGS_CREWS_BULK_MODAL,
        payload: { open, unit }
    };
};

export const updateBulkCrewUpdate = (data) => {
    return {
        type: SETTINGS_CREWS_BULK_UPDATE,
        payload: data
    };
};

export const updateBulkCrewUpdateResponse = (data) => {
    return {
        type: SETTINGS_CREWS_BULK_UPDATE_RESPONSE,
        payload: data
    };
};

export const searchSettingsCrewsAvailableOnly = (data) => {
    return {
        type: SETTINGS_CREWS_SEARCH_AVAILABLE_ONLY,
        payload: data,
    };
};

export const searchSettingsCrewsByUnitId = (data) => {
    return {
        type: SETTINGS_CREWS_SEARCH_UNIT_ID,
        payload: data,
    };
};

export const searchSettingsCrewsByUnitIdResponse = (data) => {
    return {
        type: SETTINGS_CREWS_SEARCH_UNIT_ID_RESPONSE,
        payload: data,
    };
};

export const openAssignEditModal = (open, id) => {
    return {
        type: SETTINGS_CREWS_ASSIGN_EDIT_VEHICLE,
        payload: { open, id }
    };
}

export const searchUnitCrews = (data) => {
    return {
        type: SETTINGS_CREWS_SEARCH_UNIT_CREWS,
        payload: data,
    };
}

export const searchUnitCrewsResponse = (data) => {
    return {
        type: SETTINGS_CREWS_SEARCH_UNIT_CREWS_RESPONSE,
        payload: data,
    };
}