import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../core/logManager';

import { resetALL, catalogLoad } from '../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    openModal: false,
    loading: false,
    channel: null,
    items: [],
    nextToken: null,
    error: null
};

const route = 'modules/chat/modals/chatMessageList/redux/slice'

export const chatMessageListOpen = createAsyncThunk('chat/messageList/open', async ({ chatChannelId, authId }, { rejectWithValue, dispatch }) => {
    try {
        const result = await clientQuery(`
            query getChatChannel($id: String!) {
                data: getChatChannel (id: $id) {
                    id
                    isGroup
                    name
                    users
                    avatar
                }
            }`,
            {
                id: chatChannelId
            },
            endpoints.GRAPHQL_GENERAL_V2
        )
        if (result?.data)
            dispatch(searchMessagesChat({ chatChannelId: chatChannelId, authId, limit: 10, nextToken: null }))

        return result?.data;
    } catch (exc) {
        ExceptionManager(exc, route, 'chatMessageList');
        return rejectWithValue(exc)
    }
});

export const searchMessagesChat = createAsyncThunk('chat/messageList/search', async ({ chatChannelId, authId, limit, offset, variableQuery }, { rejectWithValue, dispatch }) => {
    try {

        const result = await clientQuery(`
            query searchChatMessage ($chatChannelId: String!, $limit: Int, $nextToken: String) {
                data: searchChatMessage (chatChannelId: $chatChannelId, limit: $limit, nextToken: $nextToken) {
                    items {
                        id
                        chatChannelId
                        transmitterEntityNameId
                        receiverEntityNameId
                        message
                        status
                        createdOn
                        sendedOn
                        receivedOn
                        readOn
                        updatedOn
                    }
                    nextToken
                }
            }`,
            {
                chatChannelId,
                limit,
                offset,
                variableQuery
            },
            endpoints.GRAPHQL_GENERAL_V2
        )

        return result?.data || { items: [], nextToken: null };
    } catch (exc) {
        ExceptionManager(exc, route, 'chatListContactsOpen');
        return rejectWithValue(exc)
    }
});

export const chatMessageSend = createAsyncThunk('chat/messageList/send', async ({ chatChannelId, transmitterEntityNameId, receiverEntityNameId, message }, { rejectWithValue, dispatch }) => {
    try {
        const result = await clientMutation(`
            mutation createChatMessage ($chatChannelId: String!, $transmitterEntityNameId: String!, $receiverEntityNameId: String!, $message: String!) {
                data: createChatMessage (chatChannelId: $chatChannelId, transmitterEntityNameId: $transmitterEntityNameId, receiverEntityNameId: $receiverEntityNameId, message: $message) {
                    id
                    chatChannelId
                    transmitterEntityNameId
                    receiverEntityNameId
                    message
                    status
                    createdOn
                    sendedOn
                    receivedOn
                    readOn
                    updatedOn
                }
            }`,
            {
                chatChannelId,
                transmitterEntityNameId,
                receiverEntityNameId,
                message
            },
            endpoints.GRAPHQL_GENERAL_V2
        )
        return result?.data;
    } catch (exc) {
        ExceptionManager(exc, route, 'chatMessageList');
        return rejectWithValue(exc)
    }
});

export const chatMessageUpdateStatus = createAsyncThunk('chat/messageList/updateStatus', async ({ ids, status }, { rejectWithValue, dispatch }) => {
    try {
        const result = await clientMutation(`
            mutation updateChatMessageStatus($ids: [String]!, $status: enumChatMessageStatus!){
                data: updateChatMessageStatus(ids: $ids, status: $status)
            }
            `,
            {
                ids,
                status
            },
            endpoints.GRAPHQL_GENERAL_V2
        )
        return result?.data;
    } catch (exc) {
        ExceptionManager(exc, route, 'chatMessageList');
        return rejectWithValue(exc)
    }
});

export const chatUserStatusWriting = createAsyncThunk('chat/messageList/writing', async ({ channelId, userId }, { rejectWithValue, dispatch }) => {
    try {
        const result = await clientMutation(`
            mutation statusChatUserChannel($channelId: String!, $userId: String!, $status: enumChatUserStatus!){
                statusChatUserChannel(
                    channelId: $channelId
                    userId: $userId
                    status: $status
                )
            }
            `,
            {
                channelId,
                userId,
                status: "Writing"
            },
            endpoints.GRAPHQL_GENERAL_V2
        )
        return null;
    } catch (exc) {
        ExceptionManager(exc, route, 'chatMessageList');
        return rejectWithValue(exc)
    }
});

//Slice
const chatMessageListRedux = createSlice({
    name: 'chat/messageList',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        chatMessageUpdateSNS(state, action) {
            if (state.openModal) {
                let items = _.clone(state.items)
                const index = items.findIndex(x => x.id == action.payload.id)
                if (index > -1)
                    items[index] = action.payload
                else
                    items = [action.payload, ...items]

                console.log("chatMessageUpdateSNS", index, action.payload)
                return { ...state, open: true, items: items }
            }
            else
                return state
        },
        close(state, action) {
            state.openModal = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        builder.addCase(chatMessageListOpen.pending, (state, action) => {
            state.loading = true
            state.items = []
            state.nextToken = null
        });
        builder.addCase(chatMessageListOpen.fulfilled, (state, action) => {
            state.loading = false;
            state.openModal = true;
            state.channel = action.payload;
        });
        builder.addCase(chatMessageListOpen.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(searchMessagesChat.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(searchMessagesChat.fulfilled, (state, action) => {
            return { ...state, loading: false, ...action.payload }
        });
        builder.addCase(searchMessagesChat.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(chatMessageSend.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(chatMessageSend.fulfilled, (state, action) => {
            return { ...state, loading: false, items: [action.payload, ...state.items] }
        });
        builder.addCase(chatMessageSend.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(chatMessageUpdateStatus.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(chatMessageUpdateStatus.fulfilled, (state, action) => {
            state.loading = false;
            //state.items.push(action.payload)
        });
        builder.addCase(chatMessageUpdateStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // builder.addCase(chatUserStatusWriting.pending, (state, action) => {
        //     //state.loading = true
        // });
        // builder.addCase(chatUserStatusWriting.fulfilled, (state, action) => {
        //     // state.loading = false;
        //     //state.items.push(action.payload)
        // });
        // builder.addCase(chatUserStatusWriting.rejected, (state, action) => {
        //     // state.loading = false;
        //     // state.error = action.payload;
        // });

    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = chatMessageListRedux;

// Extract and export each action creator by name
export const { reset, close, chatMessageUpdateSNS } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;