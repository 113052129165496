export const SETTINGS_DRIVER_SEARCH = "SETTINGS_DRIVER_SEARCH";
export const SETTINGS_DRIVER_SEARCH_RESPONSE = "SETTINGS_DRIVER_SEARCH_RESPONSE";
export const SETTINGS_DRIVER_GET = "SETTINGS_DRIVER_GET";
export const SETTINGS_DRIVER_GET_RESPONSE = "SETTINGS_DRIVER_GET_RESPONSE";
export const SETTINGS_DRIVER_CREATE = "SETTINGS_DRIVER_CREATE";
export const SETTINGS_DRIVER_CREATE_RESPONSE = "SETTINGS_DRIVER_CREATE_RESPONSE";
export const SETTINGS_DRIVER_UPDATE = "SETTINGS_DRIVER_UPDATE";
export const SETTINGS_DRIVER_UPDATE_RESPONSE = "SETTINGS_DRIVER_UPDATE_RESPONSE";
export const SETTINGS_DRIVER_DELETE = "SETTINGS_DRIVER_DELETE";
export const SETTINGS_DRIVER_DELETE_RESPONSE = "SETTINGS_DRIVER_DELETE_RESPONSE";
export const SETTINGS_DRIVER_OPEN_CREATE_EDIT_MODAL = "SETTINGS_DRIVER_OPEN_CREATE_EDIT_MODAL";
export const SETTINGS_DRIVER_OPEN_DELETE_MODAL = "SETTINGS_DRIVER_OPEN_DELETE_MODAL";
export const SETTINGS_DRIVER_OPEN_CHANGE_STATUS_MODAL = "SETTINGS_DRIVER_OPEN_CHANGE_STATUS_MODAL";
export const SETTINGS_DRIVER_OPEN_DRIVER_IMPORT_MODAL = "SETTINGS_DRIVER_OPEN_DRIVER_IMPORT_MODAL";
export const SETTINGS_DRIVER_OPEN_DRIVER_TRANSACTION_MODAL = "SETTINGS_DRIVER_OPEN_DRIVER_TRANSACTION_MODAL";
export const SETTINGS_DRIVER_LOAD_CSV = "SETTINGS_DRIVER_LOAD_CSV";
export const SETTINGS_DRIVER_LOAD_CSV_RESPONSE = "SETTINGS_DRIVER_LOAD_CSV_RESPONSE";
export const SETTINGS_DRIVER_IMPORT = "SETTINGS_DRIVER_IMPORT";
export const SETTINGS_DRIVER_IMPORT_RESPONSE = "SETTINGS_DRIVER_IMPORT_RESPONSE";
export const SETTINGS_DRIVER_SEARCH_TRANSACTIONS = "SETTINGS_DRIVER_SEARCH_TRANSACTIONS";
export const SETTINGS_DRIVER_SEARCH_TRANSACTIONS_RESPONSE = "SETTINGS_DRIVER_SEARCH_TRANSACTIONS_RESPONSE";
export const SETTINGS_DRIVER_GET_URL_FILE = "SETTINGS_DRIVER_GET_URL_FILE";
export const SETTINGS_DRIVER_GET_URL_FILE_RESPONSE = "SETTINGS_DRIVER_GET_URL_FILE_RESPONSE";

export const SETTINGS_DRIVER_OPEN_CLOSE_FILTERS = "SETTINGS_DRIVER_OPEN_CLOSE_FILTERS";

export const SETTINGS_DRIVER_SEARCH_TAGS = "SETTINGS_DRIVER_SEARCH_TAGS";
export const SETTINGS_DRIVER_SEARCH_TAGS_RESPONSE = "SETTINGS_DRIVER_SEARCH_TAGS_RESPONSE";

export const SETTINGS_DRIVER_SET_ENTITY = "SETTINGS_DRIVER_SET_ENTITY";

export const SETTINGS_DRIVER_DELETE_DRIVERS_TAG = "SETTINGS_DRIVER_DELETE_DRIVERS_TAG";
export const SETTINGS_DRIVER_CHANGE_STATUS = "SETTINGS_DRIVER_CHANGE_STATUS";
export const SETTINGS_DRIVER_DELETE_DRIVERS_TAG_RESPONSE = "SETTINGS_DRIVER_DELETE_DRIVERS_TAG_RESPONSE";
export const SETTINGS_DRIVER_CHANGE_STATUS_RESPONSE = "SETTINGS_DRIVER_CHANGE_STATUS_RESPONSE";
export const SETTINGS_DRIVER_OPEN_DELETE_DRIVERS_TAG_MODAL = "SETTINGS_DRIVER_OPEN_DELETE_DRIVERS_TAG_MODAL";
export const SETTINGS_DRIVER_AVATAR = "SETTINGS_DRIVER_AVATAR";
export const SETTINGS_DRIVER_AVATAR_RESPONSE = "SETTINGS_DRIVER_AVATAR_RESPONSE";
