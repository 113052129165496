/********************************************************
 * Third Controls
 ********************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
/********************************************************
 * Controls
 ********************************************************/
import Pagination from 'Components/TableView/Pagination'
import PlaybackBar from './PlaybackBar'
import MapObject from 'Components/Map/providers/mapObject'
import { TypeObjectMap, TypeLayer } from 'Components/Map/providers/enums'
import { getEventColor } from 'Components/Map/utils'

/********************************************************
 * Styles
 ********************************************************/
 import './style.scss' 

/********************************************************
 * Actions
 ********************************************************/
 import {
  setVehicleTrailsPlayback,
  getVehicleTrails,
  savePreference
 } from "Redux/actions";

 /********************************************************
 * Global var to timer
 ********************************************************/
 let timerCountTrail = null
 var index = 0
 let polyInstance = null

 /*********************************************************
 * Author:  Walter Paz Londoño
 * Date:    24 Abr 2021
 * Module:  Realtime Maps
 * 
 * Map pagination into the Realtime maps asociate to 
 * vehice trails
 ********************************************************/
const MapPagination = (props) => {
  const { mapRef, preferences } = props;
  const { messages } = useIntl();
  const dispatch = useDispatch();

  const keyPreferences = "mapSettings";

  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [page, setPage] = useState(1)
  const [rowsCount, setRowsCount] = useState(0)
  const [play, setPlay] = useState(false)
  
  /********************************************************
  * Selectors to get data from redux
  ********************************************************/
  const playback = useSelector((state) => state.realtimeMapUtilitiesvehiclesTrailRedux.playback);
  const vehicleTrails = useSelector((state) => state.realtimeMapUtilitiesvehiclesTrailRedux.vehicleTrails);
  const loadVehicleTrails = useSelector((state) => state.realtimeMapUtilitiesvehiclesTrailRedux.loadVehicleTrails);
  const authenticatedId = useSelector(state => state.securityRedux.authenticatedId);
  const queryPagination = useSelector(state => state.realtimeMapUtilitiesvehiclesTrailRedux.queryPagination);
  const paramsVehicleTrails = useSelector(state => state.realtimeMapUtilitiesvehiclesTrailRedux.paramsVehicleTrails);
  
  useEffect(()=>{
    return () => {
        stopPlayBack();
    }
  },[])

  /********************************************************
  * Stop the playback elements
  ********************************************************/
  useEffect(()=> {
    if(!playback){
      restartPlaybackValues()
    }
  },[playback])
  

  /********************************************************
  * Get the total of rows after the new query
  ********************************************************/
  useEffect(() =>{
    if(paramsVehicleTrails){
      const { total } = paramsVehicleTrails
      setRowsCount(total)
    }
  }, [paramsVehicleTrails])

  /********************************************************
  * Restart values after the new query
  ********************************************************/
  useEffect(() => {
    if(!queryPagination){
      restartValues()
    }
  }, [queryPagination])

  /********************************************************
  * update the preferences elements into the route
  ********************************************************/
  useEffect(()=> {
    if(preferences){
      const { trailRowsPerPage, trailConnectingLine, trailShowWaypointNumbers } = preferences
      mapRef.current.showMapObjectByLayer(TypeObjectMap.Polyline, TypeLayer.VechileTrails, trailConnectingLine == undefined ? true : trailConnectingLine || false)
      if(trailShowWaypointNumbers){
        mapRef.current.addLabelMarkers(TypeLayer.VechileTrails, true)
      }else{
        mapRef.current.removeMapObjects(TypeObjectMap.Label);
      }

      if (trailRowsPerPage && trailRowsPerPage != rowsPerPage) {
        setRowsPerPage(trailRowsPerPage);
      }
    }
  },[preferences])

  /********************************************************
  * funtion to restart values
  ********************************************************/
  const restartValues = () => {
    setRowsPerPage(100)
    setPage(1)
    dispatch(setVehicleTrailsPlayback(false))
    polyInstance = null
    index = 99
  }

  /*********************************************************
  * Change the rows per pager
  *********************************************************/
  const onChangeRowsPerPage = (paramRowsPerPage) => {
    setRowsPerPage(paramRowsPerPage)
    setPage(1)
    const paramToGetVehicleTrails = {
      ...paramsVehicleTrails,
      limit: paramRowsPerPage, 
      offset: 1
    }

    //Save row per page in user preferences
    const newPreferences = { ...preferences || {}, trailRowsPerPage: paramRowsPerPage };
    dispatch(savePreference("User", authenticatedId, keyPreferences, newPreferences));

    //Stop elements in playback
    restartPlaybackValues()

    dispatch(getVehicleTrails(paramToGetVehicleTrails, false));
  }

  /*********************************************************
  * Change the page at the control
  *********************************************************/
  const onChangePage = (event, numberPage) => {
    setPage(numberPage)
    const paramToGetVehicleTrails = {
      ...paramsVehicleTrails,
      limit: rowsPerPage,
      offset: numberPage, 
    }
    dispatch(getVehicleTrails(paramToGetVehicleTrails, false));

    //Stop elements in playback
    restartPlaybackValues()
  }

  /*********************************************************
  * Restart the elements in playback items
  *********************************************************/
  const restartPlaybackValues = () => {
    dispatch(setVehicleTrailsPlayback(false))

    //stablish index value
    if(rowsCount > (rowsPerPage * page)){
      index = rowsPerPage - 1
    }else{
      index = rowsCount - (rowsPerPage * (page - 1)) - 1
    }

    polyInstance = null
    stopPlayBack()
  }

  /*******************************************************
   * Validate eventName to get color from the unit
   * @param {Object JSON} item 
   *******************************************************/
   const validateEventColor = (item) => {
    let eventName = '';
    if (item && item['eventName']) {
      eventName = item['eventName'].toString();
    }

    if (item && item['event'] && eventName == '') {
      eventName = item['event'].toString();
    }

    return getEventColor(eventName);
  }

  /*******************************************************
   * Return the lat lng value from the data coming object
   * @param {JSON} item 
   *******************************************************/
   const validateLatLng = (item, valNumeric = false) => {
    let lat = '', lng = '';
    if (item['latitude']) {
      lat = item['latitude'].toString();
    }

    if (item['lat'] && lat == '') {
      lat = item['lat'].toString();
    }

    if (item['longitude']) {
      lng = item['longitude'].toString();
    }

    if (item['lng'] && lng == '') {
      lng = item['lng'].toString();
    }

    if (valNumeric && lat && lng) {
      lat = parseFloat(lat);
      lng = parseFloat(lng);
    }
    return { lat: lat, lng: lng };
  }

  /*********************************************************
  * Starting add elements into the map
  *********************************************************/
  const onPlay = () => {

    //only index in start value
    if(index == 99){
      //stablish index value
      if(rowsCount > (rowsPerPage * page)){
        index = rowsPerPage - 1
      }else{
        index = rowsCount - (rowsPerPage * (page - 1)) - 1
      }
    }

    dispatch(setVehicleTrailsPlayback(true))
    startingPlayback()
  }

  /*********************************************************
  * back to the last marker
  *********************************************************/
  const onPrevious = () => {
    if(index + 1 >= rowsPerPage - 1) return
    //mapRef?.current.removeMapObject(vehicleTrails.items[index + 1].id, TypeLayer.Units)
    const nameObject = vehicleTrails.items[index + 1].id;
    mapRef?.current.removeMapObject(`marker_${nameObject}`, TypeObjectMap.Marker);
    mapRef?.current.removeMapObject(`label_${nameObject}`, TypeObjectMap.Label);
    const polyline = mapRef?.current.getObject(TypeObjectMap.Polyline ,'polyline_trail_1')
    if(polyline){
      const path = polyline.instance.getPath();
      path.pop();
    }
    index++;
  }

  /*********************************************************
  * draw the next marker into the map
  *********************************************************/
  const onNext = () => {
    if(index < 0) {
      stopPlayBack()
      return
    }
    if(vehicleTrails.items && index > vehicleTrails.items.length - 1){
      if(rowsCount > (rowsPerPage * page)){
        index = rowsPerPage - 1
      }else{
        index = rowsCount - (rowsPerPage * (page - 1)) - 1
      }
    }

    addMarkerVehicleTrails(vehicleTrails.items[index], TypeLayer.VechileTrails, null, true);
    index = index - 1;
  }

  /*********************************************************
  * Draw marker into the map
  *********************************************************/
  const addMarkerVehicleTrails = (item, type = TypeLayer.Units, paramColor = null, isNumber = false) => {

    let showPolyLine = true, showTrailPointNumber = false
    if(preferences){
      const { trailConnectingLine, trailShowWaypointNumbers } = preferences
      showPolyLine = trailConnectingLine == undefined ? true : trailConnectingLine || false
      showTrailPointNumber = trailShowWaypointNumbers
    }

    const position = validateLatLng(item, true);
    if (position.lat && position.lng) {
      const mapObject = new MapObject().buildMarker({
        id: `marker_${item.id}`,
        deviceId: item?.deviceId,
        position,
        layerName: type,
        label: isNumber && item.wayPoint ? item.wayPoint.toString() : '',
        showLabel: showTrailPointNumber,
        heading: item?.heading,
        color: paramColor ? paramColor : validateEventColor(item),
        eventName: item?.eventName,
        draggable: item?.draggable || false
      })
      mapRef?.current.addMapObject(mapObject)

      if(showTrailPointNumber){
        const objectLabel = new MapObject().buildLabel({
            id: `label_${item.id}`,
            label: isNumber && item.wayPoint ? item.wayPoint.toString() : '',
            position: position,
            layerName: type
        })

        //add the object into the map
        mapRef?.current.addMapObject(objectLabel)
      }

      if(showPolyLine && !polyInstance){
        //add Polyline
        mapRef.current.addMapObject(new MapObject().buildPolyLine({
          id: 'polyline_trail_1',
          coordinates: [position],
          layerName: TypeLayer.VechileTrails,
          label: 'Polyline Vehicle Trails',
          heading: 0,
          color: 'red'
        }));
        polyInstance = true
      }else if(showPolyLine && polyInstance){
        mapRef.current.addPolylinePoint('polyline_trail_1', position)
      }
      mapRef.current?.centerMapAnimated(position.lat, position.lng)
    }
  }

  /*********************************************************
  * Save the event after 2 seconds start at the last event make 
  * from the user UI
  *********************************************************/
  const startingPlayback = () => {
    if(timerCountTrail){
      clearInterval(timerCountTrail)
    }
    timerCountTrail = setInterval(onNext, 1000)
  }

  /*********************************************************
  * Save the event after 2 seconds start at the last event make 
  * from the user UI
  *********************************************************/
  const stopPlayBack = () => {
    clearInterval(timerCountTrail)
    timerCountTrail = null
    setPlay(false)
  }

  return(
    <div className={'mapPagination'}>
      <Pagination
        count={rowsCount}
        page={page}
        rowsPerPage={rowsPerPage}
        messages={messages}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        valuesPerPage={[100,200,300,500]}
        classTotal={'mapPagination-classtotal'}
        onlyLine
        hideAllInPagination
      >
        {
          (!loadVehicleTrails && vehicleTrails && vehicleTrails?.items?.length > 0)
          &&
          (
            <PlaybackBar 
              play={play}
              onPlay={onPlay}
              onPause={()=>{
                stopPlayBack()
              }}
              onPrevious={onPrevious}
              onNext={onNext}
              setPlay={(value) => setPlay(value)}
            />
          )
        }
        
      </Pagination>
    </div>
  )

}

export default MapPagination;