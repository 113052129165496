import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  clientMutation,
  clientQuery,
} from "../../../../../../../core/data/GraphQLApi";
import { endpoints } from "../../../../../../../core/defaultValues";
import { ExceptionManager } from "../../../../../../../core/logManager";

//STATE INITIAL
const INIT_STATE = {
  loadingScheduledServices: false,
  scheduledServices: [],
  exportscheduledResponse: [],
};

const route ="maintenance/views/main/components/vehiclesTab/components/vehicleDetail/components/scheduledServices/redux/slice";

export const maintenanceVVDSearchMaintenanceServiceUnits = createAsyncThunk(
  "maintenance/servicesTab/maintenanceVVDSearchMaintenanceServiceUnits",
  async ({ serviceId, unitId }, { rejectWithValue, dispatch }) => {
    try {
      let query = `
            query ($serviceId: Int, $unitId: Int, $excludeCompleted: Boolean){
                data: searchMaintenanceServiceUnits(serviceId: $serviceId, unitId: $unitId, excludeCompleted: $excludeCompleted) {
                    id
                    serviceId
                    service {
                        id
                        title
                        notes
                        miles
                        days
                        engineHours
                        estimatedCost
                        unitsCount
                        engineHoursBefore
                        engineHoursAfter
                        daysBefore 
                        daysAfter 
                        milesBefore
                        milesAfter
                    }
                    items
                    estimatedCost
                    unitId
                    lastMaintenanceDate
                    lastMaintenanceOdometer
                    lastMaintenanceEngineHours
                    nextServiceDate
                    nextServiceOdometer
                    nextServiceEngineHours
                    status
                    notes
                    completed
                    fileIds
                    subscribersCount

                }
            }
        `;
      const response = await clientQuery(
        query,
        {
          serviceId: serviceId,
          unitId: unitId,
          excludeCompleted: true,
        },
        endpoints.GRAPHQL_GENERAL
      );

      return response?.data || [];
    } catch (exc) {
      ExceptionManager(
        exc,
        route,
        "maintenanceVVDSearchMaintenanceServiceUnits"
      );
      return rejectWithValue(exc);
    }
  }
);

export const maintenanceVVDExportMaintenanceServiceUnits = createAsyncThunk(
  "maintenance/servicesTab/maintenanceVVDExportMaintenanceServiceUnits",
  async ({ services, serviceType,units }, { rejectWithValue, dispatch }) => {
    try {
      let query = `
        query getVehicleServiceOverviewReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int, $recordsPerUnitLimit: Int) {
            getVehicleServiceOverviewReport (conditions: $conditions, limit: $limit, offset: $offset, recordsPerUnitLimit: $recordsPerUnitLimit) {
                items {
                    recordInfo {
                        entityId
                        entityLabel
                        tagId
                        tagLabel
                        startDate
                        endDate
                        recordType
                        groupLabel
                        groupIndex
                        groupTotal
                        groupTagColor
                    }
                    recordDetail {
                        deviceId
                        deviceLabel
                        serviceId
                        title
                        status
                        estimatedCost
                        notes
                        miles
                        nextServiceOdometer
                        engineHours
                        nextServiceEngineHours
                        days
                        nextServiceDate
                        noData
                    }
                }
                total
                limit
                offset
                category
                reportInstanceId
            }
        }
        `;
      const response = await clientQuery(
        query,
        {
          limit: 5000,
          offset: 1,
          conditions: {
            reportName: "Vehicle Service Overview Report",
            excludeEmptyTables: true,
            UIParams: '{"id":1697753361077.9783,"type":"vehicleServiceOverviewReport","loading":false,"data":[],"timeZone":"US/Central","filters":{"filterEntity":"Unit","filterType":"deviceId","filterArray":[{"id":200041525,"name":"Unit","label":"Van #1"}],"startRange":"2023-10-19T05:00:00Z","endRange":"2023-10-20T04:59:59Z","otherFilters":{"advancedFilter":true,"speed":0,"speedLimitDelta":0,"minute":0,"checkIdleProductive":false,"excludeIdle":false,"form":1,"event":"","geofenceBaseView":"all","landmarkBaseView":"single","landmark":200116927}},"export":true,"exportType":"pdf","asyncLoading":true,"loadSync":false,"sendByEmail":false,"emailsTo":[""],"name":"Vehicle Service Overview Report","categoryName":"deviceId","timeframeName":"Today","delayed":null,"excludeEmptyTables":true,"dataResultPath":null,"createdOn":null,"userId":null,"isAll":false,"reportTimeRanges":[],"allEntities":false,"durationFormat":"DDhhmmss"}',
            delayed: null,
            dataResultPath: null,
            timeZone: "US/Central",
            action: "getDataAsync",
            category: {
              field: "deviceId",
              categoryName: "deviceId",
              entities: units
            },
            dateRange: {
              fields: ["unitTime"],
              GTE: "2023-10-19T05:00:00Z",
              LTE: "2023-10-20T04:59:59Z",
              timeframeName: "Today",
              reportTimeRanges: [],
            },
            sessionId: sessionStorage.getItem("sessionId"),
            filterValues: {
              field: "Service",
              entities: [
                {
                  id: serviceType,
                  type: "Service"
                },
              ],
            },
            stringValues: {
              values:services,
            },
            sortField: {},
            exportOptions: {
              sendByEmail: false,
              emailsTo: [""],
              culture: "en-US",
              timeZone: "US/Central",
              format: {
                fileFormat: "pdf",
                formatOptions: "{\"addPagination\":\"true\",\"addLogo\":\"true\",\"addLine\":\"true\",\"styleLogo\":{\"x\":580,\"y\":5,\"w\":240,\"h\":60},\"fontSize\":8}",
                durationFormat: "DDhhmmss",
              },
              folderName: "px-customer-reports",
              filePrefixName: "vehicleServiceOverviewReport",
              columns: [
                {
                  column: "label",
                  header: " ",
                },
                {
                  column: "detail",
                  header: " ",
                },
                {
                  column: "cost",
                  header: " ",
                },
                {
                  column: "labor",
                  header: " ",
                },
              ],
            },
          },
        },
        endpoints.GRAPHQL_PX_REPORTING
      );

      return response?.data || [];
    } catch (exc) {
      ExceptionManager(
        exc,
        route,
        "maintenanceVVDExportMaintenanceServiceUnits"
      );
      return rejectWithValue(exc);
    }
  }
);

const maintenanceScheduledServicesRedux = createSlice({
  name: "maintenance/servicesTab",
  initialState: INIT_STATE,
  reducers: {
    reset(state, action) {
      return action.payload ? { ...state, ...action.payload } : INIT_STATE;
    },
  },

  extraReducers: (builder) => {
    //builder.addCase(resetALL, () => INIT_STATE);

    //maintenanceVVDSearchMaintenanceServiceUnits
    builder.addCase(
      maintenanceVVDSearchMaintenanceServiceUnits.pending,
      (state, action) => {
        state.loadingScheduledServices = true;
      }
    );
    builder.addCase(
      maintenanceVVDSearchMaintenanceServiceUnits.fulfilled,
      (state, action) => {
        state.loadingScheduledServices = false;
        state.scheduledServices = action.payload;
      }
    );
    builder.addCase(
      maintenanceVVDSearchMaintenanceServiceUnits.rejected,
      (state, action) => {
        state.loadingScheduledServices = false;
        state.error = action.payload;
      }
    );

    //maintenanceVVDSearchMaintenanceServiceUnits
    builder.addCase(
      maintenanceVVDExportMaintenanceServiceUnits.pending,
      (state, action) => {
        state.loadingScheduledServices = true;
      }
    );
    builder.addCase(
      maintenanceVVDExportMaintenanceServiceUnits.fulfilled,
      (state, action) => {
        state.loadingScheduledServices = false;
        state.exportscheduledResponse = action.payload;
      }
    );
    builder.addCase(
      maintenanceVVDExportMaintenanceServiceUnits.rejected,
      (state, action) => {
        state.loadingScheduledServices = false;
        state.error = action.payload;
      }
    );
  },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = maintenanceScheduledServicesRedux;

// Extract and export each action creator by name
export const { reset } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;
