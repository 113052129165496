import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    loading: false,
    open: false,
    restart: false,
    routeId: 0,
    point: null, //is edit set point
    typePoint: 'Stop', //Stop - Waypoint
    erorrRoutes: null
};

const route = "settings/views/routes/components/createEditRoutePoint";

export const RoutesManagedCreateEditRoutePointRedux = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalCreateEditRoutePoint: (state, action) => {
            return {
                ...state,
                open: action.payload?.open,
                point: action.payload?.point, //is edition mode
                typePoint: action.payload?.typePoint
            }
        }
    }
    
});



const { actions, reducer } = RoutesManagedCreateEditRoutePointRedux;

export const { reset, openModalCreateEditRoutePoint } = actions;

export default reducer;