import { call, put } from "redux-saga/effects";
import { clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";
// Actions
import {
  reportsMainGenerateReportProgress,
  reportsMainGenerateReportUpdate,
} from "Redux/actions";

import {
  baseReportConditions,
  generateReportInstance,
  getTimeZone,
  handleAsyncReport,
  isJsonFormat,
  getCategoryFilter,
} from "Modules/reports/views/main/components/reportsTab/helper";

function* getMaintenanceItemsReport(
  report,
  page,
  rowsPerPage,
  sortField,
  sortOrder,
  requestFlag
) {

  const { timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath } = report;
  const { filterType, filterArray, startRange, endRange, unitsSelected, dates } = report.filters

  const typeExportValue = getCategoryFilter(filterType) === "deviceId" ? "Vehicle" : "Driver";
  const typeExportLabel = getCategoryFilter(filterType) === "deviceId" ? "deviceLabel" : "driverName";


  const baseConditionsQuery = baseReportConditions({ report, name, dataResultPath, timeZone, delayed, unitsSelected, timeframeName , filterType, filterArray, categoryName, startRange, endRange, dates})

  let payload = {
    conditions: {
      ...baseConditionsQuery,
      sortField: {
        field: sortField || "deviceId",
        order: sortOrder === "desc" ? "DESC" : "ASC",
      },
    },
    limit: rowsPerPage,
    offset: page,
  };

  let columnsFormat;

  if (report?.exportType === "pdf") {
    columnsFormat = [
      {
        column: "dateTime",
        header: "Time & Date",
      },
      {
        column: "serviceTitle",
        header: "Title",
      },
      {
        column: "distance",
        header: "Distance",
      },
      {
        column: "days",
        header: "Days",
      },
      {
        column: "hours",
        header: "Hours",
      },
      {
        column: "cost",
        header: "Cost",
      },
      {
        column: "notes",
        header: "Notes",
      },
    ];
  } else {
    columnsFormat = [
      {
        column: typeExportLabel,
        header: typeExportValue,
      },
      {
        column: "dateTime",
        header: "Time & Date",
      },
      {
        column: "serviceTitle",
        header: "Title",
      },
      {
        column: "distance",
        header: "Distance",
      },
      {
        column: "days",
        header: "Days",
      },
      {
        column: "hours",
        header: "Hours",
      },
      {
        column: "cost",
        header: "Cost",
      },
      {
        column: "notes",
        header: "Notes",
      },
    ];
  }

  if (report.export) {
    payload = {
      ...payload,
      limit: isJsonFormat(report?.exportType, dataResultPath)
        ? rowsPerPage
        : 5000,
      offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
      conditions: {
        ...payload.conditions,
        exportOptions: {
          sendByEmail,
          emailsTo,
          format: {
            fileFormat: report?.exportType || "json",
            formatOptions: '{"orientation": "landscape"}',
          },
          culture: report.lang,
          timeZone: getTimeZone(timeZone),
          folderName: "px-customer-reports",
          filePrefixName: "MaintenanceItemsReport",
          columns: columnsFormat,
        },
      },
    };
  }

  try {
    let data = yield call(
      clientQuery,
      `query getMaintenanceItemsReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
                Report: getMaintenanceItemsReport (conditions: $conditions, limit: $limit, offset: $offset) {
                  ${
                    generateReportInstance(
                      report.export,
                      dataResultPath,
                      report.exportFromTable
                    )
                      ? `reportInstanceId`
                      : `
                    items {
                        id
                        idLabel
                        totalCost
                        noData
                    }
                    total
                    limit
                    offset
                    category
                    reportInstanceId
                `
                  }
                }
              }`,
      payload,
      endpoints.GRAPHQL_PX_REPORTING
    );
    yield handleAsyncReport(report, data);
  } catch (exc) {
    ExceptionManager(
      exc,
      "reports/VehicleMaintenanceItemsReport",
      "getVehMaintenanceItemsReport"
    );
    report.loading = false;
    yield put(reportsMainGenerateReportProgress(report));
    if (!requestFlag) {
      let flag = true;
      yield put(
        reportsMainGenerateReportUpdate(
          report,
          page,
          rowsPerPage,
          sortField,
          sortOrder,
          flag
        )
      );
    } else {
      report.loading = false;
      yield put(reportsMainGenerateReportProgress(report));
    }
  }
}

export default getMaintenanceItemsReport;
