export default {
    manageDriverTasksScheduled_tile: "Manage Tasks for",
    manageDriverTasksScheduled_completed: "Completed",
    manageDriverTasksScheduled_pastDue: "Past Due",
    manageDriverTasksScheduled_dueStatus: "Due",
    manageDriverTasksScheduled_dueSoon: "Due Soon",
    manageDriverTasksScheduled_scheduled: "Scheduled",
    manageDriverTasksScheduled_done: "Done",
    manageDriverTasksScheduled_noTasks: "No Tasks",
    manageDriverTasksScheduled_addNew: "Add New",
    manageDriverTasksScheduled_toComplete: "To Complete",
    manageDriverTasksScheduled_completed: "Completed",
}