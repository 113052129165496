export default {
    realtimemaps_map_settings_offline_device: 'Dispositivos fuera de línea',
    realtimemaps_map_settings_offline_asset: 'Activo',
    realtimemaps_map_settings_offline_fleet: 'Flota',
    realtimemaps_map_settings_offline_minutes: 'Minutos',
    realtimemaps_map_settings_offline_hours: 'Horas',
    realtimemaps_map_settings_offline_days: 'Dias',
    realtimemaps_map_settings_offline_minute: 'Minuto',
    realtimemaps_map_settings_offline_hour: 'Hora',
    realtimemaps_map_settings_offline_day: 'Dias',
    realtimemaps_map_settings_offline_threshold: 'Umbral fuera de línea',
    realtimemaps_map_settings_device_type: 'Tipo de dispositivo',
    realtimemaps_map_settings_unit_labels: 'Unidad/Etiquetas',
    realtimemaps_map_settings_current_zoom: 'Zoom actual',
    realtimemaps_map_settings_considered_offline: 'El dispositivo se considera desconectado despues de ',
    realtimemaps_map_settings_description_fleet: 'Los dispositivos de la flota tienen fuentes de energía constantes y, por lo general, informan cada 1 minuto mientras conducen. Cuando no esté conduciendo pero esté conectado a la corriente, el dispositivo se registrará para confirmar que todavía está en línea. Use las opciones a continuación para establecer la duración del tiempo que un vehículo de flota puede pasar sin registrarse antes de que se considere fuera de línea',
    realtimemaps_map_settings_description_asset: 'Los dispositivos activos no tienen fuentes de energía constantes. Como mínimo, se espera que estos dispositivos se registren o informen su ubicación una vez al día. Use las opciones a continuación para establecer la duración de tiempo que un activo puede pasar sin registrarse antes de que se considere fuera de línea',   
};