import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { useLocation } from "react-router-dom";

import VidFleetVideo from 'Modules/.common/components/VidFleetVideo';
import SmartWitnessVideo from 'Modules/.common/components/SmartWitnessVideo';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const ShareVideoView = (props) => {
  const { messages } = props.intl;
  let query = useQuery();
  let format = query.get("format");
  let esn = query.get("esn");
  let mode = query.get("mode");
  let pictureId = query.get("pictureId");
  let autoplay = query.get("autoplay");
  let manufacturer = query.get("manufacturer");
  let recorderId = query.get("recorderId");
  let cameraChannel = query.get("cameraChannel");

  let options = {
    messages: messages,
    mode: mode,
    type: 'streaming',
    id: esn,
    autoplay: autoplay === "true" ? true : false,
    deviceModel: format,
    //dewrapImage: (format === '360' && (mode === "picture" || autoplay === false) ? true : false),
    dewrapImage: false,
    pictureId: pictureId,
    recorderId: recorderId,
    cameraChannel: cameraChannel || "*"
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ width: '100%', height: '100vh' }}>
        {
          manufacturer === "smartwitness" ?
          <SmartWitnessVideo  {...options} /> :
          <VidFleetVideo  {...options} />
        }
      </div>
    </div>
  );
};

export default injectIntl(memo(ShareVideoView));
