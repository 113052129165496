import React from 'react'
import { useIntl } from 'react-intl';
import { Checkbox } from "Components";


const alertList = [
  { label: "incident_alert_start", value: "incidentStart" },
  { label: "incident_alert_vehicle_stopped", value: "vehicleStopped" },
  { label: "incident_alert_incident_end", value: "incidentEnd" },
];

const AlertsList = ({
  formData,
  setFormData
}) => {

  const { messages } = useIntl()

  return (
    <div>
      <h3 style={{ marginBottom: "4px" }}>{messages["incident_alert"]}</h3>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {alertList.map(({ label, value }, index) => (
          <Checkbox
            key={value}
            id={value}
            label={messages[label]}
            value={
              formData?.alerts?.find((item) => item === value) ? true : false
            }
            size="md"
            onChange={() => {
              let tempAlerts = [...formData?.alerts];
              if (tempAlerts?.find((item) => item === value)) {
                tempAlerts = tempAlerts?.filter((item) => item !== value);
                setFormData({ ...formData, alerts: tempAlerts });
              } else {
                tempAlerts.push(value);
                setFormData({ ...formData, alerts: tempAlerts });
              }
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default AlertsList