import { NotificationManager } from 'Components/Notifications';
import { ErrorHandlerLogin } from 'Core/data/Helpers';
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
let Exceptions;

export const ExceptionManager = (exc, module, method, show = false) => {
	/*if (!Exceptions)
				Exceptions = [];

				try
		Exceptions.push(exc)*/
	try {
		console.log(`Error => Module: ${module} \nMethod: ${method} \nexc:`, exc);

		if (exc.errorData && exc.errorData.errors) {
			exc.errorData.errors.forEach(error => {
				if (error.errorInfo && error.errorInfo.code && show) {
					NotificationManager.error(
						ErrorHandlerLogin(error.message),
						"Error",
						3000,
						null,
						null,
						'error',
						error.errorInfo?.code
					);
				}
				else if (show) {
					NotificationManager.error(
						ErrorHandlerLogin(error.message),
						"Error",
						3000,
						null,
						null,
						'error'
					);
				}
			});
		}
	}
	catch (exc2) {
		console.log("ExceptionManager error: ", exc2)
	}
};

export const LogManager = (...log) => {
	console.log('Log => ', ...log);
};

export const LogManagerConsole = (key, ...log) => {
	let debug = Boolean(localStorage.getItem("debug")) === true;
	let logKeys = localStorage.getItem("logKeys")
	logKeys = logKeys && JSON.parse(logKeys) || []

	if (debug && (logKeys.length == 0 || logKeys.includes(key)))
		console.log(`Log ${key}=>`, ...log);
};


export const GetApiErrorLan = (exc) => {

	let errorCode
	if (exc?.errorData?.errors) {
		exc?.errorData?.errors.forEach(x => {
			if (x.errorInfo?.code)
				errorCode = x.errorInfo?.code
		})
	}

	const errors = {
		'1010': 'duplicated_name'
	}

	return errorCode && errors[errorCode] || '';
}
