// eslint-disable-next-line no-unused-vars
import React, { Fragment } from 'react';
import { Icon } from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './sidepanel.scss';

export const SidePanelHeader = (props) => {
	return <div className="side-panel-header" style={{ ...props.style }}>{props.children}</div>;
};

export const SidePanelBody = (props) => {
	return <div className={cx("", props.className ? props.className : "side-panel-body")} style={{ ...props.style }}>{props.children}</div>;
};

export const SidePanelFooter = (props) => {
	return <div className={cx("side-panel-footer", props.className)} style={{ ...props.style }}>{props.children}</div>;
};

const SidePanel = (props) => {
	let icon = props.isOpen ? 'navigate_next' : 'navigate_before';

	//temp
	const calendarScrollParent = useSelector(state => state.commomRedux.calendarScrollParent);

	const SidePanelbutton = (props) => (
		<span
			className={`side-panel-button side-panel-button-${props.direction ? props.direction : 'right'
				}`}
			onClick={props.toggle}
		>
			<Icon style={{ fontSize: 28 }}>{icon}</Icon>
		</span>
	)



	return (
		<Fragment>
			<div className={cx(`${(props.overlay && props.isOpen) && "overlay-panel"}`, props.overlayClass)}></div>
			<div
				id='id_sidePanel'
				style={{ ...props.style }}
				className={`side-panel ${props.isOpen ? 'shown-' + (props.direction ? props.direction : 'right') : ''
					} side-panel-${props.direction ? props.direction : 'right'} ${props.classSidePanel ? props.classSidePanel : ''}`}
			>
				<div
					className={cx((props.classPanelContent ? props.classPanelContent : "side-panel-content"), !calendarScrollParent ? "scroll-panel-content" : "")}
					style={{ ...props.contentStyle }}
				>
					{props.children}
				</div>

				{!props?.hiddenButton && (
					<SidePanelbutton {...props} />
				)}

				{props?.hiddenButton && props.isOpen && (
					<SidePanelbutton {...props} />
				)}

			</div>
		</Fragment>

	);
};

export default SidePanel;

SidePanel.propTypes = {
	isOpen: PropTypes.bool.isRequired,
};
