import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    tasks: null,
    driverScheduledTask: null,
    completeDriverScheduledTask: null,
    error: null,
    driverName: null,
};

const route = "modules/maintenance/modals/completeDriverScheduledTask/redux/slice";

export const maintenanceCDSTGetDriverScheduledTask = createAsyncThunk("maintenance/completeDriverScheduledTask/getDriverScheduledTask", async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientQuery(
            `
            query ($id: Int!) {
                data: getDriverScheduledTask (id: $id) {
                    id
                    driverId
                    driverTaskId
                    driverTaskName
                    estimatedCost
                    dueDate
                    frequency
                    timeUnit
                    notes
                    completed
                    fileIds
                    alertBefore
                    alertBeforeTimeUnit
                    alertAfter
                    alertAfterTimeUnit
                    files {
                        id
                        originalName
                        fileKey
                        repositoryCode
                    }
                    subscribersCount
                    subscribers {
                        id
                        contactId
                        contact {
                            id
                            title
                        }
                        alert
                        alertBefore
                        alertAfter
                    }
                    status
                }
            }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );
        if (response?.data) return response?.data
        else throw 'Error Deleting Driver Task';

    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceMMTSearchMaintenanceDriverTasks');
        return rejectWithValue(exc);
    }
})

export const maintenanceCDSTCreateUpdateDriverScheduledTaskLog = createAsyncThunk("maintenance/completeDriverScheduledTask/createUpdateDriverScheduledTaskLog", async (payload, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientMutation(
            `
            mutation(
				${payload?.id ? '$id: Int!' : ''}
				$scheduledTaskId: Int!, 
				$logDate: String!, 
				$actualCost: Float!, 
				$notes: String, 
				$completed: Boolean!, 
				$nextDueDate: String, 
				$fileIds: [Int]
			) {
				data: ${payload?.id ? 'updateDriverScheduledTaskLog' : 'createDriverScheduledTaskLog'} (
					${payload?.id ? 'id: $id,' : ''}
					scheduledTaskId: $scheduledTaskId, 
					logDate: $logDate, 
					actualCost: $actualCost, 
					notes: $notes, 
					completed: $completed, 
					nextDueDate: $nextDueDate, 
					fileIds: $fileIds
				) {
					id
                    scheduledTaskId
                    logDate
                    actualCost
                    dueDate
                    estimatedCost
                    notes
                    completed
                    nextDueDate
                    fileIds
                    files {
                        id
                        originalName
                        fileKey
                        repositoryCode
                    }
				}
			}
            `,
            payload,
            endpoints.GRAPHQL_GENERAL
        );
        return response.data

    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceCDSTCreateUpdateDriverScheduledTaskLog');
        return rejectWithValue(exc);
    }
})

export const maintenanceCompleteDriverScheduledTaskRedux = createSlice({
    name: "maintenance/completeDriverScheduledTask",
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openModalCompleteDriverScheduledTask(state, action) {
            return {
                ...state,
                open: action.payload.open,
                tasks: action.payload.tasks,
                driverName: action.payload.driverName,
            }
        },
    },
    extraReducers: (builder) => {

        //maintenanceCDSTGetDriverScheduledTask
        builder.addCase(maintenanceCDSTGetDriverScheduledTask.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceCDSTGetDriverScheduledTask.fulfilled, (state, action) => {
            state.loading = false;
            state.driverScheduledTask = action.payload;
        });

        builder.addCase(maintenanceCDSTGetDriverScheduledTask.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //maintenanceCDSTCreateUpdateDriverScheduledTaskLog
        builder.addCase(maintenanceCDSTCreateUpdateDriverScheduledTaskLog.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceCDSTCreateUpdateDriverScheduledTaskLog.fulfilled, (state, action) => {
            state.loading = false;
            state.completeDriverScheduledTask = action.payload;
        });

        builder.addCase(maintenanceCDSTCreateUpdateDriverScheduledTaskLog.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
})

const { actions, reducer } = maintenanceCompleteDriverScheduledTaskRedux;

// Extract and export each action creator by name
export const {
    reset,
    openModalCompleteDriverScheduledTask
} = actions;

export default reducer;