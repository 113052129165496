import {
    MAP_GEOFENCES_CONTACTS_OPEN_MODAL,
    MAP_GEOFENCES_CONTACTS_GET_GEOFENCE,
    MAP_GEOFENCES_CONTACTS_GET_GEOFENCE_RESPONSE,
    MAP_GEOFENCES_CONTACTS_SAVE,
    MAP_GEOFENCES_CONTACTS_SAVE_RESPONSE
} from 'Redux/actionTypes';

export const mapGeofencesOpenContactsModal = (open, id) => ({
    type: MAP_GEOFENCES_CONTACTS_OPEN_MODAL,
    payload: { open, id },
});

export const mapGeofencesContactsGetGeofence = (id) => ({
    type: MAP_GEOFENCES_CONTACTS_GET_GEOFENCE,
    payload: { id },
});

export const mapGeofencesContactsGetGeofenceResponse = (data) => ({
    type: MAP_GEOFENCES_CONTACTS_GET_GEOFENCE_RESPONSE,
    payload: { data },
});

export const mapGeofencesContactsSave = (geofence) => ({
    type: MAP_GEOFENCES_CONTACTS_SAVE,
    payload: { geofence },
});

export const mapGeofencesContactsSaveResponse = (response) => ({
    type: MAP_GEOFENCES_CONTACTS_SAVE_RESPONSE,
    payload: { response },
});