export default {
    map_geofence_geofenceAssign_titleUnit: "Assign Geofence to: ",
    map_geofence_geofenceAssign_titleGeofence: "Assign Unit to: ",
    map_geofence_geofenceAssign_unitVersion: "The supported geofences for this vehicle are {geofencesLimit}, with shapes: {shapes} and maximum {pointsLimit} points",
    map_geofence_geofenceAssign_unitVersion_circle: "The supported geofences for this vehicle are {geofencesLimit}, with shapes: {shapes}",
    map_geofence_geofenceAssign_geofences: "Select Geofences to assign",
    map_geofence_geofenceAssign_units: "Select Units to assign",
    map_geofence_geofenceAssign_geofencesLimitReached: "The unit has reached the geofences limit",
    map_geofence_geofenceAssign_geofencesAssignedMessage: "Geofences assigned successfully",
    map_geofence_geofenceAssign_geofenceType: "Geofence with shape '{shape}' and {points} points",
    map_geofence_geofenceAssign_geofenceType_circle: "Geofence with shape '{shape}",
    map_geofence_geofenceAssign_circle: "circle",
    map_geofence_geofenceAssign_polygon: "polygon",
    map_geofence_geofenceAssign_square: "square",
};