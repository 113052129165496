import {
    SETTINGS_VEHICLE_STATUS_SEARCH,
    SETTINGS_VEHICLE_STATUS_SEARCH_RESPONSE,
    SETTINGS_VEHICLE_STATUS_OPEN_CREATE_EDIT_MODAL,
    SETTINGS_VEHICLE_STATUS_CREATE_EDIT,
    SETTINGS_VEHICLE_STATUS_CREATE_EDIT_RESPONSE,
    SETTINGS_VEHICLE_STATUS_ICON_GET,
    SETTINGS_VEHICLE_STATUS_ICON_GET_RESPONSE,
    SETTINGS_VEHICLE_STATUS_OPEN_DELETE_MODAL,
    SETTINGS_VEHICLE_STATUS_DELETE,
    SETTINGS_VEHICLE_STATUS_DELETE_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loadingVehicleStatus: false,
    loadingCreateEdit: false,
    vehicleStatusItems: [],
    selectedItems: [],
    vehicleStatusIcons: [],
    vehicleStatus: null,
    errorVehicleStatus: false,
    openCreateEdit: false,
    openDelete: false,
    createEditResponse: null,
    deleteResponse: null,
    filters: {
        openSearch: ""
    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SETTINGS_VEHICLE_STATUS_SEARCH:
            return {
                ...state,
                vehicleStatusItems: [],
                loadingVehicleStatus: true,
                errorVehicleStatus: false,
                filters: action.payload
            };

        case SETTINGS_VEHICLE_STATUS_SEARCH_RESPONSE:
            return {
                ...state,
                vehicleStatusItems: action.payload?.vehicleStatusItems,
                total: action.payload?.total,
                errorVehicleStatus: action.payload?.error,
                loadingVehicleStatus: false
            };

        case SETTINGS_VEHICLE_STATUS_OPEN_CREATE_EDIT_MODAL:
            return {
                ...state,
                openCreateEdit: action.payload?.open,
                vehicleStatus: action.payload?.vehicleStatus
            };

        case SETTINGS_VEHICLE_STATUS_ICON_GET:
            return {
                ...state,
                vehicleStatusIcons: [],
                loadingCreateEdit: true
            };

        case SETTINGS_VEHICLE_STATUS_ICON_GET_RESPONSE:
            return {
                ...state,
                vehicleStatusIcons: action.payload,
                loadingCreateEdit: false
            };

        case SETTINGS_VEHICLE_STATUS_CREATE_EDIT:
            return {
                ...state,
                loadingCreateEdit: true,
                createEditResponse: null
            };

        case SETTINGS_VEHICLE_STATUS_CREATE_EDIT_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                createEditResponse: action.payload?.data,
            };

        case SETTINGS_VEHICLE_STATUS_OPEN_DELETE_MODAL:
            return {
                ...state,
                openDelete: action.payload?.open,
                selectedItems: action.payload?.items,
                loadingDelete: false,
                deleteResponse: null
            };

        case SETTINGS_VEHICLE_STATUS_DELETE:
            return {
                ...state,
                loadingDelete: true,
                deleteResponse: null
            };

        case SETTINGS_VEHICLE_STATUS_DELETE_RESPONSE:
            return {
                ...state,
                loadingDelete: false,
                deleteResponse: action.payload?.data,
            };

        case CLEAR_REDUX:
            return ["", "SETTINGS_VEHICLE_STATUS"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return { ...state };
    }
};