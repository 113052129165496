//View Keys
export default {
	forgotPassword_title: 'Recovery Password',
	forgotPassword_email: 'Email',
	forgotPassword_reset: 'Reset password',
	forgotPassword_subtitle: 'Please fill data',
	forgotPassword_verificationCode: 'Verification Code',
	forgotPassword_newPassword: 'New Password',
	forgotPassword_ConfirmNewPassword: 'Confirm New Password',
	forgotPassword_verify: 'Verify',
	forgotPassword_success: 'Your password has been updated, please log in!',
	forgotPassword_passwordError: 'Passwords do not match',
  forgotPassword_back: 'Return to login?',
  forgotPassword_userNoMigrated: 'In order to use Password Recovery, you will first need to register this username. Please either complete the registration process or reach out to our Customer Service team at 866-320-5810 or customerservice@gpstrackit.com for further assistance.',
  forgotPassword_userNoMigratedReseller: 'In order to use Password Recovery, you will first need to register this username. Please either complete the registration process or reach out to our Customer Service team for further assistance.',
  forgotPassword_errorMesaggeUserNoFound: 'Username/client id combination not found.',

};
