import {
    FSM_SETTINGS_FORM_SEARCH,
    FSM_SETTINGS_FORM_SEARCH_RESPONSE,
    FSM_SETTINGS_FORM_CREATE,
    FSM_SETTINGS_FORM_CREATE_RESPONSE,
    FSM_SETTINGS_FORM_UPDATE,
    FSM_SETTINGS_FORM_UPDATE_RESPONSE,
    FSM_SETTINGS_FORM_DELETE,
    FSM_SETTINGS_FORM_DELETE_RESPONSE,
    FSM_SETTINGS_FORM_OPEN_CREATE_EDIT,
    FSM_SETTINGS_FORM_OPEN_DELETE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    filters: {
        openSearch: "", 
        limit: 25, 
        offset: 0,
        reset: false
    },
    total: 0,
    loadingForms: false,
    forms: [],
    form: {},
    loadingCreateEdit: false,
    openCreateEditForm: false,
    loadingDelete:false,
    response: {},
    responseDelete:false,
    openDelete: false,
    formsDelete: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FSM_SETTINGS_FORM_SEARCH:
            return {
                ...state,
                loadingForms: true,
                forms: action.payload?.data?.reset ? [] : state.forms,
                filters: { ...state.filters, reset: false }
            };

        case FSM_SETTINGS_FORM_SEARCH_RESPONSE:
            return {
                ...state,
                loadingForms: false,
                forms: [ ...state.forms, ...action.payload?.forms],
                total: action.payload?.total,
            };

        case FSM_SETTINGS_FORM_CREATE:
            return {
                ...state,
                loadingCreateEdit: true,
                response: {}
            };

        case FSM_SETTINGS_FORM_CREATE_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                response: action.payload
            };

        case FSM_SETTINGS_FORM_UPDATE:
            return {
                ...state,
                loadingCreateEdit: true,
                response: {}
            };

        case FSM_SETTINGS_FORM_UPDATE_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                response: action.payload
            };

        case FSM_SETTINGS_FORM_DELETE:
            return {
                ...state,
                loadingDelete: true,
                responseDelete: false
            };

        case FSM_SETTINGS_FORM_DELETE_RESPONSE:
            return {
                ...state,
                loadingDelete: false,
                responseDelete: action.payload?.data
            };

        case FSM_SETTINGS_FORM_OPEN_CREATE_EDIT:
            return {
                ...state,
                openCreateEditForm: action.payload?.open,
                form: action.payload?.form,
            };

        case FSM_SETTINGS_FORM_OPEN_DELETE:
            return {
                ...state,
                openDelete: action.payload?.open,
                formsDelete: action.payload?.forms,
            };

        case CLEAR_REDUX:
            return ["", "FSM_SETTINGS_FORM"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return state;
    }
};