export default {
    settingsCrew_name: "Nombre",
    settingsCrew_member: "Miembro de Tripulación",
    settingsCrew_assignTo: "Asignado a",
    settingsCrew_search: "Buscar por miembro de tripulación",
    settingsCrew_editTitle: "Editar: ",
    settingsCrew_createTitle: "Agregar nuevo miembro de tripulación",
    settingsCrew_firstName: "Nombre",
    settingsCrew_lastName: "Apellido",
    settingsCrew_minLengthMessage: "El campo debe contener un mínimo de caracteres ",
    settingsCrew_maxLengthMessage: "El campo debe contener un máximo de caracteres: ",
    settingsCrew_vehicle: "Vehículo",
    settingsCrew_success: "Miembro de la tripulación guardado",
    settingsCrew_deleted: "Miembros de tripulación eliminados",
    settingsCrew_deleteTitle: "Eliminar Miembros de Tripulación",
    settingsCrew_deleteWarning: "¿Está seguro de que desea eliminar a los siguientes miembros de la tripulación?",
    settingsCrew_alreadyExists: "Ya existe un miembro de tripulación con el nombre ingresado",
    settingsCrew_bulkCrewAssigment: "Asignación de Tripulación Masiva",
    settingsCrew_unit: "Unidad",
    settingsCrew_unitDriver: "Unidad - Conductor",
    settingsCrew_crews: "Tripulación",
    settingsCrew_bulkSettings: "Configuración Masiva",
    settingsCrew_assignEdit: "Re-Asignar",
    settingsCrew_members: "Miembro de Tripulación",
    settingsCrew_vehicles: "Vehiculos",
    settingsCrew_crewAssigned: "Tripulación Asignada",
    settingsCrew_editCrewFor: "Editar Tripulación para ",
    settingsCrew_editCrew: "Editar Tripulación",
    settingsCrew_assignCrew: "Asignar Tripulación"
};