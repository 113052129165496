import React, { useRef } from "react";
import AreaMenu, { Area, Menu } from "Components/AreaMenu";
import { Icon, IconButton } from "@mui/material";
import UserAlerts from "Core/views/userAlerts";
import { Tooltip } from "../../../../components"

import { useIntl } from "react-intl";


const AlertsButton = () => {
  const alertMenuRef = useRef();
  const { messages } = useIntl();

  return (
    <div className="header-action">
      <AreaMenu id="areaNotifications" ref={alertMenuRef}>
        <Area>
        <Tooltip 
          title={messages["header_alertIcon"]}
          >
            <IconButton
              aria-label="placeholder"
              style={{ fontSize: 25, color: "#1D2734" }}
              size="large">
              <Icon>notifications</Icon>
            </IconButton>
          </Tooltip>
        </Area>
        <Menu className="menu-notifications">
          <UserAlerts
            onCloseMenu={() => {
              alertMenuRef.current?.toggle(false);
            }}
          />
        </Menu>
      </AreaMenu>
    </div>
  );
};

export default AlertsButton;
