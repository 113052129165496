import {
    FSM_SETTINGS_QUICK_MESSAGE_SEARCH,
    FSM_SETTINGS_QUICK_MESSAGE_SEARCH_RESPONSE,
    FSM_SETTINGS_QUICK_MESSAGE_CREATE,
    FSM_SETTINGS_QUICK_MESSAGE_CREATE_RESPONSE,
    FSM_SETTINGS_QUICK_MESSAGE_UPDATE,
    FSM_SETTINGS_QUICK_MESSAGE_UPDATE_RESPONSE,
    FSM_SETTINGS_QUICK_MESSAGE_DELETE,
    FSM_SETTINGS_QUICK_MESSAGE_DELETE_RESPONSE,
    FSM_SETTINGS_QUICK_MESSAGE_OPEN_CREATE_EDIT,
    FSM_SETTINGS_QUICK_MESSAGE_OPEN_DELETE
} from "Redux/actionTypes";



export const searchQuickMessage = () => {
    return {
        type: FSM_SETTINGS_QUICK_MESSAGE_SEARCH,
        payload: {},
    };
};

export const searchQuickMessageResponse = (data) => {
    return {
        type: FSM_SETTINGS_QUICK_MESSAGE_SEARCH_RESPONSE,
        payload: data,
    };
};

export const createQuickMessage = (quickMessage) => {
    return {
        type: FSM_SETTINGS_QUICK_MESSAGE_CREATE,
        payload: quickMessage,
    };
};

export const createQuickMessageResponse = (data) => {
    return {
        type: FSM_SETTINGS_QUICK_MESSAGE_CREATE_RESPONSE,
        payload: data,
    };
};

export const updateQuickMessage = (quickMessage) => {
    return {
        type: FSM_SETTINGS_QUICK_MESSAGE_UPDATE,
        payload: quickMessage,
    };
};

export const updateQuickMessageResponse = (data) => {
    return {
        type: FSM_SETTINGS_QUICK_MESSAGE_UPDATE_RESPONSE,
        payload: data,
    };
};

export const deleteQuickMessage = (quickMessage) => {
    return {
        type: FSM_SETTINGS_QUICK_MESSAGE_DELETE,
        payload: { quickMessage },
    };
};


export const deleteQuickMessageResponse = (data) => {
    return {
        type: FSM_SETTINGS_QUICK_MESSAGE_DELETE_RESPONSE,
        payload: data,
    };
};


export const openQuickMessageCreateEdit = (open, quickMessage) => {
    return {
        type: FSM_SETTINGS_QUICK_MESSAGE_OPEN_CREATE_EDIT,
        payload: { open, quickMessage }
    };
};

export const openQuickMessageDelete = (open, quickMessages) => {
    return {
        type: FSM_SETTINGS_QUICK_MESSAGE_OPEN_DELETE,
        payload: { open, quickMessages }
    };
};