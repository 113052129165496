import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';
// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

import {baseReportConditions, generateReportInstance, getTimeZone, handleAsyncReport, isJsonFormat} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getDeviceSummaryInfoReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
    const {timeZone, sendByEmail, emailsTo, timeframeName, categoryName, delayed, dataResultPath} = report;
    const {filterType, filterArray, startRange, endRange, otherFilters, unitsSelected} = report.filters
    const baseConditionsQuery = baseReportConditions({
        report,
        name: 'DeviceSummaryInfo',
        dataResultPath,
        timeZone,
        delayed,
        unitsSelected,
        timeframeName,
        filterType,
        filterArray,
        categoryName,
        startRange,
        endRange
    })
    try {
        let payload = {
            conditions: {
                ...baseConditionsQuery,
                sortField: {
                    field: sortField || "deviceId",
                    order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                }
            },
            limit: rowsPerPage,
            offset: page
        };

        if (report.export) {
            payload = {
                ...payload,
                limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
                offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
                conditions: {
                    ...payload.conditions,
                    exportOptions: {
                        sendByEmail,
                        emailsTo,
                        format: {
                            fileFormat: report?.exportType || "json",
                            formatOptions: "{}"
                        },
                        culture: report.lang,
                        timeZone: getTimeZone(timeZone),
                        folderName: "px-customer-reports",
                        filePrefixName: "deviceSummaryInfoReport",
                        columns: [
                            {
                                "column": "groupName",
                                "header": "Groups"
                            },
                            {
                                "column": "idLabel",
                                "header": "Unit Label"
                            },
                            {
                                "column": "unitSN",
                                "header": "Unit SN"
                            },
                            {
                                "column": "notes",
                                "header": "Notes"
                            },
                            {
                                "column": "totalEngineHours",
                                "header": "Engine Hours Total"
                            },
                            {
                                "column": "engineRunningTime",
                                "header": "Engine Running Time (hrs)"
                            },
                            {
                                "column": "currentAddress",
                                "header": "Current Address"
                            },
                            {
                                "column": "daysAtCurrentAddress",
                                "header": "# of days at current address"
                            },
                            {
                                "column": "previousAddress",
                                "header": "Previous Address"
                            },
                            {
                                "column": "lastEventTime",
                                "header": "Last Event Time"
                            }
                        ]
                    }
                }
            }
        }
        let data = yield call(
            clientQuery,
            `query getDeviceSummaryInfoReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
                Report: getDeviceSummaryInfoReport (conditions: $conditions, limit: $limit, offset: $offset) {
                  ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
                        `reportInstanceId`
                        : `
                    items {
                        id
                        idLabel
                        noData
                    }
                    total
                    limit
                    offset
                    category
                    reportInstanceId
                `}
                }
              }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
        yield handleAsyncReport(report, data);

    } catch (exc) {
        ExceptionManager(exc, 'reports/deviceSummaryInfoReport', 'getDeviceSummaryInfoReport');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}

export default getDeviceSummaryInfoReport;
