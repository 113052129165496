import {
  ENTITY_PICKER_GET_ENTITY_LIST,
  ENTITY_PICKER_GET_ENTITY_LIST_RESPONSE
} from 'Redux/actionTypes';

export const getEntityList = (data) => ({
  type: ENTITY_PICKER_GET_ENTITY_LIST,
  payload: { data },
});

export const getEntityListResponse = (data) => ({
  type: ENTITY_PICKER_GET_ENTITY_LIST_RESPONSE,
  payload: { data },
});