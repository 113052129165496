// Action types.
import {
  VIDEO_MAIN_OPEN_ADVANCED_FILTERS,
} from 'Redux/actionTypes';

export const openVideoAdvancedFilters = (open, parentTab) => {
  return {
    type: VIDEO_MAIN_OPEN_ADVANCED_FILTERS,
    payload: { open, parentTab },
  };
};

