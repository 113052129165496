import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import LoginLogo from "../../common/LoginLogo";
import { Button, ToggleSwitch } from "Components";
import { endpoints } from 'Core/defaultValues';
import { FormattedMessage } from "react-intl";

function TermsAndCondition(props) {
  const { messages, locale } = useIntl();
  const { sendTermsAndCondition,callback } = props;
  const [url, setUrl] = useState(`${endpoints.TERMS_CONDITION_URL}index-en.html`);
  const [accept, SetAccept] = useState(false);

  useEffect(() => {
    setUrl(`${endpoints.TERMS_CONDITION_URL}index-${locale}.html`);
  }, [locale]);

  return (
    <>
      <Box
        style={{
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          width: "100%",
        }}
      >
        <div style={{ width: "500px" }} className="background-white-login">
          <Box style={{ textAlign: "center" }}>
            <LoginLogo urlImgLogo={"/assets/images/verticalLogo.png"} />
            <div style={{ height: "calc(100vh - 300px)", width: "100%" }}>
              <div
                style={{
                  border: "1px solid black",
                  height: "calc(100% - 130px)",
                  width: "100%",
                }}
              >
                <iframe
                  loading="lazy"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  src={url}
                ></iframe>
              </div>
              <div style={{ width: "100%", paddingTop: 10 }}>
                <label className="text-bold" style={{ marginRight: 10 }}>
                  {messages["login_accept_terms_condition"]}
                  <span class="text-danger"> *</span>
                </label>
                <ToggleSwitch
                  id={'terms-condition-aceppt'}
                  medium
                  blueToggle
                  optionLabels={[messages["yes"], messages["no"]]}
                  checked={accept}
                  onChange={(checked) => {
                    SetAccept(checked);
                  }}
                />
              </div>

              <div style={{ width: "100%", paddingTop: 10 }}>
                <Button
                  id={"btn-save-terms"}
                  type="button"
                  disabled={!accept}
                  onClick={() => {
                    sendTermsAndCondition();
                  }}
                  className="btn-blue vehicleTrailsButton"
                >
                  {messages["login_continue"]}
                </Button>
              </div>
              <div style={{ paddingTop: 10,paddingBottom:10 }}>
                <Button
                  id="btnBack"
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                    position: "relative",
                    background: 'transparent',
                    border: 0
                  }}
                  onClick={()=>{
                    callback()
                  }}
                  className="login-page-link"
                >
                  <FormattedMessage id="forgotPassword_back" />
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </Box>
    </>
  );
}

export default TermsAndCondition;
