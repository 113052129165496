import React from "react";
import { endpoints } from "Core/defaultValues";
import { Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";

function SocialMediaButtons() {
  const currentDomain =
    window.location.hostname === "localhost"
      ? `http://localhost:40000`
      : `https://${window.location.hostname}`;

  return (
    <div className="ctn-options-login">
      <div className="ctn-social-media">
        <a
          id="btnSigninWithGoogle"
          type="link"
          className="link-social-media"
          href={`${endpoints.COGNITO_DOMAIN_USER_POOL_EXTERNAL}oauth2/authorize?response_type=code&client_id=${endpoints.COGNITO_CLIENT_ID}&redirect_uri=${currentDomain}/login-social-media&state=STATE&identity_provider=Google`}
        >
          <div id="btnSignin" className="btn-login-social-media margin-top-10 ">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <img
                  src="/assets/images/google.svg"
                  alt="image"
                  className="social-media-icons"
                />
              </Grid>
              <Grid item>
                <span className="txt-login-google">
                  <FormattedMessage id="login_signInWithGoogle" />
                </span>
              </Grid>
            </Grid>
          </div>
        </a>
        {/*<a
          id="btnSigninWithFacebook"
          className="link-social-media"
          type="link"
          href={`${endpoints.COGNITO_DOMAIN_USER_POOL_EXTERNAL}oauth2/authorize?response_type=code&client_id=${endpoints.COGNITO_CLIENT_ID}&redirect_uri=${currentDomain}/login-social-media&state=STATE&identity_provider=Facebook`}
        >
          <div className="btn-login-social-media margin-top-10">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <img
                  src="/assets/images/facebook.svg"
                  alt="image"
                  className="social-media-icons facebook-icon"
                />
              </Grid>
              <Grid item>
                <span className="txt-login-google">
                  <FormattedMessage id="login_signInWithFacebook" />
                </span>
              </Grid>
            </Grid>
          </div>
        </a>*/}
        <a
          id="btnSigninWithApple"
          type="link"
          className="link-social-media"
          href={`${endpoints.COGNITO_DOMAIN_USER_POOL_EXTERNAL}oauth2/authorize?response_type=code&client_id=${endpoints.COGNITO_CLIENT_ID}&redirect_uri=${currentDomain}/login-social-media&state=STATE&identity_provider=SignInWithApple`}
        >
          <div className="btn-login-social-media margin-top-10 ">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <img
                  src="/assets/images/apple-icon.svg"
                  alt="image"
                  className="social-media-icons"
                />
              </Grid>
              <Grid item>
                <span className="txt-login-google">
                  <FormattedMessage id="login_signInWithApple" />
                </span>
              </Grid>
            </Grid>
          </div>
        </a>
      </div>
    </div>
  );
}

export default SocialMediaButtons;
