// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    MAP_GEOFENCES_DELETE_GEOFENCE_GROUP,
} from "Redux/actionTypes";

// Actions
import {
    mapGeofencesDeleteGeofenceGroupResponse,
    //getKmls
} from "Redux/actions";

// Helpers
import { clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* deleteGeofenceGroupRequest({ payload }) {
    try {
        const { ids, newId } = payload;

        let mutations = [];
        ids.forEach((id, index) => {
          mutations.push(
            `Group${index}: deleteGeofenceGroup(
              id: ${id}
              ${newId ? `moveChildsToGroupId: ${newId}` : ''}
            )`
          );
        });
    
        let mutation = `mutation { ${mutations.join(" ")} }`;
    
        let response = yield call(
          clientMutation,
          mutation,
          {},
          endpoints.GRAPHQL_GENERAL,
        );

        if (!response) throw `Error while deleting geofences groups, ${ids} ${newId}`;

        yield put(mapGeofencesDeleteGeofenceGroupResponse({ ...response, error: false }));
        //yield put(getKmls()); //updates Json Files
    } catch (exc) {
        yield put(mapGeofencesDeleteGeofenceGroupResponse({ error: true }));
        ExceptionManager(
            exc,
            "realtimeMaps/main/components/geofences/components/geofenceGroupDelete/redux/saga",
            "deleteGeofenceGroupRequest"
        );
    }
}

function* watchDeleteGeofenceGroupMethods() {
    yield takeEvery(MAP_GEOFENCES_DELETE_GEOFENCE_GROUP, deleteGeofenceGroupRequest);
}

export default function* rootSaga() {
    yield all([fork(watchDeleteGeofenceGroupMethods)]);
}

