export default {
    realtimeMapsRoutes_uploadRoutes_upload: "Upload Routes",
    realtimeMapsRoutes_uploadRoutes_name: "Name",
    realtimeMapsRoutes_uploadRoutes_attachmentsFilesAllowed: 'csv. 25 Stops Maximum, 4MB',
    realtimeMapsRoutes_uploadRoutes_latitude: 'Latitude',
    realtimeMapsRoutes_uploadRoutes_longitude: 'Longitude',
    realtimeMapsRoutes_uploadRoutes_address: 'Address',
    realtimeMapsRoutes_uploadRoutes_city: 'City',
    realtimeMapsRoutes_uploadRoutes_state: 'State',
    realtimeMapsRoutes_uploadRoutes_zip: 'Zip',
    realtimeMapsRoutes_uploadRoutes_note: 'Notes',
    realtimeMapsRoutes_uploadRoutes_cancel: "Cancel",
    realtimeMapsRoutes_uploadRoutes_upload_route: "Upload",
    realtimeMapsRoutes_uploadRoutes_template: 'Download Template',
}