import React from "react";
import { Button } from "Components";
import { FormattedMessage } from "react-intl";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

const LinkToLogin = ({ current_reseller }) => {

  const location = useLocation()

  return (
    <Box m="25px">
      <div style={{ paddingTop: 10 }}>
        <Button
          id="btnBack"
          style={{
            fontSize: "15px",
            fontWeight: "400",
            position: "relative",
          }}
          type="link"
          className="login-page-link"
          url={
            current_reseller?.key && location.pathname.includes("reseller")
              ? `/reseller/${current_reseller?.key}`
              : "/login"
          }
        >
          <FormattedMessage id="forgotPassword_back" />
        </Button>
      </div>
    </Box>
  );
};

export default LinkToLogin;
