// Dependencies
import { all } from 'redux-saga/effects';

// Components
import vidFleetVideoSaga from 'Modules/.common/components/VidFleetVideo/redux/saga';
import infoBubbleSaga from 'Modules/.common/components/InfoBubble/redux/saga';
import sharedMediaSaga from 'Modules/.common/components/SharedMedia/redux/saga';
import entityPickerSaga from 'Modules/.common/components/EntityPicker/redux/saga';
import accountInformationSaga from 'Modules/.common/components/AccountInformation/redux/saga';
import smartwitnessVideoSaga from 'Modules/.common/components/SmartWitnessVideo/redux/saga';
import iconPickerSaga from 'Modules/.common/components/IconPicker/redux/saga';
import tagPickerSaga from 'Modules/.common/components/TagPicker/redux/saga';
import entityTagPickerSaga from 'Modules/.common/components/EntityTagPicker/redux/saga';
import newEntityTagPickerSaga from 'Modules/.common/components/NewEntityTagPicker/redux/saga';
import SliderRangePanel from 'Modules/.common/components/SliderRangePanel/redux/saga';

// Merge sagas
export default function* rootSaga() {
  yield all([
    vidFleetVideoSaga(),
    infoBubbleSaga(),
    sharedMediaSaga(),
    entityPickerSaga(),
    accountInformationSaga(),
    smartwitnessVideoSaga(),
    iconPickerSaga(),
    tagPickerSaga(),
    entityTagPickerSaga(),
    newEntityTagPickerSaga(),
    SliderRangePanel()
  ]);
}