import React, { Fragment } from "react";
import PropTypes from "prop-types";
import './toggleSwitch.scss';

const ToggleSwitch = ({ id, name, checked, onChange, optionLabels, xSmall, small, medium, large, xLarge, xxLarge, normal, mediuml, disabled, blackToggle, blueToggle, mapToggle, style, label }) => {
  function handleKeyPress(e) {
    if (e.keyCode !== 32) return;

    e.preventDefault();
    onChange(!checked)
  }

  let styleTogle = blueToggle ? 'blue' : 'toggle';
  styleTogle = mapToggle ? 'map' : styleTogle;
  styleTogle = blackToggle ? 'black' : styleTogle;
  let newStyle = { ...(style || {}) };
  if (normal && optionLabels?.length) {
    let lettersCount = checked ? optionLabels[0]?.length : optionLabels[1]?.length;
    let width = (lettersCount * 6) + 46;
    width = width < 54 ? 54 : width;
    newStyle = { ...newStyle, width: width };
  }

  return (
    <Fragment>
      <div
        style={newStyle}
        className={`${styleTogle}-switch` + (xSmall ? " x-small-switch" : "") + (small ? " small-switch" : "") + (medium ? " medium-switch" : "") + (large ? " large-switch" : "") + (xLarge ? " xlarge-switch" : "") + (xxLarge ? " xxlarge-switch" : "")+ (normal ? " normal-switch" : "") + (mediuml ? " mediuml-switch" : "")}
      >
        <input
          type="checkbox"
          name={name}
          className={`${styleTogle}-switch-checkbox`}
          id={id}
          checked={checked}
          onChange={e => onChange(e.target.checked)}
          disabled={disabled}
        />
        {
          id ? (
            <label className={`${styleTogle}-switch-label`}
              htmlFor={id}
              tabIndex={disabled ? -1 : 1}
              onKeyDown={(e) => { handleKeyPress(e) }}>
              <span
                className={
                  disabled
                    ? `${styleTogle}-switch-inner ${styleTogle}-switch-disabled`
                    : `${styleTogle}-switch-inner`
                }
                data-yes={optionLabels[0]}
                data-no={optionLabels[1]}
                tabIndex={-1}
              />
              <span
                className={
                  disabled
                    ? `${styleTogle}-switch-switch ${styleTogle}-switch-disabled`
                    : `${styleTogle}-switch-switch`
                }
                tabIndex={-1}
              />
            </label>
          ) : null
        }
      </div>
      {label && <span style={{paddingLeft: 5}}>{label}</span>}
    </Fragment>
  );
}

// Set optionLabels for rendering.
ToggleSwitch.defaultProps = {
  optionLabels: ["Yes", "No"],
};

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  optionLabels: PropTypes.array,
  small: PropTypes.bool,
  disabled: PropTypes.bool
};

export default ToggleSwitch;
