import React, { useState, memo, useRef, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { Container } from '@mui/material';

import TagPicker from 'Modules/.common/components/NewTagPicker';

import './test.scss';
import {
  Grid,
  Paper,
  Grow
} from '@mui/material';

const TestComponent = (props) => {
  const dispatch = useDispatch();

  const { user} = props;

  const { messages } = props.intl;
  const [ values, setValues ] = useState([]);

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: 24 }}>
      <Paper elevation={0} style={{ width: 304, padding: 24, height: 380 }}>
        <TagPicker
          //entity="Unit"
          label="Select a tag"
          placeholder="Type to search"
          height="calc(100vh - 150px)"
          onChange={(newItems) => {
            setValues(newItems);
          }}
          values={values}
        />
      </Paper>
    </Container>
  );
};

export default injectIntl(memo(TestComponent));
