import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox } from 'Components/uiControls';
import {
    ContextMenu,
    Button, Pill
} from 'Components';

import {
    Grid,
    Typography,
    Chip,
    Icon
} from "@mui/material";

import { getTagColor, cutNotes } from 'Core/data/Helpers';

import { Waypoint } from "react-waypoint";

// Styles
import '../styles.scss';

const EntityTagPickerSingle = (props) => {
    const { messages } = useIntl();

    const inputRef = useRef();
    const containerRef = useRef();

    const {
        searchText,
        setSearchText,
        page,
        setPage,
        dataPaginated,
        total,
        values,
        onChange,
        entity,
        disabled,
        onOpen,
        hiddenCheckbox,
        useIncludesIdle
    } = props;

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        } else if (onOpen){
            openMenu();
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open]);

    const handleClickOutside = (e) => {
        if (containerRef?.current?.contains(e.target)) {
            return;
        }

        setOpen(false);
        setSearchText("");
    };

    const openMenu = useCallback((e) => {
        if (e) e.preventDefault();
        setOpen(!open);
        inputRef?.current?.focus();
    });

    return (
        <div ref={containerRef} open={open} className="gtc-entity-tag-picker-container">
            <div className="gtc-entity-tag-picker-input-container">
                {
                    !open &&
                    <React.Fragment>
                        <div
                            className={`gtc-entity-tag-picker-value-container ${disabled ? 'gtc-entity-tag-picker-disabled' : ''}`}
                            onClick={(e) => {
                                if (!disabled) {
                                    openMenu(e);
                                }
                            }}
                        >
                            <span className='text-muted'>
                                {messages[`entityTagPicker_search${entity?.id}`]}
                            </span>
                        </div>

                        <div
                            style={{
                                marginTop: 8,
                                maxHeight: 178,
                                overflow: 'hidden auto'
                            }}
                        >
                            {
                                (values?.length > 0) && values.map((value, index) => {
                                    return (
                                        <Chip
                                            label={cutNotes(value?.label, 15)}
                                            key={index}
                                            style={{
                                                color: "white",
                                                backgroundColor: "#66bb6a",
                                                marginBottom: 8
                                            }}
                                            clickable={true}
                                            deleteIcon={<Icon style={{ fontSize: 14 }}>close</Icon>}
                                            onDelete={() => {
                                                let newValues = values.filter(nv => nv.id != value.id);
                                                if (onChange) onChange(newValues);
                                            }}
                                        />
                                    )
                                })
                            }
                        </div>
                    </React.Fragment>
                }

                {
                    open &&
                    <input
                        ref={inputRef}
                        className="gtc-entity-tag-picker-input input-normal form-control"
                        type="text"
                        value={searchText}
                        placeholder={messages[`entityTagPicker_search${entity?.id}`]}
                        onChange={(e) => {
                            setSearchText(e?.target?.value);
                        }}
                        autoFocus
                        checked={onOpen}
                        disabled={disabled}
                    />
                }

                <div className="gtc-entity-tag-picker-menu-arrow-button">
                    <Button
                        type="icon"
                        icon={open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                        placement="bottom"
                        className="btn-xs"
                        fontSize="inherit"
                        disabled={disabled}
                        onClick={openMenu}
                    />
                </div>
            </div>

            <ContextMenu
                style={{
                    width: '100%',
                    marginTop: 2,
                    position: 'absolute',
                    maxHeight: 250,
                    overflow: 'auto'
                }}
                open={open}
            >
                <div className="gtc-entity-tag-picker-options-container">
                    {
                        dataPaginated?.length == 0 && <div className="text-muted" style={{ padding: 8 }}>{messages['entityTagPicker_noItemsFound']}</div>
                    }

                    {
                        dataPaginated?.map((item, index) => {
                            let checked = values?.find(v => v?.id == (item?.id || item?.driverId)) ? true : false;
                            return (
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    wrap="nowrap"
                                    key={index}
                                    style={{
                                        padding: 8
                                    }}
                                >
                                    {
                                        !hiddenCheckbox && (
                                            <Grid item>
                                                <Checkbox
                                                    id={`item-${index}-${item?.id || item?.driverId}`}
                                                    value={checked}
                                                    onChange={() => {
                                                        let newValues = [...values];
                                                        if (values?.find(v => v?.id == (item?.id || item?.driverId))) {
                                                            newValues = values?.filter(v => v?.id != (item?.id || item?.driverId));
                                                        } else {
                                                            newValues.push({
                                                                id: (item?.id || item?.driverId),
                                                                name: entity?.id,
                                                                label: item?.name || item?.label || item?.userName || "",
                                                                status: item?.status
                                                            });
                                                        }

                                                        if (onChange) onChange(newValues);
                                                    }}
                                                />
                                            </Grid>
                                        )
                                    }

                                    <Grid item xs zeroMinWidth style={useIncludesIdle ? {display: 'flex', justifyContent: 'space-between'} : {}}>
                                        <Typography noWrap className={useIncludesIdle ? 'component-single-inactive' : ''}>
                                            <span>{item?.label || item?.name || item?.userName || ""}</span>
                                        </Typography>
                                        {
                                            useIncludesIdle && item.status === 0 && (
                                                <div>
                                                    <Grid item xs key={index}>
                                                        <Pill
                                                            key={index}
                                                            textField="label"
                                                            item={{label: messages['entityTagPicker_inactive']}}
                                                            clickable={false}
                                                            outlined={true}
                                                            className={'single-inactive-pill'}
                                                            style={{ border: '0', width: '110px' }}
                                                            colorField="default"
                                                            colorSelection="66bb6a"
                                                        />
                                                    </Grid>
                                                </div>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            )
                        })
                    }

                    <Waypoint
                        onEnter={() => {
                            if (dataPaginated?.length < total) {
                                setPage(page + 1);
                            }
                        }}
                    >
                        <span className="text-muted mt-5">
                            {dataPaginated?.length < total ? messages['loading'] : ""}
                        </span>
                    </Waypoint>
                </div>
            </ContextMenu>
        </div>
    )
}

export default EntityTagPickerSingle;
