// Action types.
import { 
  ROLE_SEARCH,
  ROLE_SEARCH_RESPONSE, 
  ROLE_GET,
  ROLE_GET_RESPONSE,
  ROLE_CREATE,
  ROLE_CREATE_RESPONSE, 
  ROLE_UPDATE,
  ROLE_UPDATE_RESPONSE, 
  ROLE_DELETE,
  ROLE_DELETE_RESPONSE,
} from "Redux/actionTypes";

export const searchRoles = (data) => {
  return {
    type: ROLE_SEARCH,
    payload: data,
  };
};

export const searchRolesResponse = (data) => {
  return {
    type: ROLE_SEARCH_RESPONSE,
    payload: data,
  };
};

export const getRole = (data) => {
  return {
    type: ROLE_GET,
    payload: data,
  };
};

export const getRoleResponse = (data) => {
  return {
    type: ROLE_GET_RESPONSE,
    payload: data,
  };
};

export const createRole = (data) => {
  return {
    type: ROLE_CREATE,
    payload: data,
  };
};

export const createRoleResponse = (data) => {
  return {
    type: ROLE_CREATE_RESPONSE,
    payload: data,
  };
};

export const updateRole = (data) => {
  return {
    type: ROLE_UPDATE,
    payload: data,
  };
};

export const updateRoleResponse = (data) => {
  return {
    type: ROLE_UPDATE_RESPONSE,
    payload: data,
  };
};

export const deleteRole = (data) => {
  return {
    type: ROLE_DELETE,
    payload: data,
  };
};

export const deleteRoleResponse = (data) => {
  return {
    type: ROLE_DELETE_RESPONSE,
    payload: data,
  };
};