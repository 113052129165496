import {
  VIDEO_ALERT_GET_EVENT_MEDIA,
  VIDEO_ALERT_GET_EVENT_MEDIA_RESPONSE,
  CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
  loading: false,
  response: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case VIDEO_ALERT_GET_EVENT_MEDIA:
      return { ...state, response: null, loading: true };

    case VIDEO_ALERT_GET_EVENT_MEDIA_RESPONSE:
      return { ...state, response: action.payload.data, loading: false };

    case CLEAR_REDUX:
      return ["", "VIDEO_ALERT"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
}