export const LEGACY_API_KEY_SEARCH = "LEGACY_API_KEY_SEARCH";
export const LEGACY_API_KEY_SEARCH_RESPONSE = "LEGACY_API_KEY_SEARCH_RESPONSE";
export const LEGACY_API_KEY_GET = "LEGACY_API_KEY_GET";
export const LEGACY_API_KEY_GET_RESPONSE = "LEGACY_API_KEY_GET_RESPONSE";
export const LEGACY_API_KEY_CREATE = "LEGACY_API_KEY_CREATE";
export const LEGACY_API_KEY_CREATE_RESPONSE = "LEGACY_API_KEY_CREATE_RESPONSE";
export const LEGACY_API_KEY_UPDATE = "LEGACY_API_KEY_UPDATE";
export const LEGACY_API_KEY_UPDATE_RESPONSE = "LEGACY_API_KEY_UPDATE_RESPONSE";
export const LEGACY_API_KEY_DELETE = "LEGACY_API_KEY_DELETE";
export const LEGACY_API_KEY_DELETE_RESPONSE = "LEGACY_API_KEY_DELETE_RESPONSE";

export const LEGACY_API_KEY_GET_COUNT = "LEGACY_API_KEY_GET_COUNT";
export const LEGACY_API_KEY_GET_COUNT_RESPONSE = "LEGACY_API_KEY_GET_COUNT_RESPONSE";