import {
    cutNotes,
    convertWhiteColor,
    getFormattedDateByTimeZone,
    formatAddress,
  } from "Core/data/Helpers";
  import { getEventColor } from "Components/Map/utils";
  import { getVidFleetEvent } from "Core/data/VideoEvents";
  import { Timezone } from "Components/uiControls";
  import React from 'react'
  import { Icon, Grid } from '@mui/material';
  
  
  const IconMarker = (props) => {
    const { color = "orangered", url, name } = props;
    if (url) {
      return <img src={url} alt={name} style={{ width: 25, height: 25 }} />;
    }
  
    return (
      <svg
        className="markerIcon"
        xmlns="http://www.w3.org/2000/svg"
        //transform={`rotate(${props.heading})`}
        width="40"
        height="40"
        viewBox="0 0 40 40"
        aria-labelledby="title"
      >
        <title id="title">{name}</title>
        <path
          fill={color}
          d="M 2.64,20.41
                        C 2.64,20.41 19.57,11.59 19.57,11.59
                        19.57,11.59 2.64,3.27 2.64,3.27
                        2.64,3.27 5.46,11.59 5.46,11.59
                        5.46,11.59 2.64,20.41 2.64,20.41 Z"
        />
      </svg>
    );
  };
  
  const TimeZoneControl = (props) => {
    const { row, timeZone, locale } = props;
    return (
      <Grid item xs zeroMinWidth>
        <div style={{ display: "flex" }}>
          <Timezone
            date={new Date(row?.unitTime)}
            dateFormat={"dddd, MMMM DD YYYY"}
            timeFormat="hh:mm:ss a"
            timezone={timeZone}
            className="labelTrailDate"
            customFormat={true}
            locale={locale}
            noWrap={false}
            oneControl={true}
          />
        </div>
      </Grid>
    );
  };
  
  const TrailAddress = (props) => {
    const { trail, landmarkGroups, landmarkName } = props;
  
    let color = getEventColor(trail?.eventName);
    if (landmarkGroups && landmarkGroups.length) {
      let landmarkGroup = landmarkGroups.find(
        (lg) => lg.id == trail?.landmark?.groupId
      );
      if (landmarkGroup) color = landmarkGroup?.color;
    }
  
    return (
      <React.Fragment>
        {trail?.landmark ? (
          <Grid container wrap="nowrap">
            <Grid item>
              <Icon
                style={{
                  color: convertWhiteColor(color),
                  marginRight: 8,
                  /* position: 'absolute', */
                  left: 45 /* ,
                    bottom: 32 */,
                }}
              >
                flag
              </Icon>
            </Grid>
            <Grid item style={{ paddingTop: 3 }}>
              <label>{landmarkName}</label>
            </Grid>
          </Grid>
        ) : (
          <span>{landmarkName}</span>
        )}
      </React.Fragment>
    );
  };
  
  export const createDataToShow = (
    vehicleTrails,
    landmarkGroups,
    messages,
    prefSettings = null,
    vehicle,
    lang
  ) => {
    const data = [];
  
      if (vehicleTrails && vehicleTrails.length > 0) {
        let showWayPoint = false;
        if (prefSettings) {
          const { trailShowWaypointNumbers } = prefSettings;
          if (trailShowWaypointNumbers) showWayPoint = true;
        }
  
        vehicleTrails.forEach((element) => {
          let newAddress = formatAddress(element) || "N/A";
          let landmarkAdress = "";
          let landmarkName = "";
  
          if (element?.landmark) {
            let lAddress = "",
              lCity = "",
              lState = "",
              lZip = "",
              lCountry = "";
            lAddress = element?.landmark?.address
              ? ` ${element?.landmark?.address}`
              : "";
            lCity = element?.landmark?.city ? `, ${element?.landmark?.city}` : "";
            lState = element?.landmark?.state
              ? `, ${element?.landmark?.state}`
              : "";
            lZip = element?.landmark?.zip ? `, ${element?.landmark?.zip}` : "";
            lCountry = element?.landmark?.country
              ? `, ${element?.landmark?.country}`
              : "";
            //landmarkAdress = `(${element?.landmark?.name}) ${lAddress}${lCity}${lState}${lZip}${lCountry}`;
            landmarkAdress = `(${element?.landmark?.name}) ${newAddress}`;
            landmarkName = element?.landmark?.name || "";
          }
  
          let color;
          let icon = (
            <IconMarker
              color={getEventColor(element?.eventName)}
              name={element?.eventName}
            />
          );
          let event;
  
          if (element?.eventCode && element?.eventCode?.includes("CameraEvent")) {
            event = getVidFleetEvent(element?.eventCode);
            color = event?.color;
            icon = <IconMarker url={event?.icon} name={element?.eventName} />;
          }
          data.push({
            trail: element,
            id: element.id,
            driverName:
              element.driverName && element.driverId > 0
                ? element.driverName || ""
                : "",
            speed: element.speed ? element.speed : "",
            latitude: element.latitude,
            longitude: element.longitude,
            name: showWayPoint
              ? `${element?.wayPoint} - ${
                  event && event?.lanKey
                    ? messages[event?.lanKey]
                    : element?.eventName || "N/A"
                }`
              : event && event?.lanKey
              ? messages[event?.lanKey]
              : element?.eventName || "N/A",
            color: color || getEventColor(element?.eventName),
            path: "../src/components/Map/svg/marker.svg",
            description:
              getFormattedDateByTimeZone(
                element?.unitTime,
                vehicle?.timeZone?.name,
                messages,
                "dddd, MMMM DD YYYY, hh:mm:ss a",
                false,
                lang
              ) || "",
            customDescription: (
              <TimeZoneControl
                row={element}
                locale={lang}
                timeZone={vehicle?.timeZone?.name}
              />
            ),
            descriptionDetail: newAddress,
            address: newAddress,
            detail: (
              <TrailAddress
                trail={element}
                landmarkGroups={landmarkGroups}
                landmarkName={landmarkName}
              />
            ),
            unitTime: element?.unitTime,
            icon: icon,
          });
        });
      }
   
    return data;
  };
  
  