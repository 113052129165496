import {
	//TYPES
  GET_DAILY_BEHAVIOR,
  GET_DAILY_BEHAVIOR_RESULT,
  UPDATE_POINTS_BEHAVIOR,
  UPDATE_GRADES_BEHAVIOR,
  SET_BEHAVIOR_POINTS,
  SET_BEHAVIOR_POINTS_RESULT,
  GET_BEHAVIOR_TRENDS,
  GET_BEHAVIOR_TRENDS_RESULT,
  BEHAVIOR_TRENDS_CHART_INFO,
  GET_POINTS_BEHAVIOR,
  GET_POINTS_BEHAVIOR_RESULTS,
  CREATE_NEW_CUSTOM_SPEED,
  CREATE_NEW_CUSTOM_SPEED_RESULTS,
  DELETE_CUSTOM_SPEED,
  DELETE_CUSTOM_SPEED_RESULT,
  EXPORT_DAILY_BEHAVIOR,
  EXPORT_DAILY_BEHAVIOR_RESULT
} from 'Redux/actionTypes';

export const getDailyBehavior = (data) => ({
	type: GET_DAILY_BEHAVIOR,
	payload: { data },
});

export const getDailyBehaviorResult = (data) => {
  return {
    type: GET_DAILY_BEHAVIOR_RESULT,
    payload: { data },
  }
};

export const updatePointsBehavior = (data) => ({
  type: UPDATE_POINTS_BEHAVIOR,
	payload: { data },
})

export const updateGradesBehavior = (data) => {
  return {
    type: UPDATE_GRADES_BEHAVIOR,
    payload: { data },
  }
}

export const setBehaviorPoints = (data) => {
  return {
    type: SET_BEHAVIOR_POINTS,
    payload: { data },
  };
}

export const setBehaviorPointsResult = (data) => ({
  type: SET_BEHAVIOR_POINTS_RESULT,
	payload: { data },
})


export const getBehaviorTrendsRequest = (data) => ({
	type: GET_BEHAVIOR_TRENDS,
	payload: { data },
});

export const getBehaviorTrendsResult = (data) => ({
	type: GET_BEHAVIOR_TRENDS_RESULT,
	payload: { data },
});

export const getBehaviorTrendsChartInfo = (data) => ({
	type: BEHAVIOR_TRENDS_CHART_INFO,
	payload: { data },
});

export const getPointsBehavior = () => ({
	type: GET_POINTS_BEHAVIOR,
	payload: {},
});

export const getPointsBehaviorResult = (data) => ({
	type: GET_POINTS_BEHAVIOR_RESULTS,
	payload: {data},
});

export const createCustomSpeed = (data) => ({
  type: CREATE_NEW_CUSTOM_SPEED,
	payload: {data}
})

export const createCustomSpeedResult = (data) => ({
  type: CREATE_NEW_CUSTOM_SPEED_RESULTS,
	payload: {data}
});


export const deleteCustomSpeed = (data) => ({
  type: DELETE_CUSTOM_SPEED,
	payload: { data }
});

export const deleteCustomSpeedResult = (data) => ({
  type: DELETE_CUSTOM_SPEED_RESULT,
	payload: {data}
});

export const exportDailyBehavior = (data) => ({
  type: EXPORT_DAILY_BEHAVIOR,
	payload: {data}
});

export const exportDailyBehaviorResult = (data) => ({
  type: EXPORT_DAILY_BEHAVIOR_RESULT,
	payload: {data}
});





