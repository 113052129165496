import * as React from "react";
import Box from "@mui/material/Box";
import { Icon, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";

export const ModalImg = ({ img, openImg, setOpenImg }) => {
  const handleClose = () => setOpenImg(false);

  return (
    <div>
      <Modal
        open={openImg}
        onClose={handleClose}
      >
        <Box>
          <div style={{
              position: "absolute",
              right: "5px"
          }}>
            <IconButton
              aria-label="close"
              style={{ color: "white" }}
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
              size="large"
            >
              <Icon
                className={"icon-vidFleet-play-replay"}
                style={{ fontSize: 50, color: "white" }}
              >
                close
              </Icon>
            </IconButton>
          </div>
          <div
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, 0)",
              width: "80%",
              top: "15px",
              bottom: "30px",
            }}
          >
            <img src={img} style={{ width: "100%", height: "100%" }} />
          </div>
        </Box>
      </Modal>
    </div>
  );
};
