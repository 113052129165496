//action types
export const GET_USER_INFO = 'AUTH/GET_USER_INFO';
export const GET_USER_INFO_RESULT = 'AUTH/GET_USER_INFO_RESULT';

export const GET_SYSTEM_PREF_RESULT = 'AUTH/GET_SYSTEM_PREF_RESULT';

export const LOGOUT = 'AUTH/LOGOUT';

export const REFRESH_TOKEN = 'AUTH/REFRESH_TOKEN';
export const REFRESH_TOKEN_RESULT = 'AUTH/REFRESH_TOKEN_RESULT';

export const GET_ACCESS_ELD_APOLLO = 'GET_ACCESS_ELD_APOLLO';
export const GET_ACCESS_ELD_APOLLO_RESULT = 'GET_ACCESS_ELD_APOLLO_RESULT';

export const CHANGE_SALESFORCE_STATUS = 'CHANGE_SALESFORCE_STATUS';
export const CHANGE_SALESFORCE_STATUS_RESULT = 'CHANGE_SALESFORCE_STATUS_RESULT';