import React, { useEffect, useRef, useState, useMemo, useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation } from "react-router-dom";
import { useIntl } from 'react-intl'
import moment from 'moment';

// Material UI
import { Grid } from '@mui/material';
// Components
import { Loading, Button } from 'Components';
import Tabs, { TabContainer, TabButton, TabIconButton } from 'Components/Tabs';
import VidFleetVideo from 'Modules/.common/components/VidFleetVideo';
import SmartWitnessVideo from 'Modules/.common/components/SmartWitnessVideo';
import SmartWitnessSnapshot from 'Modules/.common/components/SmartWitnessSnapshot';

import CameraSignalStatus from 'Modules/.common/components/VidFleetVideo/.components/cameraSignalStatus';
import { TypeObjectMap } from '../../../../components/Map/providers/enums';

import {
  getFormattedDateByTimeZone,
  getPreferencesByKey,
  checkDeviceData
} from 'Core/data/Helpers';
import { getWarningCategory } from 'Core/hooks/utils';

// Views
import Location from './.components/location';
import VehicleInfo from './.components/vehicleInfo';
import Settings from './.components/settings';
import Snapshot from './.components/snapshot';
import RequestClip from './.components/requestClip';
import LandmarkInfo from './.components/landmarkInfo';
import SearchMapInfo from './.components/searchMapInfo';
import GeofenceInfo from './.components/geofenceInfo';
import RoutePointInfo from './.components/routePointInfo';
import RoutePointInfoLog from './.components/routePointInfoLog';

import { MapUtilitiesTypes } from 'Modules/realtimeMaps/main/components/MapUtilitiesMenu/enums';
import { HasPermission } from 'Core/security';

// Styles
import './infoBubble.scss';
import '../../../../components/Map/providers/google/InfoWindow/style.scss'

// Actions
import {
  getMarkerDetails,
  getVehicleTrails,
  selectModuleMapSettings,
  setVehicleTrailToPrint,
  clipRequestOpenForm,
  searchUnitTypeEvents,
  clipRequestGetUnits,
  openTroubleshootDeviceModal,
  openOverViewUnit,
  getPublicMarkerDetails,
  openWarningsModal
} from 'Redux/actions';

const InfoBubble = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { messages, locale } = useIntl();

  const { position, type, id, deviceId, showStreetView, closeInfoBubble, mapReference, isEtaLink } = props;

  const loading = useSelector(state => state.infoBubbleRedux.loadingGetMarkerDetails);
  const response = useSelector(state => state.infoBubbleRedux.getMarkerDetailsResponse);
  const getMarkerDetailsId = useSelector(state => state.infoBubbleRedux.getMarkerDetailsId);

  const { units } = useSelector((state) => state.catalogsRedux);

  const authenticatedId = useSelector(state => state.securityRedux.authenticatedId);
  const user = useSelector(state => state.securityRedux.user);

  const requestClipResponse = useSelector(state => state.clipsRedux.requestClipResponse);
  const loadingCreateClip = useSelector(state => state.clipsRedux.loadingCreateClip);
  const availableClips = useSelector(state => state.clipsRedux.availableClips);

  const editLandMark = useSelector((state) => state.realtimeMapLandmarkCreateEditModuleRedux.response);
  const preferencesUser = useSelector(state => state.preferencesRedux.preferencesUser);
  const keyPreferences = "mapSettings";
  const streeViewContainer = useRef();
  const clipRequestRef = useRef();
  const smartwitnessRef = useRef();
  const [streetViewVisible, setStreetViewVisible] = useState(false);
  const [tabButton, setTabButton] = useState({ show: false, title: null });
  const [errors, setErrors] = useState({});
  const [internalVideoTab, setInternalVideoTab] = useState(0);
  const [disableBtn, setDisableBtn] = useState(false)
  const [tooltip, setTooltip] = useState('')
  const [changeIdUnit, setChangeIdUnit] = useState(0);
  const [preferences, setPreferences] = useState({});

  let currentTab = 0
  if (type == "Trail") {
    currentTab = parseInt(localStorage.getItem("info-bubble-last-tab") || 0);
  }

  const [mainTab, setMainTab] = useState(currentTab);
  const [isSmartWitnessVideoAvailable, setIsSmartWitnessVideoAvailable] = useState(false);

  useEffect(() => {
    setErrors({ markerDetails: null });
    if (type && id) {
      let idToSearch = id ? id.toString() : ''
      idToSearch = idToSearch.split('_')[1] || idToSearch
      if (!isEtaLink) {
        dispatch(getMarkerDetails({ type, id: idToSearch, unitId: deviceId }));
      } else {
        dispatch(getPublicMarkerDetails(deviceId))
      }
    }
  }, []);

  useEffect(() => {
    if ((editLandMark && !editLandMark?.error) && type && id && !isEtaLink) {
      let idToSearch = id ? id.toString() : ''
      idToSearch = idToSearch.split('_')[1] || idToSearch
      dispatch(getMarkerDetails({ type, id: idToSearch, unitId: deviceId }));
    }
  }, [editLandMark])

  useEffect(() => {
    if (streetViewVisible) {
      setErrors({ streetView: null });
      showStreetView(streeViewContainer.current, position, (googleStates, status) => {
        if (googleStates.ZERO_RESULTS === status || googleStates.UNKNOWN_ERROR === status) {
          let e = { streetView: messages['infoBubble_streetViewNotAvailable'] };
          setErrors(e);
        }
      });
    }
  }, [streetViewVisible]);

  useEffect(() => {
    if (response) {
      if (response.error) {
        setErrors({ markerDetails: messages['infoBubble_getMarkerDetailsError'] });
      } else {
        setErrors({ markerDetails: null });
      }
      // setDeviceInfo({
      //   ...deviceInfo,

      //   deviceName: response?.markerDetail?.unit?.label,
      //   deviceId: response?.markerDetail?.linkedDevice?.id
      // });

      //update data from the unit
      if (type === "Vehicle") {
        updateUnitForData(response)
      }
    }
  }, [response]);

  useEffect(() => {
    if (preferencesUser) {
      let newPreferences = getPreferencesByKey(preferencesUser, keyPreferences);
      setPreferences(newPreferences);
    }
  }, [preferencesUser]);

  useEffect(() => {
    if (requestClipResponse && requestClipResponse?.error) {
      setDisableBtn(true)
      setTooltip(messages['infoBubble_clipRequestError'])
    }
  }, [requestClipResponse])

  useEffect(() => {
    if (!loading) {
      setChangeIdUnit(getMarkerDetailsId)
    }
  }, [loading])

  const updateUnitForData = (data) => {

    if(!data?.markerDetail?.unit?.lastReading?.latitude || !data?.markerDetail?.unit?.lastReading?.longitude){
      return
    }

    //if not a natural number or float number
    if (isNaN(parseFloat(data?.markerDetail?.unit?.lastReading?.latitude)) || 
        !isFinite(data?.markerDetail?.unit?.lastReading?.latitude) ||
        isNaN(parseFloat(data?.markerDetail?.unit?.lastReading?.longitude)) || 
        !isFinite(data?.markerDetail?.unit?.lastReading?.longitude)
      ) {
      return;
    }

    //permision for animation trail on maps
    const animationTrailPermission = !!(HasPermission(user, 'animationmap', [281]) && preferences?.animationmap)

    mapReference.current?.updateObject({
      id: data.id,
      position: { lat: parseFloat(data?.markerDetail?.unit?.lastReading?.latitude), lng: parseFloat(data?.markerDetail?.unit?.lastReading?.longitude) },
      label: data?.markerDetail?.unit?.label,
      heading: data?.markerDetail?.unit?.lastReading?.heading || 0,
      color: data?.color || '',
      eventName: data?.markerDetail?.unit?.lastReading?.eventName,
      hasVideo: data?.markerDetail?.unit?.linkedUnitId > 0 || false,
      trail: animationTrailPermission ? data?.markerDetail?.unit?.lastReading?.trail || [] : [], //eval the permision to trail.
      unitTime: parseInt(data?.markerDetail?.unit?.lastReading?.unitTime), //item.unitTime,
      rowId: data?.rowId || 0
    }, TypeObjectMap.Marker);

    if(data?.markerDetail?.unit?.isOffline){
      //update the icon for offline devices
      mapReference?.current?.updateMarkerOfflineDevice(data?.id, data?.markerDetail?.unit?.isOffline)
    }

    //this validation preveng the movie modal when trail animation is active
    if(!animationTrailPermission || (animationTrailPermission && !data?.markerDetail?.unit?.lastReading?.trail?.length)){
      mapReference.current?.updateModalPosition({
        position: { 
          lat: parseFloat(data?.markerDetail?.unit?.lastReading?.latitude), 
          lng: parseFloat(data?.markerDetail?.unit?.lastReading?.longitude)
        } 
      })
    }
    
  }

  const hasVideo = useCallback(() => {
    if (response?.markerDetail?.linkedDevice && response?.markerDetail?.linkedDevice?.hasVideo) {
      return true
    }
    return false;
  });

  const getVideo = useMemo(() => {
    switch (response?.markerDetail?.linkedDevice?.manufacturerName) {
      case "VidFleet":
        const vidTabs = [];
        let sdCard = response?.markerDetail?.linkedDevice?.sdCard ? checkDeviceData(response?.markerDetail?.linkedDevice?.sdCard) : null
        if (response?.markerDetail?.vehicleTrail?.deviceData) {
          let clipID;
          let duration;
          let thumbnailUrl;
          try {
            let deviceDataObject = checkDeviceData(response?.markerDetail?.vehicleTrail?.deviceData);
            //const deviceDataObject = JSON.parse(JSON.parse(response?.markerDetail?.vehicleTrail?.deviceData));
            clipID = deviceDataObject.clipID;
            duration = deviceDataObject.duration;
            thumbnailUrl = deviceDataObject.thumbnailUrl;
          } catch (e) {
            console.log("Error while parse device data json vidfleet");
          }

          if (clipID && duration && !isEtaLink) {
            let eventTime = getFormattedDateByTimeZone(response?.markerDetail?.vehicleTrail?.unitTime, response?.markerDetail?.unit?.timeZone?.name, messages, "YYYY-MM-DD-HH-mm-ss");
            vidTabs.push({
              label: messages['infoBubble_clip'],
              render: () => {
                return (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ backgroundColor: 'black' }}
                  >
                    <Grid item>
                      <div className='info-window-size-width info-window-tab-vidFleet'>
                        <VidFleetVideo
                          name={`${clipID}-${response?.markerDetail?.unit?.id}-${eventTime}`}
                          messages={messages}
                          mode={response?.markerDetail?.linkedDevice?.manufacturerName=="SmartWitness"?'picture':'video'}
                          type="playback"
                          id={clipID}
                          autoplay={false}
                          deviceModel={(response?.markerDetail?.linkedDevice?.deviceModelName === "360" ? "360" : "4k")}
                          thumbnailUrl={thumbnailUrl}
                          pictureId={null}
                          disabled={false}
                        />
                      </div>
                    </Grid>
                  </Grid>
                );
              }
            });
          } else {
            vidTabs.push({
              label: messages['infoBubble_snapshot'],
              render: () => {
                return (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Grid item xs>
                      <Snapshot id={id} type={type} user={user} data={response} messages={messages} isEtaLink={isEtaLink}/>
                    </Grid>
                  </Grid>
                );
              }
            });
          }
        } else {
          vidTabs.push({
            label: messages['infoBubble_snapshot'],
            render: () => {
              return (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item xs>
                    <Snapshot id={id} type={type} user={user} data={response} messages={messages} isEtaLink={isEtaLink} />
                  </Grid>
                </Grid>
              );
            }
          });
        }

        if (type != "Trail" && !isEtaLink && HasPermission(user, "", [235])) {
          vidTabs.push({
            label: messages['infoBubble_live'],
            render: () => {
              return (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <div className='info-window-size-width info-window-tab-vidFleet'>
                      <VidFleetVideo
                        messages={messages}
                        mode="video"
                        type={response?.markerDetail?.linkedDevice?.liveMode ? response?.markerDetail?.linkedDevice?.liveMode?.toLowerCase() : "streaming"}
                        id={response?.markerDetail?.linkedDevice?.esn}
                        unitId={response?.markerDetail?.linkedDevice?.id}
                        autoplay={false}
                        deviceModel={(response?.markerDetail?.linkedDevice?.deviceModelName === "360" ? "360" : "4k")}
                        simProviderName={response?.markerDetail?.linkedDevice?.simProviderName}
                        vehicleEvent={response?.markerDetail?.unit?.lastReading?.eventName}
                        timeZone={response?.markerDetail?.unit?.timeZone?.name}
                      />
                    </div>
                  </Grid>
                </Grid>
              );
            }
          });
        }

        if (!isEtaLink) {
          vidTabs.push({
            label: messages['infoBubble_requestVideo'],
            render: () => {
              return (
                <RequestClip
                  ref={clipRequestRef}
                  id={response?.markerDetail?.linkedDevice?.id}
                  data={response}
                  type={type}
                  user={user}
                  messages={messages}
                  setDisableBtn={setDisableBtn}
                  setTooltip={setTooltip}
                />
              );
            }
          });
        }


        return (
          <Tabs
            tab={0}
            type="pill"
            border
            tabsClassName={`videoTitle tabs-pill ${isEtaLink && 'videoTitleEtaLink'}`}
            title={
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item style={{ marginBottom: isEtaLink && 10 }}>
                  <span style={{ color: 'black', fontSize: 16, fontWeight: 'bold', padding: 16 }}>
                    {isEtaLink ? `${props.timeFormatToShow || ''} - ${props.distance} ${props.speedFormat}` : messages['infoBubble_video']}
                  </span>
                </Grid>
                <Grid item>
                  {
                    type != "Trail" && !isEtaLink &&
                    <CameraSignalStatus
                      id={response?.markerDetail?.linkedDevice?.esn}
                      unitId={response?.markerDetail?.linkedDevice?.id}
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      simProviderName={response?.markerDetail?.linkedDevice?.simProviderName}
                      vehicleEvent={response?.markerDetail?.unit?.lastReading?.eventName}
                      sdCard={sdCard}
                    />
                  }
                </Grid>
              </Grid>
            }
            onChange={(tab) => {
              //if (tab === 2 && type != "Trail") {
              if (tab === 2 || (tab === 1 && type === "Trail")) {
                setTabButton({ title: messages['infoBubble_openRequestClip'] });
              }
              setInternalVideoTab(tab);
            }}
          >
            {
              vidTabs.map((vidTab, index) => {
                return (
                  <TabContainer 
                    key={index} 
                    label={vidTab.label} 
                    index={index} 
                    destroyAfterChange={vidTab.label == messages['infoBubble_live'] ? true : false}
                    style={{ padding: 0 }}
                  >
                    {vidTab.render()}
                  </TabContainer>
                );
              })
            }
          </Tabs>
        );

      case "SmartWitness":
        return (
          <Tabs
            tab={response?.markerDetail?.vehicleTrail?.deviceData?.includes("/SmartWitness/")?0:1}
            type="pill"
            border
            tabsClassName={"videoTitle tabs-pill"}
            title={messages["infoBubble_video"]}
            onChange={(tab) => {
              setInternalVideoTab(tab);
            }}
          >
            {
              response?.markerDetail?.vehicleTrail?.deviceData?.includes("/SmartWitness/") && 
                <TabContainer
                  key={0}
                  label={messages["infoBubble_live_snapshots"]}
                  index={0}
                  style={{ padding: 0 }}
                >
                  <div className='info-window-tab-video' style={{width:"95%",marginLeft:"2%"}}> 
                    <SmartWitnessSnapshot
                            messages={messages}
                            vehicleTrail={response?.markerDetail?.vehicleTrail}
                            height={250}
                            indicators={false}
                          />
                  </div>
                </TabContainer>
            }
            <TabContainer
              key={1}
              label={messages["infoBubble_live"]}
              index={1}
              style={{ padding: 0 }}
            >
                <div className='info-window-tab-video'>
                  <SmartWitnessVideo
                      ref={smartwitnessRef}
                      recorderId={response?.markerDetail?.linkedDevice?.esn}
                      deviceId={response?.markerDetail?.unit?.id}
                      cameraChannel={
                        response?.markerDetail?.linkedDevice?.cameraChannel || "*"
                      }
                      messages={messages}
                      timeZone={response?.markerDetail?.unit?.timeZone?.name}
                      vehicleTrail={response?.markerDetail?.vehicleTrail}
                      setIsVideoAvailable={setIsSmartWitnessVideoAvailable}
                    />
                </div>

            </TabContainer>
          </Tabs>
        );

      default:
        return <span></span>;
    }
  });

  if (type === "MapSearch") {
    return (
      <div className='info-window-size-width info-window-size-height' >
        <div>
          <Tabs
            tab={0}
            tabsPosition="top"
            type="icon"
            border
          >
            <TabContainer iconButton="room" label={messages['infoBubble_pointInfo']} index={0} style={{ padding: 0 }}>
              <SearchMapInfo data={props} messages={messages} />
            </TabContainer>
          </Tabs>
        </div>
      </div>
    )
  }

  const getMaintenanceWarnings = () => {
    //Getting information from the SD Card
    const sdCard = response?.markerDetail?.linkedDevice?.sdCard ? checkDeviceData(response?.markerDetail?.linkedDevice?.sdCard) : null
    const sdCardStatus = sdCard?.status?.toUpperCase();
    const isSdCardError = sdCardStatus && sdCardStatus !== "NORMAL";
    //Getting warnings
    const warnings = response?.markerDetail?.unit?.maintenanceWarningEventsCount || 0;
    //Check if the device is offline
    const isOffline = response?.markerDetail?.unit?.isOffline

    const { title, color } = getWarningCategory(isSdCardError, warnings, isOffline, messages)

    if (!title) return null

    return (
      <TabIconButton
        size="small"
        icon={"warning"}
        onClick={() => {
          if(HasPermission(user, "wgrs", [278])){
            dispatch(openWarningsModal({ ...response.markerDetail.unit, sdCard }))}}
          }
        tooltip={title}
        style={{ color: color }}
      />
    );
  }

  const newIds = (getMarkerDetailsId != changeIdUnit)
  
  return (
    <div className={`info-window-size-width ${!isEtaLink ? 'info-window-size-height' : 'info-window-size-height-eta'}`}>
      {
        ((loading && newIds && !isEtaLink) || (newIds && !isEtaLink) || (isEtaLink && loading))
          ?
          <Loading full />
          :
          <div>
            {
              (response && !response.error && !errors.markerDetails) &&
              <Tabs
                tab={mainTab}
                tabsPosition="top"
                type="icon"
                border
                onChange={(tab) => {
                  setMainTab(tab);
                  localStorage.setItem("info-bubble-last-tab", tab);
                }}
                tabsClassName={isEtaLink && 'videoTitleEtaLink'}
              >
                {
                  type === "Landmark" && !isEtaLink &&
                  <TabContainer iconButton="drive_eta" label={messages['infoBubble_landmarkInfo']} index={0} style={{ padding: 0 }}>
                    <LandmarkInfo data={response} messages={messages} />
                  </TabContainer>
                }

                {
                  type === "Geofence" && !isEtaLink &&
                  <TabContainer iconButton="drive_eta" label={messages['infoBubble_geofenceInfo']} index={0} style={{ padding: 0 }}>
                    <GeofenceInfo data={response} messages={messages} />
                  </TabContainer>
                }

                {
                  type === "RoutePoint" && !isEtaLink &&
                  <TabContainer iconButton="drive_eta" label={messages['infoBubble_routePointInfo']} index={0} style={{ padding: 0 }}>
                    <RoutePointInfo data={response} messages={messages} />
                  </TabContainer>
                }

                {
                  type === "RoutePoint" && !isEtaLink &&
                  <TabContainer iconButton="history" label={messages['infoBubble_routePointInfo_history']} index={1} style={{ padding: 0 }}>
                    <RoutePointInfoLog data={response} messages={messages} />
                  </TabContainer>
                }

                {
                  (type === "Vehicle" || type === "Trail") && !isEtaLink &&
                  <TabContainer iconButton="room" label={messages['infoBubble_location']} index={0} style={{ padding: 0 }}>
                    <Location id={id} type={type} user={user} data={response} messages={messages} locale={locale} mapRef={mapReference} />
                  </TabContainer>
                }

                {
                  (type === "Vehicle" || type === "Trail") && !isEtaLink &&
                  <TabContainer iconButton="drive_eta" label={messages['infoBubble_vehicleInfo']} index={1} style={{ padding: 0 }}>
                    <VehicleInfo id={id} type={type} user={user} data={response} messages={messages} />
                  </TabContainer>
                }
                {
                  !isEtaLink &&
                  <TabContainer
                    iconButton="streetview"
                    label={messages['infoBubble_streetView']}
                    index={(type === "Landmark" || (type === "Geofence") ? 1 : 2)}
                    style={{ padding: 0 }}
                  >
                    <div className='info-window-tab-streetview'>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{
                          padding: 20
                        }}
                      >
                        <Grid item xs>
                          <h2 style={{ margin: 0 }}>
                            {messages['infoBubble_streetView']}
                          </h2>
                        </Grid>
                      </Grid>

                      <div style={{ borderTop: 'solid 1px #e4e4e4' }}></div>

                      {
                        errors?.streetView ?
                          <div style={{ fontSize: 16, fontWeight: 'bold', padding: 16 }}>
                            {errors.streetView}
                          </div>
                          :
                          <div
                            ref={el => { streeViewContainer.current = el; setStreetViewVisible(!!el); }}
                            className='info-window-size-width'
                            style={{ height: 295 }}
                          >
                          </div>
                      }
                    </div>
                  </TabContainer>
                }
                {
                  hasVideo() &&
                  <TabContainer
                    iconButton="videocam"
                    label={messages['infoBubble_videocam']}
                    index={isEtaLink ? 0 : 3} style={{ padding: response?.markerDetail?.linkedDevice?.manufacturerName === "VidFleet" ? '10px 0px 0px' : 0 }}
                    destroyAfterChange={true}
                  >
                    <div className='info-window-size-width' style={{ minHeight: 290 }}>
                      {getVideo}
                    </div>
                  </TabContainer>
                }

                {
                  type === "Trail" && !isEtaLink &&
                  <TabContainer
                    iconButton="settings"
                    label={messages['infoBubble_settings']}
                    index={(hasVideo() ? 4 : 3)}
                    style={{ padding: 0 }}
                  >
                    <Settings id={id} user={user} authenticatedId={authenticatedId} data={response} messages={messages} />
                  </TabContainer>
                }

                {/* {
                  ((internalVideoTab === 2 || (internalVideoTab === 1 && type === "Trail")) && mainTab === 3 && response?.markerDetail?.linkedDevice?.manufacturerName === "VidFleet") &&
                  <TabButton
                    tooltip={tooltip}
                    disabled={loadingCreateClip || !availableClips.length || disableBtn}
                    className="btn-blue"
                    onClick={() => {
                      dispatch(clipRequestOpenForm(true))
                      dispatch(clipRequestGetUnits());
                      closeInfoBubble()
                    }}
                    title={tabButton?.title} 
                  />
                } */}
                {
                  type === "Vehicle" && !isEtaLink &&
                  <TabIconButton
                    onClick={(e) => {
                      const unitInfo = units?.find(item => item?.id == response?.id)
                      e.preventDefault();
                      dispatch(
                        openOverViewUnit({
                          data: { unit: unitInfo },
                          open: true,
                        })
                      );
                    }}
                    icon="gps_fixed"
                    size="small"
                    tooltip={messages["unitOverViewPanel"]}
                  />
                }
                {
                  type === "Vehicle"
                  &&
                  HasPermission(user, "trbusr", [262]) && !isEtaLink &&
                  <TabIconButton
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(openTroubleshootDeviceModal({ open: true, unit: response.markerDetail.unit }))
                    }}
                    icon="troubleshoot"
                    size="small"
                    tooltip={messages["troubleshooting"]}
                  />
                }
                {!isEtaLink && getMaintenanceWarnings()}
                {
                  (mainTab === 3 && response?.markerDetail?.linkedDevice?.manufacturerName === "SmartWitness") && !isEtaLink &&
                  <TabButton
                    disabled={!isSmartWitnessVideoAvailable}
                    className="btn-blue"
                    onClick={(e) => {
                      e.preventDefault();
                      smartwitnessRef?.current?.save();
                    }}
                    title={messages['save']}
                  />
                }

                {
                  type === "Vehicle"
                  &&
                  mainTab === 0
                  &&
                  HasPermission(user, '', [32])
                  && !isEtaLink &&
                  <TabButton
                    className="btn-blue modalTrailButtonStyle"
                    onClick={() => {
                      const { isKph } = user
                      let startDate = new Date()
                      startDate.setHours(0, 0, 0);
                      let endDate = new Date()
                      endDate.setHours(23, 59, 0);

                      const paramToGetTrails = {
                        unitId: id,
                        event: '0',
                        startDate: moment.utc(startDate).format(),
                        endDate: moment.utc(endDate).format(),
                        limit: preferences?.trailRowsPerPage || 100,
                        offset: 0,
                        useEcuSpeed: response?.markerDetail?.unit?.useEcuSpeed,
                        unitType: response?.markerDetail?.unit?.lastReading?.unitType,
                        kph: isKph
                      }

                      dispatch(selectModuleMapSettings(MapUtilitiesTypes.VehicleTrails));
                      dispatch(getVehicleTrails(paramToGetTrails));
                      dispatch(setVehicleTrailToPrint(response?.markerDetail?.unit));
                      dispatch(searchUnitTypeEvents(response?.markerDetail?.unit?.typeId));
                    }}
                    title={messages['realtimemaps_vehicletrails_gettrail_infoBubble']}
                  >
                  </TabButton>
                }



              </Tabs>
            }

            {
              (errors && errors.markerDetails) &&
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ padding: 20, height: 300 }}
              >
                <Grid item>
                  <span style={{ fontSize: 14 }}>{errors.markerDetails}</span>
                </Grid>

                <Grid item>
                  <Button
                    type="icon"
                    icon="refresh"
                    onClick={() => {
                      dispatch(getMarkerDetails({ type, id, unitId: deviceId }));
                    }}
                  />
                </Grid>
              </Grid>
            }
          </div>
      }
    </div>
  );
}

export default memo(InfoBubble);