import React from 'react';
import PropTypes from 'prop-types';
import NotificationManager from './NotificationManager';
import Notifications from './Notifications';
import { injectIntl } from 'react-intl';

class NotificationContainer extends React.Component {
	static propTypes = {
		enterTimeout: PropTypes.number,
		leaveTimeout: PropTypes.number,
	};

	static defaultProps = {
		enterTimeout: 400,
		leaveTimeout: 400,
	};

	constructor(props) {
		super(props);

		this.state = {
			notifications: [],
		};

		NotificationManager.addChangeListener(this.handleStoreChange);
	}

	componentWillUnmount = () => {
		NotificationManager.removeChangeListener(this.handleStoreChange);
	};

	handleStoreChange = (notifications) => {
		this.setState({
			notifications,
		});
	};

	handleRequestHide = (notification) => {
		NotificationManager.remove(notification);
	};

	render() {
		const { notifications } = this.state;
		const { enterTimeout, leaveTimeout } = this.props;
		const { messages } = this.props.intl;

		return (
			<Notifications
				enterTimeout={enterTimeout}
				leaveTimeout={leaveTimeout}
				notifications={notifications}
				onRequestHide={this.handleRequestHide}
				messages={messages}
			/>
		);
	}
}

export default injectIntl(NotificationContainer);
