import React from "react";
import { Box, Grid } from "@mui/material";
import ToggleLanguage from "Components/ToggleLanguage";
import FooterResellers from "../../common/FooterResellers";
import AgreementMessage from "../../common/AgreementMessage";

const ContentLogin = ({
  loginPage,
  current_reseller,
  reseller_was_validated,
  children,
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className="all-space-container"
    >
      <Grid
        item
        xs={12}
        lg={5}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "end",
            margin: "20px 25px 0 0px",
          }}
        >
          <ToggleLanguage className="btn-white" />
        </Box>
        {children}
        <Box>{!current_reseller && <AgreementMessage />}</Box>

        <Box style={{ margin: "25px", width: "100%", textAlign: "center" }}>
          {current_reseller && (
            <FooterResellers current_reseller={current_reseller} />
          )}
        </Box>
      </Grid>
      <Grid item xs={12} lg className="all-space-container">
        {(loginPage && !current_reseller) ? (
          <div
            className="all-space-container overflow-auto"
            dangerouslySetInnerHTML={{ __html: loginPage }}
          />
        ) : (
          <div
            className="login_bg"
            style={{
              backgroundImage: reseller_was_validated
              ? (!current_reseller?.loginBackgroundImage && current_reseller?.loginBackgroundColor) ? `none`
                  : `url(${
                    current_reseller?.loginBackgroundImage ||
                    "/assets/images/loginbg-dark.png"
                  })`
              : "",
              backgroundColor: (!reseller_was_validated || current_reseller?.loginBackgroundImage) ? "transparent"
                                  : (current_reseller?.loginBackgroundColor || "transparent")
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ContentLogin;
