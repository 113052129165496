import {
  REPORTS_MAIN_SCHEDULE_SHOW_REPORTSETUP,
  REPORTS_MAIN_SCHEDULE_HIDE_REPORTSETUP,
  REPORTS_MAIN_SAVE_SCHEDULE_REPORT,
  REPORTS_MAIN_SAVE_SCHEDULE_REPORT_RESULT,
  REPORTS_MAIN_GET_PRE_SCHEDULE_REPORT,
  REPORTS_MAIN_GET_SCHEDULE_REPORT,
  REPORTS_MAIN_GET_SCHEDULE_REPORT_RESULT,
  REPORTS_MAIN_UPDATE_SCHEDULE_REPORT,
  REPORTS_MAIN_UPDATE_SCHEDULE_REPORT_RESULT,
  REPORTS_MAIN_UPDATE_SUBSCRIBERS,
  REPORTS_MAIN_UPDATE_SUBSCRIBERS_RESULT,
  CLEAR_REDUX,
  REPORTS_MAIN_FTP_LIST,
  REPORTS_MAIN_FTP_LIST_RESULT,
  REPORTS_MAIN_FTP_UPDATE,
  REPORTS_MAIN_FTP_UPDATE_RESULT,
  REPORTS_MAIN_FTP_DELETE,
  REPORTS_MAIN_FTP_DELETE_RESULT
} from "Redux/actionTypes";

//STATE INITIAL
const INIT_STATE = {
  currentSchedule: null,
  showReportSetup: false,
  loading: false,
  savedSchedule: false,
  updatedSchedule: false,
  subscribersResponse: null,
  listFtp: null,
  updateFtpData: null,
  isDeleteFtp: null,
};

export default (state = INIT_STATE, action) => {
  //let reportIndex
  switch (action.type) {

    case REPORTS_MAIN_SCHEDULE_SHOW_REPORTSETUP:
      return { ...state, showReportSetup: true };
    case REPORTS_MAIN_SCHEDULE_HIDE_REPORTSETUP:
      return { ...state, showReportSetup: false, currentSchedule: null, updatedSchedule: false, subscribersResponse: null, savedSchedule: false };
    case REPORTS_MAIN_SAVE_SCHEDULE_REPORT:
      return {...state, savedSchedule: null, showReportSetup: false, loading: true};
    case REPORTS_MAIN_SAVE_SCHEDULE_REPORT_RESULT:
      return {...state, savedSchedule: action.payload.data, loading: false};
    case REPORTS_MAIN_GET_SCHEDULE_REPORT:
      return {...state, currentSchedule: null, loading: true};
    case REPORTS_MAIN_GET_PRE_SCHEDULE_REPORT:
      return {...state, currentSchedule: action.payload.data, loading: false};
    case REPORTS_MAIN_GET_SCHEDULE_REPORT_RESULT:
      return {...state, currentSchedule: action.payload.data, loading: false};
    case REPORTS_MAIN_UPDATE_SCHEDULE_REPORT:
      return {...state, updatedSchedule: false, showReportSetup: false, loading: true};
    case REPORTS_MAIN_UPDATE_SCHEDULE_REPORT_RESULT:
      return {...state, updatedSchedule: action.payload.data, loading: false};
    case REPORTS_MAIN_UPDATE_SUBSCRIBERS:
      return {...state, subscribersResponse: false, loading: true};
    case REPORTS_MAIN_UPDATE_SUBSCRIBERS_RESULT:
      return {...state, subscribersResponse: action.payload.data, loading: false};
    case REPORTS_MAIN_FTP_LIST:
      return {...state, loading:true}  
    case REPORTS_MAIN_FTP_LIST_RESULT:
      return {...state,listFtp: action.payload.data, loading:false}  
    case REPORTS_MAIN_FTP_UPDATE:
      return {...state,updateFtpData:null, loading:true}  
    case REPORTS_MAIN_FTP_UPDATE_RESULT:
      return {...state,updateFtpData: action.payload.data, loading:false}  
    case REPORTS_MAIN_FTP_DELETE:
      return {...state, loading:true}
    case REPORTS_MAIN_FTP_DELETE_RESULT:
      return {...state, isDeleteFtp: action.payload.data,loading:false}
    case CLEAR_REDUX:
      return ["", "REPORTS_MAIN_SCHEDULE"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
};
