import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';

// Actions
import {
  reportsMainGenerateReportProgress
} from 'Redux/actions';

import {
  getTimeZone,
  handleAsyncReport,
  getCategoryFilter,
  generateReportInstance,
  baseReportConditions,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

function* getTimeCardReportV2(report, page, rowsPerPage, sortField, sortOrder) {
  const { filterType, filterArray, startRange, endRange, otherFilters, unitsSelected, filterEntity , accumulateData = true, dates} = report.filters;
  let minutes = otherFilters.minute ? otherFilters.minute * 60 : 0;
  const { timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath } = report;
  const baseConditionsQuery = baseReportConditions({ report, name, dataResultPath, timeZone, delayed, unitsSelected, timeframeName , filterType, filterArray, categoryName, startRange, endRange, dates})

  let payload = {
    conditions: {
      ...baseConditionsQuery,
      sortField: {
        field: sortField || "deviceId",
        order: sortOrder === 'desc' ? 'DESC' : 'ASC'
      }
    },
    offset: page,
    limit: rowsPerPage
  }
  if (report.export) {
     payload = {
      conditions: {
        ...payload.conditions,
        exportOptions: {
          sendByEmail,
          emailsTo,
          culture: report.lang,
          timeZone: getTimeZone(timeZone),
          format: {
            fileFormat: report?.exportType  || "json",
            formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}"
          },
          folderName: "px-customer-reports",
          filePrefixName: "timeCardReportV2",
          columns: [
            {
              column: "driverName",
              header: "Driver"
            },
            {
              column: "inOut",
              header: "In/Out"
            },
            {
              column: "date",
              header: `${report?.exportType === "csv" ? `Time & Date (${getAbbvTimezone(timeZone)})` : `Time & Date`}`
            },
            {
              column: "address",
              header: "Address"
            }
          ]
        }
      },
      limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
      offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1
    }
  }
  try {
    let data = yield call(
      clientQuery,
      `query($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getTimeCardReportV2(conditions: $conditions, limit: $limit, offset: $offset) {
          ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?  

            `reportInstanceId`
              :`
              items {
                recordInfo {
                    entityId
                    entityLabel
                    tagId
                    tagLabel
                    startDate
                    endDate
                    recordType
                    groupLabel
                    groupIndex
                    groupTotal
                    groupTagColor
                }
                recordDetail {
                  driverId
                  dateTime
                  inOut
                  address
                  latitude
                  longitude
                  date
                  duration
                  noData
                }
            }
            total
            limit
            offset
          `}
        }
      }`,
      payload,
      endpoints.GRAPHQL_PX_REPORTING,
    );

    yield handleAsyncReport(report, data, accumulateData);
  } catch (exc) {
    ExceptionManager(exc, 'src/modules/reports/views/main/components/reportsTab/.components/reports/temperatureReport', 'getTimeCardReportV2');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
  }
}


export default getTimeCardReportV2;
