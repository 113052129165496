export default {
    mapGeofenceUnitGeofence_vehicle:'Vehicle',
    mapGeofenceUnitGeofence_count:'Geofences Assigned', //Replace number value
    mapGeofenceUnitGeofence_assigned:'Assing Geofence',
    mapGeofenceUnitGeofence_view:'View Geofeneces',
    mapGeofenceUnitGeofence_event:'Event',
    mapGeofenceUnitGeofence_group:'Tags',
    mapGeofenceUnitGeofence_geofences:'Geofences',
    mapGeofenceUnitGeofence_selectPlaceholder:'Select...',

}