import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Modal, { ModalBody, ModalFooter } from 'Components/Modal';
import { Button, Select } from 'Components';
import { injectIntl } from 'react-intl';

// actions 

//styles
import 'Modules/reports/views/main/main.scss';



const ReportTooLarge = (props) => {
  const { messages } = props.intl;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions([...props.drivers]);
  }, [props.drivers]);


  return (
    <Modal 
      title={messages["reportTooLarge_title"]} 
      open={props.open} 
      handleClose={props.onClose}
      size="small">
      <ModalBody>
        <div style={{ padding: 20 }}>
          <p>{messages['reportTooLarge_message']}</p>
          <p className="subheader">{messages["reportTooLarge_sendMessage"]}</p>
          <Select
            id="reportTooLarge"
            // label="Send Report To"
            valueField="id"
            textField="name"
            options={options} 
            placeholder={messages["reports_searchSourcePlaceholder"]}
            isClearable
            multiple
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button 
          className="btn-white"
          onClick={() => {
            props.onClose();
          }}
        >
          {messages['reportSetup_cancel']}
        </Button>
        <Button className="btn-blue">
          {messages['reportTooLarge_send']}
        </Button>
      </ModalFooter>
    </Modal>
  )
};

const mapStateToProps = ({ catalogsRedux }) => {
  const { drivers } = catalogsRedux;
  return {
    drivers
  }
};

export default injectIntl(
  connect(mapStateToProps, {

  })(ReportTooLarge)
);