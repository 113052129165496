import React, { useEffect, useState } from "react";
import colors from "Assets/sass/core/vars.scss";
import {
  getAbbvTimezone,
  GetFormatForMoment,
  getFormattedDateByTimeZone,
} from "./tools";
import "./style.scss";
import { Tooltip } from 'Components';
import { Typography } from "@mui/material";

export const getDateTime = ({
  date,
  timezone,
  dateFormat,
  timeFormat,
  fromNow = false,
  locale = "en",
  range = { start: '', end: '' },
  customFormat = false,
  onlyHour = false
}) => {
  const { start, end } = range
  let newFormat = GetFormatForMoment(dateFormat, customFormat);

  if (timeFormat && !onlyHour) {
    newFormat = `${newFormat} ${timeFormat}`;
  }

  if (onlyHour) {
    newFormat = `${timeFormat}`;
  }

  let formatDate;
  if (date) {
    formatDate = getFormattedDateByTimeZone(date, timezone, newFormat, fromNow, locale);
  }

  if (start && end) {
    const startDate = getFormattedDateByTimeZone(start, timezone, newFormat, fromNow, locale);
    const endDate = getFormattedDateByTimeZone(end, timezone, newFormat, fromNow, locale);

    formatDate = `${startDate} - ${endDate}`;
  }

  const abbvTimezone = getAbbvTimezone(timezone);

  return {
    date: formatDate || date.toString(),
    abbr: abbvTimezone
  }
}

const Timezone = ({
  date,
  timezone = "Argentina/Buenos_Aires",
  dateFormat = "d/m/Y",
  timeFormat,
  onlyDate = false,
  onlyHour = false,
  range = { start: '', end: '' },
  className = "",
  placement = "bottom",
  fromNow = false,
  locale = "en",
  customFormat = false,
  noWrap = true,
  oneControl = false,
  refresh = 0
}) => {
  const [newDate, setNewDate] = useState("");
  const [abbr, setAbbr] = useState("");

  useEffect(() => {
    const currentDateTime = getDateTime({ date, timezone, dateFormat, timeFormat, fromNow, locale, range, customFormat, onlyHour });

    setAbbr(`, ${currentDateTime?.abbr}`);
    setNewDate(currentDateTime?.date);
  }, [date, timezone, dateFormat, timeFormat, refresh]);

  return (
    !oneControl
      ?
      <div className="gtc-timezone">
        <Typography
          style={
            !className
              ? {
                fontSize: "14px",
                fontWeight: "400",
                color: colors.black,
              } : {}}
          className={className}
        >
          {newDate}
        </Typography>
        {!onlyDate &&
          <Tooltip placement={placement} title={timezone}>
            <div>
              <Typography
                style={
                  !className
                    ? {
                      fontSize: "14px",
                      fontWeight: "400",
                      color: colors.black,
                    } : {}}
                className={className}
              >
                {abbr}
              </Typography>
            </div>
          </Tooltip>
        }
      </div>
      :
      <div className="gtc-timezone" style={{ display: 'inline-block' }}>
        <Tooltip placement={placement} title={timezone}>
          <div>
            <Typography
              style={
                !className
                  ? {
                    fontSize: "14px",
                    fontWeight: "400",
                    color: colors.black,
                  } : {}}
              className={className}
            >
              {`${newDate} ${abbr}`}
            </Typography>
          </div>
        </Tooltip>
      </div>
  );
};

export default Timezone;