import {
    ACCOUNT_API_KEYS_SEARCH,
    ACCOUNT_API_KEYS_SEARCH_RESPONSE,
    ACCOUNT_API_KEYS_OPEN_CREATE_EDIT_MODAL,
    ACCOUNT_API_KEYS_GET_API_KEY,
    ACCOUNT_API_KEYS_GET_API_KEY_RESPONSE,
    ACCOUNT_API_KEYS_CREATE_EDIT_API_KEY,
    ACCOUNT_API_KEYS_CREATE_EDIT_API_KEY_RESPONSE,
    ACCOUNT_API_KEYS_OPEN_DELETE_MODAL,
    ACCOUNT_API_KEYS_DELETE_API_KEYS,
    ACCOUNT_API_KEYS_DELETE_API_KEYS_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loadingApiKeys: false,
    apiKeys: [],
    errorApiKeys: false,
    filters: {
        openSearch: "",
        resetSearch: false,
        nextToken: null
    },
    openCreateEdit: false,
    id: null,
    apiKey: null,
    loadingApiKey: false,
    loadingCreateEdit: false,
    createEditResponse: null,
    openDelete: false,
    selectedApiKeys: [],
    loadingDelete: false,
    deleteResponse: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACCOUNT_API_KEYS_SEARCH:
            return {
                ...state,
                apiKeys: action.payload?.resetSearch ? [] : state.apiKeys,
                filters:  action.payload,
                loadingApiKeys: true,
                errorApiKeys: false,
            }; 

        case ACCOUNT_API_KEYS_SEARCH_RESPONSE:
            return {
                ...state,
                apiKeys: [ ...state.apiKeys, ...action.payload?.items ],
                filters: { ...state.filters, nextToken: action.payload?.nextToken },
                loadingApiKeys: false,
                errorApiKeys: action.payload?.error
            };
      
        case ACCOUNT_API_KEYS_OPEN_CREATE_EDIT_MODAL:
            return {
                ...state,
                openCreateEdit: action.payload?.open,
                id: action.payload?.id,
            };

        case ACCOUNT_API_KEYS_CREATE_EDIT_API_KEY:
            return {
                ...state,
                loadingCreateEdit: true,
                createEditResponse: null
            };

        case ACCOUNT_API_KEYS_CREATE_EDIT_API_KEY_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                createEditResponse: action.payload?.data,
            }

        case ACCOUNT_API_KEYS_GET_API_KEY:
            return {
                ...state,
                apiKey: null,
                loadingApiKey: true
            };

        case ACCOUNT_API_KEYS_GET_API_KEY_RESPONSE:
            return {
                ...state,
                id: null,
                apiKey: action.payload?.data,
                loadingApiKey: false
            };

        case ACCOUNT_API_KEYS_OPEN_DELETE_MODAL:
            return {
                ...state,
                openDelete: action.payload?.open,
                selectedApiKeys: action.payload?.items,
                loadingDelete: false,
                deleteResponse: null
            };

        case ACCOUNT_API_KEYS_DELETE_API_KEYS:
            return {
                ...state,
                loadingDelete: true,
                deleteResponse: null
            };

        case ACCOUNT_API_KEYS_DELETE_API_KEYS_RESPONSE:
            return {
                ...state,
                loadingDelete: false,
                deleteResponse: action.payload?.data,
            };

        case CLEAR_REDUX:
            return ["", "ACCOUNT_API_KEYS"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
      return state;
    }
};