export const REALTIME_MAP_LAYER_MAP_GET = "REALTIME_MAP_LAYER_MAP_GET";
export const REALTIME_MAP_LAYER_MAP_GET_RESPONSE = "REALTIME_MAP_LAYER_MAP_GET_RESPONSE";

export const REALTIME_MAP_LAYER_ROUTES_GET = "REALTIME_MAP_LAYER_ROUTES_GET";
export const REALTIME_MAP_LAYER_ROUTES_GET_RESPONSE = "REALTIME_MAP_LAYER_ROUTES_GET_RESPONSE";

export const REALTIME_MAP_LAYER_ROUTE_GET = "REALTIME_MAP_LAYER_ROUTE_GET";
export const REALTIME_MAP_LAYER_ROUTE_GET_RESPONSE = "REALTIME_MAP_LAYER_ROUTE_GET_RESPONSE";

export const REALTIME_MAP_LAYER_ROUTE_REMOVE = "REALTIME_MAP_LAYER_ROUTE_REMOVE";

export const REALTIME_MAP_LAYER_CLEAR_LAYERS = "REALTIME_MAP_LAYER_CLEAR_LAYERS";

