import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { NotificationManager } from 'Components/Notifications';

//Action types
import { MAP_GEOFENCE_NEW } from 'Redux/actionTypes';

//actions
import { createGeofenceResult, getKmls } from 'Redux/actions';

//Function
function* createGeofenceRequest({ payload }) {
	try {
		let data = yield call(
			clientMutation,
        `mutation createGeofence ($name: String!, $groupId: Int, $latitude: Float!, $longitude: Float!, $shape: shapeType!, $radius: Int!, $alertOnType: String, $contacts: [Int]!, $coordinates: [CoordinateInput]!) {
          createGeofence (name: $name, groupId: $groupId, latitude: $latitude, longitude: $longitude, shape: $shape, radius: $radius, alertOnType: $alertOnType, contacts: $contacts, coordinates: $coordinates) {
              id
              companyId
              name
              groupId
              groupName
              shape
              radius
              alertOnType
              contacts {
                  id
                  title
                  address
                  type
              }
              coordinates {
                  latitude
                  longitude
              }
              createdBy
              createdOn
              updatedBy
              updatedOn
          }
      }`,
			{ 
        ...payload
      },
			endpoints.GRAPHQL_GENERAL,
		);

		if (data && data.createGeofence) {
			yield put(createGeofenceResult(data.createGeofence));
            yield put(getKmls());
		} else {
			throw "Error create Geofence";
		}
	} catch (exc) {
		// ALWAYS RETURN ACTION
		ExceptionManager(exc, 'modules/realtimeMaps/main/components/GeofenceListMain/redux/saga', 'createGeofenceRequest');
		yield put(createGeofenceResult(false, exc.errorData));
    NotificationManager.error('Error', `Error Create Geofence`, 3000, null, null, 'error');
	}
}

function* watchGeofenceTasksRequest() {
  yield takeEvery(MAP_GEOFENCE_NEW, createGeofenceRequest);
}

//Saga Export
export default function* rootSaga() {
	yield all([
		fork(watchGeofenceTasksRequest)
	]);
}