import React, { useCallback, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import Pill from 'Components/Pill';
import colors from 'Assets/sass/core/vars.scss';
import { getUserFormattedDate } from 'Core/data/Helpers';
// Components
import { TableView, Button } from 'Components';

// Actions
import {
	searchSystemAlertLogs
} from 'Redux/actions';
import { useNavigate } from 'react-router-dom';

const UserAlerts = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate()

	const { intl } = props;
	const { messages } = intl;
	const tableRef = useRef();

	const {
		systemAlertLogList: alerts,
		filters,
		loading,
		total,
		error
	} = useSelector(state => state.userAlertsRedux);

	const {
		user
	} = useSelector(state => state.securityRedux);

	const priorityList = [
		{
			id: 'all',
			name: 'all'
		},
		{
			id: 'High',
			name: 'high'
		},
		{
			id: 'Medium',
			name: 'medium'
		},
		{
			id: 'Low',
			name: 'low'
		}
	];

	const dotColors = {
		'Low': colors.green,
		'Medium': colors.orange,
		'High': colors.red,
	}

	const handleChangePage = useCallback((newPage, reset) => {
		let newOffset = newPage - 1;
		dispatch(searchSystemAlertLogs({
			...filters,
			resetSearch: reset || false,
			//alertTypes: ['CameraEvent'],
			//alertTypes: [],
			offset: filters?.limit * newOffset
		}));
	});

	return (
		<div
			style={{
				padding: '0px 10px 10px 10px'
			}}
		>
			<div id="alerts_header" >
				<h2>{messages["user_notifications_title"]}</h2>
				<div style={{ padding: '0px 10px 0 10px' }} id="manage_button"
					onClick={() => {
						navigate("/app/alerts/main")
						props.onCloseMenu && props.onCloseMenu()
					}}
				>{messages['menu_manage']}</div>
			</div>

			<div style={{ textAlign: 'left', marginBottom: 10 }}>
				<Typography variant="body2" style={{ fontWeight: 700 }}>
					{messages['user_alerts_latest_title']}
				</Typography>
			</div>

			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				style={{ marginBottom: 10 }}
			>
				{priorityList.map(item => {
					return (
						<Grid item key={item?.id}>
							<Pill
								item={{ id: item?.id, name: messages[item?.name] }}
								style={{
									backgroundColor: filters?.severity === item?.id ? colors.blue : colors.lightGray,
									border: `1px solid ${filters?.severity === item?.id ? colors.blue : colors.lightGray}`,
									color: filters?.severity === item?.id ? '#FFF' : colors.black
								}}
								clickable={false}
								onClick={(value) => {
									tableRef?.current?.setNewPage(1);
									dispatch(searchSystemAlertLogs({
										...filters,
										resetSearch: true,
										offset: 0,
										severity: value?.id
									}));
								}}
							/>
						</Grid>
					);
				})}
			</Grid>

			{
				error ?
					<div style={{ padding: '20px 10px 10px 10px' }}>
						<div>
							{messages['errorMessage']}
						</div>
						<Button
							type="icon"
							icon="refresh"
							onClick={() => {
								handleChangePage(1, true);
							}}
						/>
					</div> :
					<div style={{ marginTop: 20, height: 300, width: 300 }}>
						<TableView
							ref={tableRef}
							data={alerts || []}
							loading={loading}
							rowsPerPage={filters?.limit}
							name="alerts-list"
							topSpaceSize={0}
							infiniteScroll={true}
							height={300}
							viewType="list"
							messages={messages}
							itemSelector={false}
							serverSide
							onChangePage={(newPage) => {
								handleChangePage(newPage, newPage === 1 ? true : false);
							}}
							total={total}
							moreRowsAvailable={total > alerts?.length}
							renderRow={(row) => {
								return (
									<Grid
										container
										direction="row"
										wrap="nowrap"
										justifyContent="space-between"
										alignItems="flex-start"
										style={{
											marginBottom: 20
										}}
									>
										<Grid item>
											<div
												className="dot"
												style={{ backgroundColor: dotColors[row?.severity], marginTop: 3 }}
											/>
										</Grid>

										<Grid
											item
											xs
											zeroMinWidth
											style={{ textAlign: 'left' }}
										>
											<Typography
												variant="subtitle1"
												style={{
													fontWeight: 700,
													lineHeight: 1
												}}
												noWrap
											>
												{row?.systemAlertName}
											</Typography>
											<span className="alert_subtitle">
												{getUserFormattedDate(user, row?.unitTime, messages)}
											</span>
										</Grid>
									</Grid>
								)
							}}
						/>
					</div>
			}
		</div>
	);
};


export default injectIntl(UserAlerts);
