//scheduleDriverTask
export default {
    "scheduleDriverTask_title": "Schedule a New Task",
    "scheduleDriverTask_driverInputLabel": "Driver",
    "scheduleDriverTask_driverInputPlaceholder": "Select Driver",
    "scheduleDriverTask_schedule": "Schedule",
    "scheduleDriverTask_update": "Update",
    "scheduleDriverTask_taskInputLabel": "Task",
    "scheduleDriverTask_taskInputPlaceholder": "Select Task",
    "scheduleDriverTask_dateInputLabel": "Due Date",
    "scheduleDriverTask_dateInputPlaceholder": "Select Date",
    "scheduleDriverTask_timeInputLabel": "Time",
    "scheduleDriverTask_timeInputPlaceholder": "Time",
    "scheduleDriverTask_notesInputLabel": "Notes",
    "scheduleDriverTask_notesTaskLabel": "Task Note",
    "scheduleDriverTask_notesInputPlaceholder": "",
    "scheduleDriverTask_timeUnitInputPlaceholder": "Time Unit",
    "scheduleDriverTask_frecuencyInputPlaceholder": "Frecuency",
    "scheduleDriverTask_remindAtInputPlaceholder": "Remind At",
    "scheduleDriverTask_contactsInputLabel": "Notify",
    "scheduleDriverTask_contactsInputPlaceholder": "Select Subscribers",
    "scheduleDriverTask_createNewTask": "Add New",
    "scheduleDriverTask_taskNameInputLabel": "Task Name",
    "scheduleDriverTask_taskNameInputPlaceholder": "Task Name",
    "scheduleDriverTask_taskDescrptionInputLabel": "Task Description",
    "scheduleDriverTask_taskDescrptionInputPlaceholder": "Task Description",
    "scheduleDriverTask_taskAlreadyExists": "A task with the same name already exists",
    "scheduleDriverTask_taskScheduledSuccess": "The task was scheduled successfully",
    "scheduleDriverTask_attachmentsInputLabel": "Attachments ",
    "scheduleDriverTask_attachmentsInputPlaceholder": "Select",
    "scheduleDriverTask_selectOneLeast": "Select one least",
    "scheduleDriverTask_selectContact": "Select Subscriber",
    "scheduleDriverTask_subscribersInputLabel": "Subscribers",
    "scheduleDriverTask_repeatEveryInputLabel": "Repeat Every",
    "scheduleDriverTask_alertsInputLabel": "Alerts",
    "scheduleDriverTask_reminderInputLabel": "Reminder",
    "scheduleDriverTask_reminderInputPlaceholder": "Reminder",
    "scheduleDriverTask_reminderTimeUnitInputPlaceholder": "Select",
    "scheduleDriverTask_pastDueInputLabel": "Past Due",
    "scheduleDriverTask_pastDueInputPlaceholder": "Past Due",
    "scheduleDriverTask_pastDueTimeUnitInputPlaceholder": "Select",
    "scheduleDriverTask_editTitle": "Edit Scheduled Driver Task: ",
    "scheduleDriverTask_invalidTime": "Invalid Time",
    "scheduleDriverTask_lastService_invalidTime": "Date of last service can't be after current time",
    "scheduleDriverTask_next_due_invalid_time": "Next due date can be before that current time",
    "scheduleDriverTask_wait":"Wait",
    "reminder_time_invalid":"The reminder time can't be equal or bigger than frequency",
    "pastdue_time_invalid":"The past due time can't be equal or bigger than frequency",
    "scheduleDriverTask_attachmentsTaskLabel": "Task Attachment",
    "scheduleDriverTask_attachmentsTaskPlaceholder": "Check Files",
    "scheduleDriverTask_frequencyMax": "Maximum frequency: ",
    "scheduleDriverTask_saved": "Driver Task saved",
    "scheduleTask_saved": "Task saved",
    "scheduleTask_saved_select": "Task saved, please select it",
    "ScheduleService_saved": "Scheduled service saved",
  }