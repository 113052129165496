// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  DASHBOARD_PANEL_SEARCH,
  DASHBOARD_PANEL_CREATE,
  DASHBOARD_PANEL_UPDATE,
  DASHBOARD_PANEL_DELETE,
  DASHBOARD_PANEL_CREATE_GADGET,
  DASHBOARD_PANEL_DELETE_GADGET,
  DASHBOARD_PANEL_UPDATE_GADGET,
  DASHBOARD_PANEL_UPDATE_POSITION_GADGET,
  DASHBOARD_PANEL_UNIT,
} from "Redux/actionTypes";

// Actions
import {
  searchDashboardPanelResponse,
  createGadgetDashboardPanelResponse,
  updateDashboardPanelResponse,
  createDashboardPanelResponse,
  deleteDashboardPanelResponse,
  deleteGadgetDashboardPanelResponse,
  updateGadgetDashboardPanelResponse,
  updateGadgetPositionDashboardPanelResponse,
  searchDashboardUnitsResponse,
  updateErrorUserGadgetResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";


function* searchDashboardsRequest() {
  try {

    const query = `
            query searchDashboards {
                searchDashboards {
                    id
                    name
                    gadgets {
                        id
                        dashboardId
                        name
                        type
                        settings
                        columnPanel
                        columnOrder
                        refreshSeconds
                    }
                }
            }
        `;

    const response = yield call(
      clientQuery,
      query,
      {},
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.searchDashboards) {
      throw "Error while getting Dashboard list";
    }
    let dashboard = response.searchDashboards;
    yield put(searchDashboardPanelResponse({ dashboard }));
  } catch (exc) {
    yield put(searchDashboardPanelResponse({ dashboard: [] }));
    ExceptionManager(
      exc,
      "modules/dashboard/views/dashboardPanel/redux/saga",
      "searchDashboardsRequest"
    );
  }
}

function* createDashboardRequest({ payload }) {

  try {
    const { name } = payload;

    let query = `
              mutation createDashboard ($name: String!) {
                  createDashboard (name: $name) {
                      id
                      name
                      gadgets {
                          id
                          dashboardId
                          name
                          type
                          settings
                          columnPanel
                          columnOrder
                          refreshSeconds
                      }
                  }
              }
        `;
    const response = yield call(
      clientMutation,
      query,
      { name },
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.createDashboard) {
      throw "Error while create gadget";
    }
    let createDashboard = response.createDashboard;
    yield put(createDashboardPanelResponse({ createDashboard }));
  } catch (exc) {
    yield put(createDashboardPanelResponse(exc));
    ExceptionManager(
      exc,
      "modules/dashboard/views/dashboardPanel/redux/saga",
      "createDashboardRequest"
    );
  }
}

function* updateDashboardRequest({ payload }) {
  try {

    let query = `
            mutation updateDashboard ($id: String, $name: String!) {
              updateDashboard (id: $id, name: $name) {
                  id
                  name
                  gadgets {
                      id
                      dashboardId
                      name
                      type
                      settings
                      columnPanel
                      columnOrder
                      refreshSeconds
                  }
              }
          }
      `;

    const response = yield call(
      clientMutation,
      query,
      payload,
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.updateDashboard) {
      throw "Error while update Dashboard";
    }

    yield put(updateDashboardPanelResponse({ data: response.updateDashboard, error: false }));
  } catch (exc) {
    yield put(updateDashboardPanelResponse({data : exc}));
    ExceptionManager(
      exc,
      "modules/dashboard/views/dashboardPanel/redux/saga",
      "updateDashboardRequest"
    );
  }
}


function* deleteDashboardRequest({ payload }) {
  try {
    let query = `
              mutation deleteDashboard ($id: String!) {
                  deleteDashboard (id: $id)
              }
        `;
    const response = yield call(
      clientMutation,
      query,
      { id: payload },
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.deleteDashboard) {
      throw "Error while delete Dashboard";
    }

    yield put(deleteDashboardPanelResponse({ data: response.deleteDashboard, error: false }));
  } catch (exc) {
    yield put(deleteDashboardPanelResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/dashboard/views/dashboardPanel/redux/saga",
      "deleteDashboard"
    );
  }
}

function* createGadgetDashboardRequest({ payload }) {

  try {
    const { gadGet } = payload;

    let query = `
            mutation createUserGadget ($name: String!, $dashboardId: String!, $type: GadgetType!, $settings: AWSJSON, $columnPanel: Int!, $columnOrder: Int!, $refreshSeconds: Int!) {
                createUserGadget (name: $name, dashboardId: $dashboardId, type: $type, settings: $settings, columnPanel: $columnPanel, columnOrder: $columnOrder, refreshSeconds: $refreshSeconds) {
                    id
                    dashboardId
                    name
                    type
                    settings
                    columnPanel
                    columnOrder
                    refreshSeconds
                    
                }
            }
        `;
    const response = yield call(
      clientMutation,
      query,
      gadGet,
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.createUserGadget) {
      throw "Error while create gadget";
    }
    let createUserGadget = response.createUserGadget;
    yield put(createGadgetDashboardPanelResponse({ createUserGadget }));
  } catch (exc) {
    yield put(updateErrorUserGadgetResponse({ data: exc, error: true }));
    ExceptionManager(
      exc,
      "modules/dashboard/views/dashboardPanel/redux/saga",
      "createUserGadget"
    );
  }
}


function* deleteGadgetDashboardRequest({ payload }) {
  try {
    const { gadGetId } = payload;
    let query = `
              mutation deleteUserGadget ($id: String!) {
                deleteUserGadget (id: $id)
              }
        `;
    const response = yield call(
      clientMutation,
      query,
      { id: gadGetId },
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.deleteUserGadget) {
      throw "Error while delete gadget";
    }

    yield put(deleteGadgetDashboardPanelResponse({ data: response.deleteUserGadget, error: false }));
  } catch (exc) {
    yield put(deleteGadgetDashboardPanelResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/dashboard/views/dashboardPanel/redux/saga",
      "deleteUserGadget"
    );
  }
}

function* updateGadgetDashboardRequest({ payload }) {
  try {
    const { gadGet } = payload;

    let query = `
              mutation updateUserGadget ($id: String!, $name: String!, $dashboardId: String!, $type: GadgetType!, $settings: AWSJSON, $columnPanel: Int!, $columnOrder: Int!, $refreshSeconds: Int!) {
                updateUserGadget (id: $id, name: $name, dashboardId: $dashboardId, type: $type, settings: $settings, columnPanel: $columnPanel, columnOrder: $columnOrder, refreshSeconds: $refreshSeconds) {
                    id
                    dashboardId
                    name
                    type
                    settings
                    columnPanel
                    columnOrder
                    refreshSeconds
                }
            }
      `;

    let data = {
      id: gadGet.id,
      dashboardId: gadGet.dashboardId,
      name: gadGet.name,
      type: gadGet.type,
      settings: gadGet.settings,
      columnPanel: gadGet.columnPanel,
      columnOrder: gadGet.columnOrder,
      refreshSeconds: gadGet.refreshSeconds
    }

    const response = yield call(
      clientMutation,
      query,
      data,
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.updateUserGadget) {
      throw "Error while update gadget";
    }

    yield put(updateGadgetDashboardPanelResponse({ data: response.updateUserGadget, error: false }));
  } catch (exc) {
    yield put(updateErrorUserGadgetResponse({ data: exc, error: true }));
    ExceptionManager(
      exc,
      "modules/dashboard/views/dashboardPanel/redux/saga",
      "updateUserGadget"
    );
  }
}

function* updatePositionGadgetDashboardRequest({ payload }) {
  try {
    const { gadGets } = payload;
    let query = `
          mutation updatePositionUserGadget ($gadGets: [GadgetPosition]) {
            updatePositionUserGadget (gadGets: $gadGets)
        }
      `;



    const response = yield call(
      clientMutation,
      query,
      gadGets,
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.updatePositionUserGadget) {
      throw "Error while update gadget";
    }

    yield put(updateGadgetPositionDashboardPanelResponse({ data: response.updatePositionUserGadget, error: false }));
  } catch (exc) {
    yield put(updateGadgetPositionDashboardPanelResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/dashboard/views/dashboardPanel/redux/saga",
      "updatePositionGadgetDashboardRequest"
    );
  }
}


function* searchDashboardUnitsRequest() {
  try {

    const query = `
            query searchUnits ($openSearch: String) {
              searchUnits (openSearch: $openSearch) {
                  id
                  label
              }
          }
        `;

    const response = yield call(
      clientQuery,
      query,
      {},
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.searchUnits) {
      throw "Error while getting Unit list";
    }
    let unitDashboard = response.searchUnits;
    let errorUnitDashboard = response?.searchUnits?.length > 0 ? false : true;
    yield put(searchDashboardUnitsResponse({ unitDashboard, errorUnitDashboard }));
  } catch (exc) {
    yield put(searchDashboardUnitsResponse({ unitDashboard: [], errorUnitDashboard: true }));
    ExceptionManager(
      exc,
      "modules/dashboard/views/dashboardPanel/redux/saga",
      "searchDashboardUnitsRequest"
    );
  }
}

function* watchMethods() {
  yield takeEvery(DASHBOARD_PANEL_SEARCH, searchDashboardsRequest);
  yield takeEvery(DASHBOARD_PANEL_CREATE_GADGET, createGadgetDashboardRequest);
  yield takeEvery(DASHBOARD_PANEL_CREATE, createDashboardRequest);
  yield takeEvery(DASHBOARD_PANEL_UPDATE, updateDashboardRequest);
  yield takeEvery(DASHBOARD_PANEL_DELETE, deleteDashboardRequest);
  yield takeEvery(DASHBOARD_PANEL_DELETE_GADGET, deleteGadgetDashboardRequest);
  yield takeEvery(DASHBOARD_PANEL_UPDATE_GADGET, updateGadgetDashboardRequest);
  yield takeEvery(DASHBOARD_PANEL_UPDATE_POSITION_GADGET, updatePositionGadgetDashboardRequest);
  yield takeEvery(DASHBOARD_PANEL_UNIT, searchDashboardUnitsRequest);


}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}