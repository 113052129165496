import {
    MAP_GEOFENCES_EDIT_CREATE_GROUP_OPEN_MODAL,
    MAP_GEOFENCES_GET_GEOFENCE_GROUP,
    MAP_GEOFENCES_GET_GEOFENCE_GROUP_RESPONSE,
    MAP_GEOFENCES_EDIT_CREATE_GEOFENCE_GROUP,
    MAP_GEOFENCES_EDIT_CREATE_GEOFENCE_GROUP_RESPONSE
} from 'Redux/actionTypes';

export const mapGeofencesOpenEditCreateGeofenceGroupModal = (open, id) => ({
    type: MAP_GEOFENCES_EDIT_CREATE_GROUP_OPEN_MODAL,
    payload: { open, id },
});

export const mapGeofencesGetGeofenceGroup = (id) => ({
    type: MAP_GEOFENCES_GET_GEOFENCE_GROUP,
    payload: { id },
});

export const mapGeofencesGetGeofenceGroupResponse = (data) => ({
    type: MAP_GEOFENCES_GET_GEOFENCE_GROUP_RESPONSE,
    payload: { data },
});

export const mapGeofencesEditCreateGeofenceGroup = (geofenceGroup) => ({
    type: MAP_GEOFENCES_EDIT_CREATE_GEOFENCE_GROUP,
    payload: { geofenceGroup },
});

export const mapGeofencesEditCreateGeofenceGroupResponse = (data) => ({
    type: MAP_GEOFENCES_EDIT_CREATE_GEOFENCE_GROUP_RESPONSE,
    payload: { data },
});