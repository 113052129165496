import {
    //TYPES
    GET_GEOFENCES_GROUP,
    GET_GEOFENCES_GROUP_RESULT,
    MAP_GEOFENCES_UPDATE_ADD_GEOFENCE_GROUP,
    MAP_GEOFENCE_LIST_GETTAGS,
    MAP_GEOFENCE_LIST_GETTAGS_RESPONSE,
    MAP_GEOFENCE_LIST_GETTAGS_TOCREATE,
    MAP_GEOFENCE_LIST_GETTAGS_TOCREATE_RESPONSE
  } from "Redux/actionTypes";
  
const INIT_STATE = {
    geofencesGroups: [],
    loadingGeofenceGroup: false,
    geofenceTags: [],
    geofenceTagsLoading: false,
    geofenceTagsToCreate: [],
    geofenceTagsToCreateLoading: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case GET_GEOFENCES_GROUP:
            return { ...state, loadingGeofenceGroup: true, geofencesGroups: [] }
    
        case GET_GEOFENCES_GROUP_RESULT:
            return { ...state, loadingGeofenceGroup: false, geofencesGroups: action.payload.result, error: action.payload.error }
        
        case MAP_GEOFENCES_UPDATE_ADD_GEOFENCE_GROUP:
            let newGeofenceGroupsPreferences = [ ...state.geofencesGroups ];
            const { payload: { geofenceGroup, action: a} } = action;
      
            if (a === "add") {
              newGeofenceGroupsPreferences.push(geofenceGroup);
            } else if (a === "update") {
              newGeofenceGroupsPreferences = state.geofencesGroups.map(currentGeofenceGroup => {
                if (currentGeofenceGroup.id == geofenceGroup.id) {
                  return {
                    ...currentGeofenceGroup,
                    ...geofenceGroup
                  }
                }
                
                return currentGeofenceGroup;
              });
            }
      
            return { ...state, geofencesGroups: newGeofenceGroupsPreferences };


        case MAP_GEOFENCE_LIST_GETTAGS:
            return {
                ...state,
                geofenceTagsLoading: true
            };

        case MAP_GEOFENCE_LIST_GETTAGS_RESPONSE:
            return {
                ...state,
                geofenceTags: action.payload.data,
                geofenceTagsLoading: false
            };

        case MAP_GEOFENCE_LIST_GETTAGS_TOCREATE:
            return {
                ...state,
                geofenceTagsToCreateLoading: true
            };

        case MAP_GEOFENCE_LIST_GETTAGS_TOCREATE_RESPONSE:
            return {
                ...state,
                geofenceTagsToCreate: action.payload.data,
                geofenceTagsToCreateLoading: false
            };

      default:
        return { ...state };
    }
};