export default {
    completeMaintenanceService_complete: "Completar",
    completeMaintenanceService_max_complete: "COMPLETAR",
    completeMaintenanceService_date: "FECHA",
    completeMaintenanceService_select_date: "Seleccionar fecha",
    completeMaintenanceService_invalid_time: "Hora no válida",
    completeMaintenanceService_wait: "Espere",
    completeMaintenanceService_max_odometer: "ODÓMETRO ",
    completeMaintenanceService_odometer: "Odómetro",
    completeMaintenanceService_max_engine_hours: "HORAS DE MOTOR",
    completeMaintenanceService_engine_hours: "Horas de motor",
    completeMaintenanceService_add_service_items: "AÑADIR ELEMENTOS DE SERVICIO",
    completeMaintenanceService_add_new_service_item: "Añadir nuevo elemento de servicio",
    completeMaintenanceService_error_items: "Las piezas y la mano de obra no deberían costar 0,00 $ al mismo tiempo para un artículo de servicio",
    completeMaintenanceService_total_cost: "COSTE TOTAL",
    completeMaintenanceService_re_schedule_next_maintenance: "Reprogramar el próximo mantenimiento",
    completeMaintenanceService_next_due_date: "SIGUIENTE FECHA LÍMITE",
    completeMaintenanceService_next_odometer: "ODÓMETRO SIGUIENTE",
    completeMaintenanceService_error_next_odometer: "El ODÓMETRO SIGUIENTE debe ser igual o mayor que",
    completeMaintenanceService_next_engine_hours: "PRÓXIMAS HORAS DEL MOTOR",
    completeMaintenanceService_error_next_engine_hours: "PRÓXIMAS HORAS DEL MOTOR debe ser igual o superior a",
    completeMaintenanceService_attachment: "ADJUNTO",
    completeMaintenanceService_select_files: "Seleccionar archivo(s)",
    completeMaintenanceService_max_notes: "NOTAS",
    completeMaintenanceService_notes: "Notas",
    completeMaintenanceService_service_completed: "Servicio Completado",
    completeMaintenanceService_service_item_success: "Elemento de servicio creado correctamente",
    completeMaintenanceService_item: "ITEM",
    completeMaintenanceService_parts: "PARTES",
    completeMaintenanceService_highest_possible_value: "Valor máximo posible",
    completeMaintenanceService_labor: "LABOR",
    completeMaintenanceService_subtotal: "SUBTOTAL",
    completeMaintenanceService_for: "para",
}