// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  FSM_SETTINGS_QUICK_RESPONSE_SEARCH,
  FSM_SETTINGS_QUICK_RESPONSE_CREATE,
  FSM_SETTINGS_QUICK_RESPONSE_UPDATE,
  FSM_SETTINGS_QUICK_RESPONSE_DELETE
} from "Redux/actionTypes";

// Actions
import {
  searchQuickResponseResponse,
  createQuickResponseResponse,
  updateQuickResponseResponse,
  deleteQuickResponseResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";


function* searchQuickRequest() {
  try {

    const query = `
                query searchQuickResponses ($openSearch: String) {
                  searchQuickResponses (openSearch: $openSearch) {
                      id
                      name
                  }
              }
        `;

    const response = yield call(
      clientQuery,
      query,
      {},
      endpoints.GRAPHQL_GENERAL
    );
    if (!response && !response.searchQuickResponses) {
      throw "Error while getting Quick Response list";
    }
    let quickResponse = response.searchQuickResponses;
    yield put(searchQuickResponseResponse({ quickResponse }));
  } catch (exc) {
    yield put(searchQuickResponseResponse({ quickResponse: [] }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "searchQuickRequest",
      true
      );
  }
}

function* createQuickRequest({ payload }) {
  try {

    let query = `
      mutation createQuickResponse ($name: String!) {
        createQuickResponse (name: $name) {
            id
            name
        }
    }
      `;



    const response = yield call(
      clientMutation,
      query,
      payload,
      endpoints.GRAPHQL_GENERAL,
    );


    yield put(createQuickResponseResponse({ create: response, error: false }));
  } catch (exc) {
    yield put(createQuickResponseResponse({ create: null, error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "createQuickRequest",
      true
      );
  }
}

function* updateQuickRequest({ payload }) {
  try {
    const { QuickResponse } = payload;

    let query = `
      mutation updateQuickResponse ($id: Int!, $name: String) {
        updateQuickResponse (id: $id, name: $name) {
            id
            name
        }
    }
      `;



    const response = yield call(
      clientMutation,
      query,
      payload,
      endpoints.GRAPHQL_GENERAL,
    );


    yield put(updateQuickResponseResponse({ update: response, error: false }));
  } catch (exc) {
    yield put(updateQuickResponseResponse({ update: null, error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "updateQuickRequest",
      true
      );
  }
}

function* deleteQuickRequest({ payload }) {
  try {

    const { quickResponse } = payload;
    let mutations = [];
    quickResponse?.forEach((Form, index) => {
      mutations.push(`
            Quick${index}: deleteQuickResponse(id: ${Form?.id})
        `);
    });

    let mutation = `mutation{${mutations.join("")}}`;

    const response = yield call(
      clientMutation,
      mutation,
      {},
      endpoints.GRAPHQL_GENERAL,
    );

    if (!response || !response.Quick0) throw `Error while deleting Quick Messages, ${quickResponse}`;



    yield put(deleteQuickResponseResponse({ data: response.Quick0, error: false }));
  } catch (exc) {
    yield put(deleteQuickResponseResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "deleteForms",
      true
      );
  }
}



function* watchMethods() {
  yield takeEvery(FSM_SETTINGS_QUICK_RESPONSE_SEARCH, searchQuickRequest);
  yield takeEvery(FSM_SETTINGS_QUICK_RESPONSE_CREATE, createQuickRequest);
  yield takeEvery(FSM_SETTINGS_QUICK_RESPONSE_UPDATE, updateQuickRequest);
  yield takeEvery(FSM_SETTINGS_QUICK_RESPONSE_DELETE, deleteQuickRequest);
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}