import {
  VIDEO_MAIN_EVENTS_SEARCH_AVAILABLE_MEDIA,
  VIDEO_MAIN_EVENTS_SEARCH_AVAILABLE_MEDIA_RESPONSE,
  VIDEO_MAIN_EVENTS_UPDATE_EVENT,
  VIDEO_MAIN_EVENTS_RESTORE_AVAILABLE_MEDIA,
  VIDEO_MAIN_EVENTS_RESTORE_AVAILABLE_MEDIA_RESPONSE,
  VIDEO_MAIN_EVENTS_OPEN_MODAL_ADD_NOTES
} from 'Redux/actionTypes';

export const searchAvailableMedia = (data) => {
  return {
    type: VIDEO_MAIN_EVENTS_SEARCH_AVAILABLE_MEDIA,
    payload: data,
  };
};

export const searchAvailableMediaResponse = (data) => {
  return {
    type: VIDEO_MAIN_EVENTS_SEARCH_AVAILABLE_MEDIA_RESPONSE,
    payload: data,
  };
};

export const updateEvent = (data) => {
  return {
    type: VIDEO_MAIN_EVENTS_UPDATE_EVENT,
    payload: data
  };
};

export const restoreAvailableMedia = (data) => {
  return {
    type: VIDEO_MAIN_EVENTS_RESTORE_AVAILABLE_MEDIA,
    payload: { data }
  };
}

export const restoreAvailableMediaResponse = (data) => {
  return {
    type: VIDEO_MAIN_EVENTS_RESTORE_AVAILABLE_MEDIA_RESPONSE,
    payload: { data }
  };
}

export const openEventAddNotesModal = (data) => {
  return {
    type: VIDEO_MAIN_EVENTS_OPEN_MODAL_ADD_NOTES,
    payload: { data }
  };
}