import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    openModal: false,

    id: 0,
    rows: [],
    type: "route",
    title: "",
    content: "",
    routeId: 0,
    isRealtime: false,
    loading: false,
    isDeleteRoutes: false,
    isDeletePoint: false,
    restart: false,
    erorrRoutes: null
};

const route = "settings/views/routes";

export const routesDeleteRoutes = createAsyncThunk('routes/routesTab/routesDeleteRoutes', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const { id } = payload;

        let query = `
            mutation ($id: Int!){
                Route: deleteRoute(id: $id)
            }
        `;

        const response = await clientMutation(
            query,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response) {
            throw 'Error while deleting dynamic forms';
        }

        return response && response.Route;
        

    } catch (exc) {
        ExceptionManager(exc, route, 'routesDeleteRoutes');
        return rejectWithValue(exc)
    }
});

export const routesDeleteMultipleRoutes = createAsyncThunk('routes/routesTab/routesDeleteMultipleRoutes', async (payload, { rejectWithValue, dispatch }) => {
    try {
        
        let mutations = [];
        const { arrayDeleteRoutes } = payload;

        arrayDeleteRoutes.forEach((id, index) => {
        mutations.push(`
        Route${index}: deleteRoute(
            id: ${id}
            )`);
        })
        let mutation = `mutation { ${mutations.join(" ")} }`;

        const response = await clientMutation(
            mutation,
            {},
            endpoints.GRAPHQL_GENERAL_V2
        );
    
        if (response && response.Route0) 
            return true;

    } catch (exc) {
        ExceptionManager(exc, route, 'routesDeleteMultipleRoutes');
        return rejectWithValue(exc)
    }
});


export const routesDeleteStop = createAsyncThunk('routes/routesTab/routesDeleteStop', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        let query = `
            mutation ($id: String!){
                Stop: deleteRoutePoint(id: $id)
            }
        `;

        const response = await clientMutation(
          query,
          {
              id
          },
          endpoints.GRAPHQL_GENERAL_V2
      );

      if (!response) {
          throw 'Error while deleting dynamic forms';
      }

      return response && response.Stop;

    } catch (exc) {
        ExceptionManager(exc, route, 'routesDeleteStop');
        return rejectWithValue(exc)
    }
});




export const RoutesManagerDeleteRedux = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalDeleteRoutes: (state, action) => {
            return {
                ...state,
                openModal: action.payload?.open,
                routeId: action.payload?.routeId,
                id: action.payload?.id || 0,
                rows: action.payload?.rows || [],
                type: action.payload?.type,
                title: action.payload?.title,
                content: action.payload?.content,
                isDeleteRoutes: false,
                isRealtime: action?.payload?.isRealtime || false
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(routesDeleteRoutes.pending, (state, action) => {
            state.isDeleteRoutes = false;
            state.loading = true

        });
        builder.addCase(routesDeleteRoutes.fulfilled, (state, action) => {
            state.isDeleteRoutes = action.payload;
            state.loading = false;

        } );
        builder.addCase(routesDeleteRoutes.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loading = false;

        });

        builder.addCase(routesDeleteMultipleRoutes.pending, (state, action) => {
            state.isDeleteRoutes = false;
            state.loading = true

        });
        builder.addCase(routesDeleteMultipleRoutes.fulfilled, (state, action) => {
            state.isDeleteRoutes = action.payload;
            state.loading = false;

        } );
        builder.addCase(routesDeleteMultipleRoutes.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loading = false;

        });

        builder.addCase(routesDeleteStop.pending, (state, action) => {
            state.isDeletePoint = false;
            state.loading = true

        });
        builder.addCase(routesDeleteStop.fulfilled, (state, action) => {
            state.isDeletePoint = action.payload;
            state.loading = false;

        } );
        builder.addCase(routesDeleteStop.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loading = false;

        });

        
    }
})

const { actions, reducer } = RoutesManagerDeleteRedux;

export const { reset, openModalDeleteRoutes } = actions;

export default reducer;