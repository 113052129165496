// Action types.
import {
    SETTINGS_LOCATIONS_SEARCH,
    SETTINGS_LOCATIONS_SEARCH_RESPONSE,
    SETTINGS_LOCATIONS_CREATE_MODAL_SHOW_MODAL,
    SETTINGS_LOCATIONS_CREATE_MODAL_HIDE_MODAL,
    SETTINGS_LOCATIONS_CREATE_LOCATION,
    SETTINGS_LOCATIONS_CREATE_LOCATION_RESPONSE,
    SETTINGS_LOCATIONS_EDIT_LOCATION,
    SETTINGS_LOCATIONS_EDIT_LOCATION_RESPONSE,
    SETTINGS_LOCATIONS_DELETE_MODAL_SHOW_MODAL,
    SETTINGS_LOCATIONS_DELETE_MODAL_HIDE_MODAL,
    SETTINGS_LOCATIONS_DELETE_LOCATION,
    SETTINGS_LOCATIONS_DELETE_LOCATION_RESPONSE,
} from "Redux/actionTypes";

export const searchLocations = (data) => {
    return {
        type: SETTINGS_LOCATIONS_SEARCH,
        payload: data,
    };
};

export const searchLocationsResponse = (data) => {
    return {
        type: SETTINGS_LOCATIONS_SEARCH_RESPONSE,
        payload: data,
    };
};

export const showModalCreateEditLocation = (data) => {
    return {
        type: SETTINGS_LOCATIONS_CREATE_MODAL_SHOW_MODAL,
        payload: data
    };
};

export const hideModalCreateEditLocation = (data) => {
    return {
        type: SETTINGS_LOCATIONS_CREATE_MODAL_HIDE_MODAL
    };
};

export const createLocation = (data) => {
    return {
        type: SETTINGS_LOCATIONS_CREATE_LOCATION,
        payload: data,
    };
};

export const createLocationResponse = (data) => {
    return {
        type: SETTINGS_LOCATIONS_CREATE_LOCATION_RESPONSE,
        payload: data,
    };
};

export const editLocation = (data) => {
    return {
        type: SETTINGS_LOCATIONS_EDIT_LOCATION,
        payload: data,
    };
};

export const editLocationResponse = (data) => {
    return {
        type: SETTINGS_LOCATIONS_EDIT_LOCATION_RESPONSE,
        payload: data,
    };
};

export const showModalDeleteLocation = (data) => {
    return {
        type: SETTINGS_LOCATIONS_DELETE_MODAL_SHOW_MODAL,
        payload: data
    };
};

export const hideModalDeleteLocation = () => {
    return {
        type: SETTINGS_LOCATIONS_DELETE_MODAL_HIDE_MODAL
    };
};

export const deleteLocation = (data) => {
    return {
        type: SETTINGS_LOCATIONS_DELETE_LOCATION,
        payload: data,
    };
};

export const deleteLocationResponse = (data) => {
    return {
        type: SETTINGS_LOCATIONS_DELETE_LOCATION_RESPONSE,
        payload: data,
    };
};