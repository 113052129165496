import {
    MAP_LANDMARKS_EDIT_CREATE_GROUP_OPEN_MODAL,
    MAP_LANDMARKS_GET_LANDMARK_GROUP,
    MAP_LANDMARKS_GET_LANDMARK_GROUP_RESPONSE,
    MAP_LANDMARKS_EDIT_CREATE_LANDMARK_GROUP,
    MAP_LANDMARKS_EDIT_CREATE_LANDMARK_GROUP_RESPONSE
} from 'Redux/actionTypes';

export const mapLandmarksOpenEditCreateLandmarkGroupModal = (open, id) => ({
    type: MAP_LANDMARKS_EDIT_CREATE_GROUP_OPEN_MODAL,
    payload: { open, id },
});

export const mapLandmarksGetLandmarkGroup = (id) => ({
    type: MAP_LANDMARKS_GET_LANDMARK_GROUP,
    payload: { id },
});

export const mapLandmarksGetLandmarkGroupResponse = (data) => ({
    type: MAP_LANDMARKS_GET_LANDMARK_GROUP_RESPONSE,
    payload: { data },
});

export const mapLandmarksEditCreateLandmarkGroup = (landmarkGroup) => ({
    type: MAP_LANDMARKS_EDIT_CREATE_LANDMARK_GROUP,
    payload: { landmarkGroup },
});

export const mapLandmarksEditCreateLandmarkGroupResponse = (data) => ({
    type: MAP_LANDMARKS_EDIT_CREATE_LANDMARK_GROUP_RESPONSE,
    payload: { data },
});