import {
  ROUTES_GET_ROUTES,
  ROUTES_GET_ROUTES_RESPONSE,
  ROUTES_GET_ROUTES_AND_POINT,
  ROUTES_OPEN_CREATE_EDIT_ROUTE,
  ROUTES_OPEN_UPLOAD_ROUTE,
  ROUTES_OPEN_ADD_STOP,
  ROUTES_CREATE_UPDATE_ROUTE,
  ROUTES_CREATE_UPDATE_ROUTE_RESPONSE,
  ROUTES_ADD_STOP,
  ROUTES_ADD_STOP_RESPONSE,
  ROUTES_DELETE_ROUTE,
  ROUTES_DELETE_ROUTE_RESPONSE,
  ROUTES_DELETE_ROUTE_MULTIPLE,
  ROUTES_DELETE_ROUTE_MULTIPLE_RESPONSE,
  ROUTES_DELETE_STOP,
  ROUTES_DELETE_STOP_RESPONSE,
  ROUTES_GET_ROUTE,
  ROUTES_GET_ROUTE_RESPONSE,
  ROUTES_OPEN_DELETE_MODAL,
  ROUTES_UPDATE_STOP_ORDER,
  ROUTES_UPDATE_STOP_ORDER_RESPONSE,
  ROUTES_UPDATE_STOP,
  ROUTES_UPDATE_STOP_RESPONSE
} from 'Redux/actionTypes';

export const getRoutes = (data) => ({
  type: ROUTES_GET_ROUTES,
  payload: { data },
});

export const getRoutesResponse = (data) => ({
  type: ROUTES_GET_ROUTES_RESPONSE,
  payload: { data },
});

export const getRoutesAndRoutePoint = (data) => ({
    type: ROUTES_GET_ROUTES_AND_POINT,
    payload: { data },
});

export const openCreateEditRoute = (data) => ({
  type: ROUTES_OPEN_CREATE_EDIT_ROUTE,
  payload: { data },
});

export const openUploadRoute = (data) => ({
  type: ROUTES_OPEN_UPLOAD_ROUTE,
  payload: { data },
});

export const openAddStop = (data, pointToEdit = null) => ({
  type: ROUTES_OPEN_ADD_STOP,
  payload: { data, pointToEdit: pointToEdit },
});

export const createUpdateRoute = (data) => ({
  type: ROUTES_CREATE_UPDATE_ROUTE,
  payload: { data },
});

export const createUpdateRouteResponse = (data) => ({
  type: ROUTES_CREATE_UPDATE_ROUTE_RESPONSE,
  payload: { data },
});

export const addStop = (data) => ({
  type: ROUTES_ADD_STOP,
  payload: { data },
});

export const addStopResponse = (data) => ({
  type: ROUTES_ADD_STOP_RESPONSE,
  payload: { data },
});

export const deleteRoute = (data) => ({
  type: ROUTES_DELETE_ROUTE,
  payload: { data },
});

export const deleteRouteResponse = (data) => ({
  type: ROUTES_DELETE_ROUTE_RESPONSE,
  payload: { data },
});

export const deleteStop = (data) => ({
  type: ROUTES_DELETE_STOP,
  payload: { data },
});

export const deleteStopResponse = (data) => ({
  type: ROUTES_DELETE_STOP_RESPONSE,
  payload: { data },
});

export const getRoute = (data) => ({
  type: ROUTES_GET_ROUTE,
  payload: { data },
});

export const getRouteResponse = (data) => ({
  type: ROUTES_GET_ROUTE_RESPONSE,
  payload: { data },
});

export const openDeleteModal = (data) => ({
  type: ROUTES_OPEN_DELETE_MODAL,
  payload: { data },
});

export const updateStopOrder = (data) => ({
  type: ROUTES_UPDATE_STOP_ORDER,
  payload: { data },
});

export const updateStopOrderResponse = (data) => ({
  type: ROUTES_UPDATE_STOP_ORDER_RESPONSE,
  payload: { data },
});

export const updateStop = (data, update = true) => ({
  type: ROUTES_UPDATE_STOP,
  payload: { data, update },
});

export const updateStopResponse = (data) => ({
  type: ROUTES_UPDATE_STOP_RESPONSE,
  payload: { data },
});

export const deleteRouteMultiple = (data) => ({
  type: ROUTES_DELETE_ROUTE_MULTIPLE,
  payload: { data },
});

export const deleteRouteMultipleResponse = (data) => ({
  type: ROUTES_DELETE_ROUTE_MULTIPLE_RESPONSE,
  payload: { data },
});


export { 
  realtimeMapsRoutesSearchRoutes, realtimeMapsRoutesGetRoutes, showRoutePointsInfo, onRefreshRoutes
} from "./slice"

export {
  openModalRealtimeMapsRoutesCreateEditRoute
} from "../components/modalCreateEditRoute/redux/slice"

export {
  openModalRealtimeMapsUploadRoute, realtimeMapsRoutesCreateUpdateRoute
} from "../components/uploadRoute/redux/slice"

