// View Keys
export default {
    "AlertsinboxTab_DateTime": "Date/Time",
    "AlertsinboxTab_Label": "Label",    
    "AlertsinboxTab_Units": "Units/Drivers", 
    "AlertsinboxTab_Severity": "Severity",  
    "AlertsinboxTab_Location": "Location",
    "AlertsinboxTab_Speed": "Speed",
    "AlertsinboxTab_Read": "Read", 
    "AlertsinboxTab_None": "None",    
};
