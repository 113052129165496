import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga';

import reducers from './reducers';
import sagas from './sagas';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({ serializableCheck: false,  immutableCheck: false }),
      sagaMiddleware
    ],
  });
  
  
  sagaMiddleware.run(sagas)
  
  export default store;