export const FLEET_OPEN_STARTER_SCHEDULE = 'FLEET_OPEN_STARTER_SCHEDULE';
export const FLEET_CLOSE_STARTER_SCHEDULE = 'FLEET_CLOSE_STARTER_SCHEDULE';

export const FLEET_CREATE_STARTER_SCHEDULE = 'FLEET_CREATE_STARTER_SCHEDULE';
export const FLEET_CREATE_STARTER_SCHEDULE_RESULT = 'FLEET_CREATE_STARTER_SCHEDULE_RESULT';
export const FLEET_SEARCH_STARTER_SCHEDULES = 'FLEET_SEARCH_STARTER_SCHEDULES';
export const FLEET_SEARCH_STARTER_SCHEDULES_RESULT = 'FLEET_SEARCH_STARTER_SCHEDULES_RESULT';
export const FLEET_DELETE_STARTER_SCHEDULE = 'FLEET_DELETE_STARTER_SCHEDULE';
export const FLEET_DELETE_STARTER_SCHEDULE_RESULT = 'FLEET_DELETE_STARTER_SCHEDULE_RESULT';
export const FLEET_UPDATE_STARTER_SCHEDULE = 'FLEET_UPDATE_STARTER_SCHEDULE';
export const FLEET_UPDATE_STARTER_SCHEDULE_RESULT = 'FLEET_UPDATE_STARTER_SCHEDULE_RESULT';
