import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";
import { generarColorHex } from 'Core/hooks/utils';
import moment from "moment";

const INIT_STATE = {
    loading: false,
    result: [],
    step: 'settings',
    shape: null,
    error: null,
    group: [],
    coordinates: [],
    radius: 0,
    showNewLayer: false,
    dataRange: {
        start: moment().startOf('day'),
        end: moment().startOf('day').add(12, 'hours')
    },
    startingLocation: null,
    selectedUnit: null,
    idFromTo: null,
    center: {},
    initialShape: false,
    inicialState: false
};

const vicinityTool = "realtimeMaps/main/vicinityTool";

export const realtimeMapsVicinityToolSearchReadings = createAsyncThunk('realtimeMaps/main/vicinityTool/realtimeMapsVicinityToolSearchReadings', async (payload, { rejectWithValue, dispatch }) => {
    try {

        const response = await clientQuery(
            `
                query searchVicinityToolUnits ($shape: shapeType!, $coordinates: [CoordinateInput], $radius: Float!, $dateRange: AWSDateTimeRange, $tagIds: [Int], $limit: Int, $offset: Int) {
                    result: searchVicinityToolUnits (shape: $shape, coordinates: $coordinates, radius: $radius, dateRange: $dateRange, tagIds: $tagIds, limit: $limit, offset: $offset) {
                        items {
                            unitId
                            unitLabel
                            eventsInside
                            behaviorEvents
                            events {
                                deviceId
                                latitude
                                longitude
                                unitTime
                                eventName
                            }
                        }
                        total
                    }
                }
            `,
            payload,
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (response?.result?.items?.length) {
            let units = []
            units = response.result.items.map((item) => ({ ...item, events: item?.events?.length ? [...item.events] : [], color: generarColorHex(), show: true }))
            units.forEach(event => {
                if (event.events.length) {
                    event.events.forEach(subEvent => {
                        if (subEvent.trail) {
                            subEvent.trail = JSON.parse(JSON.stringify(JSON.parse(subEvent.trail)));
                        }
                    });
                }

            });
            units.forEach(event => {
                if (event.events.length) {
                    event.events.forEach(subEvent => {
                        if (subEvent.trail) {
                            subEvent.trail.sort((a, b) => parseInt(a.timestamp) - parseInt(b.timestamp));
                        }
                    });
                }
            });
            units.forEach(event => {
                if (event.events.length) {
                    event.events.sort((a, b) => new Date(a.unitTime) - new Date(b.unitTime));
                }
            });
            return { result: units }
        } else {
            return { error: 'no data' }
        }

    } catch (exc) {
        ExceptionManager(exc, vicinityTool, 'realtimeMapsVicinityToolSearchReadings');
        return rejectWithValue(exc)
    }
});

export const RealtimeMapsVicinityToolRedux = createSlice({
    name: vicinityTool,
    initialState: INIT_STATE,
    reducers: {
        vicinityToolReset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        vicinityToolSetField: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...state };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(realtimeMapsVicinityToolSearchReadings.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(realtimeMapsVicinityToolSearchReadings.fulfilled, (state, action) => {
            state.result = action?.payload?.result?.length ? action.payload.result : []
            state.error = action?.payload?.error || null;
            state.loading = false;
        });
        builder.addCase(realtimeMapsVicinityToolSearchReadings.rejected, (state, action) => {
            state.loading = false;
            state.error = action?.payload?.error || null
        });
    }
})

const { actions, reducer } = RealtimeMapsVicinityToolRedux;

export const { vicinityToolReset, vicinityToolSetField } = actions;

export default reducer;