import React from 'react';
import ListItemText from '@mui/material/ListItemText';

//global components
import {
	Grid,
	Icon,
	IconButton,
	OutlinedInput,
	InputAdornment,
	FormControl,
	Grow,
	Menu,
	MenuItem,
} from '@mui/material';
//import Checkbox from '@mui/material/Checkbox';

//own components
import { Button, Checkbox } from 'Components';
import './toolbar.scss';


//international
import { useIntl } from "react-intl";


export const AddButton = (props) => {
	const { messages } = useIntl();

	return <Button type="icon" tooltip={props.tooltip || messages["table_add"]} placement="bottom" icon="add_box" {...props} />;
};

export const FilterButton = (props) => {
	const { messages } = useIntl();

	return <Button type="icon" tooltip={props.tooltip || messages["table_filter"]} placement="bottom" icon="filter_list" {...props} />;
};

export const ExportButton = (props) => {

	const { onExport, disabledExport, fontSize, menuRef } = props
	const [anchorEl, setAnchorEl] = React.useState(null);
	const { messages } = useIntl();
	React.useImperativeHandle(menuRef, () => ({
    closeMenu: () => {
      handleClose();
    },
  }));

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Button
				type="icon"
				aria-controls="customized-menu"
				aria-haspopup="true"
				variant="contained"
				onClick={handleClick}
				placement="bottom"
				icon="get_app"
				disabled={disabledExport}
				tooltip={messages["downloadExport"]}
				className={disabledExport ? `color-disabled-toolbar` : `icon-down-download`}
				fontSize={fontSize}
			/>
			<Menu
				id="customized-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				sx={{
					'& .MuiMenu-paper': {
						border: '1px solid #d3d4d5'
					}
				}}
				elevation={0}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<MenuItem
					onClick={() => {
						onExport('CSV');
						handleClose();
					}}
				>
					<ListItemText primary={messages["table_export_csv"]} />
				</MenuItem>
				{!props.hidePdfOption &&
					<MenuItem
						onClick={() => {
							onExport('PDF');
							handleClose();
						}}
					>
						<ListItemText primary={messages["table_export_pdf"]} />
					</MenuItem>
				}
				{props.children}
			</Menu>
		</div>
	);
};

export const DeleteButton = (props) => {
	const { messages } = useIntl();

	return <Button type="icon" tooltip={props.tooltip || messages["table_delete"]} placement="bottom" icon="delete" {...props} />;
};

export const RefreshButton = (props) => {
	const { messages } = useIntl();

	return <Button type="icon" tooltip={props.tooltip || messages["table_refresh"]} placement="bottom" icon="refresh" {...props} />;
};

export const VisibilityButton = (props) => {

	//console.log("props",props)

	const { messages } = useIntl();


	const { columns } = window

	//console.log("toolbar columns",columns)

	const { handleChangeVisibility, visibleColumns, makeAllColumnsVisible } = props

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	let defaultChecked = false

	const [selectAll, setSelectAll] = React.useState(false)

	if (visibleColumns.length == columns.length) {
		defaultChecked = true
	}


	return (
		<div>
			<Button type="icon" variant="contained" placement="bottom" onClick={handleClick} icon="table_chart" tooltip={messages["table_visibility"]} {...props} />
			<Menu
				id="customized-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<div>
					<div id="visibiliy_header" >
						<span>{messages && messages["context_menu_table"]}</span>
					</div>
					<div className="contextMenu--separator" />
					<Grid container style={{ width: 400 }} >
						{columns && columns.map(column =>
							<Grid xs={6} container alignItems="center" key={column.props.label + "vi"} className="elements_visibility" item>

								<Checkbox
									className="checkbox-black-menu"
									id={column.props.label}
									value={visibleColumns?.includes(column.props.field)}
									onChange={() => {
										handleChangeVisibility(event, column)
									}}
								/>
								<span className="visibility_label" >{column.props.label}</span>


							</Grid>
						)}
					</Grid>
					<div className="contextMenu--separator" style={{ marginTop: 15 }} />
					<div id="visibiliy_bottom" >

						<div style={{ display: "flex", alignItems: "center" }} >
							<Checkbox
								className="checkbox-black-menu"
								onChange={async (all) => {
									makeAllColumnsVisible(all)
									setSelectAll(all)
								}}
								value={defaultChecked || selectAll}
							/>
							<span className="visibility_label" >{messages && messages["context_menu_show_all"]}</span>
						</div>

					</div>
				</div>
			</Menu>
		</div>
	)
}

export default (props) => {
	const { messages } = useIntl();


	const [searchText, setSearchText] = React.useState('');
	const [typing, setTyping] = React.useState(false);
	React.useEffect(() => {
		if (typing) {
			const timer = setTimeout(() => {
				if (props.onSearch) {
					props.onSearch(searchText);
				}
			}, 350)
			return () => clearTimeout(timer);
		}
	}, [searchText])
	return (
		<div className={props.classNameContainer || "toolbar"}>
			<Grid container direction="row" justifyContent={props.justifyContent || "space-between"} alignItems={props.alignItems || "flex-start"}>
				<Grid
					container
					item
					direction="row"
					xs={12}
					sm={props.sizeLeftGrid || 6}
					className={`mb-10 ${props.className || ''}`}
					alignItems={props.alignItems}
					justifyContent={props.justifyContent}
				>
					{
						//TOOLBAR
						props.children
					}

					{!props.hideDownloadButton && <ExportButton hidePdfOption={props.hidePdfOption} onExport={props.onExport} disabledExport={props.disabledExport} fontSize={props.fontSize}/>}
					{props.setColumnVisibility && <VisibilityButton handleChangeVisibility={props.handleChangeVisibility}
						visibleColumns={props.visibleColumns} makeAllColumnsVisible={props.makeAllColumnsVisible} />}

				</Grid>
				{!props.hideSearchForm &&
					<Grid item xs={12} sm={6} className="mb-10 text-right">
						<div>
							<FormControl style={{ width: '100%' }}>
								{props.label && <label>{props.label}</label>}
								<OutlinedInput
									id={props.id || 'custom-search'}
									placeholder={props.placeholder || messages['table_search']}
									type={'text'}
									value={searchText || ''}
									fullWidth={true}
									onChange={() => {
										setSearchText(event.target.value);
										setTyping(true);
									}}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="placeholder"
												style={{ fontSize: 25, color: 'black' }}
												size="large">
												<Icon>search</Icon>
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
						</div>
					</Grid>
				}
			</Grid>
		</div>
	);
};