// Dependencies
import React, { Children, cloneElement } from 'react';
import { Modal, Grid, Icon } from '@mui/material';
import PropTypes from 'prop-types';

// Components
import { Button, Loading } from 'Components';
import { Tooltip } from 'Components';

// Styles
import './modal.scss';

export const ModalBody = (props) => {
	const { mainProps, hideDefaultStyles, className } = props;
	const defaultStyles = 'mt-50 mb-100';

	return (
		<Grid
			style={{ ...props.style }}
			className={`modal-body ${(mainProps.position && !hideDefaultStyles) && defaultStyles} ${className || ''}`}
		>
			{props.children}
		</Grid>
	);
};

export const ModalFooter = (props) => {
	const { mainProps } = props;
	return (
		<Grid
			className={`modal-footer ${props.className || ''}  ${mainProps.position && 'modal-footer-fixed modal-footer-' + mainProps.position} modal-footer-${mainProps.size
				? mainProps.size
				: 'medium'}`}
			justifyContent="flex-start"
			container
			direction="row"
			style={{ ...props.style }}
		>
			<div style={{ ...props.styleDiv, zIndex: 2, width: "100%" }}>{props.children}</div>
		</Grid>
	);
};

const splitTitle = [
	{
		size: 'small',
		maxLength: 48
	},
	{
		size: 'medium',
		maxLength: 80
	},
	{
		size: 'large',
		maxLength: 100
	}
];

/**
 * Renders a side menu component.
 * @property {string} size -  extra-small | small | medium | large.
 * @returns {React.Node} The Modal component.
 */
const ModalComponent = (props) => {
	const { header = true } = props;

	const rootRef = React.useRef(null);

	let childs = [];
	if (Array.isArray(props.children)) {
		childs = props.children.map((child) => child);
	} else {
		childs.push(props.children);
	}

	const childrenWithProps = Children.map(childs, (child) => {
		return cloneElement(child, { mainProps: props });
	});

	const getTitleSplited = (title, maxLength) => {
		return `${title.slice(0, maxLength)} ...`;
	}

	const renderTitle = (title, size) => {
		if (title) {
			const sizesToSplit = splitTitle.find(sizeToSplit => sizeToSplit.size === size);
			if (sizesToSplit && title.length >= sizesToSplit.maxLength) {
				return (
					<Tooltip title={title} placement={'bottom'}>
						<span>{getTitleSplited(title, sizesToSplit.maxLength)}</span>
					</Tooltip>
				)
			} else {
				return title;
			}
		}
		return '';
	}

	return (
		<div className="modal-root" ref={rootRef}>

			<Modal
				disablePortal
				disableEnforceFocus
				disableAutoFocus
				open={props.open}
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
				className={`modal-modal ${props.className ? props.className : ''} ${props.position && 'modal-' + props.position}`}
				container={() => rootRef.current}
				style={{ ...props.style }}
				onClose={props.onClose}
			>
				<div className={`modal-content modal-${props.size ? props.size : 'medium'} ${props.position && 'all-height'} ${props.className || ''}`} >
					{
						props.loading &&
						<Loading overlay show />
					}

					{
						props.title
						&& header &&
						<Grid
							container
							className={!props.hiddenStyleTitle && `modal-header ${props.position && 'modal-header-fixed modal-header-' + props.position} modal-header-${props.size ? props.size : 'medium'}` || ''}
							style={{ ...props.headerStyle }}
							direction="row"
							justifyContent="space-between"
							alignItems="center"
						>
							{props.iconBackClose && (
								<Grid item className="text-left">

									<Button
										type="icon"
										icon="arrow_back"
										className="btn-close"
										onClick={() => {
											if (props.handleClose) {
												props.handleClose();
											}
										}}
									/>
								</Grid>
							)}
							<Grid item className="text-right">
								{props.useCloseButtonTop && (
									<Button
										type="icon"
										icon="close"
										className="btn-close"
										onClick={() => {
											if (props.handleClose) {
												props.handleClose();
											}
										}}
									/>
								)}
							</Grid>
							<Grid item xs style={{ display: 'flex' }}>
								{ props.headerIcon && <Icon>{props.headerIcon}</Icon>}
								<span style={{ ...props.modalTitleStyles }} className="modal-title ">{renderTitle(props.title, props.size)}</span>
							</Grid>
							<Grid item className="text-right">
								{!props.hiddenCloseButton && (
									<Button
										type="icon"
										icon="close"
										className="btn-close"
										onClick={() => {
											if (props.handleClose) {
												props.handleClose();
											}
										}}
									/>
								)}
							</Grid>
						</Grid>
					}

					{childrenWithProps}
				</div>
			</Modal>
		</div>
	);
};

export default ModalComponent;

ModalComponent.propTypes = {
	open: PropTypes.bool.isRequired,
};

ModalComponent.defaultProps = {
	handleClose: () => { },
}
