import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    loadingRoutes: false,
    restart: false,
    routes: { items: [], total: 0},
    filters: {
      openSearch: "",
      limit: 10,
      offset: 0
    },
    detail: null,
    route: null,
    loading: false,
    getRouteResponse: false,
    erorrRoutes: null,
    showRoutesInfoMap: false,
    refreshRoutes: false
};

const route = "realtimeMaps/main/routes";

export const realtimeMapsRoutesSearchRoutes = createAsyncThunk('realtimeMaps/main/routes/realtimeMapsRoutesSearchRoutes', async ({ conditions, limit = 10, offset = 0 }, { rejectWithValue, dispatch }) => {
    try {

        const sort = {
            field: "title",
            order: "ASC"
        };

        const response = await clientQuery(
            `
            query searchRoutes (
                $conditions: [RouteSearchInput],
                $sort: [RouteSortInput], 
                $limit: Int, 
                $offset: Int
              ) {
                Routes: searchRoutes (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                  items {
                    id
                    title
                    color
                    isTemplate
                    driverId
                    scheduledAt
                    status
                    encodedPath
                    driver{
                        name
                    } 
                    startPoint
                    endPoint
                  }
                  total
                }
              }
            `,
            {
                conditions: conditions,
                sort, 
                limit, 
                offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.Routes || { items: [], total: 0 }; 

    } catch (exc) {
        ExceptionManager(exc, route, 'realtimeMapsRoutesSearchRoutes');
        return rejectWithValue(exc)
    }
});

export const realtimeMapsRoutesGetRoutes = createAsyncThunk('realtimeMaps/main/routes/realtimeMapsRoutesGetRoutes', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query getRoute ($id: Int!) {
                Route: getRoute (id: $id) {
                  id
                  title
                  color
                  optimized
                  avoidToll

                  isTemplate
                  templateRouteId
                  driverId
                  scheduledAt
                  encodedPath
                  driver{
                    name
                    contactInfo
                    driverId
                  }  

                  points {
                    id
                    name
                    routeId
                    address
                    city
                    state
                    landmarkId
                    latitude
                    longitude
                    radius
                    sequence
                    note       
                    radius       
                    status
                  }                  
                }
              }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.Route || null; 

    } catch (exc) {
        ExceptionManager(exc, route, 'realtimeMapsRoutesGetRoutes');
        return rejectWithValue(exc)
    }
});


export const RealtimeMapsRoutesTasksRedux = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        showRoutePointsInfo: (state, action) => {
            return {
                ...state,
                showRoutesInfoMap: action.payload,
            }
        },
        onRefreshRoutes(state, action){
            return {
                ...state,
                refreshRoutes: action.payload,
            }
        }
    },
    extraReducers: (builder) => {

        builder.addCase(realtimeMapsRoutesSearchRoutes.pending, (state, action) => {
            state.loadingRoutes = true
            state.restart = action?.meta?.arg?.restart

        });
        builder.addCase(realtimeMapsRoutesSearchRoutes.fulfilled, (state, action) => {
            state.routes = action?.payload;
            state.loadingRoutes = false;

        } );
        builder.addCase(realtimeMapsRoutesSearchRoutes.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loadingRoutes = false;

        });

        

        builder.addCase(realtimeMapsRoutesGetRoutes.pending, (state, action) => {
            state.route = null;
            state.loading = true;
            state.getRouteResponse = false;
            state.erorrRoutes = null;

        });
        builder.addCase(realtimeMapsRoutesGetRoutes.fulfilled, (state, action) => {
            state.route = action?.payload;
            state.loading = false;
            state.getRouteResponse = true;

        } );
        builder.addCase(realtimeMapsRoutesGetRoutes.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loading = false;

        });
    }
})

const { actions, reducer } = RealtimeMapsRoutesTasksRedux;

export const { reset, showRoutePointsInfo, onRefreshRoutes } = actions;

export default reducer;