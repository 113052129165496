import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
    ALERT_LOG_GET_SYSTEM_ALERT_LOG
} from 'Redux/actionTypes';

import {
    alertLogGetSystemAlertlogResponse
} from 'Redux/actions';

function* getSystemAlertlogRequest({ payload }) {
    try {
        const { data } = payload;
        const response = yield call(
            clientQuery,
            `
            query getSystemAlertLog (
                $unitId: String,
                $rowId: String
            ) {
                alertLog: getSystemAlertLog (
                    unitId: $unitId,
                    rowId: $rowId
                ) {
                    id
                    systemAlertId
                    systemAlertName
                    severity
                    unitId
                    unit {
                        id
                        label
                        timeZone {
                            name
                        }
                    }
                    unitTime
                    driverId
                    driverName
                    latitude
                    longitude
                    address
                    landmarkId
                    landmarkName
                    read
                    deviceReadingId
                }
            }
            `,
            data,
            endpoints.GRAPHQL_GENERAL,
            {
                'x-api-key': endpoints.PUBLIC_API_KEY
            }
        );

        if (response && response.alertLog) {
            yield put(alertLogGetSystemAlertlogResponse(response.alertLog));
        } else {
            throw 'Not alert log found';
        }
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, 'Core/views/alertLog/redux', 'getSystemAlertlogRequest');
        yield put(alertLogGetSystemAlertlogResponse({ error: true }));
    }
}

function* watchGetSystemAlertlogRequest() {
    yield takeEvery(ALERT_LOG_GET_SYSTEM_ALERT_LOG, getSystemAlertlogRequest);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetSystemAlertlogRequest)
    ]);
}