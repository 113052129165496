import React from "react"
// International
import { useIntl } from "react-intl"
import './style.scss'
const NoData =  () => {
  const { messages } = useIntl();
  return (
    <div className="container-no-data">
      <span>{messages['reportsMainRT_noDataEvent']}</span>
    </div>
  )
}

export default NoData;