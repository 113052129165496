import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import './LinearProgress.scss';

const LinearProgressLoading = ({classes, className}) => {
  return (
    <Grid container>
      <Grid item sm={12} className={className}>
        <LinearProgress 
          color="primary"
          sx={{
            backgroundColor: '#a3f0b6',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#66bb6a'
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default LinearProgressLoading;