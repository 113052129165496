//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { clientMutation } from 'Core/data/GraphQLApi';
//Action Types
import { VECHICLE_STATUS_SAVE_BULK_CHANGE_STARTER } from 'Redux/actionTypes';

//Actions
import {
	saveVehicleStatusSaveBulkChangeStarterResult,
	saveVehicleStatusSaveBulkChangeStarterProgress,
} from 'Redux/actions';

//Function
function* getBulkChangeStarter({ payload }) {
	try {
		const { bulk, DesiredState, AccountId, messages } = payload;
		const newBulk = JSON.parse(JSON.stringify(bulk));
		yield* yield all(
			bulk.map(function* (vehicle, index) {
				newBulk[index] = {
					...vehicle,
					BulkStatus: `InProgress`,
					Message: `In progress`,
				};
				yield put(saveVehicleStatusSaveBulkChangeStarterProgress(newBulk));
				try {
					let data = yield call(
						clientMutation,
						`mutation  (
						$DeviceId: String!,
						$DesiredState: String!,
						$AccountId: String!
						) {
						updateDesired: updateDesiredState(
							DeviceId: $DeviceId,
							DesiredState: $DesiredState,
							AccountId: $AccountId
						) {
							DeviceId
							DesiredState
							AccountId
						}
            			}`,
						{
							DeviceId: vehicle.deviceId,
							DesiredState,
							AccountId,
						},
						endpoints.GRAPHQL_GENERAL,
					);
					// data.updateDesired
					if (data && data) {
						newBulk[index] = {
							...vehicle,
							BulkStatus: `Done`,
							Message: messages['vehicleStatusView_sentMessage'],
						};
						yield put(saveVehicleStatusSaveBulkChangeStarterProgress(newBulk));
					} else {
						newBulk[index] = {
							...vehicle,
							BulkStatus: `Failed`,
							Message: messages['vehicleStatusView_sentMessageError'],
						};
						yield put(saveVehicleStatusSaveBulkChangeStarterProgress(newBulk));
					}
				} catch (exc) {
					newBulk[index] = {
						...vehicle,
						BulkStatus: `Failed`,
						Message: messages['vehicleStatusView_sentMessageError'],
					};
					yield put(saveVehicleStatusSaveBulkChangeStarterProgress(newBulk));
				}
			}),
		);
		yield* yield put(
			saveVehicleStatusSaveBulkChangeStarterResult({
				info: newBulk,
				status: DesiredState,
			}),
		);
	} catch (exc) {
		// ALWAYS RETURN ACTION
		ExceptionManager(
			exc,
			'modules/maintenance/views/redux/saga',
			'saveVehicleStatusSaveBulkChangeStarterResult',
		);
		yield put(saveVehicleStatusSaveBulkChangeStarterResult([]));
	}
}

//Watcher
function* watchBulkChangeStarter() {
	yield takeEvery(VECHICLE_STATUS_SAVE_BULK_CHANGE_STARTER, getBulkChangeStarter);
}

//Saga Export
export default function* rootSaga() {
	yield all([fork(watchBulkChangeStarter)]);
}
