import {
  ENTITY_SELECTOR_GET_LANDMARK_GROUPS,
  ENTITY_SELECTOR_GET_LANDMARK_GROUPS_RESULT,
  ENTITY_SELECTOR_SEARCH_LANDMARKS,
  ENTITY_SELECTOR_SEARCH_LANDMARKS_RESULT,
  ENTITY_SELECTOR_SET_TOTAL_PAGINATED_ITEMS,
  ENTITY_SELECTOR_SEARCH_LANDMARKS_OVER_OFFSET,
  ENTITY_SELECTOR_SEARCH_GEOFENCES,
  ENTITY_SELECTOR_SEARCH_GEOFENCES_RESULT,
  ENTITY_SELECTOR_SEARCH_GEOFENCES_GROUP,
  ENTITY_SELECTOR_SEARCH_GEOFENCES_GROUP_RESULT,
  ENTITY_SELECTOR_SEARCH_FORM_GROUP,
  ENTITY_SELECTOR_SEARCH_FORM_GROUP_RESULT,
  ENTITY_SELECTOR_CLEAR_LANDMARKS
} from 'Redux/actionTypes';

export const getLandmarkGroups = () => ({
  type: ENTITY_SELECTOR_GET_LANDMARK_GROUPS,
});

export const getLandmarkGroupsResult = (data) => ({
  type: ENTITY_SELECTOR_GET_LANDMARK_GROUPS_RESULT,
  payload: { data },
});

export const searchLandmarks = (limit = 0, offset = 0, openSearch = '', shouldClearLandmarks, groupId = null, closeSearch = false) => ({
  type: ENTITY_SELECTOR_SEARCH_LANDMARKS,
  payload: { offset, limit, openSearch, shouldClearLandmarks, groupId, closeSearch },
});

export const clearLandmarks = () => ({
  type: ENTITY_SELECTOR_CLEAR_LANDMARKS,
});

export const searchLandmarksResult = (data, openSearch, shouldClearLandmarks) => ({
  type: ENTITY_SELECTOR_SEARCH_LANDMARKS_RESULT,
  payload: { data, openSearch, shouldClearLandmarks },
});

export const setTotalPaginatedItems = (data) => ({
  type: ENTITY_SELECTOR_SET_TOTAL_PAGINATED_ITEMS,
  payload: { data },
});

export const searchLandmarksOverOffset = (data) => ({
  type: ENTITY_SELECTOR_SEARCH_LANDMARKS_OVER_OFFSET,
  payload: { data },
});

export const searchGeofences = (limit = 0, offset = 0, openSearch = '', groupId = null) => ({
  type: ENTITY_SELECTOR_SEARCH_GEOFENCES,
  payload: { offset, limit, openSearch, groupId },
});

export const searchGeofencesResult = (data) => ({
  type: ENTITY_SELECTOR_SEARCH_GEOFENCES_RESULT,
  payload: { data },
});

export const searchGeofenceGroups = (openSearch = '') => ({
  type: ENTITY_SELECTOR_SEARCH_GEOFENCES_GROUP,
  payload: { openSearch },
});

export const searchGeofenceGroupsResult = (data) => ({
  type: ENTITY_SELECTOR_SEARCH_GEOFENCES_GROUP_RESULT,
  payload: { data },
});

export const searchFormGroups = (openSearch = '') => ({
  type: ENTITY_SELECTOR_SEARCH_FORM_GROUP,
  payload: { openSearch },
});

export const searchFormGroupsResult = (data) => ({
  type: ENTITY_SELECTOR_SEARCH_FORM_GROUP_RESULT,
  payload: { data },
});