//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientMutation, clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
	FLEET_MODAL_OUTPUTS_GET,
	FLEET_MODAL_OUTPUTS_SAVE
} from 'Redux/actionTypes';

import {
	fleetModalOutputsGetResult,
	fleetModalOutputsSaveResult,
} from 'Redux/actions';

export function* getFleetOutputRequest() {
	yield takeEvery(FLEET_MODAL_OUTPUTS_GET, function* ({ payload }) {
		const { id } = payload;
		try {
			let result = yield call(
				clientQuery,
				`query getUnit ($id: Int!) {
					unit: getUnit (id: $id) {
						id
						label
						supportedOutputs
						esn
                        type
						outputs {
							unitId
							position
							name
							type
							state
						}
					}
				}`,
				{
					id: id
				},
				endpoints.GRAPHQL_GENERAL,
			);

			yield put(fleetModalOutputsGetResult(result.unit));
		} catch (exc) {
			ExceptionManager(
				exc,
				'modules/fleet/components/modalOutputs/redux/saga',
				'getFleetOutputRequest',
			);
			yield put(fleetModalOutputsGetResult({ error: true }));
		}
	});
}

export function* saveFleetOutputRequest() {
	yield takeEvery(FLEET_MODAL_OUTPUTS_SAVE, function* ({ payload }) {
		const { data } = payload;

		try {
			const { toCreate, toDelete } = data;

			if (toDelete?.length) {
				let deleteResult = yield call(
					clientMutation,
					` mutation {
						${toDelete.map((item, index) => {
							return `
								delete_${index}: deleteUnitOutput (unitId: ${item.unitId}, position: ${item.position})`
							})
						}
					}`,
					{},
					endpoints.GRAPHQL_GENERAL,
				);
			}

			if (toCreate?.length) {
				let createResult = yield call(
					clientMutation,
					` mutation {
						${toCreate.map((item, index) => {
							return `
								create_${index}: createUnitOutput (unitId: ${item.unitId}, position: ${item.position}, name: "${item.name}", type: ${item.type}, state: ${item.state}) {
									unitId
									position
									name
									type
									state
								}`
							})
						}
					}`,
					{},
					endpoints.GRAPHQL_GENERAL,
				);
			}
			
			yield put(fleetModalOutputsSaveResult({ error: false }));
		} catch (exc) {
			ExceptionManager(
				exc,
				'modules/fleet/components/modalOutputs/redux/saga',
				'saveFleetOutputRequest',
			);
			yield put(fleetModalOutputsSaveResult({ error: true }));
		}
	});
}

//Saga Export
export default function* rootSaga() {
	yield all([
		fork(getFleetOutputRequest),
		fork(saveFleetOutputRequest)
	]);
}