import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    open: false,
    numberTab: null,
    detail: [],
    loadingDetail: false,
    entityName: "",
    entityId: null,
    entityTitle: "",
    loadingUpdateDetail: false,
    updateDetail: null,
    deleteAttachments: null,
    loadingDeleteAttachments: null
};

const route = "modules/maintenance/modals/entityDetails/redux/slice";

export const maintenanceMEDEntityDetails = createAsyncThunk("maintenance/entityDetails/getEntityDetails", async (payload, { rejectWithValue, dispatch }) => {
    const { entityId, entityName } = payload;
    try {
        let data = await clientQuery(
            `{
                    detail: get${entityName}(id: ${entityId}) {
                    id
                    notes
                    ${entityName === "DriverScheduledTask" ? `
                    driverTaskName
                    ` : ``}
                    ${entityName != "DriverScheduledTaskLog" && `subscribers{
                        id
                        contact {
                            id
                            title
                        }
                        alert
                        alertBefore
                        alertAfter
                    }` || ``}
                    files{
                        id
                        originalName
                        repositoryCode
                        fileKey
                    }
                    fileIds
                    }
                }`,
            {},
            endpoints.GRAPHQL_GENERAL
        );
        if (data) {
            return (data);
        }
        else {
            throw "Error while getting entity details";
        }

    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceMEDEntityDetails');
        rejectWithValue(exc);
    }
}
)

export const maintenanceMEDUpdateEntity = createAsyncThunk("maintenance/entityDetails/updateEntity", async (payload, { rejectWithValue, dispatch }) => {
    try {
        const { id } = payload;
        const data = await clientMutation(
            `mutation {
                updateDetail: deleteAlertSubscriber (
                    id: ${id}
                )
                }`,
            {},
            endpoints.GRAPHQL_GENERAL
        );
        if (data) {
            return (data);
        }
        else {
            throw "Error while updating entity details  (deleting alert subscriber) ";
        }
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceMEDUpdateEntity');
        rejectWithValue(exc);
    }
}
)

export const maintenanceMEDDeleteAttachments = createAsyncThunk("maintenance/entityDetails/deleteAttachments", async (payload, { rejectWithValue, dispatch }) => {
    const { id, files, entityName } = payload
    try {
        let data = await clientMutation(
            `mutation ($id: Int!, $fileIds: [Int]){
                    deleteAttachments: update${entityName}(id: $id, fileIds:$fileIds){
                        id
                        fileIds
                    }
                }`,
            { id, fileIds: files },
            endpoints.GRAPHQL_GENERAL
        );
        if (data && data.deleteAttachments) {
            return (data);
        }
        else {
            throw "Error while deleting attachment ";
        }
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, `maintenanceMEDDeleteAttachments/update${entityName}`);
        rejectWithValue(exc);
    }
}
)

export const maintenanceEntityDetailsRedux = createSlice({
    name: 'maintenance/entityDetails',
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalEntityDetails: (state, action) => {
            return {
                ...state,
                open: action.payload.open,
                entityId: action.payload.entityId,
                entityName: action.payload.entityName,
                numberTab: action.payload.numberTab,
                loadingDetail: true,
                entityTitle: action.payload.title
            }
        },
        tabNumber: (state, { payload }) => {
            state.numberTab = payload.numberTab
        },
    },
    extraReducers: (builder) => {

        //maintenanceMEDEntityDetails
        builder.addCase(maintenanceMEDEntityDetails.pending, (state, action) => {
            state.detail = [],
                state.loadingDetail = true
        });
        builder.addCase(maintenanceMEDEntityDetails.fulfilled, (state, { payload }) => {
            state.detail = payload?.detail,
                state.loadingDetail = false
        });
        builder.addCase(maintenanceMEDEntityDetails.rejected, (state, action) => {
            state.detail = [],
                state.loadingDetail = false
        });

        //maintenanceMEDUpdateEntity
        builder.addCase(maintenanceMEDUpdateEntity.pending, (state, action) => {
            state.loadingUpdateDetail = true
        });
        builder.addCase(maintenanceMEDUpdateEntity.fulfilled, (state, { payload }) => {
            state.updateDetail = payload?.updateDetail,
            state.loadingUpdateDetail = false
        });
        builder.addCase(maintenanceMEDUpdateEntity.rejected, (state, action) => {
            state.error = action.payload
            state.loadingUpdateDetail = false
        });

        //maintenanceMEDDeleteAttachments
        builder.addCase(maintenanceMEDDeleteAttachments.pending, (state, action) => {
            state.loadingDeleteAttachments = true
        });
        builder.addCase(maintenanceMEDDeleteAttachments.fulfilled, (state, { payload }) => {
            state.deleteAttachments = payload?.deleteAttachments,
            state.loadingDeleteAttachments = false
        });
        builder.addCase(maintenanceMEDDeleteAttachments.rejected, (state, action) => {
            state.error = action.payload,
            state.loadingDeleteAttachments = false
        });
    }
})

const { actions, reducer } = maintenanceEntityDetailsRedux;

export const { reset, tabNumber, openModalEntityDetails } = actions;

export default reducer;