import {
  //TYPES
  SLIDER_VIDEO_VEHICLETRAILS_GET,
  SLIDER_VIDEO_VEHICLETRAILS_GET_CONFIRM,
  SLIDER_VIDEO_VEHICLETRAILS_GET_CLEAR,
  SLIDER_VIDEO_VEHICLETRAILS_SHOW_TABLE,
  SLIDER_VIDEO_VEHICLETRAILS_SHOW_SETTINGS,
  SLIDER_VIDEO_VEHICLETRAILS_PLAYBACK_SET,
  SLIDER_VIDEO_VEHICLETRAILS_VEHICLE_PRINT,
  SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET,
  SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET_CONFIRM,
  SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNITS_GET,
  SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNITS_GET_RESULT
} from 'Redux/actionTypes';

export const getSliderVehicleTrails = (params, startingQuery = true, exportData = '') => {
  return {
    type: SLIDER_VIDEO_VEHICLETRAILS_GET,
    payload: { params: params, startingQuery: startingQuery, exportData: exportData }
  };
}

export const getSliderVehicleTrailsConfirmResult = (result, error) => ({
  type: SLIDER_VIDEO_VEHICLETRAILS_GET_CONFIRM,
  payload: { result, error },
});

export const updateSliderShowVehicleTrails = (show) => ({
  type: SLIDER_VIDEO_VEHICLETRAILS_GET_CLEAR,
  payload: show,
});

export const updateSliderShowVehicleTrailsTable = (show) => ({
  type: SLIDER_VIDEO_VEHICLETRAILS_SHOW_TABLE,
  payload: show,
});

export const updateSliderShowVehicleTrailsSettings = (show) => ({
  type: SLIDER_VIDEO_VEHICLETRAILS_SHOW_SETTINGS,
  payload: show,
});

export const setSliderVehicleTrailsPlayback = (value = true) => ({
  type: SLIDER_VIDEO_VEHICLETRAILS_PLAYBACK_SET,
  payload: value,
});

export const setSliderVehicleTrailToPrint = (value) => ({
  type: SLIDER_VIDEO_VEHICLETRAILS_VEHICLE_PRINT,
  payload: value,
});

export const searchSliderUnitTypeEvents = (value) => ({
  type: SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET,
  payload: value,
});

export const searchSliderUnitTypeEventsResult = (value) => ({
  type: SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET_CONFIRM,
  payload: value,
});

export const searchSliderUnitGet = (value) => ({
  type: SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNITS_GET,
  payload: value,
});

export const searchSliderUnitGetResult = (value) => ({
  type: SLIDER_VIDEO_VEHICLETRAILS_SEARCH_UNITS_GET_RESULT,
  payload: value,
});