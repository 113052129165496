import { NotificationManager } from 'Components/Notifications';

export class TOAST {

  execute = (payload) => {

    NotificationManager.success(
      payload.message,
      payload.messageTitle,
      0
    );
    return [false, {}]
  }
}