import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
  ROUTES_GET_ROUTES,
  ROUTES_GET_ROUTES_AND_POINT,
  ROUTES_CREATE_UPDATE_ROUTE,
  ROUTES_ADD_STOP,
  ROUTES_DELETE_ROUTE,
  ROUTES_DELETE_STOP,
  ROUTES_GET_ROUTE,
  ROUTES_UPDATE_STOP_ORDER,
  ROUTES_UPDATE_STOP,
  ROUTES_DELETE_ROUTE_MULTIPLE
} from 'Redux/actionTypes';

import {
  getRoutesResponse,
  createUpdateRouteResponse,
  addStopResponse,
  deleteRouteResponse,
  deleteStopResponse,
  getRouteResponse,
  updateStopOrderResponse,
  updateStopResponse,
  deleteRouteMultipleResponse,
  getRoutes
} from 'Redux/actions';

function* getRoutesRequest({ payload }) {
  try {
    const { data: body } = payload;

    const query = `
      query searchRoutes (
        $openSearch: String
        $limit: Int
        $offset: Int
      ) {
        Routes: searchRoutes (openSearch: $openSearch, limit: $limit, offset: $offset) {
          items {
            id
            title
            color
            optimized
            avoidToll
            points {
              id
              routeId
              address
              city
              state
              landmarkId
              latitude
              longitude
              sequence
              note
            }
          }
          total
        }
      }
    `;

    const data = yield call(
      clientQuery,
      query,
      body,
      endpoints.GRAPHQL_GENERAL,
    );

    if (data && data.Routes) {
        let arrayRoutes = {...data.Routes}
        //add the colors values
        if(data.Routes.items && data.Routes.items.length > 0){  
            arrayRoutes.items = []                   
            data.Routes.items.forEach((item) => {
                arrayRoutes.items.push({ ...item, color: item.color && item.color.includes('#') ? item.color : `#${item.color}`});
            })
        }
        yield put(getRoutesResponse(arrayRoutes));
    } else {
      throw 'Not routes data';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/realtimeMaps/main/components/routes/redux/saga', 'getRoutesRequest');
    yield put(getRoutesResponse({ items: [], total: 0, error: true }));
  }
}

function* getRoutesAndRoutePointRequest({ payload }) {
    try {
      const { data: body } = payload;
  
      const query = `
        query searchRoutesAndRoutePoints (
          $openSearch: String
          $limit: Int
          $offset: Int
        ) {
          Routes: searchRoutesAndRoutePoints (openSearch: $openSearch, limit: $limit, offset: $offset) {
            items {
              id
              title
              color
              optimized
              avoidToll
              points {
                id
                routeId
                address
                city
                state
                landmarkId
                latitude
                longitude
                sequence
                note
              }
            }
            total
          }
        }
      `;
  
      const data = yield call(
        clientQuery,
        query,
        body,
        endpoints.GRAPHQL_GENERAL,
      );
  
      if (data && data.Routes) {
          let arrayRoutes = {...data.Routes}
          //add the colors values
          if(data.Routes.items && data.Routes.items.length > 0){  
              arrayRoutes.items = []                   
              data.Routes.items.forEach((item) => {
                  arrayRoutes.items.push({ ...item, color: item.color && item.color.includes('#') ? item.color : `#${item.color}`});
              })
          }
          yield put(getRoutesResponse(arrayRoutes));
      } else {
        throw 'Not routes data';
      }
    } catch (exc) {
      // ALWAYS RETURN ACTION
      ExceptionManager(exc, 'modules/realtimeMaps/main/components/routes/redux/saga', 'getRoutesAndRoutePointRequest');
      yield put(getRoutesResponse({ items: [], total: 0, error: true }));
    }
  }

function* createUpdateRouteRequest({ payload }) {
  try {
    let { data: body } = payload;
    //Remove the # character - database have only 6 chars
    if(body?.color){
      body.color = body.color.replace('#', '');
    }

    let query = `
      mutation createRoute (
        $title: String!, 
        $color: String, 
        $optimized: Boolean, 
        $avoidToll: Boolean,
        $points: [RoutePointInput]
      ) {
        Route: createRoute(title: $title, color: $color, optimized: $optimized, avoidToll: $avoidToll, points: $points) {
          id
          title
          color
          optimized
          avoidToll
          points {
            id
            routeId
            address
            city
            state
            landmarkId
            latitude
            longitude
            sequence
            note
          }
        }
      } 
    `;

    if (body?.id) {
      query = `
        mutation updateRoute (
          $id: Int!,
          $title: String!, 
          $color: String, 
          $optimized: Boolean!, 
          $avoidToll: Boolean!
        ) {
          Route: updateRoute(id: $id, title: $title, color: $color, optimized: $optimized, avoidToll: $avoidToll) {
            id
            title
            color
            optimized
            avoidToll
            points {
              id
              routeId
              address
              city
              state
              landmarkId
              latitude
              longitude
              sequence
              note
            }
          }
        } 
      `;
    }

    const data = yield call(
      clientMutation,
      query,
      body,
      endpoints.GRAPHQL_GENERAL,
    );

    if (data && data.Route) {
      //add the char # to colors
      let dataRoute = {
        ...data.Route, 
        color : data.Route.color && data.Route.color.includes('#') ? data.Route.color : `#${data.Route.color}` 
      }
      yield put(createUpdateRouteResponse(dataRoute));
    } else {
      throw 'Error while creating route';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/realtimeMaps/main/components/routes/redux/saga', 'createUpdateRouteRequest');

    //name already exist
    if(exc.error == "Routes duplicated title"){
      yield put(createUpdateRouteResponse({ error: true, errName: true }));
      return
    }

    let hideMessage = false;
    if (exc?.errorData && exc?.errorData?.errors) {
			exc?.errorData?.errors?.forEach(error => {
        if (error.errorInfo && error.errorInfo.code) {
          hideMessage = true;
        }
      });
    }

    yield put(createUpdateRouteResponse({ error: true, hideMessage: hideMessage }));
  }
}

function* addStopRequest({ payload }) {
  try {
    const { data: body } = payload;

    const data = yield call(
      clientMutation,
      `
      mutation createRoutePoint (
        $routeId: Int!, 
        $address: String, 
        $city: String, 
        $state: String, 
        ${body?.landmarkId ? '$landmarkId: Int, ' : ''}
        $latitude: Float!, 
        $longitude: Float!, 
        $sequence: Int!, 
        $note: String
      ) {
        Stop: createRoutePoint(
          routeId: $routeId, 
          address: $address, 
          city: $city, 
          state: $state, 
          ${body?.landmarkId ? 'landmarkId: $landmarkId, ' : ''} 
          latitude: $latitude, 
          longitude: $longitude, 
          sequence: $sequence, 
          note: $note
        ) {
          id
          routeId
          address
          city
          state
          ${body?.landmarkId ? 'landmarkId' : ''}
          latitude
          longitude
          sequence
          note
        }
      } `,
      body,
      endpoints.GRAPHQL_GENERAL,
    );

    if (data && data.Stop) {
      yield put(addStopResponse(data.Stop));
    } else {
      throw 'Error while adding stop to route';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/realtimeMaps/main/components/routes/redux/saga', 'addStopRequest');
    yield put(addStopResponse({ error: true }));
  }
}

function* deleteRouteRequest({ payload }) {
  try {
    const { data: body } = payload;
    const { id } = body;
    
    let data = yield call(
      clientMutation,
      `mutation ($id: Int!){
        Route: deleteRoute(id: $id)
      }`,
      {
        id
      },
      endpoints.GRAPHQL_GENERAL
    );
    if (data && data.Route) {
      yield put(deleteRouteResponse(data.Route));
    } else {
      throw 'Error while deleting route stop';
    } 
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/realtimeMaps/main/components/routes/redux/saga', 'deleteRouteRequest');
    yield put(deleteRouteResponse({ error: true }));
  }
}

function* deleteRouteMultipleRequest({ payload }) {
	try {
    let mutations = [];
		const { data : arrayDeleteRoutes } = payload;

        arrayDeleteRoutes.forEach((id, index) => {
        mutations.push(`
        Route${index}: deleteRoute(
            id: ${id}
            )`);
        })

		let mutation = `mutation { ${mutations.join(" ")} }`;

        let data = yield call(
        clientMutation,
        mutation,
        {},
        endpoints.GRAPHQL_GENERAL,
        );
    
		if (data && data.Route0) {
			yield put(deleteRouteMultipleResponse(data.Route0));
      yield put(getRoutes({ openSearch: '', offset: 0, limit: 25 })); //limit: ROWS_PER_PAGE
		} else {
			throw "Error while deleting route";
		}
	} catch (exc) {
		// ALWAYS RETURN ACTION
		ExceptionManager(exc, 'modules/realtimeMaps/main/components/routes/redux/saga', 'deleteRouteBulkRequest');
    yield put(deleteRouteResponse({ error: true }));
	}
}

function* deleteStopRequest({ payload }) {
  try {
    const { data: body } = payload;
    const { id } = body;

    let data = yield call(
      clientMutation,
      `mutation ($id: String!){
        Stop: deleteRoutePoint(id: $id)
      }`,
      {
        id
      },
      endpoints.GRAPHQL_GENERAL
    );
    
    if (data && data.Stop) {
      yield put(deleteStopResponse(data.Stop));
      yield put(deleteStopResponse(null));
    } else {
      throw 'Error while deleting route stop';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/realtimeMaps/main/components/routes/redux/saga', 'deleteStopRequest');
    yield put(deleteStopResponse({ error: true }));
  }
}

function* getRouteRequest({ payload }) {
  try {
    const { data: body } = payload;

    const query = `
      query getRoute ($id: Int!) {
        Route: getRoute (id: $id) {
          id
          title
          color
          optimized
          avoidToll
          points {
            id
            routeId
            address
            city
            state
            landmarkId
            latitude
            longitude
            sequence
            note
          }
        }
      }
    `;

    const data = yield call(
      clientQuery,
      query,
      body,
      endpoints.GRAPHQL_GENERAL,
    );

    if (data && data.Route) {
      //add the char # to colors
      let dataRoute = {
        ...data.Route, 
        color: data.Route.color && data.Route.color.includes('#') ? data.Route.color : `#${data.Route.color}` 
      }
      yield put(getRouteResponse(dataRoute));
    } else {
      throw 'Not route data';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/realtimeMaps/main/components/routes/redux/saga', 'getRouteRequest');
    yield put(getRouteResponse({ error: true }));
  }
}

function* updateStopOrderRequest({ payload }) {
  try {
    const { data: body } = payload;

    let data = yield call(
      clientMutation,
      `mutation updateRoutePointsSequence ($routeId: Int!, $sequence: [RoutePointSequence]!) {
        Stop: updateRoutePointsSequence (routeId: $routeId, sequence: $sequence)
      }`,
      body,
      endpoints.GRAPHQL_GENERAL
    );
    
    if (data && data.Stop) {
      yield put(updateStopOrderResponse(data.Stop));
      yield put(updateStopOrderResponse(null));
    } else {
      throw 'Error while deleting route stop';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/realtimeMaps/main/components/routes/redux/saga', 'updateStopOrderRequest');
    yield put(updateStopOrderResponse({ error: true }));
  }
}

function* updateRoutePointRequest({ payload }) {
  try {
    const { data: body } = payload;
    let data = yield call(
      clientMutation,
      `mutation updateRoutePoint ($id: String!, $address: String, $city: String, $state: String, $landmarkId: Int, $latitude: Float, $longitude: Float, $sequence: Int, $note: String) {
        updateRoutePoint (id: $id, address: $address, city: $city, state: $state, landmarkId: $landmarkId, latitude: $latitude, longitude: $longitude, sequence: $sequence, note: $note) {
            id
            routeId
            address
            city
            state
            landmarkId
            latitude
            longitude
            sequence
            note
        }
    }`,
      body,
      endpoints.GRAPHQL_GENERAL
    );
    if (data && data.updateRoutePoint) {
      yield put(updateStopResponse(data.updateRoutePoint));
    } else {
      throw 'Error while deleting route stop';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/realtimeMaps/main/components/routes/redux/saga', 'updateRoutePointRequest');
    yield put(updateStopResponse({ error: true }));
  }
}

function* watchGetRoutesRequest() {
  yield takeEvery(ROUTES_GET_ROUTES, getRoutesRequest);
  yield takeEvery(ROUTES_GET_ROUTES_AND_POINT, getRoutesAndRoutePointRequest);
}

function* watchCreateUpdateRouteRequest() {
  yield takeEvery(ROUTES_CREATE_UPDATE_ROUTE, createUpdateRouteRequest);
}

function* watchAddStopRequest() {
  yield takeEvery(ROUTES_ADD_STOP, addStopRequest);
}

function* watchDeleteRouteRequest() {
  yield takeEvery(ROUTES_DELETE_ROUTE, deleteRouteRequest);
  yield takeEvery(ROUTES_DELETE_ROUTE_MULTIPLE, deleteRouteMultipleRequest);
}

function* watchDeleteStopRequest() {
  yield takeEvery(ROUTES_DELETE_STOP, deleteStopRequest);
}

function* watchGetRouteRequest() {
  yield takeEvery(ROUTES_GET_ROUTE, getRouteRequest);
}

function* watchUpdateStopsRequest() {
  yield takeEvery(ROUTES_UPDATE_STOP_ORDER, updateStopOrderRequest);
  yield takeEvery(ROUTES_UPDATE_STOP, updateRoutePointRequest);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetRoutesRequest),
    fork(watchCreateUpdateRouteRequest),
    fork(watchAddStopRequest),
    fork(watchDeleteRouteRequest),
    fork(watchDeleteStopRequest),
    fork(watchGetRouteRequest),
    fork(watchUpdateStopsRequest),
  ]);
}
