export default {
    map_geofence_geofenceCreateEdit_createTitle: "Create New Geofence",
    map_geofence_geofenceCreateEdit_editTitle: "Edit Geofence: ",
    map_geofence_geofenceCreateEdit_typeGeofence: "Geofence Type",
    map_geofence_geofenceCreateEdit_polygon: 'Polygon',
    map_geofence_geofenceCreateEdit_max: 'Polygon +',
    map_geofence_geofenceCreateEdit_circle: 'Circle',
    map_geofence_geofenceCreateEdit_square: 'Square',
    map_geofence_geofenceCreateEdit_inout: 'In/Out',
    map_geofence_geofenceCreateEdit_in: 'In',
    map_geofence_geofenceCreateEdit_out: 'Out',
    map_geofence_geofenceCreateEdit_alerton: 'Alert On',
    map_geofence_geofenceCreateEdit_info_polygon: 'Begin clicking points on the map to create a geofence. Must be 3–{maxPoints} points.',
    map_geofence_geofenceCreateEdit_info_circle: 'The geofence will be centered at the address provided with the specified radius. Use the locate address area above to change the location.',
    map_geofence_geofenceCreateEdit_group: 'Geofence Tags',
    map_geofence_geofenceCreateEdit_tag: 'Tag',
    map_geofence_geofenceCreateEdit_tag_select: "Select Tags",
    map_geofence_geofenceCreateEdit_contacts: 'Subscribers',
    map_geofence_geofenceCreateEdit_name: "Name",
    map_geofence_geofenceCreateEdit_address: "Address",
    map_geofence_geofenceCreateEdit_latLng: "Lat/Long",
    map_geofence_geofenceCreateEdit_radius: "Radius",
    map_geofence_geofenceCreateEdit_diagonal: "Diagonal",
    map_geofence_geofenceCreateEdit_circleRequired: 'Please add point in the map for create the Geofence',
    map_geofence_geofenceCreateEdit_alerton: 'Alert On',
    map_geofence_geofenceCreateEdit_create: 'Geofence Create',
    map_geofence_geofenceCreateEdit_success: "Geofence saved successfully",
    map_geofence_geofenceCreateEdit_insideDuration:"Inside Duration",
    map_geofence_geofenceCreateEdit_isSquare:"Free Desing (Draw Rectangle)",
    map_geofence_geofenceCreateEdit_timeInside:"Time Inside",
    map_geofence_geofenceCreateEdit_enabled:"Enabled",
    map_geofence_geofenceCreateEdit_disabled:"Disabled",
    map_geofence_error_time_duration:"Time Inside must be less than 120 hours",
    map_geofence_error_time_duration_min:"Time Inside must be greater than 1 minutes",
    map_geofence_error_time_duration_hours:"hours",
    map_geofence_error_time_duration_minutes:"minutes",
    map_geofence_geofenceCreateEdit_tags_shape: "Geofence Shape",
    map_geofence_geofenceCreateEdit_tags_label: "Geofence Label",
    map_geofence_geofenceCreateEdit_alert: "Send Alert",
    map_geofence_geofenceCreateEdit_error_message: "Geofence duplicated name",
    map_geofence_geofenceCreateEdit_error_define_geofence: "Use Address, Map click or Unit to create a Geofence on the map"
};