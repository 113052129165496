export default {
    dataExport_xmlOutputLabel: "XML Datapump Output",
    dataExport_xmlOutputDate: "Datapump Date range",
    dataExport_url: "Get URL",
    dataExport_dataPumpFeedDescription: "Datapump feed by Date Range",
    dataExport_dataFeed1Description: "Datapump feed (selected unit/tag updates since last feed request, limited to 400 events per unit, not recomended for large unit groups)",
    dataExport_dataFeed2Description: "Datapump feed (last reported event from selected unit/tag)",
    dataExport_dataFeed3Description: "Datapump feed (last reported event from selected unit/tag) times on UTC",
    dataExport_dataPumpFeedLabel: "Datapump Feed",
    dataExport_dataFeed1Label: "Feed 1",
    dataExport_dataFeed2Label: "Feed 2",
    dataExport_dataFeed3Label: "Feed 3",
    dataExport_FooterLabel: "**It is recommended that you keep your data request queries to a minimum of 5 minute intervals",
};