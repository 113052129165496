export default {
    settingsVehicleStatus_editTitle:"Edit Status Label",
    settingsVehicleStatus_createTitle:"Create new Status Label",
    settingsVehicleStatus_minLengthMessage:'The field must contain minimum chars: ',
    settingsVehicleStatus_maxLengthMessage:'The field must contain maximum chars: ',
    settingsVehicleStatus_success:'Vehicle Status Label saved',
    settingsVehicleStatus_deleteWarning:'Are you sure you want to delete the vehicle status label?',
    settingsVehicleStatus_deleteTitle:'Delete Status Label',
    settingsVehicleStatus_deleted:'Vehicle status label deleted',
    settingsVehicleStatus_search:'Search by Status Label',
    settingsVehicleStatus_statusLabel:'Status Label',
    settingsVehicleStatus_vehicleStatusIcon:'Icon'
};