import React, { Fragment, useState } from 'react'
import cx from 'classnames';

import { TableHead, TableRow, TableCell, TableSortLabel, Checkbox } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const TableHeader = (props) => {
    const {
        disableItemSelection = false,
        columnsView,
        onChangeColumns,
        hideDragable,
        selectedItems,
        onChageSelectedItems,
        dataItems,
        onCollapse,
        sort, 
        onChangeSort
    } = props
    return (
        <TableHead>
            {
                hideDragable ?
                    <TableRow
                    //className={cx(`drag-drop-list-container ${snapshot.isDraggingOver ? 'dragged' : 'dragging'}`)}
                    >
                        {
                            !disableItemSelection &&
                            <TableCell padding="checkbox">
                                <Checkbox
                                    style={{ color: "black" }}
                                    indeterminate={selectedItems.length > 0 && selectedItems.length < dataItems.length}
                                    checked={selectedItems.length == dataItems.length}
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            if (selectedItems.length > 0 && selectedItems.length < dataItems.length) {
                                                onChageSelectedItems([])
                                            } else {
                                                onChageSelectedItems(dataItems)
                                            }
                                        } else {
                                            onChageSelectedItems([])
                                        }
                                    }}
                                    inputProps={{ 'aria-label': 'select all desserts' }}
                                    size="small"
                                />
                            </TableCell>
                        }

                        {
                            onCollapse &&
                            <TableCell style={{width: 50, padding: 0}}></TableCell>
                        }

                        {
                            columnsView.map((item, index) => {
                                return (
                                    <TableCell
                                        key={index}
                                        style={{ width: item.width, minWidth: item.width, height: 44 }}
                                    >
                                        {
                                            item.sort ?
                                                <TableSortLabel
                                                    active={sort?.key === item.key}
                                                    direction={sort?.key === item.key ? sort?.order : 'asc'}
                                                    onClick={() => {
                                                        let newSort = sort
                                                        if (sort?.key === item.key)
                                                            newSort = { key: item.key, order: sort?.order == 'asc' ? 'desc' : 'asc' }
                                                        else
                                                            newSort = { key: item.key, order: 'asc' }

                                                        onChangeSort(newSort)
                                                    }}
                                                >
                                                    {item.title}
                                                </TableSortLabel>
                                                : item.title

                                        }
                                    </TableCell>
                                )
                            })
                        }
                    </TableRow>
                    :
                    <DragDropList
                        items={columnsView}
                        keyName="key"
                        className="header_drag-drop-list-container"
                        direction="horizontal"
                        itemRenderer={(col) => {
                            return (
                                <div key={col.key}>
                                    {col.title}
                                </div>
                            );
                        }}
                        onDragEnd={(result) => {
                            if (!result.destination) return;

                            const newColumns = [...columnsView];

                            const [reorderedItem] = newColumns.splice(result.source.index, 1);
                            newColumns.splice(result.destination.index, 0, reorderedItem);

                            onChangeColumns(newColumns);
                        }}
                        {...props}
                    />
            }

        </TableHead>
    )
}

export default TableHeader;

const DragDropList = (props) => {
    const { disableItemSelection = false, items, keyName, itemRenderer, onDragEnd, direction, isDragDisabled, sort, onChangeSort, selectedItems, onChageSelectedItems } = props;
    return (
        <Fragment>
            <DragDropContext onDragEnd={onDragEnd} alwaysRenderSuggestions={true}>
                <Droppable
                    droppableId="droppable"
                    direction={direction || 'vertical'}
                    renderClone={(provided, snapshot, rubric) => (
                        <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="droppable-cell"
                        >
                            {
                                itemRenderer && itemRenderer(items[rubric.source.index]) || items[rubric.source.index][keyName]
                            }
                        </div>
                    )}
                >
                    {(provided, snapshot) => (
                        <TableRow
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={cx(`drag-drop-list-container ${snapshot.isDraggingOver ? 'dragged' : 'dragging'}`)}
                        >
                            {
                                !disableItemSelection &&
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        style={{ color: "black" }}
                                        indeterminate={selectedItems.length > 0 && selectedItems.length < props.dataItems.length}
                                        checked={selectedItems.length == props.dataItems.length}
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                if (selectedItems.length > 0 && selectedItems.length < props.dataItems.length) {
                                                    onChageSelectedItems([])
                                                } else {
                                                    onChageSelectedItems(props.dataItems)
                                                }
                                            } else {
                                                onChageSelectedItems([])
                                            }
                                        }}
                                        inputProps={{ 'aria-label': 'select all desserts' }}
                                        size="small"
                                    />
                                </TableCell>
                            }

                            {
                                items && items.map((item, index) => (
                                    /*<TableCell
                                        key={`${item[keyName]}`}
                                        style={{ width: item.width, minWidth: item.width }}
                                    >*/
                                    <Draggable
                                        key={`${item[keyName]}`}
                                        draggableId={`${item[keyName]}`}
                                        index={index}
                                        isDragDisabled={isDragDisabled}
                                    >
                                        {(provided, snapshot) => (
                                            <TableCell
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{ width: item.width, minWidth: item.width, height: 44 }}
                                            >
                                                {
                                                    item.sort ?
                                                        <TableSortLabel
                                                            active={sort?.key === item.key}
                                                            direction={sort?.key === item.key ? sort?.order : 'asc'}
                                                            onClick={() => {
                                                                let newSort = sort
                                                                if (sort?.key === item.key)
                                                                    newSort = { key: item.key, order: sort?.order == 'asc' ? 'desc' : 'asc' }
                                                                else
                                                                    newSort = { key: item.key, order: 'asc' }

                                                                onChangeSort(newSort)
                                                            }}
                                                        >
                                                            {itemRenderer && itemRenderer(item) || item[keyName]}
                                                        </TableSortLabel>
                                                        : itemRenderer && itemRenderer(item) || item[keyName]

                                                }
                                            </TableCell>

                                        )}
                                    </Draggable>
                                    /*</TableCell>*/
                                ))
                            }
                            {provided.placeholder}
                        </TableRow>
                    )}
                </Droppable>
            </DragDropContext>
        </Fragment >
    );
}

const ResizeCell = (props) => {
    const [separatorXPosition, setSeparatorXPosition] = useState();
    const [dragging, setDragging] = useState(false);
    const [leftWidth, setLeftWidth] = useState(100)

    return (
        <TableCell
            /*onMouseDown={(e) => {
                //console.log("onMouseDown", e)
                //setSeparatorXPosition(e.clientX);
                //setDragging(true);
            }}
            onMouseMove={(e) => {
                
                if (dragging && leftWidth && separatorXPosition) {
                    console.log("onMouseMove", e, dragging , leftWidth , separatorXPosition)
                    const newLeftWidth = leftWidth + e.clientX - separatorXPosition;
                    setSeparatorXPosition(e.clientX);
                    setLeftWidth(newLeftWidth);
                }
            }}*/
            style={{ minWidth: props.width || 'auto' }}
        >
            {props.children}
        </TableCell>
    )
}