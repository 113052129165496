//imports
import {
  GET_RESELLER_INFO,
  GET_RESELLER_INFO_RESULT
} from 'Redux/actionTypes';

export const getResellerInfo = (type, value) => ({
	type: GET_RESELLER_INFO,
	payload: {
    type,
    value
  },
});

export const getResellerInfoResult = (data) => ({
	type: GET_RESELLER_INFO_RESULT,
	payload: {
    data
  },
});
