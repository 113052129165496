export default {
    "FSM_SETTINGS_DRIVER_STATUS": "Driver Status Selection",
    "FSM_SETTINGS_DRIVER_STATUS_title": "New Driver Status Selection ",
    "FSM_SETTINGS_DRIVER_STATUS_edit": "Edit Driver Status Selection ",
    "FSM_SETTINGS_DRIVER_STATUS_delete_detail":'Are you sure you want to delete the following Driver Status Selections?',
    "FSM_SETTINGS_DRIVER_STATUS_delete_warning":'This action cannot be undone.',
    "FSM_SETTINGS_DRIVER_STATUS_delete_title":'Delete Driver Status Selections',
    "FSM_SETTINGS_DRIVER_STATUS_create_success": "Driver Status Selection Created",
    "FSM_SETTINGS_DRIVER_STATUS_update_success": "Driver Status Selection Updated",
    "FSM_SETTINGS_DRIVER_STATUS_delete_success": "Driver Status Selection Deleted",
};