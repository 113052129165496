import {
    FSM_OPEN_SETTINGS,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    openSettingsFsm: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case FSM_OPEN_SETTINGS:
            return {
                ...state,
                openSettingsFsm: action.payload?.open
            };

        case CLEAR_REDUX:
            return ["", "FSM"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return state;
    }
};