import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { NotificationManager } from 'Components/Notifications';

//Action types
import {
    MAP_LANDMARKS_VISIBLE_LANDMARK_GROUPS_GET_LANDMARKS
} from 'Redux/actionTypes';

//actions
import {
    mapLandmarksVisibleGetLandmarkGroupsResponse
} from 'Redux/actions';

function* getLandmarkGroupsRequest() {
    try {
        const query = `
            query searchTagsInline ($entityName: String, $limit: Int, $offset: Int) {
                result: searchTagsInline (entityName: $entityName, limit: $limit, offset: $offset) {
                    items {
                        id
                        label
                        color
                    }
                    total
                }
            }
        `;

        let response = yield call(
            clientQuery,
            query,
            {
                entityName: "Landmark"
            },
            endpoints.GRAPHQL_GENERAL,
        );

        let landmarkGroups = response?.result;

        if (!landmarkGroups) throw 'Error while getting landmark groups';

        if(landmarkGroups?.items && landmarkGroups.items.length > 0 ){
            let landmarkTags = landmarkGroups.items.map((tag) => {
                return {
                    ...tag,
                    name: tag.label
                }
            })
            yield put(mapLandmarksVisibleGetLandmarkGroupsResponse(landmarkTags));
        }else{
            yield put(mapLandmarksVisibleGetLandmarkGroupsResponse([]));
        }

        
    } catch (exc) {
        yield put(mapLandmarksVisibleGetLandmarkGroupsResponse([]));
        ExceptionManager(exc, 'ealtimeMaps/main/components/landmarks/components/landmarkVisibleGroups/redux/saga', 'getLandmarkGroupsRequest');
    }
};

function* watcVisibleLandmarkGroupsRequest() {
    yield takeEvery(MAP_LANDMARKS_VISIBLE_LANDMARK_GROUPS_GET_LANDMARKS, getLandmarkGroupsRequest);
}

//Saga Export
export default function* rootSaga() {
    yield all([
        fork(watcVisibleLandmarkGroupsRequest)
    ]);
}