import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../../../core/logManager';

import { resetALL } from '../../../../../../../.globals/redux/actions';

import moment from "moment";

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    items: [],
    total: 0,
    services: [],
    error: null
};

const route = 'modules/maintenance/views/main/components/servicesTab/redux/slice'

export const maintenanceVSTGetServices = createAsyncThunk('maintenance/servicesTab/maintenanceVSTGetServices', async ({ searchText }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
                query ($openSearch: String){
                    data: searchMaintenanceServices(openSearch: $openSearch){
                        id
                        title
                        notes
                        miles
                        milesBefore
                        milesAfter
                        days
                        daysBefore
                        daysAfter
                        engineHours
                        engineHoursBefore
                        engineHoursAfter
                        recurrent
                        estimatedCost
                        subscribersCount
                        fileIds
                        completedServices
                        scheduledServices
                    }
                }
            `,
            {
                openSearch: searchText || '',
            },
            endpoints.GRAPHQL_GENERAL
        );

        return response?.data || [];
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceVTGetServicesList');
        return rejectWithValue(exc)
    }
});

export const maintenanceVSTSearchServices = createAsyncThunk('maintenance/servicesTab/maintenanceVSTSearchServices', async ({ conditions, sort, limit = 10, offset = 0 }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query SearchMaintenanceServices($conditions: [MaintenanceServiceSearchInput], $sort: [MaintenanceServiceSortInput], $limit: Int, $offset: Int) {
                data: searchMaintenanceServices(conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                    total
                    items {
                        id
                        title
                        notes
                        miles
                        milesAfter
                        milesBefore
                        days
                        daysAfter
                        daysBefore
                        engineHours
                        engineHoursAfter
                        engineHoursBefore
                        recurrent
                        estimatedCost
                        subscribersCount
                        fileIds
                        completedServices
                        scheduledServices
                    }
                }
            }
            `,
            {
                conditions,
                sort,
                limit,
                offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        return response?.data || { items: [], total: 0 };
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceVSTSearchServices');
        return rejectWithValue(exc)
    }
});

const maintenanceServicesTabRedux = createSlice({
    name: 'maintenance/servicesTab',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : INIT_STATE
        },
        // changeFilters(state, action) {
        //     state.filters = action.payload
        // },
        // openAdvancedFilters(state) {
        //     state.openAdvancedFilters = true;
        // },
        // closeAdvancedFilters(state) {
        //     state.openAdvancedFilters = false;
        // },
        // updateDynamicFormAnswersFilters(state, action) {
        //     state.filters = action.payload;
        // }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        //maintenanceVSTGetServices
        builder.addCase(maintenanceVSTGetServices.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(maintenanceVSTGetServices.fulfilled, (state, action) => {
            state.loading = false;
            state.services = action.payload
        });
        builder.addCase(maintenanceVSTGetServices.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });

        // maintenanceVSTSearchServices
        builder.addCase(maintenanceVSTSearchServices.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(maintenanceVSTSearchServices.fulfilled, (state, action) => {
            state.loading = false;
            state.items = action.payload?.items || []
            state.total = action.payload?.total || 0
        });
        builder.addCase(maintenanceVSTSearchServices.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = maintenanceServicesTabRedux;

// Extract and export each action creator by name
export const { reset, changeFilters } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;