//View Keys
export default {
	fleetView_vehicle: 'Unit',
	fleetView_vehicles: 'Vehicles',
	fleetView_search: 'Search',
	fleetView_vehicleStatus: 'Vehicle Status',
	fleetView_advancedFilters: 'Filter By',
	fleetView_clearFilters: 'Clear filters',
	fleetView_clearAll: 'Clear all',
	fleetView_vehicleGroups: 'Vehicle Groups',
	fleetView_select: 'Select',
	fleetView_starter: 'Starter Status',
	fleetView_drivers: 'Driver',
	fleetView_groups: 'Groups',
	fleetView_tags: 'Tags',
	fleetView_address: 'Address',
	fleetView_dataTime: 'Date / Time',
	fleetView_event: 'Event',
	fleetView_vin: 'VIN',
	fleetView_year: 'Year',
	fleetView_make: 'Make',
	fleetView_model: 'Model',
	fleetView_engineOdometer:'Odometer',
	fleetView_speed:'Speed',
	fleetView_speedLimit:'Posted Speed',
	fleetView_engineHours:'Engine Hours',
	fleetView_usn:'ESN',
	fleetView_imei:'IMEI',
	fleetView_events: 'Events',
	fleetView_times: 'Last Reported',
	fleetView_icon:'Icon',
	fleetView_searchGroups: 'Search Groups',
	fleetView_notSupport: 'No Supported',
	fleetView_starterDisabled: 'Starter Disabled',
	fleetView_starterEnablePending: 'Starter Enable Pending',
	fleetView_starterDisablePending: 'Starter Disable Pending',
	fleetView_starter_enabled: 'Starter Enabled',
	fleetView_ScheduledStarter: 'Scheduled Starter',
	fleetView_ScheduledGroupStarter: 'Scheduled Group Starter',
	vehicleStatusModal_titleModalEnableDisable: 'Enable/Disable Starter',
	vehicleStatusModal_confirmationModalEnableDisable:
		'Are you sure you want to change the status starter?',
	vehicleStatusModal_currentStatus: 'Current Status',
	fleetView_disable: 'DISABLE',
	fleetView_enable: 'ENABLE',
	fleetView_outputs: 'Unit Outputs',
	fleetView_ping_toolTip: 'Send locate',

	fleetView_starter_no_permission: 'You don\'t have permission',
	fleetView_starter_no_supported: 'No Supported',
	fleetView_create_landmark: 'Create Landmark',
	All: 'All',
	None: 'None',
	fleetView_group: "Tag",
	fleetView_unit: "Unit",
	fleetView_vehicleTags: "Tags",

	fleetView_locate: "Locate",
	fleetView_enableDisableStarter: "Enable/Disable Starter",
	fleetView_outputs: "Outputs",

	fleetView_linkedDevice: "Linked Device",

	fleetView_unitOverViewPanel: "Vehicle Overview",

	fleetView_unitOverViewPanel: "Vehicle Overview",
	fleetView_sdCardError: "SD card error",
	fleetView_warnings: "Device Warnings",
};
