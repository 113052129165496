// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  SETTINGS_VEHICLE_STATUS_SEARCH,
  SETTINGS_VEHICLE_STATUS_CREATE_EDIT,
  SETTINGS_VEHICLE_STATUS_ICON_GET,
  SETTINGS_VEHICLE_STATUS_DELETE
} from "Redux/actionTypes";

// Actions
import {
  searchSettingsVehicleStatusResponse,
  createEditSettingsVehicleStatusResponse,
  getSettingsVehicleStatusResponse,
  deleteSettingsVehicleStatusResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* searchVehicleStatusRequest({ payload }) {
  try {
    const {
      openSearch
    } = payload;

    const query = `
            query searchVehicleStatuses ($openSearch: String) {
              searchVehicleStatuses (openSearch: $openSearch) {
                  id
                  name
                  icon
                  iconId
                  className
              }
          }
        `;

    const response = yield call(
      clientQuery,
      query,
      {
        openSearch
      },
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.searchVehicleStatuses) {
      throw "Error while getting Vehicle Status Labels list";
    }
    let vehicleStatus = response.searchVehicleStatuses;
    yield put(searchSettingsVehicleStatusResponse({ vehicleStatusItems: vehicleStatus, error: false }));
  } catch (exc) {
    yield put(searchSettingsVehicleStatusResponse({ vehicleStatusItems: [], error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/vehicleStatus/redux/sagas",
      "searchVehicleStatusRequest"
    );
  }
}

function* createEditVehicleStatusRequest({ payload }) {
  try {
    const { vehicleStatus } = payload;

    let query = `
        mutation createVehicleStatus ($name: String!, $iconId: Int!) {
          createVehicleStatus (name: $name, iconId: $iconId) {
              id
              name
          }
        }
      `;

    if (vehicleStatus?.idVehicleStatus) {
      query = `
            mutation updateVehicleStatus ($id: Int!, $name: String, $iconId: Int) {
              updateVehicleStatus (id: $id, name: $name, iconId: $iconId) {
                  id
                  name
              }
            }
        `;
    }

    let data = {
      id: vehicleStatus?.idVehicleStatus,
      name: vehicleStatus?.statusLabel,
      iconId: vehicleStatus?.vehicleStatusIcon?.id
    }

    const response = yield call(
      clientMutation,
      query,
      data,
      endpoints.GRAPHQL_GENERAL,
    );

    if (!response && !vehicleStatus?.idVehicleStatus || !response.createVehicleStatus && !vehicleStatus?.idVehicleStatus) throw 'Error while creating Vehicle Status Label';

    if (!response && vehicleStatus?.idVehicleStatus || !response.updateVehicleStatus && vehicleStatus?.idVehicleStatus) throw 'Error while update Vehicle Status Label';

    let responseData = (!vehicleStatus?.idVehicleStatus) ? response.createVehicleStatus : response.updateVehicleStatus;
    yield put(createEditSettingsVehicleStatusResponse({ ...responseData, error: false }));
  } catch (exc) {
    yield put(createEditSettingsVehicleStatusResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/vehicleStatus/redux/saga",
      "createEditVehicleStatusRequest",
      true
    );
  }
}

function* getVehicleStatusIconRequest() {

  try {
    let query = `
          query searchVehicleStatusIcons ($groupName: String) {
            searchVehicleStatusIcons (groupName: $groupName) {
                id
                name
                iconPath
                groupName
            }
        }
    `;
    const response = yield call(
      clientMutation,
      query,
      { "groupName": "" },
      endpoints.GRAPHQL_GENERAL,
    );

    if (!response && !response.searchVehicleStatusIcons) throw "Error while getting searchVehicleStatusIcons";


    yield put(getSettingsVehicleStatusResponse(response.searchVehicleStatusIcons));
  } catch (exc) {
    yield put(getSettingsVehicleStatusResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/vehicleStatus/redux/saga",
      "getVehicleStatusIconRequest"
    );
  }
}

function* deleteVehicleStatusRequest({ payload }) {
  try {
    const { items } = payload;
    let mutations = [];

    items?.forEach((item, index) => {
      mutations.push(`
              DeleteVehicleStatus${index}: deleteVehicleStatus(id: ${item?.id})
          `);
    });

    let mutation = `mutation{${mutations.join("")}}`;

    const response = yield call(
      clientMutation,
      mutation,
      {},
      endpoints.GRAPHQL_GENERAL,
    );

    if (!response || !response.DeleteVehicleStatus0) throw 'Error while deleting Vehicle Status Label';

    yield put(deleteSettingsVehicleStatusResponse({ ...response, error: false }));
  } catch (exc) {
    yield put(deleteSettingsVehicleStatusResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/vehicleStatus/redux/saga",
      "deleteVehicleStatusRequest"
    );
  }
}

function* watchMethods() {
  yield takeEvery(SETTINGS_VEHICLE_STATUS_SEARCH, searchVehicleStatusRequest);
  yield takeEvery(SETTINGS_VEHICLE_STATUS_CREATE_EDIT, createEditVehicleStatusRequest);
  yield takeEvery(SETTINGS_VEHICLE_STATUS_ICON_GET, getVehicleStatusIconRequest);
  yield takeEvery(SETTINGS_VEHICLE_STATUS_DELETE, deleteVehicleStatusRequest);

}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}