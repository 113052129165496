import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { blacListSFChat, ScriptSFChat } from "./utils";
import { useLocation } from 'react-router-dom';
import { useIntl } from "react-intl";
const SalesForceApi = ({ user }) => {
  const subUser = useSelector(state => state.subUserRedux.subUserSelected);
  const location = useLocation();
  const { locale } = useIntl()

  useEffect(() => {

    let salesForceScript = document.getElementById("salesForceScript")
    if (salesForceScript) {
      salesForceScript.parentNode.removeChild(salesForceScript)
    }
    
    if (!/^\/(login|reseller|forgotPassword|forceChangePassword|migrateUser)/.test(location?.pathname)) {
      const canChat = blacListSFChat(user)
      if (!canChat) return
    }

    let userInfo = {}
    if (user?.isSubUser) {
      userInfo = {
        ...userInfo,
        username: subUser?.firstName,
        lastname: subUser?.lastName,
        email: subUser?.email,
        subject: `chat cloud ${user?.companyName}`
      };
    } else {
      userInfo = {
        ...userInfo,
        username: user?.username,
        lastname: user?.username,
        email: user?.email,
        subject: user ? `chat cloud ${user?.companyName}` : ''
      };
    }
    ScriptSFChat(userInfo);

  }, [user]);

  return <></>;
};

export default SalesForceApi;
