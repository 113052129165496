// Action types.
import { 
  MODULE_SEARCH,
  MODULE_SEARCH_RESPONSE, 
  MODULE_GET,
  MODULE_GET_RESPONSE
} from "Redux/actionTypes";

export const searchModules = (data) => {
  return {
    type: MODULE_SEARCH,
    payload: data,
  };
};

export const searchModulesResponse = (data) => {
  return {
    type: MODULE_SEARCH_RESPONSE,
    payload: data,
  };
};

export const getModule = (data) => {
  return {
    type: MODULE_GET,
    payload: data,
  };
};

export const getModuleResponse = (data) => {
  return {
    type: MODULE_GET_RESPONSE,
    payload: data,
  };
};
