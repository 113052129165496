import {
  MAP_LANDMARKS_VISIBLE_LANDMARK_TAGS_GET_LANDMARKS,
  MAP_LANDMARKS_VISIBLE_LANDMARK_TAGS_GET_LANDMARKS_RESPONSE,
  MAP_LANDMARK_UPDATE_PREFERENCE
} from 'Redux/actionTypes';

export const mapLandmarksVisibleGetLandmarkTags = () => ({
  type: MAP_LANDMARKS_VISIBLE_LANDMARK_TAGS_GET_LANDMARKS,
});

export const mapLandmarksVisibleGetLandmarkTagsResponse = (response) => ({
  type: MAP_LANDMARKS_VISIBLE_LANDMARK_TAGS_GET_LANDMARKS_RESPONSE,
  payload: { response },
});

export const mapLandmarkUpdatePreference = () => ({
  type: MAP_LANDMARK_UPDATE_PREFERENCE,
});