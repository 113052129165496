import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    loading: false,
    open: false,
    data: null,
    route : null,
    loadingSavedRoute: false,
    detail: null,
    error: null,
    
};

const route = "modules/realtimeMaps/routes/components/uploadRoute";

export const realtimeMapsRoutesCreateUpdateRoute = createAsyncThunk('routes/components/realtimeMapsRoutesCreateUpdateRoute', async (payload, { rejectWithValue, dispatch }) => {

    try {
        let body = {...payload};
        //Remove the # character - database have only 6 chars
        if(body?.color){
            body.color = body.color.replace('#', '');
        }
    
        let query = `
            mutation createRoute (
            $title: String!, 
            $color: String, 
            $optimized: Boolean, 
            $avoidToll: Boolean,
            $points: [RoutePointInput],
            $driverId: Int,
            $isTemplate: Boolean,
            $scheduledAt: AWSDateTime,
            $templateRouteId: Int,
            $distanceMeters: Float
            ) {
                Route: createRoute(title: $title, color: $color, optimized: $optimized, avoidToll: $avoidToll, points: $points, driverId: $driverId, isTemplate: $isTemplate, scheduledAt: $scheduledAt, templateRouteId: $templateRouteId, distanceMeters: $distanceMeters) {
                    id
                    title
                }
            } 
        `;
    
        if (body?.id) {
            query = `
            mutation updateRoute (
                $id: Int!,
                $title: String!, 
                $color: String, 
                $optimized: Boolean!, 
                $avoidToll: Boolean!,
                $status: enumRouteStatus,
                $driverId: Int,
                $isTemplate: Boolean,
                $scheduledAt: AWSDateTime
            ) {
                Route: updateRoute(id: $id, title: $title, color: $color, optimized: $optimized, avoidToll: $avoidToll, status: $status, driverId: $driverId, isTemplate: $isTemplate, scheduledAt: $scheduledAt) {
                    id
                    title
                    }
                }
            } 
            `;
        }

        const data = await clientMutation(
            query,
            body,
            endpoints.GRAPHQL_GENERAL_V2
        );

    
        if (data && data.Route) {
            //add the char # to colors
            let dataRoute = {
                ...data.Route, 
                color : data.Route.color && data.Route.color.includes('#') ? data.Route.color : `#${data.Route.color}` 
            }
            return dataRoute;
        } else {
            throw 'Error while creating route';
        }
    } catch (exc) {
        ExceptionManager(exc, route, 'realtimeMapsRoutesCreateUpdateRoute');
        return rejectWithValue(exc)
    }
});


export const RealtimeMapsRoutesModalUploadRoute = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalRealtimeMapsUploadRoute: (state, action) => {
            return {
                ...state,
                open: action?.payload?.open || false
            }
        }
    },
    extraReducers: (builder) => {

        builder.addCase(realtimeMapsRoutesCreateUpdateRoute.pending, (state, action) => {
            state.loading = true;
            state.loadingSavedRoute = false;
            state.error = null;
        });
        builder.addCase(realtimeMapsRoutesCreateUpdateRoute.fulfilled, (state, action) => {
            state.route = action.payload;
            state.loading = false;
            state.loadingSavedRoute = true;

        } );
        builder.addCase(realtimeMapsRoutesCreateUpdateRoute.rejected, (state, action) => {  
            state.detail = [];
            if(action.payload.error == "Route duplicated title" || action.payload.error == "Routes duplicated title"){
                state.error = "fsmRoute_modal_editCreate_error_name";
            }else{
                state.error = action.payload.error;
            }

            state.loading = false;
        });

    }
});


const { actions, reducer } = RealtimeMapsRoutesModalUploadRoute;

export const { reset, openModalRealtimeMapsUploadRoute } = actions;

export default reducer;