//View Keys
export default {
  realtimemaps_geofence_create_select: 'Tipo de Geocerca',
  realtimemaps_geofence_polygon: 'Polígono',
  realtimemaps_geofence_polygon_max: 'Polígono +',
  realtimemaps_geofence_polygon_circle: 'Circulo',
  realtimemaps_geofence_polygon_rectangle: 'Cuadrado',
  realtimemaps_geofence_contacts: 'Contactos',
  realtimemaps_geofence_group: 'Grupo de Geocercas',
  realtimemaps_geofence_inout: 'Dentro/Fuera',
  realtimemaps_geofence_in: 'Dentro',
  realtimemaps_geofence_out: 'Fuera',
  realtimemaps_geofence_alerton: 'Alerta de Ingreso',
  realtimemaps_geofence_info_polygon: 'Comience a hacer clic en los puntos del mapa para crear una Geocerca. Deben ser 3–{maxPoints} puntos.',
  realtimemaps_geofence_info_circle: 'La Geocerca se centrará en la dirección proporcionada con el radio especificado. Utilice el área de localización de la dirección anterior para cambiar la ubicación.',
  realtimemaps_geofence_create: 'Creación de Geocerca',
  realtimemaps_latLngError: 'Por favor, seleccione un punto en el mapa'
};