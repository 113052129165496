import {
  FIND_NEAREST_SEARCH,
  FIND_NEAREST_SEARCH_RESPONSE,
  FIND_NEAREST_OPEN_DIRECTIONS_MODAL
} from 'Redux/actionTypes';

export const searchNearest = (data, limit, offset) => ({
  type: FIND_NEAREST_SEARCH,
  payload: { data, limit, offset },
});

export const searchNearestResponse = (data) => ({
  type: FIND_NEAREST_SEARCH_RESPONSE,
  payload: { data },
});

export const openNearestDirectionsModal = (open, data) => ({
  type: FIND_NEAREST_OPEN_DIRECTIONS_MODAL,
  payload: { open, data },
});