//View Keys
export default {
	"vehicleDetailView_makeModel": "Marca/Modelo: ",
    "vehicleDetailView_year": "Año: ",
    "vehicleDetailView_odometer": "Odometro: ",
    "vehicleDetailView_engineHours": "Horas de Motor: ",
    "vehicleDetailView_vin": "VIN: ",
    "vehicleDetailView_assignedDriver": "Asignado: ",
    "vehicleDetailView_groups": "Grupos: ",
    "vehicleDetailView_tags": "Etiquetas: ",
    "vehicleDetailView_service": "Servicio",
    "vehicleDetailView_miles": "Millas",
    "vehicleDetailView_days": "Días",
    "vehicleDetailView_engHrs": "hrs mtr",
    "vehicleDetailView_hours": "Horas",
    "vehicleDetailView_months": "Meses",
    "vehicleDetailView_minutes": "Minutos",
    "vehicleDetailView_cost": "Costo",
    "vehicleDetailView_currentCost": "Costo Total",
    "vehicleDetailView_status": "Estado",
    "vehicleDetailView_completedStatus": "Completado",
    "vehicleDetailView_exportGenerated" : "Se está generando la exportación....",
    "vehicleDetailView_exportTask" : "Exportar Tarea",
    "vehicleDetailView_dueStatus": "Vencido",
    "vehicleDetailView_dueSoonStatus": "Prox a vencer",
    "vehicleDetailView_scheduledStatus": "Programado",
    "vehicleDetailView_subscribers": "Suscriptores",
    "vehicleDetailView_notes": "Notas",
    "vehicleDetailView_attachments": "Adjuntos ",
    "vehicleDetailView_reminder": "Recordatorio",
    "vehicleDetailView_dueNow": "Vencido",
    "vehicleDetailView_pastDue": "Atrasado",
    "vehicleDetailView_completedDate": "Completado",
    "vehicleDetailView_scheduledList": "Servicios Programados",
    "vehicleDetailView_performedList": "Servicios Completados",
    "vehicleDetailView_reminders": "Recordatorios",
    "vehicleDetailView_serviceItems" : "Elementos de Servicio",
    "vehicleDetailView_laborCost" : "Labor",
    "vehicleDetailView_partsCost" : "Partes",
    "vehicleDetailView_totalCost" : "Total",
    "vehicleDetailView_warningNotInfoOdometer" : "No es posible obtener información sobre el odometro en el vehiculo",
    "vehicleDetailView_warningNotInfoEngineHour" : "No es posible obtener información de las horas del motor en el vehiculo",
    "vehicleDetailView_warningNotNextDate" : "Es necesario agregar la próxima fecha programada ",
    //scheduleMaintenanceService
    "scheduleVehicleService_vehicleTitle": "Programar Servicio para",
    "scheduleVehicleService_editVehicleTitle": "Editar Programación de servicio para",
    "scheduleVehicleService_serviceTitle": "Adiciona un Vehiculo para",
    "scheduleVehicleService_editServiceTitle": "Editar Programación de vehiculo para",
    "scheduleVehicleService_schedule": "Programar",
    "scheduleVehicleService_update": "Actualizar",
    "scheduleVehicleService_serviceInputLabel": "Servicio",
    "scheduleVehicleService_serviceInputPlaceholder": "Selecciona",
    "scheduleVehicleService_dateInputLabel": "Fecha de Ultimo Servicio",
    "scheduleVehicleService_CompletedDateInputLabel": "Fecha en que se completo el servicio",
    "scheduleVehicleService_timeInputPlaceholder": "Hora",
    "scheduleVehicleService_timeInputLabel": "*",
    "scheduleVehicleService_dateInputPlaceholder": "Seleciona Fecha",
    "scheduleVehicleService_notesInputLabel": "Notas",
    "scheduleVehicleService_notesInputServiceLabel": "Notas de Servicio",
    "scheduleVehicleService_notesInputPlaceholder": "",
    "scheduleVehicleService_recurringInputLabel": "Guardar como Servicio Recurrente",
    "scheduleVehicleService_intervalTypeInputLabel": "Tipos de Intervalo",
    "scheduleVehicleService_intervalTypeInputPlaceholder": "Selecciona",
    "scheduleVehicleService_intervalInputLabel": "Intervalo",
    "scheduleVehicleService_intervalInputPlaceholder": "Intervalo",
    "scheduleVehicleService_nextServiceInputLabel": "Próximo Servicio",
    "scheduleVehicleService_nextServiceInputPlaceholder": "Seleccionar Fecha",
    "scheduleVehicleService_reminderInputLabel": "Reminder",
    "scheduleVehicleService_reminderInputPlaceholder": "Reminder",
    "scheduleVehicleService_pastDueInputLabel": "Atrazado",
    "scheduleVehicleService_pastDueInputPlaceholder": "Atrazado",
    "scheduleVehicleService_addNewIntervalType": "Agrega un Nuevo Tipo de Intervalo",
    "scheduleVehicleService_alertInputLabel": "Alerta",
    "scheduleVehicleService_alertInputPlaceholder": "Selecciona Alerta",
    "scheduleVehicleService_alertTypeInputLabel": "",
    "scheduleVehicleService_alertTypeInputPlaceholder": "Tipo de Alerta",
    "scheduleVehicleService_addNewAlertType": "Agrega un Nuevo Tipo de Alerta",
    "scheduleVehicleService_attachmentsInputLabel": "Adjuntos",
    "scheduleVehicleService_attachmentsInputPlaceholder": "Seleccionar Archivo(s)",
    "scheduleVehicleService_success": "Servicio programado",
    "scheduleVehicleService_vehicleInputLabel": "Vehiculo",
    "scheduleVehicleService_vehicleInputPlaceholder": "Selecciona",
    "scheduleVehicleService_alertsInputLabel": "Suscriptores",
    "scheduleVehicleService_addNewService": "Agrega un Nuevo Servicio",
    "scheduleVehicleService_alert_placeholder": "Selecciona un Suscriptor",
    "scheduleVehicleService_alert_error_message": "Debes Seleccionar Al menos uno",
    "scheduleVehicleService_alert_button_label": "Adicionar suscriptores",
    "scheduleVehicleService_delete_button_bar": "Eliminar",
    "scheduleMaintenanceService_interval_types": "Tipos de Intervalo",
    "scheduleMaintenanceService_service_settings": "Configuración del servicio ",
    "scheduleMaintenanceService_notSupportEngine": "El vehículo actual no admite horas de motor",
    "scheduleMaintenanceService_notSupportOdometer": "El vehículo actual no admite cuentakilómetros",
    "scheduleMaintenanceService_notSupportOdometerandEngineHours": "El vehículo actual no admite cuentakilómetros ni horas de motor",
	"unit_vehicle_Status_icon": "Icono de Vehiculo",
    "unit_label":"Etiqueta de la unidad",
	"unit_odometer":"Odometro",
	"unit_odometer_dash_reading_label":"Lectura de Tablero",
	"unit_odometer_dash_reading_placeholder":"lectura del tablero",
	"unit_odometer_offset_label":"Diferencia",
    "unit_odometer_offset_placeholder":"273.4",
    "unit_engine_hours":"Horas de Motor",
    "unit_engine_hours_placeholder":"horas de motor",
    "unit_engine_hours_dash_reading_label":"Lectura Rapida",
    "unit_engine_hours_dash_reading_placeholder":"lectura rapida",
    "unit_engine_hours_offset_label":"Diferencia",
	"unit_engine_hours_offset_placeholder":"horas de motor",	
	"unit_driver":"Conductor",
	"unit_select_placeholder":"Selecciona",	
	"unit_vin":"VIN",	
    "unit_group":"Grupo",
	"unit_general":"General",
	"unit_description":"Descripcion",
	"unit_input_labels":"Etiquetas de Entradas",
	"unit_input_label":"Etiqueta de Entrada",
	"unit_output_labels":"Etiquetas de Salidas",
	"unit_output_label":"Etiqueta de Salida",
	"unit_notes":"Notas",
	"unit_location":"Ubicación",
	"unit_time_zone":"Zona Horaria",	
	"unit_make":"Marca",
    "unit_make_new":"Adicionar Nueva Marca",
    "unit_model":"Modelo",
	"unit_model_new":"Adicionar Nuevo Modelo",	
	"unit_data":"Opciones",
    "unit_disable_all":"Deshabilitar Todo",
    "unit_enable_all":"Habilitar Todo",
	"unit_calculated_by_ecu":"Calculado por ECU",
	"unit_calculated_by_gps":"Calculado por GPS",	
	"unit_actual":"Actual",
    "unit_speed":"Velocidad",
    "unit_speed_placeholder":"Velocidad",
    "unit_on_label":"Etiqueta Encendida",
    "unit_off_label":"Etiqueta Apagada",
    "unit_on_label_placeholder":"Encendido",
    "unit_off_label_placeholder":"Apagado",
    "unit_mpg":"MPG",
	"driver_detail_schedule_tasks":"Tareas programadas",
    "driver_detail_schedule_tasks_logs":"Logs de tareas programadas",
	"unit_consistent_speed":"Limite de Velocidad",
    "scheduleVehicleService_attachmentsServiceLabel": "Adjuntos de Servicios",
    "scheduleVehicleService_attachmentsServicePlaceholder": "Verifica los Archivos",
    "unit_validation_alert_message":" Hay errores de validación que debes verificar antes de continuar ",
    "unit_validation_alert_title":" Error de validación ",
    "unit_toggle":"Toggle",
    "unit_pulse":"Pulse",
    "unit_toggle_options":"Interruptor Encendido/Apagado",
    "unit_pulse_options":"Pulso de Encendido/Apagado",
    "performed_Services_Date":"Fecha",
    "performed_Services_Time":"Hora",
    "performed_Services_Totals":"Totales",
    "performed_Services_Totals_Cost":"Costo Total",
    "unit_pulse_single_option":"Pulso Unico",
};
