// Action Types
import { 
  ROLE_SEARCH,
  ROLE_SEARCH_RESPONSE,
  ROLE_GET,
  ROLE_GET_RESPONSE,
  ROLE_CREATE,
  ROLE_CREATE_RESPONSE, 
  ROLE_UPDATE,
  ROLE_UPDATE_RESPONSE, 
  ROLE_DELETE,
  ROLE_DELETE_RESPONSE,
  CLEAR_REDUX
} from "Redux/actionTypes";

/**
 * Initial State ROLE
 */
const INITIAL_STATE = {
  loading: false,
  loadingGetRole: false,
  allfilters: {
    openSearch: "",
    limit: 10,
		offset: 0
  },
  registers: [],
  roleSelected: null,
  loadedRecord: false,
  deleteRoles: false,
  total: 0,
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ROLE_SEARCH:
      return {
        ...state,
        roleSelected: null,
        error: null,
        loading: true
      };

    case ROLE_SEARCH_RESPONSE:
      return {
        ...state,
        registers: action.payload?.items,
        total: action.payload?.total,
        error: action.payload?.error,
        loading: false
      };
    case ROLE_GET:
      return {
        ...state,
        roleSelected: null,
        error: null,
        loadingGetRole: true
      };
    case ROLE_GET_RESPONSE:
      return {
        ...state,
        roleSelected: action.payload?.role,
        loadingGetRole: false,
        error: action.payload?.error
      };
    case ROLE_CREATE:
      return {
        ...state,
        loadedRecord: false,
        error: null,
        loading: true,
      }; 
    case ROLE_CREATE_RESPONSE:
      return {
        ...state,
        loadedRecord: action.payload?.newRole,
        error: action.payload?.error,
        loading: false
      };
    case ROLE_UPDATE:
      return {
        ...state,
        loadedRecord: false,
        error: null,
        loading: true,
      };
    case ROLE_UPDATE_RESPONSE:
      return {
        ...state,
        loadedRecord: action.payload?.updateRole,
        error: action.payload?.error,
        loading: false
      };
    case ROLE_DELETE:
      return {
        ...state,
        deleteRoles: false,
        loading: true,
        error: null,
      };
    case ROLE_DELETE_RESPONSE:
      return {
        ...state,
        deleteRoles: action.payload?.deleteRole,
        error: action.payload?.error,
        loading: false
      };  
    case CLEAR_REDUX:
      return ["", "ROLE"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INITIAL_STATE }
        : { ...state };  
    default:
      return state;
  }
};
