import templates from "../../views/templates/lan/en";
import routesTracker from "../../views/routesTracker/lan/en";


const locales = {
    ...templates,
    ...routesTracker,
    
    "module_routes_routes_tracker":"Routes Tracker",
    "module_routes_routes_template":"Templates",
    "module_routes_select_date":"Select a date",
};
  
export default locales;