//View Keys
export default {
    "mainView_vehicles": "Vehículos",
    "mainView_services": "Servicios",
    "mainView_drivers": "Conductores",
    "mainView_export_button": "Exportar",
    "unit_not_exist": "Esta unidad no puede ser editada",
    "unit_edit_title": "Editar",
    "unitService_date_validation_message": "Por favor ingrese un año valido de 4 dígitos,entre 1950 a",
    "unitService_engine_liters_validation_message": "Puedes tener maximo 10000 litros de motor",
    "vehicle_tab_export": "Vehiculo",

    "next_service_due_tab_export": "Próximo servicio",
    "days_tab_export": "Dias",
    "engine_tab_export": "Tiempo de motor",
    "odometer_tab_export": "Odometro",
    "service_due_tab_export": "Fecha de servicio",

    "schedule_task_regarding_title": "Responsable",
    "schedule_task_task_title": "Tarea",
    "schedule_task_due_title": "Vencido",
    "schedule_task_cost_title": "Costo",
    "schedule_task_status_title": "Estado",
    "schedule_task_notes_title": "Notas",
    "schedule_task_performed_at_title": "Realizado a",
    "schedule_task_performed_attachments_title": "Adjuntos",
    "schedule_task_subscribers_title": "Suscriptores",

    "scheduleDriverTask_service": "Servicio",
    "scheduleDriverTask_miles": "Millas",
    "scheduleDriverTask_days": "Días",
    "scheduleDriverTask_hours": "Horas",
    "scheduleDriverTask_cost": "Costo",
    "scheduleDriverTask_reminders": "Recordarios",
    "scheduleDriverTask_notes": "Notas",
    "scheduleDriverTask_errorServiceCompleted": "no puede borrarse por que ya fue completado",

    "deleteSubscriber_success": "Suscriptor Eliminado",


    "deleteAttachment_success": "Adjunto removido",

    "maintenance_lastServicePerformed": "Último servicio realizado",
    "maintenance_nextService": "Próximo Servicio",

    "scheduleDriverTask_manage_service_items": "Administrar servicios",

    "unitService_lastUpdated": "Ultima Actualización",

    "service_title_add_button": "Adicionar",

    "vehicleView_tags": "Etiquetas"
};