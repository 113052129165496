export default {
    "accountTagsCreateEditTag_success": "Tag saved",
    "accountTagsCreateEditTag_editTitle": "Edit Tag",
    "accountTagsCreateEditTag_createTitle": "Create a New Tag",
    "accountTagsCreateEditTag_color": "Choose Label and Color",
    "accountTagsCreateEditTag_label": "Label",
    "accountTagsCreateEditTag_nestUnder": "Nest Under",
    "accountTagsCreateEditTag_typeToSearch": "Type to search...",

    "accountTagsCreateEditTag_tag": "Tag",
    "accountTagsCreateEditTag_vehicles": "Vehicles",
    "accountTagsCreateEditTag_drivers": "Drivers",
    "accountTagsCreateEditTag_landmarks": "Landmarks",
    "accountTagsCreateEditTag_users": "Users",
    "accountTagsCreateEditTag_forms": "Forms",
    "accountTagsCreateEditTag_dynamicForms": "Dynamic Forms",
    "accountTagsCreateEditTag_geofences": "Geofences",
    "accountTagsCreateEditTag_errorParentTag": 'You can not add the same tag as a parent',
    "accountTagsCreateEditTag_unit": "Units"
};