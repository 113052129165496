import {
  VIDEO_ALERT_GET_EVENT_MEDIA,
  VIDEO_ALERT_GET_EVENT_MEDIA_RESPONSE
} from 'Redux/actionTypes';

export const getEventMedia = (data) => ({
  type: VIDEO_ALERT_GET_EVENT_MEDIA,
  payload: { data },
});

export const getEventMediaResponse = (data) => ({
  type: VIDEO_ALERT_GET_EVENT_MEDIA_RESPONSE,
  payload: { data },
});