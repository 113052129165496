import {
    ENTITY_TAG_PICKER_SEARCH_ENTITY_TAGS,
    ENTITY_TAG_PICKER_SEARCH_ENTITY_TAGS_RESPONSE
} from 'Redux/actionTypes';

export const entityTagPickerSearchEnityTags = (filters) => ({
    type: ENTITY_TAG_PICKER_SEARCH_ENTITY_TAGS,
    payload: { filters },
});

export const entityTagPickerSearchEnityTagsResponse = (response) => ({
    type: ENTITY_TAG_PICKER_SEARCH_ENTITY_TAGS_RESPONSE,
    payload: { response },
});