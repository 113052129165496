//imports
import React, { useState, useRef, useEffect, Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ToggleLanguage from "Components/ToggleLanguage";

//controls
import { Form, Input, Loading, Button } from "Components";
import Modal, { ModalBody, ModalFooter } from "Components/Modal";
import { Box, Container, Grid } from "@mui/material";
import { ErrorHandlerLogin } from "Core/data/Helpers";
import SalesForceApi from 'Core/salesForceApi';
//components
//import {TermsOfService} from './../login'
//sass
import "Core/views/login/login.scss";

//actions
import {
  migrateUserConfirmClose,
  migrateUserConfirm,
  clearRedux,
} from "Redux/actions";

//components
import LoginLogo from "../common/LoginLogo";
import FooterResellers from "../common/FooterResellers";
import AgreementMessage from "../common/AgreementMessage";
import FormMigrate from "./FormMigrate";

const migrateUserView = (props) => {
  const { messages } = useIntl();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  let { username, emailFromUrl } = useParams();
  if (!username || username == "") navigate("/error");

  const {
    loading,
    error,
    showConfirm,
    cognitoValues,
    result,
    hasPermissionToMigrateUser,
  } = useSelector((state) => state.migrateUserRedux);
  const { loginPage } = useSelector((state) => state.loginRedux);
  const { current_reseller, reseller_was_validated } = useSelector(
    (state) => state.resellerRedux
  );
  const frmForgotConfirm = useRef();
  const [confirmationCode, setConfirmationCode] = useState("");

  const urlImgLogo = current_reseller
    ? current_reseller?.loginCompanyLogo
    : "/assets/images/verticalLogo.png";

  useEffect(() => {
    //didmount
    return () => {
      dispatch(clearRedux("MIGRATE_USER"));
    };
  }, []);

  const handleClose = () => {
    dispatch(migrateUserConfirmClose());
  };

  useEffect(() => {
    // Check permission to login to this URL
    if (!hasPermissionToMigrateUser) {
      navigate("/");
    }
  }, []);
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className="all-space-container"
    >
      <Grid
        item
        xs={12}
        lg={5}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "end",
            margin: "20px 25px 0 0px",
          }}
        >
          <ToggleLanguage className="btn-white" />
        </Box>

        <div className="background-white-login" style={{ paddingTop: 10, height: "100%", width: "500px", display: "flex", alignItems: "center"}}>
          <Container className="form-login-container">
            {reseller_was_validated && <LoginLogo urlImgLogo={urlImgLogo} />}
            {error && (
              <div className="form-item card-error-login">
                <p className="txt-card-error-login">
                 {messages[ErrorHandlerLogin(error)] ||
                    ErrorHandlerLogin(error)}
                </p>
              </div>
            )}

            <p>{messages["migrateUser_modalSubtitle"]}</p>
            <p className="blue-subtitle-migration">
              <b>{messages["migrateUser_modalSubtitle2"]}</b>
            </p>
            <FormMigrate
              setConfirmationCode={setConfirmationCode}
              current_reseller={current_reseller}
            />
          </Container>
        </div>
        {!current_reseller && <AgreementMessage />}

        <Modal
          title={messages["migrateUser_emailVerification"]}
          open={showConfirm || false}
          handleClose={handleClose}
          size="small"
        >
          <ModalBody>
            <div style={{ padding: 20 }}>
              {error && (
                <div className="form-item card-error-login login-code-error">
                  <p className="txt-card-error-login">
                    {messages[ErrorHandlerLogin(error)] ||
                      ErrorHandlerLogin(error)}
                  </p>
                </div>
              )}

              <div>
                <br />
                {messages["migrateUser_verifyTex"]}
              </div>
              {result ? (
                messages["migrateUser_success"]
              ) : (
                <Form
                  ref={frmForgotConfirm}
                  onValidSubmit={() => {
                    dispatch(
                      migrateUserConfirm(
                        username,
                        confirmationCode,
                        cognitoValues,
                        navigate
                      )
                    );
                  }}
                >
                  <div className="form-item">
                    <Input
                      id="txtVerificationCode"
                      type="text"
                      label={messages["migrateUser_verifyCode"]}
                      placeholder={messages["migrateUser_verifyCode"]}
                      value={confirmationCode}
                      onChange={(text) => {
                        setConfirmationCode(text);
                      }}
                      required
                      requiredMessage={messages["required_field"]}
                      autoFocus
                    />
                  </div>
                </Form>
              )}
            </div>
          </ModalBody>

          <ModalFooter>
            {loading ? (
              <div className="loading-area">
                <Loading />
              </div>
            ) : (
              <Fragment>
                {result ? (
                  <Button
                    id="btnContinueConfirm"
                    type="link"
                    url="/login"
                    className="btn btn-blue"
                  >
                    <FormattedMessage id="continue" />
                  </Button>
                ) : (
                  <Fragment>
                    <Button
                      id="btnCloseConfirm"
                      onClick={handleClose}
                      className="btn-cancel"
                    >
                      {messages["close"]}
                    </Button>
                    {"  "}
                    <Button
                      id="btnConfirm"
                      onClick={(e) => {
                        e.preventDefault();
                        frmForgotConfirm.current.submit();
                      }}
                      className="btn-blue"
                    >
                      {messages["migrateUser_verify"]}
                    </Button>
                  </Fragment>
                )}
              </Fragment>
            )}
          </ModalFooter>
        </Modal>
        <Box style={{ margin: "25px", width: "100%", textAlign: "center" }}>
          {current_reseller && (
            <FooterResellers current_reseller={current_reseller} />
          )}
        </Box>
      </Grid>
      <Grid item xs={12} lg className="all-space-container">
        {loginPage ? (
          <div
            className="all-space-container overflow-auto"
            dangerouslySetInnerHTML={{ __html: loginPage }}
          />
        ) : (
          <div
            className="login_bg"
            style={{
              backgroundImage: reseller_was_validated
              ? (!current_reseller?.loginBackgroundImage && current_reseller?.loginBackgroundColor) ? `none`
                  : `url(${
                    current_reseller?.loginBackgroundImage ||
                    "/assets/images/loginbg-dark.png"
                  })`
              : "",
              backgroundColor: (!reseller_was_validated || current_reseller?.loginBackgroundImage) ? "transparent"
                                  : (current_reseller?.loginBackgroundColor || "transparent")
            }}
          />
        )}
      </Grid>
      {
        !current_reseller && reseller_was_validated &&
        <SalesForceApi />
      }
    </Grid>
  );
};

export default migrateUserView;
