import {
  //TYPES
  MAP_UTILITIES_SEND_MESSAGE,
  MAP_UTILITIES_SEND_MESSAGE_RESPONSE,
  CLEAR_REDUX,
} from "Redux/actionTypes";

const INIT_STATE = {
  sendMessage: null,
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MAP_UTILITIES_SEND_MESSAGE:
      return { ...state, loading: true };

    case MAP_UTILITIES_SEND_MESSAGE_RESPONSE:
      return { ...state, sendMessage: action.payload.data, loading: false };

    case CLEAR_REDUX:
      return ["", " MAP_SEND_MESSAGE"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }
    default:
      return state;
  }
};