import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../../../core/logManager';

import { resetALL } from '../../../../../../../.globals/redux/actions';

import moment from "moment";

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    items: [],
    nextToken: null,
    error: null,
    openAdvancedFilters: false,
    filters: {
        filters: [],
        openSearch: "",
        dateRange: {
            start: moment().subtract(7, 'days').startOf('day').toDate(),
            end: moment.max(moment()).endOf('day').toDate()
        },
        limit: 25,
        timeFrame: "last7days"
    },
    dynamicForms: [],
    loadingDynamicForms: false
};

const route = 'modules/dynamicForms/views/main/components/answers/redux/slice'

export const searchDynamicFormAnswers = createAsyncThunk('dynamicForms/main/components/answers/searchDynamicFormAnswers', async ({ limit, nextToken, filters, openSearch, dateRange }, { rejectWithValue, dispatch }) => {
    try {
        let newFilters = [ ...filters ];
        console.log("filters", filters);
        if (openSearch) {
            //newFilters.push({ filterBy: "formName", filterValue: openSearch });
            newFilters.push({ filterBy: "answerName", filterValue: openSearch });
        }

        const response = await clientQuery(
            `
            query searchDynamicFormAnswers(
                $filters: [DynamicFormAnswersSearchInput],
                $dateRange: AWSDateTimeRange!, 
                $limit: Int!, 
                $nextToken: String
            ) {
                answers: searchDynamicFormAnswers(
                    filters: $filters, 
                    dateRange: $dateRange, 
                    limit: $limit, 
                    nextToken: $nextToken
                ) {
                    items {
                        id
                        formId
                        answerName
                        dynamicForm {
                            id
                            name
                        }
                        isDraft
                        coordinates {
                            latitude
                            longitude
                            accuracy
                            provider
                            address
                            city
                            state
                        }
                        updatedOn
                        updatedBy
                    }
                    nextToken
                }
            }
            `,
            {
                filters: newFilters || [],
                dateRange,
                limit, 
                nextToken
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response?.answers) {
            throw 'Error while getting dynamic forms answers';
        }

        const { items, nextToken: newNexToken } = response?.answers;
        return { items: items, nextToken: newNexToken };
    } catch (exc) {
        ExceptionManager(exc, route, 'searchDynamicFormAnswers');
        return rejectWithValue(exc)
    }
});

export const getDynamicForms = createAsyncThunk('dynamicForms/main/components/answers/getDynamicForms', async ({}, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query searchDynamicForms ($conditions: [DynamicFormSearchInput], $sort: [DynamicFormSortInput], $limit: Int, $offset: Int) {
                dynamicForms: searchDynamicForms (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                    items {
                        id
                        name
                    }
                    total
                }
            }
            `,
            {
                conditions: [],
                sort: [{
                    field: "name",
                    order: "ASC"
                }],
                limit: 0, 
                offset: 0
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response?.dynamicForms) {
            throw 'Error while getting dynamic forms';
        }

        const { items } = response?.dynamicForms;
        return items;
    } catch (exc) {
        ExceptionManager(exc, route, 'getDynamicForms');
        return rejectWithValue(exc)
    }
});

//Slice
const dynamicFormAnswersRedux = createSlice({
    name: 'dynamicFormAnswers/list',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openAdvancedFilters(state) {
            state.openAdvancedFilters = true;
        },
        closeAdvancedFilters(state) {
            state.openAdvancedFilters = false;
        },
        updateDynamicFormAnswersFilters(state, action) {
            state.filters = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        builder.addCase(searchDynamicFormAnswers.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(searchDynamicFormAnswers.fulfilled, (state, action) => {
            state.loading = false;
            state.items = action?.meta?.arg?.resetSearch ? action.payload?.items : [ ...state.items, ...action.payload.items ];
            state.nextToken = action.payload.nextToken;
            state.filters = action?.meta?.arg;
        });
        builder.addCase(searchDynamicFormAnswers.rejected, (state, action) => {
            state.loading = false;
            state.nextToken = null;
            state.error = action.payload;
        });

        builder.addCase(getDynamicForms.pending, (state, action) => {
            state.loadingDynamicForms = true
        });
        builder.addCase(getDynamicForms.fulfilled, (state, action) => {
            state.loadingDynamicForms = false;
            state.dynamicForms = action.payload || [];
        });
        builder.addCase(getDynamicForms.rejected, (state, action) => {
            state.loadingDynamicForms = false;
            state.dynamicForms = [];
            state.error = action.payload;
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = dynamicFormAnswersRedux;

// Extract and export each action creator by name
export const { reset, openAdvancedFilters, closeAdvancedFilters, updateDynamicFormAnswersFilters } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;