export default {
    "settingsDriverGroups_search": "Search by Group Name",
    'settingsDriverGroups_createTitle': "Create new Group",
    'settingsDriverGroups_editTitle': "Edit Group",
    'settingsDriverGroups_groupName':'Group Name',
    'settingsDriverGroups_Drivers':'Drivers',
    'settingsDriverGroups_minLengthMessage':'The field must contain minimum chars: ',
    'settingsDriverGroups_maxLengthMessage':'The field must contain maximum chars: ',
    'settingsDriverGroups_success':'Driver group saved',
    'settingsDriverGroups_deleteTitle':'Delete Driver group',
    'settingsDriverGroups_deleteWarning':'Are you sure you want to delete the driver groups?',
    'settingsDriverGroup_deleted':'Group deleted',
    'settingsDriverGroup_delete_move_member':'Group members need to be moved to a different group',
    'settingsDriverGroup_delete_move_member_placeholder':'Move group'

    
};