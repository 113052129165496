import {
    MAP_GEOFENCES_BULK_OPEN_MODAL,
    MAP_GEOFENCES_BULK_UPDATE,
    MAP_GEOFENCES_BULK_UPDATE_RESPONSE,
} from 'Redux/actionTypes';

export const mapGeofenceBulkOpenModal = (open, geofences) => ({
    type: MAP_GEOFENCES_BULK_OPEN_MODAL,
    payload: { open, geofences },
});

export const mapGeofenceBulkUpdate = (geofencesBulk, geofenceIcon = null) => ({
    type: MAP_GEOFENCES_BULK_UPDATE,
    payload: { geofencesBulk, geofenceIcon },
});

export const mapGeofenceBulkUpdateResponse = (data) => ({
    type: MAP_GEOFENCES_BULK_UPDATE_RESPONSE,
    payload: { data },
});