
 /********************************************************************************************
  Get address components from LatLng
 ********************************************************************************************/
let geocoder = null;
export const getGeocoder = (params) => {
  try {
    let options = {
        from: 'latlng', //latlng, address, placeId
        lat: null,
        lng: null,
        address: '',
        placeId: '',
        callback: null,
        id: '',
    };

    options = {...options, ...params };

    if (geocoder == null){
      geocoder = {
        instance: null, 
        callback: null
      }
      geocoder.instance = new google.maps.Geocoder(); // eslint-disable-line no-undef
    }
    geocoder.callback = options.callback
    geocoder.id = options.id
        

    let geocoderRequest;
    if (options.from == 'latlng') {
        var location = new google.maps.LatLng(options.lat, options.lng); // eslint-disable-line no-undef
        geocoderRequest = {
            location: location
        };
    }
    else if (options.from == 'address') {
        geocoderRequest = {
            address: options.address
        };
    }
    else if (options.from == 'placeId') {
        geocoderRequest = {
            placeId: options.placeId
        };
    }
    else {
        //mainapp.console('Invalid request to Geocoding');
        return;
    }

    //callback geocode results
    geocoder.instance.geocode(geocoderRequest, geocoding_callback);
  }
  catch (ex) {
      //console.log("getGeocoder() => ", ex);
  }
}

/********************************************************************************************
Procesa el resultado de un request de Geocodificacion
********************************************************************************************/
const geocoding_callback = (arrGeocoderResult, status) => {
  try {
    if (status != google.maps.GeocoderStatus.OK) { // eslint-disable-line no-undef
      if (geocoder.callback != null){
        geocoder.callback({ result: false, msg: 'Address / Point not found' });
      }
    }
    else {
      if (arrGeocoderResult.length > 0) {                                   
        let results = [];
        for (let n = 0; n < arrGeocoderResult.length; n++) {
          let geocoderResult = arrGeocoderResult[n];

          let lat = geocoderResult.geometry.location.lat();
          let lng = geocoderResult.geometry.location.lng();
          let state = "";
          let country = "";
          let street = "";
          let city = "";
          let postalcode = "";

          //Recorre los objetos de direccion resultantes
          for (let j = 0; j < geocoderResult.address_components.length; j++) {
            let type = geocoderResult.address_components[j].types[0];
            switch (type) {
              case "colloquial_area": 
              case "airport":
              case "premise": 
                  street += geocoderResult.address_components[j].long_name + " ";
                  break;
              case "street_number":
                  street += geocoderResult.address_components[j].long_name + " ";
                  break;
              case "route":
                  street += geocoderResult.address_components[j].long_name + " ";
                  break;
              case "postal_code":
                  postalcode = geocoderResult.address_components[j].long_name;
                  break;
              case "country":
                  country = geocoderResult.address_components[j].short_name;
                  break;
              case "administrative_area_level_1":
                  state = geocoderResult.address_components[j].short_name;
                  break;
              case "locality":
                  city = geocoderResult.address_components[j].long_name;
                  break;
              case "political":
                  if(!city){
                    city = geocoderResult.address_components[j].long_name;
                    break;
                  }
              default:
                  if(!street){
                    street = geocoderResult?.address_components[j]?.long_name + " ";
                  }
                  break;
            }
          }

          let fullAddress = (street.trim() + " " + city + ", " + state + " " + postalcode + " " + country);
          let location = {
              fulladdress: fullAddress,
              address1: street.trim(),
              city: city.trim(),
              state: state.trim(),
              postalcode: postalcode.trim(),
              country: country.trim(),
              lat: lat,
              lng: lng,
              radius: 0, //Por definir en la seleccion,
              index: n
          }
          results.push(location);
        }
        if (geocoder.callback != null){
          geocoder.callback({ result: true, msg: '', data: results, id: geocoder.id });
        }
      }
      else {
        if (geocoder.callback != null){
          geocoder.callback({ result: false, msg: 'Address / Point not found', id: geocoder.id });
        }
      }
    }
  }
  catch (ex) {
      //console.log("geocoding_callback()", ex);
  }
}
