import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getMethodToFormatDuration } from "Modules/reports/views/main/components/reportsTab/helper";
import {
  Button,
  IconButton,
  Loading,
  Tooltip,
} from "@gpstrackit/gtc-react-controls";
import moment from "moment";
import { GetFormatForMoment } from "Core/data/Helpers";
import { updateIncidentRedux, searchIncidents } from "Redux/actions";
import { NotificationManager } from "Components/Notifications";
import { reset } from "../../../../redux/slice";
import { EndIncident } from "../../../end";
import { ExportButton } from "./exportButton";
import { AlertInformation } from "./alertInformation";

export const Incident = ({
  hasVideo,
  rangeDate,
  data,
  timezone,
  clearFields,
}) => {
  const { messages } = useIntl();
  const dispatch = useDispatch();

  const {
    updateIncidentLoading,
    updateIncidentError,
    updateIncident,
    openIncidentPanel,
  } = useSelector((state) => state.incidentsRedux);

  const { user } = useSelector((state) => state.securityRedux);
  const { dateformat, durationFormat, isKph } = user;

  const [loading, setLoading] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [currentData, setCurrentData] = useState({
    start: null,
    end: null,
    reportedBy: null,
  });
  const [incidentInfo, setIncidentInfo] = useState({
    started: "...",
    duration: "...",
    reportedBy: "...",
    scheduledEnd: "...",
    end: moment(),
  });

  const getDurationDate = (endDate, startDate) => {
    const duration = moment(endDate).diff(moment(startDate), "s");
    const durationFormatTime = getMethodToFormatDuration(durationFormat);
    const durationResult = duration
      ? durationFormatTime(duration, messages)
      : "0 min, 0 sec";
    return durationResult;
  };

  useEffect(() => {


    setCurrentData({
      start: data?.start,
      end: data?.end,
      reportedBy: data?.incidentLog?.reportedBy,
    });
  }, [data]);

  useEffect(() => {
    if (!currentData?.start) return;

    let abbTimezone = moment.tz(timezone).zoneAbbr()
      if(abbTimezone === '-05'){
        abbTimezone = 'COT'
      }

    const interval = setInterval(() => {
      const durationResultSchedule = getDurationDate(
        currentData?.end,
        currentData?.start
      );
      const durationResult = getDurationDate(moment(), currentData?.start);
      setIncidentInfo({
        ...incidentInfo,
        started: (
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", marginRight: "5px" }}>
              <p style={{ margin: 0, marginRight: "5px" }}>
                {moment(currentData?.start).format("hh:mm:ss a")}
              </p>
              <Tooltip title={timezone}>
                {abbTimezone}
              </Tooltip>
            </div>
            <p style={{ margin: 0 }}>
              {moment(currentData?.start).format(
                GetFormatForMoment(dateformat)
              )}
            </p>
          </div>
        ),
        reportedBy: currentData?.reportedBy,
        scheduledEnd: (
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", marginRight: "5px" }}>
              <p style={{ margin: 0, marginRight: "5px" }}>
                {moment(currentData?.end).format("hh:mm:ss a")}
              </p>
              <Tooltip title={timezone}>
                {abbTimezone}
              </Tooltip>
            </div>
            <p style={{ margin: 0 }}>
              {moment(currentData?.end).format(GetFormatForMoment(dateformat))}
            </p>
            <p style={{ margin: 0, marginLeft: "5px" }}>
              ({durationResultSchedule})
            </p>
          </div>
        ),
        end: currentData?.end,
        duration: durationResult,
      });
    }, 100);
    
    setLoading(false);
    return () => clearInterval(interval);
  }, [currentData]);

  useEffect(() => {
    if (!updateIncidentLoading && updateIncident?.id && showNotification) {
      dispatch(
        reset({
          updateIncident: {},
          updateIncidentLoading: false,
        })
      );

      dispatch(
        searchIncidents({
          conditions: [
            {
              fields: "ended",
              terms: ["0"],
              exact: true,
            },
            {
              fields: "isDeleted",
              terms: ["0"],
              exact: false,
            }
          ],
          sort: [
            {
              field: "id",
              order: "DESC",
            },
          ],
          limit: 100,
          offset: 0,
        })
      );

      setShowNotification(false);

      setCurrentData({
        ...currentData,
        start: updateIncident?.start,
        end: updateIncident?.end,
      });

      return NotificationManager.success(
        messages["incendent_active_alerts_extend_notification"],
        messages["success"],
        3000,
        null,
        null,
        "success"
      );
    }
  }, [updateIncidentLoading, updateIncident]);

  const extendIncident = () => {
    const { id, start, unitId, name } = data;
    const newEnd = moment(incidentInfo?.end).add(1, "h");
    setShowNotification(true);
    dispatch(
      updateIncidentRedux({
        id,
        start,
        end: newEnd,
        unitId,
        name,
      })
    );
  };

  return (
    <div style={{ width: "100%", height: "250px", marginLeft: "10px" }}>
      <div style={{ display: "flex" }}>
        <p className="incident_information_title">
          {messages["incident_active_title"]}
          <ExportButton
            hasVideo={hasVideo}
            rangeDate={rangeDate}
            data={data}
            clearFields={clearFields}
          />
        </p>
      </div>
      <div style={{height: '280px', overflowY: 'auto'}}>
        {loading ? (
          <div className="incident_deviceInfo_loading">
            <Loading />
          </div>
        ) : (
          <div className="incident_container_information">
            <div style={{ display: "flex", marginBottom: "5px" }}>
              <p style={{ fontWeight: "bold", margin: "0px 5px 0 0" }}>
                {messages["incident_event_incident_started"]}
              </p>
              {incidentInfo?.started}
            </div>
            <div style={{ display: "flex", marginBottom: "5px" }}>
              <p style={{ fontWeight: "bold", margin: "0px 5px 0 0" }}>
                {messages["incident_event_incident_duration"]}
              </p>
              {incidentInfo?.duration}
            </div>
            <div style={{ display: "flex", marginBottom: "5px" }}>
              <p style={{ fontWeight: "bold", margin: "0px 5px 0 0" }}>
                {messages["incident_event_incident_reportedBy"]}
              </p>
              {incidentInfo?.reportedBy}
            </div>
            <div style={{ display: "flex", marginBottom: "5px" }}>
              <p style={{ fontWeight: "bold", margin: "0px 5px 0 0" }}>
                {messages["incident_event_incident_scheduled_end"]}
              </p>
              {incidentInfo?.scheduledEnd}
            </div>
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "45%" }}>
                <Button
                  style={{ width: "100%" }}
                  isDisabled={updateIncidentLoading ? true : false}
                  onClick={() => {
                    if (updateIncidentLoading) return;
                    extendIncident();
                  }}
                >
                  {messages["incendent_active_extend_button"]}
                </Button>
              </div>
              <div style={{ width: "45%" }}>
                <Button
                  style={{ width: "100%" }}
                  variant="warning"
                  onClick={() => setOpenDelete(true)}
                >
                  {messages["incendent_active_end_button"]}
                </Button>
              </div>
            </div>
          </div>
        )}
        <AlertInformation data={data}/>
      </div>

      <EndIncident
        item={data}
        openDelete={openDelete}
        setDeleteOpen={setOpenDelete}
      />
    </div>
  );
};
