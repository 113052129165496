import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Checkbox } from 'Components/uiControls';

import {
    Grid,
    Typography,
    Icon
} from "@mui/material";
import { getTagColor } from 'Core/data/Helpers';

import { Waypoint } from "react-waypoint";

// Styles
import '../styles.scss';

const selectAllTag = {
    id: -99,
    label: "selectAll",
    color: "000000",
    parentId: null,
    path: null,
    hasChildren: false,
    disabled: false
}

const TreeNode = (props) => {
    const { messages } = useIntl();
    const allTags = useSelector(state => state.newEntityTagPickerRedux.tags);

    const { tag, searchText, values, onChange, disabled, multiple } = props;

    const [showChildren, setShowChildren] = useState(false);

    useEffect(() => {
        if (values?.length) {
            if (((values?.find(v => v?.id == tag?.id) || values.some(v => tag?.offspring?.includes(v?.id))) && tag?.children?.length > 0 && !tag?.disabled) ||
                values?.find(v => v?.id == selectAllTag?.id)
            ) {
                setShowChildren(true);
            }
        }
    }, [values])

    const handleClick = () => {
        setShowChildren(!showChildren);
    };

    const getChildren = (item) => {
        let selected = [];
        if (item?.children?.length) {
            item?.children?.forEach(child => {
                if (!values?.find(v => v?.id == child?.id) && !child?.disabled) {
                    selected.push({
                        id: child?.id,
                        name: "Tag",
                        label: child?.label,
                        color: child?.color
                    });

                    if (child?.children?.length) {
                        let selectedChildren = getChildren(child);
                        selected = [...selected, ...selectedChildren];
                    }
                }
            });
        }

        return selected;
    }

    let checked = values?.find(v => v?.id == tag?.id) || values?.find(v => v?.id == selectAllTag?.id) ? true : false;
    let checkboxDisabled = tag?.disabled || disabled ? true : false;

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                wrap="nowrap"
                style={{
                    padding: 5
                }}
            >
                {
                    !searchText &&
                    <Grid item>
                        <div style={{ width: 33, height: 30 }}>
                            {
                                tag?.children?.length > 0 &&
                                <Icon
                                    disabled={tag?.disabled}
                                    style={{ fontSize: 30, margin: '0px', cursor: 'pointer' }}
                                    onClick={handleClick}
                                >
                                    {showChildren ? 'arrow_drop_down' : 'arrow_right'}
                                </Icon>
                            }
                        </div>
                    </Grid>
                }

                <Grid item>
                    <Checkbox
                        id={`tag-${tag?.id}`}
                        value={checked}
                        disabled={checkboxDisabled}
                        partiallySelected={(values?.some(v => tag?.offspring?.includes(v?.id)) && !checked) ? true : false}
                        onChange={() => {
                            if (!multiple) {
                                if (onChange) onChange([tag]);
                                return;
                            }

                            let newValues = [...values];

                            if (values?.find(v => v?.id == selectAllTag?.id)) {
                                newValues = allTags?.filter(t => t?.id != tag?.id);
                            } else {
                                if (values?.find(v => v?.id == tag?.id)) {
                                    newValues = values?.filter(v => v?.id != tag?.id);
                                } else {
                                    newValues.push({
                                        id: tag?.id,
                                        name: "Tag",
                                        label: tag?.label,
                                        color: tag?.color
                                    });

                                    if (tag?.children?.length) {
                                        let children = getChildren(tag);
                                        newValues = [...newValues, ...children];
                                    }
                                }
                            }

                            if (newValues?.length === allTags?.length && newValues?.length <1) {
                                newValues = [{ ...selectAllTag, label: messages[selectAllTag?.label] }];
                            }

                            if (onChange) onChange(newValues);
                        }}
                    />
                </Grid>

                <Grid item>
                    <Icon
                        style={{
                            fontSize: 17,
                            margin: '5px 5px 0px',
                            color: `#${getTagColor(tag?.color)}`
                        }}
                    >
                        local_offer
                    </Icon>
                </Grid>

                <Grid item xs zeroMinWidth>
                    <Typography noWrap>
                        {tag?.id == -1 ? messages[tag?.label] : tag?.label}
                    </Typography>
                </Grid>
            </Grid>

            <div style={{ paddingLeft: 10 }}>
                {
                    (tag?.children?.length > 0 && showChildren) &&
                    <Tree
                        tags={tag?.children}
                        searchText={searchText}
                        values={values}
                        onChange={onChange}
                        disabled={disabled}
                        multiple={multiple}
                    />
                }
            </div>
        </>
    );
}

const Tree = (props) => {
    const { tags, searchText, values, onChange, firstLevel, disabled, multiple, page, setPage, total } = props;
    const { messages } = useIntl();
    
    return (
        <div
            style={{
                paddingLeft: firstLevel ? 0 : 10
            }}
        >
            {tags?.map((tag) => (
                <TreeNode
                    tag={tag}
                    key={tag.id}
                    searchText={searchText}
                    values={values}
                    onChange={onChange}
                    disabled={disabled}
                    multiple={multiple}
                />
            ))}

            {
                firstLevel &&
                <Waypoint
                    onEnter={() => {
                        if (tags?.length < total) {
                            setPage(page + 1);
                        }
                    }}
                >
                    <span className="text-muted mt-5">
                        {tags?.length < total ? messages['loading'] : ""}
                    </span>
                </Waypoint>
            }
        </div>
    );
}

const TagPickerTags = (props) => {
    const { messages } = useIntl();

    const {
        searchText,
        tags,
        values,
        onChange,
        disabled,
        showSelectAll,
        multiple,
        page,
        setPage,
        total
    } = props;

    return (
        <div
            style={{
                height: '100%'
            }}
        >
            {
                (!searchText && showSelectAll && multiple) &&
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    style={{ padding: `10px 10px 0px 10px` }}
                >
                    <Grid item>
                        <Checkbox
                            id={`tag-${selectAllTag?.id}`}
                            value={values?.find(v => v?.id == selectAllTag?.id) ? true : false}
                            disabled={false}
                            partiallySelected={false}
                            onChange={() => {
                                let newValues = [];
                                if (!values?.find(v => v?.id == selectAllTag?.id)) newValues = [{ ...selectAllTag, label: messages[selectAllTag?.label] }];
                                if (onChange) onChange(newValues);
                            }}
                        />
                    </Grid>

                    <Grid item xs zeroMinWidth>
                        <Typography noWrap>
                            {messages['selectAll']}
                        </Typography>
                    </Grid>
                </Grid>
            }

            <Tree
                tags={tags}
                searchText={searchText}
                values={values}
                onChange={onChange}
                firstLevel={true}
                disabled={disabled}
                multiple={multiple}
                page={page}
                setPage={setPage}
                total={total}
            />
        </div>
    );
}

export default TagPickerTags;