import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';

// Actions
import {
  reportsMainGenerateReportProgress
} from 'Redux/actions';


function* getMileageGroupedByDateReport(report, event) {

  const { filterType, filterArray, startRange, endRange, sortField, sortOrder, rowsPerPage, page } = event.filters
  const { name,reportTimeRanges } = report;
  try {
    let data = yield call(
      clientQuery,
      `query getMileageGroupedByDateReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getMileageGroupedByDateReport (conditions: $conditions, limit: $limit, offset: $offset) {
            items {
                id
                date
                startDate
                endDate
                totalDistance
                isKPH
            }
            total
            limit
            offset
            category
        }
    }`,
      {
        conditions: {
          reportName: name,
          category: {
            field: filterType,
            value: filterArray.map(x => x)
          },
          dateRange: {
            fields: [
              "unitTime"
            ],
            GTE: startRange,
            LTE: endRange,
            reportTimeRanges
          },
          sortField: {
            field: sortField || "deviceId",
            order: sortOrder === 'desc' ? 'DESC' : 'ASC'
          }
        },
        limit: 0,
        offset: page
      },
      endpoints.GRAPHQL_PX_REPORTING,
    );

    if (data && data.Report) {
      report.summary = data.Report
      report.summary.filters = event.filters
      report.loadingSummary  = false
      yield put(reportsMainGenerateReportProgress(report))
    } else {
      report.loadingSummary  = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  } catch (exc) {
    report.loadingSummary  = false
    yield put(reportsMainGenerateReportProgress(report))
  }
}


export default getMileageGroupedByDateReport;
