import { call, put } from "redux-saga/effects";
import { clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";
// Actions
import {
  reportsMainGenerateReportProgress,
  reportsMainGenerateReportUpdate,
} from "Redux/actions";

import {
  baseReportConditions,
  generateReportInstance,
  getTimeZone,
  handleAsyncReport,
  isJsonFormat,
} from "Modules/reports/views/main/components/reportsTab/helper";

function* getJBSummaryReport(
  report,
  page,
  rowsPerPage,
  sortField,
  sortOrder,
  requestFlag
) {
  const {
    timeZone,
    sendByEmail,
    emailsTo,
    timeframeName,
    categoryName,
    delayed,
    dataResultPath,
  } = report;
  const {
    filterType,
    filterArray,
    startRange,
    endRange,
    otherFilters,
    unitsSelected,
  } = report.filters;
  let minutes = otherFilters.minute ? otherFilters.minute * 60 : 0;

  const baseConditionsQuery = baseReportConditions({
    report,
    name: "JBSummaryReport",
    dataResultPath,
    timeZone,
    delayed,
    unitsSelected,
    timeframeName,
    filterType,
    filterArray,
    categoryName,
    startRange,
    endRange,
  });

  let payload = {
    conditions: {
      ...baseConditionsQuery,
      sortField: {
        field: sortField || "deviceId",
        order: sortOrder === "desc" ? "DESC" : "ASC",
      },
      integerRanges: {
        fields: ["idle"],
        GTE: minutes,
        LTE: 0,
      },
    },
    offset: page,
    limit: rowsPerPage,
  };

  let columnsFormat;

  if (report?.exportType === "pdf") {
    columnsFormat = [
      {
        column: "dateTime",
        header: "Time & Date",
      },
      {
        column: "engineAverageFuelEconomy",
        header: "Eng Average Fuel Eco",
      },
      {
        column: "engineTotalFuelUsed",
        header: "Eng Total Fuel Used",
      },
      {
        column: "engineTotalHours",
        header: "Eng Total Hours",
      },
      {
        column: "engineTotalIdleFuel",
        header: "Eng Total Idle Fuel",
      },
      {
        column: "engineTotalIdleHours",
        header: "Eng Total Idle Hours",
      },
      {
        column: "hirezTotalFuel",
        header: "Hi-Rez Total Fuel",
      },
      {
        column: "totalPtoHours",
        header: "Total PTO Hours",
      },
      {
        column: "totalVehicleHours",
        header: "Total Vehicle Hours",
      },
    ];
  } else {
    columnsFormat = [
      {
        column: "idName",
        header: "Label",
      },{
        column: "dateTime",
        header: "Time & Date",
      },
      {
        column: "engineAverageFuelEconomy",
        header: "Eng Average Fuel Eco (mpg)",
      },
      {
        column: "engineTotalFuelUsed",
        header: "Eng Total Fuel Used (gal)",
      },
      {
        column: "engineTotalHours",
        header: "Eng Total Hours",
      },
      {
        column: "engineTotalIdleFuel",
        header: "Eng Total Idle Fuel (gal)",
      },
      {
        column: "engineTotalIdleHours",
        header: "Eng Total Idle Hours",
      },
      {
        column: "hirezTotalFuel",
        header: "Hi-Rez Total Fuel (gal)",
      },
      {
        column: "totalPtoHours",
        header: "Total PTO Hours",
      },
      {
        column: "totalVehicleHours",
        header: "Total Vehicle Hours",
      },
      {
        column: "odometer",
        header: "Odometer",
      },
    ];
  }

  if (report.export) {
    payload = {
      ...payload,
      limit: (!dataResultPath || !isJsonFormat(report?.exportType, dataResultPath))
        ? 5000
        : rowsPerPage,
      offset: (!dataResultPath || !isJsonFormat(report?.exportType, dataResultPath))
        ? 1
        : page,
      conditions: {
        ...payload.conditions,
        exportOptions: {
          sendByEmail,
          emailsTo,
          format: {
            fileFormat: report?.exportType || "json",
            formatOptions: '{"orientation": "landscape"}',
          },
          culture: report.lang,
          timeZone: getTimeZone(timeZone),
          folderName: "px-customer-reports",
          filePrefixName: "JBSummaryReport",
          columns: columnsFormat,
        },
      },
    };
  }

  try {
    let data = yield call(
      clientQuery,
      `query getJBSummaryReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
                Report: getJBSummaryReport (conditions: $conditions, limit: $limit, offset: $offset) {
                  ${
                    generateReportInstance(
                      report.export,
                      dataResultPath,
                      report.exportFromTable
                    )
                      ? `reportInstanceId`
                      : `
                    items {
                        id
                        idLabel
                        dates
                        noData
                    }
                    total
                    limit
                    offset
                    category
                    reportInstanceId
                `
                  }
                }
              }`,
      payload,
      endpoints.GRAPHQL_PX_REPORTING
    );
    yield handleAsyncReport(report, data);
  } catch (exc) {
    ExceptionManager(exc, "reports/JBSummaryReport", "getJBSummaryReport");
    report.loading = false;
    yield put(reportsMainGenerateReportProgress(report));
    if (!requestFlag) {
      let flag = true;
      yield put(
        reportsMainGenerateReportUpdate(
          report,
          page,
          rowsPerPage,
          sortField,
          sortOrder,
          flag
        )
      );
    } else {
      report.loading = false;
      yield put(reportsMainGenerateReportProgress(report));
    }
  }
}

export default getJBSummaryReport;
