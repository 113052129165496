import {
  FLEET_MODAL_OUTPUTS_OPEN,
  FLEET_MODAL_OUTPUTS_GET,
	FLEET_MODAL_OUTPUTS_GET_RESULT,
	FLEET_MODAL_OUTPUTS_SAVE,
	FLEET_MODAL_OUTPUTS_SAVE_RESULT,
  CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
  open: false,
  id: false,
  unit: null,
  loadingUnit: false,
  loading: false,
  response: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case FLEET_MODAL_OUTPUTS_OPEN:
      return { ...state, open: action.payload.open, unit: action.payload?.unit, id: action.payload?.id };

    case FLEET_MODAL_OUTPUTS_GET:
      return { ...state, loadingUnit: true, unit: null };

    case FLEET_MODAL_OUTPUTS_GET_RESULT:
      return { ...state, loadingUnit: false, unit: action.payload?.response };

    case FLEET_MODAL_OUTPUTS_SAVE:
      return { ...state, loading: true, response: null };

    case FLEET_MODAL_OUTPUTS_SAVE_RESULT:
      return { ...state, loading: false, response: action.payload?.response };

    case CLEAR_REDUX:
      return ["", "FLEET_MODAL_OUTPUTS"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
};
