export default {
    realtimemaps_vicinitytool_message: "Utilice esta herramienta para encontrar los vehículos que entran en una zona delimitada durante un periodo de tiempo",
    realtimemaps_vicinitytool_shape: "Forma",
    realtimemaps_vicinitytool_startinglocation: "Lugar de Inicio",
    realtimemaps_vicinitytool_firtsevent: "Prim. Evento",
    realtimemaps_vicinitytool_lastevent: "Últ. Evento",
    realtimemaps_vicinitytool_eventsinside: "Eventos Internos",
    realtimemaps_vicinitytool_behaviorevents: "Eventos de Comportamiento",
    realtimemaps_vicinitytool_range: "Seleccione una fecha o un intervalo",

    realtimemaps_vicinitytool_please_add_point: "Por favor selecciona un punto en el mapa",
    realtimemaps_vicinitytool_vicinity_tool: "Herramienta de Proximidad",
    realtimemaps_vicinitytool_search_available_tags: "Buscar etiquetas disponibles",
    realtimemaps_vicinitytool_tags: "Etiquetas",
    realtimemaps_vicinitytool_select: "Seleccione",
    realtimemaps_vicinitytool_venicle_trails: "Rutas de Vehículos",
    realtimemaps_vicinitytool_max_12_hours: "Máx 12 horas",
    realtimemaps_vicinitytool_message_max_12_hours: "El intervalo de tiempo no puede superar 12 horas",
    realtimemaps_vicinitytool_no_data_availabled: "No hay datos disponibles para el periodo solicitado",
    realtimemaps_vicinitytool_the_range_exceed: "El intervalo de fechas no puede superar las 12 horas",
    realtimemaps_vicinitytool_error_obtaining_data: "Hay un error al obtener los datos",
    realtimemaps_vicinitytool_error_polygon_area_error: "El área del polígono es demasiado grande, pruebe con una más pequeña",
    realtimemaps_vicinitytool_error_radius: "El área de forma es demasiado pequeña",

    realtimemaps_vicinitytool_error_point_in_map: "Utilice Dirección, Haga clic en el mapa o Unidad para generar la forma en el mapa",
    realtimemaps_vicinitytool_info_polygon: 'Comience a hacer clic en los puntos del mapa para crear un polígono. Deben ser 3–{maxPoints} puntos.',
    
}