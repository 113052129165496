/********************************************************
 * Third Controls
 ********************************************************/
import React from 'react'

/********************************************************
 * Icons
 ********************************************************/
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PauseIcon from '@mui/icons-material/Pause';
//import StopIcon from '@mui/icons-material/Stop';
import { Grid } from '@mui/material';

/********************************************************
 * Styles
 ********************************************************/
 import './style.scss' 

/********************************************************
 * Class to show playback controls for vehicle trails elements
 ********************************************************/
const PlaybackBar = (props) => {

  /********************************************************
   * Start or stop the play elements
   ********************************************************/
  const onPlay = () => {
    if(props.setPlay){
      props.setPlay(true)
    }
    if(props.onPlay){
      props.onPlay()
    }
  }

  /********************************************************
   * Start or stop the play elements
   ********************************************************/
  const onPause = () => {
    if(props.setPlay){
      props.setPlay(false)
    }
    if(props.onPause){
      props.onPause()
    }
  }
  

  /********************************************************
   * go to previus element
   ********************************************************/
  const onPrevious = () => {
    if(props.onPrevious){
      props.onPrevious()
    }
  }

  /********************************************************
   * go the next element
   ********************************************************/
  const onNext = () => {
    if(props.onNext){
      props.onNext()
    }
  }

  return(
    <div className='mapPagination-classtotal'>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={4}>
          <SkipPreviousIcon onClick={()=> onPrevious()} />
        </Grid>
        <Grid item xs={4}>
        {
          props.play
          ?
          <PauseIcon onClick={()=> {
            onPause()
          }} />
          :
          <PlayArrowIcon onClick={()=> {
            onPlay()
          }} />
        }
        </Grid>
        <Grid item xs={4}>
          <SkipNextIcon onClick={()=> onNext()} />
        </Grid>
      </Grid>
    </div>
  )

}

export default PlaybackBar