/* eslint-disable */
import moment from 'moment';
import * as momentTz from 'moment-timezone';
import axios from "axios";

export const getDataForEntityTags = (entity, drivers, units, subusers, forms, landmarks, geofences) => {
	let data = [];
	if (entity === "Driver") {
			data = drivers.map(d => {
					return { id: d.driverId, name: "Driver", label: d.name, status: d.status }
			});
	} else if (entity === "Unit") {
			data = units.map(d => {
					return { id: d.id, name: "Unit", label: d.label, unitVersionId: d.unitVersionId }
			});
	} else if (entity === "User") {
			data = subusers?.items?.map(d => {
					return { id: `${d.id}`, name: "User", label: d.userName }
			});
	} else if (entity === "Form") {
			data = forms?.map(f => {
					return { id: `${f.id}`, name: "Form", label: f.name }
			});
	} else if (entity === "Landmark") {
			data = landmarks?.map(d => {
					return { id: d.id, name: "Landmark", label: d.name }
			});
	} else if (entity === "Geofence") {
			data = geofences?.map(g => {
					return { id: g.id, name: "Geofence", label: g.name }
			});
	}
	return data;
}
export const getDeliveryAtFormat = (deliveryAt, frequency, deliveryOn,messages) => {
	let deliveryAtFormat = ''

	switch (frequency) {
		case 'daily':
			deliveryAtFormat = `${deliveryAt}`
			break;
			case 'weekly':
					const deliveryOnLanguage = deliveryOn.map((item)=> messages[item])
					deliveryAtFormat = `${deliveryOnLanguage} ${messages["at"]} ${deliveryAt}`
					break;
			case 'monthly':
					deliveryAtFormat = `${messages["at"] === "at" ? deliveryOn :  "El "+deliveryOn[0].replace(/[a-zA-Z]/g, "") } ${messages["at"]} ${deliveryAt}`
					break;
	}
	return deliveryAtFormat
}
export const speedFormatMPH = (speed, unitType, kph, isVehicleTrails = false, onlyNumber = false) => {
	if (!speed || speed == 0) {
		return `0 ${!onlyNumber ? (kph ? 'KPH' : 'MPH') : "" }`;
	}

	if(!isVehicleTrails){
		speed = converSpeedToMph(speed, unitType);
	}
	if (kph) {
		speed = convertToKPH(speed);
		speed = Math.round(speed);
		if(!onlyNumber){ //default value in string with speed
			return `${speed} KPH`;
		}else{
			return speed;
		}
	} else {
		speed = Math.round(speed);
		if(!onlyNumber){ //default value in string with speed format
			return `${Math.ceil(speed)} MPH`;
		}else{
			return Math.ceil(speed);
		}
	}
}

export const deleteDupplyInArray = (array, nameProp) => {
    return array.filter((item, index, self) =>
        index === self.findIndex((t) => (
            t[nameProp] === item[nameProp]
        ))
    );
}

export const postedSpeedColor = (speed, speedLimit) => {
    if (!speed || !speedLimit) return '#000000'; //Black
    if (speed <= speedLimit) return '#2AAC30'; //Green
    if (speed > speedLimit && speed <= (speedLimit + 10)) return '#FF7337' //Orange
    else return '#ED2020' //Red
}

export const removeAccents = (text) => {
    // Create a map that associates each accented vowel with its non-accented counterpart
    const accentMap = {
        'á': 'a',
        'é': 'e',
        'í': 'i',
        'ó': 'o',
        'ú': 'u',
        'Á': 'A',
        'É': 'E',
        'Í': 'I',
        'Ó': 'O',
        'Ú': 'U'
    };

    // Use replace with a replacement function to substitute accented vowels
    return text.replace(/[áéíóúÁÉÍÓÚ]/g, function(match) {
        return accentMap[match];
    });
}

/********************************************************
 * Validate JSON
 ********************************************************/
 export const isValidJson = (json) => {
	try {
		JSON.parse(json);
		return true;
	} catch (e) {
		return false;
	}
}

export function checkDeviceData(paramDeviceData){
    let deviceData = paramDeviceData;
    if (deviceData && isValidJson(deviceData)) {
        deviceData = JSON.parse(paramDeviceData);

        //double parse because trail
        if (typeof deviceData == "string" && isValidJson(deviceData)) {
            deviceData = deviceData && JSON.parse(deviceData);
        }
    }
	return deviceData;
 }

export const formatAddress = (element) => {
	let newAddress = element?.formattedAddress || '';
	if (!newAddress || newAddress == 'null') {
		if (element?.address && isValidJson(element?.address)) {
			const { formatted } = JSON.parse(element?.address)
			if (formatted) {
				newAddress = formatted || '';
			}
		}else{
			if(element?.address && element?.address?.formatted){
				const { formatted } = element?.address
				newAddress = formatted
				return newAddress
			}
		  newAddress = '';
		}
	}
	return newAddress
}

export const setLegacyUrl = (url, reseller) => {
	return (!window.location.hostname.includes('gpstrackit.com') && reseller?.legacyUrl) || url;
}

export const getLegacyUrl = (url) => {
	const legacyUrl = localStorage.getItem("legacyUrl");
	if (legacyUrl && legacyUrl != "undefined" && legacyUrl != "null") {
		return legacyUrl;
	}
	return url;
}

export const convertToKPH = (speedValue) => {
	let kph = 0;
	if (parseInt(speedValue) > 0) {
		kph = (speedValue * 1.609344);
	}
	return Math.ceil(kph);
}

export const convertToMI = (value) => {
	let mi = 0;
	if (parseInt(value) > 0) {
		mi = (value * 0.621371);
	}
	return Math.ceil(mi);
}

export const parseUrl = (url) => {
	// delete the http or https to show an URL
	if (typeof url === 'string') {
		let newUrl = url.replace("https://", '');
		newUrl = newUrl.replace("http://", '')
		return newUrl;
	}
	return url;
}

export const isFacebookError = (error) => {
	// Error when the person does not allow the email in the Facebook authentication
	if (
		error &&
		error === "attributes required: [email]"
	) {
		return true
	}
	return false;
}

export const isAppleError = (error) => {
	// Error when the person does not allow the email in the Apple authentication
	if (
		error &&
		typeof error === "string" &&
		error.includes("privaterelay.appleid.com")
	) {
		return true
	}
	return false;
}

export const EmailNotFound = (error) => {
	if (typeof error === 'string' && error.includes('Sign in with Google')) {
		return true
	}
	return false;
}

export const ErrorHandlerLogin = (error, isPasswordError) => {
	let newError = error;

	if (newError) {
		// parse to string if the error is an object
		if (typeof newError == "object") {
			newError = newError.toString();
		}
		const errorMessageGraphql = 'Error: Graphql error: ';
		newError = newError.replace(new RegExp(errorMessageGraphql, "gi"), '');
		const errorCustomException = 'CustomException:';
		newError = newError.replace(new RegExp(errorCustomException, "gi"), '');
		// Message displayed when user attempts to log in or reset password more than 5 times in a row
		const errorMessageLimit= 'LimitExceededException: ';
		newError = newError.replace(new RegExp(errorMessageLimit, "gi"), '');
		const errorMessagePrefix = 'Error: ';
		newError = newError.replace(new RegExp(errorMessageGraphql, "gi"), '');
		newError = newError.replace(new RegExp(errorMessagePrefix, "gi"), '');
		const errorMessageApollo = 'Apollo';
		newError = newError.replace(new RegExp(errorMessageApollo, "gi"), '');
		// implement language to message from cognito
		if (isPasswordError && newError.includes('Incorrect username or password.')) {
			return 'login_password_incorrect';
		}
		return newError.trim();
	}
	return error;
}

export const forTesting = (isDeveloper) => {
	if (!isDeveloper && process.env.ENVIROMENT && (process.env.ENVIROMENT === "staging")) {
		isDeveloper = true;
	}

	if (!isDeveloper && process.env.ENVIROMENT === "beta") {
		isDeveloper = true;
	}
	return isDeveloper;
}
export const OrderData = (list, key) => {

	let newSortList = [...list].sort((a, b) => {
		const itemA = String(a[key]).toUpperCase();
		const itemB = String(b[key]).toUpperCase();

		if (itemA < itemB) {
			return -1;
		}

		if (itemA > itemB) {
			return 1;
		}

		return 0;
	});

	return newSortList;
};

export const GetFormatForDate = (dateFormat) => {
	let format = 'MM/dd/yyyy';

	if (dateFormat === 'm/d/Y') {
		format = 'MM/dd/yyyy';
	} else if (dateFormat === 'd/m/Y') {
		format = 'dd/MM/yyyy';
	} else if (dateFormat === 'Y/m/d') {
		format = 'yyyy/MM/dd';
	}

	return format;
};

export const GetFormatForMoment = (dateFormat) => {
	let format = 'MM/DD/YYYY';

	if (dateFormat === 'm/d/Y') {
		format = 'MM/DD/YYYY';
	} else if (dateFormat === 'd/m/Y') {
		format = 'DD/MM/YYYY';
	} else if (dateFormat === 'Y/m/d') {
		format = 'YYYY/MM/DD';
	}

	return format;
};

export const GetFormatForMomentTwoCharacters = (dateFormat) => {
	let format = 'MM/DD/YY';

	if (dateFormat === 'm/d/Y') {
		format = 'MM/DD/YY';
	} else if (dateFormat === 'd/m/Y') {
		format = 'DD/MM/YY';
	} else if (dateFormat === 'Y/m/d') {
		format = 'YY/MM/DD';
	}

	return format;
};

export const GetNumberDaysBetweenTwoDates = (date1, date2) => {
	var differenceInTime = date2.getTime() - date1.getTime();
	return differenceInTime / (1000 * 3600 * 24);
};

export const validateEmail = (email) => {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export const MerginObjectArray = (originalArray, newArray, key = null) => {

	//***  merging array regarding its data */

	let merginObjectArray = originalArray
	newArray.map(element => {
		merginObjectArray = InsertIntoArray(merginObjectArray, element, key)
	})
	return merginObjectArray
}

export const InsertIntoArray = (arrayToInsert, data, key = null) => {

	//***  insert data in array checking if already exist */

	const index = key ? arrayToInsert.findIndex(element => element[key] === data[key]) :
		arrayToInsert.findIndex(element => element === data)

	if (index != -1) {
		arrayToInsert[index] = { ...arrayToInsert[index], ...data }
	}
	else {
		arrayToInsert.push(data)
	}

	return arrayToInsert
}

export const DayFormat = (value) => {
	let day
	switch (value) {
		case "monday":
			day = "MON"
			break;
		case "tuesday":
			day = "TUE"
			break;
		case "wednesday":
			day = "WED"
			break;
		case "thursday":
			day = "THU"
			break;
		case "friday":
			day = "FRI"
			break;
		case "saturday":
			day = "SAT"
			break;
		case "sunday":
			day = "SUN"
			break;
		default:
			break;
	}
	return day
}

export const formatCatalogAddress = (address) => {
	//console.log("address",address)
	if (address && (address.address && address.zip && address.state || address.formatted)) {
		//return `${address.address}, ${address.formatted}, ${address.zip}, ${address.state}`;
		return `${address.formatted}` || `${address.address}, ${address.zip}, ${address.state}`;
	}
	return '';
};

export const formatCatalogDate = (unitTime, paramFormat = null) => {

	//console.log("unitTime",unitTime)

	if (!unitTime) {
		return
	}

	const timeMoment = moment(unitTime);
	if (timeMoment.isValid()) {
		if (!paramFormat) {
			return `${timeMoment.format(GetFormatForMoment('m/d/Y') + ', h:mm a')} ${'(' + timeMoment.fromNow() + ')'}`
		} else {
			return `${timeMoment.format(paramFormat)} ${'(' + timeMoment.fromNow() + ')'}`
		}

	} else {
		//***  formatting integer timestamps to readable dates */

		let unixMoment

		//console.log("unitTime",unitTime,unitTime && Number(unitTime?.substring(0, unitTime.length - 3)),unitTime.length)

		if (unitTime && unitTime.length > 10) {
			unixMoment = moment.unix(Number(unitTime?.substring(0, unitTime.length - 3)))
		} else {
			unixMoment = moment.unix(Number(unitTime))
		}

		//console.log("unixMoment",unixMoment)

		if (unixMoment.isValid()) {
			if (!paramFormat) {
				return `${unixMoment.format(GetFormatForMoment('m/d/Y') + ', h:mm a')} ${'(' + unixMoment.fromNow() + ')'}`
			} else {
				return `${unixMoment.format(paramFormat)} ${'(' + unixMoment.fromNow() + ')'}`
			}
		}

		//***  formatting integer timestamps to readable dates */
	}

	return

};

export const formatOffsetDisplayOdometer = (unit) => {

	const result = unit.useEcuOdometer ? Math.round(getValueFloat(unit?.ecuEngineOdometer) + getValueFloat(unit?.offsetEcuEngineOdometer))
		: Math.round(getValueFloat(unit?.engineOdometer) + getValueFloat(unit.offsetEngineOdometer))

	if (result !== null && result !== undefined)
		if (result < 0)
			return 0
		else
			return result

	return ''
}

export const formatOffsetDisplayEngineHours = (unit) => {
	let result = unit.useEcuEngineHours ? (getValueFloat(unit?.ecuEngineHours) + getValueFloat(unit?.offsetEcuEngineHours))
		: (getValueFloat(unit?.engineHours) + getValueFloat(unit?.offsetEngineHours));

	if (result !== null && result !== undefined && !isNaN(result)) {
		if (result < 0){
			return 0
		} else {
			return result?.toFixed(2);
		}
	}

	return ''
}

export const roundTo = (n, place = 2) => {
    return +(Math.round(n + "e+" + place) + "e-" + place);
}

export const getValueFloat = (value) => {
	return parseFloat(value || 0);
}

export const isJson = (str) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

export const round = (value, precision) => {
	var multiplier = Math.pow(10, precision || 0);
	return Math.round(value * multiplier) / multiplier;
}

export const createUUID = () => {
	const pattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
	return pattern.replace(/[xy]/g, (c) => {
		const r = (Math.random() * 16) | 0;
		const v = c === 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};


export const convertIntFromObjectToBoolean = (data) => {
	if (!data) {
		return null;
	}

	let newData = { ...data }
	Object.keys(data)?.forEach((key) => {
		let value = data[key];
		if (typeof value === "boolean") {
			newData = { ...newData, [key]: value ? 1 : 0 };
		}
	});

	return newData;
};

export const convertStringToIntegerBoolean = (value = '') => {
	let newValue = value.toString().toLowerCase() == 'on' ? 1 : 0;
	if (newValue == 0 && value != '' && !isNaN(value)) {
		return parseInt(value);
	} else if (newValue == 1) {
		return newValue;
	}

	return value;
};

export const getCordinatesAndRadius = (landmark) => {
	// Method to get radius and cordinates given a landmark
	let radius = 0;
	let coordinatesCenter = [];
	if (landmark) {
		radius = landmark.shape == "circle" ? landmark.radius : 0;
		coordinatesCenter = radius > 0 ? [{ lat: landmark.latitude, lng: landmark.longitude }] : landmark.coordinates;
	}

	return {
		radius,
		coordinatesCenter
	}
}

//Convertions epoch time
export const convertEpochTimeToMoment = (unitTimeParam) => {
	if (!Number.isInteger(Number(unitTimeParam))) {
		return unitTimeParam
	}

	if (unitTimeParam.toString().length == 10) {
		return moment.unix(Number(unitTimeParam)); //seconds
	}
	return moment(Number(unitTimeParam)); //milliseconds
}

export const cutNotes = (note, maxLength = 10) => {
	let notes = note ? note : ''
	if (notes.length > maxLength) {
		notes = `${notes.slice(0, maxLength)}...`;
	}
	return notes
}

export const clearStringValue = (stringValue) => {
	//remove true
	stringValue = (stringValue + '').toString() != 'true' ? stringValue : ''

	return stringValue
}

export const clearNumberValue = (numberValue) => {

	if (!isNaN(parseFloat(numberValue)) && isFinite(numberValue))
		//remove true
		numberValue = parseFloat(numberValue)
	else
		numberValue = null

	return numberValue
}

export const validateJsonObject = (object) => {
	if (object) {
		if (typeof object === 'string') {
			try {
				return JSON.parse(object);
			} catch (e) {
				return object;
			}
		} else if (typeof object === 'object') {
			return object;
		}
	}
}

export const convertJsonObject = (object) => {
	if (object) {
		if (typeof object === 'string') {
			try {
				return JSON.parse(object);
			} catch (e) {
				return null;
			}
		} else if (typeof object === 'object') {
			return object;
		}
	}
	return null
}

export const createMomentTimeZone = (date, timeZone, convertToUTC) => {
	//  converts a given date to a given timezone
	if (momentTz(date).isValid()) {
		return momentTz(date, timeZone).format();
	}
	return date;
}

export const dateTimeZoneToUTC = (date, timezone) => {
	if (momentTz(date).isValid()) {
		const newDate = momentTz(date).format('YYYY-MM-DD HH:mm:ss');
		var a = momentTz.tz(newDate, timezone);
		const utcTime = a.utc().format();
		return utcTime;
	}
	return date;
}
export const getDateTimeZoneUser = (date, timeZone) => {
	let momentDate = moment(date).tz(timeZone);
	if (momentDate.isValid()) {
		if (Number.isInteger(date)) {
			if (date.toString().length > 10) date = (date / 1000);
			momentDate = momentTz.unix(date);
			momentDate = momentDate.tz(timeZone).format();
			return momentDate;
		}
		return momentDate = momentTz(date).tz(timeZone).format();
	}
	return date;
}

export const getUserFormattedDate = (user, date, messages, timeFormat, onlyDate = false, dateFirst = false) => {
	let momentDate = "";
	if (!date || !messages) return;

	if (!user) {
		if (moment().isSame(moment(date), 'day')) {
			return `${messages["date_today"]} ${messages['at']} ${moment(date).format("hh:mm A z")}`;
		} else if (moment().subtract(1, 'd').format("DD") === moment(date).format("DD")) {
			return `${messages["date_yesterday"]} ${messages['at']} ${moment(date).format("hh:mm A z")}`;
		}

		return moment(date).format("MM/DD/YYYY hh:mm A z");
	}

	const momentFormat = GetFormatForMoment(user.dateformat);

	if (Number.isInteger(date * 1)) {
		if (date.toString().length > 10) date = (date / 1000);

		momentDate = momentTz.unix(date);
		momentDate = momentDate.tz(user.timeZone);
	} else if (typeof date === 'string') {
		momentDate = momentTz(date).tz(user.timeZone);
	}

	if (!momentDate) return;

	if (!timeFormat) {
		if (momentTz().tz(user.timeZone).isSame(momentDate, 'day')) {
			return `${messages["date_today"]} ${messages['at']} ${momentDate.format(`${!onlyDate ? 'h:mm A' : ''}`)}`;
		} else if (momentTz().tz(user.timeZone).subtract(1, 'd').format("DD") === momentDate.format("DD") && momentTz().tz(user.timeZone).subtract(1, 'd').format("MM") === momentDate.format("MM") ) {
			return `${messages["date_yesterday"]} ${messages['at']} ${momentDate.format(`${!onlyDate ? 'h:mm A' : ''}`)}`;
		}
	}

	if (onlyDate) {
		return momentDate.utc().format(momentFormat)
	} else if (dateFirst) {
		return momentDate.format(`${momentFormat}, ${timeFormat || 'h:mm A'}`);
	} else {
		return momentDate.format(`${timeFormat || 'h:mm A'}, ${momentFormat}`);
	}
}

export const converSpeedToMph = (speed, unitType) => {
	let mph = 0
	switch (unitType) {
		case 'Calamp': //Calamp 1100
			mph = (speed * 0.0223693629); //cps to mph
			break;
		case 'Webtech':
			mph = (speed * 0.621371192); //kph to mph
			break;
		default:
			mph = speed;
			break;
	}
	return mph
}

export const conversionTimeZone = (timezone) => {
	let response = timezone
	switch (timezone) {
		case 'UCT':
			response = 'Etc/UCT'
			break
		case 'Universal':
			response = 'Etc/UTC'
			break
		case 'US/Alaska':
			response = 'America/Anchorage'
			break
		case 'US/Aleutian':
			response = 'America/Adak'
			break
		case 'US/Arizona':
			response = 'America/Phoenix'
			break
		case 'US/Central':
			response = 'America/Chicago'
			break
		case 'US/Eastern':
			response = 'America/New_York'
			break
		case 'US/East-Indiana':
			response = 'America/Indiana/Indianapolis'
			break
		case 'US/Hawaii':
			response = 'Pacific/Honolulu'
			break
		case 'US/Indiana-Starke':
			response = 'America/Indiana/Knox'
			break
		case 'US/Michigan':
			response = 'America/Detroit'
			break
		case 'US/Mountain':
			response = 'America/Denver'
			break
		case 'US/Pacific':
			response = 'America/Los_Angeles'
			break
		case 'US/Pacific-New':
			response = 'America/Los_Angeles'
			break
		case 'US/Samoa':
			response = 'Pacific/Pago_Pago'
			break
	}
	return response
}

export const headingToDirection = (num) => {
	if (num && !isNaN(num)) {
		const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
		return directions[Math.round(num / 45) % 8];
	}
	return '';
}

export const getFormattedDateByTimeZone = (date, timeZone, messages, format, fromNow, locale = 'en' ) => {
	let momentDate = "";
	moment.locale(locale);
	if (!date || !timeZone || !messages) return;

	if (Number.isInteger(date * 1)) {
		if (date.toString().length > 10) date = (date / 1000);

		momentDate = momentTz.unix(date);
		momentDate = momentDate.tz(timeZone);
	} else if (typeof date === 'string') {
		momentDate = momentTz(date).tz(timeZone);
	}

	if (!momentDate) return;

	if (format) return `${momentDate.format(format)} ${fromNow ? `(${momentDate.lang(locale).fromNow()})` : ''}`;

	if (momentTz().tz(timeZone).isSame(momentDate, 'day')) {
		return `${messages["date_today"]} ${messages['at']} ${momentDate.format('h:mm A')}`;
	} else if (momentTz().tz(timeZone).subtract(1, 'd').format("DD") === momentDate.format("DD")) {
		return `${messages["date_yesterday"]} ${messages['at']} ${momentDate.format('h:mm A')}`;
	}

	return `${momentDate.format('Y/m/d h:mm A')} ${fromNow ? `(${momentDate.lang(locale).fromNow()})` : ''}`;
}

//csv is the CSV file with headers
export const csvJSON = (csv) => {
	try {
		let lines = csv.split("\n");
		let result = [];
		let headers = lines[0].split(",");
		headers = headers.filter((item) => item != '' && item != ' ' && item != '\r');
		headers = headers.map((item) => item.replace('\r', ''));

		for (let i = 1; i < lines.length; i++) {
			let obj = {};
			let currentline = lines[i].split(",");
			const validateCurrentline = currentline.filter((item) => item != '' && item != ' ' && item != '\r');

			if (validateCurrentline && validateCurrentline.length > 0) {
				for (let j = 0; j < headers.length; j++) {
					let dataToInsert = currentline[j]
					dataToInsert = dataToInsert ? dataToInsert.replace('\r', '') : '';
					obj[headers[j]] = dataToInsert;
				}
				result.push(obj);
			}
		}
		return result;

	} catch (ex) {
		return ex;
	}
}

export const downloadFile = (content, fileName, mimeType) => {
	var a = document.createElement('a');
	mimeType = mimeType || 'application/octet-stream';

	if (navigator.msSaveBlob) { // IE10
		navigator.msSaveBlob(new Blob([content], {
			type: mimeType
		}), fileName);
	} else if (URL && 'download' in a) { //html5 A[download]
		a.href = URL.createObjectURL(new Blob([content], {
			type: mimeType
		}));
		a.setAttribute('download', fileName);
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	} else {
		location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
	}
}

//Convert the hex color to RGB
export const convertHexToRgb = (hexColor) => {

	if (hexColor) {
		hexColor = hexColor.replace('#', '');
	}

	if (hexColor?.length === 3) {
		hexColor = hexColor.split('').map(char => char + char).join('');
	}

	//Only six-digit hex colors are allowed.
	if (hexColor && hexColor.length != 6 || !hexColor) {
		return [];
	}

	let r1 = parseInt(hexColor.substring(0, 2), 16);
	let g1 = parseInt(hexColor.substring(2, 4), 16);
	let b1 = parseInt(hexColor.substring(4, 6), 16);
	return [parseInt(r1), parseInt(g1), parseInt(b1)];
}

export const convertWhiteColor = (color) => {

	if (!color || color == "#") return '#D0D3D4';

	//evaluate white color
	let rgbColor = convertHexToRgb(color);
	if (rgbColor && rgbColor.length > 0) {
		//evaluate color white
		if (rgbColor[0] > 210 && rgbColor[1] > 210 && rgbColor[2] > 210) {
			return '#D0D3D4'
		}
	} else {
		//when the color name is white
		if (color && color.toLowerCase() == 'white') {
			return '#D0D3D4'
		}
	}
	return color.includes('#') ? color : `#${color}`;
}

export const copyArrayOrObject = (array) => {
	if (array && typeof array === 'object') {
		return JSON.parse(JSON.stringify(array))
	}
	return array
}

export const detectBrowser = () => {
  // Return the current browser name
  if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
      return 'Opera';
  } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
      return 'Chrome';
  } else if(navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
  } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
      return 'Firefox';
  } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {
      return 'IE';//crap
  } else {
      return 'Unknown';
  }
}

export const copyToClipboard = (text) => {
	if (!text || text.length === 0) return;
	navigator.clipboard.writeText(text);
}

export const getTagColor = (color) => {
	if (!color) return "000000";

	let notAvailableColors = [
		'white',
		'fff',
		'ffffff'
	];

	let newColor = color?.toLowerCase();
	if (notAvailableColors?.includes(newColor)) {
		return "000000";
	}

	return color;
}

export const errorCodeHandler  = (errorCode) => {
	const errors = {
		'1010': 'duplicated_name',
		'1011': 'invalid_params',
		'1002ftp': 'exist_ftp',
		'1010ftp': 'duplicated_name',
		'1011ftp': 'invalid_params_ftp'
	}
	return errors[errorCode] || '';
}

export const download = (url, name, callback) => {
    if (url) {
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const urlObject = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = urlObject;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
		if (callback) callback();
      });
    }
};

export const formatTimeDelta = (timedelta) => {
    let minutes = Math.floor(timedelta / 60);
    const seconds = timedelta % 60;
    if (minutes > 60) {
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      if (hours > 24){
        const days = Math.floor(hours / 24);
        hours = hours % 24;
        return `${days || 0} days, ${hours || 0} hr, ${minutes || 0} min`;
      }
      return `${hours || 0} hr, ${minutes || 0} min`;
    }

    return `${minutes || 0} min`;
  }

export const formatTimeHour = (timedelta, messages) => {
	if(!timedelta) return `0 ${messages["reportsMainRT_hours"]}`

	let hours = Number.parseFloat(timedelta/3600).toFixed(2);
	return `${hours} ${messages["reportsMainRT_hours"]} `;
}

export const getPreferencesByKey = (preferencesUser, key) => {
	let preferences = {};
	if (preferencesUser && key) {
		let preferencesKeyTemp = preferencesUser.find(x => x.preferenceKey === key);
		let preferencesJSON = preferencesKeyTemp && preferencesKeyTemp.preferenceValue != "" && preferencesKeyTemp.preferenceValue || "{}";
		preferences = JSON.parse(preferencesJSON);
	}

	return preferences;
}

export const getFormattedField = (field, unit) => {
  if (field) {
	return `${field} ${unit}`;
  }

  return 'N/A';
};

export const getUnitsNaturalOrder = (units) => {
	try {
		//console.log('getUnitsNaturalOrder', units)
		return units.sort(function (a, b) {
			return a.label?.localeCompare(b.label, undefined, {
				numeric: true,
				sensitivity: 'base'
			})
		})
	} catch (error) {
		return units
	}
}