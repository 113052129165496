import {
  SHARED_MEDIA_OPEN_MODAL,
  SHARED_MEDIA_SHARE_MEDIA,
  SHARED_MEDIA_SHARE_MEDIA_RESPONSE,
  SHARED_MEDIA_GET_SHARED_MEDIA,
  SHARED_MEDIA_GET_SHARED_MEDIA_RESPONSE
} from 'Redux/actionTypes';

export const openSharedMediaModal = (data) => ({
  type: SHARED_MEDIA_OPEN_MODAL,
  payload: { data },
});

export const sharedMedia = (data) => ({
  type: SHARED_MEDIA_SHARE_MEDIA,
  payload: { data },
});

export const sharedMediaResponse = (data) => ({
  type: SHARED_MEDIA_SHARE_MEDIA_RESPONSE,
  payload: { data },
});

export const getSharedMedia = (data) => ({
  type: SHARED_MEDIA_GET_SHARED_MEDIA,
  payload: { data },
});

export const getSharedMediaResponse = (data) => ({
  type: SHARED_MEDIA_GET_SHARED_MEDIA_RESPONSE,
  payload: { data },
});