export default {
    "FSM_SETTINGS_QUICK_MESSAGE": "Quick Message",
    "FSM_SETTINGS_QUICK_MESSAGE_title": "New Quick Message ",
    "FSM_SETTINGS_QUICK_MESSAGE_edit": "Edit Quick Message ",
    "FSM_SETTINGS_QUICK_MESSAGE_delete_detail":'Are you sure you want to delete the following Quick Messages?',
    "FSM_SETTINGS_QUICK_MESSAGE_delete_warning":'This action cannot be undone.',
    "FSM_SETTINGS_QUICK_MESSAGE_delete_title":'Delete Quick Messages',
    "FSM_SETTINGS_QUICK_MESSAGE_create_success": "Quick Message Created",
    "FSM_SETTINGS_QUICK_MESSAGE_update_success": "Quick Message Updated",
    "FSM_SETTINGS_QUICK_MESSAGE_delete_success": "Quick Message Deleted",
    
};