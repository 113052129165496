export default {
    "FSM_SETTINGS_QUICK_RESPONSE": "Quick Response",
    "FSM_SETTINGS_QUICK_RESPONSE_title": "New Quick Response ",
    "FSM_SETTINGS_QUICK_RESPONSE_edit": "Edit Quick Response ",
    "FSM_SETTINGS_QUICK_RESPONSE_delete_detail":'Are you sure you want to delete the following Quick Responses?',
    "FSM_SETTINGS_QUICK_RESPONSE_delete_warning":'This action cannot be undone.',
    "FSM_SETTINGS_QUICK_RESPONSE_delete_title":'Delete Quick Responses',
    "FSM_SETTINGS_QUICK_RESPONSE_create_success": "Quick Response Created",
    "FSM_SETTINGS_QUICK_RESPONSE_update_success": "Quick Response Updated",
    "FSM_SETTINGS_QUICK_RESPONSE_delete_success": "Quick Response Deleted",
    
};