import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

//STATE INITIAL
const INIT_STATE = {}

const route = "modules/dashboard/views/landmarkRealTime/redux/slice";

export const dashboardSearchLandmarkRealTime = createAsyncThunk("modules/dashboard/views/landmarkRealTime", async ({ landmarksIds, keyMemory, parameterOrder, limit, offset, total = -1 }, { rejectWithValue, dispatch }) => {
  try {
    let response = await clientQuery(
      `
            query ($landmarksIds: [landmarkToSearchVisit]!,$keyMemory: String! $parameterOrder: String!, $limit: Int!, $offset: Int!){
                searchLandmarkRealTime(landmarksIds: $landmarksIds,keyMemory: $keyMemory, parameterOrder: $parameterOrder, limit: $limit, offset: $offset){
                items{
                    landmark {
                        landmarkId
                        landmarkName
                        url
                        color
                       }
                      deviceIds{
                        id
                        label
                      }
                      visits
                  }
                  total
                }
            }
            `,
      {
        landmarksIds,
        keyMemory,
        parameterOrder,
        limit,
        offset
      },
      endpoints.GRAPHQL_GENERAL_V2
    );
    return response.searchLandmarkRealTime
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, route, 'dashboardSearchLandmarkRealTime');
    return rejectWithValue(exc);
  }
}
)

export const dashboardSearchLandmarkRealTimeRedux = createSlice({
  name: 'dashboard/landmarkRealTime',
  initialState: INIT_STATE,
  reducers: {
    reset(state, action) {
      const { viewId } = action.payload;
      if (viewId) {
        state[viewId] = { loadingLandmarksRealTime: false, landmarksRealTime: [], error: null };
      } else {
        return INIT_STATE;
      }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(dashboardSearchLandmarkRealTime.pending, (state, action) => {
      const { viewId } = action.meta.arg;
      if (!state[viewId]) {
        state[viewId] = { loadingLandmarksRealTime: false, landmarksRealTime: [], error: null };
      }
      state[viewId].loadingLandmarksRealTime = true;
    });

    builder.addCase(dashboardSearchLandmarkRealTime.fulfilled, (state, action) => {
      const { viewId } = action.meta.arg;
      state[viewId].landmarksRealTime = action.payload;
      state[viewId].loadingLandmarksRealTime = false;
    });

    builder.addCase(dashboardSearchLandmarkRealTime.rejected, (state, action) => {
      const { error } = action.payload;
      const { viewId } = action.meta.arg;
      state[viewId].loadingLandmarksRealTime = false;
      state[viewId].error = error;
    });
  },
})

const { actions, reducer } = dashboardSearchLandmarkRealTimeRedux;

// Extract and export each action creator by name
export const {
  reset,
} = actions;

export default reducer;
