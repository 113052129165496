//View Keys
export default {
    driversTasksTab_task: 'Tarea',
	driversTasksTab_createDriverTask: 'Crear Nueva Tarea',
	driversTasksTab_taskLabel: 'Etiqueta de la Tarea',
	driversTasksTab_taskName: 'Nombre de la Tarea',
    driversTasksTab_repeat: 'Repetir',
    driversTasksTab_repeatEvery: 'Repetir Cada',
	driversTasksTab_repeatPlaceHolder: 'Repetir',
	driversTasksTab_frequency: 'Frecuencia',
	driversTasksTab_frequencyPlaceHolder: 'Frecuencia',
	driversTasksTab_newTask: 'Nueva Tarea',
	driversTasksTab_manageTask: 'Administrar Tareas',
	driversTasksTab_estimatedCostLabel: 'Costo Estimado',
	driversTasksTab_estimatedCostPlaceholder: 'Costo Estimado',
    driversTasksTab_createdNewDriverTask: 'Nueva tarea añadida exitosamente',
    driversTasksTab_deleteConfirmation: '¿Estás seguro de que quieres eliminar la tarea de conductor ',
    driversTasksTab_delete: 'Eliminar',
    driversTasksTab_deleteConfirmationSuccessfully: 'Tarea de conductor eliminada exitosamente',
    driversTasksTab_updatedSuccessfully: 'Tarea de conductor actualizada exitosamente',
    driversTasksTab_hours: 'Horas',
    driversTasksTab_days: 'Días',
    driversTasksTab_months: 'Meses',
    driversTasksTab_add_button: 'Adicionar',
    driversTasksTab_cancel_button: 'Cancelar',
    driversTasksTab_save_button: 'Guardar',
};
