
const locales = {
    chat_usersConnected: 'Show Online Only',
    chat_newChat: "Select a user to start new chat",
    chat_lastMessage: "Last Message  ",
    MASTERUSER: 'Account Owner',
    SUBUSER: 'User',
    DRIVER: 'Driver'
};

export default locales;