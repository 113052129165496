import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';
// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

import {
  baseReportConditions,
  generateReportInstance,
  getCategoryFilter,
  getTimeZone,
  handleAsyncReport,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';
import {isFilterBaseView} from "Modules/reports/views/main/components/reportsTab/.components/reports/landmarkBasedReport/sagaFunction";

function* getLandmarksSummaryReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
  const { timeZone , sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath } = report;
  const { filterType, filterArray, startRange, endRange, unitsSelected, otherFilters, filterEntity } = report.filters
  const baseConditionsQuery = baseReportConditions({ report, name, dataResultPath, timeZone, delayed, unitsSelected, timeframeName , filterType, filterArray, categoryName, startRange, endRange})
  try {
    const valueEntityFilter = isFilterBaseView(otherFilters.landmarkBaseView) ? [{
      id: otherFilters.landmark,
      type: "Landmark"
    }] : {id: otherFilters.landmarkTags.id, type: "Tag"}

    let payload = {
      conditions: {
        ...baseConditionsQuery,
        filterValues: {
          field: isFilterBaseView(otherFilters.landmarkBaseView) ? "Landmark" : "Tag",
          entities: otherFilters.landmarkBaseView === "all"? null: valueEntityFilter
        },
        sortField: {
          field: sortField || "deviceId",
          order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
      },
      limit: rowsPerPage,
      offset: page
    };

    let addColumnDriver = report?.exportType === 'csv' ? [
      {
        column: "idLabel",
        header: getCategoryFilter(filterType) === "deviceId" ? "Unit" : "Driver"
      }
    ] : [];


    if (report.export) {
      payload = {
        ...payload,
        limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
        offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
        conditions: {
          ...payload.conditions,
          exportOptions: {
            sendByEmail,
            emailsTo,
            format: {
              fileFormat: report?.exportType  || "json",
              formatOptions: "{}",
              durationFormat: report?.durationFormat
            },
            culture: report.lang,
            timeZone: getTimeZone(timeZone),
            folderName: "px-customer-reports",
            filePrefixName: "LandmarkSummaryReport",
            columns: [
              ...addColumnDriver,
              {
                "column": "unitTime",
                "header": "Time & Date"
              },
              {
                "column": "groupIdLabel",
                "header": "Vehicle Tags"
              },
              {
                "column": "deviceLabel",
                "header": "Vehicle"
              },
              {
                "column": "landmarkName",
                "header": "Landmark"
              },
              {
                "column": "totalVisits",
                "header": "Total Visits"
              },
              {
                "column": "totalVisitsDuration",
                "header": "Total Visit Duration"
              },
              {
                "column": "engineHours",
                "header": "Engine Hours"
              }
            ]
          }
        }
      }
    }
    let data = yield call(
      clientQuery,
      `query getLandmarkSummaryReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getLandmarkSummaryReport (conditions: $conditions, limit: $limit, offset: $offset) {
          ${ generateReportInstance(report.export, dataResultPath, report.exportFromTable)?
            `reportInstanceId`
          :`
            items {
              groupId
              groupIdLabel
              id
              idLabel
              deviceId
              deviceLabel
              landmarkId
              landmarkName
              totalVisits
              totalVisitsDuration
              engineHours
              unitTime
              noData
          }
            total
            limit
            offset
            category
        `}
        }
        
      }`,
      payload ,
      endpoints.GRAPHQL_PX_REPORTING,
    );
    yield handleAsyncReport(report, data);

  } catch (exc) {
    ExceptionManager(exc, 'reports/getLandmarksSummaryReport', 'getLandmarkSummaryReport');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
    if (!requestFlag) {
      let flag = true
      yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
    } else {
      report.loading = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  }
}


export default getLandmarksSummaryReport;
