import React, { useRef, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';

//global components
import { Grid } from '@mui/material';

//local components
import Chip from 'Components/Chip';

import { useInfiniteScroll } from 'Core/hooks'

const ValuesContainer = (props) => {

    const { messages } = props.intl;

    const { value, dataShow, textField, extraOptionstextField, isCollapse } = props;

    const containerRef = useRef()

    const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems,containerRef);

    const [ optionsShown, setOptionsShown ] = useState(10)

    useEffect(()=>{
        setOptionsShown(dataShow)
    },[dataShow])

    function fetchMoreListItems() {

        if(!isCollapse &&  optionsShown < value.length ){
            setTimeout(() => {
                setIsFetching(false);
                setOptionsShown(+optionsShown+10)
            }, 1000);
        }else{
            setIsFetching(false);
        }    
    }
    
    return(
        <div className="select-menu-file-container" ref={containerRef}>
            <Grid container spacing={1} direction="column">
            {value.slice(0, optionsShown).map((val, index) => {
                let label = val[textField];
                let opts = [];
                if (val.options) {
                    opts = val.options.map((option) => option[extraOptionstextField]);
                }

                return (
                    <Grid key={`${Math.floor(Math.random() * 100000)}-`+index}>
                        <Chip
                            label={`${label}${opts.length > 0 && ":" || ""} ${opts.join(', ')}`}
                            handleDelete={() => {
                               props.handleDelete(val)
                            }}
                        />
                    </Grid>
                );
            })}
            </Grid>
            {(isFetching && !isCollapse) && messages["fetching_more_list_items"]}
        </div>
    );
}


export default injectIntl(ValuesContainer);