import React from 'react';
// Material UI
import { Grid, Typography } from '@mui/material';
// Styles
import '../infoBubble.scss';
import '../../../../../components/Map/providers/google/InfoWindow/style.scss'

const SearchMapInfo = (props) => {
  const { data, messages } = props;

  return (
    <div className='info-window-tab'>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          padding: '16px 45px 16px 16px'
        }}
      >
        <Grid item xs zeroMinWidth>
          <Typography noWrap style={{ fontWeight: 'bold' }}>
            {data?.label}
          </Typography>
        </Grid>
      </Grid>

      <div style={{ borderTop: 'solid 1px #e4e4e4' }}></div>

      <div>
        <table className="info-bubble-table">
          <tbody>
            <tr>
              <td>
                {messages['infoBubble_address']}
              </td>
              <td>
                {data?.address || "N/A"}
              </td>
            </tr>

            <tr>
              <td>
                {messages['infoBubble_latLng']}
              </td>
              <td>
                {(data?.latitude && data?.longitude ? `${data?.latitude?.toFixed(6)}, ${data?.longitude?.toFixed(6)}` : "N/A")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SearchMapInfo;