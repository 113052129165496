import geofenceList from 'Modules/realtimeMaps/main/components/geofences/components/geofenceList/lan/es';
import geofenceCreateEdit from 'Modules/realtimeMaps/main/components/geofences/components/geofenceCreateEdit/lan/es';
import geofenceGroupCreateEdit from 'Modules/realtimeMaps/main/components/geofences/components/geofenceGroupCreateEdit/lan/es';
import geofenceSettings from 'Modules/realtimeMaps/main/components/geofences/components/geofenceSetting/lan/es';
import geofenceSettingsViewGeo from 'Modules/realtimeMaps/main/components/geofences/components/geofenceSettingsViewGeo/lan/es';
import geofenceDelete from 'Modules/realtimeMaps/main/components/geofences/components/geofenceDelete/lan/es';
import geofenceGroupDelete from 'Modules/realtimeMaps/main/components/geofences/components/geofenceGroupDelete/lan/es';
import geofenceSettingsViewUnits from 'Modules/realtimeMaps/main/components/geofences/components/geofenceSettingsViewUnits/lan/es';
import geofenceContacts from 'Modules/realtimeMaps/main/components/geofences/components/geofenceContacts/lan/es';
import geofenceAssign from 'Modules/realtimeMaps/main/components/geofences/components/geofenceAssign/lan/es';
import geofenceBulk from 'Modules/realtimeMaps/main/components/geofences/components/geofenceBulk/lan/es';
import geofenceVisibleTags from 'Modules/realtimeMaps/main/components/geofences/components/geofenceVisibleTags/lan/es';

export default {
    ...geofenceList,
    ...geofenceCreateEdit,
    ...geofenceGroupCreateEdit,
    ...geofenceSettings,
    ...geofenceSettingsViewGeo,
    ...geofenceDelete,
    ...geofenceGroupDelete,
    ...geofenceSettingsViewUnits,
    ...geofenceContacts,
    ...geofenceAssign,
    ...geofenceBulk,
    ...geofenceVisibleTags
};