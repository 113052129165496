import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import Notification from './Notification';

class Notifications extends React.Component {
	static propTypes = {
		notifications: PropTypes.array.isRequired,
		onRequestHide: PropTypes.func,
		enterTimeout: PropTypes.number,
		leaveTimeout: PropTypes.number,
	};

	static defaultProps = {
		notifications: [],
		onRequestHide: () => {},
		enterTimeout: 400,
		leaveTimeout: 400,
	};

	handleRequestHide = (notification) => () => {
		const { onRequestHide } = this.props;
		if (onRequestHide) {
			onRequestHide(notification);
		}
	};

	render() {
		const { messages, notifications, enterTimeout } = this.props;
		const className = classnames('notification-container', {
			'notification-container-empty': notifications.length === 0,
		});
		return (
			<div className={className}>
				<CSSTransition
					className="notification"
					timeout={enterTimeout}
					//transitionLeaveTimeout={leaveTimeout}
				>
					<Fragment>
						{notifications.map((notification) => {
							const key = notification.id || new Date().getTime();
							return (
								<Notification
									key={key}
									type={notification.type}
									title={notification.title}
									message={notification.message}
									timeOut={notification.timeOut}
									onClick={notification.onClick}
									code={notification.code}
                  codeTitle={notification.codeTitle}
									onRequestHide={this.handleRequestHide(notification)}
									customClassName={notification.customClassName}
									messages={messages}
								/>
							);
						})}
					</Fragment>
				</CSSTransition>
			</div>
		);
	}
}

export default Notifications;
