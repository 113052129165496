import {
	USER_ALERTS_GET_ALERTS,
	USER_ALERTS_GET_ALERTS_RESPONSE
} from 'Redux/actionTypes';


export const searchSystemAlertLogs = (data) => ({
	type: USER_ALERTS_GET_ALERTS,
	payload: { data },
});

export const searchSystemAlertLogsResponse = (data) => ({
	type: USER_ALERTS_GET_ALERTS_RESPONSE,
	payload: data,
});