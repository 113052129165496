//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientGraphQLAuthMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
// Action types
import { FORCE_CHANGE_PASSWORD } from 'Redux/actionTypes';
// Actions
import { login, forceChangePasswordResult } from 'Redux/actions';

/* FUNTIONS ASYNC */
function* forceChangePassword({ payload }) {
	try {
		const { username, password, session, navigate } = payload;
		let values = {
			username, newPassword: password, session
		}

		let data = yield call(
			clientGraphQLAuthMutation,
			`
				mutation forceChangePassword ($username: String!, $newPassword: String!, $session: String!) {
					forceChangePassword (username: $username, newPassword: $newPassword, session: $session) {
						authenticationResult {
							AccessToken
							ExpiresIn
							RefreshToken
							IdToken
							ChallengeName
							Session
						}
						emailVerified
						username
					}
				}
			`,
			values
		);
		if (data && data.forceChangePassword) {
			yield put(forceChangePasswordResult({ result: true, error: false }));
			yield put(login(data.forceChangePassword.username, password, navigate));
		} else {
			yield put(forceChangePasswordResult({ result: false, error: data.errorMessage }));
		}
	} catch (exc) {
		ExceptionManager(exc, 'Core/views/forgotPasswordRequest/redux', 'forgotPasswordRequest');
		yield put(forceChangePasswordResult({ result: false, error: exc.toString() }));
	}
}

function* watchMethods() {
	yield takeEvery(FORCE_CHANGE_PASSWORD, forceChangePassword);
}

export default function* rootSaga() {
	yield all([fork(watchMethods)]);
}