export default {
    settingsDriverStatusLog_title: "Tarjeta de tiempo del conductor",
    settingsDriverStatusLog_timeFrame: "Periodo de tiempo",
    settingsDriverStatusLog_driver: "Conductor",
    settingsDriverStatusLog_date: "Fecha",
    settingsDriverStatusLog_dailyTotal: "Total por día",
    settingsDriverStatusLog_day: "dia",
    settingsDriverStatusLog_hour: "hora",
    settingsDriverStatusLog_minute: "minuto",
    settingsDriverStatusLog_second: "segundo",
};