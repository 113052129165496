export default {
    realtimeMapsRoutes_uploadRoutes_upload: "Cargar Rutas",
    realtimeMapsRoutes_uploadRoutes_name: "Nombre",
    realtimeMapsRoutes_uploadRoutes_attachmentsFilesAllowed: 'csv. 25 Paradas Máximo, 4MB',
    realtimeMapsRoutes_uploadRoutes_latitude: 'Latitud',
    realtimeMapsRoutes_uploadRoutes_longitude: 'Longitud',
    realtimeMapsRoutes_uploadRoutes_address: 'Dirección',
    realtimeMapsRoutes_uploadRoutes_city: 'Ciudad',
    realtimeMapsRoutes_uploadRoutes_state: 'Estado',
    realtimeMapsRoutes_uploadRoutes_zip: 'Código Postal',
    realtimeMapsRoutes_uploadRoutes_note: 'Notas',
    realtimeMapsRoutes_uploadRoutes_cancel: "Cancelar",
    realtimeMapsRoutes_uploadRoutes_upload_route: "Cargar",
    realtimeMapsRoutes_uploadRoutes_template: 'Descargar Plantilla',
}