import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';
// Actions
import {
  reportsMainGenerateReportProgress,
  reportsMainGenerateReportUpdate
} from 'Redux/actions';

import {
  getTimeZone,
  getCategoryFilter,
  handleAsyncReport,
  baseReportConditions,
  generateReportInstance,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';

import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

function* getIdleReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
  const { timeZone , sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath } = report;
  const { filterType,otherFilters, filterArray, startRange, endRange, unitsSelected, accumulateData = true, dates } = report.filters;

  let { checkIdleProductive } = otherFilters;
  let minutes = otherFilters.minute ? otherFilters.minute * 60 : 0;

  const baseConditionsQuery = baseReportConditions({ 
    report,
    name,
    dataResultPath,
    timeZone,
    delayed,
    unitsSelected,
    timeframeName,
    filterType,
    filterArray,
    categoryName,
    startRange,
    endRange,
    dates
  })
  try {
    let payload = {
      conditions: {
        ...baseConditionsQuery,
        integerRanges: {
          fields: [
            "idle"
          ],
          GTE: minutes || 0,
          LTE: 0
        },
        filterValues: {
          field: "checkIdleProductive",
          booleanValue: checkIdleProductive
        },
        sortField: {
          field: sortField || "deviceId",
          order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
      },
      limit: rowsPerPage,
      offset: page
    };
    
    if (report.export) {
    payload = {
        ...payload,
        limit: (!isJsonFormat(report?.exportType, dataResultPath) || !dataResultPath)? 5000 : rowsPerPage,
        offset: isJsonFormat(report?.exportType, dataResultPath)? page : 1,
        conditions: {
          ...payload.conditions,
          exportOptions: {
            sendByEmail,
            emailsTo,
            format: {
              fileFormat: report?.exportType || "json",
              formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
              durationFormat: report?.durationFormat
          },
            culture: report.lang,
            timeZone: getTimeZone(timeZone),
            folderName: "px-customer-reports",
            filePrefixName: "idleReport",
            columns: [
              {
                "column": "timeAndDate",
                header: `${report?.exportType === "csv" ? `Time & Date (${getAbbvTimezone(timeZone)})` : `Time & Date`}`
              },
              {
                "column": getCategoryFilter(filterType) === "deviceId" ? "driverName" : "deviceLabel",
                "header": getCategoryFilter(filterType) === "deviceId" ? "Driver" : "Unit"
              },
              {
                "column": "addressFormatted",
                "header": "Address"
              },
              {
                "column": "eventName",
                "header": "Event" 
              },
              {
                "column": "idleTimeFormatted",
                "header": "Idle Time"
              }
            ]
          }
        }
    }
    }

    let data = yield call(
      clientQuery,
      `query($conditions: ReportConditionsInputRequired, $limit: Int, $offset: Int) {
        Report: getIdleReport(conditions: $conditions, limit: $limit, offset: $offset) {
          ${ generateReportInstance(report.export, dataResultPath, report.exportFromTable)?
            `reportInstanceId`
          :`
            items {
              recordInfo {
                entityId
                entityLabel
                tagId
                tagLabel
                startDate
                endDate
                recordType
                groupLabel
                groupIndex
                groupTotal
                groupTagColor
              }
              recordDetail {
                startUnittime
                endUnittime
                unitId
                driverId
                deviceId
                idleTime
                address
                city
                state
                country
                landmarkId
                dayGroup 
                driverName
                deviceLabel 
                landmarkName 
                eventName
                date
                unitTime
                idleTimeTotal
                PTOIdleTime
                noData
              }
            }
            total
            limit
            offset
            category
        `}
        }
        
      }`,
      payload ,
      endpoints.GRAPHQL_PX_REPORTING,
    );
    yield handleAsyncReport(report, data, accumulateData);

  } catch (exc) {
    ExceptionManager(exc, 'reports/idleReport', 'getIdleReport');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
    if (!requestFlag) {
      let flag = true
      yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
    } else {
      report.loading = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  }
}


export default getIdleReport;
