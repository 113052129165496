import {
  REPORTS_HANDLE_MODAL_REPORT_TYPE,
  REPORTS_HANDLE_MODAL_SELECT_TYPE,
  REPORTS_HANDLE_MODAL_SELECT_REPORT,
  CLEAR_REDUX
} from "Redux/actionTypes";

//STATE INITIAL
const INIT_STATE = {
  open: false,
  loading: false,
  reportType: 'basic',
  reportSelected: null,
};

export default (state = INIT_STATE, action) => {
  let reportIndex
  switch (action.type) {
    case REPORTS_HANDLE_MODAL_REPORT_TYPE:
      return { ...state, open: action.payload.open, reportType: "basic", reportSelected: null };
    case REPORTS_HANDLE_MODAL_SELECT_TYPE:
      return { ...state, reportType: action.payload.reportType, reportSelected: null };
    case  REPORTS_HANDLE_MODAL_SELECT_REPORT:
      return { ...state, reportSelected: action.payload.reportSelected };
    case CLEAR_REDUX:
      return ["", "REPORTS_MODAL_EXTRA_TIME"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
};
