import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LayersIcon from '@mui/icons-material/Layers';
import { Tooltip } from 'Components';
import { MapUtilitiesTypes } from 'Modules/realtimeMaps/main/components/MapUtilitiesMenu/enums';

/*********************************************************
 * Actions
*********************************************************/
import { selectModuleMapSettings, setShowMapSettings } from "Redux/actions";
import { useIntl } from 'react-intl';

const DrawLayerMap = (props) => {
  
  const dispatch = useDispatch();
  const [color,setColor] = useState('#2b333f');
  const {messages} = useIntl()
  const { showMapSettings, currentModule } = useSelector(state => state.realtimeMapUtilitiesRedux)

  const handleModule = () => {
    if (currentModule !== MapUtilitiesTypes.LayerMap && showMapSettings) {
      dispatch(selectModuleMapSettings(MapUtilitiesTypes.LayerMap));
    } else dispatch(selectModuleMapSettings(MapUtilitiesTypes.Default));
    if (!showMapSettings) {
      dispatch(selectModuleMapSettings(MapUtilitiesTypes.LayerMap));
      dispatch(setShowMapSettings(true));
    }
  }

  useEffect(() => {
    if (currentModule === MapUtilitiesTypes.LayerMap && showMapSettings) {
      setColor('#00AEEF')
    }else{
      setColor('#2b333f')
    }
	}, [currentModule,showMapSettings]);

  return (
    <Fragment>
      <Tooltip placement="left" title={messages['layerControl']}>
        <button
          onClick={() => handleModule()}>
          <LayersIcon style={{ color: color}} ></LayersIcon>
        </button>
      </Tooltip>
    </Fragment>
  )
}

export default DrawLayerMap;