import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../core/defaultValues';
import { resetALL } from '../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    liveIncidentLoading: true,
    liveIncident: {},
    liveIncidentError: null
};

const route = 'core/views/liveIncident/redux/slice'

export const getLiveIncident = createAsyncThunk('liveIncident/get', async ({ code }, { rejectWithValue, dispatch }) => {
    try {
        const result = await 
            clientQuery(
                `query getIncidentByShortCode ($code: String!) {
                    
                getIncidentByShortCode (code: $code)
                {
                    id
                    unitId
                    name
                    reading{
                        unitTime
                        deviceLabel
                        latitude
                        longitude
                    }
                }
          }`,
            {
                code
            },
            endpoints.GRAPHQL_GENERAL_V2,
            {
				"x-api-key": process.env.COGNITO_API_KEY_V2
            }
        )



        return result?.getIncidentByShortCode || { };
    } catch (exc) {
        return exc?.error
    }
});

//Slice
const LiveIncidentRedux = createSlice({
    name: 'liveIncident',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        builder.addCase(getLiveIncident.pending, (state, action) => {
            state.liveIncidentLoading = true
            state.liveIncident = null
            state.liveIncidentError = null
        });
        builder.addCase(getLiveIncident.fulfilled, (state, action) => {
            state.liveIncidentLoading = false
            if(action.payload?.id){
                state.liveIncidentError = null
                state.liveIncident = action.payload 
            }else{
                state.liveIncidentError = true
                state.liveIncident = null
            }
        });
        builder.addCase(getLiveIncident.rejected, (state, action) => {
            state.liveIncidentLoading = false;
            state.liveIncidentError = action.payload;
            state.liveIncident = {}
        });

    },
})

export const { actions, reducer } = LiveIncidentRedux;
// Extract and export each action creator by name
export const { reset } = actions;
export default reducer;