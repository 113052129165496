//Dependencies
import React from 'react';
import GestureIcon from '@mui/icons-material/Gesture';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'Components';
//Utils
import { TypeObjectMap } from 'Components/Map/providers/enums'
import { MapUtilitiesTypes } from 'Modules/realtimeMaps/main/components/MapUtilitiesMenu/enums';

//Actions
import { selectModuleMapSettings, setListUnits, setShowMapSettings } from "Redux/actions";

const DrawPolygonMap = (props) => {
    const dispatch = useDispatch()
    const { mapRef } = props
    const {messages} = props


    const { currentModule, showMapSettings } = useSelector(state => state.realtimeMapUtilitiesRedux);

    const handleModule = (e) => {
        e.preventDefault();
        if (!showMapSettings) dispatch(setShowMapSettings(true));
        if (currentModule === MapUtilitiesTypes.UnitSelection) {
            mapRef.current.removeMapObject('drawn_polygon', TypeObjectMap.Polygon);
            dispatch(selectModuleMapSettings(MapUtilitiesTypes.Default));
            dispatch(setListUnits([], false));
            mapRef?.current?.removeMapDraw();
        } else {
            dispatch(selectModuleMapSettings(MapUtilitiesTypes.UnitSelection));
            mapRef?.current?.drawPolygon();
        }
    }

    return (
        <>
            <Tooltip placement="left" title={messages["draw"]}>
                <button
                    onClick={(e) => handleModule(e)}>
                    <GestureIcon style={{ color: currentModule === MapUtilitiesTypes.UnitSelection ? '#00AEEF' : '#2b333f' }}></GestureIcon>
                </button>
            </Tooltip>
        </>
    )
}

export default DrawPolygonMap;