// Action Types
import {
  LOGIN_HISTORY_SEARCH,
  LOGIN_HISTORY_SEARCH_RESPONSE,
  LOGIN_HISTORY_FILTER_SEARCH_RESPONSE,
  LOGIN_HISTORY_SHOW_FILTER_SIDEPANEL,
  CLEAR_REDUX,
} from "Redux/actionTypes";

import { getInitialRange } from "Modules/video/.globals/helpers";
/**
 * Initial State for Clip Request Tab.
 */
const INITIAL_STATE = {
  loading: false,
  nextToken: null,
  error: false,
  showFilterSidePanel: false,
  allfilters: {
    openSearch: "",
    dateRange: getInitialRange(),
    username: "",
  },
  registers: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_HISTORY_SEARCH:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case LOGIN_HISTORY_SEARCH_RESPONSE:
      const { items, resetSearch, nextToken, error } = action.payload;
      return {
        ...state,
        registers: resetSearch ? items : [...state.registers, ...items],
        nextToken,
        error,
        loading: false,
      };
    case LOGIN_HISTORY_FILTER_SEARCH_RESPONSE: {
      return {
        ...state,
        allfilters: action.payload,
      };
    }
    case LOGIN_HISTORY_SHOW_FILTER_SIDEPANEL:
      return {
        ...state,
        showFilterSidePanel: !state.showFilterSidePanel,
      };
    case CLEAR_REDUX:
      return ["", "LOGIN_HISTORY"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INITIAL_STATE }
        : { ...state };
    default:
      return state;
  }
};
