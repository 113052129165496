import React, { useState, useEffect, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Input from 'Components/Input';
import cx from 'classnames';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import './index.scss';
import { TimeInput } from './../';
import { useTimePicker, useDetectOutsideClick } from 'Core/hooks';
import { GetFormatForMoment } from 'Core/data/Helpers';
import Modal, { ModalBody, ModalFooter } from 'Components/Modal';
import Button from 'Components/Button';
import { useIntl } from "react-intl";
import InputDate from 'Components/InputDate';
import { useInput } from 'Core/hooks';

const CustomDatePicker = ({ withTime, onChange, value, valueEnd, isVertical, maxDate, minDate, onClose, classNameDate, style, showModal, titleCalendar, messageClose, handleCloseModal, timeSelector, noHeaderSelector }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  // const [initialLoad, setInitialLoad] = useState(true);
  const [valueTxt, setValueTxt] = useState('');
  const startDateTime = useInput('', '', '');
  const endDateTime = useInput('', '', '');
  const [selectStartInTheSecondMonth, setSelectStartInTheSecondMonth] = useState(false);
  const timePickerStart = useTimePicker();
  const timePickerEnd = useTimePicker();

  const wrapperRef = useRef(null);
  const detectClick = useDetectOutsideClick(wrapperRef);
  const { locale } = useIntl()

  useEffect(() => {
    // Date picker modal time with select
    const currentDateStart = new Date().setHours(0, 0, 0)
    startDateTime.onChange(currentDateStart)
    const currentDateEnd = new Date().setHours(23, 59, 59)
    endDateTime.onChange(currentDateEnd)
  }, [])

  useEffect(() => {
    if (!detectClick.clickInside) {
      setShowDatePicker(false)
      if (onClose) {
        onClose();
      }
    }
  }, [detectClick.clickInside]);

  useEffect(() => {
    // If change YYYY-MM-DD
    if (value || valueEnd) {
      setValueInputText(value, valueEnd);
    } else {
      setValueTxt('')
    }
  }, [value?.toISOString(), valueEnd?.toISOString()]);

  const getTime = (time) => {
    let timeStartMoment = moment(time, 'hh:mm A');
    return timeStartMoment.format('hh:mm A');
  }

  const setValueInputText = (dateStart, dateEnd) => {
    const momentStart = moment(dateStart);
    const momentEnd = moment(dateEnd);

    if (momentStart.isValid() || momentEnd.isValid()) {
      // when the values are updated in the parent, is updated the local state for time
      timePickerStart.setHour(momentStart.format('hh'), momentStart.format('mm').toString(), momentStart.format('ss').toString(), momentStart.format('A'));
      timePickerEnd.setHour(momentEnd.format('hh'), momentEnd.format('mm').toString(), momentStart.format('ss').toString(), momentEnd.format('A'));
      // Format to show in the text input for date
      let format = getFormatByLang();
      const txt = momentStart.isValid() && `${momentStart.format(format)} - ${momentEnd.isValid() && momentEnd.format(format) || ''}` || '';
      setValueTxt(txt);
    }
  }
  // return the format to display the date according with the user language
  const getFormatByLang = () => {
    let format = 'MMM DD, yyyy';
    if (locale == 'es') {
      format = 'DD/MM/YYYY';
    }
    if (withTime) {
      format = `${format} hh:mm A`;
    }
    return format;
  }

  const getFormatedTime = (date, time) => {
    const momentFormat = GetFormatForMoment('m/d/Y');
    let newStart = moment(date).format(momentFormat);
    newStart = `${newStart} ${getTime(time)}`;
    return new Date(newStart);
  }

  const copyMaxDate = () => {
    // Duplicate Date to avoid problems with references
    const newMaxDate = moment(JSON.parse(JSON.stringify(maxDate)));
    return newMaxDate;
  }

  const combineDateWithTime = (start, end, valueTime, typeTime) => {
    let newStart = null;
    let newEnd = null;
    if (start) {
      let newTimeStart = typeTime != 'end' ? valueTime : value;
      // If the time input change so set the new time 
      // else use the old one
      const time = typeTime && newTimeStart || timePickerStart.getCompleteHour();
      newStart = getFormatedTime(start, time);
    }
    if (end) {
      let newTimeEnd = typeTime != 'start' ? valueTime : valueEnd;
      const time = typeTime && newTimeEnd || timePickerEnd.getCompleteHour('23', '59', '59');
      newEnd = getFormatedTime(end, time);
    }
    return {
      start: start && newStart,
      end: end && newEnd
    }
  }

  const handleChange = (dates, selectedFirst) => {
    const [start, end] = dates;
    if (!end) {
      setSelectStartInTheSecondMonth(selectedFirst);
    } else {
      if (!showModal) {
        setShowDatePicker(false);
      }
    }
    if (withTime) {
      const combineDateTime = combineDateWithTime(start, end);
      onChange({
        ...combineDateTime
      })
    } else {
      onChange({
        start: start && new Date(start),
        end: end && new Date(end)
      })
    }
  };

  const getMinDate = (date) => {
    let momentDate = moment(date);
    if (selectStartInTheSecondMonth) {
      momentDate = momentDate.startOf('month');
    } else {
      momentDate = momentDate.add(1, 'M').startOf('month');
    }
    return new Date(momentDate);
  }

  const getMaxDate = (date) => {
    let momentDate = moment(date);
    momentDate = momentDate.subtract(1, 'M').endOf('month');
    return new Date(momentDate);
  }

  const startDateAndEndDateAreInTheSameMonth = () => {
    const momentStartDate = moment(value);
    const momentEndDate = moment(valueEnd);
    if (momentStartDate.month() === momentEndDate.month()) {
      return true;
    }
    return false;
  }

  const startDateAndEndDateAreInTheCurrentMonth = () => {
    const momentStartDate = moment(value);
    const momentEndDate = moment(valueEnd);
    const currentMonth = moment();
    if (
      momentStartDate.format('M') === currentMonth.format('M') &&
      momentEndDate.format('M') === currentMonth.format('M')
    ) {
      return true;
    } else {
      return false;
    }
  }

  let limitSecondMonth = null;
  let limitFirstMonth = null;
  const shouldStartInSecondMonth = () => {
    // This method is used to verify maxDate exits and the startDate and endDate are in the same Month
    return maxDate && startDateAndEndDateAreInTheSameMonth() && startDateAndEndDateAreInTheCurrentMonth();
  }

  if (shouldStartInSecondMonth()) {
    const newLimitFirstMonth = copyMaxDate();
    limitFirstMonth = new Date(newLimitFirstMonth.subtract(1, 'M').endOf('month'));
    const newLimitSecondMonth = copyMaxDate();
    limitSecondMonth = new Date(newLimitSecondMonth.startOf('month'));
  } else if (value && !valueEnd || (valueEnd && !selectStartInTheSecondMonth && startDateAndEndDateAreInTheSameMonth())) {
    limitSecondMonth = getMinDate(value);
    limitFirstMonth = new Date(moment(limitSecondMonth).subtract(1, 'd'));
  } else if (value && valueEnd) {
    limitSecondMonth = getMinDate(value);
    limitFirstMonth = getMaxDate(valueEnd);
  }

  const closeModal = () => {
    setShowDatePicker(false)
    const inputCalendar = document.getElementById("txtCustomDatePicker");
    if (inputCalendar?.focus) {
      // this is necessary since the date is out of order when the modal is closed
      inputCalendar.focus = false;
    }
    if (handleCloseModal) {
      if (timeSelector) {
        handleCloseModal(endDateTime?.value)
      } else {
        handleCloseModal()
      }

    }
  }

  const renderDatePicker = () => {
    return (
      <Grid
        container
        className={cx('container', 'container-time-picker', timeSelector && !noHeaderSelector && 'time-picker-not-border-top', classNameDate)}
        spacing={0}
        style={{
          width: `${isVertical ? '263px' : '540px'}`,
          left: `${showModal ? 'auto' : isVertical ? '0px' : '-70px'}`,
          display: showDatePicker ? 'flex' : 'none',
          marginBottom: showDatePicker && !showModal && '60px',
          position: `${showModal && 'relative'}`,
          ...style
        }}
        direction={`${(isVertical && showDatePicker) ? 'column' : 'row'}`}
      >
        <Grid item sm={isVertical ? 12 : 6} className={cx(`${isVertical ? 'marginTopCtn' : ''}`, showModal && 'margin-top-modal')}>
          <DatePicker
            disabledKeyboardNavigation
            showDisabledMonthNavigation
            selected={shouldStartInSecondMonth() ? null : value}
            onChange={(dates) => {
              handleChange(dates, false);
            }}
            startDate={shouldStartInSecondMonth() ? null : value}
            endDate={shouldStartInSecondMonth() ? null : valueEnd}
            selectsRange
            inline
            minDate={minDate || undefined}
            maxDate={limitFirstMonth}
            locale={locale}
          />
          {withTime && (
            timeSelector ? (
              <div className="date-picker-container-time">
                <InputDate
                  {...startDateTime}
                  onChange={(valueT) => {
                    const valueTime = moment(valueT, 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A')
                    startDateTime.onChange(valueT);
                    const combineDateTime = combineDateWithTime(value, valueEnd, valueTime, 'start');
                    onChange({
                      ...combineDateTime
                    })
                  }}
                  type={'time'}
                  id={'date-picker-input-date-start'}
                  onClose={() => { }}
                  required
                  timeIntervals={1}
                  popperPlacement={'top-start'}
                  popperModifiers={
                    [
                      {
                        name: 'flip',
                        options: {
                          fallbackPlacements: ['top-start'],
                        },
                      },
                      {
                        name: 'preventOverflow',
                        enabled: false
                      }
                    ]
                  }
                />
              </div>
            ) : (
              <TimeInput
                {...timePickerStart}
                onChange={(valueTime) => {
                  const combineDateTime = combineDateWithTime(value, valueEnd, valueTime, 'start');
                  onChange({
                    ...combineDateTime
                  })
                }}
                paddingRight={!isVertical}
              />
            )
          )}
        </Grid>
        <Grid item sm={isVertical ? 12 : 6} className={`${isVertical ? 'marginTopCtn' : ''}`}>
          <DatePicker
            disabledKeyboardNavigation
            showDisabledMonthNavigation
            onChange={(dates) => {
              handleChange(dates, true);
            }}
            selected={startDateAndEndDateAreInTheSameMonth() ? null : valueEnd}
            startDate={startDateAndEndDateAreInTheSameMonth() && value < limitSecondMonth ? null : value}
            endDate={startDateAndEndDateAreInTheSameMonth() && valueEnd < limitSecondMonth ? null : valueEnd}
            selectsRange
            inline
            minDate={limitSecondMonth}
            maxDate={maxDate || null}
            locale={locale}
          />
          {withTime && (
            timeSelector ? (
              <div className="date-picker-container-time">
                <InputDate
                  {...endDateTime}
                  onChange={(valueT) => {
                    const valueTime = moment(valueT, 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A')
                    endDateTime.onChange(valueT);
                    const combineDateTime = combineDateWithTime(value, valueEnd, valueTime, 'end');
                    onChange({
                      ...combineDateTime
                    })
                  }}
                  type={'time'}
                  id={'date-picker-input-date'}
                  className={'date-picker-input-date-end'}
                  onClose={() => { }}
                  timeIntervals={1}
                  required
                  popperPlacement={'top-start'}
                  popperModifiers={
                    [
                      {
                        name: 'flip',
                        options: {
                          fallbackPlacements: ['top-start'],
                        }
                      },
                      {
                        name: 'preventOverflow',
                        enabled: false
                      }
                    ]

                  }
                />
              </div>
            ) : (
              <TimeInput
                {...timePickerEnd}
                onChange={(valueTime) => {
                  const combineDateTime = combineDateWithTime(value, valueEnd, valueTime, 'end');
                  onChange({
                    ...combineDateTime
                  })
                }}
                paddingRight={!isVertical}
              />
            )
          )}
        </Grid>
      </Grid>
    )
  }

  return (
    <div ref={wrapperRef}>
      <Input
        id="txtCustomDatePicker"
        type="text"
        label={''}
        placeholder={''}
        value={valueTxt}
        onClick={() => {
          setShowDatePicker(show => !show);
        }}
        onClickIcon={() => {
          setShowDatePicker(show => !show);
        }}
        onChange={() => {
        }}
        icon={'today'}
        className="inputDatePicker"
        classNameIconButton="icon-input-date-picker"
        readOnly
      />

      {
        showModal ?
          <Modal
            title={titleCalendar || 'Calendar'}
            open={showDatePicker}
            className={'date-picker-modal'}
            handleClose={closeModal}
            size="medium"
            headerStyle={{ minWidth: '541px' }}
          >
            <ModalBody>
              {renderDatePicker()}
            </ModalBody>
            <ModalFooter style={{
              textAlign: 'right',
              float: 'right',
            }}
            >
              <Button
                id={'btn-cancel-calendar'}
                type="button"
                onClick={closeModal}
                className="btn-cancel"
              >
                {messageClose || 'Close'}
              </Button>
            </ModalFooter>
          </Modal>
          : renderDatePicker()
      }
    </div>
  )
};

export default CustomDatePicker;