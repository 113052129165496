import React from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'Components';
import { LinearProgress } from '@mui/material';
import moment from 'moment';
import { round } from 'Core/data/Helpers';
import { Pill } from '@gpstrackit/gtc-react-controls';
import styles from 'Assets/sass/core/vars.scss';
import {getOdometerValue} from 'Modules/.common/components/OdometerView';
export const setContactId = (contacts) => {
  let newDate = contacts.map(contact => {
    return { ...contact, contactId: contact.id }
  });
  return newDate;
}

export const truncateString = (str, num) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str?.length <= num) {
    return str
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str?.slice(0, num) + '...'
}

export const getMaintenanceValue = (type, startValue, endValue, currentValue) => {
  let percentage = 0;

  if (!type || !startValue || !endValue || !currentValue) return percentage;

  switch (type) {
    case "time":
      let daysStart = moment.utc(startValue?.toString())
      let daysEnd = moment.utc(endValue?.toString())
      let daysNow = moment.utc(currentValue) || moment().utc()

      const diff = moment.duration(daysEnd.diff(daysStart));
      const diffNow = moment.duration(daysNow.diff(daysStart));

      percentage = (diffNow.asMinutes() / diff.asMinutes()) * 100
      if (percentage < 0) percentage = 0
      break;

    case "hours":
      break;

    case "odometer":
      let totalOdometer = endValue - startValue
      //fix value
      currentValue = currentValue - startValue;
      //calculate
      percentage = (currentValue / totalOdometer) * 100;
      if (percentage < 0) percentage = 0
      break;

    case "engineHours":
      let totalEngineH = endValue - startValue;
      //fix value
      currentValue = currentValue - startValue;
      //calculate
      percentage = (currentValue / totalEngineH) * 100;

      if (percentage < 0) percentage = 0
      break;
  }

  return Math.round(percentage)
}

export const getMaintenanceBar = (type, status, startValue, endValue, currentValue, messages, label,vehicle,user, measure = "") => {
  let title = ""
  let percentage = 0;
  let color = '';
  if (status === 'PastDue') {
    color = 'purple-linear-progress';
  } else if (status === 'Due') {
    color = 'red-linear-progress';
  } else if (status === 'DueNow') {
    color = 'orange-linear-progress';
  } else if (status === 'DueSoon') {
    color = 'orange-linear-progress';
  } else if (status === 'Scheduled') {
    color = 'green-linear-progress';
  }

  if (!startValue || !endValue || !currentValue) return "";

  switch (type) {
    case "time":
      let daysStart = moment.utc(startValue?.toString())
      let daysEnd = moment.utc(endValue?.toString())
      let daysNow = moment.utc(currentValue) || moment().utc()

      const diff = moment.duration(daysEnd.diff(daysStart));
      const diffNow = moment.duration(daysNow.diff(daysStart));

      percentage = (diffNow.asMinutes() / (diff.asMinutes() || 1)) * 100
      if (percentage < 0) percentage = 0

      let currentTime = ""
      if (diffNow.asMonths() >= 1) currentTime = `${round(diffNow.asMonths(), 1)}  ${messages['vehicleDetailView_months']}`
      else if (diffNow.asDays() >= 1) currentTime = `${round(diffNow.asDays(), 1)}  ${messages['vehicleDetailView_days']}`
      else if (diffNow.asHours() >= 1) currentTime = `${round(diffNow.asHours(), 1)}  ${messages['vehicleDetailView_hours']}`
      else if (diffNow.asMinutes() >= 1) currentTime = `${round(diffNow.asMinutes(), 1)}  ${messages['vehicleDetailView_minutes']}`
      let totalTime = ""
      if (diff.asMonths() >= 1) totalTime = `${round(diff.asMonths(), 1)}  ${messages['vehicleDetailView_months']}`
      else if (diff.asDays() >= 1) totalTime = `${round(diff.asDays(), 1)}  ${messages['vehicleDetailView_days']}`
      else if (diff.asHours() >= 1) totalTime = `${round(diff.asHours(), 1)}  ${messages['vehicleDetailView_hours']}`
      else if (diff.asMinutes() >= 1) totalTime = `${round(diff.asMinutes(), 1)}  ${messages['vehicleDetailView_minutes']}`

      //extra validation to prevent wrong progrees bar with wrong data
      //if( isFinite(percentage) && !isNaN(percentage) && isFinite(currentTime.replace(/[^0-9]/g,'')) && !isNaN(currentTime.replace(/[^0-9]/g,''))
      // && isFinite(totalTime.replace(/[^0-9]/g,'')) && !isNaN(totalTime.replace(/[^0-9]/g,'')) )
      //{
      //title = `(${Math.round(percentage)}%) ${ currentTime || 0 }/${totalTime}`;
      //}

      //Removing percentage
      title = `${currentTime || 0}/${totalTime}${measure}`;
      let currentDay = round(diffNow.asDays(), 1)
      if(currentDay > 0 &&  vehicle?.service?.id && validateServiceUnitsInternal(vehicle?.service) > 1 ){
        if (currentDay >= vehicle?.service?.days) {
          color = 'purple-linear-progress';
        } else if (currentDay >=  vehicle?.service?.daysAfter) {
          color = 'purple-linear-progress';
        } else if (currentDay >= vehicle?.service?.daysBefore) {
          color = 'orange-linear-progress';
        } else {
          color = 'green-linear-progress';
        }
      }  

      break;

    case "hours":
      break;

    case "odometer":
      let totalOdometer = endValue - startValue
      if (totalOdometer < 0) totalOdometer = 1
      //console.log("currentValue",currentValue,"startValue",startValue)
      //fix value
      currentValue = currentValue - startValue;
      //console.log("currentValue",currentValue)
      //calculate
      percentage = (currentValue / (totalOdometer || 1)) * 100;
      if (percentage < 0) percentage = 0

      //title = `(${Math.round(percentage)}%) ${currentValue < 0 ? 0 : Math.round(currentValue)}/${Math.round(totalOdometer)} ${messages['vehicleDetailView_miles']}`;
      //Removing percentage
      title = `${currentValue < 0 ? 0 : Math.round(currentValue)}/${Math.round(totalOdometer)}${measure}`;
      
      if(vehicle){
        let colorValue = getOdometerValue(vehicle, user?.isKph,true);
        if(colorValue > 0 &&  vehicle?.service && validateServiceUnitsInternal(vehicle?.service) > 1){
          if (colorValue >= vehicle?.nextServiceOdometer) {
            color = 'purple-linear-progress';
          } else if (currentValue >=  vehicle?.service?.milesAfter) {
            color = 'purple-linear-progress';
          } else if (currentValue >= vehicle?.service?.milesBefore) {
            color = 'orange-linear-progress';
          } else {
            color = 'green-linear-progress';
          }
        } ;
      }

      break;

    case "engineHours":
      let totalEngineH = endValue - startValue;
      if (totalEngineH < 0) totalEngineH = 1
      //fix value
      currentValue = currentValue - startValue;
      //calculate
      percentage = (currentValue / (totalEngineH || 1)) * 100;

      if (percentage < 0) percentage = 0

      //extra validation to prevent wrong progrees bar with wrong data
      //if( isFinite(percentage) && !isNaN(percentage) && isFinite(currentValue) && !isNaN(currentValue)  && isFinite(totalEngineH) && !isNaN(totalEngineH) )
      //{
      //title = `(${Math.round(percentage)}%) ${currentValue < 0 ? 0 : Math.round(currentValue)}/${Math.round(totalEngineH)} ${messages['vehicleDetailView_engineHours']}`;
      //}
      //Removing percentage
      title = `${currentValue < 0 ? 0 : Math.round(currentValue)}/${Math.round(totalEngineH)}${measure}`;

      let colorcurrentValue = vehicle?.displayHours;
      if(colorcurrentValue > 0 &&  vehicle?.service && validateServiceUnitsInternal(vehicle?.service) > 1){
        if (colorcurrentValue >= vehicle?.nextServiceEngineHours) {
          color = 'purple-linear-progress';
        } else if (currentValue >=  vehicle?.service?.engineHoursAfter) {
          color = 'purple-linear-progress';
        } else if (currentValue >= vehicle?.service?.engineHoursBefore) {
          color = 'orange-linear-progress';
        } else {
          color = 'green-linear-progress';
        }
      }


      break;
  }

  if (status == 'Done') {
    color = calculatedColor(percentage);
  }

  if (!label) {
    return (
      <div>
        <div style={{ padding: '5px 10px 10px 0px' }}>
          <Tooltip title={`${Math.round(percentage)}%`} placement="bottom">
            <LinearProgress
              variant="determinate"
              className={color}
              value={percentage > 100 ? 100 : percentage}
            />
          </Tooltip>
        </div>
        <div>{title}</div>
      </div>
    )
  }
  else {
    return `${Math.round(percentage)}% ${title}`
  }
}

export const getMaintenanceLabel = (type, startValue, endValue, currentValue, messages) => {
  let percentage = 0;
  let title = ""

  switch (type) {
    case "time":
      let daysStart = moment.utc(startValue.toString())
      let daysEnd = moment.utc(endValue.toString())
      let daysNow = moment.utc(currentValue) || moment().utc()

      const diff = moment.duration(daysEnd.diff(daysStart));
      const diffNow = moment.duration(daysNow.diff(daysStart));

      percentage = (diffNow.asMinutes() / diff.asMinutes()) * 100
      if (percentage < 0) percentage = 0

      let currentTime = ""
      if (diffNow.asMonths() >= 1) currentTime = `${round(diffNow.asMonths(), 1)}  ${messages['vehicleDetailView_months']}`
      else if (diffNow.asDays() >= 1) currentTime = `${round(diffNow.asDays(), 1)}  ${messages['vehicleDetailView_days']}`
      else if (diffNow.asHours() >= 1) currentTime = `${round(diffNow.asHours(), 1)}  ${messages['vehicleDetailView_engineHours']}`
      else if (diffNow.asMinutes() >= 1) currentTime = `${round(diffNow.asMinutes(), 1)}  ${messages['vehicleDetailView_minutes']}`
      let totalTime = ""
      if (diff.asMonths() >= 1) totalTime = `${round(diff.asMonths(), 1)}  ${messages['vehicleDetailView_months']}`
      else if (diff.asDays() >= 1) totalTime = `${round(diff.asDays(), 1)}  ${messages['vehicleDetailView_days']}`
      else if (diff.asHours() >= 1) totalTime = `${round(diff.asHours(), 1)}  ${messages['vehicleDetailView_engineHours']}`
      else if (diff.asMinutes() >= 1) totalTime = `${round(diff.asMinutes(), 1)}  ${messages['vehicleDetailView_minutes']}`

      title = `(${Math.floor(percentage)}%) ${currentTime}/${totalTime}`;
      break;

    case "odometer":
      let totalOdometer = endValue - startValue
      //fix value
      currentValue = currentValue - startValue;
      //calculate
      percentage = (currentValue / totalOdometer) * 100;
      if (percentage < 0) percentage = 0

      title = `(${Math.round(percentage)}%) ${currentValue < 0 ? 0 : Math.round(currentValue)}/${Math.round(totalOdometer)} ${messages['vehicleDetailView_miles']}`;
      break;

    case "engineHours":
      let totalEngineH = endValue - startValue;
      //fix value
      currentValue = currentValue - startValue;
      //calculate
      percentage = (currentValue / totalEngineH) * 100;

      if (percentage < 0) percentage = 0

      title = `(${Math.round(percentage)}%) ${currentValue < 0 ? 0 : Math.round(currentValue)}/${Math.round(totalEngineH)} ${messages['vehicleDetailView_engineHours']}`;
      break;
  }

  return title
}

export const getMaintenanceStatusColor = (status, messages) => {
  let color = "";
  let tooltip = "";

  switch (status) {
      case "PastDue":
          color = styles.purple;
          tooltip = messages["vehicleView_pastDue"];
          break;
      case "Due":
          color = styles.red;
          tooltip = messages["vehicleView_dueStatus"];
          break;
      case "DueSoon":
          color = styles.orange;
          tooltip = messages["dueSoon"];
          break;
      case "Scheduled":
          color = styles.green;
          tooltip = messages["vehicleView_scheduledStatus"];
          break;
      case "Done":
          color = styles.black;
          tooltip = messages["done"];
          break;
  }

  return { color, tooltip }
}

export const calculatedColor = (value) => {
  let color = '';
  if (value <= 50) {
    color = 'green-linear-progress';
  } else if (value > 50 && value < 100) {
    color = 'orange-linear-progress';
  } else if (value >= 100) {
    color = 'red-linear-progress';
  }
  return color;
};



export const statusPill = (tasks) => {

  const { messages } = useIntl();

  let color = '';
  let tooltip = '';

  const { status } = tasks;
  if (status === 'PastDue') {
    color = styles.purple;
    tooltip = messages['pastDue'];
  } else if (status === 'Due') {
    color = styles.red;
    tooltip = messages['due'];
  } else if (status === 'DueNow') {
    color = styles.orange;
    tooltip = messages['dueNow'];
  } else if (status === 'DueSoon') {
    color = styles.orange;
    tooltip = messages['dueSoon'];
  } else if (status === 'Scheduled') {
    color = styles.green;
    tooltip = messages['scheduled'];
  } else if (status === 'Done') {
    color = styles.black;
    tooltip = messages['done'];
  }
  return <Pill style={{ width: 110 }} item={{ label: tooltip, color: color }} />;
}

export const GetFormatForMoment = (dateFormat) => {
  let format = 'MM/DD/YYYY';
  if (dateFormat === 'm/d/Y') {
    format = 'MM/DD/YYYY';
  } else if (dateFormat === 'd/m/Y') {
    format = 'DD/MM/YYYY';
  } else if (dateFormat === 'Y/m/d') {
    format = 'YYYY/MM/DD';
  }
  return format;
};

export const formatCatalogDate = (unitTime, paramFormat = null, locale) => {

  if (!unitTime) {
    return
  }

  let timeMoment = moment(unitTime);
  if(locale) timeMoment.locale(locale)
  
  if (timeMoment.isValid()) {
    if (!paramFormat) {
      return `${timeMoment.format(GetFormatForMoment('m/d/Y') + ', h:mm a')} ${'(' + timeMoment.fromNow() + ')'}`
    } else {
      return `${timeMoment.format(paramFormat)} ${'(' + timeMoment.fromNow() + ')'}`
    }
  } else {
    let unixMoment
    if (unitTime && unitTime.length > 10) {
      unixMoment = moment.unix(Number(unitTime?.substring(0, unitTime.length - 3)))
    } else {
      unixMoment = moment.unix(Number(unitTime))
    }

    if (unixMoment.isValid()) {
      if (!paramFormat) {
        return `${unixMoment.format(GetFormatForMoment('m/d/Y') + ', h:mm a')} ${'(' + unixMoment.fromNow() + ')'}`
      } else {
        return `${unixMoment.format(paramFormat)} ${'(' + unixMoment.fromNow() + ')'}`
      }
    }
  }
  return
};

export const getPriority = (status) => {
  switch (status) {
    case "PastDue":
      return 1
    case "Due":
      return 2
    case "DueSoon":
      return 3
    case "Scheduled":
      return 4
    case "Done":
      return 5
    default:
      return 0
  }
}

export const formatVehicleStatus = (status, messages) => {
  return messages[status[0].toLowerCase() + status.slice(1)]
}

const validateServiceUnitsInternal = (service) => {
  let cont = 0;
  
  if(service?.days > 0)
    cont++

  if(service?.miles > 0)
    cont++

  if(service?.engineHours > 0)
    cont++

  return cont;
}

export const generarColorHex = () => {
  let letras = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
      color += letras[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const getWarningCategory = (isSdCardError, warnings, isOffline, messages) => {
  let title = '';
  let color = '';
  if (isSdCardError) {
    title = messages['fleetView_sdCardError'];
    color = '#F44336';
  } else if (isOffline) {
    title = "Offline";
    color = '#FFDC00';
  }

  return {
    title,
    color
  }
}