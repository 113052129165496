//View Keys
export default {
    "vehicleDetailView_makeModel": "Make/Model: ",
    "vehicleDetailView_year": "Year: ",
    "vehicleDetailView_odometer": "Odometer: ",
    "vehicleDetailView_engineHours": "Engine Hours: ",
    "vehicleDetailView_vin": "Vin: ",
    "vehicleDetailView_assignedDriver": "Assigned: ",
    "vehicleDetailView_groups": "Groups: ",
    "vehicleDetailView_tags": "Tags: ",
    "vehicleDetailView_service": "Service",
    "vehicleDetailView_miles": "Miles",
    "vehicleDetailView_days": "Days",
    "vehicleDetailView_engineHours": "Engine Hours",
    "vehicleDetailView_hours": "Hours",
    "vehicleDetailView_engHrs": "eng hrs",
    "vehicleDetailView_months": "Months",
    "vehicleDetailView_minutes": "Minutes",
    "vehicleDetailView_cost": "Cost",
    "vehicleDetailView_currentCost": "Total Cost",
    "vehicleDetailView_status": "Reminders",
    "vehicleDetailView_completedStatus": "Completed",
    "vehicleDetailView_dueStatus": "Due",
    "vehicleDetailView_dueSoonStatus": "Due Soon",
    "vehicleDetailView_scheduledStatus": "Scheduled",
    "vehicleDetailView_subscribers": "Subscribers",
    "vehicleDetailView_notes": "Notes",
    "vehicleDetailView_attachments": "Attachments ",
    "vehicleDetailView_reminder": "Reminder",
    "vehicleDetailView_dueNow": "Due Now",
    "vehicleDetailView_pastDue": "Past Due",
    "vehicleDetailView_completedDate": "Completed",
    "vehicleDetailView_scheduledList": "Scheduled",
    "vehicleDetailView_performedList": "Completed",
    "vehicleDetailView_reminders": "Reminders",
    "vehicleDetailView_serviceItems" : "Service Items",
    "vehicleDetailView_laborCost" : "Labor",
    "vehicleDetailView_partsCost" : "Parts",
    "vehicleDetailView_totalCost" : "Total",
    "vehicleDetailView_exportGenerated" : "The export is being generated",
    "vehicleDetailView_exportTask" : "Export Task Overview",
    "vehicleDetailView_warningNotInfoOdometer" : "It is not possible to retrieve information about the vehicle's odometer",
    "vehicleDetailView_warningNotInfoEngineHour" : "It is not possible to retrieve information about the vehicle's Engine Hours",
    "vehicleDetailView_warningNotNextDate" : "it is necessary to add the Next Scheduled Date ",
    //scheduleMaintenanceService
    "scheduleVehicleService_vehicleTitle": "Schedule Service for",
    "scheduleVehicleService_editVehicleTitle": "Edit Scheduled Service for",
    "scheduleVehicleService_serviceTitle": "Add a Vehicle to",
    "scheduleVehicleService_editServiceTitle": "Edit Scheduled Vehicle for",
    "scheduleVehicleService_schedule": "Schedule",
    "scheduleVehicleService_update": "Update",
    "scheduleVehicleService_serviceInputLabel": "Service",
    "scheduleVehicleService_serviceInputPlaceholder": "Select",
    "scheduleVehicleService_dateInputLabel": "Date of last service",
    "scheduleVehicleService_CompletedDateInputLabel": "Date of service completed",
    "scheduleVehicleService_timeInputPlaceholder": "Select Time",
    "scheduleVehicleService_timeInputLabel": "*",
    "scheduleVehicleService_dateInputPlaceholder": "Select Date",
    "scheduleVehicleService_notesInputLabel": "Notes",
    "scheduleVehicleService_notesInputServiceLabel": "Services Notes",
    "scheduleVehicleService_notesInputPlaceholder": "",
    "scheduleVehicleService_recurringInputLabel": "Save as Recurring Service",
    "scheduleVehicleService_intervalTypeInputLabel": "Interval Type",
    "scheduleVehicleService_intervalTypeInputPlaceholder": "Select",
    "scheduleVehicleService_intervalInputLabel": "Interval",
    "scheduleVehicleService_intervalInputPlaceholder": "Interval",
    "scheduleVehicleService_nextServiceInputLabel": "Next Service",
    "scheduleVehicleService_nextServiceInputPlaceholder": "Select Date",
    "scheduleVehicleService_reminderInputLabel": "Reminder",
    "scheduleVehicleService_reminderInputPlaceholder": "Reminder",
    "scheduleVehicleService_pastDueInputLabel": "Past Due",
    "scheduleVehicleService_pastDueInputPlaceholder": "Past Due",
    "scheduleVehicleService_addNewIntervalType": "Add New Interval Type",
    "scheduleVehicleService_alertInputLabel": "Alert",
    "scheduleVehicleService_alertInputPlaceholder": "Select Alert",
    "scheduleVehicleService_alertTypeInputLabel": "",
    "scheduleVehicleService_alertTypeInputPlaceholder": "Alert Type",
    "scheduleVehicleService_addNewAlertType": "Add New Alert Type",
    "scheduleVehicleService_attachmentsInputLabel": "Attachment",
    "scheduleVehicleService_attachmentsServiceLabel": "Services Attachment",
    "scheduleVehicleService_attachmentsServicePlaceholder": "Check Files",
    "scheduleVehicleService_attachmentsInputPlaceholder": "Select File(s)",
    "scheduleVehicleService_success": "Service scheduled",
    "scheduleVehicleService_vehicleInputLabel": "Vehicle",
    "scheduleVehicleService_vehicleInputPlaceholder": "Select",
    "scheduleVehicleService_alertsInputLabel": "Subscribers",
    "scheduleVehicleService_addNewService": "Add New Service",
    "scheduleVehicleService_alert_placeholder": "Select Subscriber",
    "scheduleVehicleService_alert_error_message": "Select one least",
    "scheduleVehicleService_alert_button_label": "Add Subscribers",
    "scheduleVehicleService_delete_button_bar": "Delete",

    "scheduleMaintenanceService_interval_types": "Interval Types",
    "scheduleMaintenanceService_service_settings": "Service Settings",

    "scheduleMaintenanceService_notSupportEngine": "Current vehicle does not support engine hours",
    "scheduleMaintenanceService_notSupportOdometer": "Current vehicle does not support odometer",
    "scheduleMaintenanceService_notSupportOdometerandEngineHours": "The current vehicle does not support odometer and engine hours.",


    //UnitForm
    "unit_label":"Unit Label",
    "unit_label_placeholder":"Unit",

    "unit_odometer":"Odometer",
    "unit_odometer_placeholder":"odometer",
    "unit_odometer_dash_reading_label":"Dash Reading",
    "unit_odometer_dash_reading_placeholder":"dash reading",
    "unit_odometer_offset_label":"Offset",
    "unit_odometer_offset_placeholder":"273.4",

    "unit_engine_hours":"Engine Hours",
    "unit_engine_hours_placeholder":"engine hours",
    "unit_engine_hours_dash_reading_label":"Dash Reading",
    "unit_engine_hours_dash_reading_placeholder":"dash reading",
    "unit_engine_hours_offset_label":"Offset",
    "unit_engine_hours_offset_placeholder":"engine hours",

    "unit_speed":"Speed",
    "unit_speed_placeholder":"speed",
    
    "unit_consistent_speed":"Consistent Speed",

    "unit_driver":"Driver",
    "unit_select_placeholder":"Select",

    "unit_group":"Group",
    "unit_general":"General",
    "unit_description":"Description",
    "unit_vin":"VIN",

    "unit_location":"Location",
    "unit_time_zone":"Timezone",

    "unit_year":"Year",
    "unit_engine_liters":"Engine Liters",
    "unit_engine_liters_placeholder":"engine liters",

    "unit_mpg":"MPG",

    "unit_highway":"Highway",
    "unit_highway_placeholder":"highway",
    "unit_city":"City",
    "unit_city_placeholder":"city",


    "unit_notes":"Notes",
    "unit_input_labels":"Input Labels",
    "unit_input_label":"Input Label",

    "unit_output_labels":"Output Labels",
    "unit_output_label":"Output Label",

    "unit_on_label":"On Label",
    "unit_off_label":"Off Label",

    "unit_on_label_placeholder":"On",
    "unit_off_label_placeholder":"Off",

    "unit_make":"Make",
    "unit_make_new":"Add New Make",
    "unit_model":"Model",
    "unit_model_new":"Add New Model",

    "unit_inputs_alert_message":" This input is already set ",
    "unit_inputs_alert_title":" Wait",

    "unit_validation_alert_message":" There are validations errors that you must check before to continue ",
    "unit_validation_alert_title":" Validation Error ",

    "unit_validation_succes_message":" Good! data updated ",
    "unit_validation_success_title":" Success ",

    "unit_vehicle_Status_icon": "Vehicle Icon Status",


    "unit_data":"Data",
    "unit_disable_all":"Disable All",
    "unit_enable_all":"Enable All",

    "unit_calculated_by_ecu":"Calculated by ECU",
    "unit_calculated_by_gps":"Calculated by GPS",

    "unit_actual":"Actual",

    "driver_detail_schedule_tasks":"Schedule tasks",
    "driver_detail_schedule_tasks_logs":"Schedule tasks logs",

    "unit_toggle_options":"Toggle On/Off",
    "unit_pulse_options":"Pulse On/Off",
    "unit_pulse_single_option":"Single Pulse",

    "performed_Services_Date":"Date",
    "performed_Services_Time":"Time",
    "performed_Services_Totals":"Totals",
    "performed_Services_Totals_Cost":"Total Cost",
};
