import {
    MAP_LANDMARKS_EDIT_CREATE_GROUP_OPEN_MODAL,
    MAP_LANDMARKS_GET_LANDMARK_GROUP,
    MAP_LANDMARKS_GET_LANDMARK_GROUP_RESPONSE,
    MAP_LANDMARKS_EDIT_CREATE_LANDMARK_GROUP,
    MAP_LANDMARKS_EDIT_CREATE_LANDMARK_GROUP_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    open: false,
    id: null,
    landmarkGroup: null,
    loadingLandmarkGroup: false,
    response: null,
    loadingResponse: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // Routes
        case MAP_LANDMARKS_EDIT_CREATE_GROUP_OPEN_MODAL:
            return {
                ...state,
                open: action.payload.open,
                id: action.payload.id,
                landmarkGroup: null,
                loadingLandmarkGroup: false,
                response: null,
                loadingResponse: false
            };

        case MAP_LANDMARKS_GET_LANDMARK_GROUP:
            return {
                ...state,
                landmarkGroup: null,
                loadingLandmarkGroup: true
            };

        case MAP_LANDMARKS_GET_LANDMARK_GROUP_RESPONSE:
            return {
                ...state,
                landmarkGroup: action.payload.data,
                loadingLandmarkGroup: false
            };

        case MAP_LANDMARKS_EDIT_CREATE_LANDMARK_GROUP:
            return {
                ...state,
                response: null,
                loadingResponse: true
            };

        case MAP_LANDMARKS_EDIT_CREATE_LANDMARK_GROUP_RESPONSE:
            return {
                ...state,
                response: action.payload.data,
                loadingResponse: false
            };

        case CLEAR_REDUX:
            return ["", "MAP_LANDMARKS_EDIT_CREATE_LANDMARK_GROUP"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
      return state;
    }
}