import React from 'react'

export const IncidentInfo = ({incidentName, unitLabel}) => {
  return (
    <div style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        margin: '40px',
        border: '1px solid black',
        padding: '15px',
        borderRadius: '10px',
        zIndex: 1,
        background: 'white'
    }}>
        {incidentName} - {unitLabel}
    </div>
  )
}
