import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { clientQuery, clientMutation } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../core/logManager';

import { resetALL } from '../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    response: null,
    dynamicForm: null,
    error: false
};

const route = 'modules/dynamicForms/modals/rename/redux/slice'

export const renameForm = createAsyncThunk('dynamicForms/modals/rename/renameForm', async ({ id, name, description }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientMutation(
            `
                mutation updateDynamicForm ($id: Int!, $name: String, $description: String) {
                    dynamicForm: updateDynamicForm (id: $id, name: $name, description: $description) {
                        id
                    }
                }
            `,
            {
                id,
                name,
                description
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response?.dynamicForm?.id) {
            throw 'Error while renaming dynamic form';
        }

        return response?.dynamicForm;
    } catch (exc) {
        ExceptionManager(exc, route, 'renameForm');
        return rejectWithValue(exc)
    }
});

//Slice
const dynamicFormsRenameRedux = createSlice({
    name: 'dynamicForms/rename',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openRenameModal(state, action) {
            state.open = true;
            state.dynamicForm = action?.payload;
        },
        closeRenameModal(state) {
            state.open = false;
            state.dynamicForm = null;
            state.response = null;
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)
        //deleteDynamicForms
        builder.addCase(renameForm.pending, (state, action) => {
            state.loading = true;
            state.error = false;
            state.response = null;
        });
        builder.addCase(renameForm.fulfilled, (state, action) => {
            state.loading = false;
            state.error = false;
            state.response = action.payload;
        });
        builder.addCase(renameForm.rejected, (state, action) => {
            state.loading = false;
            state.response = action.payload;
            state.error = true;
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = dynamicFormsRenameRedux;

// Extract and export each action creator by name
export const {
    reset,
    openRenameModal,
    closeRenameModal,
} = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;