export default {
    "FSM_SETTINGS_MESSAGES_UNIT": "Unidad:",
    "FSM_SETTINGS_MESSAGES_DRIVER_STATUS": "Estado del Conductor:",
    "FSM_SETTINGS_MESSAGES_WRITE_MESSAGE": "Escribe un mensaje aquí",
    "FSM_SETTINGS_MESSAGES_SEND": "Enviar",
    "FSM_SETTINGS_MESSAGES_SEND_ALL": "Enviar a todos",
    "FSM_SETTINGS_MESSAGES_LAST_CONNECTION": "Última conexión",
    "FSM_SETTINGS_MESSAGES_DRIVER_ALL": "Todos los conductores FSM",
    "FSM_SETTINGS_MESSAGES_DRIVER_CONNECTED": "Todos los conductores Conectados",
    "FSM_SETTINGS_MESSAGES_CREATED": "Mensaje FSM creado",
    "FSM_SETTINGS_MESSAGES_CHARACTERS": "Caracteres:",
    "FSM_SETTINGS_MESSAGES_CONFIRM": "Confirmar",
    "FSM_SETTINGS_MESSAGES_CONFIRM_TITLE": "Enviar un nuevo mensaje a todos",
    "FSM_SETTINGS_MESSAGES_CONFIRM_CONTENT": "Estas seguro de enviar un mensaje nuevo a todos los conductores?",
    "FSM_SETTINGS_MESSAGES_LEGEN": "Leyenda:",
    "FSM_SETTINGS_MESSAGES_STATUS_NOT_CONNECTED": "No conectado",
    "FSM_SETTINGS_MESSAGES_STATUS_CONNECTED": "Conectado",
    "FSM_SETTINGS_MESSAGES_STATUS_CLOCKED": "Registrado",
    "FSM_SETTINGS_MESSAGES_STATUS_CLOCKED_CONNECTED": "Conectado/Fichado",
    "FSM_SETTINGS_MESSAGES_FSM_DRIVER": "FSM Conductores",
    "FSM_SETTINGS_MESSAGES_ALLOW_DRIVER_DRIVER": "Permitir comuncación entre conductores"
};