import {
    ACCOUNT_TAGS_OPEN_CLOSE_DELETE_TAGS_MODAL,
    ACCOUNT_TAGS_DELETE_TAGS,
    ACCOUNT_TAGS_DELETE_TAGS_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    open: false,
    selected: [],
    response: null,
    loading: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case ACCOUNT_TAGS_OPEN_CLOSE_DELETE_TAGS_MODAL:
            return {
                ...state,
                open: action.payload?.open,
                selected: action.payload?.selected || []
            };

        case ACCOUNT_TAGS_DELETE_TAGS:
            return {
                ...state,
                response: null,
                loading: true
            };

        case ACCOUNT_TAGS_DELETE_TAGS_RESPONSE:
            return {
                ...state,
                response: action?.payload?.response,
                loading: false
            };

        case CLEAR_REDUX:
            return ["", "ACCOUNT_TAGS_DELETE_TAGS"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return state;
    }
};