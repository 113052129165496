// Action Types
import {
  CLIP_REQUEST_GET_UNITS,
  CLIP_REQUEST_GET_UNITS_RESULT,
  CLIP_REQUEST_OPEN_FORM,
  CLIP_REQUEST_CREATE,
  CLIP_REQUEST_CREATE_RESPONSE,
  CLIP_REQUEST_SEARCH,
  CLIP_REQUEST_SEARCH_RESPONSE,
  CLIP_REQUEST_OPEN_MODAL_ADD_NOTES,
  VIDEO_RESTORE_CLIP_REQUEST,
  VIDEO_RESTORE_CLIP_REQUEST_RESPONSE,
  CLIP_REQUEST_UPDATE_CLIP,
  CLEAR_REDUX,
  CLIP_REQUEST_REMOVE_CLIPS,
  CLIP_REQUEST_DATA,
  CLIP_REQUEST_NEW_FORM,
  CLIP_AVAILABLE_SEARCH,
  CLIP_AVAILABLE_SEARCH_RESPONSE,
  CLIP_REQUEST_REFRESH_CLIP_RESPONSE
} from 'Redux/actionTypes';

/**
 * Initial State for Clip Request Tab.
 */
const INITIAL_STATE = {
  units: [],
  clips: [],
  refreshClip: {},
  loading: false,
  requestClipResponse: null,
  visibleForm: false,
  total: 0,
  clipRequestOpenModal: false,
  clipRequest: null,
  currentTab: 0,
  loadingCreateClip: false,
  error: false,
  allFilters: {
    openSearch: "",
    limit: 10,
    total: 0,
    entity: null,
    entities: [],
    dateRange: {},
    hasNotes: null,
    timeFrame: "",
    items: [],
    status: "all",
    resetSearch: true,
    hasStarred: false,
    isDeleted: false
  },
  restoreClipRequest: null,
  loadingRestoreClipRequest: false,
  loadingUnits: false,
  resetTable: false,
  requestClipData:null,
  newClipRequest: false,
  availableClips: []
};

const formatVideoUnits = (givenVideoUnits) => {
  let videoUnits = givenVideoUnits.filter((device) => device.unit)
  if (videoUnits) {
    return videoUnits.map((givenUnit) => (
      {
        label: givenUnit.unit.label,
        id: givenUnit.linkedDevice.id,
        firmware: givenUnit.linkedDevice.firmware,
        firmwareId: givenUnit.linkedDevice.firmwareId,
        deviceModelName: givenUnit.linkedDevice.deviceModelName,        
      }
    ))
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLIP_REQUEST_GET_UNITS:
      return { ...state, loadingUnits: true };

    case CLIP_REQUEST_GET_UNITS_RESULT:
      return { ...state, loadingUnits: false, units: formatVideoUnits(action.payload) };

    case CLIP_REQUEST_OPEN_FORM:
      return { ...state, visibleForm: action.payload };

    case CLIP_REQUEST_CREATE:
      return { ...state, loadingCreateClip: true, requestClipData: action.payload }

    case CLIP_REQUEST_DATA:
      return { ...state, requestClipData: action.payload }

    case CLIP_REQUEST_NEW_FORM:
        return { ...state, newClipRequest: action.payload }

    case CLIP_REQUEST_CREATE_RESPONSE:
      return { ...state, requestClipResponse: action.payload, loadingCreateClip: false };

    case CLIP_REQUEST_SEARCH:
      return {
        ...state,
        clips: action.payload.resetSearch ? [] : state.clips,
        allFilters: action.payload,
        loading: true,
        error: false
      };

    case CLIP_REQUEST_SEARCH_RESPONSE:
      return {
        ...state,
        allFilters: { ...state.allFilters, nextToken: action.payload.nextToken, resetSearch: action.payload.resetSearch },
        total: action.payload.total,
        clips: action.payload.resetSearch
          ? action.payload.items
          : [...state.clips, ...action.payload.items],
        loading: false,
        error: action?.payload?.error
      };

      
      case CLIP_AVAILABLE_SEARCH:
        return {
          ...state,
          availableClips : state.availableClips,
          error: false
        };
  
      case CLIP_AVAILABLE_SEARCH_RESPONSE:
        return {
          ...state,
          availableClips: action.payload.items,
          loading: false,
          error: action?.payload?.error
        };

    case CLIP_REQUEST_OPEN_MODAL_ADD_NOTES:
      return {
        ...state,
        clipRequestOpenModal: action.payload.open,
        clipRequest: action.payload.data
      };

    case VIDEO_RESTORE_CLIP_REQUEST:
      return {
        ...state,
        clips: [],
        loadingRestoreClipRequest: true,
        //allFilters: { ...state.allFilters, nextToken: null }  
      };

    case VIDEO_RESTORE_CLIP_REQUEST_RESPONSE:
      return { ...state, restoreClipRequest: action.payload.data, loadingRestoreClipRequest: false };

    case CLIP_REQUEST_UPDATE_CLIP:
      const data = action?.payload?.data;
      let newClips = state.clips;
      const clipExists = state.clips.find(item => item?.id === data?.id);
      if (clipExists) {
        if (state.allFilters.category === "starred" && clipExists?.starred !== data?.starred) {
          newClips = state.clips.filter(item => item?.id !== data?.id);
        } else {
          newClips = state.clips.map(item => {
            if (item?.id === data?.id) {
              return {
                ...item,
                ...data
              }
            }

            return item;
          });
        }
      }

      return { ...state, clips: newClips };

    case CLIP_REQUEST_REMOVE_CLIPS:
      const items = action?.payload?.data;
      let clipsCopy = state.clips;
      if (items?.length) {
        clipsCopy = state.clips.filter(item => !items.includes(item?.id));
      }

      return { ...state, clips: clipsCopy };

    case CLIP_REQUEST_REFRESH_CLIP_RESPONSE:
      const clip = action?.payload?.item
      const filterClips = state?.clips?.map((item) =>  {
        if(item?.id !== clip?.id){
          return item
        }else{
          return clip
        }
      })

      return {
        ...state,
        clips: filterClips
      };
    
    
      case CLEAR_REDUX:
      return ["", "CLIPS_REQUESTED"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INITIAL_STATE }
        : { ...state }
    default:
      return state;
  }
}