// Action types
import {
  ALERTS_TAB_GET_ALERTS,
  ALERTS_TAB_GET_ALERTS_RESULT,
  ALERTS_TAB_EDIT_SUBSCRIBERS,
  ALERTS_TAB_CLOSE_MODAL,
  ALERTS_TAB_OPEN_FILTER_MODAL,
  ALERTS_TAB_MANAGE_FILTER,
  ALERTS_TAB_SAVE_ALERTS,
  ALERTS_TAB_DELETE_ALERTS,
  ALERTS_TAB_DELETE_ALERT_SUBSCRIBER,
  ALERTS_TAB_OPEN_DELETE_MODAL,
  ALERTS_TAB_OPEN_ASSIGNED_MODAL,
  CLEAR_REDUX,
} from "Redux/actionTypes";

const initialFilters = {
  selectedTypes: [],
  selectedSeverities: [],
  selectedEntities: {
    entity: '',
    itemId: '',
  }
};

const INIT_STATE = {
  loading: false,
  isOpenModalAlertsTab: false,
  isOpenModalFilterAlerts: false,
  selectedAlert: {},
  selectedAlertsDeleted:[],
  apilAlerts: [], // Inmutable alerts (Does not change applying filters)
  alerts: [], // Visible alerts (Change applying available filters)
  total: 0,
  filters: initialFilters,
  isOpenModalDeleteAlerts:false,
  isOpenModalAssigned: false,
  tab1: 0,
  tab2: 0,
};

// alertsTabRedux
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALERTS_TAB_GET_ALERTS:
      return { ...state, loading: true, filters: state.filters }
    case ALERTS_TAB_GET_ALERTS_RESULT:
      return {
        ...state,
        alerts: action.payload.items || [],
        total: action.payload.total || 0,
        loading: false,
      }
    case ALERTS_TAB_EDIT_SUBSCRIBERS:
      return { ...state, selectedAlert: action.payload, isOpenModalAlertsTab: true }
    case ALERTS_TAB_CLOSE_MODAL:
      return { ...state, isOpenModalAlertsTab: false }
    case ALERTS_TAB_OPEN_FILTER_MODAL:
      return { ...state, isOpenModalFilterAlerts: action.payload.open !== undefined ? action.payload.open : !state.isOpenModalFilterAlerts }
    case ALERTS_TAB_MANAGE_FILTER:
      return { ...state, filters: action.payload, loading: true }
    case ALERTS_TAB_SAVE_ALERTS:
      return { ...state, apilAlerts: action.payload }
    case ALERTS_TAB_DELETE_ALERTS:
      return { ...state, loading: true, filters: initialFilters, isOpenModalFilterAlerts: false, isOpenModalDeleteAlerts: false }
    case ALERTS_TAB_DELETE_ALERT_SUBSCRIBER:
      return { ...state, loading: true, isOpenModalAlertsTab: true }
    case ALERTS_TAB_OPEN_DELETE_MODAL:
      return { ...state, isOpenModalDeleteAlerts:  action.payload.open,selectedAlertsDeleted:  action.payload.alertsDeleted    }
    
    case ALERTS_TAB_OPEN_ASSIGNED_MODAL:
      return { 
        ...state, 
        isOpenModalAssigned: action.payload.open, 
        selectedAlert: action.payload.alert,
        tab1: action.payload.tab1,
        tab2: action.payload.tab2
      }

    case CLEAR_REDUX:
      return ["", "ALERTS_TAB"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }
    default:
      return state;
  }
};
