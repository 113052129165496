import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    loading: false,
    loadingSavedRoute: false,
    getRouteResponse: false,
    loadingCreateRoutePoint: false,
    loadingUpdateRoutePoint: false,
    createUpdateRoutePointResponse: false,
    deleteAllRoutePointsResponse: false,
    cloneRouteResponse: false,
    loadingCloneRoute: false,
    routePointSearch: null,
    routePointGet: null,
    loadingRoutePoint: false, 

    //to get info for routePoint log
    routePointLogResponse: null,
    routePointLogs: [],
    routePointLog: null,

    routePointLogDeleteResponse: false,

    restart: false,
    routeId: 0,
    route: null,
    erorrRoutes: null,
    errorOnMap: null,

    //to create a new template
    isTemplate: 0,
    driverId : 0,
    templateRouteId: 0,
    scheduledAt: null,
    isCloneRoute: false,
    isRoutesTracker: false
};

const route = "settings/views/routes/components/createEditRouteModalMain";

export const routePointsSearchRoutes = createAsyncThunk('routes/routesTab/routePointsSearchRoutes', async ({ openSearch, limit = 10, offset = 0 }, { rejectWithValue, dispatch }) => {
    try {
        const conditions = {
            fields: ["title"],
            terms: [openSearch]
        } 

        const sort = {
            field: "name",
            order: "ASC"
        };

        const response = await clientQuery(
            `
            query searchRoutePoints (
                $conditions: [RoutePointSearchInput],
                $sort: [RoutePointSortInput], 
                $limit: Int, 
                $offset: Int
              ) {
                RoutePoints: searchRoutePoints (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                  items {
                    id 
                    routeId

                    name
                    address
                    city
                    state
                    landmarkId
                    landmark {
                        title
                    }
                    latitude
                    longitude
                    radius
                    sequence
                    note

                    arrivalTime
                    departureTime
                    status
                    statusChangedOn

                    createdOn
                    createdBy
                    updatedOn
                    updatedBy
                  }
                  total
                }
              }
            `,
            {
                conditions,
                sort, 
                limit, 
                offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.RoutePoints || { items: [], total: 0 }; 

    } catch (exc) {
        ExceptionManager(exc, route, 'routePointsSearchRoutes');
        return rejectWithValue(exc)
    }
});

export const routesGetRoutePoint = createAsyncThunk('routes/routesTab/routesGetRoutePoint', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query getRoutePoint ($id: Int!) {
                RoutePoint: getRoutePoint (id: $id) {
                    id 
                    routeId

                    name
                    address
                    city
                    state
                    landmarkId
                    landmark {
                        title
                    }
                    latitude
                    longitude
                    radius
                    sequence
                    note

                    arrivalTime
                    departureTime
                    status
                    statusChangedOn

                    createdOn
                    createdBy
                    updatedOn
                    updatedBy

                }
              }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.RoutePoint || null; 

    } catch (exc) {
        ExceptionManager(exc, route, 'routesGetRoutePoint');
        return rejectWithValue(exc)
    }
});


export const routePointsLogSearchRoutes = createAsyncThunk('routes/routesTab/routePointsLogSearchRoutes', async ({ openSearch, limit = 10, offset = 0 }, { rejectWithValue, dispatch }) => {
    try {

        const conditions = {
            fields: ["status"],
            terms: ["InRoute"]
        }

        const sort = {
            field: "status",
            order: "ASC"
        }; 

        const response = await clientQuery(
            `
            query searchRoutePointLogs (
                $conditions: [RoutePointLogSearchInput],
                $sort: [RoutePointLogSortInput], 
                $limit: Int, 
                $offset: Int
              ) {
                RoutePointsLog: searchRoutePointLogs (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                  items {
                    id
                    pointId
                    status
                  
                    createdOn
                    createdBy

                    routePoint {
                        id
                        name
                    }
                  }
                  total
                }
              }
            `,
            {
                conditions,
                sort, 
                limit, 
                offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        
        return response?.RoutePointsLog || { items: [], total: 0 }; 

    } catch (exc) {
        ExceptionManager(exc, route, 'routePointsLogSearchRoutes');
        return rejectWithValue(exc)
    }
});

export const routesCreateRoutePointLog = createAsyncThunk('routes/routesTab/routesCreateRoutePointLog', async (body, { rejectWithValue, dispatch }) => {
    try {
        const data = await clientMutation(
            `
            mutation createRoutePointLog (
                $pointId: Int!
                $status: enumRoutePointStatus!
                $latitude: Float
                $longitude: Float
                $note: String
              ) {
                StopLog: createRoutePointLog(
                  pointId: $pointId, 
                  status: $status,
                  latitude: $latitude,
                  longitude: $longitude,
                  note: $note
                ) {
                    id
                    pointId
                    status
                    latitude
                    longitude
                    note
                    address
                    routePoint{
                        id
                        name
                        status
                    }
                }
              }

            `,
            body
            ,
            endpoints.GRAPHQL_GENERAL_V2
        );
        if (data && data.StopLog) {
            return data.StopLog
        }
        return null;

    } catch (exc) {
        ExceptionManager(exc, route, 'routesCreateRoutePoint');
        return rejectWithValue(exc)
    }
});


export const routesDeleteRoutePointLog = createAsyncThunk('routes/routesTab/routesDeleteRoutePointLog', async (body, { rejectWithValue, dispatch }) => {
    try {
        const data = await clientMutation(
            `
            mutation deleteRoutePointLog (
                $id: Int!
              ) {
                routePointLog : deleteRoutePointLog(
                  id: $id
                )
              }

            `,
            body
            ,
            endpoints.GRAPHQL_GENERAL_V2
        );
        if (data && data.routePointLog) {
            return data.routePointLog
        }
        return null;

    } catch (exc) {
        ExceptionManager(exc, route, 'routesDeleteRoutePointLog');
        return rejectWithValue(exc)
    }
});

export const routesCreateRoutePoint = createAsyncThunk('routes/routesTab/routesCreateRoutePoint', async (body, { rejectWithValue, dispatch }) => {
    try {
        const data = await clientMutation(
            `
            mutation createRoutePoint (
                $routeId: Int!, 
                $name: String,
                ${body?.landmarkId ? '$landmarkId: Int, ' : ''}
                $latitude: Float!, 
                $longitude: Float!, 
                $sequence: Int!, 
                $note: String,
                $radius: Float,
                $updatePoint: RoutePointSequence

              ) {
                Stop: createRoutePoint(
                  routeId: $routeId, 
                  name: $name,
                  ${body?.landmarkId ? 'landmarkId: $landmarkId, ' : ''} 
                  latitude: $latitude, 
                  longitude: $longitude, 
                  sequence: $sequence, 
                  note: $note,
                  radius: $radius,
                  updatePoint: $updatePoint
                ) {
                  id
                  name
                  routeId
                  address
                  city
                  state
                  ${body?.landmarkId ? 'landmarkId' : ''}
                  latitude
                  longitude
                  sequence
                  note
                  radius
                }
              }

            `,
            body
            ,
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (data && data.Stop) {
            return data.Stop
        }
        return null;

    } catch (exc) {
        ExceptionManager(exc, route, 'routesCreateRoutePoint');
        return rejectWithValue(exc)
    }
});


export const routesUpdateRoutePoint = createAsyncThunk('routes/routesTab/routesUpdateRoutePoint', async (payload, { rejectWithValue, dispatch }) => {

    try {
        const body = { ...payload };
        const data = await clientMutation(
            `mutation updateRoutePoint (
                $id: String!, 
                $name: String, 
                $landmarkId: Int, 
                $latitude: Float, 
                $longitude: Float, 
                $sequence: Int, 
                $note: String,
                $radius: Float
                $status: enumRoutePointStatus
            ) {
            updateRoutePoint (
                id: $id, 
                name: $name, 
                landmarkId: $landmarkId, 
                latitude: $latitude, 
                longitude: $longitude, 
                sequence: $sequence, 
                note: $note,
                radius: $radius,
                status: $status
            ) 
                {
                    id
                    name
                    routeId
                    address
                    city
                    state
                    landmarkId
                    latitude
                    longitude
                    sequence
                    note
                    radius
                }
            }`,
            body,
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (data && data.updateRoutePoint) 
            return data.updateRoutePoint;
        
        return null;
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'routesUpdateRoutePoint');
        return rejectWithValue(exc)
    }
})


export const routesCloneRoute = createAsyncThunk('routes/routesTab/routesCloneRoute', async (body, { rejectWithValue, dispatch }) => {
    try {
        const data = await clientMutation(
            `
            mutation cloneRoute (
                $id: Int!, 
                $title: String!,
                $isTemplate: Boolean,
                $driverId: Int,
                $scheduledAt: AWSDateTime
              ) {
                Clone: cloneRoute(
                  id: $id, 
                  title: $title,
                  isTemplate: $isTemplate,
                  driverId: $driverId,
                  scheduledAt: $scheduledAt
                ) {
                    id
                    title
                    color
                    optimized
                    avoidToll

                    isTemplate
                    templateRouteId
                    driverId
                    scheduledAt
                    encodedPath

                    points {
                        id
                        name
                        routeId
                        address
                        city
                        state
                        landmarkId
                        latitude
                        longitude
                        radius
                        sequence
                        note       
                        radius       
                    }        
                }
              }

            `,
            body
            ,
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (data && data.Clone) {
            return data.Clone
        }
        return null;

    } catch (exc) {
        ExceptionManager(exc, route, 'routesCloneRoute');
        return rejectWithValue(exc)
    }
});

export const routesDeleteAllPoints = createAsyncThunk('routes/routesTab/routesDeleteAllPoints', async (body, { rejectWithValue, dispatch }) => {
    try {

        //update routeId
        const data = await clientMutation(
            `
            mutation updateRoute (
                $id: Int!,
                $points: [RoutePointInput],
            ) {
                Route: updateRoute(id: $id, points: $points) {
                    id
                    title
                    color
                    optimized
                    avoidToll
                    isTemplate
                    templateRouteId
                    driverId
                    scheduledAt
                    encodedPath
                    driver{
                        name
                        contactInfo
                        driverId
                    }  
                    points {
                        id
                        name
                        routeId
                        address
                        city
                        state
                        landmarkId
                        latitude
                        longitude
                        sequence
                        note
                        radius
                        status
                    }
                }
            }

            `,
            {
                id: body.routeId,
                points: []
            }
            ,
            endpoints.GRAPHQL_GENERAL_V2
        );
        if (data && data.delete) {
            return data.delete
        }
        return null;

    } catch (exc) {
        ExceptionManager(exc, route, 'routesDeleteAllPoints');
        return rejectWithValue(exc)
    }
});


export const routesCreateUpdateRoute = createAsyncThunk('routes/routesTab/routesCreateUpdateRoute', async (payload, { rejectWithValue, dispatch }) => {

    try {
        let body = {...payload};
        //Remove the # character - database have only 6 chars
        if(body?.color){
            body.color = body.color.replace('#', '');
        }
    
        let query = `
            mutation createRoute (
            $title: String!, 
            $color: String, 
            $optimized: Boolean, 
            $avoidToll: Boolean,
            $points: [RoutePointInput],
            $driverId: Int,
            $isTemplate: Boolean,
            $scheduledAt: AWSDateTime,
            $templateRouteId: Int,
            $distanceMeters: Float,
            $encodedPath: String,
            $encodedRegion: String
            ) {
                Route: createRoute(title: $title, color: $color, optimized: $optimized, avoidToll: $avoidToll, points: $points, driverId: $driverId, isTemplate: $isTemplate, scheduledAt: $scheduledAt, templateRouteId: $templateRouteId, distanceMeters: $distanceMeters, encodedPath: $encodedPath,encodedRegion: $encodedRegion) {
                    id
                    title
                    color
                    optimized
                    avoidToll
                    isTemplate
                    templateRouteId
                    driverId
                    scheduledAt
                    encodedPath
                    driver{
                        name
                        contactInfo
                        driverId
                    }  
                    points {
                        id
                        name
                        routeId
                        address
                        city
                        state
                        landmarkId
                        latitude
                        longitude
                        sequence
                        note
                        radius
                        status
                    }
                }
            } 
        `;
    
        if (body?.id) {
            query = `
            mutation updateRoute (
                $id: Int!,
                $title: String, 
                $color: String, 
                $optimized: Boolean, 
                $avoidToll: Boolean,
                $status: enumRouteStatus,
                $points: [RoutePointInput],
                $driverId: Int,
                $isTemplate: Boolean,
                $scheduledAt: AWSDateTime,
                $distanceMeters: Float,
                $encodedPath: String,
                $encodedRegion: String
            ) {
                Route: updateRoute(id: $id, title: $title, color: $color, optimized: $optimized, avoidToll: $avoidToll, status: $status, points: $points, driverId: $driverId, isTemplate: $isTemplate, scheduledAt: $scheduledAt, distanceMeters: $distanceMeters, encodedPath: $encodedPath,encodedRegion: $encodedRegion) {
                    id
                    title
                    color
                    optimized
                    avoidToll
                    isTemplate
                    templateRouteId
                    driverId
                    scheduledAt
                    encodedPath
                    driver{
                        name
                        contactInfo
                        driverId
                    }  
                    points {
                        id
                        name
                        routeId
                        address
                        city
                        state
                        landmarkId
                        latitude
                        longitude
                        sequence
                        note
                        radius
                        status
                    }
                }
            } 
            `;
        }

        const data = await clientMutation(
            query,
            body,
            endpoints.GRAPHQL_GENERAL_V2//endpoints.GRAPHQL_GENERAL_V2
        );

    
        if (data && data.Route) {
            //add the char # to colors
            let dataRoute = {
                ...data.Route, 
                color : data.Route.color && data.Route.color.includes('#') ? data.Route.color : `#${data.Route.color}` 
            }
            return dataRoute;
        } else {
            throw 'Error while creating route';
        }
    } catch (exc) {
        ExceptionManager(exc, route, 'routesCreateUpdateRoute');
        return rejectWithValue(exc)
    }
});
  

export const RoutesManagedCreateEditRouteModalMainRedux = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalCreateEditRouteModalMain: (state, action) => {
            return {
                ...state,
                open: action.payload?.open,
                routeId: action.payload?.id, 
                route: null,

                //to create a new template
                isTemplate: action.payload?.isTemplate,
                driverId : action.payload?.driverId || 0,
                templateRouteId: action.payload?.templateRouteId || 0,
                scheduledAt: action.payload?.scheduledAt || null,
                isCloneRoute: action.payload?.isCloneRoute || false,
                isRoutesTracker: action.payload?.isRoutesTracker || false,
            }
        },
        updateRouteData: (state, action) => {
            return {
                ...state,
                route: action?.payload?.route
            }
        },
        updateErrorOnMap: (state, action) => {
            return{
                ...state,
                errorOnMap: action?.payload?.errorOnMap
            }
        }
    },
    extraReducers: (builder) => {

        builder.addCase(routePointsSearchRoutes.pending, (state, action) => {
            state.loading = true;
            state.routePointSearch = null;
            state.erorrRoutes = null;

        });
        builder.addCase(routePointsSearchRoutes.fulfilled, (state, action) => {
            state.loading = false;
            state.routePointSearch = action?.payload;

        } );
        builder.addCase(routePointsSearchRoutes.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loading = false;
        });

        

        builder.addCase(routesGetRoutePoint.pending, (state, action) => {
            state.loading = true;
            state.routePointGet = null;
            state.erorrRoutes = null;

        });
        builder.addCase(routesGetRoutePoint.fulfilled, (state, action) => {
            state.loading = false;
            state.routePointGet = action?.payload;

        } );
        builder.addCase(routesGetRoutePoint.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loading = false;
        });

        
        builder.addCase(routePointsLogSearchRoutes.pending, (state, action) => {
            state.loading = true;
            state.routePointLogs = [];
            state.erorrRoutes = null;

        });
        builder.addCase(routePointsLogSearchRoutes.fulfilled, (state, action) => {
            state.loading = false;
            state.routePointLogs = action.payload;

        } );
        builder.addCase(routePointsLogSearchRoutes.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loading = false;
        });

        builder.addCase(routesCreateRoutePointLog.pending, (state, action) => {
            state.loading = true;
            state.routePointLogResponse = null;
            state.routePointLog = null;
            state.erorrRoutes = null;

        });
        builder.addCase(routesCreateRoutePointLog.fulfilled, (state, action) => {
            state.loading = false;
            state.routePointLogResponse = true;
            state.routePointLog = action.payload;

        } );
        builder.addCase(routesCreateRoutePointLog.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loading = false;
        });

        
        builder.addCase(routesDeleteRoutePointLog.pending, (state, action) => {
            state.loading = true;
            state.routePointLogDeleteResponse = null;
            state.erorrRoutes = null;
        });

        builder.addCase(routesDeleteRoutePointLog.fulfilled, (state, action) => {
            state.loading = false;
            state.routePointLogDeleteResponse = true;
        } );

        builder.addCase(routesDeleteRoutePointLog.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loading = false;
        });
        
        //update 
        builder.addCase(routesCreateRoutePoint.pending, (state, action) => {
            state.loadingCreateRoutePoint = true
            state.createUpdateRoutePointResponse = false;
            state.erorrRoutes = null;

        });
        builder.addCase(routesCreateRoutePoint.fulfilled, (state, action) => {
            state.loadingCreateRoutePoint = false;
            state.createUpdateRoutePointResponse = true;

        } );
        builder.addCase(routesCreateRoutePoint.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loadingCreateRoutePoint = false;

        });

        //update point
        builder.addCase(routesUpdateRoutePoint.pending, (state, action) => {
            state.loadingUpdateRoutePoint = true
            state.createUpdateRoutePointResponse = false;
            state.erorrRoutes = null;
        });
        builder.addCase(routesUpdateRoutePoint.fulfilled, (state, action) => {
            state.loadingUpdateRoutePoint = false;
            state.createUpdateRoutePointResponse = true;

        } );
        builder.addCase(routesUpdateRoutePoint.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
        });


        //clone routes
        builder.addCase(routesCloneRoute.pending, (state, action) => {
            state.loadingCloneRoute = true;
            state.cloneRouteResponse = false;
            state.erorrRoutes = null;

        });
        builder.addCase(routesCloneRoute.fulfilled, (state, action) => {
            //update state
            state.route = action?.payload;
            state.routeId = action.payload?.id;
            state.loadingCloneRoute = false;
            state.cloneRouteResponse = true;

        } );
        builder.addCase(routesCloneRoute.rejected, (state, action) => {  
            state.detail = [];
            if(action.payload.error == "Route duplicated title" || action.payload.error == "Routes duplicated title"){
                state.erorrRoutes = "fsmRoute_modal_editCreate_error_name";
            }else{
                state.erorrRoutes = action.payload.error;
            }
            state.loadingCloneRoute = false;
        });

        //clone routes
        builder.addCase(routesDeleteAllPoints.pending, (state, action) => {
            state.loading = true;
            state.deleteAllRoutePointsResponse = false;
            state.erorrRoutes = null;
        });
        builder.addCase(routesDeleteAllPoints.fulfilled, (state, action) => {
            //update state
            //state.route = action?.payload;
            state.deleteAllRoutePointsResponse = action.payload;
            if(state.route){
                state.route.points = [];
            }
            //get data for routes again
            state.loading = false;

        } );
        builder.addCase(routesDeleteAllPoints.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loading = false;

        });

        builder.addCase(routesCreateUpdateRoute.pending, (state, action) => {
            state.loading = true;
            state.loadingSavedRoute = false;
            state.erorrRoutes = null;
        });
        builder.addCase(routesCreateUpdateRoute.fulfilled, (state, action) => {
            state.route = action.payload;
            state.routeId = action.payload?.id;
            state.loading = false;
            state.loadingSavedRoute = true;

        } );
        builder.addCase(routesCreateUpdateRoute.rejected, (state, action) => {  
            state.detail = [];
            if(action.payload.error == "Route duplicated title" || action.payload.error == "Routes duplicated title"){
                state.erorrRoutes = "fsmRoute_modal_editCreate_error_name";
            }else{
                state.erorrRoutes = action.payload.error;
            }

            state.loading = false;
        });

    }
})

const { actions, reducer } = RoutesManagedCreateEditRouteModalMainRedux;

export const { reset, openModalCreateEditRouteModalMain, updateRouteData, updateErrorOnMap } = actions;

export default reducer;