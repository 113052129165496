import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    open: false,
    loading: false,
    isUpdatedStopOrder: false,
    idRoute: 0,
    loadingRoute: false,
    route: null,
    changesToSave: false,
    restart: false,
    erorrRoutes: null,
    openModalConfirmationSave: false,
};

const route = "settings/views/routes/components/editPanelRoute";

export const routesUpdateStopOrder = createAsyncThunk('routes/routesTab/routesUpdateStopOrder', async (payload, { rejectWithValue, dispatch }) => {
    try {
        let query = `
            mutation updateRoutePointsSequence ($routeId: Int!, $sequence: [RoutePointSequence]!) {
                Stop: updateRoutePointsSequence (routeId: $routeId, sequence: $sequence)
            }
        `;

        const response = await clientMutation(
          query,
          payload,
          endpoints.GRAPHQL_GENERAL_V2
      );

      if (!response) {
          throw 'Error while deleting dynamic forms';
      }

      return response && response.Stop;

    } catch (exc) {
        ExceptionManager(exc, route, 'routesUpdateStopOrder');
        return rejectWithValue(exc)
    }
});

export const routesEditPanelRouteGetRoute = createAsyncThunk('routes/routesTab/routesEditPanelRouteGetRoute', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query getRoute ($id: Int!) {
                Route: getRoute (id: $id) {
                  id
                  title
                  color
                  optimized
                  avoidToll
                  status
                  isTemplate
                  templateRouteId
                  driverId
                  scheduledAt
                  encodedPath
                  driver{
                    name
                    contactInfo
                    driverId
                  }  

                  points {
                    id
                    name
                    routeId
                    address
                    city
                    state
                    landmarkId
                    latitude
                    longitude
                    radius
                    sequence
                    note       
                    radius       
                  }                  
                }
              }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.Route || null; 

    } catch (exc) {
        ExceptionManager(exc, route, 'routesEditPanelRouteGetRoute');
        return rejectWithValue(exc)
    }
});




export const RoutesManagerEditPanelRouteRedux = createSlice({
    name: route,
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalEditPanelRoute: (state, action) => {
            return {
                ...state,
                open: action.payload?.open,
                idRoute: action?.payload?.idRoute
            }
        },
        onUpdateRoute: (state, action) => {
            return {
                ...state, 
                route: action?.payload?.route || state?.route,
                changesToSave: action.payload.changesToSave || state.changesToSave
            }
        },
        onOpenModalConfirmationSave: (state, action) => {
            return {
                ...state, 
                openModalConfirmationSave: action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(routesUpdateStopOrder.pending, (state, action) => {
            state.isUpdatedStopOrder = false;
            state.loading = true

        });
        builder.addCase(routesUpdateStopOrder.fulfilled, (state, action) => {
            state.isUpdatedStopOrder = action.payload;
            state.loading = false;

        } );
        builder.addCase(routesUpdateStopOrder.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loading = false;

        });


        builder.addCase(routesEditPanelRouteGetRoute.pending, (state, action) => {
            state.route = null;
            state.loadingRoute = true;

        });
        builder.addCase(routesEditPanelRouteGetRoute.fulfilled, (state, action) => {
            state.route = action.payload;
            state.loadingRoute = false;

        } );
        builder.addCase(routesEditPanelRouteGetRoute.rejected, (state, action) => {  
            state.detail = [];
            state.erorrRoutes = action.payload;
            state.loadingRoute = false;

        });

        
        
    }
})

const { actions, reducer } = RoutesManagerEditPanelRouteRedux;

export const { reset, openModalEditPanelRoute, onUpdateRoute, onOpenModalConfirmationSave } = actions;

export default reducer;
