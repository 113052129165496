export default {
    settingsVehicleEquipment_editTitle:"Equipamiento del vehiculo",
    settingsVehicleEquipment_createTitle:"Crear nueva Equipamiento del vehiculo",
    settingsVehicleEquipment_minLengthMessage:'El campo debe contener un mínimo de caracteres: ',
    settingsVehicleEquipment_maxLengthMessage:'El campo debe contener un maximo de caracteres: ',
    settingsVehicleEquipment_success:'Equipamiento del vehiculo Guardado',
    settingsVehicleEquipment_deleteWarning:'Esta seguro que desea eliminar la Equipamiento del vehiculo?',
    settingsVehicleEquipment_deleteTitle:'Eliminar Equipamiento del vehiculo',
    settingsVehicleEquipment_deleted:'Equipamiento del vehiculo eliminado',
    settingsVehicleEquipment_search:'Buscando por Equipamiento del vehiculo',
    settingsVehicleEquipment_name:'Equipamiento del vehiculo',
    settingsVehicleEquipment_assignmentTitle:'Vehiculos Asignados',
    settingsVehicleEquipment_selectorTitle:'Asignar unidades',
    settingsVehicleEquipment_selected:'Seleccionados',
    settingsVehicleEquipment_filterGroup:'Filtrar por grupo',
    settingsVehicleEquipment_searchUnits:'Buscar por unidades',
    settingsVehicleEquipment_listUnit:'Lista de unidades',
    settingsVehicleEquipment_assignments:'Tareas',
    settingsVehicleEquipment_equipmentSelectorAll:'Todos',







};