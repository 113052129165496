import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { LogManager, ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
    TAG_PICKER_SEARCH_TAGS
} from 'Redux/actionTypes';

import {
    tagPickerSearchTagsResponse
} from 'Redux/actions';

function* searchTagsRequest({ payload }) {
    try {
        const { data } = payload;
        const response = yield call(
            clientQuery,
            `
            query searchTags ($openSearch: String, $parentId: Int, $limit: Int, $offset: Int, $entityName: String) {
                Tags: searchTags (openSearch: $openSearch, parentId: $parentId, limit: $limit, offset: $offset, entityName: $entityName) {
                    items {
                        id
                        label
                        color
                        parentId
                        path
                        hasChildren
                        disabled
                    }
                    total
                }
            }
            `,
            {
                openSearch: data?.openSearch || "",
                parentId: data?.parentId >= 0 ? data?.parentId : null,
                limit: data?.limit || 0,
                offset: data?.offset || 0,
                entityName: data?.entityName || null
            },
            endpoints.GRAPHQL_GENERAL,
        );
        
        if (!response || !response.Tags) {
            throw 'No tags data';
        }

        const { Tags: { items, total }} = response;
        yield put(tagPickerSearchTagsResponse({ items, total, parentId: data.parentId, error: false }));
    } catch (exc) {
        ExceptionManager(exc, 'modules/.common/components/TagPicker/redux/saga', 'searchTagsRequest');
        yield put(tagPickerSearchTagsResponse({ error: true, items: [] }));
    }
}

function* watchTagPickerMethods() {
    yield takeEvery(TAG_PICKER_SEARCH_TAGS, searchTagsRequest);
}

export default function* rootSaga() {
    yield all([fork(watchTagPickerMethods)]);
}

