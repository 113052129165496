import {
  VIDEO_DETAIL_GET_AVAILABLE_MEDIA,
  VIDEO_DETAIL_GET_CLIP_REQUEST,
  VIDEO_DETAIL_GET_MEDIA_DETAIL_RESPONSE,
  VIDEO_DETAIL_UPDATE_AVAILABLE_MEDIA,
  VIDEO_DETAIL_UPDATE_CLIP_REQUEST,
  VIDEO_DETAIL_UPDATE_CLIP_REQUEST_RESPONSE,
  VIDEO_DETAIL_UPDATE_AVAILABLE_MEDIA_RESPONSE,
  VIDEO_DETAIL_DELETE_CLIPS_REQUEST,
  VIDEO_DETAIL_DELETE_AVAILABLE_MEDIA,
  VIDEO_DETAIL_DELETE_CLIPS_REQUEST_RESPONSE,
  VIDEO_DETAIL_DELETE_AVAILABLE_MEDIA_RESPONSE,
  VIDEO_DETAIL_OPEN_DELETE_MODAL,
  VIDEO_DETAIL_ADD_NOTE,
  VIDEO_DETAIL_ADD_NOTE_RESPONSE,
  VIDEO_DETAIL_DELETE_NOTES,
  VIDEO_DETAIL_DELETE_NOTES_RESPONSE,
  CLEAR_REDUX,
} from 'Redux/actionTypes';

const INIT_STATE = {
  // Get
  loadingAvailableMedia: false,
  loadingClipRequest: false,
  mediaResponse: null,
  // Update
  loadingUpdateAvailableMedia: {},
  loadingUpdateClipRequest: {},
  updateClipRequestResponse: {},
  updateAvailableMediaResponse: {},
  // Delete
  loadingDeleteAvailableMedia: false,
  deleteAvailableMediaResponse: null,
  loadingDeleteClipRequest: false,
  deleteClipsResponse: null,

  openDeleteMediaDetailModal: false,
  deleteMedialDetailModalData: null,

  notesResponse: null,
  notesLoading: false,

  deleteNotesResponse: null,
  deleteNotesLoading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get
    case VIDEO_DETAIL_GET_AVAILABLE_MEDIA:
      return { ...state, mediaResponse: null, loadingAvailableMedia: true };

    case VIDEO_DETAIL_GET_CLIP_REQUEST:
      return { ...state, mediaResponse: null, loadingClipRequest: true };

    case VIDEO_DETAIL_GET_MEDIA_DETAIL_RESPONSE:
      return { ...state, mediaResponse: action.payload.data, loadingClipRequest: false, loadingAvailableMedia: false };

    // Update
    case VIDEO_DETAIL_UPDATE_CLIP_REQUEST:
      return { 
        ...state, 
        updateClipRequestResponse: { ...state.updateClipRequestResponse, [action.payload.data?.id]: null }, 
        loadingUpdateClipRequest: { ...state.loadingUpdateClipRequest, [action.payload.data?.id]: true } 
      };

    case VIDEO_DETAIL_UPDATE_AVAILABLE_MEDIA:
      return { 
        ...state, 
        updateAvailableMediaResponse: { ...state.updateAvailableMediaResponse, [action.payload.data?.id]: null }, 
        loadingUpdateAvailableMedia: { ...state.loadingDeleteAvailableMedia, [action.payload.data?.id]: true }  
      };

    case VIDEO_DETAIL_UPDATE_CLIP_REQUEST_RESPONSE:
      return { 
        ...state, 
        updateClipRequestResponse: { ...state.updateClipRequestResponse, [action.payload.data?.id]: action.payload.data }, 
        loadingUpdateClipRequest: { ...state.loadingUpdateClipRequest, [action.payload.data?.id]: false },
      };

    case VIDEO_DETAIL_UPDATE_AVAILABLE_MEDIA_RESPONSE:
      return { 
        ...state, 
        updateAvailableMediaResponse: { ...state.updateAvailableMediaResponse, [action.payload.data?.id]: action.payload.data },
        loadingUpdateAvailableMedia: { ...state.loadingUpdateAvailableMedia, [action.payload.data?.id]: false } 
      };

    // Delete
    case VIDEO_DETAIL_DELETE_CLIPS_REQUEST:
      return { ...state, deleteClipsResponse: null, loadingDeleteClipsRequest: true };

    case VIDEO_DETAIL_DELETE_CLIPS_REQUEST_RESPONSE:
      return { ...state, deleteClipsResponse: action.payload.data, loadingDeleteClipsRequest: false };

    case VIDEO_DETAIL_DELETE_AVAILABLE_MEDIA:
      return { ...state, deleteAvailableMediaResponse: null, loadingDeleteAvailableMedia: true };

    case VIDEO_DETAIL_DELETE_AVAILABLE_MEDIA_RESPONSE:
      return { ...state, deleteAvailableMediaResponse: action.payload.data, loadingDeleteAvailableMedia: false };

    case VIDEO_DETAIL_ADD_NOTE:
      return { ...state, notesResponse: null, notesLoading: true };

    case VIDEO_DETAIL_ADD_NOTE_RESPONSE:
      return { ...state, notesResponse: action.payload.data, notesLoading: false };

    case VIDEO_DETAIL_DELETE_NOTES:
      return { ...state, deleteNotesResponse: null, deleteNotesLoading: true };

    case VIDEO_DETAIL_DELETE_NOTES_RESPONSE:
      return { ...state, deleteNotesResponse: action.payload.data, deleteNotesLoading: false };

    // Modal
    case VIDEO_DETAIL_OPEN_DELETE_MODAL:
      return { ...state, deleteResponse: null, openDeleteMediaDetailModal: action.payload.data.open, deleteMedialDetailModalData: action.payload.data.data }

    case CLEAR_REDUX:
      return ["", "VIDEO_DETAIL"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
}
