
import { NotificationManager } from 'Components/Notifications';
import { LogManagerConsole } from '../../../../logManager';

import {
  catalogUpdateItem,
  chatMessageUpdateStatus,
  chatMessageUpdateSNS,
  chatChannelChangeLastMessage,
  chatChanneltListOpen,
  chatMessageListOpen
} from '../../../../../.globals/redux/actions'

export class CHAT {
  execute = (payload, state, dispatch) => {
    const data = JSON.parse(payload);

    switch (data?.key) {
      case "CHAT_USER":
        LogManagerConsole("CHAT", "CHAT_USER", data)
        dispatch(catalogUpdateItem('chatUsers', 'authId', data.user))
        return [false];

      case "NEW_MESSAGE":
        LogManagerConsole("CHAT", "NEW_MESSAGE", data)
        // dispatch(catalogProcessItem('GenericItem', data.user, 'chatUsers', 'entityId'))
        dispatch(chatMessageUpdateStatus({ ids: [data.fullMessage.id], status: "Received" }))

        dispatch(chatChannelChangeLastMessage({ channelId: data.channelId, lastMessage: data.fullMessage }))

        const { authId } = state.securityRedux
        const chatUser = state.catalogsRedux?.chatUsers?.find(x => x.authId == data.fullMessage.transmitterEntityNameId) //transmitterEntityNameId

        NotificationManager.success(
          data?.message,
          chatUser?.label || "Chat",
          10000,
          () => {
              dispatch(chatChanneltListOpen({
                variableQuery: {
                  userId: JSON.stringify(authId)
                }
              }));
              dispatch(chatMessageListOpen({ chatChannelId:  data.channelId, authId }))
          },
          null,
          'error'
        );
        return [false];

      case "UPDATE_MESSAGE":
        LogManagerConsole("CHAT", "UPDATE_MESSAGE", data)
        dispatch(chatChannelChangeLastMessage({ channelId: data.channelId || data.chatChannelId || data.fullMessage?.chatChannelId, lastMessage: data.fullMessage }))
        dispatch(chatMessageUpdateSNS(data.fullMessage))
        return [false]

      default:
        return [false]
    }
  };
}

