import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';
// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

import {
    baseReportConditions,
    generateReportInstance, getCategoryFilter,
    getTimeZone,
    handleAsyncReport,
    isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

function* getMovingReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
    const {timeZone, sendByEmail, emailsTo, timeframeName, categoryName, delayed, dataResultPath} = report;
    const {filterType, filterArray, startRange, endRange, otherFilters, unitsSelected,  accumulateData = true, dates} = report.filters
    const baseConditionsQuery = baseReportConditions({
        report,
        name: 'Moving',
        dataResultPath,
        timeZone,
        delayed,
        unitsSelected,
        timeframeName,
        filterType,
        filterArray,
        categoryName,
        startRange,
        endRange,
        dates
    })
    try {
        let payload = {
            conditions: {
                ...baseConditionsQuery,
                integerRanges: {
                    fields: [
                        "moving"
                    ],
                    GTE: otherFilters.minute || 0,
                    LTE: 0
                },
                sortField: {
                    field: sortField || "deviceId",
                    order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                }
            },
            limit: rowsPerPage,
            offset: page
        };

        let addColumnDriver = [];
        if(report?.exportType === 'csv') {
            addColumnDriver.push(... [{
                column: "idLabel",
                header: getCategoryFilter(filterType) === "deviceId" ? "Unit" : "Driver"
            }, {
                column: "unitOrDriver",
                header: getCategoryFilter(filterType) === "deviceId" ? "Driver" : "Unit"
            }])
        }

        payload = {
            ...payload,
            limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
            offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
            conditions: {
                ...payload.conditions,
                exportOptions: {
                    sendByEmail,
                    emailsTo,
                    format: {
                        fileFormat: report?.exportType || "json",
                        formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
                        durationFormat: report?.durationFormat
                    },
                    culture: report.lang,
                    timeZone: getTimeZone(timeZone),
                    folderName: "px-customer-reports",
                    filePrefixName: "movingReport",
                    columns: [
                        ...addColumnDriver, {
                            "column": "travelStartEvent",
                            "header": "Travel Start"
                        }, {
                            "column": "travelStartTime",
                            header: `${report?.exportType === "csv" ? `Start Date (${getAbbvTimezone(timeZone)})` : `Start Date`}`
                        }, {
                            "column": "travelStartAddress",
                            "header": "Start Address"
                        }, {
                            "column": "travelDuration",
                            "header": "Moving Time"
                        }, {
                            "column": "travelStopEvent",
                            "header": "Travel Stop"
                        }, {
                            "column": "travelStopTime",
                            header: `${report?.exportType === "csv" ? `Stop Date (${getAbbvTimezone(timeZone)})` : `Stop Date`}`
                        }, {
                            "column": "travelStopAddress",
                            "header": report?.exportType === 'csv' ? "Start Address" : 'Stop Address'
                        }, {
                            "column": "travelStopDuration",
                            "header": "Idled Time"
                        }
                    ]
                }
            }
        }
        let data = yield call(
            clientQuery,
            `query getMovingReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
Report: getMovingReport (conditions: $conditions, limit: $limit, offset: $offset) {
${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
                `reportInstanceId`
                : `
                items {
                    recordInfo {
                        entityId
                        entityLabel
                        tagId
                        tagLabel
                        startDate
                        endDate
                        recordType
                        groupLabel
                        groupIndex
                        groupTotal
                        groupTagColor
                    }
                    recordDetail {
                        id
                        date
                        unitOrDriver
                        travelStartTime
                        travelStopTime
                        travelStartAddress
                        travelStartLandmarkName
                        travelStopAddress
                        travelStopLandmarkName
                        travelStartEvent
                        travelStopEvent
                        travelDuration
                        travelStopDuration
                        travelStartLandmarkId
                        travelStopLandmarkId
                        noData
                    }
                }
                total
                limit
                offset
                category
                reportInstanceId
                `}
            }
        }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
        yield handleAsyncReport(report, data, accumulateData);
    } catch (exc) {
        ExceptionManager(exc, 'reports/getMovingReport', 'getMovingReport');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}

export default getMovingReport;
