import React from 'react';
import { useIntl } from 'react-intl';

import Tags from './tags';

// Styles
import '../styles.scss';

const TagPickerInline = (props) => {
    const { messages } = useIntl();

    const {
        label,
        placeholder,
        searchText,
        setSearchText,
        tags,
        values,
        onChange,
        disabled,
        required,
        requiredMessage,
        height,
        maxHeight,
        showSelectAll,
        multiple,
        page,
        setPage,
        total
    } = props;

    return (
        <div className="gtc-tag-picker-inline-container">
            {
                label && (
                    <label className="input-normal-label">
                        {label}
                        {required && <span className="text-danger"> *</span>}
                    </label>
                )
            }
            {
                required && requiredMessage && (
                    <label className="input-normal-label text-danger">{requiredMessage}</label>
                )
            }

            <div
                style={{
                    padding: '8px 0px 0px 0px'
                }}
            >
                <input
                    className="gtc-tag-picker-inline-input input-normal form-control"
                    type="text"
                    value={searchText}
                    placeholder={placeholder || messages['entityTagPicker_searchTag']}
                    onChange={(e) => {
                        setSearchText(e?.target?.value);
                    }}
                    autoFocus
                    disabled={disabled}
                />
            </div>

            <div
                className='gtc-tag-picker-inline-tags-container'
                style={{
                    height: height || '100%',
                    maxHeight: maxHeight || ""
                }}
            >
                <Tags
                    searchText={searchText}
                    tags={tags}
                    values={values}
                    onChange={onChange}
                    disabled={disabled}
                    showSelectAll={showSelectAll}
                    multiple={multiple}
                    page={page}
                    setPage={setPage}
                    total={total}
                />
            </div>
        </div>
    );
}

export default TagPickerInline;