import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../core/logManager';

import { resetALL } from '../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: { total: 0, items: [] },
    dataExport: { total: 0, items: [] },
    error: null,
    filters: {
        openSearch: "",
        sortBy: "name",
        // dateRange: {
        //     start: moment().subtract(7, 'days').startOf('day').toDate(),
        //     end: moment.max(moment()).endOf('day').toDate()
        // },
        limit: 10,
        offset: 0,
    }
};

const route = 'modules/settings/views/driverStatus/redux/slice'

//Extend async Action
export const searchDriverStatus = createAsyncThunk('driverStatus/list/searchDriverStatus', async ({ limit, offset, openSearch, sortBy, dateRange }, { rejectWithValue, dispatch }) => {
    try {
        let conditions = [];
        let sort = [];

        let condition = {};
        if (openSearch) {
            condition = {
                fields: ["name"],
                terms: openSearch,
                exact: false
            }
        }

        // if (dateRange?.start && dateRange?.end) {
        //     condition['range'] = {
        //         fields: ["createdOn"],
        //         GTE: dateRange?.start,
        //         LTE: dateRange?.end
        //     }
        // }

        if (condition?.fields || condition?.range) {
            conditions.push(condition);
        }

        // if (sortBy) {
        // sort.push({
        //     field: "name",
        //     order: "ASC"
        // })
        // }

        const response = await clientQuery(
            `
            query searchDriverStatus (
                $conditions: [DriverStatusSearchInput], 
                $sort: [DriverStatusSortInput], 
                $limit: Int, 
                $offset: Int
            ) {
                data: searchDriverStatus (
                    conditions: $conditions, 
                    sort: $sort, 
                    limit: $limit, 
                    offset: $offset
                ) {
                    items {
                        id
                        name
                        accumulateTime
                        updatedOn
                        updatedBy
                        createdBy
                        createdOn
                    }
                    total
                }
            }
            `,
            {
                conditions,
                sort,
                limit: openSearch?.length > 1?100:limit,
                offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response?.data) {
            throw 'Error while getting driver status';
        }

        const { items, total } = response?.data;
        return { items: items, total: total || 0 };
    } catch (exc) {
        ExceptionManager(exc, route, 'searchDriverStatus');
        return rejectWithValue(exc)
    }
});

export const searchDriverStatusExport = createAsyncThunk('driverStatus/list/searchDriverStatusExport', async ({openSearch}, { rejectWithValue, dispatch }) => {
    try {
       
        const response = await clientQuery(
            `
            query searchDriverStatusExport($openSearch: String) {
                data: searchDriverStatusExport(
                    openSearch: $openSearch
                  ) {
                    items {
                        name
                        accumulateTime
                    }
                    total
                }
            }
            `,
            {
                openSearch
            },
            endpoints.GRAPHQL_GENERAL
        );

        if (!response?.data) {
            throw 'Error while getting driver status';
        }

        const { items, total } = response?.data;
        return { items: items, total: total || 0 };
    } catch (exc) {
        ExceptionManager(exc, route, 'searchDriverStatus');
        return rejectWithValue(exc)
    }
});

//Slice
const driverStatusRedux = createSlice({
    name: 'driverStatus/list',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(searchDriverStatus.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(searchDriverStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.filters = action?.meta?.arg;
        })
        builder.addCase(searchDriverStatus.rejected, (state, action) => {
            state.loading = false;
            state.data = { total: 0, items: [] };
            state.error = action.payload;
        })
        builder.addCase(searchDriverStatusExport.pending, (state, action) => {
            state.dataExport = { total: 0, items: [] }
        })
        builder.addCase(searchDriverStatusExport.fulfilled, (state, action) => {
            state.dataExport = action.payload;
        })
        builder.addCase(searchDriverStatusExport.rejected, (state, action) => {
            state.dataExport = { total: 0, items: [] };
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = driverStatusRedux;

// Extract and export each action creator by name
export const { reset } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;