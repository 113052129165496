import {
  REPORTS_MAIN_LIST_SHOW_REPORTFILTER,
  REPORTS_MAIN_LIST_HIDE_REPORTFILTER,
} from "Redux/actionTypes";

export const reportsListShowReportFilter = (title, list) => ({
  type: REPORTS_MAIN_LIST_SHOW_REPORTFILTER,
  payload: {
    title,
    list
  }
});

export const reportsFilterHideReportFilter = (title) => ({
  type: REPORTS_MAIN_LIST_HIDE_REPORTFILTER,
  payload: {
    title
  }
});
