// Dependencies
import { all } from "redux-saga/effects";
// Views
import dashboardPanel from "Modules/dashboard/views/dashboardPanel/redux/saga";
import gadgetsChart from "Modules/dashboard/views/chart/redux/saga";
import gadgetsDeviceOffline from "Modules/dashboard/views/offlineDevice/redux/saga";
import gadgetsRecentAlerts from "Modules/dashboard/views/recentAlerts/redux/saga";


export default function* rootSaga() {
    yield all([
        dashboardPanel(),
        gadgetsChart(),
        gadgetsDeviceOffline(),
        gadgetsRecentAlerts(),
    ]);
}
