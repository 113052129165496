export const EVENTS_CODES = {
    'IgnitionStatus.parking': '#F5E081',
    'GpsData.Idling': '#F5E081',
    'Idle': '#F5E081',
    'IDLING': '#F5E081',

    'Main Power On': '#5BDC9D',
    'Travel Start': '#5BDC9D',
    'Ignition On': '#5BDC9D',
    'Drive': '#5BDC9D',
    '3': '#5BDC9D',
    '4': '#5BDC9D',
    'IgnitionStatus.driving': '#5BDC9D',
    'GpsData.Moving': '#5BDC9D',
    'GpsData.simulatedTravelStart': '#5BDC9D',
    "CameraStatus.online": "#5BDC9D",


    'Ignition Off': '#E76060',
    'Travel Stop': '#E76060',
    "5": '#E76060',
    'Stop': '#E76060',
    'Parked': '#E76060',
    'Main Power Off': '#E76060',
    'GpsData.simulatedTravelStop': '#E76060',
    'IgnitionStatus.Stopped': '#E76060',

    //review color status
    'PowerEvent.Connect': '#5BDC9D',
    'PowerEvent.Disconnect': '#E76060',
    'MaintenanceWarning': '#5BDC9D',
    'AutoSnapshot': '#5BDC9D',
    "Low Battery": "#5BDC9D",
    "25": "#5BDC9D",


    'NO_DATA': '#D1D1D1',

    
}

export const DEFAULTCOLOR = '#D1D1D1';

export const EVERY30MINUTES = [
    { id: '00:00:00', label: '00:00 AM' },
    { id: '00:30:00', label: '00:30 AM' },
    { id: '01:00:00', label: '01:00 AM' },
    { id: '01:30:00', label: '01:30 AM' },
    { id: '02:00:00', label: '02:00 AM' },
    { id: '02:30:00', label: '02:30 AM' },
    { id: '03:00:00', label: '03:00 AM' },
    { id: '03:30:00', label: '03:30 AM' },
    { id: '04:00:00', label: '04:00 AM' },
    { id: '04:30:00', label: '04:30 AM' },
    { id: '05:00:00', label: '05:00 AM' },
    { id: '05:30:00', label: '05:30 AM' },
    { id: '06:00:00', label: '06:00 AM' },
    { id: '06:30:00', label: '06:30 AM' },
    { id: '07:00:00', label: '07:00 AM' },
    { id: '07:30:00', label: '07:30 AM' },
    { id: '08:00:00', label: '08:00 AM' },
    { id: '08:30:00', label: '08:30 AM' },
    { id: '09:00:00', label: '09:00 AM' },
    { id: '09:30:00', label: '09:30 AM' },
    { id: '10:00:00', label: '10:00 AM' },
    { id: '10:30:00', label: '10:30 AM' },
    { id: '11:00:00', label: '11:00 AM' },
    { id: '11:30:00', label: '11:30 AM' },
    { id: '12:00:00', label: '12:00 AM' },
    { id: '12:30:00', label: '12:30 PM' },
    { id: '13:00:00', label: '01:00 PM' },
    { id: '13:30:00', label: '01:30 PM' },
    { id: '14:00:00', label: '02:00 PM' },
    { id: '14:30:00', label: '02:30 PM' },
    { id: '15:00:00', label: '03:00 PM' },
    { id: '15:30:00', label: '03:30 PM' },
    { id: '16:00:00', label: '04:00 PM' },
    { id: '16:30:00', label: '04:30 PM' },
    { id: '17:00:00', label: '05:00 PM' },
    { id: '17:30:00', label: '05:30 PM' },
    { id: '18:00:00', label: '06:00 PM' },
    { id: '18:30:00', label: '06:30 PM' },
    { id: '19:00:00', label: '07:00 PM' },
    { id: '19:30:00', label: '07:30 PM' },
    { id: '20:00:00', label: '08:00 PM' },
    { id: '20:30:00', label: '08:30 PM' },
    { id: '21:00:00', label: '09:00 PM' },
    { id: '21:30:00', label: '09:30 PM' },
    { id: '22:00:00', label: '10:00 PM' },
    { id: '22:30:00', label: '10:30 PM' },
    { id: '23:00:00', label: '11:00 PM' },
    { id: '23:30:00', label: '11:30 PM' }
]
