export default {
    accountTagsMergeTags_title: "Combinar Etiquetas",
    accountTagsMergeTags_success: "Etiquetas combinadas exitosamente",
    accountTagsMergeTags_tag: "Etiqueta",
    accountTagsMergeTags_color: "Escoga Nombre y Color",
    accountTagsMergeTags_label: "Nombre",
    accountTagsMergeTags_nestUnder: "Anidar debajo",
    accountTagsMergeTags_typeToSearch: "Escriba para buscar...",

    accountTagsMergeTags_tag: "Etiqueta",
    accountTagsMergeTags_vehicles: "Vehículos",
    accountTagsMergeTags_drivers: "Conductores",
    accountTagsMergeTags_landmarks: "Landmarks",
    accountTagsMergeTags_users: "Usuarios",
    accountTagsMergeTags_forms: "Formularios",
    accountTagsMergeTags_geofences: "Geofences",
    accountTagsMergeTags_tags: "Seleccione etiquetas para combinar",
    accountTagsMergeTags_minTagsMessage: "Por favor seleccione al menos 2 etiquetas para combinar",
    accountTagsMergeTags_confirmation: '¿Está seguro que desea combinar {number_tags} etiquetas(s) con la etiqueta "{tag_name}"?',
    accountTagsMergeTags_errorParentTag: 'La etiqueta principal seleccionada no debe incluirse en los elementos para fusionar',
};