export default {
    accountTagsMergeTags_title: "Merge Tags",
    accountTagsMergeTags_success: "Tags merged successfully",
    accountTagsMergeTags_tag: "Tag",
    accountTagsMergeTags_color: "Color",
    accountTagsMergeTags_label: "Label",
    accountTagsMergeTags_nestUnder: "Nest Under",
    accountTagsMergeTags_typeToSearch: "Type to search...",

    accountTagsMergeTags_tag: "Tag",
    accountTagsMergeTags_vehicles: "Vehicles",
    accountTagsMergeTags_drivers: "Drivers",
    accountTagsMergeTags_landmarks: "Landmarks",
    accountTagsMergeTags_users: "Users",
    accountTagsMergeTags_forms: "Forms",
    accountTagsMergeTags_geofences: "Geofences",
    accountTagsMergeTags_tags: "Select tags to merge",
    accountTagsMergeTags_minTagsMessage: "Please select a least 2 tags to merge",
    accountTagsMergeTags_confirmation: 'Are you sure you want to merge {number_tags} tag(s) with the tag "{tag_name}"?',
    accountTagsMergeTags_errorParentTag: 'The parent tag selected must not be included into the items to merge',
};