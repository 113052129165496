//import { endpoint } from 'Globals/defaultValues'
import axios from 'axios';
import qs from 'qs';
let endpoint;
export const clientPOST = async (method, parameters, customEndpoint, customheaders, isUrlEncoded, sendToken = true) => {
	let headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

	const token = localStorage.getItem('IdToken');
	let finalEndpoint = endpoint;

	if (token && sendToken) {
		headers = { ...headers, Authorization: `${token}` };
	}
	if (customheaders) {
		headers = { ...headers, ...customheaders };
	}

	if (customEndpoint) {
		finalEndpoint = customEndpoint;
	}

	return await axios({
		method: 'post',
		url: finalEndpoint + method,
    data: isUrlEncoded ?qs.stringify(parameters): parameters,
		headers: headers,
	})
		.then((res) => {
			return new Promise((resolve) => {
				resolve(res.data);
			});
		})
		.catch((error) => {
			return new Promise((resolve, reject) => {
				reject(error);
			});
		});
};

export const clientGET = async (method, parameters, customEndpoint, sendToken = true) => {
	const token = localStorage.getItem('IdToken');
	let headers = { 'Content-Type': 'application/json' };
  if (token && sendToken) headers = { ...headers, Authorization: `${token}` };
  let finalEndpoint = endpoint;
  if (customEndpoint) {
		finalEndpoint = customEndpoint;
  }
	return await axios({
		method: 'get',
		url: finalEndpoint + method,
		params: parameters,
		headers: headers,
	})
		.then((res) => {
			return new Promise((resolve) => {
				resolve(res.data);
			});
		})
		.catch((error) => {
			return new Promise((resolve, reject) => {
				reject(error);
			});
		});
};
