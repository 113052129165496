export default {
    settingsInputLabel_add: "Create new Input Label",
    settingsInputLabels_createTitle:"Create new Input Label",
    settingsInputLabels_editTitle:"Edit Input Label",
    settingsInputLabels_columTitle:"Input Label",
    settingsInputLabels_minLengthMessage:'The field must contain minimum chars: ',
    settingsInputLabels_maxLengthMessage:'The field must contain maximum chars: ',
    settingsInputLabels_success:'Input Label saved',
    settingsInputLabels_deleteWarning:'Are you sure you want to delete the Input Labels?',
    settingsInputLabels_deleteTitle:'Delete Input Label',
    settingsInputLabels_deleted:'Input Label deleted',
    settingsInputLabel_search:'Search by Input Label',
    settingsInputLabel_inputONLabel:'Input ON Label',
    settingsInputLabel_InputOffLabel:'Input OFF Label',
};