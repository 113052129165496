import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// controls
import Table, { Column } from '../../table'

import {
  formatDatetime,
  getCategoryFilterType,
  getEntityType,
  useHandleExportReport,
  formatTimeDelta
} from 'Modules/reports/views/main/components/reportsTab/helper';

import { formatTimeHour } from 'Core/data/Helpers';


// hooks
import { useSidePanelMap, useTableReport } from 'Core/hooks';
// actions
import {
  reportsMainGenerateReportUpdate,
  clearRedux,
  reportsMainGenerateSubEvents,
  reportsMainGenerateReportProgress,
  reportsMainGenerateEvents
} from 'Redux/actions';

import AddressLandmark from "Modules/reports/views/main/components/reportsTab/.components/addressLandmark";

import './fsmTimeCard.scss';
import { GetFormatForMoment } from 'Components/uiControls/Timezone/tools';

const FSMTimeCardReport = (props) => {

  const { messages, report } = props;
  const dispatch = useDispatch();
  const { loading, data, filters, loadingSummary, timeZone, summary, loadingSubEvents, events } = report;
  const { filterEntity,  startRange, endRange } = filters;
  const [eventFilter, setEventFilter] = useState({});
  const [currentId, setCurrentId] = useState(null);
  const exportHandler = useHandleExportReport(report);
  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();

  const { user: { dateformat } } = useSelector((state) => state.securityRedux);
  const reportRef = useRef();
  useEffect(() =>{
    if(report){
      const newReport = JSON.parse(JSON.stringify(report));
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);

  const getDateFormated = (date, dateFormat = null)=>{
    const adjustDateFormat = dateFormat ? GetFormatForMoment(dateFormat) : 'YYYY-MM-DD'
    const dateFormated = moment(date).format(adjustDateFormat);
    return dateFormated
  }

  const idEventsPerDay = (selected,id) => {
    let currentReport = report
    delete currentReport.events
    dispatch(reportsMainGenerateReportProgress(currentReport))
    let events = {
      filters: {
        filterArray: [id],
        subPage: 1,
        subLimit: 10,
        startRange: moment(selected).startOf('day').utc(),
        endRange: moment(selected).endOf('day').utc()
      }
    }
    setEventFilter(events)
    dispatch(reportsMainGenerateSubEvents(report, events))
  }

  const renderExpandableRowSubEvents = (row) => {
    const total = summary?.summaryInfo?.timeCardTotals.find(item => item.date == row);
    return (
      <div style={{ marginBottom: 10 }}>
        {/**
         * Total Time Per day
         */}
        <Table
          loading={loadingSubEvents}
          data={[{ label: messages['generatedReport_totalTime'], duration: total?.duration || 0}]}
          totalItems={1}
          hideNumberOfRows={true}
          hideHeader={true}
          hiddenPagination={true}
        >
          <Column label={messages['generatedReport_totalTime']} >
            {(element) => <span >{emptyField(element.label)}</span>}
          </Column>
          <Column label={messages['generatedReport_eventName']} >
            {(element) => <span >{formatTimeHour(element.duration, messages)}</span>}
          </Column>
        </Table>
        <Table
          loading={loadingSubEvents}
          data={events?.items || []}
          totalItems={events?.total}
          onChangedPage={(page) => {
            let currentEvents = eventFilter
            currentEvents.filters.subPage = page
            dispatch(reportsMainGenerateSubEvents(report, currentEvents))
          }}
          noDataMessage={messages["generatedReport_noEvents"]}
          hideNumberOfRows={true}
          classNameColumns={'report-sub-events-columns'}
          centerPagination
          serverSideTable={true}
        >

          <Column label={messages['generatedReport_eventName']} >
            {(element) => <span >{emptyField(element.inOut)}</span>}
          </Column>
          <Column label={messages['generatedReport_timeAndDate']}>
            {(element) => <span>{formatDatetime(element.dateTime, "MMMM D, YYYY h:mm A", timeZone)}</span>}
          </Column>
          <Column label={messages['generatedReport_address']}>
            {(element) => (element.address && <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={element}/>)}
          </Column>
        </Table>

      </div>
    )
  }
  const emptyField = (value) => {
    return value || '- -';
  }
  const onChangeExpandable = (selected) => {
    if(!selected.noData) {
      setCurrentId(selected?.id);
      let currentReport = report
      delete currentReport.summary
      dispatch(reportsMainGenerateReportProgress(currentReport))
      let events = {
        filters: {
          filterArray: [selected.id],
          page: 1,
        }
      }
      dispatch(reportsMainGenerateEvents(report, events))
    }
  }
  // START SUMMARY
  const summaryTable = (items, noData) => {
    const total = items?.reduce((prev, next)=> {
      return prev + Number(next?.duration || 0)
    }, 0) || 0;
    return (
      <Fragment>
        <Table
          loading={loadingSummary}
          data={[{ label: messages['generatedReport_combinatedTotalTime'], duration: total}]}
          totalItems={1}
          hideNumberOfRows={true}
          hideHeader={true}
          hiddenPagination={true}
        >
          <Column label={messages['generatedReport_combinatedTotalTime']} >
            {(element) => <span >{emptyField(element.label)}</span>}
          </Column>
          <Column label={messages['generatedReport_eventName']} >
            {(element) => <span >{formatTimeHour(element.duration, messages)}</span>}
          </Column>
        </Table>
        {
          !noData && (
            <Table
              data={items || []}
              noDataMessage={messages["reportsMainRT_noTripsAvailable"]}
              propertyToShowExpandable="day"
              hiddenPagination
              classNameColumns="start-stop-idle-sub-table"
            >
              <Column
                label={messages['dates']}
                noSorting
              >
                {(element) => {
                  return <span>{element.date}</span>
                }}
              </Column>
              <Column
                label={messages['reportsMainRT_Time']}
                noSorting
              >
                {(element) => {
                  return <span>{`${formatTimeDelta(element.duration) || '0 min, 0 sec'}`}</span>
                }}
              </Column>
            </Table>
          )
        }

      </Fragment>
    )
  }
  let parametersTableSummary = useTableReport({
    data:  [],
    report,
    messages,
    hideHeader: true,
    hiddenPagination: true,
    renderExpandableRow: renderExpandableRowSubEvents,
    showExpandable: true
  });

  const renderExpandableRow = (row) => {
    const summaryParameters = {
      ...parametersTableSummary,
      data : summary?.summaryInfo?.dateList || [],
      onChangeSelector:null,
      placeholderSelector:null,
      hideSelectorUnitsDriver:true,
      classNameColumns:"table-report-main-sub-table",
      idRenderExpandableRow:(event)=> {idEventsPerDay(event,row?.id)}
    }
    return (
      <div style={{ width: '100%' }}>
        {summaryTable(summary?.summaryInfo?.timeCardTotals, row.noData)}
        <Table
          {...summaryParameters}
          noData={row.noData}
        >
          <Column
            label=""
            field="day"
            noSorting
          >
            {(element) => {
              return <strong>{getDateFormated(element, dateformat)}</strong>
            }}
          </Column>
          <Column/>
        </Table>
      </div>
    )
  }
  // END SUMMARY

  let parametersTable = useTableReport({
    data: data.items,
    report,
    messages,
    hideHeader: true,
    renderExpandableRow,
    onChangeExpandable,
    propertyToShowExpandable: 'id',
    classNameColumns: "table-report-main-expandible"
  });

  return (
    <Fragment>
      <Table
        {...parametersTable}
        {...exportHandler}
      >
        <Column noSorting>
          {(element) => {
            const type = getEntityType(report, messages)
            return <span><strong>{type}:  </strong>{`${element.driverName || ''}`}</span>
          }}
        </Column>
        <Column noSorting>
          {() => {
            const localTimeStart = moment.utc(startRange).local().format();
            const localTimeEnd = moment.utc(endRange).local().format();
            return (<div>
                      <span>
                        <span>
                          <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                        </span>
                      </span>
            </div>);
          }}
        </Column>
      </Table>
      <SideMapComponent hiddenButton hiddenSearchMap/>
    </Fragment>
  );
};

export default FSMTimeCardReport;
