import {
    FSM_ROUTES_STOP_SEARCH,
    FSM_ROUTES_STOP_SEARCH_RESPONSE,
    FSM_ROUTES_STOP_UPDATE_POSITION,
    FSM_ROUTES_STOP_UPDATE_POSITION_RESPONSE,
    FSM_ROUTES_STOP_DELETE,
    FSM_ROUTES_STOP_DELETE_RESPONSE,
    FSM_ROUTES_STOP_OPEN_DELETE,
    FSM_ROUTES_STOP_OPEN_CREATE_EDIT,
    FSM_ROUTES_STOP_CREATE,
    FSM_ROUTES_STOP_CREATE_RESPONSE,
    FSM_ROUTES_STOP_OPEN_IMPORT,
    FSM_ROUTES_STOP_IMPORT_ROUTE,
    FSM_ROUTES_STOP_IMPORT_ROUTE_RESPONSE
} from "Redux/actionTypes";



export const searchStop = (driverId,load) => {
    return {
        type: FSM_ROUTES_STOP_SEARCH,
        payload: { driverId,load },
    };
};

export const searchStopResponse = (data) => {
    return {
        type: FSM_ROUTES_STOP_SEARCH_RESPONSE,
        payload: data,
    };
};

export const createStop = (stop) => {
    return {
        type: FSM_ROUTES_STOP_CREATE,
        payload: stop,
    };
};

export const createStopResponse = (data) => {
    return {
        type: FSM_ROUTES_STOP_CREATE_RESPONSE,
        payload: data,
    };
};

export const importRouteStop = (stop) => {
    return {
        type: FSM_ROUTES_STOP_IMPORT_ROUTE,
        payload: stop,
    };
};

export const importRouteStopResponse = (data) => {
    return {
        type: FSM_ROUTES_STOP_IMPORT_ROUTE_RESPONSE,
        payload: data,
    };
};

export const updatePositionStop = (stops) => {
    return {
        type: FSM_ROUTES_STOP_UPDATE_POSITION,
        payload: stops,
    };
};

export const updatePositionStopResponse = (data) => {
    return {
        type: FSM_ROUTES_STOP_UPDATE_POSITION_RESPONSE,
        payload: data,
    };
};

export const deleteRouteStop = (stops) => {
    return {
        type: FSM_ROUTES_STOP_DELETE,
        payload: { stops },
    };
};

export const deleteRouteStopResponse = (data) => {
    return {
        type: FSM_ROUTES_STOP_DELETE_RESPONSE,
        payload: data,
    };
};


export const openStopDelete = (open, stops) => {
    return {
        type: FSM_ROUTES_STOP_OPEN_DELETE,
        payload: { open, stops }
    };
};

export const openStopCreateEdit = (open) => {
    return {
        type: FSM_ROUTES_STOP_OPEN_CREATE_EDIT,
        payload: { open }
    };
};

export const openStopImport = (open) => {
    return {
        type: FSM_ROUTES_STOP_OPEN_IMPORT,
        payload: { open }
    };
};

