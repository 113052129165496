// Merge Views
import mainAlertsView from 'Modules/alerts/views/main/lan/en';
import alertsTabView from 'Modules/alerts/views/main/components//alertsTab/lan/en';
import wizardAlert from 'Modules/alerts/components/wizardAlert/lan/en';
import inboxTab from 'Modules/alerts/views/main/components/inboxTab/lan/en';

let locales = {
  alertEvent_Asleep: 'Asleep',
  alertEvent_NoDriver: 'Camera Obstructed',
  alertEvent_HeadwayMonitoringWarning: 'Close Following',
  alertEvent_ForwardCollisionWarning: 'Collision Warning',
  alertEvent_CyclistCollision: 'Cyclist Warning',
  alertEvent_DayDreaming: 'Day Dreaming',
  alertEvent_Distracted: 'Distracted',
  alertEvent_Drowsy: 'Drowsy',
  alertEvent_HardBrake: 'Hard Brake',
  alertEvent_HardTurn: 'Hard Turn',
  alertEvent_IdleNoDriver: 'Idle No Driver',
  alertEvent_LaneDeparture: 'Lane Departure',
  alertEvent_NoSeatbelt: 'No Seatbelt',
  alertEvent_PassengersInside: 'Passenger Inside',
  alertEvent_PassengersNoSeatbelt: 'Passenger Seatbelt',
  alertEvent_PedestrianCollision: 'Pedestrian Detection',
  alertEvent_Speeding: 'Posted Speed',
  alertEvent_PotentialIncident: 'Potential Incident',
  alertEvent_PowerOff: 'Power Off',
  alertEvent_RapidAcceleration: 'Rapid Acceleration',
  alertEvent_Smoking: 'Smoking',
  alertEvent_OverMaxSpeed: 'Speed Threshold',
  alertEvent_StopSignViolation: 'Stop Sign Violation',
  alertEvent_SuddenStop: 'Sudden Stop',
  alertEvent_HeadwayMonitoringEmergency: 'Tailgating',
  alertEvent_UsingPhone: 'Using Phone',
  alertEvent_Yawn: 'Yawn',
  alertEvent_Crash: 'Crash',
  alertEvent_Manual: 'Manual'
};

locales = {
  ...locales,
  ...mainAlertsView,
  ...alertsTabView,
  ...wizardAlert,
  ...inboxTab
};

export default locales;
