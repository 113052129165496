import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    serviceItems: [],
    deleteServiceItems: null,
    loadingDelete: false,
    newServiceItems: null,
    loadingNewServiceItems: false,
    updateServiceItem: null,
    loadingUpdateServiceItem: false,
    error: null
};

const route = "modules/maintenance/modals/manageServiceItems/redux/slice";

export const maintenanceMSTGetServiceItems = createAsyncThunk("maintenance/manageServiceItems/getServiceItems", async (name, { rejectWithValue, dispatch }) => {
    try {
        let data = await clientQuery(
            `
            {
                serviceItems: searchMaintenanceServiceItems(openSearch: "${name}"){
                    id
                    name
                }
            }
            `,
            {},
            endpoints.GRAPHQL_GENERAL
        );
        if (data && data.serviceItems) {
            return (data.serviceItems);
        }
        else {
            throw "Error while getting maintenance service items";
        }
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceMSTGetServiceItems');
        return rejectWithValue(exc);
    }
}
)

export const maintenanceMSTDeleteServiceItems = createAsyncThunk("maintenance/manageServiceItems/deleteServiceItems", async (id, { rejectWithValue, dispatch }) => {
    try {
        const data = await clientMutation(
            `
                mutation {
                    deleteServiceItems: deleteMaintenanceServiceItem (
                        id: ${id}
                    )
                }
            `,
            {},
            endpoints.GRAPHQL_GENERAL
        );
        if (data && data.deleteServiceItems) {
            return (data.deleteServiceItems);
            //return(null)
        } else {
            throw 'Error while deleting maintenance service item';
        }
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'deleteServiceItems');
        return rejectWithValue(exc);
    }
}
)

export const maintenanceMSTCreateServiceItem = createAsyncThunk("maintenance/manageServiceItems/createServiceItem", async (payload, { rejectWithValue, dispatch }) => {
    const { name } = payload;
    try {
        let data = await clientMutation(
            `
                    mutation ($name: String!){
                        newServiceItems: createMaintenanceServiceItem(name: $name){
                            id
                            name
                        }
                    }
                `,
            {
                name
            },
            endpoints.GRAPHQL_GENERAL
        );

        if (data && data.newServiceItems) {
            return (data.newServiceItems);
            //return(null)
        } else {
            throw 'Error while creating maintenance service item';
        }
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceMSTCreateServiceItem');
        console.log('exc', exc)
        return rejectWithValue(exc);
    }
}
)

export const maintenanceMSTUpdateServiceItem = createAsyncThunk("maintenance/manageServiceItems/updateServiceItem", async (payload, { rejectWithValue, dispatch }) => {
    try {
        const { id, name } = payload
        let data = await clientMutation(
            `   
                mutation ($id: Int!, $name: String!){
                    updateServiceItem: updateMaintenanceServiceItem(id: $id, name:$name){
                        id
                        name
                    }
                }
            `,
            { id, name },
            endpoints.GRAPHQL_GENERAL
        );
        if (data && data.updateServiceItem) {
            return (data.updateServiceItem);

        } else {
            throw 'Error while updating maintenance service item';
        }
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'updateServiceItem');
        return rejectWithValue(exc);
    }
}
)

export const maintenanceManageServiceTasksRedux = createSlice({
    name: "maintenance/manageServiceItems",
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openModalManageServiceTasks(state, action) {
            return {
                ...state,
                open: action.payload.open
            }
        },
    },
    extraReducers: (builder) => {

        //maintenanceMSTGetServiceItems
        builder.addCase(maintenanceMSTGetServiceItems.pending, (state, action) => {
            state.serviceItems = [];
            state.loading = true
        });

        builder.addCase(maintenanceMSTGetServiceItems.fulfilled, (state, { payload }) => {
            state.serviceItems = payload
            state.loading = false;
        });

        builder.addCase(maintenanceMSTGetServiceItems.rejected, (state, action) => {
            state.serviceItems = [];
            state.loading = false;
            state.error = action.payload;
        });

        //maintenanceMSTDeleteServiceItems
        builder.addCase(maintenanceMSTDeleteServiceItems.pending, (state, action) => {
            state.deleteServiceItems = null;
            state.loadingDelete = true
        });

        builder.addCase(maintenanceMSTDeleteServiceItems.fulfilled, (state, action) => {
            state.deleteServiceItems = action.payload;
            state.loadingDelete = false
        });

        builder.addCase(maintenanceMSTDeleteServiceItems.rejected, (state, action) => {
            state.deleteServiceItems = null
            state.loadingDelete = false
            state.error = action.payload;
        });

        //maintenanceMSTDeleteServiceItems
        builder.addCase(maintenanceMSTCreateServiceItem.pending, (state, action) => {
            state.newServiceItems = null;
            state.loadingNewServiceItems = true;
        });

        builder.addCase(maintenanceMSTCreateServiceItem.fulfilled, (state, { payload }) => {
            state.newServiceItems = payload;
            state.loadingNewServiceItems = false;
        });

        builder.addCase(maintenanceMSTCreateServiceItem.rejected, (state, action) => {
            state.newServiceItems = null;
            state.loadingNewServiceItems = false;
            state.error = action.payload;
        });

        //maintenanceMSTUpdateServiceItem
        builder.addCase(maintenanceMSTUpdateServiceItem.pending, (state, action) => {
            state.updateServiceItem = null;
            state.loadingUpdateServiceItem = true;
        });

        builder.addCase(maintenanceMSTUpdateServiceItem.fulfilled, (state, { payload }) => {
            state.updateServiceItem = payload; //data
            state.loadingUpdateServiceItem = false;
        });

        builder.addCase(maintenanceMSTUpdateServiceItem.rejected, (state, action) => {
            state.updateServiceItem = null;
            state.loadingUpdateServiceItem = false;
            state.error = true;
        });
    }
})

const { actions, reducer } = maintenanceManageServiceTasksRedux;

// Extract and export each action creator by name
export const {
    reset,
    openModalManageServiceTasks
} = actions;

export default reducer;