//imports
import {
    FLEET_LANDMARK_CREATE_UPDATE_LANDMARK,
	FLEET_LANDMARK_CREATE_UPDATE_LANDMARK_RESULT,
	FLEET_LANDMARK_OPEN_MODAL,
    CLEAR_REDUX
} from 'Redux/actionTypes';


//initial values
const INIT_STATE = {
    open: false,
    lodingCreateEdit: false,
    createEditResponse: null,
    landmark: null,
    vehicle: null
}

//change redux object
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FLEET_LANDMARK_OPEN_MODAL:
            return { 
                ...state, 
                open: action.payload.open, 
                vehicle: action.payload.vehicle,
                landmark: action.payload.landmark
            };

        case FLEET_LANDMARK_CREATE_UPDATE_LANDMARK:
            return { 
                ...state, 
                lodingCreateEdit: true,
                createEditResponse: null
            };

        case FLEET_LANDMARK_CREATE_UPDATE_LANDMARK_RESULT:
            return { 
                ...state, 
                lodingCreateEdit: false,
                createEditResponse: action.payload.response
            };

        case CLEAR_REDUX:
            return ["", "FLEET_LANDMARK"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state }

        default:
      return state;
    }
};