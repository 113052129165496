export default {
    "vehicleView_vehicle": "Vehículo",
    "vehicleView_engineRunTime": "Horas De Motor (Total)",
    "vehicleView_odometer": "Odómetro",
    "vehicleView_diagnosticCode": "DTC",
    "vehicleView_dueStatus": "Vencido",
    "vehicleView_dueSoonStatus": "Prox a vencer",
    "vehicleView_scheduledStatus": "Programado",
    "vehicleView_pastDue": "Atrasado",
    "vehicleView_done": "Terminado",
    "vehicleView_advancedFilters": "Filtros Avanzados",
    "vehicleView_clearFilters": "Limpiar Filtros",
    "vehicleView_maintenanceStatus": "Estado De Mantenimiento",
    "vehicleView_select": "Seleccionar",
    "vehicleView_services": "Servicios",
    "vehicleView_vehicles": "Vehículos",
    "vehicleView_scheduleService": "Programar Servicio",
    "vehicleView_viewScheduledervices": "Ver Servicios Programados",
    "vehicleView_viewDiagnosticHistory": "Ver Historial de Diagnóstico",
    "vehicleView_viewCompleteMaintenance": "Completar Mantenimiento",
}