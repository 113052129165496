//View Keys
export default {
  realtimemaps_send_message_contact: 'Contacto',
  realtimemaps_send_message_subject: 'Asunto',
  realtimemaps_send_message_message: 'Mensaje',
  realtimemaps_send_message_send: 'Enviar',
  realtimemaps_send_message_select_option: "Seleccione al menos una opción",
  realtimemaps_send_message_select_option_contacts: "Contactos: Seleccione al menos una opción",
  realtimemaps_send_message_select_option_driver: "Conductores: Seleccione al menos una opción",
  realtimemaps_send_message_sent_successfully: "El correo electrónico ha sido enviado con éxito",
  realtimemaps_send_message_sent_error: "Los conductores seleccionados no tienen un teléfono móvil configurado, por favor, añada esta información antes de volver a enviar el mensaje",
  realtimemaps_send_message_drivers: "Conductores",
  realtimemaps_send_message_driverGroups: "Grupos de Conductor",
  realtimemaps_send_message_contacts: "Contactos",
};