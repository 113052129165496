import {
  VIDEO_MAIN_EVENTS_SEARCH_AVAILABLE_MEDIA,
  VIDEO_MAIN_EVENTS_SEARCH_AVAILABLE_MEDIA_RESPONSE,
  VIDEO_MAIN_EVENTS_UPDATE_EVENT,
  VIDEO_MAIN_EVENTS_RESTORE_AVAILABLE_MEDIA,
  VIDEO_MAIN_EVENTS_RESTORE_AVAILABLE_MEDIA_RESPONSE,
  VIDEO_MAIN_EVENTS_OPEN_MODAL_ADD_NOTES,
  CLEAR_REDUX,
} from 'Redux/actionTypes';

import { formatAvailableMedia } from 'Modules/video/.globals/helpers';

const INITIAL_STATE = {
  events: [],
  total: 0,
  loading: false,
  error: false,
  allFilters: {
    openSearch: "",
    limit: 10,
    offset: 0,
    events: [],
    resetSearch: true,
    entities: [],
    dateRange: {},
    hasNotes: null,
    starred: false,
    mediaDeleted: false,
    timeFrame: "",
    entity: null,
    eventsType: [],
    items: [],
    mediaType: "All",
    category: "all"
  },
  allFiltersStarred: {
    openSearch: "",
    limit: 10,
    offset: 0,
    eventsStarred: [],
    resetSearch: true,
    entities: [],
    dateRange: {},
    hasNotes: null,
    starred: false,
    mediaDeleted: false,
    timeFrame: "",
    entity: null,
    eventsType: [],
    items: [],
    mediaType: "All",
    category: "all"
  },
  allFiltersDeleted: {
    openSearch: "",
    limit: 10,
    offset: 0,
    eventsDeleted: [],
    resetSearch: true,
    entities: [],
    dateRange: {},
    hasNotes: null,
    starred: false,
    mediaDeleted: false,
    timeFrame: "",
    entity: null,
    eventsType: [],
    items: [],
    mediaType: "All",
    category: "all"
  },
  restoreResponse: null,
  loadingRestore: false,
  openAddNotesModal: false,
  addNotesModalData: null,
  resetTable: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VIDEO_MAIN_EVENTS_SEARCH_AVAILABLE_MEDIA:
      let selectFilters = 'allFilters'
      if(action.payload.starred){
        selectFilters = 'allFiltersStarred'
      }
      if(action.payload.mediaDeleted){
        selectFilters = 'allFiltersDeleted'
      }
      return {
        ...state,
        [selectFilters]: action.payload,
        events: [],
        eventsStarred: [],
        eventsDeleted: [],
        loading: true,
        error: false
      };

    case VIDEO_MAIN_EVENTS_SEARCH_AVAILABLE_MEDIA_RESPONSE:
      return {
        ...state,
        events: action.payload.resetSearch
          ? formatAvailableMedia(action.payload.items)
          : [...state.events, ...formatAvailableMedia(action.payload.items)],
        total: action.payload.total,
        loading: false,
        error: action.payload.error
      };

    case VIDEO_MAIN_EVENTS_UPDATE_EVENT:
      let newTotalStarred = state.totalStarred;
      let newEvents = state.events;
        newEvents = state.events.map(item => {
          if (item?.id === action?.payload?.id) {
            if (action?.payload?.starred != item?.starred) {
              newTotalStarred += (action?.payload?.starred ? 1 : -1);
            }

            return {
              ...item,
              ...action?.payload
            }
          }

          return item;
        });

      return { ...state, events: newEvents, totalStarred: newTotalStarred };

    case VIDEO_MAIN_EVENTS_RESTORE_AVAILABLE_MEDIA:
      return {
        ...state,
        events: [],
        eventsStarred: [],
        eventsDeleted: [],
        restoreResponse: null,
        loadingRestore: true,
      }

    case VIDEO_MAIN_EVENTS_RESTORE_AVAILABLE_MEDIA_RESPONSE:
      return {
        ...state,
        restoreResponse: action.payload.data,
        loadingRestore: false,
      }

    case VIDEO_MAIN_EVENTS_OPEN_MODAL_ADD_NOTES:
      return {
        ...state,
        openAddNotesModal: action.payload.data.open,
        addNotesModalData: action.payload.data.data
      }

    case CLEAR_REDUX:
      return ["", "AVAILABLE_MEDIA"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INITIAL_STATE }
        : { ...state };

    default:
      return state;
  }
}