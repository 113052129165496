import './context-menu.scss';
import React, { Component } from 'react';
import cx from 'classnames';

class ContextMenu extends Component {
    state = {
        visible: false,
    };

    constructor(props){
        super(props)
        //console.log("im beign constructed",props)
    }
    
    componentDidMount() {
        //document.addEventListener('contextmenu', this._handleContextMenu);
        document.addEventListener('click', this._handleClick);
        document.addEventListener('scroll', this._handleScroll);
        this._handleContextMenu.bind(this)
    }

    componentDidUpdate(prevProps) {

       if(prevProps.open != this.props.open )
       {
           this.setState({visible:this.props.open})
       }
    }

    componentWillUnmount() {
      //document.removeEventListener('contextmenu', this._handleContextMenu);
      document.removeEventListener('click', this._handleClick);
      document.removeEventListener('scroll', this._handleScroll);
    }
    
    _handleContextMenu = (event) => {

        event.preventDefault();

        this.setState({ visible: true });
    
        const clickX = event.clientX;
        const clickY = event.clientY;
        const screenW = window.innerWidth;
        const screenH = window.innerHeight;


        const rootW = this.root?.offsetWidth;
        const rootH = this.root?.offsetHeight;
        
        const right = (screenW - clickX) > rootW;
        const left = !right;
        const top = (screenH - clickY) > rootH;
        const bottom = !top;
        
        if (right && this.root) {
            this.root.style.left = `${clickX + 5}px`;
        }
        
        if (left && this.root) {
            this.root.style.left = `${clickX - rootW - 5}px`;
        }
        
        if (top && this.root) {
            this.root.style.top = `${clickY + 5}px`;
        }
        
        if (bottom && this.root) {
            this.root.style.top = `${clickY - rootH - 5}px`;
        }

    };

    _handleClick = (event) => {

        if(this.props.exitOutside){
            const wasOutside = !this.root.contains(event.target)
            if (wasOutside ) this.setState({ visible: false, });
        }       

    };

    _handleScroll = () => {

        const { visible } = this.state;
        
        if (visible) this.setState({ visible: false, });

    };
    
    render() {

        const { visible } = this.state;
        
        return(
            this.props.children ?
            <div ref={ref => {this.root = ref}} style={{ display: visible? "block":"none", ...this.props.style }}  
                 className={cx('contextMenu', this.props.className  )} >
                { this.props.children }
            </div>
             :
             <div ref={ref => {this.root = ref}} style={{ display: visible? "block":"none", ...this.props.style }}
                className={cx('contextMenu', this.props.className)} >
                <div className="contextMenu--option">Not implemented children</div>
                <div className="contextMenu--separator" />
                <div className="contextMenu--option">Not implemented children</div>
            </div>
        )
    }
}

export default ContextMenu;

