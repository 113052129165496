export default {
    fleet_inputs_modal_title: 'Unit Outputs',
    fleet_inputs_ivalid_title: 'Wait',
    fleet_inputs_ivalid_message: 'Can not get data from this unit',
    back: 'back',
    fleet_inputs_title: 'TITLE',
    fleet_inputs_type: 'TYPE',
    fleet_inputs_label: 'Label',
    fleet_send_on: 'Send On',
    fleet_send_off: 'Send Off',
    fleet_send_pulse: 'Send Pulse',
    fleet_send_single_pulse: 'Single Pulse',

    fleet_output: 'Output',

    fleet_validation_outputs_message:"Outputs updated",
    fleet_validation_outputs_title:"Success",

    fleet_invalid_outputs_message:"There are not outputs for this unit",
    fleet_invalid_outputs_title:"Wait",

    fleetModalOutput_label: "Label",
    fleetModalOutput_title: "Title",
};
