import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';

// Actions
import {
  reportsMainGenerateReportProgress
} from 'Redux/actions';

function* getJBDTCDetailsReport(report, event) {
  const { filters , name, reportTimeRanges} = report;
  const { otherFilters } = filters
  
  let minutes = otherFilters.minute ? otherFilters.minute * 60 : 0
  const { filterType, filterArray, page, rowsPerPage, sortField, sortOrder, startRange, endRange } = event.filters;
  try {
    let data = yield call(
      clientQuery,
      `query( $conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getJBDTCDetailsReport(conditions: $conditions, limit: $limit, offset: $offset) {
          total
          limit
          offset
          category
          items {
            date
            dateTime    
            spn
            pid
            fmi
            occurrence
            status   
          }
        }
      }`,
      {
        conditions: {
          reportName: name,
          category: {
            field: filterType,
            value: filterArray
          },
          dateRange: {
            fields: [
              "unitTime"
            ],
            GTE: startRange,
            LTE: endRange,
            reportTimeRanges
          },
          sortField: {
            field: sortField || "deviceId",
            order: sortOrder === 'desc' ? 'DESC' : 'ASC'
          },
          integerRanges: {
            fields: [
              "idle"
            ],
            GTE: minutes,
            LTE: 0
          }
        },
        limit: rowsPerPage,
        offset: page
      },
      endpoints.GRAPHQL_PX_REPORTING,
    );
  
    if (data?.Report) {
      report.summary = data.Report
      report.loadingSummary = false
      yield put(reportsMainGenerateReportProgress(report))
    } else {
      report.loadingSummary = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  } catch (exc) {
    report.loadingSubEvents = false
    ExceptionManager(exc, 'src/modules/reports/views/main/components/reportsTab/.components/reports/idleReport', 'getJBDTCDetailsReport');
    yield put(reportsMainGenerateReportProgress(report))
  }
}

export default getJBDTCDetailsReport;