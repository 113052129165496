// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  FSM_ROUTES_STOP_SEARCH,
  FSM_ROUTES_STOP_UPDATE_POSITION,
  FSM_ROUTES_STOP_DELETE,
  FSM_ROUTES_STOP_CREATE,
  FSM_ROUTES_STOP_IMPORT_ROUTE
} from "Redux/actionTypes";

// Actions
import {
  searchStopResponse,
  updatePositionStopResponse,
  deleteRouteStopResponse,
  createStopResponse,
  importRouteStopResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";


function* searchStop({ payload }) {

  try {

    const query = `
    query searchFsmStop ($driverId: Int, $limit: Int, $offset: Int) {
      searchFsmStop (driverId: $driverId, limit: $limit, offset: $offset) {
          items {
              id
              status
              statusId
              date
              name
              latitude
              longitude
              description
              address
              driver {
                  name
                  groupName
              }
              sequence
          }
          total
      }
  }
        `;

    const response = yield call(
      clientQuery,
      query,
      {
        driverId: payload?.driverId
      },
      endpoints.GRAPHQL_GENERAL
    );
    if (!response && !response.searchFsmStop) {
      throw "Error while getting  stops list";
    }
    let stops = response.searchFsmStop;
    yield put(searchStopResponse({ stops, error: false }));
  } catch (exc) {
    yield put(searchStopResponse({ stops: [], error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "searchStop"
    );
  }
}

function* createStops({ payload }) {
  try {

    const query = `mutation createFsmStop ($statusId: Int!, $name: String,$address: String, $latitude: String, $longitude: String, $description: String, $sequence: Int, $driverId: Int!) {
                    createFsmStop (statusId: $statusId, name: $name, address: $address,latitude: $latitude, longitude: $longitude, description: $description, sequence: $sequence, driverId: $driverId) {
                        id
                        status
                        statusId
                        name
                        latitude
                        longitude
                        description
                        address
                        driver {
                            name
                            groupName
                        }
                        sequence
                    }
                }`;



    const response = yield call(
      clientMutation,
      query,
      payload,
      endpoints.GRAPHQL_GENERAL,
    );


    if (!response && !response.createFsmStop) {
      throw "Error while getting  positions stops list";
    }

    let stop = response.createFsmStop;
    yield put(createStopResponse({ stop, error: false }));
  } catch (exc) {
    yield put(createStopResponse({ stop: [], error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "createStops",
      true
    );
  }
}

function* importRouteStops({ payload }) {

  try {

    const query = `
    mutation importFsmStop ($routeId: Int, $driverId: Int!) {
      importFsmStop (routeId: $routeId, driverId: $driverId)
  }`;



    const response = yield call(
      clientMutation,
      query,
      payload,
      endpoints.GRAPHQL_GENERAL,
    );


    if (!response && !response.importFsmStop) {
      throw "Error while getting  positions import";
    }
    const imports = response.importFsmStop;
    yield put(importRouteStopResponse({ imports, error: false }));
  } catch (exc) {
    yield put(importRouteStopResponse({ imports: false, error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "importRouteStops",
      true
    );
  }
}

function* updatePositionStops({ payload }) {

  try {

    const query = `
                    mutation updatePositionFsmStop ($stops: [FsmStopPosition]) {
                      updatePositionFsmStop (stops: $stops)
                  }`;



    const response = yield call(
      clientMutation,
      query,
      {
        stops: payload
      },
      endpoints.GRAPHQL_GENERAL,
    );


    if (!response && !response.updatePositionFsmStop) {
      throw "Error while getting  positions stops list";
    }
    let stops = response.updatePositionFsmStop;
    yield put(updatePositionStopResponse({ stops, error: false }));
  } catch (exc) {
    yield put(updatePositionStopResponse({ stops: [], error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "updatePositionStops"
    );
  }
}

function* deleteFsmStops({ payload }) {

  const { stops } = payload;

  try {

    const { stops } = payload;


    let mutations = [];
    stops?.forEach((item, index) => {
      mutations.push(`
            stop${index}: deleteFsmStop(id: ${item?.id})
        `);
    });

    let mutation = `mutation{${mutations.join("")}}`;

    const response = yield call(
      clientMutation,
      mutation,
      {},
      endpoints.GRAPHQL_GENERAL,
    );

    if (!response || !response.stop0) throw `Error while deleting Stops, ${stops}`;



    yield put(deleteRouteStopResponse({ data: response.stop0, error: false }));
  } catch (exc) {
    yield put(deleteRouteStopResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "deleteFsmStops"
    );
  }
}


function* watchMethods() {
  yield takeEvery(FSM_ROUTES_STOP_SEARCH, searchStop);
  yield takeEvery(FSM_ROUTES_STOP_UPDATE_POSITION, updatePositionStops);
  yield takeEvery(FSM_ROUTES_STOP_DELETE, deleteFsmStops);
  yield takeEvery(FSM_ROUTES_STOP_CREATE, createStops);
  yield takeEvery(FSM_ROUTES_STOP_IMPORT_ROUTE, importRouteStops);


}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}