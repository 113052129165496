import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { clientMutation, clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

//STATE INITIAL
const INIT_STATE = {
  historyIncidents: [],
  loadingHistoryIncidents: false,
  errorHistoryIncidents: false,
  totalHistoryIncidents: 0,

  resDelete: null,
  loadingResDelete: false,
  errorResDelete: null,

  resReactive: null,
  loadingResReactive: false,
  errorResReactive: null,
};

const route = "Modules/incidents/history/redux/slice";

export const searchHistoryIncidents = createAsyncThunk(
  "incidents/historyList",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const {
      limit,
      offset,
      conditions,
      sort
    } = payload;

    try {
      const result = await clientQuery(
        `
        query searchIncidents ($conditions: [IncidentSearchInput], $sort: [IncidentSortInput], $limit: Int, $offset: Int) {
            data: searchIncidents (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                items{
                    id
                    unitId
                    name
                    start
                    end
                    shortcode
                    note
                    config
                    systemAlertId
                    assignments
                    label
                    subscribers
                    incidentLog{
                        reportedBy
                        endedBy
                    }
                    reading{
                        unitTime
                        deviceLabel
                        latitude
                        longitude
                    }
                }
                total
            }
        }`,
        {
          limit,
          offset,
          conditions,
          sort,
        },
        endpoints.GRAPHQL_GENERAL_V2
      );

      return {
        ...result?.data
      };
    } catch (exc) {
      ExceptionManager(exc, route, "incidents");
      return rejectWithValue("There is a problem to get the history incidents");
    }
  }
);

export const deleteHistoryIncidents = createAsyncThunk(
  "incidents/deleteHistoryIncidents",
  async (payload, { rejectWithValue, dispatch }) => {
    const { ids } = payload;

    try {
      const data = await clientMutation(
        `mutation deleteIncidents ($ids: [Int]!) {
          deleteIncidents (ids: $ids)
        }`,
        {
          ids
        },
        endpoints.GRAPHQL_GENERAL_V2
      );
      return data?.deleteIncidents;
    } catch (exc) {
      ExceptionManager(exc, route, "incident/deleteHistoryIncidents");
      return rejectWithValue(exc);
    }
  }
);

export const reactiveIncident = createAsyncThunk(
  "incidents/reactiveIncident",
  async (payload, { rejectWithValue, dispatch }) => {
    const { id, unitId } = payload
    try {
      const data = await clientMutation(
        `mutation reactiveIncident ($id: Int!, $unitId: Int!) {
          reactiveIncident (id: $id, unitId: $unitId) { id }
        }`,
        {
          id,
          unitId
        },
        endpoints.GRAPHQL_GENERAL_V2
      );

      return data.reactiveIncident;
    } catch (exc) {
      ExceptionManager(exc, route, "incident/reactiveIncident");
      return rejectWithValue(exc);
    }
  }
);


//Slice
const historyIncidentsRedux = createSlice({
  name: "historyIncidents",
  initialState: INIT_STATE,
  reducers: {
    reset(state, action) {
      return action.payload
        ? { ...state, ...action.payload }
        : { ...INIT_STATE };
    }
  },
  extraReducers: (builder) => {
    //searchHistoryIncidents
    builder.addCase(searchHistoryIncidents.pending, (state, action) => {
      state.loadingHistoryIncidents = true;
      state.errorHistoryIncidents = null;
      state.historyIncidents = [];
    });
    builder.addCase(searchHistoryIncidents.fulfilled, (state, action) => {
      state.loadingHistoryIncidents = false;
      state.errorHistoryIncidents = null;
      state.totalHistoryIncidents = action?.payload?.total;
      state.historyIncidents = action?.payload?.items
    });
    builder.addCase(searchHistoryIncidents.rejected, (state, action) => {
      state.loadingHistoryIncidents = false;
      state.historyIncidents = [];
      state.totalHistoryIncidents = 0
      state.errorHistoryIncidents = action?.payload;
    });
     //deleteHistoryIncidents
     builder.addCase(deleteHistoryIncidents.pending, (state, action) => {
      state.loadingResDelete = true;
      state.resDelete = null;
      state.errorResDelete = null
    });
    builder.addCase(deleteHistoryIncidents.fulfilled, (state, action) => {
      state.loadingResDelete = false;
      state.errorResDelete = null;
      state.resDelete = action?.payload
    });
    builder.addCase(deleteHistoryIncidents.rejected, (state, action) => {
      state.loadingResDelete = false;
      state.errorResDelete = null;
      state.resDelete = null;
    });

    //reactiveIncident
    builder.addCase(reactiveIncident.pending, (state, action) => {
      state.loadingResReactive = true;
      state.resReactive = null;
      state.errorResReactive = null
    });
    builder.addCase(reactiveIncident.fulfilled, (state, action) => {
      state.loadingResReactive = false;
      state.errorResReactive = null;
      state.resReactive = action?.payload
    });
    builder.addCase(reactiveIncident.rejected, (state, action) => {
      state.loadingResReactive = false;
      state.errorResReactive = action?.payload;
      state.resReactive = null;
    });
  },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = historyIncidentsRedux;

// Extract and export each action creator by name
export const { reset } = actions;

export default reducer;
