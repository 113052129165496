import {
    FSM_ROUTES_STOP_SEARCH,
    FSM_ROUTES_STOP_SEARCH_RESPONSE,
    FSM_ROUTES_STOP_UPDATE_POSITION,
    FSM_ROUTES_STOP_UPDATE_POSITION_RESPONSE,
    FSM_ROUTES_STOP_DELETE,
    FSM_ROUTES_STOP_DELETE_RESPONSE,
    FSM_ROUTES_STOP_OPEN_DELETE,
    FSM_ROUTES_STOP_OPEN_CREATE_EDIT,
    FSM_ROUTES_STOP_CREATE,
    FSM_ROUTES_STOP_CREATE_RESPONSE,
    FSM_ROUTES_STOP_OPEN_IMPORT,
    FSM_ROUTES_STOP_IMPORT_ROUTE,
    FSM_ROUTES_STOP_IMPORT_ROUTE_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loading: false,
    Stops: {},
    response: {},
    responseDelete: {},
    responseImport: {},
    stops: [],
    loadingImport: false,
    loadingUpdate: false,
    loadingDelete: false,
    openDelete: false,
    openImport: false,
    openCreateEdit: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case FSM_ROUTES_STOP_SEARCH:
            let paramsLoad = action?.payload?.load ? {} : { loading: true, Stops: {} };
            return {
                ...state,
                ...paramsLoad
            };

        case FSM_ROUTES_STOP_SEARCH_RESPONSE:
            return {
                ...state,
                loading: false,
                Stops: action?.payload
            };

        case FSM_ROUTES_STOP_UPDATE_POSITION:
            return {
                ...state,
                loadingUpdate: true,
                response: {}
            };

        case FSM_ROUTES_STOP_UPDATE_POSITION_RESPONSE:
            return {
                ...state,
                loadingUpdate: false,
                response: action.payload
            };

        case FSM_ROUTES_STOP_CREATE:
            return {
                ...state,
                loadingUpdate: true,
                response: {}
            };

        case FSM_ROUTES_STOP_CREATE_RESPONSE:
            return {
                ...state,
                loadingUpdate: false,
                response: action.payload
            };

        case FSM_ROUTES_STOP_IMPORT_ROUTE:
            return {
                ...state,
                responseImport: {},
                loadingImport: true
            };

        case FSM_ROUTES_STOP_IMPORT_ROUTE_RESPONSE:
            return {
                ...state,
                responseImport: action.payload,
                loadingImport: false
            };


        case FSM_ROUTES_STOP_DELETE:
            return {
                ...state,
                loadingDelete: true,
                responseDelete: false
            };

        case FSM_ROUTES_STOP_DELETE_RESPONSE:
            return {
                ...state,
                loadingDelete: false,
                responseDelete: action.payload?.data
            };


        case FSM_ROUTES_STOP_OPEN_DELETE:
            return {
                ...state,
                openDelete: action.payload?.open,
                stops: action.payload?.stops,
            };


        case FSM_ROUTES_STOP_OPEN_CREATE_EDIT:
            return {
                ...state,
                openCreateEdit: action.payload?.open
            };

        case FSM_ROUTES_STOP_OPEN_IMPORT:
            return {
                ...state,
                openImport: action.payload?.open
            };

        case CLEAR_REDUX:
            return ["", "FSM_ROUTES_STOP"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return state;
    }
};