//View Keys
export default {
  mapTagFilter_vehicles: 'Vehículos',
  mapTagFilter_landmark: 'Puntos de Referencia',
  mapTagFilter_geofences: 'Geocercas',
  mapTagFilter_total: 'Total',
  mapTagFilter_showAll: 'Mostrar Todas las Etiquetas',
  mapTagFilter_showAll_vehicles: 'Mostrar Todos los Vehículos',
  mapTagFilter_showAll_landmarks: 'Mostrar Todos los Puntos de Referencia',
  mapTagFilter_showAll_geofences: 'Mostrar Todas las Geocercas',
  mapTagFilter_tags_total: 'Todas las Etiquetas',
  mapTagFilter_tags_visible: 'Etiquetas Visibles',
  mapTagFilter_tags_hidden: 'Etiquetas Ocultas',
  mapTagFilter_tags_have_vehicles: 'La Etiqueta contiene Vehículos',
  mapTagFilter_tags_have_landmarks: 'La Etiqueta contiene Puntos de Referencia',
  mapTagFilter_tags_have_geofences: 'La Etiqueta contiene Geocercas',
};
