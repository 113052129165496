import { call, put } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';
// Actions
import { reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate } from 'Redux/actions';

import {
    baseReportConditions,
    generateReportInstance, getCategoryFilter,
    getTimeZone,
    handleAsyncReport,
    isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getDriverMileageSummaryReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
    const { timeZone, sendByEmail, emailsTo, timeframeName, categoryName, name, delayed, dataResultPath } = report;
    const { filterType, filterArray, startRange, endRange, unitsSelected, accumulateData = true, dates } = report.filters
    const baseConditionsQuery = baseReportConditions({
        report,
        name,
        dataResultPath,
        timeZone,
        delayed,
        unitsSelected,
        timeframeName,
        filterType,
        filterArray,
        categoryName,
        startRange,
        endRange,
        dates
    })
    try {
        let payload = {
            conditions: {
                ...baseConditionsQuery,
                sortField: {
                    field: sortField || "deviceId",
                    order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                }
            },
            limit: rowsPerPage,
            offset: page
        };

        let addColumnDriver = [];
        addColumnDriver.push(...[{
            column: "idLabel",
            header: "Driver/Unit"
        }, {
            "column": "totalDistance",
            "header": "Mileage"
        }])

        payload = {
            ...payload,
            limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
            offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
            conditions: {
                ...payload.conditions,
                exportOptions: {
                    sendByEmail,
                    emailsTo,
                    format: {
                        fileFormat: report?.exportType || "json",
                        formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
                        durationFormat: report?.durationFormat
                    },
                    culture: report.lang,
                    timeZone: getTimeZone(timeZone),
                    folderName: "px-customer-reports",
                    filePrefixName: "driverMileageSummaryReport",
                    columns: [
                        ...addColumnDriver
                    ]
                }
            }
        }
        let data = yield call(
            clientQuery,
            `query getDriverMileageSummaryReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
Report: getDriverMileageSummaryReport (conditions: $conditions, limit: $limit, offset: $offset) {
${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
                `reportInstanceId`
                : `
                items {
                    recordInfo {
                        entityId
                        entityLabel
                        tagId
                        tagLabel
                        startDate
                        endDate
                        recordType
                        groupLabel
                        groupIndex
                        groupTotal
                        groupTagColor
                    }
                    recordDetail {
                        deviceId
                        deviceLabel
                        driverId
                        driverName
                        unitId
                        distance
                        isKPH
                        totalDistance
                        noData
                    }
                }
                total
                limit
                offset
                category
                reportInstanceId
                `}
            }
        }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
        yield handleAsyncReport(report, data, accumulateData);
    } catch (exc) {
        ExceptionManager(exc, 'reports/getDriverMileageSummaryReport', 'getDriverMileageSummaryReport');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}

export default getDriverMileageSummaryReport;

