import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {isNumber} from 'lodash';
//controls
//helpers
import {
  decimalHandler,
  formatDateFromFilter,
  formatDatetime,
  getGroupId,
  getNameUnitDriverTag,
  isKPHLower,
  useHandleExportReport, getMethodToFormatDuration
} from 'Modules/reports/views/main/components/reportsTab/helper';

//actions
import {reportsMainGenerateReportUpdate} from 'Redux/actions'

//styles
import './advancedSummaryReport.scss';
import {useReportsSelector} from "Core/hooks";
import {Grid} from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InfiniteScrollTable from "Modules/reports/views/main/components/reportsTab/.components/infiniteScrollTable";
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";
import { isKPHAcronym } from '../../../helper';

//constants
const ROW_PER_PAGE = 25;

const AdvancedSummaryReport = (props) => {
  const {messages, report, user, copyReportToSetup, openReportSetup, scheduleReport, isActive} = props;
  const { durationFormat } = user;
  const dispatch = useDispatch();
  const [lastEntity, setLastEntity] = useState({});
  const {loading, asyncLoading, data, filters, timeZone} = report
  const {filterEntity, filterArray, filterType} = filters;
  const exportHandler = useHandleExportReport(report)

  const startDayFilter = formatDateFromFilter(report.filters.startRange, timeZone)
  const endDayFilter = formatDateFromFilter(report.filters.endRange, timeZone)

  const reportTableSelect = useReportsSelector(report, startDayFilter, endDayFilter, `MMMM DD, YYYY hh:mm A`, filterArray, filterType, timeZone)

  const reportRef = useRef();
  const entitiesArrayRef = useRef();
  const detailReportTableRef = useRef();
  const lastEntityRef = useRef({});

  const unitDriverSelected = reportTableSelect.valueSelecteEntity?.length > 0 ? reportTableSelect.valueSelecteEntity : null;
  const dateSelected = reportTableSelect.dateSelected?.length > 0 ? reportTableSelect.dateSelected : null;

  useEffect(() => {
    if (report) {
      const newReport = JSON.parse(JSON.stringify(report));
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);

  useEffect(() => {
    if(isActive){
      reportTableSelect.onChangeResetCache();
    }
  }, [isActive])

  const durationFormatTime = getMethodToFormatDuration(durationFormat);
  const buildTableHeader = () => {
    return (
        <Grid container item direction={"row"}
              className={"table-scroll-reports-title-details"}>
          <Grid item sm={3} className={'reports-cut-text'}>
            {messages['generatedReport_category']}
          </Grid>
          <Grid item sm={9} className={'reports-cut-text'}>
            {messages["generatedReport_totals"]}
          </Grid>
        </Grid>
    )
  }
  const TableViewGroup = ({index, style, content, id, previousItem}) => {
    const element = content?.recordInfo;
    const recordInfo = content?.recordInfo;
    return (
        <div
            className="listItem groupItem"
            style={style}
            id={id}
        >

          <Grid container direction={"column"} alignItems={"flex-start"}
                className='table-scroll-reports-group'>
            <Grid container item className='table-scroll-reports-header'>
              <Grid item sm={3}>

                {
                  element?.tagId > 0 ?
                      (<LocalOfferIcon
                          style={{
                            fontSize: "16.8px",
                            color: `#${element.groupTagColor && element.groupTagColor != "ffffff" ? element.groupTagColor : "000"}`,
                            position: 'absolute'
                          }}/>)
                      : ''
                }
                <span
                    className={`color-text-black ${element?.tagId > 0 ? 'report-home-header-padding' : ''}`}>{`${getNameUnitDriverTag(recordInfo?.groupLabel, messages)} `}</span>
                {
                    report?.filters?.filterArray.length !== 1 && (<span  className={'color-text-black report-home-header-padding-text'}>{` (${element?.tagId > 0 ? `${getNameUnitDriverTag(filterEntity, messages)} ` : ''}${recordInfo?.groupIndex} ${messages['OF']} ${recordInfo?.groupTotal})`}</span>)
                }
              </Grid>
              <Grid item sm={9}>
                <span className={'color-text-black'}>{formatDatetime(report.filters.startRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} - {formatDatetime(report.filters.endRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} ({getAbbvTimezone(timeZone)})</span>
              </Grid>
            </Grid>
            <div className='table-scroll-reports-separator'></div>
            <Grid item>
              <p className='table-scroll-reports-label'>{element?.entityLabel}</p>
            </Grid>
          </Grid>
          {buildTableHeader()}
        </div>
    )
  }

  const TableViewDetail = ({index, style, content, id, previousItem}) => {

    const element = content?.recordDetail;
    let distance = `${decimalHandler(element.distance || 0, messages['reportsMainRT_notAvailable'])} ${isNumber(Number(element.distance)) ? messages[isKPHAcronym(element.isKPH)] : ''}`;
    distance = getValueNoDataFromRow(distance, element.noData);

    return (
        <div
            className="listItem detailItem"
            style={style}
            id={id}
        >
          <Grid container direction={"column"} style={{marginBottom: "10px"}}>
            {
              !element.noData ? (
                    <Grid container item direction={"row"}
                          style={{paddingTop: "1px", paddingBottom: "1px"}}>
                      <Grid item sm={3} className={'reports-cut-text'}>
                        <Grid container item direction={"column"}
                              style={{paddingTop: "1px", paddingBottom: "1px"}}>
                          <Grid item className={"report-ctn-text"}>
                            {messages['generatedReport_driveTime']}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {messages['generatedReport_stopTime']}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {messages['generatedReport_totalStops']}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {messages['generatedReport_averageStopTime']}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {messages['generatedReport_idleTime']}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {messages['generatedReport_totalIdles']}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {messages['generatedReport_averageIdleTime']}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {messages['generatedReport_startOdometer']}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {messages['generatedReport_endOdometer']}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {messages['generatedReport_distance']}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={9} className={'reports-cut-text'}>
                        <Grid container item direction={"column"}
                              style={{paddingTop: "1px", paddingBottom: "1px"}}>
                          <Grid item className={"report-ctn-text"}>
                            {getValueNoDataFromRow(durationFormatTime(element.driveTime || 0, messages), element.noData, true)}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {getValueNoDataFromRow(durationFormatTime(element.stopTime || 0, messages), element.noData)}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {getValueNoDataFromRow(element.stops || 0, element.noData)}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {getValueNoDataFromRow(durationFormatTime(element.averageStopTime || 0, messages), element.noData)}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {getValueNoDataFromRow(durationFormatTime(element.idleTime || 0, messages), element.noData)}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {getValueNoDataFromRow(element.idles || 0, element.noData)}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {getValueNoDataFromRow(durationFormatTime(element.averageIdleTime || 0, messages), element.noData)}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {getValueNoDataFromRow(decimalHandler(element.startOdometer || 0, messages['reportsMainRT_notAvailable']), element.noData)}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {getValueNoDataFromRow(decimalHandler(element.endOdometer || 0, messages['reportsMainRT_notAvailable']), element.noData)}
                          </Grid>
                          <Grid item className={"report-ctn-text"}>
                            {getValueNoDataFromRow(distance, element.noData)}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
              ) : (
                  <span>{messages['reportsMainRT_noDataEvent']}</span>
              )
            }
          </Grid>
        </div>
    )
  }

  const getValueNoDataFromRow = (value, noData, showMessage) => {
    if (noData) {
      return showMessage ? messages['reportsMainRT_noDataEvent'] : '';
    }
    return value;
  }

  const renderItems = (parameters) => {
    const {content} = parameters;
    const type = content.recordInfo?.recordType;
    if (type === "GROUP") {
      const groupId = getGroupId(content)
      const entitiesArray = entitiesArrayRef.current || {};
      if (!entitiesArray[groupId]) {
        const newEntityArray = {...entitiesArray};
        newEntityArray[groupId] = content;
        entitiesArrayRef.current = newEntityArray
      }
      // skip the first group beacuse the first group is replaced with the sticky header
      if (parameters.index === 0) {
        return <></>;
      }
      return (
          <TableViewGroup {...parameters}></TableViewGroup>
      )
    } else if (type === "DETAIL") {
      return <TableViewDetail {...parameters}></TableViewDetail>;
    }

    return (
        <div>
          unknown type
        </div>
    )
  }

  const renderHeader = () => {
    const groupId = getGroupId(lastEntity);
    const entitiesArray = entitiesArrayRef.current || {};
    const itemHeader = entitiesArray[groupId];

    if (itemHeader) {
      return (
          <TableViewGroup
              style={{
                backgroundColor: '#fff',
                position: 'sticky',
                top: 0,
                zIndex: 2

              }}
              content={itemHeader}
              id={itemHeader?.recordInfo?.entityId}
          ></TableViewGroup>
      );
    }
    return;
  };

  const onStartIndex = (index) => {
    const item = data?.items[index];
    if(item) {
      if(item.recordInfo?.entityId !== lastEntityRef.current.recordInfo?.entityId || item.recordInfo?.tagId !== lastEntityRef.current.recordInfo?.tagId){
        setLastEntity(item)
      }
      lastEntityRef.current = item
    }
  }

  const findTag = filterArray?.findIndex((item) => item.name === "Tag")
  const hasTag = findTag >= 0;
  const type = hasTag ? messages['generatedReport_tag'] :
      (report?.filters?.filterType === "deviceId" ? messages['generatedReport_unit'] : messages['generatedReport_driver']);

  return (
      <>
        <InfiniteScrollTable
            refContainer={detailReportTableRef}
            renderItems={renderItems}
            loading={asyncLoading || loading}
            data={data.items || []}
            total={data.total}
            reportTitle={messages['reportsMainRT_advancedSummaryReport']}
            editReport={()=> {
              copyReportToSetup({...report, data: []})
            }}
            onSchedule={() => {
              scheduleReport(report)
            }}
            onCreate={openReportSetup}
            tableContainerClass={"detailed-report-reports-container"}
            type={type}
            handleNewPageLoad={(page) => {
              let currentReport = {...report};
              currentReport.exportType = "json";
              currentReport.filters = {
                ...currentReport.filters,
                unitsSelected: unitDriverSelected?.id != -99 ? unitDriverSelected: null,
                accumulateData: true,
                dateSelected: dateSelected?.id != -99 ? unitDriverSelected: null,
              };
              dispatch(reportsMainGenerateReportUpdate(currentReport, page, ROW_PER_PAGE))
            }}
            renderHeader={renderHeader}
            onStartIndex={onStartIndex}
            isActive={isActive}
            {...reportTableSelect}
            {...exportHandler}
        />
      </>
  );
};

export default AdvancedSummaryReport;
