import { getEventColor, changePathMarker, changeMarker } from "Components/Map/utils";

export const validateEventColor = (item) => {
  let eventName = "";
  if (item["eventName"]) {
    eventName = item["eventName"].toString();
  }

  if (item["event"] && eventName == "") {
    eventName = item["event"].toString();
  }

  return getEventColor(eventName);
};

export const getIconAttributes = (
  paramIconColor,
  rotation,
  url = null,
  styleImage = {},
  mapObject = null,
  path = null
) => {
  //BACKWARD_CLOSED_ARROW, BACKWARD_OPEN_ARROW, CIRCLE, FORWARD_CLOSED_ARROW, FORWARD_OPEN_ARROW
  let iconColor = "#d7d7d6"; //set the default color to markers

  const labelPosition = new google.maps.Point(20, 110); // eslint-disable-line no-undef

  if (paramIconColor) {
    iconColor = paramIconColor.toString().replace("##", "#");
  }

  if (url) {
    let imageIcon = {
      labelOrigin: labelPosition,
      url: url,
      scale: 1.8, //Yessica
      fillColor: iconColor,
      fillOpacity: 1,
      strokeColor: "white", //'#6f6f6f',
      sstrokeWeight: 2,
      rotation: parseInt(rotation),
      anchor: new google.maps.Point(9, 9), // eslint-disable-line no-undef
    };
    if (styleImage) {
      delete imageIcon["scale"];
      delete imageIcon["rotation"];
      imageIcon = {
        ...imageIcon,
        labelOrigin: labelPosition,
        ...styleImage,
      };
    }
    return imageIcon;
  }

  if (mapObject) {
    const pathImage = changePathMarker(mapObject.eventName);
    const routeMarkers = mapObject.hasVideo ? "markervideo" : "marker";
    //get direction Image
    const imageRotation = changeMarker(
      rotation,
      `/assets/icons/${routeMarkers}/${pathImage}/`
    );

    return {
      labelOrigin: labelPosition, // eslint-disable-line no-undef
      url: imageRotation,
      origin: new google.maps.Point(0, 0), // eslint-disable-line no-undef
      //x-y
      anchor: new google.maps.Point(20, 20), // eslint-disable-line no-undef
    };
  } else {
    return {
      labelOrigin: labelPosition, // eslint-disable-line no-undef
      //path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW, // eslint-disable-line no-undef
      path: path
        ? path
        : `
          M 12.11,2.48
          C 12.11,2.48 4.61,20.77 4.61,20.77
            4.61,20.77 5.32,21.48 5.32,21.48
            5.32,21.48 12.11,18.48 12.11,18.48
            12.11,18.48 18.90,21.48 18.90,21.48
            18.90,21.48 19.61,20.77 19.61,20.77
            19.61,20.77 12.11,2.48 12.11,2.48 Z`,
      //size: new google.maps.Size(30, 30),
      scale: 1.8,
      fillColor: iconColor,
      fillOpacity: 1,
      strokeColor: "white", //'#6f6f6f',
      sstrokeWeight: 2,
      rotation: parseInt(rotation),
      //x-y
      anchor: new google.maps.Point(12, 16), // eslint-disable-line no-undef
      ...styleImage,
    };
  }
};

export const focusTrailFromEvent = (id) => {
  if(!id) return
  const targetElement = document.getElementById(id);
  if (targetElement) {
    targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setTimeout(() => {
      targetElement.animate([
        { backgroundColor: '#e0e0e0', transform: 'scale(1)' },
        { backgroundColor: '#e0e0e0', transform: 'scale(1.05)' },
        { backgroundColor: '#e0e0e0', transform: 'scale(1)' }
      ], {
        duration: 500,
        easing: 'ease-in-out'
      });
    }, 1000)
  }
};
