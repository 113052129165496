import {
    ACCOUNT_TAGS_OPEN_CLOSE_MERGE_TAGS_MODAL,
    ACCOUNT_TAGS_MERGE_TAGS_SEARCH_ENTITIES,
    ACCOUNT_TAGS_MERGE_TAGS_SEARCH_ENTITIES_RESPONSE,
    ACCOUNT_TAGS_MERGE_TAGS_SAVE,
    ACCOUNT_TAGS_MERGE_TAGS_SAVE_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    open: false,
    selected: [],
    response: null,
    loading: false,
    entities: [],
    loadingEntities: false,
    filters: {
        limit: 25,
        offset: 0,
        entityName: "",
        parentTagIds: [],
        openSearch: ""
    },
    total: 0
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case ACCOUNT_TAGS_OPEN_CLOSE_MERGE_TAGS_MODAL:
            return {
                ...state,
                open: action.payload?.open,
                selected: action.payload?.selected || []
            };

        case ACCOUNT_TAGS_MERGE_TAGS_SEARCH_ENTITIES:
            return {
                ...state,
                loadingEntities: true,
                filters: action.payload?.filters,
                entities: action.payload?.filters?.reset ? [] : state.entities
            };

        case ACCOUNT_TAGS_MERGE_TAGS_SEARCH_ENTITIES_RESPONSE:
            const { items, total } = action?.payload?.response;
            return {
                ...state,
                entities: [...state.entities, ...items],
                loadingEntities: false,
                total: total
            };

        case ACCOUNT_TAGS_MERGE_TAGS_SAVE:
            return {
                ...state,
                response: null,
                loading: true
            };

        case ACCOUNT_TAGS_MERGE_TAGS_SAVE_RESPONSE:
            return {
                ...state,
                response: action?.payload?.response,
                loading: false
            };

        case CLEAR_REDUX:
            return ["", "ACCOUNT_TAGS_MERGE_TAGS"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return state;
    }
};