import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../core/data/GraphQLApi';
import { endpoints } from '../../../core/defaultValues';
import { ExceptionManager } from '../../../core/logManager';

import { resetALL } from '../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    response: null,
    dynamicForms: [],
    error: false,
    videoChangeStatus: null
};

const route = 'core/catalogs/redux/slice'
import { CHAT } from '../processors/Notification/messages/CHAT';
import { LIVEPHOTO } from '../processors/Notification/messages/LIVEPHOTO';
import { ROUTE } from '../processors/Notification/messages/ROUTE';
import { VIDEO_CHANGE_STATUS } from '../processors/Notification/messages/VIDEO_CHANGE_STATUS';

export const processNotification = createAsyncThunk('catalogs/processNotification', async ({ notification }, { rejectWithValue, dispatch, getState }) => {
    try {

        const state = getState()

        if (notification.type == 'CHAT') {
            const chatModel = new CHAT()
            chatModel.execute(notification.data, state, dispatch);
        } else if (notification.type == 'LIVEPHOTO') {
            const livePhotoModel = new LIVEPHOTO()
            livePhotoModel.execute(notification.data, state, dispatch);
        } else if (notification.type == 'Route' || notification.type == 'ROUTE') {
            const routeModel = new ROUTE()
            routeModel.execute(notification.data, state, dispatch);
        }
        if(notification.type == 'VIDEO_STATUS'){
            const videoChangeStatus = new VIDEO_CHANGE_STATUS()
            videoChangeStatus.execute(notification.data, state, dispatch);
        }

        // let query = `
        //     mutation deleteDynamicForms ($ids: [String]!) {
        //         deleted: deleteDynamicForms (ids: $ids)
        //     }
        // `;

        // const response = await clientMutation(
        //     query,
        //     {
        //         ids
        //     },
        //     endpoints.GRAPHQL_GENERAL_V2
        // );

        // if (!response?.deleted) {
        //     throw 'Error while deleting dynamic forms';
        // }

        // return response?.deleted;
    } catch (exc) {
        ExceptionManager(exc, route, 'processNotification');
        return rejectWithValue(exc)
    }
});


export const updateActivityUser = createAsyncThunk('catalogs/updateActivityUser', async ({ notification }, { rejectWithValue, dispatch, getState }) => {
    try {
        const result = await clientMutation(`
          mutation updateActivityUser {
            data: updateActivityUser
        }
        `, {},
        endpoints.GRAPHQL_GENERAL_V2
        )

        //LogManager.Console("Catalog", "Catalog", "updateActivityUser", result)
        return result?.data;

    } catch (exc) {
        ExceptionManager(exc, route, 'updateActivityUser');
        return rejectWithValue('There is a problem to get the chats');
    }
},
);

//Slice
const catalogsRedux = createSlice({
    name: 'catalogs',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        catalogVideoChangeStatus(state, action){
            return { ...state, videoChangeStatus: action.payload }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)
        //deleteDynamicForms
        builder.addCase(processNotification.pending, (state, action) => {
            state.loading = true;
            state.error = false;
            state.response = null;
        });
        builder.addCase(processNotification.fulfilled, (state, action) => {
            state.loading = false;
            state.error = false;
            state.response = action.payload;
        });
        builder.addCase(processNotification.rejected, (state, action) => {
            state.loading = false;
            state.response = action.payload;
            state.error = true;
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = catalogsRedux;

// Extract and export each action creator by name
export const { reset, catalogVideoChangeStatus } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;