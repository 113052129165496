import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
  ENTITY_PICKER_GET_ENTITY_LIST
} from 'Redux/actionTypes';

import {
  getEntityListResponse
} from 'Redux/actions';

function* getEntityListRequest({ payload }) {
  const { data: body } = payload;
  const { id, entity } = body;

  try {
    
    const params = {
      openSearch: body.openSearch,
      offset: body.offset,
      limit: body.limit
    };

    let query = "";
    if (entity === "landmarks") {
      query = `
        query ($openSearch: String, $limit: Int, $offset: Int) {
          Entity: searchLandmarks (openSearch: $openSearch, limit: $limit, offset: $offset) {
            items {
              id
              companyId
              name
              tags {
                id
                label
                color
                path
              }
              latitude
              longitude
              shape
              radius
              address
              city
              zip
              state
              country
            }
            total
          }
        }
    `;
    } else if (entity === "equipments") {
      query = `
        query ($openSearch: String, $limit: Int, $offset: Int) {
          Entity: searchEquipments (openSearch: $openSearch, limit: $limit, offset: $offset) {
            items {
              id
              name
            }
            total
          }
        }
    `;
    }

    const data = yield call(clientQuery, query, params, endpoints.GRAPHQL_GENERAL);

    if (data && data.Entity) {
      yield put(getEntityListResponse({
        id: id,
        error: false,
        data: data.Entity.items,
        total: data.Entity.total,
        openSearch: body.openSearch
      }));
    } else {
      throw 'Not entity data';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/.common/components/EntityPicker/redux/saga', 'getEntityRequest');
    yield put(getEntityListResponse({ id: id, error: true, data: [], total: 0, openSearch: "" }));
  }
}

function* watchGetEntityListRequest() {
  yield takeEvery(ENTITY_PICKER_GET_ENTITY_LIST, getEntityListRequest);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetEntityListRequest)
  ]);
}