import {
	USER_ALERTS_GET_ALERTS,
	USER_ALERTS_GET_ALERTS_RESPONSE
} from 'Redux/actionTypes';

const INIT_STATE = {
	systemAlertLogList: [],
	total: 0,
	filters: {
		resetSearch: false,
		alertTypes: [],
		unitTimeRange: null,
		entities: [],
		severity: "all",
		openSearch: "",
		limit: 25,
		offset: 0
	},
	loading: false,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case USER_ALERTS_GET_ALERTS:
			return {
				...state,
				systemAlertLogList: action.payload.data?.resetSearch ? [] : state.systemAlertLogList,
				filters: { ...state.filters, ...action.payload.data },
				loading: true,
				error: false
			}

		case USER_ALERTS_GET_ALERTS_RESPONSE:
			return {
				...state,
				systemAlertLogList: state.resetSearch ? action.payload.data : [...state.systemAlertLogList, ...action.payload.data],
				total: action.payload.total,
				error: action.payload.error,
				loading: false
			}

		default:
			return state;
	}
};


