import {
  MAP_LANDMARKS_VISIBLE_LANDMARK_GROUPS_GET_LANDMARKS,
  MAP_LANDMARKS_VISIBLE_LANDMARK_GROUPS_GET_LANDMARKS_RESPONSE
} from 'Redux/actionTypes';

export const mapLandmarksVisibleGetLandmarkGroups = () => ({
  type: MAP_LANDMARKS_VISIBLE_LANDMARK_GROUPS_GET_LANDMARKS,
});

export const mapLandmarksVisibleGetLandmarkGroupsResponse = (response) => ({
  type: MAP_LANDMARKS_VISIBLE_LANDMARK_GROUPS_GET_LANDMARKS_RESPONSE,
  payload: { response },
});