import {
    SETTINGS_INPUT_LABEL_SEARCH,
    SETTINGS_INPUT_LABEL_SEARCH_RESPONSE,
    SETTINGS_INPUT_LABEL_OPEN_CREATE_EDIT_MODAL,
    SETTINGS_INPUT_LABEL_CREATE_EDIT,
    SETTINGS_INPUT_LABEL_CREATE_EDIT_RESPONSE,
    SETTINGS_INPUT_LABEL_OPEN_DELETE_MODAL,
    SETTINGS_INPUT_LABEL_DELETE,
    SETTINGS_INPUT_LABEL_DELETE_RESPONSE
} from "Redux/actionTypes";

export const searchSettingsInputLabel = (data) => {
    return {
        type: SETTINGS_INPUT_LABEL_SEARCH,
        payload: data,
    };
};

export const searchSettingsInputLabelResponse = (data) => {
    return {
        type: SETTINGS_INPUT_LABEL_SEARCH_RESPONSE,
        payload: data,
    };
};


export const openSettingsInputLabelCreateEditModal = (open, inputLabel) => {
    return {
        type: SETTINGS_INPUT_LABEL_OPEN_CREATE_EDIT_MODAL,
        payload: { open, inputLabel }

    };
};


export const createEditSettingsinputLabels = (inputLabel) => {
    return {
        type: SETTINGS_INPUT_LABEL_CREATE_EDIT,
        payload: { inputLabel },
    };
}

export const createEditSettingsinputLabelsResponse = (data) => {
    return {
        type: SETTINGS_INPUT_LABEL_CREATE_EDIT_RESPONSE,
        payload: { data },
    };
};


export const openSettingsInputLabelsDeleteModal = (open, items = []) => {
    return {
        type: SETTINGS_INPUT_LABEL_OPEN_DELETE_MODAL,
        payload: { open, items },
    };
};

export const deleteSettingsInputLabels = (items = []) => {
    return {
        type: SETTINGS_INPUT_LABEL_DELETE,
        payload: { items },
    };
};

export const deleteSettingsInputLabelsResponse = (data) => {
    return {
        type: SETTINGS_INPUT_LABEL_DELETE_RESPONSE,
        payload: { data },
    };
};
