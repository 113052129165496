export default {
    "vehicleView_vehicle": "Vehicle",
    "vehicleView_engineRunTime": "Engine Hours (Total)",
    "vehicleView_odometer": "Odometer",
    "vehicleView_diagnosticCode": "DTC",
    "vehicleView_dueStatus": "Due",
    "vehicleView_dueSoonStatus": "Due Soon",
    "vehicleView_scheduledStatus": "Scheduled",
    "vehicleView_pastDue": "Past Due",
    "vehicleView_done": "Done",
    "vehicleView_advancedFilters": "Advanced Filters",
    "vehicleView_clearFilters": "Clear filters",
    "vehicleView_maintenanceStatus": "Maintenance Status",
    "vehicleView_select": "Select",
    "vehicleView_services": "Services",
    "vehicleView_vehicles": "Vehicles",
    "vehicleView_scheduleService": "Schedule Service",
    "vehicleView_viewScheduledervices": "View Scheduled Services",
    "vehicleView_viewDiagnosticHistory": "View Diagnostic History",
    "vehicleView_viewCompleteMaintenance": "Complete Maintenance",
}