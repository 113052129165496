export default {
    realtimemaps_vicinitytool_message: "Use this tool to find vehicles entering to a delimited area during a timeframe.",
    realtimemaps_vicinitytool_shape: "Shape",
    realtimemaps_vicinitytool_startinglocation: "Starting Location",
    realtimemaps_vicinitytool_firtsevent: "First Event",
    realtimemaps_vicinitytool_lastevent: "Last Event",
    realtimemaps_vicinitytool_eventsinside: "Events Inside",
    realtimemaps_vicinitytool_behaviorevents: "Behavior Events",
    realtimemaps_vicinitytool_range: "Select a Date or Range",

    realtimemaps_vicinitytool_please_add_point: "Please add point in the map",
    realtimemaps_vicinitytool_vicinity_tool: "Vicinity Tool",

    realtimemaps_vicinitytool_search_available_tags: "Search available tags",
    realtimemaps_vicinitytool_tags: "Tags",
    realtimemaps_vicinitytool_select: "Select",
    realtimemaps_vicinitytool_venicle_trails: "Vehicle Trails",
    realtimemaps_vicinitytool_max_12_hours: "Max 12 hours",
    realtimemaps_vicinitytool_message_max_12_hours: "The time range cannot exceed 12 hours",

    realtimemaps_vicinitytool_no_data_availabled: "No data available for the requested period",
    realtimemaps_vicinitytool_the_range_exceed: "The date range cannot exceed 12 hours",
    realtimemaps_vicinitytool_error_obtaining_data: "There is an error when obtaining data",
    realtimemaps_vicinitytool_error_polygon_area_error: "The polygon area is too large, try a smaller one",
    realtimemaps_vicinitytool_error_radius: "The shape area is too small",

    realtimemaps_vicinitytool_error_point_in_map: "Use Address, Map click or Unit to generate the shape on the map",

    realtimemaps_vicinitytool_info_polygon: 'Begin clicking points on the map to create a polygon. Must be 3–{maxPoints} points.',
}