import React from "react";
import { Button } from 'Components';
import { HasPermission } from 'Core/security';
import { ExceptionManager } from 'Core/logManager'
import { isValidJson } from 'Core/data/Helpers'
import { concat } from "lodash";
import { checkDeviceData } from 'Core/data/Helpers'


export const EventNameView = ({ value, showCopy = true }) => {

    let label = value?.eventName || "N/A";
    if (value?.battery)
        label += ` (${value?.battery}%)`

    return (
        <div>
            <span style={{ paddingRight: 8 }}>
                {label}
            </span>
            {
                showCopy &&
                <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(label)}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                />
            }
        </div>
    )
}

const FixEventName = (event) => {
    switch (event) {
        case "IDLING":
            return "Idle";
        case "Hard Acceleration":
            return "Rapid Acceleration";
        default:
            return event
    }
}

export const GetEventName = (lastReading, showBattery = true) => {
    let eventName
    if (!lastReading) return eventName;

    let battery
    if (showBattery) {
        let deviceData = checkDeviceData(lastReading?.deviceData);
        battery = deviceData?.batteryPercent;
    }

    eventName = lastReading.eventName && (FixEventName(lastReading.eventName)) || "N/A";
    if (battery) eventName += ` (${battery}%)`;
        
    return eventName;
}

export const GetEventTemperatureValue = (lastReading, user) => {
    //console.log("GetEventTemperatureValue", lastReading)
    let tempValue
    if (!lastReading)
        return tempValue

    if (lastReading.temperatures != undefined) {
        let temperatures
        if (typeof lastReading.temperatures === 'object') {
            temperatures = lastReading.temperatures
        }
        else {
            //legacy parse still because is possible have some values this way
            let hashMapTemp = lastReading.temperatures;
            hashMapTemp = hashMapTemp?.replace && hashMapTemp.replace(/=/gi, ":");
            let parsedString = hashMapTemp?.replace && hashMapTemp.replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": ') || "{}";
            temperatures = JSON.parse(parsedString);
        }

        if (temperatures.temperature1 && temperatures.temperature1 != "1830") {
            tempValue = `${temperatures.temperature1}ºF`
            if (temperatures.temperature2 && temperatures.temperature2 != "1830") {
                tempValue =concat(tempValue, `  ${temperatures.temperature2}ºF`) 
            }
            if (temperatures.temperature3 && temperatures.temperature3 != "1830") {
                tempValue =concat(tempValue, `  ${temperatures.temperature3}ºF`) 
            }
        }
    }

    if (HasPermission(user, "air_land")) {

        let deviceData = checkDeviceData(lastReading?.deviceData);

        let surface;
        if (deviceData?.temperatures?.surface) {
            try { surface = Number(deviceData.temperatures.surface).toFixed(1) } catch (e) {ExceptionManager(exc, 'modules/.common/components/EventViewTools', 'GetEventTemperatureValue', 'surface bad value', deviceData.temperatures.surface);}
        }
        
        let ambient;
        if (deviceData?.temperatures?.ambient) {
            try { ambient = Number(deviceData.temperatures.ambient).toFixed(1) } catch (e)  {ExceptionManager(exc, 'modules/.common/components/EventViewTools', 'GetEventTemperatureValue', 'ambient bad value', deviceData.temperatures.ambient);}
        }

        if (deviceData?.temperatures) {
            tempValue = `S:${surface || ''} ºF, A:${ambient || ''} ºF`;
        }
    }

    return tempValue
}