import {
    SETTINGS_KEY_FOB_SEARCH,
    SETTINGS_KEY_FOB_SEARCH_RESPONSE,
    SETTINGS_KEY_FOB_OPEN_CREATE_EDIT_MODAL,
    SETTINGS_KEY_FOB_CREATE_EDIT_RESPONSE,
    SETTINGS_KEY_FOB_CREATE_EDIT,
    SETTINGS_KEY_FOB_OPEN_DELETE_MODAL,
    SETTINGS_KEY_FOB_DELETE,
    SETTINGS_KEY_FOB_DELETE_RESPONSE,
    SETTINGS_KEY_FOB_GET_DRIVER,
    SETTINGS_KEY_FOB_GET_DRIVER_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loadingKeyFob: false,
    loadingCreateEdit: false,
    loadingDelete: false,
    keyFobs: [],
    drivers: [],
    keyFob: null,
    errorKeyFob: false,
    openCreateEdit: false,
    openDelete: false,
    createEditResponse: null,
    deleteResponse: null,
    filters: {
      openSearch: "",
      limit: 10,
      offset: 0
    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SETTINGS_KEY_FOB_SEARCH:
            return {
                ...state,
                keyFobs: [],
                loadingKeyFob: true,
                errorKeyFob: false,
                filters: action.payload
            };

        case SETTINGS_KEY_FOB_SEARCH_RESPONSE:
            return {
                ...state,
                keyFobs: action.payload?.keyFobs,
                total: action.payload?.total,
                errorKeyFob: action.payload?.error,
                loadingKeyFob: false
            };

        case SETTINGS_KEY_FOB_OPEN_CREATE_EDIT_MODAL:
            return {
                ...state,
                openCreateEdit: action.payload?.open,
                keyFob: action.payload?.keyFob
            };

        case SETTINGS_KEY_FOB_CREATE_EDIT:
            return {
                ...state,
                loadingCreateEdit: true,
                createEditResponse: null
            };

        case SETTINGS_KEY_FOB_CREATE_EDIT_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                createEditResponse: action.payload?.data,
            };



        case SETTINGS_KEY_FOB_OPEN_DELETE_MODAL:
            return {
                ...state,
                openDelete: action.payload?.open,
                selectedItems: action.payload?.items,
                loadingDelete: false,
                deleteResponse: null
            };

        case SETTINGS_KEY_FOB_DELETE:
            return {
                ...state,
                loadingDelete: true,
                deleteResponse: null,
                filters: {
                    openSearch: "",
                    limit: 10,
                    offset: 0
                  }
            };

        case SETTINGS_KEY_FOB_DELETE_RESPONSE:
            return {
                ...state,
                loadingDelete: false,
                deleteResponse: action.payload?.data,
            };

        case SETTINGS_KEY_FOB_GET_DRIVER:
            return {
                ...state,
                drivers: [],
                loadingCreateEdit: true
            };

        case SETTINGS_KEY_FOB_GET_DRIVER_RESPONSE:
            return {
                ...state,
                drivers: action.payload?.drivers,
                loadingCreateEdit: false
            };

        case CLEAR_REDUX:
            return ["", "SETTINGS_KEY_FOB"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return { ...state };
    }
};