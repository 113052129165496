import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';

// Actions
import {
  reportsMainGenerateReportProgress
} from 'Redux/actions';

import {
  getTimeZone
} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getStateMileageReport(report, event) {
  const { timeZone, name, reportTimeRanges } = report;
  const {
    filterType,
    filterArray,
    page,
    sortField,
    sortOrder,
    startRange,
    endRange
  } = event.filters;
  try {
    let data = yield call(
      clientQuery,
      `query getStateMileageReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
          Report: getStateMileageReport (conditions: $conditions, limit: $limit, offset: $offset) {
            limit
            total
            category
            items {
              deviceId
              deviceLabel
              driverId
              driverName
              unitId
              accountId
              address2
              address1
              distance
              ecuOdometer2
              ecuOdometer1
              landmark1Name
              landmark2Name
              landmarkId2
              landmarkId1
              stateTotalDistance
              stateCrossings
              odometer2
              odometer1
              longitude2
              longitude1
              latitude2
              latitude1
              state
              unittime1
              unittime2
              isKPH
            }
          }
        }`,
      {
        conditions: {
          reportName: name,
          timeZone: getTimeZone(timeZone),
          category: {
            field: filterType,
            value: filterArray
          },
          dateRange: {
            fields: ["unitTime"],
            GTE: startRange,
            LTE: endRange,
            reportTimeRanges
          },
          sortField: {
            field: sortField || "deviceId",
            order: sortOrder === 'desc' ? 'DESC' : 'ASC'
          }
        },
        limit: 5,
        offset: page
      },
      endpoints.GRAPHQL_PX_REPORTING,
    );

    if (data && data.Report) {
      event.data = data.Report
      report.subEvents = event
      report.loadingSubEvents = false;
      yield put(reportsMainGenerateReportProgress(report))
    } else {
      report.loading = false
      yield put(reportsMainGenerateReportProgress(report))
    }

  } catch (exc) {
    report.loading = false
    report.loadingSubEvents = false;
    delete report.export
    yield put(reportsMainGenerateReportProgress(report))
  }
}


export default getStateMileageReport;
