import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientMutation, clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from 'Core/logManager';

const INIT_STATE = {
    open: false,
    loading: false,
    result: null,
    service: null,
    error: null,
    maintenanceServiceUnit: null,
};

const route = "modules/maintenance/modals/deleteMaintenanceServiceUnit/redux/slice";

export const maintenanceDMSUGetMaintenanceServiceUnit = createAsyncThunk("maintenance/deleteMaintenanceServiceUnit/getMaintenanceServiceUnit", async ({ id }, { rejectWithValue, dispatch }) => {
    try {

        let response = await clientQuery(
            `query ($id: Int!){
                data: getMaintenanceServiceUnit(id: $id){
                    id
                    serviceId
                    service {
                        id
                        title
                    }
                }
            }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );
        return (response.data);
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceDMSUGetMaintenanceServiceUnit');
        rejectWithValue(exc);
    }
});

export const maintenanceDMSUDeleteMaintenanceServiceUnit = createAsyncThunk("maintenance/deleteMaintenanceServiceUnit/deleteMaintenanceServiceUnit", async ({ id }, { rejectWithValue, dispatch }) => {
    try {

        let response = await clientMutation(
            `mutation ($id: Int!){
                data: deleteMaintenanceServiceUnit(id: $id)
            }`,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );
        return (response.data);
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceDMSUDeleteMaintenanceServiceUnit');
        rejectWithValue(exc);
    }
});

export const maintenanceDeleteMaintenanceServiceUnitRedux = createSlice({
    name: 'maintenance/deleteMaintenanceServiceUnit',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalDeleteMaintenanceServiceUnit: (state, action) => {
            state.open = action.payload.open
            state.service = action.payload.service
        }
    },
    extraReducers: (builder) => {
        //maintenanceDMSUGetMaintenanceServiceUnit
        builder.addCase(maintenanceDMSUGetMaintenanceServiceUnit.pending, (state) => {
            state.loading = true
        });

        builder.addCase(maintenanceDMSUGetMaintenanceServiceUnit.fulfilled, (state, action) => {
            state.maintenanceServiceUnit = action.payload,
                state.loading = false
        });

        builder.addCase(maintenanceDMSUGetMaintenanceServiceUnit.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        });

        //maintenanceDMSUDeleteMaintenanceServiceUnit
        builder.addCase(maintenanceDMSUDeleteMaintenanceServiceUnit.pending, (state) => {
            state.loading = true
        });

        builder.addCase(maintenanceDMSUDeleteMaintenanceServiceUnit.fulfilled, (state, action) => {
            state.result = action.payload,
                state.loading = false
        });

        builder.addCase(maintenanceDMSUDeleteMaintenanceServiceUnit.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        });
    },
});

const { actions, reducer } = maintenanceDeleteMaintenanceServiceUnitRedux;

export const {
    reset,
    openModalDeleteMaintenanceServiceUnit,
} = actions;

export default reducer;