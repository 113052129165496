import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// controls
import Table, { Column } from '../../table'
import {
  formatDatetime,
  getCategoryFilterType,
  useHandleExportReport,
  getEntityType
} from 'Modules/reports/views/main/components/reportsTab/helper';

import { useSidePanelMap, useTableReport } from 'Core/hooks';


import {
  reportsMainGenerateEvents
} from 'Redux/actions'
import EventReports from "Modules/reports/views/main/components/reportsTab/.components/eventReport";

const JBDiagnosticsReport = (props) => {
  const { messages, report } = props;
  const { loading, data, filters, events, summary, loadingSummary, subEvents, loadingSubEvents, timeZone } = report;
  const { filterEntity, filterArray, otherFilters, filterType, startRange, endRange } = filters;


  const reportRef = useRef();
  const dispatch = useDispatch()
  const exportHandler = useHandleExportReport(report);



  useEffect(() =>{
    if(report){
      const newReport = JSON.parse(JSON.stringify(report));
      // to keep the reference to the units selected
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);



  const onChangeExpandable = (unit) => {
    if(!unit.noData){
      let events = {
        data: [],
        filters: {
          filterType: getCategoryFilterType(filterEntity),
          filterArray: unit,
          page: 1,
          rowsPerPage: 10,
          sortField: 'eventDate',
          otherFilters
        }
      }
      dispatch(reportsMainGenerateEvents(report, events))
    }
  }

  let parametersSummary = useTableReport({data, report, messages, hideHeader: false});



  const renderExpandableRow = (row) => {
    let parametersSummaryTable = {
      ...parametersSummary,
      data: summary?.items || [],
      totalItems: summary?.total || 0,
      hideHeader: false,
      serverSideTable: true,
      onChangedPage: (page, rowsPerPage, sortField, sortOrder) => {
        let events = {
          data: [],
          filters: { filterType: getCategoryFilterType(filterEntity), filterArray: row, page, rowsPerPage, sortField, sortOrder, otherFilters }
        }
        dispatch(reportsMainGenerateEvents(report, events))
      },
      onChangeSelector: null,
      itemsSelector: null,
      generateExport: false,
      hideSelectorUnitsDriver: true,
      groups:report.filters.units || [],
      hideNumberOfRows:true,
      centerPagination: true,
      noData: row.noData
    };
    return (
      <Table
        {...parametersSummaryTable}
        loading={loadingSummary}
      >

        <Column label={messages['generatedReport_timeAndDate']} field="unitTime" noSorting>
          {(element) => {
            return (
              formatDatetime(element.unitTime, "MMMM D, YYYY h:mm A", timeZone)
            );
          }}
        </Column>
        <Column label={messages['generatedReport_event']} field="eventName" noSorting>
          {(element) => {
            return (
                <EventReports element={{event: exitstLabel(element.eventName) || ''}}/>
            );
          }}
        </Column>
        <Column label={`${messages['generatedReport_acceleratorPedalPosition']} (%)`} field="acceleratorPedalPosition" noSorting>
          {(element) => {
            return (
            exitstLabel(element.acceleratorPedalPosition)
            );
          }}
        </Column>
        <Column label={messages['generatedReport_batteryVoltage']} field="batteryVoltage" noSorting>
          {(element) => {
            return (
            exitstLabel(element.batteryVoltage)
            );
          }}
        </Column>
        <Column label={`${messages['generatedReport_brakePedalPosition']} (%)`} field="brakePedalPosition" noSorting>
          {(element) => {
            return (
            exitstLabel(element.brakePedalPosition)
            );
          }}
        </Column>
        <Column label={`${messages['generatedReport_engineCoolantLevel']} (%)`} field="engineCoolantLevel" noSorting>
          {(element) => {
            return (
            exitstLabel(element.engineCoolantLevel)
            );
          }}
        </Column>
        <Column label={`${messages['generatedReport_engineCoolantPressure']} (Psi)`}  field="engineCoolantPressure" noSorting>
          {(element) => {
            return (
            exitstLabel(element.engineCoolantPressure)
            );
          }}
        </Column>
        <Column label={`${messages['generatedReport_engineCoolantTemp']} (F)`} field="engineCoolantTemp" noSorting>
          {(element) => {
            return (
            exitstLabel(element.engineCoolantTemp)
            );
          }}
        </Column>
        <Column label={`${messages['generatedReport_engineFuelRate']} (gal/h)`} field="engineFuelRate" noSorting>
          {(element) => {
            return (
            exitstLabel(element.engineFuelRate)
            );
          }}
        </Column>
        <Column label={`${messages['generatedReport_engineOilLevel']} (%)`} field="engineOilLevel" noSorting>
          {(element) => {
            return (
            exitstLabel(element.engineOilLevel)
            );
          }}
        </Column>
        <Column label={`${messages['generatedReport_engineOilPressure']} (Psi)`} field="engineOilPressure" noSorting>
          {(element) => {
            return (
            exitstLabel(element.engineOilPressure)
            );
          }}
        </Column>
        <Column label={`${messages['generatedReport_engineOilTemp']} (F)`} field="engineOilTemp" noSorting>
          {(element) => {
            return (
            exitstLabel(element.engineOilTemp)
            );
          }}
        </Column>
        <Column label={`${messages['generatedReport_fuelLevelOne']} (%)`} field="fuelLevelOne" noSorting>
          {(element) => {
            return (
            exitstLabel(element.fuelLevelOne)
            );
          }}
        </Column>
        <Column label={`${messages['generatedReport_fuelLevelTwo']} (%)`} field="fuelLevelTwo" noSorting>
          {(element) => {
            return (
            exitstLabel(element.fuelLevelTwo)
            );
          }}
        </Column>
        <Column label={`${messages['generatedReport_odometer']} (Miles)`} field="odometer" noSorting>
          {(element) => {
            return (
            exitstLabel(element.odometer)
            );
          }}
        </Column>
        <Column label={messages['generatedReport_rpm']} field="rpm" noSorting>
          {(element) => {
            return (
            exitstLabel(element.rpm)
            );
          }}
        </Column>
        <Column label={messages['generatedReport_seatbeltswitch']} field="seatbeltswitch" noSorting>
          {(element) => {
            return (
            exitstLabel(element.seatbeltswitch)
            );
          }}
        </Column>

      </Table>
    )
  }

  const exitstLabel = (label) => {
    if (!label) {
      return "--";
    }

    return label;
  };

  let parametersTable = useTableReport({
    data: data.items,
    report,
    messages,
    hideHeader: true,
    renderExpandableRow,
    onChangeExpandable,
    propertyToShowExpandable: 'id'
  });

  return (
    <Fragment>
      <Table
        {...parametersTable}
        {...exportHandler}
      >
        <Column label="Day" field="label">
          {(element) => {
            return (
              <div>
                <span>
                  <span>
                    <strong>
                      { getEntityType(report, messages) }:
                    </strong> 
                    &nbsp;
                    {element?.idLabel || ""} 
                  </span>
                </span>
              </div>
            );
          }}
        </Column>
        <Column label={messages['generatedReport_totalIdleTime']} field="label">
          {(element) => {
            const localTimeStart = moment.utc(startRange).local().format();
            const localTimeEnd = moment.utc(endRange).local().format();
            return (
              <div>
                <span>
                  <span>
                    <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                  </span>
                </span>
              </div>
            );
          }}
        </Column>
      </Table>
    </Fragment>
  );
}

export default JBDiagnosticsReport
