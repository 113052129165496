import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
// Components
import { Loading, Button } from 'Components';
import VidFleetVideo from 'Modules/.common/components/VidFleetVideo';
import SmartWitnessSnapshot from 'Modules/.common/components/SmartWitnessSnapshot';
// Material UI
import { Grid } from '@mui/material';


import { checkDeviceData } from 'Core/data/Helpers'

import {
  getEventMedia
} from 'Redux/actions';

const VideoAlertView = (props) => {
  const dispatch = useDispatch();
  const { messages } = props.intl;
  const { id } = useParams();

  let readingId, deviceId;
  const params = id?.split('-');
  if (params?.length) {
    readingId = params[0];
    deviceId = params[1];
  }

  const { loading, response } = useSelector(state => state.videoAlertRedux);

  const [options, setOptions] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (readingId && deviceId) {
      dispatch(getEventMedia({ readingId: readingId, deviceId: deviceId }));
    } else {
      setError(messages['videoAlert_notFound']);
    }
  }, [readingId, deviceId]);

  useEffect(() => {
    if (response) {
      if (response.error) {
        setError(messages['videoAlert_notFound']);
      } else {
        try {
          let deviceData = checkDeviceData(response?.reading?.deviceData);
          //const deviceData = JSON.parse(JSON.parse(response?.reading?.deviceData));
          let videoOptions = {
            mode: 'picture',
            id: deviceData?.pictureID,
            pictureId: deviceData?.pictureID
          };

          if (deviceData?.clipID) {
            if (deviceData?.duration) {
              videoOptions.mode = "video";
            } else {
              videoOptions.thumbnailUrl = deviceData?.url
            }
            videoOptions.id = deviceData?.clipID;
            videoOptions.pictureId = null;
          }
          
          if(deviceData?.thumbnailUrl && deviceData?.url){
            videoOptions.thumbnailUrl = deviceData?.thumbnailUrl;
            videoOptions.url = deviceData?.url;
          }

          setOptions({
            ...videoOptions,
            messages: messages,
            type: "playback",
            autoplay: true,
            //deviceModel: '4K'
            deviceModel: response?.deviceModelName === '360' ? '360' : '4K'
          });
        } catch (e) {
          //console.log("Error while parsing string json view SharedMedia");
        }
      }
    }
  }, [response]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {
        loading
          ?
          <Loading full />
          :
          <div style={{ width: '100%', height: '100%', backgroundColor: 'black' }}>
            {
              options && response?.deviceModelName!=="SmartWitness" && <VidFleetVideo  {...options} />
            }

            {
              options && response?.deviceModelName==="SmartWitness" && 
              <div style={{width: `calc( ${window.innerWidth - 50}px)`,marginLeft:25,marginTop:25, height: '100%',display:"table-caption"}}>
                <SmartWitnessSnapshot height={window.innerHeight} indicators={true} vehicleTrail={{deviceData:response?.reading?.deviceData}} />
             </div>
            }


            {
              error &&
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ padding: 20, height: '100%' }}
              >
                <Grid item>
                  <span style={{ fontSize: 18, color: 'white' }}>{error}</span>
                </Grid>

                <Grid item>
                  <Button
                    type="icon"
                    icon="refresh"
                    onClick={(e) => {
                      if (e) e.preventDefault();
                      setError(null);
                      dispatch(getEventMedia({ readingId: readingId, deviceId: deviceId }));
                    }}
                  />
                </Grid>
              </Grid>
            }
          </div>
      }
    </div>
  );
};

export default injectIntl(VideoAlertView);
