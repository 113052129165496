import React, { useEffect, useState } from "react";
import { Tooltip } from "../../../../components";
import ChatIcon from '@mui/icons-material/Chat';
import { IconButton, Icon } from "@mui/material";
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { useIntl } from "react-intl";
import { closeChat, configFielsSFChat, getStatusChat, saveStatusChat, queuedMessages } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { changeSalesForceStatus } from "Redux/actions";
import { LogManagerConsole } from 'Core/logManager';

const AVAILABLE_CHAT_COLOR = '#1D2734'
const DISABLED_CHAT_COLOR = '#DCDCDC'
const CONVERSATION_CHAT_COLOR = '#66BB6A'

const ChatSFButton = ({ user }) => {
  const [userInfo, setUserInfo] = useState({});
  const [isAgentAvailable, setIsAgentAvailable] = useState(false);
  const [showButton, setShowButton] = useState(false)
  const [colorButton, setColorButton] = useState(AVAILABLE_CHAT_COLOR)
  const [status, setStatus] = useState()
  const { messages } = useIntl();
  const subUser = useSelector(state => state.subUserRedux.subUserSelected);
  const { salesForceStatus } = useSelector(state => state.securityRedux);
  const {openChat } = useSelector(state => state.troubleshootDeviceRedux);
  const dispatch = useDispatch()

  useEffect(() => {
    if (getStatusChat()) {
      setColorButton(CONVERSATION_CHAT_COLOR)
      setStatus('inConversation')
    } else {
      setColorButton(AVAILABLE_CHAT_COLOR)
      setStatus('available')
    }

    if (queuedMessages()) {
      setStatus('message')
    }
  }, []);

  useEffect(() => {
    const getUserInfoForChat = () => {
      if (user?.isSubUser) {
        setUserInfo({
          ...userInfo,
          username: subUser?.firstName,
          lastname: subUser?.lastName,
          email: subUser?.email,
          subject: `chat cloud ${user?.companyName}`
        });
      } else {
        setUserInfo({
          ...userInfo,
          username: user.username,
          lastname: user.username,
          email: user.email,
          subject: `chat cloud ${user?.companyName}`
        });
      }
    }

    getUserInfoForChat()
  }, [subUser])

  useEffect(() => {
    setTimeout(() => {
      if (window.embedded_svc?.liveAgentAPI) {
        setShowButton(true)
        window.embedded_svc.liveAgentAPI.ping();
        window.embedded_svc.addEventHandler(
          "onAvailability",
          function (data) {
            //status salesforce chat
            LogManagerConsole("SF", 'status salesforce chat', data)
            if (data.isAgentAvailable) {
              setIsAgentAvailable(true);
              setColorButton(AVAILABLE_CHAT_COLOR);
              setStatus('available')
              if (getStatusChat()) {
                setColorButton(CONVERSATION_CHAT_COLOR);
                setStatus('inConversation')
              }
            } else {
              setIsAgentAvailable(false);
              closeChat()
              setColorButton(DISABLED_CHAT_COLOR);
              queuedMessages('delete')
              setStatus('available')
            }
          }
        );
        window.embedded_svc.addEventHandler(
          "onChatEstablished",
          function () {
            setColorButton(CONVERSATION_CHAT_COLOR)
            setStatus('inConversation')
          }
        );
        window.embedded_svc.addEventHandler("onAgentMessage", function () {
          setStatus('message')
          queuedMessages('save')
          saveStatusChat()
          setColorButton(CONVERSATION_CHAT_COLOR);
        });
        window.embedded_svc.addEventHandler(
          "onChatEndedByChasitor",
          function () {
            closeChat()
            setColorButton(AVAILABLE_CHAT_COLOR);
            setStatus('available')
            queuedMessages('delete')
          }
        );
        window.embedded_svc.addEventHandler(
          "onChatEndedByAgent",
          function () {
            closeChat()
            setColorButton(AVAILABLE_CHAT_COLOR);
            setStatus('available')
            queuedMessages('delete')
          }
        );

      }
    }, 2000);
  }, []);

  useEffect(() => {
    if (openChat) {
      chatHandler()
    }
  }, [openChat])

  const chooseIconStatus = (status = 'available', color = AVAILABLE_CHAT_COLOR) => {

    if (queuedMessages() && status !== 'available') {
      return (
        <MarkUnreadChatAltIcon style={{ color: color }}>
          question_answer
        </MarkUnreadChatAltIcon>
      )
    }

    switch (status) {
      case 'available':
        return (
          <Icon style={{ color: color }}>
            support_agent
          </Icon>
        )
      case 'inConversation':
        return (
          <ChatIcon style={{ color: color }}>
            question_answer
          </ChatIcon>
        )
      case 'message':
        return (
          <MarkUnreadChatAltIcon style={{ color: color }}>
            question_answer
          </MarkUnreadChatAltIcon>
        )
      default:
        return (
          <Icon style={{ color: color }}>
            question_answer
          </Icon>
        )

    }
  }

  const chatHandler = () => {
    if (isAgentAvailable) {
      dispatch(changeSalesForceStatus(salesForceStatus))
      queuedMessages('delete')
      if (status === 'message') {
        setStatus('inConversation')
      }
      configFielsSFChat(userInfo)
    }
  }

  return <>
    {showButton &&
      <IconButton
        id="buttonId1"
        aria-label="placeholder"
        style={{ color: colorButton }}
        onClick={() => chatHandler()}
        size="large">
        <Tooltip
          title={
            isAgentAvailable
              ? messages["header_chatEnabled"]
              : messages["header_chatDisabeld"]
          }
        >
          {/* {areMsg ? 
            <MarkUnreadChatAltIcon
            style={{ color: colorButton }}
            >
              question_answer
            </MarkUnreadChatAltIcon>
            :
            <ChatIcon
            style={{ color: colorButton }}
            >
              question_answer
            </ChatIcon>
          } */}
          {chooseIconStatus(status, colorButton)}

        </Tooltip>
      </IconButton>
    }
  </>;
};

export default ChatSFButton;
