// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    MAP_SPEED_OVERRIDE_GET_SPEED_OVERRIDE,
    MAP_SPEED_OVERRIDE_EDIT_CREATE_SPEED_OVERRIDE
} from "Redux/actionTypes";

// Actions
import {
    mapSpeedOverrideGetSpeedOverrideResponse,
    mapSpeedOverrideSaveSpeedOverrideResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* mapSpeedOverrideGetSpeedOverrideRequest({ payload }) {
    try {

        const { id } = payload;

        const response = yield call(
            clientQuery,
            `
                query getSpeedOverride ($id: Int!) {
                    getSpeedOverride (id: $id) {
                        id
                        companyId
                        name
                        startAddress
                        endAddress
                        encodedPath
                        speedLimit
                        notes
                        userName                
                        
                        createdOn
                        updatedOn
                    }
                }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );
        if (!response && !response.getSpeedOverride) throw `Error while speed override id: ${id}`;

        yield put(mapSpeedOverrideGetSpeedOverrideResponse({ ...response.getSpeedOverride, error: false }));
        
    } catch (exc) {
        yield put(mapSpeedOverrideGetSpeedOverrideResponse({ error: true }));
        ExceptionManager(
            exc,
            "realtimeMaps/main/components/speedOverride/components/createEdit/redux/saga",
            "mapSpeedOverrideGetSpeedOverrideResponse"
        );
    }
}

function* mapSpeedOverrideSaveSpeedOverrideRequest({ payload }) {
    try {
        
        const { speedOverride } = payload;

        let query = `
            mutation createSpeedOverride (
                $name: String!,
                $startAddress: String!,
                $endAddress: String!,
                $encodedPath: String!,
                $speedLimit: Float!,
                $speedData: String!,
                $notes: String
            ) {
                SpeedOverride: createSpeedOverride (
                    name: $name,
                    startAddress: $startAddress,
                    endAddress: $endAddress,
                    encodedPath: $encodedPath,
                    speedLimit: $speedLimit,
                    speedData: $speedData,
                    notes: $notes
                ) {
                    companyId
                    name
                    startAddress
                    endAddress
                    encodedPath
                    speedLimit
                    notes
                    userName                
                    
                    createdOn
                    updatedOn
                }
            } 
        `;

        if (speedOverride?.id) {
            query = `
                mutation updateSpeedOverride (
                    $id: Int!,
                    $name: String!,
                    $startAddress: String,
                    $endAddress: String,
                    $encodedPath: String,
                    $speedLimit: Float!,
                    $speedData: String,
                    $notes: String
                ) {
                    SpeedOverride: updateSpeedOverride (
                        id: $id,
                        name: $name,
                        startAddress: $startAddress,
                        endAddress: $endAddress,
                        encodedPath: $encodedPath,
                        speedLimit: $speedLimit,
                        speedData: $speedData,
                        notes: $notes
                    ) {
                        companyId
                        name
                        startAddress
                        endAddress
                        encodedPath
                        speedLimit
                        notes
                        userName                
                        
                        createdOn
                        updatedOn
                    }
                } 
            `;
        }

        const response = yield call(
            clientMutation,
            query,
            speedOverride,
            endpoints.GRAPHQL_GENERAL,
        );

        if (!response || !response.SpeedOverride) throw `Error while creating/editing Speed Override: ${speedOverride}`;

        yield put(mapSpeedOverrideSaveSpeedOverrideResponse({ ...response.SpeedOverride, error: false }));
    

    } catch (exc) {
        console.log('exc', exc)
        yield put(mapSpeedOverrideSaveSpeedOverrideResponse({ error: true, message: exc.error }));

        ExceptionManager(
            exc,
            "realtimeMaps/main/components/speedOverride/components/createEdit/redux/saga",
            "mapSpeedOverrideSaveSpeedOverrideRequest"
        );
    }
}

function* watchMethods() {
    yield takeEvery(MAP_SPEED_OVERRIDE_GET_SPEED_OVERRIDE, mapSpeedOverrideGetSpeedOverrideRequest);
    yield takeEvery(MAP_SPEED_OVERRIDE_EDIT_CREATE_SPEED_OVERRIDE, mapSpeedOverrideSaveSpeedOverrideRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}