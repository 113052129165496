import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';
// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

import {baseReportConditions, generateReportInstance, getTimeZone, handleAsyncReport, isJsonFormat} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getOdometerReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
    const {timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath} = report;
    const {
        filterType,
        filterArray,
        startRange,
        endRange,
        unitsSelected,
        filterEntity,
        otherFilters,
        accumulateData = true,
        dates
    } = report.filters

    const baseConditionsQuery = baseReportConditions({
        report,
        name,
        dataResultPath,
        timeZone,
        delayed,
        unitsSelected,
        timeframeName,
        filterType,
        filterArray,
        categoryName,
        startRange,
        endRange,
        dates
    })

    try {
        let payload = {
            conditions: {
                ...baseConditionsQuery,
                sortField: {
                    field: sortField || "deviceId",
                    order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                }
            },
            limit: rowsPerPage,
            offset: page
        };
        const addColumnDriver = (
            report?.exportType === 'csv' ?
                [{
                    "column": "groupIdLabel",
                    "header": "Tag"
                }] : [])

        payload = {
            ...payload,
            limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
            offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
            conditions: {
                ...payload.conditions,
                exportOptions: {
                    sendByEmail,
                    emailsTo,
                    format: {
                        fileFormat: report?.exportType || "json",
                        formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
                        durationFormat: report?.durationFormat
                    },
                    culture: report.lang,
                    timeZone: getTimeZone(timeZone),
                    folderName: "px-customer-reports",
                    filePrefixName: "odometerReport",
                    columns: [
                        ...addColumnDriver,
                        {
                            "column": filterEntity === 'Unit' ? "deviceLabel" : "driverName",
                            "header": filterEntity === 'Unit' ?  "Unit" : "Driver"
                        },
                        {
                            "column": "odometer",
                            "header": "Odometer Report"
                        }
                    ]
                }
            }
        }
        let data = yield call(
            clientQuery,
            `query getOdometerReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
                Report: getOdometerReport (conditions: $conditions, limit: $limit, offset: $offset) {
                    ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
                `reportInstanceId`
                : `
                    items {
                        recordInfo {
                            entityId
                            entityLabel
                            tagId
                            tagLabel
                            startDate
                            endDate
                            recordType
                            groupLabel
                            groupIndex
                            groupTotal
                            groupTagColor
                        }
                        recordDetail {
                            id
                            idLabel
                            tagId
                            deviceId
                            deviceLabel
                            driverId
                            driverName
                            groupId
                            groupIdLabel
                            odometer
                            noData
                        }
                    }
                    total
                    limit
                    offset
                    category
                    reportInstanceId
                `}
                }
              }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
        yield handleAsyncReport(report, data, accumulateData);
    } catch (exc) {
        ExceptionManager(exc, 'reports/odometerReport', 'getOdometerReport');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}

export default getOdometerReport;
