export const SETTINGS_CREWS_SEARCH = "SETTINGS_CREWS_SEARCH";
export const SETTINGS_CREWS_SEARCH_RESPONSE = "SETTINGS_CREWS_SEARCH_RESPONSE";
export const SETTINGS_CREWS_OPEN_CREATE_EDIT_MODAL = "SETTINGS_CREWS_OPEN_CREATE_EDIT_MODAL";
export const SETTINGS_CREWS_GET_CREW = "SETTINGS_CREWS_GET_CREW";
export const SETTINGS_CREWS_GET_CREW_RESPONSE = "SETTINGS_CREWS_GET_CREW_RESPONSE";
export const SETTINGS_CREWS_CREATE_EDIT_CREW = "SETTINGS_CREWS_CREATE_EDIT_CREW";
export const SETTINGS_CREWS_CREATE_EDIT_CREW_RESPONSE = "SETTINGS_CREWS_CREATE_EDIT_CREW_RESPONSE";
export const SETTINGS_CREWS_OPEN_DELETE_MODAL = "SETTINGS_CREWS_OPEN_DELETE_MODAL";
export const SETTINGS_CREWS_DELETE_CREWS = "SETTINGS_CREWS_DELETE_CREWS";
export const SETTINGS_CREWS_DELETE_CREWS_RESPONSE = "SETTINGS_CREWS_DELETE_CREWS_RESPONSE";
export const SETTINGS_CREWS_BULK_MODAL = "SETTINGS_CREWS_BULK_MODAL";
export const SETTINGS_CREWS_BULK_UPDATE = "SETTINGS_CREWS_BULK_UPDATE";
export const SETTINGS_CREWS_BULK_UPDATE_RESPONSE = "SETTINGS_CREWS_BULK_UPDATE_RESPONSE";
export const SETTINGS_CREWS_SEARCH_AVAILABLE_ONLY = "SETTINGS_CREWS_SEARCH_AVAILABLE_ONLY";
export const SETTINGS_CREWS_SEARCH_UNIT_ID = "SETTINGS_CREWS_SEARCH_UNIT_ID";
export const SETTINGS_CREWS_ASSIGN_EDIT_VEHICLE = "SETTINGS_CREWS_ASSIGN_EDIT_VEHICLE";
export const SETTINGS_CREWS_SEARCH_UNIT_CREWS = "SETTINGS_CREWS_SEARCH_UNIT_CREWS";
export const SETTINGS_CREWS_SEARCH_UNIT_CREWS_RESPONSE = "SETTINGS_CREWS_SEARCH_UNIT_CREWS_RESPONSE";
export const SETTINGS_CREWS_SEARCH_UNIT_ID_RESPONSE = "SETTINGS_CREWS_SEARCH_UNIT_ID_RESPONSE";
