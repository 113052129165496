import {
  ACCOUNT_CHANGE_PASSWORD_OPEN_MODAL,
  ACCOUNT_CHANGE_PASSWORD_SET_PASSWORD,
  ACCOUNT_CHANGE_PASSWORD_SET_PASSWORD_RESULT
} from 'Redux/actionTypes';

export const openChangePasswordModal = (open) => ({
  type: ACCOUNT_CHANGE_PASSWORD_OPEN_MODAL,
  payload: open,
});

export const userChangePassword = (data) => ({
  type: ACCOUNT_CHANGE_PASSWORD_SET_PASSWORD,
  payload: { data },
});

export const userChangePasswordResponse = (data) => ({
  type: ACCOUNT_CHANGE_PASSWORD_SET_PASSWORD_RESULT,
  payload: { data },
});