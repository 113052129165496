// Action types.
import {
  VIDEO_MAIN_SET_INITIAL_TAB
} from 'Redux/actionTypes';


export const setCurrentTab = (tab) => {
  return {
    type: VIDEO_MAIN_SET_INITIAL_TAB,
    payload: tab
  };
};