import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';
// Actions
import {reportsMainGenerateReportProgress} from 'Redux/actions';

import {getTimeZone} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getDriverStatusDetailsReport(report, event) {
    const {timeZone, name, reportTimeRanges} = report;
    const {startRange, endRange} = report.filters;
    const {
        filterType,
        filterArray,
        page,
        rowsPerPage,
        sortField,
        sortOrder,
        otherFilters
    } = event.filters;
    try {
        const newReport = JSON.parse(JSON.stringify(report))

        let data = yield call(
            clientQuery,
            `query getDriverStatusDetailsReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
              Report: getDriverStatusDetailsReport (conditions: $conditions, limit: $limit, offset: $offset) {
                items {
                    driverId  
                    dateTime
                    address
                    vehicle
                    status
                    latitude
                    longitude
                }
                total
                limit
                offset
                category
              }
            }`,
            {
                "conditions": {
                    reportName: name,
                    category: {
                        field: filterType,
                        value: filterArray?.driverId
                    },
                    timeZone: getTimeZone(timeZone),
                    dateRange: {
                        fields: [
                            "unitTime"
                        ],
                        GTE: startRange,
                        LTE: endRange,
                        reportTimeRanges
                    },
                    sortField: {
                        field: sortField || "driverId",
                        order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                    },
                },
                limit: rowsPerPage,
                offset: page
            },
            endpoints.GRAPHQL_PX_REPORTING
        );
        if (data && data.Report) {
            newReport.summary = data.Report
            newReport.loadingSummary = false
            yield put(reportsMainGenerateReportProgress(newReport))
        } else {
            newReport.loadingSummary = false
            yield put(reportsMainGenerateReportProgress(newReport))
        }
    } catch (exc) {
        report.loadingSummary = false
        ExceptionManager(exc, '/reports/driverStatusReport', 'getDriverStatusDetailsReport');
        yield put(reportsMainGenerateReportProgress(report))
    }
}

export default getDriverStatusDetailsReport;
