import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// controls
import Table, { Column } from '../../table'

import {
  formatDatetime,
  getCategoryFilterType,
  getFilterNameByEntity,
  getPlaceholderSelector,
  getEntityType,
  useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';

// hooks
import { useSidePanelMap } from 'Core/hooks';

// actions
import {
  reportsMainGenerateReportUpdate,
  openFleetLandmarkModal,
  clearRedux,
  reportsMainGenerateSubEvents,
  reportsMainGenerateReportProgress,
  reportsMainGenerateEvents
} from 'Redux/actions';


// styles
import './style.scss';

const JBDTCReport = (props) => {

  const { messages, report } = props;
  const dispatch = useDispatch();
  const { loading, data, filters, loadingSummary, timeZone } = report;
  const { filterEntity, filterArray, filterType, startRange, endRange } = filters;
  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();

  const [unitDriverSelected, setUnitDriverSelected] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
  const [currentNewSortField, setCurrentNewSortField] = useState("deviceId");
  const [currentNewSortOrder, setCurrentNewSortOrder] = useState("asc");
  const [currentId, setCurrentId] = useState(null);

  const exportHandler = useHandleExportReport(report);

  const loadingCreateLandMark = useSelector(state => state.vehicleLandMarkRedux.loadingCreateLandMark)
  const createLandMarkResult = useSelector(state => state.vehicleLandMarkRedux.createLandMarkResult)
  const reportRef = useRef();
  useEffect(() => {
    if (report) {
      const newReport = JSON.parse(JSON.stringify(report));
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);

  useEffect(() => {
    if (!loadingCreateLandMark && createLandMarkResult) {
      dispatch(reportsMainGenerateReportUpdate(report, currentPage, currentRowsPerPage, currentNewSortField, currentNewSortOrder))
      dispatch(clearRedux("LANDMARK", { "createLandMarkResult": null }));
    }
  }, [loadingCreateLandMark, createLandMarkResult])

  const idEventsPerDay = (selected, id) => {
    let currentReport = report
    delete currentReport.summary
    dispatch(reportsMainGenerateReportProgress(currentReport))
    let events = {
      filters: {
        filterType: getCategoryFilterType(filterEntity),
        filterArray: [currentId],
        page: 1,
        rowsPerPage: 10,
        startRange: moment(selected).startOf('day').utc().toDate(),
        endRange: moment(selected).endOf('day').utc().toDate()
      }
    }
    dispatch(reportsMainGenerateEvents(report, events))
  }

  const renderExpandableRowSubEvents = (row) => {
    return (
      <div style={{ marginBottom: 10 }}>
        <Table
          loading={loadingSummary}
          data={report.summary?.items || []}
          totalItems={report.summary?.total}
          onChangedPage={(page) => {
            let events = {
              filters: {
                filterType: getCategoryFilterType(filterEntity),
                filterArray: [currentId],
                page: page,
                rowsPerPage: 10,
                startRange: moment(row).startOf('day').utc(),
                endRange: moment(row).endOf('day').utc()
              }
            }
            dispatch(reportsMainGenerateSubEvents(report, events))
          }}
          noDataMessage={messages["generatedReport_noEvents"]}
          hideNumberOfRows={true}
          classNameColumns={'report-sub-events-columns'}
          centerPagination
          serverSideTable={true}
        >

          <Column label={messages['generatedReport_timeAndDate']} field="date" noSorting>
            {(element) => {
              return (
                formatDatetime(element.dateTime, "MMMM D, YYYY h:mm A", timeZone)
              );
            }}
          </Column>
          <Column label={messages['generatedReport_spn']} field="spn" noSorting>
            {(element) => {
              return (
                exitstLabel(element.spn)
              );
            }}
          </Column>
          <Column label={messages['generatedReport_pid']} field="pid" noSorting>
            {(element) => {
              return (
                exitstLabel(element.pid)
              );
            }}
          </Column>
          <Column label={messages['generatedReport_fmi']} field="fmi" noSorting>
            {(element) => {
              return (
                exitstLabel(element.fmi)
              );
            }}
          </Column>
          <Column label={messages['generatedReport_occurrence']} field="occurrence" noSorting>
            {(element) => {
              return (
                exitstLabel(element.occurrence)
              );
            }}
          </Column>
          <Column label={messages['generatedReport_dtcStatus']} field="status" noSorting>
            {(element) => {
              return (
                exitstLabel(element.status)
              );
            }}
          </Column>
        </Table>
      </div>
    )
  }

  const exitstLabel = (label) => {
    if (!label) {
      return "--";
    }

    return label;
  };

  const selectId = (selected) => {
    if(!selected.noData){
      let selectedId = (filterEntity === "Unit" ? selected.deviceId : selected.driverId) || selected.id
      setCurrentId(selectedId);
    }
  }

  const summaryByDate = (row) => {
    return (
      <Table
        loading={loadingSummary}
        data={row?.dates || []}
        noDataMessage={messages["generatedReport_noEvents"]}
        hideHeader
        hiddenPagination
        renderExpandableRow={renderExpandableRowSubEvents}
        idRenderExpandableRow={(event) => { idEventsPerDay(event, row?.id) }}
        classNameColumns="landmark-crew-report-sub-table"
        showExpandable
        serverSideTable
        noData={row.noData}
      >
        <Column label="day" field="label">
          {(element) => {
            return <span><strong>{formatDatetime(element, "dddd, LL")}</strong></span>
          }}
        </Column>
        <Column label={messages['generatedReport_totalIdleTime']} field="label"></Column>
      </Table>
    )
  }

  return (
    <Fragment>
      <Table
        loading={loading}
        data={data.items || []}
        totalItems={data.total}
        groups={report.filters.filterArray || []}
        onChangedPage={(page, rowsPerPage, newSortField, newSortOrder) => {
          dispatch(reportsMainGenerateReportUpdate(reportRef.current || report, page, rowsPerPage, newSortField, newSortOrder))
          setCurrentPage(page)
          setCurrentRowsPerPage(rowsPerPage)
          setCurrentNewSortField(newSortField)
          setCurrentNewSortOrder(newSortOrder)
        }}
        noDataMessage={messages["reportsMainRT_noDataMessage"]}
        renderExpandableRow={summaryByDate}
        idRenderExpandableRow={selectId}
        hideHeader
        classNameColumns="landmark-crew-report-expandible"
        propertyToShowExpandable="dates"
        onChangeSelector={(value) => {
          const newReport = JSON.parse(JSON.stringify(report));
          newReport.filters = {
            ...newReport.filters,
            unitsSelected: value,
            loadingSummary: false
          };
          setUnitDriverSelected(value)
          dispatch(reportsMainGenerateReportUpdate(newReport, currentPage, currentRowsPerPage, currentNewSortField, currentNewSortOrder))
        }}
        valueSelector={unitDriverSelected}
        itemsSelector={filterArray}
        textFieldSelector={getFilterNameByEntity(filterType)}
        placeholderSelector={getPlaceholderSelector(filterType, messages)}
        asyncLoading={report.asyncLoading}
        serverSideTable={true}
        {...exportHandler}
      >
        <Column noSorting>
          {(element) => {
            const type = getEntityType(report, messages)
            return <span><strong>{type}:  </strong>{`${element.idLabel || ''}`}</span>
          }}
        </Column>
        <Column noSorting>
          {() => {
            const localTimeStart = moment.utc(startRange).local().format();
            const localTimeEnd = moment.utc(endRange).local().format();
            return (<div>
                      <span>
                        <span>
                          <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                        </span>
                      </span>
            </div>);
          }}
        </Column>
      </Table>
      <SideMapComponent hiddenButton hiddenSearchMap />
    </Fragment>
  );
};

export default JBDTCReport;
