//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
//Action Types
import {
	FLEET_CREATE_STARTER_SCHEDULE,
	FLEET_SEARCH_STARTER_SCHEDULES,
	FLEET_DELETE_STARTER_SCHEDULE,
	FLEET_UPDATE_STARTER_SCHEDULE,
} from 'Redux/actionTypes';

//Actions
import {
	saveStarterScheduleResult,
	searchStarterSchedulesResult,
	deleteStarterScheduleResult,
	updateStarterScheduleResult,
} from 'Redux/actions';

function* updateStarterSchedule({ payload }) {
	try {
		let data = yield call(
			clientMutation,
			`mutation  (
        $id: String!
        $name: String!
        $groupIds: [Int]!
        $schedule: WeekScheduleInput!
      ) {
        updateStarterSchedule(id: $id, name: $name, groupIds: $groupIds, schedule: $schedule){
          id
        }
      }`,
			{
				...payload
			},
			endpoints.GRAPHQL_GENERAL,
		);
		if (data && data.updateStarterSchedule) {
			yield put(updateStarterScheduleResult(data.updateStarterSchedule));
		} else throw 'no update starter schedule';
	} catch (exc) {
		// ALWAYS RETURN ACTION
		ExceptionManager(
			exc,
			'modules/fleet/components/ModalScheduleStater/redux/saga',
			'updateStarterSchedule',
		);
		yield put(updateStarterScheduleResult([]));
	}
}

function* deleteStarterSchedule({ payload }) {
	try {
		let data = yield call(
			clientMutation,
			`mutation  (
        $id: String
      ) {
        deleteStarterSchedule(
          id: $id
        )
      }`,
			{
				id: payload,
			},
			endpoints.GRAPHQL_GENERAL,
		);
		if (data && data.deleteStarterSchedule) {
			yield put(deleteStarterScheduleResult(data.deleteStarterSchedule));
		} else throw 'no delete starter schedule';
	} catch (exc) {
		// ALWAYS RETURN ACTION
		ExceptionManager(
			exc,
			'modules/fleet/components/ModalScheduleStater/redux/saga',
			'deleteStarterSchedule',
		);
		yield put(deleteStarterScheduleResult([]));
	}
}

function* searchStarterSchedules() {
	try {
		let data = yield call(
			clientQuery,
			`query {
        searchStarterSchedules {
          id
					name
					accountId
					groupIds
					groups {
							id
							name
							color
					}
					schedule {
							monday {
									enableTime
									disableTime
							}
							tuesday {
									enableTime
									disableTime
							}
							wednesday {
									enableTime
									disableTime
							}
							thursday {
									enableTime
									disableTime
							}
							friday {
									enableTime
									disableTime
							}
							saturday {
									enableTime
									disableTime
							}
							sunday {
									enableTime
									disableTime
							}
					}
        }
      }`,
			{},
			endpoints.GRAPHQL_GENERAL,
		);
		if (data && data.searchStarterSchedules)
			yield put(searchStarterSchedulesResult(data.searchStarterSchedules));
		else throw 'no search starter schedules';
	} catch (exc) {
		// ALWAYS RETURN ACTION
		ExceptionManager(exc, 'modules/fleet/components/ModalScheduleStater/redux/saga', 'searchStarterSchedules');
		yield put(searchStarterSchedulesResult([]));
	}
}

function* createStarterScheduleRequest({ payload }) {
	try {
		let data = yield call(
			clientMutation,
			`mutation  (
        $name: String!
        $groupIds: [Int]!
        $schedule: WeekScheduleInput!
      ) {
        createStarterSchedule(
          name: $name,
          groupIds: $groupIds,
          schedule: $schedule
        ){
          id
					name
					accountId
					groupIds
        }
      }`,
			{
				...payload,
			},
			endpoints.GRAPHQL_GENERAL,
		);
		if (data && data.createStarterSchedule) yield put(saveStarterScheduleResult(data.createStarterSchedule));
		else throw 'not created starter schedule';
	} catch (exc) {
		// ALWAYS RETURN ACTION
		ExceptionManager(
			exc,
			'modules/fleet/components/ModalScheduleStater/redux/saga',
			'createStarterScheduleRequest',
		);
		yield put(saveStarterScheduleResult([]));
	}
}


//Watcher

function* watchUpdateVehicleSchedule() {
	yield takeEvery(FLEET_UPDATE_STARTER_SCHEDULE, updateStarterSchedule);
}

function* watchDeleteVehicleSchedule() {
	yield takeEvery(FLEET_DELETE_STARTER_SCHEDULE, deleteStarterSchedule);
}

function* watchSaveVehicleScheduled() {
	yield takeEvery(FLEET_CREATE_STARTER_SCHEDULE, createStarterScheduleRequest);
}

function* watchGetVehiclesScheduleGroupsRequest() {
	yield takeEvery(FLEET_SEARCH_STARTER_SCHEDULES, searchStarterSchedules);
}

//Saga Export
export default function* rootSaga() {
	yield all([
		fork(watchDeleteVehicleSchedule),
		fork(watchUpdateVehicleSchedule),
		fork(watchSaveVehicleScheduled),
		fork(watchGetVehiclesScheduleGroupsRequest),
	]);
}
