import React, { useEffect, useState } from "react";
import { Tooltip } from "../../../../components";
import ChatIcon from "@mui/icons-material/Forum";
import { IconButton, Badge } from "@mui/material";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { chatChanneltListOpen } from '../../../../.globals/redux/actions';

const Chat = () => {
  const { messages } = useIntl();
  const dispatch = useDispatch();

  const unreadMessages = true;
  const { authId } = useSelector(state => state.securityRedux)

  return (
    <>
      <IconButton
        aria-label="placeholder"
        onClick={() => {
          dispatch(chatChanneltListOpen({
            variableQuery: {
              userId: JSON.stringify(authId)
            }
          }));
        }}
        size="large"
      >
        <Tooltip title={messages["chat"]}>
          <Badge variant="dot" color="success" overlap="circular">
            <ChatIcon style={{ color: unreadMessages > 0 ? "rgb(102, 187, 106)" : "" }} />
          </Badge>
        </Tooltip>
      </IconButton>
    </>
  );
};

export default Chat;
