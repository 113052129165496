import {
    //TYPES
    MAP_GEOFENCES_UNITS_SEARCH,
    MAP_GEOFENCES_UNITS_SEARCH_RESPONSE
} from 'Redux/actionTypes';

export const searchUnitsGeofence = (openSearch) => ({
    type: MAP_GEOFENCES_UNITS_SEARCH,
    payload: { openSearch }
});

export const searchUnitsGeofenceResponse = (data) => ({
        type: MAP_GEOFENCES_UNITS_SEARCH_RESPONSE,
        payload: { data },
});