import { Box } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AreaMenu, { Area, Menu } from "Components/AreaMenu";
import { Input, Select, Button } from "Components";

const RangeLabelTime = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  selectedTime,
  value,
  setValue
}) => {
  const { messages } = useIntl();
  const areaMenuRef = useRef();

  const getPositionInRange = (x) => {
    const start = moment(selectedTime);
    const target = moment(x);
    const secondsFromStart = target.diff(start, 'seconds');
    return secondsFromStart;
  };

  const validateStartDate = (date) => {
    if(moment(selectedTime).isAfter(date)) return
    if(date.isAfter(moment(endDate).subtract(1, 'm'))) return
    const newValue = getPositionInRange(date)
    let rightValue = value[1];
    setValue([newValue , rightValue])
    setStartDate(date.format())
  }

  const validateEndDate = (date) => {
    if(moment(selectedTime).add(5, 'm').isBefore(date)) return
    if(date.isBefore(moment(startDate).add(1, 'm'))) return
    const newValue = getPositionInRange(date)
    let leftValue = value[0];
    setValue([leftValue , newValue])
    setEndDate(date.format())
  }

  return (
    <>
      <AreaMenu
        className="datepicker_container--areamenu"
        id={"ssss"}
        ref={areaMenuRef}
      >
        <Area>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <label
              style={{
                marginRight: "13px",
                fontWeight: "bold",
                fontSize: "14px",
                color: "#000000",
                cursor: "pointer",
              }}
            >
              {messages["videoClipRequest_selectedClip"]}:
            </label>
            <label
              style={{ fontSize: "14px", color: "#000000", cursor: "pointer" }}
            >
              {`${startDate ? moment(startDate).format("hh:mm:ss a") : "----"} - ${
                endDate ? moment(endDate).format("hh:mm:ss a") : "----"
              } `}
            </label>
            <span style={{ margin: "-1px", cursor: "pointer" }}>
              <ArrowDropDownIcon />
            </span>
          </Box>
        </Area>
        <Menu style={{ width: "475px" }}>
          <Box style={{ display: "flex", padding: "10px" }}>
            <Box mr={4} style={{ width: "20%" }}>
              <Input
                label={messages["mins"]}
                onlyPositive
                preventOnChange
                value={moment(startDate)?.format('mm')}
                onChange={(val) => {
                  if (val.length > 2) return;
                  const newDate = moment(startDate).minutes(val)
                  validateStartDate(newDate)
                }}
                style={{ width: 15 }}
                type="number"
                min={0}
                max={59}
                className="inputDateTime"
              />
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                margin: "30px 5px 0px -5px",
              }}
            >
              :
            </Box>
            <Box mr={4} style={{ width: "20%" }}>
              <Input
                label={messages["seconds"]}
                onlyPositive
                preventOnChange
                value={moment(startDate)?.format('ss')}
                onChange={(val) => {
                  if (val.length > 2) return;
                  const newDate = moment(startDate).seconds(val)
                  validateStartDate(newDate)
                }}
                style={{ width: 15 }}
                type="number"
                min={0}
                max={59}
                className="inputDateTime"
              />
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                margin: "30px 5px 0px -5px",
              }}
            >
              -
            </Box>
            <Box mr={4} style={{ width: "20%" }}>
              <Input
                label={messages["mins"]}
                onlyPositive
                preventOnChange
                value={moment(endDate)?.format('mm')}
                onChange={(val) => {
                  if (val.length > 2) return;
                  const newDate = moment(endDate).minutes(val)
                  validateEndDate(newDate)
                }}
                style={{ width: 15 }}
                type="number"
                min={0}
                max={59}
                className="inputDateTime"
              />
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                margin: "30px 5px 0px -5px",
              }}
            >
              :
            </Box>
            <Box mr={4} style={{ width: "20%" }}>
              <Input
                label={messages["seconds"]}
                onlyPositive
                preventOnChange
                value={moment(endDate)?.format('ss')}
                onChange={(val) => {
                  if (val.length > 2) return;
                  const newDate = moment(endDate).seconds(val)
                  validateEndDate(newDate)
                }}
                style={{ width: 15 }}
                type="number"
                min={0}
                max={59}
                className="inputDateTime"
              />
            </Box>
          </Box>
        </Menu>
      </AreaMenu>
    </>
  );
};

export default RangeLabelTime;
