import {
    FSM_SETTINGS_TIME_CARD_SEARCH,
    FSM_SETTINGS_TIME_CARD_SEARCH_RESPONSE,
    FSM_SETTINGS_TIME_CARD_CREATE,
    FSM_SETTINGS_TIME_CARD_CREATE_RESPONSE,
    FSM_SETTINGS_TIME_CARD_UPDATE,
    FSM_SETTINGS_TIME_CARD_UPDATE_RESPONSE,
    FSM_SETTINGS_TIME_CARD_DELETE,
    FSM_SETTINGS_TIME_CARD_DELETE_RESPONSE,
    FSM_SETTINGS_TIME_CARD_OPEN_CREATE_EDIT,
    FSM_SETTINGS_TIME_CARD_OPEN_DELETE,
    FSM_SETTINGS_TIME_CARD_DRIVER,
    FSM_SETTINGS_TIME_CARD_DRIVER_RESPONSE
} from "Redux/actionTypes";



export const searchTimeCard = (data,load) => {
    return {
        type: FSM_SETTINGS_TIME_CARD_SEARCH,
        payload: { data,load }
    };
};

export const searchTimeCardResponse = (data) => {
    return {
        type: FSM_SETTINGS_TIME_CARD_SEARCH_RESPONSE,
        payload: data,
    };
};

export const searchDriverTimeCard = () => {
    return {
        type: FSM_SETTINGS_TIME_CARD_DRIVER,
        payload: {},
    };
};

export const searchDriverTimeCardResponse = (data) => {
    return {
        type: FSM_SETTINGS_TIME_CARD_DRIVER_RESPONSE,
        payload: data,
    };
};

export const createTimeCard = (timeCard) => {
    return {
        type: FSM_SETTINGS_TIME_CARD_CREATE,
        payload: timeCard,
    };
};

export const createTimeCardResponse = (data) => {
    return {
        type: FSM_SETTINGS_TIME_CARD_CREATE_RESPONSE,
        payload: data,
    };
};

export const updateTimeCard = (timeCard) => {
    return {
        type: FSM_SETTINGS_TIME_CARD_UPDATE,
        payload: timeCard,
    };
};

export const updateTimeCardResponse = (data) => {
    return {
        type: FSM_SETTINGS_TIME_CARD_UPDATE_RESPONSE,
        payload: data,
    };
};

export const deleteTimeCard = (timeCard) => {
    return {
        type: FSM_SETTINGS_TIME_CARD_DELETE,
        payload: { timeCard },
    };
};


export const deleteTimeCardResponse = (data) => {
    return {
        type: FSM_SETTINGS_TIME_CARD_DELETE_RESPONSE,
        payload: data,
    };
};


export const openTimeCardCreateEdit = (open, timeCard) => {
    return {
        type: FSM_SETTINGS_TIME_CARD_OPEN_CREATE_EDIT,
        payload: { open, timeCard }
    };
};

export const openTimeCardDelete = (open, timeCards) => {
    return {
        type: FSM_SETTINGS_TIME_CARD_OPEN_DELETE,
        payload: { open, timeCards }
    };
};