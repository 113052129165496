import {
    FSM_SETTINGS_DRIVER_STATUS_SEARCH,
    FSM_SETTINGS_DRIVER_STATUS_SEARCH_RESPONSE,
    FSM_SETTINGS_DRIVER_STATUS_CREATE,
    FSM_SETTINGS_DRIVER_STATUS_CREATE_RESPONSE,
    FSM_SETTINGS_DRIVER_STATUS_UPDATE,
    FSM_SETTINGS_DRIVER_STATUS_UPDATE_RESPONSE,
    FSM_SETTINGS_DRIVER_STATUS_DELETE,
    FSM_SETTINGS_DRIVER_STATUS_DELETE_RESPONSE,
    FSM_SETTINGS_DRIVER_STATUS_OPEN_CREATE_EDIT,
    FSM_SETTINGS_DRIVER_STATUS_OPEN_DELETE
} from "Redux/actionTypes";



export const searchDriverStatus = () => {
    return {
        type: FSM_SETTINGS_DRIVER_STATUS_SEARCH,
        payload: {},
    };
};

export const searchDriverStatusResponse = (data) => {
    return {
        type: FSM_SETTINGS_DRIVER_STATUS_SEARCH_RESPONSE,
        payload: data,
    };
};

export const createDriverStatus = (driverStatus) => {
    return {
        type: FSM_SETTINGS_DRIVER_STATUS_CREATE,
        payload: driverStatus,
    };
};

export const createDriverStatusResponse = (data) => {
    return {
        type: FSM_SETTINGS_DRIVER_STATUS_CREATE_RESPONSE,
        payload: data,
    };
};

export const updateDriverStatus = (driverStatus) => {
    return {
        type: FSM_SETTINGS_DRIVER_STATUS_UPDATE,
        payload: driverStatus,
    };
};

export const updateDriverStatusResponse = (data) => {
    return {
        type: FSM_SETTINGS_DRIVER_STATUS_UPDATE_RESPONSE,
        payload: data,
    };
};

export const deleteDriverStatus = (driverStatus) => {
    return {
        type: FSM_SETTINGS_DRIVER_STATUS_DELETE,
        payload: { driverStatus },
    };
};


export const deleteDriverStatusResponse = (data) => {
    return {
        type: FSM_SETTINGS_DRIVER_STATUS_DELETE_RESPONSE,
        payload: data,
    };
};


export const openDriverStatusCreateEdit = (open, driverStatus) => {
    return {
        type: FSM_SETTINGS_DRIVER_STATUS_OPEN_CREATE_EDIT,
        payload: { open, driverStatus }
    };
};

export const openDriverStatusDelete = (open, driverStatuss) => {
    return {
        type: FSM_SETTINGS_DRIVER_STATUS_OPEN_DELETE,
        payload: { open, driverStatuss }
    };
};