import {
  MAP_GEOFENCE_VISIBLE_GEOFENCE_TAGS,
  MAP_GEOFENCE_VISIBLE_GEOFENCE_TAGS_RESPONSE,
  MAP_GEOFENCE_UPDATE_PREFERENCE
} from 'Redux/actionTypes';

export const mapGeofencesVisibleGetTags = () => ({
  type: MAP_GEOFENCE_VISIBLE_GEOFENCE_TAGS,
});

export const mapGeofenceVisibleGetGeofenceTagsResponse = (response) => ({
  type: MAP_GEOFENCE_VISIBLE_GEOFENCE_TAGS_RESPONSE,
  payload: { response },
});

export const mapGeofencesUpdatePreference = () => ({
  type: MAP_GEOFENCE_UPDATE_PREFERENCE,
});