import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientMutation, clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from 'Core/logManager';

const INIT_STATE = {
    open: false,
    loading: false,
    driverTasksScheduled: [],
    driverId: null,
    driverName: null,
};

const route = "modules/maintenance/modals/manageDriverTasksScheduled/redux/slice";

export const maintenanceMDTSSearchDriverScheduledTasks = createAsyncThunk("maintenance/manageDriverTasksScheduled/searchDriverScheduledTasks", async (payload, { rejectWithValue, dispatch }) => {
    try {
        const { openSearch, driverId } = payload;
        let response = await clientQuery(
            `query ($openSearch: String, $driverId: Int){
                data: searchDriverScheduledTasks(openSearch: $openSearch, driverId: $driverId){
                    id
                    driverId
                    driverTaskId
                    driverTaskName
                    status
                    dueDate
                    completed
                    lastMaintenanceDate
                }
            }`,
            {
                openSearch,
                driverId,
            },
            endpoints.GRAPHQL_GENERAL
        );
        return (response.data);
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceMDTSSearchDriverScheduledTasks');
        rejectWithValue(exc);
    }
});

export const maintenanceManageDriverTasksScheduledRedux = createSlice({
    name: 'maintenance/manageDriverTasksScheduled',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalManageDriverTasksScheduled: (state, action) => {
            state.open = action.payload.open
            state.driverId = action.payload.driverId
            state.driverName = action.payload.driverName
        }
    },
    extraReducers: (builder) => {
        //maintenanceMDTSSearchDriverScheduledTasks
        builder.addCase(maintenanceMDTSSearchDriverScheduledTasks.pending, (state) => {
            state.loading = true
        });

        builder.addCase(maintenanceMDTSSearchDriverScheduledTasks.fulfilled, (state, action) => {
            state.driverTasksScheduled = action.payload,
            state.loading = false
        });

        builder.addCase(maintenanceMDTSSearchDriverScheduledTasks.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        });
    },
});

const { actions, reducer } = maintenanceManageDriverTasksScheduledRedux;

export const {
    reset,
    openModalManageDriverTasksScheduled,
} = actions;

export default reducer;