import {
  UNIT_SETTINGS_GET_UNIT_GROUPS,
  UNIT_SETTINGS_GET_UNIT_GROUPS_RESPONSE,
  UNIT_SETTINGS_UPDATE_MULTIPLE_UNITS,
  UNIT_SETTINGS_UPDATE_MULTIPLE_UNITS_RESPONSE
} from "Redux/actionTypes";

export const getUnitGroupsByUnitsResponse = (data) => {
  return {
      type: UNIT_SETTINGS_GET_UNIT_GROUPS_RESPONSE,
      payload: data,
  };
};

export const updateUnitGroupTagAssignment = (data, dataIcons = null) => {
  return {
      type: UNIT_SETTINGS_UPDATE_MULTIPLE_UNITS,
      payload: { data, dataIcons },
  };
};

export const updateUnitGroupTagAssignmentResponse = (data) => {
  return {
      type: UNIT_SETTINGS_UPDATE_MULTIPLE_UNITS_RESPONSE,
      payload: data,
  };
};
