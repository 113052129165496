export const MapUtilitiesTypes = {
  Default: 0,
  Vehicles : 1,
  RealtimeMaps : 2,
  VehicleTrails : 3,
  Landmarks : 4, 
  Geofences : 5,
  RouteMaker : 6,
  VehicleDirections : 7,
  FindNearest : 8,
  SendSMS : 9,
  MapSettings : 10, 
  GoogleEarth : 11,
  Video : 12,
  LayerMap: 13,
  UnitSelection: 14,
  UnitSelectionModal: 15,
  MapSettings: 16,
  Eta: 17,
  SpeedOverride: 18,
  VicinityTool: 19,
  TagFilter: 20
}