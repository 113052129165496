export const FlagColorData = [
    '8677D9',
    '5243AA',
    '2584FF',
    '0053CB',
    '58D8A3',
    '018759',
    '08C6E6',
    '06A2BF',
    'FF7452',
    'DE350D',
    'FFC402',
    'FF991F',
    '6B778C',
    '253858'
];
