import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { clientQuery, clientMutation } from '../../../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../../../../../core/logManager';

import { resetALL } from '../../../../../../../../../.globals/redux/actions';


//STATE INITIAL
const INIT_STATE = {
    open: false,
    response: null,
    driverStatusLog: null,
    driver: null,
    lastLog: null,
    driverStates: [],
    error: null,
    loading: false
};

const route = '/modules/settings/views/driver/components/timeClock/components/createEdit/redux/slice';

export const createEditDriverStatusLog = createAsyncThunk('driver/components/timeClock/components/createEdit/createEditDriverStatusLog', async ({ id, driverId, statusId, latitude, longitude, address, statusLogOn }, { rejectWithValue, dispatch }) => {
    try {
        let query = `
            mutation createDriverStatusLog (
                $driverId: Int!
                $statusId: Int!
                $statusLogOn: AWSDateTime	
                $latitude: Float!
                $longitude: Float!
                $address: String
            ) {
                driverStatusLog: createDriverStatusLog (
                    driverId: $driverId
                    statusId: $statusId
                    statusLogOn: $statusLogOn	
                    latitude: $latitude
                    longitude: $longitude
                    address: $address
                ) {
                    id
                }
            }
        `;

        if (id) {
            query = `
                mutation updateDriverStatusLog (
                    $id: Int!
                    $driverId: Int!
                    $statusId: Int!
                    $statusLogOn: AWSDateTime	
                    $latitude: Float!
                    $longitude: Float!
                    $address: String
                ) {
                    driverStatusLog: updateDriverStatusLog (
                        id: $id
                        driverId: $driverId
                        statusId: $statusId
                        statusLogOn: $statusLogOn	
                        latitude: $latitude
                        longitude: $longitude
                        address: $address
                    ) {
                        id
                    }
                }
            `;
        }


        const response = await clientMutation(
            query,
            {
                id, 
                driverId, 
                statusId, 
                latitude, 
                longitude, 
                statusLogOn,
                address
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response?.driverStatusLog?.id) {
            throw `Error while creating/updating driverStatusLog ${id}`;
        }

        return response?.driverStatusLog;
    } catch (exc) {
        ExceptionManager(exc, route, 'searchDriverStatusLog');
        return rejectWithValue(exc)
    }
});

export const getAllDriverStatus = createAsyncThunk('driver/components/timeClock/components/createEdit/getAllDriverStatus', async ({}, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query searchDriverStatus (
                $sort: [DriverStatusSortInput], 
                $limit: Int, 
                $offset: Int
            ) {
                data: searchDriverStatus (
                    sort: $sort, 
                    limit: $limit, 
                    offset: $offset
                ) {
                    items {
                        id
                        name
                    }
                    total
                }
            }
            `,
            {
                sort: {
                    field: "name",
                    order: "ASC"
                },
                limit: 100,
                offset: 0
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response?.data) {
            throw 'Error while getting driver states';
        }

        const { items } = response?.data;
        return items;
    } catch (exc) {
        ExceptionManager(exc, route, 'getAllDriverStatus');
        return rejectWithValue(exc)
    }
});

//Slice
const driverStatusLogCreateEditRedux = createSlice({
    name: 'driverStatusLog/createEdit',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openCreateEditModal(state, action) {
            state.open = true;
            state.response = null;
            state.driverStatusLog = action?.payload?.driverStatusLog;
            state.driver = action?.payload?.driver;
            state.lastLog = action?.payload?.lastLog;
            state.error = null;
        },
        closeCreateEditModal(state) {
            state.open = false;
            state.driverStatusLog = null;
            state.driver = null;
            state.lastLog = null;
            state.response = null;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(createEditDriverStatusLog.pending, (state, action) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(createEditDriverStatusLog.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
            state.error = null;
        });
        builder.addCase(createEditDriverStatusLog.rejected, (state, action) => {
            state.loading = false;
            state.response = null;
            state.error = action.payload;
        });

        builder.addCase(getAllDriverStatus.pending, (state, action) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAllDriverStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.driverStates = action.payload;
            state.error = null;
        });
        builder.addCase(getAllDriverStatus.rejected, (state, action) => {
            state.loading = false;
            state.driverStates = [];
            state.error = action.payload;
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = driverStatusLogCreateEditRedux;

// Extract and export each action creator by name
export const {
    reset,
    openCreateEditModal,
    closeCreateEditModal,
} = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;