import {
    //TYPES
    MAP_UTILITIES_UNIT_SELECTION_SET,
    CLEAR_REDUX,
} from "Redux/actionTypes";

const INIT_STATE = {
    units: [],
    show: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MAP_UTILITIES_UNIT_SELECTION_SET:
            return { ...state, units: action.payload.units, show: action.payload.show  }
        case CLEAR_REDUX:
            return ["", "UNIT_SELECTION"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
      return state;
    }
};