import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
// controls
import Table, {Column} from '../../table'

import {formatDatetime, getCategoryFilterType, getEntityType, getNameEntity, useHandleExportReport} from 'Modules/reports/views/main/components/reportsTab/helper';


// hooks
import {useSidePanelMap, useTableReport} from 'Core/hooks';
// actions
import {reportsMainGenerateEvents, reportsMainGenerateReportProgress} from 'Redux/actions';
import './stopSummaryReport.scss';
import moment from "moment/moment";

const ServiceItemsReport = (props) => {

    const {messages, report} = props;
    const dispatch = useDispatch();
    const {loading, data, filters, loadingSummary, timeZone, summary} = report;
    const {filterEntity, startRange, endRange} = filters;

    const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
    const [currentId, setCurrentId] = useState(null);

    const [eventFilter, setEventFilter] = useState({});
    const exportHandler = useHandleExportReport(report);

    const reportRef = useRef();
    useEffect(() => {
        if (report) {
            const newReport = JSON.parse(JSON.stringify(report));
            reportRef.current = newReport;
        }
    }, [JSON.stringify(report)]);


    const idEventsPerDay = (selected, id) => {
        let currentReport = report
        delete currentReport.summary
        dispatch(reportsMainGenerateReportProgress(currentReport))
        let events = {
            filters: {
                filterType: getCategoryFilterType(filterEntity),
                filterArray: [selected.id],
                otherFilters: {
                    event: id
                },
                page: 1,
                rowsPerPage: 10,
                startRange: startRange,
                endRange: endRange
            }
        }
        setEventFilter(events)
        dispatch(reportsMainGenerateEvents(report, events))
    }

    const summaryTable = (item, type) => {
        const durations = item.map((id) => id?.cost)
        const summary = item.length ? durations.reduce((p, c) => p + c) : '';
        return (
            <Fragment>
                <Table
                    data={[0]}
                    noDataMessage={messages["reportsMainRT_noTripsAvailable"]}
                    hiddenPagination
                    hideHeader
                    classNameColumns={'report-sub-events-columns summary-event-columns'}
                >
                    <Column>
                        {() => {
                            return <strong>{messages['generatedReport_totalCost']}</strong>
                        }}
                    </Column>
                    <Column>
                        {() => {
                            return <span>${Number(summary).toFixed(2)}</span>
                        }}
                    </Column>
                </Table>
            </Fragment>
        )
    }

    const renderExpandableRowSubEvents = (row) => {

        return (
            <div style={{marginBottom: 10}}>
                {
                    <div style={{width: '100%'}}>
                        {summaryTable(summary?.items || [], row.title)}
                    </div>
                }
                <Table
                    loading={loadingSummary}
                    data={summary?.items || []}
                    noDataMessage={messages["reportsMainRT_noTripsAvailable"]}
                    hideNumberOfRows={true}
                    hiddenPagination={true}
                    classNameColumns={'report-sub-events-columns detail-day-moving-tr'}
                >
                    <Column label={messages["generatedReport_maintenance"]} field="maintenance" noSorting>
                        {(element) => {
                            return (
                                <div>
                                    <span>{element.maintenance}</span>
                                </div>
                            )
                        }}
                    </Column>
                    <Column label={messages["generatedReport_timeAndDate"]} field="dateTime" noSorting>
                        {(element) => {
                            return (
                                <div>
                                    <span className="speed-report-stacked">{element.dateTime && formatDatetime(element.dateTime, "h:mm A", timeZone)}</span>
                                    <span
                                        className="speed-reportstacked speed-report-event-date">{element.dateTime && formatDatetime(element.dateTime, "MMM D", timeZone)}</span>
                                </div>
                            )
                        }}
                    </Column>
                    <Column label={messages['generatedReport_cost']} field="cost" noSorting>
                        {(element) => {
                            return (
                                <div>
                                    <span>${element.cost}</span>
                                </div>
                            )
                        }}
                    </Column>
                    <Column label={messages['generatedReport_serviceNotes']} field="serviceNotes" noSorting>
                        {(element) => {
                            return (
                                <div>
                                    <span>{element.serviceNotes}</span>
                                </div>
                            )
                        }}
                    </Column>
                </Table>
            </div>
        )
    }

    const onChangeExpandable = (selected) => {
        if(!selected.noData){
            let selectedId = (filterEntity === "Unit" ? selected.deviceId : selected.driverId) || selected.id
            setCurrentId(selectedId);
        }
    }

    let parametersTableSummary = useTableReport({
        data: [],
        report,
        messages,
        hideHeader: true,
        hiddenPagination: true,
        renderExpandableRow: renderExpandableRowSubEvents,
        showExpandable: true
    });

    const renderExpandableRow = (row) => {
        const summaryParameters = {
            ...parametersTableSummary,
            data: (filterEntity === 'Driver' ? row?.serviceItems : row?.devices[0]?.serviceItems) || [],
            onChangeSelector: null,
            placeholderSelector: null,
            hideSelectorUnitsDriver: true,
            classNameColumns: "table-report-main-sub-table",
            idRenderExpandableRow: (event) => {idEventsPerDay(event, filterEntity === 'Driver' ? row?.deviceHID : row?.devices[0]?.deviceHID)},
            noData: row.noData
        }
        return (
            <Table
                {...summaryParameters}
            >
                <Column label="day" field="title">
                    {(element) => {
                        return <span><strong>{messages['generatedReport_serviceItem']}</strong>{element.title}</span>
                    }}
                </Column>
                <Column>
                </Column>
            </Table>
        )
    }

    let parametersTableDriver = useTableReport({
        data: [],
        report,
        messages,
        hideHeader: true,
        hiddenPagination: true,
        renderExpandableRow,
        onChangeExpandable,
        showExpandable: true,
    });

    const renderExpandableRowDriver = (row) => {
        const summaryParameters = {
            ...parametersTableDriver,
            data: row?.devices,
            onChangeSelector: null,
            placeholderSelector: null,
            hideSelectorUnitsDriver: true,
            noData: row.noData
        }
        return (
            <Table
                {...summaryParameters}
            >
                <Column label="day" field="label">
                    {(element) => {
                        return <span><strong>{messages['generatedReport_unit']}</strong>{element.deviceLabel}</span>
                    }}
                </Column>
                <Column>
                </Column>
            </Table>
        )
    }

    let parametersTable = useTableReport({
        data: data.items || [],
        report,
        messages,
        hideHeader: true,
        renderExpandableRow: filterEntity === 'Driver' ? renderExpandableRowDriver : renderExpandableRow,
        onChangeExpandable,
        propertyToShowExpandable: 'id',
        classNameColumns: "table-report-main-expandible"
    });

    return (
        <Fragment>
            <Table
                {...parametersTable}
                {...exportHandler}
            >
                <Column noSorting>
                    {(element) => {
                        return <span><strong>{getNameEntity(filterEntity, messages)}:</strong> {element.idLabel} </span>
                    }}
                </Column>
                <Column noSorting>
                    {() => {
                        const localTimeStart = moment.utc(startRange).local().format();
                        const localTimeEnd = moment.utc(endRange).local().format();
                        return (<div>
                                  <span>
                                    <span>
                                      <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                                    </span>
                                  </span>
                            </div>);
                    }}
                </Column>
            </Table>
        </Fragment>
    );
};

export default ServiceItemsReport;
