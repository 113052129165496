export default {
    scheduleMaintenanceService_vehicle: "Vehículo",
    scheduleMaintenanceService_service: "Servicio",
    scheduleMaintenanceService_dateOfServiceCompletes: "Fecha de servicio completa",
    scheduleMaintenanceService_dateOfLastService: "Fecha del último servicio",
    scheduleMaintenanceService_odometer: "Odómetro",
    scheduleMaintenanceService_engineHours: "Horas de motor",
    scheduleMaintenanceService_notes: "Notas",
    scheduleMaintenanceService_intervalTypes: "Tipos de intervalo",
    scheduleMaintenanceService_servicesNotes: "Notas de servicio",
    scheduleMaintenanceService_addVehicle: "Añadir un vehículo a",
    scheduleMaintenanceService_selectDate: "Seleccionar fecha",
    scheduleMaintenanceService_servicesAttachment: "Adjuntos de Servicios",
    scheduleMaintenanceService_serviceSettings: "Ajustes del servicio",
    scheduleMaintenanceService_editScheduledServiceFor: "Editar servicio programado para",
    scheduleMaintenanceService_scheduleServiceFor: "Programar servicio para",
    scheduleMaintenanceService_editScheduledVehicleFor: "Editar vehículo programado para",
    scheduleMaintenanceService_addNewService: "Añadir nuevo servicio",
}