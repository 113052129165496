import React from "react";
import { ErrorHandlerLogin } from "Core/data/Helpers";
import { useIntl } from "react-intl";

const ErrorMessage = ({ current_reseller, error }) =>  {
  const { messages } = useIntl();

  const validateErrorMessage = (label) => {
    switch (label) {
      case "Error: Username/client id combination not found.":
        return messages["forgotPassword_errorMesaggeUserNoFound"];
      default:
        return ErrorHandlerLogin(label);
    }
  };

  return (
    <div className="form-item card-error-login">
      <p className="txt-card-error-login">
        {
          // If forgot password and is a reseller show a different message
          current_reseller &&
          ErrorHandlerLogin(error) === "forgotPassword_userNoMigrated"
            ? messages["forgotPassword_userNoMigratedReseller"]
            : messages[ErrorHandlerLogin(error)] || validateErrorMessage(error)
        }
      </p>
    </div>
  );
}

export default ErrorMessage;
