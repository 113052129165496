// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  SUB_USER_SEARCH,
  SUB_USER_GET,
  SUB_USER_CREATE,
  SUB_USER_UPDATE,
  SUB_USER_DELETE,
  SUB_USER_RESET_COGNITO,
  SUB_USER_DELETE_BULK,
  SUB_USER_RESET_PASSWORD,
} from "Redux/actionTypes";

// Actions
import {
  searchSubUserResponse,
  getSubUserResponse,
  createSubUserResponse,
  updateSubUserResponse,
  deleteSubUserResponse,
  resetCognitoSubUserResponse,
  resetPasswordCognitoResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* searchSubUser({ payload }) {
  const { openSearch, limit, offset } = payload;
  const query = `
    query searchSubUsers ($openSearch: String, $limit: Int, $offset: Int) {
      searchSubUsers (openSearch: $openSearch, limit: $limit, offset: $offset) {
          items {
              id
              userName
              firstName
              lastName
              roleId
              customRoleId
              role {
                  id
                  name
                  description
                  modules {
                      id
                      code
                      userCode
                      name
                      category
                      subModules {
                          id
                          userCode
                          name
                          groupName
                          parentModuleId
                          category
                      }
                  }
                  isCustom
                  customOwner
              }
              customRole {
                  id
                  name
                  description
                  modules {
                      id
                      code
                      userCode
                      name
                      groupName
                      parentModuleId
                      category
                      subModules {
                          id
                          userCode
                          name
                          groupName
                          parentModuleId
                          category
                      }
                  }
                  isCustom
                  customOwner
              }
              tagIds
              email
              activeTab
              expiration
              phone
              eldUser {
                username
                password
              }
          }
          total
      }
    }`;

  const variables = {
    openSearch,
    limit,
    offset
  };

  try {
    const data = yield call(
      clientQuery,
      query,
      variables,
      endpoints.GRAPHQL_GENERAL
    );
    if (data) {
      const { searchSubUsers: { items, total } } = data;
      yield put(searchSubUserResponse({ items, total, error: null }));
    }
  } catch (exc) {
    yield put(searchSubUserResponse({
      error: exc.error,
      items: [],
      total: 0
    }));
    ExceptionManager(
      exc,
      "modules/account/views/subUser/redux/sagas",
      "searchSubUser"
    );
  }
}

function* getSubUser({ payload }) {
  const { id } = payload;
  const query = `
    query getSubUser ($id: Int) {
      getSubUser (id: $id) {
        id
        userName
        firstName
        lastName
        roleId
        customRoleId
        role {
            id
            name
            description
            modules {
                id
                code
                userCode
                name
                category
                subModules {
                    id
                    userCode
                    name
                    groupName
                    parentModuleId
                    category
                }
            }
            isCustom
            customOwner
            isSupervisor
        }
        customRole {
            id
            name
            description
            isSupervisor
            modules {
                id
                code
                userCode
                name
                groupName
                parentModuleId
                category
                subModules {
                    id
                    userCode
                    name
                    groupName
                    parentModuleId
                    category
                }
            }
            isCustom
            customOwner
        }
        tagIds
        email
        activeTab
        expiration
        phone
        eldUser {
          username
          password
        }
      }
    }`;

  const variables = { id };

  try {
    const data = yield call(
      clientQuery,
      query,
      variables,
      endpoints.GRAPHQL_GENERAL
    );
    if (data) {
      const { getSubUser } = data;
      yield put(getSubUserResponse({ getSubUser }));
    }
  } catch (exc) {
    yield put(getSubUserResponse({ error: exc.error }));
    ExceptionManager(
      exc,
      "modules/account/views/subUser/redux/sagas",
      "getSubUser"
    );
  }
}

function* createSubUser({ payload }) {
  const {
    firstName,
    lastName,
    userName,
    password,
    expiryDate,
    roleId,
    tagIds,
    roleModules,
    customRole,
    phone,
    email,
    eldUser,
    isSupervisor = 0
  } = payload;

  const mutation = `
      mutation createSubUser ($firstName: String!, $lastName: String!, $roleId: Int!, $customRole: Boolean, $languageId: Int, $userName: String!, $password: String!, $email: String, $tagIds: [Int]!, $activeTab: String, $expiryDate: String, $roleModules: [Int], $phone: String, $eldUser: EldUserInput, $isSupervisor: Boolean) {
        createSubUser (firstName: $firstName, lastName: $lastName, roleId: $roleId, customRole: $customRole, languageId: $languageId, userName: $userName, password: $password, email: $email, tagIds: $tagIds, activeTab: $activeTab, expiryDate: $expiryDate, roleModules: $roleModules, phone: $phone, eldUser: $eldUser, isSupervisor: $isSupervisor) {
          id
          userName
          firstName
          lastName
          roleId
          customRoleId
          role {
              id
              name
              description
              modules {
                  id
                  code
                  userCode
                  name
                  category
                  subModules {
                      id
                      userCode
                      name
                      groupName
                      parentModuleId
                      category
                  }
              }
              isCustom
              customOwner
              isSupervisor
          }
          customRole {
              id
              name
              description
              modules {
                  id
                  code
                  userCode
                  name
                  groupName
                  parentModuleId
                  category
                  subModules {
                      id
                      userCode
                      name
                      groupName
                      parentModuleId
                      category
                  }
              }
              isCustom
              customOwner
              isSupervisor
          }
          tagIds
          email
          activeTab
          expiration
          phone
          eldUser {
            username
            password
          }
        }
    }`;

  const variables = {
    firstName,
    lastName,
    userName,
    password,
    expiryDate,
    roleId,
    tagIds,
    roleModules,
    customRole,
    phone,
    email,
    eldUser,
    isSupervisor
  };

  try {
    const data = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL);
    if (data) {
      yield put(createSubUserResponse({
        newSubUser: data.createSubUser ? true : false,
        error: null
      }));
    } else {
      throw 'SubUser has not been created';
    }
  } catch (exc) {
    const customExceptionMessage = "CustomException: ";
    const message = exc?.error?.replace(customExceptionMessage, "") || "";
    yield put(
      createSubUserResponse({
        error: message,
        newSubUser: false
      })
    );
    
    exc.message = message;
    exc.error = message;
    
    exc.errorData.errors.map(error => {
      error.message = error?.message?.replace(customExceptionMessage, "") || "";
      return;
    })
    ExceptionManager(
      exc,
      "modules/account/views/SUB_USER/redux/sagas",
      "createSubUser",
      true,
    );
  }
}

function* updateSubUser({ payload }) {
  const {
    id,
    firstName,
    lastName,
    expiryDate,
    roleId,
    tagIds,
    customRole,
    roleModules,
    phone,
    eldUser,
    isSupervisor = 0
  } = payload;

  const mutation = `
    mutation updateSubUser ($id: Int!, $firstName: String, $lastName: String, $roleId: Int, $customRole: Boolean, $languageId: Int, $tagIds: [Int], $activeTab: String, $expiryDate: String, $roleModules: [Int], $phone: String, $eldUser: EldUserInput, $isSupervisor: Boolean) {
      updateSubUser (id: $id, firstName: $firstName, lastName: $lastName, roleId: $roleId, customRole: $customRole, languageId: $languageId, tagIds: $tagIds, activeTab: $activeTab, expiryDate: $expiryDate, roleModules: $roleModules, phone: $phone, eldUser: $eldUser, isSupervisor: $isSupervisor) {
        id
        userName
        firstName
        lastName
        roleId
        customRoleId
        role {
            id
            name
            description
            modules {
                id
                code
                userCode
                name
                category
                subModules {
                    id
                    userCode
                    name
                    groupName
                    parentModuleId
                    category
                }
            }
            isCustom
            customOwner
            isSupervisor
        }
        customRole {
            id
            name
            description
            
            modules {
                id
                code
                userCode
                name
                groupName
                parentModuleId
                category
                subModules {
                    id
                    userCode
                    name
                    groupName
                    parentModuleId
                    category
                }
            }
            isCustom
            customOwner
            isSupervisor
        }
        tagIds
        activeTab
        expiration
        phone
        eldUser {
          username
          password
        }
      }
    }`;

  const variables = {
    id,
    firstName,
    lastName,
    expiryDate,
    roleId,
    tagIds,
    customRole,
    roleModules,
    phone,
    eldUser,
    isSupervisor
  };

  try {
    const data = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL);
    if (data) {
      yield put(updateSubUserResponse({
        updateSubUser: data.updateSubUser ? true : false,
        error: null
      }));
    } else {
      throw 'SubUser has not been updated';
    }
  } catch (exc) {
    yield put(
      updateSubUserResponse({
        error: exc.error,
        updateSubUser: false
      })
    );
    ExceptionManager(
      exc,
      "modules/account/views/SubUser/redux/sagas",
      "updateSubUser",
      true,
    );
  }
}

function* deleteSubUser({ payload }) {
  try {
    const { items } = payload;
    let mutations = [];

    items.forEach((item, index) => {
      mutations.push(`SubUser${index}: deleteSubUser(id: ${item?.id})`)
    });

    let mutation = `mutation{${mutations.join("")}}`;

    const response = yield call(
      clientMutation,
      mutation,
      {},
      endpoints.GRAPHQL_GENERAL,
    );

    if (response && response.SubUser0) {
      yield put(deleteSubUserResponse({ deleteSubUser: response, error: null }));
    } else {
      throw 'Error while deleting user';
    }
  } catch (exc) {
    yield put(
      deleteSubUserResponse({
        error: exc.error,
        deleteSubUser: false
      })
    );
    ExceptionManager(
      exc,
      "modules/account/views/SubUser/redux/sagas",
      "deleteSubUser",
      true,
    );
  }
}

function* resetCognitoSubUser({ payload }) {
  const { id, email } = payload;

  const mutation =
    `mutation resetCognitoSubUser ($userId: Int!, $email: String!, $newUsername: String) {
      resetCognitoSubUser (userId: $userId, email: $email, newUsername: $newUsername)
    }`;

  const variables = {
    userId: id,
    newUsername: "",
    email
  };

  try {
    const data = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL);
    if (data) {
      yield put(resetCognitoSubUserResponse({ cognitoReset: data.resetCognitoSubUser }));
    } else {
      throw 'SubUser has not been updated';
    }
  } catch (exc) {
    yield put(
      resetCognitoSubUserResponse({
        error: exc.error,
        cognitoReset: false
      })
    );
    ExceptionManager(
      exc,
      "modules/account/views/SubUser/redux/sagas",
      "resetCognitoSubUser",
      true
    );
  }
}

function* deleteSubUsers({ payload }) {
  const { ids } = payload;

  const mutation =
    `mutation deleteSubUsers ($ids: [Int]!) {
      deleteSubUsers (ids: $ids)
    }`;

  const variables = {
    ids
  };

  try {
    const data = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL);
    if (data) {
      yield put(deleteSubUserResponse({ deleteSubUser: data.deleteSubUsers, error: null }));
    } else {
      throw 'Error while deleting user';
    }
  } catch (exc) {
    yield put(
      deleteSubUserResponse({
        error: exc.error,
        deleteSubUser: false
      })
    );
    ExceptionManager(
      exc,
      "modules/account/views/SubUser/redux/sagas",
      "deleteSubUsers"
    );
  }
}

function* resetPasswordSubUser({ payload }) {
  const { userId, password } = payload;

  const mutation =
    `mutation resetPasswordSubUser ($userId: Int!, $password: String!) {
      resetPasswordSubUser (userId: $userId, password: $password)
    }`;

  const variables = {
    userId,
    password
  };

  try {
    const data = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL);
    if (data && data?.resetPasswordSubUser) {
      yield put(resetPasswordCognitoResponse({
        result: data.resetPasswordSubUser,
        error: null
      }));
    } else {
      throw 'SubUser has not been updated password';
    }
  } catch (exc) {
    yield put(
      resetPasswordCognitoResponse({
        result: false,
        error: exc.error
      })
    );
    ExceptionManager(
      exc,
      "modules/account/views/SubUser/redux/sagas",
      "resetPasswordSubUser",
      true
    );
  }
}

function* watchMethods() {
  yield takeEvery(SUB_USER_SEARCH, searchSubUser);
  yield takeEvery(SUB_USER_GET, getSubUser);
  yield takeEvery(SUB_USER_CREATE, createSubUser);
  yield takeEvery(SUB_USER_UPDATE, updateSubUser);
  yield takeEvery(SUB_USER_DELETE, deleteSubUser);
  yield takeEvery(SUB_USER_RESET_COGNITO, resetCognitoSubUser);
  yield takeEvery(SUB_USER_DELETE_BULK, deleteSubUsers);
  yield takeEvery(SUB_USER_RESET_PASSWORD, resetPasswordSubUser);
}
export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}