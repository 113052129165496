export const FSM_SETTINGS_TIME_CARD_SEARCH = "FSM_SETTINGS_TIME_CARD_SEARCH";
export const FSM_SETTINGS_TIME_CARD_SEARCH_RESPONSE = "FSM_SETTINGS_TIME_CARD_SEARCH_RESPONSE";
export const FSM_SETTINGS_TIME_CARD_CREATE = "FSM_SETTINGS_TIME_CARD_CREATE";
export const FSM_SETTINGS_TIME_CARD_CREATE_RESPONSE = "FSM_SETTINGS_TIME_CARD_CREATE_RESPONSE";
export const FSM_SETTINGS_TIME_CARD_UPDATE = "FSM_SETTINGS_TIME_CARD_UPDATE";
export const FSM_SETTINGS_TIME_CARD_UPDATE_RESPONSE = "FSM_SETTINGS_TIME_CARD_UPDATE_RESPONSE";
export const FSM_SETTINGS_TIME_CARD_DELETE = "FSM_SETTINGS_TIME_CARD_DELETE";
export const FSM_SETTINGS_TIME_CARD_DELETE_RESPONSE = "FSM_SETTINGS_TIME_CARD_DELETE_RESPONSE";
export const FSM_SETTINGS_TIME_CARD_OPEN_CREATE_EDIT= "FSM_SETTINGS_TIME_CARD_OPEN_CREATE_EDIT";
export const FSM_SETTINGS_TIME_CARD_OPEN_DELETE= "FSM_SETTINGS_TIME_CARD_OPEN_DELETE";
export const FSM_SETTINGS_TIME_CARD_DRIVER = "FSM_SETTINGS_TIME_CARD_DRIVER";
export const FSM_SETTINGS_TIME_CARD_DRIVER_RESPONSE = "FSM_SETTINGS_TIME_CARD_DRIVER_RESPONSE";
