// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import { 
  LEGACY_API_KEY_SEARCH, 
  LEGACY_API_KEY_GET,
  LEGACY_API_KEY_CREATE, 
  LEGACY_API_KEY_UPDATE, 
  LEGACY_API_KEY_DELETE,
  LEGACY_API_KEY_GET_COUNT
} from "Redux/actionTypes";

// Actions
import { 
  searchLegacyApiKeyResponse,
  getLegacyApiKeyResponse,
  createLegacyApiKeyResponse,
  updateLegacyApiKeyResponse,
  deleteLegacyApiKeyResponse,
  countLegacyApiKeysResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* searchLegacyApiKey({ payload }) {
  const { openSearch, userId, limit, offset } = payload;
  const query = `
    query searchLegacyApiKeys ($openSearch: String, $userId: Int, $limit: Int, $offset: Int) {
      searchLegacyApiKeys (openSearch: $openSearch, userId: $userId, limit: $limit, offset: $offset) {
          items {
              id
              userId
              username
              key
              ipAddresses
              createdOn
              createdBy
              updatedOn
              updatedBy
          }
          total
      }
    }`;

  const variables = {
    openSearch, 
    userId,
    limit, 
    offset
  };

  try {
    const data = yield call(
      clientQuery,
      query,
      variables,
      endpoints.GRAPHQL_GENERAL
    );
    if (data) {
      const { searchLegacyApiKeys: { items, total } } = data;
      yield put(searchLegacyApiKeyResponse({ items, total, error: false }));
    }
  } catch (exc) {
    yield put(searchLegacyApiKeyResponse({ items: [], total: 0, error: true }));
    ExceptionManager(
      exc,
      "modules/account/views/legacyApiKey/redux/sagas",
      "searchLegacyApiKey"
    );
  }
}

function* countLegacyApiKeys() {
  const query = `
    query searchLegacyApiKeys ($openSearch: String, $userId: Int, $limit: Int, $offset: Int) {
      count: searchLegacyApiKeys (openSearch: $openSearch, userId: $userId, limit: $limit, offset: $offset) {
        total
      }
    }`;

  const variables = {
    openSearch: "",
    userId: 0,
    limit: 20,
		offset: 0
  };

  try {
    const data = yield call(
      clientQuery,
      query,
      variables,
      endpoints.GRAPHQL_GENERAL
    );
    if (data) {
      const { count: { total } } = data;
      yield put(countLegacyApiKeysResponse({ total, error: false }));
    }
  } catch (exc) {
    yield put(countLegacyApiKeysResponse({ total: 0, error: true }));
    ExceptionManager(
      exc,
      "modules/account/views/legacyApiKey/redux/sagas",
      "countLegacyApiKeys"
    );
  }
}

function* getLegacyApiKey({ payload }) {
  const { id } = payload;
  const query = `
    query getLegacyApiKey ($id: Int!) {
      getLegacyApiKey (id: $id) {
          id
          userId
          username
          key
          ipAddresses
          createdOn
          createdBy
          updatedOn
          updatedBy
      }
    }`;

  const variables = { id };

  try {
    const data = yield call(
      clientQuery,
      query,
      variables,
      endpoints.GRAPHQL_GENERAL
    );
    if (data) {
      const { getLegacyApiKey } = data;
      yield put(getLegacyApiKeyResponse({ getLegacyApiKey, error: false }));
    }
  } catch (exc) {
    yield put(getLegacyApiKeyResponse({ getLegacyApiKey: null, error: true }));
    ExceptionManager(
      exc,
      "modules/account/views/legacyApiKey/redux/sagas",
      "getLegacyApiKey"
    );
  }
}

function* createLegacyApiKey({ payload }) {
  const { userId, ipAddresses } = payload;
  const mutation = `
    mutation createLegacyApiKey ($userId: Int, $ipAddresses: [String]) {
      createLegacyApiKey (userId: $userId, ipAddresses: $ipAddresses) {
          id
          userId
          username
          key
          ipAddresses
          createdOn
          createdBy
          updatedOn
          updatedBy
      }
    }`;
  const variables = { userId, ipAddresses };
  try {
    const data = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL);
    if (data) {
      yield put(createLegacyApiKeyResponse({ 
        newLegacyApiKey: data.createLegacyApiKey ? true : false, 
        error: false 
      }));
    } else {
      throw 'Legacy API Key has not been created';
    }
  } catch (exc) {
    yield put(
      createLegacyApiKeyResponse({
        error: true,
        newLegacyApiKey: false
      })
    );
    ExceptionManager(
      exc,
      "modules/account/views/legacyApiKey/redux/sagas",
      "createLegacyApiKey"
    );
  }
}

function* updateLegacyApiKey({ payload }) {
  const { id, userId, ipAddresses } = payload;
  const mutation = `
    mutation updateLegacyApiKey ($id: Int!, $userId: Int, $ipAddresses: [String]) {
      updateLegacyApiKey (id: $id, userId: $userId, ipAddresses: $ipAddresses) {
          id
          userId
          username
          key
          ipAddresses
          createdOn
          createdBy
          updatedOn
          updatedBy
      }
  }`;
  const variables = { id, userId, ipAddresses };
  try {
    const data = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL);
    if (data) {
      yield put(updateLegacyApiKeyResponse({ 
        updateLegacyApiKey: data.updateLegacyApiKey? true : false, 
        error: false }));
    } else {
      throw 'Legacy Api Key has not been updated';
    }
  } catch (exc) {
    yield put(
      updateLegacyApiKeyResponse({
        error: true,
        updateLegacyApiKey: false
      })
    );
    ExceptionManager(
      exc,
      "modules/account/views/legacyApiKey/redux/sagas",
      "updateLegacyApiKey"
    );
  }
}

function* deleteLegacyApiKey({ payload }) {
  const { id, message } = payload;
  const mutation = `
    mutation deleteLegacyApiKey ($id: Int!) {
      deleteLegacyApiKey (id: $id)
    }`;

  const variables = { id };

  try {
    const data = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL);
    if (data) {
      yield put(deleteLegacyApiKeyResponse({ deleteLegacyApiKey: data.deleteLegacyApiKey, error: false }));
    } else {
      throw 'Legacy Api Key has not been deleted';
    }
  } catch (exc) {
    yield put(
      deleteLegacyApiKeyResponse({
        error: true,
        deleteLegacyApiKey: false
      })
    );
    ExceptionManager(
      exc,
      "modules/account/views/legacyApiKey/redux/sagas",
      "deleteLegacyApiKey"
    );
  }
}

function* watchMethods() {
  yield takeEvery(LEGACY_API_KEY_SEARCH, searchLegacyApiKey);
  yield takeEvery(LEGACY_API_KEY_GET, getLegacyApiKey);
  yield takeEvery(LEGACY_API_KEY_CREATE, createLegacyApiKey);
  yield takeEvery(LEGACY_API_KEY_UPDATE, updateLegacyApiKey);
  yield takeEvery(LEGACY_API_KEY_DELETE, deleteLegacyApiKey);
  yield takeEvery(LEGACY_API_KEY_GET_COUNT, countLegacyApiKeys);
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}