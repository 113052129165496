import {
    FSM_SETTINGS_MEAL_BREAK_GET,
    FSM_SETTINGS_MEAL_BREAK_GET_RESPONSE,
    FSM_SETTINGS_MEAL_BREAK_CREATE,
    FSM_SETTINGS_MEAL_BREAK_CREATE_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    mealBreak: {},
    response: {},
    loadingMealBreak: false,
    loadingCreateEdit: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FSM_SETTINGS_MEAL_BREAK_GET:
            return {
                ...state,
                loadingMealBreak: true,
                mealBreak:{}
            };

        case FSM_SETTINGS_MEAL_BREAK_GET_RESPONSE:
            return {
                ...state,
                loadingMealBreak: false,
                mealBreak: action.payload
            };

            case FSM_SETTINGS_MEAL_BREAK_CREATE:
                return {
                    ...state,
                    loadingCreateEdit: true,
                    response: {}
                };
    
            case FSM_SETTINGS_MEAL_BREAK_CREATE_RESPONSE:
                return {
                    ...state,
                    loadingCreateEdit: false,
                    response: action.payload
                };

    
        case CLEAR_REDUX:
            return ["", "FSM_SETTINGS_MEAL_BREAK"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return state;
    }
};