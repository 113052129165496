import {
  REPORTS_HANDLE_MODAL_EXTRA_TIME
} from "Redux/actionTypes";

export const reportsOpenModalExtraTime = (open, option) => ({
  type: REPORTS_HANDLE_MODAL_EXTRA_TIME,
  payload: {
    open,
    option
  },
});
