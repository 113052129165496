// Dependencies
import React from "react";
import { injectIntl } from "react-intl";
import { Typography } from "@mui/material";

// Components
import { Chip } from "Components";

const SelectedItems = (props) => {
  const {
    selectedItems,
    label,
    itemLabel,
    fieldValue,
    onChange,
  } = props;

  return (
    <>
      <div className="mt-10">
        <Typography variant="subtitle2">
          {label}
        </Typography>
      </div>
      <br />
      {
        selectedItems.map((item) => (
          item[itemLabel] && (
            <Chip
              key={item[fieldValue]}
              label={item[itemLabel]}
              handleDelete={() => {
                const filteredItems = [...selectedItems];
                filteredItems.splice(selectedItems.indexOf(item), 1);
                onChange(filteredItems);
              }}
            />
          )
        ))
      }
    </>
  );
}

export default injectIntl(SelectedItems);
