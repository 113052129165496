import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';

// controls
//helpers
import {
    formatDateFromFilter,
    formatDatetime,
    getGroupId, getNameUnitDriverForSubtables,
    getNameUnitDriverTag,
    useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';

//hooks
import {useReportsSelector, useSidePanelMap} from 'Core/hooks';
//actions
import {reportsMainGenerateReportUpdate} from 'Redux/actions'

// styles
import './stateMileageCrossingReport.scss';
import {Grid} from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InfiniteScrollTable from "Modules/reports/views/main/components/reportsTab/.components/infiniteScrollTable";
import {GetFormatForMoment} from "Core/data/Helpers";
import EventReports from "Modules/reports/views/main/components/reportsTab/.components/eventReport";
import AddressLandmark from "Modules/reports/views/main/components/reportsTab/.components/addressLandmark";

import { isKPHAcronym, isKPHAcronymSpeed } from '../../../helper';

const ROW_PER_PAGE = 10;

const StateMileageCrossingReport = (props) => {
    const {messages, report, user, copyReportToSetup, openReportSetup, scheduleReport, isActive} = props;
    const dispatch = useDispatch()
    const {loading, asyncLoading, data, filters, timeZone} = report
    const {filterEntity, filterArray, filterType} = filters;
    const exportHandler = useHandleExportReport(report)
    const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
    const [lastEntity, setLastEntity] = useState({});
    const momentFormat = GetFormatForMoment(user.dateformat)

    const startDayFilter = formatDateFromFilter(report.filters.startRange, timeZone)
    const endDayFilter = formatDateFromFilter(report.filters.endRange, timeZone)

    const reportRef = useRef();
    const entitiesArrayRef = useRef();
    const currentIndexScroll = useRef({});
    const detailReportTableRef = useRef();
    const lastEntityRef = useRef({});
    const reportTableSelect = useReportsSelector(report, startDayFilter, endDayFilter, `MMMM DD, YYYY hh:mm A`, filterArray, filterType, timeZone)
    const unitDriverSelected = reportTableSelect.valueSelecteEntity?.length > 0 ? reportTableSelect.valueSelecteEntity : null;
    const dateSelected = reportTableSelect.dateSelected?.length > 0 ? reportTableSelect.dateSelected : null;

    useEffect(() => {
        if (report) {
            const newReport = JSON.parse(JSON.stringify(report));
            // to keep the reference to the units selected
            reportRef.current = newReport;
        }
    }, [JSON.stringify(report)]);

    useEffect(() => {
        if (isActive) {
            reportTableSelect.onChangeResetCache();
        }
    }, [isActive])

    const buildTableHeader = () => {
        return (
            <Grid container item direction={"row"} className={"table-scroll-reports-title-details"}>
            <Grid item sm={2}>
              {filters.filterType !== 'deviceId' ? messages['generatedReport_unit'] : messages['generatedReport_driver'] }
            </Grid>
            <Grid item sm={1}>
              {messages['generatedReport_state']}
            </Grid>
            <Grid item sm={1}>
              {messages['generatedReport_event']}
            </Grid>
            <Grid item sm={4}>
              {messages['generatedReport_address']}
            </Grid>
            <Grid item sm={2}>
              {messages['infoBubble_Time']}
            </Grid>
            <Grid item sm={1}>
              {messages['infoBubble_distance']}
            </Grid>
            <Grid item sm={1}>
              {messages['permissions_reportsOdometer']}
            </Grid>
          </Grid>
        )
      }

      const TableViewGroup = ({index, style, content, id, previousItem}) => {
        const element = content?.recordInfo;
        const recordInfo = content?.recordInfo;


        return (
          <div
            className="listItem groupItem"
            id={id}
            key={id}
            style={style}
          >

            <Grid container  direction={"column"} alignItems={"flex-start"} className='table-scroll-reports-group'>
                <Grid container item className='table-scroll-reports-header'>
                    <Grid item sm={3}>

                        {
                            element?.tagId > 0 ?
                                (<LocalOfferIcon
                                    style={{fontSize: "16.8px", color: `#${element.groupTagColor && element.groupTagColor != "ffffff" ? element.groupTagColor : "000"}`, position: 'absolute'}}/>)
                                : ""
                        }
                        <span className={`color-text-black ${element?.tagId > 0 ? 'report-home-header-padding':''}`}>{`${getNameUnitDriverTag(recordInfo?.groupLabel, messages)}`}</span>
                        {
                            report?.filters?.filterArray.length !== 1 && (<span  className={'color-text-black report-home-header-padding-text'}>{` (${element?.tagId > 0 ? `${getNameUnitDriverTag(filterEntity, messages)} ` : ''}${recordInfo?.groupIndex} ${messages['OF']} ${recordInfo?.groupTotal})`}</span>)
                        }
                    </Grid>
                    <Grid item sm={9}>
                        <span className={'color-text-black'}>{formatDatetime(report.filters.startRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} - {formatDatetime(report.filters.endRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()}</span>
                    </Grid>
                </Grid>
              <div className='table-scroll-reports-separator'></div>
              <Grid item>
                <p className='table-scroll-reports-label'>{element?.entityLabel}</p>
              </Grid>
            </Grid>
          </div>
        )
      }

    const TableViewDetail = ({ index, style, content, id, previousItem }) => {
        const element = content?.recordDetail;
        const previousItemData = previousItem?.recordInfo;

        return (
            <div
                className="listItem detailItem"
                style={style}
                id={id}
                key={id}
            >
                <Grid container direction={"column"} style={{ marginBottom: "12px" }} className={element.event === "Exit" ? "underLine" : ""}>
                  {previousItemData?.recordType != "DETAIL" ? buildTableHeader() : <></>}
                <Grid container item direction={"row"} style={{ paddingTop: "1px", paddingBottom: "1px" }}>
                    {
                    element.noData ?
                        <Grid item className={"report-ctn-text"} sm={12}>
                        {messages["reportsMainRT_noDataEvent"]}
                        </Grid>
                        :
                        <>

                        <Grid item className={"report-ctn-text"} sm={2}>
                            <span>{filters.filterType !== 'deviceId' ? element.deviceLabel : element.driverName }</span>
                        </Grid>

                        <Grid item className={"report-ctn-text"} sm={1}>
                            <span>{element?.state}</span>
                        </Grid>

                        <Grid item className={"report-ctn-text"} sm={1}>
                            <span>{element.event}</span>
                        </Grid>

                        <Grid item sm={4}>
                            <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={{
                                address: element?.address,
                                landmarkName: element?.landmarkName,
                                landmarkId: element?.landmarkId,
                            }} iconStyle={{ fontSize: 18 }} classNameButtonAddress={'report-address-button'} />
                        </Grid>

                        <Grid item className={"report-ctn-text"} sm={2}>
                          {element.unitTime && formatDatetime(element.unitTime, `${momentFormat} h:mm A`)}
                        </Grid>

                        <Grid item className={"report-ctn-text"} sm={1}>
                            <span>{`${element.distance} ${ element.distance.length ? messages[isKPHAcronym(element.isKPH)] : ``}`}</span>
                        </Grid>

                        <Grid item className={"report-ctn-text"} sm={1}>
                            <span>{element?.odometer}</span>
                        </Grid>

                        </>
                    }
                </Grid>
                </Grid>
            </div>
        )
      }

      const renderItems = (parameters) => {
        const {content} = parameters;
        const type = content.recordInfo?.recordType;
        if(type === "GROUP"){
          const groupId = getGroupId(content)
          const entitiesArray = entitiesArrayRef.current || {};
          if(!entitiesArray[groupId]){
              const newEntityArray = {...entitiesArray};
              newEntityArray[groupId] = content;
              entitiesArrayRef.current = newEntityArray
          }
          // skip the first group beacuse the first group is replaced with the sticky header
          if(parameters.index === 0){
            return;
          }

          return (
            <TableViewGroup {...parameters}></TableViewGroup>
          )
        } else if(type === "DETAIL"){
          return <TableViewDetail {...parameters}></TableViewDetail>;
        }

        if(parameters.index === 0){
          return;
        }

        return (
          <div>
            unknown type
          </div>
        )
      }

    const onStartIndex = (index) => {
        const item = data?.items[index];

        if (item) {
            if (item.recordInfo?.entityId !== lastEntityRef.current.recordInfo?.entityId) {
                setLastEntity(item)
            }
            lastEntityRef.current = item
        }
    }

    const scrollEvent = (index) => {
      currentIndexScroll.current = index;
    }

    const renderHeader = () => {
        const groupId = getGroupId(lastEntity);
        const entitiesArray = entitiesArrayRef.current || {};
        const itemHeader = entitiesArray[groupId];

        if(itemHeader){
          return (
            <TableViewGroup
              content={itemHeader}
              id={itemHeader?.recordInfo?.entityId}
              index={currentIndexScroll.current}
            ></TableViewGroup>
          );
        }
        return;

      };

    const findTag = filterArray?.findIndex((item) => item.name === "Tag")
    const hasTag = findTag >= 0;
    const type = hasTag ? messages['generatedReport_tag'] :
        (report?.filters?.filterType === "deviceId" ? messages['generatedReport_unit'] : messages['generatedReport_driver'])
    return (
        <>
            <InfiniteScrollTable
                refContainer={detailReportTableRef}
                renderItems={renderItems}
                loading={asyncLoading || loading}
                data={data.items || []}
                total={data.total}
                reportTitle={messages['reportsMainRT_stateMileageCrossingReport']}
                editReport={() => {
                    copyReportToSetup({...report, data: []})
                }}
                onSchedule={() => {
                    scheduleReport(report)
                }}
                onCreate={openReportSetup}
                tableContainerClass={"detailed-report-reports-container"}
                type={type}
                handleNewPageLoad={(page) => {
                    let currentReport = {...report};
                    currentReport.exportType = "json";
                    currentReport.filters = {
                        ...currentReport.filters,
                        unitsSelected: unitDriverSelected?.id !== -99 ? unitDriverSelected : null,
                        accumulateData: true,
                        dateSelected: dateSelected?.id !== -99 ? unitDriverSelected : null,
                    };
                    dispatch(reportsMainGenerateReportUpdate(currentReport, page, ROW_PER_PAGE))
                }}
                renderHeader={renderHeader}
                onStartIndex={onStartIndex}
                isActive={isActive}
                scrollEvent={scrollEvent}
                {...reportTableSelect}
                {...exportHandler}
            />
            <SideMapComponent hiddenButton hiddenSearchMap overlayClass={"overlay-class-reports"}/>
        </>
    );
}

export default StateMileageCrossingReport;
