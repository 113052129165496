export default {
    "FSM_SETTINGS_ROUTE_ON_SITE": "En el sitio",
    "FSM_SETTINGS_ROUTE_ON_ROUTE": "En ruta",
    "FSM_SETTINGS_ROUTE_PENDING": "Pendiente",
    "FSM_SETTINGS_ROUTE_DONE": "Listo",
    "FSM_SETTINGS_ROUTES": "Rutas",
    "FSM_SETTINGS_ROUTE_IMPORT_TITLE": "Importar Paradas de ruta",
    "FSM_SETTINGS_ROUTE_IMPORT_ERROR": "Por favor seleccione una ruta",
    "FSM_SETTINGS_ROUTE_CREATE_DONE": "Parada de ruta FSM creada",
    "FSM_SETTINGS_ROUTE_TITLE": "Rutas",
    "FSM_SETTINGS_ROUTE_PREVIEW": "Vista Previa",
    "FSM_SETTINGS_ROUTE_DELETE_BODY": "¿Está seguro de que desea eliminar las siguientes paradas de rutas FSM?",
    "FSM_SETTINGS_ROUTE_DELETE_TITLE": "Selección de paradas de rutas FSM",
    "FSM_SETTINGS_ROUTE_DELETE": "Paradas de rutas FSM Eliminada",
    "FSM_SETTINGS_ROUTE_NEW": "Crear nueva parada de rutas FSM",
    "FSM_SETTINGS_ROUTE_CREATE_LOCATION_ERROR": "Por favor seleccione una ubicación" ,
    "FSM_SETTINGS_ROUTE_IMPORT_DONE": "Las parada de rutas FSM se importaron con éxito",

    "FSM_SETTINGS_ROUTE_LANDMARK": "Seleccionar Landmark",
    "FSM_SETTINGS_ROUTE_MORE_STOPS": "Añadir otra parada",
    "FSM_SETTINGS_ROUTE_LAT_LONG_WRONG": "La latitud o longitud puede tener algun error, intente nuevamente",
    "FSM_TITLE_STOP": 'Titulo',
    "FSM_SETTINGS_ROUTE_ERROR_CREATE": "Seleccione un punto en el mapa o escriba y seleccione la dirección para crear una nueva parada de ruta FSM"

};