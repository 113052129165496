export default {
    "updateService_title": "Adicionar Servicio",
    "updateService_EditTitle": "Editar Servicio",
    "updateService_titleInputLabel": "Nombre de Servicio",
    "updateService_titleInputPlaceholder": "Nombre de Servicio",
    "updateService_engineHoursInputLabel": "Horas de Motor",
    "updateService_engineHoursInputPlaceholder": "Horas de Motor",
    "updateService_engineOdometerInputLabel": "Odometro",
    "updateService_engineOdometerInputPlaceholder": "Odometro",
    "updateService_costInputLabel": "Costo Estimado",
    "updateService_costInputPlaceholder": "$0",
    "updateService_notesInputLabel": "Notas",
    "updateService_notesInputPlaceholder": "",
    "updateService_recurringInputLabel": "Guardar Como Servicio Recurrente",
    "updateService_intervalTypeInputLabel": "Tipo de Intervalo",
    "updateService_intervalTypeInputPlaceholder": "Seleccionar",
    "updateService_intervalInputLabel": "Intervalo",
    "updateService_intervalInputPlaceholder": "Intervalo",
    "updateService_nextServiceInputLabel": "Próximo Servicio",
    "updateService_nextServiceInputPlaceholder": "Seleccionar Fecha",
    "updateService_reminderInputLabel": "Recordatorio",
    "updateService_reminderInputPlaceholder": "Recordatorio",
    "updateService_periodExport": "Periodo es exportacion:",
    "updateService_pastDueInputLabel": "Vencido",
    "updateService_pastDueInputPlaceholder": "Vencido",
    "updateService_addNewIntervalType": "Adicionar Intervalo",
    "updateService_alertInputLabel": "ALERTA",
    "updateService_alertInputPlaceholder": "Seleccionar Alerta",
    "updateService_alertTypeInputLabel": "",
    "updateService_alertTypeInputPlaceholder": "Tipo de Alerta",
    "updateService_addNewAlertType": "Adicionar un Tipo de Alerta",
    "updateService_attachmentsInputLabel": "Adjuntos",
    "updateService_attachmentsInputPlaceholder": "Seleccionar Archivo(s)",
    "updateService_success": "Servicio Guardado",
    "updateService_intervalTypeError": "Debes Seleccionar un Tipo de Intervalo",
    "updateService_alertError": "Debes Seleccionar una Alerta",
    "updateService_errorLabel": "Error",
    "updateService_subscribers": "Suscriptores",
}