//imports
import {
	GET_USER_INFO,
	GET_USER_INFO_RESULT,
	LOGOUT,
	REFRESH_TOKEN,
	REFRESH_TOKEN_RESULT,
	GET_SYSTEM_PREF_RESULT,
	GET_ACCESS_ELD_APOLLO,
	GET_ACCESS_ELD_APOLLO_RESULT,
	CHANGE_SALESFORCE_STATUS,
	CHANGE_SALESFORCE_STATUS_RESULT,
	CLEAR_REDUX
} from 'Redux/actionTypes';

//initial values
const INIT_STATE = {
	loading: false,
	user: null,
	error: null,
	refreshTokenResult: false,
	authenticatedId: null,
	systemPreference: null,
	accessEldApollo: null,
	loadingAccessEldApollo: false,
	salesForceStatus: false,
	authId: null
};

//change redux object
export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case GET_USER_INFO:
			return { ...state, loading: true };

		case GET_USER_INFO_RESULT:

			const companyId = action.payload?.companyId

			const userId = action.payload?.subUserId

			const user = action.payload;
			const authenticatedId = companyId + (userId ? "_" + userId : "")

			let authId = "PX_" + user.companyId;
			if (user.isSubUser)
				authId += "_" + user.subUserId

			return { ...state, loading: false, user: user, authenticatedId, authId };

		case GET_SYSTEM_PREF_RESULT:
			return { ...state, systemPreference: action.payload }

		case REFRESH_TOKEN:
			return { ...state, refreshTokenResult: false };

		case REFRESH_TOKEN_RESULT:
			return { ...state, refreshTokenResult: action.payload };

		case LOGOUT:
			return { ...state, loading: false, user: null, error: null };

		case GET_ACCESS_ELD_APOLLO:
			return { ...state, loadingAccessEldApollo: true };

		case GET_ACCESS_ELD_APOLLO_RESULT:
			return { ...state, loadingAccessEldApollo: false, accessEldApollo: action.payload };

		case CHANGE_SALESFORCE_STATUS:
			return { ...state };

		case CHANGE_SALESFORCE_STATUS_RESULT:
			return { ...state, salesForceStatus: action.payload };

		case CLEAR_REDUX:
			return ["", "AUTHCOMPONENT"].includes(action.payload.option)
				? action.payload.parameters
					? { ...state, ...action.payload.parameters }
					: { ...INIT_STATE }
				: { ...state }

		default:
			return state;
	}
};
