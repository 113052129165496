// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  SETTINGS_KEY_FOB_SEARCH,
  SETTINGS_KEY_FOB_DELETE,
  SETTINGS_KEY_FOB_GET_DRIVER,
  SETTINGS_KEY_FOB_CREATE_EDIT
} from "Redux/actionTypes";

// Actions
import {
  searchSettingsKeyFobResponse,
  createEditSettingsKeyFobsResponse,
  deleteSettingsKeyFobsResponse,
  getSettingsKeyFobDriversResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* createEditKeyFobRequest({ payload }) {
  try {
    const { keyFob } = payload;

    let query = `
        mutation createKeyFob ($hex: String!, $driverId: Int!) {
          createKeyFob (hex: $hex, driverId: $driverId) {
            id
            hex
            driverId
            driverName
          }
        }
      `;

    if (keyFob?.id) {
      query = `
          mutation updateKeyFob ($id: Int!, $hex: String, $driverId: Int) {
            updateKeyFob (id: $id, hex: $hex, driverId: $driverId) {
              id
              hex
              driverId
              driverName
            }
          }
        `;
    }


    let data = {
      id: keyFob?.id,
      hex: keyFob?.hex,
      driverId: keyFob?.driver.driverId
    }

    const response = yield call(
      clientMutation,
      query,
      data,
      endpoints.GRAPHQL_GENERAL,
    );

    if (!response && !keyFob?.id || !response.createKeyFob && !keyFob?.id) throw 'Error while creating Key Fobs';


    if (!response && keyFob?.id || !response.updateKeyFob && keyFob?.id) throw 'Error while update Key Fobs';

    let responseData = (!keyFob?.id) ? response.createKeyFob : response.updateKeyFob;
    yield put(createEditSettingsKeyFobsResponse({ ...responseData, error: false }));
  } catch (exc) {
    yield put(createEditSettingsKeyFobsResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/keyFob/redux/saga",
      "createEditKeyFobRequest",
      true
    );

  }
}

function* searchKeyFobRequest({ payload }) {
  try {
    const {
      openSearch,
      limit,
      offset
    } = payload;
    const query = `
    query searchKeyFobs ($openSearch: String, $limit: Int, $offset: Int) {
      searchKeyFobs (openSearch: $openSearch, limit: $limit, offset: $offset) {
          items {
              id
              hex
              driverId
              driverName
              keyFobModelId
          }
          total
      }
    }
        `;

    const response = yield call(
      clientQuery,
      query,
      {
        openSearch,
        limit,
        offset
      },
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.searchKeyFobs) {
      throw "Error while getting Key Fobs list";
    }
    let keyFobs = response.searchKeyFobs.items;
    let total = response.searchKeyFobs.total;
    yield put(searchSettingsKeyFobResponse({ keyFobs, total, error: false }));
  } catch (exc) {
    yield put(searchSettingsKeyFobResponse({ keyFobs: [], total: 0, error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/keyFob/redux/saga",
      "searchKeyFobRequest"
    );
  }
}

function* getKeyFobDriversRequest() {
  try {

    const query = `
          query getDrivers {
            getDrivers {
                name
                driverId
            }
          }
              `;

    const response = yield call(
      clientQuery,
      query,
      {},
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.getDrivers) {
      throw "Error while getting Drivers list";
    }
    let drivers = response.getDrivers;
    yield put(getSettingsKeyFobDriversResponse({ drivers, error: false }));
  } catch (exc) {
    yield put(getSettingsKeyFobDriversResponse({ drivers: [], error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/keyFob/redux/saga",
      "getKeyFobDriversRequest"
    );
  }
}

function* deleteKeyFobsRequest({ payload }) {
  try {
    const { items } = payload;
    let mutations = [];

    items?.forEach((item, index) => {
      mutations.push(`
              DeleteKey${index}: deleteKeyFob(id: ${item?.id})
          `);
    });

    let mutation = `mutation{${mutations.join("")}}`;

    const response = yield call(
      clientMutation,
      mutation,
      {},
      endpoints.GRAPHQL_GENERAL,
    );

    if (!response || !response.DeleteKey0) throw 'Error while deleting Key Fob';

    yield put(deleteSettingsKeyFobsResponse({ ...response, error: false }));
  } catch (exc) {
    yield put(deleteSettingsKeyFobsResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/keyFob/redux/saga",
      "deleteKeyFobsRequest"
    );
  }
}



function* watchMethods() {
  yield takeEvery(SETTINGS_KEY_FOB_SEARCH, searchKeyFobRequest);
  yield takeEvery(SETTINGS_KEY_FOB_CREATE_EDIT, createEditKeyFobRequest);
  yield takeEvery(SETTINGS_KEY_FOB_DELETE, deleteKeyFobsRequest);
  yield takeEvery(SETTINGS_KEY_FOB_GET_DRIVER, getKeyFobDriversRequest);


}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}