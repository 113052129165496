import React from "react";

const Label = ({ children, title, ...props }) => {
  return (
    <label
      style={{
        color: "black",
        fontSize: title ? "18px" : "12px",
        fontWeight: title ? "500" : "400",
      }}
      {...props}
    >
      {children}
    </label>
  );
};

export default Label;
