import React from 'react';
import { Chip, Icon } from '@mui/material';
import { Tooltip } from 'Components';

const customChipStyles = {
	fontSize: '12px !important',
	fontWeight: '500 !important',
	backgroundColor: '#66bb6a',
	color: 'white',
	borderRadius: '25px !important',
	marginBottom: "5px",
	marginRight: "5px",
	width: '100%',
	paddingRight: "3px",
	paddingLeft: "3px",
	display:"flex",
	alignItems:"center",
	'&:focus': {
		backgroundColor: '#66bb6a'
	},
	'& .MuiChip-deleteIcon': {
		color: 'white',
		fontSize: "20px",
		margin: '0 5px 0 -5px',
		height: 'auto'
	},
	'& .MuiChip-label': {
		width: '100%',
	}
}

const CustomChip = (props) => {

	const { noDelete, disabled } = props

	return (
		 props.label?.length > 70 ?
		<Tooltip title={props.label} placement={props.placement || 'bottom'}>
			{!noDelete ?
			<Chip			
				label={props.label}
				onDelete={props.handleDelete || null}
				deleteIcon={<Icon style={{ fontSize: 14 }}>close</Icon>}
				disabled={disabled}
				style={{ backgroundColor: props.color }}
				sx={customChipStyles}
			/>:
			<Chip			
				label={props.label}
				disabled={disabled}
				style={{ backgroundColor: props.color }}
				sx={customChipStyles}
			/>}
		</Tooltip> :
		!noDelete ?
		<Chip			
			label={props.label}
			onDelete={props.handleDelete || null}
			deleteIcon={<Icon style={{ fontSize: 14 }}>close</Icon>}
			disabled={disabled}
			style={{ backgroundColor: props.color }}
			sx={customChipStyles}
		/>:
		<Chip			
			label={props.label}
			disabled={disabled}
			style={{ backgroundColor: props.color }}
			sx={customChipStyles}
		/>	
	);
};

export default CustomChip;
