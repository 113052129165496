import {
    MAP_SPEED_OVERRIDE_EDIT_CREATE_OPEN_MODAL,
    MAP_SPEED_OVERRIDE_GET_SPEED_OVERRIDE,
    MAP_SPEED_OVERRIDE_GET_SPEED_OVERRIDE_RESPONSE,
    MAP_SPEED_OVERRIDE_EDIT_CREATE_SPEED_OVERRIDE,
    MAP_SPEED_OVERRIDE_EDIT_CREATE_SPEED_OVERRIDE_RESPONSE
} from 'Redux/actionTypes';

export const mapSpeedOverrideOpenEditCreateModal = (open, id) => ({
    type: MAP_SPEED_OVERRIDE_EDIT_CREATE_OPEN_MODAL,
    payload: { open, id },
});

export const mapSpeedOverrideGetSpeedOverride = (id) => ({
    type: MAP_SPEED_OVERRIDE_GET_SPEED_OVERRIDE,
    payload: { id },
});

export const mapSpeedOverrideGetSpeedOverrideResponse = (response) => ({
    type: MAP_SPEED_OVERRIDE_GET_SPEED_OVERRIDE_RESPONSE,
    payload: { response },
});

export const mapSpeedOverrideSaveSpeedOverride = (speedOverride) => ({
    type: MAP_SPEED_OVERRIDE_EDIT_CREATE_SPEED_OVERRIDE,
    payload: { speedOverride },
});

export const mapSpeedOverrideSaveSpeedOverrideResponse = (response) => ({
    type: MAP_SPEED_OVERRIDE_EDIT_CREATE_SPEED_OVERRIDE_RESPONSE,
    payload: { response },
});