import {
	FLEET_OPEN_STARTER_SCHEDULE,
	FLEET_CLOSE_STARTER_SCHEDULE,
	FLEET_CREATE_STARTER_SCHEDULE,
	FLEET_CREATE_STARTER_SCHEDULE_RESULT,
	FLEET_SEARCH_STARTER_SCHEDULES,
	FLEET_SEARCH_STARTER_SCHEDULES_RESULT,
	FLEET_DELETE_STARTER_SCHEDULE,
	FLEET_DELETE_STARTER_SCHEDULE_RESULT,
	FLEET_UPDATE_STARTER_SCHEDULE,
	FLEET_UPDATE_STARTER_SCHEDULE_RESULT,
	CLEAR_REDUX
} from 'Redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
	isOpen: false,
	vehicleGroupsStatus: [],
	createScheduleGroupResult: null,
	loadingSearchStarterSchedule: false,
	starterScheduleList: [],
	deleteStarterSchedule: null,
	loadingDeleteStarterSchedule: false,
	updateStarterScheduleResult: null,
	loadingUpdateStarterSchedule: false,
	createStarterSchedule: {},
	loadingCreateStarterSchedule: false
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case FLEET_OPEN_STARTER_SCHEDULE:
			return { ...state, isOpen: true };

		case FLEET_CLOSE_STARTER_SCHEDULE:
			return { ...state, isOpen: false };

		case FLEET_CREATE_STARTER_SCHEDULE:
			return {
				...state,
				createStarterSchedule: {},
				loadingCreateStarterSchedule: true,
			};

		case FLEET_CREATE_STARTER_SCHEDULE_RESULT:
			return {
				...state,
				createStarterSchedule: action.payload.data,
				loadingCreateStarterSchedule: false,
			};

		case FLEET_SEARCH_STARTER_SCHEDULES:
			return {
				...state,
				createScheduleGroupResult: null,
				loadingSearchStarterSchedule: true,
			};

		case FLEET_SEARCH_STARTER_SCHEDULES_RESULT:
			return {
				...state,
				starterScheduleList: action.payload.data,
				loadingSearchStarterSchedule: false,
			};

		case FLEET_DELETE_STARTER_SCHEDULE:
			return {
				...state,
				deleteStarterSchedule: null,
				loadingDeleteStarterSchedule: true,
			};

		case FLEET_DELETE_STARTER_SCHEDULE_RESULT:
			return {
				...state,
				deleteStarterSchedule: action.payload.data,
				loadingDeleteStarterSchedule: false,
			};

		case FLEET_UPDATE_STARTER_SCHEDULE:
			return {
				...state,
				updateStarterScheduleResult: null,
				loadingUpdateStarterSchedule: true
			};

		case FLEET_UPDATE_STARTER_SCHEDULE_RESULT:
			return {
				...state,
				updateStarterScheduleResult: action.payload.data,
				loadingUpdateStarterSchedule: false
			};

		case CLEAR_REDUX:
			return ["", "FLEET_MODAL_SCHEDULE_STARTER"].includes(action.payload.option)
				? action.payload.parameters
					? { ...state, ...action.payload.parameters }
					: { ...INIT_STATE }
				: { ...state }

		default:
      return state;
	}
};
