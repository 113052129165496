import {
    SETTINGS_CREWS_SEARCH,
    SETTINGS_CREWS_SEARCH_RESPONSE,
    SETTINGS_CREWS_OPEN_CREATE_EDIT_MODAL,
    SETTINGS_CREWS_GET_CREW,
    SETTINGS_CREWS_GET_CREW_RESPONSE,
    SETTINGS_CREWS_CREATE_EDIT_CREW,
    SETTINGS_CREWS_CREATE_EDIT_CREW_RESPONSE,
    SETTINGS_CREWS_OPEN_DELETE_MODAL,
    SETTINGS_CREWS_DELETE_CREWS,
    SETTINGS_CREWS_DELETE_CREWS_RESPONSE,
    SETTINGS_CREWS_BULK_MODAL,
    SETTINGS_CREWS_BULK_UPDATE,
    SETTINGS_CREWS_BULK_UPDATE_RESPONSE,
    SETTINGS_CREWS_SEARCH_AVAILABLE_ONLY,
    SETTINGS_CREWS_SEARCH_UNIT_ID,
    SETTINGS_CREWS_ASSIGN_EDIT_VEHICLE,
    SETTINGS_CREWS_SEARCH_UNIT_CREWS,
    SETTINGS_CREWS_SEARCH_UNIT_CREWS_RESPONSE,
    SETTINGS_CREWS_SEARCH_UNIT_ID_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loadingCrews: false,
    crews: [],
    errorCrews: false,
    total: 0,
    filters: {
        openSearch: "",
        availableOnly: false, 
        unitId: 0,
        limit: 10,
        offset: 0
    },
    openCreateEdit: false,
    id: null,
    crew: null,
    loadingCrew: false,
    loadingCreateEdit: false,
    createEditResponse: null,
    openDelete: false,
    selectedItems: [],
    loadingDelete: false,
    deleteResponse: null,
    openBulk: false,
    updateCrewBulk: false,
    crewsAvaibleOnly: [],
    loadingCrewsAvaibleOnly: false,
    crewsUnitId: [],
    loadingCrewsUnitId: false,
    openAssignEdit: false,
    loadingVehicles: false,
    vehicles: [],
    totalVehicles: 0,
    openVehicleEdit: false,
    unitSelected: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SETTINGS_CREWS_SEARCH:
            return {
                ...state,
                crews: [],
                crewsAvaibleOnly: [],
                crewsUnitId: [],
                loadingCrews: true,
                errorCrews: false,
                filters: action.payload
            }; 

        case SETTINGS_CREWS_SEARCH_RESPONSE:
            return state.filters.availableOnly ?
                {
                ...state,
                crewsAvaibleOnly: action.payload?.items,
                errorCrews: action.payload?.error,
                loadingCrewsAvaibleOnly: false
                }  :
                {
                    ...state,
                    crews: action.payload?.items,
                    total: action.payload?.total,
                    errorCrews: action.payload?.error,
                    loadingCrews: false
                }

        case SETTINGS_CREWS_SEARCH_UNIT_ID_RESPONSE: 
            return {
                ...state,
                crewsUnitId: action.payload?.items,
                errorCrews: action.payload?.error,
                loadingCrewsUnitId: false
            }

        case SETTINGS_CREWS_OPEN_CREATE_EDIT_MODAL:
            return {
                ...state,
                openCreateEdit: action.payload?.open,
                id: action.payload?.id,
                crew: null
            };

        case SETTINGS_CREWS_GET_CREW:
            return {
                ...state,
                crew: null,
                loadingCrew: true
            };

        case SETTINGS_CREWS_GET_CREW_RESPONSE:
            return {
                ...state,
                id: null,
                crew: action.payload?.data,
                loadingCrew: false
            };
      
        case SETTINGS_CREWS_CREATE_EDIT_CREW:
            return {
                ...state,
                loadingCreateEdit: true,
                createEditResponse: null
            };

        case SETTINGS_CREWS_CREATE_EDIT_CREW_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                createEditResponse: action.payload?.data,
            };

        case SETTINGS_CREWS_OPEN_DELETE_MODAL:
            return {
                ...state,
                openDelete: action.payload?.open,
                selectedItems: action.payload?.items,
                loadingDelete: false,
                deleteResponse: null
            };

        case SETTINGS_CREWS_DELETE_CREWS:
            return {
                ...state,
                loadingDelete: true,
                deleteResponse: null
            };

        case SETTINGS_CREWS_DELETE_CREWS_RESPONSE:
            return {
                ...state,
                loadingDelete: false,
                deleteResponse: action.payload?.data,
            };

        case SETTINGS_CREWS_BULK_MODAL:
            return {
                ...state,
                openBulk: action.payload?.open,
                unitSelected: action.payload?.unit
            };  
        
        case SETTINGS_CREWS_BULK_UPDATE:
            return {
                ...state,
                loadingCreateEdit: true,
                updateCrewBulk: false,
                errorCrews: false
            };    
        
        case SETTINGS_CREWS_BULK_UPDATE_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                updateCrewBulk: action.payload?.update,
                errorCrews: action.payload?.error
            };    

        case SETTINGS_CREWS_SEARCH_AVAILABLE_ONLY:
            return {
                ...state,
                filters: action.payload,
                crewsAvaibleOnly: [],
                loadingCrewsAvaibleOnly: true,
                errorCrews: false
            }; 

        case SETTINGS_CREWS_SEARCH_UNIT_ID:
            return {
                ...state,
                filters: action.payload,
                crewsUnitId: [],
                loadingCrewsUnitId: true,
                errorCrews: false
            };            
        
        case SETTINGS_CREWS_ASSIGN_EDIT_VEHICLE:
            return {
                ...state,
                openAssignEdit: action.payload?.open,
                id: action.payload?.id,
                crew: null
            };  
        
        case SETTINGS_CREWS_SEARCH_UNIT_CREWS: 
            return {
                ...state,
                loadingVehicles: true,
                vehicles: [],
                errorCrews: false,
                filters: action.payload
            };

        case SETTINGS_CREWS_SEARCH_UNIT_CREWS_RESPONSE: 
            return {
                ...state,
                loadingVehicles: false,
                vehicles: action.payload?.items,
                totalVehicles: action.payload?.total,
                errorCrews: action.payload?.error
            };    

        case CLEAR_REDUX:
            return ["", "SETTINGS_CREWS"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return state;
    }
};