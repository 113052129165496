//imports
import {
	GET_PREFERENCES,
	GET_PREFERENCES_RESULT,
	SAVE_PREFERENCE,
	GET_PREFERENCES_RESULT_UPDATE,
	CLEAR_REDUX
} from 'Redux/actionTypes';

//initial values
const INIT_STATE = {
	loadingPreference: false,
	preferencesCompany: [],
	preferencesUser: [],
	preferencesOThers: [],
	returnServer: false,
	preferenceResultUpdate: false
};

//change redux object
export default (state = INIT_STATE, action) => {
	switch (action.type) {


		case GET_PREFERENCES:
			return { ...state }

		case GET_PREFERENCES_RESULT:
			return { ...state, loadingPreference: false, returnServer: true, ...action.payload };

		case SAVE_PREFERENCE:
			return { ...state, returnServer: false };

		case GET_PREFERENCES_RESULT_UPDATE:
			return { ...state, loadingPreference: false, preferenceResultUpdate: action.payload };

		case CLEAR_REDUX:
			return ["", "PREFERENCES"].includes(action.payload.option)
				? action.payload.parameters
					? { ...state, ...action.payload.parameters }
					: { ...INIT_STATE }
				: { ...state }

		default:
			return state;
	}
};
