import {
    MAP_SPEED_OVERRIDE_DELETE_OPEN_MODAL,
    MAP_SPEED_OVERRIDE_DELETE_SPEED_OVERRIDE,
    MAP_SPEED_OVERRIDE_DELETE_SPEED_OVERRIDE_RESPONSE
} from 'Redux/actionTypes';

export const mapSpeedOverrideOpenDeleteModal = (open, speedOverrides) => ({
    type: MAP_SPEED_OVERRIDE_DELETE_OPEN_MODAL,
    payload: { open, speedOverrides },
});

export const mapSpeedOverrideDeleteSpeedOverride = (ids) => ({
    type: MAP_SPEED_OVERRIDE_DELETE_SPEED_OVERRIDE,
    payload: { ids },
});

export const mapSpeedOverrideDeleteSpeedOverrideResponse = (response, error) => ({
    type: MAP_SPEED_OVERRIDE_DELETE_SPEED_OVERRIDE_RESPONSE,
    payload: { response, error },
});