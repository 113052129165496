import { NotificationManager } from 'Components/Notifications';
import { detectBrowser } from 'Core/data/Helpers'
export class OPEN_URL {

  execute = (payload) => {

    // check the target to see if it is shown to all users.
    // open the url
    // show the notification
    // display the message with the "message
    // the url arrives in the data field { link: "" }

    if (payload.data?.status === 'SUCCESS') {
      if (!payload.sessionId || payload.sessionId == sessionStorage.getItem("sessionId")) {
        if (payload.data?.url.length) {
          NotificationManager.success(
            null,
            null,
            0,
            null,
            null,
            null,
            payload.message,
            payload.messageTitle
          );
    
          // Valid if export type for refreshing reports
          if(payload.message === "reportsMainRT_exportReportMessageSuccess"){
            this.openLink(payload.data?.url);
            return [true, { newReport:{exportFile: true} }]
          }

          return this.openLink(payload.data?.url);
        }

        NotificationManager.success(
          null,
          null,
          0,
          null,
          null,
          null,
          'reportsMainRT_noDataMessage',
          payload.messageTitle
        );

        if(payload.message === "reportsMainRT_exportReportMessageSuccess"){
          return [true, { newReport:{exportFile: true} }]
        }
        
      }
    } else {
      NotificationManager.error(
        null,
        null,
        0,
        null,
        null,
        null,
        payload.message,
        payload.messageTitle
      );
    }
    return [false, {}]
  }

  openLink(link) {
    if (link) {
      if (Array.isArray(link) && link.length > 1) {
        return [true, { urlsDownload: link }]
      }
      const newLink = Array.isArray(link) ? link[0]?.url : link
      // If the url is not empty, open the link
      if (newLink) {
        const url = newLink?.includes("http") ? newLink : `https://${newLink}`;
        if (detectBrowser() === 'Firefox') {
          window.open(url, '_blank')
        } else {
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.click();
          document.body.removeChild(a);
        }
        return [false, {}]
      }
    }
  }
}
