export default {
  "sharedMedia_title": "Compartir Medios",
  "sharedMedia_subtitle": "Método",
  "sharedMedia_cancel": "Cancelar",
  "sharedMedia_share": "Compartir",
  "sharedMedia_contactsEmail": "Escriba un email válido o seleccione un contacto",
  "sharedMedia_email": "Correo Eléctronico",
  "sharedMedia_contactsSMS": "Escriba un número de teléfono válido o seleccione un contacto",
  "sharedMedia_sms": "Número de Teléfono",
  "sharedMedia_addEmailSubscriber": "Agregar email",
  "sharedMedia_invalidEmail": "Debe ingresar una dirección de correo electrónico válida",
  "sharedMedia_success": "Se ha compartido exitosamente",
  "sharedMedia_error": "Ha ocurrido un error. Por favor intente de nuevo.",
  "sharedMedia_notFound": "Medio no encontrado",
  "sharedMedia_selected": "Medio seleccionado",
  "sharedMedia_noOptionsMessageEmail": "Este email ya está agregado",
  "sharedMedia_noOptionsMessageSMS": "Este número de teléfono ya está agregado",
  "sharedMedia_addSMSSubscriber": "Agregar número de teléfono",
  "sharedMedia_carrier": "Operador",
  "sharedMedia_ratesMayApply": "* Se pueden aplicar tarifas por mensajes y datos",
  "sharedMedia_text": "SMS",
  "sharedMedia_email": "Email",
  "sharedMedia_send": "Enviar",
  "sharedMedia_choose_contact": "Seleccionar Método de Contacto:",
}