export default {
    formFtp_newAdd: 'Añadir Nuevo Servidor FTP',
    formFtp_ftpServer: 'Servidor FTP',
    formFtp_host: 'Servidor',
    formFtp_protocol: 'Protocolo',
    formFtp_userName: 'Nombre de usuario',
    formFtp_password: 'Contraseña',
    formFtp_port: 'Puerto',
    formFtp_basePath: 'Ruta base',
    formFtp_cancel: 'Cancelar',
    formFtp_delete: 'Eliminar servidor FTP',
    formFtp_saveFtpServer: 'Guardar servidor FTP',
    formFtp_saveChanges: 'Guardar cambios',
    formFtp_success: 'FTP ha sido registrado correctamente.',
    formFtp_successDelete: 'FTP ha sido eliminado correctamente.',
    formFtp_errorHostDuplicate: 'Servidor FTP duplicado, cámbialo e inténtalo de nuevo.',
    formFtp_error: 'Ocurrió un error inténtelo más tarde',
    formFtp_Label: 'Seleccionar servidor FTP'
}
