import {
  TOGGLE_SIDEBAR,
  HIDE_TOGGLE_SIDEBAR,
  DOWNLOAD_ATTACHMENT_URL,
  DOWNLOAD_ATTACHMENT_URL_RESULT,
  UPLOAD_ATTACHMENT,
  UPLOAD_ATTACHMENT_RESULT,
  UPLOAD_ATTACHMENT_LOCAL,
  UPLOAD_ATTACHMENT_LOCAL_RESULT,
  SET_DEVELOPER,
  TOGGLE_FULL_SCREEN,
  GENERIC_EXPORT_SEND,
  GENERIC_EXPORT_SEND_RESULT,
  CLEAR_REDUX,
  SET_CALENDAR_SCROLL_PARENT,
} from 'Redux/actionTypes';
import { CHANGE_SELECT_LANGUAGE, GET_USER_LANGUAGE, GET_USER_LANGUAGE_RESULT } from './actionTypes';

const INIT_STATE = {
  sidebarShow: true,
  hideToggleButton: false,
  endPoint: null,
  uploadedAttachment: null,
  uploadedAttachmentLocal: null,
  loadingUploadFile: false,
  loadingDownloadFile: false,
  isDeveloper: false,
  isFullScreen: false,
  language: localStorage.getItem('lan') || 'en',
  loadingExport: false,
  exportName: null,
  exportResult: null,
  calendarScrollParent: false,
  loadingUserLanguage: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case TOGGLE_SIDEBAR:
      return { ...state, sidebarShow: !state.sidebarShow };

    case HIDE_TOGGLE_SIDEBAR:
      return { ...state, hideToggleButton: action.payload };

    case DOWNLOAD_ATTACHMENT_URL:
      return { ...state, loadingDownloadFile: true, endPoint: null };

    case DOWNLOAD_ATTACHMENT_URL_RESULT:
      return { ...state, loadingDownloadFile: false, endPoint: action.payload.data };

    case UPLOAD_ATTACHMENT:
      return { ...state, loadingUploadFile: true, uploadedAttachment: null };

    case UPLOAD_ATTACHMENT_RESULT:
      return { ...state, loadingUploadFile: false, uploadedAttachment: action.payload.data };

    case UPLOAD_ATTACHMENT_LOCAL:
      return { ...state, loadingUploadFile: true, uploadedAttachmentLocal: null };

    case UPLOAD_ATTACHMENT_LOCAL_RESULT:
      return { ...state, loadingUploadFile: false, uploadedAttachmentLocal: action.payload.data };

    case SET_DEVELOPER:
      return { ...state, isDeveloper: action.payload };

    case TOGGLE_FULL_SCREEN:
      return { ...state, isFullScreen: action.payload };

    case CHANGE_SELECT_LANGUAGE:
      return { ...state, language: action.payload }

    case GENERIC_EXPORT_SEND:
      return { ...state, exportName: action.payload.name, loadingExport: true }

    case GENERIC_EXPORT_SEND_RESULT:
      return { ...state, loadingExport: false, exportResult: action.payload }

    case GET_USER_LANGUAGE:
      return { ...state, loadingUserLanguage: true }

    case GET_USER_LANGUAGE_RESULT:
      return { ...state, loadingUserLanguage: false }

    case SET_CALENDAR_SCROLL_PARENT:
      return {
        ...state,
        calendarScrollParent: action.payload
    }

    case CLEAR_REDUX:
      return ["", "COMMON"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }
    default:
      return state;
  }
};
