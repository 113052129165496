import {
  EDIT_UNIT_OPEN,
  EDIT_UNIT_OPEN_RESULT,
  EDIT_UNIT_CLOSE,
  EDIT_UNIT_UPDATE,
  EDIT_UNIT_UPDATE_RESULT,
  EDIT_MULTIPLE_UNITS,
  EDIT_UNIT_SEND_OTA,
  EDIT_UNIT_SEND_OTA_RESULT,
  EDIT_UNIT_SEND_PING,
  EDIT_UNIT_SEND_PING_RESULT,
  EDIT_UNIT_DECODE_VIN,
	EDIT_UNIT_DECODE_VIN_RESPONSE,
  CLEAR_REDUX
} from 'Redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
  isOpen: false,
  loading: false,
  unit: null,
  resultUpdate: null,
  showEditMultipleUnits: false,
  units: null,
  loadingOta: false,
  otaResponse: null,
  loadingPing: false,
  pingResponse: null,
  vehicleInformation: null,
  vehicleInformationLoading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case EDIT_UNIT_OPEN:
      return { ...state, isOpen: true, loading: true }

    case EDIT_UNIT_OPEN_RESULT:
      return { ...state, loading: false, unit: action.payload }

    case EDIT_UNIT_CLOSE:
      return { ...INIT_STATE }

    case EDIT_UNIT_UPDATE:
      return { ...state, loading: true }

    case EDIT_UNIT_UPDATE_RESULT:
      return { ...state, loading: false, resultUpdate: action.payload };

    case EDIT_MULTIPLE_UNITS:
      return { ...state, showEditMultipleUnits: true, units: action.payload };

    case EDIT_UNIT_SEND_OTA:
      return { ...state, loadingOta: true, otaResponse: null };

    case EDIT_UNIT_SEND_OTA_RESULT:
      return { ...state, loadingOta: false, otaResponse: action.payload };

    case EDIT_UNIT_SEND_PING:
      return { ...state, loadingPing: true, pingResponse: null };

    case EDIT_UNIT_SEND_PING_RESULT:
      return { ...state, loadingPing: false, pingResponse: action.payload };

    case CLEAR_REDUX:
      return ["", "MODAL_EDIT_UNIT"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    case EDIT_UNIT_DECODE_VIN:
      return {
        ...state,
        vehicleInformation: null,
        vehicleInformationLoading: true
      };

    case EDIT_UNIT_DECODE_VIN_RESPONSE:
      return {
        ...state,
        vehicleInformation: action.payload?.response,
        vehicleInformationLoading: false
      };

    default:
      return state;
  }
};
