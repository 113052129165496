//imports
import {
	FORCE_CHANGE_PASSWORD,
	FORCE_CHANGE_PASSWORD_RESULT,
	CLEAR_REDUX
} from 'Redux/actionTypes';

//initial values
const INIT_STATE = {
	loading: false,
	error: null
};

//change redux object
export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case FORCE_CHANGE_PASSWORD:
			return { ...INIT_STATE, loading: true, error: null };

		case FORCE_CHANGE_PASSWORD_RESULT:
			return {
				...state,
				loading: false,
				error: action.payload.error,
			};

		case CLEAR_REDUX:
			return ["", "FORCE_CHANGE_PASSWORD"].includes(action.payload.option)
				? action.payload.parameters
					? { ...state, ...action.payload.parameters }
					: { ...INIT_STATE }
				: { ...state }
		default:
      return state;
	}
};
