

export const permissionUnit = (list = [], unitsList = []) => {
    if(!unitsList && unitsList?.length === 0) return []
    if(!list && list?.length === 0) return []
    const validItems = []
    list?.map(item => {
        const exist = unitsList?.find(unit => unit?.id === item?.unitId)
        if(exist){
            validItems.push(item)
        }
    })

    return validItems
}

export const getUnit = (unitId, unitList = []) => {
    const unit = unitList.find(item => item.id === unitId)
    return unit
}

export const findUnit = (unitList = [], unitSelected) => {
    const find = unitList?.find((unit) => unit?.id === unitSelected);
    return find;
};