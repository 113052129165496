import React from 'react';
// Material UI
import { Grid, Typography } from '@mui/material';
import {
  cutNotes,
  copyToClipboard
} from 'Core/data/Helpers'
import { Tooltip, Button } from 'Components';
import { TagsGroup } from 'Components/Tag';
// Styles
import '../infoBubble.scss';
import '../../../../../components/Map/providers/google/InfoWindow/style.scss'

const evalDirection = (data) => {
  const address = data?.markerDetail?.landmark?.address
  if (address) {
    if (address.indexOf(',') == -1) {
      const { address, city, zip, state, country } = data?.markerDetail?.landmark
      let response = address;
      response = city ? response + `, ${city}` : response;
      response = state || zip ? response + `, ${state} ${zip}` : response;
      response = country ? response + `, ${country}` : response;
      return response;
    }
  }
  return address;
}

const LandmarkInfo = (props) => {
  const { data, messages } = props;

  const address = evalDirection(data)

  return (
    <div className='info-window-tab-streetview'>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          padding: '16px 45px 16px 16px'
        }}
      >
        <Grid item xs zeroMinWidth>
          <Typography noWrap style={{ fontWeight: 'bold' }}>
            {data?.markerDetail?.landmark?.name}
          </Typography>
        </Grid>
      </Grid>

      <div style={{ borderTop: 'solid 1px #e4e4e4' }}></div>

      <div>
        <table className="info-bubble-table">
          <tbody>
            <tr>
              <td>
                {messages['infoBubble_address']}
              </td>
              <td>
                <div className="hover-container">
                  <span style={{ paddingRight: 8 }}>
                    {address || "N/A"}
                  </span>
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(address || "N/A")}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                Shape
              </td>
              <td>
                <div className="hover-container">
                  <span style={{ paddingRight: 8 }}>
                    {data?.markerDetail?.landmark?.shape || "N/A"}
                  </span>
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(data?.markerDetail?.landmark?.shape || "N/A")}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                Note
              </td>
              <td>
                <Tooltip
                  title={data?.markerDetail?.landmark?.note ? cutNotes(data?.markerDetail?.landmark?.note, 200) : "N/A"}
                  placement={'bottom'}
                >
                  <div style={{
                    width: 380,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}>
                    <div className="hover-container">
                      <span style={{ paddingRight: 8 }}>
                        {data?.markerDetail?.landmark?.note ? cutNotes(data?.markerDetail?.landmark?.note, 50) : "N/A"}
                      </span>
                      <Button
                        type="icon"
                        icon="content_copy"
                        onClick={() => copyToClipboard(`${data?.markerDetail?.landmark?.note ? data?.markerDetail?.landmark?.note : "N/A"}`)}
                        fontSize="inherit"
                        className="btn-no-padding hover-item"
                      />
                    </div>
                  </div>
                </Tooltip>
              </td>
            </tr>

            <tr>
              <td>
                {messages['infoBubble_latLng']}
              </td>
              <td>
                <div className="hover-container">
                  <span style={{ paddingRight: 8 }}>
                    {(data?.markerDetail?.landmark?.latitude && data?.markerDetail?.landmark?.longitude ? `${data?.markerDetail?.landmark?.latitude?.toFixed(6)}, ${data?.markerDetail?.landmark?.longitude?.toFixed(6)}` : "N/A")}
                  </span>
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(`${(data?.markerDetail?.landmark?.latitude && data?.markerDetail?.landmark?.longitude ? `${data?.markerDetail?.landmark?.latitude?.toFixed(6)}, ${data?.markerDetail?.landmark?.longitude?.toFixed(6)}` : "N/A")}`)}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>

              </td>
            </tr>

            {
              data?.markerDetail?.landmark?.tags?.length > 0 &&
              <tr>
                <td>
                  {messages['infoBubble_tags']}
                </td>
                <td>
                  <TagsGroup
                    options={data?.markerDetail?.landmark?.tags}
                    nameField="label"
                    colorField="color"
                    //alwaysGroup={true}
                  />
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default LandmarkInfo;