//View Keys
export default {
	forceChangePassword_username: 'Username',
	forceChangePassword_password: 'Password',
	forceChangePassword_confirmPassword: 'Confirm Password',
	forceChangePassword_minMessagePassword: "The password must contain minimun chars:",
  forceChangePassword_maxMessagePassword: "The password must contain maximum chars:",
  forceChangePassword_minMessageConfirmPassword: "The confirm password must contain minimun chars:",
  forceChangePassword_maxMessageConfirmPassword: "The confirm password must contain maximum chars:",
	forceChangePassword_valueToCompareMessage: "Confirm password does not match new Password",
	forceChangePassword_changePasswordMessages: "It is necessary to change the password"
};
