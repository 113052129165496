// Dependencies
import { all, put, takeEvery, fork, call } from 'redux-saga/effects';

// Logger
import { ExceptionManager } from 'Core/logManager';

// Local GraphQL
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';

// Action types
import {
  ENTITY_SELECTOR_GET_LANDMARK_GROUPS,
  ENTITY_SELECTOR_SEARCH_LANDMARKS,
  ENTITY_SELECTOR_SEARCH_LANDMARKS_OVER_OFFSET,
  ENTITY_SELECTOR_SEARCH_GEOFENCES,
  ENTITY_SELECTOR_SEARCH_GEOFENCES_GROUP,
  ENTITY_SELECTOR_SEARCH_FORM_GROUP
} from 'Redux/actionTypes';

// Actions
import {
  getLandmarkGroupsResult,
  setTotalPaginatedItems,
  searchLandmarksResult,
  searchGeofencesResult,
  searchGeofenceGroupsResult,
  searchFormGroupsResult
} from 'Redux/actions';

//Get the landmark groups from tags entity
function* getLandmarkGroupsRequest() {
  try {
    const query = `query searchTagsInline ($entityName: String, $limit: Int, $offset: Int) {
      result: searchTagsInline (entityName: $entityName, limit: $limit, offset: $offset) {
            items {
                id
                label
                color
            }
            total
        }
    }`;

    const params = {
      entityName: "Landmark",
    }

    let data = yield call(
      clientQuery,
      query,
      params,
      endpoints.GRAPHQL_GENERAL,
    );

    const landmarkTags = data?.result;

    if (landmarkTags && landmarkTags?.items && landmarkTags?.items.length > 0) {
      yield put(getLandmarkGroupsResult(landmarkTags.items.map((tag) => { 
        return {
          ...tag,
          name: tag.label // return name atribute from UI
        }
      } )));
    } else {
      throw 'not service details';
    }
  } catch (exc) {
    yield put(getLandmarkGroupsResult([]));
    ExceptionManager(exc, 'modules/.common/components/EntitySelector/redux/saga', 'getLandmarkGroupsRequest');
  }
};

/**
 * Get the paginated landmarks according the given params.
 * @param { {payload: {offset, limit}}} params - The paginations params.
 */
function* searchLandmarksRequest({ payload }) {
  const { offset, limit, openSearch, shouldClearLandmarks, groupId, closeSearch } = payload;
  try {
    const query =
      `query ($openSearch: String, $limit: Int, $offset: Int, $tagId: [Int]) {
        searchLandmarks (openSearch: $openSearch, limit: $limit, offset: $offset, tagId: $tagId) {
            items {
                id
                companyId
                name
                latitude
                longitude
                shape
                radius
                address
                city
                tags{
                  label
                }
                zip
                state
                country
                note
                coordinates {
                    latitude
                    longitude
                }
                createdBy
                createdOn
                updatedBy
                updatedOn
            }
            total
        }
      }`;

    let params = {
      offset,
      limit,
      openSearch,
      tagId: groupId
    };

    if(!groupId){
      delete params["groupId"]
    }

    if(!openSearch){
      delete params["openSearch"]
    }

    const data = yield call(clientQuery, query, params, endpoints.GRAPHQL_GENERAL);
    const concatenateLandmarks = openSearch ? openSearch : closeSearch

    const { searchLandmarks: { items, total } } = data;
    if (items) {
      yield put(searchLandmarksResult(items, concatenateLandmarks, shouldClearLandmarks));
      yield put(setTotalPaginatedItems(total));
    } else {
      throw 'Error getting paginated landmarks';
    }

  } catch (exc) {
    ExceptionManager(exc, 'modules/.common/components/EntitySelector/redux/saga', 'searchLandmarksRequest')
  }
};

function* searchLandmarksOverOffset({ payload }) {
  // TODO
}

/**
 * Get the paginated landmarks according the given params.
 * @param { {payload: {offset, limit}}} params - The paginations params.
 */
function* searchGeofenceRequest({ payload }) {
  const { offset, limit, openSearch, groupId } = payload;
  try {
    const query =
      `query searchGeofences ($openSearch: String, $groupId: Int, $limit: Int, $offset: Int) {
        searchGeofences (openSearch: $openSearch, groupId: $groupId, limit: $limit, offset: $offset) {
            items {
                id
                companyId
                name
                groupId
                groupName
                unitType
                radius
                alertOnType
                contacts {
                    id
                    title
                    address
                    type
                }
                coordinates {
                    latitude
                    longitude
                }
                insideDurationMins
                createdBy
                createdOn
                updatedBy
                updatedOn
            }
            total
        }
    }`;

    let params = {
      offset,
      limit,
      openSearch,
      groupId
    };

    if(groupId == null){
      delete params["groupId"]
    }

    if(!openSearch){
      delete params["openSearch"]
    }

    const data = yield call(clientQuery, query, params, endpoints.GRAPHQL_GENERAL);
    
    const { searchGeofences: { items, total } } = data;
    if (items) {
      yield put(searchGeofencesResult(items));
      yield put(setTotalPaginatedItems(total));
    } else {
      throw 'Error getting paginated geofences';
    }

  } catch (exc) {
    ExceptionManager(exc, 'modules/.common/components/EntitySelector/redux/saga', 'searchGeofenceRequest')
  }
};

/**
 * Get the paginated landmarks according the given params.
 * @param { {payload: {offset, limit}}} params - The paginations params.
 */
 function* searchGeofenceGroupsRequest({ payload }) {
  const { openSearch } = payload;
  try {
    const query =
      `query searchGeofenceGroups ($openSearch: String) {
        searchGeofenceGroups (openSearch: $openSearch) {
            id
            name
        }
    }`;

    let params = {
      openSearch
    };

    if(!openSearch){
      delete params["openSearch"]
    }

    const data = yield call(clientQuery, query, params, endpoints.GRAPHQL_GENERAL);
    
    const { searchGeofenceGroups } = data;
    if (searchGeofenceGroups) {
      yield put(searchGeofenceGroupsResult(searchGeofenceGroups));
    } else {
      throw 'Error getting paginated geofences';
    }

  } catch (exc) {
    ExceptionManager(exc, 'modules/.common/components/EntitySelector/redux/saga', 'searchGeofenceRequest')
  }
};

/**
 * Get the paginated Formgroups according the given params.
 * @param { {payload: {offset, limit}}} params - The paginations params.
 */
 function* searchFormGroupsRequest({ payload }) {
  const { openSearch, limit, offset } = payload;
  try {
    const query =
      `query searchFormGroups ($openSearch: String, $limit: Int!, $offset: Int) {
        searchFormGroups (openSearch: $openSearch, limit: $limit, offset: $offset) {
            items {
                id
                name
            }
            total
        }
      }`;

    let params = {
      openSearch,
      limit: limit || 0,
      offset: offset || 0
    };

    const data = yield call(clientQuery, query, params, endpoints.GRAPHQL_GENERAL);
    
    const { searchFormGroups } = data;

    if (searchFormGroups) {
      yield put(searchFormGroupsResult(searchFormGroups));
    } else {
      throw 'Error getting paginated formgroup';
    }
  } catch (exc) {
    ExceptionManager(exc, 'modules/.common/components/EntitySelector/redux/saga', 'searchFormGroupsRequest')
  }
};

function* watchGetLandmarkGroupsRequest() {
  yield takeEvery(ENTITY_SELECTOR_GET_LANDMARK_GROUPS, getLandmarkGroupsRequest);
};

function* watchSearchLandmarksRequest() {
  yield takeEvery(ENTITY_SELECTOR_SEARCH_LANDMARKS, searchLandmarksRequest);
};

function* watchSearchGeofencesRequest() {
  yield takeEvery(ENTITY_SELECTOR_SEARCH_GEOFENCES, searchGeofenceRequest);
  yield takeEvery(ENTITY_SELECTOR_SEARCH_GEOFENCES_GROUP,searchGeofenceGroupsRequest)
};

function* watchSearchFormGroupsRequest() {
  yield takeEvery(ENTITY_SELECTOR_SEARCH_FORM_GROUP, searchFormGroupsRequest)
};

function* watchSearchLandmarksOverOffset() {
  yield takeEvery(ENTITY_SELECTOR_SEARCH_LANDMARKS_OVER_OFFSET, searchLandmarksOverOffset);
};

export default function* rootSaga() {
  yield all([fork(watchGetLandmarkGroupsRequest)]);
  yield all([fork(watchSearchLandmarksRequest)]);
  yield all([fork(watchSearchGeofencesRequest)]);
  yield all([fork(watchSearchFormGroupsRequest)]);
};
