import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux';
import { IconButton, Loading, Tooltip } from '@gpstrackit/gtc-react-controls';
import { GetFormatForMoment } from "Core/data/Helpers";
import { speedFormatMPH } from "Core/data/Helpers";
import { getMethodToFormatDuration } from 'Modules/reports/views/main/components/reportsTab/helper';
import { getEventColor } from "Components/Map/utils";

export const DeviceInfo = ({readingSelected, numberSelected}) => {
  const { messages, locale } = useIntl()
  const { user } = useSelector((state) => state.securityRedux);
  const { dateformat, durationFormat, isKph } = user;
  const { isOpenHistoryOverViewIncident } = useSelector((state) => state.incidentsRedux);
  const unitId = isOpenHistoryOverViewIncident?.data?.unit?.id

  if(!unitId) return <></>

  const [loading, setLoading] = useState(true)
  const [readingInformation, setReadingInformation] = useState({
    lastEvent: '...',
    unitTime: '...',
    address: '...',
    eventName: '...',
    speed: '...',
    latLng: '...',
  })


  useEffect(() => {
    const unit = isOpenHistoryOverViewIncident?.data?.unit
    if(unit){
      const newSpeed = unit?.useEcuSpeed ? (readingSelected?.ecuSpeed ? readingSelected?.ecuSpeed : readingSelected?.speed) : readingSelected?.speed;
      const speedFormat = speedFormatMPH(newSpeed, unit?.type, isKph);

      const unixTime = Number(readingSelected?.unitTime)
      const duration = moment().diff(moment(unixTime), 's');
      const durationFormatTime = getMethodToFormatDuration(durationFormat);
      const durationResult = duration ? durationFormatTime(duration, messages): '0 min, 0 sec'

      let durationLabel = `${moment(readingSelected?.unitTime).tz(unit?.timeZone?.name).format('hh:mm a')} (${durationResult} ago),`
      if(locale === 'es'){
        durationLabel = `${moment(readingSelected?.unitTime).tz(unit?.timeZone?.name).format('hh:mm a')} (hace ${durationResult} ),`
      }

      let address = readingSelected?.formattedAddress ? readingSelected?.formattedAddress : '...'
      if(address?.length > 45){
        address = (<Tooltip title={address}>
                    {address.slice(0, 45) + "..."}
                  </Tooltip>)
      }

      let abbTimezone = moment.tz(unit?.timeZone?.name).zoneAbbr()
      if(abbTimezone === '-05'){
        abbTimezone = 'COT'
      }


      setReadingInformation({
        ...readingInformation,
        eventName: readingSelected?.eventName ? readingSelected?.eventName : '...',
        latLng: `${readingSelected?.latitude ? readingSelected?.latitude : '...'} , ${readingSelected?.longitude ? readingSelected?.longitude : '...'}`,
        address: address,
        lastEvent: <div style={{display: 'flex'}}>
                    <div style={{display: 'flex', marginRight: '5px'}}>
                      <p style={{ margin: 0, marginRight: "5px" }}>
                        {moment(readingSelected?.unitTime).tz(unit?.timeZone?.name).format("hh:mm")}
                      </p>
                      <Tooltip title={unit?.timeZone?.name}>
                        {abbTimezone}
                      </Tooltip>
                    </div>
                    <p style={{ margin: 0 }}>{moment(readingSelected?.unitTime).tz(unit?.timeZone?.name).format(GetFormatForMoment(dateformat))}</p>
                  </div>,
        speed: speedFormat,
        unitTime: <div style={{display: 'flex'}}>
                    <p style={{ margin: 0, marginRight: "5px" }}>{durationLabel}</p>
                    <Tooltip title={unit?.timeZone?.name}>
                      {abbTimezone}
                    </Tooltip>
                  </div>

      })

      setLoading(false)
    }
  }, [readingSelected])

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {}).catch(err => {
      console.error('Error to copy text: ', err);
    });
  }

  const copyField = (field = '') => {
    const unit = isOpenHistoryOverViewIncident?.data?.unit
    let text = ''
    switch(field){
      case 'lastEvent':
        text = `${moment(readingSelected?.unitTime).format("hh:mm")} ${unit?.timeZone?.name} ${moment(readingSelected?.unitTime).format(GetFormatForMoment(dateformat))}` 
        break
      case 'unitTime':
        const unixTime = Number(readingSelected?.unitTime)
        const duration = moment().diff(moment(unixTime), 's');
        const durationFormatTime = getMethodToFormatDuration(durationFormat);
        const durationResult = duration ? durationFormatTime(duration, messages): '0 min, 0 sec'

        let durationLabel = `${moment(readingSelected?.unitTime).format('hh:mm a')} (${durationResult} ago), ${unit?.timeZone?.name}`
        text = durationLabel
        break
      case 'address':
        text = readingSelected?.formattedAddress
        break
      case 'eventName':
        text = readingSelected?.eventName
        break
      case 'speed':
        const newSpeed = unit?.useEcuSpeed ? (readingSelected?.ecuSpeed ? readingSelected?.ecuSpeed : readingSelected?.speed) : readingSelected?.speed;
        const speedFormat = speedFormatMPH(newSpeed, unit?.type, isKph);
        text = speedFormat
        break
      case 'latLng':
        text = `${readingSelected?.latitude} / ${readingSelected?.longitude}`
        break
      default:
        text = ''
    }

    return copyToClipboard(text)
  }

  const renderIcon = (copyTo = '') => {
    return <IconButton onClick={() => copyField(copyTo)} style={{marginTop: '-5px'}} className='icon' icon='content_copy' size='xsmall'/>
  }

  


  return (
    <div className='incident_deviceInfo_history' style={{width: '100%', height: '200px'}}>
        <p className='incident_deviceInfo_title_history'>
          {numberSelected && `${numberSelected} -`} {readingInformation?.eventName}
        </p>
        
        {loading ?
          <div className='incident_deviceInfo_loading'>
            <Loading />
          </div> :
          <div className='incident_deviceInfo_container_history'>
            <div className='incident_deviceInfo_lastEvent' style={{display: 'flex', margin: '3px 0px -5px 0px'}}>
              <p style={{fontWeight: 'bold', marginRight: '5px'}}>{messages['incident_event_deviceInfo_lastEvent']}</p>
              {readingInformation?.lastEvent}
              {renderIcon('lastEvent')}
            </div>
            <div className='incident_deviceInfo_unitTime' style={{display: 'flex', margin: '3px 0px -5px 0px'}}>
              <p style={{fontWeight: 'bold', marginRight: '5px'}}>{messages['incident_event_deviceInfo_unitTime']}</p>
              <p>{readingInformation?.unitTime}</p>
              {renderIcon('unitTime')}
            </div>
            <div className='incident_deviceInfo_address' style={{display: 'flex', margin: '3px 0px -5px 0px'}}>
              <p style={{fontWeight: 'bold', marginRight: '5px'}}>{messages['incident_event_deviceInfo_address']}</p>
              <p>{readingInformation?.address}</p>
              {renderIcon('address')}
            </div>
            <div className='incident_deviceInfo_eventName' style={{display: 'flex', margin: '3px 0px -5px 0px'}}>
              <p style={{fontWeight: 'bold', marginRight: '5px'}}>{messages['incident_event_deviceInfo_event']}</p>
              <p style={{color: readingInformation?.eventName !== '...' ? getEventColor(readingInformation?.eventName) : 'black' }}>{readingInformation?.eventName}</p>
              {renderIcon('eventName')}
            </div>
            <div className='incident_deviceInfo_speed' style={{display: 'flex', margin: '3px 0px -5px 0px'}}>
              <p style={{fontWeight: 'bold', marginRight: '5px'}}>{messages['incident_event_deviceInfo_speed']}</p>
              <p>{readingInformation?.speed}</p>
              {renderIcon('speed')}
            </div>
            <div className='incident_deviceInfo_latLng' style={{display: 'flex', margin: '3px 0px -5px 0px'}}>
              <p style={{fontWeight: 'bold', marginRight: '5px'}}>{messages['incident_event_deviceInfo_lat_long']}</p>
              <p>{readingInformation?.latLng}</p>
              {renderIcon('latLng')}
            </div>
          </div>
        }
    </div>
  )
}