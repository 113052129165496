import _ from 'lodash'
//imports
import {
  CATALOG_LOAD,
  CATALOG_LOAD_RESULT,
  CATALOG_INITIAL,
  CATALOG_LOADINGS,
  CATALOG_INITIAL_RESULT,
  CLEAR_REDUX,
  CATALOG_PROCESS_ITEM,
  CATALOG_PROCESS_ITEMS,
  CREATE_ASYNC_REPORTS,
  CATALOG_INITIAL_REFRESH,
  CATALOG_INITIAL_REFRESH_RESULT,
  CATALOG_SYNC_PUSH_NOTIFICATIONS,
  CATALOG_SYNC_PUSH_NOTIFICATIONS_RESULT,
  CATALOG_UPDATE_ITEM,
  CATALOG_INITIAL_GET_UNIT,
  CATALOG_INITIAL_GET_UNIT_RESULT,
  CATALOG_ADD_ITEM,
  CATALOG_REMOVE_ITEM
} from 'Redux/actionTypes';

import * as processors from '../processors'

//initial values
const INIT_STATE = {
  // socket units
  loadingUnits: false,
  units: [],
  unitListToShow: [],
  temp_units: [],

  // socket last readings
  loadingReadings: false,
  readings: [],

  //vehicle information properties
  vehiclesInformation: [],
  loadingVehiclesInformation: false,

  pmDevices: [],
  loadingPmDevices: false,


  //OTHER CATALOGS

  loadingDrivers: false,
  drivers: [],
  driversIgnoreStatus: [],
  driversWithAvatar: [],

  loadingContacts: false,
  contacts: [],

  loadingDriverTasks: false,
  driverTasks: [],

  loadingUnitGroups: false,
  unitGroups: [],

  loadingDriverGroups: false,
  driverGroups: [],

  loadingLocation: false,
  locations: [],

  loadingTimeZones: false,
  timeZones: [],

  loadingInputLabels: false,
  inputLabels: [],

  loadingUnitsList: false,
  unitsList: [],

  loadingUnitsLastLocation: false,
  unitsLastLocation: [],

  loadingMaintenanceServicesItems: false,
  maintenanceServicesItems: [],

  loadingVehicleStatus: false,
  vehicleStatus: [],

  kmlPushNotification: 0,
  migrationPushNotification: {},
  fsmNomadPushNotification: {},
  chatNotification: {},

  asyncReports: [],
  refreshClipRequest: {},
  getUserInfoRefresh: false,
  isNavigate: false,
  newReport: null,

  loadingUnitGroupsList: false,
  unitGroupsList: [],

  loadingCellCarriers: false,
  cellCarriers: [],

  loadingSubusers: false,
  subusers: [],

  urlsDownload: [],

  loadingForms: false,
  forms: [],

  tags: [],
  loadingTags: false,

  unitTags: [],
  loadingUnitTags: false,

  idSubUserLogout: null,

  chatUsers: [],
  loadingChatUsers: false,

  notificationAlertList: [],
  notificationAlert: 0,

  endIncidentNotification: [],
  liveClipUpdated: null

};

//change redux object
export default (state = INIT_STATE, action) => {

  let objResult;
  const processInitialItems = (type, items, oldItems) => {
    let processor = processors[type]
    if (processor) {
      let processorObj = new processor()
      const [result, itemsProcessed] = processorObj.executeArray(items, oldItems)

      if (result)
        return itemsProcessed


      return {}
    }
  }

  switch (action.type) {

    case CATALOG_LOAD:
      return state;

    case CATALOG_LOAD_RESULT:
      //console.log(state)
      return { ...state, ...action.payload, unitTags:[] };

    case CATALOG_INITIAL:
      //console.log(state)
      return { ...state, loadingUnits: true, loadingReadings: true, loadingTags: true };

    case CATALOG_LOADINGS:
      return { ...state, ...action.payload };

    case CATALOG_INITIAL_RESULT:
      let objUpdate = action.payload;
      objResult = { ...state, ...objUpdate }
      //console.log(objResult)
      /*if (state.temp_units && state.temp_units.length > 0) {
        objResult = processInitialItems("units", state.temp_units, action.payload || [])
        objUpdate = { ...objUpdate, ...objResult, units_temp: [] }
      }*/

      let units = processInitialItems("Unit", objUpdate.units, objResult)
      objResult = { ...objResult, ...units }

      let readings = processInitialItems("LastReading", objUpdate.readings, objResult)
      objResult = { ...objResult, ...readings }

      // let pmDevices = processInitialItems("pmDevices", objUpdate.pmDevices, objResult)
      // objResult = { ...objResult, ...pmDevices }

      let vehiclesInformation = processInitialItems("VehicleInformation", objUpdate.vehiclesInformation, objResult)
      objResult = { ...objResult, ...vehiclesInformation }

      let unitTags = processInitialItems("UnitTags", objUpdate.unitTags, objResult);

      objResult = { ...objResult, ...unitTags };

      //return { ...state, loadingUnits: true, loadingReadings: true, loadingVehiclesInformation: true };
      return { ...state, ...objResult };

    case CATALOG_PROCESS_ITEM:

      //Process notifications specific Cases
      if (action.payload?.type == 'Notification' && action.payload?.item?.type == 'KML') {
        return { ...state, kmlPushNotification: state.kmlPushNotification + 1 }
      }

      if (action.payload?.type == 'Notification' && action.payload?.item?.type == "MIGRATION") {
        return { ...state, migrationPushNotification: JSON.parse(action.payload?.item?.data) }
      }

      if (action.payload?.type == 'Notification' && action.payload?.item?.Entity?.includes('nomad')) {
        return { ...state, fsmNomadPushNotification: action.payload.item }
      }

      let processor = processors[action.payload.type]
      if (!processor) return { ...state };

      let processorObj = new processor()
      const res = processorObj.execute(action.payload.item, state, action.payload.entity, action.payload.entityKey)
      if(!res) return { ...state };
      const [result, itemsProcessed] = res
      if (result)
        return { ...state, ...itemsProcessed };
      else
        return { ...state };

    case CATALOG_PROCESS_ITEMS:
      //validate if exists
      /*if (state[action.payload.type] && state[action.payload.type].length == 0) {
        //set temp value
        state["temp_" + action.payload.type] = action.payload.items
        return { ...state };
      }*/

      //console.log("CATALOG_PROCESS_ITEMS", action.payload.type, action.payload.items)
      objResult = processInitialItems(action.payload.type, action.payload.items, state || [])

      //return { ...state, loadingUnits: true, loadingReadings: true, loadingVehiclesInformation: true };
      return { ...state, ...objResult };

    case CATALOG_UPDATE_ITEM:
      const { entity, entityKey, values } = action.payload
      //console.log("CATALOG_UPDATE_ITEM", entity, entityKey, values)
      const items = _.clone(state[entity]) || []
      let findIndex = items.findIndex(x => x[entityKey] == values[entityKey])

      //console.log("CATALOG_UPDATE_ITEM", findIndex, values[entityKey])
      if (findIndex > -1) {
        //console.log("CATALOG_UPDATE_ITEM findIndex", findIndex, items[findIndex])
        items[findIndex] = { ...items[findIndex], ...values }
      }

      state[entity] = items

      //console.log("CATALOG_UPDATE_ITEM2", items, findIndex, values)
      return { ...state };

    case CATALOG_INITIAL_GET_UNIT:
      return { ...state, loadingUnits: true };

    case CATALOG_INITIAL_GET_UNIT_RESULT:
      return { ...state, loadingUnits: action.payload || false };

    case CATALOG_ADD_ITEM:
        const { entity : entityName, values : dataValue, procesor : procesorName } = action.payload
        let catalogData = _.clone(state[entityName])

        //validate exist element before create
        let findIndexElement = catalogData.findIndex(x => x.id == dataValue.id)
        if (findIndexElement == -1) {
          //insert new element
          let processorObj = new processors[procesorName]()
          const result = processorObj.getProcessItem(dataValue, dataValue)
          catalogData.push(result)
        }else{
          //update the item data.
          let processorObj = new processors[procesorName]()
          const result = processorObj.getProcessItem(catalogData[findIndexElement], dataValue)
          catalogData[findIndexElement] = { ...result }
        }   
        
        state[entityName] = [...catalogData]
        return { ...state };

    case CATALOG_REMOVE_ITEM:
      const { entity : entityNameToDelete, values : dataValueToDelete } = action.payload
      let itemsCatalogBeforeDelete = _.clone(state[entityNameToDelete]) || []

      //validate exist element before create
      let findIndexElementToDelete = itemsCatalogBeforeDelete.findIndex(x => x.id == dataValueToDelete.id)
      if (findIndexElementToDelete > -1) {
        itemsCatalogBeforeDelete.splice(findIndexElementToDelete, 1);
      }        
      
      state[entityNameToDelete] = [...itemsCatalogBeforeDelete]
      return { ...state };

    case CREATE_ASYNC_REPORTS:
      // Added one report instance Id to the export list
      return {
        ...state,
        asyncReports: [
          ...state.asyncReports,
          action.payload.report
        ]
      };

    case CATALOG_INITIAL_REFRESH:
      return { ...state, loadingReadings: false, refreshClipRequest: {}, incidents: 0 };

    case CATALOG_INITIAL_REFRESH_RESULT:
      return { ...state, loadingReadings: false, refreshClipRequest: {}, getUserInfoRefresh: false, isNavigate: false, incidents: 0 };

    case CATALOG_SYNC_PUSH_NOTIFICATIONS:
      return { ...state };

    // case CATALOG_SYNC_PUSH_NOTIFICATIONS_RESULT:
    //   console.log("CATALOG_SYNC_PUSH_NOTIFICATIONS_RESULT", action.payload)

    //   let processorNotification = new processors.Notification()
    //   let itemsProcessedPush = {}
    //   action.payload.pushNotifications?.forEach(x => {
    //     itemsProcessedPush = processorNotification.execute(x, state).itemsProcessedPush
    //   })
    //   return { ...state, ...itemsProcessedPush }

    case CLEAR_REDUX:
      return ["", "CATALOG_REDUX"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
};
