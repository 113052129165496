import React from 'react';
import { Grid } from '@mui/material';
// Cloud React Components
import { Checkbox } from 'Components';
// Styles
import './list.scss';



const ListView = (props) => {

  const { rows, renderRow, onSelectRow, selectedRows, itemSelector } = props;
  const isSelected = (row) => selectedRows.indexOf(row) !== -1;

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{width:1, m:0}}
      wrap="nowrap"
    >
      {
        rows.map((row, index) => {
          const isItemSelected = isSelected(row);

          return (
            <Grid
              key={index}
              sx={{width: 1}}
              item
            >
              <div className="table-view-list-container">
                {renderRow(row, index)}

                {
                  itemSelector !== false &&
                  <div
                    className={`item-list-selector-container ${isItemSelected ? 'item-list-selected' : ''}`}
                  >
                    <Checkbox
                      value={isItemSelected}
                      id={row?.id + ""}
                      onChange={() => {
                        onSelectRow(row);
                      }}
                    />
                  </div>
                }
              </div>
            </Grid>
          )
        })
      }
    </Grid>
  );
};

export default ListView;