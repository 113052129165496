import {
    MAPS_ETA_LINK_GET,
    MAPS_ETA_LINK_GET_RESPONSE
} from 'Redux/actionTypes';

export const mapsEtaLinkPublicGet = (code, first) => ({
    type: MAPS_ETA_LINK_GET,
    payload: { code, first },
});

export const mapsEtaLinkPublicGetResponse = (response, error) => ({
    type: MAPS_ETA_LINK_GET_RESPONSE,
    payload: { response, error },
});