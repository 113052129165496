import React, { useEffect, useCallback, useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl, useIntl } from 'react-intl';
// Cloud React Components
import { Loading, Button } from 'Components';
import VidFleetVideo from 'Modules/.common/components/VidFleetVideo';
import { isValidJson, checkDeviceData } from 'Core/data/Helpers'
// Utils
import { GetFormatForMoment } from 'Core/data/Helpers';
import moment from 'moment';
import * as momentTz from 'moment-timezone';
// Material UI
import { Grid, Typography } from '@mui/material';
import {
  getFormattedDateByTimeZone
} from 'Core/data/Helpers';
// Actions
import {
  getLastSnapshotResponse,
  getLastSnapshot,
  clearRedux
} from 'Redux/actions';
// Styles
import '../infoBubble.scss';
import '../../../../../components/Map/providers/google/InfoWindow/style.scss'

const Snapshot = (props) => {

  const dispatch = useDispatch();
  const { user, type, data, isEtaLink } = props;
  const { messages, locale } = useIntl()
  const { loadingSnapshot: loading, snapshot: response } = useSelector(state => state.infoBubbleRedux);

  const [snapshot, setSnapshot] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    return () => {
      dispatch(clearRedux("INFO_BUBBLE", { snapshot: null, loadingSnapshot: false }));
    };
  }, []);

  useEffect(() => {
    if (data?.markerDetail?.linkedDevice?.id) {
      setErrors({});
      getSnapShot();
    } else {
      setErrors({ noSnapshot: messages['infoBubble_noSnapshotAvailable'] });
    }
  }, [data]);

  useEffect(() => {
    if (response) {
      if (response.error) {
        setErrors({ snapshot: messages['infoBubble_getSnapshotError'] });
      } else {
        if (typeof response === 'string') {
          try {
            const responseData = JSON.parse(response);
            setSnapshot(responseData);
          } catch (e) {
            console.log("Error while parsing string json: InfoBubble/Snapshot"); // eslint-disable-line no-undef
          }
        } else if (typeof response === 'object') {
          if (response?.pictureID) {
            setSnapshot(response);
          } else {
            setErrors({ noSnapshot: messages['infoBubble_noSnapshotAvailable'] });
          }
        }
      }
    }
  }, [response]);

  const getSnapShot = useCallback(() => {
    try {
      if (isEtaLink) {
        let currentLastSnapshot = data?.markerDetail?.linkedDevice?.lastSnapshot
        if (currentLastSnapshot) {
          dispatch(getLastSnapshotResponse(JSON.stringify(currentLastSnapshot)))
        } else {
          dispatch(getLastSnapshotResponse({}))
        }
        return
      }

      if (data?.markerDetail?.vehicleTrail?.deviceData) {
        if (isValidJson(data?.markerDetail?.vehicleTrail?.deviceData)) {
          const deviceData = checkDeviceData(data?.markerDetail?.vehicleTrail?.deviceData);

          if (deviceData?.url) {
            dispatch(getLastSnapshotResponse(JSON.stringify({ ...deviceData, pictureID: deviceData?.pictureID || deviceData?.clipID})));

            return;
          }
        }
      }

      let unitTime = "";
      let timeZone = data?.markerDetail?.unit?.timeZone?.name;
      let momentDate = "";
      let unixTime = "";
      if (type === "Trail") {
        unixTime = data?.markerDetail?.vehicleTrail?.unitTime;
      } else if (type === "Vehicle") {
        unixTime = data?.markerDetail?.unit?.lastReading?.unitTime;
      }

      if (Number.isInteger(unixTime * 1)) {
        if (unixTime.toString().length > 10) unixTime = (unixTime / 1000);
        momentDate = momentTz.unix(unixTime);
        momentDate = momentDate.tz(timeZone);
      } else if (typeof unixTime === 'string') {
        momentDate = momentTz(unixTime).tz(timeZone);
      }

      unitTime = momentDate?.format();

      dispatch(getLastSnapshot({
        deviceId: data?.markerDetail?.linkedDevice?.id,
        unitTime: unitTime,
      }));
    } catch (e) {
      console.log(e);
    }
  });

  const formatDateUser = useCallback(() => {
    let dateformat = user ? user?.dateformat : 'm/d/Y'
    const momentFormat = GetFormatForMoment(dateformat);
    return `h:mm A, ${momentFormat}`;
  });

  return (
    <div className='info-window-tab-video'>
      {
        loading
          ?
          <Loading full />
          :
          <div>
            {
              (errors && errors.snapshot) ?
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  style={{ padding: 30, height: 250 }}
                >
                  <Grid item>
                    <span style={{ fontSize: 14 }}>{errors.snapshot}</span>
                  </Grid>

                  <Grid item>
                    <Button
                      type="icon"
                      icon="refresh"
                      onClick={() => {
                        setSnapshot({});
                        setErrors({});
                        getSnapShot();
                      }}
                    />
                  </Grid>
                </Grid>
                :
                <div>
                  {
                    (snapshot && snapshot?.pictureID) ?
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item style={{ margin: '0 auto' }}>
                          <div style={{ width: 350, height: 240 }}>
                            <VidFleetVideo
                              messages={messages}
                              mode="picture"
                              pictureId={snapshot?.pictureID}
                              deviceModel={(data?.markerDetail?.linkedDevice?.deviceModelName === "360" ? "360" : "4k")}
                              thumbnailUrl={snapshot?.url}
                            />
                          </div>
                        
                          <div style={{ padding: '8px 0px' }}>
                            <Typography noWrap variant="subtitle2" component="span" style={{ fontWeight: 'normal' }}>
                              {messages['infoBubble_snapshotDateTime']}
                            </Typography>
                            <Typography noWrap variant="subtitle2" component="span" style={{ fontWeight: 'bold' }}>
                              {getFormattedDateByTimeZone(snapshot?.unitTime || data?.markerDetail?.vehicleTrail?.unitTime, data?.markerDetail?.unit?.timeZone?.name, messages, formatDateUser(), true, locale)}
                            </Typography>
                          </div>
                          
                        </Grid>
                      </Grid> :
                      <div>
                        {
                          (errors && errors.noSnapshot) &&
                          <Typography noWrap variant="subtitle1" style={{ fontWeight: 'bold', padding: '15px 15px' }}>
                            {errors.noSnapshot}
                          </Typography>
                        }
                      </div>
                  }
                </div>
            }
          </div>
      }
    </div>
  );
}

export default injectIntl(memo(Snapshot));