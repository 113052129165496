import {
    MAP_GEOFENCES_DELETE_GROUP_OPEN_MODAL,
    MAP_GEOFENCES_DELETE_GEOFENCE_GROUP,
    MAP_GEOFENCES_DELETE_GEOFENCE_GROUP_RESPONSE
} from 'Redux/actionTypes';

export const mapGeofenceGroupOpenDeleteModal = (open, geofenceGroups) => ({
    type: MAP_GEOFENCES_DELETE_GROUP_OPEN_MODAL,
    payload: { open, geofenceGroups },
});

export const mapGeofenceDeleteGeofenceGroup = (ids, newId) => ({
    type: MAP_GEOFENCES_DELETE_GEOFENCE_GROUP,
    payload: { ids, newId },
});

export const mapGeofencesDeleteGeofenceGroupResponse = (data) => ({
    type: MAP_GEOFENCES_DELETE_GEOFENCE_GROUP_RESPONSE,
    payload: { data },
});