import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';

// Actions
import {
  reportsMainGenerateReportProgress
} from 'Redux/actions';

import {
  handleAsyncReport,
  getTimeZone,
  baseReportConditions,
  generateReportInstance,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';
import { ExceptionManager } from "Core/logManager";

function* getSummaryReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
  const { timeZone, sendByEmail, emailsTo, name, delayed, timeframeName, categoryName, dataResultPath } = report;
  const { filterType, filterArray,filterEntity, startRange, endRange, unitsSelected, accumulateData = true, dates } = report.filters
  const baseConditionsQuery = baseReportConditions({
    report,
    name,
    dataResultPath,
    timeZone,
    delayed,
    unitsSelected,
    timeframeName,
    filterType,
    filterArray,
    categoryName,
    startRange,
    endRange,
    dates
  })
  try {
    let payload = {
      conditions: {
        ...baseConditionsQuery,
        sortField: {
          field: sortField || "deviceId",
          order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
      },
      limit: rowsPerPage,
      offset: page
    };
    if (report.export) {
      payload = {
        limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
        offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
        conditions: {
          ...payload.conditions,
          exportOptions: {
            sendByEmail,
            emailsTo: emailsTo,
            culture: report.lang,
            timeZone: getTimeZone(timeZone),
            format: {
              fileFormat: report?.exportType || "json",
              formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
              durationFormat: report?.durationFormat
            },
            folderName: "px-customer-reports",
            filePrefixName: "summaryReport",
            columns: [
              {
                "column": "label",
                "header": filterEntity === 'Unit' ? "Unit" : 'Driver'
              },
              {
                "column": "engineRunningTimeHours",
                "header": "Engine On"
              },
              {
                "column": "engineRunningTimePercentaje",
                "header": "Engine On Utilization"
              },
              {
                "column": "engineOffTimeHours",
                "header": "Engine Off"
              },
              {
                "column": "engineOffTimePercentaje",
                "header": "Engine Off Utilization"
              },
              {
                "column": "totalTravelTimeHours",
                "header": "Travel Time"
              },
              {
                "column": "distance",
                "header": "Distance"
              },
              {
                "column": "totalIdleTimeHours",
                "header": "Idle Time"
              },
              {
                "column": "input1",
                "header": "Input 1 On Time"
              },
              {
                "column": "input2",
                "header": "Input 2 On Time"
              },
              {
                "column": "maxSpeed",
                "header": "Max Speed"
              },
              {
                "column": "averageSpeed",
                "header": "Avg Speed"
              }


            ]
          }
        },
      }
    }
    let data = yield call(
      clientQuery,
      `query getSummaryReport (
        $conditions: ReportConditionsInput
        $limit: Int
        $offset: Int
      ) {
        Report: getSummaryReport(
          conditions: $conditions, 
          limit: $limit, 
          offset: $offset
        ) {
          ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
        `reportInstanceId`
        : `
            items {
              recordInfo {
                  entityId
                  entityLabel
                  tagId
                  tagLabel
                  startDate
                  endDate
                  recordType
                  groupLabel
                  groupIndex
                  groupTotal
                  groupTagColor
              }
              recordDetail {
                label
                startTime
                endTime
                deviceId
                driverId
                engineOn
                engineOff
                travelTime
                distance
                idleTime
                maxSpeed
                maxECUSpeed
                averageSpeed
                averageECUSpeed
                driverName
                deviceLabel
                noData
                isKPH
                inputs{
                  input
                  duration
                  unitTime
                }
                utilization {
                  engineOn
                  engineOff
                }
              }
          }
          total
          limit
          offset
          category
          `}
        }
      }`,
      payload,
      endpoints.GRAPHQL_PX_REPORTING,
    );
    yield handleAsyncReport(report, data, accumulateData);
  } catch (exc) {
    ExceptionManager(exc, 'reports/SummaryReport', 'getSummaryReport');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
    if (!requestFlag) {
        let flag = true
        yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
    } else {
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
    }
  }
}


export default getSummaryReport;
