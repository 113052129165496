import {
  ACCOUNT_CHANGE_PASSWORD_OPEN_MODAL,
  ACCOUNT_CHANGE_PASSWORD_SET_PASSWORD,
  ACCOUNT_CHANGE_PASSWORD_SET_PASSWORD_RESULT,
  CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
  open: false,
  loading: false,
  response: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACCOUNT_CHANGE_PASSWORD_OPEN_MODAL:
      return { ...state, open: action.payload, response: null, loading: false };

    case ACCOUNT_CHANGE_PASSWORD_SET_PASSWORD:
      return { ...state, response: null, loading: true };

    case ACCOUNT_CHANGE_PASSWORD_SET_PASSWORD_RESULT:
      return { ...state, response: action.payload.data, loading: false };

    case CLEAR_REDUX:
      return ["", "ACCOUNTS_CHANGE_PASSWORD"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
}