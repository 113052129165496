import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// controls
import Table, { Column } from "../../table";
//helpers
import {
  formatDatetime,
  getCategoryFilterType,
  getNameVehicle,
  getEntityType,
  useHandleExportReport,
} from "Modules/reports/views/main/components/reportsTab/helper";

//hooks
import { useSidePanelMap, useTableReport } from "Core/hooks";
//actions
import {
  clearRedux,
  reportsMainGenerateEvents,
  reportsMainGenerateReportUpdate,
  reportsMainGenerateReportProgress,
} from "Redux/actions";
import moment from "moment";

const JBSummaryReport = (props) => {
  const { messages, report } = props;
  const dispatch = useDispatch();
  const { loading, data, filters, loadingSummary, timeZone, summary } = report;
  const { filterEntity, filterArray, filterType, startRange, endRange } =
    filters;
  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
  const [unitDriverSelected, setUnitDriverSelected] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
  const [currentNewSortField, setCurrentNewSortField] = useState("deviceId");
  const [currentNewSortOrder, setCurrentNewSortOrder] = useState("asc");
  const [currentId, setCurrentId] = useState(null);

  const exportHandler = useHandleExportReport(report);

  const loadingCreateLandMark = useSelector(
    (state) => state.vehicleLandMarkRedux.loadingCreateLandMark
  );
  const createLandMarkResult = useSelector(
    (state) => state.vehicleLandMarkRedux.createLandMarkResult
  );
  const reportRef = useRef();

  useEffect(() => {
    if (report) {
      const newReport = JSON.parse(JSON.stringify(report));
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);

  useEffect(() => {
    if (!loadingCreateLandMark && createLandMarkResult) {
      dispatch(
        reportsMainGenerateReportUpdate(
          report,
          currentPage,
          currentRowsPerPage,
          currentNewSortField,
          currentNewSortOrder
        )
      );
      dispatch(clearRedux("LANDMARK", { createLandMarkResult: null }));
    }
  }, [loadingCreateLandMark, createLandMarkResult]);

  const idEventsPerDay = (selected, id) => {
    let currentReport = report;
    delete currentReport.summary;
    dispatch(reportsMainGenerateReportProgress(currentReport));
    let events = {
      filters: {
        filterType: getCategoryFilterType(filterEntity),
        filterArray: [currentId],
        page: 1,
        rowsPerPage: 10,
        startRange: moment(selected).startOf("day").utc().toDate(),
        endRange: moment(selected).endOf("day").utc().toDate(),
      },
    };
    dispatch(reportsMainGenerateEvents(report, events));
  };

  const renderExpandableRowSubEvents = (row) => {
    return (
      <div style={{ marginBottom: 10 }}>
        <Table
          loading={loadingSummary}
          data={summary?.items || []}
          totalItems={summary?.total}
          onChangedPage={(page) => {
            let events = {
              filters: {
                filterType: getCategoryFilterType(filterEntity),
                filterArray: [currentId],
                page: page,
                rowsPerPage: 10,
                startRange: moment(startRange).startOf("day").utc(),
                endRange: moment(endRange).endOf("day").utc(),
              },
            };
            dispatch(reportsMainGenerateEvents(report, events));
          }}
          noDataMessage={messages["generatedReport_noEvents"]}
          hideNumberOfRows={true}
          classNameColumns={"report-sub-events-columns"}
          centerPagination
          serverSideTable={true}
        >
          <Column label={messages["reportsMainRT_TimeDate"]}>
            {(element) => (
              <span>
                {formatDatetime(
                  element.dateTime,
                  " hh:mm A  DD/MM/YYYY",
                  timeZone
                )}
              </span>
            )}
          </Column>
          <Column label={messages["reportsMainRT_engineAverageFuelEconomy"]}>
            {(element) => {
              const label = exitstLabel(element.engineAverageFuelEconomy);
              return <span>{label}</span>;
            }}
          </Column>
          <Column label={messages["reportsMainRT_engineTotalFuelUsed"]}>
            {(element) => {
              const label = exitstLabel(element.engineTotalFuelUsed);
              return <span>{label}</span>;
            }}
          </Column>
          <Column label={messages["reportsMainRT_engineTotalHours"]}>
            {(element) => {
              const label = exitstLabel(element.engineTotalHours);
              return <span>{label}</span>;
            }}
          </Column>
          <Column label={messages["reportsMainRT_engineTotalIdleFuel"]}>
            {(element) => {
              const label = exitstLabel(element.engineTotalIdleFuel);
              return <span>{label}</span>;
            }}
          </Column>
          <Column label={messages["reportsMainRT_engineTotalIdleHours"]}>
            {(element) => {
              const label = exitstLabel(element.engineTotalIdleHours);
              return <span>{label}</span>;
            }}
          </Column>
          <Column label={messages["reportsMainRT_hirezTotalFuel"]}>
            {(element) => {
              const label = exitstLabel(element.hirezTotalFuel);
              return <span>{label}</span>;
            }}
          </Column>
          <Column label={messages["reportsMainRT_totalPtoHours"]}>
            {(element) => {
              const label = exitstLabel(element.totalPtoHours);
              return <span>{label}</span>;
            }}
          </Column>
          <Column label={messages["reportsMainRT_totalVehicleHours"]}>
            {(element) => {
              const label = exitstLabel(element.totalVehicleHours);
              return <span>{label}</span>;
            }}
          </Column>

        </Table>
      </div>
    );
  };

  const exitstLabel = (label) => {
    if (!label) {
      return "--";
    }

    return label;
  };

  const onChangeExpandable = (selected) => {
    if(!selected.noData){
      let selectedId = (filterEntity === "Unit" ? selected.deviceId : selected.driverId) || selected.id;
      setCurrentId(selectedId);
    }
  };

  let parametersTableSummary = useTableReport({
    data: [],
    report,
    messages,
    hideHeader: true,
    hiddenPagination: true,
    renderExpandableRow: renderExpandableRowSubEvents,
    showExpandable: true,
  });

  const renderExpandableRow = (row) => {
    const summaryParameters = {
      ...parametersTableSummary,
      data: row?.dates,
      onChangeSelector: null,
      placeholderSelector: null,
      hideSelectorUnitsDriver: true,
      classNameColumns: "table-report-main-sub-table",
      idRenderExpandableRow: (event) => {
        idEventsPerDay(event, row?.id);
      },
      noData: row.noData
    };
    return (
      <Table {...summaryParameters}>
        <Column label="day" field="label">
          {(element) => {
            return (
              <span>
                <strong>{formatDatetime(element, "dddd, LL")}</strong>
              </span>
            );
          }}
        </Column>
        <Column
          label={messages["generatedReport_totalIdleTime"]}
          field="label"
        ></Column>
      </Table>
    );
  };

  let parametersTable = useTableReport({
    data: data.items,
    report,
    messages,
    hideHeader: true,
    renderExpandableRow,
    onChangeExpandable,
    propertyToShowExpandable: "id",
    classNameColumns: "table-report-main-expandible",
  });

  return (
    <Fragment>
      <Table {...parametersTable} {...exportHandler}>
        <Column noSorting>
          {(element) => {
            const type = getEntityType(report, messages);
            return (
              <span>
                <strong>{type}: </strong>
                {`${element.idLabel || ""}`}
              </span>
            );
          }}
        </Column>
        <Column noSorting>
          {() => {
            const localTimeStart = moment.utc(startRange).local().format();
            const localTimeEnd = moment.utc(endRange).local().format();
            return (<div>
                      <span>
                        <span>
                          <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                        </span>
                      </span>
            </div>);
          }}
        </Column>
      </Table>
      <SideMapComponent hiddenButton hiddenSearchMap />
    </Fragment>
  );
};

export default JBSummaryReport;
