import {
    TAG_PICKER_SEARCH_TAGS,
    TAG_PICKER_SEARCH_TAGS_RESPONSE
} from 'Redux/actionTypes';

export const tagPickerSearchTags = (data) => ({
    type: TAG_PICKER_SEARCH_TAGS,
    payload: { data },
});

export const tagPickerSearchTagsResponse = (response) => ({
    type: TAG_PICKER_SEARCH_TAGS_RESPONSE,
    payload: { response },
});