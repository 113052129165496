import {
	MODAL_ENABLE_STARTER_OPEN,
	MODAL_ENABLE_STARTER_CLOSE,
	VECHICLE_STATUS_SAVE_BULK_CHANGE_STARTER,
	VECHICLE_STATUS_SAVE_BULK_CHANGE_STARTER_PROGRESS,
	VECHICLE_STATUS_SAVE_BULK_CHANGE_STARTER_RESULT,
	SHOW_HIDE_ENABLE_DISABLE_STARTER_MODAL,
} from 'Redux/actionTypes';


export const modalEnableStarterOpen = (data) => ({
	type: MODAL_ENABLE_STARTER_OPEN,
	payload: data,
});

export const modalEnableStarterClose = () => ({
	type: MODAL_ENABLE_STARTER_CLOSE,
	//payload: data,
});

export const saveVehicleStatusSaveBulkChangeStarter = (data) => ({
	type: VECHICLE_STATUS_SAVE_BULK_CHANGE_STARTER,
	payload: data,
});

export const saveVehicleStatusSaveBulkChangeStarterResult = (data) => {
	return {
		type: VECHICLE_STATUS_SAVE_BULK_CHANGE_STARTER_RESULT,
		payload: { data },
	};
};

export const saveVehicleStatusSaveBulkChangeStarterProgress = (data) => ({
	type: VECHICLE_STATUS_SAVE_BULK_CHANGE_STARTER_PROGRESS,
	payload: { data },
});

export const showHideEnableDisableStarterModal = (data) => {
	return {
		type: SHOW_HIDE_ENABLE_DISABLE_STARTER_MODAL,
		payload: { data },
	};
};
