import {
    MAP_GEOFENCES_EDIT_CREATE_OPEN_MODAL,
    MAP_GEOFENCES_GET_GEOFENCE,
    MAP_GEOFENCES_GET_GEOFENCE_RESPONSE,
    MAP_GEOFENCES_EDIT_CREATE_GEOFENCE,
    MAP_GEOFENCES_EDIT_CREATE_GEOFENCE_RESPONSE
} from 'Redux/actionTypes';

export const mapGeofencesOpenEditCreateModal = (open, id) => ({
    type: MAP_GEOFENCES_EDIT_CREATE_OPEN_MODAL,
    payload: { open, id },
});

export const mapGeofencesGetGeofence = (id) => ({
    type: MAP_GEOFENCES_GET_GEOFENCE,
    payload: { id },
});

export const mapGeofencesGetGeofenceResponse = (data) => ({
    type: MAP_GEOFENCES_GET_GEOFENCE_RESPONSE,
    payload: { data },
});

export const mapGeofencesEditCreateGeofence = (geofence) => ({
    type: MAP_GEOFENCES_EDIT_CREATE_GEOFENCE,
    payload: { geofence },
});

export const mapGeofencesEditCreateGeofenceResponse = (data) => ({
    type: MAP_GEOFENCES_EDIT_CREATE_GEOFENCE_RESPONSE,
    payload: { data },
});