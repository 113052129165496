export default {
  "no_notifications_message":"No tienes notificaciones",
  "user_notifications_title":"Alertas",
  "user_alerts_latest_title":"Últimas alertas",
  "user_alerts_marked_title":"Marcar todo como leido",
  "user_alerts_low_title":"Bajo",
  "user_alerts_medium_title":"Medio",
  "user_alerts_high_title":"Alto",
  "user_alerts_today":"Hoy",
  "user_alerts_yesterday":"Ayer",
  "user_alerts_view_all":"Ver todo"
}