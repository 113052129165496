//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { NotificationManager } from 'Components/Notifications';
import {
  /*calculateWeighted,
  updateDriverGradeColor,
  getNewGrades,
  getNewPoints*/
  orderInfoDailyBehavior
} from 'Modules/behavior/.globals/helpers'
import moment from 'moment';
//Action Types
import {
  GET_DAILY_BEHAVIOR,
  SET_BEHAVIOR_POINTS,
  GET_BEHAVIOR_TRENDS,
  GET_POINTS_BEHAVIOR,
  CREATE_NEW_CUSTOM_SPEED,
  DELETE_CUSTOM_SPEED,
  EXPORT_DAILY_BEHAVIOR
} from 'Redux/actionTypes';

//Actions
/**
 * updatePointsBehavior,
  updateGradesBehavior,
 */
import {
  getDailyBehaviorResult,
  createCustomSpeedResult,
  getBehaviorTrendsResult,
  getBehaviorTrendsChartInfo,
  getPointsBehaviorResult,
  deleteCustomSpeedResult,
  setBehaviorPointsResult,
  exportDailyBehaviorResult
} from 'Redux/actions';

//Function

function* getDailyBehaviors({ payload }) {
  const { data } = payload;
  const { startDate, endDate, id, limit, offset, filterBy, orderBy, order, risk, tags, option, scores } = data;
  try {
    let tagsIds = tags?.map((t) => t.id)
    let data = yield call(
      clientQuery,
      `query getDailyBehavior ($startDate: AWSDate, $endDate: AWSDate, $filterBy: DailyBehaviorFilter, $ids: [Int], $limit: Int, $offset: Int, $orderBy: String, $order: String,$tagsIds: [Int],$risk: enumBehaviorRisk, $option: String, $scores: [Int]) {
        getDailyBehavior(
            startDate: $startDate, 
            endDate: $endDate, 
            filterBy:  $filterBy, 
            ids: $ids, 
            limit: $limit, 
            offset: $offset
            orderBy: $orderBy
            order: $order
            tagsIds: $tagsIds
            risk: $risk
            option: $option
            scores: $scores
        ) {
            items {
                entity{
                    name
                } 
                name
                metrics {
                    metric 
                    metricValue
                    events
                }
                safetyEvents
                score
            }
            totalSafetyEvents
            total
        }
      }`,
      {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        ids: id ? [id] : null,
        filterBy,
        limit,
        offset,
        orderBy,
        order,
        tagsIds: tagsIds?.length > 0 ? tagsIds : null,
        risk: (risk == 'All') ? null : risk,
        option,
        scores
      },
      endpoints.GRAPHQL_GENERAL,
    );

    if (data && data.getDailyBehavior) {
      const infoDailyBehavior = orderInfoDailyBehavior(data.getDailyBehavior);
      yield put(getDailyBehaviorResult(infoDailyBehavior || []));
    } else throw 'There are not Behaviors';
  } catch (e) {
    yield put(getDailyBehaviorResult({ error: true }));
  }
}

function* setBehaviors({ payload }) {
  const { data } = payload;
  const {
    Id,
    metric,
    points,
    metricValue
  } = data;
  try {
    let data = yield call(
      clientMutation,
      `mutation updateBehaviorPoints (
              $Id: String
              $metric: Metric
              $metricValue: Int
              $points: Int
          ) {
          updateBehaviorPoints(
              Id: $Id
              metric: $metric
              metricValue: $metricValue
              points: $points
          ) {
            isNew
            Id
            metric
            metricValue
          }
      }`,
      {
        Id,
        metric,
        points,
        metricValue
      },
      endpoints.GRAPHQL_GENERAL,
    );
    if (data && data.updateBehaviorPoints) {
      const response = {
        ...data.updateBehaviorPoints,
        update: true
      };
      yield put(setBehaviorPointsResult(response));
      /*if (saveGrades) {
        yield put(updateGradesBehavior(grades));
        let newDrivers = updateDriverGradeColor(drivers, points, grades)
        yield put(getDailyBehaviorResult(newDrivers || []));
      } else {
        yield put(updatePointsBehavior(points));
      }*/
    }
  } catch (e) {
    ExceptionManager(e, 'modules/maintenance/views/redux/saga', 'getMaintenanceServiceItems');
    yield put(setBehaviorPointsResult(false));
    NotificationManager.error('Error', `Error Fetching Behaviors`, 3000, null, null, 'error');
  }
}

function* getPointsBehavior() {
  try {
    let data = yield call(
      clientQuery,
      `query Points {
        getPoints {
          points {
            Id
            metric
            metricValue
            points
          }
          totalAllowedPoints
        }
      }
      `,
      {},
      endpoints.GRAPHQL_GENERAL,
    );
    if (data && data.getPoints) {
      let idling = [];
      let parsePoints = {};
      // Handle points to be shown in the score tab
      data.getPoints.points.forEach((point) => {
        if (point.metric) {
          if (point.metric == "SpeedOver" && (point.metricValue != 75 && point.metricValue != 80 && point.metricValue != 85)) {
            if (parsePoints[`CustomSpeedOver`]) {
              parsePoints[`CustomSpeedOver`].push(point);
            } else {
              parsePoints[`CustomSpeedOver`] = [point];
            }
          } else if (point.metric === "SpeedOverPosted" || point.metric == "SpeedOver") {
            parsePoints[`${point.metric}${point.metricValue}`] = point;
          } else {
            const tempObj = {
              Id: point.Id,
              points: point?.points || 0,
              metricValue: point.metricValue || 0,
              metric: point?.metric
            };
            parsePoints[point.metric] = tempObj;
          }
        }
      });
      parsePoints = { ...parsePoints, idling };
      yield put(getPointsBehaviorResult(parsePoints));
    } else {
      throw 'Error Fetching Points Behaviors';
    }
  } catch (e) {
    ExceptionManager(e, 'modules/maintenance/views/redux/saga', 'getPointsBehavior');
    //yield put(getDailyBehaviorResult({}));
    NotificationManager.error('Error', `Error Fetching Points Behaviors`, 3000, null, null, 'error');
  }
}
function* getBehaviorTrends({ payload }) {
  const { data } = payload;
  const { startDate, endDate, id, canvasRef, filterBy, format, tags, option,risk,scores } = data
  const ctx = canvasRef.getContext("2d");
  const defaultFormat = "YYYY-MM-DD";
  let gradientStroke = ctx.createLinearGradient(0, 0, 0, 400);
  gradientStroke.addColorStop(0, '#fd5433');
  gradientStroke.addColorStop(.3, '#F8AB52');
  gradientStroke.addColorStop(.6, '#D3D140');
  gradientStroke.addColorStop(.8, '#9CD141');
  gradientStroke.addColorStop(1, '#43BF60');

  let dataChart = {
    labels: [""],
    datasets: [{
      label: '',
      fill: false,
      lineTension: 0,
      borderColor: gradientStroke,
      backgroundColor: gradientStroke,
      pointBorderColor: gradientStroke,
      pointBorderWidth: 1,
      pointHoverRadius: 8,
      fontColor: gradientStroke,
      pointHoverBorderWidth: 2,
      pointRadius: 7,
      pointHitRadius: 0,
      data: [{ datasets: [], labels: [] }]
    }]
  };
  let tagsIds = tags?.map((t) => t.id)

  try {
    let data = yield call(
      clientQuery,
      `query getBehaviorTrends($startDate: AWSDate!, $endDate: AWSDate!, $filterBy: DailyBehaviorFilter, $ids: [Int],$tagsIds: [Int],$risk: enumBehaviorRisk, $option: String, $scores: [Int]) {
        getBehaviorTrends(endDate: $endDate, startDate: $startDate, filterBy: $filterBy , ids: $ids,tagsIds: $tagsIds,risk: $risk,option: $option,scores: $scores){
          entity {
            name
          }
          entityIds
          range
          driverEvents {
              name
              date
              metrics {
                  metric
                  metricValue
                  events
              }
              score
          }
        }
      }
      `,
      {
        startDate: moment(startDate).format(defaultFormat),
        endDate: moment(endDate).format(defaultFormat),
        ids: id ? [id] : null,
        filterBy,
        tagsIds: tagsIds.length > 0 ? tagsIds : null,
        option,
        risk: (risk == 'All') ? null : risk,
        scores
      },
      endpoints.GRAPHQL_GENERAL,
    );

    if (data && data.getBehaviorTrends) {
      const { getBehaviorTrends } = data;
      let labels = [];
      let points = [];
      let realPoints = [];
      const range = getBehaviorTrends.range;
      // text to by shown in the title of the point
      const nameTitle = range === "Monthly" ? "Month" :
        range === "Weekly" ? "Week" :
          '';
      // Behavior Trends configure the chart points
      getBehaviorTrends.driverEvents.forEach((event) => {
        let { date, score } = event;
        score = (score && !isNaN(score) ? parseFloat(score).toFixed(2) : score);
        // set the format configured by the user in settings
        const formatUpperCase = format?.toString()?.toUpperCase();
        const dateFormat = moment(date).format(formatUpperCase || defaultFormat);
        labels.push(`${nameTitle}  ${dateFormat}`);
        realPoints.push(score);
        let newScore = (score > 100 ? 100 : score);
        points.push(newScore);
      });

      dataChart.labels = labels;
      dataChart.datasets[0].data = points;
      dataChart.points = realPoints;
      yield put(getBehaviorTrendsResult(getBehaviorTrends))
      yield put(getBehaviorTrendsChartInfo(dataChart))
    } else throw 'There are not Behavior Trends';
  } catch (e) {
    yield put(getBehaviorTrendsResult([]))
    yield put(getBehaviorTrendsChartInfo([]))
    ExceptionManager(e, 'modules/behavior/views/redux/saga', 'getBehaviorTrends');
  }
}
function* createCustomSpeed({ payload }) {
  const { data } = payload;
  const { metricValue, points } = data;
  try {
    let data = yield call(
      clientMutation,
      `mutation createCustomSpeed (
        $metricValue:Int,
        $points: Int
      ) {
          createCustomSpeed (metricValue: $metricValue, points: $points)
      }
      `,
      {
        metricValue,
        points
      },
      endpoints.GRAPHQL_GENERAL,
    );
    if (data && data.createCustomSpeed) {
      yield put(createCustomSpeedResult(data.createCustomSpeed));
    } else {
      throw 'Error Creating Custom Speed';
    }
  } catch (e) {
    ExceptionManager(e, 'modules/maintenance/views/redux/saga', 'getPointsBehavior');
    yield put(createCustomSpeedResult(false));
    const errorMessageGraphql = 'Graphql error: ';
    const error = e.error || `Error Creating Custom Speed`;
    const newError = error.replace(new RegExp(errorMessageGraphql, "gi"), '');
    NotificationManager.error(newError, 'Error', 3000, null, null, 'error');
  }
}


function* exportGetDailyBehaviors({ payload }) {
  const { data } = payload;
  const { startDate, endDate, id, filterBy, orderBy, order, format } = data;
  try {
    let data = yield call(
      clientQuery,
      `query exportGetDailyBehavior ($startDate: AWSDate, $endDate: AWSDate, $filterBy: DailyBehaviorFilter, $ids: [Int], $orderBy: String, $order: String, $format: ExportFormatInput!) {
        exportGetDailyBehavior(
          startDate: $startDate, 
          endDate: $endDate, 
          filterBy:  $filterBy, 
          ids: $ids,
          orderBy: $orderBy
          order: $order
          format: $format
        )
      }`,
      {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        ids: id ? [id] : null,
        filterBy,
        orderBy,
        order,
        format
      },
      endpoints.GRAPHQL_GENERAL,
    );
    if (data && data.exportGetDailyBehavior) {
      yield put(exportDailyBehaviorResult(data.exportGetDailyBehavior));
    } else throw 'There are not Behaviors';
  } catch (e) {
    ExceptionManager(e, 'modules/behavior/views/redux/saga', 'exportGetDailyBehavior');
    yield put(getDailyBehaviorResult([]));
    NotificationManager.error('Error', `Error Fetching Behaviors`, 3000, null, null, 'error');
  }
}

function* deleteCustomSpeed({ payload }) {
  const { data } = payload;
  const { Id } = data;
  try {
    let data = yield call(
      clientMutation,
      `
        mutation deleteCustomSpeed ($Id: String!) {
          deleteCustomSpeed(
              Id: $Id
          )
        }
      `,
      {
        Id
      },
      endpoints.GRAPHQL_GENERAL,
    );
    if (data && data.deleteCustomSpeed) {
      yield put(deleteCustomSpeedResult(true));
    } else {
      throw 'Error Deleting Custom Speed';
    }
  } catch (e) {
    ExceptionManager(e, 'modules/maintenance/views/redux/saga', 'getPointsBehavior');
    yield put(deleteCustomSpeedResult(false));
    NotificationManager.error('Error', `Error Deleting Custom Speed`, 3000, null, null, 'error');
  }
}

function* watchDeleteCustomSpeed() {
  yield takeEvery(DELETE_CUSTOM_SPEED, deleteCustomSpeed);
}

function* watchCreateCustomSpeed() {
  yield takeEvery(CREATE_NEW_CUSTOM_SPEED, createCustomSpeed);
}


function* watchExportDailyBehavior() {
  yield takeEvery(EXPORT_DAILY_BEHAVIOR, exportGetDailyBehaviors);
}
function* watchCreateNewServiceItem() {
  yield takeEvery(GET_DAILY_BEHAVIOR, getDailyBehaviors);
}

function* watchSetBehaviorsPointsGrades() {
  yield takeEvery(SET_BEHAVIOR_POINTS, setBehaviors);
}

function* watchGetBehaviorTrends() {
  yield takeEvery(GET_BEHAVIOR_TRENDS, getBehaviorTrends);
}

function* watchGetPointsBehavior() {
  yield takeEvery(GET_POINTS_BEHAVIOR, getPointsBehavior);
}


//Saga Export
export default function* rootSaga() {
  yield all([
    fork(watchCreateNewServiceItem),
    fork(watchSetBehaviorsPointsGrades),
    fork(watchExportDailyBehavior),
    fork(watchGetBehaviorTrends),
    fork(watchGetPointsBehavior),
    fork(watchCreateCustomSpeed),
    fork(watchDeleteCustomSpeed)
  ]);
}
