import React, { useState, useEffect,memo } from 'react'
import { useIntl } from "react-intl";
import CustomTime from './components/CustomTime'
import { Grid } from '@mui/material';
import {ToggleSwitch } from 'Components';
import moment from "moment";

import './index.scss'

//DATA
import INITIAL_DAYS from 'Modules/alerts/common/days.json';

let dataInitial;

const CustomTimeRange = ({ validateCustomTimeRanges, setStateCustomTimeRange,errorMessage,scheduleTimeRanges, isSimple = false, isDisabled = false ,activeDays = {}}) => {

  const INIT_STATE = {
    schedule: null
  }



  const [configuration, setConfiguration] = useState(INIT_STATE);
  const { schedule } = configuration;
  const { messages } = useIntl()

  const {actDays, setActDays} = activeDays

  const [days, setDays] = useState([])
  const [applyMondays, setApplyMondays] = useState(false)
  const [firstDay, setFirstDay] = useState("Monday")

  const dayInLabelToDayKey = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7
  };

  const convertDateToFormattedString = (days,date24h) => {
    const currentDateTime = moment().day(dayInLabelToDayKey[days]);
    const dateTimeWithGivenTime = moment(`${currentDateTime.format('YYYY-MM-DD')}T${date24h}`).toDate();
    return dateTimeWithGivenTime;
  }

  function compareDays(a, b) {
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday","Sunday"];
    return days.indexOf(a.day) - days.indexOf(b.day);
  }


  useEffect(() => {
    if (days.length == 0) {}

    let initialDays;

      if(scheduleTimeRanges?.isDataInitial){
        scheduleTimeRanges = scheduleTimeRanges.days;
        dataInitial = scheduleTimeRanges
      }

      if(isDisabled){
        initialDays = INITIAL_DAYS.filter(day =>  dataInitial.some(dayInitial => day.day.toLowerCase() === dayInitial.day))
      }else{
        initialDays = INITIAL_DAYS;
      }

      let daysToShow = [];

      initialDays.forEach((day) => {
        daysToShow.push({ ...day, timeFrom: moment().day(dayInLabelToDayKey[day.key]).set("hour", 0).set("minute", 0).set("second",0).toDate(),
                                    timeTo: moment().day(dayInLabelToDayKey[day.key]).set("hour", 23).set("minute", 59).set("second",0).toDate() });
      });


      if(scheduleTimeRanges){

        scheduleTimeRanges.forEach(item => {
          let updateDay = daysToShow.findIndex(day => (day.key === item.day) && (day.checked === false) )
          const timeFrom = convertDateToFormattedString(item.day,item.starTime)
          const timeTo = convertDateToFormattedString(item.day,item.endTime)

          if(daysToShow[updateDay] === undefined){
            daysToShow.push({...daysToShow[daysToShow.findIndex(day => (day.key === item. day) && (day.checked === true) )], timeFrom: timeFrom, timeTo:timeTo,checked: true, parentDay: false})
          }else{
            daysToShow[updateDay] = {...daysToShow[updateDay], timeFrom: timeFrom, timeTo:timeTo,checked: true}
          }

        })
      }

      if(actDays?.length > 0){
        daysToShow.map(day => {
          day.checked = actDays.some( (items)=> items.day === day.key );
          if(day.checked){
            const nameDay =  actDays.find( (items)=> items.day === day.key)
            day.timeFrom = convertDateToFormattedString(nameDay.day, nameDay.starTime);
            day.timeTo = convertDateToFormattedString(nameDay.day, nameDay.endTime);
          }
          return day;
        })
        setActDays([])
      }
      

      const dayOrder = daysToShow.sort(compareDays)

      setDays(dayOrder);
      setFirstDay(dayOrder[0]?.day);
  }, []);

  useEffect(() => {
    validateCustomTimeRanges(false)
    setStateCustomTimeRange(days)
  }, [days])

  useEffect(() => {
    if (!schedule || !schedule.frequency) {
      setConfiguration({ ...configuration, schedule: { ...schedule, frequency: "Everyday", occurrences: "EveryEvent", occurrencesLimitPerDay: 0, afterConsecutiveEvents: 0 } })
    }
  }, [schedule]);

  const changeDay = (day, key, valueObj, index) => {

    let newDay = {};

    if(key === 'checked' && isDisabled){
      const dayName = day?.day.toLowerCase();
      const result = dataInitial.find(item => item.day === dayName)
      if(!result)return;
    }
      

    if ((key === 'checked' && !valueObj)) {
      newDay = { ...day, checked: valueObj, startedEnable: null, startedDisable: null,};
    } else {
      newDay = { ...day, [key]: valueObj };
    }

    const head = days.slice(0, Math.max(0, index));
    const tail = days.slice(Math.min(index + 1, days.length));
    const updatedDays = [...head, newDay, ...tail];

    setDays(updatedDays);
    return updatedDays
  };

  const validateRange = (day, posc) => {

    const validateOverlapping = () => {
      const otherDays = days.filter((x, i) => ((i !== posc) && (x.day === day.day)) && (x.checked && day.checked));

      for (const x of otherDays) {
        if (day.timeFrom <= x.timeFrom && x.timeFrom <= day.timeTo) {
          return true; // b starts in a
        } else if (day.timeFrom <= x.timeTo && x.timeTo <= day.timeTo) {
          return true; // b ends in a
        } else if (x.timeFrom < day.timeFrom && day.timeTo < x.timeTo) {
          return true; // a in b
        }
      }

      return false;
    }

    const shouldValidateOverlapping = (day) => {
      const { checked, timeFrom, timeTo } = day;
      const completedRange = timeFrom && timeTo;

      return !!(checked && completedRange);
    };
    const resp = shouldValidateOverlapping(day) ? !validateOverlapping() : true
    return resp;
  };


  const validateDay = (day) => {
    const { checked, timeFrom, timeTo } = day;
    return checked ? timeFrom < timeTo : true;
  };

  const addDayCallback = (newDay) => {
    const indexDay = days.indexOf(newDay) + 1;
    const daysCopy = [...days];
    const resetDay = (day) => (
      {
        ...day,
        timeFrom: moment().day(dayInLabelToDayKey[day.key]).set("hour", 0).set("minute", 0).set("second",0).toDate(),
        timeTo: moment().day(dayInLabelToDayKey[day.key]).set("hour", 23).set("minute", 59).set("second",0).toDate(),
        parentDay: false,
      });
    daysCopy.splice(indexDay, 0, resetDay(newDay));

    setDays(daysCopy);
    return daysCopy
  };

  const removeDayCallback = (dayToRemove) => {
    const indexDay = days.findIndex(day => day === dayToRemove);
    const daysCopy = [...days];
    daysCopy.splice(indexDay, 1);

    setDays(daysCopy);
    return daysCopy
};

  const applyMondaysTime = (newDays = days) => {
    const mondaySetup = newDays.filter((dayObject) => dayObject.key === days.sort(compareDays)[0].day.toLowerCase());

    if(isDisabled){
      const isNotChecked = days.filter(days => !days.checked)
      const newConfiguredDays = days.filter(days => days.checked).flatMap(day => {
        return mondaySetup.map(mondayDay => {
          const { timeFrom, timeTo, parentDay, checked } = mondayDay;
          return { ...day, timeFrom, timeTo, parentDay, checked };
        });
      });

      newConfiguredDays.push(...isNotChecked);
      setDays(newConfiguredDays.sort(compareDays));
      return
    }

    const newConfiguredDays = INITIAL_DAYS.flatMap(day => {
      return mondaySetup.map(mondayDay => {
        const { timeFrom, timeTo, parentDay, checked } = mondayDay;
        return { ...day, timeFrom, timeTo, parentDay, checked };
      });
    });

    setDays(newConfiguredDays);
  };

  const errorInRangers = [];
  const setValidateRange = validateCustomTimeRanges(false,false,true);

  useEffect(() => {
    setValidateRange( errorInRangers.some(item => item === false) )
  }, [errorInRangers])

  const dayNames = messages[`${firstDay?.toLowerCase()}`]

  return (
    <>
      <Grid>
        {validateCustomTimeRanges(false,true) && <span className="schedule-report-error">{errorMessage}</span>}
      </Grid>
      <Grid item className={schedule?.frequency !== "Custom" ? "disabledArea" : ""} style={{ paddingTop: 10 }}>
        <ToggleSwitch
          id="applyAllsMonday"
          small
          blueToggle
          optionLabels={["", ""]}
          label={`${messages['reportsAdvanceTimeRanges1']} ${dayNames} ${messages['reportsAdvanceTimeRanges2']}`}
          checked={applyMondays}
          onChange={(event) => {
            setApplyMondays(event)
            if(event) applyMondaysTime()
          }}
        />
      </Grid>
      <Grid item className={schedule?.frequency !== "Custom" ? "disabledArea" : ""}>
        {
          days.map((day, key) => {
            errorInRangers.push(...[validateRange(day, key), validateDay(day)]);
            return (
              <CustomTime
                isSimple={isSimple} 
                validateCustomTimeRanges={validateCustomTimeRanges}
                key={key}
                value={key}
                day={day}
                checkOneDay={(e) => changeDay(days[e], "checked", !days[e].checked, e)}
                handleTimeChange={(newValue, action) => {
                  let newDays = changeDay(days[key], action, newValue, key)
                  if (applyMondays) applyMondaysTime(newDays)
                }}
                addDayCallback={() => {
                  if(day.checked){
                    let newDays = addDayCallback(day)
                    if (applyMondays) applyMondaysTime(newDays)
                  }
                }}
                removeDayCallback={() => {
                  let newDays = removeDayCallback(day)
                  if (applyMondays) applyMondaysTime(newDays)
                }}
                validRange={validateRange(day, key)}
                overlappingDatesErrorMessage={messages['alertsNewAlert_OverlappingDatesErrorMessage']}
                isValidDay={validateDay(day)}
                invalidDayMessage={messages['alertsNewAlert_HoursCompletationValidation']}
                disableAddChange={applyMondays && (day.day !== firstDay)}
              />
            )
          })
        }
      </Grid>
    </>
  )
}

export default memo(CustomTimeRange)
