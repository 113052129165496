import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import PanelLabel from "./PanelLabel";
import SliderList from "./SliderList";
import { EVERY30MINUTES } from "./helpers/constants";
import AreaMenu, { Area, Menu } from "Components/AreaMenu";
import { Box } from "@mui/material";
import { Input, Select, Button } from 'Components';
import moment from "moment";
import { NotificationManager } from 'Components/Notifications';

export default function SliderRangeTime({
  date,
  selectedTime,
  onApply,
}) {
  const { messages } = useIntl();
  const [timer, setTimer] = useState()
  const areaMenuRef = useRef();

  useEffect(() => {
    const destructureDate = new Date(selectedTime)
    const dayTime = moment(selectedTime).format('a')
    const hours = moment(selectedTime).format('a') === 'pm' && destructureDate.getHours() > 12 ? Number(destructureDate.getHours()) - 12 : destructureDate.getHours()
    setTimer({
      hour: hours < 10 ? `0${hours}` : hours,
      min: destructureDate.getMinutes() < 10 ? `0${destructureDate.getMinutes()}` : destructureDate.getMinutes(),
      sec: destructureDate.getSeconds() < 10 ? `0${destructureDate.getSeconds()}` : destructureDate.getSeconds(),
      ampm: dayTime
    })
  }, [date, selectedTime])


  const setApply = () => {
    if (!timer.hour || !timer.min || !timer.sec) {
      return NotificationManager.warning(
        messages["videoMainView_noValidTime"],
        messages["videoClipRequest_Alert"],
        4000,
        null,
        null,
        "info",
        null
      );
    }

    let hour = Number(timer.hour)
    if (timer.ampm === 'pm' && hour < 12) {
      hour = hour + 12
    }

    let date = new Date(selectedTime);
    date.setHours(hour)
    date.setMinutes(Number(timer.min))
    date.setSeconds(Number(timer.sec))
    onApply(new Date(date))
  }


  return (
    <>
      {date ? (

        <AreaMenu className='datepicker_container--areamenu' id={"ssss"} ref={areaMenuRef}>
          <Area>
            <PanelLabel
              message={messages["videoClipRequest_time"]}
              value={date}
              row
            />
          </Area>
          <Menu style={{ width: "475px" }}>
            <Box style={{ display: 'flex', padding: '10px' }}>
              <Box mr={4} style={{ width: '20%' }}>
                <Input
                  label={messages['hour']}
                  onlyPositive
                  preventOnChange
                  value={timer?.hour}
                  onChange={(val) => {
                    if (val.length > 2) return
                    setTimer({ ...timer, hour: val })
                  }}
                  style={{ width: 15 }}
                  type='number'

                  min={0}
                  max={12}
                  className="inputDateTime"
                />

              </Box>
              <Box style={{ display: 'flex', alignItems: 'center', margin: '30px 5px 0px -5px' }}>
                :
              </Box>
              <Box mr={4} style={{ width: '20%' }}>
                <Input
                  label={messages['mins']}
                  onlyPositive
                  preventOnChange
                  value={timer?.min}
                  onChange={(val) => {
                    if (val.length > 2) return
                    setTimer({ ...timer, min: val })
                  }}
                  style={{ width: 15 }}
                  type='number'
                  min={0}
                  max={59}
                  className="inputDateTime"
                />

              </Box>
              <Box style={{ display: 'flex', alignItems: 'center', margin: '30px 5px 0px -5px' }}>
                :
              </Box>
              <Box mr={4} style={{ width: '20%' }}>
                <Input
                  label={messages['seconds']}
                  onlyPositive
                  preventOnChange
                  value={timer?.sec}
                  onChange={(val) => {
                    if (val.length > 2) return
                    setTimer({ ...timer, sec: val })
                  }}
                  style={{ width: 15 }}
                  type='number'
                  min={0}
                  max={59}
                  className="inputDateTime"
                />
              </Box>
              <Box mr={1} style={{ width: '20%' }}>
                <Select
                  id="type"
                  name="type"
                  value={{ value: timer?.ampm, label: timer?.ampm }}
                  label="am/pm"
                  placeholder="am/pm"
                  onChange={(value) => {
                    setTimer({ ...timer, ampm: value.value })
                  }}
                  options={[{ value: 'am', label: 'am' }, { value: 'pm', label: 'pm' }]}
                  textField="label"
                  valueField="value"
                />
              </Box>
              <Box style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "30px"
              }}>
                <Button
                  className="btn-blue"
                  onClick={() => setApply()}
                >
                  {messages['set']}
                </Button>
              </Box>
            </Box>
          </Menu>
        </AreaMenu>
      ) : (
        <PanelLabel
          message={messages["no_data"]}
          value='-----'
        />
      )}
    </>
  );
}
