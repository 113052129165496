import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

const INIT_STATE = {
    scheduledDriverTaskId: null,
    open: false,

    driverId: null,

    //driverTasks
    loadingDriverTasks: false,
    errorDriverTasks: null,
    driverTasks: [],

    //scheduledDriverTask
    loadingScheduledDriverTask: false,
    scheduledDriverTask: null,
    errorScheduledDriverTask: null,

    //alertSubscriberResponse
    alertSubscriberResponse: null,
    loadingAlertSubscriberResponse: false,
    errorAlertSubscriberResponse: null,

    //listDriverScheduledTasks
    listDriverScheduledTasks: [],
    errorListDriverScheduledTasks: null,
    loadingListDriverScheduledTasks: false,

    //listTotalDriversScheduledTasks
    listTotalDriversScheduledTasks: [],
    loadingListTotalDriversScheduledTasks: false,

    //scheduleDriverTaskResult
    scheduleDriverTaskResult: null,
    errorScheduleDriverTaskResult: null,
    loadingCreateUpdateDriverScheduledTask: false,

    //driverTasksScheduled: []
    driverTasksScheduled: [],
    loadinDriverTasksSchedule: false,
    errorDriverTasksScheduled: null,
};

const route = "modules/maintenance/modals/scheduleDriverTask/redux/slice";

export const maintenanceMSDTSearchMaintenanceDriverTasks = createAsyncThunk('maintenance/scheduleDriverTask/searchMaintenanceDriverTasks', async ({ openSearch }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `{
                data: searchMaintenanceDriverTasks(openSearch: "${openSearch || ''}"){
                    id
                    name
                    description
                    frequency
                    estimatedCost
                        timeUnit
                        fileIds
                        files {
                            id
                            originalName
                            fileKey
                            repositoryCode
                        }
                    }
              }`,
            {},
            endpoints.GRAPHQL_GENERAL
        );
        return response?.data;
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceMSDTSearchMaintenanceDriverTasks');
        return rejectWithValue(exc)
    }
});

export const maintenanceMSDTGetDriverScheduledTask = createAsyncThunk('maintenance/scheduleDriverTask/getDriverScheduledTask', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `query ($id: Int!) {
                data: getDriverScheduledTask (id: $id) {
                    id
                    driverId
                    driverName
                    driverTaskId
                    driverTaskName
                    estimatedCost
                    dueDate
                    frequency
                    timeUnit
                    notes
                    completed
                    fileIds
                    alertBefore
                    alertBeforeTimeUnit
                    alertAfter
                    alertAfterTimeUnit
                    files {
                        id
                        originalName
                        fileKey
                        repositoryCode
                    }
                    subscribersCount
                    subscribers {
                        id
                        contactId
                        contact {
                            id
                            title
                        }
                        alert
                        alertBefore
                        alertAfter
                    }
                    status
                }
            }`,
            {
                id: id
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.data;
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceMSDTGetDriverScheduledTask');
        return rejectWithValue(exc)
    }
});

export const maintenanceMSDTCreateEditAlertSubscriber = createAsyncThunk('maintenance/scheduleDriverTask/createEditAlertSubscriber', async ({ updateAlerts, deleteAlerts }, { rejectWithValue, dispatch }) => {
    try {
        if (updateAlerts && updateAlerts.length > 0) {
            let updateMutations = []
            updateAlerts.forEach((updateAlert, index) => {
                updateMutations.push(`updatedSubscriber${index}: ${'createAlertSubscriber'} (
                    ${'entityName: ' + '' + updateAlert.entityName + ','}
                    ${'entityId: ' + '"' + updateAlert.entityId + '",'} 
                    contactId: ${updateAlert.contactId}, 
                    alert: ${!!updateAlert.alert}, 
                    alertBefore: ${!!updateAlert.alertBefore}, 
                    alertAfter: ${!!updateAlert.alertAfter} ) {
                        id
                    }`
                )
            })
            let updateMutation = `mutation{${updateMutations.join("")}}`;
            await clientMutation(
                updateMutation,
                {},
                endpoints.GRAPHQL_GENERAL
            );
        }

        if (deleteAlerts && deleteAlerts.length > 0) {
            let deleteMutations = []
            deleteAlerts.forEach((deleteAlert, index) => {
                deleteMutations.push(`deletedSubscriber${index}: deleteAlertSubscriber(id: ${deleteAlert})`)
            })
            let deleteMutation = `mutation{${deleteMutations.join("")}}`;
            await clientMutation(
                deleteMutation,
                {},
                endpoints.GRAPHQL_GENERAL
            );
        }
        return true;
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceMSDTCreateEditAlertSubscriber');
        return rejectWithValue(exc)
    }
});

export const maintenanceMSDTSearchDriverScheduledTasks = createAsyncThunk('maintenance/scheduleDriverTask/searchDriverScheduledTasks', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
                query ($driverId: Int, $excludeCompleted: Boolean){
                    data: searchDriverScheduledTasks(driverId: $driverId, excludeCompleted: $excludeCompleted){
                        id
                        driverId
                        driverTaskId
                        driverTaskName
                        estimatedCost
                        dueDate
                        frequency
                        notes
                        fileIds
                        subscribersCount
                        status
                        createdOn
                        completed
                        timeUnit
                        lastMaintenanceDate
                    }
                }
            `,
            {
                driverId: id || '',
                excludeCompleted: true
            },
            endpoints.GRAPHQL_GENERAL
        );

        return response.data
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceMSDTSearchDriverScheduledTasks');
        return rejectWithValue(exc)
    }
});

export const maintenanceMSDTListTotalDriversScheduledTasks = createAsyncThunk('maintenance/scheduleDriverTask/listTotalDriversScheduledTasks', async ({ searchText }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
                query ($openSearch: String){
                    data: searchDriverScheduledTasks(openSearch: $openSearch){
                        id
                        driverId
                        driverTaskId
                        driverTaskName
                        estimatedCost
                        dueDate
                        frequency
                        notes
                        fileIds
                        subscribersCount
                        completed
                        status
                        createdOn
                    }
                }
            `,
            {
                openSearch: searchText || '',
            },
            endpoints.GRAPHQL_GENERAL
        );

        return response.data
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceMSDTListTotalDriversScheduledTasks');
        return rejectWithValue(exc)
    }
});

export const maintenanceMSDTCreateUpdateDriverScheduledTask = createAsyncThunk('maintenance/scheduleDriverTask/createUpdateDriverScheduledTask', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientMutation(
            `
                mutation(
                    ${payload.id ? '$id: Int!' : ''}
                    ${!payload.id ? '$driverId: Int!' : ''}
                    ${!payload.id ? '$driverTaskId: Int!' : ''}
                    $estimatedCost: Float!
                    $dueDate: String!
                    $frequency: Int!
                    $timeUnit: enumTimeUnit!
                    $alertBefore: Int
                    ${payload.alertBeforeTimeUnit ? '$alertBeforeTimeUnit: enumTimeUnit' : ''}
                    $alertAfter: Int
                    ${payload.alertAfterTimeUnit ? '$alertAfterTimeUnit: enumTimeUnit' : ''}
                    $notes: String
                    $fileIds: [Int]
                ) {
                    data: ${payload.id ? 'updateDriverScheduledTask' : 'createDriverScheduledTask'} (
                        ${payload.id ? 'id: $id,' : ''}
                        ${!payload.id ? 'driverId: $driverId,' : ''}
                        ${!payload.id ? 'driverTaskId: $driverTaskId,' : ''}
                        estimatedCost: $estimatedCost,
                        dueDate: $dueDate,
                        frequency: $frequency,
                        timeUnit: $timeUnit,
                        alertBefore: $alertBefore,
                        ${payload.alertBeforeTimeUnit ? 'alertBeforeTimeUnit: $alertBeforeTimeUnit,' : ''}
                        alertAfter: $alertAfter,
                        ${payload.alertAfterTimeUnit ? 'alertAfterTimeUnit: $alertAfterTimeUnit,' : ''}
                        notes: $notes
                        fileIds: $fileIds
                    ) {
                        id
                    }
                }
            `,
            payload,
            endpoints.GRAPHQL_GENERAL
        );

        return response.data
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceMSDTCreateUpdateDriverScheduledTask');
        return rejectWithValue(exc)
    }
});

export const maintenanceMSDTGetDriverTasksScheduledRequest = createAsyncThunk('maintenance/scheduleDriverTask/getDriverTasksScheduledRequest', async ({ openSearch, driverId }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientMutation(
            `
                query ($openSearch: String, $driverId: Int){
                    driverTasksScheduled: searchDriverScheduledTasks(openSearch: $openSearch, driverId: $driverId){
                    id
                    driverId
                    driverTaskId
                    driverTaskName
                    status
                    dueDate
                    completed
                    lastMaintenanceDate
                    }
                }
            `,
            {
                openSearch,
                driverId
            },
            endpoints.GRAPHQL_GENERAL
        );

        return response.data
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceMSDTGetDriverTasksScheduledRequest');
        return rejectWithValue(exc)
    }
});

export const maintenanceScheduleDriverTasksRedux = createSlice({
    name: "maintenance/scheduleDriverTask",
    initialState: INIT_STATE,
    reducers: {
        reset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalScheduleDriverTasks: (state, action) => {
            return {
                ...state,
                open: action.payload.open,
                scheduledDriverTaskId: action.payload.id,
                driverId: action.payload.driverId,
            }
        }
    },
    extraReducers: (builder) => {

        //maintenanceMSDTSearchMaintenanceDriverTasks
        builder.addCase(maintenanceMSDTSearchMaintenanceDriverTasks.pending, (state, action) => {
            state.driverTasks = [],
                state.loadingDriverTasks = true
        });
        builder.addCase(maintenanceMSDTSearchMaintenanceDriverTasks.fulfilled, (state, action) => {
            state.driverTasks = action.payload,
                state.loadingDriverTasks = false
        });
        builder.addCase(maintenanceMSDTSearchMaintenanceDriverTasks.rejected, (state, action) => {
            state.driverTasks = [],
                state.errorDriverTasks = action.payload
            state.loadingDriverTasks = false
        });

        //maintenanceMSDTGetDriverScheduledTask
        builder.addCase(maintenanceMSDTGetDriverScheduledTask.pending, (state, action) => {
            state.scheduledDriverTask = null,
            state.loadingScheduledDriverTask = true
        });
        builder.addCase(maintenanceMSDTGetDriverScheduledTask.fulfilled, (state, action) => {
            state.scheduledDriverTask = action.payload,
            state.loadingScheduledDriverTask = false
        });
        builder.addCase(maintenanceMSDTGetDriverScheduledTask.rejected, (state, action) => {
            state.scheduledDriverTask = null,
                state.errorScheduledDriverTask = action.payload
            state.loadingScheduledDriverTask = false
        });

        //maintenanceMSDTGetDriverScheduledTask
        builder.addCase(maintenanceMSDTCreateEditAlertSubscriber.pending, (state, action) => {
            state.alertSubscriberResponse = null,
                state.loadingAlertSubscriberResponse = true
        });
        builder.addCase(maintenanceMSDTCreateEditAlertSubscriber.fulfilled, (state, action) => {
            state.alertSubscriberResponse = action.payload,
                state.loadingAlertSubscriberResponse = false
        });
        builder.addCase(maintenanceMSDTCreateEditAlertSubscriber.rejected, (state, action) => {
            state.alertSubscriberResponse = null,
                state.errorAlertSubscriberResponse = action.payload
            state.loadingAlertSubscriberResponse = false
        });

        //maintenanceMSDTSearchDriverScheduledTasks
        builder.addCase(maintenanceMSDTSearchDriverScheduledTasks.pending, (state, action) => {
            state.loadingListDriverScheduledTasks = true
        });
        builder.addCase(maintenanceMSDTSearchDriverScheduledTasks.fulfilled, (state, action) => {
            state.listDriverScheduledTasks = action.payload,
                state.loadingListDriverScheduledTasks = false
        });
        builder.addCase(maintenanceMSDTSearchDriverScheduledTasks.rejected, (state, action) => {
            state.listDriverScheduledTasks = [],
                state.errorListDriverScheduledTasks = action.payload
            state.loadingListDriverScheduledTasks = false
        });

        //maintenanceMSDTListTotalDriversScheduledTasks
        builder.addCase(maintenanceMSDTListTotalDriversScheduledTasks.pending, (state, action) => {
            state.loadingListTotalDriversScheduledTasks = true
        });
        builder.addCase(maintenanceMSDTListTotalDriversScheduledTasks.fulfilled, (state, action) => {
            state.listTotalDriversScheduledTasks = action.payload,
                state.loadingListTotalDriversScheduledTasks = false
        });
        builder.addCase(maintenanceMSDTListTotalDriversScheduledTasks.rejected, (state, action) => {
            state.listTotalDriversScheduledTasks = [],
                state.loadingListTotalDriversScheduledTasks = false
        });

        //maintenanceMSDTCreateUpdateDriverScheduledTask
        builder.addCase(maintenanceMSDTCreateUpdateDriverScheduledTask.pending, (state, action) => {
            state.loadingCreateUpdateDriverScheduledTask = true
        });

        builder.addCase(maintenanceMSDTCreateUpdateDriverScheduledTask.fulfilled, (state, action) => {
            state.scheduleDriverTaskResult = action.payload,
                state.loadingCreateUpdateDriverScheduledTask = false
        });

        builder.addCase(maintenanceMSDTCreateUpdateDriverScheduledTask.rejected, (state, action) => {
            state.listTotalDriversScheduledTasks = [],
                state.errorScheduleDriverTaskResult = action.payload,
                state.loadingCreateUpdateDriverScheduledTask = false
        });

        //maintenanceMSDTGetDriverTasksScheduledRequest
        builder.addCase(maintenanceMSDTGetDriverTasksScheduledRequest.pending, (state, action) => {
            state.loadinDriverTasksSchedule = true
        });

        builder.addCase(maintenanceMSDTGetDriverTasksScheduledRequest.fulfilled, (state, action) => {
            state.driverTasksScheduled = action.payload,
                state.loadinDriverTasksSchedule = false
        });

        builder.addCase(maintenanceMSDTGetDriverTasksScheduledRequest.rejected, (state, action) => {
            state.driverTasksScheduled = [],
                state.errorDriverTasksScheduled = action.payload,
                state.loadinDriverTasksSchedule = false
        });
    }
})

const { actions, reducer } = maintenanceScheduleDriverTasksRedux;

export const { reset, openModalScheduleDriverTasks } = actions;

export default reducer;