import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { clientQuery, clientMutation } from '../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../../../core/logManager';

import { resetALL } from '../../../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    response: null,
    driverStates: [],
    error: false
};

const route = 'modules/settings/views/driverStatus/components/delete/redux/slice';

export const deleteDriverStates = createAsyncThunk('driverStatus/components/delete/deleteDriverStates', async ({ ids }, { rejectWithValue, dispatch }) => {
    try {
        let query = `
            mutation deleteDriverStates ($ids: [Int]!) {
                deleted: deleteDriverStates (ids: $ids)
            }
        `;

        const response = await clientMutation(
            query,
            {
                ids
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response?.deleted) {
            throw 'Error while deleting driver states';
        }

        return response?.deleted;
    } catch (exc) {
        ExceptionManager(exc, route, 'deleteDynamicForms');
        return rejectWithValue(exc)
    }
});

//Slice
const driverStatusDeleteRedux = createSlice({
    name: 'driverStatus/delete',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openDeleteModal(state, action) {
            state.open = true;
            state.driverStates = action?.payload;
        },
        closeDeleteModal(state) {
            state.open = false;
            state.driverStates = [];
            state.response = null;
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)
        //deleteDynamicForms
        builder.addCase(deleteDriverStates.pending, (state, action) => {
            state.loading = true;
            state.error = false;
            state.response = null;
        });
        builder.addCase(deleteDriverStates.fulfilled, (state, action) => {
            state.loading = false;
            state.error = false;
            state.response = action.payload;
        });
        builder.addCase(deleteDriverStates.rejected, (state, action) => {
            state.loading = false;
            state.response = action.payload;
            state.error = true;
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = driverStatusDeleteRedux;

// Extract and export each action creator by name
export const { 
    reset, 
    openDeleteModal, 
    closeDeleteModal,
} = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;