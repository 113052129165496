import {
    SETTINGS_VEHICLE_EQUIPMENT_SEARCH,
    SETTINGS_VEHICLE_EQUIPMENT_SEARCH_RESPONSE,
    SETTINGS_VEHICLE_EQUIPMENT_UNIT_GET_RESPONSE,
    SETTINGS_VEHICLE_EQUIPMENT_CREATE_EDIT,
    SETTINGS_VEHICLE_EQUIPMENT_CREATE_EDIT_RESPONSE,
    SETTINGS_VEHICLE_EQUIPMENT_OPEN_CREATE_EDIT_MODAL,
    SETTINGS_VEHICLE_EQUIPMENT_OPEN_DELETE_MODAL,
    SETTINGS_VEHICLE_EQUIPMENT_DELETE,
    SETTINGS_VEHICLE_EQUIPMENT_DELETE_RESPONSE,
    SETTINGS_VEHICLE_EQUIPMENT_ASSIGNMENT_DELETE_RESPONSE,
    SETTINGS_VEHICLE_EQUIPMENT_ASSIGNMENT_DELETE,
    SETTINGS_VEHICLE_EQUIPMENT_OPEN_ASSIGNMENT_MODAL
} from "Redux/actionTypes";


export const openSettingsVehicleEquipmentAssignment = (open,assignments) => {
    return {
        type: SETTINGS_VEHICLE_EQUIPMENT_OPEN_ASSIGNMENT_MODAL,
        payload: { open,assignments }

    };
};
export const searchSettingsVehicleEquipment = (data) => {
    return {
        type: SETTINGS_VEHICLE_EQUIPMENT_SEARCH,
        payload: data,
    };
};

export const searchSettingsVehicleEquipmentResponse = (data) => {
    return {
        type: SETTINGS_VEHICLE_EQUIPMENT_SEARCH_RESPONSE,
        payload: data,
    };
};


export const getSettingsVehicleEquipmentUnitResponse = (data) => {
    return {
        type: SETTINGS_VEHICLE_EQUIPMENT_UNIT_GET_RESPONSE,
        payload: data,
    };
};

export const openSettingsVehicleEquipmentCreateEditModal = (open, vehicleEquipment) => {
    return {
        type: SETTINGS_VEHICLE_EQUIPMENT_OPEN_CREATE_EDIT_MODAL,
        payload: { open, vehicleEquipment }

    };
};

export const createEditSettingsVehicleEquipment = (vehicleEquipment) => {
    return {
        type: SETTINGS_VEHICLE_EQUIPMENT_CREATE_EDIT,
        payload: { vehicleEquipment },
    };
}

export const createEditSettingsVehicleEquipmentResponse = (data) => {
    return {
        type: SETTINGS_VEHICLE_EQUIPMENT_CREATE_EDIT_RESPONSE,
        payload: { data },
    };
};




export const openSettingsVehicleEquipmentDeleteModal = (open, items = []) => {
    return {
        type: SETTINGS_VEHICLE_EQUIPMENT_OPEN_DELETE_MODAL,
        payload: { open, items },
    };
};

export const deleteSettingsVehicleEquipment = (items = []) => {
    return {
        type: SETTINGS_VEHICLE_EQUIPMENT_DELETE,
        payload: { items },
    };
};

export const deleteSettingsVehicleEquipmentResponse = (data) => {
    return {
        type: SETTINGS_VEHICLE_EQUIPMENT_DELETE_RESPONSE,
        payload: { data },
    };
};


export const deleteSettingsVehicleEquipmentAssigment = (idVehicleEquipment) => {
    return {
        type: SETTINGS_VEHICLE_EQUIPMENT_ASSIGNMENT_DELETE,
        payload: { idVehicleEquipment },
    };
};

export const deleteSettingsVehicleEquipmentAssigmentResponse = (data) => {
    return {
        type: SETTINGS_VEHICLE_EQUIPMENT_ASSIGNMENT_DELETE_RESPONSE,
        payload: { data },
    };
};
