export default {
    mapGeofenceDeleteGeofence_title:'Delete Geofences',
    mapGeofenceDeleteGeofence_detail:'Are you sure you want to delete the following Geofences?', //Replace number value
    mapGeofenceDeleteGeofence_detail_one:'Are you sure you want to delete the following Geofence?', //Replace number value
    mapGeofenceDeleteGeofence_warning:'This action cannot be undone.',
    mapGeofenceDeleteGeofence_success:'Geofences deleted',
    mapGeofenceDeleteGeofence_success_one:'Geofence deleted',
    mapGeofenceDeleteGeofence_remove_all_geofence_keyword: 'all Geofences',
    mapGeofenceDeleteGeofence_remove_all: 'Are you sure to delete',
    mapGeofenceDeleteGeofence_remove_all_except: 'except this list'
}