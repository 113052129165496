import React, { useState, useRef, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from "react-router-dom";

// Controls
import { Form, Input, Loading, Button } from "Components";
import { Box, Container, Grid } from "@mui/material";
// Actions
import {
  forceChangePassword
} from "Redux/actions";
// Components
import ToggleLanguage from "../../../components/ToggleLanguage";
import LoginLogo from "../common/LoginLogo";
import ChatWithSupport from "../common/ChatWithSupport";
import SalesForceApi from 'Core/salesForceApi';
const ForceChangePassword = (props) => {
  const { messages } = useIntl();

  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation()

  // State manager
  const loading = useSelector((state) => state.loginRedux.loading);
  const session = useSelector((state) => state.loginRedux.session);
  const username = useSelector((state) => state.loginRedux.username);
  const currentReseller = useSelector((state) => state.resellerRedux.current_reseller);
  const resellerWasValidated = useSelector((state) => state.resellerRedux.reseller_was_validated);
  const error = useSelector((state) => state.forceChangePasswordRedux.error);
  const loadingForceChange = useSelector((state) => state.forceChangePasswordRedux.loading);
  // State local
  const [formData, setFormData] = useState(null);

  // Ref
  const formRef = useRef();

  const backToLogin = () => {
    return (
      <div style={{ paddingTop: 10 }}>
        <Button
          id="btnBack"
          type="link"
          className="login-page-link"
          url={
            currentReseller?.key &&
              location.pathname.includes("reseller")
              ? `/reseller/${currentReseller?.key}`
              : "/login"
          }
        >
          <FormattedMessage id="forgotPassword_back" />
        </Button>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="background-white-login">
        <Container className="form-login-container">
          <LoginLogo />
          {error && (
            <div className="form-item card-error-login">
              <p className="txt-card-error-login">
                {error}
              </p>
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p className="blue-subtitle-migration">
              <b>
                {messages['forceChangePassword_changePasswordMessages']}
              </b>
            </p>
          </div>
          <Form
            ref={formRef}
            onValidSubmit={() => {
              dispatch(forceChangePassword({
                username: formData.username,
                password: formData.password,
                session: formData.session,
                navigate: navigate
              }))
            }}
          >
            <div>
              <Input
                id="username"
                type="text"
                label={messages["forceChangePassword_username"]}
                value={formData?.username}
                disabled
                autoFocus
              />
            </div>
            <div>
              <Input
                id="password"
                type="password"
                label={messages['forceChangePassword_password']}
                placeholder={messages['forceChangePassword_password']}
                value={formData?.password || ""}
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    password: value
                  })
                }
                minLength={6}
                minLengthMessage={`${messages['forceChangePassword_minMessagePassword']} 6`}
                maxLength={20}
                maxLengthMessage={`${messages['forceChangePassword_maxMessagePassword']} 20`}
                required
                requiredMessage={messages['required_field']}
                autoFocus
              />
            </div>
            <div>
              <Input
                id="passwordConfirm"
                type="password"
                label={messages['forceChangePassword_confirmPassword']}
                placeholder={messages['forceChangePassword_confirmPassword']}
                value={formData?.passwordConfirm || ""}
                valueToCompare={formData?.password}
                valueToCompareMessage={messages['forceChangePassword_valueToCompareMessage']}
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    passwordConfirm: value
                  })
                }
                minLength={6}
                minLengthMessage={`${messages['forceChangePassword_minMessageConfirmPassword']} 6`}
                maxLength={20}
                maxLengthMessage={`${messages['forceChangePassword_maxMessageConfirmPassword']} 20`}
                required
                requiredMessage={messages['required_field']}
              />
            </div>
            <div style={{ paddingTop: 10 }}>
              {loading || loadingForceChange ? (
                <div className="loading-area">
                  <Loading />
                </div>
              ) : (
                <Button
                  id="btnContinue"
                  className="btn btn-blue btn-block"
                  disabled={loading || loadingForceChange}
                  onClick={(e) => {
                    e.preventDefault();
                    formRef.current.submit();
                  }}
                >
                  <FormattedMessage id="continue" />
                </Button>
              )}
            </div>
            {
              !currentReseller && resellerWasValidated &&
              <div style={{ marginTop: 10 }}>
                <ChatWithSupport />
              </div>
            }
          </Form>
        </Container>
      </div>
    );
  };

  useEffect(() => {
    if (username) {
      setFormData({
        ...formData,
        username,
        session
      })
    }
  }, [username]);

  useEffect(() => {
    // Check permission to login to this URL
    if(!session){
      navigate("/");
    }
  }, [])

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className="all-space-container"
    >
      <Grid
        item
        xs={12} lg={5}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "end",
            margin: "20px 25px 0 0px",
          }}
        >
          <ToggleLanguage className="btn-white" />
        </Box>

        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          {renderForm()}
        </div>
        <Box m="25px">{backToLogin()}</Box>

      </Grid>
      <Grid item xs={12} lg className="all-space-container">
        <div
          className="login_bg"
          style={{
            backgroundImage: (!currentReseller?.loginBackgroundImage && currentReseller?.loginBackgroundColor) ? `none`
              : `url(${currentReseller?.loginBackgroundImage ||
              "/assets/images/loginbg-dark.png"
              })`,
            backgroundColor: currentReseller?.loginBackgroundImage ? "transparent" : (currentReseller?.loginBackgroundColor || "transparent")
          }}
        />
      </Grid>
      {
        !currentReseller && resellerWasValidated &&
        <SalesForceApi />
      }
    </Grid>
  )
}

export default ForceChangePassword;