import {
    ACCOUNT_API_KEYS_SEARCH,
    ACCOUNT_API_KEYS_SEARCH_RESPONSE,
    ACCOUNT_API_KEYS_OPEN_CREATE_EDIT_MODAL,
    ACCOUNT_API_KEYS_GET_API_KEY,
    ACCOUNT_API_KEYS_GET_API_KEY_RESPONSE,
    ACCOUNT_API_KEYS_CREATE_EDIT_API_KEY,
    ACCOUNT_API_KEYS_CREATE_EDIT_API_KEY_RESPONSE,
    ACCOUNT_API_KEYS_OPEN_DELETE_MODAL,
    ACCOUNT_API_KEYS_DELETE_API_KEYS,
    ACCOUNT_API_KEYS_DELETE_API_KEYS_RESPONSE
} from "Redux/actionTypes";

export const searchAccountApiKeys = (data) => {
    return {
        type: ACCOUNT_API_KEYS_SEARCH,
        payload: data,
    };
};

export const searchAccountApiKeysResponse = (data) => {
    return {
        type: ACCOUNT_API_KEYS_SEARCH_RESPONSE,
        payload: data,
    };
};

export const getAccountApiKey = (id) => {
    return {
        type: ACCOUNT_API_KEYS_GET_API_KEY,
        payload: { id },
    };
};

export const getAccountApiKeyResponse = (data) => {
    return {
        type: ACCOUNT_API_KEYS_GET_API_KEY_RESPONSE,
        payload: { data },
    };
};

export const openAccountApiKeyCreateEditModal = (open, id) => {
    return {
        type: ACCOUNT_API_KEYS_OPEN_CREATE_EDIT_MODAL,
        payload: { open, id },
    };
};

export const createEditAccountApiKey = (apiKey) => {
    return {
        type: ACCOUNT_API_KEYS_CREATE_EDIT_API_KEY,
        payload: { apiKey },
    };
}

export const createEditAccountApiKeyResponse = (data) => {
    return {
        type: ACCOUNT_API_KEYS_CREATE_EDIT_API_KEY_RESPONSE,
        payload: { data },
    };
};

export const openAccountApiKeyDeleteModal = (open, items = []) => {
    return {
        type: ACCOUNT_API_KEYS_OPEN_DELETE_MODAL,
        payload: { open, items },
    };
};

export const deleteAccountApiKeys = (items = []) => {
    return {
        type: ACCOUNT_API_KEYS_DELETE_API_KEYS,
        payload: { items },
    };
};

export const deleteAccountApiKeysResponse = (data) => {
    return {
        type: ACCOUNT_API_KEYS_DELETE_API_KEYS_RESPONSE,
        payload: { data },
    };
};