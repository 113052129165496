//View Keys
export default {
  subUser_title: "Users",
  subUser_delete: "Delete",
  subUser_cancel: "Cancel",
  subUser_manageUserTypes: "Manage User Types",
  subUser_userName: "Username",
  subUser_email: "Email",
  subUser_groups: "Groups",
  subUser_driverGroups: "Driver Groups",
  subUser_tags: "Tags",
  subUser_formGroup: "Form Groups",
  subUser_landmarkGroups: "Landmark Groups",
  subUser_permissions: "Permissions",
  subUser_expires: "Expires",
  subUser_expiryDate: "Expiration Date",
  subUser_selectDate: "Select Date",
  subUser_ipRestriction: "IP Restriction",
  subUser_password: "Password",
  subUser_confirmPassword: "Confirm Password",
  subUser_userType: "User Type",
  subUser_select: "Select",
  subUser_messageDelete: "Are you sure you want to delete the users",
  subUser_deleteUser: "Delete Users",
  subUser_create: "New User",
  subUser_edit: "Edit User",
  subUser_createMessage: "User Created",
  subUser_editMessage: "User Updated",
  subUser_deleteMessage: "User Deleted",
  subUser_valueToCompareMessage: "Confirm password does not match New Password",
  subUser_minMessageUsername: "The username must contain minimun chars:",
  subUser_minMessagePassword: "The password must contain minimun chars:",
  subUser_maxMessagePassword: "The password must contain maximum chars:",
  subUser_minMessageConfirmPassword: "The confirm password must contain minimun chars:",
  subUser_maxMessageConfirmPassword: "The confirm password must contain maximum chars:",
  subUser_maxMessageUsername: "The username must contain maximum chars:",
  subUser_messageIpRestriction: "Invalid IP address",
  subUser_resetCognito: "Reset",
  subUser_viewPermissions: "View permissions",
  subUser_resetUser: "Reset user email",
  subUser_resetPermissionMessage: "Are you sure you want to reset the user email",
  subUser_successResetCognito: "The Email Address has been Reset. Login again to register a new email address.",
  subUser_name: "Name",
  subUser_role: "Role",
  subUser_firstName: "First Name",
  subUser_lastName: "Last Name",
  subUser_never: "Never",
  subUser_phone: "Cell",
  subUser_maxLengthMessageCellPhone: "The cell must contain a maximum of digits:",
  subUser_minLengthMessageCellPhone: "The cell must contain a minimum of digits:",
  subUser_successPasswordReset: "Password reset",
  subUser_resetPassword: "Reset user password",
  subUser_resetPasswordMessage: "Are you sure you want to reset the user password",
  subUser_emailMessage: "The email is incorrect",
  subUser_minLengthMessageEmail: "The email must contain a minimum of digits:",
  subUser_maxLengthMessageEmail: "The email must contain a maximum of digits:",
  subUser_apolloEldAccount: "Apollo ELD Account",
  subUser_changePassword: "Change Password",
};