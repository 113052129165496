import { put, call } from "redux-saga/effects";
import { clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";
// Actions
import { reportsMainGenerateReportProgress } from "Redux/actions";

import { getTimeZone } from "Modules/reports/views/main/components/reportsTab/helper";

function* getEngineHoursSummaryReport(report, event) {
  const { timeZone, name,reportTimeRanges } = report;
  const { startRange, endRange } = report.filters;
  const {
    filterType,
    filterArray,
    page,
    rowsPerPage,
    sortField,
    sortOrder,
    otherFilters,
  } = event.filters;
  try {
    const newReport = JSON.parse(JSON.stringify(report));
    let data = yield call(
      clientQuery,
      `query getEngineHoursDetailsReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
          Report: getEngineHoursDetailsReport (conditions: $conditions, limit: $limit, offset: $offset) {
            items {
                dateTime
                id
                idLabel
                location
                hours
            }
            total
            limit
            offset
            category
            reportInstanceId
          }
        }`,
      {
        conditions: {
          reportName: name,
          category: {
            field: filterType,
            value: filterArray?.id,
          },
          timeZone: getTimeZone(timeZone),
          dateRange: {
            fields: ["unitTime"],
            GTE: startRange,
            LTE: endRange,
            reportTimeRanges
          },
          sortField: {
            field: sortField || "deviceId",
            order: sortOrder === "desc" ? "DESC" : "ASC",
          },
        },
        limit: rowsPerPage,
        offset: page,
      },
      endpoints.GRAPHQL_PX_REPORTING
    );

    if (data?.Report) {
      newReport.summary = data.Report;
      newReport.loadingSummary = false;
      yield put(reportsMainGenerateReportProgress(newReport));
    } else {
      newReport.loadingSummary = false;
      yield put(reportsMainGenerateReportProgress(newReport));
    }
  } catch (exc) {
    report.loadingSummary = false;
    ExceptionManager(
      exc,
      "src/modules/reports/views/main/components/reportsTab/.components/reports/engineHoursReports",
      "getEngineHoursSummaryReport"
    );
    yield put(reportsMainGenerateReportProgress(report));
  }
}

export default getEngineHoursSummaryReport;
