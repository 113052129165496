import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery } from '../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../../../core/logManager';
import { resetALL } from '../../../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    data: null,
    result: null,
    items: [],
    total: 0,
    limit: 20,
    offset: 0,
};

const route = 'Warnings/.components/Details/redux/slice'

export const searchMaintenanceWarningEvents = createAsyncThunk('warnings/Details/searchMaintenanceWarningEvents', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const { unitId, warning, resolutionStatus, offset, firstEventDate, lastEventDate } = payload
        let response = await clientQuery(`
            query searchMaintenanceWarningEvents($conditions: [MaintenanceWarningEventSearchInput], $sort: [MaintenanceWarningEventSortInput], $limit: Int, $offset: Int) {
                result: searchMaintenanceWarningEvents(conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                    items {
                        id
                        unitId
                        unitTime
                        warning
                        value
                        details
                        createdOn
                        updatedOn
                    }
                    total
                }
            }
            `,
            {
                "conditions": [
                    {
                        "fields": ["unitId"],
                        "terms": [unitId],
                        "exact": true,
                        "conditionOperator": "AND"
                    },
                    {
                        "fields": ["warning"],
                        "terms": [warning],
                        "exact": true,
                        "conditionOperator": "AND"
                    },
                    {
                        "fields": ["resolutionStatus"],
                        "terms": [resolutionStatus],
                        "exact": true,
                        "conditionOperator": "AND"
                    },
                    {
                        range: {
                            fields: ["unitTime"],
                            GTE: firstEventDate,
                            LTE: lastEventDate
                        },
                    }
                ],
                "limit": 20,
                "offset": offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        )

        return response?.result || { items: [], total: 0 }

    } catch (exc) {
        ExceptionManager(exc, route, 'searchMaintenanceWarningEvents');
        return rejectWithValue(exc)
    }
});

//Slice
const warningsDetailsRedux = createSlice({
    name: 'warnings/details',
    initialState: INIT_STATE,
    reducers: {
        resetDetails(state, action) {
            return action.payload ? { ...state, ...action.payload } : INIT_STATE
        },
        openWarningDetailsModal(state, action) {
            state.open = true
            state.data = action.payload
        },
        closeWarningDetailsModal() {
            return INIT_STATE
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        //searchMaintenanceWarningEvents
        builder.addCase(searchMaintenanceWarningEvents.pending, (state, action) => {
            state.open = true
            state.loading = true
        });
        builder.addCase(searchMaintenanceWarningEvents.fulfilled, (state, action) => {
            state.items = [...state.items, ...action.payload.items] || []
            state.total = action.payload.total
            state.loading = false

        });
        builder.addCase(searchMaintenanceWarningEvents.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = warningsDetailsRedux;

// Extract and export each action creator by name
export const { resetDetails, openWarningDetailsModal, closeWarningDetailsModal } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;