import {
    MAP_LANDMARKS_GROUPS_DELETE_OPEN_MODAL,
    MAP_LANDMARKS_DELETE_LANDMARK_GROUPS,
    MAP_LANDMARKS_DELETE_LANDMARK_GROUPS_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    open: false,
    landmarkGroups: [],
    response: null,
    loadingResponse: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MAP_LANDMARKS_GROUPS_DELETE_OPEN_MODAL:
            return {
                ...state,
                open: action.payload.open,
                landmarkGroups: action.payload.landmarkGroups || [],
                response: null,
                loadingResponse: false
            };

        case MAP_LANDMARKS_DELETE_LANDMARK_GROUPS:
            return {
                ...state,
                response: null,
                loadingResponse: true
            };

        case MAP_LANDMARKS_DELETE_LANDMARK_GROUPS_RESPONSE:
            return {
                ...state,
                response: action.payload.data,
                loadingResponse: false
            };

        case CLEAR_REDUX:
            return ["", "MAP_LANDMARKS_DELETE_LANDMARK_GROUPS"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
      return state;
    }
}