//View Keys
export default {
    "migrateUser_title": "Recovery Password",
    "migrateUser_email": "Email",
    "migrateUser_login": "Login",
    "migrateUser_currentUsername": "Current Username",
    "migrateUser_password": "Password",
    "migrateUser_verificationCode": "Verification Code",
    "migrateUser_newPassword": "New Password",
    "migrateUser_ConfirmNewPassword": "Confirm New Password",
    "migrateUser_emailVerification": "Email Verification",
    "migrateUser_verifyCode": "Verification Code",
    "migrateUser_verifyCodeError": "Error during the Verification Code",
    "migrateUser_modalSubtitle" : "We are switching to an email based authentication system and will need to convert your username to a valid email address",
    "migrateUser_modalSubtitle2" : "Please enter an email address you have access to and re-enter your username and password.",
    "migrateUser_verify": "Verify",
    "migrateUser_verifyTex": "A verification code has just been emailed to you, please enter the code below to verify your email",
    "migrateUser_success": "Your password has been updated, please log in!",
    "migrateUser_passwordError": "Passwords do not match",
    "migrateUser_back": "Return to login?",
    "migrateUser_cancel": "Cancel",
    "migrateUser_termsTitle": "TERMS AND CONDITIONS",
    "migrateUser_acceptTerms": "I have read and agree to the Terms and Conditions",
    "migrateUser_terms": `    
    <div>
    <div>
    THESE TERMS AND CONDITIONS (the "<b>Agreement</b>"), is made and entered into by and between you and Global Tracking Communications, LLC (“GTC”),
    including its successors and/or assigns ("<b>GTC</b>," "<b>we</b>," or “<b>us</b>”) and is effective immediately upon your affirmative act of acceptance on the website or application
    (the “<b>Effective Date</b>”).

      </div>
    <ul>
      <li>
      1.  Hardware, Software License, and Customer Service:  In exchange for your ongoing payments for service and access to our application we provide to you the following:
          <ul>
          <li>
          a.  Equipment Use/Title: For Purchased Hardware, the Equipment is for you to keep beginning on the Effective Date of the sale.
          For Included Hardware only (Devices included with GPS tracking software plans), title to the Equipment is and will remain vested in GTC.
          Customer agrees that it will not permit any lien, encumbrance or security interest to attach to the Equipment.
          In the event of cancellation of service, Customer agrees all Equipment will be returned to GTC. There are no cancellation penalties.
          In the event the Equipment is lost or are not returned, a fee may be charged that will not exceed value of Included Hardware.
            </li>
          <li>
            b.  Software License:
            <ul>
              <li>
              i.  Subject to your compliance with all terms and conditions of this Agreement, GTC grants you a personal,
              non-exclusive license (the “<b>Software License</b>”) to use any and all software provided by GTC (the “<b>Software</b>”) during the Term of this Agreement.

              </li>
              <li>
              ii. <u>Reservation of Rights and Ownership:</u> GTC reserves all rights not expressly granted to you in this Agreement. 
              The Software is protected by copyright and other intellectual property laws and treaties, which is owned by GTC. You are licensing the Software.
              You are not purchasing the Software.
              </li>
              <li>
              iii.  <u>Prohibitions.</u>  You may not reverse engineer the Software. You may not resell the Software, permit another party (affiliate or otherwise) to use the Software,
              or otherwise share or distribute the Software to any other person.  You further agree to ensure that the Systems are not used for illegal,
              fraudulent or abusive purposes or in a way which would negatively affect InTouch’s reputation in the business community.
              </li>
            </ul>
          </li>
          <li>
          c.  Customer Service: GTC has customer service representatives standing by during normal business hours to answer any questions related to the products.
          </li>
          <li>
          d.  The Hardware, Software License, and Customer Service are sometimes collectively referred to herein as the “<b>System.</b>”
            <ul>
              <li>
              1.  Term: The term of this Agreement, unless otherwise documented in your sales agreement, begins on the Effective Date (ship date of hardware).
              This Agreement shall automatically renew for one (1) year periods unless you provide written notice of your intent to terminate this Agreement
              no less than sixty (60) days prior to the expiration of any particular term period (the “<b>Term</b>”).
              </li>
              <li>
              2.  Warranty and Liability: <br/>
              <u>Express Hardware and Software Warranty:</u> The Hardware may include a manufacturer’s warranty.  Information concerning this warranty may be provided upon request by you.
              The Software is warranted against manufacturer defects for one (1) year from the Effective Date (the “<b>Equipment and Software Warranty</b>”).
              This Equipment and Software Warranty entitles you to a replacement of the Equipment and Software in the case of a malfunction related to a manufacturing defect only, 
              and does not include replacement for any damage or malfunction caused by you, your customers, or any other third party.
              </li>
              <li>
              a.  <u>Warranty Disclaimer:</u> OTHER THAN THE EXPRESS WARRANTY ABOVE, NEITHER GTC NOR ANY GTC SERVICE PROVIDER MAKE ANY WARRANTY OF ANY KIND REGARDING THE SYSTEM,
              WHICH ARE PROVIDED ON AN "AS IS" BASIS. GTC EXPRESSLY DISCLAIMS ANY REPRESENTATION OR WARRANTY THAT THE SYSTEMS WILL BE ERROR-FREE, SECURE OR UNINTERRUPTED.
              </li>
              <li>
              b.  <u>Implied Warranties:</u> GTC (INCLUDING ITS SERVICE PROVIDERS) EXPRESSLY DISCLAIMS ALL IMPLIED WARRANTIES AND CONDITIONS, INCLUDING IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND THOSE ARISING BY STATUTE OR OTHERWISE IN LAW OR FROM A COURSE OF DEALING OR USAGE OF TRADE.
              </li>
              <li>
              c.  <u>Contents and Personal Injury:</u> GTC expressly disclaims, and you expressly assume, all risk for loss or damage to your monitored vehicle, its contents,
              and for personal injury to persons occupying or affected by your monitored vehicle.
              </li>
              <li>
              d.  <u>Limitations, Exclusions and Disclaimers</u> <br/>
              Damage Limitation: GTC SHALL NOT BE LIABLE FOR ANY GENERAL, DIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE, AND/OR CONSEQUENTIAL DAMAGES RELATED TO THE SYSTEMS OR YOUR USE OF THE SYSTEMS.
              Furthermore, you acknowledge that no express or implied warranty shall be deemed created by any statement made to GTC or its representatives outside of this Agreement. <br/>
              i.  Limitation of Liability: You acknowledge and agree that GTC is not an insurer and does not insure the Systems or your assets. You are hereby made aware that you must obtain or cause your customers
              to obtain insurance for the Systems or assets in an amount which you deem to be desirable. The monthly amount paid by you is based upon the Systems (including the software and services contained therein)
              and is unrelated to the value of your property or assets. You hereby waive all subrogation and other rights of recovery against GTC that any insurer or other person may have. You agree that, 
              since GTC does not know the extent of any potential damages as a result thereof, GTC cannot and will not be held responsible for interruptions in service of the Device.  
              Because of the difficulty to determine actual damages that may result from failure to perform duties under this Agreement, if it is determined that GTC or any of its agents, employees, subsidiaries, affiliates,
              assigns, or parent companies are directly or indirectly responsible for any such loss, damage, injury or other consequence, you agree that damages shall be limited to $2,000 or 20% of the annual charges you pay 
              under this Agreement, whichever is less. These agreed upon damages are not a penalty, but rather are an agreement by the Parties to be the sole remedy to you no matter how the loss is caused.  
              The purpose for this is to provide a quick resolution to a dispute (which would benefit you) while limiting the potential ceiling for any such amount (which would benefit us).  
              Specifically as it relates to the use of the Devices and not by way of limitation of any other portion of this paragraph, Customer agrees that GTC is not responsible for any taxes, fines, fees, 
              or penalties associated with the failure of the Devices to remain powered, connected to the internet or other devices, or mechanically viable. 
              </li>
              <li>
              e.  <i>Exclusive Remedy:</i> This Section contains your exclusive remedy against GTC. <br/>
              <i>Indemnification and Hold Harmless:</i> In the event any lawsuit or other claim is threatened or filed by any party against GTC or its agents, employees, subsidiaries, affiliates, or parent companies arising out,
              related to, or in connection with the Systems, you agree to be solely responsible for, and to indemnify and hold GTC completely harmless from such lawsuits or other claims, 
              regardless of the type or nature of such lawsuit or claim.  Accordingly, you are required to pay all damages, expenses, costs and attorney's fees and expenses associated therewith. 
              This Section shall survive the termination of this Agreement.<br/>
              i.  <i>Statute of Limitations:</i> Any lawsuit or other action you may have against GTC or agents, employees, subsidiaries, affiliates,
              or parent companies must be filed within one (1) year from the date of the event which caused the loss, damage or liability.<br/>
              <i>ii. GPS and Cellular Service:</i> The System receives signals from the Global Positioning Satellite ("<b>GPS</b>") system and transmits signals to, and receives signals from, 
              GTC third party providers (the “<b>Providers</b>”) and to a Service Center ("<b>SC</b>") and/or directly over the Internet to various devices. The Providers and SC services may be provided in any method determined by GTC. 
              The System does not work with any other providers. You understand that the System installed in the monitored vehicle uses cellular telephone technology as the transmission mode of sending signals to you. 
              Services are only available to you in the United States and Canada and only when in a cellular coverage area. Services may be limited, interrupted, or discontinued, without liability to InTouch, due to many conditions.  
              GPS systems will not be available if satellite signals are somehow disrupted, blocked, or otherwise obstructed.  In order for the GPS system to work properly, the System must be outside with a clear line of site to the satellite.  
              You acknowledge that the reliability of the GPS and cellular networks are fundamental to the effectiveness of the product and that GTC does not have any control over the utility of that system.  
              You further understand and explicitly accept the risk of the GPS and cellular system working properly and agree that GTC shall not be responsible for the workings of either system. 
              You further acknowledge and agree that service interruptions in either system will occur and you accept the risk of such interruptions. Accordingly, you agree that GTC shall not, in any way, be liable for,
              or have responsibility with respect to, the GPS system, any of the information therein obtained, or for interruptions in service for any reason whatsoever.   
              You also understand that when the System is damaged, that GTC does not receive signals and that you are in the closest and best position to understand when damage has occurred or the system is not working properly. 
              You further acknowledge and agree that GTC shall not have any liability related to the System for any issues occurring due to lightning, power failures, riots, floods, fire, war or acts of God. 
              The use of radio frequencies and cellular devices are controlled by the Federal Communications Commission and that changes to its rules could potentially end the business of GTC.
              </li>
            </ul>
          </li>
          <li>
          1.  Installation and Maintenance: You are responsible for installing, activating and maintaining the System. You understand and agree that GTC is not responsible for, and shall have no liability for the System’s installation into the monitored vehicle.
          You understand that government regulations apply to the proper operation of the System and that, in the event that the system is not operating properly, whether through a lack of power, connectivity, or otherwise, 
          you are responsible to keep accurate logs related to the system to comply with those regulations.  You further acknowledge and agree that GTC is not responsible to provide any backup information to you in order for you to comply with the regulations.
          We are not an insurer that your regulatory requirements are being met, but a tool to assist you.  If the System is not working, it is your responsibility to maintain compliance with regulations.

          </li>
          <li>
          2.  Authorized Use: You agree that you will only place the Systems on vehicles which you own.
          You further agree that you and any agent of yours will use the Systems only for lawful purposes and will not tamper with the device or in any way deviate from the intent of the parties with respect to the use of the Systems.  
          </li>
          <li>
          3.  Termination: This Agreement may be terminated immediately at the option of GTC at any time upon the occurrence of any of the following events:
          your default under this Agreement or failure to perform as required by this Agreement;<br/>
          a.  your default in payment due under this Agreement;<br/>
          b.  in the event governmental regulations or limitations necessitate the discontinuance of the System.

          </li>
        </ul>
      </li>
      <li>
      1.  Access to Services: Access to Services may be provided by means of a ten-digit cellular telephone number (the "<b>Equipment Number</b>"), which is owned by GTC. GTC reserves the right to change the Equipment Number upon ten (10) days prior notice to you, 
      except that no notice is required if GTC suspects fraud. The Equipment Number may not be transferred or changed without the prior written consent of GTC. You are responsible for all calls placed by or to the Equipment Number.
      </li>
      <li>
      2.  Requests for Services: You authorize GTC to make requests for information, service, orders or equipment in any respect on behalf of you to Service Providers, as GTC determines to be necessary or desirable.
      </li>
      <li>
      3.  Privacy Waiver: You agree that, in the interest of providing internal training based on customer interactions, GTC may monitor or record your conversations with GTC personnel involved in providing you Services under this Agreement. Further, 
      you agree that GTC cannot ensure privacy in a cellular phone or GPS setting and, resultantly, GTC shall not liable to you for any claims, losses, damages or costs resulting from any lack of privacy. You acknowledge and agree that, while GTC will not identify you personally,
      we may analyze and distribute your information and statistics to offer new products or to enforce the terms of this Agreement.  You hereby grant us the full authority, without limitation, to provide any governmental authority, without your knowledge or further permission,
      past, present, or future information concerning the System.  Your System may or may not include cameras or other recording devices.  To the extent that a locality regulates privacy-related matters, 
      you waive your right to claim any breach of privacy against you or any of your agents or employees, and you further agree to fully indemnify and hold GTC harmless against any claims from any third parties related to privacy-related matters.  
      </li>
      <li>
      4.  Assigns: This Agreement is not assignable by you except upon the prior written consent of GTC. GTC shall have the right, in whole or in part, to assign this Agreement or to subcontract any of its obligations under this Agreement without notice to you.
      </li>
      <li>
      5.  Notices.  Any notice, communication, request, reply or advice (hereinafter severally and collectively, for convenience, called “<b>Notice</b>”) in this Agreement provided or permitted to be given, made or accepted by either Party to the other, must be in writing,
      addressed to the person to be notified (in the event that such person is an entity, the address to be used is the address of record with the Secretary of State in the state of formation), and either delivered in person to the Party or an officer of such Party,
      sent via certified mail return receipt requested, or sent by a nationally recognized overnight courier for next business day delivery.  Notice deposited in the mail or via overnight courier in the manner hereinabove described shall be effective, unless otherwise stated in this Agreement, 
      from and after the date it is so deposited. Notice given in any other manner shall be effective only if and when received by the Party to be notified.  
      Any Party may change the address to which Notices are to be delivered by giving the other Parties notice in a manner consistent with the notice requirements described above.
      </li>
      <li>
      6.  Headings.  The headings in this Agreement are intended solely for convenience of reference and shall be given no effect in the construction or interpretation of this Agreement.
      </li>
      <li>
      7.  Counterparts.  This Agreement may be executed in multiple counterparts, each of which shall be deemed an original, and all of which together shall constitute one and the same document.
      </li>
      <li>
      8.  Governing Law.  This Agreement shall be governed by and construed under Florida law, without regard to conflict of laws principles.
      </li>
      <li>
      9.  Exclusive Jurisdiction and Venue.  Each Party hereto consents and agrees, with respect to any claim or cause of action, whether in law or equity, including specific performance, arising under or in any way relating to this Agreement, to the exclusive jurisdiction of, and venue in,
      Polk County, Florida, or if in federal court, in the Middle District of Florida, Tampa Division.  Each Party hereto waives any objection based on forum non conveniens and waives any objection to venue of any action instituted hereunder.
      </li>
      <li>
      10. Waivers.  Compliance with the provisions of this Agreement may be waived only by a written instrument specifically referring to this Agreement and signed by the Party waiving compliance.  No course of dealing, nor any failure or delay in exercising any right, shall be construed as a waiver,
      and no single or partial exercise of a right shall preclude any other or further exercise of that or any other right.
      </li>
      <li>
      11. Pronouns.  The use of a particular pronoun herein shall not be restrictive as to gender or number but shall be interpreted in all cases as the context may require.
      </li>
      <li>
      12. Time Periods.  Any action required hereunder to be taken within a certain number of days shall be taken within that number of calendar days; provided, however, that if the last day for taking such action falls on a weekend or a holiday, 
      the period during which such action may be taken shall be automatically extended to the next business day.
      </li>
      <li>
      13. No Strict Construction.  The language used in this Agreement will be deemed to be the language chosen by the Parties hereto to express their mutual intent, and no rule of strict construction will be applied against either Party.
      </li>
      <li>
      14. Modification.  No supplement, modification or amendment of this Agreement shall be binding unless made in a written instrument which is signed by all of the Parties and which specifically refers to this Agreement.
      </li>
      <li>
      15. Entire Agreement.  This Agreement and the agreements and documents referred to in this Agreement or delivered hereunder are the exclusive statement of the agreement among the Parties concerning the subject matter hereof.
      All negotiations among the Parties are merged into this Agreement, and there are no representations, warranties, covenants, understandings, or agreements, oral or otherwise, in relation thereto among the Parties other than those incorporated herein and to be delivered hereunder.
      </li>
      <li>
      16. Attorneys’ Fees.  If any legal action, arbitration, or other proceeding is brought for the enforcement of this Agreement, or because of an alleged dispute, breach, default, or misrepresentation in connection with any of the provisions of this Agreement, 
      the successful or prevailing Party or Parties will be entitled to recover reasonable attorney fees and other costs incurred in that action or proceeding, in addition to any other relief to which they may be entitled.
      </li>
    </ul>
    Electronic Signature: By clicking or giving an authorized representative permission to click the "I Agree to the Terms and Conditions" checkbox on the website, you agree to be bound by the terms and conditions of this Agreement, furthermore, understand, acknowledge,
    and agree that by clicking or giving an authorized representative permission to click the "I Agree to the Terms and Conditions" you have affixed your electronic signature to this Agreement and are bound to the aforementioned terms and conditions as required under the Uniform Electronic Transactions Act ("UETA"),
    which establishes the legal validity of electronic signatures and contracts in the U.S.A.
  </div>`
};

