import {
  //TYPES
  MAP_GEOFENCE_GROUP_HIDE,
  MAP_GEOFENCE_NEW,
  MAP_GEOFENCE_NEW_RESPONSE,
  MAP_GEOFENCE_RESTART_PROCESS
} from "Redux/actionTypes";

const INIT_STATE = {
  hiddenGeofenceGroups: [],
  loadingGeofence: false,
  process: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MAP_GEOFENCE_GROUP_HIDE:
      return { ...state, hiddenGeofenceGroups: action.payload };

    case MAP_GEOFENCE_NEW:
      return { ...state, loadingGeofence: true, process: false }
  
    case MAP_GEOFENCE_NEW_RESPONSE:
      return { ...state, loadingGeofence: false, process: action.payload.result, error: action.payload.error }
    
    case MAP_GEOFENCE_RESTART_PROCESS:
      return { ...state, process: false }

    default:
      return state;
  }
};