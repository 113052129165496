//imports
import { all } from 'redux-saga/effects';

import realtimeMapSpeedOverrideCreateEditSaga from 'Modules/realtimeMaps/main/components/speedOverride/components/speedOverrideCreateEdit/redux/saga';
import realtimeMapSpeedOverrideDeleteSaga from 'Modules/realtimeMaps/main/components/speedOverride/components/speedOverrideDelete/redux/saga';
import realtimeMapSpeedOverrideListSaga from 'Modules/realtimeMaps/main/components/speedOverride/components/speedOverrideList/redux/saga';

//merge sagas
export default function* rootSaga() {
    yield all([
        realtimeMapSpeedOverrideCreateEditSaga(),
        realtimeMapSpeedOverrideDeleteSaga(),
        realtimeMapSpeedOverrideListSaga()
    ]);
}