import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
// controls
import Table, {Column} from '../../table'

import {
    formatDatetime,
    getCategoryFilterType,
    getEntityType,
    getMethodToFormatDuration,
    useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';

// hooks
import {useSidePanelMap, useTableReport} from 'Core/hooks';

// actions
import {clearRedux, reportsMainGenerateEvents, reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

// styles
import './landmarkCrewReport.scss';

const LandmarkCrewReport = (props) => {

    const {messages, report, user} = props;
    const { durationFormat } = user;
    const dispatch = useDispatch();
    const {loading, data, filters, loadingSummary, timeZone, summary} = report;
    const {filterEntity, filterArray, filterType, startRange, endRange} = filters;
    const [SideMapComponent, onToggleSideMap] = useSidePanelMap();

    const [unitDriverSelected, setUnitDriverSelected] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
    const [currentNewSortField, setCurrentNewSortField] = useState("deviceId");
    const [currentNewSortOrder, setCurrentNewSortOrder] = useState("asc");
    const [currentId, setCurrentId] = useState(null);

    const [eventFilter, setEventFilter] = useState({});
    const exportHandler = useHandleExportReport(report);

    const loadingCreateLandMark = useSelector(state => state.vehicleLandMarkRedux.loadingCreateLandMark)
    const createLandMarkResult = useSelector(state => state.vehicleLandMarkRedux.createLandMarkResult)
    const reportRef = useRef();
    useEffect(() => {
        if (report) {
            const newReport = JSON.parse(JSON.stringify(report));
            reportRef.current = newReport;
        }
    }, [JSON.stringify(report)]);

    useEffect(() => {
        if (!loadingCreateLandMark && createLandMarkResult) {
            dispatch(reportsMainGenerateReportUpdate(report, currentPage, currentRowsPerPage, currentNewSortField, currentNewSortOrder))
            dispatch(clearRedux("LANDMARK", {"createLandMarkResult": null}));
        }
    }, [loadingCreateLandMark, createLandMarkResult])

    const durationFormatTime = getMethodToFormatDuration(durationFormat);
    const idEventsPerDay = (selected, id) => {
        let currentReport = report
        delete currentReport.summary
        dispatch(reportsMainGenerateReportProgress(currentReport))
        let events = {
            filters: {
                filterType: getCategoryFilterType(filterEntity),
                filterArray: [currentId],
                page: 1,
                rowsPerPage: 10,
                startRange: moment(selected).startOf('day').utc().toDate(),
                endRange: moment(selected).endOf('day').utc().toDate()
            }
        }
        setEventFilter(events)
        dispatch(reportsMainGenerateEvents(report, events))
    }

    const renderExpandableRowSubEvents = (row) => {
        return (
            <div style={{marginBottom: 10}}>
                <Table
                    loading={loadingSummary}
                    data={summary?.items || []}
                    totalItems={summary?.total}
                    onChangedPage={(page) => {
                        let currentEvents = eventFilter
                        currentEvents.filters.page = page
                        dispatch(reportsMainGenerateEvents(report, currentEvents))
                    }}
                    noDataMessage={messages["generatedReport_noEvents"]}
                    hideNumberOfRows={true}
                    classNameColumns={'report-sub-events-columns'}
                    centerPagination
                    serverSideTable={true}
                >
                    <Column label={messages['generatedReport_timeAndDate']}>
                        {(element) => <span>{formatDatetime(element.date, "MMMM D, YYYY h:mm A", timeZone)}</span>}
                    </Column>
                    <Column label={messages['landmark']}>
                        {(element) => <span>{element.landmarkName}</span>}
                    </Column>
                    <Column label={filterEntity === "Unit" ? messages['generatedReport_driver'] : messages['generatedReport_unit']}>
                        {(element) => <span>{element.idLabel}</span>}
                    </Column>
                    <Column label={messages['generatedReport_crewMember']}>
                        {(element) => <span>{element.crewMemberName}</span>}
                    </Column>
                    <Column label={messages['generatedReport_crewMemberDuration']}>
                        {(element) => <span>{element.crewMemberDuration ? durationFormatTime(element.crewMemberDuration, messages) : '0 min, 0 sec'}</span>}
                    </Column>
                    <Column label={messages['generatedReport_vehicleTotalTimeInLandmark']}>
                        {(element) => <span>{element.vehicleDuration ? durationFormatTime(element.vehicleDuration, messages) : '0 min, 0 sec'}</span>}
                    </Column>
                </Table>
            </div>
        )
    }

    const onChangeExpandable = (selected) => {
        if(!selected.noData){

            let selectedId = (filterEntity === "Unit" ? selected.deviceId : selected.driverId) || selected.id
            setCurrentId(selectedId);
        }
    }

    let parametersTableSummary = useTableReport({
        data: [],
        report,
        messages,
        hideHeader: true,
        hiddenPagination: true,
        renderExpandableRow: renderExpandableRowSubEvents,
        showExpandable: true
    });

    const renderExpandableRow = (row) => {
        const summaryParameters = {
            ...parametersTableSummary,
            data: row?.dates,
            onChangeSelector: null,
            placeholderSelector: null,
            hideSelectorUnitsDriver: true,
            classNameColumns: "table-report-main-sub-table",
            idRenderExpandableRow: (event) => {idEventsPerDay(event, row?.id)},
            noData: row.noData
        }
        return (
            <Table
                {...summaryParameters}
            >
                <Column label="day" field="label">
                    {(element) => {
                        return <span><strong>{formatDatetime(element, "dddd, LL")}</strong></span>
                    }}
                </Column>
                <Column label={messages['generatedReport_totalIdleTime']} field="label"></Column>
            </Table>
        )
    }

    let parametersTable = useTableReport({
        data: data.items,
        report,
        messages,
        hideHeader: true,
        renderExpandableRow,
        onChangeExpandable,
        propertyToShowExpandable: 'id',
        classNameColumns: "table-report-main-expandible"
    });

    return (
        <Fragment>
            <Table
                {...parametersTable}
                {...exportHandler}
            >
                <Column noSorting>
                    {(element) => {
                        const type = getEntityType(report, messages)
                        return <span><strong>{type}:  </strong>{`${element.idLabel || ''}`}</span>
                    }}
                </Column>
                <Column noSorting>
                    {() => {
                        const localTimeStart = moment.utc(startRange).local().format();
                        const localTimeEnd = moment.utc(endRange).local().format();
                        return (<div>
                      <span>
                        <span>
                          <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                        </span>
                      </span>
                    </div>);
                    }}
                </Column>
            </Table>
        </Fragment>
    );
};

export default LandmarkCrewReport;
