import {
  FIND_NEAREST_SEARCH,
  FIND_NEAREST_SEARCH_RESPONSE,
  FIND_NEAREST_OPEN_DIRECTIONS_MODAL,
  CLEAR_REDUX
} from 'Redux/actionTypes';
const INIT_STATE = {
  items: [],
  total: 0,
  error: false,
  errorMessage: "",
  loading: false,
  openDirectionsModal: false,
  directionsModalData: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FIND_NEAREST_SEARCH:
      return { ...state, error: false, errorMessage: "", loading: true };

    case FIND_NEAREST_SEARCH_RESPONSE:
      const { items, total, error, message } = action.payload.data;
      const data = total === 0 ? items : [ ...state.items, ...items ];
      return { ...state, items: data, total: total, error: error, errorMessage: message, loading: false };

    case FIND_NEAREST_OPEN_DIRECTIONS_MODAL:
      return { ...state, openDirectionsModal: action.payload.open, directionsModalData: action.payload.data };
    
    case CLEAR_REDUX:
      return ["", "FIND_NEAREST_REDUX"].includes(action.payload.option)
      ? action.payload.parameters
        ? { ...state, ...action.payload.parameters }
        : { ...INIT_STATE }
      : { ...state };

    default:
      return state;
  }
}