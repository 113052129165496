/*********************************************************
 * Imports
*********************************************************/
import React from 'react';
import { injectIntl } from "react-intl";
import { Icon } from "@mui/material";
import { Tooltip } from 'Components';
/*********************************************************
 * Class to add button Fit bounds Vehicles
 ********************************************************/
const ButtonFitBoundsVehicles = (props) =>  {
    const { intl, mapRef } = props
    const { messages } = intl
  
    /********************************************************
     * Event to open legend component
     ********************************************************/
    const onCenterVehicles = () => {
        mapRef?.current?.centerVehicles()
    }
     
    /********************************************************
     * Return the legend component
     ********************************************************/

    return (
        <div>
            <Tooltip placement="left" title={messages['maputilities_fitbounds_vehicles']}>
                <button
                    onClick={onCenterVehicles}
                >
                    <Icon>group_work</Icon>
                </button>
            </Tooltip>
        </div>
    )
 }
 
 export default injectIntl(ButtonFitBoundsVehicles);