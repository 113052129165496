import React, { PureComponent, Fragment } from 'react';

import { Icon, IconButton, Grid } from '@mui/material';

import Pill from 'Components/Pill';
import Loading from 'Components/Loading';
import './fileupload.scss';
import { connect } from 'react-redux';
import { uploadAttachment } from 'Redux/actions';

export class FileUploadComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      errorMessage: null,
      componentEvent:null
    };

    this.validate = this.validate.bind(this);
    this.closePillAttachment = this.closePillAttachment.bind(this);
    this.uploadImg = this.uploadImg.bind(this);
    this.addNewImage = this.addNewImage.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Uso tipico (no olvides de comparar los props):

    if (this.props.uploadedAttachment !== prevProps.uploadedAttachment) {

      if (this.props.uploadedAttachment && !prevProps.uploadedAttachment) {
        //console.log("this.props.id",this.props.id,"this.state.componentEvent?.target?.id",this.state.componentEvent?.target)
        ( this.props.id && this.state.componentEvent?.target?.id) && this.addNewImage(this.props.uploadedAttachment) ||  !this.props.id && this.addNewImage(this.props.uploadedAttachment) ;
      } else if (
        this.props.uploadedAttachment
        && this.props.uploadedAttachment?.id != prevProps.uploadedAttachment?.id
      ) {
        const newFile = this.props.uploadedAttachment;
        let exists;
        if (this.props.value) {
          exists = this.props.value.find(file => file.id === newFile.id);
        }
        if (!exists) {
          ( this.props.id && this.state.componentEvent?.target?.id) && this.addNewImage(newFile) || !this.props.id && this.addNewImage(newFile)  ;
        }
      }
    }
  }

  
  
  addNewImage(image) {
    this.setState({componentEvent:null})
    let files = this.props.value || [];
    files = files.concat([image]);
    if (this.props.onChange) this.props.onChange(files);
  }

  validate(val) {
    let isValid = true;
    let errorMessage;
    let value = val || this.props.value;
    if (this.props.required) {
      if (!value || value.length === 0) {
        isValid = false;
        errorMessage = this.props.requiredMessage;
      }
    }

    if (this.props.maxSize) {
      let bytes = this.props.maxSize * 1000000;
      if (value[0]?.size > bytes) {
        isValid = false;
        errorMessage = this.props.maxSizeMessage;
      }
    }

    this.setState({ isValid, errorMessage });
    return isValid;
  }

  closePillAttachment(item) {
    let files = this.props.value || [];
    files = files.filter(file => file.id != item.id)
    if (item.fileUri) {
      //delete
    }
    this.validate(files);
    if (this.props.onChange) this.props.onChange(files);
  }

  uploadImg(e) {
    //** saving component event for validation */
    e.persist();
    this.setState({componentEvent:e})
    const fileKey = e.target.files[0];
    const payload = {
      fileKey
    };
    if (fileKey && !this.props.onReadFile) {
      this.props.uploadAttachment(payload);
    }else{
      if(this.props.onReadFile){
        this.props.onReadFile(fileKey);
      }
    }
  }

  render() {
    return (
      <div className="form-item fileupload-container">
        {
          //LABEL AREA
          this.props.label && (
            <label className="input-normal-label">
              {this.props.label}
              {!this.props.notRequiderdShow && this.props.required && (
                <span className="text-danger"> *</span>
              )}
            </label>
          )
        }
        {this.props.loadingUploadFile ? (
          <div className="input-loading">
            <Loading />
          </div>
        ) : (
            <Fragment>
              <div className="fileupload-container-input">
                <div className="fileupload-input" onClick={() => this.refs.inputFile.click()}>
                  {this.props.placeholder}
                  <input
                    ref="inputFile"
                    id={this.props.id}
                    name={this.props.name}
                    type="file"
                    multiple={this.props.multiple}
                    accept={this.props.types || '*'}
                    style={{ display: 'none' }}
                    onClick={e => {
                      if (!this.props.multiple) e.target.value = null;
                    }}
                    onChange={(e) => {
                      if (this.validate(e.target.files)) {
                        this.uploadImg(e);
                      }
                    }}
                    required={this.props.required}
                    disabled={this.props.disabled}
                  />
                </div>
                <div className="fileupload-icon-button">
                 { 
                    !this.props.disabled &&
                    <IconButton
                      aria-label="placeholder"
                      onClick={() => this.refs.inputFile.click()}
                      size="large">
                      <Icon>add</Icon>
                    </IconButton>
                  }
                </div>
              </div>
              {this.props.value && this.props.value.length > 0 && (
                <div className="fileupload-file-container">
                  <Grid container spacing={1} direction="column">
                    {this.props.value.map((item, key) => {
                      return (
                        <Grid item sm={12} key={key}>
                          <Pill outlined={true}
                            item={item}
                            textField="originalName"
                            colorField="color"
                            isDownload
                            noDownloadButton={this.props.noDownloadButton == false ? this.props.noDownloadButton : true}
                            onClose={this.closePillAttachment}
                            noDelete={this.props.noDelete}
                          ></Pill>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              )}

              {this.props.helpText && (
                <small id={`${this.props.id}-help`} className="form-text text-muted">
                  {this.props.helpText}
                </small>
              )}

              {!this.state.isValid && this.state.errorMessage && (
                <small className="text-danger" style={{ paddingTop: 5 }}>
                  {this.state.errorMessage}
                </small>
              )}
            </Fragment>
          )}
      </div>
    );
  }
}

const mapStateToProps = ({ commomRedux }) => {
  const { uploadedAttachment, loadingUploadFile } = commomRedux;
  return {
    uploadedAttachment,
    loadingUploadFile
  };
};
export default connect(mapStateToProps, {
  uploadAttachment
})(FileUploadComponent);

