// Dependencies
import {
  all,
  call,
  fork,
  put,
  takeEvery,
} from 'redux-saga/effects';

// Logs
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

// Components
import { NotificationManager } from "Components/Notifications";

// Action Types
import {
  ALERTS_TAB_GET_ALERTS,
  ALERTS_TAB_DELETE_ALERTS,
  ALERTS_TAB_DELETE_ALERT_SUBSCRIBER,
  ALERTS_TAB_GET_ALERT,
} from 'Redux/actionTypes';

//Actions
import {
  searchAlertsTabResult,
  saveAlertsResult,
  searchAlertsTab,
  alertsOpenModalSubscribers,
  searchSystemAlert,
} from 'Redux/actions';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';

function* getAlertServicesRequest({ payload: alertId }) {
  try {
    const query = `query getSystemAlert ($id: String!) {
      getSystemAlert (id: $id) {
          id
          name
          type
          configJSON
          severity
          summary
          triggerCount
          triggers {
              id
              entity {
                  name
                  id
                  label
              }
          }
          assignmentsCount
          assignedTo {
              id
              entity {
                  name
                  id
              }
          }
          subscribersCount
          subscribers {
              id
              contactId
              contact {
                  id
                  title
                  address
                  type
              }
              alert
              alertBefore
              alertAfter
              createdOn
              createdBy
              updatedOn
              updatedBy
          }
          schedule {
              frequency
              timeFrom
              timeTo
              customSchedule {
                  day
                  timeFrom
                  timeTo
              }
              occurrences
              occurrencesLimitPerDay
          }
          createdOn
          createdBy
          updatedOn
          updatedBy
      }
    }`;

    const variables = {
      id: alertId,
    };

    const data = yield call(clientQuery, query, variables, endpoints.GRAPHQL_GENERAL);
    if (data?.getSystemAlert) {
      yield put(alertsOpenModalSubscribers(data.getSystemAlert));
    } else {
      throw 'Not services';
    }
  } catch (exc) {
    ExceptionManager(exc, 'modules/alerts/views/main/components/alertsTab/redux/saga', 'getSystemAlert');
  }
}

function* getAlertsServicesRequest({ payload }) {
  try {
    const query = `
      query (
        $openSearch: String, 
        $types: [enumAlertType], 
        $assignedTo: [EntityInput], 
        $severities: [Severity], 
        $orderBy: String,
        $desc: Boolean,
        $limit: Int, 
        $offset: Int
      ) {
        Services: searchSystemAlerts(
            openSearch: $openSearch, 
            types: $types, 
            assignedTo: $assignedTo, 
            severities: $severities,
            orderBy: $orderBy,
            desc: $desc,
            limit: $limit, 
            offset: $offset
          ) {
            items {
              id
              name
              type
              configJSON
              severity
              summary
              triggerCount
              triggers {
                  id
                  entity {
                      name
                      id
                      label
                  }
              }
              assignmentsCount
              assignedTo {
                  id
                  entity {
                      name
                      id
                  }
              }
              subscribersCount
              subscribers {
                  id
                  contactId
                  contact {
                      id
                      title
                      address
                      type
                  }
                  alert
                  alertBefore
                  alertAfter
                  createdOn
                  createdBy
                  updatedOn
                  updatedBy
              }
              schedule {
                  frequency
                  timeFrom
                  timeTo
                  customSchedule {
                      day
                      timeFrom
                      timeTo
                  }
                  occurrences
                  occurrencesLimitPerDay
                  afterConsecutiveEvents
              }
              buildSummary
            },
            total
          }
      }
    `;

    let variables = {
      openSearch: '',
      limit: 10,
      offset: 0,
    };

    if (payload) {
      variables = { ...variables, ...payload };
    }

    const data = yield call(clientQuery, query, variables, endpoints.GRAPHQL_GENERAL);

    if (data?.Services) {
      //yield put(saveAlertsResult(data.Services.items));
      yield put(searchAlertsTabResult(data.Services));
    } else {
      throw 'Not services';
    }
  } catch (exc) {
    ExceptionManager(exc, 'modules/alerts/views/main/components/alertsTab/redux/saga', 'getAlertsServicesRequest');
    yield put(searchAlertsTabResult([]));
  }
}

function* deleteAlertsServicesRequest({ payload }) {
  const { alerts, successDeletionMessage, failedDeletionMessage } = payload;

  try {
    const mutation = `mutation deleteSystemAlert ($id: String!) {
      deleteSystemAlert (id: $id)
    }`;

    const response = yield all(alerts.map((alert) => call(
      clientMutation,
      mutation,
      { id: alert.id },
      endpoints.GRAPHQL_GENERAL,
    )));

    const reducer = (
      accumulatedValue,
      { deleteSystemAlert: currentValue },
    ) => accumulatedValue && currentValue;

    const successResponse = response.reduce(reducer, true);

    if (successResponse && successDeletionMessage) {
      NotificationManager.success(successDeletionMessage, '', 3000, null, null, '', null);
      yield put(searchAlertsTab());
    } else if (failedDeletionMessage) {
      NotificationManager.error(failedDeletionMessage, '', 3000, null, null, '', null);
    }
  } catch (exc) {
    ExceptionManager(exc, 'modules/alerts/views/main/components/alertsTab/redux/saga', 'deleteSystemAlert');
  }
}

function* deleteAlertSubscriberServicesRequest({ payload }) {
  const {
    alertSubscriberId,
    alertId,
    successDeletionMessage,
    failedDeletionMessage,
  } = payload;

  try {
    const mutation = `mutation deleteAlertSubscriber ($id: Int!) {
      deleteAlertSubscriber (id: $id)
  }`;

    const variables = {
      id: alertSubscriberId,
    };

    const response = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL);

    if (response.deleteAlertSubscriber) {
      NotificationManager.success(successDeletionMessage, '', 3000, null, null, '', null);
      yield put(searchSystemAlert(alertId));
      yield put(searchAlertsTab());
    } else {
      NotificationManager.error(failedDeletionMessage, '', 3000, null, null, '', null);
    }
  } catch (exc) {
    ExceptionManager(exc, 'modules/alerts/views/main/components/alertsTab/redux/saga', 'deleteAlertSubscriber');
  }
}

function* watchAlersTab() {
  yield takeEvery(ALERTS_TAB_GET_ALERTS, getAlertsServicesRequest);
  yield takeEvery(ALERTS_TAB_DELETE_ALERTS, deleteAlertsServicesRequest);
  yield takeEvery(ALERTS_TAB_DELETE_ALERT_SUBSCRIBER, deleteAlertSubscriberServicesRequest);
  yield takeEvery(ALERTS_TAB_GET_ALERT, getAlertServicesRequest);
}

//Saga Export
export default function* rootSaga() {
  yield all([fork(watchAlersTab)]);
}
