import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./styles.scss";
import {
  DeviceInfo,
  Events,
  IncidentInfo,
  Map,
  Title,
  Trails,
} from "./components";
import { HasPermission } from "Core/security"


export const HistoryOverView = () => {
  const [map, setMap] = useState(null);
  const [readingSelected, setReadingSelected] = useState(null);
  const [numberSelected, setNumberSelected] = useState()

  const { isOpenHistoryOverViewIncident } = useSelector(
    (state) => state.incidentsRedux
  );
  const { user } = useSelector((state) => state.securityRedux);
  const { isKph } = user

  const dateRange = {
    start: isOpenHistoryOverViewIncident?.data?.incident?.start,
    end: isOpenHistoryOverViewIncident?.data?.incident?.end,
  };

  if (!isOpenHistoryOverViewIncident?.open) {
    return null;
  }

  const hasVideo = isOpenHistoryOverViewIncident?.data?.unit?.hasVideo && HasPermission(user, 'vidfleetvideo', [217])
  const type = isOpenHistoryOverViewIncident?.data?.unit?.type

  const focusTrail = (trail = null) => {
    if (!trail) return;
    const { latitude, longitude } = trail;
    if (!latitude && !longitude) return;
    map.setCenter({ lat: latitude, lng: longitude });
  };

  const clearFields = () => {
    setMap(null)
  }


  return (
    <div className="incident_modal-overlay">
      <div
        className="incident_modal-body"
        style={{ width: hasVideo ? "100%" : "75%", textAlign: 'left' }}
      >
        <Title clearFields={clearFields}/>
        <div className="incident_liveVideo_map">
          <Map
            map={map}
            setMap={setMap}
            setReadingSelected={setReadingSelected}
            setNumberSelected={setNumberSelected}
          />
        </div>
        <div className="incident_container_overView">
          {hasVideo && <Events dateRange={dateRange} />}
          <Trails
            dateRange={dateRange}
            setNumberSelected={setNumberSelected}
            unitType={type}
            isKph={isKph}
          />
          <div style={{width: '40%'}}>
            <DeviceInfo numberSelected={numberSelected}  readingSelected={readingSelected} />
            <IncidentInfo />
          </div>
        </div>
      </div>
    </div>
  );
};
