import {
    MAPS_ETA_LINK_GET,
    MAPS_ETA_LINK_GET_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';
const INIT_STATE = {
    response: null,
    error: "OK",
    loading: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MAPS_ETA_LINK_GET:
            return { ...state, response: null, error: "OK", loading: action.payload.first ? true : false };

        case MAPS_ETA_LINK_GET_RESPONSE:
            return { ...state, response: action.payload.response, error: action.payload?.error, loading: false };

        case CLEAR_REDUX:
            return ["", "MAPS_ETA_LINK"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
            return state;
    }
}