import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

// controls
import InfiniteScrollTable from 'Modules/reports/views/main/components/reportsTab/.components/infiniteScrollTable'
import AddressLandmark from 'Modules/reports/views/main/components/reportsTab/.components/addressLandmark';

//helpers
import {
  formatDatetime,
  useHandleExportReport,
  formatDateFromFilter,
  getGroupId
} from 'Modules/reports/views/main/components/reportsTab/helper';

//hooks
import { useSidePanelMap, useReportsSelector } from 'Core/hooks';
import { GetFormatForMoment } from 'Core/data/Helpers';

//actions
import { reportsMainGenerateReportUpdate } from 'Redux/actions'
// styles
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

const ROW_PER_PAGE = 25;

const NonReportingReport = (props) => {
  const { messages, report, user, copyReportToSetup, openReportSetup, scheduleReport, isActive } = props;
  const { durationFormat } = user;
  const dispatch = useDispatch()
  const { loading, asyncLoading, data, filters, timeZone } = report
  const { filterEntity, filterArray, filterType } = filters;
  const exportHandler = useHandleExportReport(report)
  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
  const [lastEntity, setLastEntity] = useState({});
  const momentFormat = GetFormatForMoment(user.dateformat)
  const startDayFilter = formatDateFromFilter(report.filters.startRange, timeZone)
  const endDayFilter = formatDateFromFilter(report.filters.endRange, timeZone)
  const reportRef = useRef();
  const entitiesArrayRef = useRef();
  const currentIndexScroll = useRef({});
  const detailReportTableRef = useRef();
  const lastEntityRef = useRef({});
  const reportTableSelect = useReportsSelector(report, startDayFilter, endDayFilter, `MMMM DD, YYYY hh:mm A`, filterArray, filterType, timeZone)
  const unitDriverSelected = reportTableSelect.valueSelecteEntity?.length > 0 ? reportTableSelect.valueSelecteEntity : null;
  const dateSelected = reportTableSelect.dateSelected?.length > 0 ? reportTableSelect.dateSelected : null;

  useEffect(() => {
    if (report) {
      const newReport = JSON.parse(JSON.stringify(report));
      // to keep the reference to the units selected
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);

  useEffect(() => {
    if (isActive) {
      reportTableSelect.onChangeResetCache();
    }
  }, [isActive])

  const buildTableHeader = () => {
    return (
      <Grid container item direction={"row"} className={"table-scroll-reports-title-details"}>

        <Grid item sm={4} className={'reports-cut-text'}>
         { messages['generatedReport_unit']}
        </Grid>
        <Grid item sm={1.5} className={'reports-cut-text'} >
          {messages["generatedReport_tag"]}
        </Grid>
        <Grid item sm={3} className={'reports-cut-text'}>
          {messages['generatedReport_LastReportedDateTime']}
        </Grid>
        <Grid item sm={3.5} className={'reports-cut-text'}>
          {messages['generatedReport_LastReportedAddress']}
        </Grid>
      </Grid>
    )
  }

  const TableViewGroup = ({index, style, content, id, previousItem}) => {
    const element = content?.recordInfo;
    const recordInfo = content?.recordInfo;


    return (
      <div
        className="listItem groupItem"
        id={id}
        key={id}
        style={style}
      >

        <Grid container  direction={"column"} alignItems={"flex-start"} className='table-scroll-reports-group'>
            <Grid container item className='table-scroll-reports-header'>
                <Grid item sm={12}>
                  <span className={'color-text-black'}>{formatDatetime(report.filters.startRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} - {formatDatetime(report.filters.endRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} ({getAbbvTimezone(timeZone)})</span>
                </Grid>
            </Grid>
          <div className='table-scroll-reports-separator'></div>
        </Grid>
        {buildTableHeader()}
      </div>
    )
  }

  const TableViewDetail = ({ index, style, content, id, previousItem }) => {
    const element = content?.recordDetail;

    return (
      <div
        className="listItem detailItem"
        style={style}
        id={id}
        key={id}
      >
        <Grid container direction={"column"} style={{ marginBottom: "12px" }}>
          <Grid container spacing={2} item direction={"row"} style={{ paddingTop: "1px", paddingBottom: "1px" }}>
            {
              element.noData ?
                <Grid item className={"report-ctn-text"} sm={12}>
                  {messages["reportsMainRT_noDataEvent"]}
                </Grid>
                :
                <>
                  <Grid item className={"report-ctn-text"} sm={4}>
                    {element.deviceLabel}
                  </Grid>
                  <Grid item className={"report-ctn-text"} sm={1.5}>
                    {element.tagLabelVal}
                  </Grid>
                  <Grid item className={"report-ctn-text"} sm={3}>
                    {element.installed ?
                      (element.noRecentData ?
                        messages['generatedReport_more180Days']
                        :
                        (element.unitTime && formatDatetime(element.unitTime, `h:mm A ${momentFormat || "MMMM D, YYYY"}`, timeZone))
                      )
                      :
                      messages['generatedReport_NotInstalled']
                    }
                  </Grid>
                  <Grid item className={"report-ctn-text"} sm={3.5}>
                  <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={{
                    address: element?.address,
                    landmarkName: element?.landmarkName,
                    landmarkId: element?.landmarkId,
                  }} iconStyle={{ fontSize: 18 }} classNameButtonAddress={'report-address-button'} />
                  </Grid>
                </>
            }
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderItems = (parameters) => {
    const {content} = parameters;
    const type = content.recordInfo?.recordType;
    if(type === "GROUP"){
      const groupId = getGroupId(content)
      const entitiesArray = entitiesArrayRef.current || {};
      if(!entitiesArray[groupId]){
          const newEntityArray = {...entitiesArray};
          newEntityArray[groupId] = content;
          entitiesArrayRef.current = newEntityArray
      }
      // skip the first group beacuse the first group is replaced with the sticky header
      if(parameters.index === 0){
        return (
          <TableViewGroup {...parameters}></TableViewGroup>
        )
      }

    } else if(type === "DETAIL"){
      return <TableViewDetail {...parameters}></TableViewDetail>;
    };

    if(parameters.index === 0){
      return;
    }else{
      return (
        <div>
          unknown type
        </div>
      )
    }


  }

  const onStartIndex = (index) => {
    const item = data?.items[index];

    if (item) {
      if(item.recordInfo?.entityId !== lastEntityRef.current.recordInfo?.entityId || item.recordInfo?.tagId !== lastEntityRef.current.recordInfo?.tagId){
        setLastEntity(item)
      }
      lastEntityRef.current = item
    }
  }

  const renderHeader = () => {
    const groupId = getGroupId(lastEntity);
    const entitiesArray = entitiesArrayRef.current || {};
    const itemHeader = entitiesArray[groupId];
    if (itemHeader) {
      return (
        <TableViewGroup
          style={{
            backgroundColor: '#fff',
            position: 'absolute',
            top: 0,
            zIndex: 2,
            width: 'inherit'
          }}
          content={itemHeader}
          id={itemHeader?.recordInfo?.entityId}
          index={currentIndexScroll.current}
        ></TableViewGroup>
      );
    }
  };

  let isFirstDetail = true
  const itemsReport = []

  data?.items?.forEach(item => {
    itemsReport.push(item)
    if (isFirstDetail) {
      const {recordInfo} = item;
      const cloneRecordInfo = {...recordInfo};
      cloneRecordInfo.recordType = "GROUP"
      itemsReport.unshift({...item, recordInfo: cloneRecordInfo})
      isFirstDetail = false
      data.total += 1;
    }
  });

  const findTag = filterArray?.findIndex((item) => item.name == "Tag")
  const hasTag = findTag >= 0;
  const type = hasTag ? messages['generatedReport_tag'] :
    (report?.filters?.filterType === "deviceId" ? messages['generatedReport_unit'] : messages['generatedReport_driver'])
  return (
    <>
      <InfiniteScrollTable
        refContainer={detailReportTableRef}
        renderItems={renderItems}
        loading={asyncLoading || loading}
        data={itemsReport || []}
        total={data.total}
        reportTitle={messages['reportsMainRT_NonReportingReport']}
        editReport={() => {
          copyReportToSetup({ ...report, data: [] })
        }}
        onSchedule={() => {
          scheduleReport(report)
        }}
        onCreate={openReportSetup}
        tableContainerClass={"detailed-report-reports-container"}
        type={type}
        handleNewPageLoad={(page) => {
          let currentReport = { ...report };
          currentReport.exportType = "json";
          currentReport.filters = {
            ...currentReport.filters,
            unitsSelected: unitDriverSelected?.id != -99 ? unitDriverSelected : null,
            accumulateData: true,
            dateSelected: dateSelected?.id != -99 ? unitDriverSelected : null,
          };
          dispatch(reportsMainGenerateReportUpdate(currentReport, page, ROW_PER_PAGE))
        }}
        renderHeader={renderHeader}
        onStartIndex={onStartIndex}
        isActive={isActive}
        {...reportTableSelect}
        {...exportHandler}
      />
      <SideMapComponent hiddenButton hiddenSearchMap overlayClass={"overlay-class-reports"} />
    </>
  );
}

export default NonReportingReport;
