import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

// components
import { Icon, Grid } from '@mui/material';
import Table, { Column } from '../../table'
import { Accordion } from 'Components';
import AddressLandmark from 'Modules/reports/views/main/components/reportsTab/.components/addressLandmark';

// helpers
import {
  formatDatetime,
  decimalHandler,
  getEntityType,
  getCategoryFilterType,
  getPlaceholderSelector,
  isJson,
  convertUTCtimeToLocalTimeZone,
  converDateToTimeZone,
  converDateToUTC,
  useHandleExportReport,
  getFilterNameByEntity, getMethodToFormatDuration
} from 'Modules/reports/views/main/components/reportsTab/helper';

// hooks
import { useSidePanelMap } from 'Core/hooks';

// actions
import {
  reportsMainGenerateReportUpdate,
  clearRedux,
  openFleetLandmarkModal,
  reportsMainGenerateEvents,
  reportsMainGenerateSubEvents,
  reportsMainGenerateReportProgress

} from 'Redux/actions'

// styles
import './geofenceBasedReport.scss';

const GeofenceBasedReport = (props) => {
  const { messages, report, user } = props;
  const { durationFormat } = user;
  const dispatch = useDispatch();
  const { loading, data, loadingSummary, filters, timeZone, events, summary, loadingSubEvents } = report;
  const { filterEntity, filterType, filterArray, otherFilters } = filters;

  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();

  const [unitDriverSelected, setUnitDriverSelected] = useState([]);

  const exportHandler = useHandleExportReport(report)

  const durationFormatTime = getMethodToFormatDuration(durationFormat);

  const summaryTable = (item, type) => {
    return (
      <Fragment>
        <Table
          data={item.summaryInfo || []}
          noDataMessage={messages["reportsMainRT_noTripsAvailable"]}
          propertyToShowExpandable="day"
          hiddenPagination
          classNameColumns="start-stop-idle-sub-table"
        >
          <Column
            label={type}
            noSorting
          >
            {(element) => {
              return element.idLabel
            }}
          </Column>
          <Column
            label={messages['reportsMainRT_totalInGeofenceCount']}
            noSorting
          >
            {(element) => {
              return <span>{element.ins}</span>
            }}
          </Column>
          <Column
            label={messages['generatedReport_totalTime']}
            noSorting
          >
            {(element) => {
               return element.totalTime ? durationFormatTime(element.totalTime, messages): '0 min, 0 sec'
            }}
          </Column>
          <Column
            label={messages['generatedReport_totalDistance']}
            noSorting
          >
            {(element) => {
              return <span>{Number(element.totalDistance || 0).toFixed(2)} {messages['miles']}</span>
            }}
          </Column>
        </Table>
      </Fragment>
    )
  }



  const summaryPerUnit = (row) => {
    const type = getEntityType(report, messages)
    return (
      <div>
        {

          <Accordion
            customTitle={() => { return (<b>{`${messages['generatedReport_totalsFor']}: ${row.geofenceLabel}`}</b>) }}
            className="AccordionContainer-root start-stop-idle-accordeon"
            defaultExpanded
          >
            <div style={{ width: '100%' }}>
              {!row.noData && summaryTable(row, type)}
              <Table
                data={summary?.items || []}
                loading={loading || loadingSummary}
                totalItems={summary?.total || 0}
                noDataMessage={messages["generatedReport_noEvents"]}
                onChangedPage={(page, rowsPerPage, sortField, sortOrder) => {

                  let events = {
                    data: [],
                    filters: {
                      filterArray: [row.id],
                      page,
                    }
                  }
                  dispatch(reportsMainGenerateEvents(report, events))
                }}
                classNameColumns="start-stop-idle-sub-table"
                serverSideTable={true}
                centerPagination
                numberofRows={10}
                hideNumberOfRows={true}
                noData={row.noData}
              >
                <Column
                  label={type}
                  field="label"
                  noSorting
                >
                  {(element) => {
                    return <strong>{element.idLabel}</strong>
                  }}
                </Column>
                <Column
                  label={messages['generatedReport_from']}
                  field="dateFrom"
                  noSorting
                >
                  {(element) => { return <span>{`${formatDatetime(element.dateFrom, "MMMM D, YYYY h:mm A", timeZone) || ''}`}</span> }}
                </Column>
                <Column
                  label={messages['generatedReport_to']}
                  field="dateTo"
                  noSorting
                >
                  {(element) => { return <span>{`${formatDatetime(element.dateTo, "MMMM D, YYYY h:mm A", timeZone) || ''}`}</span> }}
                </Column>
                <Column
                  label={messages['generatedReport_timeInGeofence']}
                  field="time"
                  noSorting
                >
                  {(element) => { return element.time ? durationFormatTime(element.time, messages): '0 min, 0 sec'}}
                </Column>
                <Column
                  label={messages['generatedReport_distanceInGeofence']}
                  field="distance"
                  noSorting
                >
                  {(element) => { return <span>{Number(element.distance || 0).toFixed(2)} {messages['miles']}</span> }}
                </Column>


              </Table>
            </div>
          </Accordion>
        }
      </div>
    )
  }

  const idRenderExpandableRow = (selected) => {
    if(!selected.noData){
      let currentReport = report
      delete currentReport.summary
      dispatch(reportsMainGenerateReportProgress(currentReport))
      let events = {
        filters: {
          filterArray: [selected.id || selected.geofenceId],
          page: 1,
        }
      }
      dispatch(reportsMainGenerateEvents(report, events))
    }
  }

  return (
    <Fragment>
      <Table
        loading={loading}
        asyncLoading={report.asyncLoading}
        data={data.items || []}
        totalItems={data.total}
        groupField="id"
        onChangedPage={(page, rowsPerPage, newSortField, newSortOrder) => {
          dispatch(reportsMainGenerateReportUpdate(report, page, rowsPerPage, newSortField, newSortOrder))
        }}
        defaultSortField="deviceId"
        defaultSortOrder="asc"
        noDataMessage={messages["reportsMainRT_noDataMessage"]}
        hideHeader
        onChangeSelector={(value) => {
          setUnitDriverSelected(value)
          let currentReport = report;
          currentReport.filters = { ...currentReport.filters, unitsSelected: value };
          dispatch(reportsMainGenerateReportUpdate(currentReport, 1, 10));
        }}
        valueSelector={unitDriverSelected}
        itemsSelector={filterArray}
        textFieldSelector={getFilterNameByEntity(filterType)}
        placeholderSelector={getPlaceholderSelector(filterType, messages)}
        classNameColumns="start-stop-idle-main-table"
        renderExpandableRow={summaryPerUnit}
        propertyToShowExpandable="geofenceLabel"
        idRenderExpandableRow={idRenderExpandableRow}
        {...exportHandler}
      >
        <Column noSorting>
          {(element) => {
            return <span><strong>{messages['geofence']}  </strong>{`${element.geofenceLabel || ''}`}</span>
          }}
        </Column>
        <Column noSorting>
          {() => {
            const localTimeStart = moment.utc(report.filters.startRange).local().format();
            const localTimeEnd = moment.utc(report.filters.endRange).local().format();
            return (<div>
                      <span>
                        <span>
                          <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                        </span>
                      </span>
            </div>);
          }}
        </Column>
      </Table>
      <SideMapComponent hiddenButton hiddenSearchMap />
    </Fragment>
  );
};

export default GeofenceBasedReport;
