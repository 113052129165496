import {
    SETTINGS_FUEL_CARD_SEARCH,
    SETTINGS_FUEL_CARD_SEARCH_RESPONSE,
    SETTINGS_FUEL_CARD_GET,
    SETTINGS_FUEL_CARD_GET_RESPONSE,
    SETTINGS_FUEL_CARD_UPDATE,
    SETTINGS_FUEL_CARD_UPDATE_RESPONSE,
    SETTINGS_FUEL_CARD_OPEN_UPDATE,
    SETTINGS_FUEL_CARD_BULK_OPEN,
    SETTINGS_FUEL_CARD_BULK_UPDATE,
    SETTINGS_FUEL_CARD_BULK_UPDATE_RESPONSE,
    SETTINGS_FUEL_CARD_RULE,
    SETTINGS_FUEL_CARD_RULE_RESPONSE,
} from "Redux/actionTypes";

export const searchFuelCards = (data) => {
    return {
        type: SETTINGS_FUEL_CARD_SEARCH,
        payload: data,
    };
};

export const searchFuelCardsResponse = (data) => {
    return {
        type: SETTINGS_FUEL_CARD_SEARCH_RESPONSE,
        payload: data
    };
};

export const getFuelCard = (id) => {
    return {
        type: SETTINGS_FUEL_CARD_GET,
        payload: { id }
    };
};

export const getFuelCardResponse = (data) => {
    return {
        type: SETTINGS_FUEL_CARD_GET_RESPONSE,
        payload: { data }
    };
};

export const updateFuelCard = (data) => {
    return {
        type: SETTINGS_FUEL_CARD_UPDATE,
        payload: data
    };
};

export const updateFuelCardResponse = (data) => {
    return {
        type: SETTINGS_FUEL_CARD_UPDATE_RESPONSE,
        payload: data
    };
};

export const openEditFuelCardModal = (open, id) => {
    return {
        type: SETTINGS_FUEL_CARD_OPEN_UPDATE,
        payload: { open, id }
    };
};

export const openBulkFuelCardModal = (open, items = []) => {
    return {
        type: SETTINGS_FUEL_CARD_BULK_OPEN,
        payload: { open, items }
    };
};

export const updateBulkFuelCards = (data) => {
    return {
        type: SETTINGS_FUEL_CARD_BULK_UPDATE,
        payload: data
    };
};

export const updateBulkFuelCardsResponse = (data) => {
    return {
        type: SETTINGS_FUEL_CARD_BULK_UPDATE_RESPONSE,
        payload: data
    };
};

export const ruleFuelCards = (data) => {
    return {
        type: SETTINGS_FUEL_CARD_RULE,
        payload: data
    };
};

export const ruleFuelCardsResponse = (data) => {
    return {
        type: SETTINGS_FUEL_CARD_RULE_RESPONSE,
        payload: data
    };
};
