// Merge Views
import mainVideoView from 'Modules/video/views/main/lan/en';
import eventsTabView from 'Modules/video/views/main/.components/events/lan/en';
import clipsTabView from 'Modules/video/views/main/.components/clips/lan/en';
import advancedFilters from 'Modules/video/views/main/.components/advancedFilters/lan/en';
import manageCamerasView from 'Modules/video/views/manageCamerasTab/lan/en';
import videoDetailView from 'Modules/video/views/videoDetail/lan/en';
let locales = {};

locales = {
  ...locales,
  ...mainVideoView,
  ...eventsTabView,
  ...clipsTabView,
  ...advancedFilters,
  ...videoDetailView,
  ...manageCamerasView
};

export default locales;
