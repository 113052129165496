export default {
    "dynamicFormAnswers_title": "Answers",
    "dynamicFormAnswers_form": "Form",
    "dynamicFormAnswers_answer": "Answer",
    "dynamicFormAnswers_updatedBy": "Updated By",
    "dynamicFormAnswers_address": "Address",
    "dynamicFormAnswers_updatedAt": "Updated At",
    "dynamicFormAnswers_viewAnswers": "View Answers",

    "dynamicFormAnswers_advanceFilters": "Advanced Filters",
    "dynamicFormAnswers_clearAll": "Clear All",
    "dynamicFormAnswers_form": "Form",
    "dynamicFormAnswers_user": "Driver",

    "dynamicFormAnswers_searchByAnswer": "Search by answer",

    "dynamicFormAnswers_today": 'Today',
    "dynamicFormAnswers_yesterday": 'Yesterday',
    "dynamicFormAnswers_last7days": 'Last 7 Days',
    "dynamicFormAnswers_last30Days": 'Last 30 Days',
    "dynamicFormAnswers_custom": 'Custom'
}