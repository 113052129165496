import React, { Component } from 'react';
import Tooltip from '@mui/material/Tooltip';

class CustomTooltip extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<Tooltip 
				placement={this.props.placement} 
				title={this.props.title}
				componentsProps={{ 
					tooltip: { 
						sx: { 
							backgroundColor: '#ffffff',
							color: 'rgba(0, 0, 0, 0.87)',
							maxWidth: "500px",
							fontSize: "14px",
							padding: "12px",
							border: '1px solid #ddd',
							boxShadow: '0px 0px 15px 1px rgba(0,0,0,0.2)', 
						} 
					} 
				}}
			>
				{this.props.children}
			</Tooltip>
		);
	}
}

export default CustomTooltip;
