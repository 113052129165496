/********************************************************
 * Third Controls
 ********************************************************/
import React from 'react'

/********************************************************
 * Icon marker to realtime maps
 ********************************************************/
const LandmarkMarkerSvg = (props) => {
  const color = props.color || 'orangered'
  const width = props.width ? props.width : 18 
  const height = props.height ? props.height : 18
  return (

    <svg width={width} height={height} viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>{props.name}</title>
      <defs>
          <polygon id="path-1" points="14.4 6 14 4 5 4 5 21 7 21 7 14 12.6 14 13 16 20 16 20 6"></polygon>
      </defs>
      <g id="icon-landmark" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="icon_landmark_new" transform="translate(-3.051667, -3.000000)">
              <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
              </mask>
              <g id="icon/content/flag_24px" fillRule="nonzero"></g>
              <g id="Group" mask="url(#mask-2)" fill={color} fillOpacity="0.87">
                  <g id="↳-Color">
                      <rect id="Rectangle-13" x="0" y="0" width="24" height="24"></rect>
                  </g>
              </g>
          </g>
      </g>
  </svg>

  )

}

export default LandmarkMarkerSvg


