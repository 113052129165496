//View Keys
export default {
  realtimemaps_vehicledirections_units: 'Seleccionar Unidad',
  realtimemaps_vehicledirections_landmark: 'Seleccionar Punto de Referencia',
  realtimemaps_vehicledirections_tolls: 'Evite los peajes',
  realtimemaps_vehicledirections_highways: 'Evite las autopistas',
  realtimemaps_vehicledirections_route: 'Optimizar la ruta',
  realtimemaps_vehicledirections_from: 'Desde',
  realtimemaps_vehicledirections_to: 'Hasta',
  realtimemaps_vehicledirections_options: 'Opciones',
  realtimemaps_vehicledirections_getdirections: 'Obtener Direcciones',
  realtimemaps_vehicledirections_nolatlng: 'No hay Latitud y Longitud para esta unidad',
  realtimemaps_vehicledirections_latError: 'Latitud inválida',
  realtimemaps_vehicledirections_lngError: 'Longitud inválida',
};