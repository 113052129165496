import {
    MAP_GEOFENCES_DELETE_GROUP_OPEN_MODAL,
    MAP_GEOFENCES_DELETE_GEOFENCE_GROUP,
    MAP_GEOFENCES_DELETE_GEOFENCE_GROUP_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    open: false,
    geofenceGroups: [],
    response: null,
    loadingResponse: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MAP_GEOFENCES_DELETE_GROUP_OPEN_MODAL:
            return {
                ...state,
                open: action.payload.open,
                geofenceGroups: action.payload.geofenceGroups || [],
                response: null,
                loadingResponse: false
            };

        case MAP_GEOFENCES_DELETE_GEOFENCE_GROUP:
            return {
                ...state,
                response: null,
                loadingResponse: true
            };

        case MAP_GEOFENCES_DELETE_GEOFENCE_GROUP_RESPONSE:
            return {
                ...state,
                response: action.payload.data,
                loadingResponse: false
            };

        case CLEAR_REDUX:
            return ["", "MAP_GEOFENCE_DELETE_GEOFENCE_GROUP"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
            return { ...state };
    }
}