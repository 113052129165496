export const FSM_ROUTES_STOP_SEARCH = "FSM_ROUTES_STOP_SEARCH";
export const FSM_ROUTES_STOP_SEARCH_RESPONSE = "FSM_ROUTES_STOP_SEARCH_RESPONSE";
export const FSM_ROUTES_STOP_UPDATE_POSITION = "FSM_ROUTES_STOP_UPDATE_POSITION";
export const FSM_ROUTES_STOP_UPDATE_POSITION_RESPONSE = "FSM_ROUTES_STOP_UPDATE_POSITION_RESPONSE";
export const FSM_ROUTES_STOP_DELETE = "FSM_ROUTES_STOP_DELETE";
export const FSM_ROUTES_STOP_DELETE_RESPONSE = "FSM_ROUTES_STOP_DELETE_RESPONSE";
export const FSM_ROUTES_STOP_OPEN_DELETE = "FSM_ROUTES_STOP_OPEN_DELETE";
export const FSM_ROUTES_STOP_OPEN_CREATE_EDIT = "FSM_ROUTES_STOP_OPEN_CREATE_EDIT";
export const FSM_ROUTES_STOP_OPEN_IMPORT = "FSM_ROUTES_STOP_OPEN_IMPORT";
export const FSM_ROUTES_STOP_CREATE = "FSM_ROUTES_STOP_CREATE";
export const FSM_ROUTES_STOP_CREATE_RESPONSE = "FSM_ROUTES_STOP_CREATE_RESPONSE";
export const FSM_ROUTES_STOP_IMPORT_ROUTE = "FSM_ROUTES_STOP_IMPORT_ROUTE";
export const FSM_ROUTES_STOP_IMPORT_ROUTE_RESPONSE = "FSM_ROUTES_STOP_IMPORT_ROUTE_RESPONSE";