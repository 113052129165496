export default {
    fleetModalBulkOutputs_title: "Bulk Outputs ({number_units}) Units",
    fleetModalBulkOutputs_selectOutput: "Select Output",
    fleetModalBulkOutputs_commandType: "Command Type",
    fleetModalBulkOutputs_selectedUnits: "Selected Units",
    fleetModalBulkOutputs_supported: "Supported",
    fleetModalBulkOutputs_notSupported: "Not Supported",
    fleetModalBulkOutputs_sendPulse: "Send Pulse",
    fleetModalBulkOutputs_sendOn: "Send On",
    fleetModalBulkOutputs_sendOff: "Send Off",
    fleetModalBulkOutputs_commandSendSuccess: "Command sent successfull",
}