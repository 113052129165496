import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    warnings: []
}

const route = "modules/alerts/components/wizardAlert/redux/slice";

export const alertsWizardAlertSearchWarnings = createAsyncThunk("maintenance/driverDetail/ScheduleTasksTab/searchWarnings", async (payload, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientQuery(
            `
            query ($conditions: [WarningSearchInput], $sort: [ WarningSortInput ], $limit: Int, $offset: Int){
                data: searchWarnings(conditions: $conditions, sort: $sort, limit: $limit, offset: $offset){
                    items {
                        id
                        code
                        name
                    }
                    total
                }
            }
            `,
            {
                conditions: [

                ],
                sort: [
                    {
                        field: "name",
                        order: "DESC",
                    },
                ],
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        return response.data?.items || []
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'alertsWizardAlertSearchWarnings');
        return rejectWithValue(exc);
    }
}
)

export const alertsConfigRedux = createSlice({
    name: 'alert/wizardAlert/config',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {

        //maintenanceCreateDriverTask
        builder.addCase(alertsWizardAlertSearchWarnings.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(alertsWizardAlertSearchWarnings.fulfilled, (state, action) => {
            state.warnings = action.payload
            state.loading = false;
        });

        builder.addCase(alertsWizardAlertSearchWarnings.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
})

const { actions, reducer } = alertsConfigRedux;

// Extract and export each action creator by name
export const {
    reset,
} = actions;

export default reducer;