import FsmMainView from "Modules/fsm/views/main/lan/es";
import FormView from "Modules/fsm/views/fsmSettings/components/formSettings/lan/es";
import QuickMessageView from "Modules/fsm/views/fsmSettings/components/quickMessage/lan/es";
import QuickResponseView from "Modules/fsm/views/fsmSettings/components/quickResponse/lan/es";
import MealBreakNotificationsView from "Modules/fsm/views/fsmSettings/components/mealBreakNotifications/lan/es";
import DriverStatusView from "Modules/fsm/views/fsmSettings/components/driverStatus/lan/es";
import timeCardView from "Modules/fsm/views/fsmSettings/components/timeCards/lan/es";
import fsmMessagesiew from "Modules/fsm/views/fsmMessages/lan/es";
import fsmRouteView from "Modules/fsm/views/fsmRoutes/lan/es";

const locales = {
    ...FsmMainView,
    ...FormView,
    ...QuickMessageView,
    ...QuickResponseView,
    ...MealBreakNotificationsView,
    ...DriverStatusView,
    ...timeCardView,
    ...fsmMessagesiew,
    ...fsmRouteView
};

export default locales;