import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// controls
import Table, { Column } from '../../table'

import {
  formatDatetime,
  formatTimeDelta,
  getCategoryFilterType,
  getEntityType,
  useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';


// hooks
import { useSidePanelMap, useTableReport } from 'Core/hooks';
// actions
import {
  reportsMainGenerateReportUpdate,
  clearRedux,
  reportsMainGenerateSubEvents,
  reportsMainGenerateReportProgress,
  reportsMainGenerateEvents
} from 'Redux/actions';

import { getColorByEvent } from '../../../../../../../.globals/helpers';
import './diagnostic.scss';
import { getColorSpeed } from '../../../helper';

const DiagnosticParametersReport = (props) => {

  const { messages, report } = props;
  const dispatch = useDispatch();
  const [eventFilter, setEventFilter] = useState({});
  const { loading, data, filters, loadingSummary, timeZone, summary } = report;
  const { filterEntity,  startRange, endRange } = filters;

  const [currentId, setCurrentId] = useState(null);

  const exportHandler = useHandleExportReport(report);

  const reportRef = useRef();
  useEffect(() =>{
    if(report){
      const newReport = JSON.parse(JSON.stringify(report));
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);


  const idEventsPerDay = (selected,id) => {
    let currentReport = report
    delete currentReport.summary
    dispatch(reportsMainGenerateReportProgress(currentReport))
    let events = {
      filters: {
        filterType: getCategoryFilterType(filterEntity),
        filterArray: [currentId],
        page: 1,
        rowsPerPage: 10,
        startRange: moment(selected).startOf('day').utc().toDate(),
        endRange: moment(selected).endOf('day').utc().toDate()
      }
    }
    setEventFilter(events)
    dispatch(reportsMainGenerateEvents(report, events))
  }

  const renderExpandableRowSubEvents = (row) => {
    return (
      <div style={{ marginBottom: 10 }}>
        <Table
          loading={loadingSummary}
          data={summary?.items || []}
          totalItems={summary?.total}
          onChangedPage={(page) => {
            let currentEvents = eventFilter
            currentEvents.filters.page = page
            dispatch(reportsMainGenerateEvents(report, currentEvents))
          }}
          noDataMessage={messages["generatedReport_noEvents"]}
          hideNumberOfRows={true}
          classNameColumns={'report-sub-events-columns'}
          centerPagination
          serverSideTable={true}
        >
          <Column label={messages['generatedReport_timeAndDate']}>
            {(element) => <span>{formatDatetime(element.dateTime, "MMMM D, YYYY h:mm A", timeZone)}</span>}
          </Column>
          <Column label={messages['generatedReport_eventName']} >
            {(element) => <span className={getColorByEvent(element.eventName)}>{emptyField(element.eventName)}</span>}
          </Column>
          <Column label={messages['generatedReport_batteryVoltage']}>
            {(element) => <span>{emptyField(element.batteryVoltage)}</span>}
          </Column>
          <Column label={messages['generatedReport_coolantTemp']}>
            {(element) => <span>{emptyField(element.coolantTemp)}</span>}
          </Column>
          <Column label={messages['generatedReport_engineSpeed']}>
            {(element) => <span>{emptyField(element.engineSpeed)}</span>}
          </Column>
          <Column label={messages['generatedReport_fuelPercent']}>
            {(element) => <span>{emptyField(element.fuelPercent)}</span>}
          </Column>
          <Column label={messages['generatedReport_fuelRemain']}>
            {(element) => <span>{emptyField(element.fuelRemain)}</span>}
          </Column>
          <Column label={messages['generatedReport_fuelRate']}>
            {(element) => <span>{emptyField(element.fuelRate)}</span>}
          </Column>
          <Column label={messages['odometer']}>
            {(element) => <span>{emptyField(element.odometer)}</span>}
          </Column>
          <Column label={messages['generatedReport_throttlePosition']}>
            {(element) => <span>{emptyField(element.throttlePosition)}</span>}
          </Column>
          <Column label={messages['generatedReport_tripFuel']}>
            {(element) => <span>{emptyField(element.tripFuel)}</span>}
          </Column>
          <Column label={messages['generatedReport_tripOdometer']}>
            {(element) => <span>{emptyField(element.tripOdometer)}</span>}
          </Column>
          <Column label={messages['generatedReport_vehicleSpeed']}>
            {(element) => <span className={getColorSpeed(element.vehicleSpeed)}>{emptyField(element.vehicleSpeedUnit)}</span>}
          </Column>
        </Table>
      </div>
    )
  }
  const emptyField = (value) => {
    return value || '- -';
  }
  const onChangeExpandable = (selected) => {
    if(!selected.noData){
      let selectedId = (filterEntity === "Unit" ? selected.deviceId : selected.driverId) || selected.id
      setCurrentId(selectedId);
    }
  }

  let parametersTableSummary = useTableReport({
    data:  [],
    report,
    messages,
    hideHeader: true,
    hiddenPagination: true,
    renderExpandableRow: renderExpandableRowSubEvents,
    showExpandable: true
  });

  const renderExpandableRow = (row) => {
    const summaryParameters = {
      ...parametersTableSummary,
      data : row?.dates || [],
      onChangeSelector:null,
      placeholderSelector:null,
      hideSelectorUnitsDriver:true,
      classNameColumns:"table-report-main-sub-table",
      idRenderExpandableRow:(event)=> {idEventsPerDay(event,row?.id)}
    }
    return (
      <Table
        {...summaryParameters}
        noData={row.noData}
      >
        <Column label="day" field="label">
          {(element) => {
            return <span><strong>{formatDatetime(element, "dddd, LL")}</strong></span>
          }}
        </Column>
        <Column label={messages['generatedReport_totalIdleTime']} field="label"></Column>
      </Table>
    )
  }

  let parametersTable = useTableReport({
    data: data.items,
    report,
    messages,
    hideHeader: true,
    renderExpandableRow,
    onChangeExpandable,
    propertyToShowExpandable: 'id',
    classNameColumns: "table-report-main-expandible"
  });

  return (
    <Fragment>
      <Table
        {...parametersTable}
        {...exportHandler}
      >
        <Column noSorting>
          {(element) => {
            const type = getEntityType(report, messages)
            return <span><strong>{type}:  </strong>{`${element.idLabel || ''}`}</span>
          }}
        </Column>
        <Column noSorting>
          {() => {
            const localTimeStart = moment.utc(startRange).local().format();
            const localTimeEnd = moment.utc(endRange).local().format();
            return (<div>
                      <span>
                        <span>
                          <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                        </span>
                      </span>
            </div>);
          }}
        </Column>
      </Table>
    </Fragment>
  );
};

export default DiagnosticParametersReport;
