import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';
// Actions
import {
  reportsMainGenerateReportProgress,
  reportsMainGenerateReportUpdate
} from 'Redux/actions';

import {
  getTimeZone,
  getCategoryFilter,
  handleAsyncReport,
  baseReportConditions,
  generateReportInstance,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';
import { isFilterBaseView } from '../landmarkBasedReport/sagaFunction';


function* getGeofenceBasedReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
  const { timeZone , sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath } = report;
  const { filterType, filterArray, startRange, endRange, unitsSelected, otherFilters } = report.filters
  const baseConditionsQuery = baseReportConditions({ report, name, dataResultPath, timeZone, delayed, unitsSelected, timeframeName , filterType, filterArray, categoryName, startRange, endRange})
  try {
    const valueEntityFilter = isFilterBaseView(otherFilters.geofenceBaseView) ? [{
      id: otherFilters.geofence,
      type: "Geofence"
    }] :  {id: otherFilters.geofenceTags?.id, type: "Tag"} ;
    let payload = {
      conditions: {
        ...baseConditionsQuery,
        filterValues: {
          field: isFilterBaseView(otherFilters.geofenceBaseView) ? "Geofence" : "Tag",
          entities: otherFilters.geofenceBaseView == "all"? null: valueEntityFilter
        },
        sortField: {
          field: sortField || "deviceId",
          order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
      },
      limit: rowsPerPage,
      offset: page
    };

    if (report.export) {
      payload = {
        ...payload,
        limit: isJsonFormat(report?.exportType, dataResultPath)? rowsPerPage : 5000,
        offset: isJsonFormat(report?.exportType, dataResultPath)? page : 1,
        conditions: {
          ...payload.conditions,
          exportOptions: {
            sendByEmail,
            emailsTo,
            format: {
              fileFormat: report?.exportType  || "json",
              formatOptions: "{}",
              durationFormat: report?.durationFormat
            },
            culture: report.lang,
            timeZone: getTimeZone(timeZone),
            folderName: "px-customer-reports",
            filePrefixName: "geofenceBasedReport",
            columns: [
              {
                column: "geofence",
                header: "Geofence"
              },
              {
                column: "idLabel",
                header: "Label"
              },
              {
                column: "dateFrom",
                header: "From"
              },
              {
                column: "dateTo",
                header: "To"
              },
              {
                column: "time",
                header: "Time In Geofence"
              },
              {
                column: "distance",
                header: "Distance In Geofence"
              }
            ]
          }
        }
      }
    }
    let data = yield call(
      clientQuery,
      `query getGeofenceBasedReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getGeofenceBasedReport (conditions: $conditions, limit: $limit, offset: $offset) {
          ${ generateReportInstance(report.export, dataResultPath, report.exportFromTable)?
            `reportInstanceId`
          :`
            items {
              geofenceId
              geofenceLabel
              noData
              summaryInfo {
                id
                idLabel
                geofenceIns
                totalTime
                totalDistance
              }
            }
            total
            limit
            offset
            category
        `}
        }
        
      }`,
      payload ,
      endpoints.GRAPHQL_PX_REPORTING,
    );
    yield handleAsyncReport(report, data);

  } catch (exc) {
    ExceptionManager(exc, 'reports/geofenceBasedReport', 'getGeofenceBasedReport');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
    if (!requestFlag) {
      let flag = true
      yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
    } else {
      report.loading = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  }
}


export default getGeofenceBasedReport;
