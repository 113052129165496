// Dependencies
import { combineReducers } from 'redux';

// Reducers
import core from 'Core/.globals/redux/reducers';
import maintenance from 'Modules/maintenance/.globals/redux/reducers';
import behavior from 'Modules/behavior/.globals/redux/reducers';
import fleet from 'Modules/fleet/.globals/redux/reducers';
import reports from 'Modules/reports/.globals/redux/reducers';
import entitySelector from 'Modules/.common/components/EntitySelector/redux/reducer';
import alerts from 'Modules/alerts/.globals/redux/reducers';
import realtimeMaps from 'Modules/realtimeMaps/.globals/redux/reducer';
import common from 'Modules/.common/.globals/redux/reducers';
import video from 'Modules/video/.globals/redux/reducers';
import account from 'Modules/account/.globals/redux/reducers';
import settings from 'Modules/settings/.globals/redux/reducers';
import dashboard from 'Modules/dashboard/.globals/redux/reducers';
import fsm from 'Modules/fsm/.globals/redux/reducers';
import dynamicForms from 'Modules/dynamicForms/.globals/redux/reducers';
import chat from '../../modules/chat/.globals/redux/reducers';
import routes from '../../modules/routes/.globals/redux/reducer';
import incidents from '../../modules/incidents/.globals/redux/reducers'

const reducersModules = {
  ...core,
  ...maintenance,
  ...behavior,
  ...fleet,
  ...reports,
  ...entitySelector,
  ...alerts,
  ...realtimeMaps,
  ...common,
  ...video,
  ...account,
  ...fsm,
  ...settings,
  ...dashboard,
  ...dynamicForms,
  ...chat,
  ...routes,
  ...incidents
};

const reducers = combineReducers(reducersModules);

export default reducers;
