// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import { 
  CONTACTS_SEARCH, 
  CONTACTS_CREATE, 
  CONTACTS_UPDATE, 
  CONTACTS_DELETE,
  CONTACTS_SEARCH_CELL_CARRIERS
} from "Redux/actionTypes";

// Actions
import { 
  searchContactsResponse, 
  createContactResponse,
  updateContactResponse,
  deleteContactResponse,
  searchCellCarriersResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* searchContacts({ payload }) {
  const { openSearch, limit, offset } = payload;
  const query = `
      query getAllContacts ($openSearch: String, $limit: Int!, $offset: Int) {
        getAllContacts (openSearch: $openSearch, limit: $limit, offset: $offset) {
          items {
            id
            title
            address
            type
          }
          total
        }
    }`;

  const variables = {
    openSearch, 
    limit, 
    offset
  };

  try {
    const data = yield call(
      clientQuery,
      query,
      variables,
      endpoints.GRAPHQL_GENERAL
    );
    if (data) {
      const { getAllContacts: { items, total } } = data;
      yield put(searchContactsResponse({ items, total, error: false }));
    }
  } catch (exc) {
    yield put(searchContactsResponse({ items: [], total: 0, error: true }));
    ExceptionManager(
      exc,
      "modules/account/views/contacts/redux/sagas",
      "searchContacts"
    );
  }
}

function* createContact({ payload }) {
  const { title, address, type } = payload;
  const mutation = `
    mutation createContact ($title: String!, $address: String!, $type: ContactType!) {
      createContact (title: $title, address: $address, type: $type) {
          id
          title
          address
          type
      }
  }`;
  const variables = { title, address, type };
  try {
    const data = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL);
    if (data) {
      yield put(createContactResponse({ newContact: data.createContact, error: false }));
    } else {
      throw 'Contact has not been created';
    }
  } catch (exc) {
    yield put(
      createContactResponse({
        error: true,
        newContact: null
      })
    );
    ExceptionManager(
      exc,
      "modules/account/views/contacts/redux/sagas",
      "createContact",
      true,
    );
  }
}

function* updateContact({ payload }) {
  const { id, title, address, type } = payload;
  const mutation = `
    mutation updateContact ($id: Int!, $title: String, $address: String, $type: ContactType) {
      updateContact (id: $id, title: $title, address: $address, type: $type) {
          id
          title
          address
          type
      }
    }`;
  const variables = { id, title, address, type };
  try {
    const data = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL);
    if (data) {
      yield put(updateContactResponse({ updateContact: data.updateContact, error: false }));
    } else {
      throw 'Contact has not been updated';
    }
  } catch (exc) {
    yield put(
      updateContactResponse({
        error: true,
        updateContact: null
      })
    );
    ExceptionManager(
      exc,
      "modules/account/views/contacts/redux/sagas",
      "updateContact",
      true
    );
  }
}

function* deleteContact({ payload }) {
  const { id } = payload;
  const mutation = `
    mutation deleteContact ($id: Int!) {
      deleteContact (id: $id)
    }`;

  const variables = { id };

  try {
    const data = yield call(clientMutation, mutation, variables, endpoints.GRAPHQL_GENERAL);
    if (data) {
      yield put(deleteContactResponse({ deleteContact: data.deleteContact, error: false }));
    } else {
      throw 'Contact has not been deleted';
    }
  } catch (exc) {
    yield put(
      deleteContactResponse({
        error: true,
        deleteContact: false
      })
    );
    ExceptionManager(
      exc,
      "modules/account/views/contacts/redux/sagas",
      "deleteContact"
    );
  }
}

function* searchCellCarriers() {
  const query = `
      query searchCellCarriers {
        searchCellCarriers {
            id
            carrier
            alias
            gateway
        }
      }`;

  try {
    const data = yield call(
      clientQuery,
      query,
      {},
      endpoints.GRAPHQL_GENERAL
    );
    if (data) {
      const { searchCellCarriers } = data;
      yield put(searchCellCarriersResponse({ cellCarriers: searchCellCarriers, error: false }));
    }
  } catch (exc) {
    yield put(searchCellCarriersResponse({ cellCarriers: [], error: true }));
    ExceptionManager(
      exc,
      "modules/account/views/contacts/redux/sagas",
      "searchCellCarriers"
    );
  }
}

function* watchMethods() {
  yield takeEvery(CONTACTS_SEARCH, searchContacts);
  yield takeEvery(CONTACTS_CREATE, createContact);
  yield takeEvery(CONTACTS_UPDATE, updateContact);
  yield takeEvery(CONTACTS_DELETE, deleteContact); 
  yield takeEvery(CONTACTS_SEARCH_CELL_CARRIERS, searchCellCarriers); 
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}
