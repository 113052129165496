import React, { useState } from "react";
import Modal, { ModalBody, ModalFooter } from "Components/Modal";
import { useIntl } from "react-intl";
import { Button } from "Components";

const ModalConfigMediaUser = () => {
  const { messages } = useIntl();
  const [errorIsInFacebook,] = useState(false);
  const [showErrorSocialMedia, setShowErrorSocialMedia] = useState(false);

  return (
    <Modal
      title={
        errorIsInFacebook
          ? messages["login_social_modal_configuration_facebook"]
          : messages["login_social_modal_configuration_apple"]
      }
      open={showErrorSocialMedia}
      handleClose={() => setShowErrorSocialMedia(false)}
      size="small"
    >
      <ModalBody>
        <p className="title-correctly-configuration">
          {messages["login_social_media_title"]}{" "}
          {errorIsInFacebook
            ? messages["login_facebook"]
            : messages["login_apple"]}
          :
        </p>
        <ol>
          {errorIsInFacebook ? (
            <>
              <li>
                {messages["login_social_step_one_facebook"]}{" "}
                <a
                  href="https://www.facebook.com/settings"
                  target="_blank"
                  rel="noreferrer"
                  className="learn-more-error settings-facebook-url"
                >
                  <span>{messages["login_social_settings"]}</span>
                </a>
              </li>
              <li>{messages["login_social_step_two_facebook"]}</li>
              <li>{messages["login_social_step_three_facebook"]}</li>
              <li>{messages["login_social_step_four_facebook"]}</li>
              <li>{messages["login_social_step_five_facebook"]}</li>
            </>
          ) : (
            <>
              <li>
                {messages["login_social_step_one_apple"]}{" "}
                <a
                  href="https://appleid.apple.com/account/manage"
                  target="_blank"
                  rel="noreferrer"
                  className="learn-more-error settings-facebook-url"
                >
                  <span>https://appleid.apple.com/account/manage</span>
                </a>
              </li>
              <li>{messages["login_social_step_two_apple"]}</li>
              <li>{messages["login_social_step_three_apple"]}</li>
              <li>{messages["login_social_step_four_apple"]}</li>
              <li>{messages["login_social_step_five_apple"]}</li>
            </>
          )}
        </ol>
      </ModalBody>

      <ModalFooter>
        <>
          <Button
            id="btnCloseConfirm"
            onClick={() => setShowErrorSocialMedia(false)}
            className="btn-blue"
          >
            {messages["close"]}
          </Button>
        </>
      </ModalFooter>
    </Modal>
  );
};

export default ModalConfigMediaUser;
