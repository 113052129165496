import React, { useState } from 'react';
import { Grid } from '@mui/material/';
import { Pagination as MaterialPagination } from '@mui/material';
import { usePagination } from '@mui/lab';
import cx from 'classnames';
import './pagination.scss';
//international
import { injectIntl } from 'react-intl';

const Pagination = (props) => {
	const availablePages = Math.ceil(props.count / props.rowsPerPage);
	const [allRows, setAllRows] = useState(false);
	const { messages } = props.intl;

	const handleChangeRowsPerPage = (rowsPerPage) => {
		const { onChangeRowsPerPage } = props;
		if (rowsPerPage == props.count) {
			setAllRows(true);
		} else {
			setAllRows(false);
		}

		if (onChangeRowsPerPage) {
			onChangeRowsPerPage(rowsPerPage);
		}
	};

	const { items } = usePagination({
		count: availablePages,
		page: props.page,
		onChange: props.onChangePage,
		color: "secondary"
	});

	return (
		<div className="pagination-container">
			<Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
				<Grid
					item
					xs={props?.paginationGrid?.page?.xs || 12}
					sm={props?.paginationGrid?.page?.sm || 12}
					md={props?.paginationGrid?.page?.md || 4}
					lg={props?.paginationGrid?.page?.lg || 4}
					container
					direction="row"
					justifyContent="flex-start"
					alignItems="flex-start"
					className="pagination-controls"
				>
					<MaterialPagination
						style={{ display: 'none' }}
						count={availablePages}
						page={props.page}
						onChange={props.onChangePage}
						color="secondary"
					/>

					<ul className="MuiPagination-ul">
						{
							items.map(({ page, type, selected, ...item }, index) => {
								let children = null;

								if (type === 'start-ellipsis' || type === 'end-ellipsis') {
									children = '…';
								} else if (type === 'page') {
									children = (
										//MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-page MuiPaginationItem-textSecondary 
										//
										<button type="button" className={cx('MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-page MuiPaginationItem-textSecondary', selected && 'Mui-selected')} {...item}>
											{page}
										</button>
									);
								} else {
									children = (
										<button type="button" className="btn-link" {...item}>
											{type === "previous" ? (props?.buttonPreviousText || messages['pag_prev']) : (props?.buttonNextText || messages['pag_next'])}
										</button>
									);
								}

								return <li key={index}>{children}</li>;
							})
						}
					</ul>
				</Grid>

				<Grid
					item
					xs={props?.paginationGrid?.countTotal?.xs || 12}
					sm={props?.paginationGrid?.countTotal?.sm || 12}
					md={props?.paginationGrid?.countTotal?.md || 4}
					lg={props?.paginationGrid?.countTotal?.lg || 4}
					container
					direction="row"
					justifyContent="center"
					alignItems="flex-start"
					style={{ paddingBottom: 20 }}
				>
					<div>
						<span className="page-circle cursor-auto">Total: {props.count}</span>
					</div>
				</Grid>

				<Grid
					item
					xs={props?.paginationGrid?.rowsPerPage?.xs || 12}
					sm={props?.paginationGrid?.rowsPerPage?.sm || 12}
					md={props?.paginationGrid?.rowsPerPage?.md || 4}
					lg={props?.paginationGrid?.rowsPerPage?.lg || 4}
					container
					direction="row"
					justifyContent="flex-end"
					alignItems="flex-start"
					className="pagination-controls"
					style={{ paddingRight: props.paddingRight }}
				>
					<div>
          {
            !props.valuesPerPage
            ?
            <React.Fragment>
              <span
                className={`page-circle ${props.rowsPerPage == 10 ? 'page-circle-active' : ''}`}
                onClick={() => handleChangeRowsPerPage(10)}
              >
                10
              </span>

              <span
                className={`page-circle ${props.rowsPerPage == 25 ? 'page-circle-active' : ''}`}
                onClick={() => handleChangeRowsPerPage(25)}
              >
                25
              </span>

              <span
                className={`page-circle ${props.rowsPerPage == 50 ? 'page-circle-active' : ''}`}
                onClick={() => handleChangeRowsPerPage(50)}
              >
                50
              </span>
            </React.Fragment>
            :
              //Array to pagination items
              props.valuesPerPage.map((element, index) =>{
                return(
                  <span
                    key={index}
                    className={`page-circle ${props.rowsPerPage == element ? 'page-circle-active' : ''}`}
                    onClick={() => handleChangeRowsPerPage(element)}
                  >
                    {element}
                  </span>
                )
              })
            }

						{
							!props.hideAllInPagination && (
								<span
									className={`page-circle ${props.rowsPerPage == props.count || allRows ? 'page-circle-active' : ''
										}`}
									onClick={() => handleChangeRowsPerPage(props.count)}
								>
									{messages["table_all"]}
								</span>
							)
						}


						<span className={`page-circle cursor-auto`}>{messages["pag_per_page"]}</span>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default injectIntl(Pagination);
