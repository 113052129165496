import {
    ALERT_LOG_GET_SYSTEM_ALERT_LOG,
    ALERT_LOG_GET_SYSTEM_ALERT_LOG_RESPONSE
  } from 'Redux/actionTypes';
  
  export const alertLogGetSystemAlertlog = (data) => ({
    type: ALERT_LOG_GET_SYSTEM_ALERT_LOG,
    payload: { data },
  });
  
  export const alertLogGetSystemAlertlogResponse = (data) => ({
    type: ALERT_LOG_GET_SYSTEM_ALERT_LOG_RESPONSE,
    payload: { data },
  });