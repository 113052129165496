export const DASHBOARD_PANEL_SEARCH = "DASHBOARD_PANEL_SEARCH";
export const DASHBOARD_PANEL_SEARCH_RESPONSE = "DASHBOARD_PANEL_SEARCH_RESPONSE";
export const DASHBOARD_PANEL_CREATE = "DASHBOARD_PANEL_CREATE";
export const DASHBOARD_PANEL_CREATE_RESPONSE = "DASHBOARD_PANEL_CREATE_RESPONSE";
export const DASHBOARD_PANEL_UPDATE = "DASHBOARD_PANEL_UPDATE";
export const DASHBOARD_PANEL_UPDATE_RESPONSE = "DASHBOARD_PANEL_UPDATE_RESPONSE";
export const DASHBOARD_PANEL_DELETE = "DASHBOARD_PANEL_DELETE";
export const DASHBOARD_PANEL_DELETE_RESPONSE = "DASHBOARD_PANEL_DELETE_RESPONSE";
export const DASHBOARD_PANEL_OPEN_CREATE = "DASHBOARD_PANEL_OPEN_CREATE";
export const DASHBOARD_PANEL_OPEN_CREATE_EDIT_MODAL = "DASHBOARD_PANEL_OPEN_CREATE_EDIT_MODAL";
export const DASHBOARD_PANEL_SIZE_CREATE_EDIT_MODAL = "DASHBOARD_PANEL_SIZE_CREATE_EDIT_MODAL";
export const DASHBOARD_PANEL_CREATE_GADGET = "DASHBOARD_PANEL_CREATE_GADGET";
export const DASHBOARD_PANEL_CREATE_GADGET_RESPONSE = "DASHBOARD_PANEL_CREATE_GADGET_RESPONSE";
export const DASHBOARD_PANEL_DELETE_GADGET = "DASHBOARD_PANEL_DELETE_GADGET";
export const DASHBOARD_PANEL_DELETE_GADGET_RESPONSE = "DASHBOARD_PANEL_DELETE_GADGET_RESPONSE";
export const DASHBOARD_PANEL_UPDATE_GADGET = "DASHBOARD_PANEL_UPDATE_GADGET";
export const DASHBOARD_PANEL_UPDATE_GADGET_RESPONSE = "DASHBOARD_PANEL_UPDATE_GADGET_RESPONSE";
export const DASHBOARD_PANEL_UPDATE_POSITION_GADGET = "DASHBOARD_PANEL_UPDATE_POSITION_GADGET";
export const DASHBOARD_PANEL_UPDATE_POSITION_GADGET_RESPONSE = "DASHBOARD_PANEL_UPDATE_POSITION_GADGET_RESPONSE";
export const DASHBOARD_PANEL_UNIT = "DASHBOARD_PANEL_UNIT";
export const DASHBOARD_PANEL_UNIT_RESPONSE = "DASHBOARD_PANEL_UNIT_RESPONSE";
export const DASHBOARD_PANEL_ERROR_USER_GADGET_RESPONSE = "DASHBOARD_PANEL_ERROR_USER_GADGET_RESPONSE";