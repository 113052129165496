export default {
    "accountTagsCreateEditTag_success": "Etiqueta guardada",
    "accountTagsCreateEditTag_editTitle": "Editar etiqueta",
    "accountTagsCreateEditTag_createTitle": "Nueva Etiqueta",
    "accountTagsCreateEditTag_color": "Escoga Nombre y Color",
    "accountTagsCreateEditTag_label": "Nombre",
    "accountTagsCreateEditTag_nestUnder": "Anidar debajo",
    "accountTagsCreateEditTag_typeToSearch": "Escriba para buscar...",
    "accountTagsCreateEditTag_tag": "Etiqueta",
    "accountTagsCreateEditTag_vehicles": "Vehículos",
    "accountTagsCreateEditTag_drivers": "Conductores",
    "accountTagsCreateEditTag_landmarks": "Landmarks",
    "accountTagsCreateEditTag_users": "Usuarios",
    "accountTagsCreateEditTag_forms": "Formularios",
    "accountTagsCreateEditTag_dynamicForms": "Formularios Dinámicos",
    "accountTagsCreateEditTag_geofences": "Geofences",
    "accountTagsCreateEditTag_errorParentTag": "No puede agregar como padre al mismo tag",
    "accountTagsCreateEditTag_unit": "Unidades"
};