//View Keys
export default {
	login_username: 'Usuario',
	login_password: 'Contraseña',
	login_signIn: 'Iniciar Sesion',
  login_forgot_password: 'Olvido la contraseña?',
  login_log_into_account: 'Ingrese a su cuenta',
  login_next: 'Siguiente',
  login_continue: 'Continuar',
  login_signInWithGoogle: 'Inicia sesión con Google',
  login_signInWithFacebook: 'Inicia sesión con Facebook',
  login_signInWithApple: 'Inicia sesión con Apple',
  page_expirate_link: 'El enlace expiró',
  page_not_found: 'Oops! Golpeamos un tope de velocidad',
  page_not_found_message: 'Parece que no se encuentra la página que está buscando. Si necesita que lo lleven a otro lugar, pruebe con uno de los botones de la izquierda',
  page_not_found_homepage:'Ir a la página de inicio',
  login_social_media_or: 'O',
  login_social_media_error_default: 'Nombre de usuario o contraseña incorrecta',
  login_social_media_error_presignin: 'PreSignUp failed with error ',
  login_social_media_error_preauthentication:'PreAuthentication failed with error',
  login_social_media_error: 'Error durante el proceso de inicio de sesión',
  login_social_modal_configuration_facebook: 'Configuración de Facebook',
  login_social_modal_configuration_apple: 'Configuración de Apple',
  login_social_media_title:'Siga los pasos a continuación para configurar correctamente el inicio de sesión con ',
  login_social_step_one_facebook:'Vaya a Facebook y haga clic en',
  login_social_step_two_facebook:'Busque la aplicación GPSTrackIt Fleet y haga clic en "Ver y editar"',
  login_social_step_three_facebook:'Haga clic en "Aplicaciones y sitios web".',
  login_social_step_four_facebook:'Haga clic en el botón "Eliminar"',
  login_social_step_five_facebook:'Intente iniciar sesión nuevamente con la opción "Compartir correo electrónico" habilitada.',
  login_social_step_one_apple:'Ir a administrar su cuenta de Apple',
  login_social_step_two_apple:'En la sección Seguridad, haga clic en "Administrar aplicaciones y sitios web".',
  login_social_step_three_apple:'Haga clic en la aplicación "GPSTrackit".',
  login_social_step_four_apple:'Haga clic en "Dejar de usar ID de Apple".',
  login_social_step_five_apple:'Intente iniciar sesión nuevamente con la opción "Compartir correo electrónico" habilitada.',
  login_social_recommendation_google: 'Recomendación',
  login_social_multiples_accounts:'Si tiene varias cuentas de Google y desea probar con otra, haga clic en',
  login_social_here:'aquí.',
  login_social_error_title: 'Su dirección de correo electrónico se nos ha ocultado, puede corregirla configurándola correctamente en',
  login_social_settings:'Ajustes',
  login_facebook:'Facebook',
  login_apple:'Apple',
  login_referral_bonus: "¡Obtenga un bono de referencia de $ 500!",
  login_new_video: "Nuevo video disponible! Haga clic aquí para saber más.",
  login_back: "Volver",
  login_error_by_default_cognito: 'Nombre de usuario o contraseña incorrecta.',
  login_password_incorrect: 'La contraseña ingresada es incorrecta.',
  login_api_email_is_already_used: 'Otro usuario ya está utilizando el correo electrónico. Elija otro correo electrónico.',
  login_api_user_is_already_migrated: 'El usuario ya está migrado.',
  login_api_user_email_not_found: 'No se encontró el correo electrónico del usuario',
  login_api_new_password_must_be_different: 'Cambiar contraseña: la nueva contraseña debe ser diferente de la contraseña anterior',
  login_api_username_incorrect: 'El nombre de usuario o correo electrónico ingresado es incorrecto.',
  login_api_challenge_name: 'Nombre de desafío inesperado',
  login_privacy_policy: 'Política de privacidad',
  login_passive_acceptance: "Al iniciar sesión en GPSTrackit, reconoce que acepta los ",
  login_end_user_license_agreement: 'Términos de servicio',
  login_need_help: '¿Necesita ayuda para registrarse?',
  login_inactive_account: 'Nombre de usuario o contraseña incorrecta.',
  login_forgot_username_or_password: 'Olvidaste tu nombre de usuario o contraseña?',
  login_email_or_username: 'Correo o Usuario',
  login_accept_terms_condition: '¿Acepta los términos y condiciones?',
  login_chat_with_support: 'Chatear con Soporte'
};
