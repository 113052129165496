export const SETTINGS_FUEL_CARD_SEARCH = "SETTINGS_FUEL_CARD_SEARCH";
export const SETTINGS_FUEL_CARD_SEARCH_RESPONSE = "SETTINGS_FUEL_CARD_SEARCH_RESPONSE";
export const SETTINGS_FUEL_CARD_GET = "SETTINGS_FUEL_CARD_GET";
export const SETTINGS_FUEL_CARD_GET_RESPONSE = "SETTINGS_FUEL_CARD_GET_RESPONSE";
export const SETTINGS_FUEL_CARD_UPDATE = "SETTINGS_FUEL_CARD_UPDATE";
export const SETTINGS_FUEL_CARD_UPDATE_RESPONSE = "SETTINGS_FUEL_CARD_UPDATE_RESPONSE";    
export const SETTINGS_FUEL_CARD_OPEN_UPDATE = "SETTINGS_FUEL_CARD_OPEN_UPDATE";    
export const SETTINGS_FUEL_CARD_BULK_OPEN = "SETTINGS_FUEL_CARD_BULK_OPEN";
export const SETTINGS_FUEL_CARD_BULK_UPDATE = "SETTINGS_FUEL_CARD_BULK_UPDATE";
export const SETTINGS_FUEL_CARD_BULK_UPDATE_RESPONSE = "SETTINGS_FUEL_CARD_BULK_UPDATE_RESPONSE";
export const SETTINGS_FUEL_CARD_RULE = "SETTINGS_FUEL_CARD_RULE";
export const SETTINGS_FUEL_CARD_RULE_RESPONSE = "SETTINGS_FUEL_CARD_RULE_RESPONSE";
