import React from 'react'
import { Icon } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from 'Redux/actions';

const HamburgerToggle = () =>  {

  const hideToggleButton = useSelector(state => state.commomRedux.hideToggleButton);

  const handleClick = useDispatch()
  return (
    <div className="header-menu">
      {
        !hideToggleButton
        &&
        <a
          onClick={() => handleClick(toggleSidebar())}
        >
          <Icon className="vertical-align-middle">menu</Icon>
        </a>
      }
      </div>
  )
}

export default HamburgerToggle