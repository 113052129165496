//imports
import {
	FORCE_CHANGE_PASSWORD,
	FORCE_CHANGE_PASSWORD_RESULT
} from 'Redux/actionTypes';

/* ACTIONS */

export const forceChangePassword = (data) => ({
	type: FORCE_CHANGE_PASSWORD,
	payload: data,
});

export const forceChangePasswordResult = (data) => ({
	type: FORCE_CHANGE_PASSWORD_RESULT,
	payload: data,
});
