import React, { useState, useEffect, Children, cloneElement, Fragment, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Controls
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import ToolbarComponent from './Toolbar';
import Pagination from './Pagination';
import Checkbox from '@mui/material/Checkbox';
import { Icon, Collapse, IconButton } from '@mui/material';
import useExport from './Export';
import './table.scss';
import { LinearProgress } from 'Components';
import { NotificationManager } from 'Components/Notifications';
export const Toolbar = ToolbarComponent;
export * from './Toolbar';

// // Sortable library
// import { SortableContainer, SortableElement } from "react-sortable-hoc";

//resizing library
import { resizableGrid } from './Plugin/resize.columns'


//international
import { useIntl } from "react-intl";



export const Column = (props) => {
  const { mainProps, style } = props;
  let view = 'comfortable';
  if (mainProps && mainProps.viewStyle) {
    view = mainProps.viewStyle;
  }

  let align = 'left';
  if (props && props.align) {
    align = props.align;
  }

  return (
    <TableCell className={`table-${view}-view table-${align}-item ${props.className || ""} `} style={style || {}}>
      {props.children ? props.children(props.row) : props.row[props.field]}
    </TableCell>
  );
};

// ***************   ordering columns helpers *******************************//

// const SortableHead = SortableContainer(({ children, className }) => {
//   return (
//     <TableHead className={className}>
//       <TableRow style={{ padding: "15px", backgroundColor: "white" }} >{children}</TableRow>
//     </TableHead>
//   );
// });

// const SortableCell = SortableElement(({ value }) => {
//   return <>{value}</>;
// });

// ***************   ordering columns helpers *******************************//

const TableComponent = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    resetPagination() {
      setPage(1)
    },
    resetRowsPerPage() {
      setPage(1)
      if (props.rowsPerPage) {
        setRowsPerPage(props.rowsPerPage)
      }
    },
    changePreferences(_visibleColumns, _columnsOrder, _columnResize) {
      _visibleColumns && setVisibleColumns(_visibleColumns)
      _columnsOrder && setColumnOrder(_columnsOrder)
      _columnResize && setColumnsResize(_columnResize)
    }

  }));

  const { height, hiddenHeader, heightAuto = false } = props;
  const [data, setData] = useState(props.data || []);
  const [rows, setRows] = useState(props.data || []);
  const [rowsToExport, setRowsToExport] = useState(props.data || []);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 50);
  const [rowsCount, setRowsCount] = useState(props.total || rows ? rows.length : 0);
  const [selectedItems, setSelectedItems] = useState([]);
  //const [customValue, setCustomValue] = useState([null]);
  const [searchText, setSearchText] = useState('');
  const [order, setOrder] = useState(props.order || '');
  const [orderBy, setOrderBy] = useState(props.orderBy || '');
  const [openCollapsedRows, setOpenCollapsedRows] = React.useState([]);
  const isSelected = (item) => selectedItems.indexOf(item) !== -1;
  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  //*** for context menu constants and alert */
  const { messages } = useIntl();
  //*** for context menu constants and alert */

  /*useEffect(() => {
    if (props.showingAtThisMoment) {
      props.showingAtThisMoment(rows);
    }
  }, [rows])*/

  //component will mount
  useEffect(() => {
    processData({});

  }, []);

  const myRef = useRef();

  //Component did update
  useEffect(() => {

    if (!_.isEqual(data, props.data)) {
      setData(props.data)
      setRows(props.data);
      setRowsToExport(props.data);
      if (props.hidePagination) {
        setRowsPerPage(props.data ? props.data.length : 0);
        processData({ newRowsPerPage: props.data.length });
      } else {
        processData({});
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (props.loading) {
      setOpenCollapsedRows([]);
      setSelectedItems([]);
    }

  }, [props.loading])

  let columns = [];
  let toolbar;
  if (Array.isArray(props.children)) {
    columns = props.children.filter((col) => col.type === Column);
    toolbar = props.children.find((col) => col.type !== Column);
  } else {
    if (props.children.type === Column) {
      columns = props.children;

    } else {
      throw 'No Columns';
    }
  }

  window.columns = columns

  const exportExcelPdf = useExport(
    props.getHeaderToExport,
    props.getDataToExport,
    columns,
    (searchText && rowsToExport) || //Replace rows by rowsToExport - remove pagination on data to export
    (rowsToExport.map(x => {

      //Add validation to get customValue to export
      let newRow = { ...x }
      columns.forEach(column => {
        if (column.props.customValue)
          if (typeof column.props.customValue === 'function') {
            const currentCustomValue = column.props.customValue(x)
            if (currentCustomValue != null)
              newRow[column.props.field] = currentCustomValue
          } else {
            newRow[column.props.field] = column.props.customValue
          }
      })

      return newRow
    }) || []),
    searchText ? [] : selectedItems,
    props.name,
    props.exportOrientation
  );

  const handleExport = (type) => {
    if (props.exportServerSide) {
      props.exportServerSide(type);
    } else if (type === 'CSV') {
      exportExcelPdf.exportCSV(rows);
    } else if (type === 'PDF') {
      if (props.customPdfExport) {
        props.customPdfExport()
      }
      else {
        exportExcelPdf.exportPDF(rows);
      }
    }
  };

  /*** Pagination functions */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    processData({ newPage: newPage });

    setSelectedItems([]);
    if (props.setSelectedItems)
      props.setSelectedItems([]);

    if (props.onChangeNewPage)
      props.onChangeNewPage(newPage, rowsPerPage)
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setPage(1);
    setRowsPerPage(newRowsPerPage);
    processData({ newPage: 1, newRowsPerPage: newRowsPerPage });

    if (props.onChangeNewPage)
      props.onChangeNewPage(1, newRowsPerPage)
  };

  /*** Search functions */
  const handleSearch = (searchText) => {
    /** Adding timeout to improve performance */
    if (window.typingTime) {
      clearTimeout(window.typingTime)
    }

    window.typingTime = setTimeout(() => {
      setPage(1);
      setSearchText(searchText);
      processData({ newPage: 1, newSearchText: searchText });
      if (props.onChangeNewPage)
        props.onChangeNewPage(1, rowsPerPage)
      if (props.onChageSearch)
        props.onChageSearch(searchText)
      window.typingTime = null
    }, 500)
    /** Adding timeout to improve performance */
  };

  /*** Sort functions */
  const handleSort = (order, orderBy, getValue) => {
    setPage(1);
    setOrder(order);
    setOrderBy(orderBy);
    //setCustomValue([getValue]);
    processData({
      newPage: 1,
      newOrder: order,
      newOrderBy: orderBy,
      newCustomValue: getValue,
    });

    if (props.onChageSort)
      props.onChageSort(order, orderBy, getValue, 1, rowsPerPage)
  };

  const processData = ({
    newSearchText,
    newOrder,
    newOrderBy,
    newPage,
    newRowsPerPage,
    //newCustomValue
  }) => {
    //Filtering
    let currentSearchText = newSearchText != undefined ? newSearchText : searchText;
    let newRows = [];
    let rowsCount = 0;

    if (!props.serverSide) {
      if (!currentSearchText || (currentSearchText && currentSearchText.length <= 0)) {
        newRows = [...props.data];
      } else {
        props.data.forEach((row) => {
          let newRow = {};
          columns.forEach(column => {

            if (column.props.customValue) {

              /** Managing custom value as a function */
              if (typeof column.props.customValue === 'function') {
                const currentCustomValue = column.props.customValue(row)
                if (currentCustomValue != null)
                  newRow[column.props.field] = currentCustomValue
              } else {
                newRow[column.props.field] = column.props.customValue
              }
              /** Managing custom value as a function */

            } else {

              /** Changing filter to access to objects and check data with string */
              if (typeof row[column.props.field] === 'object') {
                newRow[column.props.field] = JSON.stringify(row[column.props.field])

              }
              else {
                newRow[column.props.field] = row[column.props.field]
              }
              /** Changing filter to access to objects and check data with string */

            }
          });


          let match = false;
          let values = Object.values(newRow);

          values.forEach((value) => {
            let stack = value ? value.toString() : '';
            let needle = currentSearchText ? currentSearchText.toString() : '';

            if (!props.noCaseSensitive) {
              needle = needle.toLowerCase().replace(/\s+/g, '');
              stack = stack.toLowerCase().replace(/\s+/g, '');
            }

            if (stack.indexOf(needle) >= 0) {
              match = true;
            }
          });

          if (match) {
            newRows.push(row);
          }
        });
      }

      rowsCount = newRows.length;

      //Ordering
      let currentOrder = newOrder != undefined ? newOrder : order;
      let currentOrderBy = newOrderBy != undefined ? newOrderBy : orderBy;
      // let currentCustomValue = newCustomValue != undefined ? newCustomValue : customValue[0];

      //when table grouping is necesary force order
      /*if (props.groupField)
        newRows = stableSort(newRows, getComparator("asc", props.groupField, currentCustomValue));
      else
        newRows = stableSort(newRows, getComparator(currentOrder, currentOrderBy, currentCustomValue));*/

      //Sorting multiple
      let sortBy = []
      //when grouping the sorting is mandatory
      if (props.groupField)
        sortBy.push({
          prop: props.groupField,
          direction: 1 //asc
        })

      //when have another sort
      if (currentOrderBy)
        sortBy.push({
          prop: currentOrderBy,
          direction: currentOrder === "asc" ? 1 : -1
        });

      const cleanValues = (sortBy, a, b) => {

        let column = columns.find(x => x.props?.field === sortBy.prop)
        let customValue = column?.props.customValue;
        let customOrder = column?.props.customOrder;

        let av = customOrder && String(customOrder(a)) || customValue && String(customValue(a)) || a[sortBy.prop] || "";
        let bv = customOrder && String(customOrder(b)) || customValue && String(customValue(b)) || b[sortBy.prop] || "";

        /** found that some functions brings undefined as a string lol */
        if (av === "undefined") { av = "" }
        if (bv === "undefined") { bv = "" }

        /** 0 is not count so it must be re define */

        if (!isNaN(av) && av !== "") {
          av = Number(av)
        }

        if (!isNaN(bv) && bv !== "") {
          bv = Number(bv)
        }

        //this is not required on the reverse
        if (typeof av !== 'number' || typeof bv !== 'number') {
          av = av?.toString().toLowerCase()
          bv = bv?.toString().toLowerCase()
        }

        return [av, bv]

      }

      sortBy.map(sortBy => {

        newRows.sort((a, b) => {

          let result

          const values = cleanValues(sortBy, a, b)

          const av = values[0]
          const bv = values[1]


          if (av === bv) {
            result = 0
          }
          else {
            result = ((!av && av !== 0) && 1) || ((!bv && bv !== 0) && -1) || (av < bv ? -1 : 1)
          }

          return result;
        })

        if (sortBy.direction === -1) {
          newRows.reverse()
        }
      })

      //data without paggination
      setRowsToExport(newRows)

      //Pagination
      let currentPage = newPage != undefined ? newPage : page;
      let currentRowsPerPage = newRowsPerPage != undefined ? newRowsPerPage : rowsPerPage;
      newRows = newRows.slice(
        (currentPage - 1) * currentRowsPerPage,
        (currentPage - 1) * currentRowsPerPage + currentRowsPerPage,
      );

      if (currentRowsPerPage != rowsPerPage) {
        newRows = newRows.slice(0 * currentRowsPerPage, 0 * currentRowsPerPage + currentRowsPerPage);
      }

      setRows(newRows);

      if (props.watchSearchDataChange) {
        props.watchSearchDataChange(newRows)
      }

      setRowsCount(rowsCount);
      rowsCount = newRows.length;

      if (props.showingAtThisMoment && !_.isEqual(rows, newRows))
        props.showingAtThisMoment(newRows);
    }
    else {
      //setRowsPerPage(rowsPerPage)
      setRows(props.data)
      setRowsCount(props.total)

      if (props.showingAtThisMoment)
        props.showingAtThisMoment(props.data);
    }
  };


  // ***************   ordering columns state *******************************//

  const [columnOrder, setColumnOrder] = useState(new Array(columns.length).fill(null).map((n, i) => i))

  const onReorderEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      //change cursor
      document.body.style.cursor = 'default'

      const newOrder = [...columnOrder];
      const moved = newOrder.splice(oldIndex, 1);
      newOrder.splice(newIndex, 0, moved[0]);
      setColumnOrder(newOrder);
    },
    [columnOrder, setColumnOrder]
  );

  // ***************   column resizing listener and setup *******************************//

  const [columnResize, setColumnsResize] = useState({})

  window.addEventListener('resize-columns', function (e) {

    if (window.saveResizing) {
      clearTimeout(window.saveResizing)
    }

    window.saveResizing = setTimeout(() => {

      const resizeToSave = { ...columnResize, [e.detail.Column]: e.detail.finalWidth }

      setColumnsResize(resizeToSave)

    }, 500)

  }, false);


  // ***************   cancel column ordering drag and drop *******************************//
  const shouldCancelStart = (e) => {
    //return e.target.tagName.toLowerCase() !== "th" || !props.orderColumn
    return e.target.tagName.toLowerCase() === "path" || e.target.tagName.toLowerCase() === "svg" || e.target.tagName.toLowerCase() === "div" || !props.orderColumn
  }

  let columnLabels = []

  columns.map(column => columnLabels.push(column.props.label))

  const [visibleColumns, setVisibleColumns] = useState(columnLabels)

  const [readyTosavePreferences, setReadyTosavePreferences] = useState(false)

  // *************** save preferences if column or visibility change   *******************************//
  useEffect(() => {

    if (!props.loading && readyTosavePreferences && props.savePreferences) {
      props.savePreferences(JSON.stringify({ visibleColumns, columnOrder, columnResize }))
    }

    if (props.loading && !readyTosavePreferences) {
      setReadyTosavePreferences(true)
    }

  }, [visibleColumns, columnOrder, columnResize]);

  // ***************   ordering columns state *******************************//

  let view = 'comfortable';
  if (props && props.viewStyle) {
    view = props.viewStyle;
  }

  const heightValue = hiddenHeader || heightAuto ? "auto" : `calc(100vh - ${props.topSpaceSize || 0}px)`;

  useEffect(() => {

    if (props.visibleColumns && JSON.stringify(props.visibleColumns) != JSON.stringify(visibleColumns)) {
      setVisibleColumns(props.visibleColumns)
    }

  }, [props.visibleColumns]);

  //** Handling columns order change on parent  */

  useEffect(() => {
    if (props.columnsOrder && JSON.stringify(props.columnsOrder) != JSON.stringify(columnOrder)) {
      setColumnOrder(props.columnsOrder)
    }

  }, [props.columnsOrder]);

  //** Handling columns order change on parent  */

  useEffect(() => {

    if (props.columnResize && JSON.stringify(props.columnResize) != JSON.stringify(columnResize)) {
      setColumnsResize(props.columnResize)
    }

  }, [props.columnResize]);

  useEffect(() => {
    if (myRef.current && !props.loading && props.setColumnResizing && props.data && props.data.length > 0) {
      window.setTimeout(() => {
        const table = myRef.current
        const tds = table.getElementsByTagName("td");
        if (tds.length > 1) {
          resizableGrid(table, columnResize)
        }
      }, 500)
    }
  }, [myRef, props.loading, columnResize, props.data, visibleColumns])

  const handleChangeVisibility = (e, column) => {

    let visibleCopy = JSON.parse(JSON.stringify(visibleColumns))
    if (e.target.checked) {
      visibleCopy.push(column.props.field)
    }
    else {
      if (visibleColumns.length > 1) {
        const index = visibleCopy.indexOf(column.props.field);
        if (index > -1) {
          visibleCopy.splice(index, 1);
        }
      }
      else {
        return NotificationManager.warning(
          messages['visibility_validation_message'],
          messages['visibility_validation_title'],
          3000,
          null,
          null,
          'warning',
          null
        );
      }

    }

    setVisibleColumns(visibleCopy)
  }

  const makeAllColumnsVisible = (option) => {
    if (option) {
      let visibleC = []
      columns.map(column => visibleC.push(column.props.field))
      setVisibleColumns(visibleC)
    } else {
      setVisibleColumns([])
    }
  }

  let currentGroup

  return (
    <div style={{ width: '100%' }}>
      {
        toolbar &&
        cloneElement(toolbar, {
          onSearch: props.onSearch ? props.onSearch : handleSearch,
          onExport: handleExport,
          hideDownloadButton: props.hideDownloadButton,
          hidePdfOption: props.hidePdfOption,
          hideSearchForm: props.hideSearchForm,
          sizeLeftGrid: props.sizeLeftGrid,
          //columns,
          setColumnVisibility: props.setColumnVisibility,
          visibleColumns: visibleColumns || [],
          handleChangeVisibility: handleChangeVisibility,
          makeAllColumnsVisible: makeAllColumnsVisible
        })
      }

      {
        props.loading
          ? (
            <Fragment>
              <LinearProgress color="primary" className="table-padding-content" />
              {
                props.allScreenLoading && (
                  <div className="loadingAllScreen">

                  </div>
                )
              }
            </Fragment>
          )
          : (
            !props.isExpandableOnHover && (
              <div className="content-loading-disable">
              </div>
            )
          )
      }
      <TableContainer
        className={`${!hiddenHeader && "hiddenHeader"} ${props.opacityInTable && "actions-disabled"}  ${props.className}`}
        style={{ height: height || heightValue }}
        ref={props.wrapperRefOnHover}
      >
        <div className="table" style={props.style || {}}>
          <Table
            ref={myRef}
            stickyHeader={props.stickyHeader}
            aria-label="Data View"
            size={props.size ? props.size : 'medium'}
            style={{ display: props.loading ? "none" : "table" }}
          >
            {/* <SortableHead axis="x"
              onSortStart={() => (document.body.style.cursor = 'grab')}
              onSortEnd={onReorderEnd}
              shouldCancelStart={shouldCancelStart}
              className={props.hiddenHeader ? "table-hidden-header" : ""}>*/}
            <TableHead>
              <TableRow>
              {
                !props.hideSelectableCheckbox && (
                  <TableCell className={`table-${view}-view`} style={{ ...props.globalHeadersCellStyles }} >
                    <div className="resize-wrapper" >
                      <Checkbox
                        color="default"
                        className="checkbox-black"
                        indeterminate={selectedItems.length > 0 && selectedItems.length < rows.length}
                        checked={selectedItems.length === rows.length}
                        onChange={(event) => {
                          if (event.target.checked) {
                            if (selectedItems.length > 0 && selectedItems.length < rows.length) {
                              setSelectedItems([]);
                              if (props.setSelectedItems) {
                                props.setSelectedItems([]);
                              }
                            } else {
                              setSelectedItems(rows);
                              if (props.setSelectedItems) {
                                props.setSelectedItems(rows);
                              }
                            }
                          } else {
                            setSelectedItems([]);
                            if (props.setSelectedItems) {
                              props.setSelectedItems([]);
                            }
                          }
                        }}
                        inputProps={{ 'aria-label': 'Select all rows' }}
                      />
                    </div>
                  </TableCell>
                )
              }

              {
                props.renderExpandableRow
                && <TableCell />
              }

              {
                columnOrder.map((colIdx, index) => {

                  const item = columns[colIdx]
                  let align = 'left';
                  if (item.props && item.props.align) {
                    align = item.props.align;
                  }
                  return (
                    (!props.setColumnVisibility || (props.setColumnVisibility && visibleColumns?.includes(item.props.field))) &&
                    // <SortableCell
                    //   index={index}
                    //   key={colIdx}
                    //   value={

                    <TableCell
                      style={{ ...props.globalHeadersCellStyles }}
                      className={`table-${view}-view table-${align}-item`}
                      key={colIdx}
                      sortDirection={orderBy === item.props.field ? (order || 'asc') : false}
                    >
                      <div className="resize-wrapper" >
                        {
                          !item.props.disableSort ? (
                            <TableSortLabel
                              active={orderBy === item.props.field}
                              direction={orderBy === item.props.field ? (order || 'asc') : 'asc'}
                              onClick={(e) => {

                                if (e.target.tagName.toLowerCase() === 'path' ||
                                  e.target.tagName.toLowerCase() === 'svg' || item.props.SortOnCellClick) {
                                  let isAsc = false;
                                  if (orderBy === item.props.field) {
                                    if (order != 'asc') {
                                      isAsc = true;
                                    }
                                  }

                                  if (props.onSort) {
                                    props.onSort(
                                      isAsc ? 'asc' : 'desc',
                                      item.props.field,
                                      item.props.customValue,
                                    );
                                  } else {
                                    handleSort(
                                      isAsc ? 'asc' : 'desc',
                                      item.props.field,
                                      item.props.customValue,
                                    );
                                  }
                                }


                              }}
                            >

                              <span style={{ ...item.props.headerStyles }} className={`text-bold ${item.props.headerClassName || ""} `}>{item.props.label}</span>
                            </TableSortLabel>
                          ) : (
                            <span style={{ ...item.props.headerStyles }} className={`text-bold ${item.props.headerClassName || ""} `}>{item.props.label}</span>
                          )
                        }
                      </div>
                    </TableCell>
                    //}
                    // />
                  );
                })
              }
              </TableRow>
            </TableHead>
            {/*</SortableHead> */}

            <TableBody>
              {
                rows.map((row, index) => {

                  let showGroup = false
                  if (row[props.groupField] != currentGroup) {
                    currentGroup = row[props.groupField]
                    showGroup = true
                  }

                  const isItemSelected = isSelected(row);
                  const labelId = `table-checkbox-${index}`;
                  const emptyCells = [];
                  if (props.renderExpandableRowStartColumn) {
                    for (let i = 0; i < props.renderExpandableRowStartColumn; i++) {
                      emptyCells.push(i);
                    }
                  }
                  const childrenWithProps = Children.map(columns, (child) => {
                    return cloneElement(child, { row: row, mainProps: props });
                  });

                  return (
                    <Fragment key={index}>
                      {
                        showGroup &&
                        <TableRow>
                          <td colSpan={columns.length}>
                            {props.customGroupArea && props.customGroupArea(currentGroup, row) || <div>AREA {currentGroup}</div>}
                          </td>
                        </TableRow>
                      }

                      <TableRow
                        hover
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                        tabIndex={-1}
                        onMouseOver={() => {
                          if (props.renderExpandableRowOnHover) {
                            props.detectMouseOverRow(index);
                          }
                        }}
                        className={props.noBorders && "no-borders-table"}
                      >
                        {
                          !props.hideSelectableCheckbox && (
                            <TableCell className={`table-${view}-view`}>
                              <div className="resize-wrapper" >
                                <Checkbox
                                  color="default"
                                  className="checkbox-black"
                                  checked={isItemSelected}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                  onClick={() => {
                                    const selectedIndex = selectedItems.indexOf(row);
                                    let newSelected = [];

                                    if (selectedIndex === -1) {
                                      newSelected = newSelected.concat(selectedItems, row);
                                    } else if (selectedIndex === 0) {
                                      newSelected = newSelected.concat(selectedItems.slice(1));
                                    } else if (selectedIndex === selectedItems.length - 1) {
                                      newSelected = newSelected.concat(selectedItems.slice(0, -1));
                                    } else if (selectedIndex > 0) {
                                      newSelected = newSelected.concat(
                                        selectedItems.slice(0, selectedIndex),
                                        selectedItems.slice(selectedIndex + 1),
                                      );
                                    }

                                    setSelectedItems(newSelected);
                                    if (props.setSelectedItems) {
                                      props.setSelectedItems(newSelected);
                                    }
                                  }}
                                />
                              </div>
                            </TableCell>
                          )
                        }

                        {
                          props.renderExpandableRow && (
                            <TableCell className={`table-${view}-view`} style={{ width: "50px", textAlign: "center" }}>
                              {row && row.Count && row.Count > 1 ? (
                                <div style={{ width: "50px" }}>
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => {
                                      if (openCollapsedRows.includes(index)) {
                                        setOpenCollapsedRows([
                                          ...openCollapsedRows.filter((op) => op !== index),
                                        ]);
                                      } else {
                                        props.idRenderExpandableRow(row)
                                        setOpenCollapsedRows([...openCollapsedRows, ...[index]]);
                                      }
                                    }}
                                  >
                                    {openCollapsedRows.includes(index) ? (
                                      <Icon>{`${props.iconOpen || 'layers_clear'}`}</Icon>
                                    ) : (
                                      <Icon >{`${props.iconClose || 'layers'}`}</Icon>
                                    )}
                                  </IconButton>
                                </div>

                              ) : ""}

                            </TableCell>
                          )
                        }

                        {
                          columnOrder.map((colIdx) => {
                            return (
                              (!props.setColumnVisibility || (props.setColumnVisibility && visibleColumns?.includes(childrenWithProps[colIdx].props.field))) ?
                                childrenWithProps[colIdx] : null
                            )
                          })}
                      </TableRow>
                      {(props.renderExpandableRowOnHover && props.openCollapsedRowsOnHover === index) &&
                        <TableRow>
                          {emptyCells.map((column, index) => {
                            return (
                              <TableCell key={index} style={{ padding: 0, width: "50px" }} />
                            )
                          })}
                          <TableCell style={{ padding: 0 }} colSpan={props.renderExpandableRowStartColumn ? (columns.length + 1) - props.renderExpandableRowStartColumn : columns.length + 1}>
                            <Collapse
                              in={true}
                              timeout="auto"
                              unmountOnExit
                            >
                              {props.renderExpandableRowOnHover(row)}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      }
                      {props.renderExpandableRow && (
                        <TableRow>
                          {emptyCells.map((column, index) => {
                            return (
                              <TableCell key={index} style={{ padding: 0, width: "50px" }} />
                            )
                          })}
                          <TableCell style={{ padding: 0 }} colSpan={props.renderExpandableRowStartColumn ? (columns.length + 1) - props.renderExpandableRowStartColumn : columns.length + 1}>
                            <Collapse
                              in={openCollapsedRows.includes(index)}
                              timeout="auto"
                              unmountOnExit
                            >
                              {props.renderExpandableRow(row)}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  );
                })}
            </TableBody>

          </Table>
        </div>
      </TableContainer>

      {(!props.hidePagination && !props.loading)
        &&
        <Pagination
          count={rowsCount}
          page={page}
          rowsPerPage={rowsPerPage}
          hideAllInPagination={props.hideAllInPagination}
          paddingRight={props.paddingRight || 50}
          valuesPerPage={props.valuesPerPage}
          buttonNextText={props?.buttonNextText}
          buttonPreviousText={props?.buttonPreviousText}
          paginationGrid={props?.paginationGrid}
          onChangeRowsPerPage={
            props.onChangeRowsPerPage
              ? props.onChangeRowsPerPage
              : handleChangeRowsPerPage
          }
          onChangePage={props.onChangePage ? props.onChangePage : handleChangePage}
        />
      }

    </div>
  );
});



export default TableComponent

TableComponent.propTypes = {
  data: PropTypes.array.isRequired,
};
