import React from "react";

function CompanyLogo({ urlImgLogo }) {
  return (
        <div className="header-logo-company">
          <img
            src={urlImgLogo}
            alt="image"
            className="header-logo-size"
            style={{
              width: `${urlImgLogo.includes("svg") ? "100px" : "auto"}`,
            }}
          />
        </div>
  );
}

export default CompanyLogo;
