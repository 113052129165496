//imports
import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from 'Components';

import {
	getPreferences,
	savePreference,
	clearRedux,
  updatePreferenceView
} from 'Redux/actions';

//component
const PreferencesContainer = forwardRef((props, ref) => {

	const dispatch = useDispatch()
	const { force = false } = props;
	const { preferencesCompany, preferencesUser, preferencesOther, returnServer } = useSelector(state => state.preferencesRedux);
	const { authenticatedId, user } = useSelector(state => state.securityRedux);
	const [loadPage, setLoadPage] = useState(false)

	useEffect(() => {
		dispatch(getPreferences("Company", user?.companyId, force))
		dispatch(getPreferences("User", authenticatedId, force))
		dispatch(getPreferences("Other", authenticatedId, force))

	}, [])

	useEffect(() => {
		if (returnServer) {
			setLoadPage(true)
			dispatch(clearRedux("PREFERENCES", { returnServer: false }))

			let preferencesKeyTemp = preferencesUser.find(x => x.preferenceKey === props.preferencesKey)
			let preferencesUserJSON = preferencesKeyTemp && preferencesKeyTemp.preferenceValue != "" && preferencesKeyTemp.preferenceValue || "{}"
			preferencesUserJSON = JSON.parse(preferencesUserJSON)

			preferencesKeyTemp = preferencesCompany.find(x => x.preferenceKey === props.preferencesKey)
			let preferencesCompanyJSON = preferencesKeyTemp && preferencesKeyTemp.preferenceValue != "" && preferencesKeyTemp.preferenceValue || "{}"
			preferencesCompanyJSON = JSON.parse(preferencesCompanyJSON)

			if (props.onInitialPreferences) props.onInitialPreferences(preferencesUserJSON, preferencesCompanyJSON);
		}
	}, [returnServer])

	useImperativeHandle(ref, () => ({
		getPreferences: (entity, key) => {

			let preferencesKeyTemp
			let preferencesJSON

			switch (entity) {
				case "User":
					preferencesKeyTemp = preferencesUser.find(x => x.preferenceKey === key)
					preferencesJSON = preferencesKeyTemp && preferencesKeyTemp.preferenceValue != "" && preferencesKeyTemp.preferenceValue || "{}"
					preferencesJSON = JSON.parse(preferencesJSON)
					return preferencesJSON

				case "Company":
					preferencesKeyTemp = preferencesCompany.find(x => x.preferenceKey === key)
					preferencesJSON = preferencesKeyTemp && preferencesKeyTemp.preferenceValue != "" && preferencesKeyTemp.preferenceValue || "{}"
					preferencesJSON = JSON.parse(preferencesJSON)
					return preferencesJSON

				case "Other":
					preferencesKeyTemp = preferencesOther.find(x => x.preferenceKey === key)
					preferencesJSON = preferencesKeyTemp && preferencesKeyTemp.preferenceValue != "" && preferencesKeyTemp.preferenceValue || "{}"
					preferencesJSON = JSON.parse(preferencesJSON)
					return preferencesJSON

			}
		},
		savePreference: (entity, key, preference) => {
      dispatch(updatePreferenceView(true))
			switch (entity) {
				case "User":
					dispatch(savePreference("User", authenticatedId, key, preference))
					break;
				case "Company":
					dispatch(savePreference("Company", user.companyId, key, preference))
					break;
				case "Other":
					dispatch(savePreference("Other", authenticatedId, key, preference))
					break;
			}
		}
	}));

	return (
		<Fragment>
			{
				!loadPage
					? <Loading full />
					: props.children
			}
		</Fragment>
	);

})

//Export Component
export default PreferencesContainer
