export default {
    mapGeofenceSettings_geofence_manage: "Gestionar",
    mapGeofenceSettings_geofence_contacts: "Contactos",
    mapGeofenceSettings_geofence_radius: "Radio",
    mapGeofenceSettings_geofence_group: "Etiquetas",
    mapGeofenceSettings_geofence_alerton: "Alerta",
    mapGeofenceSettings_geofence_units: "Unidades",
    mapGeofenceSettings_geofence_export: "Tabla de Geocercas",
    mapGeofenceSettings_geofence_title: 'Título',
    mapGeofenceSettings_geofence_radius: 'Radio',
    mapGeofenceSettings_geofence_groups: 'Etiquetas',
    mapGeofenceSettings_geofence_exportgroup: "Table de Grupos de Geocercas",
};