export default {
    mapLandmarkImport_landmark:'Landmark',
    mapLandmarkImport_ImportTitle:'Import Landmarks',
    mapLandmarkImport_downloadTemplate:'Download Template',
    mapLandmarkImport_fieldsRequied:'Please complete all the fields',
    mapLandmarkImport_formatSupport:'Supported CSV formats: comma, semicolon, tab.',
    mapLandmarkImport_import:'Import',
    mapLandmarkImport_titleEditImport:'The following landmarks were not found.  Please correct any errors',
    mapLandmarkImport_fieldsAdressRequied:'Address field or Longitude and Latitude fields are required',
    mapLandmarkImport_correctErrors:'There were some errors in the import, to correct them click here',
    mapLandmarkImport_success:'Successful import of landmarks',
    mapLandmarkImport_maxSizeFile:'Max file size 5 mb',
    mapLandmarkImport_chooseFile:'Choose File',
    mapLandmarkImport_errorRadius:'Error format column Radius, is not number',
    mapLandmarkImport_errorTag:'Tag not exist',
    mapLandmarkImport_errorAddress:'Error in format Address'
}