//View Keys
export default {
	fleetModal_title: 'Group De Inicio De Arranque',
	vehicleStatusModal_selectVehicle: 'Selecciona los grupos del vehiculo',
	vehicleStatusModal_newStarterSchedule: 'Nombre del nuevo arranque del vehiculo',
	vehicleStatusModal_scheduleName: 'Nombre Cronograma',
	vehicleStatusModal_selectEnableDisable: 'Selecciona Los Tiempos De Habilitar/Deshabilitar',
	vehicleStatusModal_starterEnabled: 'Arranque Encendido',
	vehicleStatusModal_starterDisabled: 'Arranque Apagado',
	fleetModal_time: 'Fecha',
	vehicleStatusModal_monday: 'Lunes',
	vehicleStatusModal_tuesday: 'Martes',
	vehicleStatusModal_wednesday: 'Miercoles',
	vehicleStatusModal_thursday: 'Jueves',
	vehicleStatusModal_friday: 'Viernes',
	vehicleStatusModal_saturday: 'Sabado',
	vehicleStatusModal_sunday: 'Domingo',
	fleetModal_createStarterScheduleSuccess: 'Cronograma De Arranque Agregado Exitosamente',
	fleetModal_deleteStarterSchuduleSuccess: 'Cronograma De Arranque Eliminado Exitosamente',
	fleetModal_updateStarterScheduleSuccess: 'Actualización De Cronograma Actualizado Exitosamente',
	fleetModal_tableName: 'NOMBRE',
	fleetModal_tableDetail: 'DETALLES',
	fleetModal_tableGroups: 'GRUPOS',
	fleetModal_delete: 'eliminar',
	fleetModal_cancel: 'Cancelar',
	fleetModal_deleteBtn: 'ELIMINAR',
	fleetModal_deleteConfirmation: '¿Estás seguro de que quieres eliminar el horario?',
	fleetModal_tabNew: 'Crear Nuevo',
	fleetModal_tabUpdate: 'Actualizar',
	vehicleStatusModal_scheduleBtn: 'CRONOGRAMA',
	fleetModal_schedule: 'PROGRAMADO',
	fleetModal_newStarterScheduleName: 'NOMBRE CRONOGRAMA',
	fleetModal_schedulePlaceholder: 'Mi Nuevo Cronograma',
	fleetModal_starterEnabledLabel: 'Disponible En:',
	fleetModal_starterDisabledLabel: 'Deshabilitado En:',
	fleetModal_save: 'Guardar',
	fleetModal_vehicleGroup: 'GRUPO DE VEHICULOS',
	fleetModal_starterDisabledBeGreater : 'Arrancador deshabilitado debe ser mayor que Arrancador habilitado',
	fleetModal_fieldRequired: 'Campo requerido'


};
