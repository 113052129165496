import dashboardPanelRedux from "Modules/dashboard/views/dashboardPanel/redux/reducers";
import gadgetChartRedux from "Modules/dashboard/views/chart/redux/reducers";
import gadgeOfflineDeviceRedux from "Modules/dashboard/views/offlineDevice/redux/reducers";
import gadgetRecentAlertsRedux from "Modules/dashboard/views/recentAlerts/redux/reducers";


export default {
    dashboardPanelRedux,
    gadgetChartRedux,
    gadgeOfflineDeviceRedux, 
    gadgetRecentAlertsRedux
};
