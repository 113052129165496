// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  /* SETTINGS_DRIVER_GROUP_SEARCH, */
  /* SETTINGS_DRIVER_GROUP_CREATE_EDIT, */
  SETTINGS_DRIVER_GROUP_GET,
  /* SETTINGS_DRIVER_GROUP_DELETE */
} from "Redux/actionTypes";

// Actions
import {
  searchSettingsDriverGroupsResponse,
  /* createEditSettingsDriverGroupsResponse,
  deleteSettingsDriverGroupsResponse, */
  getSettingsDriverGroupsResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

/* function* searchDriverGroupsRequest({ payload }) {
  try {
    const {
      openSearch,
      groupIds
    } = payload;

    const query = `
            query searchDriverGroups ($openSearch: String) {
                searchDriverGroups (openSearch: $openSearch) {
                    id
                    name
                    driversCount
                }
            }
        `;

    const response = yield call(
      clientQuery,
      query,
      {
        openSearch,
        groupIds
      },
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.searchDriverGroups) {
      throw "Error while getting Driver group  list";
    }
    let driverGroups = response.searchDriverGroups;
    yield put(searchSettingsDriverGroupsResponse({ driverGroups, error: false }));
  } catch (exc) {
    yield put(searchSettingsDriverGroupsResponse({ driverGroups: [], error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/driverGroups/redux/saga",
      "searchDriverGroupsRequest"
    );
  }
} */

/* function* createEditDriverGroupRequest({ payload }) {
  try {
      const { driverGroup } = payload;

      let query = `
          mutation createDriverGroup ($Name: String!) {
            createDriverGroup (Name: $Name) {
                id
                name
                driversCount
            }
          } 
      `;
        
      if(driverGroup?.idDriverGroup){
        query = `
            mutation updateDriverGroup ($id: Int!, $Name: String) {
              updateDriverGroup (id: $id, Name: $Name) {
                  id
                  name
                  driversCount
              }
          }
        `;
      }
      

      let data = {
        id: driverGroup?.idDriverGroup,
        Name: driverGroup?.groupName
      }

      const response = yield call(
          clientMutation,
          query,
          data,
          endpoints.GRAPHQL_GENERAL,
      );

      if (!response && !driverGroup?.idDriverGroup || !response.createDriverGroup && !driverGroup?.idDriverGroup) throw 'Error while creating driver group';
      
      if (!response && driverGroup?.idDriverGroup || !response.updateDriverGroup && driverGroup?.idDriverGroup) throw 'Error while update driver group';

      let responseData = (!driverGroup?.idDriverGroup)?response.createDriverGroup:response.updateDriverGroup;
      yield put(createEditSettingsDriverGroupsResponse({ ...responseData, error: false }));
  } catch (exc) {
      yield put(createEditSettingsDriverGroupsResponse({ error: true }));
      ExceptionManager(
          exc,
          "modules/settings/views/driverGroups/redux/saga",
          "createEditDriverGroupRequest"
      );
  }
} */

/* function* deleteDriverGroupRequest({ payload }) {
  try {
      const { items,moveChildsToGroupId } = payload;
      let mutations = [];

      let validationMoveChildsToGroupId = (moveChildsToGroupId)?`, moveChildsToGroupId:${moveChildsToGroupId} `:'';
      items?.forEach((item, index) => {
          mutations.push(`
              DeleteGroup${index}: deleteDriverGroup(id: ${item?.id} ${validationMoveChildsToGroupId} )
          `);
      });

    let mutation = `mutation{${mutations.join("")}}`;

      const response = yield call(
          clientMutation,
          mutation,
          {},
          endpoints.GRAPHQL_GENERAL,
      );

      if (!response || !response.DeleteGroup0) throw 'Error while deleting group';

      yield put(deleteSettingsDriverGroupsResponse({ ...response, error: false }));
  } catch (exc) {
      yield put(deleteSettingsDriverGroupsResponse({ error: true }));
      ExceptionManager(
          exc,
          "modules/settings/views/driverGroups/redux/saga",
          "deleteDriverGroupRequest"
      );
  }
} */

/* function* getDriverGroupRequest({ payload }) {

  try{
    let query = `
      query getDriverGroups {
          getDriverGroups {
              id
              name
              
          }
      }
    `;
    const response = yield call(
        clientMutation,
        query,
        {},
        endpoints.GRAPHQL_GENERAL,
    );

    if (!response && !response.getDriverGroups) throw "Error while getting getDriverGroups";
      

      yield put(getSettingsDriverGroupsResponse(response.getDriverGroups));
  } catch (exc) {
      yield put(getSettingsDriverGroupsResponse({ error: true }));
      ExceptionManager(
          exc,
          "modules/settings/views/driverGroup/redux/saga",
          "getDriverGroupRequest"
      );
  }
} */

function* watchMethods() {
  /* yield takeEvery(SETTINGS_DRIVER_GROUP_SEARCH, searchDriverGroupsRequest); */
  /* yield takeEvery(SETTINGS_DRIVER_GROUP_CREATE_EDIT, createEditDriverGroupRequest); */
  /* yield takeEvery(SETTINGS_DRIVER_GROUP_DELETE, deleteDriverGroupRequest); */
  /* yield takeEvery(SETTINGS_DRIVER_GROUP_GET, getDriverGroupRequest); */

}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}