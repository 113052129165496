export const EDIT_UNIT_OPEN = "EDIT_UNIT_OPEN";
export const EDIT_UNIT_OPEN_RESULT = "EDIT_UNIT_OPEN_RESULT";
export const EDIT_UNIT_CLOSE = "EDIT_UNIT_CLOSE";
export const EDIT_UNIT_UPDATE = "EDIT_UNIT_UPDATE";
export const EDIT_UNIT_UPDATE_RESULT = "EDIT_UNIT_UPDATE_RESULT";
export const EDIT_MULTIPLE_UNITS = "EDIT_MULTIPLE_UNITS";
export const EDIT_UNIT_SEND_OTA = "EDIT_UNIT_SEND_OTA";
export const EDIT_UNIT_SEND_OTA_RESULT = "EDIT_UNIT_SEND_OTA_RESULT";
export const EDIT_UNIT_SEND_PING = "EDIT_UNIT_SEND_PING";
export const EDIT_UNIT_SEND_PING_RESULT = "EDIT_UNIT_SEND_PING_RESULT";
export const EDIT_UNIT_DECODE_VIN = "EDIT_UNIT_DECODE_VIN";
export const EDIT_UNIT_DECODE_VIN_RESPONSE = "EDIT_UNIT_DECODE_VIN_RESPONSE";