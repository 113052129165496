//View Keys
export default {
	entityDetails_subscribersNotFound: 'Ninguno',
	entityDetails_notesNotFound: 'Nada',
	entityDetails_attachmentsNotFound: 'Ninguno',
	entityDetails_deleteAttachment: '¿Estás seguro de que quieres eliminar el adjunto ',
	entityDetails_deleteSubscriber: '¿Estás seguro de que quieres eliminar el suscriptor ',
	entityDetails_titleDeleteAttachment: 'Eliminar Adjunto',
	entityDetails_titleDeleteSubscriber: 'Eliminar Suscriptor',
	entityDetails_subscribers_label: 'Suscriptores',
	entityDetails_subscribers_notes: 'Notas',
	entityDetails_subscribers_attachments: 'Adjuntos',
};