import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { updateIncidentRedux, searchIncidents } from "Redux/actions";
import { reset } from "../../../redux/slice";
import { IconButton, Tooltip } from "@gpstrackit/gtc-react-controls";
import { TextField } from "@mui/material";
import { NotificationManager } from "Components/Notifications";

export const Title = ({ activeIncident, incidentData, clearFields }) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const {
    isOpenOverViewUnit,
    updateIncidentLoading,
    updateIncidentError,
    updateIncident,
  } = useSelector((state) => state.incidentsRedux);

  const [label, setLabel] = useState("");
  const [editName, setEditName] = useState(false);
  const [newName, setNewName] = useState("");
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    let unitName = isOpenOverViewUnit?.data?.unit?.label;
    if (unitName?.length > 20) {
      unitName = (
        <Tooltip title={isOpenOverViewUnit?.data?.unit?.label}>
          {unitName.slice(0, 20) + "..."}
        </Tooltip>
      );
    }

    setLabel(unitName);
  }, [isOpenOverViewUnit]);

  useEffect(() => {
    if (incidentData?.name) setNewName(incidentData?.name);
  }, [incidentData]);

  useEffect(() => {
    if (!updateIncidentLoading && updateIncident?.id && showNotification) {



      dispatch(
        reset({
          updateIncident: {},
          updateIncidentLoading: false,
        })
      );

      dispatch(
        searchIncidents({
          conditions: [
            {
              fields: "ended",
              terms: ["0"],
              exact: true,
            },
            {
              fields: "isDeleted",
              terms: ["0"],
              exact: false,
            }
          ],
          sort: [
            {
              field: "id",
              order: "DESC",
            },
          ],
          limit: 100,
          offset: 0,
        })
      );

      setShowNotification(false);
      return NotificationManager.success(
        messages["incident_active_newName_success"],
        messages["success"],
        3000,
        null,
        null,
        "success"
      );
    }
  }, [updateIncidentLoading, updateIncident]);

  const updateName = () => {
    if (incidentData?.name === newName) return setEditName(false);

    if (newName?.length < 3 && newName?.length > 50) {
      return NotificationManager.warning(
        messages["incident_active_newName_incorrect"],
        messages["warning"],
        3000,
        null,
        null,
        "info"
      );
    }
    setShowNotification(true);
    dispatch(
      updateIncidentRedux({
        ...incidentData,
        name: newName,
      })
    );
    setEditName(false);
  };

  return (
    <div className="incident_modal-title">
      <p style={{ display: "flex", width: '33%' }}>
        {activeIncident ? (
          <div>
            {!editName ? (
              newName &&
                newName?.length > 20 ?
                  <Tooltip title={newName}>
                    {newName.slice(0, 20) + "... -  "}
                  </Tooltip> :
                `${newName} - `
            ) : (
              <TextField
                style={{ height: 0, width: "180px", marginRight: '10px' }}
                id="standard-basic"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                variant="standard"
              />
            )}
          </div>
        ) : null}{" "}
        {label ? label : messages["incidents_noUnitLabel"]}
      </p>
      {activeIncident && (
        <div
          style={{
            width: "70%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {!editName ? (
            <IconButton
              style={{ paddingTop: 0, marginTop: "-10px" }}
              icon="edit"
              tooltip="incident_edit_name_title"
              onClick={() => {
                setEditName(true);
              }}
            />
          ) : (
            <IconButton
              style={{ paddingTop: 0, marginTop: "-10px" }}
              icon="check"
              tooltip="save"
              onClick={() => {
                updateName();
              }}
            />
          )}
          <div
            style={{
              padding: "3px 5px",
              background: "#ff4848",
              borderRadius: '6px',
              color: 'white',
              marginRight: '15px'
            }}
          >
            {messages["incident_active_button_title"]}
          </div>
        </div>
      )}

      <button
        className="incident_modal-close"
        style={{ margin: "-3px 0 0 0" }}
        onClick={() => {
          dispatch(
            reset({
              isOpenOverViewUnit: {
                open: false,
                data: {},
              },
              trailsIncident: [],
              trailsIncidentTotal: 0,
              avMediaIncident: [],
              avMediaIncidentTotal: 0,
            })
          );
          clearFields();
        }}
      >
        x
      </button>
    </div>
  );
};
