// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  DASHBOARD_PANEL_DEVICE_OFFLINE,
} from "Redux/actionTypes";

// Actions
import {
  deviceOfflineDashboardPanelResponse
} from "Redux/actions";

// Helpers
import { clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";



function* deviceOfflineRequest({ payload }) {
  try {

    let value = {};
    if (payload.data?.deviceCategory) {
      value = {
        deviceCategory: payload.data.deviceCategory,
        thresholdMinutes: payload.data.thresholdMinutes,

      }
    }


    const query = `
            query getUnitsOffline ($deviceCategory: enumDeviceCategory, $thresholdMinutes: Int) {
              getUnitsOffline (deviceCategory: $deviceCategory, thresholdMinutes: $thresholdMinutes) {
                  id
                  label
                  typeId
                  type
                  hid
                  deviceCategory
                  lastReading {
                    LastMessageTime
                    serverTime
                    unitTime
                  }
              }
          }
        `;

    const response = yield call(
      clientQuery,
      query,
      value,
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.getUnitsOffline) {
      throw "Error while getting Device Offlne list";
    }
    let devices = response.getUnitsOffline;

    yield put(deviceOfflineDashboardPanelResponse({ devices, data: payload.data }));
  } catch (exc) {
    yield put(deviceOfflineDashboardPanelResponse({ devices: [], data: {} }));
    ExceptionManager(
      exc,
      "modules/dashboard/views/dashboardPanel/redux/saga",
      "deviceOfflineRequest"
    );
  }
}

function* watchMethods() {
  yield takeEvery(DASHBOARD_PANEL_DEVICE_OFFLINE, deviceOfflineRequest);
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}