// dependencies
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// controls
import Table, {Column} from '../../table'


// helpers
import {
  formatDatetime,
  getFilterNameByEntity,
  getMethodToFormatDuration,
  getPlaceholderSelector,
  useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';

// actions
import {clearRedux, reportsMainGenerateReportUpdate} from 'Redux/actions'
import {useTableReport} from "Core/hooks";
import moment from 'moment';
// styles

const LandmarkSummaryReport = (props) => {
    const {messages, report, user} = props;
  const { durationFormat } = user;
    const dispatch = useDispatch()
    const {loading, data, filters, timeZone} = report
    const {filterEntity, filterArray, filterType} = filters;
    const exportHandler = useHandleExportReport(report)

    const [currentPage, setCurrentPage] = useState(1);
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
    const [currentNewSortField, setCurrentNewSortField] = useState("deviceId");
    const [currentNewSortOrder, setCurrentNewSortOrder] = useState("asc");
    const [unitDriverSelected, setUnitDriverSelected] = useState([]);

    const createLandMarkResult = useSelector(state => state.vehicleLandMarkRedux.createLandMarkResult)
    const loadingCreateLandMark = useSelector(state => state.vehicleLandMarkRedux.loadingCreateLandMark)

    const reportRef = useRef();
    useEffect(() => {
        if (report) {
            const newReport = JSON.parse(JSON.stringify(report));
            reportRef.current = newReport;
        }
    }, [JSON.stringify(report)]);

    useEffect(() => {
        if (!loadingCreateLandMark && createLandMarkResult) {
            dispatch(reportsMainGenerateReportUpdate(report, currentPage, currentRowsPerPage, currentNewSortField, currentNewSortOrder))
            dispatch(clearRedux("LANDMARK", {"createLandMarkResult": null}));
        }
    }, [loadingCreateLandMark, createLandMarkResult])

  const durationFormatTime = getMethodToFormatDuration(durationFormat);

    return (
        <Fragment>
            <Table
                loading={loading}
                data={data.items || []}
                totalItems={data.total}
                groupField="id"
                customGroupArea={(group, item) => {
                    let type = report?.filters?.filterType === "deviceId" || report?.filters?.filterType === "unitGroupId" ? messages['generatedReport_unit'] :
                        messages['generatedReport_driver'];
                    let events = data?.items && data.items.filter((i) => i.id === item.id)
                    const localTimeStart = moment.utc(filters.startRange).local().format();
                    const localTimeEnd = moment.utc(filters.endRange).local().format();
                    return (
                        <Fragment>
                            <div className='detailed-report-custom-area'>
                                <span><strong>{type}:</strong> {item.idLabel || ''} </span>
                                <span><strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}</span>
                                {filterType === "unitGroupId" || filterType === "driverGroupId" ? <span><strong>{messages['generatedReport_group']}: </strong>{group}</span> :
                                    <div></div>}
                            </div>
                            <Table
                                data={events || []}
                                totalItems={events?.length}
                                groups={report.filters.units || []}
                                groupField="id"
                                noDataMessage={messages["reportsMainRT_noDataMessage"]}
                                hiddenPagination
                                serverSideTable={true}
                                noData={item.noData}
                            >
                                <Column label={messages["generatedReport_time"]} field="unitTime" noSorting>
                                    {(element) => {
                                        return (
                                            <div>
                                                <span className="simple-report-stacked">{formatDatetime(element.unitTime, "h:mm A", timeZone)}</span>
                                                <span className="simple-report-stacked simple-report-event-date">{formatDatetime(element.unitTime, "MMM D", timeZone)}</span>
                                            </div>
                                        );
                                    }}
                                </Column>

                                <Column label={messages['generatedReport_vehicle_group']} field="groupIdLabel" noSorting>
                                    {(element) => {
                                        return <span>{element.groupIdLabel}</span>;
                                    }}
                                </Column>

                                <Column label={messages['generatedReport_vehicle']} field="deviceLabel" noSorting>
                                    {(element) => {
                                        return <span>{element.deviceLabel}</span>;
                                    }}
                                </Column>


                                <Column label={messages['generatedReport_landmarkLabel']} field="landmarkName" noSorting style={{minWidth: '150px'}}>
                                    {(element) => {
                                        return <span>{element.noData ? messages['reportsMainRT_noDataEvent'] : element.landmarkName}</span>;
                                    }}
                                </Column>


                                <Column label={messages['generatedReport_totalVisits']} field="totalVisits" noSorting>
                                    {(element) => {
                                        return <span>{element.totalVisits}</span>;
                                    }}
                                </Column>

                                <Column label={messages['generatedReport_totalVisitDuration']} field="totalVisitsDuration" noSorting>

                                    {(element) => {
                                        return <span>{element.noData ? '' : (element.totalVisitsDuration ? durationFormatTime(element.totalVisitsDuration, messages) : '0 min, 0 sec')}</span>;
                                    }}
                                </Column>


                                <Column label={messages['generatedReport_engineHours']} field="engineHours" noSorting>
                                    {(element) => {
                                        return <span>{element.engineHours}</span>;
                                    }}
                                </Column>

                            </Table>
                        </Fragment>
                    )
                }}
                onChangedPage={(page, rowsPerPage, newSortField, newSortOrder) => {
                  let currentReport = report;
                  currentReport.exportType = "json";
                  currentReport.filters = { ...currentReport.filters, unitsSelected: unitDriverSelected };
                  dispatch(reportsMainGenerateReportUpdate(currentReport, page, rowsPerPage))
                }}
                onChangeSelector={(value) => {
                  setUnitDriverSelected(value)
                  let currentReport = report;
                  currentReport.filters = { ...currentReport.filters, unitsSelected: value };
                  dispatch(reportsMainGenerateReportUpdate(currentReport, 1, 10));
                }}

                defaultSortField="deviceId"
                defaultSortOrder="asc"
                noDataMessage={messages["reportsMainRT_noDataMessage"]}
                hideHeader
                hiddenColumns
                valueSelector={unitDriverSelected}
                itemsSelector={filterArray}
                textFieldSelector={getFilterNameByEntity(filterType)}
                placeholderSelector={getPlaceholderSelector(filterType, messages)}
                asyncLoading={report.asyncLoading}
                {...exportHandler}
            />
        </Fragment>
    );
}

export default LandmarkSummaryReport;
