// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  DASHBOARD_PANEL_RECENT_ALERTS,
} from "Redux/actionTypes";

// Actions
import {
  recentAlertsDashboardPanelResponse
} from "Redux/actions";

// Helpers
import { clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";



function* recentAlertsRequest({ payload }) {
  try {

    let value = { ...payload.data };

    const query = `
      query searchSystemAlertLogs (
        $alertTypes: [enumAlertType] 
        $unitTimeRange: AWSDateTimeRange
        $entities: [EntityInput]
        $severity: Severity
        $openSearch: String
        $limit: Int
        $offset: Int
      ) {
        Alerts: searchSystemAlertLogs (
          alertTypes: $alertTypes,
          unitTimeRange: $unitTimeRange,
          entities: $entities,
          severity: $severity,
          openSearch: $openSearch, 
          limit: $limit, 
          offset: $offset
        ) {
          items {
            id,
            systemAlertName,
            systemAlertType,
            config,
            severity,
            unitId,
            unitTime,
            driverId,
            driverName,
            latitude,
            longitude,
            address,
            landmarkId,
            landmarkName,
            read,
            deviceReadingId
            unit {
              id
              label
              timeZone {
                    id
                    name
                }
            }
          }
          total
        }
      }
    `;

    const response = yield call(
      clientQuery,
      query,
      value,
      endpoints.GRAPHQL_GENERAL
    );


    if (!response && !response.Alerts) {
      throw "Error while getting Device Offlne list";
    }

    let recentAlerts = response.Alerts;

    yield put(recentAlertsDashboardPanelResponse({ recentAlerts, data: payload.data }));
  } catch (exc) {
    yield put(recentAlertsDashboardPanelResponse({ recentAlerts: [], data: {} }));
    ExceptionManager(
      exc,
      "modules/dashboard/views/dashboardPanel/redux/saga",
      "recentAlertsRequest"
    );
  }
}

function* watchMethods() {
  yield takeEvery(DASHBOARD_PANEL_RECENT_ALERTS, recentAlertsRequest);
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}