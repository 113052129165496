import _ from 'lodash'
class CustomOverlay {
  constructor(marker, id, callback) {
    this.id = id;
    this.marker_ = marker;
    this.overlay_ = null;
    this.container_ = null;
    this.callback_ = callback;
    this.listeners = [];
    this.createOverlay();
  }

  createOverlay() {
    this.overlay_ = new google.maps.OverlayView(); // eslint-disable-line no-undef
    this.overlay_.onAdd = this.onAdd.bind(this);
    this.overlay_.draw = this.draw.bind(this);
    this.overlay_.onRemove = this.onRemove.bind(this);
    this.overlay_.onMove = this.onMove.bind(this);
  }

  cancelEvents() {
    const events = ['mousedown', 'mousemove', 'mouseover',
      'mouseout', 'mouseup', 'mousewheel',
      'DOMMouseScroll', 'touchstart', 'touchend', 'click',
      'touchmove', 'dblclick', 'contextmenu'];
    // Note, don't disable 'click' if you want to be able to click links in the dom. Some things we're disabling here will can effect how your user might interact with the popup (double click to select text etc)
    for (let i = 0; i < events.length; i++) {
      let event = events[i];
      this.listeners.push(
        google.maps.event.addListener( // eslint-disable-line no-undef
          this.container_,
          event,
          function (e) {
            e.cancelBubble = true;
            if (e.stopPropagation) {
              e.stopPropagation();
            }
          }
        )
      );
    }
  }

  /**
   * onAdd is called when the map's panes are ready and the overlay has been
   * added to the map.
   */
  onAdd() {
    this.container_ = document.createElement("div");
    this.container_.style.borderStyle = "none";
    this.container_.style.zIndex = 108;
    this.container_.style.borderWidth = "0px";
    this.container_.style.position = "absolute";
    /* Add the element to the pane.
          MapPanes.mapPane (Level 0)
          MapPanes.overlayLayer (Level 1)
          MapPanes.markerLayer (Level 2)
          MapPanes.overlayMouseTarget (Level 3)
          MapPanes.floatPane (Level 4)
    */
    const panes = this.overlay_.getPanes();
    panes.floatPane.appendChild(this.container_);
    this.cancelEvents();
  }

  draw() {
    const overlayProjection = this.overlay_.getProjection();
    let lat = this.marker_.position?.lat;
    let lng = this.marker_.position?.lng;
    if (_.isFunction(this.marker_.position?.lat) && _.isFunction(this.marker_.position?.lng)) {
      lat = this.marker_.position?.lat();
      lng = this.marker_.position?.lng();
    }

    if(!overlayProjection) return
    const point = overlayProjection.fromLatLngToDivPixel(new google.maps.LatLng(lat, lng)); // eslint-disable-line no-undef

    if (this.container_) {
      let x = point.x;
      let y = point.y;

      //const { rotation } = this.marker_.icon;
      y = y - 40;
      /* if (rotation >= 0 && rotation <= 90) {
        y = y - 8;
      } else if (rotation > 90 && rotation <= 180) {
        x = x - 20;
        y = y - 15;
      } else if (rotation > 180 && rotation <= 270) {
        y = y - 50;
      } else if (rotation > 270 && rotation < 360) {
        x = x + 20;
        y = y - 8;
      } */

      this.container_.style.left = `${x}px`;
      this.container_.style.top = `${y}px`;

      if (this.callback_) {
        this.callback_(this);
      }
    }
  }
  /**
   * The onRemove() method will be called automatically from the API if
   * we ever set the overlay's map property to 'null'.
   */
  onRemove() {
    if (this.container_) {
      this.container_.parentNode.removeChild(this.container_);
      this.container_ = null;
    }

    for (let i = 0; i < this.listeners.length; i++) {
      // remove our event listeners
      google.maps.event.removeListener(this.listeners[i]); // eslint-disable-line no-undef
    }
  }

  onMove(position){

    if(this.overlay_ && position){
      this.overlay_.position = position;
      this.draw()
    }
  }

  setMap(map) {
    this.overlay_.setMap(map);
  }
}

export default CustomOverlay