import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../core/logManager';

import { resetALL, catalogLoad } from '../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    openModal: false,
    loading: false,
    items: [],
    total: 0,
    offset: 0,
    error: null
};

const route = 'modules/chat/modals/chatChannelList/redux/slice'

export const chatChanneltListOpen = createAsyncThunk('chat/channelList/open', async ({ variableQuery }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(catalogLoad(['chatUsers']));
        dispatch(searchChatChannels({ offset: 0, variableQuery }))
        return;
    } catch (exc) {
        ExceptionManager(exc, route, 'chatListContactsOpen');
        return rejectWithValue(exc)
    }
});

export const searchChatChannels = createAsyncThunk('chat/channelList/search', async ({ offset, conditions, variableQuery }, { rejectWithValue, dispatch }) => {
    try {

        const result = await clientQuery(`
            query searchChatChannels ($conditions: [ChatChannelSearchInput], $variableQuery: ConditionChannel, $sort: [ChatChannelSortInput], $limit: Int, $offset: Int) {
                data: searchChatChannels (conditions: $conditions, variableQuery: $variableQuery, sort: $sort, limit: $limit, offset: $offset) {
                    items {
                        id
                        isGroup
                        name
                        lastMessage{
                            id
                            message
                            createdOn
                            status
                            transmitterEntityNameId
                            receiverEntityNameId
                        }
                        users
                        avatar
                        createdOn
                        createdBy
                        updatedOn
                        updatedBy
                    }
                    total
                }
            }`,
            {
                limit: 10,
                offset,
                conditions: conditions,
                variableQuery,
                sort: [{ field: 'lastMessageDate', order: 'DESC' }]
            },
            endpoints.GRAPHQL_GENERAL_V2
        )
        return result?.data || { items: [], total: 0 };
    } catch (exc) {
        ExceptionManager(exc, route, 'chatListContactsOpen');
        return rejectWithValue(exc)
    }
});

//Slice
const chatChannelListRedux = createSlice({
    name: 'chat/channelList',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        chatChannelChangeLastMessage(state, action) {
            let items = _.clone(state.items)
            const index = items.findIndex(x => x.id == action.payload.channelId)
            if (index > -1)
                items[index] = { ...items[index], lastMessage: action.payload.lastMessage }

            return { ...state, open: false, items: items }
        },
        close(state, action) {
            state.openModal = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        builder.addCase(chatChanneltListOpen.pending, (state, action) => {
            state.loading = true;
            state.items = [];
            state.total = 0;
            state.offset = 0;
        });
        builder.addCase(chatChanneltListOpen.fulfilled, (state, action) => {
            state.loading = false;
            state.openModal = true;
        });
        builder.addCase(chatChanneltListOpen.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(searchChatChannels.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(searchChatChannels.fulfilled, (state, action) => {
            const { total, items } = action.payload
            let newitems = _.clone(state.items)

            return { ...state, loading: false, offset: action.meta.arg.offset, items: action.meta.arg.offset == 0 ? items : [...newitems, ...items], total }
        });
        builder.addCase(searchChatChannels.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = chatChannelListRedux;

// Extract and export each action creator by name
export const { reset, close, chatChannelChangeLastMessage } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;