//View Keys
export default {
	serviceDetailView_vehicle: 'Vehiculo',
	serviceDetailView_lastPerformed: 'Ultimo Realizado',
	serviceDetailView_miles: 'Millas',
	serviceDetailView_days: 'Días',
	serviceDetailView_hours: 'Horas',
	serviceDetailView_cost: 'Costo',
	serviceDetailView_status: 'Estado',
	serviceDetailView_reminder: 'Recordatorio',
	serviceDetailView_subscribers: 'Suscriptores',
	serviceDetailView_notes: 'Notas',
	serviceDetailView_attachments: 'Adjuntos',
};
