// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  SETTINGS_VEHICLE_EQUIPMENT_SEARCH,
  SETTINGS_VEHICLE_EQUIPMENT_CREATE_EDIT,
  SETTINGS_VEHICLE_EQUIPMENT_DELETE,
  SETTINGS_VEHICLE_EQUIPMENT_ASSIGNMENT_DELETE
} from "Redux/actionTypes";

// Actions
import {
  searchSettingsVehicleEquipmentResponse,
  createEditSettingsVehicleEquipmentResponse,
  deleteSettingsVehicleEquipmentAssigmentResponse,
  deleteSettingsVehicleEquipmentResponse,
  getSettingsVehicleEquipmentUnitResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* searchVehicleEquipmentRequest({ payload }) {
  try {
    const {
      openSearch,
      limit,
      offset
    } = payload;

    const query = `
              query searchEquipments ($openSearch: String, $limit: Int, $offset: Int) {
                searchEquipments (openSearch: $openSearch, limit: $limit, offset: $offset) {
                    items {
                        id
                        name
                        vehicles {
                          id
                          unitId
                          equipmentId
                          unit {
                              id
                              label
                          }
                        }
                    }
                    total
                }
            }
        `;

    const response = yield call(
      clientQuery,
      query,
      {
        openSearch,
        limit,
        offset
      },
      endpoints.GRAPHQL_GENERAL
    );

    if (!response && !response.searchEquipments) {
      throw "Error while getting Vehicle Equipment list";
    }
    let vehicleEquipment = response.searchEquipments.items;
    yield put(searchSettingsVehicleEquipmentResponse({ vehicleEquipmentItems: vehicleEquipment, total: response.searchEquipments.total, error: false }));
  } catch (exc) {
    yield put(searchSettingsVehicleEquipmentResponse({ vehicleEquipmentItems: [], total: 0, error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/vehicleEquipment/redux/sagas",
      "searchVehicleEquipmentRequest"
    );
  }
}

function* createEditVehicleEquipmentRequest({ payload }) {
  try {
    const { vehicleEquipment } = payload;

    let query = `
          mutation createEquipment ($name: String!, $units:[Int]) {
            createEquipment (name: $name, units:$units) {
                id
                name
                vehicles {
                  id
                  unitId
                  equipmentId
                  unit {
                      id
                      label
                  }
                }
            }
          }
      `;

    if (vehicleEquipment?.idVehicleEquipment) {
      query = `
          mutation updateEquipment ($id: Int!, $name: String, $units:[Int]) {
            updateEquipment (id: $id, name: $name,units:$units) {
                id
                name
                vehicles {
                  id
                  unitId
                  equipmentId
                  unit {
                      id
                      label
                  }
                }
            }
          }
        `;
    }

    let data = {
      id: vehicleEquipment?.idVehicleEquipment,
      name: vehicleEquipment?.name,
      units: vehicleEquipment?.units
    }


    const response = yield call(
      clientMutation,
      query,
      data,
      endpoints.GRAPHQL_GENERAL,
    );

    if (!response && !vehicleEquipment?.idVehicleEquipment || !response.createEquipment && !vehicleEquipment?.idVehicleEquipment) throw 'Error while creating Vehicle Equipment';

    if (!response && vehicleEquipment?.idVehicleEquipment || !response.updateEquipment && vehicleEquipment?.idVehicleEquipment) throw 'Error while update Vehicle Equipment';

    let responseData = (!vehicleEquipment?.idVehicleEquipment) ? response.createEquipment : response.updateEquipment;
    yield put(createEditSettingsVehicleEquipmentResponse({ ...responseData, error: false }));
  } catch (exc) {
    yield put(createEditSettingsVehicleEquipmentResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/vehicleEquipment/redux/saga",
      "createEditVehicleEquipmentRequest",
      true
    );
  }
}


function* deleteVehicleEquipmentRequest({ payload }) {
  try {
    const { items } = payload;
    let mutations = [];

    items?.forEach((item, index) => {
      mutations.push(`
              DeleteVehicleEquipment${index}: deleteEquipment(id: ${item?.id})
          `);
    });

    let mutation = `mutation{${mutations.join("")}}`;

    const response = yield call(
      clientMutation,
      mutation,
      {},
      endpoints.GRAPHQL_GENERAL,
    );

    if (!response || !response.DeleteVehicleEquipment0) throw 'Error while deleting Vehicle Equipment';

    yield put(deleteSettingsVehicleEquipmentResponse({ ...response, error: false }));
  } catch (exc) {
    yield put(deleteSettingsVehicleEquipmentResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/vehicleEquipment/redux/saga",
      "deleteVehicleEquipmentRequest"
    );
  }
}

function* deleteVehicleEquipmentAssignmentRequest({ payload }) {
  try {
    const { idVehicleEquipment } = payload;

    let mutation = `
      mutation deleteVehicleEquipment ($id: Int!) {
        deleteVehicleEquipment (id: $id)
      }`;

    const response = yield call(
      clientMutation,
      mutation,
      { id: idVehicleEquipment },
      endpoints.GRAPHQL_GENERAL,
    );

    if (!response || !response.deleteVehicleEquipment) throw 'Error while deleting Vehicle Equipment Assigned';

    yield put(deleteSettingsVehicleEquipmentAssigmentResponse({ ...response, error: false }));
  } catch (exc) {
    yield put(deleteSettingsVehicleEquipmentAssigmentResponse({ error: true }));
    ExceptionManager(
      exc,
      "modules/settings/views/vehicleEquipment/redux/saga",
      "deleteVehicleEquipmentAssignmentRequest"
    );
  }
}

function* watchMethods() {
  yield takeEvery(SETTINGS_VEHICLE_EQUIPMENT_SEARCH, searchVehicleEquipmentRequest);
  yield takeEvery(SETTINGS_VEHICLE_EQUIPMENT_CREATE_EDIT, createEditVehicleEquipmentRequest);
  yield takeEvery(SETTINGS_VEHICLE_EQUIPMENT_DELETE, deleteVehicleEquipmentRequest);
  yield takeEvery(SETTINGS_VEHICLE_EQUIPMENT_ASSIGNMENT_DELETE, deleteVehicleEquipmentAssignmentRequest);

}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}