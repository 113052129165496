//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientMutation, clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
//Action Types
import { CATALOG_UPDATE_LAST_READING, FLEET_VEHICLE_GET_STATUSES } from 'Redux/actionTypes';

//Actions
import { setUpdateLastReadingResult, fleetVehicleGetStatusesResult } from 'Redux/actions';



function* updateLastReading({ payload }) {
	const { deviceId, desiredStarterState } = payload.data;

	try {
		const response = yield call(
			clientMutation,
			`mutation updateUnitStarter ($starterState: String!, $id: Int!) {
				updateUnitStarter (starterState: $starterState, id: $id) {
					id
					label
					desiredStarterState
				}
			}`,
			{ 	
				id:deviceId,
				starterState:desiredStarterState?"enable":"disable"
			},
			endpoints.GRAPHQL_GENERAL_V2,
		);
		
		if (response && response?.updateUnitStarter) {
			yield put(setUpdateLastReadingResult({ ...response?.updateUnitStarter, id: deviceId }));		
		} else {
			throw 'could not update unit';
		}
	} catch (exc) {
		// ALWAYS RETURN ACTION
		ExceptionManager(
			exc,
			'modules/fleet/views/redux/saga',
			'updateLastReading',
		);

		yield put(setUpdateLastReadingResult({ id: deviceId, error: true }));	
	}
}

function* watchLastReadingUpdate() {
	yield takeEvery(CATALOG_UPDATE_LAST_READING, updateLastReading);
}


function* searchVehicleStatuses() {
	try {

		const data = yield call(clientQuery,
			`
			query searchVehicleStatuses{
				searchVehicleStatuses{
					id
					name
					iconId
					icon
					className
					createdBy
					createdOn
					updatedBy
					updatedOn
				}
			}
			`, {}, endpoints.GRAPHQL_GENERAL);

		yield put(fleetVehicleGetStatusesResult(data.searchVehicleStatuses))
		
	} catch (exc) {
		// catch throw
		ExceptionManager(exc, 'modules/fleet/views/redux/saga', 'searchVehicleStatuses');
		yield put(fleetVehicleGetStatusesResult(null))
	}
}

function* watchSearchVehicleStatuses() {
	yield takeEvery(FLEET_VEHICLE_GET_STATUSES, searchVehicleStatuses);
}



//Saga Export
export default function* rootSaga() {
	yield all([fork(watchLastReadingUpdate)]);
	yield all([fork(watchSearchVehicleStatuses)]);
}
