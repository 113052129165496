//imports
import { all } from 'redux-saga/effects';

import realtimeMapsLandmarkCreateEditSagas from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkCreateEdit/redux/saga';
import realtimeMapsLandmarkGroupCreateEditSagas from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkGroupCreateEdit/redux/saga';
import realtimeMapsLandmarkDeleteSagas from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkDelete/redux/saga';
import realtimeMapsLandmarkGroupDeleteSagas from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkGroupDelete/redux/saga';
import realtimeMapsLandmarkGroupVisibleSagas from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkVisibleGroups/redux/saga';
import realtimeMapsLandmarkListSagas from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkList/redux/saga';
import realtimeMapsLandmarkBulkSagas from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkBulk/redux/saga';
import realtimeMapsLandmarkImportSagas from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkImport/redux/saga';
import realtimeMapsLandmarkVisibleTagsSagas from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkVisibleTags/redux/saga';


//merge sagas
export default function* rootSaga() {
    yield all([
        realtimeMapsLandmarkCreateEditSagas(),
        realtimeMapsLandmarkGroupCreateEditSagas(),
        realtimeMapsLandmarkDeleteSagas(),
        realtimeMapsLandmarkGroupDeleteSagas(),
        realtimeMapsLandmarkGroupVisibleSagas(),
        realtimeMapsLandmarkListSagas(),
        realtimeMapsLandmarkBulkSagas(),
        realtimeMapsLandmarkImportSagas(),
        realtimeMapsLandmarkVisibleTagsSagas()
    ]);
}
