import React, { useRef, useState } from "react";
import LoginLogo from "../../common/LoginLogo";
import { Form, Input, Loading, Button } from "Components";
import { Container } from "@mui/material";
import ErrorMessage from "./ErrorMessage";
import { FormattedMessage, useIntl } from "react-intl";
import ModalResetPwd from "./ModalResetPwd";
import { ErrorHandlerLogin } from "Core/data/Helpers";
//actions
import {
  forgotPassword,
  forgotPasswordConfirmClose,
  forgotPasswordConfirm,
  clearRedux,
  getResellerInfo,
} from "Redux/actions";
import { useDispatch, useSelector } from "react-redux";

import ChatWithSupport from "../../common/ChatWithSupport";

function FormForgotPwd({
  current_reseller,
  reseller_was_validated,
  forgotPassword,
}) {
  const dispatch = useDispatch();
  const { loading, error, showConfirm, result } = useSelector(
    (state) => state.forgotPasswordRedux
  );

  const { messages } = useIntl();
  const urlImgLogo = current_reseller
    ? current_reseller?.loginCompanyLogo
    : "/assets/images/verticalLogo.png";
  const frmForgotPassword = useRef();

  const [username, setUsername] = useState("");

  return (
    <div className="background-white-login" style={{ width: "500px" }}>
      <Container className="form-login-container">
        {reseller_was_validated && <LoginLogo urlImgLogo={urlImgLogo} />}
        {error && (
          <ErrorMessage current_reseller={current_reseller} error={error} />
        )}

        <Form
          ref={frmForgotPassword}
          onValidSubmit={() => {
            dispatch(forgotPassword(username));
          }}
        >
          <div>
            <p
              style={{
                fontSize: "16px",
                margin: "30px 0 0 0",
                fontWeight: "400",
              }}
            >
              {messages["forgotPassword_title"]}
              <span className="text-danger"> *</span>
            </p>
            <Input
              id="txtEmail"
              type="text"
              placeholder={messages["forgotPassword_email"]}
              value={username}
              onChange={(text) => {
                setUsername(text);
              }}
              required
              requiredMessage={messages["required_field"]}
              autoFocus
            />
          </div>
          <div style={{ paddingTop: 10 }}>
            {loading ? (
              <div className="loading-area">
                <Loading />
              </div>
            ) : (
              <Button
                id="btnContinue"
                className="btn btn-blue btn-block"
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  frmForgotPassword.current.submit();
                }}
              >
                <FormattedMessage id="continue" />
              </Button>
            )}
          </div>
        </Form>
        {
          !current_reseller && reseller_was_validated &&
          <div style={{ marginTop: 10 }}>
            <ChatWithSupport />
          </div>
        }
      </Container>

      <ModalResetPwd
        forgotPasswordConfirmClose={forgotPasswordConfirmClose}
        clearRedux={clearRedux}
        showConfirm={showConfirm}
        result={result}
        error={error}
        ErrorHandlerLogin={ErrorHandlerLogin}
        forgotPasswordConfirm={forgotPasswordConfirm}
        loading={loading}
        username={username}
        setUsername={setUsername}
      />
    </div>
  );
}

export default FormForgotPwd;
