export default {
    "dynamicFormsDesign_titleCreate": "New Form",
    "dynamicFormsDesign_titleEdit": "Edit Form",
    "dynamicFormsDesign_name": "Name",
    "dynamicFormsDesign_description": "Description",
    "dynamicFormsDesign_pages": "Pages",
    "dynamicFormsDesign_addPages": "Add page",
    "dynamicFormsDesign_addQuestion": "Add question",
    "dynamicFormsDesign_edit": "Edit",
    "dynamicFormsDesign_duplicate": "Duplicate",
    "dynamicFormsDesign_delete": "Delete",
    "dynamicFormsDesign_label": "Label",
    "dynamicFormsDesign_placeholder": "Placeholder",
    "dynamicFormsDesign_helpText": "Help text",
    "dynamicFormsDesign_required": "Required",
    "dynamicFormsDesign_readOnly": "Read only",
    "dynamicFormsDesign_maxlength": "Max length",
    "dynamicFormsDesign_defaultValue": "Default value",
    "dynamicFormsDesign_title": "Title",
    "dynamicFormsDesign_onLabel": "On label",
    "dynamicFormsDesign_offLabel": "Off label",
    "dynamicFormsDesign_allowGallery": "Allow gallery",
    "dynamicFormsDesign_minimum": "Minimum value",
    "dynamicFormsDesign_maximum": "Maximum value",
    "dynamicFormsDesign_defaultDate": "Default date",
    "dynamicFormsDesign_minimumDate": "Minimum date",
    "dynamicFormsDesign_maximumDate": "Maximum date",
    "dynamicFormsDesign_pagesMandatory": "You must create a least one page for the form",
    "dynamicFormsDesign_fieldsMandatory": "You must create a least one question per page",
    "dynamicFormsDesign_success": "Success",
    "dynamicFormsDesign_successMessage": "The form was saved",
    "dynamicFormsDesign_allowDraft": "Allow the driver to save draft",
    "dynamicFormsDesign_draft": "Draft",
    "dynamicFormsDesign_openQuestionConfigurationDropped": "Open question configuration automatically when new question is added",
    "dynamicFormsDesign_yes": "Yes",
    "dynamicFormsDesign_no": "No",
    "dynamicFormsDesign_closeConfirmationModalTitle": "Warning!",
    "dynamicFormsDesign_closeConfirmationModalMessage": "Are you sure you want to close the form editor? Any unsaved changes will be lost",
    "dynamicFormsDesign_selectPageFieldsMandatory": "Please, add a least one question before change the page",
    "dynamicFormsDesign_minValue": "Min value",
    "dynamicFormsDesign_maxValue": "Max value",
    "dynamicFormsDesign_maxDateValue": "Max date",
    "dynamicFormsDesign_minDateValue": "Min date",
    "dynamicFormsDesign_minDateTimeValue": "Min date time",
    "dynamicFormsDesign_maxDateTimeValue": "Max date time",
    "dynamicFormsDesign_invalidEmail": "Invalid email",
    "dynamicFormsDesign_parameters": "Parameters",
    "dynamicFormsDesign_questions": "Questions",
    "dynamicFormsDesign_active": "Active",
    "dynamicFormsDesign_inactive": "Inactive",
    "dynamicFormsDesign_status": "Status",
    "dynamicFormsDesign_tags": "Tags",
    "dynamicFormsDesign_tagsMandatory": "Please, add a least one tag",
    "dynamicFormsDesign_minTimeValue": "Min time",
    "dynamicFormsDesign_maxTimeValue": "Max time",
    "dynamicFormsDesign_minimumTime": "Minimum time",
    "dynamicFormsDesign_maximumTime": "Maximum time",
    "dynamicFormsDesign_defaultTime": "Default time",
    "dynamicFormsDesign_invalidPhone": "Invalid phone number",
    //Field types
    "dynamicFormsDesign_textField": "Text",
    "dynamicFormsDesign_textFieldHelp": "Insert a new text question",
    "dynamicFormsDesign_textArea": "Text area",
    "dynamicFormsDesign_textAreaHelp": "Insert a new text area question",
    "dynamicFormsDesign_switch": "Yes / No",
    "dynamicFormsDesign_switchHelp": "Insert a new toggle question",
    "dynamicFormsDesign_picture": "Picture",
    "dynamicFormsDesign_pictureHelp": "Insert a new picture question",
    "dynamicFormsDesign_number": "Number",
    "dynamicFormsDesign_numberHelp": "Insert a new numeric question",
    "dynamicFormsDesign_datetime": "Date time",
    "dynamicFormsDesign_datetimeHelp": "Insert a new date time question",
    "dynamicFormsDesign_date": "Date",
    "dynamicFormsDesign_dateHelp": "Insert a new date question",
    "dynamicFormsDesign_email": "Email",
    "dynamicFormsDesign_emailHelp": "Insert a new email question",
    "dynamicFormsDesign_signature": "Signature",
    "dynamicFormsDesign_signatureHelp": "Insert a new signature question",
    "dynamicFormsDesign_phone": "Phone",
    "dynamicFormsDesign_phoneHelp": "Insert a new phone question",
    "dynamicFormsDesign_time": "Time",
    "dynamicFormsDesign_timeHelp": "Insert a new time question",
    "dynamicFormsDesign_dropdown": "Dropdown",
    "dynamicFormsDesign_dropdownHelp": "Insert a new dropdown question",
    "dynamicFormsDesign_checkbox": "Checkbox",
    "dynamicFormsDesign_checkboxHelp": "Insert a new checkbox question",
    "dynamicFormsDesign_option": "Option",
    "dynamicFormsDesign_addOption": "Add option",
    "dynamicFormsDesign_removeOption": "Remove option",
    "dynamicFormsDesign_premiumFeature": "Upgrade to advanced forms",
    "dynamicFormsDesign_resourceFieldsLimit": "You reached the maximum number (5) of questions of this type per form",
    "dynamicFormsDesign_duplicateValue": "This value is duplicated",
    "dynamicFormsDesign_duplicateFormName": "There is already a form with the same name",
}