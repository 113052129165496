// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import { 
  MODULE_SEARCH, 
  MODULE_GET
} from "Redux/actionTypes";

// Actions
import { 
  searchModulesResponse, 
  getModuleResponse
} from "Redux/actions";

// Helpers
import { clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";


function* searchModules({ payload }) {
  const { openSearch, limit, offset } = payload;
  const query = `
    query searchModules ($openSearch: String, $limit: Int, $offset: Int) {
      searchModules (openSearch: $openSearch, limit: $limit, offset: $offset) {
          items {
              id
              code
              userCode
              name
              groupName
              parentModuleId
              category
              subModules {
                  id
                  code
                  userCode
                  name
                  groupName
                  parentModuleId
                  category
                  subModules {
                      id
                      code
                      userCode
                      name
                      groupName
                      parentModuleId
                      category
                  }
              }
          }
          total
      }
    }
    `;

  const variables = {
    openSearch,
    limit,
    offset
  };

  try {
    const data = yield call(
      clientQuery,
      query,
      variables,
      endpoints.GRAPHQL_GENERAL
    );
    
    if (data) {
      const { searchModules: { items, total } } = data;
      yield put(searchModulesResponse({ items, total, error: null }));
    }
  } catch (exc) {
    yield put(searchModulesResponse({ 
      error: exc.error,
      items: [],
      total: 0
    }));
    ExceptionManager(
      exc,
      "modules/account/views/Modules/redux/sagas",
      "searchModule"
    );
  }
}

function* getModule({ payload }) {
  const { id } = payload;
  const query = `
    query getModule ($id: Int!) {
      getModule (id: $id) {
          id
          code
          userCode
          name
          groupName
          parentModuleId
          category
          subModules {
              id
              code
              userCode
              name
              groupName
              parentModuleId
              category
              subModules {
                  id
                  code
                  userCode
                  name
                  groupName
                  parentModuleId
                  category
              }
          }
      }
    }`;

  const variables = { id };

  try {
    const data = yield call(
      clientQuery,
      query,
      variables,
      endpoints.GRAPHQL_GENERAL
    );
    if (data) {
      const { getModule } = data;
      yield put(getModuleResponse({ getModule }));
    }
  } catch (exc) {
    yield put(getModuleResponse({ error: exc.error }));
    ExceptionManager(
      exc,
      "modules/account/views/Module/redux/sagas",
      "getModule"
    );
  }
}

function* watchMethods() {
  yield takeEvery(MODULE_SEARCH, searchModules);
  yield takeEvery(MODULE_GET, getModule);
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}