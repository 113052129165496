import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { NotificationManager } from 'Components/Notifications';

//Action types
import {
    MAP_GEOFENCE_VISIBLE_GEOFENCE_TAGS
} from 'Redux/actionTypes';

//actions
import {
    mapGeofenceVisibleGetGeofenceTagsResponse
} from 'Redux/actions';

function* getGeofenceTagsRequest() {
    try {
        const query = `
            query searchTagsInline ($entityName: String, $limit: Int, $offset: Int) {
                result: searchTagsInline (entityName: $entityName, limit: $limit, offset: $offset) {
                    items {
                        id
                        label
                        color
                    }
                    total
                }
            }
        `;

        let response = yield call(
            clientQuery,
            query,
            {
                entityName: "Geofence"
            },
            endpoints.GRAPHQL_GENERAL,
        );

        let data = response?.result;

        if (!data) throw 'Error while getting geofence groups';

        if(data?.items && data.items.length > 0 ){
            let geofenceTags = data.items.map((tag) => {
                return {
                    ...tag,
                    name: tag.label
                }
            })
            yield put(mapGeofenceVisibleGetGeofenceTagsResponse(geofenceTags));
        }else{
            yield put(mapGeofenceVisibleGetGeofenceTagsResponse([]));
        }

        
    } catch (exc) {
        yield put(mapGeofenceVisibleGetGeofenceTagsResponse([]));
        ExceptionManager(exc, 'realtimeMaps/main/components/geofences/components/geofenceVisibleTags/redux/saga', 'getGeofenceTagsRequest');
    }
};

function* watcVisibleGeofenceTagsRequest() {
    yield takeEvery(MAP_GEOFENCE_VISIBLE_GEOFENCE_TAGS, getGeofenceTagsRequest);
}

//Saga Export
export default function* rootSaga() {
    yield all([
        fork(watcVisibleGeofenceTagsRequest)
    ]);
}