//View Keys
export default {
  realtimemaps_landmark_view: 'VISTA',
  realtimemaps_landmark_create: 'CREACIÓN',
  realtimemaps_landmark_create_select: 'Tipos de Puntos de Referencia',
  realtimemaps_landmark_create_lat: 'Lat',
  realtimemaps_landmark_create_lng: 'Long',
  realtimemaps_landmark_create_notes: 'Notas',
  realtimemaps_landmark_visible_groups: 'Gestionar grupos de hitos visibles',
  realtimemaps_landmark_visible_actions: 'ACCIONES',
  realtimemaps_landmark_manage_landmarks: 'Gestionar Landmarks',
  realtimemaps_landmarks: 'Puntos de Referencia',
  realtimemaps_landmarks_create: 'Crear Puntos de Referencia',
  realtimemaps_geofences_create: 'Crear Geocercas',
  realtimemaps_landmarks_groups: 'Grupos de Puntos de Referencia',
  realtimemaps_landmarks_group: 'Grupo de Puntos de Referencia',
  realtimemaps_landmarks_groups_create: 'Crear Grupos de Puntos de Referencia',
  realtimemaps_landmarks_groups_edit: 'Editar Grupos de Puntos de Referencia',
  realtimemaps_landmarks_fromto: 'Desde',
  realtimemaps_landmarks_groupcolor: 'Color',
  realtimemaps_landmarks_groupactions: 'Acciones',
  realtimemaps_landmarks_groups_select_name: 'Grupo',
  realtimemaps_landmarks_groups_select_placeholder: 'Filtro por Grupo',
  realtimemaps_landmarks_groups_update_placeholder: 'Cambiar Grupo de Puntos de Referencia',
  realtimemaps_landmarks_groups_delete_question: '¿Seguro que desea eliminar este grupo de puntos de referencia?',
  realtimemaps_landmarks_groups_delete_questionsub: 'Las acciones de eliminación no se pueden deshacer.',
  realtimemaps_landmarks_groups_delete_list: 'Borrar (Se elimirán todos los puntos de referencia de este grupo)',
  realtimemaps_landmarks_groups_delete_yes: 'Si, Borrar',
  realtimemaps_landmarks_groups_delete_cancel: 'No, Cancelar',
  realtimemaps_landmarks_edit_modal:'Editar Puntos de Referencia',
  realtimemaps_landmarks_groups_edit_modal:'Editar Grupo de Puntos de Referencia',
  realtimemaps_landmarks_groups_delete_modal:'Eliminar Grupos de Puntos de Referencia',
  realtimemaps_landmarks_bulk_delete:'Borrar en bloque los puntos de referencia',
  realtimemaps_landmarks_delete:'Borrar Punto de Referencia',
  realtimemaps_landmarks_bulk_delete_detail:'¿Estás seguro de que quieres eliminar {number} puntos de referencia?',
  realtimemaps_landmarks_bulk_delete_sdetail:'Esta acción no se puede deshacer.',
  realtimemaps_landmarks_groups_delete_title: '¿Qué quiere hacer con los puntos de referencia de estos grupos?',
  realtimemaps_landmarks_groups_delete_move: 'Mover a',
  realtimemaps_landmarks_grid_title: 'Título',
  realtimemaps_landmarks_grid_address: 'Dirección',
  realtimemaps_landmarks_grid_latlng: 'Lat / Long',
  realtimemaps_landmarks_grid_radius: 'Radio',
  realtimemaps_landmarks_grid_groups: 'Etiqueta',
  realtimemaps_landmarks_grid_notes: 'Notas',
  realtimemaps_landmarks_grid_export: 'Exportar',
  realtimemaps_landmarks_grid_edit: 'Editar',
  realtimemaps_landmarks_groups_new_modal: 'Crear nuevo grupo de puntos de referencia',
  realtimemaps_landmarks_circle: 'Círculo',
  realtimemaps_landmarks_polygon: 'Polígono',
  realtimemaps_landmarks_groups_delete: 'Borrar (Se elimirán todos los puntos de referencia de estos grupos)',
  realtimemaps_landmarks_groups_delete_action: "Acciones Disponibles",
  realtimemaps_landmarks_groups_bulk_delete: 'Borrar en bloque los grupos de puntos de referencia',
  realtimemaps_landmarks_groups_delete_success: 'Grupos de puntos de referencia eliminados exitosamente'
};