import {
  REPORTS_HANDLE_MODAL_DOWNLOAD_FILES
} from "Redux/actionTypes";

export const reportsOpenModalDownloadFiles = (open) => ({
  type: REPORTS_HANDLE_MODAL_DOWNLOAD_FILES,
  payload: {
    open
  },
});
