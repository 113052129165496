import {
    SETTINGS_VEHICLE_EQUIPMENT_SEARCH,
    SETTINGS_VEHICLE_EQUIPMENT_SEARCH_RESPONSE,
    SETTINGS_VEHICLE_EQUIPMENT_OPEN_CREATE_EDIT_MODAL,
    SETTINGS_VEHICLE_EQUIPMENT_CREATE_EDIT,
    SETTINGS_VEHICLE_EQUIPMENT_CREATE_EDIT_RESPONSE,
    SETTINGS_VEHICLE_EQUIPMENT_UNIT_GET_RESPONSE,
    SETTINGS_VEHICLE_EQUIPMENT_OPEN_DELETE_MODAL,
    SETTINGS_VEHICLE_EQUIPMENT_DELETE,
    SETTINGS_VEHICLE_EQUIPMENT_DELETE_RESPONSE,
    SETTINGS_VEHICLE_EQUIPMENT_ASSIGNMENT_DELETE,
    SETTINGS_VEHICLE_EQUIPMENT_ASSIGNMENT_DELETE_RESPONSE,
    SETTINGS_VEHICLE_EQUIPMENT_OPEN_ASSIGNMENT_MODAL,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loadingVehicleEquipment: false,
    loadingCreateEdit: false,
    loadingAssignment: false,
    vehicleEquipmentItems: [],
    selectedItems: [],
    assignmentItems: [],
    total: 0,
    vehicleEquipmentUnits: [],
    vehicleEquipment: null,
    errorVehicleEquipment: false,
    openCreateEdit: false,
    openDelete: false,
    openAssignment: false,
    createEditResponse: null,
    deleteResponse: null,
    deleteResponseAssignment: null,
    filters: {
        openSearch: "",
        limit: 10,
        offset: 0
    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SETTINGS_VEHICLE_EQUIPMENT_SEARCH:
            return {
                ...state,
                vehicleEquipmentItems: [],
                loadingVehicleEquipment: true,
                errorVehicleEquipment: false,
                filters: action.payload
            };

        case SETTINGS_VEHICLE_EQUIPMENT_SEARCH_RESPONSE:
            return {
                ...state,
                vehicleEquipmentItems: action.payload?.vehicleEquipmentItems,
                total: action.payload?.total,
                errorVehicleEquipment: action.payload?.error,
                loadingVehicleEquipment: false
            };

        case SETTINGS_VEHICLE_EQUIPMENT_OPEN_ASSIGNMENT_MODAL:
            return {
                ...state,
                openAssignment: action.payload?.open,
                assignmentItems: action.payload?.assignments
            };

        case SETTINGS_VEHICLE_EQUIPMENT_OPEN_CREATE_EDIT_MODAL:
            return {
                ...state,
                openCreateEdit: action.payload?.open,
                vehicleEquipment: action.payload?.vehicleEquipment
            };


        case SETTINGS_VEHICLE_EQUIPMENT_UNIT_GET_RESPONSE:
            return {
                ...state,
                vehicleEquipmentUnits: action.payload,
                loadingCreateEdit: false
            };

        case SETTINGS_VEHICLE_EQUIPMENT_CREATE_EDIT:
            return {
                ...state,
                loadingCreateEdit: true,
                createEditResponse: null
            };

        case SETTINGS_VEHICLE_EQUIPMENT_CREATE_EDIT_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                createEditResponse: action.payload?.data,
            };

        case SETTINGS_VEHICLE_EQUIPMENT_OPEN_DELETE_MODAL:
            return {
                ...state,
                openDelete: action.payload?.open,
                selectedItems: action.payload?.items,
                loadingDelete: false,
                deleteResponse: null
            };

        case SETTINGS_VEHICLE_EQUIPMENT_DELETE:
            return {
                ...state,
                loadingDelete: true,
                deleteResponse: null
            };

        case SETTINGS_VEHICLE_EQUIPMENT_DELETE_RESPONSE:
            return {
                ...state,
                loadingDelete: false,
                deleteResponse: action.payload?.data,
                filters: {
                    openSearch: "",
                    limit: 10,
                    offset: 0
                }
            };

        case SETTINGS_VEHICLE_EQUIPMENT_ASSIGNMENT_DELETE:
            return {
                ...state,
                loadingAssignment: true,
                deleteResponse: null
            };

        case SETTINGS_VEHICLE_EQUIPMENT_ASSIGNMENT_DELETE_RESPONSE:
            return {
                ...state,
                loadingAssignment: false,
                deleteResponse: action.payload?.data,
            };

        case CLEAR_REDUX:
            return ["", "SETTINGS_VEHICLE_EQUIPMENT"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return { ...state };
    }
};