import {
    /* SETTINGS_DRIVER_GROUP_SEARCH, */
    SETTINGS_DRIVER_GROUP_SEARCH_RESPONSE,
    SETTINGS_DRIVER_GROUP_OPEN_CREATE_EDIT_MODAL,
    SETTINGS_DRIVER_GROUP_CREATE_EDIT,
    SETTINGS_DRIVER_GROUP_OPEN_DELETE_MODAL,
    SETTINGS_DRIVER_GROUP_CREATE_EDIT_RESPONSE,
    SETTINGS_DRIVER_GROUP_DELETE_RESPONSE,
    SETTINGS_DRIVER_GROUP_DELETE,
    SETTINGS_DRIVER_GROUP_GET,
    SETTINGS_DRIVER_GROUP_GET_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loadingDriverGroups: false,
    loadingCreateEdit: false,
    driverGroups: [],
    getDriverGroups: [],
    driverGroup: null,
    errorDriverGroups: false,
    openCreateEdit:false,
    openDelete:false,
    createEditResponse:null,
    deleteResponse:null,
    filters: {
        openSearch: "",
        groupIds: ""
    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        /* case SETTINGS_DRIVER_GROUP_SEARCH:
            return {
                ...state,
                driverGroups: [],
                loadingDriverGroups: true,
                errorDriverGroups: false,
                filters: action.payload
            };  */

        case SETTINGS_DRIVER_GROUP_SEARCH_RESPONSE:
            return {
                ...state,
                driverGroups: action.payload?.driverGroups,
                total: action.payload?.total,
                errorDriverGroups: action.payload?.error,
                loadingDriverGroups: false
            };

        case SETTINGS_DRIVER_GROUP_OPEN_CREATE_EDIT_MODAL:
            return {
                ...state,
                openCreateEdit: action.payload?.open,
                driverGroup: action.payload?.driverGroup
            };
        
        case SETTINGS_DRIVER_GROUP_GET:
            return {
                ...state,
                getDriverGroups: [],
                loadingDelete: true
            };

        case SETTINGS_DRIVER_GROUP_GET_RESPONSE:
            return {
                ...state,
                getDriverGroups: action.payload,
                loadingDelete: false
            };

        case SETTINGS_DRIVER_GROUP_CREATE_EDIT:
            return {
                ...state,
                loadingCreateEdit: true,
                createEditResponse: null
            };

        case SETTINGS_DRIVER_GROUP_CREATE_EDIT_RESPONSE:
            return {
                ...state,
                loadingCreateEdit: false,
                createEditResponse: action.payload?.data,
            };

        case SETTINGS_DRIVER_GROUP_OPEN_DELETE_MODAL:
            return {
                ...state,
                openDelete: action.payload?.open,
                selectedItems: action.payload?.items,
                loadingDelete: false,
                deleteResponse: null
            };

        case SETTINGS_DRIVER_GROUP_DELETE:
            return {
                ...state,
                loadingDelete: true,
                deleteResponse: null
            };

        case SETTINGS_DRIVER_GROUP_DELETE_RESPONSE:
            return {
                ...state,
                loadingDelete: false,
                deleteResponse: action.payload?.data,
            };
    
        case CLEAR_REDUX:
            return ["", "SETTINGS_DRIVER_GROUP"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return { ...state };
    }
};