export default {
    routes_create_edit_point: "Point",
    routes_create_edit_point_name: "Name",
    routes_create_edit_point_notes: "Notes",
    routes_create_edit_point_new: "Point of route added sucessful",
    routes_create_edit_point_new_stop_button: "Add Stop",
    routes_create_edit_point_update_stop_button: "Update Stop",
    routes_create_edit_point_new_waypoing_button: "Add Waypoint",
    routes_create_edit_point_update_waypoing_button: "Update Waypoint",
    routes_create_edit_point_option_middle: "Middle",
    routes_create_edit_point_edit_stop: "Edit Stop",
    routes_create_edit_point_edit_waypoint: "Edit Waypoint",
};