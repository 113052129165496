import colors from 'Assets/sass/core/vars.scss';
export const calculateWeighted = (drivers, points, grades) => {
  let newDrivers = [];

  drivers.forEach((driver, index) => {
      const { EventTotals } = driver;

      let newDriver = {
          name: driver.Name,
          hardTurn: EventTotals.HardTurnCount,
          hardBrake: EventTotals.HardBrakeCount,
          suddenStop: EventTotals.SuddenStopCount,
          rapidAcceleration: EventTotals.RapidAccelerationCount,
          speeding75: EventTotals.Over75Count,
          speeding80: EventTotals.Over80Count,
          speeding85: EventTotals.Over85Count,
          total: EventTotals.TotalCount,
          grade: "A",
          color: colors['color_behavior_green'],
          score: EventTotals.Score
      };

      newDriver.events = [ ...driver.Events ];
      // newDriver.grade = gradeForTotal(newDriver.total, grades, points.allowance);
      newDriver.color = getGradeColor(newDriver.score, grades);
      newDrivers[index] = newDriver;
  });
  return newDrivers;
}

export const updateDriverGradeColor = (drivers, points, grades) => {
  let newDrivers = [];
  drivers.forEach(driver => {
      let color = colors['color_behavior_green'];
      if (driver.score) {
          color = getGradeColor(driver.score, grades);
      }
      let newDriver = { ...driver, color: color };
      newDrivers.push(newDriver);
  });
  return newDrivers;
}

//COLORS SLIDERS SCORES BEHAVIOR
export const COLORS_SLIDERS_SCORE = [
  colors['color_behavior_green'],
  colors['color_behavior_green_light'],
  colors['color_behavior_orange_light'],
  colors['color_behavior_orange'],
  colors['color_behavior_red']
];

export const getGradeColor = (value, grades) => {
  // Update color acording to the score (icon and score colors)
  let color = COLORS_SLIDERS_SCORE[0];
  if (value <= grades[0]) {
      color = COLORS_SLIDERS_SCORE[0];
  } else if (value >= grades[0] && value <= grades[1]) {
      color = COLORS_SLIDERS_SCORE[2];
  } else if (value > grades[1]) {
      color = COLORS_SLIDERS_SCORE[4];
  }
  return color;
}

export const getNewGrades = (newGradeRange) => {
  let grades =[];
  grades[0] = 0;
  grades[1] = newGradeRange[0].B;
  grades[2] = newGradeRange[0].C;
  grades[3] = newGradeRange[0].D;
  grades[4] = newGradeRange[0].E;
  grades[5] = 100;
  return grades;
}

export const getNewPoints = (newPoints) => {
  let points = {};
  points.hardTurn = newPoints[0].HardTurn;
  points.hardBrake = newPoints[0].HardBrake;
  points.potentialIncident = newPoints[0].PotentialIncident;
  points.rapidAcceleration = newPoints[0].RapidAcceleration;
  points.speeding75 = newPoints[0].Over75;
  points.speeding80 = newPoints[0].Over80;
  points.speeding85 = newPoints[0].Over85;
  points.allowance = newPoints[0].TotalAllowed;
  return points;
}

export const updateTrendsGrades = (grades, data) => {
    // Update color acording to the score
    let { datasets } = data;
    if (datasets && datasets.length > 0) {
        let newDatasets = [ ...datasets ];
        newDatasets[0].pointBackgroundColor = (context) => {
            let index = context.dataIndex;
            let value = context.dataset.data[index];
            let color = colors['color_behavior_green'];
            if (value >= grades[0] && value <= grades[1]) {
                color = colors['color_behavior_green'];
            } else if (value > grades[1] && value <= grades[2]) {
                color = colors['color_behavior_green_light'];
            } else if (value > grades[2] && value <= grades[3]) {
                color = colors['color_behavior_orange_light'];
            } else if (value > grades[3] && value <= grades[4]) {
                color = colors['color_behavior_orange'];
            } else if (value > grades[4] && value <= grades[5]) {
                color = colors['color_behavior_red'];
            }
            return color;
        },
        data.datasets = newDatasets;
    }
    return data
} 


//COLOR DISABLED BEHAVIOR
export const COLOR_DISABLED =  colors['color-background-input-no-border'];

export const orderInfoDailyBehavior = (driversUnits) => {
  // Order rows in order to show in the table 
  // and put Speeds in just one array
  let organizeInfo = { total: driversUnits.total}
  let items = driversUnits?.items?.map((driverUnit) => {
    let item = {name: driverUnit?.name || '', score: driverUnit?.score || 0, total: driverUnit?.safetyEvents || 0};
    driverUnit.metrics?.forEach((metric) => {
      if(metric.metric == "SpeedOver"){
        item[`SpeedOver`] = item[`SpeedOver`] ? [...item[`SpeedOver`], metric] :[metric];
      }else if(metric.metric === "SpeedOverPosted"){
        item[`${metric.metric}${metric.metricValue}`] = metric.events;
      }else{
        item[metric.metric] = metric.events;
      }
    })
    item[`Count`] = driverUnit?.metrics?.length || 0;
    return item;
  });
  organizeInfo = {...organizeInfo, items}
  return organizeInfo;
}