import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
    DEBUG_UNIT_GET_UNIT_DATA
} from 'Redux/actionTypes';

import {
    debugUnitGetUnitDataResponse
} from 'Redux/actions';

function* getUnitDataRequest({ payload }) {
    try {
        const { data } = payload;
        const response = yield call(
            clientQuery,
            `
            query getUnitAuditData (
                $id: Int!
            ) {
                unit: getUnitAuditData (
                    id: $id
                )
            }
            `,
            {
                id: data.id
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (response && response.unit) {
            const unit = JSON.parse(response.unit);
            yield put(debugUnitGetUnitDataResponse(unit, null));
        } else {
            throw 'Not unit data found';
        }
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, 'Core/views/alertLog/redux', 'getSystemAlertlogRequest');
        yield put(debugUnitGetUnitDataResponse(null, true));
    }
}

function* watchGetUnitDataRequest() {
    yield takeEvery(DEBUG_UNIT_GET_UNIT_DATA, getUnitDataRequest);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetUnitDataRequest)
    ]);
}