export default {
    "dynamicFormsDesign_titleCreate": "Nuevo Formulario",
    "dynamicFormsDesign_titleEdit": "Editar Formulario",
    "dynamicFormsDesign_name": "Nombre",
    "dynamicFormsDesign_description": "Descripción",
    "dynamicFormsDesign_pages": "Páginas",
    "dynamicFormsDesign_addPages": "Agregar página",
    "dynamicFormsDesign_addQuestion": "Añadir pregunta",
    "dynamicFormsDesign_edit": "Editar",
    "dynamicFormsDesign_duplicate": "Duplicar",
    "dynamicFormsDesign_delete": "Eliminar",
    "dynamicFormsDesign_label": "Etiqueta",
    "dynamicFormsDesign_placeholder": "Placeholder",
    "dynamicFormsDesign_helpText": "Texto de ayuda",
    "dynamicFormsDesign_required": "Requerido",
    "dynamicFormsDesign_readOnly": "Solo lectura",
    "dynamicFormsDesign_maxlength": "Longitud",
    "dynamicFormsDesign_defaultValue": "Valor por defecto",
    "dynamicFormsDesign_title": "Título",
    "dynamicFormsDesign_onLabel": "Etiqueta de encendido",
    "dynamicFormsDesign_offLabel": "Etiqueta de apagado",
    "dynamicFormsDesign_allowGallery": "Permitir galería",
    "dynamicFormsDesign_minimum": "Valor mínimo",
    "dynamicFormsDesign_maximum": "Valor máximo",
    "dynamicFormsDesign_defaultDate": "Fecha por defecto",
    "dynamicFormsDesign_minimumDate": "Fecha mínima",
    "dynamicFormsDesign_maximumDate": "Fecha máxima",
    "dynamicFormsDesign_pagesMandatory": "Debe crear al menos una página para el formulario",
    "dynamicFormsDesign_fieldsMandatory": "Debe crear al menos una pregunta por página",
    "dynamicFormsDesign_success": "Exito",
    "dynamicFormsDesign_successMessage": "El formulario guardado",
    "dynamicFormsDesign_allowDraft": "Permitir al conductor guardar como borrador",
    "dynamicFormsDesign_draft": "Borrador",
    "dynamicFormsDesign_openQuestionConfigurationDropped": "Abrir configurar pregunta automaticamente cuando se agregue un nuevo campo",
    "dynamicFormsDesign_yes": "Si",
    "dynamicFormsDesign_no": "No",
    "dynamicFormsDesign_closeConfirmationModalTitle": "Aviso",
    "dynamicFormsDesign_closeConfirmationModalMessage": "¿Está seguro de que desea cerrar el editor de formularios? Cualquier cambio no guardado se perderá",
    "dynamicFormsDesign_selectPageFieldsMandatory": "Por favor, agregue al menos una pregunta antes de cambiar la página",
    "dynamicFormsDesign_minValue": "Valor mínimo",
    "dynamicFormsDesign_maxValue": "Valor máximo",
    "dynamicFormsDesign_maxDateValue": "Fecha máxima",
    "dynamicFormsDesign_minDateValue": "Fecha mínima",
    "dynamicFormsDesign_minDateTimeValue": "Fecha y hora mínima",
    "dynamicFormsDesign_maxDateTimeValue": "Fecha y hora máxima",
    "dynamicFormsDesign_invalidEmail": "Dirección de correo inválida",
    "dynamicFormsDesign_parameters": "Parámetros",
    "dynamicFormsDesign_questions": "Preguntas",
    "dynamicFormsDesign_active": "Activo",
    "dynamicFormsDesign_inactive": "Inactivo",
    "dynamicFormsDesign_status": "Estado",
    "dynamicFormsDesign_tags": "Etiquetas",
    "dynamicFormsDesign_tagsMandatory": "Por favor, seleccione al menos una etiqueta",
    "dynamicFormsDesign_minTimeValue": "Hora mínima",
    "dynamicFormsDesign_maxTimeValue": "Hora máxima",
    "dynamicFormsDesign_minimumTime": "Hora mínima",
    "dynamicFormsDesign_maximumTime": "Hora máxima",
    "dynamicFormsDesign_defaultTime": "Hora por defecto",
    "dynamicFormsDesign_invalidPhone": "Número de teléfono inválido",
    //Field types
    "dynamicFormsDesign_textField": "Texto",
    "dynamicFormsDesign_textFieldHelp": "Insertar nueva pregunta de texto",
    "dynamicFormsDesign_textArea": "Área de texto",
    "dynamicFormsDesign_textAreaHelp": "Insertar nueva pregunta de area texto",
    "dynamicFormsDesign_switch": "Si / No",
    "dynamicFormsDesign_switchHelp": "Insertar nueva pregunta de palanca",
    "dynamicFormsDesign_picture": "Imagen",
    "dynamicFormsDesign_pictureHelp": "Insertar nueva pregunta de imagen",
    "dynamicFormsDesign_number": "Númerico",
    "dynamicFormsDesign_numberHelp": "Insertar nueva pregunta númerica",
    "dynamicFormsDesign_datetime": "Fecha y hora",
    "dynamicFormsDesign_datetimeHelp": "Insertar nueva pregunta de fecha y hora",
    "dynamicFormsDesign_date": "Fecha",
    "dynamicFormsDesign_dateHelp": "Insertar nueva pregunta de fecha",
    "dynamicFormsDesign_email": "Correo Electrónico",
    "dynamicFormsDesign_emailHelp": "Insertar nueva pregunta de correo electrónico",
    "dynamicFormsDesign_signature": "Firma",
    "dynamicFormsDesign_signatureHelp": "Insertar nuevo campo de firma",
    "dynamicFormsDesign_phone": "Teléfono",
    "dynamicFormsDesign_phoneHelp": "Insertar nuevo campo de teléfono",
    "dynamicFormsDesign_time": "Hora",
    "dynamicFormsDesign_timeHelp": "Insertar nuevo campo de hora",
    "dynamicFormsDesign_dropdown": "Seleccion",
    "dynamicFormsDesign_dropdownHelp": "Insertar nuevo campo de seleccion",
    "dynamicFormsDesign_checkbox": "Casilla de verificación",
    "dynamicFormsDesign_checkboxHelp": "Insertar nuevo campo de casillas de verificación",
    "dynamicFormsDesign_option": "Opción",
    "dynamicFormsDesign_addOption": "Agregar opción",
    "dynamicFormsDesign_removeOption": "Remover opción",
    "dynamicFormsDesign_premiumFeature": "Actualizar a formularios avanzados",
    "dynamicFormsDesign_resourceFieldsLimit": "Alcanzaste el número máximo (5) de preguntas de este tipo por formulario",
    "dynamicFormsDesign_duplicateValue": "Este valor está duplicado",
    "dynamicFormsDesign_duplicateFormName": "Ya existe un formulario con el mismo nombre.",
}