import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';

// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

import {baseReportConditions, generateReportInstance, getTimeZone, handleAsyncReport, isJsonFormat} from 'Modules/reports/views/main/components/reportsTab/helper';

import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

function* getStopReport(report, page, rowsPerPage, sortField, sortOrder) {
  const {timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath} = report;
  const {filterType, filterArray, startRange, endRange, unitsSelected, accumulateData = true, dates, otherFilters, filterEntity} = report.filters
  const baseConditionsQuery = baseReportConditions({
    report,
    name,
    dataResultPath,
    timeZone,
    delayed,
    unitsSelected,
    timeframeName,
    filterType,
    filterArray,
    categoryName,
    startRange,
    endRange,
    dates
  })
  const { excludeIdle, minute } = otherFilters
  const addColumnDriver = (
    report?.exportType === 'csv' ?
          [{
            "column": "idLabel",
            "header": filterEntity === 'Driver' ? "Driver" : "Unit"
          },
          {
            "column": "driverUnit",
            "header": filterEntity === 'Driver' ? "Unit" : "Driver"
          }]
        : [{
          "column": "driverUnit",
          "header": filterEntity === 'Unit' ? "Driver" : 'Unit'
        }])

  let payload = {
    conditions: {
      ...baseConditionsQuery,
      integerRanges: {
        fields: [
          "stop"
        ],
        GTE: minute || 0,
        LTE: 0
      },
      filterValues: {
        field: "excludeIdle",
        booleanValue: excludeIdle
      },
      sortField: {
        field: sortField || "deviceId",
        order: sortOrder === 'desc' ? 'DESC' : 'ASC'
      }
    },
    offset: page,
    limit: rowsPerPage
  }
  if (report.export) {
     payload = {
       ...payload,
       limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
       offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
       conditions: {
         ...payload.conditions,
         exportOptions: {
           sendByEmail,
           emailsTo,
           format: {
             fileFormat: report?.exportType || "json",
             formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
             durationFormat: report?.durationFormat
           },
           culture: report.lang,
           timeZone: getTimeZone(timeZone),
           folderName: "px-customer-reports",
           filePrefixName: "StopReport",
           columns: [
             ...addColumnDriver,
             {
               "column": "event",
               "header": "Event"
             },
             {
               "column": "address",
               "header": "Address"
             },
             {
               "column": "dateTime",
               "header": `Time & Date ${report?.exportType === 'csv' ? `(${getAbbvTimezone(timeZone)})`: ''}`
             },
             {
               "column": "duration",
               "header": "Duration"
             }
           ]
         }
       }
     }
  }

  try {
    let data = yield call(
      clientQuery,
      `query($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getStopReport(conditions: $conditions, limit: $limit, offset: $offset) {
          ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?  

            `reportInstanceId`
              :`
              items {
                recordInfo {
                    entityId
                    entityLabel
                    tagId
                    tagLabel
                    startDate
                    endDate
                    recordType
                    groupLabel
                    groupIndex
                    groupTotal
                    groupTagColor
                }
                recordDetail {
                  deviceId
                  date
                  unitId
                  unitTime
                  eventName
                  driverId
                  driverName
                  address
                  deviceLabel
                  duration
                  landmarkId
                  landmarkName
                  latitude
                  longitude
                  totalIdleTime
                  totalIgnitionOffTime
                  noData
                }
            }
            total
            limit
            offset
            category
            reportInstanceId
          `}
        }
      }`,
      payload,
      endpoints.GRAPHQL_PX_REPORTING,
    );

    yield handleAsyncReport(report, data, accumulateData);
  } catch (exc) {
    ExceptionManager(exc, 'reports/stopReport', 'getStopReport');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
    if (!requestFlag) {
      let flag = true
      yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
    } else {
      report.loading = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  }
}


export default getStopReport;
