// Action Types
import { 
  CONTACTS_SEARCH,
  CONTACTS_SEARCH_RESPONSE,
  CONTACTS_CREATE,
  CONTACTS_CREATE_RESPONSE,
  CONTACTS_UPDATE,
  CONTACTS_UPDATE_RESPONSE,
  CONTACTS_DELETE,
  CONTACTS_DELETE_RESPONSE,
  CLEAR_REDUX,
  CONTACTS_SEARCH_CELL_CARRIERS,
  CONTACTS_SEARCH_CELL_CARRIERS_RESPONSE
} from "Redux/actionTypes";

/**
 * Initial State Contacts
 */
const INITIAL_STATE = {
  loading: false,
  allfilters: {
    openSearch: "",
    limit: 10,
		offset: 0
  },
  registers: [],
  cellCarriers: [],
  contact: null,
  deleteContactResponse: false,
  formOptionsTypeSelected: [
    {
      id: "email",
      name: "Email"
    },
    {
      id: "cellphone",
      name: "Cell phone"
    }
  ],
  total: 0,
  error: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONTACTS_SEARCH:
      return {
        ...state,
        contact: null,
        error: false,
        deleteContactResponse: false,
        loading: true,
        allfilters: action.payload
      };
    case CONTACTS_SEARCH_RESPONSE:
      return {
        ...state,
        registers: action.payload?.items,
        total: action.payload?.total,
        loading: false,
        error: action.payload?.error
      };
    case CONTACTS_CREATE:
      return {
        ...state,
        contact: null,
        error: false,
        loading: true,
      };
    case CONTACTS_CREATE_RESPONSE:
      return {
        ...state,
        contact: action.payload?.newContact,
        error: action.payload?.error,
        loading: false
      };
    case CONTACTS_UPDATE:
      return {
        ...state,
        contact: null,
        error: false,
        loading: true,
      };
    case CONTACTS_UPDATE_RESPONSE:
      return {
        ...state,
        contact: action.payload?.updateContact,
        error: action.payload?.error,
        loading: false
      };  
    case CONTACTS_DELETE:
      return {
        ...state,
        contact: null,
        error: false,
        loading: true
      };  
    case CONTACTS_DELETE_RESPONSE:
      return {
        ...state,
        deleteContactResponse: action.payload?.deleteContact,
        error: action.payload?.error,
        loading: false
      };
    case CONTACTS_SEARCH_CELL_CARRIERS:
      return {
        ...state,
        error: false,
        loading: true
      }; 
    case CONTACTS_SEARCH_CELL_CARRIERS_RESPONSE:
      return {
        ...state,
        loading: false,
        cellCarriers: action.payload?.cellCarriers,
        error: action.payload?.error
      }; 
    case CLEAR_REDUX:
      return ["", "CONTACTS"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INITIAL_STATE }
        : { ...state };  
    default:
      return state;
  }
};
