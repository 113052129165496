import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';

import Popover from '@mui/material/Popover';

import { Checkbox } from "@gpstrackit/gtc-react-controls";

import {
    savePreference
} from 'Redux/actions';

let timerLastEventWeather = null

const WeatherToggle = (props) => {
    const { onShowWeather, onHideWeather, isWeatherEnabled } = props;
    const keyPreferences = "mapSettings";
    const dispatch = useDispatch();
    const { messages, locale } = useIntl();

    const { preferencesUser } = useSelector(state => state.preferencesRedux);
    const { authenticatedId } = useSelector(state => state.securityRedux);

    const [preferences, setPreferences] = useState({});
    const [isLegendsOpened, setIsLegendsOpened] = useState() 

    const LISTWEATHERLAYER = [
        { id: 0, name: messages['map_radar'], value: "radar", isChecked: false },
        { id: 1, name: messages['map_satellite'], value: "satellite", isChecked: false },
        //{ id: 2, name: "Puntos de Rocío", value: "dew_points", isChecked: false },
        //{ id: 6, name: "Puntos de Rocío Pronosticados", value: "fdew-points", isChecked: false },
        { id: 2, name: messages['map_heat_index'], value: "heat_index", isChecked: false },
        { id: 3, name: messages['map_forecasted_heat_index'], value: "fheat-index", isChecked: false },
        { id: 4, name: messages['map_humidity'], value: "humidity", isChecked: false },
        //{ id: 10, name: "Humedad Pronosticada", value: "fhumidity", isChecked: false },
        { id: 5, name: messages["map_surface_analysis"], value: "surface_analysis", isChecked: false },
        { id: 6, name: messages["map_drought_monitor"], value: "drought_monitor", isChecked: false },
        { id: 7, name: messages["map_forecasted_feels_like_temperature"], value: "ffeels-like", isChecked: false },
        { id: 8, name: messages["map_forecasted_jet_stream"], value: "fjet-stream", isChecked: false },
        { id: 9, name: messages["map_forecasted_precipitation"], value: "fqpf-1h", isChecked: false },
        { id: 10, name: messages["map_forecasted_precipitation_6"], value: "fqpf-6h", isChecked: false },

    ];

    const [weatherLayers, setWeatherLayers] = useState(LISTWEATHERLAYER) 

    useEffect(()=> {
        setWeatherLayers((layers) =>
            layers.map((layer, i) =>
                ({ ...layer, name: LISTWEATHERLAYER[i].name })
            )
        );
    },[locale])

    useEffect(() => {
        if (preferencesUser) {
            let preferencesKeyTemp = preferencesUser.find(x => x.preferenceKey === keyPreferences);
            let preferencesJSON = preferencesKeyTemp && preferencesKeyTemp.preferenceValue != "" && preferencesKeyTemp.preferenceValue || "{}";
            preferencesJSON = JSON.parse(preferencesJSON);

            setPreferences(preferencesJSON);
            if (preferencesJSON?.showWeather && !isWeatherEnabled) {

                //show the checked elements
                const weatherSelected = preferencesJSON?.weatherLayers && preferencesJSON?.weatherLayers.length > 0 ? preferencesJSON?.weatherLayers.split(',') : [];
                const updatedWeatherLayers = weatherLayers.map(layer => ({
                    ...layer,
                    isChecked: weatherSelected.includes(layer.value),
                }));
                setWeatherLayers(updatedWeatherLayers);

                onShowWeather(preferencesJSON?.weatherLayers || '');
            } else if (!preferencesJSON?.showWeather && isWeatherEnabled) {
                onHideWeather();
            }
        }
    }, [preferencesUser]);


    const showWeather = (elementsToCheck = null) => {
        const items = elementsToCheck ? elementsToCheck.filter((item) => item.isChecked) : weatherLayers.filter((item) => item.isChecked)
        const valueLayers = items && items?.length > 0 ? items.map(layer => layer.value).join(',') : "";

        const enableWeather = items?.length > 0
        
        
        if(enableWeather){
            onShowWeather(valueLayers);
        } else {
            onHideWeather();
        }

        const newPreferences = { ...preferences || {}, showWeather: enableWeather, weatherLayers: valueLayers };
        dispatch(savePreference("User", authenticatedId, keyPreferences, newPreferences));
    }

    const openWeatherControl = (event) => {
        setIsLegendsOpened(event.currentTarget)
    }

    const handleClose = () => {
        setIsLegendsOpened(null)

    };

    const handleCheckboxChange = (index) => {

        const elementsToCheck = weatherLayers.map((layer, i) =>
            i === index ? { ...layer, isChecked: !layer.isChecked } : layer
        )

        setWeatherLayers((prevLayers) =>
          prevLayers.map((layer, i) =>
            i === index ? { ...layer, isChecked: !layer.isChecked } : layer
          )
        );

        return elementsToCheck
      };

    const open = Boolean(isLegendsOpened);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <button
                className={"traffic-control"}
                onClick={(event) => openWeatherControl(event)}
                title="Weather"
                style={{ color: `${(isWeatherEnabled ? '#00AEEF' : 'rgba(0, 0, 0, 0.6)')}` }}
            >
                {messages['map_weather']}
                <ThunderstormIcon className={'icon-traffic-button'} style={{ color: `${(isWeatherEnabled ? '#00AEEF' : '#aeaeae')}` }} />
                
            </button>
            <Popover
                id={id}
                open={open}
                anchorEl={isLegendsOpened}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                sx={{
                    marginTop: '10px',
                    borderRadius: 0
                }}
            >
                <div style={{ width: 240, height: 375, padding: 10}}>
                {
                    weatherLayers.map((layer, key) => {
                        return (
                            <div style={{ color: 'rgb(0, 174, 239)', fontFamily: 'Outfit' }} >
                            <Checkbox
                                key={key}
                                checked={layer.isChecked}
                                label={layer.name}
                                onChange={() => { }}
                                onClick={() => {

                                    if(timerLastEventWeather) clearInterval(timerLastEventWeather);
                                    timerLastEventWeather = null

                                    const elementsToCheck = handleCheckboxChange(key)

                                    timerLastEventWeather = setTimeout(() => { showWeather(elementsToCheck) }, 800)
                                    
                                }}
                            />
                            </div>
                        )
                    })
                }
                </div>
            </Popover>
        </div>
    );

}

export default WeatherToggle