import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { LogManager, ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
    ENTITY_TAG_PICKER_SEARCH_ENTITY_TAGS
} from 'Redux/actionTypes';

import {
    entityTagPickerSearchEnityTagsResponse
} from 'Redux/actions';

function* entityTagPickerSearchEnityTagsRequest({ payload }) {
    const { filters } = payload;
    const { parentTagId, entityName, onlyTagsWithEntities, unitVersionIds } = filters;

    try {
        const response = yield call(
            clientQuery,
            `
            query searchTagsAndEntitiesNested(
                $parentTagIds: [Int], 
                $entityName: EntityName!, 
                $openSearch: String,
                $onlyEntities: Boolean,
                $onlyTagsWithEntities: Boolean,
                $unitVersionIds: [Int],
                $limit: Int, 
                $offset: Int
            ) {
                entityTags: searchTagsAndEntitiesNested (
                    parentTagIds: $parentTagIds, 
                    entityName: $entityName, 
                    limit: $limit, 
                    offset: $offset,
                    openSearch: $openSearch,
                    onlyEntities: $onlyEntities,
                    onlyTagsWithEntities: $onlyTagsWithEntities,
                    unitVersionIds: $unitVersionIds,
                ) {
                    items {
                        name
                        id
                        label
                        color
                        disabled
                        parentTagId
                        tag {
                            hasChildren
                            unitsCount
                            driversCount
                            ${entityName === "Landmark" ? 'entitiesCount: landmarksCount' : ''} 
                            ${entityName === "User" ? 'entitiesCount: usersCount' : ''}
                            ${entityName === "Form" ? 'entitiesCount: formsCount' : ''}
                            ${entityName === "Geofence" ? 'entitiesCount: geofencesCount' : ''}
                            ${entityName === "DynamicForm" ? 'entitiesCount: dynamicFormsCount' : ''}
                        }
                    }
                    total
                }
            }
            `,
            {
                parentTagIds: parentTagId ? [parentTagId] : [],
                entityName: entityName,
                limit: filters?.limit || 0,
                offset: filters?.offset || 0,
                openSearch: filters?.openSearch || "",
                onlyEntities: false,
                onlyTagsWithEntities: onlyTagsWithEntities,
                unitVersionIds: unitVersionIds
            },
            endpoints.GRAPHQL_GENERAL,
        );
        
        if (!response || !response.entityTags) {
            throw 'No entityTags data';
        }

        const { entityTags: { items, total }} = response;
        yield put(entityTagPickerSearchEnityTagsResponse({ items, total, parentTagId, entityName }));
    } catch (exc) {
        ExceptionManager(exc, 'modules/.common/components/EntityTagPicker/redux/saga.js', 'entityTagPickerSearchEnityTagsRequest');
        yield put(entityTagPickerSearchEnityTagsResponse({ items: [], total: 0, error: true, parentTagId, entityName }));
    }
}

function* watchMethods() {
    yield takeEvery(ENTITY_TAG_PICKER_SEARCH_ENTITY_TAGS, entityTagPickerSearchEnityTagsRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}

