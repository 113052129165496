import {
  //TYPES
  MAP_LANDMARK_MANAGE_SHOW_MODAL,
  MAP_LANDMARK_VISIBLE_SHOW_MODAL,
  MAP_LANDMARK_GROUP_DELETE_SHOW_MODAL,
  MAP_LANDMARK_GROUP_DELETE,
  MAP_LANDMARK_GROUP_DELETE_RESPONSE,
  MAP_LANDMARK_GROUP_EDIT_SHOW_MODAL,
  MAP_LANDMARK_GROUP_EDIT,
  MAP_LANDMARK_GROUP_EDIT_RESPONSE,
  MAP_LANDMARK_GROUP_NEW_SHOW_MODAL,
  MAP_LANDMARK_GROUP_NEW,
  MAP_LANDMARK_GROUP_NEW_RESPONSE,
  MAP_LANDMARK_BULK_DELETE_SHOW_MODAL,
  MAP_LANDMARK_BULK_EDIT,
  MAP_LANDMARK_BULK_EDIT_RESPONSE,
  MAP_LANDMARK_BULK_EDIT_SHOW_MODAL,
  MAP_LANDMARK_CREATE_SELECT_TYPE,
  MAP_LANDMARK_GROUP_HIDE,
  MAP_LANDMARK_NEW,
  MAP_LANDMARK_NEW_RESPONSE,
  MAP_LANDMARK_RESTART_PROCESS,
  MAP_LANDMARK_BULK_DELETE,
  MAP_LANDMARK_BULK_DELETE_RESPONSE,
  MAP_LANDMARK_EDIT_SHOW_MODAL,
  MAP_LANDMARK_DELETE_SHOW_MODAL,
  MAP_LANDMARK_GET_LANDMARK_GROUPS,
  MAP_LANDMARK_GET_LANDMARK_GROUPS_RESPONSE,
  MAP_LANDMARK_GET_LANDMARKS,
  MAP_LANDMARK_GET_LANDMARKS_RESPONSE,
  MAP_LANDMARK_UPDATE_ADD_LANDMARK_GROUP,
  CLEAR_REDUX
} from "Redux/actionTypes";

const INIT_STATE = {
  smodalManageLandmark: false,
  smodalVisibleLanmark: false,
  smodalLandmarkGroupDelete: false,
  smodalLandmarkGroupEdit: false,
  smodalLandmarkGroupNew: false,
  smodalLandmarkBulkDelete: false,
  smodalLandmarkBulkEdit: false,
  smodalLandmarkEdit: false,
  smodalLandmarkDelete: false,
  loadingLandmark: false,
  process: false,
  error: '',
  selectedLandmarkGroups: [],
  landmarkGroupItem: null,
  deleteLandmarkGroupsResponse: null,
  loadingDeleteLandmarkGroupsResponse: false,
  landmarkItemsSelected: null,
  createTypeSelect: 0,
  createMaxPoints: 0,
  hiddenLandmarkGroups: [],
  landmarkGroups: [],
  landmarkGroupsPreferences: [],
  landmarksProperties: { offset: 0, limit: 0, openSearch: '', groupId: 0, total: 0 },
  landmarks: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MAP_LANDMARK_MANAGE_SHOW_MODAL:
      return { ...state, smodalManageLandmark: action.payload };

    case MAP_LANDMARK_VISIBLE_SHOW_MODAL:
      return { ...state, smodalVisibleLanmark: action.payload };

    case MAP_LANDMARK_GROUP_DELETE_SHOW_MODAL:
      return { 
        ...state, 
        smodalLandmarkGroupDelete: action.payload.show, 
        selectedLandmarkGroups: action.payload.show ? action.payload.items : []
      };

    case MAP_LANDMARK_NEW:
    case MAP_LANDMARK_GROUP_NEW:
    case MAP_LANDMARK_GROUP_EDIT:
    case MAP_LANDMARK_BULK_EDIT:
    case MAP_LANDMARK_BULK_DELETE:
      return { ...state, loadingLandmark: true, process: false }

    case MAP_LANDMARK_GROUP_DELETE:
      return { ...state, process: false, deleteLandmarkGroupsResponse: null, loadingDeleteLandmarkGroupsResponse: true }

    case MAP_LANDMARK_NEW_RESPONSE:
    case MAP_LANDMARK_GROUP_NEW_RESPONSE:
    case MAP_LANDMARK_GROUP_EDIT_RESPONSE:
    case MAP_LANDMARK_BULK_EDIT_RESPONSE:
    case MAP_LANDMARK_BULK_DELETE_RESPONSE:
      return { ...state, loadingLandmark: false, process: action.payload.result, error: action.payload.error }

    case MAP_LANDMARK_GROUP_DELETE_RESPONSE:
      return { ...state, process: true, deleteLandmarkGroupsResponse: action.payload.result, loadingDeleteLandmarkGroupsResponse: false }

    case MAP_LANDMARK_GROUP_EDIT_SHOW_MODAL:
      return { ...state, 
                smodalLandmarkGroupEdit: action.payload.show, 
                landmarkGroupItem: action.payload.show ? action.payload.landmarkGroupItem : null };

    case MAP_LANDMARK_GROUP_NEW_SHOW_MODAL:
      return { ...state, smodalLandmarkGroupNew: action.payload.show, landmarkGroupItem: null };

    case MAP_LANDMARK_BULK_DELETE_SHOW_MODAL:
      return { ...state, smodalLandmarkBulkDelete: action.payload.show, landmarkItemsSelected: action.payload.landmarkItemsSelected };
      
    case MAP_LANDMARK_BULK_EDIT_SHOW_MODAL:
      return { ...state, smodalLandmarkBulkEdit: action.payload.show, landmarkItemsSelected: action.payload.landmarkItemsSelected };

    case MAP_LANDMARK_EDIT_SHOW_MODAL:
      return { ...state, smodalLandmarkEdit: action.payload.show, landmarkItemsSelected: action.payload.landmarkItemsSelected };

    case MAP_LANDMARK_DELETE_SHOW_MODAL:
      return { ...state, smodalLandmarkDelete: action.payload.show, landmarkItemsSelected: action.payload.landmarkItemsSelected };

    case MAP_LANDMARK_CREATE_SELECT_TYPE:
      return { ...state, createTypeSelect: action.payload.type, createMaxPoints: action.payload.maxPoints };
    
    case MAP_LANDMARK_GROUP_HIDE:
      return { ...state, hiddenLandmarkGroups: action.payload };

    case MAP_LANDMARK_RESTART_PROCESS:
      return { ...state, process: false };

    case MAP_LANDMARK_GET_LANDMARK_GROUPS:
      return { ...state, landmarkGroups: [], loadingLandmarkGroups: true };

    case MAP_LANDMARK_GET_LANDMARK_GROUPS_RESPONSE:
      return { ...state, landmarkGroups:  action.payload.response, loadingLandmarkGroups: false };

    case MAP_LANDMARK_GET_LANDMARKS:
      return { ...state, 
        landmarks: 
            action.payload.limit != state.landmarksProperties.limit
            || action.payload.groupId != state.landmarksProperties.groupId
            || action.payload.clearLandmarks 
            ? [] : state.landmarks,
        loadingLandmark: true, 
        landmarksProperties: action.payload 
      };
        
    case MAP_LANDMARK_GET_LANDMARKS_RESPONSE:
      const { data, total } = action.payload;
      return {
        ...state,
        landmarks: [...state.landmarks, ...data],  
        landmarksProperties: {...state.landmarksProperties, total: total },
        loadingLandmark: false
      };

    case MAP_LANDMARK_UPDATE_ADD_LANDMARK_GROUP:
      let newLandmarkGroupsPreferences = [ ...state.landmarkGroupsPreferences ];
      const { payload: { landmarkGroup, action: a} } = action;

      if (a === "add") {
        newLandmarkGroupsPreferences.push(landmarkGroup);
      } else if (a === "update") {
        newLandmarkGroupsPreferences = state.landmarkGroupsPreferences.map(currentLandmarkGroup => {
          if (currentLandmarkGroup.id == landmarkGroup.id) {
            return {
              ...currentLandmarkGroup,
              ...landmarkGroup
            }
          }
          
          return currentLandmarkGroup;
        });
      }

      return { ...state, landmarkGroupsPreferences: newLandmarkGroupsPreferences };

    case CLEAR_REDUX:
      return ["", "MAP_LANDMARKS"].includes(action.payload.option)
      ? action.payload.parameters
        ? { ...state, ...action.payload.parameters }
        : { ...INIT_STATE }
      : { ...state };

    default:
      return state;
  }
};