
import React, { useRef, useState, Fragment } from 'react';
import { Grid } from '@mui/material';
import SidePanel, { SidePanelHeader, SidePanelBody, SidePanelFooter } from 'Components/SidePanel';
import InputSearch from 'Components/InputSearch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import {
  Pill,
  Form,
  Button
} from 'Components';

import { useReportTypeOptions } from 'Core/hooks'
import { validateReportTypePermissions } from '../../../../../../.globals/helpers';
import {
  reportModalSelectType,
  reportModalSelectReport,
  reportsOpenModalReportType
} from 'Redux/actions';

import './styles.scss';

const ReportTypes = (props) => {

  const { messages } = useIntl();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const open = useSelector(state => state.reportsTypesModal.open);
  const reportType = useSelector(state => state.reportsTypesModal.reportType);
  const reportSelected = useSelector(state => state.reportsTypesModal.reportSelected);
  const user = useSelector(state => state.securityRedux.user);
  const reportTypeOptions = useReportTypeOptions(user)?.reportTypeOptions;
  const validatedPermissionsReportType = validateReportTypePermissions(reportTypeOptions);
  const formTypeSetup = useRef();
  const { onValidSubmit } = props;
  const onToggleModal = () => {
    dispatch(reportsOpenModalReportType(!open));
  }

  const renderItems = () => {
    const reports = validatedPermissionsReportType.find(reportTypeItem => reportTypeItem.id === reportType);
    const filterBy = (term) => {
      const termLowerCase = term?.toLowerCase()
      return (person) =>
        Object.keys(person)
          .some(prop => {
            if(person[prop]?.toLowerCase){
              return person[prop]?.toLowerCase().indexOf(termLowerCase) !== -1
            }
            return;
          })
    }

    const options = reports?.options?.filter(filterBy(search))

		return (
			<Grid
        container
        direction={"column"}
        style={{position: 'relative', marginBottom: '200px'}}
      >
				{options?.map((report, key) => {
					return (
						<Grid
							container
              item
							direction="row"
              alignItems="flex-start"
							className={'content-item-report-type mt-20 cursor-pointer'}
							key={key}
						>
							<Grid
                container
								onClick={() => {
                  dispatch(reportModalSelectReport(report.id === reportSelected ? null : report.id))
								}}
							>
								<Grid
                  container
                  item
                  justifyContent='space-between'
                >
                  < Grid
                    item
                    style={{minHeight: "30px"}}
                  >
                    <span className="report-type-item-title">{report.name}</span>
                  </Grid>
                  < Grid
                    item
                  >
                     <CheckCircleIcon style={{display: reportSelected == report.id ? 'initial': 'none' }} className="report-item-selected-icon"/>
                  </Grid>
                </Grid>

								< Grid
                  item
                >
									<p style={{ fontSize: '14px', marginTop: '2px', marginBottom: '5px' }}>
										{report.info}
									</p>
								</Grid>
							</Grid>
						</Grid>
					);
				})}
			</Grid>
		);
	};

  return (
    <>
      <SidePanel sidebarShow={false} isOpen={open} toggle={onToggleModal} classSidePanel={open && 'report-side-panel'} hiddenButton={true} style={{zIndex: 3, display: (open ? "initial": "none")}}>
        <SidePanelHeader>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{paddingBottom: '10px'}}>
            <Grid item sm={12}>
              <h2 style={{ margin: 0, fontSize: '19px' }}>{messages['reportTypesModal_title']}</h2>
            </Grid>
            <div className='report-types-line'/>
          </Grid>
        </SidePanelHeader>
        <SidePanelBody
          className={
            cx(
              'contentModal-reportSetup'
            )
          }
        >
          <Form
            ref={formTypeSetup}
            onSubmit={() => {
              return true;
            }}
            onValidSubmit={(type) => {
              setSearch("");
              onValidSubmit(reportSelected);
              dispatch(reportsOpenModalReportType(false));
              
            }}
          >
            <Grid container spacing={1} style={{marginTop: "5px"}}>
              {
                validatedPermissionsReportType.map((newOption, index) => {
                    return (
                        <Grid item xs={newOption?.id === 'legacymaintenance' ? 6: 4} key={index} >
                            <Pill
                                key={index}
                                textField="label"
                                item={newOption}
                                outlined={newOption?.id != reportType}
                                onClick={() => {
                                  setSearch("")
                                  dispatch(reportModalSelectType(newOption?.id))
                                }}
                                style={{ border: '0', width: '100%' }}
                                colorField="default"
                                colorSelection="66bb6a"
                            />
                        </Grid>
                    )
                })
              }
            </Grid>
            <Grid container justifyContent={'center'} direction={'column'} className="search-report-type-item">
              <InputSearch
                onSearch={(txt) => {
                  setSearch(txt);
                }}
                onSearchWhenFinish={(txt) => {
                  // getDriverTasks(txt);
                }}
                searchWhenFinishedTyping={true}
                searchText={search}
              />
              {renderItems()}
            </Grid>
          </Form>
        </SidePanelBody>
        <SidePanelFooter className={'reports-setup-item-type-footer'} >
          <Button
            className="btn-cancel"
            onClick={() => {
              onToggleModal()
            }}
          >
            {messages['reportSetup_cancel']}
          </Button>
          <Button
            onClick={() => {
              formTypeSetup.current.submit()
            }}
            className="btn-blue"
            disabled={!reportSelected}
          >
            {messages["choose"]}
          </Button>
        </SidePanelFooter>
      </SidePanel>
    </>
  )
}

export default ReportTypes;