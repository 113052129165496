import {
  //TYPES
  MAP_LANDMARK_MANAGE_SHOW_MODAL,
  MAP_LANDMARK_VISIBLE_SHOW_MODAL,
  MAP_LANDMARK_GROUP_DELETE_SHOW_MODAL,
  MAP_LANDMARK_GROUP_DELETE,
  MAP_LANDMARK_GROUP_DELETE_RESPONSE,
  MAP_LANDMARK_GROUP_EDIT_SHOW_MODAL,
  MAP_LANDMARK_GROUP_EDIT,
  MAP_LANDMARK_GROUP_EDIT_RESPONSE,
  MAP_LANDMARK_GROUP_NEW_SHOW_MODAL,
  MAP_LANDMARK_GROUP_NEW,
  MAP_LANDMARK_GROUP_NEW_RESPONSE,
  MAP_LANDMARK_BULK_DELETE_SHOW_MODAL,
  MAP_LANDMARK_BULK_EDIT,
  MAP_LANDMARK_BULK_EDIT_RESPONSE,
  MAP_LANDMARK_BULK_EDIT_SHOW_MODAL,
  MAP_LANDMARK_CREATE_SELECT_TYPE,
  MAP_LANDMARK_GROUP_HIDE,
  MAP_LANDMARK_NEW,
  MAP_LANDMARK_NEW_RESPONSE,
  MAP_LANDMARK_RESTART_PROCESS,
  MAP_LANDMARK_BULK_DELETE,
  MAP_LANDMARK_BULK_DELETE_RESPONSE,
  MAP_LANDMARK_EDIT_SHOW_MODAL,
  MAP_LANDMARK_DELETE_SHOW_MODAL,
  MAP_LANDMARK_GET_LANDMARK_GROUPS,
  MAP_LANDMARK_GET_LANDMARK_GROUPS_RESPONSE,
  MAP_LANDMARK_GET_LANDMARKS,
  MAP_LANDMARK_GET_LANDMARKS_RESPONSE,
  MAP_LANDMARK_UPDATE_ADD_LANDMARK_GROUP
} from 'Redux/actionTypes';

export const showModalManageLandmark = (show) => {
  return {
    type: MAP_LANDMARK_MANAGE_SHOW_MODAL,
    payload: show
  };
}

export const showModalVisibleLandmark = (show) => {
  return {
    type: MAP_LANDMARK_VISIBLE_SHOW_MODAL,
    payload: show
  };
}

export const showModalNewLandmarkGroup = (show) => {
  return {
    type: MAP_LANDMARK_GROUP_NEW_SHOW_MODAL,
    payload: { show: show }
  };
}

export const showModalEditLandmarkGroup = (show, params = null) => {
  return {
    type: MAP_LANDMARK_GROUP_EDIT_SHOW_MODAL,
    payload: { show: show, landmarkGroupItem: params }
  };
}

export const updateLandmarkGroup = (params = {}) => {
  return {
    type: MAP_LANDMARK_GROUP_EDIT,
    payload: { ...params }
  };
}

export const updateLandmarkGroupResult = (result, error = '') => ({
  type: MAP_LANDMARK_GROUP_EDIT_RESPONSE,
  payload: { result, error },
});

export const createLandmarkGroup = (params = {}) => {
  return {
    type: MAP_LANDMARK_GROUP_NEW,
    payload: { ...params }
  };
}

export const createLandmarkGroupResult = (result, error = '') => ({
  type: MAP_LANDMARK_GROUP_NEW_RESPONSE,
  payload: { result, error },
});

export const showModalDeleteLandmarkGroups = (show, items) => {
  return {
    type: MAP_LANDMARK_GROUP_DELETE_SHOW_MODAL,
    payload: { show, items }
  };
}

export const showModalBulkDeleteLandmark = (show, params = null) => {
  return {
    type: MAP_LANDMARK_BULK_DELETE_SHOW_MODAL,
    payload: { show: show, landmarkItemsSelected: params }
  };
}

export const showModalBulkEditLandmark = (show, params = null) => {
  return {
    type: MAP_LANDMARK_BULK_EDIT_SHOW_MODAL,
    payload: { show: show, landmarkItemsSelected: params }
  };
}

export const showModalEditLandmark = (show, params = null) => {
  return {
    type: MAP_LANDMARK_EDIT_SHOW_MODAL,
    payload: { show: show, landmarkItemsSelected: params }
  };
}

export const showModalDeleteLandmark = (show, params = null) => {
  return {
    type: MAP_LANDMARK_DELETE_SHOW_MODAL,
    payload: { show: show, landmarkItemsSelected: params }
  };
}

export const deleteLandmarkGroups = (params) => {
  return {
    type: MAP_LANDMARK_GROUP_DELETE,
    payload: { ...params } // id, newId
  };
}

export const deleteLandmarkGroupsResult = (result) => ({
  type: MAP_LANDMARK_GROUP_DELETE_RESPONSE,
  payload: { result },
});

export const selectCreateClickMapType = (type = 0, maxPoints = 0) => {
  return {
    type: MAP_LANDMARK_CREATE_SELECT_TYPE,
    payload: { type: type, maxPoints: maxPoints }
  }
};

export const hideLandmarkGroups = (params) => {
  return {
    type: MAP_LANDMARK_GROUP_HIDE,
    payload: params 
  };
}

export const createLandmarkMap = (params = {}, updateKml = false) => {
  return {
    type: MAP_LANDMARK_NEW,
    payload: { ...params },
    updateKml: updateKml
  };
}

export const createLandmarkMapResult = (result, error = '') => ({
  type: MAP_LANDMARK_NEW_RESPONSE,
  payload: { result, error },
});

export const landmarkRestarProcess = () => ({
  type: MAP_LANDMARK_RESTART_PROCESS
});

export const editLandmarkBulk = (params) => {
  return {
    type: MAP_LANDMARK_BULK_EDIT,
    payload: params
  };
}

export const editLandmarkBulkResult = (result, error = '') => ({
  type: MAP_LANDMARK_BULK_EDIT_RESPONSE,
  payload: { result, error },
});

export const deleteLandmarkBulk = (params) => {
  return {
    type: MAP_LANDMARK_BULK_DELETE,
    payload: { landmarksToDelete: params }
  };
}

export const deleteLandmarkBulkResult = (result, error = '') => ({
  type: MAP_LANDMARK_BULK_DELETE_RESPONSE,
  payload: { result, error },
});

export const mapLandmarkGetLandmarkGroups = () => ({
  type: MAP_LANDMARK_GET_LANDMARK_GROUPS,
});

export const mapLandmarkGetLandmarkGroupsResponse = (response) => ({
  type: MAP_LANDMARK_GET_LANDMARK_GROUPS_RESPONSE,
  payload: { response },
});




/************************************************************************************************
 * @deprecated landmarks now is loading for JSON Files, review this return params 
 * for tags after the use.
 * 
 * testing this method before the use.
 * 
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} openSearch 
 * @param {*} groupId 
 * @param {*} clearLandmarks 
 * @returns data to landmarks.
 ***********************************************************************************************/
export const searchMapLandmarks = (limit = 5000, offset = 0, openSearch = '', groupId = null, clearLandmarks = false) => ({
    type: MAP_LANDMARK_GET_LANDMARKS,
    payload: { offset, limit, openSearch, groupId, clearLandmarks },
}); 
      
export const searchLandmarksMapResult = (data, total) => ({
    type: MAP_LANDMARK_GET_LANDMARKS_RESPONSE,
    payload: { data, total },
});

export const updateLandmarkGroupInRedux = (action, landmarkGroup) => ({
    type: MAP_LANDMARK_UPDATE_ADD_LANDMARK_GROUP,
    payload: { action, landmarkGroup }
});