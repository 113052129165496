import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

//STATE INITIAL
const INIT_STATE = {
    loadingScheduledTasksLogs: false,
    scheduledTasksLogs: [],
    error: null,
}

const route = "modules/maintenance/views/driverDetail/.components/scheduleTasksLog/redux/slice";

export const maintenanceDDSDLTSearchDriverScheduledTasks = createAsyncThunk("maintenance/driverDetail/ScheduleTasksLogTab/searchDriverScheduledTasks", async ({ openSearch, scheduledTaskId, driverId }, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientQuery(
            `
            query searchDriverScheduledTaskLogs ($openSearch: String, $scheduledTaskId: Int, $driverId: Int) {
				data: searchDriverScheduledTaskLogs (openSearch: $openSearch, scheduledTaskId: $scheduledTaskId, driverId: $driverId) {
					id
					scheduledTaskId
					scheduledTaskName
					logDate
					actualCost
					startDate
					dueDate
					estimatedCost
					notes
					completed
					nextDueDate
					fileIds
					createdBy
					updatedOn
					updatedBy
				}
			}
            `,
            {
                openSearch,
                scheduledTaskId,
                driverId,
            },
            endpoints.GRAPHQL_GENERAL
        );
        return response.data
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceMCDTGetMaintenanceDriverTask');
        return rejectWithValue(exc);
    }
}
)

export const maintenanceScheduleTasksLogsTabRedux = createSlice({
    name: 'maintenance/driverDetail/ScheduleTasksLogsTab',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {

        //maintenanceDDSDLTSearchDriverScheduledTasks
        builder.addCase(maintenanceDDSDLTSearchDriverScheduledTasks.pending, (state, action) => {
            state.loadingScheduledTasksLogs = true
        });

        builder.addCase(maintenanceDDSDLTSearchDriverScheduledTasks.fulfilled, (state, action) => {
            state.scheduledTasksLogs = action.payload
            state.loadingScheduledTasksLogs = false;
        });

        builder.addCase(maintenanceDDSDLTSearchDriverScheduledTasks.rejected, (state, action) => {
            state.loadingScheduledTasksLogs = false;
            state.error = action.payload;
        });
    }
})

const { actions, reducer } = maintenanceScheduleTasksLogsTabRedux;

// Extract and export each action creator by name
export const {
    reset,
} = actions;

export default reducer;