import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    createDriverTaskResponse: null,
    error: null,
    updateDriverTaskResponse: null,
    driverTasks: null,
    loadingDriverTasks: false,
    taskId: null,
};

const route = "modules/maintenance/modals/createEditDriverTask/redux/slice";

export const maintenanceMCDTGetMaintenanceDriverTask = createAsyncThunk("maintenance/createEditDriverTasks/getMaintenanceDriverTask", async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientQuery(
            `
                query ($id: Int!){
                    data: getMaintenanceDriverTask(id: $id){
                    id
                    name
                    description
                    frequency
                    timeUnit
                    estimatedCost
                    fileIds
                    files {
                        id
                        originalName
                        fileKey
                        repositoryCode
                    }
                    }
                }
            `,
            {  
                id
            },
            endpoints.GRAPHQL_GENERAL
        );
        return response.data
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceMCDTGetMaintenanceDriverTask');
        return rejectWithValue(exc);
    }
}
)

export const maintenanceMCDTCreateMaintenanceDriverTask = createAsyncThunk("maintenance/createEditDriverTasks/createMaintenanceDriverTask", async ({ name, description, frequency, timeUnit, estimatedCost, fileIds }, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientMutation(
            `
                mutation ($name: String! $description: String $frequency: Int! $timeUnit: enumTimeUnit! $estimatedCost: Float! $fileIds: [Int]) {
                    data: createMaintenanceDriverTask (name: $name description: $description frequency: $frequency timeUnit: $timeUnit estimatedCost: $estimatedCost fileIds: $fileIds) {
                        id
                        name
                        description
                        frequency
                        timeUnit
                        estimatedCost
                        fileIds
                        files {
                            id
                            originalName
                            fileKey
                            repositoryCode
                        }
                    }
                }
            `,
            {  
                name,
				description,
				frequency,
				timeUnit,
				estimatedCost,
				fileIds
            },
            endpoints.GRAPHQL_GENERAL
        );
        return response.data
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceCreateDriverTask');
        return rejectWithValue(exc);
    }
}
)

export const maintenanceMCDTUpdateMaintenanceDriverTask = createAsyncThunk("maintenance/createEditDriverTasks/updateMaintenanceDriverTask", async ({ id, name, description, frequency, timeUnit, estimatedCost, fileIds }, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientMutation(
            `
                mutation ($id: Int! $name: String! $description: String $frequency: Int! $timeUnit: enumTimeUnit! $estimatedCost: Float! $fileIds: [Int]) {
                    data: updateMaintenanceDriverTask (id: $id name: $name description: $description frequency: $frequency timeUnit: $timeUnit estimatedCost: $estimatedCost fileIds: $fileIds) {
                        id
                        name
                        description
                        frequency
                        timeUnit
                        estimatedCost
                        fileIds
                    }
                }
            `,
            {
                id: id || -1,
                name,
                description,
                frequency,
                timeUnit,
                estimatedCost,
                fileIds
            },
            endpoints.GRAPHQL_GENERAL
        );
        return response.data
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceMCDTUpdateMaintenanceDriverTask');
        return rejectWithValue(exc);
    }
}
)

export const maintenanceCreateDriverTasksRedux = createSlice({
    name: 'maintenance/createEditDriverTasks',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openModalCreateDriverTasks(state, action) {
            return {
                ...state,
                open: action.payload.open,
                taskId: action.payload.taskId
            }
        },
    },
    extraReducers: (builder) => {

        //maintenanceCreateDriverTask
        builder.addCase(maintenanceMCDTCreateMaintenanceDriverTask.pending, (state, action) => {
            state.createDriverTaskResponse = [];
            state.loading = true
        });

        builder.addCase(maintenanceMCDTCreateMaintenanceDriverTask.fulfilled, (state, { payload }) => {
            state.createDriverTaskResponse = payload
            state.loading = false;
        });

        builder.addCase(maintenanceMCDTCreateMaintenanceDriverTask.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //maintenanceMCDTUpdateMaintenanceDriverTask
        builder.addCase(maintenanceMCDTUpdateMaintenanceDriverTask.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceMCDTUpdateMaintenanceDriverTask.fulfilled, (state, { payload }) => {
            state.updateDriverTaskResponse = payload
            state.loading = false;
        });

        builder.addCase(maintenanceMCDTUpdateMaintenanceDriverTask.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //maintenanceMCDTGetMaintenanceDriverTask
        builder.addCase(maintenanceMCDTGetMaintenanceDriverTask.pending, (state, action) => {
            state.driverTasks = null;
            state.loading = true
        });

        builder.addCase(maintenanceMCDTGetMaintenanceDriverTask.fulfilled, (state, action) => {
            state.driverTasks = action.payload
            state.loading = false;
        });

        builder.addCase(maintenanceMCDTGetMaintenanceDriverTask.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
})

const { actions, reducer } = maintenanceCreateDriverTasksRedux;

// Extract and export each action creator by name
export const {
    reset,
    openModalCreateDriverTasks,
} = actions;

export default reducer;