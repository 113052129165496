import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';
// Actions
import {reportsMainGenerateReportProgress} from 'Redux/actions';
import {
  baseReportConditions,
  generateReportInstance,
  getTimeZone,
  handleAsyncReport,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getAdvancedSummaryReport(report, page, rowsPerPage, sortField, sortOrder) {
  const { timeZone, sendByEmail, emailsTo, name, delayed, timeframeName, categoryName, dataResultPath } = report;
  const { filterType, filterArray, startRange, endRange, unitsSelected, accumulateData = true, dates } = report.filters
  const baseConditionsQuery = baseReportConditions({
    report,
    name,
    dataResultPath,
    timeZone,
    delayed,
    unitsSelected,
    timeframeName,
    filterType,
    filterArray,
    categoryName,
    startRange,
    endRange,
    dates
  })
  try {
    let payload = {
      conditions: {
        ...baseConditionsQuery, sortField: {
          field: sortField || "deviceId",
          order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
      }, limit: rowsPerPage, offset: page
    };

    payload = {
      ...payload,
      limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
      offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
      conditions: {
        ...payload.conditions,
        sortField: [
          {
            "field": sortField || "deviceId",
            "order": "ASC"
          },
          {
            "field": "eventDate",
            "order": "ASC"
          }],
        exportOptions: {
          sendByEmail,
          emailsTo,
          culture: report.lang,
          timeZone: getTimeZone(timeZone),
          format: {
            fileFormat: report?.exportType || "json",
            formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
            durationFormat: report?.durationFormat
          },
          folderName: "px-customer-reports",
          filePrefixName: "advancedSummaryReport",
          columns: report?.exportType === "pdf" ?
             [
               {
                 "column": "label",
                 "header": "Category"
               },
               {
                 "column": "description",
                 "header": "Totals"
               },
               {
                 "column": "none",
                 "header": " "
               },
               {
                 "column": "none",
                 "header": " "
               },
               {
                 "column": "none",
                 "header": " "
               },
               {
                 "column": "none",
                 "header": " "
               },
               {
                 "column": "none",
                 "header": " "
               },
               {
                 "column": "none",
                 "header": " "
               },
               {
                 "column": "none",
                 "header": " "
               },
               {
                 "column": "none",
                 "header": " "
               },
               {
                 "column": "none",
                 "header": " "
               },
               {
                 "column": "none",
                 "header": " "
               }
             ] :
             [
               {
                 "column": "label",
                 "header": "Label"
               },
               {
                 "column": "driveTime",
                 "header": "Drive Time"
               },
               {
                 "column": "stopTime",
                 "header": "Stop Time"
               },
               {
                 "column": "stops",
                 "header": "# Stops"
               },
               {
                 "column": "averageStopTime",
                 "header": "Average Stop Time"
               },
               {
                 "column": "idleTime",
                 "header": "Idle Time"
               },
               {
                 "column": "idles",
                 "header": "# Idles"
               },
               {
                 "column": "averageIdleTime",
                 "header": "Average Idle Time"
               },
               {
                 "column": "startOdometer",
                 "header": "Start Odometer"
               },
               {
                 "column": "endOdometer",
                 "header": "End Odometer"
               },
               {
                 "column": "distance",
                 "header": "Distance"
               }
             ]
        }
      },
    }

    let data = yield call(
       clientQuery,
       `query getAdvancedSummaryReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getAdvancedSummaryReport (conditions: $conditions, limit: $limit, offset: $offset) {
          ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ? `reportInstanceId` : `
            items {
              recordInfo {
                entityId
                entityLabel
                tagId
                tagLabel
                startDate
                endDate
                recordType
                groupLabel
                groupIndex
                groupTotal
                groupTagColor
              },
              recordDetail {
                label
                driveTime
                stopTime
                stops
                averageStopTime
                idleTime
                idles
                averageIdleTime
                startOdometer
                endOdometer
                distance
                isKPH
                noData
              }
            }
            total
            limit
            offset
            category
          `}
        }
      }`, payload, endpoints.GRAPHQL_PX_REPORTING,);
    yield handleAsyncReport(report, data, accumulateData);
  } catch (exc) {
    ExceptionManager(exc, '/reports/advancedSummaryReport/sagaFunction', 'getAdvancedSummaryReport');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
  }
}

export default getAdvancedSummaryReport;
