import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';
// Actions
import {
  reportsMainGenerateReportProgress,
  reportsMainGenerateReportUpdate
} from 'Redux/actions';

import {
  getTimeZone,
  getCategoryFilter,
  handleAsyncReport,
  baseReportConditions,
  generateReportInstance,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getFormStopReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
  const { timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath } = report;
  const { filterType, filterArray, startRange, endRange, unitsSelected,otherFilters } = report.filters
  const baseConditionsQuery = baseReportConditions({ report, name, dataResultPath, timeZone, delayed, unitsSelected, timeframeName, filterType, filterArray, categoryName, startRange, endRange })
  try {
    let payload = {
      conditions: {
        ...baseConditionsQuery,
        integerRanges: {
          fields: [
            "stop"
          ],
          GTE: otherFilters.minute || 0,
          LTE: 0
        },
        stringValues: {
          values: otherFilters?.form
        },
        sortField: {
          field: sortField || "deviceId",
          order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
      },
      limit: rowsPerPage,
      offset: page
    };

    if (report.export) {
      payload = {
        ...payload,
        limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 1000,
        offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
        conditions: {
          ...payload.conditions,
          exportOptions: {
            sendByEmail,
            emailsTo,
            format: {
              fileFormat: report?.exportType || "json",
              formatOptions: "{\"orientation\": \"landscape\"}"
            },
            culture: report.lang,
            timeZone: getTimeZone(timeZone),
            folderName: "px-customer-reports",
            filePrefixName: "formStopSummaryReport",
            columns: [
              {
                "column": "label",
                "header": "Label"
              },
              {
                "column": "event",
                "header": "Event"
              },
              {
                "column": "address",
                "header": "Address"
              },
              {
                "column": "duration",
                "header": "Duration"
              },
              {
                "column": "dateTime",
                "header": "Time & Date"
              },
              {
                "column": "form",
                "header": "Form"
              },
              {
                "column": "question",
                "header": "Question"
              },
              {
                "column": "answer",
                "header": "Answer"
              }
            ]
          }
        }
      }
    }
    let data = yield call(
      clientQuery,
      `query getFormStopReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getFormStopReport (conditions: $conditions, limit: $limit, offset: $offset) {
          ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
        `reportInstanceId`
        : `
            items {
              id
              driverName
              noData
            }
            total
            limit
            offset
            category
        `}
        }
        
      }`,
      payload,
      endpoints.GRAPHQL_PX_REPORTING,
    );
    yield handleAsyncReport(report, data);

  } catch (exc) {
    ExceptionManager(exc, 'reports/detailedReport', 'getFormStopReport');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
    if (!requestFlag) {
      let flag = true
      yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
    } else {
      report.loading = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  }
}


export default getFormStopReport;
