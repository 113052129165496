export default {
  "header_preferences": "Preferences",
  "header_logout": "Logout",
  "header_training": "Training",
  "header_schedule_training": "Schedule Live Training",
  "header_help": "Help Center",
  "header_changePassword": "Change password",
  "header_chatEnabled" : "Chat with Support",
  "header_chatDisabeld" : "Chat with Support - Offline",
  "header_alertIcon" : "Alerts",
  "header_personIcon" : "Profile",
  "header_incidentIcon" : "Incidents"

};