//View Keys
export default {
	entityDetails_subscribersNotFound: 'None',
	entityDetails_notesNotFound: 'None',
	entityDetails_attachmentsNotFound: 'None',
	entityDetails_deleteAttachment: 'Are you sure you want to delete the Attachment ',
	entityDetails_deleteSubscriber: 'Are you sure you want to delete the subscriber ',
	entityDetails_titleDeleteAttachment: 'Delete Attachment',
	entityDetails_titleDeleteSubscriber: 'Delete Subscriber',
	entityDetails_subscribers_label: 'Subscribers',
	entityDetails_subscribers_notes: 'Notes',
	entityDetails_subscribers_attachments: 'Attachments',
};