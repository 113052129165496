// Dependencies
import { all, put, takeEvery, fork, call } from 'redux-saga/effects';

// Logger
import { ExceptionManager } from 'Core/logManager';

// Local GraphQL
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';

// Action types
import {
    GET_GEOFENCES_GROUP,
    MAP_GEOFENCE_LIST_GETTAGS,
    MAP_GEOFENCE_LIST_GETTAGS_TOCREATE
} from 'Redux/actionTypes';

// Actions
import {
    getGeofenceGroupsResult,
    mapGetGeofenceTagsResponse,
    mapGetGeofenceTagsToCreateResponse
} from 'Redux/actions';

/**
 * Get the paginated landmarks according the given params.
 * @param { {payload: {offset, limit}}} params - The paginations params.
 */
function* getGeofenceGroupsRequest({ payload }) {
    const { openSearch } = payload;
    try {
        const query =
            `query searchTagsInline ($entityName: String, $limit: Int, $offset: Int) {
                result: searchTagsInline (entityName: $entityName, limit: $limit, offset: $offset) {
                      items {
                          id
                          label
                          color
                      }
                      total
                  }
              }`;
  
        let params = {
            openSearch,
            entityName: "Geofence",
        };
    
        if(!openSearch){
            delete params["openSearch"]
        }
    
        const data = yield call(clientQuery, query, params, endpoints.GRAPHQL_GENERAL);
        let { searchGeofenceGroups } = data;
        if (searchGeofenceGroups) {

            if(searchGeofenceGroups.items > 0){
                let geofencesTags = searchGeofenceGroups.items.map((tag) => {
                    return {
                        ...tag, 
                        name: tag.label
                    }
                })
                yield put(getGeofenceGroupsResult(geofencesTags));
            }else{
                yield put(getGeofenceGroupsResult([]));
            } 
            
        } else {
            const error = 'Error getting geofences groups'
            yield put(getGeofenceGroupsResult([], error));
            throw error;
        }
    
    } catch (exc) {
        const error = 'Error getting geofences groups'
        yield put(getGeofenceGroupsResult([], error));
        ExceptionManager(exc, 'src/modules/realtimeMaps/main/components/geofences/redux/saga.js', 'getGeofenceGroupsRequest') 
    }
};

function* getTagsInlineGeofenceList({ payload }) {
    const { entityName, getAllTags } = payload
    try {
        const sendParams = getAllTags ? {} : { entityName }

        const response = yield call(
            clientQuery,
                `query searchTagsInline ($entityName: String, $limit: Int, $offset: Int) {
                    result: searchTagsInline (entityName: $entityName, limit: $limit, offset: $offset) {
                        items {
                            id
                            label
                            color
                            parentId
                            path
                            level
                        }
                        total
                    }
                }`,
            { ...sendParams },
            endpoints.GRAPHQL_GENERAL,
        );
        if (response.result) {
            if(getAllTags){
                yield put(mapGetGeofenceTagsToCreateResponse(response.result.items?.map((element) => ({ ...element, name: element.label }))));
            }else{
                yield put(mapGetGeofenceTagsResponse(response.result.items?.map((element) => ({ ...element, name: element.label }))));
            }
            
        } else {
            if(getAllTags){
                yield put(mapGetGeofenceTagsToCreateResponse(null, e))
            }else{
                yield put(mapGetGeofenceTagsResponse(null))
            }
        }
    } catch (e) {
        if(getAllTags){
            yield put(mapGetGeofenceTagsToCreateResponse(null, e))
        }else{
            yield put(mapGetGeofenceTagsResponse(null, e))
        }
        ExceptionManager(e, 'modules/realtimeMaps/main/components/geofence/components/geofenceList/redux/saga', 'getTagsInlineGeofenceList');
    }
}


  
function* watchGeofencesRequest() {
    yield takeEvery(GET_GEOFENCES_GROUP, getGeofenceGroupsRequest),
    yield takeEvery(MAP_GEOFENCE_LIST_GETTAGS, getTagsInlineGeofenceList),
    yield takeEvery(MAP_GEOFENCE_LIST_GETTAGS_TOCREATE, getTagsInlineGeofenceList)
};
  
  
export default function* rootSaga() {
    yield all([fork(watchGeofencesRequest)]);
    /* yield all([fork(watchSearchLandmarksRequest)]); */
};