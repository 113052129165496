import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { NotificationManager } from 'Components/Notifications';
import { v4 as uuidv4 } from 'uuid';

// Action types
import { REALTIME_MAP_LAYER_MAP_GET, REALTIME_MAP_LAYER_ROUTES_GET, REALTIME_MAP_LAYER_ROUTE_GET } from 'Redux/actionTypes';

// Actions
import { getVehicleTrailLayerMapResult, getRoutesResponseLayerMap, getRouteResponseLayerMap } from 'Redux/actions';

// Function
function* getVehicleTrails({ payload }) {
  const { params } = payload
  const { unitId, event, startDate, endDate, color, messageError } = params
  
  let queryElements = { deviceId: unitId, event, startDate, endDate, limit: 100, offset: 0 }
  if (event == '0') {
    delete queryElements["event"]
  }

  try {
    let data = yield call(
      clientQuery,
      `query searchVehicleTrails ($startDate: AWSDateTime!, $endDate: AWSDateTime!, $event: VehicleTrailEvent, $deviceId: String!, $limit: Int, $offset: Int) {
        searchVehicleTrails (startDate: $startDate, endDate: $endDate, event: $event, deviceId: $deviceId, limit: $limit, offset: $offset) {
            items {
                id
                deviceId
                latitude
                longitude
                heading
                eventCode
                eventName
                address
                driverId
                driverName
                ignitionStatus
                satelliteCount
                signal
                speed
                speedLimit
                unitTime
            }
            total
        }
    }`,
      queryElements,
      endpoints.GRAPHQL_GENERAL,
    );
  
    if (data && data.searchVehicleTrails) {
      const { total } = data.searchVehicleTrails;
      if(total > 0){
        const vehicleTrailsData = {
          id: uuidv4(),
          color,
          ...data.searchVehicleTrails
        }
        yield put(getVehicleTrailLayerMapResult(vehicleTrailsData));
      }else{
        NotificationManager.error(messageError, 'Error', 3000, null, null, 'error');
        yield put(getVehicleTrailLayerMapResult({}));
      }
    }
  
    else throw 'Not Vehicle Trails';
} catch (e) {
  yield put(getVehicleTrailLayerMapResult([]));
  NotificationManager.error(messageError, 'Error', 3000, null, null, 'error');
}
}

function* getAllRoutes({ payload }) {
  try {

    const conditions = [{
      fields: ["isTemplate"],
      terms: [1]
    }];

    const sort = {
      field: "title",
      order: "ASC"
    };

    const { search, limit, offset } = payload.data;

    if(search){
      conditions.push({
        fields: ["title"],
        terms: search
      })

    }

    let data = yield call(
      clientQuery,
      `query searchRoutes (
        $conditions: [RouteSearchInput],
        $sort: [RouteSortInput], 
        $limit: Int, 
        $offset: Int
      ) {
        Routes: searchRoutes (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
          items {
              id
              title
              color
              optimized
              avoidToll
          }
          total
        }
    }`,
    {
      conditions: conditions,
      sort, 
      limit: limit || 200, 
      offset
    },
    endpoints.GRAPHQL_GENERAL_V2
    );

    if (data && data.Routes) {
      yield put(getRoutesResponseLayerMap(data.Routes));
    }
    else {
      NotificationManager.error("No routes available", "Error", 3000, null, null, "error");
      yield put(getRoutesResponseLayerMap({}));
    }
  } catch (exc) {
    ExceptionManager(exc, 'modules/realtimeMaps/main/components/LayerMap/redux/saga', 'getAllRoutes');
    yield put(getRoutesResponseLayerMap({}));
  }
}

function* getRoute({ payload }) {
  const { id } = payload
  try {
    let data = yield call(
      clientQuery,
      `query getRoute ($id: Int!) {
        Route: getRoute (id: $id) {
            id
            title
            color
            points {
              id
              routeId
              address
              city
              state
              latitude
              longitude
            }
        }
    }`,
      {
        id
      },
      endpoints.GRAPHQL_GENERAL_V2,
    );
    if (data && data.Route) {
      yield put(getRouteResponseLayerMap(data.Route));
    } else {
      NotificationManager.error("The route is not available", "Error", 3000, null, null, "error");
      yield put(getRouteResponseLayerMap({}));
    }
  } catch (error) {
    ExceptionManager(error, 'modules/realtimeMaps/main/components/LayerMap/redux/saga', 'getRoute');
    yield put(getRouteResponseLayerMap({}));
  }
}

function* watchGetVehicleTrailsRequest() {
  yield takeEvery(REALTIME_MAP_LAYER_MAP_GET, getVehicleTrails);
  yield takeEvery(REALTIME_MAP_LAYER_ROUTES_GET, getAllRoutes);
  yield takeEvery(REALTIME_MAP_LAYER_ROUTE_GET, getRoute);
}

//Saga Export
export default function* rootSaga() {
  yield all([
    fork(watchGetVehicleTrailsRequest)
  ]);
}