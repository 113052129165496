import React, { useEffect, useRef, useState } from "react";
import Modal, { ModalBody, ModalFooter } from "Components/Modal";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Input, Loading, Button } from "Components";
import { useDispatch } from "react-redux";

function ModalResetPwd({
  forgotPasswordConfirmClose,
  clearRedux,
  showConfirm,
  result,
  error,
  ErrorHandlerLogin,
  forgotPasswordConfirm,
  loading,
  username,
  setUsername
}) {
  const { messages } = useIntl();
  const frmForgotConfirm = useRef();
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(forgotPasswordConfirmClose());
    dispatch(clearRedux("FORGOT_PASSWORD", { result: false }));
  };

  const [confirmationCode, setConfirmationCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");


  useEffect(() => {
    if (result) {
      setUsername("");
      setConfirmationCode("");
      setPassword("");
      setPasswordConfirm("");
    }
  }, [result]);

  return (
    <Modal
      title={messages["forgotPassword_reset"]}
      open={showConfirm}
      handleClose={handleClose}
      size="small"
    >
      <ModalBody>
        <div style={{ padding: 20 }}>
          {error && (
            <div className="form-item card-error-login login-code-error">
              {messages[error] || ErrorHandlerLogin(error)}
            </div>
          )}
          {result ? (
            messages["forgotPassword_success"]
          ) : (
            <Form
              ref={frmForgotConfirm}
              onValidSubmit={() => {
                dispatch(forgotPasswordConfirm(
                  username,
                  confirmationCode,
                  password,
                  passwordConfirm
                ));
              }}
            >
              <div className="form-item">
                <Input
                  id="txtVerificationCode"
                  type="text"
                  label={messages["forgotPassword_verificationCode"]}
                  placeholder={messages["forgotPassword_verificationCode"]}
                  value={confirmationCode}
                  onChange={(text) => {
                    setConfirmationCode(text);
                  }}
                  required
                  requiredMessage={messages["required_field"]}
                  autoFocus
                />
              </div>
              <div className="form-item">
                <Input
                  id="txtPassword"
                  type="password"
                  label={messages["forgotPassword_newPassword"]}
                  placeholder={messages["forgotPassword_newPassword"]}
                  value={password}
                  onChange={(text) => {
                    setPassword(text);
                  }}
                  required
                  requiredMessage={messages["required_field"]}
                />
              </div>
              <div className="form-item">
                <Input
                  id="txtPasswordConfirm"
                  type="password"
                  label={messages["forgotPassword_ConfirmNewPassword"]}
                  placeholder={messages["forgotPassword_ConfirmNewPassword"]}
                  value={passwordConfirm}
                  onChange={(text) => {
                    setPasswordConfirm(text);
                  }}
                  required
                  requiredMessage={messages["required_field"]}
                />
              </div>
            </Form>
          )}
        </div>
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <div className="loading-area">
            <Loading />
          </div>
        ) : (
          <>
            {result ? (
              <Button
                id="btnContinueConfirm"
                type="link"
                url="/login"
                className="btn btn-blue"
              >
                <FormattedMessage id="continue" />
              </Button>
            ) : (
              <>
                <Button
                  id="btnCloseConfirm"
                  onClick={handleClose}
                  className="btn-cancel"
                >
                  {messages["close"]}
                </Button>
                {"  "}
                <Button
                  id="btnConfirm"
                  onClick={(e) => {
                    e.preventDefault();
                    frmForgotConfirm.current.submit();
                  }}
                  className="btn-blue"
                >
                  {messages["forgotPassword_verify"]}
                </Button>
              </>
            )}
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default ModalResetPwd;
