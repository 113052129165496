// Dependencies
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Grid, IconButton } from '@mui/material';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { TimePickerInputs } from "@gpstrackit/gtc-react-controls";
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import Typography from '@mui/material/Typography';

// Components
import InputDate from 'Components/InputDate';
import moment from "moment";
import './index.scss';

const CustomTime = ({
    value,
    day,
    checkOneDay,
    handleTimeChange,
    addDayCallback,
    removeDayCallback,
    validRange,
    overlappingDatesErrorMessage,
    isValidDay,
    invalidDayMessage,
    disableAddChange,
    validateCustomTimeRanges,
    isSimple
    }) => {

    const { messages } = useIntl();

    useEffect(() => {
        validateCustomTimeRanges(validRange && isValidDay,false,true)
    }, [])


    /**
     * Builds the days right button according the given day.
     * @param {Object} day - The day to show.
     * @returns {React.Node} The built button (add or subtract button).
     */
    const rightButton = (day) => {
        const { parentDay } = day;
        const IconComponent = parentDay ? AddCircleOutlinedIcon : RemoveCircleOutlinedIcon;

        return (
            <IconButton
                aria-label="delete"
                className={'CustomTime-margin'}
                size="small"
                onClick={() => {
                    if (!disableAddChange)
                        return parentDay ? addDayCallback(day) : removeDayCallback(day);
                }}
            >
                <IconComponent fontSize="medium" />
            </IconButton>
        )
    };

    return (
        <Grid
            item
            sm={12}
            style={{borderLeft: (validRange && isValidDay) ? '.5px transparent solid' : '.5px red solid',padding: '5px'}}
        >
            <Grid
                container
                className={'CustomTime-dateContainer'}
                justifyContent={'flex-start'}
                sx={{columnGap: '3px',flexWrap: 'nowrap'}}
            >
                <Grid item sm={2}>
                    <div className="form-item">
                        <label className="app-radio-button ">
                            <input
                                type='checkbox'
                                name='type'
                                checked={day.checked}
                                onChange={() => { checkOneDay(value) }}
                            />
                            <div className='app-radio-button-box'>
                                <span className='app-radio-button-box-type pt-2'>{messages[day.day]}</span>
                            </div>
                        </label>
                    </div>
                </Grid>
                <Grid item sm={5} sx={{marginRight: '4px'}}>
                    <TimePickerInputs
                        isDisabled={!day.checked || disableAddChange}
                        style={{
                            justifyContent: 'space-around',
                        }}
                        className="customTimePickerRanges"
                        value={moment(day.timeFrom || null)}
                        onChange={(date) => {
                            handleTimeChange(date.toDate(), 'timeFrom');
                        }}
                    />
                </Grid>
                <Grid item sm={5}>
                    <TimePickerInputs
                        style={{
                            justifyContent: 'space-around',
                        }}
                        isDisabled={!day.checked || disableAddChange}
                        className="customTimePickerRanges"
                        value={moment(day.timeTo || null)}
                        onChange={(date) => {
                            handleTimeChange(date.toDate(), 'timeTo');
                        }}
                    />
                </Grid>
                { 
                    !isSimple && rightButton(day)
                }
            </Grid>
            {!validRange && (
                <Typography variant="caption" display="block" gutterBottom color="error">
                    {overlappingDatesErrorMessage}
                </Typography>
            )}
            {!isValidDay && (
                <Typography variant="caption" display="block" gutterBottom color="error">
                    {invalidDayMessage}
                </Typography>
            )}
        </Grid>
    )
}

export default CustomTime;
