import {
  ENTITY_PICKER_GET_ENTITY_LIST,
  ENTITY_PICKER_GET_ENTITY_LIST_RESPONSE,
  CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
  error: {},
  openSearch: {},
  data: {},
  loading: {},
  total: {}
};

export default (state = INIT_STATE, action) => {
  let key;
  switch (action.type) {
    
    case ENTITY_PICKER_GET_ENTITY_LIST:
      key = action?.payload?.data?.id;
      return { ...state, loading: { ...state.loading, [key]: true }, error: { ...state.error, [key]: false } };

    case ENTITY_PICKER_GET_ENTITY_LIST_RESPONSE:
      const { data: result } = action.payload;
      const { id, error, data, total, openSearch } = result;
      key = id;
      const newData = ((openSearch && total < state.data[key]?.length) || openSearch != state.openSearch[key]) ? data : [ ...state.data[key], ...data ]

      return { 
        ...state, 
        openSearch: { ...state.openSearch, [key]: openSearch },
        data: { ...state.data, [key]: newData},
        total: { ...state.total, [key]: total }, 
        loading: { ...state.loading, [key]: false },
        error: { ...state.error, [key]: error }
      };

    case CLEAR_REDUX:
      return ["", "ENTITY_PICKER"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
}