export default {
  "nearest_vehicle": "Vehículo",
  "nearest_equipment": "Equipo",
  "nearest_fromTitle": "Encontrar más cercano",
  "nearest_selectFrom": "Por favor seleccione una opción...",
  "nearest_toTitle": "A:",
  "nearest_find": "Buscar",
  "nearest_show": "Mostrar en el mapa",
  "nearest_directions": "Obtener indicaciones",
  "nearest_clear": "Limpiar",
  "nearest_error": "Ha ocurrido un error, por favor intente de nuevo.",
  "nearest_vehicleDirections": "Indicaciones",
  "nearest_optimizeRoute": "Optimizar Ruta",
  "nearest_avoidTolls": "Evitar Peajes",
  "nearest_avoidHighways": "Evitar Autopistas",
  "nearest_landmark": "Punto de referencia (Landmark)",
  "nearest_equipmentWithoutUnitError": "El equipo seleccionado no tiene una unidad asignada",
  "nearest_miles": "Millas",
  "nearest_kilometers": "Km",
}