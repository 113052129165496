import React from 'react';
// Material UI
import { Grid, Typography } from '@mui/material';
import {
  copyToClipboard
} from 'Core/data/Helpers'
import { Button } from 'Components';

import { ColorsToRoutePointsOnRoutesTracker } from '../../../../../components/Map/providers/enums'

// Styles
import '../infoBubble.scss';
import '../../../../../components/Map/providers/google/InfoWindow/style.scss'
import { useSelector } from 'react-redux';

const RoutePointInfo = (props) => {
  const { data, messages } = props;

  const isKph = useSelector((state) => state.securityRedux.user.isKph);

  let coordinates = `${data?.markerDetail?.routePoint?.latitude?.toFixed(6)}, ${data?.markerDetail?.routePoint?.longitude?.toFixed(6)}` || "N/A"
  
  //get Radius
  const valueRadius = data?.markerDetail?.routePoint?.radius
  const routePointRadius = `${isKph ? parseFloat(valueRadius * 0.001).toFixed(2) : parseFloat(valueRadius * 0.000621371).toFixed(2)} ${isKph ? messages['kilometers'] : messages['miles']}`;

  let address = data?.markerDetail?.routePoint?.address
  address = address.includes(',') ? address : `${data?.markerDetail?.routePoint?.address}, ${data?.markerDetail?.routePoint?.city}, ${data?.markerDetail?.routePoint?.state}`

  const statusObject = ColorsToRoutePointsOnRoutesTracker.find((item) => item.name == data?.markerDetail?.routePoint?.status);
                    
    /* "landmarkId": 0,
    "sequence": 0,
    "note": "", */

  return (
    <div className='info-window-tab-streetview'>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          padding: '16px 45px 16px 16px'
        }}
      >
        <Grid item xs zeroMinWidth>
          <Typography noWrap style={{ fontWeight: 'bold' }}>
            {data?.markerDetail?.routePoint?.name || data?.markerDetail?.routePoint?.address}
          </Typography>
        </Grid>
      </Grid>

      <div style={{ borderTop: 'solid 1px #e4e4e4' }}></div>

      <div>
        <table className="info-bubble-table">
          <tbody>

            <tr>
              <td>
                {messages['infoBubble_status']}
              </td>
              <td>
                <div className="hover-container">
                    <span style={{ paddingRight: 8, color: statusObject.name != 'Pending' ? statusObject.color : '' }}>
                      {messages[statusObject.messages]}
                    </span>
                    <Button
                        type="icon"
                        icon="content_copy"
                        onClick={() => copyToClipboard(messages[statusObject.messages])}
                        fontSize="inherit"
                        className="btn-no-padding hover-item"
                    />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                {messages['infoBubble_latLng']}
              </td>
              <td>
                <div className="hover-container">
                  <span style={{ paddingRight: 8 }}>
                    {coordinates}
                  </span>
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(coordinates)}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                {messages['infoBubble_type']}
              </td>
              <td>
                <div className="hover-container">
                    <span style={{ paddingRight: 8 }}>
                      {'Stop'}
                    </span>
                    <Button
                        type="icon"
                        icon="content_copy"
                        onClick={() => copyToClipboard('Stop')}
                        fontSize="inherit"
                        className="btn-no-padding hover-item"
                    />
                </div>
              </td>
            </tr>

            {
              data?.markerDetail?.routePoint?.radius > 0 &&
              <tr>
                <td>
                  {messages['infoBubble_radius']}
                </td>
                <td>
                  <div className="hover-container">
                    <span style={{ paddingRight: 8 }}>
                      {routePointRadius}
                    </span>
                    <Button
                      type="icon"
                      icon="content_copy"
                      onClick={() => copyToClipboard(routePointRadius)}
                      fontSize="inherit"
                      className="btn-no-padding hover-item"
                    />
                  </div>
                </td>
              </tr>
            }

            {
                data?.markerDetail?.routePoint?.name &&
                <tr>
                <td>
                    {messages['infoBubble_address']}
                </td>
                <td>
                    <div className="hover-container">
                        <span style={{ paddingRight: 8 }}>
                        {address}
                        </span>
                        <Button
                            type="icon"
                            icon="content_copy"
                            onClick={() => copyToClipboard(address)}
                            fontSize="inherit"
                            className="btn-no-padding hover-item"
                        />
                    </div>
                </td>
                </tr>
          }

            
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default RoutePointInfo;