import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import Datepicker, { CalendarContainer } from 'react-datepicker';
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
import { setCalendarScrollParent } from "Redux/actions";
import moment from "moment";
import cx from 'classnames';
import { Icon } from '@mui/material'

import AreaMenu, { Area, Menu } from 'Components/AreaMenu';
import { Select } from 'Components';

import '../style.scss'

function useOutsideClick(ref,btnApply) {
    const dispatch = useDispatch()

    useEffect(() => {
        function handleClickOutside(event) {
             if ((ref.current && !ref.current.contains(event.target)) && document.querySelector(".datepicker_container").classList.contains("show")){
                 btnApply.current.click();
                 dispatch(setCalendarScrollParent(false)) 
             }
        } 
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const DatePickerRangeComponent = (props) => {
    let { id, startDate, endDate, format = 'MMM DD, yyyy', showButtons = true, onChange, iconName = 'calendar_month', className, withTime = true, maxDate } = props
    const [dateStartValue, setDateStartValue] = useState(startDate && moment(startDate) || moment())
    const [dateEndValue, setDateEndValue] = useState(endDate && moment(endDate) || null)
    const [openPicker, setOpenPicker] = useState(false)
    const areaMenuRef = useRef()
    const calendar = useRef(null)
    const wrapperRef = useRef(null)
    const btnApply = useRef(null)
    const { messages, locale } = useIntl()
    const dispatch = useDispatch()
    const calendarScrollParent = useSelector(state => state.commomRedux.calendarScrollParent);
    useOutsideClick(wrapperRef,btnApply)
    if (withTime)
        format = 'MMM DD, yyyy hh:mm A'

    const getOptions = useCallback((qtty = 0, includeCero = false) => {
        let items = []
        for (let i = includeCero ? 0 : 1; i <= qtty; i++) {
            items.push({ id: i, label: i })
        }
        return items
    })

    const getTimeObject = useCallback((time, value) => {
        if (time == "hour") {
            let hour = value.hour()
            if (hour > 12)
                hour = hour - 12
            if (hour == 0)
                hour = 12

            return { id: hour, label: hour }
        }

        if (time == "minute") {
            let minute = value.minute()
            return { id: minute, label: minute }
        }
    })

    const calendarBottom = () => {

        const calendarDiv = document.querySelector('.datepicker_container')
        calendarDiv.style.bottom  = null;

        const calendarGroup = calendar.current

        if(window.innerHeight <= calendarGroup.clientHeight + calendarGroup.getBoundingClientRect().top + scrollY + 70){
            calendarDiv.style.bottom = '54px';
        }
    }

    const temporalStatusPicker = () => {

        calendarBottom();
        const element1= document.querySelector(".modal-content")
        const element2= document.querySelector(".modalCreateEditDashboard")
        const element3= document.querySelector(".recent-alert-form-container")
        
        if(!openPicker){
            if(element1) element1.style['overflow']= "initial";
            if(element2) element2.style['overflow']= "initial";
            if(element3) element3.style['overflow']= "initial";
        }else {
            if(element1) element1.style['overflow']= "hidden";
            if(element2) element2.style['overflow']= "hidden";
            if(element3) element3.style['overflow']= "auto";
        }
        
        dispatch(setCalendarScrollParent(!calendarScrollParent))
        setOpenPicker(!openPicker)

        
    }

    useEffect(() => {
        setDateStartValue(startDate && moment(startDate) || moment())
        setDateEndValue(endDate && moment(endDate) || null)
    }, [startDate, endDate])



    const saveInfoInClose = () => {
        if(dateStartValue?.toDate() && dateEndValue?.toDate() && onChange){
            onChange(dateStartValue?.toDate(), dateEndValue?.toDate(), dateStartValue, dateEndValue)
        } else if(dateStartValue?.toDate() && onChange){ 
            const dateEnd = dateStartValue.clone()?.endOf('day')
            onChange(dateStartValue?.toDate(), dateEnd?.toDate(), dateStartValue, dateEnd)
        }
    }

    /** Converts the first letter to uppercase and removes the dot (.) of abbreviation (used for spanish locale)*/
    function capitalize(str) {
        if (str)
            return (str.charAt(0).toUpperCase() + str.slice(1)).replace('.','');
    }

        return (
        <div className={cx("datepicker datepicker_range", className)} style={props.style} ref={wrapperRef}>
            <AreaMenu
                id={id || "daterangepicker"}
                ref={areaMenuRef}
                onBlur={() => {
                    // setDateStartValue(startDate && moment(startDate) || moment())
                    // setDateEndValue(endDate && moment(endDate) || null)
                }}
                noHideOnBlur
            >
                <Area>
                    <input className="datepicker_input input-normal" value={`${capitalize(dateStartValue?.locale(locale).format(format)) || ''} - ${capitalize(dateEndValue?.locale(locale).format(format)) || ''}`} 
                    onClick={() => {
                        areaMenuRef?.current?.toggle(false)
                        saveInfoInClose()
                        temporalStatusPicker()
                    }} 
                    readOnly/>
                    <Icon className="datepicker_icon"
                    onClick={()=>{
                        areaMenuRef?.current?.toggle(false)
                        saveInfoInClose()
                        temporalStatusPicker()
                    }}
                    >{iconName}</Icon>
                </Area>
                <Menu className="datepicker_container" >
                    <div className="datepicker_container_range" ref={calendar}>
                        <div>
                            <Datepicker
                                selected={dateStartValue?.toDate()}
                                onChange={(newDates) => {
                                    const [start, end] = newDates;

                                    let newStart = start && moment(start).set({ "hour": 0, "minute": 0, "second": 0 })
                                    let newEnd = end && moment(end).set({ "hour": 23, "minute": 59, "second": 59 })

                                    setDateStartValue(newStart)
                                    setDateEndValue(newEnd)
                                }}
                                inline
                                startDate={dateStartValue?.toDate()}
                                endDate={dateEndValue?.toDate()}
                                monthsShown={2}
                                selectsRange
                                maxDate={maxDate}
                                locale={locale}
                            />

                            {
                                withTime && dateEndValue &&
                                <div className="datepicker_container_range_time">
                                    <div className="time_container">
                                        <div className="time_select_container_icon"><Icon>schedule</Icon></div>
                                        <div className="time_select_container">
                                            <Select
                                                className="time_select"
                                                id="hours"
                                                value={getTimeObject("hour", dateStartValue)}
                                                onChange={(value) => {
                                                    let newDate = dateStartValue.clone()
                                                    let isPm = dateStartValue?.format('A') == "PM"
                                                    let hour = value.id
                                                    if (isPm && hour < 12)
                                                        hour = hour + 12
                                                    else if (!isPm && hour == 12)
                                                        hour = 0

                                                    newDate.set("hour", hour)
                                                    setDateStartValue(newDate)
                                                }}
                                                options={getOptions(12)}
                                                isSearchable
                                                textField={'label'}
                                                valueField={'id'}
                                            />

                                        </div>
                                        <div className="time_select_container_icon">:</div>
                                        <div className="time_select_container">
                                            <Select
                                                className="time_select"
                                                id="hours"
                                                value={getTimeObject("minute", dateStartValue)}
                                                onChange={(value) => {
                                                    let newDate = dateStartValue.clone()
                                                    newDate.set("minute", value.id)
                                                    setDateStartValue(newDate)
                                                }}
                                                options={getOptions(59, true)}
                                                isSearchable
                                                textField={'label'}
                                                valueField={'id'}
                                            />

                                        </div>
                                        <div className="time_select_container">
                                            <Select
                                                className="time_select"
                                                id="hours"
                                                value={dateStartValue?.format('A') == "PM" ? { id: "PM", label: "PM" } : { id: "AM", label: "AM" }}
                                                onChange={(value) => {
                                                    let hour = dateStartValue.hour()
                                                    let newDate = dateStartValue.clone()
                                                    if (value.id == "AM" && hour > 12)
                                                        hour = hour - 12
                                                    else if (value.id == "PM" && hour < 12)
                                                        hour = hour + 12
                                                    else if (value.id == "AM" && hour == 12)
                                                        hour = 0

                                                    newDate.set("hour", hour)
                                                    setDateStartValue(newDate)
                                                }}
                                                options={[{ id: "AM", label: "AM" }, { id: "PM", label: "PM" }]}
                                                isSearchable
                                                textField={'label'}
                                                valueField={'id'}
                                            />

                                        </div>
                                    </div>
                                    <div className="time_container">
                                        <div className="time_select_container_icon"><Icon>schedule</Icon></div>
                                        <div className="time_select_container">
                                            <Select
                                                className="time_select"
                                                id="hours"
                                                value={getTimeObject("hour", dateEndValue)}
                                                onChange={(value) => {
                                                    let newDate = dateEndValue.clone()
                                                    let isPm = dateEndValue?.format('A') == "PM"
                                                    let hour = value.id
                                                    if (isPm && hour < 12)
                                                        hour = hour + 12
                                                    else if (!isPm && hour == 12)
                                                        hour = 0

                                                    newDate.set("hour", hour)
                                                    setDateEndValue(newDate)
                                                }}
                                                options={getOptions(12)}
                                                isSearchable
                                                textField={'label'}
                                                valueField={'id'}
                                            />

                                        </div>
                                        <div className="time_select_container_icon">:</div>
                                        <div className="time_select_container">
                                            <Select
                                                className="time_select"
                                                id="hours"
                                                value={getTimeObject("minute", dateEndValue)}
                                                onChange={(value) => {
                                                    let newDate = dateEndValue.clone()
                                                    newDate.set("minute", value.id)
                                                    setDateEndValue(newDate)
                                                }}
                                                options={getOptions(59, true)}
                                                isSearchable
                                                textField={'label'}
                                                valueField={'id'}
                                            />

                                        </div>
                                        <div className="time_select_container">
                                            <Select
                                                className="time_select"
                                                id="hours"
                                                value={dateEndValue?.format('A') == "PM" ? { id: "PM", label: "PM" } : { id: "AM", label: "AM" }}
                                                onChange={(value) => {
                                                    let hour = dateEndValue.hour()
                                                    let newDate = dateEndValue.clone()
                                                    if (value.id == "AM" && hour > 12)
                                                        hour = hour - 12
                                                    else if (value.id == "PM" && hour < 12)
                                                        hour = hour + 12
                                                    else if (value.id == "AM" && hour == 12)
                                                        hour = 0
                                                    newDate.set("hour", hour)
                                                    setDateEndValue(newDate)
                                                }}
                                                options={[{ id: "AM", label: "AM" }, { id: "PM", label: "PM" }]}
                                                isSearchable
                                                textField={'label'}
                                                valueField={'id'}
                                            />

                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                    {
                        showButtons &&
                        <div className="datepicker_buttons_container">

                            <button className="btn btn-cancel btn-datepicker" onClick={() => {
                                event.preventDefault();
                                setDateStartValue(startDate && moment(startDate) || moment())
                                setDateEndValue(endDate && moment(endDate) || null)
                                areaMenuRef?.current?.toggle(false)
                                temporalStatusPicker()
                            }}>
                                {messages["cancel"] || "Cancel"}
                            </button>

                            <button className="btn btn-blue btn-datepicker" ref={btnApply} onClick={() => {
                                event.preventDefault();
                                areaMenuRef?.current?.toggle(false)
                                saveInfoInClose()
                                temporalStatusPicker()
                            }}>
                                {messages["apply"] || "Apply"}
                            </button>
                        </div>
                    }
                </Menu>
            </AreaMenu>
        </div >
    )
}

export default DatePickerRangeComponent