// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";

// Action Types
import {
  MANAGE_CAMERAS_LIST,
  MANAGE_CAMERAS_PAIR_DEVICE,
  MANAGE_CAMERAS_UNPAIR_DEVICE,
} from 'Redux/actionTypes';

// Actions
import {
  searchCamerasListResponse,
  pairDeviceResponse,
  unpairDeviceResponse,
} from 'Redux/actions';

// Helpers
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';

function* searchVideoDevices({ payload }) {
  try {
    const { resetSearch, searchText, limit, offset } = payload;
    let data = yield call(
      clientQuery,
      `query ($openSearch: String, $limit: Int, $offset: Int){
        Cameras: searchVideoDevices(openSearch: $openSearch, limit: $limit, offset: $offset){
          items {
            unit {
              id
              label
            }
            linkedDevice {
              id
              esn
              label
            }
          }
          total
        }
      }`,
      {
        openSearch: searchText || '',
        limit: limit || 25,
        offset: offset || 0,
      },
      endpoints.GRAPHQL_GENERAL,
    );
    if (data && data.Cameras) {
      const { items = [], total = 0 } = data.Cameras;
      yield put(searchCamerasListResponse({ items, total, resetSearch }));
    } else {
      yield put(searchCamerasListResponse({ error: true, items: [], total: 0, resetSearch: true }));
    }
  } catch (exc) {
    yield put(searchCamerasListResponse({ error: true, items: [], total: 0, resetSearch: true }));
    ExceptionManager(
      exc,
      'modules/video/views/manageCamerasTab/redux/sagas',
      'searchVideoDevices',
    );
  }
}

function* pairDevice({ payload }) {
  try {
    const { unitId, videoDeviceId } = payload;
    const data = yield call(
      clientMutation,
      `mutation linkUnitToVideoDevice ($unitId: Int!, $videoDeviceId: String!) {
        Pair: linkUnitToVideoDevice (unitId: $unitId, videoDeviceId: $videoDeviceId)
      }`,
      {
        unitId,
        videoDeviceId,
      },
      endpoints.GRAPHQL_GENERAL,
    );
    if (data && data.Pair) {
      yield put(pairDeviceResponse(data.Pair));
    } else {
      yield put(pairDeviceResponse({ error: true }));
    }
  } catch (exc) {
    yield put(pairDeviceResponse({ error: true }));
    ExceptionManager(
      exc,
      'modules/video/views/manageCamerasTab/redux/sagas',
      'pairDevice',
    );
  }
}

function* unpairDevice({ payload }) {
  try {
    const { videoDeviceId } = payload
    const data = yield call(
      clientMutation,
      `mutation removeLinkedUnit ($videoDeviceId: String!) {
        Remove: removeLinkedUnit (videoDeviceId: $videoDeviceId)
      }`,
      {
        videoDeviceId,
      },
      endpoints.GRAPHQL_GENERAL,
    );
    if (data && data.Remove) {
      yield put(unpairDeviceResponse(data.Remove));
    } else {
      yield put(unpairDeviceResponse({ error: true }));
    }
  } catch (exc) {
    yield put(unpairDeviceResponse({ error: true }));
    ExceptionManager(
      exc,
      'modules/video/views/manageCamerasTab/redux/sagas',
      'unpairDevice',
    );
  }
}

function* watchMethods() {
  yield takeEvery(MANAGE_CAMERAS_LIST, searchVideoDevices);
  yield takeEvery(MANAGE_CAMERAS_PAIR_DEVICE, pairDevice);
  yield takeEvery(MANAGE_CAMERAS_UNPAIR_DEVICE, unpairDevice);
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}
