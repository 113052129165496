import { call, put } from "redux-saga/effects";
import { clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";
// Actions
import { reportsMainGenerateReportProgress } from "Redux/actions";

import {
  getTimeZone,
  baseReportConditions,
} from "Modules/reports/views/main/components/reportsTab/helper";

function* getJBSummaryDetailsReport(report, event) {
  const { filters, name, reportTimeRanges } = report;
  const { otherFilters } = filters;

  const {
    filterType,
    filterArray,
    page,
    rowsPerPage,
    sortField,
    sortOrder,
    startRange,
    endRange,
  } = event.filters;

  try {
    let data = yield call(
      clientQuery,
      `query getJBSummaryDetailsReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
              Report: getJBSummaryDetailsReport (conditions: $conditions, limit: $limit, offset: $offset) {
                items {
                    date
                    dateTime
                    engineAverageFuelEconomy
                    engineTotalFuelUsed
                    engineTotalHours
                    engineTotalIdleFuel
                    engineTotalIdleHours
                    hirezTotalFuel
                    totalPtoHours
                    totalVehicleHours
                }
                total
                limit
                offset
                category
              }
            }`,
      {
        conditions: {
          reportName: name,
          category: {
            field: filterType,
            value: filterArray,
          },
          dateRange: {
            fields: ["unitTime"],
            GTE: startRange,
            LTE: endRange,
            reportTimeRanges
          },
          sortField: {
            field: sortField || "deviceId",
            order: sortOrder === "desc" ? "DESC" : "ASC",
          },
        },
        limit: rowsPerPage,
        offset: page,
      },
      endpoints.GRAPHQL_PX_REPORTING
    );
    if (data && data.Report) {
        report.summary = data.Report;
        report.loadingSummary = false;
      yield put(reportsMainGenerateReportProgress(report));
    } else {
        report.loadingSummary = false;
      yield put(reportsMainGenerateReportProgress(report));
    }
  } catch (exc) {
    report.loadingSummary = false;
    ExceptionManager(
      exc,
      "/reports/JBSummaryReport",
      "getJBSummaryDetailsReport"
    );
    yield put(reportsMainGenerateReportProgress(report));
  }
}

export default getJBSummaryDetailsReport;
