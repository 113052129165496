// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
  FSM_SETTINGS_MEAL_BREAK_GET,
  FSM_SETTINGS_MEAL_BREAK_CREATE
} from "Redux/actionTypes";

// Actions
import {
  createBreakSettingsResponse,
  getMealBreakSettingResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";


function* getBreakNotificationRequest({ payload }) {
  try {

    const query = `
        query getBreakNotification ($openSearch: String) {
          getBreakNotification (openSearch: $openSearch) {
            breakDuration
            notifyAt
            contacts
          }
      }`;

    const response = yield call(
      clientQuery,
      query,
      {},
      endpoints.GRAPHQL_GENERAL
    );

    yield put(getMealBreakSettingResponse({ break: response?.getBreakNotification }));
  } catch (exc) {
    yield put(getMealBreakSettingResponse({ break: {} }));
    ExceptionManager(
      exc,
      "modules/fsm/views/main/redux/saga",
      "getBreakNotificationRequest",
      true
    );
  }
}

function* createBreakNotificationRequest({ payload }) {

  try {

    let query = ` mutation createBreakNotification ($breakDuration:Int, $notifyAt: Int, $contacts: [Int]) {
        createBreakNotification (breakDuration: $breakDuration, notifyAt: $notifyAt, contacts: $contacts) {
          breakDuration
          notifyAt
          contacts
        }
    }`;

    let value = {
      notifyAt: payload?.data?.notifyAt?.id,
      breakDuration: payload?.data?.breakDuration?.id,
      contacts: payload?.data?.contacts?.map(d => { return d.id })
    }

    const response = yield call(
      clientMutation,
      query,
      value,
      endpoints.GRAPHQL_GENERAL,
    );

    yield put(createBreakSettingsResponse({ create: response, error: false }));
  } catch (exc) {
    yield put(createBreakSettingsResponse({ create: null, error: true }));
    ExceptionManager(
      exc,
      "modules/fsm/views/fsmSettings/components/formSettings/redux/saga",
      "createBreakNotificationRequest",
      true
    );
  }
}



function* watchMethods() {
  yield takeEvery(FSM_SETTINGS_MEAL_BREAK_GET, getBreakNotificationRequest);
  yield takeEvery(FSM_SETTINGS_MEAL_BREAK_CREATE, createBreakNotificationRequest);
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}