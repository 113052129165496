export default {
    exportVehicles_title: "Export Upcoming Maintenance",
    exportVehicles_upcoming: "Upcoming Maintenance",
    exportVehicles_fileFormat: "File Format",
    exportVehicles_dueIn: "Due In",
    exportVehicles_exportPeriod: "Export Period",
    exportVehicles_days: "days",
    exportVehicles_ahead: "ahead",
    exportVehicles_left: "left",
    exportVehicles_vehicledueexport: "Vehicles Due Export",
    exportVehicles_pdfMessage: "For PDF it is necessary to select a maximum of 7 columns",
    exportVehicles_tags: 'Tags',
    exportVehicles_vehicle: 'Vehicle',
    exportVehicles_lastServiceDate: 'Last Service Date',
    exportVehicles_odometerAtLastService: 'Odometer At Last Service',
    exportVehicles_currentOdometer: 'Current Odometer',
    exportVehicles_engineHoursAtLastService: 'Engine Hours At Last Service',
    exportVehicles_currentEngineHours: 'Current Engine Hours',
    exportVehicles_nextServiceDue: 'Next Service Due',
    exportVehicles_nextServiceStatus: 'Next Service Status',
    exportVehicles_daysInterval: 'Days Interval',
    exportVehicles_engineHoursInterval: 'Engine Hours Interval',
    exportVehicles_milesInterval: 'Miles Interval',
    exportVehicles_lastReportedLocation: 'Last Reported Location',
    exportVehicles_pdfMaxFiles: 'For export in PDF, it is necessary to select the columns you want to see in the file (max 7 fields)',
    exportVehicles_requireType: 'File format is required',
    exportVehicles_requirePdfFields: 'At least one column selected is required',


}