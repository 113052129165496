export default {
    fleetLandmark_create: 'Crear Punto de Referencia',
    fleetLandmark_edit: 'Editar Punto de Referencia: ',
    fleetLandmark_radius: 'Radio',
    fleetLandmark_shape: 'Forma',
    fleetLandmark_circle: 'Circulo',
    fleetLandmark_polygon: 'Polygono',
    fleetLandmark_service_error_title:'Error',
    fleetLandmark_service_error_message:'Hay Un Error Con Este Servicio',
    fleetLandmark_tags: "Etiquetas",
    fleetLandmark_notes: "Notas",
    fleetLandmark_successCreate: "Punto de referencia creado exitosamente",
    fleetLandmark_successEdit: "Punto de referencia actualizado exitosamente",
    fleetLandmark_error_name: "El nombre del punto de referencia ya existe",
};