export default {
    mapLandmarkCreateEditLandmark_createTitle: "Create New Landmark",
    mapLandmarkCreateEditLandmark_editTitle: "Edit Landmark: ",
    mapLandmarkCreateEditLandmark_circle: "Circle",
    mapLandmarkCreateEditLandmark_createLandmark: "Create Landmark",
    mapLandmarkCreateEditLandmark_polygon: "Polygon",
    mapLandmarkCreateEditLandmark_square: "Square",
    mapLandmarkCreateEditLandmark_select: "Landmark Type",
    mapLandmarkCreateEditLandmark_name: "Name",
    mapLandmarkCreateEditLandmark_landmarkName: "Landmark Name",
    mapLandmarkCreateEditLandmark_landmarkIcon: "Landmark Icon",
    mapLandmarkCreateEditLandmark_iconType: "Icon Type",
    mapLandmarkCreateEditLandmark_Icon_color: "Icon & Color",
    mapLandmarkCreateEditLandmark_group: "Group",
    mapLandmarkCreateEditLandmark_tag: "Tag",
    mapLandmarkCreateEditLandmark_tag_select: "Select Tags",
    mapLandmarkCreateEditLandmark_radius: "Radius",
    mapLandmarkCreateEditLandmark_note: "Notes",
    mapLandmarkCreateEditLandmark_address: "Address",
    mapLandmarkCreateEditLandmark_latLng: "Lat/Long",
    mapLandmarkCreateEditLandmark_success: "Landmark saved successfully",
    mapLandmarkCreateEditLandmark_iconRequired: 'You must select an icon or disable "Landmark Icon" toggle, please try again',
    mapLandmarkCreateEditLandmark_circleRequired: 'Please add point in the map for create the landmark',
    mapLandmarkCreateEditLandmark_tags_shape: "Landmark Shape",
    mapLandmarkCreateEditLandmark_tags_label: "Landmark Label",
    mapLandmarkCreateEditLandmark_error_define_landmark: "Use Address, Map click or Unit to create a Landmark on the map",
    mapLandmarkCreateEditLandmark_info_polygon: 'Begin clicking points on the map to create a Landmark. Must be 3–{maxPoints} points.'
};