import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// controls
import Table, {Column} from '../../table'
//helpers
import {
    formatDatetime,
    getCategoryFilterType,
    getNameVehicle,
    useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';

//hooks
import {useSidePanelMap, useTableReport} from 'Core/hooks';
//actions
import {clearRedux, reportsMainGenerateEvents, reportsMainGenerateReportUpdate} from 'Redux/actions'
import moment from 'moment';
import AddressLandmark from 'Modules/reports/views/main/components/reportsTab/.components/addressLandmark'

const DriverFormsReport = (props) => {
    const {messages, report} = props;
    const {data, filters, summary, loadingSummary, timeZone} = report;
    const {filterEntity, otherFilters, startRange, endRange} = filters;
    const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
    const [currentNewSortField, setCurrentNewSortField] = useState("deviceId");
    const [currentNewSortOrder, setCurrentNewSortOrder] = useState("asc");
    const exportHandler = useHandleExportReport(report)

    const loadingCreateLandMark = useSelector(state => state.vehicleLandMarkRedux.loadingCreateLandMark)
    const createLandMarkResult = useSelector(state => state.vehicleLandMarkRedux.createLandMarkResult)

    const reportRef = useRef();
    const dispatch = useDispatch()

    useEffect(() => {
        if (report) {
            const newReport = JSON.parse(JSON.stringify(report));
            // to keep the reference to the units selected
            reportRef.current = newReport;
        }
    }, [JSON.stringify(report)]);


    useEffect(() => {
        if (!loadingCreateLandMark && createLandMarkResult) {
            dispatch(reportsMainGenerateReportUpdate(report, currentPage, currentRowsPerPage, currentNewSortField, currentNewSortOrder))
            dispatch(clearRedux("LANDMARK", {"createLandMarkResult": null}));
        }

    }, [loadingCreateLandMark, createLandMarkResult])

    const onChangeExpandable = (unit) => {
        if(!unit.noData){
            let events = {
                data: [],
                filters: {
                    filterType: getCategoryFilterType(filterEntity),
                    filterArray: unit,
                    page: 1,
                    rowsPerPage: 10,
                    sortField: 'eventDate',
                    otherFilters
                }
            }
            dispatch(reportsMainGenerateEvents(report, events))
        }
    }

    const exitstLabel = (label) => {
        if(!label){
            return '--'
        }

        return label
    }

    let parametersSummary = useTableReport(
        {
            data,
            report,
            messages,
            hideHeader: false
        }
    );

    const renderExpandableRow = (row) => {
        let parametersSummaryTable = {
            ...parametersSummary,
            data: summary?.items || [],
            totalItems: summary?.total || 0,
            hideHeader: false,
            serverSideTable: true,
            onChangedPage: (page, rowsPerPage, sortField, sortOrder) => {
              let events = {
                data: [],
                filters: { filterType: getCategoryFilterType(filterEntity), filterArray: row, page, rowsPerPage, sortField, sortOrder, otherFilters }
              }
              dispatch(reportsMainGenerateEvents(report, events))
            },
            onChangeSelector: null,
            itemsSelector: null,
            generateExport: false,
            hideSelectorUnitsDriver: true,
            groups:report.filters.units || [],
            hideNumberOfRows:true,
            centerPagination: true,
          };

        return (
            <div>
                <Table
                   {...parametersSummaryTable}
                   loading={loadingSummary}
                   noData={row.noData}
                >
                    <Column label={messages["reportsMainRT_TimeDate"]} field="dateTime" noSorting>
                        {(element) => {
                            return (<div>
                                <span className="speed-report-stacked">{element.dateTime && formatDatetime(element.dateTime, "h:mm A", timeZone)}</span>
                                <span className="speed-reportstacked speed-report-event-date">{element.dateTime && formatDatetime(element.dateTime, "MMM D", timeZone)}</span>
                            </div>)
                        }}
                    </Column>
                    <Column label={messages['reportsMainRT_Address']} field="address" noSorting>
                        {(element) => {
                            return (
                            <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={element}/>
                            );
                        }}
                    </Column>
                    <Column label={messages["reportsMainRT_Form"]} field="form" noSorting>
                        {(element) => {
                              const formatLabel = exitstLabel(element.form)
                            return (
                                <div>
                                    <span>{formatLabel}</span>
                                </div>
                            )
                        }}
                    </Column>
                    <Column label={messages["reportsMainRT_Question"]} field="question" noSorting>
                        {(element) => {
                              const formatLabel = exitstLabel(element.question)
                            return (
                                <div>
                                    <span>{formatLabel}</span>
                                </div>
                            )
                        }}
                    </Column>
                    <Column label={messages["reportsMainRT_Answer"]} field="answer" noSorting>
                        {(element) => {
                              const formatLabel = exitstLabel(element.answer)
                            return (
                                <div>
                                    <span>{formatLabel}</span>
                                </div>
                            )
                        }}
                    </Column>
                </Table>
            </div>
        )
    }

    let parametersTable = useTableReport({
        data: data.items,
        report,
        messages,
        hideHeader: true,
        renderExpandableRow,
        onChangeExpandable,
        propertyToShowExpandable: 'driverId'
    });

    return (
        <Fragment>
            <Table
                {...parametersTable}
                {...exportHandler}
            >
                <Column label="Day" field="label" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span><strong>{getNameVehicle(filterEntity, messages)}:</strong> {element.driverName} </span>
                            </div>
                        );
                    }}
                </Column>
                <Column label={messages['generatedReport_totalIdleTime']} field="label" noSorting>
                    {(element) => {
                        const localTimeStart = moment.utc(startRange).local().format();
                        const localTimeEnd = moment.utc(endRange).local().format();
                        return (
                            <div>
                                <span>
                                  <span>
                                    <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                                  </span>
                                </span>
                            </div>
                        );
                    }}
                </Column>
            </Table>
            <SideMapComponent hiddenButton hiddenSearchMap/>
        </Fragment>
    );
}


export default DriverFormsReport;
