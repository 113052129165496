import {
	MODAL_ENABLE_STARTER_OPEN,
	MODAL_ENABLE_STARTER_CLOSE,
	VECHICLE_STATUS_SAVE_BULK_CHANGE_STARTER,
	VECHICLE_STATUS_SAVE_BULK_CHANGE_STARTER_PROGRESS,
	VECHICLE_STATUS_SAVE_BULK_CHANGE_STARTER_RESULT,
	SHOW_HIDE_ENABLE_DISABLE_STARTER_MODAL,
	CLEAR_REDUX
} from 'Redux/actionTypes';

//STATE INITIAL
const INIT_STATE = {
	isOpen: false,
	loading: false,
	vehicles: [],

	bulkChangeStarterFinished: null,
	bulkChangeStarterFinishedState: null,
	bulkChangeStarterInProgress: false,
	bulkChangeStarterData: null,
	isEnableDisableStarterModalOpen: false,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case MODAL_ENABLE_STARTER_OPEN:
			return { ...state, isOpen: true, vehicles: action.payload || [] };

		case MODAL_ENABLE_STARTER_CLOSE:
			return { ...state, isOpen: false };

		case CLEAR_REDUX:
			return ["", "MODAL_ENABLE_STARTER"].includes(action.payload.option)
				? action.payload.parameters
					? { ...state, ...action.payload.parameters }
					: { ...INIT_STATE }
				: { ...state }

		case VECHICLE_STATUS_SAVE_BULK_CHANGE_STARTER:
			return {
				...state,
				bulkChangeStarterFinished: false,
				bulkChangeStarterInProgress: true,
				bulkChangeStarterFinishedState: null,
			};

		case VECHICLE_STATUS_SAVE_BULK_CHANGE_STARTER_PROGRESS:
			return {
				...state,
				bulkChangeStarterInProgress: true,
				bulkChangeStarterData: action.payload.data,
			};

		case SHOW_HIDE_ENABLE_DISABLE_STARTER_MODAL:
			return {
				...state,
				isEnableDisableStarterModalOpen: action.payload.data,
				bulkChangeStarterFinished: null,
				bulkChangeStarterInProgress: false,
				bulkChangeStarterData: [],
			};

		case VECHICLE_STATUS_SAVE_BULK_CHANGE_STARTER_RESULT:
			return {
				...state,
				bulkChangeStarterFinished: true,
				bulkChangeStarterFinishedState: action.payload.data.status,
				bulkChangeStarterInProgress: false,
				bulkChangeStarterData: action.payload.data.info,
			};
			
		default:
      return state;
	}
};
