import {
    DASHBOARD_PANEL_RECENT_ALERTS,
    DASHBOARD_PANEL_RECENT_ALERTS_RESPONSE
} from "Redux/actionTypes";


export const recentAlertsDashboardPanel = (data) => {
    return {
        type: DASHBOARD_PANEL_RECENT_ALERTS,
        payload: {data},
    };
};

export const recentAlertsDashboardPanelResponse = (data) => {
    return {
        type: DASHBOARD_PANEL_RECENT_ALERTS_RESPONSE,
        payload: data,
    };
};

