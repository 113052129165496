export default {
    mapLandmarkImport_landmark:'Punto de referencia',
    mapLandmarkImport_ImportTitle:'Importar Puntos de referencia',
    mapLandmarkImport_downloadTemplate:'Descargar Plantilla',
    mapLandmarkImport_fieldsRequied:'Por favor complete todos los campos',
    mapLandmarkImport_formatSupport:'Formato CSV admitido: coma, punto y coma, tabulador.',
    mapLandmarkImport_import:'Importar',
    mapLandmarkImport_titleEditImport:'No se encontraron los siguientes puntos de referencia. Por favor corrija cualquier error',
    mapLandmarkImport_fieldsAdressRequied:'El campo de dirección o los campos de longitud y latitud son necesarios',
    mapLandmarkImport_correctErrors:'Se presentaron algunos errores en la importacion, para corregirlos presione clic aqui',
    mapLandmarkImport_success:'Importación exitosa de puntos de referencia',
    mapLandmarkImport_maxSizeFile:'Tamaño máximo de archivo 5 mb',
    mapLandmarkImport_chooseFile:'Seleccionar Archivo',
    mapLandmarkImport_errorRadius:'Error en la columna radio',
    mapLandmarkImport_errorTag:'El Tag no existe',
    mapLandmarkImport_errorAddress:'Error in format Address'
}