import {
    ENTITY_TAG_PICKER_SEARCH_ENTITY_TAGS,
    ENTITY_TAG_PICKER_SEARCH_ENTITY_TAGS_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    entityTags: {},
    loadingEntityTags: {},
    filters: {},
    loading: false
};

export default (state = INIT_STATE, action) => {
    let currentLoading = {};
    let currentFilter = {};

    switch (action.type) {
        case ENTITY_TAG_PICKER_SEARCH_ENTITY_TAGS:
            const { payload: { filters } } = action;

            if (state.loadingEntityTags[filters?.entityName]) {
                currentLoading = state.loadingEntityTags[filters?.entityName];
            }

            currentLoading = {
                ...currentLoading,
                [filters?.parentTagId]: true
            };

            if (state.filters[filters?.entityName]) {
                currentFilter = state.filters[filters?.entityName];
            }

            currentFilter = {
                ...currentFilter,
                [filters?.parentTagId]: filters
            };

            return {
                ...state,
                loadingEntityTags: { 
                    ...state.loadingEntityTags,
                    [filters?.entityName]: currentLoading
                },
                filters: { 
                    ...state.filters, 
                    [filters?.entityName]: currentFilter
                },
                loading: true
            };

        case ENTITY_TAG_PICKER_SEARCH_ENTITY_TAGS_RESPONSE:
            const { items, total, parentTagId, entityName } = action?.payload?.response;

            let currentEntityTags = [];
            if (state.entityTags[entityName] && state.entityTags[entityName][parentTagId]) {
                currentEntityTags = [ ...state.entityTags[entityName][parentTagId] ];
            }

            let currentEntity = {};
            if (state.entityTags[entityName]) {
                currentEntity = { ...state.entityTags[entityName] };
            }


            if (state.loadingEntityTags[entityName]) {
                currentLoading = state.loadingEntityTags[entityName];
            }

            currentLoading = {
                ...currentLoading,
                [parentTagId]: false
            };

            if (state.filters[entityName]) {
                currentFilter = state.filters[entityName];
            }

            currentFilter = {
                ...currentFilter,
                [parentTagId]: {
                    ...currentFilter[parentTagId],
                    total: total
                }
            };

            return {
                ...state,
                entityTags: { 
                    ...state.entityTags, 
                    [entityName]: {
                        ...currentEntity,
                        [parentTagId]: [ ...currentEntityTags, ...items]
                    }
                },

                loadingEntityTags: { 
                    ...state.loadingEntityTags, 
                    [entityName]: currentLoading
                },
                filters: { 
                    ...state.filters, 
                    [entityName]: currentFilter
                },
                loading: false
            };

        case CLEAR_REDUX:
            return ["", "ENTITY_TAG_PICKER"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state }

        default:
            return { ...state };
    }
}