 /*********************************************************
 * Imports
 *********************************************************/
 import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
 import { useDispatch } from 'react-redux';
 /*********************************************************
  * Components
  *********************************************************/
 import GoogleProvider from './providers/googleProvider'
 import MapObject from './providers/mapObject'
 import LegendsToggle from './components/LegendsToggle'
 import SpeedOverrideMap from './components/SpeedOverrideMap'
 import RoutesInfoMap from './components/RoutesInfoMap'
 /* import WeatherToggle from './components/WeatherToggle' */
 import LayerToggle from './components/LayerToggle'
 import InputSearchMap from './components/InputSearchMap'
 
 import MapSearchBox from './components/MapSearchBox'
 
 import InfoBubble from '../../modules/.common/components/InfoBubble'
 import MapPagination from './components/MapPagination'
 import DrawLayerMap from './components/DrawLayerMap'
 import DrawMapSettings from './components/DrawMapSettings'
 import TagFilterAppliedOnMap from './components/TagFilterAppliedOnMap'
 import ButtonFitBoundsVehicles from './components/ButtonFitBoundsVehicles'
 import { NotificationManager } from 'Components/Notifications';
 import { LinearProgress } from 'Components';
 import { cutNotes } from 'Core/data/Helpers'
 import DirectionsMap from 'Components/Map/components/DirectionsMap'
 import DrawPolygonMap from 'Components/Map/components/DrawPolygonMap';
 import { LogManagerConsole } from 'Core/logManager';
 import PanelEtaLink from './components/PanelEtaLink'
 
 //import OMSMaps from './Providers/OSMMaps'
 //import MicrosoftMaps from './Providers/MicrosoftMaps'
 
 /*********************************************************
  * Utils and enums
  *********************************************************/
 import { MapProvider } from './providers/enums';
 import { TypeObjectMap, TypeLayer } from './providers/enums'
 import { getEventColor, getIconGroupWithAnotherColor, radiusFeetToMeters, validateLatLng } from './utils'

/*********************************************************
* Slider video
*********************************************************/
import Modal, { ModalBody } from "Components/Modal";
import { SliderRangePanel } from "Modules/.common/components/SliderRangePanel";
import { useSelector } from 'react-redux';

/*********************************************************
 * Style
 *********************************************************/
import './style.scss';

/********************************************************
 * Global var to timer
 ********************************************************/
let timerDragMarker = null

/*********************************************************
 * Author:  Walter Paz Londoño
 * Date:    30 Nov 2020
 * Module:  Realtime Maps
 * 
 * Controller to realtime maps data
 ********************************************************/
const ControllerMaps = forwardRef((props, ref) => {
  const { messages } = props
  
  const mapReference = useRef()
  //-------------- Declare the references ----------------
  const [loadUnits, setLoadUnits] = useState(false);
  const [dataUnits, setDataUnits] = useState([])
  const [styleClassMap, setStyleClassMap] = useState('class-element-map')
  const mapObjectID = 'mapObject_01' //Id reference to createObject
  const nameRoute = 'routeObject01'
  const dispatch = useDispatch()

  /*******************************************************
    * slider video states
  *******************************************************/
  const { visibleForm, loadingCreateClip, requestClipResponse } = useSelector((state) => state.clipsRedux);

  useEffect(() => {
    if(requestClipResponse && requestClipResponse?.error){
      NotificationManager.warning(
        messages["videoMainView_errorRequestClip"],
        messages["videoClipRequest_Alert"],
        3000,
        null,
        null,
        "info"
      )
    }else if(requestClipResponse?.id){
      NotificationManager.success(
        messages['infoBubble_clipRequestSuccess'],
        messages["success"],
        3000,
        null,
        null,
        "success"
      )
    }
  }, [requestClipResponse])

  /*******************************************************
   * Validate eventName to get color from the unit
   * @param {Object JSON} item 
   *******************************************************/
  const validateEventColor = (item) => {
    let eventName = '';
    if (item && item['eventName']) {
      eventName = item['eventName'].toString();
    }

    if (item && item['event'] && eventName == '') {
      eventName = item['event'].toString();
    }

    return getEventColor(eventName);
  }

  /*******************************************************
   * validate the Label element from the parameter
   * @param {array} item 
   *******************************************************/
  const validateLabel = (item) => {
    let label = '';
    if (item['Label']) {
      label = item['Label'].toString();
    }

    if (item['label'] && label == '') {
      label = item['label'].toString();
    }

    if (item['deviceLabel'] && label == '') {
      label = item['deviceLabel'];
    }

    return label;
  }

  /*******************************************************
    * Change the map type depends of the view
    * @param {string} item 
   *******************************************************/
  const changeMapType = (mapType) => {
     mapReference.current.updateMapType(mapType)
  }
 
   /********************************************************
    * use effect default
    ********************************************************/
   useEffect(() => {
     mapReference.current.initMap()
   }, [])
 
   /********************************************************
    * Return map reference.
    ********************************************************/
   useImperativeHandle(ref, () => ({
     mapItem: mapReference.current.mapItem,
     updateObject: mapReference.current.updateObject,
     showInfoWindow: showInfoWindow,
     getPositionMarker: mapReference.current.getPositionMarker,
     modalPopupEvent: modalPopupEvent,
     centerByFitBounds: mapReference.current.centerByFitBounds,
     hideMarkerModal: mapReference.current.hideMarkerModal,
     getPolygonCoordinates: mapReference.current.getPolygonCoordinates,
     getCircleCoordinates: mapReference.current.getCircleCoordinates,
     addMapObject: mapReference.current.addMapObject,
     addMapObjects: mapReference.current.addMapObjects,
     removeAll: mapReference.current.removeAll,
     addMapObjectsWihoutInstance: mapReference.current.addMapObjectsWihoutInstance,
     addMapObjectsInstancebyLayer: mapReference.current.addMapObjectsInstancebyLayer,
     removeLayer: mapReference.current.removeLayer,
     showLayer: mapReference.current.showLayer,
     drawLandmark: mapReference.current.drawLandmark,
     updateMarkerOfflineDevice: mapReference.current.updateMarkerOfflineDevice,
     updateMarkerIconMap: mapReference.current.updateMarkerIconMap,
     centerVehicles: mapReference.current.centerVehicles,
     getOpenInfoWindow: mapReference.current.getOpenInfoWindow,
     centerMapAnimated: mapReference.current.centerMapAnimated,
     removeMapObject: mapReference.current.removeMapObject,
     removeMapObjects: mapReference.current.removeMapObjects,
     addPolylinePoint: mapReference.current.addPolylinePoint,
     getObject: mapReference.current.getObject,
     removeMapClick: mapReference.current.removeMapClick,
     initMapClick: mapReference.current.initMapClick,
     centerObjectOnMap: mapReference.current.centerObjectOnMap,
     updateCirclePoint: mapReference.current.updateCirclePoint,
     addCircleOnMapToCreate: mapReference.current.addCircleOnMapToCreate,
     addSquareOnMapToCreate: mapReference.current.addSquareOnMapToCreate,
     updateStyleToCreateObject: mapReference.current.updateStyleToCreateObject,
     setZoom: mapReference.current.setZoom,
     setZoomOut: mapReference.current.setZoomOut,
     getZoom: mapReference.current.getZoom,
     addLabelMarkers: mapReference.current.addLabelMarkers,
     removeMapClusterListener: mapReference.current.removeMapClusterListener,
     showMarkerCluster: mapReference.current.showMarkerCluster,
     addMapObjectSettings: mapReference.current.addMapObjectSettings,
     centerPointOnMap: mapReference.current.centerPointOnMap,
     drawPolygon: mapReference.current.drawPolygon,
     removeMapDraw: mapReference.current.removeMapDraw,
     getCurrentZoom: mapReference.current.getCurrentZoom,
     onSuscribeZoom: mapReference.current.onSuscribeZoom,
     showMarkerModal: mapReference?.current?.showMarkerModal,
     updateModalPosition: mapReference?.current?.updateModalPosition,
     getDurationRoute: mapReference?.current?.getDurationRoute,
     deleteRouteInMap: deleteRouteInMap,
     updateTypeSquareToFreeRectangle: mapReference?.current?.updateTypeSquareToFreeRectangle,
     updateRouteStyles: mapReference?.current?.updateRouteStyles,
     getGeometryEncoded: mapReference?.current?.getGeometryEncoded,
     drawPolygonWithEncodedPath: mapReference?.current?.drawPolygonWithEncodedPath,
     showTraffic: mapReference?.current?.showTraffic,
     showWeather: mapReference?.current?.showWeather,
     hideWeather: mapReference?.current?.hideWeather,
     showVehicleTrails: mapReference?.current?.showVehicleTrails,
     drawShapeObjectTempOnMap: mapReference?.current?.drawShapeObjectTempOnMap,
     setUpdateSquareDiagonally: mapReference?.current?.setUpdateSquareDiagonally,
     showNormalMarkers: mapReference?.current?.showNormalMarkers,
     setSizeMarkerIcon: mapReference?.current?.setSizeMarkerIcon,
     showUnitsByPreferences: showUnitsByPreferences,
     addMapClickRouteListener: mapReference.current.addMapClickRouteListener,
     removeMapClickRouteListener: mapReference.current.removeMapClickRouteListener,
     drawHistoryTrailOnMap: mapReference.current.drawHistoryTrailOnMap,
     updateMarkersVisible: mapReference.current.updateMarkersVisible,
     reproduceTrailEvents: mapReference.current.reproduceTrailEvents,
     focusStopTrailMarkersAnimations: mapReference.current.focusStopTrailMarkersAnimations,
     focusStartTrailMarkerAnimations: mapReference.current.focusStartTrailMarkerAnimations
   }))
 
 
   /********************************************************
    * use effect to center unit into the map
    ********************************************************/
   useEffect(() => {
     const { centerUnitId, currentZoom } = props
     if (centerUnitId != 0) {
       showInfoWindow(centerUnitId, currentZoom);
     }
   }, [props.centerUnitId])
 
   /********************************************************
    * Stablish styles to Map size
    ********************************************************/
   useEffect(() => {
 
     if (props.noRealtime) {
       return
     }
     if (!props.showMapSettings) {
       if (props.sidebarShow) {
         setStyleClassMap('class-element-map-complete-sidebar')
       } else {
         setStyleClassMap('class-element-map-complete')
       }
     } else {
       let classNameMap = 'class-element-map';
       if (props.sidebarShow) {
          if(props?.tabEditMode){
            classNameMap = 'class-element-map-sidebar-edit';   
          }else{
            classNameMap = 'class-element-map-sidebar';
          }
       }else{
        if(props?.tabEditMode){
          classNameMap = 'class-element-map-edit';
        }
       }
       setStyleClassMap(classNameMap)
     }
   }, [props.showMapSettings, props.sidebarShow, props.tabEditMode])
 
   /********************************************************
    * Update the kmls files into the map
    ********************************************************/
   useEffect(() => {
     const {
       landmarkTags } = props
     if (!landmarkTags && (landmarkTags && landmarkTags.length == 0)) {
       return
     }
 
     evalPreferencesLandmarksAndGeofences()
 
   }, [
     props.kmlsToShowMap,
     props.landmarkTagsLoading,
     props.geofenceTagsLoading,
     props.flagLandmarkTagsVisible,
     props.flagGeofenceTagsVisible,
     props.prefHiddenLandmarks,
     props.prefHiddenGeofences,
     props.userMapPreferences,
     props.showVehicleTrails,
     props?.userMapPreferences?.trailShowLandmark
   ])
 
   const evalPreferencesLandmarksAndGeofences = () => {
     const {
       prefHiddenLandmarks,
       prefHiddenGeofences,
       landmarkTags,
       geofenceTags,
       userMapPreferences,
       flagLandmarkTagsVisible,
       flagGeofenceTagsVisible } = props
 
     
     let hiddenLandmarks = []
     if(prefHiddenLandmarks && prefHiddenLandmarks.length > 0)
       hiddenLandmarks = [...prefHiddenLandmarks]
 
     if(flagLandmarkTagsVisible && flagLandmarkTagsVisible.length > 0){
       hiddenLandmarks = [...hiddenLandmarks, ...flagLandmarkTagsVisible?.map(item => item.id)]
     }
 
     let showLandmarksPref = landmarkTags?.filter((item) => !hiddenLandmarks.includes(item.id))
     showLandmarksPref = showLandmarksPref && showLandmarksPref.length > 0
       ? showLandmarksPref.map((tag) => tag.id)
       : [];
 
     if(hiddenLandmarks && hiddenLandmarks.length > 0){
       mapReference.current.setEnableObjectsByType(TypeObjectMap.Landmark, false);
       if(!props.showVehicleTrails){
         //Hidden landmarks     
         if (showLandmarksPref && showLandmarksPref.length > 0) {
           showLandmarksPref.forEach((tagId) => {
             mapReference.current.setEnableObjectsByGroup(TypeObjectMap.Landmark, tagId.toString(), true);
           })
         }
       }
       mapReference.current.addMapObjectsInstancebyLayer('landmarkSettings', TypeLayer.LandmarkMarker);
     }else{
      mapReference.current.setEnableObjectsByType(TypeObjectMap.Landmark, true);
      mapReference.current.addMapObjectsInstancebyLayer('landmarkSettings', TypeLayer.LandmarkMarker);
     }

     //if vehicle trails hidden
     if(props.showVehicleTrails && userMapPreferences){
      mapReference.current.setEnableObjectsByType(TypeObjectMap.Landmark, (userMapPreferences?.trailShowLandmark || false));
      mapReference.current.addMapObjectsInstancebyLayer('landmarkSettings', TypeLayer.LandmarkMarker);
     }
 
     let hiddenGeofences = []
     if(prefHiddenGeofences && prefHiddenGeofences.length > 0)
       hiddenGeofences = [...prefHiddenGeofences];
     
     if(flagGeofenceTagsVisible && flagGeofenceTagsVisible.length > 0)
     hiddenGeofences = [...hiddenGeofences,...flagGeofenceTagsVisible?.map(item => item.id)]
 
     let showGeofencesPref = geofenceTags?.filter((item) => !hiddenGeofences.includes(item.id))
     showGeofencesPref = showGeofencesPref && showGeofencesPref.length > 0
       ? showGeofencesPref.map((tag) => tag.id)
       : [];
 
     //Hidden geofences
     if(hiddenGeofences && hiddenGeofences.length > 0){
       mapReference.current.setEnableObjectsByType(TypeObjectMap.Geofence, false);
       if (showGeofencesPref && showGeofencesPref.length > 0) {
         showGeofencesPref.forEach((tagId) => {
           mapReference.current.setEnableObjectsByGroup(TypeObjectMap.Geofence, tagId.toString(), true);
         })
       }
       mapReference.current.addMapObjectsInstancebyLayer('geofenceSettings', TypeLayer.Geofence);
     }else{
      mapReference.current.setEnableObjectsByType(TypeObjectMap.Geofence, true);
      mapReference.current.addMapObjectsInstancebyLayer('geofenceSettings', TypeLayer.Geofence);
     }
   }
 
   /********************************************************
    * Create Map click to create Polygon
    ********************************************************/
   const onMapClickPolygon = (latLng, mapMouseEvent, item) => {
     if (props.createMaxPoints > 0 && props.createMaxPoints < item) {
       mapReference.current.reduceMapClickItems()
       if (messages) {
         warningNotification('', messages['realtimemaps_polygon_maxpoints'].replace('{maxPoints}', props.createMaxPoints))
       }
       return
     }

     const objectToSettingsPolygon = mapReference.current.getObject(TypeObjectMap.Settings, 'mapCreateObjectSettings')
     
     // Add a new marker at the new plotted point on the polyline.
     const markerPolygon = new MapObject().buildMarker({
       id: `markerLandmark_${item}`,
       position: latLng,
       layerName: TypeLayer.PolygonCreate,
       title: "#" + item,
       index: item,
       label: 'Item ID',
       color: objectToSettingsPolygon ? objectToSettingsPolygon.color : '#24F7FF',
       draggable: true
     })
 
     mapReference.current.AddPolygonPoint(mapMouseEvent, mapObjectID, markerPolygon);
   }
 
   /********************************************************
    * Drag marker in create polygon
    ********************************************************/
   const onDragMarkerEvent = (event, mapObject) => {
     if (mapObject.layerName == TypeLayer.PolygonCreate) {
       mapReference.current.movePolygonPoint(event, mapObjectID, mapObject)
     } else if (mapObject.layerName == TypeLayer.CircleCreate) {
       mapReference.current.updateCirclePoint(mapObjectID, event)
     } else if (mapObject.layerName == TypeLayer.SquareCreate) {
       return mapReference.current.updateSquarePoint(mapObjectID, event)
     } else if(mapObject && mapObject?.updatePointers){
       if (timerDragMarker) {
         clearInterval(timerDragMarker)
       }
       timerDragMarker = setInterval(() => { onEndDrawMarkerUpdatePointers(mapObject, event) }, 300)
     } else{
       if (props.onDragMarkerEvent) {
         props.onDragMarkerEvent(event, mapObject)
       }
     }
   }
 
   const onEndDrawMarkerUpdatePointers = (mapObject, event) => {
     clearInterval(timerDragMarker)
     timerDragMarker = null
     let pointers = mapReference.current.getObjectsByTypeByLayer(TypeObjectMap.Marker, TypeLayer.Pointers)
 
     let newPointers = []
     pointers.map((marker) => {
       newPointers.push({
         ...marker,
         lat: marker.id == mapObject.id ? event.latLng.lat() : parseFloat(marker.position.lat || 0),
         lng: marker.id == mapObject.id ? event.latLng.lng() : parseFloat(marker.position.lng || 0),
       })
     })
     //save pointers into redux
     if (props.addPointers) {
       dispatch(props.addPointers([...newPointers]))
     }
   }
 
   /********************************************************
    * Clear the marker from right click event
    ********************************************************/
   const onMarkerRightClick = (mapObject) => {
     if (mapObject.layerName == TypeLayer.PolygonCreate) {
       mapReference.current.removePolygonPoint(mapObjectID, mapObject)
     } else {
       if (props.onDragMarkerEvent) {
         props.onDragMarkerEvent(mapObject)
       }
     }
   }
 
   /********************************************************
    * Create Polygon
    ********************************************************/
   const instanceMapPolygon = () => {
     const objectToSettingsPolygon = mapReference.current.getObject(TypeObjectMap.Settings, 'mapCreateObjectSettings')

     mapReference.current.addMapObject(new MapObject().buildPolygon({
       id: mapObjectID,
       //position: { lat: item.lat, lng: item.lng },
       layerName: TypeLayer.PolygonCreate,
       label: 'Create Label...', //Add label name of landmark
       heading: 0,
       color: objectToSettingsPolygon ? objectToSettingsPolygon.color : '#00AFFF', //default values
       strokeColor: objectToSettingsPolygon ? objectToSettingsPolygon.strokeColor : '#24F7FF',
     }))
   }
 
   /********************************************************
    * Draw the radius into the marker circle
   ********************************************************/
  const onMapClickCircle = (latLng) => {
    const position = { lat: latLng.lat, lng: latLng.lng };
    const mapObject = new MapObject().buildMarker({
      id: 'landmarkCircle01',
      position,
      layerName: TypeLayer.CircleCreate,
      label: '',
      heading: 0,
      color: '000',
      eventName: 'no event',
      draggable: true
    })
    mapReference.current.addMapObject(mapObject)
    //Create the Circle Object
    const circle = new MapObject().buildCircle({
      id: mapObjectID, //Ref to move elements in marker event
      position,
      layerName: TypeLayer.CircleCreate,
      label: '',
      heading: 0,
      color: '#0098dc',
      eventName: 'no event',
      draggable: true
    })
    mapReference.current.addMapObject(circle)
    if (props.pointersCreated) {
      props.pointersCreated(true)
    }
    mapReference.current.removeMapClick();
  } 
 
   /********************************************************
    * Center the element into the realtime map
    ********************************************************/
   useEffect(() => {
     if (props.pointOnMap) {
       const { coordinates } = props.pointOnMap
       if (coordinates) {
         mapReference.current.centerByFitBounds(coordinates)
       } else {
         mapReference.current.centerPointOnMap(props.pointOnMap?.lat, props.pointOnMap?.lng)
       }
     }
   }, [props.pointOnMap])
 
   /********************************************************
    * On map Click
    ********************************************************/
   const onCreateMarker = (latLng) => {
     const pointer = {
       id: '01',
       lat: latLng.lat,
       lng: latLng.lng,
       label: 'Map Point',
       heading: 0,
       color: '000',
       eventName: 'no event',
       draggable: true
     }
     if (props.addPointers) {
       props.addPointers([pointer])
     }
   }
 
   /********************************************************
    * Create Map click on the map
    ********************************************************/
   useEffect(() => {
     if (mapReference) {
       mapReference.current.removeMapClick();
       mapReference.current.removeLayer(TypeLayer.PolygonCreate);
       mapReference.current.removeLayer(TypeLayer.CircleCreate);
       mapReference.current.removeLayer(TypeLayer.SquareCreate);
 
       switch (props.createTypeSelect) {
         case TypeObjectMap.Polygon:
           mapReference.current.initMapClick(onMapClickPolygon);
           instanceMapPolygon()
           break;
 
         case TypeObjectMap.Circle:
           //mapReference.current.initMapClick(onMapClickCircle);
           break;
 
         case TypeObjectMap.Square:
           break;
 
         case TypeObjectMap.CreatePointer:
           mapReference.current.initMapClick(onCreateMarker);
           break;
 
         case TypeObjectMap.MapClick:
           if (props.onMapClick) {
             mapReference.current.initMapClick(props.onMapClick);
           }
           break;
 
         case TypeObjectMap.Default:
           mapReference.current.removeMapClick()
           mapReference.current.removeLayer(TypeLayer.PolygonCreate);
           mapReference.current.removeLayer(TypeLayer.CircleCreate);
           mapReference.current.showLayer(TypeLayer.Units, false);
           break;
       }
     }
   }, [props.createTypeSelect, props.createMaxPoints])
 
   /**********************************************************
    * UseEffect to load units
    **********************************************************/
   useEffect(() => {
     const { units, noRealtime } = props
     if (units && mapReference.current.mapInstance() == true) {
       if (units.length > 0) {
         if (loadUnits == false || noRealtime == true) {
           setLoadUnits(true)
           setDataUnits(units)
           //Remove all items on the map
           if (noRealtime == true) {
             mapReference.current.removeAllbyItemObject(TypeObjectMap.Marker)
             setStyleClassMap('class-element-map-complete')
           }
 
           const { userMapPreferences } = props;
           let _unitCluster = false, _showLabel = false;
           if (userMapPreferences) {
             const { unitLabelShow, unitCluster } = userMapPreferences;
             _showLabel = unitLabelShow
             _unitCluster = unitCluster;
           }
           let arrayLatLng = null;
           arrayLatLng = addUnits(units);

           /*********************************************************
            * Update the icon to offline devices
           *********************************************************/
           units.forEach((vehicle) => {
             const isOffline = vehicle?.isOffline
             mapReference.current?.updateMarkerOfflineDevice(vehicle.id, isOffline)
           })
          
           if (_showLabel) {
             mapReference.current.addLabelMarkers(TypeLayer.UnitLabel, true, false)
           } else {
             mapReference.current.removeMapClusterListener()
           }
 
           //Refresh the cluster when settings is active
           if (_unitCluster) {
             mapReference.current.showMarkerCluster(true, true, _showLabel)
           }
 
           //is one point, center the map
           if (props.noRealtime == true) {
             //const keysElements = Object.keys(units)
             // mapReference.current.centerObjectOnMap(TypeObjectMap.Marker, keysElements[0])
             if (units?.length > 0) {
               mapReference.current.centerPointOnMap(units[0]?.latitude, units[0]?.longitude, 15);
             }
 
           }
 
           if (!loadUnits) {
             //mapReference.current.centerByFitBounds(arrayLatLng);
             mapReference.current.centerVehicles();
           }
         }
       }
       
       if(dataUnits.length > 0 && dataUnits.length != units.length){
        const idsForCurrentUnits = dataUnits.map((item) => item.id)
        const unitsToAdd = units.length > 0 
                          ? 
                          units?.map((item) => {
                              if(!idsForCurrentUnits.includes(item.id)){
                                return { ...item }
                              }
                              return null
                          }).filter(item => item !== null)
                          : []

        //to adds new units
        if(unitsToAdd.length > 0){
          addUnits(unitsToAdd);

          /*********************************************************
            * Update the icon to offline devices
          *********************************************************/
          unitsToAdd.forEach((vehicle) => {
            if(vehicle?.isOffline){
              mapReference.current?.updateMarkerOfflineDevice(vehicle?.id, vehicle?.isOffline)
            }
          })

        }

        //units to delete.
        const idsForNewUnits = units.map((item) => item.id);
        const unitsToDelete = dataUnits.map((item) => {
          if(!idsForNewUnits.includes(item.id)){
            return { ...item }
          }
          return null;
        }).filter(item => item !== null);
        unitsToDelete.length > 0 && unitsToDelete.forEach((removeUnit) => {
          //remove units on map
          mapReference.current.removeMapObject(removeUnit.id, TypeObjectMap.Marker);
        })

        //update the units state
        setDataUnits(units);
       }

     }
   }, [props?.units?.length])
 
   /**********************************************************
    * UseEffect to update units
    **********************************************************/
   useEffect(() => {
     const { updateUnit } = props
     if (updateUnit) {
       if (mapReference.current.mapInstance() == true) {
         updateUnitsMap([updateUnit])
       }
     }
   }, [props.updateUnit])
 
   const showInfoWindow = (centerUnitId, currentZoom) => {
     const mapObject = mapReference.current.centerObjectOnMap(TypeObjectMap.Marker, centerUnitId, 16, -190, false, currentZoom)
     //Show modal unit
     const instanceObject = mapObject?.instance ? Object.create(mapObject.instance) : null
     modalPopupEvent(mapObject, instanceObject)
   }
 
   /**********************************************************
    * Make the move units into the realtime maps
    **********************************************************/
   const updateUnitsMap = (data) => {
     data.forEach((item) => {
       try {
         if (item.state == 'update') {
           mapReference.current.updateObject({
             id: item.id,
             position: { lat: item.lat, lng: item.lng },
             label: item.label,
             heading: item.heading,
             color: validateEventColor(item)
           }, TypeObjectMap.Marker);
         } else if (item.state == 'create') {
           mapReference.current.addMapObjects(new MapObject().buildMarker({
             id: item.id,
             position: { lat: item.lat, lng: item.lng },
             layerName: TypeLayer.Units,
             label: item.label,
             heading: item.heading,
             color: validateEventColor(item)
           }));
         }
       } catch (ex) {
         //console.log('updateUnits.foreach() =>', ex)
       }
     })
   }
 
   /**********************************************************
    * Add the new units into the map
    **********************************************************/
   const addUnits = (data, type = TypeLayer.Units, paramColor = null, showLabel = false, isNumber = false) => {
     const arrayPositions = []
     try {
       if (data && data.length > 0) {
         let mapObjectMarker = []
         data.forEach((item, index) => {
           const position = validateLatLng(item);
           arrayPositions.push(position)
           const labelName = type == TypeLayer.Units ? cutNotes(validateLabel(item), 30) : validateLabel(item)
           if (position.lat && position.lng) {
             const id = item.id ? item.id : index
             const mapObject = new MapObject().buildMarker({
               id: id + '',
               deviceId: item?.deviceId,
               position,
               layerName: item?.layerName || type,
               label: isNumber && item.wayPoint ? item.wayPoint.toString() : labelName,
               showLabel: showLabel || item?.showLabel,
               heading: item?.heading,
               color: paramColor ? paramColor : validateEventColor(item),
               eventCode: item?.eventCode,
               eventName: item?.eventName,
               draggable: item?.draggable || false,
               updatePointers: item?.updatePointers || false,
               hasVideo: item?.hasVideo || false,
               hideMarker: item?.hideMarker || false,
               icon: item?.icon,
               value: item?.value || '',
               optimized: item?.optimized,
               hasChild: item?.hasChild || false,
               isRouteTracker: item?.isRouteTracker || false,
               trail: item?.trail || null,
               trailTimestamp: item?.unitTime || 0
             })
             mapObjectMarker.push(mapObject)
           }
         });
         mapReference.current.addMapObjects(mapObjectMarker)
       }
     } catch (e) {
       warningNotification('', e)
     }
     return arrayPositions;
   }
 
   /**********************************************************
    * UseEffect to update units
   **********************************************************/
   useEffect(() => {
     const { pointers, noCenterPoints } = props
     if (pointers && pointers.length > 0) {
       if (mapReference.current.mapInstance() == true) {
         mapReference.current.removeLayer(TypeLayer.Pointers)
         const arrayLatLng = addUnits(pointers, TypeLayer.Pointers)
         if(!noCenterPoints){
            if (pointers.length == 1) {
                if(!pointers[0]?.noZoom)
                  mapReference.current.centerObjectOnMap(TypeObjectMap.Marker, pointers[0].id)
            } else {
              mapReference.current.centerByFitBounds(arrayLatLng);
            }
         }
         
       }
     } else {
       if (pointers && pointers.length == 0) {
         mapReference.current.removeLayer(TypeLayer.Pointers)
       }
     }
   }, [props.pointers])
 
   /**********************************************************
    * Show the notifications into the realtime maps
   **********************************************************/
   const errorDrawRoute = (propsMap, messageNotification) => {
     //dispatch(props.addPointers([]));
     if(props?.errorMapRoutesError){
      dispatch(props.errorMapRoutesError());
      warningNotification(messageNotification);
     }

     props?.onEventMapRoutesError && props?.onEventMapRoutesError(propsMap);
   }
 
   /**********************************************************
    * Show toast to notifications in map
    **********************************************************/
   const warningNotification = (messageNotification, valueMessage = '') => {
     if (messages) {
       return NotificationManager.warning(
         valueMessage ? valueMessage : messages[messageNotification],
         messages['realtimemaps_title'],
         3000,
         null,
         null,
         'warning',
         null
       );
     }
   }
 
   const deleteRouteInMap = () => {
     mapReference.current.removeMapObject(nameRoute, TypeObjectMap.Routes)
     mapReference.current.removeLayer(TypeLayer.RouteLayer)
     mapReference.current.removeRouteListener()
   }
 
   /**********************************************************
    * Draw the Route into the realtime maps
    **********************************************************/
   useEffect(() => {
     const { directions, pointers, layerDrawRoute } = props
     if (!directions) {
       return
     }
     const { getDirections, options } = directions

     if (getDirections && pointers && pointers.length > 0) {
        if(options?.routeModule){

          //clear the other routes first
          const validateInstanceRouteOnMap = mapReference.current.getObject(TypeObjectMap.Routes, nameRoute)
          if(validateInstanceRouteOnMap && validateInstanceRouteOnMap.instance){
            validateInstanceRouteOnMap.instance.setMap(null)
            mapReference.current.removeMapObject(nameRoute, TypeObjectMap.Routes)
          } 

          if(validateInstanceRouteOnMap && validateInstanceRouteOnMap.mapRoute){
            validateInstanceRouteOnMap.mapRoute.removeRoute()
            validateInstanceRouteOnMap.mapRoute = null;
          }
        }
        
       const mapObjectRoute = mapReference.current.addMapObject(new MapObject().buildRoute({
         id: layerDrawRoute ? `layerRoute_${layerDrawRoute}` : nameRoute
       }))

       if (mapObjectRoute?.instance) {
         mapReference.current.showVehicleTrails(true) //remove markers and labels in map
         mapReference.current.drawRoute(props, mapObjectRoute, pointers, errorDrawRoute, options)
       }
     } else {
       if (pointers?.length == 0) {
          
          const objectDirectionRender = mapReference.current.getObject(TypeObjectMap.Routes, nameRoute)
          if(objectDirectionRender && objectDirectionRender.instance && objectDirectionRender.mapRoute){
            objectDirectionRender.mapRoute.removeRoute()
            objectDirectionRender.instance.setMap(null)
          } 
         !props.showSpeedOverride && mapReference.current.showVehicleTrails(false) //show again markers and labels in map
         //remove route elements into the realtime map
         mapReference.current.removeMapObject(nameRoute, TypeObjectMap.Routes)
         mapReference.current.removeLayer(TypeLayer.Pointers)
         //is new route remove elements on layer
         mapReference.current.removeLayer(TypeLayer.FSMRoute)
       }
     }
   }, [props.directions, props.pointers])
 
   /**********************************************************
    * Create or Remove units in realtime maps
    **********************************************************/
   useEffect(() => {
    showUnitsByPreferences()
   }, [props.hiddenTagsPreferences, props.hiddenTagsIds, props.loadingUnits, props.loadTagsVehicleList])

   const showUnitsByPreferences = () => {

    const { hiddenTagsPreferences, units, hiddenTagsIds } = props
     let arrayHiddenElements = []
     if (hiddenTagsPreferences && hiddenTagsPreferences?.length) {
       arrayHiddenElements = [...hiddenTagsPreferences]
     }
     if (hiddenTagsIds && hiddenTagsIds?.length) {
       let arrayHiddenUnits = hiddenTagsIds.map((group) => {
         if (group.visible == false) {
           return group.hiddenGroupIds
         }
       })
       arrayHiddenElements = [...arrayHiddenElements, ...arrayHiddenUnits]
     }
 
     if (arrayHiddenElements && arrayHiddenElements.length > 0 && units && units.length > 0) {
       //Remove all markers on the map
       mapReference.current.removeAllbyItemObject(TypeObjectMap.MarkerCluster)
       mapReference.current.showLayer(TypeLayer.Units, false)
       mapReference.current.removeLayer(TypeLayer.UnitLabel)
 
       let showAfterRemoveMarkersMap = [...units]
       units.map((item) => {
         let countsItemsToHide = item?.deviceTags.length;
         item?.deviceTags?.map((group) => {
           if (arrayHiddenElements.length > 0) {
             const findUnits = arrayHiddenElements.filter(x => x == group.id)
             if (findUnits.length > 0) {
               countsItemsToHide--;
             }
           }
         })
         if (countsItemsToHide == 0) {
           //Remove element of the map
           showAfterRemoveMarkersMap = showAfterRemoveMarkersMap.filter((unit) => unit.id != item.id)
         }
       })
 
       showAfterRemoveMarkersMap.forEach((unit) => {
         mapReference.current.showMapObject(unit.id, TypeObjectMap.Marker, true);
       })
       
     }else{
      mapReference.current.showLayer(TypeLayer.Units, true)
     }
     evalLabelAndClusterPreferences()

   }

   /**********************************************************
    * Eval preferences to cluster and labels on map
    **********************************************************/
   const evalLabelAndClusterPreferences = () => {
    if (props.userMapPreferences) {
      const { unitCluster, unitLabelShow } = props.userMapPreferences
      //Refresh the cluster when settings is active
      if (unitCluster) {
        if (unitLabelShow) {
          mapReference.current.showMarkerCluster(true, false, unitLabelShow)
        } else {
          mapReference.current.removeMapClusterListener()
        }
      }
      //Show or hide labels on the markers
      if (unitLabelShow && !props.showVehicleTrails) {
        if (!unitCluster) {
          mapReference.current.addLabelMarkers()
        } else {
          mapReference.current.showOrHideLabelsOnCluster()
        }
      } else {
        mapReference.current.removeLayer(TypeLayer.UnitLabel)
        if (unitCluster && !unitLabelShow) {
          mapReference.current.removeMapClusterListener();
        }
      }
    }
   }
 
   /**********************************************************
    * Show the vehicle trails options
    **********************************************************/
   useEffect(() => {
 
     if (props.showVehicleTrails && mapReference) {
       mapReference.current.showVehicleTrails(props.showVehicleTrails);
       mapReference.current.hideMarkerModal();
       if (!props.playback && props.vehicleTrails && props.vehicleTrails.items && props.vehicleTrails.items.length > 0) {
         const { userMapPreferences } = props
         let showPolyLine = true, showTrailPointNumber = false
         if (userMapPreferences) {
           const { trailConnectingLine, trailShowWaypointNumbers } = userMapPreferences
           showPolyLine = trailConnectingLine == undefined ? true : trailConnectingLine
           showTrailPointNumber = trailShowWaypointNumbers
         }
         //const vehicleTrailsItems = props.vehicleTrails.items.filter((item) => !item.isShow)
         const vehicleTrailsItems = props.vehicleTrails.items

         let arrayLatLng = null;
         if(showPolyLine){
          arrayLatLng = addUnits(vehicleTrailsItems, TypeLayer.VechileTrails, null, showTrailPointNumber, true);
         }else{
          const allTrailMarkersView = vehicleTrailsItems.map((item) => { return { ...item, hideMarker: false } })
          arrayLatLng = addUnits(allTrailMarkersView, TypeLayer.VechileTrails, null, showTrailPointNumber, true);
         }
    
         if (showTrailPointNumber) {
           mapReference.current.addLabelMarkers(TypeLayer.VechileTrails, true)
         }
 
         const trailRoute = props.vehicleTrails.trailRoute
         //const arrayCoordinates = props.vehicleTrails.items.map((element) => {
         const arrayCoordinates = trailRoute.map((element) => {
           return validateLatLng(element, true);
         })
         let color = '', indexPolyline = 0;
         let arrayPositions = [];
         trailRoute.forEach((item, index) => {
          if(color != item.color && index > 0 || item?.cutTrailLine){            
            indexPolyline +=1;
            //add Polyline
            mapReference.current.addMapObject(new MapObject().buildPolyLine({
              id: `polyline_${indexPolyline}`,
              coordinates: arrayPositions,
              layerName: TypeLayer.VechileTrails,
              label: 'Polyline Vehicle Trails',
              heading: 0,
              color: color,
              fillOpacity: 0.6,
              strokeWeight: 8
            }));

            arrayPositions = [];
            //if item.cutTrailLine not continues the line for validation of distance.
            !item?.cutTrailLine && arrayPositions.push(arrayCoordinates[index - 1]); 
            arrayPositions.push(arrayCoordinates[index]);
          }else{
            arrayPositions.push(arrayCoordinates[index])
          }
           
          color = item.color;
         })

         if(arrayPositions.length > 0){
            mapReference.current.addMapObject(new MapObject().buildPolyLine({
              id: `polyline_${indexPolyline}`,
              coordinates: arrayPositions,
              layerName: TypeLayer.VechileTrails,
              label: 'Polyline Vehicle Trails',
              heading: 0,
              color: color,
              fillOpacity: 0.6,
              strokeWeight: 8
            }));
         }
 
         
         if (!showPolyLine) {
            //hidden by tipe layer
           mapReference.current.showMapObjectByLayer(TypeObjectMap.Polyline, TypeLayer.VechileTrails, false)
         }
 
         if(arrayLatLng)
          mapReference.current.centerByFitBounds(arrayLatLng);
       }
     } else if (!props.showVehicleTrails && props.vehicleTrails && props.vehicleTrails.items && !props.vicinityTool) {
       mapReference.current.showVehicleTrails(props.showVehicleTrails);
       mapReference.current.centerObjectOnMap(null, null, 5)
     }
   }, [
    props.vehicleTrails, 
    props.showVehicleTrails,
    props.vicinityTool, 
    props.playback, 
    props?.userMapPreferences?.trailConnectingLine, 
    props?.userMapPreferences?.trailShowWaypointNumbers])
 
   /**********************************************************
    * Update vehicle trails preferences
    **********************************************************/
   useEffect(() => {
 
     const { userMapPreferences } = props
     let showPolyLine = true, showTrailPointNumber = false
     if (userMapPreferences) {
       const { trailConnectingLine, trailShowWaypointNumbers, unitLabelShow, unitCluster } = userMapPreferences
 
       showPolyLine = trailConnectingLine == undefined ? true : trailConnectingLine
       showTrailPointNumber = trailShowWaypointNumbers
 
       mapReference.current.showMapObjectByLayer(TypeObjectMap.Polyline, TypeLayer.VechileTrails, showPolyLine)
       if (showTrailPointNumber) {
         mapReference.current.addLabelMarkers(TypeLayer.VechileTrails, true)
       }
 
       if (unitLabelShow && !props.showVehicleTrails) {
         if (!unitCluster) {
           mapReference.current.addLabelMarkers()
         } else {
           mapReference.current.showOrHideLabelsOnCluster()
         }
       } else {
         mapReference.current.removeLayer(TypeLayer.UnitLabel)
         if (unitCluster && !unitLabelShow) {
           mapReference.current.removeMapClusterListener();
         }
       }
 
     }
   }, [props.userMapPreferences])
 
   /**********************************************************
    * Show the vehicle trails layer options
    **********************************************************/
   useEffect(() => {
     if (props.showVehicleTrailsLayer && mapReference) {
       if (props.vehicleTrailsLayer && props.vehicleTrailsLayer.length > 0) {
         props.vehicleTrailsLayer.map((vehicleTrail, index) => {
           const arrayLatLng = addUnits(vehicleTrail.items, TypeLayer.VehicleTrailLayer, vehicleTrail?.color);
           const arrayCoordinates = vehicleTrail?.items && vehicleTrail.items.map((element) => {
             return validateLatLng(element, true);
           })
           //add Polyline
           mapReference.current.addMapObject(new MapObject().buildPolyLine({
             id: `polyline_${index}`,
             coordinates: arrayCoordinates,
             layerName: TypeLayer.VehicleTrailLayer,
             label: 'Polyline Vehicle Trails',
             heading: 0,
             color: vehicleTrail?.color,
             strokeColor: vehicleTrail?.color, // Color del borde
             lineWidth: 8, // Grosor del borde
             fillColor: 'yellow', // Color del centro
             fillOpacity: 0.5 // Opacidad del centro
           }));
           mapReference.current.centerByFitBounds(arrayLatLng);
         })
       } else {
         mapReference.current.removeLayer(TypeLayer.VehicleTrailLayer);
       }
     } else if (!props.showVehicleTrails && props.vehicleTrails && props.vehicleTrails.items) {
       mapReference.current.showVehicleTrails(props.showVehicleTrails);
       mapReference.current.centerObjectOnMap(null, null, 5)
     }
   }, [props.vehicleTrailsLayer, props.showVehicleTrailsLayer])
 
 
   /**********************************************************
    * Load the landmarks objects into map
    **********************************************************/
   useEffect(() => {
     const { jsonLandmark, landmarkTags, loadObjectLandmarkOnMap } = props
     if (loadObjectLandmarkOnMap && jsonLandmark && jsonLandmark.length > 0) {
       mapReference.current.removeMapObjects(TypeObjectMap.Landmark)
       try {
         let mapObjectLandmark = createObjectsLandmarksGeofence(jsonLandmark, landmarkTags, true)
         mapReference.current.addMapObjectsWihoutInstance(mapObjectLandmark)
         //Add the objects in map
         mapReference.current.addMapObjectsInstancebyLayer('landmarkSettings', TypeLayer.LandmarkMarker)
 
         //eval preferences
         evalPreferencesLandmarksAndGeofences()
       } catch (e) {
         console.log('error', e.toString())
       }
     }
   }, [props.jsonLandmark, props.landmarkTags, props.loadObjectLandmarkOnMap])
 
   /**********************************************************
    * Load the Geofences objects into map
    **********************************************************/
   useEffect(() => {
     const { jsonGeofence, geofenceTags, loadObjectGeofenceOnMap } = props
     if (loadObjectGeofenceOnMap && jsonGeofence && jsonGeofence.length > 0) {
       try {
         mapReference.current.removeMapObjects(TypeObjectMap.Geofence)
         //get first group
         let groupsIds = 0, idMinGroup = 0, startGroup = 0
         if(geofenceTags && geofenceTags.length > 0){
          groupsIds = geofenceTags.map((item) => parseInt(item.id))
          idMinGroup = Math.min(...groupsIds)
          startGroup = geofenceTags.find((item) => item.id == idMinGroup)
         }
         //create the geofences.
         let mapObjectGeofence = createObjectsLandmarksGeofence(jsonGeofence, geofenceTags, false, startGroup)
         mapReference.current.addMapObjectsWihoutInstance(mapObjectGeofence)
         //Add the objects in map
         mapReference.current.addMapObjectsInstancebyLayer('geofenceSettings', TypeLayer.Geofence)
 
         //eval preferences
         evalPreferencesLandmarksAndGeofences()
       } catch (e) {
         console.log('error', e.toString())
       }
     }
   }, [props.jsonGeofence, props.geofenceTags, props.loadObjectGeofenceOnMap])
 
   /**********************************************************
    * Create the landmarks and geofences objects into the map
    **********************************************************/
   const createObjectsLandmarksGeofence = (data, landmarkOrGeofenceTags = null, isLandmark = true, startGroup = null) => {
     let mapObjectLandGeo = []
     for (let i = 0; i < data.length; i++) {
       const item = data[i];
 
       let group = null
       if (landmarkOrGeofenceTags) {
         let valid = item?.tagsId
         if (valid) {
           const tagsArray = item.tagsId.split(',')
           group = landmarkOrGeofenceTags.find((element) => tagsArray.includes(element.id.toString()));
         }
 
         //this validation is only for landmarks
         if (valid && !group && isLandmark) continue; // no have permision
 
         if (!isLandmark && !group) {
           group = startGroup
         }
       }
 
       let position = validateLatLng(item, true);
       let childType = null;
       if (item.shape) {
         childType = item.shape == "circle" ? TypeObjectMap.Circle : TypeObjectMap.Polygon
       } else {
         childType = item.radius > 0 ? TypeObjectMap.Circle : TypeObjectMap.Polygon
       }
 
       let coordinates = []
       if (childType == TypeObjectMap.Polygon && item.coordinates) {
         coordinates = item.coordinates.map(element => {
           return { lat: parseFloat(element.latitude), lng: parseFloat(element.longitude) }
         })
         if ((position.lat == '' || position.lng == '') && coordinates && coordinates.length > 0) {
           position.lat = coordinates[0].lat.toString();
           position.lng = coordinates[0].lng.toString();
         }
       }
 
       if ((!position.lat || !position.lng) && item.radius > 0 && item.coordinates && item.coordinates.length > 0) {
         position = { lat: item.coordinates[0].latitude, lng: item.coordinates[0].longitude }
       }
 
       const landmarkGeoColor = item.color ? item.color : group?.color || '8677D9' //add the default color to pallete
       let landmarkIcon = group && group.iconName ? group.iconName : '' //get icon from landmark group
       landmarkIcon = item.iconName ? item.iconName : getIconGroupWithAnotherColor(landmarkIcon, item.color)  // get icon from landmark
 
       if (position.lat && position.lng) {
         let mapObject = null;
         if (isLandmark) {
           mapObject = new MapObject().buildLandmark({
             id: `landmark_${item.id}`,
             index: item.id,
             position,
             groupId: item && item.tagsId ? item.tagsId.split(',') : [],
             url: landmarkIcon,
             radius: radiusFeetToMeters(item.radius),
             layerName: TypeLayer.LandmarkMarker,
             label: cutNotes(item.name, 30),
             showLabel: false,
             hasChild: true,
             childType: childType,
             coordinates: item.radius == 0 ? coordinates : [],
             color: `#${landmarkGeoColor}`
           })
         } else {
           //Geofence
           mapObject = new MapObject().buildGeofence({
             id: `geofence_${item.id}`,
             index: item.id,
             position,
             groupId: item && item.tagsId ? item.tagsId.split(',') : [],
             radius: item.radius,
             layerName: TypeLayer.Geofence,
             label: cutNotes(item.name, 30),
             showLabel: false,
             hasChild: false,
             childType: childType,
             coordinates: coordinates,
             color: `#${landmarkGeoColor}`
           })
         }
 
         mapObjectLandGeo.push(mapObject)
       }
     };
     return mapObjectLandGeo;
   }
 
   /**********************************************************
      * Create the modal popup event
      **********************************************************/
   const modalPopupEvent = (mapObject, instance = null, mapInstance = null) => {
     if (props.noRealtime == true && !props.isEtaLink) {
       return;
     }
 
     const instanceMarker = instance || mapObject?.instance
     if (instanceMarker) {
       let type;
       let position;
       switch (mapObject.layerName) {
         case TypeLayer.Units:
           type = "Vehicle";
           if(mapObject?.instance?.getPosition()?.lng()){
            position = {lat: mapObject?.instance?.getPosition().lat(), lng: mapObject?.instance?.getPosition().lng()}; 
           }else{
            position = mapObject?.position;
           }
           break;
         case TypeLayer.VechileTrails:
           type = "Trail";
           position = mapObject?.position;
           break;
         case TypeLayer.Landmark:
           type = "Landmark";
           position = {
             lat: mapObject?.position?.lat(),
             lng: mapObject?.position?.lng(),
           }
           break;
         case TypeLayer.Geofence:
           type = "Geofence";
           position = {
             lat: mapObject?.position?.lat(),
             lng: mapObject?.position?.lng(),
           }
           break;
 
         case TypeLayer.MapSearch:
           type = "MapSearch";
           position = {
             lat: mapObject?.position?.lat(),
             lng: mapObject?.position?.lng(),
           }
           break;
        case TypeLayer.FSMRoute:
          type = "RoutePoint";
          position = mapObject?.position;
          break;
       }
 
       let infoBubble = (<span></span>);
 
       if (type) {
         infoBubble = (
           <InfoBubble
             {...mapObject}
             mapReference={mapReference}
             closeInfoBubble={mapReference.current.hideMarkerModal}
             type={type}
             showStreetView={(container, latLng, callback) => {
               const instanceMap = mapReference?.current ? mapReference : mapInstance;
               if (instanceMap?.current) {
                 instanceMap.current.showStreetView(container, latLng, callback);
               }
             }}
             isEtaLink={props.isEtaLink}
           />
         );
       }
 
       if (mapReference?.current) {
         let currentZoom = mapReference?.current?.getCurrentZoom();
         mapReference?.current?.showMarkerModal(instanceMarker, mapObject, infoBubble, position, currentZoom);
         //mapReference?.current?.centerPointOnMap(position?.lat, position?.lng, currentZoom, -190);
       } else if (mapInstance) { //TODO why instance is null
         let currentZoom = mapInstance?.current?.getCurrentZoom();
         mapInstance?.current?.showMarkerModal(instanceMarker, mapObject, infoBubble, position, currentZoom);
         //mapInstance?.current?.centerPointOnMap(position?.lat + 0.0001, position?.lng, currentZoom, -190);
       }
     }
   }
 
 
   let classMap = styleClassMap ? styleClassMap : 'class-element-map-complete'
   classMap = props.generatePrint ? 'class-element-map-print' : classMap
   if(props.classNameToMap) classMap = props.classNameToMap
   /**********************************************************
    * Return the control on map
    **********************************************************/
 
   let messagesToMap = new Array();
   if(messages){
     messagesToMap['kilometers'] = messages['kilometers']
     messagesToMap['miles'] = messages['miles']
     messagesToMap['Day'] = messages['Day']
     messagesToMap['Days'] = messages['Days']
     messagesToMap['hour'] = messages['hour']
     messagesToMap['hours'] = messages['hours']
     messagesToMap['min'] = messages['min']
     messagesToMap['mins'] = messages['mins']
   } 

   const showPreferences = Boolean((props?.prefHiddenLandmarks?.length > 0) || (props?.prefHiddenGeofences?.length > 0) || (props?.hiddenTagsPreferences?.length > 0)) || false;
 
   //console.log("dataUnits", dataUnits)
   if (props.mapProvider == MapProvider.Google) {
     return (
       <div style={ !props.noRealtime ? { display: 'block', position: 'absolute'} : {}}>
         <div style={!props.noRealtime ? { height: '100vh', width: '100vw' } : { height: props.height || '90vh', width: '100%' }}>
           {
             props.loading
             &&
             <LinearProgress color="primary" />
           }
           <div id={'mapToPrint'} style={!props.generatePrint ? { height: props.height || 'calc(100% - 50px)' } : { height: '100%', width: '100%' }} className={!props.noRealtime || props?.classNameToMap ? classMap : ''} >
             <GoogleProvider
               ref={mapReference}
               {...props}
               //permissions
               weatherPermision={props?.weatherPermision}
               animationTrailPermission={props?.animationTrailPermission}

               //other props
               isKph={props?.isKph}
               messages={messagesToMap}
               locale={props?.locale || 'en'}
               isMobile={props?.isMobile || false}
               enableTraffic={true}
               enableCluster={true}
               generatePrint={props.generatePrint}
               modalPopupEvent={modalPopupEvent}
               onDragMarkerEvent={onDragMarkerEvent}
               onMarkerRightClick={onMarkerRightClick}
               showUnitsByPreferences={showUnitsByPreferences}
               showEtaLink={props.showEtaLink}
               legends={<LegendsToggle />}
               speedOverrideControl={props.showSpeedOverride && <SpeedOverrideMap />}
               routesInfoMapControl={props.showRoutesInfoMap && <RoutesInfoMap />}

               /*TODO: make implementation
               weather={<WeatherToggle />} */
               layer={(!props.hiddenLayerToggle || !props.noRealtime) && <LayerToggle changeMapType={changeMapType} />}
               /* inputSearchMap={<InputSearchMap useIdClearBtn />} */
               dataUnits={dataUnits}
               inputSearchMap={<MapSearchBox
                 units={dataUnits}
                 modalPopupEvent={modalPopupEvent}
                 showInfoWindow={showInfoWindow}
                 mapRef={mapReference} />}
               tagFilterApplied={showPreferences ? <TagFilterAppliedOnMap /> : <></>}
               mapSettings={messages ? <DrawMapSettings mapRef={mapReference} messages={messages} /> : <></>}
               mPagination={props.showVehicleTrails && messages
                 ?
                 <MapPagination
                   mapRef={mapReference}
                   messages={messages}
                   preferences={props.userMapPreferences} />
                 : <></>}
               enableVehicleTrails={props.showVehicleTrails}
               drawLayerMap={props.messages ? <DrawLayerMap messages={props.messages} /> : <></>}
               zoomVehicles={props.showButtonZoomVehicles ? <ButtonFitBoundsVehicles mapRef={mapReference} /> : <></>}
               drawPolygonMap={props.messages ? <DrawPolygonMap messages={props.messages} mapRef={mapReference} /> : <></>}
               panelEtaLink={props.showEtaLink ? <PanelEtaLink messages={props.messages} mapRef={mapReference} pointers={props.pointers} /> : <></>}
               /* onBoundsChanged={() => {
                 LogManagerConsole("Map", "onBoundsChanged")
                 let unitsOnBounds = mapReference.current?.dataUnits?.filter(x => {
                   return mapReference.current?.mapItem.getBounds().contains({
                     lat: x.lat,
                     lng: x.lng
                   })
                 })
                 LogManagerConsole("Map", "onBoundsChanged", unitsOnBounds)
                 if (props.onBoundsChanged) props.onBoundsChanged(unitsOnBounds)
               }} */
             >
             </GoogleProvider>
           </div>
         </div>
         {/* to print directons on map */}
         <div>
           <DirectionsMap print />
         </div>
         <Modal
          open={visibleForm}
          loading={loadingCreateClip}
          className="slider-clip"
          size="large">
          <ModalBody className="clip-request-modal-body">
            <SliderRangePanel />
          </ModalBody>
        </Modal>
      </div>
    )
  } else {
    return <div>Not supported</div>
  }

})

export default ControllerMaps
