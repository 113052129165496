export default {
    dataExport_xmlOutputLabel: "Salida XML de Datapump",
    dataExport_xmlOutputDate: "Periodo",
    dataExport_url: "Generar URL",
    dataExport_dataPumpFeedDescription: "Datapump feed por fechas",
    dataExport_dataFeed1Description: "Datapump feed (actualizaciones desde la ultima solicitud de unidad/etiqueta seleccionada, limitado a 400 events por unidad, no recomendado para grandes grupos de unidades)",
    dataExport_dataFeed2Description: "Datapump feed (ultimo evento reportado de unidad/etiqueta seleccionada)",
    dataExport_dataFeed3Description: "Datapump feed (ultimo evento reportado de unidad/etiqueta seleccionada) hora en UTC",
    dataExport_dataPumpFeedLabel: "Datapump Feed",
    dataExport_dataFeed1Label: "Feed 1",
    dataExport_dataFeed2Label: "Feed 2",
    dataExport_dataFeed3Label: "Feed 3",
    dataExport_FooterLabel: "**Se recomienda mantener un intervalo minimo de 5 minutos entre peticiones",
};