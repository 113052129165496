export default {
    "FSM_SETTINGS_ROUTE_ON_SITE": "On Site",
    "FSM_SETTINGS_ROUTE_ON_ROUTE": "On Route",
    "FSM_SETTINGS_ROUTE_PENDING": "Pending",
    "FSM_SETTINGS_ROUTE_DONE": "Done",
    "FSM_SETTINGS_ROUTES": "Routes",
    "FSM_SETTINGS_ROUTE_IMPORT_TITLE": "Import FSM Route Stop",
    "FSM_SETTINGS_ROUTE_IMPORT_ERROR": "Please Select a Route",
    "FSM_SETTINGS_ROUTE_PREVIEW": "Preview",
    "FSM_SETTINGS_ROUTE_CREATE_DONE": "FSM Route Stop Created",
    "FSM_SETTINGS_ROUTE_DELETE": "FSM Route Stop Deleted",
    "FSM_SETTINGS_ROUTE_TITLE": "Routes",
    "FSM_SETTINGS_ROUTE_DELETE_BODY": "Are you sure you want to delete the following FSM Route Stop Selections?",
    "FSM_SETTINGS_ROUTE_DELETE_TITLE": "FSM Route Stop Selections",
    "FSM_SETTINGS_ROUTE_NEW": "Create new FSM Route Stop",
    "FSM_SETTINGS_ROUTE_CREATE_LOCATION_ERROR": "Please Select a Location",
    "FSM_SETTINGS_ROUTE_IMPORT_DONE": "The FSM Route Stop were imported successfully",

    "FSM_SETTINGS_ROUTE_LANDMARK": "Select Landmark",
    "FSM_SETTINGS_ROUTE_MORE_STOPS": "Add another stop",
    "FSM_SETTINGS_ROUTE_LAT_LONG_WRONG": "The latitud or longitud could has some error, try again",
    "FSM_TITLE_STOP": 'Stop Title',
    "FSM_SETTINGS_ROUTE_ERROR_CREATE": "Please select a point in the map or type and select address for create a new FSM Route Stop"

    
};