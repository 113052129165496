import React, { Fragment } from 'react';
import { endpoints } from 'Core/defaultValues';
import { useIntl } from 'react-intl';
import { Grid, Typography  } from '@mui/material';

import './style.scss'

const PanelEtaLink = (props) => {

  const { pointers } = props

  const pathFile = `https://s3.amazonaws.com/${endpoints.REPOSITORY_CODE}`

  return (
    <Fragment>
      <div className="panel-eta-container">
        <Grid
          container
          direction="row"
          wrap="nowrap"
          justifyContent="flex-start"
          alignItems="top"
          spacing={1}
        >
          <Grid item>
            <img style={{ position: 'relative', width: 24, height: 24, marginTop: 1 }} alt="start" src={`${pathFile}/icons/maps/place/DE350D.png`} />
          </Grid>
          <Grid item xs>
            <Typography style={{ textAlign: 'left' }}>{pointers && pointers.length > 0 && pointers[0].label || ''}</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="top"
          spacing={1}
        >
          <Grid item>
            <img style={{ position: 'relative', width: 24, height: 24, marginTop: 1 }} alt="end "src={`${pathFile}/icons/maps/place/018759.png`} />
          </Grid>
          <Grid item xs>
            <Typography style={{ textAlign: 'left' }}>{pointers && pointers.length > 1 && pointers[1].label || ''}</Typography>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  )
}

export default PanelEtaLink;