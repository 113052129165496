import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager"

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    serviceUnitId: null,
    maintenanceServiceUnit: null,
    error: null,
    serviceItems: [],
    newServiceItemResult: null,
    saveCompleteServiceResult: null,
    maintenanceServiceUnitLogItem: null,
    updateCompleteServiceResponse: null
};

const route = "modules/maintenance/modals/completeMaintenanceService/redux/slice";

export const maintenanceMCMSGetMaintenanceServiceUnit = createAsyncThunk("maintenance/completeMaintenanceService/getMaintenanceServiceUnit", async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientQuery(
            `query ($id: Int!){
                data: getMaintenanceServiceUnit(id: $id){
                    id
                    serviceId
                    service {
                        id
                        title
                        notes
                        miles
                        milesBefore
                        milesAfter
                        days
                        daysBefore
                        daysAfter
                        engineHours
                        engineHoursBefore
                        engineHoursAfter
                        recurrent
                        estimatedCost
                        unitsCount
                        subscribers {
                            id
                            contactId
                            contact {
                              id
                              title
                              address
                              type
                            }
                            alert
                            alertBefore
                            alertAfter
                        }
                        fileIds
                        files {
                          id
                          originalName
                          fileKey
                          repositoryCode
                        }
                        serviceItems{
                            id
                            name
                            parts
                            labor
                            subtotal
                        }
                    }
                    subscribers {
                        id
                        contactId
                        contact {
                          id
                          title
                          address
                          type
                        }
                        alert
                        alertBefore
                        alertAfter
                    }
                    serviceUnitItems{
                        id
                        name
                        parts
                        labor
                        subtotal
                    }
                    unitId
                    lastReadingId
                    lastMaintenanceDate
                    lastMaintenanceOdometer
                    lastMaintenanceEngineHours
                    nextServiceDate
                    nextServiceOdometer
                    nextServiceEngineHours
                    status
                    notes
                    completed
                    fileIds
                    files {
                      id
                      originalName
                      fileKey
                      repositoryCode
                    }
                }
            }`,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response.data
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceMCMSGetMaintenanceServiceUnit');
        return rejectWithValue(exc);
    }
}
)

export const maintenanceMCMSSearchMaintenanceServiceItems = createAsyncThunk("maintenance/completeMaintenanceService/searchMaintenanceServiceItems", async ({ }, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientQuery(
            `query ($openSearch: String){
                data: searchMaintenanceServiceItems(openSearch: $openSearch){
                  id
                  name
                }
            }`,
            {
                openSearch: ''
            },
            endpoints.GRAPHQL_GENERAL
        );
        return response.data
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceMCMSSearchMaintenanceServiceItems');
        return rejectWithValue(exc);
    }
}
)


export const maintenanceMCMSCreateMaintenanceServiceItem = createAsyncThunk("maintenance/completeMaintenanceService/createMaintenanceServiceItem", async ({ name }, { rejectWithValue, dispatch }) => {
    try {
        let response = await clientMutation(
            `mutation ($name: String!) {
                data: createMaintenanceServiceItem(name: $name){
                    id
                    name
                }
            }`,
            {
                name
            },
            endpoints.GRAPHQL_GENERAL
        );
        return response.data

    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceMCMSCreateMaintenanceServiceItem');
        return rejectWithValue(exc);
    }
}
)

export const maintenanceMCMSCreateMaintenanceServiceUnitLog = createAsyncThunk("maintenance/completeMaintenanceService/createMaintenanceServiceUnitLog", async (payload, { rejectWithValue, dispatch }) => {
    try {
        const {
            serviceUnitId,
            maintenanceDate,
            notifyToInput,
            actualCost,
            notes,
            readingId,
            engineHours,
            odometer,
            nextServiceDate,
            nextServiceOdometer,
            nextServiceEngineHours,
            completed,
            fileIds
        } = payload;

        let response = await clientMutation(
            `
                mutation ($serviceUnitId: Int! $maintenanceDate: String! $actualCost: Float $notes: String $readingId: String $engineHours: Float $odometer: Float $nextServiceDate: String $nextServiceOdometer: Float $nextServiceEngineHours: Float $completed: Boolean! $fileIds: [Int] $notifyTo: [NotifyToInput]) {
                    data: createMaintenanceServiceUnitLog(
                        serviceUnitId: $serviceUnitId
                        maintenanceDate: $maintenanceDate
                        actualCost: $actualCost
                        notes: $notes
                        readingId: $readingId
                        engineHours: $engineHours
                        odometer: $odometer
                        nextServiceDate: $nextServiceDate
                        nextServiceOdometer: $nextServiceOdometer
                        nextServiceEngineHours: $nextServiceEngineHours
                        completed: $completed
                        fileIds: $fileIds
                        notifyTo: $notifyTo
                    ){
                        id
                        serviceUnitId
                    }
                }`,
            {
                serviceUnitId,
                maintenanceDate,
                completed,
                notifyTo: notifyToInput,
                readingId,
                notes,
                actualCost,
                engineHours,
                odometer,
                nextServiceDate,
                nextServiceOdometer,
                nextServiceEngineHours,
                fileIds
            },
            endpoints.GRAPHQL_GENERAL
        );
        return response.data

    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceMCMSCreateMaintenanceServiceItem');
        return rejectWithValue(exc);
    }
}
)

export const maintenanceMCMSCreateMaintenanceServiceUnitLogItem = createAsyncThunk("maintenance/completeMaintenanceService/createMaintenanceServiceUnitLogItem", async (payload, { rejectWithValue, dispatch }) => {
    try {
        const { items, serviceUnitLogId,itemsIncluded } = payload;
        let mutations = [];

        if (!items || !items.length) {
            throw 'Error while creating Items Units Logs ';
        }

        items.forEach((service, index) => {
            mutations.push(`
            data${index}: createMaintenanceServiceUnitLogItem(
              serviceUnitLogId: ${serviceUnitLogId},
              serviceItemId: ${service.id},
              partsCost: ${parseFloat(service.parts)},
              laborCost: ${parseFloat(service.labor)},
              included: ${itemsIncluded[`included-${index}`]?true:false}
            ){
              id
              serviceUnitLogId
              serviceItemId
              createdOn
              createdBy
              updatedOn
              updatedBy
              included
            }
        `);
        });
        let mutation = `mutation { ${mutations.join(' ')} }`;

        let response = await clientMutation(
            mutation,
            {
                serviceUnitLogId,
                serviceItemId: 0,
                partsCost: 0,
                laborCost: 0,
            },
            endpoints.GRAPHQL_GENERAL_V2
        );

        return response.data0

    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, route, 'maintenanceMCMSCreateMaintenanceServiceItem');
        return rejectWithValue(exc);
    }
}
)

export const maintenanceMCMSCreateEditMaintenanceServiceUnit = createAsyncThunk('maintenance/completeMaintenanceService/createEditMaintenanceServiceUnit', async (payload, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientMutation(
            `mutation (
                ${payload.id ? '$id: Int!' : ''}
                ${!payload.id ? '$serviceId: Int!' : ''}
                ${!payload.id ? '$unitId: Int!' : ''}
                $lastReadingId: String
                $lastMaintenanceDate: ${payload.id ? 'String' : 'String!'}
                ${payload.maintenanceCompleted ? '$maintenanceCompleted: String' : ''}
                $lastMaintenanceOdometer: Float
                $lastMaintenanceEngineHours: Float
                $nextServiceDate: String
                $nextServiceOdometer: Float
                $nextServiceEngineHours: Float
                $notes: String,
                $estimatedCost: Float,
                $fileIds: [Int]
                $subscribers: [AlertSubscriberInput]
                $items: [MaintenanceServiceUnitItemsInput]

            ) {
                data: ${payload.id ? 'updateMaintenanceServiceUnit' : 'createMaintenanceServiceUnit'} (
                    ${payload.id ? 'id: $id,' : ''}
                    ${!payload.id ? 'serviceId: $serviceId,' : ''}
                    ${!payload.id ? 'unitId: $unitId,' : ''}
                    lastReadingId: $lastReadingId,
                    lastMaintenanceDate: $lastMaintenanceDate,
                    ${payload.maintenanceCompleted ? 'maintenanceCompleted: $maintenanceCompleted,' : ''},
                    lastMaintenanceOdometer: $lastMaintenanceOdometer,
                    lastMaintenanceEngineHours: $lastMaintenanceEngineHours,
                    nextServiceDate: $nextServiceDate,
                    nextServiceOdometer: $nextServiceOdometer,
                    nextServiceEngineHours: $nextServiceEngineHours,
                    notes: $notes,
                    estimatedCost: $estimatedCost,
                    fileIds: $fileIds,
                    subscribers: $subscribers,
                    items: $items
                ) {
                    id
                }
            }`,
            payload,
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.data
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceMCMSCreateEditMaintenanceServiceUnit');
        return rejectWithValue(exc)
    }
});

export const maintenanceCompleteMaintenanceServiceRedux = createSlice({
    name: "maintenance/completeMaintenanceService",
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openModalCompleteMaintenance(state, action) {
            return {
                ...state,
                open: action.payload.open,
                serviceUnitId: action.payload.serviceUnitId,
            }
        },
    },
    extraReducers: (builder) => {
        //maintenanceMCMSGetMaintenanceServiceUnit
        builder.addCase(maintenanceMCMSGetMaintenanceServiceUnit.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceMCMSGetMaintenanceServiceUnit.fulfilled, (state, action) => {
            state.maintenanceServiceUnit = action.payload
            state.loading = false;
        });

        builder.addCase(maintenanceMCMSGetMaintenanceServiceUnit.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //maintenanceMCMSSearchMaintenanceServiceItems
        builder.addCase(maintenanceMCMSSearchMaintenanceServiceItems.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceMCMSSearchMaintenanceServiceItems.fulfilled, (state, action) => {
            state.serviceItems = action.payload
            state.loading = false;
        });

        builder.addCase(maintenanceMCMSSearchMaintenanceServiceItems.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //maintenanceMCMSCreateMaintenanceServiceItem
        builder.addCase(maintenanceMCMSCreateMaintenanceServiceItem.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceMCMSCreateMaintenanceServiceItem.fulfilled, (state, action) => {
            state.newServiceItemResult = action.payload
            state.loading = false;
        });

        builder.addCase(maintenanceMCMSCreateMaintenanceServiceItem.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //maintenanceMCMSCreateMaintenanceServiceUnitLog
        builder.addCase(maintenanceMCMSCreateMaintenanceServiceUnitLog.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceMCMSCreateMaintenanceServiceUnitLog.fulfilled, (state, action) => {
            state.saveCompleteServiceResult = action.payload
            state.loading = false;
        });

        builder.addCase(maintenanceMCMSCreateMaintenanceServiceUnitLog.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //maintenanceMCMSCreateMaintenanceServiceUnitLogItem
        builder.addCase(maintenanceMCMSCreateMaintenanceServiceUnitLogItem.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceMCMSCreateMaintenanceServiceUnitLogItem.fulfilled, (state, action) => {
            state.maintenanceServiceUnitLogItem = action.payload
            state.loading = false;
        });

        builder.addCase(maintenanceMCMSCreateMaintenanceServiceUnitLogItem.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(maintenanceMCMSCreateEditMaintenanceServiceUnit.pending, (state, action) => {
            state.loading = true
        });

        builder.addCase(maintenanceMCMSCreateEditMaintenanceServiceUnit.fulfilled, (state, action) => {
            state.updateCompleteServiceResponse = action.payload,
            state.loading = false
        });

        builder.addCase(maintenanceMCMSCreateEditMaintenanceServiceUnit.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        });
    }
})

const { actions, reducer } = maintenanceCompleteMaintenanceServiceRedux;

// Extract and export each action creator by name
export const {
    openModalCompleteMaintenance,
    reset,
    reset: resetCompleteMaintenanceService
} = actions;

export default reducer;