import {
    DASHBOARD_PANEL_GADGET_CHART_DRIVER_GROUP,
    DASHBOARD_PANEL_GADGET_CHART_DRIVER_GROUP_RESPONSE,
    DASHBOARD_PANEL_GADGET_CHART_DATA,
    DASHBOARD_PANEL_GADGET_CHART_DATA_RESPONSE
} from "Redux/actionTypes";

export const driverGroupChartPanel = (data) => {
    return {
        type: DASHBOARD_PANEL_GADGET_CHART_DRIVER_GROUP,
        payload: {},
    };
};

export const driverGroupChartPanelResponse = (data) => {
    return {
        type: DASHBOARD_PANEL_GADGET_CHART_DRIVER_GROUP_RESPONSE,
        payload: data,
    };
};

export const chartDashboardPanel = (data) => {
    return {
        type: DASHBOARD_PANEL_GADGET_CHART_DATA,
        payload: {data},
    };
};

export const chartDashboardPanelResponse = (data) => {
    return {
        type: DASHBOARD_PANEL_GADGET_CHART_DATA_RESPONSE,
        payload: data,
    };
};