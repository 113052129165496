import * as messages from './messages'

export class Notification {

  executeArray = () => {
    return [false, {}]
  }

  execute = (item, state) => {
    let notificationQueue = sessionStorage.getItem("notificationQueue")
    let sessionId = sessionStorage.getItem("sesionId")
    notificationQueue = notificationQueue && JSON.parse(notificationQueue) || []

    //Compare sesion to donwload 
    let notify = true
    if ((item.sessionId && item.sessionId !== sessionId) && notificationQueue?.find(x => x == item.id)) {
      notify = false
    }

    if (notify) {
      let message = messages[item.type]

      if (item.sessionId) {
        if (notificationQueue.length > 49)
          notificationQueue.shift()

        notificationQueue.push(item.id)
        sessionStorage.setItem("notificationQueue", JSON.stringify(notificationQueue))
      }

      if (item.type != 'KML' && message) {
        let objMessage = new message();
        return objMessage.execute(item, state)
      }
    }

    return [false, {}]
  }
}