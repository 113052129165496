import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// controls
import Table, {Column} from '../../table'
//helpers
import {formatDatetime, getCategoryFilterType, getNameEntity, useHandleExportReport} from 'Modules/reports/views/main/components/reportsTab/helper';

//hooks
import {useSidePanelMap, useTableReport} from 'Core/hooks';
//actions
import {clearRedux, reportsMainGenerateEvents, reportsMainGenerateReportUpdate} from 'Redux/actions'
import moment from 'moment';
// styles
import './chatHistoryReport.scss';

const ChatHistoryReport = (props) => {
    const {messages, report} = props;
    const {data, filters, summary, loadingSummary, timeZone} = report;
    const {filterEntity, otherFilters, startRange, endRange} = filters;

    const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
    const [unitDriverSelected, setUnitDriverSelected] = useState();
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
    const [currentNewSortField, setCurrentNewSortField] = useState("driverId");
    const [currentNewSortOrder, setCurrentNewSortOrder] = useState("asc");

    const exportHandler = useHandleExportReport(report)

    const loadingCreateLandMark = useSelector(state => state.vehicleLandMarkRedux.loadingCreateLandMark)
    const createLandMarkResult = useSelector(state => state.vehicleLandMarkRedux.createLandMarkResult)

    const reportRef = useRef();
    const dispatch = useDispatch()
    useEffect(() => {
        if (report) {
            const newReport = JSON.parse(JSON.stringify(report));
            // to keep the reference to the units selected
            reportRef.current = newReport;
        }
    }, [JSON.stringify(report)]);

    useEffect(() => {
        if (!loadingCreateLandMark && createLandMarkResult) {
            dispatch(reportsMainGenerateReportUpdate(report, currentPage, currentRowsPerPage, currentNewSortField, currentNewSortOrder))
            dispatch(clearRedux("LANDMARK", {"createLandMarkResult": null}));
        }

    }, [loadingCreateLandMark, createLandMarkResult])

    const onChangeExpandable = (unit) => {
        if(!unit.noData){
            let events = {
                data: [],
                filters: {
                    filterType: getCategoryFilterType(filterEntity),
                    filterArray: unit,
                    page: 1,
                    rowsPerPage: 10,
                    sortField: 'eventDate',
                    otherFilters
                }
            }
            dispatch(reportsMainGenerateEvents(report, events))
        }
    }

    const renderExpandableRow = (row) => {
        return (
            <div>
                {
                    !row.noData && (
                        <div>
                            <h3 className="landmark-set">Dispatch</h3>
                        </div>
                    )
                }
                
                <Table
                    loading={loadingSummary}
                    data={row.noData ? [{}]: (summary?.items || [{}])}
                    totalItems={row.noData ? 1 : (summary?.total || 1)}
                    onChangedPage={(page, rowsPerPage, sortField, sortOrder) => {
                        let events = {
                            data: [],
                            filters: {filterType: getCategoryFilterType(filterEntity), filterArray: row, page, rowsPerPage, sortField, sortOrder, otherFilters}
                        }
                        dispatch(reportsMainGenerateEvents(report, events))
                    }}
                    serverSideTable={true}
                    hideNumberOfRows={true}
                    hiddenPagination={false}
                    classNameColumns={'report-column-events-expandible'}
                    noDataMessage={messages["generatedReport_noEvents"]}
                    numberofRows={10}
                    centerPagination
                    noData={row.noData}
                >
                    <Column label={messages["generatedReport_timeAndDate"]} field="dateTime" noSorting>
                        {(element) => {
                            return (
                                <div>
                                    <span className="speed-report-stacked">{element.dateTime && formatDatetime(element.dateTime, "h:mm A", timeZone)}</span>
                                    <span className="speed-reportstacked speed-report-event-date">{element.dateTime && formatDatetime(element.dateTime, "MMM D", timeZone)}</span>
                                </div>
                                
                            )
                        }}
                    </Column>
                    <Column label={messages["generatedReport_toAndFrom"]} field="messageDirection" noSorting>
                        {(element) => {
                            return (
                                element.messageDirection === 'To' ? (
                                    <div>
                                        <strong className="color-start">{element.messageDirection}</strong>
                                    </div>
                                ) : (
                                    <div>
                                        <strong className="color-stop">{element.messageDirection}</strong>
                                    </div>
                                )
                                
                            )
                        }}
                    </Column>
                    <Column label={messages["generatedReport_message"]} field="message" noSorting>
                        {(element) => {
                            return (
                                
                                    <div>
                                        <strong>{element.message}</strong>
                                    </div>
                                
                            )
                        }}
                    </Column>
                </Table>
            </div>
        )
    }

    let parametersTable = useTableReport({
        data: data.items,
        report,
        messages,
        hideHeader: true,
        renderExpandableRow,
        onChangeExpandable,
        propertyToShowExpandable: 'driverName',
        classNameColumns: 'start-stop-idle-main-table'
    });

    return (
        <Fragment>
            <Table
                {...parametersTable}
                {...exportHandler}
            >
                <Column label="Day" field="label" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span>
                                  <span><strong>{getNameEntity(filterEntity, messages)}:</strong> {element.driverName} </span>
                                </span>
                            </div>
                        );
                    }}
                </Column>
                <Column label={messages['generatedReport_totalIdleTime']} field="label" noSorting>
                    {(element) => {
                        const localTimeStart = moment.utc(startRange).local().format();
                        const localTimeEnd = moment.utc(endRange).local().format();
                        return (
                            <div>
                                <span>
                                  <span>
                                    <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                                  </span>
                                </span>
                            </div>
                        );
                    }}
                </Column>
            </Table>
            <SideMapComponent hiddenButton hiddenSearchMap/>
        </Fragment>
    );
}

export default ChatHistoryReport;
