import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { LogManager, ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
    NEW_ENTITY_TAG_PICKER_GET_TAGS
} from 'Redux/actionTypes';

import {
    entityTagPickerGetTagsResponse
} from 'Redux/actions';

const getOffspring = (tree, children, offspring = []) => {
    let newOffspring = [ ...offspring ];
    for (let i = 0; i < children?.length; i++) {
        let child = children[i];
        newOffspring.push(child?.id);

        if (tree[child?.id]) {
            newOffspring = getOffspring(tree, tree[child?.id], newOffspring);
        }
    }

    return newOffspring;
}

const getChildren = (tree, tag, path = []) => {
    let newPath = [ ...path ];
    if (tag?.parentId) {
        newPath.push(tag?.parentId);
    }

    let newTag = {
        ...tag,
        ancestry: newPath,
        offspring: [],
        children: []
    };

    if (tree[tag?.id]) {
        const children = tree[tag?.id];
        newTag.offspring = getOffspring(tree, tree[tag?.id], []);
        let newChildren = [];
        for (let i = 0; i < children?.length; i++) {
            let child = children[i];
            let newChild = getChildren(tree, child, newPath);
            newChildren.push(newChild);
        }

        newTag.children = newChildren;
    }

    return newTag;
}

function* entityTagPickerGetTagsRequest({ payload }) {
    const { entity } = payload;

    try {
        const response = yield call(
            clientQuery,
            `
            query searchTags ($openSearch: String, $parentId: Int, $limit: Int, $offset: Int, $entityName: String) {
                tags: searchTags (openSearch: $openSearch, parentId: $parentId, limit: $limit, offset: $offset, entityName: $entityName) {
                    items {
                        id
                        label
                        color
                        parentId
                        path
                        hasChildren
                        disabled
                    }
                    total
                }
            }
            `,
            {
                openSearch: "",
                parentId: 0,
                limit: 0,
                offset: 0,
                entityName: entity
            },
            endpoints.GRAPHQL_GENERAL,
        );
        
        if (!response || !response.tags) {
            throw 'No tags data';
        }

        const { tags: { items }} = response;
        
        let newItems = [];
        for (let x = 0; x < items?.length; x++) {
            const item = items[x];
            if (!item?.disabled) {
                newItems.push(item);
            }
        }

        let tree = {};
        for (let m = 0; m < newItems?.length; m++) {
            const newItem = newItems[m];
            if (!newItem?.parentId) {
                if (!tree[0]) tree[0] = [];
                tree[0].push(newItem);
            } else if (newItem?.parentId && newItems?.find(ni => ni?.id === newItem?.parentId)) {
                if (!tree[newItem?.parentId]) tree[newItem?.parentId] = [];
                tree[newItem?.parentId].push(newItem);
            } else if (newItem?.parentId) {
                let currentPath = newItem?.path?.split("/");
                currentPath = currentPath?.reverse();

                if (currentPath?.length === 1) {
                    if (!tree[0]) tree[0] = [];
                    tree[0].push({
                        ...newItem,
                        path: null,
                        parentId: null
                    });
                } else if (currentPath?.length > 1) {
                    for (let h = 0; h < currentPath?.length; h++) {
                        let p = currentPath[h];
                        let newParent = newItems?.find(ni => ni?.label === p);
                        if (newParent) {
                            if (!tree[newParent?.id]) tree[newParent?.id] = [];
                            tree[newParent?.id].push({
                                ...newItem,
                                parentId: newParent?.id
                            });
                            break;
                        }
                    }
                }
            }
            
        }

        let data = [];
        data = tree[0] || [];
        for (let i = 0; i < data?.length; i++) {
            const parent = data[i];
            data[i] = getChildren(tree, parent);
        }

        yield put(entityTagPickerGetTagsResponse(newItems, data, false));
    } catch (exc) {
        ExceptionManager(exc, 'modules/.common/components/NewEntityTagPicker/redux/saga.js', 'entityTagPickerGetTagsRequest');
        yield put(entityTagPickerGetTagsResponse([], [], true));
    }
}

function* watchMethods() {
    yield takeEvery(NEW_ENTITY_TAG_PICKER_GET_TAGS, entityTagPickerGetTagsRequest);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}