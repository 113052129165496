export const ACCOUNT_API_KEYS_SEARCH = "ACCOUNT_API_KEYS_SEARCH";
export const ACCOUNT_API_KEYS_SEARCH_RESPONSE = "ACCOUNT_API_KEYS_SEARCH_RESPONSE";

export const ACCOUNT_API_KEYS_OPEN_CREATE_EDIT_MODAL = "ACCOUNT_API_KEYS_OPEN_CREATE_EDIT_MODAL";

export const ACCOUNT_API_KEYS_GET_API_KEY = "ACCOUNT_API_KEYS_GET_API_KEY";
export const ACCOUNT_API_KEYS_GET_API_KEY_RESPONSE = "ACCOUNT_API_KEYS_GET_API_KEY_RESPONSE";

export const ACCOUNT_API_KEYS_CREATE_EDIT_API_KEY = "ACCOUNT_API_KEYS_CREATE_EDIT_API_KEY";
export const ACCOUNT_API_KEYS_CREATE_EDIT_API_KEY_RESPONSE = "ACCOUNT_API_KEYS_CREATE_EDIT_API_KEY_RESPONSE";

export const ACCOUNT_API_KEYS_OPEN_DELETE_MODAL = "ACCOUNT_API_KEYS_OPEN_DELETE_MODAL";
export const ACCOUNT_API_KEYS_DELETE_API_KEYS = "ACCOUNT_API_KEYS_DELETE_API_KEYS";
export const ACCOUNT_API_KEYS_DELETE_API_KEYS_RESPONSE = "ACCOUNT_API_KEYS_DELETE_API_KEYS_RESPONSE";