import {
    FSM_OPEN_SETTINGS
} from "Redux/actionTypes";

export const openSettingsFsm = (open) => {
    return {
        type: FSM_OPEN_SETTINGS,
        payload: {open},
    };
};

