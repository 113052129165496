// Dependencies
import { all } from 'redux-saga/effects';

// Components
import alertsTabSaga from 'Modules/alerts/views/main/components//alertsTab/redux/saga';
import wizardAlertSaga from 'Modules/alerts/components/wizardAlert/redux/saga';
import alertCommonSaga from 'Modules/alerts/common/redux/saga';

// Merge sagas
export default function* rootSaga() {
  yield all([
    alertsTabSaga(),
    wizardAlertSaga(),
    alertCommonSaga()
  ]);
}