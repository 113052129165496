import {
    getLivePhotoResponse
} from '../../../../../.globals/redux/actions'

export class LIVEPHOTO {
  execute = (data, state, dispatch) => {
    let res = {};

    if (!data?.isSessionEnd) {
      res[data?.esn] = { ...data, id: Math.random() * 1000, status: "live" };
      dispatch(getLivePhotoResponse(res));
    } else {
      res[data?.esn] = { ...data, id: Math.random() * 1000, status: "stopped" };
      dispatch(getLivePhotoResponse(res));
    }

    return [false];
  };
}

