import FsmMainView from "Modules/fsm/views/main/lan/en";
import FormView from "Modules/fsm/views/fsmSettings/components/formSettings/lan/en";
import QuickMessageView from "Modules/fsm/views/fsmSettings/components/quickMessage/lan/en";
import QuickResponseView from "Modules/fsm/views/fsmSettings/components/quickResponse/lan/en";
import MealBreakNotificationsView from "Modules/fsm/views/fsmSettings/components/mealBreakNotifications/lan/en";
import driverStatusView from "Modules/fsm/views/fsmSettings/components/driverStatus/lan/en";
import timeCardView from "Modules/fsm/views/fsmSettings/components/timeCards/lan/en";
import fsmMessagesiew from "Modules/fsm/views/fsmMessages/lan/en";
import fsmRouteView from "Modules/fsm/views/fsmRoutes/lan/en";

const locales = {
    ...FsmMainView,
    ...FormView,
    ...QuickMessageView,
    ...QuickResponseView,
    ...MealBreakNotificationsView,
    ...driverStatusView,
    ...timeCardView,
    ...fsmMessagesiew,
    ...fsmRouteView,
};

export default locales;