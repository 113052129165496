import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../core/logManager';

import { resetALL } from '../../../../../.globals/redux/actions';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    answers: null,
    id: null,
    error: null,
    open: false,
    openAdvancedFilters: false,
};

const route = 'modules/dynamicForms/modals/viewAnswers/redux/slice'

export const getDynamicFormAnswers = createAsyncThunk('dynamicForms/modals/viewAnswers/getDynamicFormAnswers', async ({ id }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
            query getDynamicFormAnswers(
                $id: String!
            ) {
                answers: getDynamicFormAnswers(
                    id: $id
                ) {
                    id
                    formId
                    answerName
                    dynamicForm {
                        id
                        name
                        pages {
                            id
                            title
                            fields {
                                id
                                type
                                label
                                required
                                readOnly
                                placeHolder
                                help
                                attributes
                            }
                        }
                    }
                    formValues {
                        id
                        label
                        type
                        value
                    }
                    isDraft
                    coordinates {
                        latitude
                        longitude
                        accuracy
                        provider
                        address
                        city
                        state
                    }
                    updatedOn
                    updatedBy
                }
            }
            `,
            { id },
            endpoints.GRAPHQL_GENERAL_V2
        );

        if (!response?.answers) {
            throw `Error while getting dynamic form answers: ${id}`;
        }
    
        let answers = {
            ...response?.answers,
            dynamicForm: {
                ...response?.answers?.dynamicForm,
                properties: {
                    allowDraft: response?.answers?.dynamicForm?.properties?.allowDraft
                },
                pages: response?.answers?.dynamicForm?.pages?.map(page => {
                    let newPage = {
                        ...page,
                        fields: page?.fields?.map(field => {
                            let newField = {
                                ...field,
                                attributes: field.attributes ? JSON.parse(field.attributes) : {}
                            };
    
                            delete newField.__typename;
                            return newField;
                        })
                    };
    
                    delete newPage.__typename;
                    return newPage;
                })
            }
        };

        return answers;
    } catch (exc) {
        ExceptionManager(exc, route, 'getDynamicFormAnswers');
        return rejectWithValue(exc)
    }
});

//Slice
const dynamicFormsViewAnswersRedux = createSlice({
    name: 'dynamicForms/viewAnswers',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openViewAnswersModal(state, action) {
            state.open = true;
            state.id = action?.payload;
        },
        closeViewAnswersModal(state) {
            state.open = false;
        },
        openAdvancedFilters(state) {
            state.openAdvancedFilters = true;
        },
        closeAdvancedFilters(state) {
            state.openAdvancedFilters = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)
     
        builder.addCase(getDynamicFormAnswers.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(getDynamicFormAnswers.fulfilled, (state, action) => {
            state.loading = false;
            state.answers = action.payload;
        });
        builder.addCase(getDynamicFormAnswers.rejected, (state, action) => {
            state.loading = false;
            state.answers = null;
            state.error = true;
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = dynamicFormsViewAnswersRedux;

// Extract and export each action creator by name
export const { 
    reset, 
    openViewAnswersModal, 
    closeViewAnswersModal,
    openAdvancedFilters, 
    closeAdvancedFilters
} = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;