export default {
  "accountChangePassword_title": "Cambiar Contraseña",
  "accountChangePassword_currentPassword": "Contraseña actual",
  "accountChangePassword_newPassword": "Nueva contraseña",
  "accountChangePassword_confirmNewPassword": "Confirmar nueva contraseña",
  "accountChangePassword_passwordNotEqual": "Las contraseñas no coinciden",
  "accountChangePassword_passwordValidation": "La contraseña debe contener al menos 1 carácter alfabético en minúscula, 1 carácter alfabético en mayúscula, 1 carácter numérico, 1 carácter especial, no se permiten espacios y debe tener 6 caracteres o más (20)",
  "accountChangePassword_passwordChanged": "Su contraseña ha sido cambiada",
  "accountChangePassword_incorrectPassword": "Contraseña actual incorrecta",
  "accountChangePassword_diferentErrorPassword": "La nueva contraseña debe ser diferente a la anterior.",
  "accountChangePassword_attemptLimitExceeded": "Se superó el límite de intentos, por favor intente más tarde",
  "accountChangePassword_minLengthMessage": "La contraseña debe contener un mínimo de caracteres:",
  "accountChangePassword_maxLengthMessage": "La contraseña debe contener un máximo de caracteres:"
};