import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';

// Actions
import {
  reportsMainGenerateReportProgress
} from 'Redux/actions';

function* getStopSummaryDetailsReport(report, event) {
  const { filters , name, reportTimeRanges} = report;
  const { otherFilters } = filters

  const { filterType, filterArray, page, rowsPerPage, sortField, sortOrder, startRange, endRange } = event.filters;
  try {
    let data = yield call(
      clientQuery,
      `query( $conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getStopSummaryDetailsReport(conditions: $conditions, limit: $limit, offset: $offset) {
          total
          limit
          offset
          category
          items {
            stop
            dateTime
            address
            status
            duration
          }
        }
      }`,
      {
        conditions: {
          reportName: name,
          category: {
            field: filterType,
            value: filterArray
          },
          dateRange: {
            fields: [
              "unitTime"
            ],
            GTE: startRange,
            LTE: endRange,
            reportTimeRanges
          },
          sortField: {
            field: sortField || "driverId",
            order: sortOrder === 'desc' ? 'DESC' : 'ASC'
          }
        },
        limit: rowsPerPage,
        offset: page
      },
      endpoints.GRAPHQL_PX_REPORTING,
    );

    if (data?.Report) {
      report.summary = data.Report
      report.loadingSummary = false
      yield put(reportsMainGenerateReportProgress(report))
    } else {
      report.loadingSummary = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  } catch (exc) {
    report.loadingSubEvents = false
    ExceptionManager(exc, 'src/modules/reports/views/main/components/reportsTab/.components/reports/stopSummaryReport', 'getStopSummaryDetailsReport');
    yield put(reportsMainGenerateReportProgress(report))
  }
}

export default getStopSummaryDetailsReport;
