export default {
  settings_crew: "Crew",
  settings_unit_settings: "Unit Settings",
  settings_unit_groups: "Unit Groups",
  settings_driver_groups: "Driver Groups",
  settings_input_labels: "Inputs",
  settings_vehicle_status: "Vehicle Status",
  settings_drivers: "Drivers",
  settings_vehicle_equipment: "Equipment",
  settings_vehicle_key_fobs: "Key Fobs",
  settings_vehicle_temperature: "Temperature",
  settings_fuel_card: "Fuel Card",
  settings_drivers_status: "Driver Status",
};