// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    MAP_LANDMARKS_IMPORT_LANDMARK,
    MAP_LANDMARKS_IMPORT_LANDMARK_RESPONSE
} from "Redux/actionTypes";

// Actions
import {
    importLandmarkResponse
} from "Redux/actions";
import { clientGET } from 'Core/data/RestApi';

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* importLandmark({ payload }) {
    try {
        const { fileKey, delimiter } = payload?.data;

        let query = `
                mutation importLandmarks ($fileKey: String!, $delimiter: String!) {
                    importLandmarks (fileKey: $fileKey, delimiter: $delimiter) {
                        stepFunctionId
                        transactionId
                        transactionType
                        processStartDate
                    }
                }
      `;


        const response = yield call(
            clientMutation,
            query,
            {
                fileKey,
                delimiter
            },
            endpoints.GRAPHQL_GENERAL,
        );

        if (response && response.importLandmarks) {
            yield put(importLandmarkResponse({ importLandmark: response, error: false }));
        } else {
            yield put(
                importLandmarkResponse({ error: true, importLandmark: null })
            );
        }
    } catch (exc) {
        yield put(
            importLandmarkResponse({ error: true, importLandmark: null })
        );
        ExceptionManager(
            exc,
            "modules/settings/views/driver/redux/sagas",
            "importLandmark"
        );
    }
}


function* watchMethods() {
    yield takeEvery(MAP_LANDMARKS_IMPORT_LANDMARK, importLandmark);
}

export default function* rootSaga() {
    yield all([fork(watchMethods)]);
}