import {
    MAP_GEOFENCES_DELETE_OPEN_MODAL,
    MAP_GEOFENCES_DELETE_GEOFENCE,
    MAP_GEOFENCES_DELETE_GEOFENCE_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    open: false,
    geofences: [],
    response: null,
    loadingResponse: false,
    deleteAll: false,
    unselectedRows : []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MAP_GEOFENCES_DELETE_OPEN_MODAL:
            return {
                ...state,
                open: action.payload.open,
                geofences: action.payload.geofences || [],
                response: null,
                deleteAll: action.payload.deleteAll || false,
                unselectedRows: action.payload.unselectedRows || [],
                loadingResponse: false
            };

        case MAP_GEOFENCES_DELETE_GEOFENCE:
            return {
                ...state,
                response: null,
                loadingResponse: true
            };

        case MAP_GEOFENCES_DELETE_GEOFENCE_RESPONSE:
            return {
                ...state,
                response: action.payload.data,
                loadingResponse: false,
                deleteAll: false,
                unselectedRows: [],
            };

        case CLEAR_REDUX:
            return ["", "MAP_GEOFENCE_DELETE_GEOFENCE"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
            return { ...state };
    }
}