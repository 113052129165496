import {
    MAP_ICON_PICKER_SEARCH_ICONS,
    MAP_ICON_PICKER_SEARCH_ICONS_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
    placesIcons: [],
    mapsIcons: [],
    error: false,
    filters: {
        openSearch: "",
        category: "",
        limit: 0,
        offset: 0
    },
    loadingIcons: false
};

export default (state = INIT_STATE, action) => {
    let error;
    switch (action.type) {
        case MAP_ICON_PICKER_SEARCH_ICONS:
            return { 
                ...state,
                error: false,
                loadingIcons: true,
                filters: action.payload.data
            };

        case MAP_ICON_PICKER_SEARCH_ICONS_RESPONSE:
            const { items, total, error, category } = action.payload.data;
            let icons = {};
            if (category === "places") {
                icons.placesIcons = items;
            } else {
                icons.mapsIcons = items;
            }
            
            return { 
                ...state, 
                ...icons,
                error: false,
                filters: { ...state.filters, total: total || 0 },
                loadingIcons: false
            };

        case CLEAR_REDUX:
            return ["", "MAP_ICON_PICKER"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state }

        default:
      return state;
    }
}