export class ASYNC_REPORT {
  execute = (payload, state) => {
    const items = JSON.parse(JSON.stringify(state?.asyncReports));
      const foundIndex = items.findIndex(report => report?.reportInstanceId === payload.data?.reportInstanceId);
      let newReport = null;
      if(foundIndex > -1 && items[foundIndex]?.pending){
        items[foundIndex] = {
          ...items[foundIndex],
          pending: false
        }
        newReport = JSON.parse(JSON.stringify(items[foundIndex]));
        if(newReport){
          newReport.status = payload.data?.status
        }
      }
      return [true, { asyncReports: items, newReport}]
  }
}