import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";
import { generarColorHex } from 'Core/hooks/utils';

import moment from "moment";

const INIT_STATE = {
    loading: false,
    open: false,
    result: null,
    error: false,
    list: []
};

const eta = "realtimeMaps/main/eta";

export const realtimeMapsDeleteEtaLink = createAsyncThunk('realtimeMaps/main/eta/realtimeMapsDeleteEtaLink', async (payload, { rejectWithValue, dispatch }) => {
    try {

        const response = await clientMutation(
            `
                mutation deleteEtaLinkBulk (
                $ids: [Int!]
            ) {
                etaLink: deleteEtaLinkBulk(
                    ids: $ids
                )
            }
            `,
            { ids: payload },
            endpoints.GRAPHQL_GENERAL_V2
        );

        return response?.etaLink

    } catch (exc) {
        ExceptionManager(exc, eta, 'realtimeMapsDeleteEtaLink');
        return rejectWithValue(exc)
    }
});

export const RealtimeMapEtaLinkDeleteRedux = createSlice({
    name: eta,
    initialState: INIT_STATE,
    reducers: {
        etaDeleteReset: (state, action) => {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        etaDeleteOpenModal: (state, action) => {
            return {
                ...state,
                open: true,
                list: action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(realtimeMapsDeleteEtaLink.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(realtimeMapsDeleteEtaLink.fulfilled, (state, action) => {
            state.result = action?.payload;
            state.loading = false;
        });
        builder.addCase(realtimeMapsDeleteEtaLink.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });
    }
})

const { actions, reducer } = RealtimeMapEtaLinkDeleteRedux;

export const { etaDeleteReset, etaDeleteOpenModal } = actions;

export default reducer;