import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// controls
import Table, {Column} from '../../table'
//helpers
import {
    formatDatetime,
    getCategoryFilterType,
    getNameVehicle,
    useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';
import { GetFormatForMoment } from 'Core/data/Helpers';
//hooks
import {useSidePanelMap, useTableReport} from 'Core/hooks';
//actions
import {clearRedux, reportsMainGenerateEvents, reportsMainGenerateReportUpdate} from 'Redux/actions'
import moment from 'moment';

const EngineHoursReport = (props) => {
    const {messages, report, user} = props;
    const {data, filters, summary, loadingSummary, timeZone} = report;
    const {filterEntity, otherFilters, startRange, endRange} = filters;
    const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
    const [currentNewSortField, setCurrentNewSortField] = useState("id");
    const [currentNewSortOrder, setCurrentNewSortOrder] = useState("asc");
    const newData = []
    const exportHandler = useHandleExportReport(report)
    const momentFormat = GetFormatForMoment(user.dateformat)

    const loadingCreateLandMark = useSelector(state => state.vehicleLandMarkRedux.loadingCreateLandMark)
    const createLandMarkResult = useSelector(state => state.vehicleLandMarkRedux.createLandMarkResult)

    const reportRef = useRef();
    const dispatch = useDispatch()

    for(let i = 0; i < data?.items?.length; i++){
        if(data?.summaryInfo?.length){
            const entitySummary = data?.summaryInfo?.find(item => item.id === data?.items[i]?.id)
            if(entitySummary){
                newData.push({...data?.items[i], ...entitySummary})
            }
        }
    }

    useEffect(() => {
        if (report) {
            const newReport = JSON.parse(JSON.stringify(report));
            // to keep the reference to the units selected
            reportRef.current = newReport;
        }
    }, [JSON.stringify(report)]);


    useEffect(() => {
        if (!loadingCreateLandMark && createLandMarkResult) {
            dispatch(reportsMainGenerateReportUpdate(report, currentPage, currentRowsPerPage, currentNewSortField, currentNewSortOrder))
            dispatch(clearRedux("LANDMARK", {"createLandMarkResult": null}));
        }

    }, [loadingCreateLandMark, createLandMarkResult])

    const onChangeExpandable = (unit) => {
        if(!unit.noData){
            let events = {
                data: [],
                filters: {
                    filterType: getCategoryFilterType(filterEntity),
                    filterArray: unit,
                    page: 1,
                    rowsPerPage: 10,
                    sortField: 'eventDate',
                    otherFilters
                }
            }
            dispatch(reportsMainGenerateEvents(report, events))
        }
    }

    const exitstLabel = (label) => {
        if(!label){
            return '--'
        } 

        return label
    }

    let parametersSummary = useTableReport(
        {
            data, 
            report, 
            messages, 
            hideHeader: false
        }
    );

    const renderExpandableRow = (row) => {
        let parametersSummaryTable = {
            ...parametersSummary,
            data: summary?.items || [],
            totalItems: summary?.total || 0,
            hideHeader: false,
            serverSideTable: true,
            onChangedPage: (page, rowsPerPage, sortField, sortOrder) => {
              let events = {
                data: [],
                filters: { filterType: getCategoryFilterType(filterEntity), filterArray: row, page, rowsPerPage, sortField, sortOrder, otherFilters }
              }
              dispatch(reportsMainGenerateEvents(report, events))
            },
            onChangeSelector: null,
            itemsSelector: null,
            generateExport: false,
            hideSelectorUnitsDriver: true,
            groups:report.filters.units || [],
            hideNumberOfRows:true,
            centerPagination: true,
          };

        return (
            <div>
                <Table
                   {...parametersSummaryTable}
                   loading={loadingSummary}
                   noData={row.noData}
                >
                    <Column label={messages["reportsMainRT_TimeDate"]} field="dateTime" noSorting>
                        {(element) => {
                            return (
                                <div>
                                    <span>{element.dateTime && formatDatetime(element.dateTime, `h:mm A ${momentFormat || "MMMM D, YYYY"}`, timeZone)}</span>
                                </div>
                            )
                        }}
                    </Column>
                    <Column label={messages['reportsMainRT_Location']} field="location" noSorting>
                        {(element) => {
                            const formatLabel = exitstLabel(element.location)
                            return (
                                <div>
                                    <span>{formatLabel}</span>
                                </div>
                            )
                        }}
                    </Column>
                    <Column label={messages["reportsMainRT_hours"]} field="hours" noSorting>
                        {(element) => {
                              const formatLabel = exitstLabel(element.hours)
                            return (
                                <div>
                                    <span>{formatLabel}</span>
                                </div>
                            )
                        }}
                    </Column>
                </Table>
            </div>
        )
    }

    let parametersTable = useTableReport({
        data: newData?.length ? newData : data.items,
        report,
        messages,
        hideHeader: true,
        renderExpandableRow,
        onChangeExpandable,
        propertyToShowExpandable: 'id'
    });

    return (
        <Fragment>
            <Table
                {...parametersTable}
                {...exportHandler}
            >
                <Column label="Day" field="label" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span><strong>{getNameVehicle(filterEntity, messages)}:</strong> {element.idLabel} </span>
                            </div>
                        );
                    }}
                </Column>
                <Column label={messages['generatedReport_totalIdleTime']} field="label" noSorting>
                    {(element) => {
                        const localTimeStart = moment.utc(startRange).local().format();
                        const localTimeEnd = moment.utc(endRange).local().format();
                        return (
                            <div>
                                <span>
                                  <span>
                                    <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                                  </span>
                                </span>
                            </div>
                        );
                    }}
                </Column>
                <Column label={messages['reportsMainRT_totalDays']} field="totalDays" noSorting>
                    {(element) => {
                        return (
                            <span>
                                <strong>{messages['reportsMainRT_totalDays']}:</strong> {element.totalDays}
                            </span>
                        );
                    }}
                </Column>
                <Column label={messages['reportsMainRT_totalHours']} field="totalHours" noSorting>
                    {(element) => {
                        return (
                            <span>
                                <strong>{messages['reportsMainRT_totalHours']}:</strong> {element.totalHours}
                            </span>
                        );
                    }}
                </Column>
                <Column label={messages['reportsMainRT_averageHours']} field="avgHours" noSorting>
                    {(element) => {
                        return (
                            <span>
                                <strong>{messages['reportsMainRT_averageHours']}:</strong> {element.avgHours}
                            </span>
                        );
                    }}
                </Column>
            </Table>
            <SideMapComponent hiddenButton hiddenSearchMap/>
        </Fragment>
    );
}


export default EngineHoursReport;
