//Module Keys
let locales = {};

//Merge Views
import mainView from 'Modules/fleet/views/main/lan/en';
import ModalScheduleStarter from 'Modules/fleet/components/ModalScheduleStarter/lan/en';
import ModalEnableDisable from 'Modules/fleet/components/ModalEnableDisable/lan/en';
import ModalOutputs from 'Modules/fleet/components/ModalOutputs/lan/en';
import ModalBulkOutputs from 'Modules/fleet/components/ModalBulkOutputs/lan/en';
import ModalPing from 'Modules/fleet/components/ModalPing/lan/en';
import ModalLandMark from 'Modules/fleet/components/ModalLandMark/lan/en';

locales = { 
    ...locales, 
    ...mainView, 
    ...ModalScheduleStarter, 
    ...ModalEnableDisable, 
    ...ModalOutputs, 
    ...ModalPing, 
    ...ModalLandMark,
    ...ModalBulkOutputs
};

export default locales;
